import Highcharts from "highcharts";

const dxColors = [
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#C7F464",
  "#775DD0",
  "#3F51B5",
  "#03A9F4",
  "#4CAF50",
  "#F9CE1D",
  "#FF9800",
  "#33B2DF",
  "#546E7A",
  "#D4526E",
  "#13D8AA",
  "#A5978B",
  "#4ECDC4",
  "#FF4560",
  "#81D4FA",
  "#546E7A",
  "#FD6A6A",
  "#449DD1",
  "#F86624",
  "#EA3546",
  "#662E9B",
  "#C5D86D",
  "#662E9B",
  "#F86624",
  "#F9C80E",
  "#EA3546",
  "#43BCCD",
  "#D7263D",
  "#1B998B",
  "#2E294E",
  "#F46036",
  "#E2C044",
  "#A300D6",
  "#7D02EB",
  "#5653FE",
  "#2983FF",
  "#00B1F2"
];

Highcharts.setOptions({
  colors: dxColors
});

const costRadialGauge = {
  orange: '#FF9800',
  blue: '#449DD1',
  crimson: '#D4526E'
}

const kpiBar = {
  orange: '#FF9800',
  blue: '#008FFB',
  gray: '#C4BBAF'
}

export {
  dxColors,
  costRadialGauge,
  Highcharts,
  kpiBar
};
