<template>
  <div>
    <div><highcharts :options="chartOptions" /></div>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
export default {
  components: {
    highcharts: Chart
  },
  props: {
    donutData: null
  },
  created: function () {
    Object.assign(this.chartOptions, this.donutData);
  },
  data () {
    return {
      chartOptions: {
        lang: {
          decimalPoint: ",",
          thousandsSep: "."
        },
        chart: {
          height: 200,
          minPadding: 0,
          maxPadding: 0,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie"
        },
        credits: {
          enabled: false
        },
        title: {
          text: ""
        },
        tooltip: {
          backgroundColor: "#FCFFC5"
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              style: {
                color:
                  (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                  "black"
              }
            },
            startAngle: 0,
            endAngle: 360,
            size: "110%"
          }
        },
        series: [
          {
            name: "Value",
            innerSize: "50%",
            colorByPoint: true,
            data: []
          }
        ]
      }
    };
  }
};
</script>
