import { brandAwareness } from "./BrandAwareness.js"
import { engagement } from "./Engagement.js"
import { jobApplicants } from "./JobApplicants.js"
import { leadGeneration } from "./LeadGeneration.js"
import { videoViews } from "./VideoViews.js";
import { websiteConversions } from "./WebsiteConvesions.js"
import { websiteVisits } from "./WebsiteVisits.js"
import { linkedInEnums } from "../../Constants/LinkedInEnums.js";
// import { equals } from "./../../helper.js"

class LinkedInConfig {
  constructor (Config) {
    this.config = Config
  }
  getConfig (configName) {
    let config = { ...this.getLinkedInConfig(configName) };
    return config
  }
  getLinkedInConfig (configName) {
    switch (this.config.objective) {
      case linkedInEnums.objectiveEnums.BrandAwareness:
        return brandAwareness[configName]
      case linkedInEnums.objectiveEnums.WebsiteVisits:
        return websiteVisits[configName]
      case linkedInEnums.objectiveEnums.Engagement:
        return engagement[configName]
      case linkedInEnums.objectiveEnums.VideoViews:
        return videoViews[configName]
      case linkedInEnums.objectiveEnums.LeadGeneration:
        return leadGeneration[configName]
      case linkedInEnums.objectiveEnums.WebsiteConvesions:
        return websiteConversions[configName]
      case linkedInEnums.objectiveEnums.JobApplicants:
        return jobApplicants[configName]
      default:
        return null;
    }
  }
  getObjectiveClassById (id) {
    if (id == linkedInEnums.objectiveEnums["Brand awareness"]) {
      return brandAwareness;
    } else if (id == linkedInEnums.objectiveEnums["Website Visits"]) {
      return websiteVisits;
    } else if (id == linkedInEnums.objectiveEnums["Engagement"]) {
      return engagement
    } else if (id == linkedInEnums.objectiveEnums["Video Views"]) {
      return videoViews;
    } else if (id == linkedInEnums.objectiveEnums["Lead Generation"]) {
      return leadGeneration;
    } else if (id == linkedInEnums.objectiveEnums["Website Convesions"]) {
      return websiteConversions;
    } else if (id == linkedInEnums.objectiveEnums["Job Applicants"]) {
      return jobApplicants;
    }
  }
  displayHeaderNameLookup () {
    return {
      "HOT_Is Valid": "Valid",
      "HOT_Ad Name": "Ad Name",
      "HOT_Introductory Text": "Introductory Text",
      "HOT_Ad Title": "Headline",
      "HOT_Call To Action": "Call To Action",
      "HOT_Description": "Description",
      "HOT_Destination URL": "Destination URL",
      "HOT_Headline": "Headline",
      "HOT_Image": "Image",
      "HOT_Lead Form Id": "Lead Form"
    }
  }
  columnWidths () {
    return {
      "default": 100,
      108: ["HOT_Image"],
      200: [
        "HOT_Ad Name",
        "HOT_Introductory Text",
        "HOT_Ad Title",
        "HOT_Call To Action",
        "HOT_Description",
        "HOT_Destination URL",
        "HOT_Headline",
        "HOT_Image",
        "HOT_Lead Form Id"
      ],
      240: ["HOT_Introductory Text", "HOT_Description"]
    }
  }
}

export { LinkedInConfig }
