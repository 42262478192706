<template>
  <div>
    <div
      data-toggle="popover"
      data-placement="top"
      type="button"
      :id="dynamicId"
    >
      <div>
        <span v-if="iconshow">
          {{ weekCount }}
        </span>

        <span v-else>
          <i class="fa fa-check-circle iconCircle" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    $(`#${this.dynamicId}`).popover({
      content: this.tableContent,
      html: true,
    });
  },
  props: {
    iconshow: {
      type: Boolean,
    },
    weekCount: {
      type: Number,
    },
    hover: {
      type: Array,
    },
    dynamicId: {
      type: String,
    },
  },
  computed: {
    tableContent() {
      var content = `<div style="height: 200px; overflow: auto;"> <table class="popovertable">`;
      if(typeof this.hover != 'undefined' && this.hover != null){
         for (let x of this.hover) {
        content += `<tr><td> ${x.businessProfileId == null ? x.portalId : x.businessProfileId}</td> <td>`;
        for(let y of x.featureValues)
        {
            content += `${y.name}, `;
        }
        content += `</td></tr>`;
      }
      }
      content += `</table> </div>`;
      return content;
    },
  },
};
</script>

<style scoped>
::v-deep .popover{
  width: 350px;
  background-color: black !important;
  color: white;
  opacity: 0.9;
}
::v-deep .popovertable{
  border: none;
  width: 100%;
}
::v-deep .popovertable td:nth-child(1) {
    opacity: 0.9;
    background-color: black !important;
    color: white !important;
    border: none !important;
    width: 20%;
    vertical-align:top !important;
    padding-bottom: 5px;
  }
::v-deep .popovertable td:nth-child(2) {
    opacity: 0.9;
    background-color: black !important;
    color: white !important;
    border: none !important;
    width: 80%;
    vertical-align:top !important;
    padding-bottom: 5px;
  }
.iconCircle {
  color: green;
  font-size: 22px;
}
::v-deep .popover.top>.arrow:after{
  border-top-color: black;
}
</style>

<style src="../../../../App/Styles/dx-iview.css"></style>
