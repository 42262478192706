import ApiService from '../Features/Promotion/ApiService.js';
import { StoreType } from '../Features/Promotion/constants/constants.js';
export default {
  data () {
    return {
      storeSelectionType: 0,
      filterStoreParams: "",
      showStoreFiltering: true,
      selectedFilterType: "Advertiser",
      isViewStores: false,
      tableValueArray: [],
      isMultiselectLoading: false
    };
  },
  methods: {
    createBatchStoreRequest (entity, maxBatchCount, filterType, isCalledFromLauncher) {
      var promises = []
      let entityList = entity.split(',');
      let batchCount = Math.floor(entityList.length / maxBatchCount);
      if (batchCount * maxBatchCount != entityList.length) {
        batchCount += 1;
      }
      for (let i = 0; i < batchCount; i++) {
        let batchedEntityList = entityList.slice(i * (maxBatchCount), ((i + 1) * maxBatchCount));
        if (filterType == 'Advertiser') {
          let promise = ApiService.getStores(false, '', batchedEntityList.join(','), !isCalledFromLauncher);
          promises.push(promise)
        } else if (filterType == 'StoreCode') {
          let promise = ApiService.getStores(false, batchedEntityList.join(','), '', !isCalledFromLauncher);
          promises.push(promise);
        } else {
          let promise = ApiService.getStores(false, '', '', !isCalledFromLauncher, batchedEntityList.join(','));
          promises.push(promise);
        }
      }
      return promises;
    },
    async fetchStores ({ advertiserIds = '', storeCodes = '', storeIds = '', includeDefaultStore = false, isCalledFromLauncher = false }) {
      var maxBatchCount = 100;
      var promiseList = [];
      let self = this;
      var mergedArray = [];
      var fetchedStores = [];
      this.storesBeingFetched = true;
      this.renderMultiSelect = false;
      this.isMultiselectLoading = true;
      var filterType = '';
      if (advertiserIds && advertiserIds.split(',').length > maxBatchCount) {
        filterType = 'Advertiser'
        promiseList = this.createBatchStoreRequest(advertiserIds, maxBatchCount, filterType, isCalledFromLauncher)
      } else if (storeCodes && storeCodes.split(',').length > maxBatchCount) {
        filterType = 'StoreCode'
        promiseList = this.createBatchStoreRequest(storeCodes, maxBatchCount, filterType, isCalledFromLauncher)
      } else if (storeIds && storeIds.split(',').length > maxBatchCount) {
        filterType = 'StoreId'
        promiseList = this.createBatchStoreRequest(storeIds, maxBatchCount, filterType, isCalledFromLauncher)
      }
      if (!promiseList.length) {
        if (this.storeSelectionType == 0) {
          promiseList.push(ApiService.getStores(false, storeCodes, advertiserIds, includeDefaultStore, storeIds));
        } else if (this.storeSelectionType != 0 && !this.$store.state.allStoresAlreadyFetched) {
          promiseList.push(ApiService.getStores());
        }
      }
      await Promise.all(promiseList).then(async res => {
        mergedArray = res.reduce((acc, curr) => acc.concat(curr), []);
        fetchedStores = mergedArray.filter((obj, index, self) =>
          index === self.findIndex((o) => o.storeCode === obj.storeCode)
        );
        if (!isCalledFromLauncher) {
          if (this.isStoreEligible) {
            fetchedStores.forEach(store => {
              store['$isDisabled'] = !self.isStoreEligible(store);
              return store;
            });
          }
          let defaultStoreIndex = fetchedStores.findIndex(x => x.status == 1 && x.isDefault == true)
          if (defaultStoreIndex == -1 && !this.defaultStore) {
            self.$store.set_promoAlertMessages([...self.promoAlertMessages, "Could not find a default store"])
            this.isMultiselectLoading = false;
            return
          } else {
            self.defaultStore = fetchedStores[defaultStoreIndex]
            fetchedStores.splice(defaultStoreIndex, 1)
          }
          if (fetchedStores.length == 0) {
            self.set_promoAlertMessages([...self.promoAlertMessages, "Store setup is incomplete"])
          }
        }
        var storeIdList = [];
        fetchedStores.forEach(store => {
          storeIdList.push(store.id);
        })
        if (!isCalledFromLauncher) {
          self.stores = [...self.stores, ...fetchedStores].reduce((store1, store2) => {
            if (!store1.some(item => item.storeCode == store2.storeCode)) {
              store1.push(store2);
            }
            return store1;
          }, []);
          if (this.setStoreLabelAndDimension && typeof this.setStoreLabelAndDimension === 'function') {
            await this.setStoreLabelAndDimension(storeIdList);
          }
        } else {
          let savedDbStores = self.$store.state.dbStores ? self.$store.state.dbStores : []
          let dbStores = [...savedDbStores, ...fetchedStores].reduce((store1, store2) => {
            if (!store1.some(item => item.storeCode == store2.storeCode)) {
              store1.push(store2);
            }
            return store1;
          }, []);
          self.$store.commit("set_dbStores", dbStores)
          self.locations = JSON.parse(JSON.stringify(self.$store.state.dbStores));
          if (!self.isCreateMode && self.$store.state.selectedMediaPlan && self.locations && self.locations.length) {
            self.setStoreData();
            await self.setupMultiselect();
          }
        }
      })
      this.renderMultiSelect = true;
      this.storesBeingFetched = false;
      this.showStoreFiltering = true;
      this.isMultiselectLoading = false;
      return fetchedStores;
    },
    async bulkApply (isCalledFromLauncher = false) {
      this.isViewStores = true;
      this.closePromoAlert();
      let bulkStoresResponse;
      let self = this;
      var invalidStoresCount = 0;
      var validStores = [];
      var newlyFetchedStores = [];
      this.enabledStores = JSON.parse(JSON.stringify(self.enabledStores));
      if (isCalledFromLauncher) {
        this.enabledStores = JSON.parse(JSON.stringify(self.multiSelectArray));
      }
      if (this.filterStoreParams) {
        this.filterStoreParams = this.filterStoreParams.replace(/\n/g, ',');
        if (this.selectedFilterType == 'Advertiser') {
          bulkStoresResponse = await this.fetchStores({ advertiserIds: this.filterStoreParams, includeDefaultStore: !isCalledFromLauncher, isCalledFromLauncher: isCalledFromLauncher })
        } else if (this.selectedFilterType == 'StoreCode') {
          bulkStoresResponse = await this.fetchStores({ storeCodes: this.filterStoreParams, includeDefaultStore: !isCalledFromLauncher, isCalledFromLauncher: isCalledFromLauncher })
        }
        // For launcher we can select Default store also in multiselect but not in promotion flow
        if (isCalledFromLauncher) {
          validStores = bulkStoresResponse.filter(store => !store.$isDisabled);
        } else {
          validStores = bulkStoresResponse.filter(store => !store.$isDisabled && !store.isDefault);
        }
        self.enabledStores = [...self.enabledStores, ...JSON.parse(JSON.stringify(validStores))].reduce((store1, store2) => {
          if (!store1.some(item => item.storeCode == store2.storeCode)) {
            store1.push(store2);
          }
          return store1;
        }, []);
        if (!isCalledFromLauncher) {
          if (!self.loadingExsisitingPromo) {
            invalidStoresCount = bulkStoresResponse.length - validStores.length;
          } else {
            newlyFetchedStores = self.enabledStores.filter(allstore => !self.promoConfigToEdit.enabledStoreIds.includes(allstore.id))
          }
        } else {
          newlyFetchedStores = validStores.filter(store => !self.selectedLocations.includes(store.storeCode))
          self.selectedLocations = [...self.enabledStores.map(obj => obj.storeCode)];
          self.multiSelectArray = self.locations.filter(location => self.selectedLocations.includes(location.storeCode));
        }

        self.isMultiselectLoading = false;
        if (this.showStoreFiltering) {
          this.showFilteredStores(invalidStoresCount, newlyFetchedStores, bulkStoresResponse, isCalledFromLauncher)
        }
        this.showMultiselect()
      } else {
        this.set_promoAlertMessages([...this.promoAlertMessages, "Input can't be an empty string"])
      }
      this.generateTableValuesforAlert();
    },
    showFilteredStores (invalidStoresCount, newlyFetchedStores, bulkStoresResponse, isCalledFromLauncher = false) {
      if (!isCalledFromLauncher) {
        this.promoConfig.storeType = StoreType.selective;
      }
      let invalidStoreMessage = "";
      let defaultStoremessage = ''
      if (this.selectedFilterType == 'StoreCode') {
        if (this.filterStoreParams.split(',').length != bulkStoresResponse.length) {
          let storesFetchedCount = bulkStoresResponse.length
          if (!isCalledFromLauncher) {
            if (this.filterStoreParams.includes(this.defaultStore.storeCode)) {
              defaultStoremessage = `Skipped: 1 ${this.defaultStore.name}.`
              storesFetchedCount += 1;
            }
          } if ((this.filterStoreParams.split(',').length - storesFetchedCount) > 0) {
            invalidStoreMessage = `Not found: ${this.filterStoreParams.split(',').length - storesFetchedCount} <span id="underlinedSpan" style="text-decoration: underline; cursor: pointer;"> (Click to copy failed stores) </span>`;
          }
        }
      } else {
        let distinctAdvertiserIdsFetched = [...new Set(this.enabledStores.map(store => store.advertiserId))];
        let advertiserIdInRequest = this.filterStoreParams.split(',')
        if (!(advertiserIdInRequest.every(element => distinctAdvertiserIdsFetched.includes(element)))) {
          let unfetchedAdvertiserCount = advertiserIdInRequest.filter(element => !distinctAdvertiserIdsFetched.includes(element)).length;
          if (!isCalledFromLauncher) {
            if (this.filterStoreParams.includes(this.defaultStore.advertiserId)) {
              defaultStoremessage = `Skipped: 1  (${this.defaultStore.name})`
              unfetchedAdvertiserCount -= 1;
            }
          } if (unfetchedAdvertiserCount > 0) {
            invalidStoreMessage = `Not found: ${unfetchedAdvertiserCount} <span id="underlinedSpan" style="text-decoration: underline; cursor: pointer;"> (Click to copy failed advertiser Ids) </span>`;
          }
        }
      } if (this.enabledStores.length) {
        this.set_promoAlertMessages([...this.promoAlertMessages, `Stores selected: ${this.enabledStores.length}`])
      } if (newlyFetchedStores.length) {
        this.set_promoAlertMessages([...this.promoAlertMessages, `New Stores selected: ${newlyFetchedStores.length}`])
      } if (invalidStoreMessage) {
        this.set_promoAlertMessages([...this.promoAlertMessages, `${invalidStoreMessage}`])
      } if (invalidStoresCount) {
        this.set_promoAlertMessages([...this.promoAlertMessages, `Ineligible: ${invalidStoresCount}`])
      } if (defaultStoremessage) {
        this.set_promoAlertMessages([...this.promoAlertMessages, `${defaultStoremessage}`])
      }
      this.generateTableValuesforAlert();
    },
    generateTableValuesforAlert () {
      this.tableValueArray = [];
      this.promoAlertMessages.forEach(message => {
        const index = message.indexOf(':');
        if (index !== -1) {
          let part1 = message.slice(0, index).trim();
          let part2 = message.slice(index + 1).trim();
          this.tableValueArray.push([part1, part2]);
        } else {
          this.tableValueArray.push([message, ''])
        }
      })
    },
    copyUnfetchedStores (e) {
      if (e) {
        let clickedElId = e.target.id
        if (clickedElId === 'underlinedSpan') {
          let totalStores = this.filterStoreParams.split(',');
          var fetchedStores = [];
          if (this.selectedFilterType == 'Advertiser') {
            fetchedStores = this.enabledStores.filter(store => !store.$isDisabled).map(store => store.advertiserId);
            if (this.defaultStore) {
              fetchedStores.push(this.defaultStore.advertiserId);
            }
          } else {
            fetchedStores = this.enabledStores.filter(store => !store.$isDisabled).map(store => store.storeCode);
            if (this.defaultStore) {
              fetchedStores.push(this.defaultStore.storeCode);
            }
          }
          let unfetchedStores = totalStores.filter(item => !fetchedStores.includes(item))
          this.copyToClipboard(unfetchedStores.join(','))
        }
      }
    }
  }
}
