import { Objective } from "./Objective.js"
let ColumnConfig = function () {
  this.customJson = new function () {
    this.base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_JSON", "HOT_Landing Page URL", "HOT_Jio Creative ID", "HOT_Ad Target"];
    this.requiredBase = ["HOT_Ad Name", "HOT_JSON"];
    this.creativeHeader = ["HOT_JSON"];
    this.card = []
    this.requiredCard = []
  }();
}
let leads = new Objective(new ColumnConfig())
export { leads }
