<template>
    <Modal class="target-modal"
        width=99
        title="Create Target"
        v-model="isModalOpen"
        @on-cancel="close()"
        >
        <div slot="header" class="modal-header">
          <div class="header-style">
            <span>
              <h4 class="modal-title">Create Target</h4>
            </span>
            <span @click="close">
              <i class="fa fa-times fa-lg" />
            </span>
          </div>
        </div>
        <div
          class="modal-body targets-body" style="height: 80vh;"
        >
          <v-row>
          <v-col span="16">
              <div class="target-mode">
                <span class="account-names">Accounts : </span>
                <div class="account-select">
                  <Select
                    placeholder="Select an account"
                    filterable
                    class="select-target"
                    v-model="selectedAccount"
                    @on-change="handleAccounChange"
                  >
                    <Option
                      v-for="(value, index) in accounts"
                      :key="index"
                      :value="value.id"
                    >{{ getAccountsText(value) }}</Option>
                  </Select>
                </div>
              </div>
              <div class="target-mode" v-if="selectedAccount > 0">
                <span class="label-names">Target Mode : </span>
                <div style="margin-left: 4px;">
                  <RadioGroup class="radio-group" v-model="targetMode" @on-change="handleTargetMode">
                    <Radio label="New"></Radio>
                    <Radio label="Edit Existing"></Radio>
                  </RadioGroup>
                  <Select
                    :placeholder="selectText"
                    v-model="selectedTarget"
                    filterable
                    class="select-target"
                    @on-change="storeTarget"
                  >
                    <Option
                      v-for="(value, index) in savedTargetsByBpseIdMapper[selectedBpseId]"
                      :key="index"
                      :value="value.id"
                    >{{ value.name }}</Option>
                  </Select>
                </div>
              </div>
              <div v-if="(targetMode === 'New' || selectedTarget > 0) && selectedAccount > 0">
                <TargetName ref="targetName" :bpseId="selectedBpseId"
                :targetMode="targetMode"
                :loadData="prefillData"
                @reset-selected-target="resetSelectedTarget"/>
                <div class="target-mode">
                  <span class="label-names">Labels : </span>
                  <div class="labels-select labels">
                  <Select
                    :key="getUniqueKey"
                    placeholder="Select Labels"
                    multiple
                    placement="bottom-start"
                    :remote-method="query => getLabels(query)"
                    v-model="selectedLabels"
                    filterable
                    :loading="labelsLoading"
                    remote
                  >
                    <Option
                      v-for="(item, index) in labels"
                      :value="item.id"
                      :key="index"
                      :label="item.name"
                    >
                    </Option>
                  </Select></div>
                </div>
                <TargetLocation v-for="(item, index) in facebookTargetLocationData" :key="item.locationId"
                :locationData="item"
                :compKey="index"
                :bpseId="selectedBpseId"
                :showModal="isModalOpen"
                :targetMode="targetMode"
                :loadData="prefillData"
                @add-target="handleTargetCreation('location','add-target')"
                @copy-target="handleTargetCreation('location','copy-target', index)"
                @delete-target="handleTargetCreation('location','delete-target', index)" />
                <DetailTargeting v-for="(item, targetIndex) in facebookFlexibleSpecData"
                :key="item.flexId"
                :targetData="item"
                :currentComponentKey="targetIndex"
                :bpseId="selectedBpseId"
                :targetMode="targetMode"
                :loadData="prefillData"
                @add-target="handleTargetCreation('flexibleSpec','add-target')"
                @copy-target="handleTargetCreation('flexibleSpec','copy-target', targetIndex)"
                @delete-target="handleTargetCreation('flexibleSpec','delete-target', targetIndex)" />
              </div>
          </v-col>
          <v-col span="8" class="fixed-column">
          <AudiencePreview v-if="(targetMode === 'New' || selectedTarget > 0) && selectedAccount > 0"
            :bpseId="selectedBpseId"
            :targetMode="targetMode"
            @update-target="(value) => updateSavedTargets(value)"
            @add-saved-target="(value) => addSavedTarget(value)"/>
          </v-col>
          </v-row>
        </div>
        <div slot="footer" class="modal-footer hide"></div>
    </Modal>
</template>

<script>
import { APIService } from "./../../ApiService.js";
import { mapState, mapMutations } from "vuex";
import { Modal, Row, Col, RadioGroup, Radio, Select, Option } from "iview";
import TargetName from "./TargetName.vue";
import TargetLocation from "./TargetLocation.vue";
import DetailTargeting from "./DetailTargeting.vue";
import AudiencePreview from "./AudiencePreview.vue";
import { locationCategories, SupportedTargetsProperties, RelationshipStatus, EducationSatus, filterConditions } from '../../../CampaignLauncher/Constants/FacebookEnums.js'

export default {
  name: "TargetCreationWrapper",
  components: {
    Modal,
    "v-row": Row,
    "v-col": Col,
    TargetName,
    TargetLocation,
    DetailTargeting,
    AudiencePreview,
    RadioGroup,
    Radio,
    Select,
    Option
  },

  data: function () {
    return {
      search: "",
      loader: false,
      socialTargetRequestData: {},
      socialTargets: [],
      iFrameloaded: false,
      iframeUrl: "",
      targetMode: "New",
      selectedTarget: 0,
      browseData: [],
      prefillData: false,
      searchTimeout: null,
      labelsLoading: false,
      labels: [],
      selectedLabels: [],
      selectedAccount: 0
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.selectedAccount = this.selectedBpseId;
      if (this.selectedBpseId) {
        this.$store.dispatch("getTargetsByBpseId", this.selectedBpseId);
      }
    })
  },
  props: {
    showModal: Boolean,
    selectedBpseId: Number,
    accounts: Array
  },
  methods: {
    close () {
      this.$emit('close-modal');
      this.selectedTarget = 0;
      this.targetMode = "New";
      this.reset_facebookTargetCreationData();
      this.selectedLabels = []
      this.labels = [];
      if (this.$refs.targetName) {
        this.$refs.targetName.reset();
      }
      this.$store.dispatch("reset_globalLabel");
    },
    handleTargetCreation (targetType, value, index) {
      if (targetType === 'location') {
        if (value === 'add-target') {
          this.$store.dispatch("add_facebookTargetCreationData", { addType: "location" });
        } else if (value === 'copy-target') {
          this.$store.dispatch("copy_facebookTargetCreationData", { copyType: "location", index: index });
        } else if (value === 'delete-target') {
          this.$store.dispatch("delete_facebookTargetCreationData", { deleteType: "location", index: index });
        }
      } else if (targetType === 'flexibleSpec') {
        if (value === 'add-target') {
          this.$store.dispatch("add_facebookTargetCreationData", { addType: "flexibleSpec" });
        } else if (value === 'copy-target') {
          this.$store.dispatch("copy_facebookTargetCreationData", { copyType: "flexibleSpec", index: index });
        } else if (value === 'delete-target') {
          this.$store.dispatch("delete_facebookTargetCreationData", { deleteType: "flexibleSpec", index: index });
        }
      }
    },
    async getBrowseData () {
      try {
        var response = await APIService.getTargetingBrowse(this.selectedBpseId);
        this.browseData = response;
      } catch (error) {
        console.log(error);
      }
    },
    async storeTarget () {
      if (!this.browseData.length) {
        this.getBrowseData();
      }
      var target = this.savedTargetsByBpseIdMapper[this.selectedBpseId].find(t => t.id === this.selectedTarget)
      if (target) {
        this.prefillData = !this.prefillData;
        var targetObj = JSON.parse(target.targetString);
        this.verifyAndFormatTargetString(targetObj);
        targetObj.name = target.name
        if (this.targetMode === "Edit Existing") {
          this.$store.commit('set_savedTargetId', { targetId: target.id, index: 0 });
        }
        for (let i = 0; i < targetObj.flexible_spec.length; i++) {
          if (targetObj.flexible_spec[i]) {
            for (let property in targetObj.flexible_spec[i]) {
              this.formatFlexibleSpec(property, targetObj.flexible_spec, i)
            }
          }
        }
        if (targetObj.exclusions) {
          for (let property in targetObj.exclusions) {
            if (property !== 'custom_audiences') {
              targetObj.exclusions[property].forEach((item, itemIndex) => {
                item.type = property;
                this.browseData.forEach((browseItem) => {
                  if (browseItem.id === item.id) {
                    targetObj.exclusions[property][itemIndex] = browseItem;
                  }
                });
              })
            }
          }
        }
        delete targetObj.geo_locations['location_types'];
        if (targetObj.excluded_geo_locations) {
          delete targetObj.excluded_geo_locations['location_types'];
          this.formatLocationsFromTargetString(targetObj.excluded_geo_locations);
        }
        this.formatLocationsFromTargetString(targetObj.geo_locations);
        if (targetObj.flexible_spec[0].custom_audiences) {
          targetObj.custom_audiences = targetObj.flexible_spec[0].custom_audiences;
          delete targetObj.flexible_spec[0].custom_audiences;
        }
        if (targetObj.exclusions.custom_audiences) {
          targetObj.excluded_custom_audiences = targetObj.exclusions.custom_audiences;
          delete targetObj.exclusions.custom_audiences;
        }
        this.$store.dispatch("set_facebookTargetCreationData", ({ data: targetObj, index: 0 }));
      }
      this.labels = [];
      this.selectedLabels = [];
      var labelsForTarget = []
      if (this.selectedTarget) {
        labelsForTarget = await APIService.getLabelsBTargetId(this.selectedBpseId, this.selectedTarget);
      }
      if (labelsForTarget.length) {
        this.labels = labelsForTarget;
        labelsForTarget.forEach(element => {
          this.selectedLabels = [...this.selectedLabels, element.id];
        });
      }
    },
    handleTargetMode () {
      this.selectedTarget = 0
      this.reset_facebookTargetCreationData();
      this.$store.dispatch("reset_globalLabel");
      if (this.$refs.targetName) {
        this.$refs.targetName.reset();
      }
    },
    handleAccounChange () {
      if (this.selectedAccount) {
        this.selectedLabels = [];
        this.$emit('update-bpseId', this.selectedAccount);
        this.handleTargetMode();
      }
    },
    formatLocationsFromTargetString (location) {
      let countriesObj = []
      let zipsObj = []
      for (const property in location) {
        if (location.hasOwnProperty(property)) {
          for (const childObject of location[property]) {
            if (property === locationCategories.city) {
              childObject.type = "city";
            } else if (property === locationCategories.country) {
              countriesObj.push({ "key": childObject, "type": 'country', "country_code": childObject });
            } else if (property === locationCategories.geo_market) {
              childObject.type = "geo_market";
            } else if (property === locationCategories.region) {
              childObject.type = "region";
            } else if (property === locationCategories.zip) {
              zipsObj.push({ "key": childObject.key, "type": 'zip', "name": childObject.key })
            } else if (property === locationCategories.neighborhood) {
              childObject.type = "neighborhood";
            } else if (property === locationCategories.custom_location) {
              childObject.type = "custom_location";
              childObject.key = `(${childObject.latitude},${childObject.longitude})`;
              childObject.name = `(${childObject.latitude},${childObject.longitude})`;
            }
          }
        }
      }
      location.countries = countriesObj;
      location.zips = zipsObj;
    },
    formatFlexibleSpec (property, flexibleSpec, index) {
      if (property === "relationship_statuses") {
        flexibleSpec[index][property].forEach((item, itemIndex) => {
          let relationship = { id: item, type: property }
          RelationshipStatus.forEach((status) => {
            if (status.id == item) {
              relationship.name = status.name
              flexibleSpec[index][property][itemIndex] = relationship
            }
          })
        });
      } else if (property === "education_statuses") {
        flexibleSpec[index][property].forEach((item, itemIndex) => {
          let educationStatus = { id: item, type: property }
          EducationSatus.forEach((status) => {
            if (status.id == item) {
              educationStatus.name = status.name
              flexibleSpec[index][property][itemIndex] = educationStatus
            }
          })
        });
      } else if (property !== 'custom_audiences') {
        flexibleSpec[index][property].forEach((item, itemIndex) => {
          item.type = property;
          this.browseData.forEach((browseItem) => {
            if (browseItem.id === item.id) {
              flexibleSpec[index][property][itemIndex] = browseItem;
            }
          });
        });
      }
    },
    resetSelectedTarget () {
      this.selectedTarget = 0
    },
    updateSavedTargets (target) {
      this.savedTargetsByBpseIdMapper[this.selectedBpseId].forEach((t) => {
        if (t.id === target.id) {
          t.targetString = target.targetString;
          t.name = target.targetName;
        }
      })
    },
    addSavedTarget (target) {
      let updatedTargets = [ target, ...this.savedTargetsByBpseIdMapper[this.selectedBpseId] ]
      this.set_savedTargetsByBpseIdMapper({ bpseId: this.selectedBpseId, savedTargets: updatedTargets });
    },
    verifyAndFormatTargetString (targetString) {
      if (!targetString.flexible_spec) {
        targetString.flexible_spec = [{}]
      }
      if (!targetString.exclusions) {
        targetString.exclusions = {}
      }
      if (!targetString.genders) {
        targetString.genders = [1, 2]
      }

      let extraProperties = {}
      for (let propertyName in targetString) {
        if (targetString.hasOwnProperty(propertyName)) {
          if (!SupportedTargetsProperties.includes(propertyName)) {
            extraProperties[propertyName] = targetString[propertyName]
          }
        }
      }
      this.set_extraPropertiesFromTargets(extraProperties);
    },
    async getLabels (query) {
      this.labelsLoading = true;
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(async () => {
        try {
          let response = await APIService.getLabels('Name', filterConditions.LIKE, query);
          this.labels = response.labels;
          this.labelsLoading = false;
        } catch (error) {
          console.log(error);
        }
      }, 500);
    },
    getAccountsText (value) {
      if (value.NickName) {
        return `${value.NickName} (${value.AccountId})`;
      }
      return `${value.accountNickName} (${value.accountId})`
    },
    ...mapMutations(["reset_facebookTargetCreationData", "set_extraPropertiesFromTargets", "set_savedTargetsByBpseId", "set_savedTargetsByBpseIdMapper", "set_globalLabel"])
  },
  computed: {
    ...mapState(["businessProfilesSearchEngineId", "businessProfileId", "facebookTargetLocationData", "facebookTargetCreationData", "facebookFlexibleSpecData", "deltax", "facebookTargetMacroName", "savedTargetsByBpseIdMapper"]),
    selectText () {
      return this.targetMode === "New"
        ? "Load from Existing"
        : "Select a target";
    },
    isModalOpen: {
      get: function () {
        return this.showModal;
      },
      set: function () {}
    },
    getUniqueKey () {
      return `${this.selectedTarget || 0}-${this.selectedBpseId}-${+this.isModalOpen}`;
    }
  },
  watch: {
    selectedBpseId () {
      this.selectedAccount = this.selectedBpseId;
      this.$store.dispatch("getTargetsByBpseId", this.selectedBpseId);
    },
    selectedLabels (newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        let addedIds = newVal.filter(id => !oldVal.includes(id));
        addedIds.forEach((id) => {
          let label = this.labels.find((element) => element.id == id);
          if (label) {
            this.$store.dispatch("add_globalLabel", label);
          }
        })
      } else if (newVal.length < oldVal.length) {
        const removedIds = oldVal.filter(id => !newVal.includes(id));
        removedIds.forEach((id) => {
          this.$store.dispatch("remove_globalLabel", id);
        })
      }
      if (this.selectedBpseId) {
        this.$store.dispatch("generate_TargetAudience");
      }
    }
  }
};
</script>

<style scoped>
.modal-header {
    padding: 15px;
    border: none;
    min-height: 16.43px;
}
::v-deep .target-modal .modal-header {
  padding-bottom: 5px !important;
}
.target-modal .modal-dialog {
  width: 98% ;
  z-index: 9997;
}
.targets-body {
  padding: 0px !important;
  overflow: auto !important;
  height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
}
::v-deep .ivu-collapse-header {
  height: auto !important;
  line-height: unset !important;
}
::v-deep .ivu-modal{
  top: 15px !important;
}
.target-modal .modal-header {
  padding: 0px;
}
.targets-body {
  overflow: auto !important;
}
.header-style {
  display:flex;
  justify-content: space-between;
}
.modal-title {
  display: inline-block;
  margin-right:10px
}
.target-mode {
  display: flex;
  margin-left: 30px;
  font-size: 13px !important;
}
.select-target {
  width: 400px;
  display: block;
}
.label-names, .account-names {
  padding-top: 5px;
  color: #878787;
}
::v-deep .ivu-radio-wrapper {
  font-size: 13px;
  color: #878787;
}
::v-deep .ivu-select-item {
  font-size: 13px !important;
}
.fixed-column {
  position: sticky;
  top: 0;
}
.labels-select {
  margin-left: 39px;
}
.account-select{
  margin-left: 23px;
  margin-bottom: 35px;
}
.labels {
  width: 400px;
}
</style>
