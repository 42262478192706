<template>
  <div>
      <div
          v-if="alert.show"
          class="alert no-data"
          :class="alert.type"
        >
          {{ alert.message }}
        </div>
        <template v-else>
          <Row class="cl-ivu-row" v-for="post in filteredPosts" :key="post.id">
            <Row-Col span="1">
              <i
                v-if="post.uploadingMedia"
                v-tooltip="'Uploading to Facebook'"
                data-placement="right"
                class="fa spin-icon fa-spinner"
                key="spinner"
              >
              </i>
              <i
                v-else
                v-tooltip="'Add this post'"
                data-placement="right"
                class="far action-icon fa-plus-square"
                key="plus-square"
                @click="addToAdSheet(post)"
              >
              </i>
            </Row-Col>
            <Row-Col span="1">
                <i
                  v-tooltip="'Remove this post'"
                  data-placement="right"
                  class="far action-icon fa-minus-square"
                  key="minus-square"
                  @click="removeFromAdSheet(post)"
                >
                </i>
            </Row-Col>
            <Row-Col class="cl-widget-text" span="16" offset="1">
              <span> {{ post["caption"] | checkEmptyCaption() }} </span>
            </Row-Col>
            <Row-Col span="1" offset="1">
              <i
                v-if="post.uploadError"
                class="fa fa-exclamation-triangle"
                key="exclamation-triangle"
              >
              </i>
            </Row-Col>
            <Row-Col span="2" offset="1" class="list-image-wrapper">
              <img v-lazy="post['thumbnail_url'] || post['media_url']" class="list-image" />
            </Row-Col>
          </Row>
        </template>
  </div>
</template>

<script>
import Vue from "vue";
import { Row, Col } from "iview";
import { APIService } from "./../ApiService.js";
import { mapState, mapMutations, mapActions } from "vuex";
import VueLazyload from "vue-lazyload";
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js"

Vue.use(VueLazyload, {
  attempt: 1
});

export default {
  components: {
    Row,
    "Row-Col": Col
  },
  props: {
    searchQuery: { default: null },
    allPosts: { default: () => { return [] } },
    isFacebookPosts: { default: true }

  },
  mixins: [FacebookCampaignLauncherConfig],
  data () {
    return {
      processingData: false,
      filteredPosts: [],
      posts: [],
      searchPost: "",
      filterTimeout: null,
      checkValidPostTimeout: null,
      alert: {},
      postIdsaddedToAdSheet: {}
    };
  },
  mounted () {},
  watch: {
    allPosts () {
      this.posts = this.allPosts
        .filter(e => {
          if (e.boost_eligibility_info instanceof Object && !e.boost_eligibility_info.eligible_to_boost) return false;

          return true;
        })
        .map(this.formatPost);

      this.filteredPosts = this.posts;
    },
    searchQuery () {
      clearTimeout(this.filterTimeout)
      clearTimeout(this.checkValidPostTimeout)
      if (!this.isFacebookPosts) {
        this.alert.show = false

        if (!this.searchQuery) {
          this.filteredPosts = this.posts;
          this.processingData = false
          return
        }

        this.processingData = true
        var that = this
        this.filterTimeout = setTimeout(() => {
          let postId = that.searchQuery
          if (that.searchQuery.split("_").length == 2) {
            postId = that.searchQuery.split("_")[1]
          }
          // if (/^\d+$/.test(postId) == false) {
          //   this.alert = {
          //     show: true,
          //     message: "Please enter a valid Post Id",
          //     type: "alert-warning"
          //   }
          //   this.filteredPosts = []
          //   this.processingData = false
          //   return
          // }

          that.filteredPosts = that.posts.filter(x => x.id.includes(postId))
          if (that.filteredPosts && that.filteredPosts.length == 0) {
            APIService.getPostById(
              that.businessProfilesSearchEngineId,
              postId,
              this.selectedCampaignLauncherConfig.facebookPageId,
              true
            ).then(res => {
              let postInfo = res.data.data._data;
              that.addNewPost(postInfo)
            }).catch(() => {
              that.alert = {
                show: true,
                message: `Could not find Post with Id ${that.searchQuery}`,
                type: "alert-warning"
              }
            }).finally(() => {
              that.processingData = false
            })
          } else {
            that.processingData = false
          }
        }, 400)
      }
    },
    processingData () {
      this.$emit("processing", this.processingData);
    }
  },
  computed: {
    ...mapState([
      "businessProfilesSearchEngineId",
      "businessProfileId",
      "selectedCampaignLauncherConfig",
      "existingPostAds",
      "selectedFbObjective"
    ])
  },
  filters: {
    checkEmptyCaption (value) {
      if (!value) {
        return "No Caption"
      }
      return value
    }
  },
  methods: {
    ...mapMutations(["set_existingPostAds"]),
    ...mapActions(["uploadInstagramVideoToFacebook"]),
    addOrRemovePostFromAdSheet (post) {
      if (post.id in this.postIdsaddedToAdSheet) {
        this.removeFromAdSheet(post)
        this.$delete(this.postIdsaddedToAdSheet, post.id)
      } else {
        this.addToAdSheet(post)
        this.$set(this.postIdsaddedToAdSheet, post.id, true)
      }
    },
    async addToAdSheet (post) {
      try {
        if (post["media_type"] === "VIDEO") {
          post.uploadingMedia = true;
          post.uploadError = false;

          await this.uploadInstagramVideoToFacebook(post.id);
        }

        this.set_existingPostAds([...this.existingPostAds, post]);
        this.$set(this.postIdsaddedToAdSheet, post.id, true);
      } catch (error) {
        post.uploadError = true;
        console.error(error);
      } finally {
        post.uploadingMedia = false;
      }
    },
    removeFromAdSheet (post) {
      let indexToRemove = this.existingPostAds.findLastIndex(x => x.id == post.id)

      if (indexToRemove != -1) {
        this.set_existingPostAds(
          this.existingPostAds.filter((x, index) => index != indexToRemove)
        );
      }
      this.$delete(this.postIdsaddedToAdSheet, post.id)
    },
    formatPost (post) {
      return this.includeFields(post);
    },
    addNewPost (postData) {
      let errorMessage = "";

      if (postData.boost_eligibility_info instanceof Object && !postData.boost_eligibility_info.eligible_to_boost) {
        errorMessage = `The Post with Id ${this.searchPost} is not eligible to be boosted`
      }

      if (errorMessage !== "") {
        this.alert = {
          show: true,
          message: errorMessage,
          type: "alert-warning"
        }
      } else {
        let formattedPost = this.formatPost(postData)
        this.filteredPosts = [formattedPost]
        this.posts.push(formattedPost)
      }
    },
    includeFields (post) {
      return { ...post, uploadingMedia: false, uploadError: false };
    }
  }
};
</script>

<style>
.input-search-bar .ivu-input {
  height: 30px;
}
</style>
<style scoped>
.search-padding {
  padding: 0 0 0 15px;
}
.flex-center {
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center > i {
  font-size: 16px;
}
.list-image-poptip {
  max-width: 250px;
  max-height: 250px;
}
.list-image-wrapper {
  position: relative;
  width: 2rem;
  height: 2rem;
  float: right;
}
.list-image {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transition: transform 0.05s linear;
  transform: translate(-50%, -50%);
}
.spin-icon {
  animation: spin-icon 1s linear infinite;
}
@keyframes spin-icon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
