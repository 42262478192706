<template>
<Collapse simple style="margin-top: 0px;">
  <Panel>
    Reach & Frequency Suggestions
          <span v-if="isRnfSuggestionLoad">
            <i class="fa fa-spinner fa-spin" />
          </span>
    <div slot="content">
      <div style="margin-left:10px;margin-right:10px" v-if="rNFDatas.length > 0">
      <row>
        <row-col span="4">
          <label>LineItem</label>
        </row-col>
        <row-col span="4">
          <label>{{rNFDatas[0]["budget"]["label"]}}</label>
        </row-col>
        <row-col span="4">
          <label>{{rNFDatas[0]["perOfUniverse"]["label"]}}</label>
        </row-col>
        <row-col span="4">
          <label>{{rNFDatas[0]["reach"]["label"]}}</label>
        </row-col>
        <row-col span="4">
          <label>{{rNFDatas[0]["frequency"]["label"]}}</label>
        </row-col>
        <row-col span="4">
          <label>{{rNFDatas[0]["cpm"]["label"]}}</label>
        </row-col>
      </row>
    </div>
    <VuePerfectScrollbar class="widget-body">
        <div v-for="rnf in rNFDatas" :key="rnf.id">
          <row>
            <row-col span="4">
              <p>{{rnf.lineItemName}}</p>
            </row-col>
            <row-col span="4">
              <p>{{rnf["budget"]["value"]}}</p>
            </row-col>
            <row-col span="4">
              <p>{{rnf["perOfUniverse"]["value"]}}</p>
            </row-col>
            <row-col span="4">
              <p>{{rnf["reach"]["value"]}}</p>
            </row-col>
            <row-col span="4">
              <p>{{rnf["frequency"]["value"]}}</p>
            </row-col>
            <row-col span="4">
              <p>{{rnf["cpm"]["value"]}}</p>
            </row-col>
          </row>
        </div>
      </VuePerfectScrollbar>
    </div>
  </Panel>
  </Collapse>
</template>
<script>
import { Row, Col, Panel, Collapse } from "iview";
import { mapState } from "vuex";
import { APIService } from "./../ApiService.js";
import ApiService from '../BulkExport/Components/ApiService';
import { EventBus } from "../EventBus.js"
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: "ReachAndFrequencyWidget",
  components: {
    "row": Row,
    "row-col": Col,
    VuePerfectScrollbar,
    Collapse,
    Panel
  },
  data: function () {
    return {
      reachAndFrequencySuggestions: {},
      lineItems: [],
      config: {},
      rNFDatas: [],
      isRnfSuggestionLoad: false,
      targets: []
    }
  },
  props: {
    loadRnfConfig: { type: Boolean, default: false }
  },
  computed: {
    ...mapState(["businessProfilesSearchEngineId", "selectedCampaignLauncherConfigId", "mediaPlanLineItems", "mediaPlanId", "businessProfileId", "campaignLauncherConfigs", "savedTargetsMapperByBpseId"])
  },
  watch: {
    selectedCampaignLauncherConfigId (val) {
      if (val == "") { return; }
      this.rNFDatas = []
      this.reachAndFrequencySuggestions = {}
      this.GetReachAndFrequencySuggestion(val)
    }
  },
  methods: {
    GetReachAndFrequencySuggestion (configId) {
      this.config = JSON.parse(this.campaignLauncherConfigs[configId].config)
      this.targets = this.savedTargetsMapperByBpseId[this.config.bpseId];
      this.lineItems = this.mediaPlanLineItems.filter(x => x.config == configId && x.isReachAndFrequencyConfig == true)
      for (var lineitem in this.lineItems) {
        if (!Array.isArray(this.lineItems[lineitem].targets)) {
          this.lineItems[lineitem].targets = [this.lineItems[lineitem].targets]
        }
      }
      if (this.lineItems.length > 0) {
        this.processRnFData();
      }
    },
    getPredictionSuccessCallback (response) {
      this.rNFDatas = []
      this.reachAndFrequencySuggestions = {}
      let rnfSuggestions = JSON.parse(response)
      this.isRnfSuggestionLoad = false
      Object.keys(rnfSuggestions).forEach((key) => {
        this.reachAndFrequencySuggestions[key] = JSON.parse(rnfSuggestions[key])
      })
      this.processRnFData()
    },
    processRnFData () {
      let self = this
      this.lineItems.forEach((lineItem) => {
        let rnfData = {}
        // setting a label and values
        rnfData["reach"] = {}
        rnfData["cpm"] = {}
        rnfData["budget"] = {}
        rnfData["perOfUniverse"] = {}
        rnfData["frequency"] = {}
        if (self.config.adset.billingEvent == "15") {
          rnfData["reach"]["label"] = "Unique ThruPlays"
          rnfData["cpm"]["label"] = "Cost per ThruPlay"
        } else {
          rnfData["reach"]["label"] = "Reach"
          rnfData["cpm"]["label"] = "CPM"
        }
        if (lineItem.reachAndFrequencyData) {
          for (var targetId of lineItem.targets) {
            var reachAndFrequencyDataByTarget = lineItem.reachAndFrequencyData.find(rnf => rnf.targetId == targetId)
            if (reachAndFrequencyDataByTarget) {
              rnfData["lineItemName"] = lineItem.name + this.getTargretName(targetId)
              rnfData["id"] = reachAndFrequencyDataByTarget.reachAndFrequencyPredictionId;
              rnfData["cpm"]["value"] = reachAndFrequencyDataByTarget.reachAndFrequencySettings.cpa || "-"
              rnfData["budget"]["label"] = "Budget"
              rnfData["perOfUniverse"]["label"] = "% of Universe"
              rnfData["frequency"]["label"] = "Frequency"
              rnfData["budget"]["value"] = reachAndFrequencyDataByTarget.reachAndFrequencySettings.budget || "-";
              rnfData["perOfUniverse"]["value"] = reachAndFrequencyDataByTarget.reachAndFrequencySettings.percentageOfUniverse || "-";
              rnfData["reach"]["value"] = (reachAndFrequencyDataByTarget.reachAndFrequencySettings.reach || "-").toLocaleString("en");
              rnfData["frequency"]["value"] = reachAndFrequencyDataByTarget.reachAndFrequencySettings.frequency || "-"
              self.rNFDatas.push(JSON.parse(JSON.stringify(rnfData)))
            }
          }
        }
      })
    },
    getTargretName (targetId) {
      var target = this.targets.find(t => t.Id == targetId);
      return target ? target.Name : ""
    }
  },
  created () {
    EventBus.$on("getReachAndFrequency", () => {
      this.rNFDatas = []
      this.reachAndFrequencySuggestions = {}
      this.GetReachAndFrequencySuggestion(this.selectedCampaignLauncherConfigId)
    });
  }
}
</script>
<style src="../../../Styles/dx-iview.css"></style>
<style scoped>
.widget  hr{
  margin: 0px 0px 5px 0px;
}
</style>
