export const AuthFlowAppliesTo = [
  {
    Id: 1,
    Name: "MEDIA_PLAN_APPROVAL",
    Label: "Media Plan Approval"
  },
  {
    Id: 2,
    Name: "FACEBOOK_BULK_AD_CREATION",
    Label: "Facebook Bulk Ad Launch"
  }
]

export const ApprovalStatus = {
  IN_REVIEW: "IN_REVIEW",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED"
}

export const AuthStageActions = [
  {
    Id: "ON_ENTER",
    Value: "EMAIL_CURRENT_STATE_TEAMS"
  },
  {
    Id: "ON_SUCCESS",
    Value: "EMAIL_NEXT_STAGE_TEAMS"
  },
  {
    Id: "ON_REJECT",
    Value: "EMAIL_ALL"
  }
]
