<template>
  <div class="row">
    <div>{{FetchedCommentData}}</div>
    <div v-if="isPageLoading" class="row padding-loader centered">
      <i class="fa fa-spinner fa-spin fa-2x centered spinner-icon"></i>
    </div>
    <div v-if="!isPageLoading">
      <div class="col-md-12 row-spacing">
        <div v-show="bannerAlert" class="col-md-12 banner alert alert-success" role="alert">
          <button
            type="button"
            @click.stop="alertClose"
            aria-label="Close"
            data-dismiss="alert"
            class="close close-icon-padding"
          >×</button>
          Note has been saved successfully. Click
          <a
            v-if="!isEdit"
            class="underline"
            href="#"
            v-bind:class="allComments"
            @click="allComments"
          >here</a> to view the note.
        </div>
      </div>
      <div class="col-md-12 row-spacing">
        <div class="col-md-2 right-align">
          Date
          <span class="error-text f_req">*</span>
        </div>
        <div class="col-md-10">
          <i-datePicker
            class="full-length no-radius"
            type="date"
            name="Date"
            v-validate="'required'"
            v-model="dateSelected"
            format="MMM dd yyyy"
            placeholder="Select date"
          ></i-datePicker>
          <div v-if="errors.items.length > 0">
            <div v-for="error in errors.items">
              <span
                class="error-text field-validation-error"
                v-if="error.rule == 'required' && error.field == 'Date'"
              >Date is a required field.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 row-spacing">
        <div class="col-md-2 right-align">
          Topic
          <span class="error-text f_req">*</span>
        </div>
        <div class="col-md-10">
          <i-input
            class="full-length no-radius"
            v-model="topicData"
            name="Topic"
            v-validate="'required|max:100|timelineCommentsCheck'"
            placeholder="Enter topic name"
          />
          <div v-if="errors.items.length > 0">
            <div v-for="error in errors.items">
              <span
                class="error-text field-validation-error"
                v-if="error.rule == 'required' && error.field == 'Topic'"
              >Topic is a required field.</span>
              <span
                class="error-text field-validation-error"
                v-if="error.rule == 'max' && error.field == 'Topic'"
              >Topic must not exceed 100 characters.</span>
            </div>
          </div>
          <span
            v-if="isInvalidFormat == true && topicData.trim() != '' "
            class="error-text field-validation-error"
          >Topic should not start with space.</span>
        </div>
      </div>
      <div class="col-md-12 row-spacing">
        <div class="col-md-2 right-align">Label</div>
        <div class="col-md-10">
          <i-select
            class="full-length no-radius"
            v-model="labelsSelected"
            filterable
            placeholder="Select labels"
            multiple
          >
            <i-optionGroup
              v-for="label in listOfLabels"
              :value="label.LabelType"
              :key="label.LabelType"
              :label="label.LabelType"
            >
              <i-option
                class="ivu-list-option"
                v-for="item in label.Data"
                :value="item.value"
                :key="item.value"
              >{{ item.label }}</i-option>
            </i-optionGroup>
          </i-select>
        </div>
      </div>
      <div class="col-md-12 row-spacing">
        <div class="col-md-2 right-align">
          Note
          <span class="error-text f_req">*</span>
        </div>
        <div class="col-md-10">
          <i-input
            name="Note"
            class="full-length textarea-ivu no-radius"
            v-model="comment"
            :autosize="{minRows: 4,maxRows: 4}"
            type="textarea"
            v-validate="'required|max:750'"
            placeholder="Enter note"
          />
          <div v-if="errors.items.length > 0">
            <div v-for="error in errors.items">
              <span
                class="error-text field-validation-error"
                v-if="error.rule == 'required' && error.field == 'Note'"
              >Note is a required field.</span>
              <span
                class="error-text field-validation-error"
                v-if="error.rule == 'max' && error.field == 'Note'"
              >Note must not exceed 750 characters.</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isEdit" class="col-md-12 row-spacing">
        <div class="background-grey">
          <div class="col-md-4 vertical-align-div">
            <a
              href="#"
              class="underline"
              v-bind:class="allComments"
              @click="allComments"
            >View All Notes</a>
          </div>
          <div class="col-md-8 buttons">
            <button class="btn btn-white" @click.stop="cancel">Cancel</button>
            <button v-if="!isEdit" @click.stop="SaveComment" class="btn btn-success">
              Add Note
              <i
                v-show="isAddNoteLoading"
                class="fa fa-spinner fa-spin fa-2x button-spinner centered spinner-icon"
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div v-if="isEdit" class="col-md-12 padding-top">
        <div class="buttons">
          <div>
            <hr class="hr-margins" />
          </div>
          <button class="btn btn-white" @click.stop="cancel">Cancel</button>
          <button @click.stop="SaveComment" class="btn btn-success">
            Update
            <i
              v-show="isUpdateNoteLoading"
              class="fa fa-spinner fa-spin fa-2x centered button-spinner spinner-modal spinner-icon"
            ></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Input, Select, OptionGroup, Option, DatePicker, locale } from "iview";
import lang from "iview/dist/locale/en-US";
import Vue from "vue";
import VeeValidate from "vee-validate";
locale(lang); // iview is default chinese
Vue.use(VeeValidate);
var apiService = require("../TimelineCommentsApiService.js");

export default {
  name: "TimelineComments",
  props: ["commentId"],
  components: {
    "i-optionGroup": OptionGroup,
    "i-input": Input,
    "i-select": Select,
    "i-option": Option,
    "i-datePicker": DatePicker
  },
  created () {
    this.$validator.extend("timelineCommentsCheck", {
      getMessage: () => "Invalid Format",
      validate: value => {
        if (value.charAt(0) == "") {
        }
        this.isInvalidFormat = false;
        var asciivalueIs = value.charAt(0).charCodeAt(0);
        if (value != "") {
          if (value[0] == " ") {
            this.isInvalidFormat = true;
          }
        }
        return "Invalid Format";
      }
    });
  },
  computed: {
    FetchedCommentData () {
      if (this.commentId != null && this.commentId != 0) {
        this.getCommentById();
      }
      return "";
    }
  },
  data () {
    return {
      apiService: new apiService.ApiService(),
      listOfLabels: [],
      labelsSelected: [],
      dateSelected: new Date(),
      topicData: "",
      isAddNoteLoading: false,
      isUpdateNoteLoading: false,
      comment: "",
      isEdit: false,
      oldcommentId: 0,
      bannerAlert: false,
      isPageLoading: true,
      isInvalidFormat: false
    };
  },
  methods: {
    getCommentById () {
      var self = this;
      this.isPageLoading = true;
      var promise = this.apiService.getCommentById(this.commentId);
      promise.then(response => {
        var obj = response.data.comment;
        self.oldcommentId = obj.id;
        self.dateSelected = obj.date;
        self.topicData = obj.topic;
        var labels = obj.labels;
        var labelsValues = [];
        var i = 0;
        if (labels[0].id != 0) {
          for (i = 0; i < labels.length; i++) {
            labelsValues[i] = parseInt(labels[i].id, 10);
          }
        }
        self.labelsSelected = labelsValues;
        self.comment = obj.comment;
        self.isPageLoading = false;
        self.isEdit = true;
      });
    },
    allComments () {
      var promise = this.apiService.allCommentsPage();
      promise.then(response => {
        location.href = response.request.responseURL;
      });
    },
    alertClose () {
      this.bannerAlert = false;
    },
    clearData () {
      this.topicData = "";
      this.labelsSelected = [];
      this.dateSelected = new Date();
      this.comment = "";
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    cancel () {
      this.$emit("childToParent", "CancelClicked");
      this.clearData();
    },
    LoadDropDown () {
      var self = this;
      var labelsPromise = this.apiService.GetLabelsData();
      var labelTypesPromise = this.apiService.GetLabelTypesData();
      var promises = [];
      promises.push(labelsPromise);
      promises.push(labelTypesPromise);
      Promise.all(promises).then(function (values) {
        var labels = values[0].data.result;
        var labelTypes = values[1].data.result;
        if (labels.length != 0) {
          var i = 0;
          var dataTest = [];
          for (i = 0; i <= labelTypes.length; i++) {
            var listOfLabels = [];
            labels.forEach(element => {
              if (i < labelTypes.length) {
                if (element.labelTypeId == labelTypes[i].id) {
                  var temp = {
                    value: element.id,
                    label: element.name
                  };
                  if (temp != undefined) { listOfLabels.push(temp) }
                }
              } else {
                if (element.labelTypeId == null) {
                  temp = {
                    value: element.id,
                    label: element.name
                  };
                  if (temp != undefined) { listOfLabels.push(temp) }
                }
              }
            })
            if (listOfLabels != undefined) {
              if (i == labelTypes.length) {
                var listOfLabelsForType = {};
                listOfLabelsForType.Data = listOfLabels;
                listOfLabelsForType.LabelType = "Others"
                dataTest.push(listOfLabelsForType)
              } else {
                listOfLabelsForType = {};
                listOfLabelsForType.LabelType = labelTypes[i].name
                listOfLabelsForType.Data = listOfLabels;
                dataTest.push(listOfLabelsForType)
              }
            }
          }
          labels.forEach(element => {
            if (element.labelTypeId == null) {
              listOfLabels = [];
              listOfLabels = labels.forEach(element => {
              })
            }
          })
          self.listOfLabels = dataTest;
          self.isPageLoading = false;
        }
      });
    },
    SaveComment (value) {
      var self = this;
      this.$validator.validateAll().then(result => {
        if (result && !self.isInvalidFormat) {
          if (!self.isEdit) {
            self.isAddNoteLoading = true;
            var promise = self.apiService.SaveComment(
              moment(self.dateSelected).format("YYYY-MM-DD"),
              self.topicData,
              self.labelsSelected,
              self.comment
            );
            promise.then(response => {
              self.clearData();
              self.bannerAlert = true;
              self.isAddNoteLoading = false;
              self.$emit("childToParent", "UpdateTable");
            });
          } else {
            self.isUpdateNoteLoading = true;
            promise = self.apiService.UpdateComment(
              moment(self.dateSelected).format("YYYY-MM-DD"),
              self.topicData,
              self.labelsSelected,
              self.comment,
              self.oldcommentId
            );
            promise.then(response => {
              self.clearData();
              self.isUpdateNoteLoading = false;
              self.$emit("childToParent", "UpdateTable");
            });
          }
        }
      });
    }
  },
  mounted () {
    this.LoadDropDown();
  }
};
</script>

<style scoped>
.right-align {
  text-align: right;
  margin-top: 8px;
  padding-right: 0px;
}
.vertical-align-div {
  position: relative;
  top: 50%;
  transform: translateY(50%);
}
.row-spacing {
  margin-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
}
.centered {
  text-align: center;
}
.background-grey {
  background-color: #f5f5f5;
  overflow: hidden;
  padding-top: 10px;
}
::v-deep ul {
  padding-inline-start: 0px !important;
}
.ivu-list-option {
  padding-left: 30px;
}
::v-deep .buttons {
  text-align: right;
}
.full-length {
  width: 100%;
}
.banner {
  margin-bottom: 5px;
  width: 95%;
  margin-left: 2%;
  margin-top: 10px;
  padding-right: 4px;
  padding-left: 0px;
  /* margin-left: 9px; */
}
.padding-sides {
  padding-left: 15px;
  padding-right: 15px;
}
.hr-margins {
  margin-top: 10px;
  margin-bottom: 10px;
}
.error-text {
  font-size: 13px;
}
.ivu-select-group-wrap {
  display: block;
  width: 100%;
}
.no-radius {
  border-radius: 0px;
}
.padding-loader {
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding-top {
  padding-top: 10px;
}
::v-deep .ivu-select-multiple .ivu-select-item-focus,
::v-deep .ivu-select-multiple .ivu-select-item-selected:hover {
  background-color: white;
}
::v-deep .underline {
  text-decoration: underline !important;
}
::v-deep .spinner-modal {
  font-size: 12px !important;
}
::v-deep .button-spinner {
  color: white !important;
  font-size: 12px !important;
}
::v-deep .ivu-select-item-selected:after {
  display: none !important;
}
::v-deep .ivu-select-item-selected,
::v-deep .ivu-select-item-selected:hover {
  font-weight: initial !important;
}
::v-deep .textarea-ivu {
  min-width: 100%;
  font-size: 12px;
}
::v-deep .ivu-input:hover,
::v-deep .ivu-input-number:hover {
  border-color: #ddd;
}
::v-deep .ivu-select-group-title {
  font-weight: bold;
  color: black;
}
.close-icon-padding {
  padding-top: 2px;
}
::v-deep .ivu-input {
  box-shadow: 0 0 0 1px white !important;
  border: 0 0 1 px #ddd;
  border-radius: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
}
::v-deep .ivu-select-item-selected {
  background-color: lightgray !important;
}
::v-deep .ivu-select-dropdown-list {
  max-height: 300px;
  color: #000 !important;
}
::v-deep .ivu-input-number-focused,
::v-deep .ivu-input-focused,
::v-deep .ivu-select-visible,
::v-deep .ivu-select-selection,
::v-deep .ivu-select-selection-focused,
::v-deep .ivu-select-selection:hover {
  border-color: #ddd !important;
  box-shadow: 0 0 0 1px white !important;
}
::v-deep textarea.ivu-input {
  padding: 5px !important;
  font-size: 12px !important;
  min-width: 100%;
}
</style>

<style src="../../../Styles/dx-iview.css">
/*
    For default iview css, use
    iview/dist/styles/iview.css
  */
</style>
