var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-block",staticStyle:{"display":"block"}},[_c('div',_vm._l((_vm.browseData),function(category,index){return _c('div',{key:index},[_c('Collapse',{attrs:{"value":_vm.activeNames[index]}},[_c('Panel',{attrs:{"name":index.toString()}},[_c('p',{staticClass:"content-text"},[_vm._v(_vm._s(category.name))]),_vm._l((category.subcategories),function(subcategory,subIndex){return _c('div',{key:subIndex,attrs:{"slot":"content"},slot:"content"},[_c('Collapse',{attrs:{"value":_vm.activeNames[index + '-' + subIndex]}},[_c('Panel',{attrs:{"name":subIndex.toString()}},[_c('p',{staticClass:"content-text"},[_vm._v(_vm._s(subcategory.name))]),_vm._l((subcategory.items),function(item,itemIndex){return _c('div',{key:itemIndex,staticClass:"content-bg",attrs:{"slot":"content"},slot:"content"},[_c('Checkbox',{attrs:{"label":item.name,"value":_vm.isSelected(item.id)},on:{"on-change":function($event){return _vm.getCheckedTarget(item)}}},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"badge new-badge"},[_vm._v("\n                    "+_vm._s(parseInt(item.audience_size_lower_bound).toLocaleString())+" -\n                    "+_vm._s(parseInt(item.audience_size_upper_bound).toLocaleString())+"\n                  ")])],1)}),_vm._l((subcategory.subcategories),function(subSubcategory,subSubIndex){return _c('div',{key:subSubIndex,attrs:{"slot":"content"},slot:"content"},[_c('Collapse',{attrs:{"value":_vm.activeNames[
                        index + '-' + subIndex + '-' + subSubIndex
                      ]}},[_c('Panel',{attrs:{"name":subSubIndex.toString()}},[_c('p',{staticClass:"content-text"},[_vm._v("\n                        "+_vm._s(subSubcategory.name)+"\n                      ")]),_vm._l((subSubcategory.items),function(subSubItem,subSubItemIndex){return _c('div',{key:subSubItemIndex,staticClass:"content-bg",attrs:{"slot":"content"},slot:"content"},[_c('Checkbox',{attrs:{"label":subSubItem.name,"value":_vm.isSelected(subSubItem.id)},on:{"on-change":function($event){return _vm.getCheckedTarget(subSubItem)}}},[_vm._v(_vm._s(subSubItem.name))]),_c('span',{staticClass:"badge new-badge"},[_vm._v("\n                          "+_vm._s(parseInt(subSubItem.audience_size_lower_bound).toLocaleString())+" -\n                          "+_vm._s(parseInt(subSubItem.audience_size_upper_bound).toLocaleString())+"\n                        ")])],1)}),_vm._l((subSubcategory.subcategories),function(level4Subcategory,level4SubIndex){return _c('div',{key:level4SubIndex,attrs:{"slot":"content"},slot:"content"},[_c('Collapse',{attrs:{"value":_vm.activeNames[
                              index +
                                '-' +
                                subIndex +
                                '-' +
                                subSubIndex +
                                '-' +
                                level4SubIndex
                            ]}},[_c('Panel',{attrs:{"name":level4SubIndex.toString()}},[_c('p',{staticClass:"content-text"},[_vm._v("\n                              "+_vm._s(level4Subcategory.name)+"\n                            ")]),_vm._l((level4Subcategory.items),function(level4Item,level4ItemIndex){return _c('div',{key:level4ItemIndex,staticClass:"content-bg",attrs:{"slot":"content"},slot:"content"},[_c('Checkbox',{attrs:{"label":level4Item.name,"value":_vm.isSelected(level4Item.id)},on:{"on-change":function($event){return _vm.getCheckedTarget(level4Item)}}},[_vm._v(_vm._s(level4Item.name))]),_c('span',{staticClass:"badge new-badge"},[_vm._v("\n                                "+_vm._s(parseInt(level4Item.audience_size_lower_bound).toLocaleString())+"\n                                -\n                                "+_vm._s(parseInt(level4Item.audience_size_upper_bound).toLocaleString())+"\n                              ")])],1)}),_vm._l((level4Subcategory.subcategories),function(level5Subcategory,level5SubIndex){return _c('div',{key:level5SubIndex,attrs:{"slot":"content"},slot:"content"},[_c('Collapse',{attrs:{"value":_vm.activeNames[
                                    index +
                                      '-' +
                                      subIndex +
                                      '-' +
                                      subSubIndex +
                                      '-' +
                                      level4SubIndex +
                                      '-' +
                                      level5SubIndex
                                  ]}},[_c('Panel',{attrs:{"name":level5SubIndex.toString()}},[_c('p',[_vm._v(_vm._s(level5Subcategory.name))]),_vm._l((level5Subcategory.items),function(level5Item,level5ItemIndex){return _c('div',{key:level5ItemIndex,staticClass:"content-bg",attrs:{"slot":"content"},slot:"content"},[_c('Checkbox',{attrs:{"label":level5Item.name,"value":_vm.isSelected(level5Item.id)},on:{"on-change":function($event){return _vm.getCheckedTarget(level5Item)}}},[_vm._v(_vm._s(level5Item.name))]),_c('span',{staticClass:"badge new-badge"},[_vm._v("\n                                      "+_vm._s(parseInt(level5Item.audience_size_lower_bound).toLocaleString())+"\n                                      -\n                                      "+_vm._s(parseInt(level5Item.audience_size_upper_bound).toLocaleString())+"\n                                    ")])],1)})],2)],1)],1)})],2)],1)],1)})],2)],1)],1)})],2)],1)],1)})],2)],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }