<template>
  <div>
    <i-modal
      v-model="visible"
      :mask="true"
      :closable="false"
      class="insights-alert-modal"
      :styles="{ top: '32vh', width: '600px' }"
    >
      <template v-slot:default>
        <p class="insights-alert-text">
          <strong>Downloading this report will incur additional costs</strong> due to the inclusion of
          <strong>AI-generated insights</strong>. To avoid unnecessary charges,
          <strong>AI insights should only be enabled after completing the QC process</strong>.
        </p>
      </template>
      <template v-slot:footer>
        <div class="footer">
          <div>
            <button
              type="button"
              class="btn btn-success"
              style="margin:0px"
              @click="confirm"
            >I understand and want to proceed</button>
            <button
              type="button"
              class="btn btn-white"
              style="margin:0 0 0 10px"
              @click="cancel"
            >Cancel</button>
          </div>
        </div>
      </template>
    </i-modal>
  </div>
</template>

<script>
import { Modal } from "iview"
export default {
  data () {
    return {
      ActionType: {
        "Download": 1,
        "Publish": 2
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  components: {
    "i-modal": Modal
  },
  methods: {
    confirm () {
      this.$emit("confirm");
    },
    cancel () {
      this.$emit("cancel");
    }
  }
}
</script>

<style scoped>
  .insights-alert-text {
      color: #353333;
      font-size: small;
  }
</style>
