<template>
  <div v-show="show">
    <div v-show="mails.length == 0">
      <span class="no-mail-info">There are no emails yet, send an email to {{ inboundEmailId() }}</span>&nbsp;
      <!-- <Poptip transfer placement="left-start" trigger="hover">
        <i class="fas fa-copy action-icon" @click="copyToClipboard(inboundEmailId())"></i>
        <div slot="content">
          Click to copy
        </div>
      </Poptip> -->
    </div>
    <div v-show="mails.length > 0">
      <Input v-model="searchTerm" search placeholder="Search by email subject" class="mail-search-term"/>
      <div class="mail-no-search-results" style="text-align:center" v-show="emails.length == 0">
        No emails found
      </div>
      <div class="mail-info" v-show="emails.length > 0">
        <Row class="mail-header">
          <Row-Col span="24">
            <Row-Col span="1" class="mail-previous" :class="{ 'disable-mail-navigation': (ifFirstMailInView) }" @click.native="showPreviousMail()">
              <Icon type="md-arrow-dropleft" size="20" />
            </Row-Col>
            <Row-Col span="2"  class="mail-next" :class="{ 'disable-mail-navigation': (ifLastMailInView) }"  @click.native="showNextMail()">
              <Icon type="md-arrow-dropright" size="20"/>
            </Row-Col>
            <Row-Col span="21">
              <div class="mail-subject">{{ mailInView.name }}</div>
            </Row-Col>
          </Row-Col>
        </Row>
        <VuePerfectScrollbar class="widget-body">
          <div v-for="image in images" :key="image.ImageId">
            <Row class="mail-image cl-ivu-row">
              <Row-Col span="1">
                <!-- <Poptip transfer placement="left-start" trigger="hover">
                  <i class="fas fa-copy action-icon" @click="copyImageEncodedId(image.ImageId)"></i>
                  <div slot="content">
                    Click to copy
                  </div>
                </Poptip> -->
              </Row-Col>
              <Row-Col span="1" offset="1">
                <!-- <Poptip transfer placement="left-start">
                  <i class="fas fa-eye"></i>
                  <div slot="content">
                    <img v-lazy="image.ImageUrl" class="list-image-poptip">
                  </div>
                </Poptip> -->
              </Row-Col>
              <Row-Col class="cl-widget-text" span="16" offset="1">
                <span> {{ image.ImageName }} </span>
                <i class="fas fa-exclamation-triangle list-icon alert-icon display-none"></i>
              </Row-Col>
              <Row-Col span="3" offset="1" class="list-image-wrapper">
                <img v-lazy="image.ImageUrl" class="list-image">
              </Row-Col>
            </Row>
          </div>
        </VuePerfectScrollbar>
        <Row class="mail-footer">
          <Row-Col span="24">
            <Row-Col span="3">
              From
            </Row-Col>
            <Row-Col span="21">
              <div class="mail-sender">{{ mailInView.sender }}</div>
            </Row-Col>
          </Row-Col>
          <Row-Col span="24">
            <Row-Col span="3">
              Time
            </Row-Col>
            <Row-Col span="21">
              <div class="mail-time">{{ mailInView.time }}</div>
            </Row-Col>
          </Row-Col>
        </Row>
      </div>
      <Row class="mail-recipient-info">
        <Row-Col span="24" style="text-align:center">
          <div>
            <span class="mail-bp-recipient">{{ inboundEmailId() }}</span>&nbsp;
            <!-- <Poptip transfer placement="left-start" trigger="hover">
              <i class="fas fa-copy action-icon" @click="copyToClipboard(inboundEmailId())"></i>
              <div slot="content">
                Click to copy
              </div>
            </Poptip> -->
          </div>
        </Row-Col>
      </Row>
    </div>
  </div>
</template>
<script>
import { Row, Col, Icon, Input } from "iview"
import { mapState } from "vuex"
import VueLazyload from 'vue-lazyload'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Vue from 'vue'
Vue.use(VueLazyload, {
  attempt: 1
})
export default {
  components: {
    Row,
    "Row-Col": Col,
    Icon,
    VuePerfectScrollbar,
    Input
  },
  props: {
    show: false,
    mails: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      mailInViewIndex: 0,
      emails: [],
      searchTerm: ""
    }
  },
  mounted: function () {
    this.renderEmails();
  },
  watch: {
    searchTerm: function () {
      this.renderEmails();
    },
    mails: function () {
      this.renderEmails();
    }
  },
  computed: {
    ...mapState(["businessProfileId", "s3InboundMailRecipientDomain"]),
    ifMoreThanOneMailPresent: function () {
      return this.emails.length > 1
    },
    images: function () {
      return this.emails.length > 0 ? this.emails[this.mailInViewIndex].Images : []
    },
    mailInView: function () {
      let mailInView = {
        name: "",
        sender: "",
        time: ""
      }
      if (this.emails.length > 0) {
        var name = this.emails[this.mailInViewIndex].Name;
        mailInView.name = name == "" ? "(No Subject)" : name;
        mailInView.sender = this.emails[this.mailInViewIndex].Sender;
        var date = new Date(this.emails[this.mailInViewIndex].CreatedAt);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var hour = date.getHours() % 12;
        var hours = hour == 0 ? 12 : hour;
        var minutes = date.getMinutes();
        minutes = minutes > 10 ? minutes : `0${minutes}`;
        var ampm = date.getHours() >= 12 ? "PM" : "AM";
        var formattedTime = `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`
        mailInView.time = formattedTime;
      }
      return mailInView;
    },
    ifFirstMailInView: function () {
      return this.mailInViewIndex == 0
    },
    ifLastMailInView: function () {
      return this.mailInViewIndex == this.emails.length - 1;
    }
  },
  methods: {
    showNextMail: function () {
      if (this.mailInViewIndex < this.emails.length - 1) {
        this.mailInViewIndex++;
      }
    },
    showPreviousMail: function () {
      if (this.mailInViewIndex > 0) {
        this.mailInViewIndex--;
      }
    },
    inboundEmailId: function () {
      return `dxcreatives_${this.businessProfileId}@${this.s3InboundMailRecipientDomain}`;
    },
    copyImageEncodedId (imageId) {
      var encodedId = `DXC-${Number.parseInt(this.businessProfileId).toString(36)}-${imageId}`;
      this.copyToClipboard(encodedId)
    },
    // todo : move this method to a mixin or plugin
    copyToClipboard (id) {
      let textarea = document.createElement("textarea")
      textarea.textContent = id
      textarea.style.position = "fixed"
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand("copy")
      } catch (e) {
        alert('Unable to copy to clipboard. Please copy manually.')
      }
      document.body.removeChild(textarea)
    },
    renderEmails () {
      var searchTerm = this.searchTerm;
      if (this.searchTerm != "") {
        this.emails = this.mails.filter(e => {
          return e.Name.toLowerCase().includes(searchTerm.toLowerCase());
        })
      } else {
        this.emails = [...this.mails];
      }
      this.mailInViewIndex = 0;
    }
  }
}
</script>
<style>
.mail-next, .mail-previous {
  cursor: pointer;
}
.cl-hide {
  visibility: hidden;
}
.mail-footer {
  font-size: 11px;
  padding: 5px;
  background: #efefef;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mail-header {
  padding: 5px;
  background: #efefef;
  margin-bottom: 10px;
}
.mail-search-term {
  margin-bottom: 10px;
}
.mail-recipient-info {
  margin-top: 10px;
}
.disable-mail-navigation {
  color: darkgray;
}
</style>
