<template>
  <div>
    <Modal
      v-model="isModalOpen"
      width="85%"
      :scrollable="true"
      :styles="{ top: '20px' }"
      :mask-closable="false"
    >
      <div slot="header">
        <h3 class="ccHeader">
          Creative Customization Setup
        </h3>
        <i-switch v-model="creativeSetupEnabled" />
      </div>

      <Row v-show="!creativeSetupEnabled">
        Enable Creative Customization to customize your ad placements or provide
        dynamic creative
      </Row>
      <Row v-show="creativeSetupEnabled">
        <Row-Col span="24">
          <Row class="creative-customization-setup">
            <Form>
              <Form-Item label="Type">
                <RadioGroup v-model="setupType">
                  <Radio
                    :disabled="setupType != 'Placement Customization'"
                    label="Placement Customization"
                  ></Radio>
                  &nbsp;
                  <Radio
                    :disabled="setupType != 'Dynamic Creative'"
                    label="Dynamic Creative"
                  ></Radio>
                </RadioGroup>
              </Form-Item>
            </Form>
          </Row>

          <Row>
            <transition name="cs-alert">
              <div
                v-show="showAlert"
                class="alert alert-warning alert-container"
                role="alert"
              >
                <button type="button" class="close" @click="showAlert = false">
                  ×
                </button>
                <div v-html="alertMessage"></div>
              </div>
            </transition>
            <div v-if="isPlacementCustomizationSelected" class="alert alert-info">
                Customization here is no longer supported, please use the ad sheet settings to configure the placements.
            </div>
            <dynamic-creative
              ref="dynamicCreative"
              v-if="setupType == 'Dynamic Creative'"
              :dynamicCreativeData="dynamicCreativeData"
              :isPublished="isPublished"
              :mainTableData="mainTableData"
            >
            </dynamic-creative>

            <placement-customization
              ref="placementCustomization"
              :placementCustomizationData="placementCustomizationData"
              :placementOptions="placementOptions"
              :placementCustomizationHeaders="placementCustomizationHeaders"
              :isPublished="isPublished"
            >
            </placement-customization>

            <language-customization ref="languageCustomization"></language-customization>
          </Row>
        </Row-Col>
        <!-- <Row-Col span="8" class="images">
          <creative-library-widget
            :fetchMediaUrl="deltax.fetchMediaUrl"
            :fetchVideosUrl="deltax.fetchVideosUrl"
            :saveImageURL="deltax.saveImageURL"
            :inCreativeSetup="true"
          >
          </creative-library-widget>
        </Row-Col> -->
      </Row>

      <div slot="footer">
        <input
          type="button"
          class="btn btn-white"
          @click="closeModal()"
          :value="isPublished ? 'Close' : 'Cancel'"
        />
        <input
          v-if="!isPublished"
          :disabled="isPlacementCustomizationSelected"
          type="button"
          class="btn btn-success"
          @click="saveSetup()"
          value="Save"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  RadioGroup,
  Radio,
  Switch
} from "iview";
import CreativeLibraryWidget from "./CreativeLibraryWidget.vue";
import DynamicCreative from "./DynamicCreative.vue";
import PlacementCustomization from "./PlacementCustomization.vue";
import LanguageCustomization from "./LanguageCustomization.vue"

export default {
  components: {
    Modal,
    Row,
    "Row-Col": Col,
    Form,
    FormItem,
    RadioGroup,
    Radio,
    "i-switch": Switch,
    // "creative-library-widget": CreativeLibraryWidget,
    "dynamic-creative": DynamicCreative,
    "placement-customization": PlacementCustomization,
    "language-customization": LanguageCustomization
  },
  props: {
    creativeSetupData: {
      default: function () {
        return {};
      }
    },
    placementOptions: {
      default: function () {
        return {};
      }
    },
    mainTableData: {
      default: function () {
        return {};
      }
    },
    placementCustomizationHeaders: {
      default: function () {
        return [];
      }
    },
    mainSheetHotData: {
      default: function () {
        return [];
      }
    },
    mainSheetHeaderName: {
      default: function () {
        return [];
      }
    },
    rowNum: { default: 0 },
    associatedMainTableRowNum: { default: 0 },
    isPublished: { default: false }
  },
  data: function () {
    return {
      isModalOpen: false,
      setupType: "",
      customizationTypeRef: "placementCustomization",
      dynamicCreativeData: {},
      placementCustomizationData: {},
      creativeSetupEnabled: true,
      showAlert: false,
      alertMessage: ""
    };
  },
  computed: {
    ...mapState(["deltax"]),
    isPlacementCustomizationSelected () {
      return (this.customizationTypeRef == "placementCustomization")
    }
  },
  watch: {
    isModalOpen: function () {
      if (this.isModalOpen === true) {
        this.setupType = this.creativeSetupData[this.associatedMainTableRowNum].type;
        this.dynamicCreativeData = this.placementCustomizationData = null
        this.creativeSetupEnabled = this.creativeSetupData[this.associatedMainTableRowNum].enabled
        this.$nextTick(() => {
          if (this.setupType === "Dynamic Creative") {
            this.dynamicCreativeData = this.creativeSetupData[this.associatedMainTableRowNum];
            this.customizationTypeRef = "dynamicCreative"
          } else {
            this.placementCustomizationData = this.creativeSetupData[this.associatedMainTableRowNum];
            this.customizationTypeRef = "placementCustomization"
          }
        })
      } else {
        this.showAlert = false;
      }
    }
  },
  methods: {
    openModal () {
      this.isModalOpen = true;
    },
    closeModal () {
      this.isModalOpen = false;
    },
    validateSetup () {
      let validationResult = this.$refs[this.customizationTypeRef].validateData();
      this.alertMessage = validationResult.alertMessage;
      this.showAlert = validationResult.showAlert;
      return !this.showAlert;
    },
    saveSetup () {
      if (this.creativeSetupEnabled === false) {
        this.creativeSetupData[this.associatedMainTableRowNum].enabled = false;
        this.$emit("saveSetupData", this.creativeSetupData[this.associatedMainTableRowNum]);
        this.isModalOpen = false;
      } else {
        // newSetupData.data = this.$refs.placementCustomization.getFormattedData()
        // this.handontableData(newSetupData.data)
        // This should send data in hotTableData.
        if (this.validateSetup()) {
          this.$emit("saveSetupData", {
            type: this.setupType,
            enabled: true,
            hotTableData: this.$refs[this.customizationTypeRef].getFormattedData()
          });
          this.isModalOpen = false;
        }
      }
    },
    getAssetFeedSpecJSON (filteredData, mainSheetHotData, mainSheetHeaderName, isLanguageCustomization) {
      isLanguageCustomization = isLanguageCustomization || false;
      if (isLanguageCustomization) {
        return this.$refs.languageCustomization.getAssetFeedSpecJSON(filteredData, mainSheetHotData, mainSheetHeaderName)
      }
      return this.$refs.placementCustomization.getAssetFeedSpecJSON(filteredData, mainSheetHotData, mainSheetHeaderName)
    }
  }
};
</script>

<style scoped>
.ccHeader {
  margin: 0px 30px 0px 0px;
  float: left;
  font-size: 20px !important;
}

.alert-container {
  margin-right: 15px;
}
.cs-alert-enter-active {
  transition: all 0.25s ease-out;
}
.cs-alert-leave-active {
  transition: all 0.25s ease-in;
}
.cs-alert-enter,
.cs-alert-leave-to {
  transform: translate(0px, -10px);
  opacity: 0;
}
.images {
  padding-left: 7px;
  padding-top: 43px;
}
.creative-customization-setup {
  height: 43px
}
</style>

<style
  src="../../../../../../node_modules/handsontable/dist/handsontable.full.css"
></style>
