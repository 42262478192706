<template>
  <v-highchart :options="chartOptions"></v-highchart>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import { kpiBar } from "../../../Directives/dxHighchartsModule.js"

export default {
  props: {
    ActualKPI: { default: 0 },
    PlannedKPI: { default: 0 },
    KPIPace: { default: 0 }
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          height: '60px',
          width: 100
        },
        title: null,
        tooltip: {
          enabled: true
        },
        yAxis: {
          title: null,
          min: 0,
          max: this.PlannedKPI > this.ActualKPI ? this.PlannedKPI : this.ActualKPI,
          labels: {
            enabled: false
          },
          gridLineWidth: 0,
          plotLines: [{
            color: kpiBar.orange,
            value: this.KPIPace,
            width: '2',
            zIndex: 5
          }]
        },
        xAxis: {
          title: null,
          minorTickLength: 0,
          tickLength: 0,
          labels: {
            enabled: false
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          scatter: {
            // stickyTracking: false,
            tooltip: {
              headerFormat: '',
              valueSuffix: ' %',
              valueDecimals: 1,
              outside: true,
              useHTML: true,
              pointFormat: '<span style="color:{point.color}"></span> {series.name}<br /><b>{point.y}</b>'
            }
          },
          column: {
            stickyTracking: false,
            tooltip: {
              headerFormat: '',
              valueSuffix: ' %',
              valueDecimals: 1,
              outside: true,
              useHTML: true,
              pointFormat: '<span style="color:{point.color}"></span> {series.name}<br /><b>{point.y}</b>'
            },
            dataLabels: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: "KPI",
            type: "column",
            data: [{ y: this.PlannedKPI, color: kpiBar.gray }, { y: this.ActualKPI, color: kpiBar.blue }],
            pointPadding: 0,
            groupPadding: 0.15
          },
          {
            name: "Pace",
            type: "scatter",
            marker: {
              // symbol: 'pacemark',
              radius: 2
            },
            data: [null, { y: this.KPIPace, color: kpiBar.orange }]
          }
        ]
      }
    };
  },
  components: {
    "v-highchart": Chart
  },
  watch: {
    ActualKPI: function (nv, ov) {
      this.chartOptions.yAxis.max = this.PlannedKPI > nv ? this.PlannedKPI : nv;
      this.chartOptions.series[0].data = [{ y: this.PlannedKPI, color: kpiBar.gray }, { y: nv, color: kpiBar.blue }];
    },
    PlannedKPI: function (nv, ov) {
      this.chartOptions.yAxis.max = nv > this.ActualKPI ? nv : this.ActualKPI;
      this.chartOptions.series[0].data = [{ y: nv, color: kpiBar.gray }, { y: this.ActualKPI, color: kpiBar.blue }];
    },
    KPIPace: function (nv, ov) {
      this.chartOptions.series[1].data = [null, { y: nv, color: kpiBar.orange }];
      this.chartOptions.yAxis.plotLines[0].value = nv;
    }
  }
};

Highcharts.SVGRenderer.prototype.symbols.pacemark = function (x, y, w, h) {
  return ['M', x - w, y, 'L', x + 2 * w, y, 'L', x + 2 * w, y + h / 4, 'L', x - w, y + h / 4, 'z'];
}
if (Highcharts.VMLRenderer) {
  Highcharts.VMLRenderer.prototype.symbols.pacemark = Highcharts.SVGRenderer.prototype.symbols.pacemark;
}
</script>
