import { Objective } from "./Objective.js"
let ColumnConfig = function () {
  this.inStream = new function () {
    this.base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Asset", "HOT_App Icon", "HOT_Business Title", "HOT_Description", "HOT_Call To Action", "HOT_Image Banner", "HOT_Landing Page URL", "HOT_Jio Creative ID", "HOT_Ad Target"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Asset"];
    this.creativeHeader = ["HOT_Asset", "HOT_App Icon", "HOT_Business Title", "HOT_Description", "HOT_Call To Action", "HOT_Image Banner"];
    this.card = []
    this.requiredCard = []
  }();
  this.imageBanner = new function () {
    this.base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Asset", "HOT_Landing Page URL", "HOT_Jio Creative ID", "HOT_Ad Target"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Asset"];
    this.creativeHeader = ["HOT_Asset"];
    this.card = []
    this.requiredCard = []
  }();
  this.nativeImage = new function () {
    this.base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Asset", "HOT_App Icon", "HOT_Business Title", "HOT_Description", "HOT_Call To Action", "HOT_Landing Page URL", "HOT_Jio Creative ID", "HOT_Ad Target"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Asset", "HOT_App Icon", "HOT_Business Title", "HOT_Description"];
    this.creativeHeader = ["HOT_Asset", "HOT_App Icon", "HOT_Business Title", "HOT_Description", "HOT_Call To Action"];
    this.card = []
    this.requiredCard = []
  }();
  this.nativeVideo = new function () {
    this.base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Asset", "HOT_App Icon", "HOT_Business Title", "HOT_Description", "HOT_Call To Action", "HOT_Landing Page URL", "HOT_Jio Creative ID", "HOT_Ad Target"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Asset", "HOT_App Icon", "HOT_Business Title", "HOT_Description"];
    this.creativeHeader = ["HOT_Asset", "HOT_App Icon", "HOT_Business Title", "HOT_Description", "HOT_Call To Action"];
    this.card = []
    this.requiredCard = []
  }();
  this.nativeLogo = new function () {
    this.base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_App Icon", "HOT_Business Title", "HOT_Description", "HOT_Call To Action", "HOT_Landing Page URL", "HOT_Jio Creative ID", "HOT_Ad Target"];
    this.requiredBase = ["HOT_Ad Name", "HOT_App Icon", "HOT_Business Title", "HOT_Description"];
    this.creativeHeader = ["HOT_App Icon", "HOT_Business Title", "HOT_Description", "HOT_Call To Action"];
    this.card = []
    this.requiredCard = []
  }();
  this.customJson = new function () {
    this.base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_JSON", "HOT_Landing Page URL", "HOT_Jio Creative ID", "HOT_Ad Target"];
    this.requiredBase = ["HOT_Ad Name", "HOT_JSON"];
    this.creativeHeader = ["HOT_JSON"];
    this.card = []
    this.requiredCard = []
  }();
}
let brandAwareness = new Objective(new ColumnConfig())
export { brandAwareness }
