<template>
  <row class="product-set-filter">
    <i-col span="5">
      <i-select
        v-model="productSetFilter.type"
        placeholder="Select a filter type"
      >
        <i-option
          v-for="(type, index) in types"
          :key="index"
          :value="type.value"
        >
          {{ type.name }}
        </i-option>
      </i-select>
    </i-col>
    <i-col span="5" offset="1">
      <i-select
        v-model="productSetFilter.operator"
        placeholder="Select a filter Operator"
      >
        <i-option
          v-for="(operator, index) in operators"
          :key="index"
          :value="operator.value"
        >
          {{ operator.name }}
        </i-option>
      </i-select>
    </i-col>
    <i-col span="9" offset="1">
      <template v-if="['eq', 'neq'].includes(productSetFilter.operator)">
        <i-input
          v-model="productSetFilter.value"
        ></i-input>
      </template>
      <template v-else>
        <multiselect
          v-model="productSetFilter.tags"
          :options="productSetFilter.options"
          track-by="code"
          placeholder="Enter tags"
          tag-placeholder="New tag"
          label="name"
          :multiple="true"
          :taggable="true"
          @tag="addTag"
        ></multiselect>
      </template>
    </i-col>
    <i-col span="1" offset="1">
      <Icon
        type="md-close"
        size="24"
        class="iview-icons close-btn"
        @click="removeFilter()"
      />
    </i-col>
  </row>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Select,
  Option,
  Input
} from "iview";
import Multiselect from "vue-multiselect";

export default {
  name: "ProductSetFilter",
  props: {
    filter: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    productSetType: {
      type: Number,
      default: 1
    }
  },
  components: {
    Row,
    "i-col": Col,
    Icon,
    "i-select": Select,
    "i-option": Option,
    Multiselect,
    "i-input": Input
  },
  data () {
    return {
      productSetFilter: {},
      types: [
        {
          name: "availability",
          value: "availability"
        },
        {
          name: "brand",
          value: "brand"
        },
        {
          name: "category",
          value: "category"
        },
        {
          name: "condition",
          value: "condition"
        },
        {
          name: "gender",
          value: "gender"
        },
        {
          name: "product type",
          value: "product_type"
        },
        {
          name: "price amount",
          value: "price_amount"
        },
        {
          name: "sale price amount",
          value: "sale_price_amount"
        }
      ],
      operators: [
        {
          name: "contains",
          value: "i_contains"
        },
        {
          name: "not contains",
          value: "i_not_contains"
        },
        {
          name: "equals",
          value: "eq"
        },
        {
          name: "not equals",
          value: "neq"
        }
      ]
    }
  },
  created () {
    this.productSetFilter = this.filter;
  },
  computed: {

  },
  methods: {
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      };

      this.productSetFilter.tags.push(tag);
      this.productSetFilter.options.push(tag);
    },
    removeFilter () {
      this.$emit("filterRemoval", { index: this.index, type: this.productSetType });
    }
  },
  watch: {
    productSetFilter: {
      handler (value) {
        this.$emit("filterUpdate", { value: value, index: this.index, type: this.productSetType });
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.product-set-filter {
  padding: 0.25rem 0rem;
}
.iview-icons {
  cursor: pointer;
}
.close-btn {
  color: maroon;
  margin-top: 0.25rem;
}
</style>

<style lang="scss">
.product-set-filter{
  .multiselect__tag {
    background: #f7f7f7 !important;
    border: 1px solid #cccccc !important;
    border-radius: 0.3rem !important;
    margin: auto !important;
    color: #515A5E;
  }
  .multiselect__tag-icon:focus:after, .multiselect__tag-icon:hover:after {
    color: #8e8e8e !important;
  }
  .multiselect__tag-icon:hover, .multiselect__tag-icon:focus {
    background-color: transparent !important;
  }
  .multiselect__tags {
      min-height: 32px  !important;
      padding: 0px 40px 0 5px !important;
      border-radius: 1px !important;
      font-size: 12px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .multiselect__placeholder {
    margin-bottom: 0px;
  }
  .multiselect__single {
      padding-left: 5px !important;
      margin-bottom: 6px !important;
  }
  .multiselect__select {
      right: 1px !important;
      top: -2px !important;
  }
  .multiselect__element .multiselect__option--highlight span {
      color: #fff !important;
  }
}
</style>
