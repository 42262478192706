<template>
<div>
  <div>
    <div v-for="(input,i) in inputs" :key="i">
      <hr>
       <div class="row margin-bottom">
        <div :class="phaseNameClass">
           <div :class="!verticalIntegration ? `row margin-bottom` : `row margin-bottom top-margin`">
           <div :class="verticalIntegration ? 'col-md-10' : 'col-md-12'">
          <i-input
                     :class="isNameValid[i] || !isNameEdited[i] ? `modal-label` : `red-label modal-label`"
                     :placeholder="`Phase ${i+1}`"
                     prefix="ios-flag-outline"
                     v-model="input.name"
                     @on-focus="setEditedName(i)"
                     />
           </div>
           <div v-if="verticalIntegration" class="col-md-1">
               <i class="fas fa-times"  v-if="inputs.length != 1" @click="removePhase(i)"></i>
            </div>
        </div>
         </div>
        <div :class="scheduleBudgetClassName">
           <div :class="!verticalIntegration ? `row margin-bottom` : `row margin-bottom top-margin`">
            <div class="col-md-12">
               <DatePicker
                        type="daterange"
                        placement="bottom-end"
                        :class="isScheduleValid[i] || !isScheduleEdited[i] ? 'schedulePicker' : 'schedulePicker red-label'"
                        format="yyyy/MM/dd"
                        placeholder="Schedule"
                        :transfer="true"
                        :clearable="false"
                        :split-panels="true"
                        @on-open-change="setEditedSchedule(i)"
                        :editable="false"
                        v-model="input.schedule"
                        ></DatePicker>
            </div>
          </div>
        </div>
          <div :class="scheduleBudgetClassName">
          <div :class="!verticalIntegration ? `row margin-bottom` : `row margin-bottom top-margin`">
            <div class="col-md-12">
                <i-input
                     :class="isBudgetValid[i] || !isBudgetEdited[i]? 'modal-label':'red-label modal-label'"
                     placeholder="Budget"
                     type="number"
                     prefix="ios-cash-outline"
                     v-model="input.budget"
                     @on-focus="setEditedBudget(i)"
                     />
            </div>
          </div>
        </div>
        <div v-if="!verticalIntegration" class="col-md-1" >
          <i class="fas fa-times action-button add-phase" title="Remove Phase" v-if="inputs.length != 1" @click="removePhase(i)"></i>
          <i :class=" isValidPhaseInput ? 'fas fa-plus-circle  action-button' : 'fas fa-plus-circle action-button disabled'"  title="Add Phase" v-if="(!verticalIntegration) && (i == inputs.length - 1)" @click="addPhase"></i>
        </div>
       </div>
      </div>
      <hr>
        <div class="row margin-bottom" v-if="verticalIntegration">
              <div class="col-md-6">
            <Button class='btn btn-success' @click="addPhase">Add Phase </Button>
              </div>
        </div>
  </div>
</div>
</template>
<script>
import {
  Input,
  DatePicker
} from "iview";
export default {
  props: ["verticalIntegration", "phaseInput", "briefInputPhases"],
  components: {
    "i-input": Input,
    DatePicker
  },
  data () {
    return {
      inputs: [
        {
          name: "",
          budget: null,
          schedule: null
        }
      ],
      fullwidth: "col-md-12",
      halfwidth: "col-md-6",
      col5width: "col-md-5",
      quaterwidth: "col-md-2",
      isBudgetValid: [true],
      isNameValid: [true],
      isScheduleValid: [true],
      isNameEdited: [false],
      isBudgetEdited: [false],
      isScheduleEdited: [false]
    }
  },
  methods: {
    addPhase () {
      if (!this.isValidPhaseInput) {
        return;
      }
      let input = {
        name: "",
        budget: null,
        schedule: null
      }
      this.inputs.push(input);
      this.isNameEdited.push(false);
      this.isBudgetEdited.push(false);
      this.isScheduleEdited.push(false);
    },
    removePhase (i) {
      this.inputs.splice(i, 1);
    },
    setEditedName (i) {
      this.isNameEdited[i] = true;
    },
    setEditedSchedule (i) {
      this.isScheduleEdited[i] = true;
    },
    setEditedBudget (i) {
      this.isBudgetEdited[i] = true;
    }
  },
  computed: {
    isValidPhaseInput: function () {
      let valid = true;
      for (let i = 0; i < this.inputs.length; i++) {
        for (let j = 0; j < this.inputs.length; j++) {
          if (i != j) {
            if (((this.inputs[i].budget == this.inputs[j].budget) && (this.inputs[i].startDate == this.inputs[j].startDate) && (this.inputs[i].endDate == this.inputs[j].endDate)) || (this.inputs[i].name == this.inputs[j].name)) {
              valid = false;
            }
          }
        }
      }
      this.inputs.forEach(input => {
        if (input.name == "" || (input.budget == 0 || isNaN(input.budget)) || !input.schedule[0] || !input.schedule[1] || !input.budget) {
          valid = false;
        }
      });
      return valid;
    },
    phaseNameClass: function () {
      return this.verticalIntegration ? this.fullwidth : this.col5width;
    },
    scheduleBudgetClassName: function () {
      return this.verticalIntegration ? this.col5width : this.quaterwidth;
    }
  },
  watch: {
    phaseInput: function (newValue, oldValue) {
      if (this.phaseInput != null) {
        this.inputs = this.phaseInput;
        this.inputs.forEach(input => {
          input.schedule = [];
          input.schedule.push(moment(input.startDate).format("YYYY-MM-DD"));
          input.schedule.push(moment(input.endDate).format("YYYY-MM-DD"));
        })
      }
    },
    inputs: {
      handler: function (newValue, oldValue) {
        this.isBudgetValid = [];
        this.isScheduleValid = [];
        this.isNameValid = [];
        this.inputs.forEach(e => {
          if (e.schedule) {
            e.startDate = moment(e.schedule[0]).format("YYYY-MM-DD");
            e.endDate = moment(e.schedule[1]).format("YYYY-MM-DD");
          }
          if (e.budget) {
            this.isBudgetValid.push(true);
          } else {
            this.isBudgetValid.push(false);
          }
          if (e.schedule && e.schedule[0] && e.schedule[1]) {
            this.isScheduleValid.push(true);
          } else {
            this.isScheduleValid.push(false);
          }
          if (e.name) {
            this.isNameValid.push(true);
          } else {
            this.isNameValid.push(false);
          }
        })
        this.$emit('update:briefInputPhases', this.inputs);
      },
      deep: true
    }
  }
}
</script>
<style scoped>
.action-button{
  font-size: 16px;
  padding-top: 15%;
  cursor: pointer;
}
.add-phase{
  padding-right: 15%;
}
.row{
  margin-left: -15px !important;
  margin-right: -15px !important;
  }
.top-margin{
    margin-top: 10px !important;
}
.disabled{
  color: rgb(199, 199, 199);
}
::v-deep .schedulePicker .ivu-icon-ios-calendar-outline{
  display:block !important;
  height: 32px !important;
  width: 28px !important;
}
::v-deep .schedulePicker .ivu-input.ivu-input-default{
  padding-right: 30px !important;
}
</style>
<style>
.red-label .ivu-icon {
  color: red !important;
}
.red-label {
  color: red !important;
  border: red solid 1px !important;
}
</style>
