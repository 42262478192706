<template>
  <table class="overwrite-table">
    <tr class="overwrite-table-row">
      <td class="overwrite-platform">
        <u>Moloco:</u>
      </td>
      <td class="overwrite-value">
        {{ molocoValue }}
      </td>
      <td class="overwrite-action">
        <Button type="success" size="small" @click="useLive">
          Use Live
        </Button>
      </td>
    </tr>
    <tr class="overwrite-table-row">
      <td class="overwrite-platform">
        <u>DeltaX:</u>
      </td>
      <td class="overwrite-value">
        {{ dxValue }}
      </td>
      <td class="overwrite-action">
        <Button type="warning" size="small" @click="overwrite">
          Overwrite
        </Button>
      </td>
    </tr>
  </table>
</template>

<script>
import { Button } from 'iview';

export default {
  components: { Button },
  props: {
    molocoValue: {
      type: String | Number,
      required: true
    },
    dxValue: {
      type: String | Number,
      required: true
    }
  },
  emits: ["useLive", "overwrite"],
  methods: {
    useLive () {
      this.$emit("useLive");
    },
    overwrite () {
      this.$emit("overwrite");
    }
  }
};
</script>

<style scoped>
.overwrite-table {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem
}

.overwrite-table-row {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.overwrite-value {
  flex-grow: 1;
}
</style>
