<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <div class="row">
          <div class="col-md-5">
            <h4>Target Clusters</h4>
          </div>
          <div class="col-md-7 no-padding">
            <Input v-model="search" search placeholder="Search" class="search-bar input-search-bar" />
          </div>
        </div>
      </div>
      <VuePerfectScrollbar class="widget-body">
          <template>
          <div
              style="margin-top:10px"
              v-if="!promoTargetClusters || promoTargetClusters.length<=0"
              class="alert alert-info"
            >There are no target clusters choosen for a promotion.</div>
          </template>
            <Row v-for="targetCluster in filteredtargetClusters" v-bind:key="targetCluster.Id" class="cl-ivu-row">
              <Row-Col span="1">
                <i class="fas fa-copy action-icon"
                  @click="copyToClipboard('DXTC-' + Number.parseInt(businessProfileId).toString(36) + '-' + targetCluster.id)">
                </i>
              </Row-Col>
              <Row-Col span="1" offset="1">
              </Row-Col>
              <Row-Col span="8" offset="1" class="cl-widget-text">
                <span class="target-cluster-identifier">{{targetCluster.name}}</span>
              </Row-Col>
            </Row>
      </VuePerfectScrollbar>
    </Card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Input, Card, Row, Col } from "iview";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default {
  components: {
    Input,
    VuePerfectScrollbar,
    Card,
    "Row-Col": Col,
    Row
  },
  data: function () {
    return {
      search: ""
    };
  },
  methods: {
    copyToClipboard (value) {
      let textarea = document.createElement("textarea")
      textarea.textContent = value
      textarea.style.position = "fixed"
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand("copy")
      } catch (e) {
        alert('Unable to copy to clipboard. Please copy manually.')
      }
      document.body.removeChild(textarea)
    }
  },
  computed: {
    ...mapState(["promoTargetClusters", "businessProfileId"]),
    filteredtargetClusters () {
      let searchTerm = this.search;
      if (searchTerm != "") {
        return this.promoTargetClusters.filter(e => {
          return e.name.toLowerCase().includes(searchTerm.toLowerCase())
        })
      } else {
        return this.promoTargetClusters;
      }
    }
  }
}
</script>
<style scoped>
.widget-body > table {
  width: 100%;
}
.widget-body > table > tr > td {
  padding-top: 10px;
}
</style>
