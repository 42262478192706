<template>
  <vue-tel-input
    :disabled="disabled"
    class="dx-tel-input"
    v-model="value"
    :defaultCountry="countryCode"
    @input="onInput"
    mode="international"
  >
    <template v-slot:arrow-icon>
      <span :class="{ disabled: disabled }"> ▼ </span>
    </template>
  </vue-tel-input>
</template>
<script>
import { VueTelInput } from "vue-tel-input";
import debounce from "lodash/debounce";
export default {
  props: {
    debounceTime: {
      type: Number,
      default: 3000
    },
    phoneNumber: {
      type: String
    },
    countryCode: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: this.phoneNumber
    };
  },
  watch: {
    phoneNumber (value) {
      this.value = value;
    }
  },
  components: {
    VueTelInput
  },
  methods: {
    onInput (value, phoneInputObject) {
      let self = this
      let phoneObject = phoneInputObject;
      function emitPhoneNumber () {
        self.emitInput(phoneObject);
      }
      const deb = debounce(emitPhoneNumber, this.debounceTime); // I want to reuse it
      return deb();
    },
    emitInput (phoneInputObject) {
      this.$emit('input', phoneInputObject)
    }
  }
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style scoped>
.dx-tel-input {
  font-size: 12px;
  border: 1px solid #dcdee2;
  border-radius: 0px !important;
  color: #515a6e;
  background-color: #fff;
}
.dx-tel-input:focus {
  border-color: #57a3f3;
  outline: 0;
}
.dx-tel-input .disabled {
  pointer-events: none;
  cursor: no-drop;
  color: var(--dx-gray) !important;
}
</style>
