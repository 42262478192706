<template>
  <div>
    <Modal :value="showModal" width="65%" :title="modalTitle" :mask-closable="false" @on-cancel="closeModal()"
      @on-visible-change="ToggleModal">
      <div class="header">
        <i-row class="vertical-center" :gutter="10" style="align-items: baseline;">
          <i-col class="text-align" span="3">
            <span>Vendor Invoice</span>
          </i-col>
          <i-col class="i-input-field" span="5">
            <i-select v-model="selectedInvoiceId" @on-change="setInvoicedata" :disabled="isEdit">
              <i-option v-for="incomingBillLog in incomingBillLogs" :key="incomingBillLog.id"
                :value="incomingBillLog.id">
                {{ incomingBillLog.invoiceNumber }}
              </i-option>
            </i-select>
          </i-col>
        </i-row>
        <i-row type="flex" align="middle" v-if="invoicedText != ''" class="spaced-row">
          <i-col span="3"></i-col>
          <i-col><span class="invoicedText">{{ invoicedText }}</span></i-col>
        </i-row>
      </div>
      <div class="header">
        <i-row class="vertical-center" :gutter="10" style="align-items: baseline;">
          <i-col span="3" class="col-heading text-align" v-if="!isEdit">
            Attach
          </i-col>
          <i-col span="3" class="col-heading text-align" v-if="isEdit && (currentFiles.length != 0)">
            Download
          </i-col>
          <i-col span="10" v-if="!isEdit">
            <div>
              <i-upload multiple class="supportingsUpload" type="drag" action="" :before-upload="uploadSupportingFile"
                accept=".jpg,.jpeg,.png,.pdf" :format="fileFormats.supportingsFormat" name="supportings">
                <div style="padding: 10px 0" :disabled="isEdit">
                  Drag & Drop or <span style="color: green;"><u> Click to Browse</u></span>
                </div>
              </i-upload>
            </div>
            <div v-if="currentFiles.length != 0" style="display: inline-flex ; flex-direction: column">
              <div v-for="(supportingFile, index) in currentFiles" :key="index + 1"
                class="uploadedFileName uploadedSupportingsFileName">
                {{ supportingFile.name }}
                <span v-if="!isEdit">
                  <i class="fa fa-times" @click="deleteSupportingFile(index)"></i>
                </span>
              </div>
              <br>
            </div>
          </i-col>
          <i-col span="3" v-if="isEdit && (currentFiles.length != 0)">
            {{ currentFiles.length }} {{ currentFiles.length == 1 ? 'file' : 'files' }} present
          </i-col>
          <i-col span="1" v-if="isEdit && (currentFiles.length != 0)">
            <i class="fa fa-download downloadSize" aria-hidden="true" @click="downloadIBLog(IncomingBillLogId)"></i>
          </i-col>
        </i-row>
      </div>
      <div>
        <i-row class="vertical-center" :gutter="10">
          <i-col span="3" class="col-heading text-align"> RO Details </i-col>
          <i-col span="21">
            <div class="input-table">
              <i-table :columns="getReserveOrderColumns()" :data="reserveOrders" :loading="loadingGrid"
                :no-data-text="noDataText" border>
                <template slot-scope="{ row}" slot="name">
                  <div :title="row.name" class="truncate-text-container">{{ row.name }}</div>
                </template>
                <template slot-scope="{ row}" slot="number">
                  <div :title="row.number" class="truncate-text-container">{{ row.number }}</div>
                </template>
                <template slot-scope="{ row}" slot="value">
                  <div :title="row.value" class="truncate-text-container">{{ row.value }}</div>
                </template>
                <template slot-scope="{ row}" slot="invoiceShare">
                  <div :title="row.invoiceShare" class="truncate-text-container">{{ row.invoiceShare
                    }}</div>
                </template>
                <template v-if="!isEdit" slot-scope="{ row, index }" slot="updatedAmount">
                  <div :title="row.updatedAmount">
                    <i-input-number v-model="reserveOrders[index].updatedAmount" :min="0">
                    </i-input-number>
                  </div>
                </template>
              </i-table>
            </div>
          </i-col>
        </i-row>
      </div>

      <div>
        <i-row class="vertical-center" :gutter="10" style="margin-top:15px">
          <i-col span="3" class="col-heading text-align"> Tax Information </i-col>
          <i-col span="21">
            <br />
            <i-table :columns="getTaxInformationColumns()" :data="taxInformation.data" border :no-data-text="noDataText"
              :loading="loadingGrid">

              <template slot-scope="{ row, index }" slot="taxType">
                <i-input size="small" :data-tax-error="row.taxType == 0" v-model="row.taxType"
                  @on-change="handleTaxTypeChange(row, index)" placeholder="Tax Type" :disabled="isEdit">
                </i-input>
              </template>

              <template slot-scope="{ row, index }" slot="taxPercentage">
                <i-input :data-tax-error="isNaN(parseFloat(row.taxPercentage))" size="small" type="number"
                  v-model="row.taxPercentage" @on-change="handleTaxPercentageChange(row, index)" :disabled="isEdit">
                </i-input>
              </template>

              <template slot-scope="{ index }" slot="remove">
                <div>
                  <i class="fa fa-times" @click="deleteTaxRow(index)"></i>
                </div>
              </template>

            </i-table>
          </i-col>
        </i-row>
      </div>

      <div>
        <i-row :gutter="10" style="margin-top:10px">
          <i-col offset="3" class-name="top-padding-10">
            <Button class="btn btn-success addButton" @click="addTaxInformation" v-if="!isEdit">
              Add Tax Information
            </Button>
          </i-col>
        </i-row>
      </div>

      <div slot="footer" class="footer">
        <button class="btn btn-white" @click="closeModal">Close</button>
        <button class="btn btn-success" @click="submitCreditOrDebitNote" v-if="!isEdit"
          :disabled="(loadingSubmit) || (this.selectedInvoiceId == null) || (this.reserveOrders.length == 0)">
          <span v-if="loadingSubmit">
            <i class="fa fa-spinner fa-spin"></i>
          </span> Submit</button>
      </div>
    </Modal>
    <Modal :value="showConfirmationModal" width="50%" :title="modalTitle" :mask-closable="false"
      @on-cancel="confirmBoxCancel()">
      <h4 slot="header">Confirm</h4>
      <div>
        <p class="confirm-box-text-style">{{ confirmationMessage }}</p>
      </div>
      <div slot="footer" class="footer">
        <button type="button" class="btn btn-white" @click="confirmBoxCancel">Back</button>
        <button class="btn btn-success" @click="confirmAction">
          <span v-if="loadingConfirm">
            <i class="fa fa-spinner fa-spin"></i>
          </span> Proceed
        </button>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Row, Col, Modal, Input, Option, Select, Table, InputNumber, Upload } from "iview";
import { ApiService } from "./../Services/ApiService.js";
export default {
  components: {
    Modal,
    "i-col": Col,
    "i-row": Row,
    "i-input": Input,
    "i-option": Option,
    "i-select": Select,
    "i-table": Table,
    "i-input-number": InputNumber,
    "i-upload": Upload
  },
  emits: ['close'],
  data () {
    return {
      modalTitle: '',
      noDataText: "No data available",
      loadingGrid: false,
      iData: [],
      showModal: false,
      showConfirmationModal: false,
      confirmationMessage: '',
      loadingSubmit: false,
      loadingConfirm: false,
      incomingBillLogs: [],
      selectedInvoiceId: null,
      selectedInvoice: null,
      noteRequest: null,
      reserveOrders: [],
      totalCost: 0,
      invoicedText: '',
      isEdit: false,
      editData: null,
      mappedBillLogDetails: {},
      taxInformation: {
        data: []
      },
      updatedcost: 0,
      amount: 0,
      totalTax: 0,
      currentFiles: [],
      fileFormats: {
        invoiceFormat: ['.pdf'],
        supportingsFormat: ['.jpg', '.jpeg', '.png', '.pdf']
      },
      lineItemsInformation: []
    }
  },
  props: {
    Type: Number,
    IncomingBillLogId: Number
  },
  methods: {
    getReserveOrderColumns () {
      var baseColumns = [
        {
          title: "RO Name",
          key: "name",
          align: "left",
          slot: "name",
          minWidth: 150
        },
        {
          title: "RO Number",
          key: "number",
          align: "left",
          slot: "number",
          minWidth: 150
        },
        {
          title: "RO Value",
          key: "value",
          align: "right",
          slot: "value",
          minWidth: 120
        },
        {
          title: "Invoice Share",
          key: "invoiceShare",
          align: "right",
          slot: "invoiceShare",
          minWidth: 120
        }
      ];

      if (!this.isEdit) {
        baseColumns.push({
          title: this.Type === 2 ? "Credit Amount" : "Debit Amount",
          key: "updatedAmount",
          align: "right",
          slot: "updatedAmount",
          minWidth: 120
        });
      }

      return baseColumns;
    },

    getTaxInformationColumns () {
      var baseColumns = [
        {
          title: "Tax Type",
          key: "taxType",
          align: "center",
          slot: "taxType"
        },
        {
          title: "Tax Percentage",
          key: "taxPercentage",
          align: "center",
          slot: "taxPercentage"
        },
        {
          title: "Tax Value",
          key: "taxValue",
          align: "center"
        }
      ];

      if (!this.isEdit) {
        baseColumns.push({
          title: "Remove",
          key: "remove",
          align: "center",
          slot: "remove",
          width: 150
        });
      }

      return baseColumns;
    },
    fetchIncomingBillLogs () {
      var queryParams = {
        Type: 1
      };
      this.apiService = new ApiService();
      this.iData = [];
      this.incomingBillLogs = [];
      this.loadingGrid = true;
      this.apiService.getIncomingBillLogs(queryParams)
        .then((response) => {
          this.iData = response.data.data.data;
          this.incomingBillLogs = this.iData.map((element) => ({
            id: element.id,
            invoiceNumber: element.invoiceNumber,
            ReserveOrderIds: element.reserveOrderIds
          }));
          if (this.isEdit) {
            this.setInvoicedata();
          } else {
            this.loadingGrid = false;
          }
        })
        .catch(() => {
          this.loadingGrid = false;
        });
    },
    setInvoicedata () {
      this.reserveOrders = [];
      this.loadingGrid = true;
      this.invoicedText = '';
      this.taxInformation.data = [];

      var ibLogId = this.isEdit ? this.IncomingBillLogId : this.selectedInvoiceId;

      this.selectedInvoice = this.incomingBillLogs.find(
        (incomingBillLog) => incomingBillLog.id == this.selectedInvoiceId
      );
      var promises = [];

      var queryparams = {
        Type: this.Type,
        ParentIncomingBillLogId: this.selectedInvoiceId
      };
      if (ibLogId > 0) {
        promises.push(this.apiService.getIncomingBillLogById(ibLogId));
        promises.push(this.apiService.getIncomingBillLogs(queryparams));
        if (this.isEdit) { promises.push(this.apiService.getAttachmentsByIbLogId(ibLogId)) }

        Promise.all(promises)
          .then(([incomingBillLogResponse, response, attachmentsData]) => {
            var incomingBillLog = incomingBillLogResponse.data.data;
            var incomingBillLogs = response.data.data.data;
            var attachments = (attachmentsData && attachmentsData.data && attachmentsData.data.data) || [];
            var reserveOrders;
            var filters;

            if (this.selectedInvoice && this.selectedInvoice.ReserveOrderIds && !this.isEdit) {
              filters = {
                ReserveOrderIds: this.selectedInvoice.ReserveOrderIds
              };
            }
            if (this.isEdit) {
              filters = {
                ReserveOrderIds: incomingBillLog.reserveOrderIds
              };
            }
            if (filters.ReserveOrderIds.length > 0) {
              this.apiService.getReserveOrders(filters).then((response) => {
                reserveOrders = response.data.data.reserveOrderResponses;

                if (this.isEdit) {
                  this.selectedInvoiceId = incomingBillLog.parentIncomingBillLogId;
                  let files = attachments.map(x => {
                    return {
                      id: x.id,
                      name: x.name
                    };
                  })
                  this.currentFiles.push(...files);
                }

                var reserveOrderDetails = incomingBillLog.reserveOrderDetails || [];
                var invoiceShareMap = reserveOrderDetails.reduce((map, detail) => {
                  map[detail.reserveOrderId] = detail.invoiceShare;
                  return map;
                }, {});

                this.reserveOrders = reserveOrders.map((order) => ({
                  id: order.id,
                  name: order.name,
                  number: order.number,
                  value: parseFloat(order.allocatedBudget || 0).toLocaleString('en-US', { minimumFractionDigits: 4 }),
                  invoiceShare: parseFloat(invoiceShareMap[order.id] || 0).toLocaleString('en-US', { minimumFractionDigits: 4 }),
                  updatedAmount: 0
                }));

                this.mappedBillLogDetails = this.mapIncomingBillLogDetails(incomingBillLog);

                var costBreakdown = JSON.parse(incomingBillLog.costBreakdown || "{}");
                var taxInformation = costBreakdown.taxInformation || [];
                this.lineItemsInformation = costBreakdown.lineItemsInformation || [];
                this.taxInformation.data = taxInformation.map((tax) => ({
                  taxType: tax.name,
                  taxPercentage: tax.rate,
                  taxValue: tax.value
                }));
                if (incomingBillLogs.length > 0 && !this.isEdit) {
                  var totalAmount = incomingBillLogs.reduce((sum, invoice) => sum + (invoice.amount || 0), 0);
                  if (this.Type === 2) {
                    this.invoicedText = `${incomingBillLogs.length} Credit Note generated, with a combined total of ${totalAmount}`;
                  } else {
                    this.invoicedText = `${incomingBillLogs.length} Debit Note generated, with a combined total of ${totalAmount}`;
                  }
                }
                this.totalCost = incomingBillLog.baseCost;
                this.loadingGrid = false;
              })
            }
          })
          .catch(() => {
            this.loadingGrid = false;
          });
      } else {
        this.loadingGrid = false;
      }
    },
    ToggleModal (isVisible) {
      if (isVisible) {
        this.isEdit = this.IncomingBillLogId > 0;
        if (this.Type == 2) {
          this.modalTitle = this.isEdit ? "Edit Credit Note" : "Generate Credit Note";
        } else {
          this.modalTitle = this.isEdit ? "Edit Debit Note" : "Generate Debit Note";
        }
        this.fetchIncomingBillLogs();
      }
    },
    ShowModal () {
      this.showModal = true;
    },
    uploadSupportingFile (file) {
      this.currentFiles.push(file);
    },
    deleteSupportingFile: function (index) {
      this.currentFiles.splice(index, 1);
    },
    addTaxInformation () {
      this.taxInformation.data.unshift({
        taxType: "",
        taxPercentage: 0,
        taxValue: 0
      })
    },
    handleTaxTypeChange (row, index) {
      this.taxInformation.data[index].taxType = row.taxType;
      this.taxInformation.data[index].taxTypeEdited = true;
    },
    deleteTaxRow (index) {
      this.taxInformation.data.splice(index, 1);
      this.refreshTaxInformation();
    },
    handleTaxPercentageChange (row, index) {
      let taxRate = row.taxPercentage;
      if (taxRate == undefined || taxRate == '' || isNaN(parseFloat(taxRate))) {
        taxRate = 0
      }
      taxRate = parseFloat(taxRate)
      row.taxValue = taxRate * this.getTotalCost() / 100;

      this.taxInformation.data[index].taxPercentage = row.taxPercentage;
      this.taxInformation.data[index].taxPercentageEdited = true;
      this.taxInformation.data[index].taxValue = row.taxValue;
      this.refreshTaxInformation();
    },
    refreshTaxInformation () {
      let totalCost = this.getTotalCost();
      this.taxInformation.data.forEach(taxRow => {
        taxRow.taxValue = totalCost * taxRow.taxPercentage / 100;
      })
    },
    getTotalCost () {
      let totalCost = this.totalCost;
      if (totalCost == undefined || isNaN(parseFloat(totalCost))) {
        totalCost = 0.0;
      }
      return parseFloat(totalCost);
    },
    getTotalTax () {
      this.totalTax = this.taxInformation.data.reduce((total, row) => {
        return total + (row.taxValue || 0);
      }, 0);
      return this.totalTax;
    },
    closeModal () {
      this.resetData();
      this.$emit('success', '');
    },
    resetData () {
      this.iData = [];
      this.invoicedText = '';
      this.modalTitle = '';
      this.loadingGrid = false;
      this.loadingConfirm = false;
      this.loadingSubmit = false;
      this.noteRequest = null;
      this.showModal = false;
      this.showConfirmationModal = false;
      this.confirmationMessage = '';
      this.noDataText = "No data available";
      this.reserveOrders = [];
      this.currentFiles = [];
      this.taxInformation.data = [];
      this.selectedInvoiceId = null;
      this.selectedInvoice = null;
      this.mappedBillLogDetails = {};
      this.lineItemsInformation = [];
      this.editData = null;
    },
    mapIncomingBillLogDetails (incomingBillLog) {
      return {
        voucher: incomingBillLog.voucher,
        startDate: incomingBillLog.startDate,
        endDate: incomingBillLog.endDate,
        invoiceNumber: incomingBillLog.invoiceNumber,
        supplierInvoiceDate: incomingBillLog.supplierInvoiceDate,
        receivedDate: incomingBillLog.receivedDate,
        notes: incomingBillLog.notes,
        generationDate: incomingBillLog.generationDate,
        verification: incomingBillLog.verification,
        incomingBillLogStatus: incomingBillLog.incomingBillLogStatus,
        supplierId: incomingBillLog.supplierId,
        clientId: incomingBillLog.clientId,
        agencyUnitId: incomingBillLog.agencyUnitId
      };
    },
    confirmBoxCancel () {
      this.showConfirmationModal = false;
      this.showModal = true;
    },
    confirmAction () {
      this.loadingConfirm = true;

      this.apiService
        .saveIncomingBillLog(this.noteRequest)
        .then((response) => {
          this.uploadFiles(response.data.data);
          this.resetData();
          this.loadingConfirm = false;
          this.showConfirmationModal = false;
          this.$emit('success', `successfully added ${this.Type == 3 ? 'Debit' : 'Credit'} Note`);
        })
        .catch(() => {
          this.loadingConfirm = false;
          this.$emit('failure', `Error while adding ${this.Type == 3 ? 'Debit' : 'Credit'} Note`);
        });
    },
    createCostBreakdown () {
      var taxInformation = this.taxInformation.data.map(taxRow => ({
        name: taxRow.taxType,
        rate: taxRow.taxPercentage,
        value: taxRow.taxValue
      }))
      var costBreakdown = {
        taxInformation: taxInformation,
        lineItemsInformation: this.lineItemsInformation
      };
      return JSON.stringify(costBreakdown);
    },
    async uploadFiles (incomingBillLogId) {
      this.currentFiles.forEach((file) => {
        const formData = new FormData();
        formData.append("file", file);
        this.apiService
          .uploadIncomingBillLogAttachments(incomingBillLogId, formData)
          .then(() => {
            alert('attachment added successfully');
          })
          .catch(() => {
            alert('failed to add attachment');
          });
      });
    },
    createReserveOrderData () {
      var reserveOrdersData = this.reserveOrders.map((order) => ({
        ReserveOrderId: order.id,
        Status: 1,
        InvoiceShare: order.updatedAmount
      }));
      return reserveOrdersData;
    },
    submitCreditOrDebitNote () {
      this.loadingSubmit = true;
      this.updatedcost = this.reserveOrders.reduce((total, row) => {
        return total + (row.updatedAmount || 0.00);
      }, 0);
      var TotalTax = this.getTotalTax();
      this.amount = (parseFloat(TotalTax.toFixed(2)) || 0.00) + this.updatedcost;

      var costBreakdown = this.createCostBreakdown();
      var ReserveOrderData = this.createReserveOrderData();
      this.noteRequest = {
        ...this.mappedBillLogDetails,
        id: 0,
        baseCost: this.updatedcost,
        amount: this.amount,
        type: this.Type,
        reserveOrders: ReserveOrderData,
        costBreakdown: costBreakdown,
        parentIncomingBillLogId: this.selectedInvoiceId
      }

      if (this.Type == 2) {
        this.confirmationMessage = `Credit Note with total amount ${this.amount} will be generated. Do you want to proceed? `
      } else {
        this.confirmationMessage = `Debit Note with total amount ${this.amount} will be generated. Do you want to proceed?`
      }
      this.loadingSubmit = false;
      this.showModal = false;
      this.showConfirmationModal = true;
    },
    downloadIBLog: function (ibLogId) {
      if (ibLogId > 0) {
        if (this.currentFiles.length > 0) {
          var promise = this.apiService.downloadIncomingBillLogAttachments(
            ibLogId
          );
          promise.then(
            (successResponse) => {
              const url = window.URL.createObjectURL(
                new Blob([successResponse.data], {
                  type: successResponse.headers["content-type"]
                })
              );
              const link = document.createElement("a");
              link.href = url;
              var fileName = "";
              if (
                successResponse.headers["content-disposition"] &&
                successResponse.headers["content-disposition"] != ""
              ) {
                fileName = successResponse.headers["content-disposition"]
                  .split("filename=")[1]
                  .split(";")[0];
              }
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
            }
          );
        }
      }
    }
  }
}
</script>
<style scoped>
.btn {
  margin: 0px;
}

.footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.invoicedText {
  display: inline-block;
  color: #2c2e2f;
}

.spaced-row {
  margin-bottom: 8px !important;
}

::v-deep i.fa.fa-download {
  font-size: 15px !important;
  cursor: pointer;
}

.vertical-center {
  display: flex;
  align-items: center;
}

::v-deep .ivu-input-number-handler-wrap {
  display: none;
}

::v-deep .input-table .ivu-table td,
::v-deep .input-table .ivu-table th {
  height: 35px !important;
}

::v-deep th .ivu-table-cell {
  font-weight: 500;
}

.i-input-label {
  padding-right: 4% !important;
}

.header {
  margin-bottom: 15px !important;
}

.confirm-box-text-style {
  font-size: 14px !important;
}

.text-align {
  text-align: right;
}

.uploadedFileName {
  padding: 8px;
  display: inline-flex;
  gap: 15px;
  background-color: #F3F3F3;
  border-radius: 4px;
}

.uploadedSupportingsFileName {
  margin-top: 5px;
  justify-content: space-between;
}

::v-deep .input-table td .ivu-table-cell {
  padding-left: 0px !important;
}

::v-deep th.ivu-table-column-right {
  text-align: center;
}

::v-deep th.ivu-table-column-left {
  text-align: center;
}

::v-deep .ivu-modal {
  top: 53px !important;
}

::v-deep .input-table .ivu-table {
  min-height: unset !important;
  max-height: 60vh !important;
  overflow: auto;
}

.truncate-text-container {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
