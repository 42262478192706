import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    spinner: false,
    spinnerText: "",
    alertType: "warning",
    showModal: false,
    modalCancelText: "Cancel",
    modalOkText: "Yes",
    modalOkSpinnerClass: "",
    modalTitleText: "",
    modalBodyText: "",
    modalConfirmCallback: null,
    authFlows: [
      /*
            {
                CurrentStage: "STAGE_1",
                Status: "IN_REVIEW",
                Id: 0,
                Name: "",
                AppliesTo: [
                    {
                        Id: 0,
                        Name: "",
                        Label: ""
                    }
                ],
                Stages: [
                    {
                        Status: "IN_REVIEW",
                        Id: "STAGE_1",
                        Order: 1,
                        Name: "",
                        Factions: [
                            {
                                Approvals: [
                                    {
                                        "UserId": 1231,
                                        "Time": "",
                                        "Comment": "Approved by AY"
                                    },
                                ],
                                Rejections: [
                                    {
                                        "UserId": 1231,
                                        "Time": "",
                                        "Comment": "Approved by AY"
                                    },
                                ],
                                Order: 1,
                                Name: "",
                                Id: "FACTION_1",
                                Users: [
                                    {
                                        Id: 0,
                                        FirstName: "",
                                        LastName: "",
                                        Initials: ""
                                    }
                                ]
                            }
                        ],
                        Actions: [
                            {
                                Name: "ON_ENTER",
                                Value: "EMAIL_CURRENT_STATE_TEAMS"
                            }
                        ]
                    }
                ],
            }
            */
    ],
    isEditEnabled: true,
    bpusers: [],
    engineTypes: {
      0: {
        "Id": 0,
        "Name": "MEDIAPLAN_BULK_CREATION",
        "Label": "MEDIA PLAN BULK CREATION"
      },
      1: {
        "Id": 1,
        "Name": "Search",
        "Label": "Search"
      },
      2: {
        "Id": 2,
        "Name": "Social",
        "Label": "Social"
      },
      11: {
        "Id": 11,
        "Name": "AMS",
        "Label": "AMS"
      }
    },
    permissionType: {
      All: 1,
      Any: 2,
      Skip: 3
    }
  },
  actions,
  mutations,
  getters
}
