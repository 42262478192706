<template>
  <Form
    label-position="right"
    :label-width="200"
    :model="config"
    :rules="validationRules"
    ref="linkedInConfig"
  >
    <div v-show="showConfigForm">
      <Divider orientation="left">Accounts and Assets</Divider>
      <Form-Item label="LinkedIn Account" prop="bpseId" key="bpseId" :required="true">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.bpseId"
              placeholder="Select an account"
              :transfer="true"
              :disabled="isExistingConfig"
              filterable
            >
              <Option
                v-for="(item, index) in linkedInAccounts"
                :key="index"
                :value="item.id"
                :disabled="item.isOauthExpired"
                >{{ item.accountId + " - " + item.accountNickName }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Divider orientation="left">Campaign Settings</Divider>
      <Form-Item label="Objective" prop="objective" key="objective">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.objective"
              placeholder="Select an Objective"
              :transfer="true"
            >
              <OptionGroup label="Awareness">
                <Option
                  v-for="(item, id) in linkedInEnums.objective.awareness"
                  :key="parseInt(id)"
                  :value="parseInt(id)"
                  >{{ item }}</Option
                >
              </OptionGroup>
              <OptionGroup label="Consideration">
                <Option
                  v-for="(item, id) in linkedInEnums.objective.consideration"
                  :key="parseInt(id)"
                  :value="parseInt(id)"
                  >{{ item }}</Option
                >
              </OptionGroup>
              <OptionGroup label="Conversions">
                <Option
                  v-for="(item, id) in linkedInEnums.objective.conversions"
                  :key="parseInt(id)"
                  :value="parseInt(id)"
                  >{{ item }}</Option
                >
              </OptionGroup>
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Placement" prop="placement" key="placement" v-if="config.objective != 5">
        <Row>
          <Row-Col span="18">
            <Checkbox v-model="config.placement" type="button">LinkedIn Audience Network</Checkbox>
            <Tooltip>
                <Icon type="md-information-circle" />
                <div slot="content">
                  <p>Only supported for Single Image </p>
                  <p>and Video ad formats.</p>
              </div>
            </Tooltip>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Target Expansion" prop="targetExpansion" key="targetExpansion">
        <Row>
          <Row-Col span="18">
            <Checkbox v-model="config.targetExpansion" type="button">Expand Targeting</Checkbox>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Budget Type" prop="budgetType" key="budgetType">
        <Row>
          <Row-Col span="18">
            <Select
              v-model=config.budgetType
              placeholder="Select Budget Type"
              :transfer="true"
              filterable
            >
              <Option
                v-for="(type,id) in linkedInEnums.budgetType"
                :key="parseInt(id)"
                :value="parseInt(id)"
                >{{ type }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Optimisation Goal" prop="optimisationGoal" key="optimisationGoal">
        <Row>
          <Row-Col span="18">
            <Select
              v-model=config.optimizationGoal
              placeholder="Select Optimisation Goal"
              :transfer="true"
              filterable
            >
              <Option
                v-for="goal in filteredOptimizationGoal"
                :key="goal.id"
                :value="goal.id"
                >{{ goal.label }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
            <Form-Item label="Bidding Strategy" prop="biddingStrategy" key="biddingStrategy">
        <Row>
          <Row-Col span="18">
            <Select
              v-model=config.biddingStrategy
              placeholder="Select Bidding Strategy"
              :transfer="true"
              filterable
            >
              <Option
                v-for="biddingStrategy in availableBiddingStrategies"
                :key="parseInt(biddingStrategy.id)"
                :value="parseInt(biddingStrategy.id)"
                >{{ biddingStrategy.value }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Bid Amount" prop="bidAmount" key="bidAmount" v-if="config.biddingStrategy === 2 || config.biddingStrategy === 3">
        <Row>
          <Row-Col span="4">
            <i-input v-model.number="config.bidAmount" placeholder="Bid" type="number" clearable >
              <span slot="prepend">{{bpCurrencySymbol}}</span>
              </i-input>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Scheduling" prop="scheduling" key="scheduling">
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.scheduling">
              <Radio
                :label="parseInt(id)"
                v-for="(name, id) in linkedInEnums.scheduling"
                :key="parseInt(id)"
              >
                <span> {{ name }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Conversion Tracking" prop="conversionTracking" key="conversionTracking">
        <Row>
          <Row-Col span="18">
            <Select
              v-model=config.conversionTracking
              placeholder="Select Conversions"
              :transfer="true"
              :disabled="!conversionsFetched"
              filterable
              multiple
            >
              <Option
                v-for="conversion in conversions"
                :key="conversion.id"
                :value="conversion.id"
                >{{ conversion.name }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Campaign Status" prop="status" key="status">
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.status">
              <Radio
                :label="parseInt(id)"
                v-for="(status, id) in linkedInEnums.status"
                :key="parseInt(id)"
              >
                <span> {{ status }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>
       <Form-Item label="Configuration Name" prop="name" key="name">
        <Row>
          <Row-Col span="18">
            <i-input v-model="config.name" clearable />
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Campaign Name" prop="campaignName" key="campaignName">
        <Row>
          <Row-Col span="18">
            <i-input :disabled="isNomenclatureEnabled" v-model="config.campaignName" clearable/>
          </Row-Col>
        </Row>
        <!-- <Row>
          <Row-Col span="18">
            <ul class="macro-list">
              <li class = "macros">Available Macros:</li>
              <li v-for="macroName in linkedInEnums.macroList" :key ="macroName">
                <a class = "macros" @click="addMacro(macroName)"> {{macroName}} </a>
              </li>
          </ul>
          </Row-Col>
        </Row> -->
      </Form-Item>
    </div>
  </Form>
</template>
<script>
import {
  Divider,
  Row,
  Col,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Option,
  OptionGroup,
  Checkbox,
  Input,
  Tooltip,
  Icon
} from "iview";
import { mapState, mapMutations } from "vuex";
import { APIService } from "./../../ApiService.js";
import { linkedInEnums } from "../../Constants/LinkedInEnums.js";
import CLHelperMethodsMixin from "../../Mixins/CLHelperMethods.js";

export default {
  props: {
    loadExistingConfigId: { default: "" },
    showConfigForm: { default: false },
    isExistingConfig: { default: false },
    nomenclatureMacro: { default: null }
  },
  components: {
    Divider,
    Row,
    "Row-Col": Col,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Select,
    Option,
    OptionGroup,
    Checkbox,
    "i-input": Input,
    Tooltip,
    Icon
  },
  data () {
    return {
      config: {
        searchEngineTypeId: 7,
        name: "",
        bpseId: 0,
        objective: null,
        placement: false,
        targetExpansion: false,
        budgetType: null,
        optimizationGoal: null,
        biddingStrategy: null,
        scheduling: 1,
        conversionTracking: null,
        status: 1,
        campaignName: "",
        macroList: ["MP_name"],
        goal: null,
        bidAmount: 0.00
      },
      conversionsFetched: false,
      optimizationListPresent: false,
      conversions: [],
      filteredOptimizationGoal: [],
      validationRules: {
        bpseId: [
          {
            validator: (rule, value, callback) => {
              if (this.checkIfValid && (isNaN(value) || value <= 0)) {
                callback(new Error("Please select an account"));
              }
              callback();
            }
          }
        ],
        "budgetType": [
          { required: true, message: "Please select a budget type" }
        ],
        "objective": [
          { required: true, message: "Please select a campaign objective" }
        ],
        "campaignName": [
          { required: true, message: "Please enter a campaign name" }
        ],
        name: [{ required: true, message: "Please enter a configuration name" }],
        "bidAmount": [
          {
            type: "number",
            min: 0.1,
            required: true,
            message: "Please enter a valid bid amount",
            trigger: "change"
          },
          {
            validator: (rule, value, callback) => {
              if (!value && (this.config.biddingStrategy == 2 || this.config.biddingStrategy == 3)) {
                callback(new Error("Please enter a bid amount"));
              }
              callback();
            }
          }
        ],
        "biddingStrategy": [
          { required: true, message: "Please select a bidding strategy" }
        ]
      },
      linkedInEnums,
      maxExposureValue: 1,
      isNomenclatureEnabled: false
    };
  },
  mixins: [CLHelperMethodsMixin],
  mounted () {
    this.loadNomenclature();
  },
  updated () {
    this.loadNomenclature();
  },
  watch: {
    loadExistingConfigId (id) {
      if (id) {
        this.config = JSON.parse(this.campaignLauncherConfigs[id].config);
        if (!this.isExistingConfig) {
          this.config.name = this.appendCopyToConfigName(this.config.name)
        }
      }
    },
    "config.bpseId" () {
      if (this.config.bpseId) {
        this.fetchConversions(this.config.bpseId);
      }
    },
    "config.objective" () {
      if (this.config.objective) {
        this.setOptimizationGoals();

        if (this.config.objective == 5) {
          this.config.placement = false;
        }
      }
    },
    "config.biddingStrategy" () {
      if (this.config.biddingStrategy == 1) {
        this.config.bidAmount = 0;
      }
    }
  },
  computed: {
    ...mapState([
      "linkedInAccounts",
      "businessProfileId",
      "campaignLauncherConfigs",
      "publisher",
      "deltax",
      "mediaPlanId",
      "selectedMediaPlan",
      "bpCurrencySymbol"
    ]),
    "config.macroList" () {
      return [...this.config.campaignName].join();
    },
    availableBiddingStrategies: function () {
      if (!this.config.objective || !this.config.optimizationGoal) {
        return [];
      }

      return this.linkedInEnums.objectiveBiddingStrategyMapping[this.config.objective][this.config.optimizationGoal].map(x => {
        return {
          id: x,
          value: this.linkedInEnums.biddingStrategy[x]
        }
      });
    }
  },
  methods: {
    ...mapMutations([]),
    getConfig () {
      return this.config;
    },
    resetFields () {
      this.$refs["linkedInConfig"].resetFields();
    },
    isLinkedInFormValid (saveCallback) {
      this.checkIfValid = true;
      this.$refs["linkedInConfig"].validate(valid => {
        if (valid) {
          saveCallback();
        }
        this.checkIfValid = false;
      });
    },
    addMacro (name) {
      let macroName = "{" + name + "}"
      if (!this.config.campaignName.includes(macroName)) {
        if (this.config.campaignName) {
          this.config.campaignName += "-"
        }
        this.config.campaignName += macroName
      }
    },
    async fetchConversions (bpseId) {
      try {
        let response = await APIService.getApiData(
          this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/businessProfileSearchEngines/" +
          bpseId +
          "/linkedinconversions"
        );
        if (response.data) {
          this.conversionsFetched = true;
          this.conversions = response.data.data;
        }
      } catch (err) {
        console.log(err);
        this.conversionsFetched = false;
      }
    },
    setOptimizationGoals () {
      if (this.config.objective) {
        var optimizationList = this.linkedInEnums.objectiveOptimizationGoalMapping[this.config.objective]
        this.filteredOptimizationGoal = this.linkedInEnums.optimizationGoal.filter(function (obj) {
          return optimizationList.some(function (obj2) {
            return obj.id === obj2;
          });
        });
        this.config.optimizationGoal = this.linkedInEnums.defaultSelectionForOptimisationByObjective[this.config.objective]
      }
    },
    loadNomenclature () {
      if (this.nomenclatureMacro && this.nomenclatureMacro.campaign && this.nomenclatureMacro.campaign != '') {
        this.isNomenclatureEnabled = true;
        this.config.campaignName = this.nomenclatureMacro.campaign;
      }
    }
  }
};
</script>
<style scoped>
.macro-list > li {
    display: inline-block;
    *display:inline;
    cursor: pointer;
}
.macros {
  padding-right: 10px;
}
</style>
