<template>
  <div class="elements">
    {{ allVerified }}
    <div v-if="budgetTypePivot.campaigns > 0" class="budget-type-pivot">
      <div class="">
        <label class="element-label">Campaign Setup</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ budgetTypeVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="budgetTypeVerified" :disabled="budgetTypeVerifiedDisable"/>
          </label>
        </span>
      </div>
      <div class="budget-type-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th
                v-for="item in budgetTypeColumns"
                :key="item"
                class="table-header"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody v-for="(record, i) in budgetTypePivot.records" :key="i">
            <tr :class = "{'disable-row' : record.status === 'Success'}">
              <td class="data-center">
                <span>
                    <a
                    data-toggle="collapse"
                    v-bind:href="'#adgroup-preview-' +  record.index"
                  ><u>{{ record.campaignName }}</u></a>
               </span ></td>
              <td class="data-center">{{ record.status }}</td>
              <td class="data-center">{{ record.startDate }} - {{ record.endDate }}</td>
              <td class="data-center">{{ record.budgetType }}</td>
              <td class="data-center"><span slot="prepend">{{record.bpCurrency}}</span>{{ record.totalBudget }}</td>
              <td class="data-center">
                <Row v-if="record.editingBudget" type="flex" align="top">
                  <column span="15" style="padding-right:5%;">
                <i-input
                      v-model.number="budgetValue"
                      type="number"
                    >
                      <span slot="prepend">{{record.bpCurrency}}</span>
                </i-input>
                  </column>
                   <column span="2" style="padding-right:5%;">
                <span @click="saveBudgetValue(i, budgetValue)">
              <i :class="' fa fa-check ' "
                aria-hidden="true"
                title="Update"
              ></i>
            </span>
             </column>
              <column span="2">
            <span @click="record.editingBudget=false,reserveEditMode=false">
              <i class="fa fa-times" aria-hidden="true" title="Cancel"></i>
            </span>
              </column>
            </Row>
            <span v-if="!record.editingBudget" slot="content" style="padding-right:5%">{{ record.bpCurrency +" "+ record.totalBudget + " " + " (" + record.budget + " Per Day)"}}</span>
                <span v-if="!record.editingBudget"
                  :class="reserveEditMode ? ' disabled': ''"
                  data-toggle="collapse"
                  @click="editBudget(i)"
                  ><i v-if = "record.status === 'Success'" class="fa fa-pencil" :style="{color:'grey'}" />
                  <i v-else class="fa fa-pencil" />
                </span>
              </td>
            </tr>
            <tr class="panel-collapse collapse in" v-bind:id="'adgroup-preview-' +  record.index">
              <td v-bind:colspan="budgetTypeColumns.length">
                <AmsAdgroupPreview
                  :adGroupDetails = "record.adGroupDetails"
                ></AmsAdgroupPreview>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
       <div class="">
         <Row style="width:100%" type="flex" align="top">
           <column span="21">
        <label class="element-label">Targeting</label>
           </column>
          </Row>
      </div>
      <div class="budget-type-table" slot="content">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="3" class="table-header targetWidth data-left-top">Targets</th>
              <th  class="table-header data-left-top">Adgroup</th>
            </tr>
          </thead>
          <tbody v-for="(campaign, i) in budgetTypePivot.records" :key="i">
            <tr v-for="(record, i) in campaign.adGroupDetails" :key="i" :class = "{'disable-row' : record.status == 8}">
              <td class="data-left-top" v-if="record.targetType == 'Product Targeting' && record.categories!= '' && record.categories != '(None)'" :colspan="record.categorySpan"><b>Categories:</b><br/>{{ record.categories }}<br/><b>Bid Range:</b> {{ record.categoryBidRange }}</td>
              <td class="data-left-top" v-if="record.targetType == 'Product Targeting' && record.asinsToTarget != '' && record.asinsToTarget != '(None)'" :colspan="record.asinSpan"><b>Asins:</b><br/>{{ record.asinsToTarget }}<br/><b>Bid Range:</b> {{ record.asinsBidRange }}</td>
              <td class="data-left-top" v-if="record.targetType == 'Product Targeting' && record.negativeAsins != '' && record.negativeAsins != '(None)'" :colspan="record.negativeAsinSpan"><b>Excluded asins:</b><br/>{{ record.negativeAsins }}</td>
              <td class="data-left-top" v-if="record.targetType == 'Keyword Targeting' && record.keywords != '' && record.keywords != '(None)'" :colspan="record.keywordSpan"><b>Keywords:</b><br/>{{ record.keywords }}<br/><b>Bid Range:</b> {{ record.keywordBidRange }}</td>
              <td class="data-left-top" v-if="record.targetType == 'Keyword Targeting' && record.negativeKeywords != '' && record.negativeKeywords != '(None)'" :colspan="record.negativeKeywordSpan"><b>NegativeKeywords:</b><br/>{{ record.negativeKeywords }}</td>
              <td class="data-left-top" v-if="record.categories == '(None)' && record.asinsToTarget == '(None)' && record.negativeAsins == '(None)' && record.negativeKeywords == '(None)' && record.keywords == '(None)'" colspan="3">No Targets</td>
              <td class="data-left-top" v-if="(record.targetType == 'Keyword Targeting' && record.keywords != '' && record.negativeKeywords != '') || (record.targetType == 'Product Targeting' && record.categories!= '' && record.asinsToTarget != '' && record.negativeAsins != '')">{{ record.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="">
        <label class="element-label">Ads</label>
      </div>
      <div class="budget-type-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th  class="table-header">Cluster</th>
              <th  class="table-header adGroupWidth">
                <Row type="flex" align="middle">
                  <column span="19">Products Advertised</column>
                  <column span="4" style="text-align:end;"><i
                        v-tooltip="'View all Products'"
                        class="fa fa-expand"
                        @click.stop="showAdsPopUp=true"
                      ></i></column>
                </Row>
              </th>
              <th  class="table-header">Adgroups</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, i) in clusterDetails" :key="i" :class = "{'disable-row' : record.status == 8}">
              <td class="data-center">{{ record.clusterName }}</td>
              <td class="data-center adsDisplay"><div v-for="(ad, i) in record.ad" :key="i"><div v-if="i<3" style="display: flex;flex-direction: column;padding: 10px;"><img  :src= adDetails[ad.name] style="width:100px;height:100px;max-width: 287.797px;height:150px" /> <div> Asin : {{ ad.name }} </div> <div v-if="ad.sku!=null"> Sku : {{ ad.sku }} </div></div></div></td>
              <td class="data-center">{{ record.adgroup }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal style="color:black"  v-model="showAdsPopUp" @cancel="showAdsPopUp=false" >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Ads</h4>
        <Select v-model="selectedAdsCampaign">
        <Option v-for="adgroup in adgroupInfo" :value="adgroup.adgroup.name" :key="adgroup.adgroup.name">{{ adgroup.adgroup.name }}</Option>
      </Select>
      </div>
      <div slot="modal-body" class="modal-body" style="min-height:0px">
      <div class="budget-type-table" slot="content">
        <Row  v-for="(ad, i) in adsCampaign.ads" :key="i">
          <column span="10">
          <img :src = adDetails[ad.name] style="width:100px;height:100px" />
          </column>
          <column span="14">
          <div>Asin : {{ ad.name }} </div>
          <div v-if="ad.sku != null"> Sku : {{ ad.sku }}</div>
          </column>
        </Row>
      </div>
      </div>
      <div slot="modal-footer">
         <div class="footer-div">
            <button type="button" class="btn btn-white" v-on:click="showAdsPopUp=false">Close</button>
         </div>
      </div>
   </modal>
  </div>
</template>

<script>
import { locale, Row, Col, Input, Select, Option } from "iview";
import { modal } from 'vue-strap';
import ApiService from "./ApiService.js";
import lang from "iview/dist/locale/en-US";
import { mapState, mapMutations } from "vuex";
import AmsAdgroupPreview from "./AmsAdgroupPreview.vue";

locale(lang); // iview is default chinese

export default {
  components: {
    Row,
    "column": Col,
    "i-input": Input,
    modal,
    AmsAdgroupPreview,
    Select,
    Option
  },
  mounted: function () {},
  props: {
    isSubmission: { default: true }
  },
  data: function () {
    return {
      adgroupInfo: [],
      budgetTypeVerified: true,
      showBidPopUp: false,
      budgetTypeVerifiedDisable: false,
      budgetTypeColumns: [
        "Campaigns",
        "Status",
        "Schedule",
        "Budget Type",
        "Original Budget",
        "Current Budget"
      ],
      targetColumns: [
        "Targets",
        "Campaigns"
      ],
      campaignStatus: {
        1: "Active",
        3: "Paused",
        8: "Success",
        10: "Failed"
      },
      targetingType: {
        1: "Keyword Targeting",
        2: "Product Targeting"
      },
      budgetType: {
        1: "Daily",
        2: "LifeTime"
      },
      bidingCampaign: {},
      selectedCampaign: "",
      reserveEditMode: false,
      budgetValue: 0,
      showAdsPopUp: false,
      adsCampaign: {},
      selectedAdsCampaign: "",
      budgetUpdatedLineitems: [],
      budgetVerified: false,
      minimumBudgetINR: 50,
      minimumBudgetDollar: 1,
      clusterDetails: [],
      source: '',
      htmlEndpoint: deltax.htmlApiEndpoint,
      adDetails: [],
      spanFirst: true
    };
  },
  computed: {
    ...mapState([
      "amsEntitiesResponse",
      "selectedPublisher",
      "publisher",
      "isCampaignLauncherVerificationEnabled"
    ]),
    budgetTypePivot: function () {
      const amsEntities = this.amsEntitiesResponse.data.amsEntities;
      this.spanFirst = true;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.budgetTypeVerified = false;
      }
      if (!amsEntities.length) {
        this.budgetTypeVerified = true;
        return { records: [], campaigns: 0 };
      }
      let records = amsEntities.map((campaign, index) => ({
        index: index,
        campaignName: campaign.name,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        totalBudget: campaign.additionalData.totalBudget.toLocaleString(),
        currencyCode: campaign.additionalData.bpcurrencyCode.toLocaleString(),
        budget: campaign.additionalData.budget.toLocaleString(),
        editingBudget: false,
        adGroupDetails: campaign.childEntities.map(adgroup => ({
          adgroup: adgroup,
          name: adgroup.name,
          status: adgroup.status,
          ads: adgroup.childEntities,
          bpCurrency: campaign.additionalData.bpcurrency,
          clusterName: adgroup.additionalData.cluster.name,
          targetType: this.targetingType[adgroup.amsTargetType],
          keywordSpan: adgroup.additionalData.keywordsBidInfo.length > 0 ? adgroup.additionalData.negativeKeywords.length > 0 ? 2 : 3 : 0,
          asinSpan: adgroup.additionalData.productsToTargets.length > 0 ? this.GetSpan(adgroup.additionalData.productsToTargets.length, adgroup.additionalData.negativeAsins.length, adgroup.additionalData.categories.length) : 0,
          negativeAsinSpan: adgroup.additionalData.negativeAsins.length > 0 ? this.GetSpan(adgroup.additionalData.productsToTargets.length, adgroup.additionalData.negativeAsins.length, adgroup.additionalData.categories.length) : 0,
          categorySpan: adgroup.additionalData.categories.length > 0 ? this.GetSpan(adgroup.additionalData.productsToTargets.length, adgroup.additionalData.negativeAsins.length, adgroup.additionalData.categories.length) : 0,
          negativeKeywordSpan: adgroup.additionalData.negativeKeywords.length > 0 ? adgroup.additionalData.keywordsBidInfo.length > 0 ? 1 : 3 : 0,
          asinsToTarget: adgroup.additionalData.productsToTargets.length > 0 ? adgroup.additionalData.productsToTargets.map(asin => asin.AsinsToTarget).sort().join(', ') : "(None)",
          categories: adgroup.additionalData.categories.length > 0 ? adgroup.additionalData.categories.map(e => e.CategoriesToTarget).sort().join(', ') : "(None)",
          keywords: adgroup.additionalData.keywordsBidInfo.length > 0 ? adgroup.additionalData.keywordsBidInfo.map(e => e.Keyword).sort().join(', ') : "(None)",
          negativeKeywords: adgroup.additionalData.negativeKeywords.length > 0 ? adgroup.additionalData.negativeKeywords.map(e => e.NegativeKeywords).sort().join(',') : "(None)",
          negativeAsins: adgroup.additionalData.negativeAsins.length > 0 ? adgroup.additionalData.negativeAsins.sort().join(',') : "(None)",
          categoryBidRange: adgroup.additionalData.categories.length > 0 ? this.GetBidRange(campaign.additionalData.bpcurrency, adgroup.additionalData.categories) : 0,
          asinsBidRange: adgroup.additionalData.productsToTargets.length > 0 ? this.GetBidRange(campaign.additionalData.bpcurrency, adgroup.additionalData.productsToTargets) : 0,
          keywordBidRange: adgroup.additionalData.keywordsBidInfo.length > 0 ? this.GetBidRange(campaign.additionalData.bpcurrency, adgroup.additionalData.keywordsBidInfo) : 0
        })),
        status: this.campaignStatus[campaign.status],
        bpCurrency: campaign.additionalData.bpcurrency,
        budgetType: this.budgetType[campaign.additionalData.budgetType],
        lineItemId: campaign.lineItemId
      }));
      let minimumBudget = records[0].currencyCode == "INR" ? this.minimumBudgetINR : this.minimumBudgetDollar;
      for (var i = 0; i < records.length; i++) {
        if (parseInt(records[i].budget.split(',').join('')) < minimumBudget && !this.budgetVerified) {
          this.$Message.error({
            background: true,
            content: "Budget should be greater than " + minimumBudget + " for the campaign" + records[i].campaignName
          });
        }
      }
      this.budgetVerified = true;
      let targets = [];
      this.clusterDetails = [];
      let adgroups = records.map(campaign => campaign.adGroupDetails);
      let details = [];
      adgroups.forEach(adgroup => {
        adgroup.forEach(adgrp => {
          details.push(adgrp);
        })
      })
      this.adgroupInfo = details;
      details.forEach(info => {
        info.adgroup.childEntities.forEach(ad => {
          this.adDetails[ad.name] = this.htmlEndpoint + 'Amazon/Asin/' + ad.name
        })
        var i = this.clusterDetails.findIndex(x => (x.clusterName == info.adgroup.additionalData.cluster.name));
        if (i <= -1) {
          this.clusterDetails.push({
            clusterName: info.adgroup.additionalData.cluster.name,
            ad: info.adgroup.childEntities,
            adgroup: info.adgroup.name
          });
        } else {
          let index = this.clusterDetails.findIndex(cluster => cluster.clusterName == info.adgroup.additionalData.cluster.name);
          this.clusterDetails[index].adgroup = this.clusterDetails[index].adgroup + ", " + info.adgroup.name
        }
        if (info.targetType == 'Keyword Targeting') {
          targets.push(info.keywords + ',' + info.negativeKeywords)
        } else if (info.targetType == 'Product Targeting') {
          targets.push(info.asinsToTarget + ',' + info.categories + ',' + info.negativeAsins)
        }
      })
      targets.forEach((element, index) => {
        let count = targets.findIndex((keyword) => keyword == element);
        if (index != count && details[index].targetType == 'Keyword Targeting') {
          details[index].keywords = '';
          details[index].negativeKeywords = '';
          details[count].name = details[count].name + ", " + details[index].name
        } else if (index != count && details[index].targetType == 'Product Targeting') {
          details[index].asinsToTarget = '';
          details[index].categories = '';
          details[index].negativeAsins = '';
          details[count].name = details[count].name + ", " + details[index].name
        }
      });
      var adsToBeExported = 0;
      this.clusterDetails.forEach(obj => {
        obj.ad.forEach(ad => {
          if (ad.status != 8 && ad.status != 10) {
            adsToBeExported++;
          }
        })
      })
      this.budgetTypeVerifiedDisable = !adsToBeExported > 0;
      let totalCampaigns = records.length;
      return { records: records, campaigns: totalCampaigns };
    },
    allVerified: function () {
      this.$emit("elementsVerified", [
        { value: this.budgetTypeVerified, label: "Campaign Setup" }
      ]);
      return "";
    }
  },
  methods: {
    ...mapMutations(["Set_Ams_sd_entities"]),
    editBudget (index) {
      this.reserveEditMode = true;
      this.budgetValue = this.budgetTypePivot.records[index].budget;
      this.budgetTypePivot.records[index].editingBudget = true;
    },
    closeBudgetEditing (index) {
      this.budgetTypePivot.records[index].editingBudget = false
      this.reserveEditMode = false;
    },
    saveBudgetValue (index, value) {
      let updatedTotalBudget = 0;
      this.reserveEditMode = false;
      let endDate = new Date(this.budgetTypePivot.records[index].endDate);
      let startDate = new Date(this.budgetTypePivot.records[index].startDate);
      let lineitemCampaigns = this.budgetTypePivot.records.filter((x, ind) => x.lineItemId == this.budgetTypePivot.records[index].lineItemId).map((x, ind) => ({ budget: x.budget, index: ind }));
      let diff = this.budgetTypePivot.records[index].endDate != undefined ? ((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) + 1 : 30;
      updatedTotalBudget = value * diff;
      lineitemCampaigns.forEach(ele => {
        if (ele.index != index) {
          updatedTotalBudget += parseInt(ele.budget) * diff
        }
      })
      this.budgetTypePivot.records[index].budget = value;
      this.budgetTypePivot.records[index].editingBudget = false;
      lineitemCampaigns.forEach(ele => {
        this.budgetTypePivot.records[ele.index].totalBudget = updatedTotalBudget;
        this.amsEntitiesResponse.data.amsEntities[ele.index].additionalData.totalBudget = updatedTotalBudget;
      })
      let lineitemIndex = this.budgetUpdatedLineitems.findIndex(object => object.lineItemId === this.budgetTypePivot.records[index].lineItemId);
      if (lineitemIndex === -1) {
        this.budgetUpdatedLineitems.push({
          LineItemId: this.budgetTypePivot.records[index].lineItemId,
          TotalBudget: updatedTotalBudget
        });
      } else {
        this.budgetUpdatedLineitems[lineitemIndex].TotalBudget = updatedTotalBudget;
      }
      this.amsEntitiesResponse.data.amsEntities[index].additionalData.budget = value;
      let requestParam = {
        EntityData: JSON.stringify(this.amsEntitiesResponse.data),
        LineItemDetails: this.budgetUpdatedLineitems
      }
      this.Set_Ams_sd_entities(requestParam);
    },
    GetBidRange (bpCurrency, array) {
      let max = Math.max.apply(Math, array.map(e => e.Bid));
      let min = Math.min.apply(Math, array.map(e => e.Bid));
      return max == min ? bpCurrency + "" + min.toFixed(2) : bpCurrency + "" + max.toFixed(2) + " - " + min.toFixed(2);
    },
    GetSpan (productsLength, negativeProductsLength, categoriesLength) {
      if (productsLength > 0 && negativeProductsLength > 0 && categoriesLength > 0) {
        return 1
      } else if ((productsLength > 0 && negativeProductsLength > 0) || (productsLength > 0 && categoriesLength > 0) || ((negativeProductsLength > 0 && categoriesLength > 0))) {
        if (this.spanFirst) {
          this.spanFirst = false;
          return 2
        } else {
          return 1
        }
      } else if ((productsLength == 0 && negativeProductsLength == 0) || (productsLength == 0 && categoriesLength == 0) || ((negativeProductsLength == 0 && categoriesLength == 0))) {
        return 3
      }
    }
  },
  watch: {
    selectedAdsCampaign: function () {
      this.budgetTypePivot.records.forEach(campaign => {
        let adgroup = campaign.adGroupDetails.filter(record => record.adgroup.name == this.selectedAdsCampaign);
        if (adgroup.length > 0) {
          this.adsCampaign = adgroup[0];
        }
      })
    }
  }
};
</script>

<style scoped>
tr.disable-row{
  pointer-events: none;
  background-color: #f9f9f9;
}
.adsDisplay {
  display:flex;
}
.adGroupWidth {
  width: 700px !important;
}
.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
}
.verified-action {
  float: right;
  padding: 10px;
}
.checkbox-status-label {
  padding-right: 5px;
  color: #999;
  cursor: pointer;
}
input[type="checkbox"] {
  vertical-align: text-bottom;
}
.total-footer {
  font-weight: 600;
  background-color: #eee;
}
.table-footer-label {
  border: 1px solid #ddd;
  padding: 0px 5px;
}
.target-label {
  padding-top: 10px;
}
.target-sub-label {
  padding-top: 10px;
  font-size: 0.9em;
  color: #979898;
  max-width: 420px;
}
.data-center {
  text-align: left;
  vertical-align: middle;
}
.data-left-top {
  text-align: left;
  vertical-align: top;
}
::v-deep .ivu-modal-footer {
  display: none;
}
.table-header{
  border:1px solid #ddd !important;
  padding:10px !important;
}
.buttonload {
  margin-top: 2px;
}
::v-deep .ivu-collapse-header {
    display: flex !important;
}
::v-deep .ivu-collapse>.ivu-collapse-item>.ivu-collapse-header>i {
    margin-top: 1%;
}
::v-deep .ivu-collapse>.ivu-collapse-item.ivu-collapse-item-active>.ivu-collapse-header>i {
  margin-top: 0px;
}
::v-deep .fa-expand {
    transform: none !important;;
}
.targetWidth {
  width: 60%;
}
</style>
