<template>
  <section id="target-detailTargeting-comp">
    <Collapse v-model="collapseOpen">
      <Panel name="1">
      <div>
      <div id="card-title">
        <div id="title-area">
          <p class="title-text">
            Age Group : <span class="title-sub-text">{{ selectedMinAge }} - {{ selectedMaxAge }}</span>
          </p>
          <p class="title-text">Gender : <span class="title-sub-text">{{ selectedGender }}</span></p>
          <p v-if="getSelectedLanguagesString().length > 0" class="title-text">Languages : <span class="title-sub-text">{{ getSelectedLanguagesString() }}</span></p>
        </div>
        <div id="icon-area">
          <tooltip :content="iconsContent[0]" placement="top">
            <i
              class="fa fa-plus title-icons icons"
              :class="{ 'disabled-icon': disableIcons }"
              @click.stop="!disableIcons && $emit('add-target')"
            ></i>
          </tooltip>
          <tooltip :content="iconsContent[1]" placement="top">
            <i
              class="fa fa-copy title-icons icons"
              :class="{ 'disabled-icon': disableIcons }"
              @click.stop="!disableIcons && $emit('copy-target')"
            ></i>
          </tooltip>
          <tooltip :content="iconsContent[2]" placement="top">
            <i
              class="fa fa-trash title-icons icons"
              :class="{ 'disabled-icon': disableIcons }"
              @click.stop="!disableIcons && $emit('delete-target')"
            ></i>
          </tooltip>
        </div>
      </div>
    </div>
      <div id="card-body" slot="content">
        <div class="card-block card-block-display">
          <Row>
            <i-col span="12">
              <div class="card-block">
                <div class="card-body-label">
                  <div class="label-text">Min Age: &nbsp;</div>
                </div>
                <div class="age-width">
                  <Select v-model="selectedMinAge" @on-change="storeMinAge" filterable>
                    <Option v-for="age in minAgeData" :key="age" :value="age">{{
                      age
                    }}</Option>
                  </Select>
                </div>
              </div>
            </i-col>
            <i-col span="12">
              <div class="card-block">
                <div class="card-body-label">
                  <div class="label-text">Max Age: &nbsp;</div>
                </div>
                <div class="age-width">
                  <Select v-model="selectedMaxAge" @on-change="storeMaxAge" filterable>
                    <Option v-for="age in maxAgeData" :key="age" :value="age">{{
                      age
                    }}</Option>
                  </Select>
                </div>
              </div>
            </i-col>
          </Row>
        </div>
        <div class="card-block">
          <div class="card-body-label card-body-label-width">
            <div class="label-text">Gender &nbsp;</div>
          </div>
          <RadioGroup
            type="button"
            class="radiogroup"
            v-model="selectedGender"
            @on-change="storeGender"
          >
            <Radio label="All"></Radio>
            <Radio label="Male"></Radio>
            <Radio label="Female"></Radio>
          </RadioGroup>
        </div>
        <div class="card-block">
          <div class="card-body-label card-body-label-width">
            <div class="label-text">Languages &nbsp;</div>
          </div>
          <div>
            <Select
              class="select-width"
              placeholder="Enter Language"
              placement="bottom-start"
              multiple
              v-model="selectedLanguages"
              :remote-method="getLanguages"
              :loading="loadingLanguages"
              filterable
              @on-change="storeLanguages"
            >
              <Option
                v-for="(value, index) in facebookLanguages"
                :value="value.key"
                :key="index"
                :label="value.name"
              >
                <Icon type="md-add-circle" size="15" />
                <span class="dropdown-text icon-text">{{ value.name }}</span>
              </Option>
            </Select>
          </div>
        </div>
        <div class="card-block">
          <div class="card-body-label card-body-label-width">
            <div class="label-text">Relationship Status &nbsp;</div>
          </div>
          <div>
            <Select
              class="select-width"
              placeholder="Choose Relationship Status"
              placement="bottom-start"
              multiple
              v-model="selectedRelationshipStatus"
              @on-change="storeRelationshipStautses"
            >
              <Option
                v-for="(value, index) in relationshipStatus"
                :value="value.id"
                :key="index"
                :label="value.name"
              >
                {{ value.name }}
              </Option>
            </Select>
          </div>
        </div>
        <div class="card-block card-block-display">
          <div class="card-body-label">
            <div>Custom Audiences &nbsp;</div>
          </div>
          <div>
            <div
              v-if="showExclusionsAudience"
              class="card-body-label custom-audience"
            >
              Include people who are at least in one of the following
            </div>
            <Select
              placeholder="Search Existing Audiences"
              prefix="ios-search"
              multiple
              :remote-method="searchCustomAudiences"
              filterable
              :loading="loadingCustomAudience"
              v-model="includedCustomAudiences"
              placement="bottom-start"
              @on-change="storeIncludedCustomAudiences"
            >
              <Option
                v-for="(value, index) in searchedCustomAudiences"
                :value="value.id"
                :key="index"
                :label="value.name"
              >
                {{ value.name }}
              </Option>
            </Select>
            <div v-if="!showExclusionsAudience" class="exclude-btn">
              <Button @click="showExclusionsAudience = true">Exclude</Button>
            </div>
          </div>
          <div v-show="showExclusionsAudience">
            <div>
              <div class="card-body-label custom-audience">
                Exclude people who are at least in one of the following
              </div>
              <Select
                placeholder="Search Existing Audiences"
                prefix="ios-search"
                multiple
                :remote-method="searchCustomAudiences"
                filterable
                :loading="loadingCustomAudience"
                v-model="excludedCustomAudiences"
                placement="bottom-start"
                @on-change="storeExcludedCustomAudiences"
              >
                <Option
                  v-for="(value, index) in searchedExcludeCustomAudiences"
                  :value="value.id"
                  :key="index"
                  :label="value.name"
                >
                  {{ value.name }}
                </Option>
              </Select>
            </div>
          </div>
        </div>
        <div class="card-block card-block-display">
          <div class="card-body-label">
            <div>Detailed Targeting </div>
          </div>
          <div v-for="(count, narrowIndex) in mergedIncludedTargets" :key="narrowIndex">
            <div
            class="card-body-label"
            style="display: inline-block; margin-top: 5px"
          >
            <span v-if="narrowIndex === 0" class="label-text">Include people who match &nbsp;</span>
            <span v-else class="label-text">and must also match &nbsp;</span>
            <tooltip placement="right" content="Adding more targeting details here will expand your audience to include more people"><Icon type="ios-information-circle" size="15" /></tooltip>
            </div>
            <div
            class="selected-target-box"
            v-for="(item, index) in mergedIncludedTargets[narrowIndex]"
            :key="item.id"
          >
            <div class="selected-target-data">
              <div>
                {{ item.name }} <span v-if="item.type" style="font-style: italic;">({{ item.type }})</span>
              </div>
              <div>
                <span v-for="(path, index) in item.path" :key="path.name">
                  {{ path }}
                  <Icon
                    v-if="index != item.path.length - 1"
                    type="md-arrow-dropright"
                  />
                </span>
              </div>
            </div>
            <div class="estimate-reach">
              <span v-if="item.audience_size_lower_bound" class="badge">{{ parseInt(item.audience_size_lower_bound).toLocaleString() }} <span v-if="item.audience_size_lower_bound">-</span>
                {{ parseInt(item.audience_size_upper_bound).toLocaleString() }}</span>
                <span v-if="item.audience_size" class="badge">{{ parseInt(item.audience_size).toLocaleString() }}</span>
            </div>
            <i class="selected-target-icon fa fa-trash" @click="deleteIncludedTarget(index, item, narrowIndex)"></i>
            </div>
            <div>
            <Row>
              <i-col span="14">
                <Select
                  ref="selectTargetsField"
                  class="browse-target"
                  placeholder="Add Demographics, interests or behaviours"
                  placement="bottom-start"
                  prefix="ios-search"
                  multiple
                  remote
                  :remote-method="query => searchTargets(query, false)"
                  v-model="selectedTargets[narrowIndex]"
                  filterable
                  :loading="loadingSearchTargets"
                  @on-change="storeParsedTarget({ name: 'include', index: narrowIndex })"
                >
                  <Option
                    v-for="(target, index) in searchedTargets"
                    :value="JSON.stringify(target)"
                    :key="index"
                  >
                    <Icon type="md-add-circle" size="15" />
                    <span class="dropdown-text icon-text">{{ target.name }}</span>
                    <span class="target-path"> {{ target.path[target.path.length - 2] }}</span>
                    <span class="badge">{{ parseInt(target.audience_size_upper_bound).toLocaleString() }}</span>
                  </Option>
                </Select>
              </i-col>
              <i-col span="4"
                ><Button
                  :disabled="mergedIncludedTargets[narrowIndex].length == 0"
                  @click="giveSuggestions(narrowIndex)"
                  class="btn-width"
                  >Suggestions</Button
                ></i-col
              >
              <i-col span="4">
                <Button
                  :class="buttonSelected(narrowIndex)"
                  id="includeBrowseBtn"
                  @click="showBrowseMenu(narrowIndex)"
                  :loading="loadingBrowseData[narrowIndex]"
                  class="btn-width"
                  >Browse</Button
                >
              </i-col>
              <i-col span="2">
                <BulkInterets class="btn-width" @add-bulk-interests="(value) => saveBulkInterests(value, narrowIndex)" :selectedBpseId="bpseId"></BulkInterets>
              </i-col>
            </Row>
            <div v-if="showIncludeBrowseData[narrowIndex]" class="browse-data-container">
              <TargetBrowseData class="browse-data" :browseData="categories" @get-checked-target="(value) => getCheckedTarget(value, { name: 'include', index: narrowIndex })" :mergerdTargets="mergedIncludedTargets[narrowIndex]"></TargetBrowseData>
            </div>
            </div>
          </div>
          <div style="display: flex">
              <div v-if="!showExclusions" class="exclude-btn btn-space">
              <Button @click="showExclusions = true">Exclude</Button>
            </div>
            <div v-if="mergedIncludedTargets[mergedIncludedTargets.length - 1].length > 0" class="exclude-btn">
                <Button @click="narrowFurther">Narrow further</Button>
              </div>
          </div>
          <div v-if="showExclusions">
            <div
              class="card-body-label"
              style="display: inline-block; margin-top: 10px;"
            >
              <span class="label-text">Exclude people who match &nbsp;</span>
              <tooltip placement="right" content="Choose new details to exclude people from the audience included above."><Icon type="ios-information-circle" size="15" /></tooltip>
            </div>
            <div>
              <div
                class="selected-target-box"
                v-for="(item, index) in showExcludedTargets"
                :key="item.id"
              >
                <div class="selected-target-data">
                  <div>
                    {{ item.name }} <span v-if="item.type" style="font-style: italic;">({{ item.type }})</span>
                  </div>
                  <div>
                    <span v-for="(path, index) in item.path" :key="path.name">
                      {{ path }}
                      <Icon
                        v-if="index != item.path.length - 1"
                        type="md-arrow-dropright"
                      />
                    </span>
                  </div>
                </div>
                <div class="estimate-reach">
                  <span v-if="item.audience_size_lower_bound" class="badge">{{ parseInt(item.audience_size_lower_bound).toLocaleString() }} <span v-if="item.audience_size_lower_bound">-</span>
                    {{ parseInt(item.audience_size_upper_bound).toLocaleString() }}</span>
                </div>
                <i class="selected-target-icon fa fa-trash" @click="deleteExcludedTarget(index, item)"></i>
              </div>
              <Row>
                <i-col span="20">
                  <Select
                    placeholder="Add Demographics, interests or behaviours"
                    placement="bottom-start"
                    prefix="ios-search"
                    class="browse-target"
                    multiple
                    :remote-method="query => searchTargets(query, true)"
                    v-model="excludedTargets"
                    filterable
                    @on-change="storeParsedTarget({ name: 'exclude' })"
                    :loading="loadingSearchTargets"
                  >
                    <Option
                      v-for="(value, index) in searchedTargets"
                      :value="JSON.stringify(value)"
                      :key="index"
                    >
                      <Icon type="md-add-circle" size="15" />
                      <span class="dropdown-text icon-text">{{ value.name }}</span>
                      <span class="target-path"> {{ value.path[value.path.length - 2] }}</span>
                      <span class="badge">{{ value.type }}</span>
                    </Option>
                  </Select>
                </i-col>
                <i-col span="4">
                  <Button id="excludeBrowseBtn" class="btn-width" :class="exclusionBtnSelected" :loading="loadingExclusionBrowseData" @click="showExclusionBrowseMenu"
                    >Browse</Button
                  >
                </i-col>
              </Row>
              <div v-if="showExcludeBrowseData" class="browse-data-container">
                <TargetBrowseData  class="browse-data" :browseData="categories" @get-checked-target="(value) => getCheckedTarget(value, { name: 'exclude' })" :mergerdTargets="mergerdExcludedTargets"></TargetBrowseData>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
    </Collapse>
  </section>
</template>

<script>
import {
  Icon,
  Row,
  Col,
  Button,
  Select,
  Option,
  RadioGroup,
  Radio,
  Collapse,
  Panel
} from "iview";
import { tooltip } from "vue-strap";
import { APIService } from "../../ApiService.js";
import { mapState, mapMutations } from "vuex";
import TargetBrowseData from "./TargetBrowseData.vue";
import BulkInterets from "./BulkInterets.vue";
import * as fbEnums from "../../Constants/FacebookEnums.js";

export default {
  props: {
    bpseId: {
      type: Number
    },
    currentComponentKey: {
      type: Number
    },
    targetData: {
      type: Object
    },
    targetMode: {
      type: String
    },
    loadData: {
      type: Boolean
    }
  },
  components: {
    RadioGroup,
    Radio,
    Icon,
    Row,
    "i-col": Col,
    Button,
    Select,
    Option,
    tooltip,
    TargetBrowseData,
    Collapse,
    Panel,
    BulkInterets
  },
  data: function () {
    return {
      collapseOpen: '1',
      categories: [],
      minAgeData: Array.from({ length: 53 }, (_, i) => 13 + i),
      maxAgeData: [],
      relationshipStatus: fbEnums.RelationshipStatus,
      browse: [],
      selectedMinAge: 18,
      selectedMaxAge: "No Max",
      selectedGender: "",
      selectedLanguages: [],
      selectedRelationshipStatus: [],
      includedCustomAudiences: [],
      includedCustomAudiencesObj: [],
      canSetCustomAud: false,
      excludedCustomAudiences: [],
      excludedCustomAudiencesObj: [],
      mergedIncludedTargets: [[]],
      mergerdExcludedTargets: [],
      searchedLanguages: [],
      searchedCustomAudiences: [],
      searchedExcludeCustomAudiences: [],
      searchedTargets: [],
      includedTargetsIds: [[]],
      excludedTargetsIds: [],
      showExclusions: false,
      showExclusionsAudience: false,
      showIncludeBrowseData: [false],
      showExcludeBrowseData: false,
      loadingBrowseData: [false],
      loadingExclusionBrowseData: false,
      loadingSearchTargets: false,
      loadingCustomAudience: false,
      loadingLanguages: false,
      selectedTargets: [[]],
      excludedTargets: [],
      iconsContent: ['Add', 'Clone', 'Remove']
    };
  },
  mounted () {
    this.maxAgeData = [...this.minAgeData, "No Max"];
    if (this.targetMode === "Edit Existing") {
      this.iconsContent = ["Cannot add while editing target", "Cannot clone while editing target", "Cannot remove while editing target"]
    }
    this.$nextTick(() => {
      this.loadSpecData();
    })
  },
  watch: {
    loadData: {
      handler () {
        this.loadSpecData();
      },
      deep: true
    }
  },
  computed: {
    ...mapState([
      "facebookLanguages"
    ]),
    showExcludedTargets () {
      return this.mergerdExcludedTargets;
    },
    exclusionBtnSelected () {
      return { "button-selected": this.showExcludeBrowseData };
    },
    disableIcons () {
      if (this.targetMode === "New") {
        return false;
      }
      return true;
    }
  },
  methods: {
    ...mapMutations(["set_facebookLanguages"]),
    buttonSelected (index) {
      return { "button-selected": this.showIncludeBrowseData[index] };
    },
    loadSpecData () {
      if (this.targetData && Object.keys(this.targetData).length) {
        this.$set(this, 'selectedMinAge', this.targetData.minAge);
        this.$set(this, 'selectedMaxAge', this.targetData.maxAge);
        let genderProperties = Object.keys(fbEnums.Genders);
        genderProperties.forEach((property) => {
          if (this.arraysEqual(fbEnums.Genders[property], this.targetData.gender)) {
            this.$set(this, "selectedGender", property);
          }
        });
        this.getLanguages();
        this.$set(this, "selectedLanguages", this.targetData.locales);
        this.$set(this, "selectedRelationshipStatus", this.targetData.relationship_statuses);
        if (this.targetData.custom_audiences.length) {
          this.$set(this, "searchedCustomAudiences", this.targetData.custom_audiences);
          let customAudIds = this.targetData.custom_audiences.map((item) => item.id);
          this.$set(this, "includedCustomAudiences", customAudIds);
          this.canSetCustomAud = true;
        } else {
          this.$set(this, "includedCustomAudiences", []);
        }
        if (this.targetData.excluded_custom_audiences.length) {
          this.$set(this, "searchedExcludeCustomAudiences", this.targetData.excluded_custom_audiences);
          let customExcludeAudIds = this.targetData.excluded_custom_audiences.map((item) => item.id);
          this.$set(this, "excludedCustomAudiences", customExcludeAudIds);
          this.showExclusionsAudience = true
        } else {
          this.showExclusionsAudience = false
          this.$set(this, "excludedCustomAudiences", []);
        }
        this.mergedIncludedTargets = [[]]
        this.includedTargetsIds = [[]]
        this.selectedTargets = [[]]
        for (let i = 0; i < this.targetData.flexible_spec.length; i++) {
          if (this.targetData.flexible_spec[i]) {
            this.mergedIncludedTargets[i] = []
            this.includedTargetsIds[i] = []
            this.selectedTargets[i] = []
            for (let property in this.targetData.flexible_spec[i]) {
              if (property !== 'custom_audiences') {
                this.targetData.flexible_spec[i][property].forEach((item) => {
                  this.mergedIncludedTargets[i].push(item);
                  this.selectedTargets[i].push(JSON.stringify(item));
                  this.includedTargetsIds[i].push(item.id);
                })
              }
            }
          }
        }
        this.mergerdExcludedTargets = []
        if (this.targetData.exclusions) {
          for (let property in this.targetData.exclusions) {
            if (property !== 'custom_audiences') {
              this.targetData.exclusions[property].forEach((item) => {
                this.mergerdExcludedTargets.push(item);
                this.showExclusions = true;
              })
            }
          }
        }
      }
    },
    arraysEqual (arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    },
    createCategories () {
      let categories = {};
      this.browse.forEach(item => {
        let currentLevel = categories;
        for (let i = 0; i < item.path.length; i++) {
          if (!currentLevel[item.path[i]]) {
            currentLevel[item.path[i]] = {
              name: item.path[i],
              subcategories: {},
              items: []
            };
          }
          if (i === item.path.length - 1) {
            item.isChecked = false;
            currentLevel[item.path[i]].items.push(item);
          }
          currentLevel = currentLevel[item.path[i]].subcategories;
        }
      });
      this.categories = Object.values(categories).map(category => {
        category.subcategories = Object.values(category.subcategories);
        return category;
      });
      return categories;
    },
    async showBrowseMenu (index) {
      this.$set(this.loadingBrowseData, index, true);
      try {
        if (!this.browse.length) {
          var response = await APIService.getTargetingBrowse(this.bpseId);
          this.browse = response;
          this.createCategories();
        }
      } catch (error) {
        console.log(error);
      }
      this.$set(this.loadingBrowseData, index, false);
      if (this.showIncludeBrowseData[index]) {
        this.showIncludeBrowseData[index] = false
      } else {
        this.showIncludeBrowseData[index] = true;
        this.showIncludeBrowseData.forEach(() => {
          for (let i = 0; i < this.showIncludeBrowseData.length; i++) {
            if (i !== index) {
              this.showIncludeBrowseData[i] = false;
            }
          }
        })
      }
      this.showExcludeBrowseData = false;
    },
    async showExclusionBrowseMenu () {
      this.loadingExclusionBrowseData = true;
      try {
        if (!this.browse.length) {
          var response = await APIService.getTargetingBrowse(this.bpseId);
          this.browse = response;
          this.createCategories();
        }
      } catch (error) {
        console.log(error);
      }
      this.loadingExclusionBrowseData = false;
      this.showExcludeBrowseData = !this.showExcludeBrowseData;
      for (let i = 0; i < this.showIncludeBrowseData.length; i++) {
        this.showIncludeBrowseData[i] = false;
      }
    },
    async getLanguages () {
      this.loadingLanguages = true
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(async () => {
        try {
          if (!this.facebookLanguages.length) {
            let response = await APIService.getFacebookLanguages();
            this.set_facebookLanguages(response.data);
          }
          this.loadingLanguages = false;
        } catch (error) {
          console.log(error);
        }
      }, 500);
    },
    searchCustomAudiences () {
      if (!this.searchedCustomAudiences.length) {
        this.setCustomAudiences();
      } else if (this.canSetCustomAud) {
        this.canSetCustomAud = false;
        this.setCustomAudiences();
      }
    },
    async setCustomAudiences () {
      this.loadingCustomAudience = true;
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(async () => {
        try {
          let response = await APIService.getCustomAudiences(this.bpseId, 500);
          this.searchedCustomAudiences = response;
          this.searchedExcludeCustomAudiences = response;
          this.loadingCustomAudience = false;
        } catch (error) {
          console.log(error);
        }
      }, 500);
    },
    async searchTargets (query, isExclusion) {
      clearTimeout(this.searchTimeout);
      if (query !== '') {
        this.loadingSearchTargets = true;
        this.searchTimeout = setTimeout(() => {
          APIService.searchTargetingBrowse(this.bpseId, query)
            .then(response => {
              if (isExclusion) {
                let excludeTargets = ["family_statuses", "income", "relationship_statuses"]
                this.searchedTargets = response.filter((target) => !excludeTargets.includes(target.type));
              } else {
                this.searchedTargets = response;
              }
            })
            .catch(error => {
              console.error(error);
            }).finally(() => {
              this.loadingSearchTargets = false;
            })
        }, 500);
      }
    },
    async giveSuggestions (index) {
      var target = this.mergedIncludedTargets[index][this.mergedIncludedTargets[index].length - 1];
      this.loadingSearchTargets = true;
      var payload = [{ 'id': target.id, 'type': target.type }]
      try {
        let response = await APIService.getTargetSuggestions(
          this.bpseId,
          payload
        );
        this.searchedTargets = response;
        this.loadingSearchTargets = false;
        this.$refs.selectTargetsField[index].$el.querySelector("input").click();
      } catch (error) {
        console.log(error);
      }
    },
    getCheckedTarget (value, type) {
      value.isChecked = !value.isChecked;
      if (type.name === 'include') {
        let targetIndex = this.mergedIncludedTargets[type.index].findIndex(
          target => target.id === value.id
        );
        if (targetIndex < 0) {
          value.isChecked = true;
          this.mergedIncludedTargets[type.index].push(value);
          this.mergedIncludedTargets = [...this.mergedIncludedTargets]
          if (!this.includedTargetsIds[type.index]) {
            this.includedTargetsIds[type.index] = []
            this.selectedTargets[type.index] = []
          }
          this.includedTargetsIds[type.index].push(value.id);
          this.selectedTargets[type.index].push(JSON.stringify(value));
          this.storeDetailedTargets(type.index);
        } else {
          this.deleteIncludedTarget(targetIndex, value, type.index);
        }
      } else if (type.name === 'exclude') {
        let targetIndex = this.mergerdExcludedTargets.findIndex(
          target => target.id === value.id
        );
        if (targetIndex < 0) {
          value.isChecked = true;
          this.mergerdExcludedTargets.push(value);
          this.excludedTargetsIds.push(value.id);
          this.storeExcludedDetailedTargets();
        } else {
          this.deleteExcludedTarget(targetIndex, value);
        }
      }
    },
    storeParsedTarget (type) {
      if (type.name === 'include') {
        this.includedTargetsIds[type.index] = this.selectedTargets[type.index].map(target => {
          let parsedTarget = JSON.parse(target);
          return parsedTarget.id;
        });
        this.includedTargetsIds[type.index].forEach(id => {
          let target = this.searchedTargets.find(target => target.id === id);
          if (target) {
            let targetIndex = this.mergedIncludedTargets[type.index].findIndex(
              t => target.id === t.id
            );
            if (targetIndex < 0) {
              this.mergedIncludedTargets[type.index].push(target);
              this.storeDetailedTargets(type.index);
            }
          }
        });
      } else if (type.name === 'exclude') {
        this.excludedTargetsIds = this.excludedTargets.map(target => {
          let parsedTarget = JSON.parse(target);
          return parsedTarget.id;
        });
        this.excludedTargetsIds.forEach(id => {
          let target = this.searchedTargets.find(target => target.id === id);
          if (target) {
            let targetIndex = this.mergerdExcludedTargets.findIndex(
              t => target.id === t.id
            );
            if (targetIndex < 0) {
              this.mergerdExcludedTargets.push(target);
              this.storeExcludedDetailedTargets();
            }
          }
        });
      }
    },
    storeMinAge (value) {
      this.$store.dispatch("set_AgeMin", { data: value, index: this.currentComponentKey });
    },
    storeMaxAge (value) {
      this.$store.dispatch("set_AgeMax", { data: value, index: this.currentComponentKey });
    },
    storeGender (value) {
      if (value === "Male") {
        this.$store.dispatch("set_Gender", { data: fbEnums.Genders.Male, index: this.currentComponentKey });
      }
      if (value === "Female") {
        this.$store.dispatch("set_Gender", { data: fbEnums.Genders.Female, index: this.currentComponentKey });
      }
      if (value === "All") {
        this.$store.dispatch("set_Gender", { data: fbEnums.Genders.All, index: this.currentComponentKey });
      }
    },
    storeLanguages () {
      this.$store.dispatch("set_Languages", { data: this.selectedLanguages, index: this.currentComponentKey });
    },
    storeRelationshipStautses (value) {
      this.$store.dispatch("set_RelationshipStatuses", {
        data: value,
        index: this.currentComponentKey
      });
    },
    storeIncludedCustomAudiences () {
      if (this.includedCustomAudiences.length < this.includedCustomAudiencesObj.length) {
        this.includedCustomAudiencesObj = []
      }
      this.includedCustomAudiences.forEach((id) => {
        let audIndex = this.includedCustomAudiencesObj.findIndex((aud) => aud.id === id)
        if (audIndex === -1) {
          let audienceObj = this.searchedCustomAudiences.find(audience => audience.id === id)
          if (audienceObj) {
            this.includedCustomAudiencesObj.push({ "id": audienceObj.id, "name": audienceObj.name });
          }
        }
      });
      this.$store.dispatch("set_IncludedCustomAudience", {
        data: this.includedCustomAudiencesObj,
        index: this.currentComponentKey
      });
    },
    storeExcludedCustomAudiences () {
      if (this.excludedCustomAudiences.length < this.excludedCustomAudiencesObj.length) {
        this.excludedCustomAudiencesObj = []
      }
      this.excludedCustomAudiences.forEach((id) => {
        let audIndex = this.excludedCustomAudiencesObj.findIndex((aud) => aud.id === id)
        if (audIndex === -1) {
          let audienceObj = this.searchedExcludeCustomAudiences.find(audience => audience.id === id)
          if (audienceObj) {
            this.excludedCustomAudiencesObj.push({ "id": audienceObj.id, "name": audienceObj.name });
          }
        }
      });
      this.$store.dispatch("set_ExcludedCustomAudience", {
        data: this.excludedCustomAudiencesObj,
        index: this.currentComponentKey
      });
    },
    storeDetailedTargets (ind) {
      this.$store.dispatch("set_FlexibleSpec", {
        data: this.mergedIncludedTargets[ind],
        index: this.currentComponentKey,
        narrowIndex: ind
      });
    },
    storeExcludedDetailedTargets () {
      this.$store.dispatch("set_Exclusions", {
        data: this.mergerdExcludedTargets,
        index: this.currentComponentKey
      });
    },
    deleteIncludedTarget (index, item, narrowInd) {
      item.isChecked = false;
      if (this.includedTargetsIds[narrowInd]) {
        let targetIndex = this.includedTargetsIds[narrowInd].findIndex(
          targetId => targetId === item.id
        );
        if (targetIndex >= 0) {
          this.selectedTargets[narrowInd].splice(targetIndex, 1);
        }
      }
      this.$set(
        this.mergedIncludedTargets,
        narrowInd,
        this.mergedIncludedTargets[narrowInd].filter((_, i) => i !== index)
      );
      this.$store.dispatch("remove_TargetFromFlexibleSpec", {
        data: item,
        index: this.currentComponentKey,
        narrowIndex: narrowInd
      });
    },
    deleteExcludedTarget (index, item) {
      let targetIndex = this.excludedTargetsIds.findIndex(
        targetId => targetId === item.id
      );
      if (targetIndex >= 0) {
        this.excludedTargetsIds.splice(targetIndex, 1);
      }
      this.mergerdExcludedTargets.splice(index, 1);
      this.$store.dispatch("remove_TargetFromExclusion", {
        data: item,
        index: this.currentComponentKey
      });
    },
    isOptionDisabled (lowerBound, upperBound) {
      if (lowerBound <= 1000 || upperBound <= 1000) {
        return true;
      }
      return false;
    },
    narrowFurther () {
      this.mergedIncludedTargets.push([]);
    },
    getSelectedLanguagesString () {
      let languagesPreview = [];
      this.selectedLanguages.forEach(key => {
        this.facebookLanguages.forEach((language) => {
          if (language.key === key) {
            languagesPreview.push(language.name);
          }
        })
      });
      return languagesPreview.join(', ');
    },
    saveBulkInterests (data, index) {
      if (data.mode == "Overwrite") {
        this.selectedTargets[index] = [];
        this.$set(this.mergedIncludedTargets, index, data.data);
      } else {
        data.data.forEach((interest) => {
          if (!this.mergedIncludedTargets[index].some(existingInterest => existingInterest.id === interest.id)) {
            this.mergedIncludedTargets[index].push(interest);
          }
        });
        this.$set(this.mergedIncludedTargets, index, [...this.mergedIncludedTargets[index]]);
      }
      this.storeDetailedTargets(index);
    }
  }
};
</script>

<style scoped>
::v-deep .ivu-radio-focus {
  box-shadow: none !important;
}
.browse-target::v-deep .ivu-tag {
  display: none;
}
.browse-target::v-deep .ivu-select-item-selected:after {
  display: none;
}
.browse-target::v-deep .ivu-select-item-selected {
  font-weight: normal;
  color: #515a6e;
}
.browse-target::v-deep .ivu-select-item-focus{
  background-color:white
}
.content-bg {
  display: flex;
  background-color: white;
  color: gray;
}
::v-deep .ivu-checkbox-wrapper {
  width: 65%;
  margin-left: 35px;
}
::v-deep .ivu-checkbox-wrapper-checked {
  font-weight: bold;
  color: #000000;
}
::v-deep .ivu-checkbox-checked {
  color: #000000;
  font-weight: bold;
}
.content-text {
  margin-top: 9px;
}
::v-deep .ivu-radio-group-button .ivu-radio-wrapper {
  display: inline-block;
  height: 32px;
  line-height: 30px;
  margin: 0;
  padding: 0 15px;
  font-size: 13px;
  color: gray !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 1px solid #dcdee2 !important;
  background: #fff;
  position: relative;
  border-radius: 0px !important;
}

::v-deep .ivu-select-multiple .ivu-tag {
  height: 24px;
  line-height: 22px;
  margin: 3px 4px 3px 0;
  max-width: 99%;
  position: relative;
}
::v-deep .ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #ebebeb;
  -webkit-box-shadow: 0 0 0 0 #cccccc;
  box-shadow: 0 0 0 0 #d7d7d7;
  z-index: 1;
}
::v-deep .ivu-radio-group-button .ivu-radio-wrapper:hover {
  color: #292929;
  background: #e5e5e5;
}
::v-deep .ivu-select-head-flex {
  display: flex;
}
::v-deep .ivu-select-item {
  font-size: 13px !important;
}
::v-deep .ivu-select-prefix {
  padding: 5px;
}
::v-deep .ivu-collapse {
  width: 95%;
}
::v-deep .ivu-icon-ios-arrow-forward {
  display: none;
}
::v-deep .ivu-collapse-header {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
}
::v-deep .ivu-collapse-content {
  border-top: 2px solid var(--main-bg-color);
}
::v-deep .ivu-btn>span {
  font-size: 13px;
}
::v-deep .ivu-select-selection {
  height: 32.4px;
}

::v-deep .ivu-col-span-12 {
  height: 31px;
}

.browse-data::v-deep .ivu-collapse-header {
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}
.browse-data::v-deep .ivu-icon-ios-arrow-forward {
  display: flex;
  align-items: center;
}
.browse-data::v-deep .ivu-collapse {
  background-color: white;
  border: 0;
  border-radius: 0;
}
.browse-data::v-deep .ivu-collapse-content {
  padding: 0px;
  border-top: 0px;
}
.browse-data::v-deep .ivu-collapse-content-box {
  padding-top: 0px;
  padding-bottom: 0px;
}
.browse-data::v-deep .ivu-collapse-item.ivu-collapse-item-active > .ivu-collapse-header {
  display: flex;
  border-bottom: 0;
}
.browse-data::v-deep .ivu-collapse-item {
  margin-left: 15px;
}
.browse-data::v-deep .ivu-checkbox-wrapper {
  font-size: 13px !important;
}
.card-body-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  width: 30%;
}
.title-sub-text {
  font-weight: normal;
  margin: 0;
  color: #000000;
}
.title-text {
  font-weight: bold;
  margin: 0;
  color: #000000;
}
.title-icons {
  margin: 2px 8px;
  padding: 8px 8px;
}
.icons {
  font-size: 13px;
  cursor: pointer;
  color: gray;
}
.card-block {
  margin-bottom: 20px;
  display: flex;
}
#title-area {
  width: 80%;
}
#icon-area {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}
#card-title {
  display: flex;
  flex-grow: 1;
  cursor: pointer;
}
#target-detailTargeting-comp {
  font-family: "sans-serif", sans-serif;
  font-size: 13px;
  font-weight: normal;
  color: #000000;
  margin: 5px 30px;
}
.selected-target-icon {
  margin: 2px 4px;
  padding: 20px 10px;
  float: inline-end;
}
.selected-target-data {
  margin: 6px 4px;
  padding: 3px 4px;
  display: block;
  width: 95%;
  color: grey;
}
.selected-target-box:hover {
  background: #f2f2f2;
  cursor: pointer;
}
.selected-target-box {
  border: 1px solid #dcdee2;
  display: flex;
}
.button-selected {
  background-color: #f2f2f2;
}
.card-block-display {
  display: block;
}
.browse-data {
  border: 1px solid #dcdee2;
  margin-top: 4px;
}
.select-width {
  width: 600px;
}
.btn-space{
  margin-right: 5px;
}
.custom-audience {
  width: 100%;
  margin-top: 5px
}
.card-body-label-width {
  width: 15%;
}
.exclude-btn {
  margin-top: 5px;
}
.btn-width {
  width: 100%;
}
.btn-width:focus {
  box-shadow: none !important;
}
.badge {
  float: right;
}
.age-width {
  width: 50%;
}
.icon-text {
  margin-left: 7px;
}
.target-path{
  color: lightgray;
  font-size: 12px;
}
.disabled-icon {
  cursor: not-allowed;
  color: lightgray;
}
.estimate-reach{
  align-self: center;
}
</style>
