<template>
    <div class="hot-div" style="width: 100%; height: 530px; overflow: hidden;" :key="rerenderKey">
      <div v-show="isEditDataFetchComplete && htData.length < 1" class="alert alert-info">
        No published ads were found.
      </div>
      <div v-show="!isEditDataFetchComplete" class="hot-spinner">
        <i class="fa fa-lg fa-spinner fa-spin" />
      </div>
      <hot-table v-show="isEditDataFetchComplete" class="ad-sheet-hot" ref="hotEdit" :settings="hotSettings"></hot-table>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex"
import { HotTable } from "@handsontable/vue"
import { columnWidths } from "../../columnConfig.js"
import Handsontable from 'handsontable'
import * as molocoEnums from "../../Constants/MolocoEnums.js";

export default {
  components: {
    "hot-table": HotTable
  },
  props: {
    tab: { default: '' },
    creativeSpec: { default: function () { return {} } },
    colHeaders: { default: function () { return [] } }, // ["HOT_Ad Name", "HOT_Call To Action"]
    displayColHeaders: { default: function () { return [] } }, // ["Ad Name", "Call To Action"]
    requiredCols: { default: function () { return {} } },
    creativeHeaders: { default: function () { return {} } },
    isEditDataFetchComplete: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      htData: [],
      hotSettings: {},
      startRows: 0,
      uniqueId: null,
      publishedRows: [],
      createSheetCreativesSize: 0,
      previewable: {},
      hardcodedCardsCount: 8,
      rerenderKey: 0,
      availableWidth: 0,
      alreadyProcessed: {},
      mainTableData: {},
      colNameIndexMap: this.colHeaders.reduce(
        (obj, colName, colIndex) => {
          obj[colName] = colIndex
          return obj
        },
        {}
      ),
      bulkUpdateQueries: {},
      molocoEnums,
      publishedRowIds: [],
      hotIndexIdMapper: {},
      hotEventKeys: [],
      mappedTargets: []
    }
  },
  computed: {
    ...mapState([
      "builtImageIdUrlMap",
      "campaignLauncherConfigs",
      "imageIdUrlMap",
      "mappedLineItems",
      "createSheetCreatives",
      "selectedCampaignLauncherConfig",
      "selectedCampaignLauncherConfigId",
      "selectedFbObjective",
      "mediaPlanId",
      "mediaPlans",
      "publisher",
      "connectedInstagramAccountId",
      "tableData",
      "imagesData",
      "existingTweets",
      "allCreativesInfo",
      "editSheetCreatives",
      "savedTargets"
    ]),
    ...mapGetters(["getMediaPlanById"]),
    molocoEvents () {
      return Object.keys(this.molocoEnums.events)
    }
  },
  created () {
    const { count, fieldMacros } = this.molocoEnums.eventTracking
    this.hotEventKeys = Array.from(new Array(count), (_, i) => i + 1).reduce((l, e) => [...l, `HOT_${fieldMacros[0].split("$").join(e)}`], [])
    this.selectedMediaPlanDetails = this.getMediaPlanById(this.mediaPlanId)
    this.hotSettings = this.buildHotSettings()
  },
  mounted () {
    this.availableWidth = document.querySelector('.hot-div').offsetWidth
  },
  watch: {
    editSheetCreatives: {
      immediate: true,
      handler: function () {
        this.previewable = {}
        this.publishedRows = []
        let newHtData = [];
        if (this.editSheetCreatives[this.tab] && this.editSheetCreatives[this.tab].length) {
          let publishedRowObjects = JSON.parse(JSON.stringify(this.editSheetCreatives[this.tab]))
          publishedRowObjects.forEach((rowObject, rowIndex) => {
            this.hotEventKeys.forEach(hotEventKey => {
              if (rowObject[hotEventKey]) {
                rowObject[hotEventKey] = this.getKeyByValue(this.molocoEnums.events, rowObject[hotEventKey])
              }
            })
            let htRow = []
            this.colHeaders.forEach(header => {
              if (header in rowObject) {
                if (header == 'HOT_Status') {
                  rowObject[header] = this.molocoEnums.AdStatus[rowObject[header]]
                }
                htRow.push(rowObject[header])
              } else {
                htRow.push(null)
              }
            })
            newHtData.push(htRow)
            htRow.forEach((cellData, colIndex) => {
              this.updatePreviewColumn(rowIndex, colIndex, null, cellData, null);
            });
          })
          this.publishedRows = JSON.parse(JSON.stringify(newHtData))
        } else {
          for (let i = 0; i < this.startRows; i++) {
            newHtData.push(Array(this.colHeaders.length).fill(null))
          }
        }
        this.htData = newHtData;
        var currentData = this.tableData
        if (currentData == null) {
          currentData = {}
        }
        newHtData == null ? currentData[this.tab] = [] : currentData[this.tab] = newHtData;
        this.set_tableData(currentData);

        this.$nextTick(() => {
          let updatedSettings = {
            data: this.htData
          }

          // If handsontable width is less than available width, stretch all cols equally except 1st col(preview ad)
          let totalColWidth = [...Array(this.colHeaders.length).keys()]
            .map(index => this.getColWidthByIndex(index))
            .reduce((accu, curVal) => accu + Number(curVal), 0)
          if (totalColWidth < this.availableWidth) {
            let equalSize = (this.availableWidth - 100) / (this.colHeaders.length - 1)
            updatedSettings['colWidths'] = [100, ...Array(this.colHeaders.length - 1).fill(equalSize)]
          }
          if (this.$refs['hotEdit'] && this.$refs['hotEdit'].hotInstance) {
            this.$refs['hotEdit'].hotInstance.updateSettings(updatedSettings)
          }
        })
      }
    },
    builtImageIdUrlMap () {
      if (this.builtImageIdUrlMap) {
        this.rerender()
      }
    },
    mappedLineItems: {
      immediate: true,
      deep: true,
      handler: function (val) {
        if (val && val.length) {
          let lineItemTargets = [];
          this.mappedTargets = [];
          this.mappedLineItems.forEach(element => {
            if (element.config === this.selectedCampaignLauncherConfigId) {
              element.targets.forEach(target => {
                if (!lineItemTargets.includes(target)) {
                  lineItemTargets.push(target);
                }
              });
            }
          });
          this.mappedTargets = this.savedTargets.filter(target => lineItemTargets.includes(target.id));
        }
      }
    }
  },
  methods: {
    ...mapMutations(["set_tableData"]),
    rerender () {
      this.$nextTick(() => {
        if (this.$refs['hotEdit'] && this.$refs['hotEdit'].hotInstance) {
          this.$refs['hotEdit'].hotInstance.render()
        }
      })
    },
    imageColRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (this.publishedRowIds.includes(row)) {
        td.classList.add('disabled-cell')
      }
      value = Handsontable.helper.stringify(value)
      let img = td.querySelector("img")
      if (value in this.imageIdUrlMap) {
        if (img && img.src == this.imageIdUrlMap[value]) {
          return td
        }
        img = document.createElement('IMG')
        if (value.split('-')[0] === 'DXC') {
          img.src = this.imageIdUrlMap[value]['src']
        } else {
          img.src = this.imageIdUrlMap[value]['src']
        }
        img.classList.add('hot-img')
        Handsontable.dom.addEvent(img, 'mousedown', function (event) {
          event.preventDefault()
        })

        Handsontable.dom.empty(td)
        td.appendChild(img)
      } else if (value.includes("http") || value.includes("www")) {
        if (img && img.src == value) {
          return td
        }
        img = document.createElement('IMG')
        img.src = value;
        img.classList.add('hot-img')
        Handsontable.dom.addEvent(img, 'mousedown', function (event) {
          event.preventDefault()
        })
        Handsontable.dom.empty(td)
        td.appendChild(img)
      } else {
        Handsontable.renderers.TextRenderer.apply(this, arguments)
      }
      if (cellProperties.className) td.classList.add(cellProperties.className);
      return td
    },
    adStatusRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (value === null || value == '') {
        instance.setDataAtCell(row, col, 'DISABLE');
      }
      cellProperties.type = 'dropdown'
      cellProperties.source = ['ENABLE', 'DISABLE']
      cellProperties.allowInvalid = false
      let previewTdWrapper = document.createElement('div');
      previewTdWrapper.classList.add('preview-td-wrapper');
      let previewIcon = document.createElement('span');
      previewIcon.classList.add('adstatus-span');
      if (value == 'ENABLE') {
        previewIcon.innerHTML = '<i class="fa fa-play"></i>';
      } else if (value == 'DISABLE') {
        previewIcon.innerHTML = '<i class="fa fa-pause"></i>';
      }
      Handsontable.dom.addEvent(previewTdWrapper, 'mousedown', function (event) {
        event.preventDefault();
      });
      Handsontable.dom.empty(td);
      previewTdWrapper.appendChild(previewIcon);
      td.appendChild(previewTdWrapper);
      /* else {
        if (value == 'ENABLE' && td.innerHTML.includes('fa fa-pause')) {
          td.firstElementChild.firstElementChild.innerHTML = '<i class="fa fa-play"></i>'
        }
        if (value == 'DISABLE' && td.innerHTML.includes('fa fa-play')) {
          td.firstElementChild.firstElementChild.innerHTML = '<i class="fa fa-pause"></i>'
        }
      } */
      return td
    },
    previewBtnRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (!(td.firstElementChild)) {
        td.innerHTML = "";
        let previewTdWrapper = document.createElement('div')
        previewTdWrapper.classList.add('preview-td-wrapper')
        let previewIcon = document.createElement('span')
        previewIcon.classList.add('eye')
        previewIcon.innerHTML = '<i class="fa fa-check preview-icon"></i>'
        previewTdWrapper.appendChild(previewIcon)
        td.appendChild(previewTdWrapper)
      }
      if (value !== "true" && value !== true) {
        td.firstElementChild.innerHTML = '<span class="eye"><i class="fa fa-times preview-icon"></i></span>'
      } else {
        td.firstElementChild.innerHTML = '<span class="eye"><i class="fa fa-check preview-icon"></i></span>'
      }
      cellProperties.readOnly = 'true';
      cellProperties.copyable = 'false';
      td.classList.add('trim')
      return td
    },
    disableCellRenderer (instance, td, row, col, prop, value, cellProperties) {
      cellProperties.readOnly = 'true'
      td.classList.add('disabled-cell-text')
      return td;
    },
    dropdownCellRenderer (instance, td, row, col, prop, value, cellProperties) {
      cellProperties.type = 'dropdown';
      cellProperties.source = this.molocoEvents;
      cellProperties.allowInvalid = false;
      return td;
    },
    checkIfReq (col) {
      if (this.colHeaders[col] === undefined) { return [false, -1] }
      let colName = this.colHeaders[col]
      if (col < this.requiredCols['baseLength']) {
        return (this.requiredCols['base'].has(colName)) ? [true, -1] : [false, -1]
      }
      let space = colName.lastIndexOf(' ')
      if (this.requiredCols['card'].has(colName.substring(0, space))) {
        let cardIndex = Math.floor((col - this.requiredCols['baseLength']) / this.requiredCols['cardLength'])
        return [true, cardIndex]
      }
      return [false, -1]
    },
    checkIfPreviewable (previewRow) {
      let validCard =
        previewRow["card"].filter((x) => x >= this.requiredCols["card"].size)
          .length > 1;
      let validBase = previewRow["base"] >= this.requiredCols["base"].size;
      return (validCard && validBase);
    },
    createNewPreviewableObj () {
      return {
        'validCard': false,
        'validBase': false,
        'card': new Array(this.hardcodedCardsCount).fill(0),
        'base': 0
      }
    },
    getColWidthByIndex (index) {
      if (this.colHeaders[index] === "HOT_Ad Id" || this.colHeaders[index] === "HOT_Campaign Name" || this.colHeaders[index] === "HOT_Adgroup Name") {
        return "160";
      }
      var colWidthByPublisher = columnWidths(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config);
      for (let [width, headerNames] of Object.entries(colWidthByPublisher)) {
        if (width === "default") { continue }
        for (let headerName of headerNames) {
          if (this.colHeaders[index].indexOf(headerName) != -1) {
            return width
          }
        }
      }
      return colWidthByPublisher["default"]
    },
    processImageUrl (row, col, oldVal, newVal, instance) {
      let img = new Image();
      img.onload = () => {
        this.alreadyProcessed[`${row}-${col}`] = true;
        instance.setDataAtCell(row, col, newVal);
      };
      img.onerror = () => {
        this.$Message.error({
          background: true,
          content: 'Please enter a valid image URL'
        })
      };
      img.src = newVal;
    },
    getMainTableData (rowNum) {
      const instance = this.$refs['hotEdit'].hotInstance;
      const rowData = instance.getDataAtRow(rowNum);
      let dataObj = {}
      for (let i = 0; i < rowData.length; i++) {
        dataObj[this.colHeaders[i]] = rowData[i]
      }
      return dataObj;
    },
    setHotCellData (row, col, value, instance, lazyUpdate) {
      if (lazyUpdate) {
        this.bulkUpdateQueries[[row, col]] = [row, col, value]
      } else {
        setTimeout(() => { instance.setDataAtCell(row, col, value) })
      }
    },
    updatePreviewColumn (row, col, oldVal, newVal, instance, lazyUpdate = false) {
      if (!(row in this.previewable)) {
        this.previewable[row] = this.createNewPreviewableObj()
      }
      let [required, cardIndex] = this.checkIfReq(col)
      if (required) {
        if (!oldVal && newVal) {
          if (cardIndex != -1) {
            this.previewable[row]['card'][cardIndex] += 1
          } else {
            this.previewable[row]['base'] += 1
          }
        } else if (oldVal && !newVal) {
          if (cardIndex != -1) {
            this.previewable[row]['card'][cardIndex] -= 1
          } else {
            this.previewable[row]['base'] -= 1
          }
        }
        if (instance) {
          const isPreviewable = this.checkIfPreviewable(this.previewable[row]);
          if (isPreviewable && !(this.htData[row] && this.htData[row][0] === 'true')) {
            this.setHotCellData(row, 0, 'true', instance, lazyUpdate)
          } else if (!isPreviewable && !(this.htData[row] && this.htData[row][0] === 'false')) {
            this.setHotCellData(row, 0, 'false', instance, lazyUpdate)
          }
        }
      }
    },
    buildHotSettings () {
      var self = this;
      return {
        data: this.htData,
        startCols: this.colHeaders.length,
        startRows: this.startRows,
        manualColumnResize: true,
        autoRowSize: true,
        autoColumnSize: true,
        viewportColumnRenderingOffset: 35,
        colHeaders: function (index) {
          var header = self.colHeaders[index];
          var displayName = self.displayColHeaders[index];
          var displayText = displayName;
          if (self.requiredCols.base.has(header)) {
            displayText += "<span style='color:#b94a48'>*</span>";
          }
          return displayText;
        },
        rowHeights: 74,
        // stretchH: 'all',
        colWidths: this.getColWidthByIndex,
        // comments: true,
        afterRenderer: (td, row, col, prop, value, cellProperties) => {
          let instance = this.$refs['hotEdit'].hotInstance
          if (col == 0) {
            this.previewBtnRenderer(instance, td, row, col, prop, value, cellProperties);
          }
          if (this.colHeaders[col] == "HOT_Status") {
            this.adStatusRenderer(instance, td, row, col, prop, value, cellProperties);
          }
          if (this.colHeaders[col] == "HOT_Campaign Name" || this.colHeaders[col] == "HOT_Adgroup Name" || this.colHeaders[col] == "HOT_Ad Id" || this.colHeaders[col] == "HOT_Ad Target") {
            this.disableCellRenderer(instance, td, row, col, prop, value, cellProperties);
          }
          if (this.hotEventKeys.includes(this.colHeaders[col])) {
            this.dropdownCellRenderer(instance, td, row, col, prop, value, cellProperties);
          }
          if (this.colHeaders[col] === "HOT_Asset" || this.colHeaders[col] === "HOT_App Icon" || this.colHeaders[col] === "HOT_Image Banner" || this.colHeaders[col] === "HOT_Image" || this.colHeaders[col] === "HOT_Video" || this.colHeaders[col] === "HOT_Image/Video") {
            this.imageColRenderer(instance, td, row, col, prop, value, cellProperties);
          }
          if (this.colHeaders[col].indexOf("HOT_Ad Target") != -1) {
            this.targetNameRenderer(instance, td, row, col, prop, value, cellProperties);
          }
        },
        beforeRenderer: (td, row, col, prop, value, cellProperties) => {},
        columns: (index) => {
          if (index >= this.colHeaders.length) { return }
          let settings = {}
          if (
            this.colHeaders[index].indexOf("HOT_Image/Video") != -1 ||
            this.colHeaders[index].indexOf("HOT_Image") != -1 ||
            this.colHeaders[index].indexOf("HOT_Video") != -1 ||
            this.colHeaders[index].indexOf("HOT_Intro Card: Image") != -1 ||
            this.colHeaders[index].indexOf("HOT_App Icon") != -1 ||
            this.colHeaders[index].indexOf("HOT_Asset") != -1 ||
            this.colHeaders[index].indexOf("HOT_Image Banner") != -1
          ) {
            settings.renderer = this.imageColRenderer;
          } else if (this.colHeaders[index] == "HOT_Call To Action") {
            settings.source = this.callToAction
          } else if (this.colHeaders[index].indexOf("HOT_Landing Page URL") != -1) {
            settings.validator = this.validateUrls
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Targets") {
            settings.allowInvalid = true
          } else if (this.colHeaders[index] == "HOT_Ad Target") {
            settings.renderer = this.targetNameRenderer;
          } else if (this.colHeaders[index] == "HOT_JSON") {
            settings.validator = this.validateJson
            settings.allowInvalid = false
          }
          return settings
        },
        cells: (row, col, prop) => {
          let cellProperties = {}
          if (this.editSheetCreatives[this.tab] && this.publishedRowIds.includes(row)) {
            cellProperties.readOnly = 'true'
            cellProperties.className = 'disabled-cell-text'
          }
          return cellProperties
        },
        beforePaste: (data, coords) => {
        },
        beforeColumnResize: (newSize, column, isDoubleClick) => {
          return isDoubleClick && newSize > window.innerWidth ? window.innerWidth : newSize
        },
        beforeAutofill: (st, ed, data) => {
        },
        beforeChange: (changes, source) => {
          let instance = this.$refs['hotEdit'].hotInstance
          let requireRerender = false;
          this.bulkUpdateQueries = {};
          for (let index = 0; index < changes.length; index++) {
            let change = changes[index];
            let [row, col, oldVal, newVal] = change;
            if (this.publishedRowIds.includes(row) || col >= this.colHeaders.length) {
              return;
            }
            if (this.colHeaders[col] === "HOT_Landing Page URL") {
              let errorMessage = null
              if (!this.validateUrl(newVal)) {
                errorMessage = "Please enter a valid URL."
              }
              if (errorMessage !== null) {
                this.$Message.error({
                  background: true,
                  content: errorMessage
                })
                changes[index] = null
                continue;
              }
            }
            if (this.colHeaders[col] === "HOT_JSON") {
              let errorMessage = null
              if (!this.isValidJson(newVal)) {
                errorMessage = "Please enter valid JSON"
              }
              if (errorMessage !== null) {
                this.$Message.error({
                  background: true,
                  content: errorMessage
                })
                changes[index] = null
                continue;
              }
            }
            if (
              newVal &&
              (this.colHeaders[col].indexOf("HOT_Image/Video") != -1 ||
              this.colHeaders[col].indexOf("HOT_Video") != -1 ||
              this.colHeaders[col].indexOf("HOT_Image") != -1 ||
              this.colHeaders[col].indexOf("HOT_Asset") != -1 ||
              this.colHeaders[col].indexOf("HOT_App Icon") != -1)
            ) {
              let errorMessage = null
              if (!(newVal in this.imageIdUrlMap)) {
                errorMessage = "Image/Video not found."
              } else if (this.colHeaders[col] === "HOT_Video" && newVal.split('-')[0] === 'DXC') {
                errorMessage = "Please select a Video."
              }
              if (this.colHeaders[col] === "HOT_App Icon") {
                if (newVal.split('-')[0] !== 'DXC') {
                  errorMessage = "Please select an Image"
                } else {
                  const image = this.imagesData.find(obj => obj.id === newVal);

                  if (image.width !== image.height) {
                    errorMessage = "Please select a image with right dimension"
                  }
                }
              }
              if (this.colHeaders[col] === "HOT_Asset") {
                if (this.tab === "inStream" || this.tab === "nativeVideo") {
                  if (newVal.split('-')[0] !== 'DXV') {
                    errorMessage = "Please select an Video"
                  }
                } else if (this.tab === "imageBanner" || this.tab === "nativeImage") {
                  if (newVal.split('-')[0] !== 'DXC') {
                    errorMessage = "Please select an Image"
                  }
                }
              }
              if (errorMessage !== null) {
                this.$Message.error({
                  background: true,
                  content: errorMessage
                })
                changes[index] = null
                continue;
              }
            }
            this.updatePreviewColumn(row, col, oldVal, newVal, instance, true);
          }

          let bulkUpdatesArray = Object.values(this.bulkUpdateQueries);
          setTimeout(() => {
            instance.setDataAtRowProp(bulkUpdatesArray);
          })
          if (requireRerender) {
            this.rerender();
          }
        },
        afterGetColHeader: function (col, TH) {
          // das
        }
      }
    },
    setCarouselCardNum (obj, cardNum) {
      Object.keys(obj).forEach(key => {
        if (obj[key] !== null && typeof obj[key] === "object") {
          this.setCarouselCardNum(obj[key], cardNum);
          return;
        }
        if (typeof obj[key] === "string" && obj[key].startsWith("HOT_")) {
          obj[key] = `${obj[key]} ${cardNum}`;
        }
      });
    },
    recurse (obj, headerValMap) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] !== null && typeof obj[key] === 'object') {
          this.recurse(obj[key], headerValMap)
          return
        }
        if (typeof obj[key] === 'string' && obj[key].startsWith("HOT_")) {
          if (obj[key].startsWith("HOT_Image/Video")) {
            if (headerValMap[obj[key]].startsWith("http://") || headerValMap[obj[key]].startsWith("https://")) {
              // use picture
              obj[key] = headerValMap[obj[key]]
            } else {
              // use image hash
              obj["image_hash"] = headerValMap[obj[key]]
              delete obj[key];
            }
          } else {
            obj[key] = headerValMap[obj[key]]
          }
        }
      })
    },
    saveSetupData (newData) {
      const rowNum = this.creativeSetupRowNum
      this.creativeSetupData[rowNum] = newData;
      this.$emit("onSetAdSheetAlert", {
        show: false,
        tab: this.tab,
        message: ""
      });
    },
    getImageHashOrUrl (key) {
      return this.imageIdUrlMap[key]['hash'] ? this.imageIdUrlMap[key]['hash'] : this.imageIdUrlMap[key]['src']
    },
    regularSpec (row) {
      let headerValMap = {}
      let creativeSpec = JSON.parse(JSON.stringify(this.creativeSpec));
      let spec = JSON.parse(JSON.stringify(this.creativeSpec))
      for (let i = 0; i < this.colHeaders.length; i++) {
        if ((this.colHeaders[i] === 'HOT_Image/Video' || this.colHeaders[i] === 'HOT_Video') && row[i]) {
          if (row[i].split('-')[0] === 'DXC') {
            spec = ('image' in spec) ? spec['image'] : spec;
            headerValMap[this.colHeaders[i]] = this.getImageHashOrUrl(row[i]);
          } else if (row[i].includes("http") || row[i].includes("www")) {
            spec = spec['image']
            headerValMap[this.colHeaders[i]] = row[i]
          } else {
            spec = spec['video']
            headerValMap[this.colHeaders[i]] = this.imageIdUrlMap[row[i]]['src']
            headerValMap['HOT_SearchEngineVideoId'] = this.imageIdUrlMap[row[i]]['SearchEngineVideoId']
          }
        } else {
          headerValMap[this.colHeaders[i]] = row[i]
        }
        if (this.colHeaders[i] === 'HOT_Tweet Id') {
          headerValMap['HOT_Tweet Text'] = this.existingTweets.find(x => x.id == row[i]).full_text;
        }
      }
      this.recurse(spec, headerValMap)
      return spec
    },
    getPreviewableRows () {
      // this.uniqueId = this.createSheetCreativesSize + 1
      this.uniqueId = this.allCreativesInfo[this.tab].lastUsedId + 1
      let validSpec = []; let validRows = []
      let instance = this.$refs['hotEdit'].hotInstance
      for (let [rowNum, previewRow] of Object.entries(this.previewable)) {
        let rowData = instance.getDataAtRow(rowNum) // [true, ad name, Dlow, null]
        if (this.checkIfPreviewable(previewRow) && rowData[0] == 'true') {
          let rowDataObj = this.buildRowDataObj(rowData, rowNum)
          let adNameIndex = this.colHeaders.indexOf("HOT_Ad Name")
          let adName = (adNameIndex != -1 && rowData[adNameIndex]) ? rowData[adNameIndex] : ""
          let creativeSpec = this.buildCreativeSpec(rowData, rowNum);
          let rowId = 0;
          if (this.hotIndexIdMapper[rowNum]) {
            rowId = this.hotIndexIdMapper[rowNum]
          } else {
            rowId = this.uniqueId + rowNum
          }
          let spec = {
            'hotRowId': `${this.tab}-${rowId}`,
            'adName': `${adName}`,
            'creative': creativeSpec["creative"]
          }
          validSpec.push(spec)
          validRows.push(rowDataObj)
        }
      }
      return [validSpec, validRows]
    },
    getCreativeStructure (tableData) {
      // this.uniqueId = this.createSheetCreativesSize + 1
      this.uniqueId = this.allCreativesInfo[this.tab].lastUsedId + 1
      let validSpec = []; let validRows = []
      for (let [rowNum, previewRow] of Object.entries(this.previewable)) {
        if (this.checkIfPreviewable(previewRow)) {
          let rowData = tableData[rowNum]
          let rowDataObj = this.buildRowDataObj(rowData, rowNum)
          let adNameIndex = this.colHeaders.indexOf("HOT_Ad Name")
          let adName = (adNameIndex != -1 && rowData[adNameIndex]) ? rowData[adNameIndex] : ""
          let creativeSpec = this.buildCreativeSpec(rowData, rowNum);
          let spec = {
            'hotRowId': `${this.tab}-${this.uniqueId++}`,
            'AdName': `${adName}`,
            'Creative': creativeSpec["creative"]
          }
          validSpec.push(spec)
          validRows.push(rowDataObj)
        }
      }
      return [validSpec, validRows]
    },
    buildCreativeSpec (row, index) {
      return this.regularSpec(row)
    },
    getAllRows () {
      var self = this
      let validRows = []
      let instance = this.$refs['hotEdit'].hotInstance
      let rowNum = 0;
      var gridData = instance.getData();
      for (let rowData of gridData) {
        let rowDataObj = this.buildRowDataObj(rowData, rowNum)
        validRows.push(rowDataObj)
        rowNum++;
      }
      let rowObjects = this.editSheetCreatives[this.tab] ? JSON.parse(JSON.stringify(this.editSheetCreatives[this.tab])) : [];
      rowNum = 0;
      validRows.forEach(elem => {
        if (elem["HOT_Is Valid"] === "true") {
          elem["HOT_Is Valid"] = true;
        }
        this.hotEventKeys.forEach(hotEventKey => {
          if (elem[hotEventKey]) {
            elem[hotEventKey] = self.molocoEnums.events[elem[hotEventKey]]
          }
        })
        elem["HOT_Id"] = rowObjects[rowNum]["HOT_Id"];
        elem["HOT_LineItemId"] = rowObjects[rowNum]["HOT_LineItemId"];
        elem["HOT_TargetId"] = rowObjects[rowNum]["HOT_TargetId"];
        elem["HOT_LineItemFractionId"] = rowObjects[rowNum]["HOT_LineItemFractionId"];
        elem["HOT_IsNotPublished"] = rowObjects[rowNum]["HOT_IsNotPublished"];
        elem["HOT_IsEdited"] = rowObjects[rowNum]["HOT_IsEdited"];
        elem["HOT_CreativeJson Id"] = rowObjects[rowNum]["HOT_CreativeJson Id"];
        elem["HOT_IsCreativeEdited"] = rowObjects[rowNum]["HOT_IsCreativeEdited"];
        elem["HOT_Status"] = this.molocoEnums.AdStatusValue[elem["HOT_Status"]];
        elem["HOT_EditSummary"] = rowObjects[rowNum]["HOT_EditSummary"];
        elem["HOT_Ad Target"] = rowObjects[rowNum]["HOT_Ad Target"];
        let summary = elem["HOT_IsEdited"] && elem["HOT_EditSummary"] ? elem["HOT_EditSummary"].split(',') : [];
        let newValue = this.sortObject(elem);
        let oldValue = this.sortObject(rowObjects[rowNum]);
        if (JSON.stringify(newValue, (key, value) => {
          if (value === null || value === "") return undefined;
          return value;
        }) !== JSON.stringify(oldValue, (key, value) => {
          if (value === null || value === "") return undefined;
          return value;
        })) {
          elem["HOT_IsEdited"] = true;
        }
        elem["HOT_IsCreativeEdited"] = false;
        this.creativeHeaders.forEach(header => {
          let self = this;
          if (header == "HOT_JSON") {
            try {
              let newJson = JSON.parse(elem[header]);
              let oldJson = JSON.parse(rowObjects[rowNum][header]);
              if (self.isCustomJsonCreativeEdited(newJson, oldJson)) {
                elem["HOT_IsCreativeEdited"] = true;
              }
            } catch (error) {
              elem["HOT_IsCreativeEdited"] = true;
              console.error(error);
            }
          } else if (elem[header] != rowObjects[rowNum][header]) {
            elem["HOT_IsCreativeEdited"] = true;
          }
        })
        if (elem["HOT_IsCreativeEdited"]) {
          if (!summary.includes(this.molocoEnums.AdProperties["HOT_Status"])) {
            summary.push(this.molocoEnums.AdProperties["HOT_Status"]);
          }
        } else if (elem["HOT_IsEdited"]) {
          for (var header in elem) {
            if (this.isChanged(header, rowObjects[rowNum][header], elem[header])) {
              if (header.includes("HOT_Event") && !summary.includes(this.molocoEnums.AdProperties["HOT_Event"])) {
                summary.push(this.molocoEnums.AdProperties["HOT_Event"]);
              } else if (!header.includes("HOT_Event") && !summary.includes(this.molocoEnums.AdProperties[header])) {
                summary.push(this.molocoEnums.AdProperties[header]);
              }
            }
          }
        }
        elem["HOT_EditSummary"] = summary.join(',')
        rowNum++;
      });
      return validRows.filter(row => row["HOT_IsEdited"] === true);
    },
    isCustomJsonCreativeEdited (newJson, oldJson) {
      let isCreativeEdited = this.isEdited(JSON.stringify(this.sortObject(newJson["cta"])), JSON.stringify(this.sortObject(oldJson["cta"])))
      isCreativeEdited = isCreativeEdited || this.isEdited(newJson["title"], oldJson["title"])
      isCreativeEdited = isCreativeEdited || this.isEdited(newJson["subtitle"], oldJson["subtitle"])
      isCreativeEdited = isCreativeEdited || this.isEdited(newJson["logo_url"], oldJson["logo_url"])

      if (oldJson["adformat"] && oldJson["adformat"].includes("carousel")) {
        isCreativeEdited = isCreativeEdited || !newJson["images"] || !oldJson["images"] || newJson["images"].length != oldJson["images"].length
        if (!isCreativeEdited) {
          for (let i = 0; i < oldJson["images"].length; i++) {
            isCreativeEdited = isCreativeEdited || this.isEdited(newJson["images"][i]["url"], oldJson["images"][i]["url"])
            isCreativeEdited = isCreativeEdited || this.isEdited(newJson["images"][i]["title"], oldJson["images"][i]["title"])
            isCreativeEdited = isCreativeEdited || this.isEdited(newJson["images"][i]["subtitle"], oldJson["images"][i]["subtitle"])
          }
        }
      } else {
        isCreativeEdited = isCreativeEdited || this.isEdited(JSON.stringify(this.sortObject(newJson["image"])), JSON.stringify(this.sortObject(oldJson["image"])))
      }

      if (oldJson["adformat"] && (oldJson["adformat"].includes("custom-native-banner") || oldJson["adformat"].includes("carousel"))) {
        isCreativeEdited = isCreativeEdited || this.isEdited(newJson["description"], oldJson["description"])
      }

      return isCreativeEdited
    },
    isEdited (oldValue, newValue) {
      return oldValue != newValue
    },
    isChanged (header, oldValue, newValue) {
      return header != "HOT_Is Valid" && header != "HOT_IsEdited" && header != "HOT_IsCreativeEdited" && (header == "HOT_JSON" || !this.creativeHeaders.includes(header)) && newValue != oldValue
    },
    sortObject (obj) {
      if (!obj || typeof obj !== 'object') {
        return obj;
      }
      return Object.keys(obj).sort().reduce((result, key) => {
        result[key] = obj[key];
        return result;
      }, {});
    },
    buildRowDataObj (rowData, rowNum) {
      let rowObj = Object.assign(
        ...rowData.map((cellValue, index) => ({
          [this.colHeaders[index]]: cellValue
        }))
      );
      return rowObj;
    },
    getRowValueForColumn (rowData, headerName) {
      var indexValue = this.colHeaders.indexOf(headerName);
      return indexValue != -1 && rowData[indexValue] ? rowData[indexValue] : null
    },
    validateUrl (value) {
      if (value == "" || value == null) {
        return true;
      }
      if (value.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)) {
        return true;
      }
      return false;
    },
    validateUrls (value, callBack) {
      if (value == "" || value == null) {
        callBack(true);
        return;
      }
      var self = this
      if (value.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)) {
        callBack(true);
        return;
      } else {
        self.$Message.error({
          background: true,
          content: "Please enter a valid URL"
        })
        callBack(false)
      }
      callBack(true)
    },
    isValidJson (value) {
      try {
        if (value) JSON.parse(value);

        return true;
      } catch {
        return false;
      }
    },
    validateJson (value, callBack) {
      try {
        if (value) JSON.parse(value);

        callBack(true);
      } catch {
        this.$Message.error({
          background: true,
          content: "Please enter valid JSON"
        });

        callBack(false);
      }
    },
    getKeyByValue (object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    targetNameRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (this.publishedRowIds.includes(row)) {
        td.classList.add('disabled-cell')
      }
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }
      if (value) {
        value = Handsontable.helper.stringify(value);
        let enteredTarget = value.split("-").pop();
        let target = this.savedTargets.find(obj => obj.id == enteredTarget);
        if (target) {
          let span = document.createElement('span');
          span.innerHTML = target.name;
          td.appendChild(span);
        }
      }
      if (cellProperties.className) td.classList.add(cellProperties.className);
      return td;
    }
  }
}
</script>

  <style>
  /* .ht_clone_left table.htCore {
    box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.2);
  } */
  .hot-img {
    display: block;
    padding: 2px;
    margin: 0 auto;
    max-height: 70px;
    max-width: 100px;
  }
  .ad-sheet-hot .handsontable td,
  .ad-sheet-hot .handsontable tr,
  .ad-sheet-hot .handsontable th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  }

  .preview-td-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }
  .preview-td-wrapper > span {
    display: inline-flex;
    flex-grow:1;
    flex-basis: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
      cursor: pointer;
  }
  .preview-icon {
      font-size: 18px;
      opacity: 1;
  }
  .preview-icon-disabled > span.eye {
      pointer-events: none;
    opacity: 0.5;
  }

  .disabled-cell {
      background-color: #f6f6f6 !important;
  }
  .disabled-cell-text {
    background-color: #f6f6f6 !important;
    color: #bbb !important;
  }
  .post-cell {
    cursor: not-allowed;
  }
  .trim {
    margin: 0 !important;
    padding: 0 !important;
  }
  .handsontable td.htInvalid {
    background-color: white !important;
    border: 1px solid red
  }
  .adstatus-span {
    font-size: 14px;
  }
  </style>

  <style src="../../../../../../../node_modules/handsontable/dist/handsontable.full.css"></style>
