import axiosInstance from "../../Api";

var getBusinessProfiles = function () {
  const getAllBusinessProfiles = deltax.apiBaseUrl
  // eslint-disable-next-line no-undef
    .concat(`portals/${parseInt(portalId)}/BusinessProfiles`)
  const promise = axiosInstance.get(getAllBusinessProfiles)
  return promise
}

var downloadFile = function (purchaseOrderId) {
  var url = deltax.apiBaseUrl
    .concat(`portal/${deltax.defaultPortalId}/purchaseorder/${purchaseOrderId}/Download`);
  const promise = axiosInstance.get(
    url,
    { responseType: 'blob' }
  );
  return promise;
}

var addPurchaseOrder = function (purchaseOrder) {
  var promise = axiosInstance({
    method: "POST",
    url: deltax.apiBaseUrl
      // eslint-disable-next-line no-undef
      .concat(`portal/${deltax.defaultPortalId}/PurchaseOrder`),
    data: JSON.stringify(purchaseOrder)
  });
  return promise;
}

var updatePurchaseOrder = function (purchaseOrder) {
  var promise = axiosInstance({
    method: "POST",
    url: deltax.apiBaseUrl
      // eslint-disable-next-line no-undef
      .concat(`portal/${deltax.defaultPortalId}/PurchaseOrder/${purchaseOrder.id}`),
    data: JSON.stringify(purchaseOrder)
  });
  return promise;
}

var uploadPurchaseOrderAttachment = function (formData, purchaseOrderId) {
  var promise = axiosInstance({
    method: "POST",
    url: deltax.apiBaseUrl
      // eslint-disable-next-line no-undef
      .concat(`portal/${deltax.defaultPortalId}/PurchaseOrder/${purchaseOrderId}/Upload`),
    data: formData
  });
  return promise;
}

var getPurchaseOrdersById = function (purchaseOrderId) {
  var url = deltax.apiBaseUrl
    // eslint-disable-next-line no-undef
    .concat(`portal/${deltax.defaultPortalId}/PurchaseOrder/${purchaseOrderId}`);
  return axiosInstance.get(url);
}

var getUserDetails = function (userId) {
  var promise = axiosInstance({
    method: "GET",
    url: deltax.apiBaseUrl + "Users/" + userId
  });
  return promise;
}

var getPurchaseOrdersByEstimateId = function (id) {
  var url = deltax.apiBaseUrl
    // eslint-disable-next-line no-undef
    .concat(`portal/${parseInt(portalId)}/PurchaseOrder`);
  var params = '?approvedBuyIds=' + id;
  return axiosInstance.get(url + params);
}

export const GetBusinessProfiles = getBusinessProfiles;
export const DownloadFile = downloadFile;
export const AddPurchaseOrder = addPurchaseOrder;
export const UpdatePurchaseOrder = updatePurchaseOrder;
export const UploadPurchaseOrderAttachment = uploadPurchaseOrderAttachment;
export const GetUserDetails = getUserDetails;
export const GetPurchaseOrdersByEstimateId = getPurchaseOrdersByEstimateId;
export const GetPurchaseOrdersById = getPurchaseOrdersById;
