<template>
  <div style="position:relative;">
    <div :class="index===0 ? 'warning-message-1' : 'warning-message'" v-if="sameFieldWarning"><span class="warning-message-text">* Duplicate Values</span></div>
    <v-row style="display: flex" class="card-body">
      <v-col span="6" :id="index===0 ? 'targeting-key-box-1' : 'targeting-key-box'">
        <Select
          v-model="selectedMolocoTargetKey"
          style="width: 100%"
          filterable
          :disabled="campaignConfiguration && index > 0"
        >
          <Option
            v-for="item in molocoTargetingKeyValuePairs"
            :value="item.key"
            :key="item.key"
            :disabled="isOptionDisabled(item)"
            >{{ item.name }}</Option
          >
        </Select>
        <div id="and-or-text" v-if="index !== rowLength-1">
          {{ selectedCondition }}
        </div>
      </v-col>
      <v-col :span="campaignConfiguration ? 14 : 18" :id="index===0 ? 'targeting-value-box-1': 'targeting-value-box'">
        <v-row style="width: 100%">
          <v-col span="6" class="targeting-value-box-child">
            <Select
              v-model="selectedIsValue"
              style="width: 100%"
              :disabled="campaignConfiguration && index > 0"
            >
              <Option
                v-for="item in ['IS', 'IS_NOT']"
                :value="item"
                :key="item"
                >{{ item }}</Option
              >
            </Select>
          </v-col>
          <v-col
            :span="campaignConfiguration ? 18 : 16"
            class="targeting-value-box-child"
          >
            <Select
              v-model="selectedTargetingValues"
              multiple
              filterable
              style="width: 100%"
            >
              <Option
                v-for="item in targetingValues"
                :value="item"
                :key="item"
                >{{ item }}</Option
              >
            </Select>
          </v-col>
          <v-col
            span="2"
            class="targeting-value-box-child"
            id="clear-icon"
            v-if="!campaignConfiguration"
          >
          <tooltip content="Remove" placement="top">
            <Icon
              type="ios-close-circle-outline"
              size="20"
              @click="removeTargetingKeyComp"
              class="remove-icon"
            />
          </tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="campaignConfiguration" span="4" :id="index===0 ? 'impression-cap-box-1': 'impression-cap-box'">
            <v-row class="card-body conf-card-body">
                <v-col span="18" id="impression-cap-input-box" >
                  <Input v-model="impressionCapValue" :number="true"/>
                </v-col>
                <v-col span="6" id="conf-clear-icon">
                  <tooltip content="Remove" placement="top">
                    <Icon type="ios-close-circle-outline" size="20" @click="removeTargetingKeyComp(index)" class="remove-icon"/>
                  </tooltip>
                </v-col>
              </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Select,
  Option,
  Icon,
  Input
} from "iview";
import { tooltip } from "vue-strap";
import { mapState } from "vuex";

export default {
  props: {
    rowLength: Number,
    index: Number,
    campaignConfiguration: Boolean,
    selectedCondition: String,
    selectedConfigKey: {
      type: String,
      default: "",
      required: false
    },
    selectedConfigIsValue: {
      type: String,
      default: "IS",
      required: false
    },
    impressionCaps: Array,
    id: Number,
    sameFieldWarning: Boolean,
    editTargetModeTriggered: Number
  },
  components: {
    "v-row": Row,
    "v-col": Col,
    Select,
    Option,
    Icon,
    Input,
    tooltip
  },
  data () {
    return {
      impressionCapValue: 0,
      targetingValues: [],
      selectedIsValue: "IS",
      selectedMolocoTargetKey: "",
      selectedTargetingValues: [],
      targetingValuesComputed: []
    };
  },
  computed: {
    ...mapState([
      "molocoTargetingKeyValuePairs"
    ])
  },
  methods: {
    removeTargetingKeyComp () {
      this.$emit("remove-targeting-key-comp");
    },
    setTargetingValuesFromKey (val) {
      if (val) {
        this.selectedTargetingValues = [];
        const selectedKeyValue = this.molocoTargetingKeyValuePairs.find(item => item.key === val).value;

        if (!selectedKeyValue) return [];

        const values = selectedKeyValue.split(",");

        if (!Array.isArray(values) || values.length === 0) return null;

        // sort by value difference if array of Numbers
        values.some(e => isNaN(Number(e))) ? values.sort() : values.sort((a, b) => Number(a) - Number(b));

        return values;
      }
    },
    setSelectedMolocoTargetKey () {
      if (this.campaignConfiguration) {
        this.selectedMolocoTargetKey = this.selectedConfigKey;
        this.targetingValues = this.setTargetingValuesFromKey(this.selectedConfigKey);
      }
    },
    isOptionDisabled (item) {
      return this.impressionCaps.some(element => element.selectedMolocoTargetKey === item.key);
    }
  },
  watch: {
    selectedMolocoTargetKey (val) {
      if (this.campaignConfiguration) {
        this.$emit("config-selected-key", val);
      }
      this.targetingValues = this.setTargetingValuesFromKey(val);
      this.$emit("update-impression-data", { selectedMolocoTargetKey: val, selectedIsValue: this.selectedIsValue, selectedTargetingValues: this.selectedTargetingValues, impressionCapValue: this.impressionCapValue });
    },
    selectedTargetingValues (val) {
      this.$emit("update-impression-data", { selectedMolocoTargetKey: this.selectedMolocoTargetKey, selectedIsValue: this.selectedIsValue, selectedTargetingValues: val, impressionCapValue: this.impressionCapValue });
    },
    selectedConfigKey () {
      this.setSelectedMolocoTargetKey();
    },
    selectedIsValue (val) {
      if (this.campaignConfiguration) {
        this.$emit("config-selected-is-value", val);
      }
      this.$emit("update-impression-data", { selectedMolocoTargetKey: this.selectedMolocoTargetKey, selectedIsValue: this.selectedIsValue, selectedTargetingValues: this.selectedTargetingValues, impressionCapValue: this.impressionCapValue });
    },
    selectedConfigIsValue () {
      if (this.campaignConfiguration) {
        this.selectedIsValue = this.selectedConfigIsValue;
      }
    },
    impressionCapValue () {
      this.$emit("update-impression-data", { selectedMolocoTargetKey: this.selectedMolocoTargetKey, selectedIsValue: this.selectedIsValue, selectedTargetingValues: this.selectedTargetingValues, impressionCapValue: this.impressionCapValue });
    },
    editTargetModeTriggered: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.selectedMolocoTargetKey = this.impressionCaps[this.index].selectedMolocoTargetKey;
          this.targetingValues = this.setTargetingValuesFromKey(this.selectedMolocoTargetKey);
          this.selectedIsValue = this.impressionCaps[this.index].selectedIsValue;
          this.selectedTargetingValues = this.impressionCaps[this.index].selectedTargetingValues;
        }
      }
    }
  },
  created () {
    this.setSelectedMolocoTargetKey();
  }
};
</script>

<style scoped>
/* ::v-deep .ivu-tag {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    margin: 2px 4px 2px 0;
    padding: 0 8px;
    border: 1px solid #41b883;
    border-radius: 3px;
    background: #41b883 !important;
    font-size: 12px;
    vertical-align: middle;
    opacity: 1;
    overflow: hidden;
    cursor: pointer;
} */
::v-deep .ivu-btn, .ivu-btn:active, .ivu-btn:focus {
    outline: 0;
    padding: 0px 5px;
    margin: 5px 5px 5px 0px;
    color: var(--main-bg-color);
    background-color: #fff;
    border-color: var(--main-bg-color);
}
::v-deep .ivu-select-multiple .ivu-select-selection {
    padding: 1px 24px 1px 4px;
}
/* ::v-deep .ivu-tag-text {
    color: #ffffff !important;
} */
::v-deep .ivu-icon-ios-close:before {
    content: "\F178";
    font-weight: bolder;
}
.remove-icon {
  cursor: pointer;
}
.warning-message-text {
  padding: 10px 10px 0px 10px;
  font-size: 10px;
  font-weight: 100;
  color: rgb(221, 0, 0);
}
.warning-message {
  position: absolute;
  width: 25%;
  border-right: 1px solid #d6d6d6;
  top: 25px;
  padding-bottom: 5px;
}
.warning-message-1 {
  position: absolute;
  width: 25%;
  border-right: 1px solid #d6d6d6;
  top: 38px;
  padding-bottom: 5px;
}
#and-or-text{
    font-weight: 100;
    padding-top: 10px;
}
#conf-clear-icon{
    padding: 0px 10px;
}
#clear-icon {
    /* margin: 5px 0px; */
    padding: 0px 15px;
    cursor: pointer;
}
.targeting-value-box-child{
    padding: 0px 6px;
}
#targeting-value-box {
    padding: 0px 8px 8px 8px;
}
#targeting-value-box-1 {
    padding: 15px 8px 8px 8px;
}
#targeting-key-box {
    padding: 0 8px 8px 8px;
    border-right: 1px solid #d6d6d6;
}
#targeting-key-box-1 {
    padding: 15px 8px 8px 8px;
    border-right: 1px solid #d6d6d6;
}
#impression-cap-box {
    padding: 0 8px 8px 8px;
    border-left: 1px solid #d6d6d6;
}
#impression-cap-box-1 {
    padding: 15px 8px 8px 8px;
    border-left: 1px solid #d6d6d6;
}
.card-body {
    padding: 0px;
}
</style>
