export const launchStatus = {
  "ReadyForExecution": 1,
  "ExecutionInProgress": 2,
  "ReadyForLaunch": 3,
  "LaunchCompleted": 4
}

export const typeId = {
  "Search": 1,
  "Social": 2
}

export const searchEngineId = {
  "Adwords": 1,
  "Facebook": 5
}

export const mediaPlanEffectiveStatus = {
  active: "Active",
  paused: "Paused"
}

export const mediaPlanCommentType = {
  "ChangeSuggestion": 1,
  "Rejection": 2,
  "Approval": 3
}

export const briefTypeIds = {
  "existing": "1",
  "new": "2"
}
