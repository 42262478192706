import { Objective } from "./Objective.js";

let ColumnConfig = {
  image: {
    base: ["HOT_Is Valid", "HOT_Ad Name", "HOT_Image", "HOT_Landing Page URL", "HOT_HTML Tracking Tag", "HOT_Target"],
    requiredBase: ["HOT_Ad Name", "HOT_Image", "HOT_Landing Page URL"],
    card: [],
    requiredCard: [],
    callToAction: []
  }
}
let CreativeSpec = {
  image: {}
}

let image = new Objective(ColumnConfig, CreativeSpec)
export { image }
