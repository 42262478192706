<template>
    <i-modal
        v-model="showModal"
        title="Common Modal dialog box title"
        :closable="false"
        :mask-closable="false">
          <div slot="header" class="fractions-modal-header">
            <span class="modal-custom-header">Fractions
              <CheckboxGroup v-model="selectedEntities" @on-change="setSelectedEntities()" v-show="isSettingsEnabled">
                <Checkbox style="margin-left: 50px;" label="campaign" disabled>
                  <span>{{checkboxNames.campaign}}</span>
                </Checkbox>
                <Checkbox v-show="checkboxNames.insertionOrder.length > 0" class="entity-checkbox" label="insertionOrder" :disabled="this.selectedEntities.some(e => e.data == 'lineItemMacro')">
                  <span>{{checkboxNames.insertionOrder}}</span>
                </Checkbox>
                <Checkbox v-show="checkboxNames.lineItemMacro.length > 0" class="entity-checkbox" label="lineItemMacro" :disabled="this.selectedEntities.some(e => e.data == 'creative')">
                  <span>{{checkboxNames.lineItemMacro}}</span>
                </Checkbox>
                <Checkbox v-show="checkboxNames.creative.length > 0" class="entity-checkbox" label="creative">
                  <span>{{checkboxNames.creative}}</span>
                </Checkbox>
              </CheckboxGroup>
            </span>
          </div>
          <div>
            <hot-table ref="table" :settings="settings"></hot-table>
            <br />
            <!-- <button
              type="button"
              class="btn btn-success fraction-btn"
              @click="addNewFraction">
              <i class="fas fa-plus"></i>
              Row
            </button> -->
            <Alert v-if="isInvalidFraction" type="error" closable>Fraction name cannot be empty.</Alert>
          </div>
          <div slot="footer">
             <button
              type="button"
              class="btn btn-white button-style pull-left add-row"
              @click="addNewFraction">
              <i class="fas fa-plus"></i>
              Row
            </button>
            <button
              type="button"
              class="btn create-btn btn-white button-style"
              @click="cancel">
              Cancel
            </button>
            <button
              type="button"
              class="btn create-btn btn-success button-style"
              @click="save">
              Save
            </button>
         </div>
    </i-modal>
</template>

<script>
import {
  Modal,
  Alert,
  Checkbox,
  CheckboxGroup
} from "iview";
import { HotTable } from '@handsontable/vue';

export default {
  props: [
    "lineItemOrderId",
    "lineItemFractions",
    "lineItemId",
    "isFractionEnabled",
    "allNomenclatureSettings",
    "labelTypes",
    "labels",
    "lineItemData",
    "mediaPlanObject",
    "lineItemPublisher"
  ],
  components: {
    "i-modal": Modal,
    Alert,
    "hot-table": HotTable,
    Checkbox,
    CheckboxGroup
  },
  data: function () {
    return {
      showModal: false,
      allowAdditon: true,
      fractionsData: [],
      fractionColumns: [
        {
          title: " ",
          data: 'action',
          renderer: "html",
          wordWrap: false,
          readOnly: true,
          width: '50%',
          className: "htCenter"
        },
        {
          title: "Id",
          data: "uniqueKey",
          readOnly: true,
          minwidth: "100px",
          width: "100px"
        },
        {
          title: "Alias",
          data: "alias",
          minwidth: "100px",
          width: "100px",
          wordWrap: false,
          validator: this.emptyValidator
        }
      ],
      isInvalidFraction: false,
      maxId: 1,
      selectedEntities: [],
      entityOrder: [
        {
          title: 'Campaign',
          data: 'campaign'
        },
        {
          title: 'Insertion Order',
          data: 'insertionOrder'
        },
        {
          title: 'Line Item',
          data: 'lineItemMacro'
        },
        {
          title: 'Creative',
          data: 'creative'
        }
      ],
      nomenclatureConfig: {},
      nomenclatureData: {},
      lineItemFractionHotTableData: [],
      editableColumns: [],
      editableColumnNames: [],
      replacedNomenclatureData: [],
      nomenclatureColumns: [],
      isSettingsEnabled: true,
      selectedLineItem: {},
      publishers: [{
        name: 'DV360',
        value: 9
      },
      {
        name: 'Google',
        value: 1
      },
      {
        name: 'Facebook',
        value: 5
      },
      {
        name: 'Twitter',
        value: 6
      },
      {
        name: 'LinkedIn',
        value: 7
      },
      {
        name: 'AMS',
        value: 10
      }
      ],
      nomenclatureSettings: {},
      checkboxNames: {
        campaign: 'Campaign',
        insertionOrder: 'Insertion Order',
        lineItemMacro: 'Line Item',
        creative: 'Creative'
      },
      engineId: 0
    }
  },
  computed: {
    settings () {
      let self = this;
      return {
        data: [],
        height: 200,
        readOnlyCellClassName: "read-only-class",
        manualColumnResize: true,
        manualRowResize: true,
        columns: this.fractionColumns,
        afterChange: (changes) => {
          if (changes) {
            this.updateNomenclatureMacros(changes);
          }
        },
        afterOnCellMouseDown: function (event, coords, td) {
          if (event.toElement.id == 'deleteRow') {
            self.lineItemFractionHotTableData.splice(coords.row, 1);
            self.replacedNomenclatureData.splice(coords.row, 1);
            this.updateSettings({
              data: self.lineItemFractionHotTableData
            });
          }
        },
        beforePaste: function (data, coords) {
          self.bulkCopyPasteData(data, coords[0].startRow);
        }
      }
    },
    hot: function () {
      return this.$refs.table.hotInstance;
    }
  },
  methods: {
    save () {
      this.hot.validateCells(valid => {
        console.log(valid);
      });
      var fractionType = 1;
      if (this.fractionsData.length < 1) {
        var lastSelectedEntity = this.selectedEntities[this.selectedEntities.length - 1];
        switch (lastSelectedEntity) {
          case 'campaign':
            fractionType = 1;
            break;
          case 'insertionOrder':
            fractionType = 2;
            break;
          case 'lineItemMacro':
            fractionType = 3;
            break;
          case 'creative':
            fractionType = 4;
            break;
          default:
            fractionType = 1;
        }
      }
      var saveData = [];
      if (this.nomenclatureSettings != null &&
      this.nomenclatureSettings.columns.length != 0 && // non editable rows
      this.nomenclatureConfig.columns.length != 0) { // Editable rows i.e. labeldimension rows
        var allowedColumns = this.nomenclatureConfig.columns.map(e => e.value);
        for (const data of this.replacedNomenclatureData) {
          var fractionData = Object.keys(data)
            .filter(key => allowedColumns.includes(key))
            .reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});

          if (data.alias == '' || data.alias == null) {
            // Do not proceed if alias is empty or null
            return;
          }

          saveData.push({
            alias: data.alias,
            uniqueKey: data.uniqueKey,
            fractionNomenclatureJson: JSON.stringify(fractionData)
          })
        }
      } else {
        for (const data of this.lineItemFractionHotTableData) {
          if (data.alias == '' || data.alias == null) {
            // Do not proceed if alias is empty or null
            return;
          }

          saveData.push({
            alias: data.alias,
            uniqueKey: data.uniqueKey,
            fractionNomenclatureJson: '{}'
          })
        }
      }
      let fractionsObject = {
        isFractionEnabled: fractionType,
        lineItemFractions: saveData,
        lineItemOrderId: this.lineItemOrderId
      }
      this.$emit("saveFractionDetails", fractionsObject);
    },
    cancel () {
      this.$emit("hideFractionsModal");
    },
    addNewFraction () {
      if (this.nomenclatureSettings != null && this.nomenclatureSettings.columns.length != 0) {
        this.replacedNomenclatureData.push(this.getNewReplacedNomenclatureData())
      }
      let newHotTableData = {
        action: "<i id='deleteRow' class='fa fa-trash'></i>",
        uniqueKey: null,
        alias: ""
      }
      if (this.nomenclatureSettings != null && this.nomenclatureSettings.columns.length != 0) {
        for (const column of this.nomenclatureConfig.columns) {
          newHotTableData[`${column.value}`] = "";
        }

        this.labelTypes.forEach(labelType => {
          if (this.nomenclatureSettings.columns.map(x => x.name).includes(labelType.name)) {
            var labelDimension = ('LabelDimension.' + labelType.name).replace(/\s/g, "");
            newHotTableData[labelDimension] = Object.keys(this.selectedLineItem).includes(labelType.name) ? this.selectedLineItem[labelType.name] : "";
          }
        })

        for (const macro in this.nomenclatureData) {
          newHotTableData[`${macro}`] = this.replaceMacro(this.nomenclatureData[`${macro}`], newHotTableData);
        }
      }
      this.lineItemFractionHotTableData.push(newHotTableData);

      var index = this.replacedNomenclatureData.length;
      this.replacedNomenclatureData[index - 1] = newHotTableData;
      this.hot.updateSettings({
        data: this.lineItemFractionHotTableData
      });
    },
    printData () {
      console.log(this.fractionsData);
    },
    setSelectedEntities: function () {
      var currentSelected = this.selectedEntities;
      var lastSelected = this.selectedEntities[currentSelected.length - 1];
      var lastSelectedIndex = this.entityOrder.findIndex(e => e.data == lastSelected);
      var selectedEntityObjects = this.entityOrder.slice(0, lastSelectedIndex + 1);
      this.selectedEntities = this.entityOrder.slice(0, lastSelectedIndex + 1).map(e => e.data);
      var nonEditableColumns = this.nomenclatureColumns
        .concat(selectedEntityObjects
          .filter(e => e.title != 'campaign')
          .map(e => {
            return {
              title: e.title,
              minwidth: "100px",
              width: "150px",
              data: e.data,
              allowEmpty: true,
              readOnly: true,
              wordWrap: false
            }
          }));
      nonEditableColumns.shift();
      this.hot.updateSettings({
        columns: [
          ...this.fractionColumns,
          ...this.editableColumns,
          ...nonEditableColumns
        ]
      });
    },
    updateNomenclatureMacros: function (changes) {
      if (this.nomenclatureSettings != null && this.nomenclatureSettings.columns.length != 0) {
        for (const change of changes) {
          var columnName = change[1];
          var oldValue = change[2];
          var newValue = change[3];
          if (newValue == oldValue) {
            return;
          }
          if (!this.entityOrder.some(e => e.title === columnName)) {
            var index = change[0];
            this.replacedNomenclatureData[index][`${columnName}`] = newValue;
            for (const macro in this.nomenclatureData) {
              this.hot.setDataAtRowProp(
                index,
                macro,
                this.replaceMacro(this.nomenclatureData[macro], this.replacedNomenclatureData[index])
              );
            }
          }
        }
      }
    },
    replaceMacro: function (macro, data) {
      var str = macro;
      for (const key in data) {
        if (data[key] != '' && key.includes('LabelDimension')) {
          str = str.replace('{{' + key + '}}', data[key]);
        }
        if (data[key] != '' && key.includes('Fraction.Note')) {
          str = str.replace('{{' + key + '}}', data[key]);
        }
      }
      return str;
    },
    getNewReplacedNomenclatureData: function () {
      var data = {};
      for (const column of this.nomenclatureConfig.columns) {
        data[column.value] = '';
      }
      return data;
    },
    initializeMacro: function (macro) {
      macro = macro.replace('{{MediaPlan.Id}}', this.$root.$children[0].mediaPlanId);
      macro = macro.replace('{{MediaPlan.Name}}', this.mediaPlanObject.mediaPlanName);
      macro = macro.replace('{{MediaPlan.Objective}}', (this.$root.$children[0].mediaPlanObjectivesSource.filter(e => e.id == this.$root.$children[0].mediaPlanObj.objective)).map(x => x.name)[0]);
      macro = macro.replace('{{MediaPlan.LandingPageLink}}', this.mediaPlanObject.landingPageLink ? this.mediaPlanObject.landingPageLink : '');
      macro = macro.replace('{{MediaPlan.StartDate}}', moment(this.mediaPlanObject.dateFilters[0]).format('YYYYMMDD'));
      macro = macro.replace('{{MediaPlan.EndDate}}', moment(this.mediaPlanObject.dateFilters[1]).format('YYYYMMDD'));
      macro = macro.replace('{{LineItem.Note1}}', this.selectedLineItem.note1);
      macro = macro.replace('{{LineItem.Note2}}', this.selectedLineItem.note2);
      macro = macro.replace('{{LineItem.Variable1}}', this.selectedLineItem.note1);
      macro = macro.replace('{{LineItem.Variable2}}', this.selectedLineItem.note2);
      macro = macro.replace('{{LineItem.Theme}}', this.selectedLineItem.theme);
      macro = macro.replace('{{LineItem.IoNumber}}', this.selectedLineItem.roNumber);
      macro = macro.replace('{{LineItem.Publisher}}', this.selectedLineItem.publisher);
      macro = macro.replace('{{LineItem.StartDate}}', moment(this.selectedLineItem.startDate).format('YYYYMMDD'));
      macro = macro.replace('{{LineItem.EndDate}}', moment(this.selectedLineItem.endDate).format('YYYYMMDD'));
      macro = macro.replace('{{LineItem.BuyType}}', this.selectedLineItem.buyType);
      macro = macro.replace('{{LineItem.StartYear}}', moment(this.selectedLineItem.startDate).format('YYYY'));
      macro = macro.replace('{{LineItem.StartMonth}}', moment(this.selectedLineItem.startDate).format('MMMM'));
      macro = macro.replace('{{LineItem.BuyMetric}}', this.selectedLineItem.buyMetric);
      macro = macro.replace('{{LineItem.Id}}', this.selectedLineItem.liId);
      macro = macro.replace('{{LineItem.EncodedId}}', this.selectedLineItem.liId);
      macro = macro.replace('{{LineItem.CreativeFormat}}', this.selectedLineItem.creativeFormat);
      macro = macro.replace('{{LineItem.Targeting}}', this.selectedLineItem.targeting ? this.selectedLineItem.targeting : '');
      macro = macro.replace('{{LineItem.KpiName}}', this.selectedLineItem.kpiMetric);
      macro = macro.replace('{{LineItem.NickName}}', this.selectedLineItem.nickname ? this.selectedLineItem.nickname : '');
      // eslint-disable-next-line no-undef
      macro = macro.replace('{{BusinessProfile.Name}}', businessProfileName);
      // eslint-disable-next-line no-undef
      macro = macro.replace('{{BusinessProfile.Currency}}', deltax.currencyFormattingOptionsCode);

      macro = this.replaceInvalidMacros(macro);

      return macro;
    },
    replaceInvalidMacros: function (macro) {
      let macroCopy = macro;
      let validLabelDimensions = this.labelTypes.map(e => ('LabelDimension.' + e.name).replace(/\s/g, ""));
      for (const validLabelDimension of validLabelDimensions) {
        macroCopy = macroCopy.replace("{{" + validLabelDimension + "}}", "<<" + validLabelDimension + ">>");
      }

      macroCopy = macroCopy.replaceAll("{{Fraction.Note}}", "<<Fraction.Note>>");

      macroCopy = macroCopy.replace(/{{[a-zA-Z0-9]+[.][a-zA-Z0-9_\-\/\\&$%*()\[\]|+?.]+}}/g, '');
      macroCopy = macroCopy.replaceAll('<<', "{{");
      macroCopy = macroCopy.replaceAll('>>', "}}");
      return macroCopy;
    },
    emptyValidator: function (value, callbk) {
      if (!value || value.length === 0) {
        callbk(false);
      } else {
        callbk(true);
      }
    },
    setNomencatureSettingsByPublisher: function () {
      let publisher = this.lineItemPublisher;
      if (publisher == null || publisher == '') {
        this.nomenclatureSettings = null;
      } else {
        let lineItem = this.publishers.find(e => e.name.toLowerCase().trim() == publisher.toLowerCase().trim());
        if (lineItem == undefined) {
          this.nomenclatureSettings = null;
        } else {
          let engineId = lineItem.value;
          this.engineId = engineId;

          var publisherNomenclatureSettings = this.allNomenclatureSettings.find(e => e.engineId == engineId);
          if (!publisherNomenclatureSettings) {
            this.nomenclatureSettings = null;
            return;
          }

          this.nomenclatureSettings = JSON.parse(publisherNomenclatureSettings.settingsJson);

          // DV360
          if (engineId == 9) {
            this.checkboxNames.campaign = "Campaign";
            this.checkboxNames.insertionOrder = 'Insertion Order';
            this.checkboxNames.lineItemMacro = 'Line Item';
            this.checkboxNames.creative = 'Creative';
          }

          // Google
          if (engineId == 1) {
            this.checkboxNames.campaign = "Campaign";
            this.checkboxNames.insertionOrder = 'Ad Group';
            this.checkboxNames.lineItemMacro = 'Ad';
            this.checkboxNames.creative = '';
            this.entityOrder[1].title = "Ad Group";
            this.entityOrder[2].title = "Ad";
            this.entityOrder.pop();
          }

          // Facebook
          if (engineId == 5) {
            this.checkboxNames.campaign = "Campaign";
            this.checkboxNames.insertionOrder = 'Ad Group';
            this.checkboxNames.lineItemMacro = 'Ad';
            this.checkboxNames.creative = '';
            this.entityOrder[1].title = "Ad Group";
            this.entityOrder[2].title = "Ad";
            this.entityOrder.pop();
          }

          // Twitter
          if (engineId == 6) {
            this.checkboxNames.campaign = "Campaign";
            this.checkboxNames.insertionOrder = 'Ad Group';
            this.checkboxNames.lineItemMacro = 'Creative';
            this.checkboxNames.creative = '';
            this.entityOrder[1].title = "Ad Group";
            this.entityOrder[2].title = "Creative";
            this.entityOrder.pop();
          }

          // LinkedIn
          if (engineId == 7) {
            this.checkboxNames.campaign = "Campaign Group"
            this.checkboxNames.insertionOrder = 'Campaign';
            this.checkboxNames.lineItemMacro = 'Ad';
            this.checkboxNames.creative = '';
            this.entityOrder[0].title = "Campaign Group"
            this.entityOrder[1].title = "Campaign";
            this.entityOrder[2].title = "Ad";
            this.entityOrder.pop();
          }

          // AMS
          if (engineId == 10) {
            this.checkboxNames.campaign = "Campaign";
            this.checkboxNames.insertionOrder = '';
            this.checkboxNames.lineItemMacro = '';
            this.checkboxNames.creative = '';
            this.entityOrder.pop();
            this.entityOrder.pop();
            this.entityOrder.pop();
          }
        }
      }
    },
    bulkCopyPasteData: function (data, startRow) {
      let copiedDataLength = data.length;
      let existingDataLength = this.replacedNomenclatureData.slice(startRow).length;
      if (existingDataLength < copiedDataLength) {
        let newRowsLength = copiedDataLength - existingDataLength;
        for (let index = 0; index < newRowsLength + 1; index++) {
          this.addNewFraction();
        }
        this.lineItemFractionHotTableData.pop();
        this.replacedNomenclatureData.pop();
      }
    }
  },
  watch: {
  },
  mounted () {
    let self = this;
    self.fractionsData = [];

    self.selectedLineItem = self.lineItemData.filter(e => e.orderId == self.lineItemOrderId)[0];
    self.setNomencatureSettingsByPublisher();

    this.nomenclatureColumns = [
      {
        title: this.entityOrder[0].title,
        minwidth: "100px",
        width: "150px",
        data: "campaign",
        allowEmpty: true,
        readOnly: true,
        wordWrap: false
      }
    ];

    if (self.nomenclatureSettings == null || self.nomenclatureSettings.columns.length == 0) {
      this.isSettingsEnabled = false;
      if (self.lineItemFractions && self.lineItemFractions.length > 0) {
        for (const fraction of self.lineItemFractions) {
          self.lineItemFractionHotTableData.push({
            action: "<i id='deleteRow' class='fa fa-trash'></i>",
            uniqueKey: fraction.uniqueKey,
            alias: fraction.alias
          });
          self.replacedNomenclatureData.push({
            uniqueKey: fraction.uniqueKey,
            alias: fraction.alias
          })
        }
      }
      self.showModal = true;
      self.hot.updateSettings({
        data: self.lineItemFractionHotTableData,
        columns: [...self.fractionColumns, ...self.editableColumns]
      });
    } else {
      self.nomenclatureConfig = {
        campaign: self.nomenclatureSettings.campaign,
        insertionOrder: self.nomenclatureSettings.insertionOrder,
        lineItemMacro: self.nomenclatureSettings.lineItemMacro,
        creative: self.nomenclatureSettings.creative,
        columns: self.nomenclatureSettings.columns
      };

      self.selectedEntities.push('campaign');
      if (self.isFractionEnabled >= 2) {
        self.selectedEntities.push('insertionOrder');
      }
      if (self.isFractionEnabled >= 3) {
        self.selectedEntities.push('lineItemMacro');
      }
      if (self.isFractionEnabled == 4) {
        self.selectedEntities.push('creative');
      }

      var noteColumn = self.nomenclatureConfig.columns.filter(e => e.value.includes('Fraction.Note'))[0];

      self.nomenclatureConfig.columns = self.nomenclatureConfig.columns.filter(e => e.value.includes('LabelDimension'));
      self.editableColumns = self.nomenclatureConfig.columns.map(e => {
        let labelType = self.labelTypes.filter(item => item.name == e.name)[0];
        if (labelType != undefined) {
          return {
            title: e.name,
            data: e.value,
            type: 'dropdown',
            source: self.labels.filter(item => item.labelTypeId == labelType.id).map(item => item.name),
            minwidth: "100px",
            width: "150px",
            allowEmpty: true,
            wordWrap: false
          }
        }
      });

      if (noteColumn != null) {
        self.nomenclatureConfig.columns.push(noteColumn);
        self.editableColumns.push({
          title: noteColumn.name,
          data: noteColumn.value,
          type: 'text',
          minwidth: "100px",
          width: "150px",
          allowEmpty: true,
          wordWrap: false
        });
      }

      var nomenclature = {
        campaign: self.initializeMacro(self.nomenclatureConfig.campaign),
        insertionOrder: self.initializeMacro(self.nomenclatureConfig.insertionOrder),
        lineItemMacro: self.initializeMacro(self.nomenclatureConfig.lineItemMacro),
        creative: self.initializeMacro(self.nomenclatureConfig.creative)
      };
      self.nomenclatureData = JSON.parse(JSON.stringify(nomenclature));

      if (self.lineItemFractions && self.lineItemFractions.length > 0) {
        for (const fraction of self.lineItemFractions) {
          var fractionNomenclature = JSON.parse(fraction.fractionNomenclatureJson);

          for (var key of self.nomenclatureConfig.columns.map(e => e.value)) {
            if (!fractionNomenclature.hasOwnProperty(key)) {
              fractionNomenclature[key] = '';
            }
          }

          self.lineItemFractionHotTableData.push({
            action: "<i id='deleteRow' class='fa fa-trash'></i>",
            uniqueKey: fraction.uniqueKey,
            alias: fraction.alias,
            ...fractionNomenclature,
            campaign: self.replaceMacro(self.initializeMacro(self.nomenclatureConfig.campaign), fractionNomenclature),
            insertionOrder: self.replaceMacro(self.initializeMacro(self.nomenclatureConfig.insertionOrder), fractionNomenclature),
            lineItemMacro: self.replaceMacro(self.initializeMacro(self.nomenclatureConfig.lineItemMacro), fractionNomenclature),
            creative: self.replaceMacro(self.initializeMacro(self.nomenclatureConfig.creative), fractionNomenclature)
          });
          self.replacedNomenclatureData.push({
            uniqueKey: fraction.uniqueKey,
            alias: fraction.alias,
            ...fractionNomenclature,
            campaign: self.replaceMacro(self.initializeMacro(self.nomenclatureConfig.campaign), fractionNomenclature),
            insertionOrder: self.replaceMacro(self.initializeMacro(self.nomenclatureConfig.insertionOrder), fractionNomenclature),
            lineItemMacro: self.replaceMacro(self.initializeMacro(self.nomenclatureConfig.lineItemMacro), fractionNomenclature),
            creative: self.replaceMacro(self.initializeMacro(self.nomenclatureConfig.creative), fractionNomenclature)

          })
        }
      }
      self.showModal = true;
      self.hot.updateSettings({
        data: self.lineItemFractionHotTableData,
        columns: [...self.fractionColumns, ...self.editableColumns, ...self.nomenclatureColumns]
      });

      if (self.isFractionEnabled > 1) {
        self.setSelectedEntities();
      }
    }
  }
}
</script>

<style scoped>
.ivu-modal-header {
  padding: 1rem 1rem 0rem;
}

.fraction-setup-toggle {
  margin-left: 2rem;
}

.fractions-modal-header {
  font-size: 1.5rem;
  font-weight: 600;
}

.fraction-btn {
  margin-left: 1rem;
}

.modal-custom-header {
  display: inline-flex;
  padding: 10px;
}

::v-deep .ivu-modal {
  width: 1160px !important;
  top: 150px !important;
}

.entity-checkbox {
  margin-left: 10px;
}

::v-deep .ivu-checkbox {
  margin-right: 3px;
}

.add-row {
  top: -7px;
  position: relative;
}
</style>
