import { store } from "../../store.js";
class AmazonSDConfig {
  constructor (Config) {
    this.config = Config;
  }
  getConfig () {
    let targetsConfig = [];
    if (this.config.campaign.creativeCustomization == Object.keys(store.state.amsEnums.creativeCustomization)[Object.values(store.state.amsEnums.creativeCustomization).indexOf("Headline and logo")]) {
      let keywords = new function () {
        this.base = ["HOT_Preview", "HOT_ClusterId", "HOT_Logo", "HOT_HeadLine", "HOT_ASINs"];
        this.requiredBase = [];
        this.card = []
        this.requiredCard = []
      }();
      targetsConfig.push(keywords);
    } else if (this.config.campaign.creativeCustomization == Object.keys(store.state.amsEnums.creativeCustomization)[Object.values(store.state.amsEnums.creativeCustomization).indexOf("Custom image")]) {
      let keywords = new function () {
        this.base = ["HOT_Preview", "HOT_ClusterId", "HOT_CustomImage", "HOT_ASINs"];
        this.requiredBase = [];
        this.card = []
        this.requiredCard = []
      }();
      targetsConfig.push(keywords);
    } else {
      let keywords = new function () {
        this.base = ["HOT_Preview", "HOT_ClusterId", "HOT_ASINs"];
        this.requiredBase = [];
        this.card = []
        this.requiredCard = []
      }();
      targetsConfig.push(keywords);
    }
    return targetsConfig;
  }
  displayHeaderNameLookup () {
    return {
      "HOT_Preview": "Preview",
      "HOT_ClusterId": "Cluster ID",
      "HOT_Logo": "Logo",
      "HOT_HeadLine": "HeadLine",
      "HOT_CustomImage": "Custom Image",
      "HOT_ASINs": "ASINs"
    };
  }
  getTargetConfig () {
    let targetsConfig = [];
    if (this.config.campaign.targetingType == Object.keys(store.state.amsEnums.targetingTypeForDisplay)[Object.values(store.state.amsEnums.targetingTypeForDisplay).indexOf("Product Targeting")]) {
      let AsinsOrCategories = new function () {
        this.base = ["HOT_ClusterIds", "HOT_Asins Or Categories To Target", "HOT_Bid"];
        this.requiredBase = [];
        this.card = []
        this.requiredCard = []
      }();
      let negativeAsins = new function () {
        this.base = ["HOT_ClusterIds", "HOT_Excluded ASINs"];
        this.requiredBase = [];
        this.card = []
        this.requiredCard = []
      }();
      targetsConfig.push(AsinsOrCategories);
      targetsConfig.push(negativeAsins);
    } else if (this.config.campaign.targetingType == Object.keys(store.state.amsEnums.targetingTypeForDisplay)[Object.values(store.state.amsEnums.targetingTypeForDisplay).indexOf("Audience Targeting")]) {
      let Audiences = new function () {
        this.base = ["HOT_AudienceClusterIds", "HOT_Audiences"];
        this.requiredBase = [];
        this.card = []
        this.requiredCard = []
      }();
      targetsConfig.push(Audiences);
    } else {
      let AsinsOrCategories = new function () {
        this.base = ["HOT_ClusterIds", "HOT_Asins Or Categories To Target", "HOT_Bid"];
        this.requiredBase = [];
        this.card = []
        this.requiredCard = []
      }();
      let negativeAsins = new function () {
        this.base = ["HOT_ClusterIds", "HOT_Excluded ASINs"];
        this.requiredBase = [];
        this.card = []
        this.requiredCard = []
      }();
      let Audiences = new function () {
        this.base = ["HOT_AudienceClusterIds", "HOT_Audiences"];
        this.requiredBase = [];
        this.card = []
        this.requiredCard = []
      }();
      targetsConfig.push(AsinsOrCategories);
      targetsConfig.push(negativeAsins);
      targetsConfig.push(Audiences);
    }
    return targetsConfig;
  }
  displayHeaderNameLookupTarget () {
    return {
      "HOT_ClusterIds": "ClusterId",
      "HOT_AudienceClusterIds": "ClusterId",
      "HOT_Bid": "Bid",
      "HOT_Asins Or Categories To Target": "ASINs/Categories To Target",
      "HOT_Excluded ASINs": "Excluded ASINs",
      "HOT_Audiences": "Audiences"
    };
  }
}
export { AmazonSDConfig };
