import { Objective } from "./Objective.js";

let ColumnConfig = {
  "existing creative": {
    base: ["HOT_Is Valid", "HOT_Creative Id", "HOT_Creative Name", "HOT_Ad Name", "HOT_CM360 Id", "HOT_Status", "HOT_Target"],
    requiredBase: ["HOT_Creative Id"],
    card: [],
    requiredCard: [],
    callToAction: []
  }
}
let CreativeSpec = {
  "existing creative": {}
}

let existingCreative = new Objective(ColumnConfig, CreativeSpec)
export { existingCreative }
