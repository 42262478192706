import axiosInstance from '../../../Api';
const fetchUsers = deltax.apiBaseUrl + "admin/portals/" + deltax.portalId + "/users";
const getALlLanguages = deltax.apiBaseUrl + "dxlanguages";
const getUsers = deltax.apiBaseUrl + "users";
const getPortal = deltax.apiBaseUrl + "portals";
export class ApiService {
  GetUsers (page, items, queryParam) {
    const fetchUsersURL = fetchUsers;
    const params = '?page=' + page + '&items=' + items + '&NameOrEmail=' + encodeURIComponent(queryParam);
    const promise = axiosInstance.get(fetchUsersURL + params);
    return promise;
  }
  GetUsersBusinessProfiles (id) {
    const fetchBusinessProfilesURL = getUsers.concat(`/${id}/businessProfiles`);
    const promise = axiosInstance.get(fetchBusinessProfilesURL);
    return promise;
  }
  RemoveUser (id) {
    const removeUserURL = fetchUsers;
    const params = '?Ids=' + id;
    const promise = axiosInstance.delete(removeUserURL + params);
    return promise;
  }
  GetAllLanguages () {
    const getALlLanguagesURL = getALlLanguages;
    const promise = axiosInstance.get(getALlLanguagesURL);
    return promise;
  }
  GetPortalRoles () {
    const portalRoles = deltaXApiUrl.roles.getPortalRoles
      .replace('{portalId}', deltax.portalId);
    const promise = axiosInstance.get(portalRoles);
    return promise;
  }
  GetBpRoles () {
    const bpRoles = deltaXApiUrl.roles.getBpRoles
      .replace('{portalId}', deltax.portalId);
    const promise = axiosInstance.get(bpRoles);
    return promise;
  }
  GetAllDateFormats () {
    var url = "/App/Users/GetAllDateFormats";
    const promise = axiosInstance.get(url);
    return promise;
  }
  CheckIfUserExists (email) {
    const getUserURL = getUsers;
    const params = '?Email=' + encodeURIComponent(email);
    const promise = axiosInstance.get(getUserURL + params);
    return promise;
  }
  AssignPortal (id, portalAssignment) {
    const assignPortalURL = getUsers.concat(`/${id}/portals`);
    const promise = axiosInstance.post(assignPortalURL, portalAssignment);
    return promise;
  }
  CreateUser (userData) {
    const createUserURL = getUsers;
    const promise = axiosInstance.post(createUserURL, userData);
    return promise;
  }
  GetPortalByDomain (domain) {
    const getPortalByDomainURL = getPortal;
    const params = '?Domain=' + domain;
    const promise = axiosInstance.get(getPortalByDomainURL + params);
    return promise;
  }
  SendInvite (id, inviteRequest) {
    const sendInviteURL = getUsers.concat(`/${id}/invite`);
    const promise = axiosInstance.post(sendInviteURL, inviteRequest);
    return promise;
  }
  UpdateUser (id, userData) {
    const updateUserURL = getUsers.concat(`/${id}`);
    const promise = axiosInstance.put(updateUserURL, userData);
    return promise;
  }
  GetPortalBusinessProfiles () {
    var portalId = deltax.portalId;
    const getPortalBusinessProfilesURL = getPortal.concat(`/${portalId}/businessProfiles`);
    const promise = axiosInstance.get(getPortalBusinessProfilesURL);
    return promise;
  }
  GetBusinessProfilesByPortalUserId (userId) {
    var portalId = deltax.portalId;
    const getPortalBusinessProfilesURL = getPortal.concat(`/${portalId}/businessProfiles`);
    const params = '?UserId=' + userId;
    const promise = axiosInstance.get(getPortalBusinessProfilesURL + params);
    return promise;
  }

  GetBusinessProfilesGroupsByUserId (userId) {
    var portalId = deltax.portalId;
    const url = deltaXApiUrl.users.getBusinessProfilesGroupsByUserId
      .replace('{portalId}', portalId)
      .replace('{userId}', userId)
    const promise = axiosInstance({
      url: url,
      method: 'GET'
    })
    return promise;
  }

  GetBusinessProfileAccess (id, businessProfileId) {
    const getAccessURL = getUsers.concat(`/${id}/businessProfiles/${businessProfileId}`);
    const promise = axiosInstance.get(getAccessURL);
    return promise;
  }
  UpdateWhatsappPreferences (id, data) {
    const updateWhatsappPreferencesURL = getUsers.concat(`/${id}`);
    const promise = axiosInstance.patch(updateWhatsappPreferencesURL, data);
    return promise;
  }
  UpdateUserAccess (id, data) {
    var portalId = deltax.portalId;
    const updateAccessURL = deltax.apiBaseUrl.concat(`admin/portals/${portalId}/users/${id}/businessProfiles`);
    const promise = axiosInstance.put(updateAccessURL, data);
    return promise;
  }

  UpdateUserBpAccess (id, data) {
    var portalId = deltax.portalId;
    const url = deltaXApiUrl.users.UpdateUserBusinessProfileAccess
      .replace('{portalId}', portalId)
      .replace('{userId}', id);
    const promise = axiosInstance({
      url: url,
      method: 'POST',
      data
    });
    return promise;
  }

  UpdateUserBpGroupAccess (id, data) {
    var portalId = deltax.portalId;
    const url = deltaXApiUrl.users.UpdateUserBusinessProfileGroupAccess
      .replace('{portalId}', portalId)
      .replace('{userId}', id)
    const promise = axiosInstance({
      url: url,
      method: 'POST',
      data
    });
    return promise;
  }

  GetUserBpGroup (id) {
    var portalId = deltax.portalId;
    const getURL = deltax.apiBaseUrl.concat(`Portals/${portalId}/Users/${id}/BusinessProfileGroups`);
    const promise = axiosInstance.get(getURL);
    return promise;
  }

  GetUserBp (id) {
    var portalId = deltax.portalId;
    const getURL = deltax.apiBaseUrl.concat(`Portals/${portalId}/Users/${id}/BusinessProfiles`);
    const promise = axiosInstance.get(getURL);
    return promise;
  }

  GetUserById (id) {
    const getUserById = getUsers.concat(`/${id}`);
    const promise = axiosInstance.get(getUserById);
    return promise;
  }
  AssingUserToPortal (userId, roles) {
    const url = deltaXApiUrl.users.assignUserToPortal
      .replace('{portalId}', deltax.portalId)
      .replace('{userId}', userId)
    const promise = axiosInstance({
      method: "POST",
      url: url,
      data: roles
    });
    return promise;
  }
  DeleteUserAccess (userId, roles) {
    const url = deltaXApiUrl.users.removeUserAccess
      .replace('{portalId}', deltax.portalId)
      .replace('{userId}', userId)
    const promise = axiosInstance({
      method: "Delete",
      url: url,
      data: roles
    });
    return promise;
  }
  UserPortalBps (userId) {
    const url = deltaXApiUrl.users.userPortalBps
      .replace('{portalId}', deltax.portalId)
      .replace('{userId}', userId)
    const promise = axiosInstance.get(url);
    return promise;
  }
  LoggedInUserDetails () {
    const url = deltaXApiUrl.users.loggedInUserDetails
    return axiosInstance.get(url);
  }

  RemoveBpGroupAccess (id, data) {
    var portalId = deltax.portalId;
    const url = deltaXApiUrl.users.RemoveBpGroupAccess
      .replace('{portalId}', portalId)
      .replace('{userId}', id);
    const promise = axiosInstance({
      url: url,
      method: 'DELETE',
      data
    });
    return promise;
  }

  RemoveBpAccess (id, data) {
    var portalId = deltax.portalId;
    const url = deltaXApiUrl.users.RemoveBpAccess
      .replace('{portalId}', portalId)
      .replace('{userId}', id);
    const promise = axiosInstance({
      url: url,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      data
    });
    return promise;
  }

  AddUser (userData) {
    var portalId = deltax.portalId;
    const createUserURL = deltaXApiUrl.users.addUser
      .replace('{portalId}', portalId);
    const promise = axiosInstance.post(createUserURL, userData);
    return promise;
  }

  GetUserInviteLinks (userId) {
    const inviteUserLink = deltaXApiUrl.users.inviteUser
      .replace('{portalId}', deltax.portalId)
      .replace('{userId}', userId)

    const promise = axiosInstance.get(inviteUserLink);
    return promise;
  }

  SendUserInviteLinks (userId, appIds) {
    const inviteUserLink = deltaXApiUrl.users.inviteUser
      .replace('{portalId}', deltax.portalId)
      .replace('{userId}', userId)

    const promise = axiosInstance.post(inviteUserLink, appIds);
    return promise;
  }
}
