<template>
    <div class="cl-widget">
      <Card>
        <div slot="title">
          <div class="row">
            <div class="col-md-4">
              <h4>
                Targeting <i v-show="loader" class="fa fa-spinner fa-spin" />
              </h4>
            </div>
            <div class="col-md-6 no-padding">
              <Input
                v-model="search"
                search
                placeholder="Search"
                class="search-bar input-search-bar"
              />
            </div>
            <div
              class="col-md-2 widget-element pull-right"
              :class="{ disabled: businessProfilesSearchEngineId == 0 }"
            >
              <MolocoTargetModal :loadedFromAsset=true />
            </div>
          </div>
        </div>
        <VuePerfectScrollbar class="widget-body">
          <template>
            <div
              style="margin-top:10px"
              v-if="businessProfilesSearchEngineId == 0"
              class="alert alert-info"
            >
              Please select a campaign configuration to see the saved targets.
            </div>
            <div
              style="margin-top:10px"
              v-else-if="molocoTargets.length <= 0"
              class="alert alert-info"
            >
              There are no saved targets yet, create a new one to get started.
            </div>
          </template>
          <template v-if="businessProfilesSearchEngineId > 0">
            <Row
              v-for="target in filteredTargets"
              v-bind:key="target.id"
              class="cl-ivu-row"
            >
              <Row-Col span="1">
                <i
                  class="fas fa-copy action-icon"
                  @click="
                    copyToClipboard(
                      'DXT-' +
                        Number.parseInt(businessProfileId).toString(36) +
                        '-' +
                        target.Id
                    )
                  "
                >
                </i>
              </Row-Col>

              <Row-Col span="15" offset="1" class="cl-widget-text">
                <span class="target-name">{{ target.Name }}</span>
              </Row-Col>
            </Row>
          </template>
        </VuePerfectScrollbar>
      </Card>
    </div>
  </template>
<script>
import { mapState, mapMutations } from "vuex";
import { Input, locale, Card, Row, Col } from "iview";
import lang from "iview/dist/locale/en-US";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import MolocoTargetModal from "./TargetCreationModal.vue";
locale(lang);

export default {
  name: "TargetWidget",
  components: {
    Input,
    VuePerfectScrollbar,
    Card,
    "Row-Col": Col,
    MolocoTargetModal,
    Row
  },
  data: function () {
    return {
      search: "",
      loader: false,
      molocoTargets: []
    };
  },
  props: {},
  methods: {
    copyToClipboard (value) {
      let textarea = document.createElement("textarea");
      textarea.textContent = value;
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
      } catch (e) {
        alert("Unable to copy to clipboard. Please copy manually.");
      }
      document.body.removeChild(textarea);
    },
    getTargetsMappedToConfig () {
      let mappedTargetIds = [];
      this.mediaPlanLineItems.forEach(element => {
        if (element.config === this.selectedCampaignLauncherConfigId) {
          mappedTargetIds.push(element.targets);
        }
      });
      mappedTargetIds = new Set(mappedTargetIds.flat());
      let targets = this.savedTargets.filter(x =>
        x.bpseId == this.businessProfilesSearchEngineId
      );
      var targetsMapped = targets.filter(target =>
        mappedTargetIds.has(target.Id)
      );
      return targetsMapped;
    },
    getAllTargetsForBpse () {
      let targets = this.savedTargets.filter(x =>
        x.bpseId == this.businessProfilesSearchEngineId
      );
      return targets
    },
    ...mapMutations(["set_savedTargetsMapperByBpseId"])
  },
  mounted () {
    this.$watch(
      vm => [
        vm.businessProfilesSearchEngineId,
        vm.selectedCampaignLauncherConfigId,
        vm.mediaPlanLineItems
      ],
      callback => {
        this.molocoTargets = this.getAllTargetsForBpse()
      }
    );
  },
  computed: {
    ...mapState([
      "businessProfilesSearchEngineId",
      "businessProfileId",
      "savedTargetsMapperByBpseId",
      "labels",
      "selectedCampaignLauncherConfigId",
      "mediaPlanLineItems",
      "savedTargets"
    ]),
    filteredTargets () {
      let searchTerm = this.search.trim();
      if (searchTerm != "") {
        return this.molocoTargets.filter(e => {
          return e.Name.toLowerCase().includes(searchTerm.toLowerCase());
        });
      }
      return this.molocoTargets;
    }
  }
};
</script>
  <style scoped>
  .widget-body > table {
    width: 100%;
  }
  .widget-body > table > tr > td {
    padding-top: 10px;
  }
  .widget-element {
    height: 30px;
  }
  .widget > hr {
    margin: 0px;
  }
  .loader-div {
    width: inherit;
    text-align: center;
    height: inherit;
  }
  .ellipsis {
    width: 50%;
    position: relative;
  }
  .ellipsis:before {
    content: "&nbsp;";
    visibility: hidden;
  }
  .ellipsis span {
    width: 190px;
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  </style>

  <style>
  .target-tooltip .tooltip .tooltip-inner {
    max-width: 600px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    word-wrap: normal;
    word-break: initial;
    padding: 5px 10px;
  }
  .target-info-table {
    max-width: 300px !important;
    min-width: 250px !important;
  }
  .target-modal .modal-dialog {
    width: 98%;
    height: 100%;
  }
  .input-search-bar .ivu-input {
    height: 30px;
  }
  .tooltip-size {
    max-width: 500px;
    max-height: 200px;
  }
  </style>
  <style src="../../../../Styles/dx-iview.css"></style>
