<template>
  <div>
    <i-modal
    v-model="showModal"
    width="85%"
    :closable="false"
    :mask-closable="false">

    <h4 slot="header" class="header-size">
      <span>Pacing {{ lineItemName }}</span>
    </h4>

      <div ref="scrollRef" class="perfect-scroll-bar overflow-hidden modal-body">

        <div>
          <i-radio-group class="radio-group" v-model="pacingType">
              <i-radio class="radio" label="1">
                Even Pacing
              </i-radio>
              <i-radio class="radio" label="2">
                Custom Pacing
              </i-radio>
              <i-radio class="radio" label="3">
                Advanced Pacing
              </i-radio>
          </i-radio-group>
        </div>
        <hr/>

        <div class="even-pacing" v-if="pacingType == '1'">
            Budget and Buy Metric will be equally distributed among all dates in Line Item
        </div>

        <div v-if="pacingType == '2'" class="budget-separation table-grid">
          <span class="grid-pos1">Monthly Budget</span>
          <div class="grid-pos4" style="display: block;">

            <i-table
            class="monthlyBudgetTable"
            size="small"
            :columns="monthlyBudgetTableColumns"
            :data="tableData"
            :highlight-row="true">

              <template
              class="monthlyBudgetTableColumn"
              slot-scope="{ row }"
              slot="monthSlot">
                <div>{{row.Month}}</div>
              </template>

              <template
              class="monthlyBudgetTableColumn"
              slot-scope="{ row }"
              slot="budgetSlot" >
                <div>
                  <i-input
                  class="monthlyBudgetInput"
                  type="number"
                  v-model="row.Budget"
                  min="0"
                  @on-change="monthlyBudgetValueChanged(row)">
                  </i-input>
                </div>
              </template>
               <template
              class="monthlyBudgetTableColumn"
              slot-scope="{ row }"
              slot="buyMetricSlot" >
                <div>
                  <i-input
                  class="monthlyBudgetInput"
                  type="number"
                  v-model="row.BuyMetric"
                  @on-change="monthlyBuyMetricValueChanged(row)"
                  min="0">
                  </i-input>
                </div>
              </template>
            </i-table>
          </div>

          <span class="grid-pos1">Weekly Budget</span>
          <div class="grid-pos2" style="display: block;">

            <div class="weekly-budget-wrapper">
               <i-table class="weeklyBudgetTable"
                        size="small"
                         :columns="weeklyBudgetTableColumns"
                         :data="weeklyTableData"
                         :highlight-row="true">

              <template
              class="monthlyBudgetTableColumn"
              slot-scope="{ row }"
              slot="day">
                <div>{{row.Day}}</div>
              </template>

              <template
              class="monthlyBudgetTableColumn"
              slot-scope="{ row }"
              slot="budget" >
                <div>
                  <i-input
                  type="number"
                  class="weeklyBudgetInput"
                  v-model="row.Budget"
                  @on-change="weeklyBudgetValueChanged(row)"
                  min="0">
                  </i-input>
                </div>
              </template>
               <template
              class="monthlyBudgetTableColumn"
              slot-scope="{ row }"
              slot="buyMetric" >
                <div>
                  <i-input
                  type="number"
                  class="weeklyBuyMetricInput"
                  v-model="row.BuyMetric"
                  @on-change="weeklyBuyTypeValueChanged(row)"
                  min="0">
                  </i-input>
                </div>
              </template>
            </i-table>
            </div>
          </div>

          <span class="grid-pos1">Exception Days</span>
          <div class="grid-pos3" style="display: block;">

            <i-table
            id="exceptionDaysTable"
            class="exceptionDaysTable grid-pos2"
            size="small"
            :columns="exceptionDaysTableColumns"
            :data="tableExceptionData">

              <template
              class="exceptionDaysTableColumn"
              slot-scope="{ row }"
              slot="dateSlot">
                <div>
                  <i-date-picker
                  type="date"
                  v-model="row.ExceptionDate"
                  placeholder="Select date"
                  format="MM/dd/yyyy"
                  class="datePicker"
                  :clearable = false
                  :options="datePickerOptions"
                  @on-change="dateChanged($event, row.id)">
                  </i-date-picker>
                </div>
              </template>

              <template
              class="exceptionDaysTableColumn"
              slot-scope="{ row }"
              slot="budgetSlot">
                  <i-input
                  class="exceptionDayInput"
                  v-model="row.ExceptionBudget"
                  type="number"
                  min="0"
                  @on-change="exceptionBudgetValueChanged(row)">
                  </i-input>
              </template>
              <template
              class="exceptionDaysTableColumn"
              slot-scope="{ row }"
              slot="buyMetric">
                  <i-input
                  class="exceptionDayInput"
                  v-model="row.ExceptionBuyMetric"
                  type="number"
                  min="0"
                  @on-change="exceptionBuyRateValueChanged(row)">
                  </i-input>
              </template>

              <template
              class="exceptionDaysTableColumn"
              slot-scope="{ row }"
              slot="removeSlot">
                  <i @click="removeExceptionRow(row.id)" class="fas fa-times"></i>
              </template>

            </i-table>

            <Button class="addExceptionRow" @click="addExceptionRow">Add</Button>
          </div>

        </div>

        <div ref="advancedPacingSection" class="advanced-pacing-section" v-show="pacingType == '3'">
          <div ref="advancedPacingGraphWrapper" id="advancedPacingGraphWrapper" :class="{ 'advanced-pacing-graph-wrapper-show': showGraph, 'advanced-pacing-graph-wrapper-hide': !showGraph}" v-if="showGraphWrapper">
            <svg ref="advancedPacingGraph" class="advancedPacingGraph" id="advancedPacingGraph" v-show="showGraph">
            </svg>
          </div>
          <div v-if="pacingType == '3'">
            <hot-table
                class="hot-table"
                ref="table"
                :settings="hotTableSettings">
            </hot-table>
          </div>
        </div>
      </div>

      <div class="footer-wrapper" slot="footer">
          <span
          class="col-md-6 alert alert-warning line-item-message"
          style="text-align: left;"
          v-if="(cost!=updatedCost) && (pacingType!='1') ">
            Line Item Budget will be updated from {{cost}} to {{updatedCost}}
          </span>

          <span
          class="col-md-6"
          v-if="!(cost!=updatedCost) || (pacingType=='1')">
          </span>

          <div class="col-md-6 no-padding progress-track">
            <div
            class="col-md-7 no-padding"
            v-if="pacingType != '1'">
              <div>
                Budget: {{cost}},
                Total Allocated: {{updatedCost}}
              </div>
              <div>
                Balance : {{cost - updatedCost}}
              </div>
            </div>

            <div
            class="col-md-7 no-padding"
            v-if="pacingType == '1'">
            </div>

            <span
            class="progress-circle-wrap col-md-1"
            v-if="pacingType != '1'">
              <i-circle
              class="progressCircle"
              :percent="progressStrokePercentage"
              :size="30"
              :stroke-width="12"
              :trail-width="12"
              :stroke-color="circleColour">
                <span
                class="demo-Circle-inner circle-text">
                  {{showPercentage}}%
                </span>
              </i-circle>
            </span>

            <span
            class="progress-circle-wrap col-md-1"
            v-if="pacingType == '1'">
            </span>

            <span class="col-md-4 no-padding button-wrapper">
              <Button class="btn create-btn btn-white button-style" @click="cancel">Cancel</Button>
              <Button id="btn-save" class="btn create-btn btn-success button-style" @click="save">Save</Button>
            </span>
        </div>
      </div>
    </i-modal>
  </div>
</template>
<script>
import {
  Modal,
  RadioGroup,
  Radio,
  Table,
  Input,
  DatePicker,
  Circle
} from "iview";

import * as d3 from 'd3';

import { HotTable } from '@handsontable/vue';

import moment from 'moment';

export default {
  components: {
    "i-modal": Modal,
    "i-radio-group": RadioGroup,
    "i-radio": Radio,
    "i-table": Table,
    "i-input": Input,
    "i-date-picker": DatePicker,
    "i-circle": Circle,
    "hot-table": HotTable
  },

  props: {
    lineItemName: String,
    startdate: String,
    enddate: String,
    lineItemBudget: 0,
    stringCostPacingJson: String | undefined,
    orderId: Number,
    costPacingTypeId: Number
  },

  data () {
    var self = this;
    return {
      showModal: true,
      showGraphWrapper: true,
      pacingType: "1",
      monthlyBudgetTableColumns: [{ title: 'Month', key: 'month', slot: 'monthSlot', width: '90' }, { title: 'Budget', key: 'budget', align: 'center', slot: 'budgetSlot' }, { title: 'Buy Metric', key: 'BuyMetric', slot: 'buyMetricSlot'
      }],
      weeklyBudgetTableColumns: [{ title: 'Day', key: 'day', slot: 'day' }, { title: 'Budget', key: 'budget', align: 'center', slot: 'budget' }, { title: 'Buy Metric', key: 'buyMetric', slot: 'buyMetric'
      }],
      tableExceptionData: [],
      exceptionDays: [],
      monthWeightage: [],
      tableData: [],
      weeklyTableData: [{ "Day": "Sunday", "dayId": 0, "Budget": undefined, "BuyMetric": undefined },
        { "Day": "Monday", "dayId": 1, "Budget": undefined, "BuyMetric": undefined },
        { "Day": "Tuesday", "dayId": 2, "Budget": undefined, "BuyMetric": undefined },
        { "Day": "Wednesday", "dayId": 3, "Budget": undefined, "BuyMetric": undefined },
        { "Day": "Thursday", "dayId": 4, "Budget": undefined, "BuyMetric": undefined },
        { "Day": "Friday", "dayId": 5, "Budget": undefined, "BuyMetric": undefined },
        { "Day": "Saturday", "dayId": 6, "Budget": undefined, "BuyMetric": undefined }],
      customPacingStartDate: moment(),
      customPacingEndDate: moment(),
      exceptionDaysTableColumns: [{ title: 'Date', key: 'date', slot: 'dateSlot', align: 'center', width: '150' },
        { title: 'Budget', key: 'budget', slot: 'budgetSlot', align: 'center', width: '150' }, { title: 'BuyMetric', key: 'buyMetric', slot: 'buyMetric', align: 'center', width: '150' }, { title: 'Remove', key: 'remove', slot: 'removeSlot', align: 'center' }],
      exceptionRowNumber: 0,
      costPacingJson: JSON.parse('{}'),
      circleColour: "#ff7f50",
      progressPercentage: 100,
      progressStrokePercentage: 100,
      updatedCost: parseFloat(this.cost).toFixed(2),
      hotTableData: [],
      showGraph: true,
      cost: 0,
      validHotTableData: true,
      svgWidth: 0,
      svgHeight: 0,
      fieldEmptyError: "",
      maxRows: 0,
      datePickerOptions: {
        disabledDate (date) {
          var returnValue = false;
          if (moment(date) < moment(self.startdate) || moment(date) > moment(self.enddate)) {
            returnValue = true;
          } else {
            for (var i = 0; i < self.exceptionDays.length; i++) {
              if (moment(self.exceptionDays[i].ExceptionDate).valueOf() == moment(date).valueOf()) {
                returnValue = true;
              }
            }
          }
          return returnValue;
        }
      }
    }
  },

  mounted () {
    if (moment(this.enddate).diff(moment(this.startdate), 'd') > 35) {
      this.showGraphWrapper = false;
    }
    this.$nextTick(() => {
      this.svgHeight = 300;
      this.svgWidth = 1000;
    })
    if (this.lineItemBudget === undefined) {
      this.cost = 0;
    } else if (this.lineItemBudget === null) {
      this.cost = 0;
    } else {
      this.cost = parseFloat(this.lineItemBudget);
    }
    this.updatedCost = this.cost;
    this.customPacingStartDate = moment(this.startdate);
    this.customPacingEndDate = moment(this.enddate);

    $(".perfect-scroll-bar").perfectScrollbar({ suppressScrollX: true });
    this.pacingType = this.costPacingTypeId.toString();
  },

  computed: {
    hotTableSettings: function () {
      var self = this;
      return {
        data: this.hotTableData,
        maxRows: this.maxRows,
        colHeaders: ['Date', 'Budget', 'Buy Metric'],
        afterChange: (changes, source) => {
          if (source == 'edit' && changes[0][1] === "budget") {
            if (self.checkValidityOfInput(changes[0][3])) {
              d3.select('svg.advancedPacingGraph').selectAll('*').remove();
              let sum = 0;
              self.hotTableData.forEach(a => {
                if (isNaN(parseFloat(a.budget))) {
                  a.budget = 0;
                }
                sum += parseFloat(a.budget)
              });
              self.updatedCost = parseFloat(sum).toFixed(2);
              if (this.showGraphWrapper) {
                self.drawSvg();
              }
            }
          }
        },
        columns: [{ data: 'date', readOnly: true }, { data: 'budget', validator: this.hotTableDataValidator, type: 'numeric', className: 'htCenter htMiddle' }, { data: 'buyMetric', validator: this.hotTableDataValidator, type: 'numeric', className: 'htCenter htMiddle' }],
        className: "htMiddle",
        colWidths: [100, 100, 100],
        rowHeights: 30,
        viewportRowRenderingOffset: 200000,
        afterValidate: (isValid) => {
          if (!isValid) {
            this.validHotTableData = false;
          } else {
            this.validHotTableData = true;
          }
        }
      }
    },

    showPercentage: function () {
      this.progressPercentage = parseFloat((this.updatedCost / this.cost) * 100.0).toFixed(2);
      if (isNaN(this.progressPercentage)) {
        this.progressPercentage = 100;
      }
      this.progressStrokePercentage = this.progressPercentage;
      return this.progressPercentage;
    }
  },

  methods: {
    drawSvg () {
      var self = this;

      const delim = 4;
      const margin = { top: 10, right: 10, bottom: 50, left: 80 };
      const svgElement = d3.select('svg.advancedPacingGraph');

      let xValue = d => d.date;

      this.svgHeight = 300;
      this.svgWidth = 1000;

      const width = this.svgWidth;
      const innerWidth = width - margin.right - margin.left;
      const height = this.svgHeight;
      const innerHeight = height - margin.top - margin.bottom;

      var maxYAxisValue = d3.max(this.hotTableData, function (d) {
        if (isNaN(parseFloat(d.budget))) {
          return 0;
        } else {
          return parseFloat(d.budget * 2);
        }
      });

      if (maxYAxisValue == 0) {
        maxYAxisValue = 1000;
      }

      var totalBudget = maxYAxisValue;

      var scaleY = d3.scaleLinear()
        .domain([0, maxYAxisValue])
        .rangeRound([innerHeight, 0]);

      let xScale = d3.scaleBand()
        .domain(this.hotTableData.map(xValue))
        .range([0, innerWidth]);

      var yAxis = d3.axisLeft(scaleY);
      var yAxisSelector = d3.select('svg.advancedPacingGraph').append('g')
        .call(yAxis)
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      let xAxis = d3.axisBottom(xScale);
      d3.select('svg.advancedPacingGraph').append('g').call(xAxis)
        .attr('transform', `translate(${margin.left}, ${innerHeight + margin.top})`)
        .selectAll('text')
        .attr('y', 10)
        .attr('transform', 'rotate(45)')
        .style("text-anchor", "start");

      var x = d3.scaleLinear()
        .domain([0, this.hotTableData.length])
        .rangeRound([0, innerWidth]);

      var svgY = d3.select('svg.advancedPacingGraph')
        .append("svg")
        .attr("width", innerWidth + margin.left + margin.top)
        .attr("height", innerHeight + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      var brushY = d3.brushY()
        .extent(function (d, i) {
          return [[parseFloat(x(i) + delim / 2), 0],
            [parseFloat(x(i) + x(1) - delim / 2), innerHeight]];
        })
        .on("brush", brushmoveY)
        .on("end", brushendY);

      var svgbrushY = svgY
        .selectAll('.brush')
        .data(this.hotTableData)
        .enter()
        .append('g')
        .attr('class', 'brush')
        .append('g')
        .call(brushY)
        .call(brushY.move, function (d) { return [d.budget, 0].map(scaleY); });

      let sum = 0;
      self.hotTableData.forEach(a => {
        if (isNaN(parseFloat(a.budget))) {
          a.budget = 0;
        }
        sum += parseFloat(a.budget)
      });
      self.updatedCost = parseFloat(sum).toFixed(2);

      function brushendY () {
        if (!d3.event.sourceEvent) return;
        if (d3.event.sourceEvent.type === "brush") return;
        if (!d3.event.selection) {
          svgbrushY
            .call(brushY.move, function (d) {
              return [d.budget, 0].map(scaleY);
            })
        };
      }

      function brushmoveY () {
        if (!d3.event.sourceEvent) return;
        if (d3.event.sourceEvent.type === "brush") return;
        if (!d3.event.selection) return;

        var d0 = d3.event.selection.map(scaleY.invert);
        var d = d3.select(this).select('.selection');

        d.datum().budget = parseFloat(d0[0]);

        var maxBudget = 0;
        self.hotTableData.forEach(a => {
          if (a.budget > maxBudget) {
            if (isNaN(parseFloat(a.budget))) {
              a.budget = 0;
            }
            maxBudget = parseFloat(a.budget);
          }
        });

        if (maxBudget * 1.2 >= maxYAxisValue) {
          maxYAxisValue = parseFloat(maxYAxisValue * 1.2);
        } else if (maxBudget * 1.5 < maxYAxisValue) {
          maxYAxisValue *= 0.9;
          if (maxYAxisValue < totalBudget) {
            maxYAxisValue = parseFloat(totalBudget);
          }
        }
        scaleY.domain([0, maxYAxisValue]);
        yAxisSelector.call(yAxis);

        updateTheGraph();
      }

      function updateTheGraph () {
        svgbrushY
          .call(brushY.move, function (d) {
            return [d.budget, 0].map(scaleY);
          });
        let sum = 0;
        self.hotTableData.forEach(a => {
          if (isNaN(parseFloat(a.budget))) {
            a.budget = 0;
          }
          sum += parseFloat(a.budget);
        });
        self.updatedCost = parseFloat(sum).toFixed(2);
      }
    },
    hideFormat () {
      return null;
    },
    hotTableDataValidator (value, callbk) {
      if (this.checkValidityOfInput(value)) {
        callbk(true);
      } else {
        callbk(false);
      }
    },
    calculateNewTotalLineItemBudget () {
      if (this.pacingType == "2") {
        let sum = 0.0;
        this.monthWeightage.forEach(i => {
          if (isNaN(parseFloat(i.Budget))) {
            i.Budget = 0;
          }
          sum += parseFloat(i.Budget)
        });
        this.updatedCost = parseFloat(sum).toFixed(2);
        return sum;
      }
    },
    monthlyBudgetValueChanged (row) {
      var index = this.monthWeightage.findIndex(i => i.MonthId == row.MonthId);
      this.monthWeightage[index].Budget = row.Budget;
      let x = document.querySelectorAll('.monthlyBudgetInput input');
      this.calculateNewTotalLineItemBudget();
      if (this.checkValidityOfInput(row.Budget)) {
        x[index * 2].style.borderColor = "#dcdee2";
      } else {
        x[index * 2].style.borderColor = "red";
      }
    },
    monthlyBuyMetricValueChanged (row) {
      var index = this.monthWeightage.findIndex(i => i.MonthId == row.MonthId);
      let x = document.querySelectorAll('.monthlyBudgetInput input');
      if (this.checkValidityOfInput(row.BuyMetric)) {
        this.monthWeightage[index].BuyMetric = parseFloat(row.BuyMetric);
        x[index * 2 + 1].style.borderColor = "#dcdee2";
      } else {
        x[index * 2 + 1].style.borderColor = "red";
      }
    },
    weeklyBuyTypeValueChanged (row) {
      var x = document.querySelectorAll('.weeklyBuyMetricInput input');
      var dayData = this.weeklyTableData.find(i => i.dayId == row.dayId);
      dayData.BuyMetric = row.BuyMetric;
      var index = this.weeklyTableData.findIndex(i => i.dayId == row.dayId);
      if (row.BuyMetric < 0) {
        x[index].style.borderColor = "red";
      } else {
        x[index].style.borderColor = "#dcdee2";
      }
    },
    weeklyBudgetValueChanged (row) {
      var x = document.querySelectorAll('.weeklyBudgetInput input');
      var index = this.weeklyTableData.findIndex(i => i.dayId == row.dayId);

      var dayData = this.weeklyTableData.find(i => i.dayId == row.dayId);
      dayData.Budget = row.Budget;
      if (row.Budget < 0) {
        x[index].style.borderColor = "red";
      } else {
        x[index].style.borderColor = "#dcdee2";
      }
    },
    checkValidityOfInput (value) {
      if (isNaN(parseFloat(value)) || parseFloat(value) < 0 || !(String(value).match(/^\d+\.\d+$/) || String(value).match(/^\d+$/))) {
        return false;
      } else {
        return true;
      }
    },
    exceptionBudgetValueChanged (row) {
      let index = this.exceptionDays.findIndex(i => i.id == row.id);
      var x = document.querySelectorAll('.exceptionDayInput input');
      if (this.checkValidityOfInput(row.ExceptionBudget)) {
        x[index * 2].style.borderColor = "#dcdee2";
        this.changeExceptionBudgetValue(row, index);
      } else {
        x[index * 2].style.borderColor = "red";
      }
    },
    exceptionBuyRateValueChanged (row) {
      let index = this.exceptionDays.findIndex(i => i.id == row.id);
      var x = document.querySelectorAll('.exceptionDayInput input');
      if (this.checkValidityOfInput(row.ExceptionBuyMetric)) {
        x[index * 2 + 1].style.borderColor = "#dcdee2";
        this.exceptionDays[index].ExceptionBuyMetric = parseFloat(row.ExceptionBuyMetric);
      } else {
        x[index * 2 + 1].style.borderColor = "red";
      }
    },
    changeExceptionBudgetValue (row, index) {
      this.exceptionDays[index].ExceptionBudget = parseFloat(row.ExceptionBudget);
      let month = moment(this.exceptionDays[index].ExceptionDate).month();
      let sum = 0;
      this.exceptionDays.forEach(i => {
        if (moment(i.ExceptionDate).month() == month) {
          sum += parseFloat(i.ExceptionBudget);
        }
      });
      var monthBudgetIndex = this.monthWeightage.findIndex(i => i.MonthId == month);
      var monthBudget = parseFloat(this.monthWeightage[monthBudgetIndex].Budget);
      var x = document.querySelectorAll('.monthlyBudgetInput input');
      if (sum > monthBudget) {
        x[monthBudgetIndex * 2].style.borderColor = "red";
      } else {
        x[monthBudgetIndex * 2].style.borderColor = "#dcdee2";
      }
    },
    cancel () {
      this.$emit("hideCostPacingModal");
    },
    save () {
      var self = this;
      if (this.pacingType == "1") {
        let costPacingDetailsObject = { "costPacingJson": null,
          "orderId": this.orderId,
          "cost": this.updatedCost,
          "costPacingTypeId": 1 };
        this.$emit("saveCostPacingDetails", costPacingDetailsObject);
      } else if (this.pacingType == "2") {
        if (self.allowSave() === true) {
          var ExceptionDaysReturn = self.exceptionDays;
          ExceptionDaysReturn.forEach(i => {
            i.ExceptionBudget = i.ExceptionBudget + "";
            i.ExceptionDate = moment(i.ExceptionDate).format('MM/DD/YYYY');
          });
          var MonthWeightageReturn = self.monthWeightage;
          MonthWeightageReturn.forEach(i => {
            if (isNaN(parseFloat(i.Budget))) {
              i.Budget = 0;
            }
            i.Budget = parseFloat(i.Budget) + "";
            i.MonthId = i.MonthId + 1;
          });
          var dayOfWeekWeightageReturn = self.weeklyTableData;
          var costPacingJson = JSON.stringify({ "ExceptionDays": ExceptionDaysReturn,
            "MonthWeightage": MonthWeightageReturn,
            "DayOfWeekWeightage": dayOfWeekWeightageReturn });
          let costPacingDetailsObject = { "costPacingJson": costPacingJson,
            "orderId": self.orderId,
            "cost": self.updatedCost,
            "costPacingTypeId": 2 }
          self.$emit("saveCostPacingDetails", costPacingDetailsObject);
        }
      } else {
        if (this.validHotTableData) {
          var advancedPacingSaveFormat = [];
          this.hotTableData.forEach(a => {
            advancedPacingSaveFormat.push({ "Dates": a.date, "IsSelected": false, "DateBudget": a.budget, "DateBuyMetric": a.buyMetric });
          });
          if (advancedPacingSaveFormat.length >= 1) {
            advancedPacingSaveFormat[0].DateBudget = advancedPacingSaveFormat[0].DateBudget + "";
          }
          let costPacingDetailsObject = { "costPacingJson": JSON.stringify({ "DayWiseDistribution": advancedPacingSaveFormat }),
            "orderId": this.orderId,
            "cost": this.updatedCost,
            "costPacingTypeId": 3 };
          this.$emit("saveCostPacingDetails", costPacingDetailsObject);
        }
      }
    },
    allowSave () {
      var flag = 1;
      this.monthWeightage.forEach(i => {
        let monthBudget = parseFloat(i.Budget);
        if (isNaN(monthBudget)) {
          i.Budget = 0;
          monthBudget = 0;
        }
        let month = i.MonthId;
        let sum = 0;
        this.exceptionDays.forEach(a => {
          if (moment(a.ExceptionDate).month() == month) {
            if (isNaN(parseFloat(a.ExceptionBudget))) {
              a.ExceptionBudget = "0";
            }
            sum += parseFloat(a.ExceptionBudget);
          }
        });
        if (sum > monthBudget) {
          flag = 0;
        }
      });

      var x = document.querySelectorAll('.monthlyBudgetInput input');
      for (let i = 0; i < x.length; i = i + 1) {
        if (!this.checkValidityOfInput(x[i].value)) {
          flag = 0;
          x[i].style.borderColor = "red";
        }
      }

      var y = document.querySelectorAll('.exceptionDayInput input');
      for (let i = 0; i < y.length; i = i + 1) {
        if (!this.checkValidityOfInput(y[i].value)) {
          flag = 0;
          y[i].style.borderColor = "red";
        }
      }

      var z = document.querySelectorAll('.weeklyBuyMetricInput input');
      for (let i = 0; i < z.length; i = i + 1) {
        if ((z[i].value) < 0) {
          flag = 0;
          z[i].style.borderColor = "red";
        }
      }
      var weeklyBudgetCheck = document.querySelectorAll('.weeklyBudgetInput input');
      for (let i = 0; i < weeklyBudgetCheck.length; i = i + 1) {
        if ((weeklyBudgetCheck[i].value) < 0) {
          flag = 0;
          weeklyBudgetCheck[i].style.borderColor = "red";
        }
      }

      if (flag == 0) {
        return false;
      } else {
        return true;
      }
    },
    addExceptionRow () {
      var exceptionDate = moment(this.startdate);
      var datePresent = false;
      var exceptionEndDate = moment(this.enddate).valueOf();
      do {
        datePresent = this.isDatePresent(exceptionDate);
        if (datePresent == true) {
          exceptionDate.add(1, 'days');
        }
      } while (datePresent == true && exceptionDate.valueOf() <= exceptionEndDate);

      if (exceptionDate.valueOf() < moment(this.enddate).valueOf()) {
        this.exceptionDays.push({ 'id': ++this.exceptionRowNumber, "ExceptionDate": exceptionDate, "ExceptionBudget": "", ExceptionBuyMetric: "" });
        this.tableExceptionData = [];
        this.tableExceptionData = JSON.parse(JSON.stringify(this.exceptionDays));
        this.tableExceptionData.forEach(i => {
          if (isNaN(parseFloat(i.ExceptionBudget))) {
            i.ExceptionBudget = "0";
          }
          i.ExceptionDate = moment(i.ExceptionDate).toDate();
        });
      }
    },
    isDatePresent (exceptionDate) {
      for (var y = 0; y < this.exceptionDays.length; y++) {
        if (moment(this.exceptionDays[y].ExceptionDate).valueOf() == exceptionDate.valueOf()) {
          return true;
        }
      }
      return false;
    },
    removeExceptionRow (idValue) {
      if (this.exceptionRowNumber != 0) {
        let index = this.exceptionDays.findIndex(i => i.id == idValue);
        this.exceptionDays.splice(index, 1);
        this.tableExceptionData = [];
        this.tableExceptionData = JSON.parse(JSON.stringify(this.exceptionDays));
        this.tableExceptionData.forEach(i => {
          if (isNaN(parseFloat(i.ExceptionBudget))) {
            i.ExceptionBudget = "0";
          }
          i.ExceptionDate = moment(i.ExceptionDate).toDate();
        });
      }
    },
    dateChanged (date, idValue) {
      let index = this.exceptionDays.findIndex(i => i.id == idValue);
      this.exceptionDays[index].ExceptionDate = moment(date, "MM-DD-YYYY").toDate();
    },

    initialiseMonthWeightage (startDate, endDate) {
      // find the first date of the startdate;
      var startDateMonth = moment({ y: startDate.year(), M: startDate.month(), d: 1 });
      // find the firstdate of the ending month;
      var endDateMonth = moment({ y: endDate.year(), M: endDate.month(), d: 1 });
      var numberOfMonths = moment(endDateMonth).diff(moment(startDateMonth), 'months', true) + 1;
      for (var date = startDateMonth; date <= endDateMonth; date = date.add('month', 1)) {
        this.monthWeightage.push(
          {
            "MonthId": date.month(),
            "IsValid": true,
            "Year": date.year(),
            "Month": date.format('MMM'),
            "Budget": this.cost / numberOfMonths,
            "BuyMetric": 0
          }
        );
      }
    }
  },

  watch: {
    svgWidth: function (newValue, oldValue) {
      d3.select('svg.advancedPacingGraph').selectAll('*').remove();
      this.svgHeight = 300;
      this.svgWidth = 1000;
      if (this.showGraphWrapper && this.pacingType == '3') {
        this.drawSvg();
      }
    },

    updatedCost: function (newValue, oldValue) {
      if (isNaN(parseFloat(newValue))) {
        this.updatedCost = 0;
      } else {
        this.updatedCost = parseFloat(this.updatedCost).toFixed(2);
      }
    },

    progressPercentage: function (newValue, oldValue) {
      if (newValue < 100) {
        this.circleColour = "#ff7f50";
        this.progressStrokePercentage = this.progressPercentage;
      } else if (newValue == 100) {
        this.circleColour = "#32cd32";
        this.progressStrokePercentage = this.progressPercentage;
      } else {
        this.circleColour = "#ff0000";
        this.progressStrokePercentage = 100;
      }
    },

    pacingType: function (newValue, oldValue) {
      this.updatedCost = this.cost;
      var self = this;
      if (oldValue == "3") {
        d3.select('svg.advancedPacingGraph').selectAll('*').remove();
        self.showGraph = false;
      }
      if (newValue == "2") {
        if (!(typeof (this.stringCostPacingJson) === "undefined") && this.costPacingTypeId == 2) {
          this.costPacingJson = JSON.parse(this.stringCostPacingJson);
          this.costPacingJson.ExceptionDays.forEach(i => {
            i.ExceptionBudget = parseFloat(i.ExceptionBudget);
            if (i.ExceptionBuyMetric != undefined) {
              i.ExceptionBuyMetric = parseFloat(i.ExceptionBuyMetric);
            }
          });
          self.updatedCost = 0;
          this.costPacingJson.MonthWeightage.forEach(i => {
            i.Budget = parseFloat(i.Budget);
            self.updatedCost += i.Budget;
            if (i.BuyMetric == undefined || i.BuyMetric == null) {
              i.BuyMetric = 0;
            }
            i.MonthId = i.MonthId - 1;
          });
          this.tableExceptionData = this.costPacingJson.ExceptionDays;
          this.tableExceptionData.forEach(function (a, index) {
            a.ExceptionDate = moment(a.ExceptionDate).toDate();
            a.id = index + 1;
            self.exceptionRowNumber = a.id;

            if (isNaN(parseFloat(a.ExceptionBudget))) {
              a.ExceptionBudget = "0";
            }
          });

          this.exceptionDays = JSON.parse(JSON.stringify(this.tableExceptionData));
          this.exceptionDays.forEach(i => {
            i.ExceptionDate = moment(i.ExceptionDate).toDate();
            if (isNaN(parseFloat(i.ExceptionBudget))) {
              i.ExceptionBudget = "0";
            }
          });
          this.monthWeightage = JSON.parse(JSON.stringify(this.costPacingJson.MonthWeightage));
          this.monthWeightage.forEach(i => { i.Budget = i.Budget + "" });
          this.tableData = JSON.parse(JSON.stringify(this.monthWeightage));
          self.costPacingJson.DayOfWeekWeightage.forEach(function (item) {
            if (item.Budget == undefined || item.Budget == null) {
              item.Budget = undefined;
            }
            if (item.BuyMetric == undefined || item.BuyMetric == null) {
              item.BuyMetric = undefined;
            }
          });
          self.weeklyTableData = self.costPacingJson.DayOfWeekWeightage;
        } else {
          this.monthWeightage = [];
          this.initialiseMonthWeightage(this.customPacingStartDate, this.customPacingEndDate);
          this.tableData = JSON.parse(JSON.stringify(this.monthWeightage));
          this.tableData.forEach(i => {
            if (isNaN(parseFloat(i.Budget))) {
              i.Budget = 0;
            }
            i.Budget = parseFloat(i.Budget) + ""
          });
        }
      }
      if (newValue == "3") {
        this.showGraph = true;
        var numberOfDays = 0;
        if (!(typeof (this.stringCostPacingJson) === "undefined") && this.costPacingTypeId == 3) {
          var jsonHotTableData = JSON.parse(this.stringCostPacingJson);
          this.hotTableData = [];
          numberOfDays = 0;
          jsonHotTableData.DayWiseDistribution.forEach(a => {
            if (isNaN(parseFloat(a.DateBudget))) {
              a.DateBudget = 0;
            }
            if (a.DateBuyMetric == undefined || a.DateBuyMetric == null) {
              a.DateBuyMetric = 0;
            }
            numberOfDays++;
            a.DateBudget = parseFloat(a.DateBudget);
            a.DateBuyMetric = parseFloat(a.DateBuyMetric);
            this.hotTableData.push({ "date": a.Dates, "budget": a.DateBudget, "buyMetric": a.DateBuyMetric });
          });
        } else {
          var dateIterator = moment(this.customPacingStartDate);
          numberOfDays = 0;
          while (dateIterator <= this.customPacingEndDate) {
            numberOfDays++;
            dateIterator.add(1, 'd');
          }
          this.hotTableData = [];
          dateIterator = moment(this.customPacingStartDate);
          while (dateIterator <= this.customPacingEndDate) {
            this.hotTableData.push({ date: dateIterator.format('MM/DD/YYYY'), budget: parseFloat(this.cost / numberOfDays).toFixed(2), buyMetric: 0 });
            dateIterator.add(1, 'd');
          }
        }
        if (this.svgHeight >= 300) {
          if (this.showGraphWrapper) {
            this.drawSvg();
          }
        }
        if (numberOfDays > 35) {
          this.showGraph = false;
          this.showGraphWrapper = false;
        } else {
          this.showGraph = true;
          this.showGraphWrapper = true;
        }
        this.maxRows = numberOfDays;
      }
    }
  }
}
</script>

<style scoped>
.header-size{
  font-size:17px;
  margin-top: 0px;
  margin-bottom: 0px;
}

::v-deep .ivu-modal-wrap {
  display: flex;
  align-items: center;
}

::v-deep .ivu-modal {
  top: 0;
}

::v-deep .ivu-radio-checked {
  border-color: #717272 !important;
}

::v-deep .ivu-radio-inner {
  border-color: #717272 !important;
}

::v-deep .ivu-radio-inner:after {
  background-color: #717272;
}

::v-deep .ivu-icon-ios-close {
  font-size: 2em !important;
  padding: 0.1em;
}

::v-deep .ivu-modal-header {
  border-bottom: 3px solid #dcdee2;
}

.advanced-pacing-graph-wrapper-show {
  height: 300px;
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
}

.advanced-pacing-graph-wrapper-hide {
  height: 1px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.advancedPacingGraph {
  width: 100%;
  height: 100%;
  min-height: 40px;
}

.even-pacing {
  color: #A9A9A9;
}

.datePicker {
  width: 100%;
}

.monthlyBudgetTable {
    width: 350px;
    margin-bottom: 4%;
}

.button-wrapper {
  float: right;
}

.weekly-budget-wrapper {
  margin-bottom: 4%;
  padding-left: 57px;
}

.budget-separation {
    margin: 2% auto 0% auto;
}

.budget-separation-grid {
    padding: 0%;
    width: 500px;
    display: grid;
    grid-template-columns: 22.5% 55% 22.5%;
}

::v-deep .grid-item {
    padding-top: 1em;
    display: inline;
}

.table-grid {
  display: grid;
  grid-template-columns: 20% 2.5% 2.5% 5% 10% 10% 10% 20% 20%;
  grid-row-gap: 5%;
}

.grid-pos1 {
  grid-column-start: 1;
}

.grid-pos2 {
  grid-column-start: 2;
}

.grid-pos3 {
  grid-column-start: 3;
}

.grid-pos4 {
  grid-column-start: 4;
}

.grid-pos5 {
  grid-column-start: 5;
}

.grid-pos6 {
  grid-column-start: 6;
}

.grid-pos7 {
  grid-column-start: 7;
}

.grid-pos8 {
  grid-column-start: 8;
}

.grid-pos9 {
  grid-column-start: 9;
}

.radio {
  margin: 0% 15% 0% 0%;
}

.radio-group {
  width: 100%;
}

.overflow-hidden {
  overflow-y: hidden !important;
  overflow-x: visible !important;
}

.overflow-visible {
  overflow-y: visible !important;
}

.exceptionDaysTable {
    width: 535px;
}

.line-item-message {
  font-size: 13px;
}

.progress-track {
  font-size: 11px;
}

.exceptionDaysTableWrapper {
  margin: 0% auto;
}

::v-deep .addExceptionRow {
    width: 100px;
    margin-top: 12px;
}

.modal-body {
    padding: 0% !important;
    height: auto;
    max-height: 380px;
}

.hot-table {
  overflow: visible !important;
  margin: 2% auto 0% auto;
  width: 500px;
  text-align: center;
}

::v-deep .handsontable td.htInvalid {
  border: 1px solid red;
  background-color: white !important;
}

.progress-circle-wrap {
  text-align: left;
}

.progressCircle {
  vertical-align: middle;
  background-color: black;
  border-radius: 20px 20px 20px 20px;
}

.advanced-pacing-section {
  text-align: center;
}

.footer-wrapper {
  height: 3em;
}

.circle-text {
  font-size: 10px;
  color: white;
}

.ivue-slider {
  width: 50px;
}

.slider-value {
  text-align: center;
}

::v-deep .ivu-slider-bar {
  background: #D3D3D3 !important;
}

::v-deep .ivu-slider-button {
  border-color: #D3D3D3 !important;
}

::v-deep .selection {
  fill: steelblue;
  fill-opacity: 1;
}

::v-deep .wtHolder {
  width: 100% !important;
  height: auto !important;
}
.field-empty-error{
  border: red solid 1px;
}
::v-deep .ivu-input:hover {
  border-color: #ddd;
}
::v-deep .ivu-input:focus,
::v-deep .ivu-input-focused {
  border-color: #ddd !important;
  box-shadow: 0 0 0 1px white !important;
}
.weeklyBudgetTable {
    width: 350px;
    margin-bottom: 4%;
}
</style>
