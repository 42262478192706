const FacebookSdk = {};
FacebookSdk.install = function (Vue, options) {
  (function (d, s, id) {
    var js; var fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) { return }
    js = d.createElement(s)
    js.id = id
    js.src = "//connect.facebook.net/en_US/sdk.js"
    fjs.parentNode.insertBefore(js, fjs)
    console.log('setting fb sdk')
  }(document, 'script', 'facebook-jssdk'))

  window.fbAsyncInit = function () {
    // eslint-disable-next-line no-undef
    FB.init(options)
    // eslint-disable-next-line no-undef
    FB.AppEvents.logPageView()
    // eslint-disable-next-line no-undef
    Vue.FB = FB
    window.dispatchEvent(new Event('fb-sdk-ready'))
  }
  Vue.FB = undefined;
}

FacebookSdk.getMixin = function (Vue) {
  return {
    data () {
      return { isFbSdkReady: false }
    },
    mounted () {
      this.isFbSdkReady = Vue.FB != undefined
      window.addEventListener('fb-sdk-ready', this.onFbSdkReady)
    },
    beforeDestroy () {
      window.removeEventListener('fb-sdk-ready', this.onFbSdkReady)
    },
    methods: {
      onFbSdkReady () {
        this.isFbSdkReady = true
      }
    }
  }
}

export { FacebookSdk }
