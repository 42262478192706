<template>
    <div>
      <modal style="color:black;" v-model="showModal" @cancel="CloseAudiencePopUp()" title="Audience" width="90%" >
        <div slot="modal-body" class="modal-body" style="min-height:0px;">
          <Row>
            <column span="11" style="width:49%;padding-right:1%">
                <i-tab type="card" name="audiencePopUpTab">
                  <i-tabPane label="Categories" tab="audiencePopUpTab">
                    <Row align="middle" type="flex">
                      <column span="4" style="text-align: end;">
                        <label style="margin-top: 1%; padding-right:1%;">LookBack:</label>
                      </column>
                      <column span="5" style="padding-right:1%">
                        <Select v-model="categoryLookBackDays">
                          <Option v-for="item in LookBackDays" :value="item.id" :key="item.id">{{ item.label }}</Option>
                        </Select>
                      </column>
                      <column span="14">
                        <i-input @keyup.enter.native="searchCategories()" v-model="search" search placeholder="Search" class="search-bar input-search-bar" />
                      </column>
                    </Row>
                    <br/>
                    <div v-show="showDrawer" class = "refineOuter">
                    <div class="popUpBackdrop"></div>
                      <Card class="refineClass">
                        <p slot="title">Refine Category: {{RefineDataTitle}}</p>
                          <Row type="flex" align="middle">
                            <label class="labelClass">Price Range</label>
                          </Row>
                          <Row align="middle" type="flex">
                            <column span="8">
                              <i-input v-model="minPrice" type="number" style="margin-left:1%;margin-right:1%">
                              <span slot="prepend">{{currencyCode}}</span>
                              </i-input>
                            </column>
                            <column span="1" style="text-align:center">
                              <p>-</p>
                            </column>
                            <column span="8">
                              <i-input v-model="maxPrice" type="number" style="padding-right:1%">
                              <span slot="prepend">{{currencyCode}}</span>
                              </i-input>
                            </column>
                          </Row>
                          <Row type="flex" align="middle">
                            <column span="5" class="label-text">
                            </column>
                            <column span="17">
                              <span style='color:#b94a48'>{{ priceErrorMessage }}</span>
                            </column>
                          </Row>
                          <br/>
                          <Row type="flex" align="middle">
                                  <label class="labelClass">Review star ratings</label>
                          </Row>
                          <Row align="middle" type="flex">
                            <column span="20" style="margin-left:1%">
                              <Slider v-model="slider" :step="1" :min="0" :max="5" show-stops range></Slider>
                            </column>
                            <column span="3" style="margin-left:2%">
                              <p>{{slider[0]}} - {{slider[1]}} stars</p>
                            </column>
                          </Row>
                          <br/>
                          <Row type="flex" align="middle">
                            <label class="labelClass">Shipping</label>
                          </Row>
                          <Row align="middle" type="flex">
                            <column span="20" style="margin-left:1%">
                              <RadioGroup v-model="shipping">
                                <Radio label="All"></Radio>
                                <Radio label="Prime eligible"></Radio>
                                <Radio label="Not Prime eligible"></Radio>
                              </RadioGroup>
                            </column>
                          </Row>
                          <br/>
                        <button type="button" class="btn btn-white" v-on:click="ResetRefinements()">Close</button>
                        <button type="button" class="btn btn-success" v-on:click="AddRefinements(data)">Add</button>
                      </Card>
                    </div>
                    <Tree v-model="tree" :data="filteredData" :render="renderContent"  :load-data = "loadOptions" style="height:220px;overflow:auto"></Tree>
                  </i-tabPane>
                  <i-tabPane label='Products' tab="audiencePopUpTab">
                    <Row align="middle" type="flex">
                      <column span="4" style="text-align: end;">
                        <label style="margin-top: 1%; padding-right:1%;">LookBack:</label>
                      </column>
                      <column span="5" style="left:1%">
                        <Select v-model="productLookBackDays">
                          <Option v-for="item in LookBackDays" :value="item.id" :key="item.id">{{ item.label }}</Option>
                        </Select>
                      </column>
                    </Row>
                    <br/>
                    <div v-for="item in productType" :name="JSON.stringify(item)" :key="item.id">
                      <Row>
                        <column span="20" style="padding-left:3rem">
                        {{item.name}}
                        </column>
                        <column span="4" style="text-align:end;">
                          <Button type="text" :disabled="item.isAdded ? true : false" @click="AddProduct(item)">Add</Button>
                        </column>
                      </Row>
                    </div>
                  </i-tabPane>
                  <i-tabPane label='Amazon Audience' tab="audiencePopUpTab">
                    <Row>
                      <i-input  v-model="filteredAudienceKey" @keyup.enter.native="searchAudience()" search placeholder="Search" class="search-bar input-search-bar" />
                    </Row>
                    <br/>
                    <Row>
                      <Tree v-model="tree1" :data="filteredAudience" :render="renderAudienceContent" :load-data= "loadAudienceOptions" style="height:220px;overflow:auto"></Tree>
                    </Row>
                  </i-tabPane>
                </i-tab>
            </column>
            <column span="1" class="separator">
            </column>
            <column span="11" style="width:48%;">
              <Card style="display:contents">
                <p slot="title">Audiences</p>
                <Tag v-for="(item, index) in listAudience" :name="JSON.stringify(item)" closable :key="index" class="audienceList" @on-close="handleClose">
                  <Row>
                    <column span="10">
                      {{item.type}}<br/>
                      <div v-if="item.category != ''"><b>Category: {{item.category}}</b></div>
                      <div v-if="item.product != ''"><b>{{item.product}}</b></div>
                      <div v-if="item.lookBackDays != ''"><b>LookBackDays: </b>{{item.lookBackDays}}</div>
                      <div v-if="item.audience != ''"><b>{{item.audience}}</b></div>
                    </column>
                    <column span="8">
                      <div v-if="item.price != ''"><b>Price: </b>{{item.price}}</div>
                      <div v-if="item.rating != ''"><b>Rating: </b>{{item.rating}}</div>
                      <div v-if="item.shippingValue != ''"><b>Shipping: </b>{{item.shippingValue}}</div>
                      <div v-else><p style="width: 120px"></p></div>
                    </column>
                    <column span="4" offset="1">
                     <div v-if="showBidProp">
                       <i-input v-model.number="item.bid" placeholder="Bid" type="number" style="margin-top:5px">
                      <span slot="prepend">{{currencyCode}}</span>
                      </i-input>
                     </div>
                    </column>
                  </Row>
                </Tag>
              </Card>
            </column>
          </Row>
        </div>
        <div slot="modal-footer">
         <div class="footer-div">
            <button type="button" class="btn btn-white" v-on:click="CloseAudiencePopUp()">Close</button>
            <button type="button" class="btn btn-success" v-on:click="SaveAudience()">Save</button>
         </div>
      </div>
      </modal>
    </div>
</template>
<script>
import { Tabs, TabPane, locale, Tree, Row, Col, Card, Input, RadioGroup, Radio, Tag, Button, Select, Option, Slider } from "iview";
import { APIService } from "../ApiService.js";
import { modal } from 'vue-strap';
import lang from "iview/dist/locale/en-US";
locale(lang);
export default {
  components: {
    "modal": modal,
    'i-tab': Tabs,
    'i-tabPane': TabPane,
    Tree,
    Row,
    "column": Col,
    Card,
    "i-input": Input,
    RadioGroup,
    Radio,
    Slider,
    Tag,
    Button,
    Select,
    Option
  },
  props: {
    showModalProp: Boolean,
    country: String,
    clusterId: Number,
    audienceProp: Object,
    currencyCode: { default: '₹', String },
    showBidProp: { type: Boolean, default: false },
    bidProp: { type: Number, default: 0 },
    showRefine: { type: Boolean, default: false }
  },
  data: function () {
    return {
      allCategoryNames: [],
      data: [],
      RefineDataTitle: '',
      showModal: false,
      filteredData: [],
      closeDeleteModal: false,
      search: "",
      loader: false,
      searchData: [],
      categorydata: [],
      child: [],
      tree: "",
      showDrawer: false,
      minPrice: '',
      maxPrice: '',
      slider: [0, 5],
      shipping: 'All',
      categories: [],
      categoryId: '',
      buttonProps: {
        type: 'text',
        size: 'small'
      },
      listAudience: [],
      allAudienceNames: [],
      tree1: "",
      productType: [
        {
          id: 1,
          name: 'Advertised products',
          isAdded: false
        },
        {
          id: 2,
          name: 'Similar to advertised products',
          isAdded: false
        }
      ],
      products: [],
      audienceData: [],
      audiences: [],
      productLookBackDays: 30,
      categoryLookBackDays: 30,
      filteredAudience: [],
      filteredAudienceKey: '',
      priceErrorMessage: '',
      LookBackDays: [
        {
          id: 7,
          label: '7 Days'
        },
        {
          id: 14,
          label: '14 Days'
        },
        {
          id: 30,
          label: '30 Days'
        },
        {
          id: 60,
          label: '60 Days'
        },
        {
          id: 90,
          label: '90 Days'
        }

      ],
      render: (h, { root, node, data }) => {
        return h('span', {
          style: {
            display: 'inline-block',
            width: '98%',
            paddingLeft: '2%',
            fontSize: '14px'
          }
        }, [
          h('span', data.title)
        ]);
      },
      bid: 0,
      audiencesWithBid: []
    };
  },
  created () {
    this.fetchCategories();
    this.fetchAudiences();
    this.fetchCategoriesNames();
    this.fetchAudiencesNames();
  },
  methods: {
    fetchCategoriesNames () {
      APIService
        .GetCategoriesNames()
        .then((response) => {
          this.allCategoryNames = response.data.data;
        })
        .catch((x) => {
          this.errorOccurred = true;
          this.errorMessage = "Unable to fetch categories data.";
        });
    },
    fetchAudiencesNames () {
      APIService
        .GetAudienceNames()
        .then((response) => {
          this.allAudienceNames = response.data.data;
        })
        .catch((x) => {
          this.errorOccurred = true;
          this.errorMessage = "Unable to fetch categories data.";
        });
    },
    handleClose (event, name) {
      var obj = JSON.parse(name);
      var self = this;
      if (obj.category != '') {
        let minimumPrice = obj.price != "" ? obj.price.includes("-") ? obj.price.split("-")[0] : obj.price.includes("above") ? obj.price.split(" and above")[0] : 0 : 0;
        let maximumPrice = obj.price != "" ? obj.price.includes("-") ? obj.price.split("-")[1] : obj.price.includes("below") ? obj.price.split(" and below")[0] : 0 : 0;
        let shippingType = obj.shippingValue == '' ? null : obj.shippingValue == "Prime eligible" ? 1 : 0;
        let lookdays = obj.lookBackDays.includes(" ") ? obj.lookBackDays.split(" ")[0] : obj.lookBackDays;
        let listInd = this.listAudience.findIndex(cat => cat.categoryId == obj.categoryId && cat.lookBackDays == obj.lookBackDays && cat.price == obj.price && cat.rating == obj.rating && cat.shippingValue == obj.shippingValue)
        this.listAudience.splice(listInd, 1)

        var catInd = this.categories.findIndex(cat => {
          if (cat.refinements != undefined) {
            return cat.categoryId == obj.categoryId && cat.lookBackDays == lookdays && cat.refinements.minPrice == minimumPrice && cat.refinements.maxPrice == maximumPrice && cat.refinements.rating == obj.rating && cat.refinements.isPrimeShippingEligible == shippingType
          } else {
            return cat.categoryId == obj.categoryId && cat.lookBackDays == lookdays
          }
        });
        this.categories.splice(catInd, 1);
      }
      if (obj.product != '') {
        let listInd = this.listAudience.findIndex(cat => cat.productId == obj.productId && cat.lookBackDays == obj.lookBackDays)
        this.listAudience.splice(listInd, 1);
        let lookdays = obj.lookBackDays.includes(" ") ? obj.lookBackDays.split(" ")[0] : obj.lookBackDays;
        var proInd = this.products.findIndex(cat => cat.productType == obj.productId && cat.lookBackDays == lookdays);
        this.products.splice(proInd, 1);
        if (this.productLookBackDays == lookdays) {
          var proTypeInd = this.productType.findIndex(cat => cat.id == obj.productId);
          this.productType[proTypeInd].isAdded = false;
        }
      }
      if (obj.audience != '') {
        let listInd = this.listAudience.findIndex(cat => cat.audienceId == obj.audienceId)
        this.listAudience.splice(listInd, 1)
        let audInd = this.audiences.findIndex(cat => cat == obj.audienceId);
        this.audiences.splice(audInd, 1);
        if (this.audienceData[obj.typeId - 1].children.length > 0) {
          let listAudInd = this.audienceData[obj.typeId - 1].children.findIndex(cat => cat.id == obj.audienceId);
          self.$set(this.audienceData[obj.typeId - 1].children[listAudInd], 'class', 'ivu-btn ivu-btn-text');
        }
      }
    },
    SaveAudience () {
      this.showModal = false;
      let Audience = {};
      if (this.showBidProp) {
        for (var i = 0; i < this.listAudience.length; i++) {
          if (this.listAudience[i].audienceId != "") {
            for (var audienceIndex = 0; audienceIndex < this.audiencesWithBid.length; audienceIndex++) {
              if (this.listAudience[i].audienceId == this.audiencesWithBid[audienceIndex].audienceId) {
                this.audiencesWithBid[audienceIndex].bid = this.listAudience[i].bid
              }
            }
          }
          if (this.listAudience[i].productId != "") {
            for (var productIndex = 0; productIndex < this.products.length; productIndex++) {
              if (this.listAudience[i].productId == this.products[productIndex].productType) {
                this.products[productIndex].bid = this.listAudience[i].bid
              }
            }
          }
          if (this.listAudience[i].categoryId != "") {
            for (var categoryIndex = 0; categoryIndex < this.categories.length; categoryIndex++) {
              if (this.listAudience[i].categoryId == this.categories[categoryIndex].categoryId) {
                this.categories[categoryIndex].bid = this.listAudience[i].bid
              }
            }
          }
        }
        Audience.audienceId = this.audiences
        Audience.targetId = this.clusterId
        Audience.product = this.products
        Audience.category = this.categories
        Audience.audienceIds = this.audiencesWithBid
      } else {
        Audience.audienceId = this.audiences
        Audience.clusterId = this.clusterId
        Audience.product = this.products
        Audience.category = this.categories
      }
      this.ResetPopUp();
      this.$emit("AddAudience", Audience);
    },
    ResetPopUp () {
      this.categories = [];
      this.products = [];
      this.audiences = [];
      this.minPrice = '';
      this.maxPrice = '';
      this.slider = [0, 5];
      this.shipping = 'All';
      this.listAudience = [];
      this.productType.forEach(ele => {
        ele.isAdded = false;
      });
      this.filteredAudience.forEach(ele => {
        ele.children = [];
        this.$set(ele, 'expand', false);
      });
      this.bid = this.bidProp
    },
    CloseAudiencePopUp () {
      this.showModal = false;
      this.ResetPopUp();
      this.$emit("CloseAudiencePopUp");
    },
    renderContent (h, { root, node, data }) {
      return h('span', {
        style: {
          display: 'inline-block',
          width: '98%',
          paddingLeft: '2%',
          fontSize: '13px'
        }
      }, [
        h('span', data.categoryName),
        h('span', {
          style: {
            display: 'inline-block',
            float: 'right',
            marginRight: '2%',
            fontSize: '10px'
          }
        }, [
          h('Button', {
            props: Object.assign({}, {
            }, {
            }),
            class: 'ivu-btn ivu-btn-text',
            style: {
              visibility: this.showRefine ? 'visible' : 'hidden'
            },
            on: {
              click: () => { this.RefineData(data) }
            }
          }, 'Refine'),
          h('Button', {
            props: Object.assign({}, {}, {
            }),
            class: 'ivu-btn ivu-btn-text',
            on: {
              click: () => { this.AddCategories(data); }
            }
          }, 'Add')
        ])
      ]);
    },
    renderAudienceContent (h, { root, node, data }) {
      return h('span', {
        style: {
          display: 'inline-block',
          width: '98%',
          paddingLeft: '2%',
          fontSize: '13px'
        }
      }, [
        h('span', data.title),
        h('span', {
          style: {
            display: 'inline-block',
            float: 'right',
            marginRight: '2%',
            fontSize: '10px'
          }
        }, [
          h('Button', {
            props: Object.assign({}, {}, {
            }),
            class: data.class,
            on: {
              click: () => { this.AddAudiences(data); }
            }
          }, 'Add')
        ])
      ]);
    },
    AddProduct (item) {
      this.listAudience.push({
        type: "View Remarketing",
        product: item.name,
        category: "",
        lookBackDays: this.productLookBackDays + " Days",
        productId: item.id,
        audience: '',
        audienceId: '',
        typeId: '',
        price: '',
        rating: '',
        shippingValue: '',
        bid: this.bidProp
      })
      this.products.push({
        remarketingType: 1,
        productType: item.id,
        lookBackDays: this.productLookBackDays
      })
      item.isAdded = true;
    },
    AddAudiences (item) {
      var self = this;
      self.$set(item, 'class', 'ivu-btn ivu-btn-text disabled');
      this.listAudience.push({
        type: this.audienceData[item.type - 1].title,
        product: "",
        category: "",
        lookBackDays: "",
        productId: "",
        audience: item.title,
        audienceId: item.id,
        typeId: item.type,
        price: '',
        rating: '',
        shippingValue: '',
        bid: this.bidProp
      })

      this.audiences.push(
        item.id
      )
      this.audiencesWithBid.push({ audienceId: item.id })
    },
    RefineData (data) {
      this.showDrawer = true;
      this.RefineDataTitle = data.categoryName;
      this.categoryId = data.amsCategoryId;
    },
    loadOptions (node, callback) {
      var self = this;
      let bpseId = self.$parent.configColumns.bpseId;
      APIService
        .GetCategories(node.amsCategoryId, bpseId)
        .then((response) => {
          var index = self.data.findIndex((x) => x.amsCategoryId == node.amsCategoryId);
          var normalizeData = response.data.data;
          normalizeData.forEach(item => {
            item.class = 'ivu-btn ivu-btn-text';
            if (item.hasChildren) {
              item.children.forEach(child => {
                child.class = 'ivu-btn ivu-btn-text';
              });
            }
          });
          if (self.data[index].hasChildren) {
            self.$set(self.data[index], 'children', normalizeData);
          } else {
            self.$set(self.data[index], 'children', []);
          }
          self.filteredData = self.data;
          callback(normalizeData);
        })
    },
    loadAudienceOptions (node, callback) {
      var self = this;
      APIService
        .GetAudience("", node.id)
        .then((response) => {
          response.data.data.forEach(ele => {
            ele.title = ele.audienceName;
            if (this.audiences.includes(ele.id)) {
              self.$set(ele, 'class', 'ivu-btn ivu-btn-text disabled');
            } else {
              self.$set(ele, 'class', 'ivu-btn ivu-btn-text');
            }
          })
          let data = self.audienceData[(node.id) - 1];
          data.children = response.data.data;
          this.$set(self.audienceData, 'children', data.children);
          callback(data.children);
        })
    },
    fetchCategories: function () {
      var self = this;
      self.categorydata = [];
      let bpseId = self.$parent.configColumns.bpseId;
      APIService
        .GetCategories(0, bpseId)
        .then((response) => {
          self.categorydata = response.data.data;
          self.categorydata.forEach(node => {
            self.data.push(node);
            self.$set(node, 'class', 'ivu-btn ivu-btn-text');
            if (node.hasChildren) {
              self.$set(node, 'loading', false)
            }
          })
          self.filteredData = self.data;
        })
        .catch((x) => {
          this.errorOccurred = true;
          this.errorMessage = "Unable to fetch categories data.";
        });
    },
    fetchAudiences: function () {
      var self = this;
      self.audienceData = [];
      APIService
        .GetAudience(this.country, 0)
        .then((response) => {
          response.data.data.forEach(ele => {
            if (ele.type == 0) {
              ele.children = [];
              ele.title = ele.audienceName;
              self.$set(ele, 'loading', false)
              self.$set(ele, 'render', this.render);
              self.audienceData.push(ele);
            }
          });
          self.filteredAudience = self.audienceData;
        })
        .catch((x) => {
          this.errorOccurred = true;
          this.errorMessage = "Unable to fetch categories data.";
        });
    },
    filterNodeData (child, query) {
      var filterData = [];
      if (child.children.length > 0) {
        filterData = child.children.filter(c => {
          return c.title.toLowerCase().includes(query.toLowerCase())
        })
        child.children.forEach(e => {
          this.filterNodeData(e, query);
        })
      }
      if (filterData.length > 0) {
        filterData.forEach(f => {
          this.searchData.push({
            title: f.title,
            children: [],
            class: 'ivu-btn ivu-btn-text'
          })
        });
      }
    },
    ValidateRefinements () {
      if (this.maxPrice != "" && this.minPrice != "" && parseInt(this.maxPrice) < parseInt(this.minPrice)) {
        this.priceErrorMessage = 'Maximum price cannot be less than minimum price';
        return false;
      }
      if (this.maxPrice < 0 || this.minPrice < 0) {
        this.priceErrorMessage = 'Price value cannot be negative'
        return false;
      }
      return true;
    },
    ResetRefinements () {
      this.showDrawer = false;
      this.minPrice = "";
      this.maxPrice = "";
      this.slider = [0, 5];
      this.shipping = 'All';
      this.priceErrorMessage = '';
    },
    AddRefinements () {
      this.priceErrorMessage = '';
      let isValid = this.ValidateRefinements();
      if (isValid) {
        this.categories.push({
          remarketingType: 1,
          categoryId: this.categoryId,
          refinements: {
            minPrice: this.minPrice == '' ? 0 : this.minPrice,
            maxPrice: this.maxPrice == '' ? 0 : this.maxPrice,
            rating: this.slider[0] == 0 && this.slider[1] == 5 ? '' : this.slider[0] + "-" + this.slider[1],
            isPrimeShippingEligible: this.shipping == "All" ? null : this.shipping == "Prime eligible" ? 1 : 0
          },
          lookBackDays: this.categoryLookBackDays,
          isAudienceTargeting: 1
        })
        this.listAudience.push({
          type: "View Remarketing",
          category: this.RefineDataTitle,
          lookBackDays: this.categoryLookBackDays + " Days",
          product: "",
          productId: "",
          categoryId: this.categoryId,
          audience: '',
          audienceId: '',
          typeId: '',
          price: this.minPrice != '' && this.maxPrice != '' ? this.minPrice + "-" + this.maxPrice
            : this.maxPrice == '' && this.minPrice != '' ? this.minPrice + " and above"
              : this.minPrice == '' && this.maxPrice != '' ? this.maxPrice + " and below"
                : '',
          rating: this.slider[0] != 0 && this.slider[1] != 5 ? this.slider[0] - this.slider[1] : '',
          shippingValue: this.shipping != "All" ? this.shipping : '',
          bid: this.bidProp
        })
        this.ResetRefinements();
      }
    },
    AddCategories (data) {
      this.categories.push({
        remarketingType: 1,
        categoryId: data.id,
        lookBackDays: this.categoryLookBackDays,
        isAudienceTargeting: 1
      })
      this.listAudience.push({
        type: "View Remarketing",
        category: data.title,
        lookBackDays: this.categoryLookBackDays + " Days",
        categoryId: data.id,
        productId: "",
        audience: '',
        audienceId: '',
        typeId: '',
        product: '',
        price: '',
        rating: '',
        shippingValue: '',
        bid: this.bidProp
      })
    },
    searchCategories () {
      var self = this;
      let searchTerm = this.search;
      self.searchData = [];
      if (searchTerm != "") {
        var dataCheck = self.data.filter(e => {
          if (e.children.length > 0) {
            self.filterNodeData(e, searchTerm);
          }
          return e.title.toLowerCase().includes(searchTerm.toLowerCase())
        })
        dataCheck.forEach(d => {
          self.searchData.push({
            title: d.categoryName,
            class: 'ivu-btn ivu-btn-text',
            children: []
          });
        })
        this.filteredData = self.searchData;
      } else {
        this.filteredData = this.data;
      }
    },
    searchAudience () {
      var self = this;
      let searchTerm = this.filteredAudienceKey;
      let searchAudienceData = [];
      var dataCheck = [];
      if (searchTerm != "") {
        self.audienceData.filter(e => {
          if (e.children.length > 0) {
            dataCheck.push(e.children.filter(c => {
              return c.title.toLowerCase().includes(searchTerm.toLowerCase())
            }));
          }
        })
        dataCheck.forEach(d => {
          d.forEach(entity => {
            searchAudienceData.push(
              entity
            );
          })
        })
        this.filteredAudience = searchAudienceData;
      } else {
        this.filteredAudience = this.audienceData;
      }
    },
    editAudience () {
      this.listAudience = [];
      this.audiencesWithBid = [];
      this.products = this.audienceProp.product;
      this.categories = this.audienceProp.category;
      this.audiences = this.audienceProp.audienceId;
      this.audiencesWithBid = this.audienceProp.audienceIds;
      this.products.forEach(ele => {
        var lookback = this.LookBackDays.filter(e => e.id == ele.lookBackDays).map(e => e.label);
        this.productType[ele.productType - 1].isAdded = true;
        this.listAudience.push({
          type: "View Remarketing",
          category: "",
          lookBackDays: lookback[0],
          categoryId: "",
          audience: '',
          audienceId: '',
          productId: ele.productType,
          typeId: '',
          product: this.productType[ele.productType - 1].name,
          price: '',
          rating: '',
          shippingValue: '',
          bid: ele.bid != 0 ? ele.bid : this.bidProp
        });
      })
      this.categories.forEach(ele => {
        var lookback = this.LookBackDays.filter(e => e.id == ele.lookBackDays).map(e => e.label);
        let categoryName = this.allCategoryNames.filter(e => e.id == ele.categoryId);
        this.listAudience.push({
          type: "View Remarketing",
          category: categoryName[0].categoryName,
          lookBackDays: lookback[0],
          categoryId: categoryName[0].id,
          audience: '',
          audienceId: '',
          productId: "",
          typeId: '',
          product: '',
          price: ele.refinements != undefined ? ele.refinements.minPrice != 0 && ele.refinements.maxPrice != 0 ? ele.refinements.minPrice + "-" + ele.refinements.maxPrice
            : ele.refinements.maxPrice == 0 && ele.refinements.minPrice != 0 ? ele.refinements.minPrice + " and above"
              : ele.refinements.minPrice == 0 && ele.refinements.maxPrice != 0 ? ele.refinements.maxPrice + " and below"
                : '' : '',
          rating: ele.refinements != undefined && ele.refinements.rating != undefined && ele.refinements.rating != '0-5' ? ele.refinements.rating : '',
          shippingValue: ele.refinements != undefined && ele.refinements.isPrimeShippingEligible != undefined ? ele.refinements.isPrimeShippingEligible == 1 ? 'Prime eligible' : 'Non Prime eligible' : '',
          bid: ele.bid != 0 ? ele.bid : this.bidProp
        });
      })
      if (this.audiencesWithBid != undefined && this.audiencesWithBid.length > 0) {
        this.audiencesWithBid.forEach(ele => {
          let audienceInfo = this.allAudienceNames.filter(e => e.id == ele.audienceId);
          this.listAudience.push({
            type: this.filteredAudience[audienceInfo[0].type - 1].audienceName,
            category: "",
            lookBackDays: "",
            categoryId: "",
            productId: "",
            audience: audienceInfo[0].audienceName,
            audienceId: audienceInfo[0].audienceId,
            typeId: audienceInfo[0].type,
            product: '',
            price: '',
            rating: '',
            shippingValue: '',
            bid: ele.bid != undefined ? ele.bid : this.bidProp
          });
        })
      } else {
        this.audiencesWithBid = [];
        this.audiences.forEach(ele => {
          let audienceInfo = this.allAudienceNames.filter(e => e.id == ele);
          this.listAudience.push({
            type: this.filteredAudience[audienceInfo[0].type - 1].audienceName,
            category: "",
            lookBackDays: "",
            categoryId: "",
            productId: "",
            audience: audienceInfo[0].audienceName,
            audienceId: audienceInfo[0].id,
            typeId: audienceInfo[0].type,
            product: '',
            price: '',
            rating: '',
            shippingValue: '',
            bid: this.bidProp
          });
          this.audiencesWithBid.push({ audienceId: audienceInfo[0].id });
        })
      }
    }
  },
  watch: {
    showModalProp: function () {
      if (this.showModalProp) {
        this.showModal = true;
        if (this.audienceProp != undefined && (this.audienceProp.audienceId.length > 0 || this.audienceProp.product.length > 0 || this.audienceProp.category.length > 0)) {
          this.editAudience();
        } else {
          this.audiencesWithBid = [];
        }
      }
    },
    productLookBackDays: function () {
      var productFiltered = [];
      productFiltered = this.products.filter(x => x.lookBackDays == this.productLookBackDays).map(ele => ele.productType);
      if (productFiltered.length != this.productType.length) {
        this.productType.forEach(ele => {
          if (productFiltered.length == 0) {
            ele.isAdded = false;
          } else if (!productFiltered.includes(ele.id)) {
            ele.isAdded = false;
          } else {
            ele.isAdded = true;
          }
        })
      }
    },
    slider: function () {
      if (this.slider[0] == this.slider[1]) {
        this.slider[0] = this.slider[0] - 1;
      }
    }
  }
}
</script>
<style scoped>
.refineClass {
        flex: 1 0 auto;
    min-width: 300px;
    width: 10%;
    max-width: 650px;
    position: relative;
    filter: drop-shadow(rgba(0, 0, 0, 0.3) -1px 0px 4px);
    background-color: rgb(251, 251, 251);
    display: flex;
    flex-flow: column nowrap;
    -webkit-box-pack: start;
    place-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    height: 100%;
}
.refineOuter {
    position: absolute;
    inset: -46px 0px 0px 2px;
    z-index: 10;
    background-color: rgba(235, 235, 237, 0.65);
    transform: translate(0%);
    transition: transform 200ms ease-in-out 0s, background-color 200ms ease-in-out 0s;
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    place-content: stretch flex-start;
    -webkit-box-align: stretch;
    align-items: stretch;
}
.labelClass {
    margin-left: 2%;
    margin-top: 1%;
}
.popUpBackdrop {
    max-width: 300px;
    flex: 1 0 auto;
}
::v-deep .ivu-tag .ivu-icon-ios-close {
  padding-top: 3% !important;
    right: 1% !important;
}
::v-deep .ivu-tag {
    height: auto !important;
    border: 0px;
    border-top: 1px solid rgb(233, 230, 230) !important;
    background: rgb(255 255 255 / 90%)
}
.productsClass {
display: flex;
height: fit-content !important;
}
::v-deep .productsClass .ivu-tag-text {
    width: 100% !important;
}
.footer-div{
    padding-top: 15px;
    text-align: right;
    border-top:1px solid #e5e5e5;
    margin-top: 1%;
}
.audienceList {
display: flex;
margin-bottom: 1%;
margin-top: 1%;
padding-top: 1%;
}
.separator {
  padding-right: 1%;
  border-left: 2px solid #dcdee2;
  height: 320px;
  width: 1%;
}
::v-deep .audienceList .ivu-tag-text {
    width: 100% !important;
}
::v-deep .ivu-card-head {
    padding: 0px 16px !important;
}
::v-deep .ivu-tabs.ivu-tabs-card> .ivu-tabs-tab-active{
    background: #e9eaed !important;
    border-top: 2px solid var(--main-bg-color) !important;
    color: #111111 !important;
}
::v-deep .ivu-tabs.ivu-tabs-card>.ivu-tabs-bar .ivu-tabs-tab-active {
    background: #fff !important;
    border-top: 2px solid var(--main-bg-color) !important;
    color: #111111 !important;
    border-left: 1px solid #dcdee2 !important;
    border-right: 1px solid #dcdee2 !important;
    border-bottom: 0px !important;
}
::v-deep .ivu-tabs-nav .ivu-tabs-tab:hover {
    background: #e9eaed !important;
    border-top: 2px solid var(--main-bg-color) !important;
    color: #111111 !important;
}
::v-deep .ivu-tabs.ivu-tabs-card>.ivu-tabs-bar .ivu-tabs-tab {
    background: #dcdee2;
    border-left: 0px;
    border-right:0px;
    border-top: 0px;
    border-bottom: 1px solid #dcdee2
}
::v-deep .ivu-tabs-nav-container:focus .ivu-tabs-tab-focused {
    border-color: #e9eaed!important;
}
::v-deep .ivu-card-body {
  height: 280px;
  overflow: auto;
}
</style>
