<template>
  <Form
    label-position="right"
    :label-width="200"
    :model="config"
    :rules="validationRules"
    ref="formAmsSD"
  >
    <div v-show="showConfigForm">
      <Divider orientation="left">Accounts and Assets</Divider>

      <Form-Item label="Type">
        <Row>
          <Row-Col span="18">
            Sponsored Display
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Amazon Account" prop="bpseId">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.bpseId"
              placeholder="Select Account"
              :transfer="true"
              filterable
            >
              <Option
                v-for="(item, index) in amsAccounts"
                :key="index"
                :value="item.id"
                :disabled="item.IsOauthExpired"
                >{{ item.customerId + " - " + item.accountNickName }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Divider orientation="left">Campaign Settings</Divider>

      <Form-Item label="Campaign Mode" prop="campaign.status">
        <Row>
          <Row-Col>
            <RadioGroup v-model="config.campaign.status">
              <Radio :label="name" v-for="(name, id) in amsEnums.status" :key="id">
                <span> {{ id }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Campaign Name" prop="campaign.name">
        <Row>
          <Row-Col span="18">
            <i-input :disabled="isNomenclatureEnabled" v-model="config.campaign.name" clearable/>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Scheduling" prop="campaign.scheduling">
        <RadioGroup v-model="config.campaign.scheduling">
          <Radio :label="name" v-for="(name, id) in amsEnums.scheduling" :key="id">
                <span> {{ id }} </span>
          </Radio>
        </RadioGroup>
      </Form-Item>
      <Form-Item label="Targeting Type" prop="targeting">
        <Row>
          <Row-Col span="18">
            <CheckboxGroup v-model="config.targeting">
              <Checkbox :label="parseInt(id)" v-for="(name, id) in amsEnums.targetingTypeForDisplay" :key="parseInt(id)">
                <span> {{ name }} </span>
              </Checkbox>
            </CheckboxGroup>
          </Row-Col>
        </Row>
      </Form-Item>
        <Form-Item label="Default Bid" prop="campaign.defaultBid">
          <Row>
            <Row-Col span="4">
              <i-input v-model.number="config.campaign.defaultBid" placeholder="Bid" type="number" >
              </i-input>
            </Row-Col>
          </Row>
        </Form-Item>
      <Form-Item label="Bidding Optimization" prop="campaign.biddingOptimization">
        <RadioGroup v-model="config.campaign.biddingOptimization">
          <Radio :label="parseInt(id)" v-for="(name, id) in amsEnums.biddingOptimization" :key="parseInt(id)">
                <span> {{ name }} </span>
          </Radio>
        </RadioGroup>
      </Form-Item>
      <Form-Item label="Creative Customization" prop="campaign.creativeCustomization">
        <RadioGroup v-model="config.campaign.creativeCustomization">
          <Radio :label="parseInt(id)" v-for="(name, id) in amsEnums.creativeCustomization" :key="parseInt(id)">
                <span> {{ name }} </span>
          </Radio>
        </RadioGroup>
      </Form-Item>
      <Form-Item label="Config Name" prop="name">
        <Row>
          <Row-Col span="18">
            <i-input v-model="config.name" clearable/>
          </Row-Col>
        </Row>
      </Form-Item>
    </div>
  </Form>
</template>
<script>
import {
  Divider,
  Row,
  Col,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  CheckboxGroup,
  Checkbox
} from "iview";
import { mapState, mapMutations } from "vuex";
import { APIService } from "../../ApiService.js";
import CLHelperMethodsMixin from "../../Mixins/CLHelperMethods.js";

export default {
  props: {
    loadExistingConfigId: { default: "" },
    showConfigForm: { default: false },
    openConfigurationModal: { default: false },
    isExistingConfig: { default: false },
    nomenclatureMacro: { default: null }
  },
  components: {
    Divider,
    Row,
    "Row-Col": Col,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Select,
    Option,
    "i-input": Input,
    CheckboxGroup,
    Checkbox
  },
  data () {
    return {
      config: {
        searchEngineTypeId: 13,
        name: "",
        bpseId: 0,
        campaign: {
          name: "",
          scheduling: 1,
          targetingType: 0,
          defaultBid: null,
          biddingOptimization: 1,
          creativeCustomization: 2,
          status: 1,
          checkIfValid: false
        },
        targeting: [1]
      },
      validationRules: {
        bpseId: [
          {
            validator: (rule, value, callback) => {
              if (this.checkIfValid && (isNaN(value) || value <= 0)) {
                callback(new Error("Please select an account"));
              }
              callback();
            }
          }
        ],
        "targeting": [
          { type: "array", min: 1, required: true, message: "Please select a campaign targetting type", trigger: "change" }
        ],
        "campaign.status": [
          { required: true, message: 'Please select a campaign status' }
        ],
        "campaign.name": [
          { required: true, message: 'Please enter a campaign name' }
        ],
        "campaign.scheduling": [
          { required: true, message: 'Please select a campaign schedule type' }
        ],
        "campaign.defaultBid": [
          {
            type: "number",
            min: 0.1,
            required: true,
            message: "Please enter a valid bid",
            trigger: "change"
          }
        ],
        "name": [
          { required: true, message: 'Please enter a configuration name' }
        ],
        "campaign.biddingOptimization": [
          { required: true }
        ],
        "campaign.creativeCustomization": [
          { required: true }
        ]
      },
      isNomenclatureEnabled: false
    };
  },
  mixins: [CLHelperMethodsMixin],
  watch: {
    loadExistingConfigId (id) {
      if (id) {
        this.config = JSON.parse(this.campaignLauncherConfigs[id].config);
        if (this.config.campaign.targetingType == 1) {
          this.$set(this.config, 'targeting', [1]);
        } else if (this.config.campaign.targetingType == 2) {
          this.$set(this.config, 'targeting', [2]);
        } else if (this.config.campaign.targetingType == 3) {
          this.$set(this.config, 'targeting', [1, 2]);
        } else if (!this.isExistingConfig) {
          this.config.name = this.appendCopyToConfigName(this.config.name);
        }
      }
    },
    "openConfigurationModal": function () {
      if (this.openConfigurationModal) {
        if (this.nomenclatureMacro && this.nomenclatureMacro.campaign && this.nomenclatureMacro.campaign != '') {
          this.isNomenclatureEnabled = true;
          this.config.campaign.name = this.nomenclatureMacro.campaign;
        } else {
          this.isNomenclatureEnabled = false;
          this.config.campaign.name = this.getCampaignName();
        }
      }
    }
  },
  computed: {
    ...mapState([
      "amsEnums",
      "amsAccounts",
      "businessProfileId",
      "campaignLauncherConfigs",
      "publisher",
      "mediaPlanId",
      "selectedMediaPlan"
    ])
  },
  methods: {
    ...mapMutations([]),
    getConfig () {
      if (this.config.targeting.includes(1) && (this.config.targeting.includes(2))) {
        this.config.campaign.targetingType = 3;
      } else if (this.config.targeting.includes(1)) {
        this.config.campaign.targetingType = 1;
      } else {
        this.config.campaign.targetingType = 2;
      }
      return this.config;
    },
    resetFields () {
      this.$refs["formAmsSD"].resetFields();
    },
    isAMSFormValid (saveCallback) {
      this.checkIfValid = true;
      this.$refs["formAmsSD"].validate((valid) => {
        if (valid) {
          saveCallback();
        }
        this.checkIfValid = false;
      })
    },
    getCampaignName: function () {
      var suggestedSubmissionName = this.selectedMediaPlan.name + " - " + new Date().toLocaleString() + " - AMS Sponsored Display"
      return suggestedSubmissionName
    }
  }
};
</script>
<style scoped>
.ivu-input-number{
      width: 100px;
}
</style>
