<template>
  <div>
    <ul class="summmary-list">
      <li
        v-for="(campaign, campaignIndex) in publishSummary"
        :key="campaignIndex"
      >
        <div
          data-toggle="collapse"
          class="summary-collapse"
          v-bind:href="'#campaign-summary-' + campaignIndex"
        >
          <span>
            <i v-bind:class="getEntityStatusIcon(campaign.status)"></i>
            <span v-if="campaign.id != ''">({{ campaign.id }})</span>
            <span class="entity-label">
              {{ campaign.name }}
              <span class="pull-right"
                >{{ campaign.childEntities.length }} insertionOrder(s)</span
              >
            </span>
          </span>
          <span
            class="entity-export-error"
            v-if="campaign.failureReason != ''"
            >{{ campaign.failureReason }}</span
          >
        </div>
        <ul
          v-bind:id="'campaign-summary-' + campaignIndex"
          class="collapse-open collapse in campaign-summary"
        >
          <li
            class="panel-collapse"
            v-for="(insertionOrder, ioIndex) in campaign.childEntities"
            :key="ioIndex"
          >
            <div
              data-toggle="collapse"
              class="summary-collapse"
              :href="`#insertionOrder-summary-${campaignIndex}-${ioIndex}`"
            >
              <span>
                <i
                  v-bind:class="getEntityStatusIcon(insertionOrder.status)"
                  class="no-pointer"
                ></i>
                <span v-if="insertionOrder.id != ''"
                  >({{ insertionOrder.id }})</span
                >
                <span class="entity-label">
                  {{ insertionOrder.name }}
                </span>
              </span>
              <span
                class="entity-export-error"
                v-if="insertionOrder.failureReason != ''"
                >{{ insertionOrder.failureReason }}</span
              >

              <ul
                v-bind:id="`insertionOrder-summary-${campaignIndex}-${ioIndex}`"
                class="collapse-open collapse in campaign-summary"
              >
                <li
                  class="panel-collapse"
                  v-for="(li, liId) in insertionOrder.childEntities"
                  :key="liId"
                >
                  <div
                    data-toggle="collapse"
                    class="summary-collapse"
                    v-bind:href="
                      `#li-summary-${campaignIndex}-${ioIndex}-${liId}`
                    "
                  >
                    <span>
                      <i
                        v-bind:class="getEntityStatusIcon(li.status)"
                        class="no-pointer"
                      ></i>
                      <span v-if="li.id">({{ li.id }})</span>
                      <span class="entity-label">
                        {{ li.name }}
                      </span>
                    </span>
                    <span
                      class="entity-export-error"
                      v-if="li.failureReason != ''"
                      >{{ li.failureReason }}</span
                    >
                  </div>

                  <ul
                    v-bind:id="`li-summary-${campaignIndex}-${ioIndex}-${liId}`"
                    class="collapse-open collapse in campaign-summary"
                  >
                    <li
                      class="panel-collapse"
                      v-for="(child, liChildId) in li.childEntities"
                      :key="liChildId"
                    >
                      <template v-if="child.entityType == 4">
                        <div>
                          <span>
                            <i
                              v-bind:class="getEntityStatusIcon(child.status)"
                              class="no-pointer"
                            ></i>
                            <span v-if="child.id">({{ child.id }})</span>
                            <span class="entity-label">
                              {{ child.name }}
                            </span>
                          </span>
                          <span
                            class="entity-export-error"
                            v-if="child.failureReason != ''"
                            >{{ child.failureReason }}</span
                          >
                        </div>
                      </template>
                      <template v-else>
                        <div>
                          <span>
                            <i
                              v-bind:class="getEntityStatusIcon(child.status)"
                              class="no-pointer"
                            ></i>
                            <span v-if="child.id">({{ child.id }})</span>
                            <span class="entity-label">
                              {{ child.name }}
                            </span>
                          </span>
                          <span
                            class="entity-export-error"
                            v-if="child.failureReason != ''"
                            >{{ child.failureReason }}</span
                          >
                        </div>

                        <ul class="collapse-open collapse in campaign-summary">
                          <li
                            class="panel-collapse"
                            v-for="(ad, adId) in child.childEntities"
                            :key="adId"
                          >
                            <div>
                              <span>
                                <i
                                  v-bind:class="getEntityStatusIcon(ad.status)"
                                  class="no-pointer"
                                ></i>
                                <span v-if="ad.id">({{ ad.id }})</span>
                                <span class="entity-label">
                                  {{ ad.name }}
                                </span>
                              </span>
                              <span
                                class="entity-export-error"
                                v-if="ad.failureReason != ''"
                                >{{ ad.failureReason }}</span
                              >
                            </div>
                          </li>
                        </ul>
                      </template>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  data: function () {
    return {};
  },
  computed: {
    ...mapState(["dv360EntitiesResponse"]),
    publishSummary: function () {
      return this.dv360EntitiesResponse.data.childEntities;
    }
  },
  methods: {
    getEntityStatusIcon: function (status) {
      if (status == 8) {
        return "fa fa-check-circle green";
      } else if (status == 10) {
        return "fa fa-exclamation-triangle red";
      } else if (status == 1) {
        return "fa fa-clock-o yellow";
      }
    }
  }
};
</script>

<style scoped>
.a-tag-disable {
  pointer-events: none !important;
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}
::v-deep .pt-0 {
  padding-top: 0;
}
::v-deep .pt-15 {
  padding-top: 15px !important;
}
.error-message {
  white-space: pre-line;
  word-wrap: break-word;
}
.new-sheet {
  margin-left: 15px !important;
}
::v-deep .table-bordered th {
  background-color: #eee;
  text-align: center;
  padding: 10px;
  min-width: 100px;
}
.campaign-summary {
  list-style: none;
  padding-left: 20px;
}
.insertionOrder-summary {
  list-style: none;
  padding-left: 35px;
}
.entity-export-status {
  float: right;
}
.entity-label {
  color: #979898;
}
.green {
  color: green;
}
.grey {
  color: grey;
}
.red {
  color: red;
}
.yellow {
  color: #ffc300;
}
.fa.fa-caret-right {
  color: #979898;
}
.summmary-list {
  padding-left: 0px;
}
span.pull-right {
  display: none;
}
.ad-summary-container {
  position: sticky;
  right: 20px;
  top: 65px;
  border: 1px solid #ddd;
  background-color: white;
  margin-top: 15px;
}
.ad-summary-container ul.summmary-list {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}
.no-pointer {
  cursor: default;
}
.summary-collapse {
  cursor: pointer;
}
.summary-collapse:before {
  font-family: "FontAwesome";
  content: "\f0d7";
  color: grey;
  font-size: 1.3em;
  padding-right: 5px;
}
.summary-collapse.collapsed:before {
  content: "\f0da";
}
::v-deep .dropdown-menu {
  overflow-y: auto;
  max-height: 380px;
}
.submission-box-row {
  margin-bottom: 10px;
}
.submission-dropdown {
  margin-right: 0 !important;
}
.comment-area {
  width: 100%;
  min-height: 200px;
  max-height: 350px;
}
.tab-controls {
  padding: 0px;
  border-bottom: none !important;
}
.tab-content {
  padding: 0px !important;
  margin-bottom: 0px !important;
}
.nav.nav-tabs {
  padding-top: 15px;
  top: 48px;
  background: white;
  z-index: 20;
}
</style>
