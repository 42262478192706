<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <Row>
          <Row-Col span="22">
            <h4>Instant Experiences
              <i v-show="loading" class="fa fa-spinner fa-spin"></i>
              <i class="fas fa-refresh pull-right" v-on:click="getCanvases()"></i>
            </h4>
          </Row-Col>
          <Row-Col span="2">
          <Dropdown trigger="click" v-on:on-click="openCanvasBuilder($event)">
            <button :disabled="!getIfBusinessExistsForSelectedConfigAccount" class="btn btn-success btn-sm pull-right">
              <i class="fa fa-plus"></i>
            </button>
            <DropdownMenu slot="list">
              <template v-for="canvasTemplate in canvasTemplates">
                <DropdownItem :name="canvasTemplate.id" :key="canvasTemplate.id">{{ canvasTemplate.name }}</DropdownItem>
              </template>
            </DropdownMenu>
          </Dropdown>
          </Row-Col>
        </Row>
      </div>
      <VuePerfectScrollbar class="widget-body">
        <Alert v-if="!loading && canvases.length == 0" type="info">
            No Instant Experiences found for the selected page
        </Alert>
        <Row class="cl-ivu-row" v-for="canvas in canvases" :key="canvas.id">
          <Row-Col span="1">
            <i class="fas fa-copy action-icon"
              @click="copyToClipboard(canvas.id)">
            </i>
          </Row-Col>
          <Row-Col span="1" offset="1">
            <i class="fas fa-eye" v-on:click="openCanvasBuilder(canvas.id, true)">
            </i>
          </Row-Col>
          <Row-Col offset="1" span="20" class="cl-widget-text">{{ canvas.name }}</Row-Col>
        </Row>
      </VuePerfectScrollbar>
    </Card>
  </div>
</template>
<script>
import { FacebookSdk } from "../../../Plugins/facebooksdk.js"
import Vue from "vue"
import { Card, Row, Col, Dropdown, DropdownMenu, DropdownItem, Alert } from "iview";
import { mapState, mapGetters, mapMutations } from "vuex";
import { APIService } from "./../ApiService.js";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { EventBus } from "../EventBus.js"
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js"

export default {
  mixins: [FacebookSdk.getMixin(Vue), FacebookCampaignLauncherConfig],
  components: {
    Card,
    Row,
    "Row-Col": Col,
    VuePerfectScrollbar,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Alert
  },
  props: {
    deltaxConfig: { type: Object, default: () => {} }
  },
  data () {
    return {
      loading: false,
      canvases: [],
      canvasBuilderOptions: {
        accountId: "",
        pageId: "",
        businessId: "",
        bpseId: ""
      },
      canvasTemplates: [{
        id: "133471657203838",
        name: "Instant Customer Acquisition"
      }, {
        id: "1063217037112304",
        name: "Instant Storytelling"
      }, {
        id: "424787857903852",
        name: "Instant Storefront"
      }, {
        id: "1369752616394017",
        name: "Instant Lookbook"
      }, {
        id: "1932289657009030",
        name: "Instant Storefront Grid"
      }, {
        id: 0,
        name: "Build your own custom template"
      }]
    }
  },
  computed: {
    ...mapState(["selectedCampaignLauncherConfigId", "campaignLauncherConfigs", "businessProfileId", "instantExperiences"]),
    ...mapGetters(["getIfBusinessExistsForSelectedConfigAccount"])
  },
  beforeDestroy () {
    EventBus.$off("instantExperienceWidget-previewCanvas");
  },
  mounted () {
    Vue.use(FacebookSdk, {
      appId: this.deltaxConfig.facebookAppId,
      version: this.deltaxConfig.facebookApiVersion
    });
    EventBus.$on("instantExperienceWidget-previewCanvas", (canvasId) => {
      this.openCanvasBuilder(canvasId, true);
    });
  },
  methods: {
    ...mapMutations(["set_instantExperiences"]),
    setCanvasBuilderOptions () {
      if (this.isFbSdkReady && this.selectedCampaignLauncherConfigId) {
        let config = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config);
        if (this.isInstantExperiencesSupported(config)) {
          this.accountId = config.facebookAccountId;
          this.pageId = config.facebookPageId;
          this.bpseId = config.bpseId;
        }
      }
    },
    openCanvasBuilder (templateId, isPreview) {
      isPreview = isPreview || false;
      let options = {
        display: 'popup',
        business_id: '891892264211459'
      }
      if (this.getIfBusinessExistsForSelectedConfigAccount) {
        options.business_id = this.getIfBusinessExistsForSelectedConfigAccount;
      }
      if (isPreview) {
        options.method = 'instant_experiences_preview'
        options.canvas_id = templateId
      } else {
        options.method = 'instant_experiences_builder'
        options.account_id = this.accountId
        options.page_id = this.pageId
        if (templateId != 0) {
          options.template_id = templateId
        }
      }
      this.setCanvasBuilderOptions();
      let self = this;
      Vue.FB.ui(options, function (response) {
        console.log(response);
        if (response && response.id) {
          self.getCanvases();
        }
      });
    },
    getCanvases () {
      this.loading = true;
      this.canvases = [];
      APIService.get("/App/CampaignLauncher/GetCanvases/" + this.businessProfileId + "?pageId=" + this.pageId + "&bpseId=" + this.bpseId).then(this.processGetCanvases)
    },
    processGetCanvases (response) {
      var data = JSON.parse(response);
      if (!data.error) {
        this.canvases = data;
        this.set_instantExperiences(data);
      }
      this.loading = false;
    },
    copyToClipboard (value) {
      let textarea = document.createElement("textarea")
      textarea.textContent = value
      textarea.style.position = "fixed"
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand("copy")
      } catch (e) {
        alert('Unable to copy to clipboard. Please copy manually.')
      }
      document.body.removeChild(textarea)
    },
    fetchInstantExperiences () {
      this.setCanvasBuilderOptions();
      // selectedCampaignLauncherConfigId is set for auth flow page as well. Do not make the call in that case. bpse id will be undefined
      if (this.bpseId) {
        this.getCanvases();
      }
    }
  },
  watch: {
    selectedCampaignLauncherConfigId: function () {
      this.fetchInstantExperiences();
    },
    isFbSdkReady: function (value) {
      this.fetchInstantExperiences();
    }
  }
}
</script>
<style>
.fa-eye:hover {
  cursor: pointer;
}
</style>
<style scoped>
.fa-refresh {
  margin-left:5px;
  margin-right:5px;
}
</style>
