const linkedInEnums = {
  objective: {
    awareness: {
      1: "Brand awareness"
    },
    consideration: {
      2: "Website Visits"
      // 3: "Engagement",
      // 4: "Video Views"
    },
    conversions: {
      5: "Lead Generation"
      // 6: "Website Convesions",
      // 7: "Job Applicants"
    }
  },
  optimizationGoal: [
    {
      id: 1,
      label: "Impressions"
    },
    {
      id: 2,
      label: "Landing Page Clicks"
    },
    {
      id: 3,
      label: "Engagement Clicks"
    },
    {
      id: 4,
      label: "Video Views"
    },
    {
      id: 5,
      label: "Leads"
    },
    {
      id: 6,
      label: "Website Convesions"
    },
    {
      id: 7,
      label: "Clicks"
    }
  ],
  objectiveOptimizationGoalMapping: {
    1: [1],
    2: [2, 1],
    3: [3, 1],
    4: [4, 1],
    5: [5, 7, 1],
    6: [6, 2, 1],
    7: [2, 1]
  },
  defaultSelectionForOptimisationByObjective: {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 2
  },
  biddingStrategy: {
    1: "Maximum delivery",
    2: "Target cost",
    3: "Manual Bidding"
  },
  objectiveBiddingStrategyMapping: {
    1: {
      1: [1, 2, 3]
    },
    2: {
      1: [3],
      2: [1, 2, 3]
    },
    3: {
      1: [1, 2, 3],
      3: [1, 2, 3]
    },
    4: {
      1: [1, 2, 3],
      4: [1, 2, 3]
    },
    5: {
      1: [3],
      5: [1, 3],
      7: [3]
    },
    6: {
      1: [1, 2, 3],
      2: [1, 2, 3],
      6: [1, 2, 3]
    },
    7: {
      1: [1, 2, 3],
      2: [1, 2, 3]
    }
  },
  biddingStrategy_OptimizationGoalMapping: {
    1: [2, 3, 4, 5, 6, 7],
    2: [2, 3, 4, 5, 6, 7],
    3: [1, 2, 3, 4, 5, 6, 7]
  },
  budgetType: {
    1: "Daily Budget",
    2: "Lifetime Budget",
    3: "Both a daily and lifetime Budget"
  },
  scheduling: {
    1: "Run continuously starting today",
    2: "Use line item scheduling"
  },
  macroList: [
    "MP_name",
    "LI_name",
    "Creative_type",
    "Goal",
    "LI_Id"
  ],
  kpiType: {
    1: "CPM",
    2: "CPC",
    3: "CPA",
    4: "Viewability %",
    5: "CPIAVC",
    6: "CPE",
    7: "CTR"
  },
  status: {
    1: "Active",
    2: "Paused",
    3: "Archived"
  },
  adStatus: {
    1: "Active",
    2: "Paused"
  },
  campaignStatus: {
    1: "New",
    3: "Paused",
    8: "Sucesss",
    10: "Failed"
  },
  adType: {
    1: "Single Image",
    2: "Text Ads"
  },
  budgetTypeColumns: [
    "Campaign Group",
    "Campaign",
    "Status",
    "Objective",
    "Budget Type",
    "Budget",
    "Bid Amount"
  ],
  targetingColumns: [
    "Target Name",
    "Number of Adgroups"
  ],
  locationsColumns: [
    "Location",
    "Number of Adgroups"
  ],
  adFormatsColumns: [
    "Ad Formats",
    "Number of Adgroups"
  ],
  pagesAndCreativeColumns: [
    "Ad Copy Id",
    "Creative",
    "Landing Page",
    "Targets"
  ],
  placementsColumns: [
    "LinkedIn Display Network",
    "Number of Adgroups"
  ],
  objectiveEnums: {
    BrandAwareness: 1,
    WebsiteVisits: 2,
    Engagement: 3,
    VideoViews: 4,
    LeadGeneration: 5,
    WebsiteConvesions: 6,
    JobApplicants: 7
  },
  imageRecipes: [
    {
      id: 1,
      label: "Feed share image",
      recipe: "urn:li:digitalmediaRecipe:feedshare-image",
      disabled: true
    },
    {
      id: 2,
      label: "Image or Article Ad for a campaign",
      recipe: "urn:li:digitalmediaRecipe:companyUpdate-article-image",
      disabled: false
    },
    {
      id: 3,
      label: "Carousel Ad for a campaign",
      recipe: "urn:li:digitalmediaRecipe:ssu-carousel-card-image",
      disabled: true
    },
    {
      id: 4,
      label: "Text Ad for a campaign",
      recipe: "urn:li:digitalmediaRecipe:rightRail-logo-image",
      disabled: false
    },
    {
      id: 5,
      label: "Dynamic Ads for a campaign",
      recipe: "urn:li:digitalmediaRecipe:rightRail-logo-image",
      disabled: true
    }
  ],
  imageRecipe_adType: {
    single: 2,
    text: 4
  },
  ctaEnums: {
    LEARN_MORE: 0,
    APPLY: 1,
    DOWNLOAD: 2,
    VIEW_QUOTE: 3,
    SIGN_UP: 4,
    SUBSCRIBE: 5,
    REGISTER: 6,
    JOIN: 7,
    ATTEND: 8,
    REQUEST_DEMO: 9,
    SEE_MORE: 10
  },
  entityType: {
    campaignGroup: 1,
    campaign: 2,
    ad: 3
  }
};

export { linkedInEnums };
