<template>
  <modal effect="fade" :value="dialogVisible" title="Pop-up Blocked" @cancel="closeDialog">
    <div slot="modal-body" class="modal-body">
      Auto download is blocked by browser. Please manually <a class="download-link" @click="download">Download Report <i class="fa fa-external-link" aria-hidden="true"></i></a>
    </div>
    <div slot="modal-footer"></div>
  </modal>
</template>
<script>
import { modal } from 'vue-strap';
export default {
  props: {
    dialogVisible: Boolean,
    reportLink: String
  },
  components: {
    modal
  },
  methods: {
    closeDialog () {
      this.$emit("close");
    },
    download () {
      window.open(this.reportLink, '_blank')
    }
  }
}
</script>
<style scoped>
.modal-body {
  margin-top: 15px;
  margin-bottom: 15px;
}
.download-link {
  cursor: pointer;
  text-decoration: underline;
}
</style>
