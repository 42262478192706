<template>
   <div>
      <i-modal
         v-model="showModal"
         width="65%"
         :footer-hide="false"
         :styles="{top: '70px'}"
         :closable="false"
         :mask-closable="false"
         >
         <h4 class="title" slot="header" >
            Link {{entityName}} to {{mediaPlan}} {{lineItem}}
         </h4>
         <div class="modal-body overflow-hidden" style="height:610px;">
            <div v-show="isError" class="col-md-12 alert alert-danger panel-body ko-multiselect-tree body container perfect-scroll-bar overflow-hidden" style="height: 60px;">
              Following {{entityName}}
              are linked with multiple line items:
              <br />
              <ul style="padding-inline-start:30px">
                <li v-for="(item,index) in duplicateLinkingErrorEntities" :key="index">
                  <span> {{item}} </span> is linked to
                  <span> {{lineItemNamesWithDuplicateEntities(item)}} </span>
                </li>
              </ul>
            </div>
            <div>
               <div class="col-md-6" style="padding-left: 0px">
                  <div class="col-md-6" style="padding:0px;">
                     <i-select size="small" v-model="selectedChannelId" filterable>
                        <option-group label="Internal Channels">
                           <i-option
                              v-for="channel in internalChannels"
                              :key="channel.id"
                              :value="channel.id"
                              >{{channel.text}}</i-option>
                        </option-group>
                        <option-group label="External Channels">
                           <i-option
                              v-for="channel in externalChannels"
                              :key="channel.id"
                              :value="channel.id"
                              >{{channel.text}}</i-option>
                        </option-group>
                     </i-select>
                  </div>
                  <div class="col-md-1">
                  <i class="fa fa-filter icon-size"></i>
                  </div>
                  <div class="col-md-3 no-padding" style="padding-left:10px;">
                     <i-select size="small" v-model="entityFilter">
                        <i-option :value="1">All</i-option>
                        <i-option :value="2">All Active</i-option>
                        <i-option :value="3">All Inactive</i-option>
                     </i-select>
                  </div>
               </div>
            </div>
            <div class="col-md-4 pull-right">
               <div class="form-group zero-margin-bottom pull-right">
                  <i-radio-group v-model="entityTypeId">
                     <i-radio label=1>Campaign</i-radio>
                     <i-radio label=2>Adgroup</i-radio>
                  </i-radio-group>
               </div>
            </div>
            <div>
               <div class="col-md-12 no-padding ko-multiselect-tree container" :class="{'with-error':isError, 'without-error': !isError}">
                  <div class="col-md-6 no-padding ko-multiselect-tree container">
                     <div class="panel panel-default">
                        <div
                           class="panel-heading ko-multiselect-tree searchbar"
                           style="padding-bottom:0px !important"
                           >
                           <div class="input-group input-group-sm">
                              <i-input
                                 size="small"
                                 search
                                 style="width: 407px"
                                 v-model="searchedQuery"
                                 @on-search="searchQueryString"
                                 placeholder="Entity Name"
                                 />
                           </div>
                           <div class="pull-left">
                              <a class="select-options" v-on:click="addAll" style="font-size: 11px;">Add All</a>
                           </div>
                           <div class="pull-right">
                              <a class="select-options" v-on:click="removeAll" style="font-size: 11px;">Remove All</a>
                           </div>
                        </div>
                        <div class="panel-body ko-multiselect-tree body perfect-scroll-bar overflow-hidden">
                           <ul class="list-group">
                              <li class="list-group-item tree-item">
                                 <ul v-if ="!showLoader" style="list-style: none">
                                    <li
                                       v-for="entity in entitiesToShow"
                                       :key="entity.Id"
                                       >
                                       <div v-if="entity.SearchEngineId == selectedChannelId " class="checkbox">
                                          <label style="max-width:310px;">
                                          <input
                                             type="checkbox"
                                             v-on:click="selectEntity(entity)"
                                             v-model="entity.isSelected"
                                             />
                                              <span v-dxTooltip='{ title: entity.toolTipDisplay }' class="entityname-span">{{entity.Name}}</span>
                                          </label>
                                          <i
                                             v-if="entity.generalStatus == entityStatus.Active"
                                             class="fa fa-play pull-right campaign-status-info-padding"
                                             ></i>
                                          <i
                                             v-if="entity.generalStatus == entityStatus.Paused"
                                             class="fa fa-pause pull-right campaign-status-info-padding"
                                             ></i>
                                          <i
                                             v-if="entity.generalStatus == entityStatus.Archived"
                                             class="fa fa-archive pull-right campaign-status-info-padding"
                                             ></i>
                                       </div>
                                    </li>
                                 </ul>
                              </li>
                              <li v-if="noResult" class="list-group-item tree-group-header">No results found</li>
                           </ul>
                        </div>
                        <i
                           v-if="showLoader"
                           class="fa fa-spinner fa-spin fa-fw fa-2x ko-multiselect-tree spinner"
                           ></i>
                     </div>
                  </div>
                  <div class="col-md-6 no-padding ko-multiselect-tree container">
                     <div class="panel panel-default">
                        <div class="panel-heading ko-multiselect-tree searchbar">
                           <h5 class>Selected {{entityType }} ({{allSelectedEntities.length}})</h5>
                        </div>
                        <div id="verify" class="panel-body ko-multiselect-tree body perfect-scroll-bar overflow-hidden">
                           <ul class="list-group">
                              <span v-for="entity in entitiesSelected" :key="entity.SearchEngineId">
                                 <li class v-if="entity.selectedEntities.length>0" style="background:#eee; height:25px; text-align:center">
                                    <span class="entityname-span" style="padding-top:5px;">{{entity.text}}</span>
                                 </li>
                                 <li v-for="selectedEntity in entity.selectedEntities" :key="selectedEntity.Id" class="list-group-item">
                                    <span v-dxTooltip='{ title: selectedEntity.toolTipDisplay }' class="entityname-span">
                                    {{selectedEntity.Name}}  </span>
                                    <span class="pull-right">
                                    <i
                                       class="fa fa-times no-top-padding"
                                       v-on:click="removeEntity(selectedEntity)"
                                       ></i>
                                    </span>
                                 </li>
                              </span>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div slot="footer">
            <button
               type="button"
               class="btn create-btn btn-white button-style"
               v-on:click="cancel"
               >Cancel</button>
            <button
               type="button"
               class="btn create-btn btn-success button-style"
               v-on:click="save"
               :disabled="isLinkingDisabled"
               >Update</button>
         </div>
      </i-modal>
   </div>
</template>
<script>
import {
  Modal,
  OptionGroup,
  Select,
  Option,
  Radio,
  RadioGroup,
  Input
} from "iview";
import { ApiService } from ".././Services/linkEntityApiService.js";

export default {
  components: {
    "i-modal": Modal,
    "option-group": OptionGroup,
    "i-select": Select,
    "i-option": Option,
    "i-radio": Radio,
    "i-radio-group": RadioGroup,
    "i-input": Input
  },
  props: [
    "linkedEntityTypeId",
    "lineItemName",
    "mediaPlanName",
    "linkedEntities",
    "alreadyLinkedCampaignIds",
    "alreadyLinkedAdgroupIds",
    "lineItemOrderId",
    "publisherName",
    "autoSave",
    "showEntityLinkingModal",
    "isKnockout"
  ],
  data: function () {
    return {
      entityTypeId: this.linkedEntityTypeId,
      showModal: this.showEntityLinkingModal,
      selectedChannelId: 5,
      duplicateLinkingErrorEntities: [],
      linkingErrorLineItems: [],
      entityFilter: 1,
      internalChannels: [],
      externalChannels: [],
      entityType: 'Campaigns',
      entityStatus: { Active: 1, Paused: 2, Archived: 3 },
      groupedSearchEngines: [],
      entitiesToShow: [],
      lineItemId: 0,
      searchedQuery: "",
      entitiesSelected: [],
      noResult: false,
      showLoader: false,
      isAutoSaveEnabled: this.autoSave,
      lineItem: this.lineItemName,
      mediaPlan: this.mediaPlanName,
      entitiesLinked: this.linkedEntities,
      alreadyLinkedCampaigns: this.alreadyLinkedCampaignIds,
      alreadyLinkedAdgroups: this.alreadyLinkedAdgroupIds,
      lineItemOrder: this.lineItemOrderId,
      publisher: this.publisherName,
      knockout: this.isKnockout,
      isError: false,
      isLinkingDisabled: false
    };
  },
  computed: {
    allSelectedEntities: function () {
      // `this` points to the vm instance
      var self = this;
      var selectedEntities = [];
      self.entitiesSelected.forEach(function (item) {
        selectedEntities.push(...item.selectedEntities);
      });
      return selectedEntities;
    },
    entityName: function () {
      var self = this;
      var entitySelected = "Campaign(s)";
      self.entityType = 'Campaigns'
      if (self.entityTypeId == 2) {
        entitySelected = "Adgroup(s)";
        self.entityType = 'Adgroups'
      }
      return entitySelected;
    }
  },
  watch: {
    entityTypeId: function (val) {
      var postData = this.getPostData();
      this.getEntitiesData(postData);
      this.resetSelectedEntities();
    },
    selectedChannelId: function (val) {
      var postData = this.getPostData();
      this.getEntitiesData(postData);
    },
    entityFilter: function (val) {
      var postData = this.getPostData();
      this.getEntitiesData(postData);
    }
  },
  methods: {
    init: function () {
      var self = this;
      self.isLinkingDisabled = false;
      var selectedEntityIds = self.entitiesLinked;
      var joinedIds = selectedEntityIds.join(",");
      if (self.publisher != undefined && self.publisher != "" && self.publisher != null) {
        var publisherProp = self.publisher.toLowerCase();
        var internalChannel = self.internalChannels.find(i => i.text.toLowerCase().includes(publisherProp));
        var externalChannel = self.externalChannels.find(i => i.text.toLowerCase().includes(publisherProp));
        if (internalChannel != undefined) {
          self.selectedChannelId = internalChannel.id;
        }
        if (internalChannel == undefined && externalChannel != undefined) {
          self.selectedChannel = externalChannel.id;
        }
      }
      var postData = self.getPostData();
      postData.entityIds = joinedIds;
      self.getEntitiesData(postData);
    },
    save: function () {
      var self = this;
      self.isLinkingDisabled = true;
      var selectedEntities = this.allSelectedEntities;
      var alreadyLinkedEntities = [];
      self.duplicateLinkingErrorEntities = [];
      if (self.entityTypeId == 1) {
        self.linkingErrorLineItems = [];
        // check in already linked campaigns
        selectedEntities.forEach(function (item) {
          var errorEntity = self.alreadyLinkedCampaigns.filter(x => x.entityId == item.Id)
          var isAlreadyLinkedCampaign = errorEntity.length > 0;
          if (isAlreadyLinkedCampaign) {
            alreadyLinkedEntities.push(item.Name);
            self.linkingErrorLineItems.push(errorEntity.map(x => x.lineItemName).join(','))
          }
        });
      }
      if (self.entityTypeId == 2) {
        // check in already linked campaigns
        selectedEntities.forEach(function (item) {
          var errorEntity = self.alreadyLinkedAdgroups.filter(x => x.entityId == item.Id)
          var isAlreadyLinkedAdgroup = errorEntity.length > 0;
          if (isAlreadyLinkedAdgroup) {
            alreadyLinkedEntities.push(item.Name);
            self.linkingErrorLineItems.push(errorEntity.map(x => x.lineItemName).join(','))
          }
        });
      }
      self.duplicateLinkingErrorEntities = alreadyLinkedEntities;
      if (self.duplicateLinkingErrorEntities.length > 0) {
        self.isError = true;
        self.isLinkingDisabled = false;
      }
      if (self.duplicateLinkingErrorEntities.length == 0) {
        var entityIdsSelected = self.allSelectedEntities.map(a => a.Id);
        var object = [];
        var lineItemEntityRequest = [];
        entityIdsSelected.forEach(element => {
          lineItemEntityRequest.push({
            EntityId: element,
            FxConvertor: 1
          })
        })
        object = {
          lineItemId: self.lineItemOrder,
          entityTypeId: parseInt(self.entityTypeId),
          lineItemEntityRequest: lineItemEntityRequest
        }
        if (this.isAutoSaveEnabled && this.knockout) {
          this.showModal = false;
          this.resetSelectedEntities();
        } else {
          this.$emit(
            "saveLinkedEntities", object
          );
        }
      }
    },
    cancel: function () {
      if (this.isAutoSaveEnabled && this.knockout) {
        this.showModal = false;
        this.resetSelectedEntities();
      } else {
        this.$emit("hideEntityLinking");
      }
    },
    addAll: function () {
      var self = this;
      var searchEngineId = self.selectedChannelId;
      var obj = self.entitiesSelected.find(o => o.id == searchEngineId);
      if (obj != undefined) {
        self.entitiesToShow.forEach(function (item) {
          var entityId = item.Id;
          // dont add already selected entities
          if (
            !obj.selectedEntities.some(i => i.Id === entityId) &&
            item.SearchEngineId == searchEngineId
          ) {
            item.isSelected = true;
            obj.selectedEntities.push(item);
          }
        });
      }
    },
    removeAll: function () {
      var self = this;
      var searchEngineId = self.selectedChannelId;
      var obj = self.entitiesSelected.find(o => o.id == searchEngineId);
      if (obj != undefined) {
        self.entitiesToShow.forEach(function (item) {
          var entityId = item.Id;
          if (
            obj.selectedEntities.some(i => i.Id === entityId) &&
            item.SearchEngineId == searchEngineId
          ) {
            item.isSelected = false;
            obj.selectedEntities = obj.selectedEntities.filter(function (item) {
              return item.Id != entityId;
            });
          }
        });
      }
    },
    selectEntity: function (entity) {
      var isSelectedEntity = entity.isSelected;
      var searchEngineId = entity.SearchEngineId;
      // if selected entity is true then add it if false remove it from selected
      var obj = this.entitiesSelected.find(o => o.id == searchEngineId);
      isSelectedEntity = !isSelectedEntity;
      if (isSelectedEntity == true) {
        obj.selectedEntities.push(entity);
      } else {
        if (obj != undefined) {
          obj.selectedEntities = obj.selectedEntities.filter(function (item) {
            return item.Id != entity.Id;
          });
        }
      }
    },
    removeEntity: function (selectedEntity) {
      var self = this;
      var entitySelected = self.entitiesToShow.find(
        o => o.Id == selectedEntity.Id
      );
      if (entitySelected != undefined) {
        entitySelected.isSelected = false;
      }
      var obj = self.entitiesSelected.find(
        o => o.id == selectedEntity.SearchEngineId
      );
      if (obj != undefined) {
        obj.selectedEntities = obj.selectedEntities.filter(function (item) {
          return item.Id != selectedEntity.Id;
        });
      }
    },
    getPostData: function () {
      var self = this;
      self.showLoader = true;
      var selectedEntityIds = [];
      var joinedIds = selectedEntityIds.join(",");
      var searchEngineId = self.selectedChannelId;
      var entityTypeId = self.entityTypeId;
      var filterValue = self.entityFilter;
      var statusValue = self.getEntityStatusId(
        searchEngineId,
        entityTypeId,
        filterValue
      );
      statusValue = statusValue.join();

      if (self.searchedQuery !== "") {
        return {
          lineItemId: self.lineItemId,
          searchEngineId: searchEngineId,
          entityTypeId: self.entityTypeId,
          status: statusValue,
          query: self.searchedQuery,
          entityIds: joinedIds
        };
      } else {
        return {
          lineItemId: self.lineItemId,
          searchEngineId: searchEngineId,
          entityTypeId: self.entityTypeId,
          status: statusValue,
          entityIds: joinedIds
        };
      }
    },
    getEntityStatusId: function (engineId, entityTypeId, filterValue) {
      var self = this;
      var selectedChannel;
      var internal = self.internalChannels.filter(function (i) {
        return i.id == engineId;
      })[0];

      var external = self.externalChannels.filter(function (i) {
        return i.id == engineId;
      })[0];
      selectedChannel = internal || external;

      var status = "";
      if (entityTypeId == 1) {
        if (filterValue == 1) {
          status = selectedChannel.CampaignAll;
        } else if (filterValue == 2) {
          status = selectedChannel.CampaignAllActive;
        } else if (filterValue == 3) {
          status = selectedChannel.CampaignAllInactive;
        }
      }
      if (entityTypeId == 2) {
        if (filterValue == 1) {
          status = selectedChannel.AdgroupAll;
        } else if (filterValue == 2) {
          status = selectedChannel.AdgroupAllActive;
        } else if (filterValue == 3) {
          status = selectedChannel.AdgroupAllInactive;
        }
      }
      return status;
    },
    resetSelectedEntities: function () {
      var self = this;
      var channelEntities = [];
      self.internalChannels.forEach(function (item) {
        var object = {
          id: item.id,
          text: item.text,
          selectedEntities: []
        };
        channelEntities.push(object);
      });
      self.externalChannels.forEach(function (item) {
        var object = {
          id: item.id,
          text: item.text,
          selectedEntities: []
        };
        channelEntities.push(object);
      });
      this.entitiesSelected = channelEntities;
    },

    getEntitiesData: function (postData) {
      var self = this;
      self.noResult = false;
      var entitiesData = ApiService.getLinkEntityInformation(postData);
      entitiesData
        .then(response => {
          var result = response.data;
          if (
            result.length == 0 ||
            !result.some(o => o.SearchEngineId == self.selectedChannelId)
          ) {
            self.noResult = true;
          }
          var searchEngineId = self.selectedChannelId;
          var obj = self.entitiesSelected.find(o => o.id == searchEngineId);
          result.forEach(element => {
            element.isSelected = false;
            if (self.entityTypeId == 1) {
              element.toolTipDisplay = "Campaign:" + element.Name
            } else {
              element.toolTipDisplay = "Campaign:" +
                  element.CampaignName +
                  "\n Adgroup:" +
                  element.Name;
            }
            // check if element is present in selectedList
            if (obj != undefined && obj.selectedEntities) {
              if (obj.selectedEntities.some(i => i.Id == element.Id)) {
                element.isSelected = true;
              }
            }
            element.entityTypeId = self.entityTypeId;
            element.generalStatus = self.getEntityGeneralStatus(
              element.SearchEngineId,
              element.Status,
              postData.entityTypeId
            );
          });
          self.entitiesToShow = result;
          self.showLoader = false;
          if (postData.entityIds.length > 0) {
            var entityIdsString = postData.entityIds;
            var linkedEntityIds = entityIdsString
              .split(",")
              .map(Number)
              .filter(x => !isNaN(x));
            var linkedEntitiesObjects = self.entitiesToShow.filter(f =>
              linkedEntityIds.includes(f.Id)
            );
            linkedEntitiesObjects.forEach(element => {
              element.isSelected = true;
              var searchEngineId = element.SearchEngineId;
              var channelObj = self.entitiesSelected.find(
                o => o.id == searchEngineId
              );
              if (channelObj != undefined) {
                if (
                  !channelObj.selectedEntities.some(i => i.Id == element.Id)
                ) {
                  channelObj.selectedEntities.push(element);
                }
              }
            });
          }
          self.sortEntitiesToShow();
        })
        .catch(x => {
          this.showLoader = false;
          console.log("Error occured in getting channels");
        });
    },
    sortEntitiesToShow: function () {
      var self = this;
      var selectedEntities = self.entitiesToShow.filter((x) => { return x.isSelected == true; })
      var otherEntities = self.entitiesToShow.filter((x) => { return x.isSelected == false; })
      var entitiesToShow = [];
      entitiesToShow = selectedEntities.concat(otherEntities);
      self.entitiesToShow = entitiesToShow;
    },
    getEntityGeneralStatus: function (searchEngineId, status, entityTypeId) {
      var self = this;
      var generalStatus = self.entityStatus.Archived;
      var selectedChannel;
      var internal = self.internalChannels.filter(function (i) {
        return i.id == searchEngineId;
      })[0];
      var external = self.externalChannels.filter(function (i) {
        return i.id == searchEngineId;
      })[0];
      selectedChannel = internal || external;
      if (entityTypeId == 1) {
        if (selectedChannel.CampaignAllActive.includes(status)) {
          generalStatus = self.entityStatus.Active;
        } else if (selectedChannel.CampaignAllInactive.includes(status)) {
          generalStatus = self.entityStatus.Paused;
        }
      }
      if (entityTypeId == 2) {
        if (selectedChannel.AdgroupAllActive.includes(status)) {
          generalStatus = self.entityStatus.Active;
        } else if (selectedChannel.AdgroupAllInactive.includes(status)) {
          generalStatus = self.entityStatus.Paused;
        }
      }
      return generalStatus;
    },
    searchQueryString: function () {
      var postData = this.getPostData();
      this.getEntitiesData(postData);
    },
    setLinkEntity: function (showModal, entityTypeId, lineItemName, linkedCampaignIds, alreadyLinkedCampaignIds, id, publisher, isKnockout, mediaplanName = "") {
      var channelPromise = ApiService.getChannels();
      this.isLinkingDisabled = false;
      channelPromise
        .then(response => {
          var self = this;
          this.internalChannels = response.data[0].children;
          this.externalChannels = response.data[1].children;
          this.groupedSearchEngines = response.data;
          this.entityTypeId = entityTypeId.toString();
          this.lineItem = lineItemName;
          this.mediaPlan = mediaplanName;
          this.entitiesLinked = linkedCampaignIds;
          this.alreadyLinkedCampaigns = alreadyLinkedCampaignIds;
          this.alreadyLinkedAdgroups = alreadyLinkedCampaignIds;
          this.lineItemOrder = id;
          this.publisher = publisher;
          this.isAutoSaveEnabled = true;
          this.knockout = isKnockout;
          this.duplicateLinkingErrorEntities = [];
          this.showModal = showModal;
          self.resetSelectedEntities();
          self.init();
        })
        .catch(error => {
          console.log("Error in fetching channels" + error);
        });
    },
    lineItemNamesWithDuplicateEntities: function (entityName) {
      var self = this;
      var lineItemInformation = [];
      var alreadyLinkedEntities = [];
      var selectedEntities = this.allSelectedEntities;
      selectedEntities = selectedEntities.filter(x => x.Name == entityName)
      selectedEntities.forEach(function (item) {
        if (self.entityTypeId == 1) {
          var errorCampaignEntity = self.alreadyLinkedCampaigns.filter(x => x.entityId == item.Id)
          var isAlreadyLinkedCampaign = errorCampaignEntity.length > 0;
          if (isAlreadyLinkedCampaign) {
            alreadyLinkedEntities.push(item.Name);
            var campaignData = []
            errorCampaignEntity.map(x => x.lineItemInformation).forEach(x => { campaignData = campaignData.concat(x) })
            lineItemInformation = lineItemInformation.concat(campaignData)
          }
        }
        if (self.entityTypeId == 2) {
          var errorAdGroupEntity = self.alreadyLinkedAdgroups.filter(x => x.entityId == item.Id)
          var isAlreadyLinkedAdGroup = errorAdGroupEntity.length > 0;
          if (isAlreadyLinkedAdGroup) {
            alreadyLinkedEntities.push(item.Name);
            var adgroupData = []
            errorAdGroupEntity.map(x => x.lineItemInformation).forEach(x => { adgroupData = adgroupData.concat(x) })
            lineItemInformation = lineItemInformation.concat(adgroupData)
          }
        }
      });

      var result = []
      result = lineItemInformation.map(x => {
        var id = x.lineItemId
        var name = x.lineItemName
        if (!id) {
          return name
        } else {
          return name + '(' + (parseInt(agency)).toString(36) + '-' + id + 'L' + ')'
        }
      })
      result = [...new Set(result)];
      if (result.length >= 2) {
        result = result.slice(0, 2)
        return result.join(',') + ' and more lineitem(s).'
      }
      if (result.length > 0) {
        return ' lineitem(s) ' + result.join(',') + "."
      }
    }
  },
  mounted () {
    $(".perfect-scroll-bar").perfectScrollbar({ suppressScrollX: true });
  }
};
</script>
<style scoped>
.overflow-hidden{
  overflow-y: hidden !important;
  overflow-x: visible !important;
}
::v-deep .ivu-modal-body {
    padding: 0px !important;
  }
::v-deep .ivu-modal-header {
    padding-top: 1px;
    padding-bottom: 1px;
  }
::v-deep .ivu-select-input::placeholder {
  /* Firefox, Chrome, Opera */
  font-weight: 400 !important;
  border-color: 1px solid #cecdcd !important;
  color: black !important;
}
::v-deep .ivu-radio-checked .ivu-radio-inner{
  border-color: grey !important;
}
::v-deep .ivu-radio-checked .ivu-radio-inner::after{
  background-color: grey !important;
}
::v-deep .ivu-select-group-title {
    color: black;
    font-weight: bold;
}
::v-deep .ivu-input:hover {
  border-color: #ddd;
}
::v-deep .ivu-input:focus,
::v-deep .ivu-input-focused {
  border-color: #ddd !important;
  box-shadow: 0 0 0 1px white !important;
}
.icon-size{
  font-size: 20px;
  margin-top: 2px;
}
.with-error {
  height: 295px !important;
}
.without-error {
  height: 365px !important;
}
</style>
