import { video } from "./Video.js";
import { image } from "./Image.js";
import { youtube } from "./Youtube.js";
import { existingCreative } from "./ExistingCreative.js";
import { dbmEnums } from "../../Constants/DbmEnums.js";

class DV360Config {
  constructor (Config) {
    this.config = Config;
  }
  getConfig (configName) {
    let config = this.getDV360Config(configName);
    return config;
  }
  getDV360Config (configName) {
    let combinedConfig = {};
    let includeExistingCreative = false;

    for (let creativeType of this.config.creativeType) {
      if (creativeType == dbmEnums.creativeType.Display) {
        // includeExistingCreative = true;
        combinedConfig = {
          ...combinedConfig,
          ...image[configName]
        };
      } else if (creativeType == dbmEnums.creativeType.Video) {
        // includeExistingCreative = true;
        combinedConfig = {
          ...combinedConfig,
          ...video[configName]
        };
      } else if (creativeType == dbmEnums.creativeType.Youtube) {
        combinedConfig = {
          ...combinedConfig,
          ...youtube[configName](this.config)
        };
      }
    }

    if (includeExistingCreative) {
      combinedConfig = {
        ...combinedConfig,
        ...existingCreative[configName]
      }
    }

    return combinedConfig;
  }
  displayHeaderNameLookup () {
    return {
      "HOT_Is Valid": "Valid",
      "HOT_Ad Name": "Name",
      "HOT_Image": "Image",
      "HOT_No Scale To Fit": "Don't scale to fit device width",
      "HOT_Target": "Target",
      "HOT_HTML Tracking Tag": "HTML Tracking Tag",
      "HOT_Video": "Video",
      "HOT_Landing Page URL": "Landing Page URL",
      "HOT_Skip Button": "Skip Button",
      "HOT_Companion Creative": "Companion Creative",
      "HOT_Creative Id": "Creative Id",
      "HOT_Creative Name": "Creative Name",
      "HOT_CM360 Id": "CM360 Id",
      "HOT_Status": "Status",
      "HOT_Youtube URL": "Youtube URL",
      "HOT_Display URL": "Display URL",
      "HOT_Companion Banner": "Companion Banner",
      "HOT_Call To Action": "Call to action",
      "HOT_Headline": "Headline",
      "HOT_Long Headline": "Long headline",
      "HOT_Description": "Description",
      "HOT_Description2": "Description 2",
      "HOT_AdGroup": "AdGroup"
    };
  }
  columnWidths () {
    return {
      default: 100,
      108: ["HOT_Image", "HOT_Video"]
    };
  }
}

export { DV360Config };
