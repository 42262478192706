<template>
      <div style="height:500px;overflow:auto">
        <nav id="page-nav">
           <Select v-model="selectedAdgroup" size="large" style="width:30%">
            <Option v-for="item in adgroupInfo" :value="item" :key="item">{{ item }}</Option>
          </Select>
        </nav>
          <div v-for="(record, i) in budgetTypePivot.records" :key="i">
            <h4 :id="record.adgroup" style="padding: 10px 10px"><b>{{record.adgroup}}</b></h4>
              <div style="display:flex" v-if ="record.targetType == 'Keyword Targeting'">
        <table v-if="record.keywordsDetails.length > 0" class="table table-light table-bordered table-size">
        <thead>
            <tr>
                <th class="table-header">Keyword</th>
                <th class="table-header">MatchType</th>
                <th class="table-header">Bid</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(record, i) in record.keywordsDetails" :key="i">
                <td class="data-center"><Row type="flex" align="middle"><column span="20">{{record.Keyword}}</column><column span="4"><div v-if="failedTargets.keyword != undefined && failedTargets.keyword.includes(record.Keyword)"> <i class="fa fa-exclamation-triangle red"></i></div></column></Row></td>
                <td class="data-center">{{record.MatchType}}</td>
                <td class="data-center">{{record.Bid.toFixed(2)}}</td>
            </tr>
        </tbody>
      </table>
      <table v-if="record.negativeKeywordDetails.length > 0" class="table table-light table-bordered table-size" style="margin-left:2%">
        <thead>
            <tr>
                <th class="table-header">Negative Keywords</th>
                <th class="table-header">Matchtype</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(record, i) in record.negativeKeywordDetails" :key="i">
                <td class="data-center"><Row type="flex" align="middle"><column span="20">{{record.NegativeKeywords}}</column><column span="4"><div v-if="failedTargets.negativeKeyword != undefined && failedTargets.negativeKeyword.includes(record.NegativeKeywords)"> <i class="fa fa-exclamation-triangle red"></i></div></column></Row></td>
                <td class="data-center">{{record.MatchType}}</td>
            </tr>
        </tbody>
      </table>
              </div>
              <div style="display:flex" v-if ="record.targetType == 'Product Targeting'">
        <table v-if="record.targetDetails.length > 0" class="table table-light table-bordered table-size">
        <thead>
            <tr>
                <th class="table-header">Products</th>
                <th class="table-header">Bid</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(record, i) in record.targetDetails" :key="i">
                <td class="data-center"><Row type="flex" align="middle"><column span="20">{{record.AsinsToTarget}}</column><column span="4"><div v-if="failedTargets.asin != undefined && failedTargets.asin.includes(record.AsinsToTarget)"> <i class="fa fa-exclamation-triangle red"></i></div></column></Row></td>
                <td class="data-center">{{record.Bid.toFixed(2)}}</td>
            </tr>
        </tbody>
      </table>
      <table v-if="record.categoryDetails.length > 0" class="table table-light table-bordered table-size" style="margin-left:2%">
        <thead>
            <tr>
                <th class="table-header">Categories</th>
                <th class="table-header">Bid</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(record, i) in record.categoryDetails" :key="i">
                <td class="data-center"><Row type="flex" align="middle"><column span="20">{{record.CategoriesToTarget}}</column><column span="4"><div v-if="failedTargets.category != undefined && failedTargets.category.includes(record.categoryId)"> <i class="fa fa-exclamation-triangle red"></i></div></column></Row></td>
                <td class="data-center">{{record.Bid.toFixed(2)}}</td>
            </tr>
        </tbody>
      </table>
      <table v-if="record.excludedAsins.length > 0" class="table table-light table-bordered table-size" style="margin-left:2%">
        <thead>
            <tr>
                <th class="table-header">NegativeProducts</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(record, i) in record.excludedAsins" :key="i">
              <td class="data-center"><Row type="flex" align="middle"><column span="20">{{record}}</column><column span="4"><div v-if="failedTargets.negativeAsin != undefined && failedTargets.negativeAsin.includes(record)"> <i class="fa fa-exclamation-triangle red"></i></div></column></Row></td>
            </tr>
        </tbody>
      </table>
      </div>
      </div>
      </div>
</template>

<script>
import { mapState } from "vuex";
import { Select, Option, Row, Col } from "iview";
export default {
  components: {
    Select,
    Option,
    Row,
    "column": Col
  },
  data: function () {
    return {
      adgroupInfo: [],
      selectedAdgroup: '',
      Status:
    {
      0: "None",
      1: "enabled",
      2: "paused",
      3: "archived"
    },
      targetingType: {
        1: "Keyword Targeting",
        2: "Product Targeting"
      },
      targets: [],
      targetType: ''
    };
  },
  computed: {
    ...mapState([
      "amsEntitiesResponse",
      "selectedPublisher",
      "publisher",
      "isCampaignLauncherVerificationEnabled",
      "failedTargets"
    ]),
    budgetTypePivot: function () {
      const amsEntities = this.amsEntitiesResponse.data.amsEntities;
      if (!amsEntities.length) {
        return { records: [], campaigns: 0 };
      }
      let records = amsEntities.map(campaign => ({
        adGroupDetails: campaign.childEntities.map(adgroup => ({
          adgroup: adgroup.name,
          targetType: adgroup.additionalData.keywordsBidInfo.length > 0 ? this.targetingType[1] : this.targetingType[2],
          clusterInfo: adgroup.additionalData,
          targetDetails: adgroup.additionalData.productsToTargets.length > 0 ? adgroup.additionalData.productsToTargets : [],
          categoryDetails: adgroup.additionalData.categories.length > 0 ? adgroup.additionalData.categories : [],
          excludedAsins: adgroup.additionalData.negativeAsins.length > 0 ? adgroup.additionalData.negativeAsins : [],
          keywordsDetails: adgroup.additionalData.keywordsBidInfo.length > 0 ? adgroup.additionalData.keywordsBidInfo : [],
          negativeKeywordDetails: adgroup.additionalData.negativeKeywords.length > 0 ? adgroup.additionalData.negativeKeywords : []
        }))
      }));
      let adgroups = records.map(campaign => campaign.adGroupDetails);
      let details = [];
      adgroups.forEach(adgroup => {
        adgroup.forEach(adgrp => {
          details.push(adgrp);
        })
      })
      this.adgroupInfo = details;
      this.adgroupInfo = details.map(adgroup => adgroup.adgroup);
      let totalCampaigns = records.length;
      return { records: details, campaigns: totalCampaigns };
    }
  },
  methods: {
    scrollIntoView: function (id) {
      var e = document.getElementById(id);
      e.scrollIntoView();
    }
  },
  watch: {
    selectedAdgroup: function () {
      this.scrollIntoView(this.selectedAdgroup);
    }
  }
}
</script>

<style scoped>
  .data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
.card {
  /* Add shadows to create the "card" effect */
  transition: 0.3s;
  background-color: rgba(1,1,1,0);
  padding: 5px
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.table-header{
  border:1px solid #ddd !important;
  padding:10px !important;
}
.table-size {
    width: auto;
}
::v-deep .ivu-select-large.ivu-select-single .ivu-select-selection {
    border: none;
    height: 36px;
}
#page-nav {
  position: sticky;
  top: 0;
  z-index: 9;
  padding: 10px 16px;
  background: #fff;
  color: #f1f1f1;
  border-bottom: 1px solid #BBB;
  padding-left: 0px;
}
.ivu-select-selection {
    display: block;
    outline: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    position: relative;
    background-color: #f6f7f8;
    /* border-radius: 0px !important; */
    /* border-color: #dcdee2 !important; */
    border: none !important;
}
::v-deep .ivu-select-large.ivu-select-single .ivu-select-selection {
    border: none;
    height: 36px;
    display: initial;
}
::v-deep .ivu-select-selected-value {
    font-size: 17px !important;
    font-weight: 700 !important;
}
</style>
