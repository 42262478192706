class AmazonSpConfig {
  constructor (Config) {
    this.config = Config;
  }
  getConfig () {
    let targetsConfig = [];
    if (this.config.campaign.targetingType == 1) {
      let adGroups = new function () {
        this.base = ["HOT_Preview", "HOT_Campaigns", "HOT_Clusters", "HOT_Adgroup", "HOT_Targeting", "HOT_DefaultBid", "HOT_Targets"];
        this.requiredBase = [];
        this.card = []
        this.requiredCard = []
      }();
      targetsConfig.push(adGroups);
    } else {
      let adGroups = new function () {
        this.base = ["HOT_Campaigns", "HOT_Clusters", "HOT_Adgroup", "HOT_DefaultBid"];
        this.requiredBase = [];
        this.card = []
        this.requiredCard = []
      }();
      targetsConfig.push(adGroups);
    }
    return targetsConfig;
  }
  displayHeaderNameLookup () {
    return {
      "HOT_Preview": "Preview",
      "HOT_Campaigns": "Campaigns",
      "HOT_Clusters": "Clusters",
      "HOT_Adgroup": "Adgroup",
      "HOT_Targeting": "Targeting",
      "HOT_DefaultBid": "Default Bid",
      "HOT_Targets": "Targets"
    };
  }
  getKeywordTargetConfig () {
    let targetsConfig = [];
    let keywordsConfig = new function () {
      this.base = ["HOT_AdgroupIds", "HOT_Keyword", "HOT_MatchType", "HOT_Bid", "HOT_LowBid", "HOT_HighBid", "HOT_SuggestedBid"];
      this.requiredBase = [];
      this.card = []
      this.requiredCard = []
    }();
    let negativeKeywordsConfig = new function () {
      this.base = ["HOT_NKAdgroupIds", "HOT_NegativeKeyword", "HOT_NegativeMatchType"];
      this.requiredBase = [];
      this.card = []
      this.requiredCard = []
    }();
    targetsConfig.push(keywordsConfig);
    targetsConfig.push(negativeKeywordsConfig);
    return targetsConfig;
  }
  displayHeaderNameLookupTarget () {
    return {
      "HOT_AdgroupIds": "Adgroup",
      "HOT_Keyword": "Keyword",
      "HOT_Bid": "Bid",
      "HOT_MatchType": "Matchtype",
      "HOT_LowBid": "Low Bid",
      "HOT_HighBid": "High Bid",
      "HOT_SuggestedBid": "Suggested Bid bpCurrency <button onclick = 'updateKeywordBidValues()'><i class='fa fa-refresh' aria-hidden='true'></i></button>",
      "HOT_NegativeKeyword": "Negative Keyword",
      "HOT_NKAdgroupIds": "Adgroup",
      "HOT_NegativeMatchType": "Matchtype"
    };
  }
  getProductTargetConfig () {
    let targetsConfig = [];
    let keywordsConfig = new function () {
      this.base = ["HOT_ProductAdgroupIds", "HOT_Asin", "HOT_ProductBid", "HOT_LowBid", "HOT_HighBid", "HOT_SuggestedBid"];
      this.requiredBase = [];
      this.card = []
      this.requiredCard = []
    }();
    let negativeKeywordsConfig = new function () {
      this.base = ["HOT_NKProductAdgroupIds", "HOT_ExcludedAsins"];
      this.requiredBase = [];
      this.card = []
      this.requiredCard = []
    }();
    targetsConfig.push(keywordsConfig);
    targetsConfig.push(negativeKeywordsConfig);
    return targetsConfig;
  }
  displayHeaderNameLookupProductTarget () {
    return {
      "HOT_ProductAdgroupIds": "Adgroup",
      "HOT_NKProductAdgroupIds": "Adgroup",
      "HOT_Asin": "ASINs/Category",
      "HOT_ProductBid": "Bid",
      "HOT_MatchType": "Matchtype",
      "HOT_SuggestedBid": "Suggested Bid bpCurrency <button onclick = 'updateProductBidValues()'><i class='fa fa-refresh' aria-hidden='true'></i></button>",
      "HOT_LowBid": "Low Bid",
      "HOT_HighBid": "High Bid",
      "HOT_ExcludedAsins": "Excluded ASINs"
    };
  }

  getAutoTargetConfig () {
    let targetsConfig = [];
    let bidsConfig = new function () {
      this.base = ["HOT_AdgroupIds", "HOT_CloseMatchBid", "HOT_LooseMatchBid", "HOT_SubsitituteBid", "HOT_ComplementBid"];
      this.requiredBase = [];
      this.card = []
      this.requiredCard = []
    }();

    let negativeAsinsConfig = new function () {
      this.base = ["HOT_AdgroupIds", "HOT_ExcludedAsins"];
      this.requiredBase = [];
      this.card = []
      this.requiredCard = []
    }();

    let negativeKeywordsConfig = new function () {
      this.base = ["HOT_NKAdgroupIds", "HOT_NegativeKeyword", "HOT_NegativeMatchType"];
      this.requiredBase = [];
      this.card = []
      this.requiredCard = []
    }();
    targetsConfig.push(bidsConfig);
    targetsConfig.push(negativeAsinsConfig);
    targetsConfig.push(negativeKeywordsConfig);
    return targetsConfig;
  }
  displayAutoTargetingHeaderNameLookupTarget () {
    return {
      "HOT_AdgroupIds": "Adgroup",
      "HOT_NKAdgroupIds": "Adgroup",
      "HOT_Keyword": "Keyword",
      "HOT_Bid": "Bid",
      "HOT_MatchType": "Matchtype",
      "HOT_ExcludedAsins": "Excluded ASINs",
      "HOT_NegativeKeyword": "Negative Keyword",
      "HOT_CloseMatchBid": "Close Match Bid",
      "HOT_LooseMatchBid": "Loose Match Bid",
      "HOT_SubsitituteBid": "Subtitutes Bid",
      "HOT_ComplementBid": "Complements Bid"
    };
  }
}
export { AmazonSpConfig };
