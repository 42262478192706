<template>
    <v-row>
        <v-col span="24">
            <Select v-model="selectedCustomAudiences" filterable multiple placeholder="Select Custom Audiences">
                <Option v-for="item in molocoCustomTargets" :value="item.id" :key="item.id">{{ item.title }}</Option>
            </Select>
        </v-col>
    </v-row>
</template>

<script>
import {
  Row,
  Col,
  Select,
  Option
} from "iview";
import { mapState } from "vuex";

export default {
  props: {
    bpseId: {
      type: Number,
      required: true
    }
  },
  components: {
    "v-row": Row,
    "v-col": Col,
    Select,
    Option
  },
  data () {
    return {
      selectedCustomAudiences: [],
      customAudiences: []
    };
  },
  computed: {
    ...mapState([
      "molocoCustomTargets"
    ])
  },
  watch: {
    selectedCustomAudiences (val) {
      let customAudienceNames = [];
      for (let item of val) {
        for (let obj of this.molocoCustomTargets) {
          if (obj.id === item) {
            customAudienceNames.push(obj.title);
            break;
          }
        }
      }
      this.$store.state.molocoSelectedCustomAudiencesNames = customAudienceNames;
    }
  }
};
</script>
