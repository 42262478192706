import { Objective } from "./Objective.js"
let ColumnConfig = function () {
  this.single = new function () {
    this.base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Introductory Text", "HOT_Call To Action", "HOT_Image", "HOT_Ad Title", "HOT_Destination URL"];
    this.requiredBase = ["HOT_Introductory Text", "HOT_Call To Action", "HOT_Image", "HOT_Ad Title", "HOT_Destination URL"];
    this.card = []
    this.requiredCard = []
    this.callToAction = ["Learn More", "Apply", "Download", "View Quote", "Sign Up", "Subscribe", "Register", "Join", "Attend", "Request Demo", "See More"]
  }();
  this.text = new function () {
    this.base = ["HOT_Is Valid", "HOT_Image", "HOT_Ad Title", "HOT_Description", "HOT_Destination URL"]
    this.requiredBase = ["HOT_Image", "HOT_Ad Title", "HOT_Description", "HOT_Destination URL"]
    this.card = []
    this.requiredCard = []
    this.callToAction = []
  }();
}
let CreativeSpec = function () {
  let single = new function () {
    this.creative = {
      "$type": "DeltaX.API.Models.ClientModels.CampaignLauncher.LinkedIn.SingleImageAdData, DeltaX.API",
      "type": "1",
      "clickUri": "HOT_Destination URL",
      "introductoryText": "HOT_Introductory Text",
      "callToAction": "HOT_Call To Action",
      "dxImageId": "HOT_Image",
      "title": "HOT_Ad Title",
      "adName": "HOT_Ad Name"
    }
  }();
  let text = new function () {
    this.creative = {
      "$type": "DeltaX.API.Models.ClientModels.CampaignLauncher.LinkedIn.TextAdData, DeltaX.API",
      "type": "2",
      "clickUri": "HOT_Destination URL",
      "dxImageId": "HOT_Image",
      "text": "HOT_Description",
      "title": "HOT_Ad Title",
      "adName": "HOT_Ad Name"
    }
  }();
  return { single, text }
}

let websiteVisits = new Objective(new ColumnConfig(), new CreativeSpec())
export { websiteVisits }
