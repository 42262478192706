export default [
  {
    urn: "urn:li:adTargetingFacet:companyCategory",
    name: "Company Category",
    includeOnly: false,
    entityFinder: "GetAll"
  },
  {
    urn: "urn:li:adTargetingFacet:firstDegreeConnectionCompanies",
    name: "Company Connections",
    includeOnly: false,
    entityFinder: "TypeAhead"
  },
  {
    urn: "urn:li:adTargetingFacet:growthRate",
    name: "Company Growth Rate",
    includeOnly: false,
    entityFinder: "GetAll"
  },
  {
    urn: "urn:li:adTargetingFacet:industries",
    name: "Company Industries",
    includeOnly: false,
    entityFinder: "TypeAhead"
  },
  {
    urn: "urn:li:adTargetingFacet:employers",
    name: "Company Names",
    includeOnly: false,
    entityFinder: "TypeAhead"
  },
  {
    urn: "urn:li:adTargetingFacet:staffCountRanges",
    name: "Company Size",
    includeOnly: false,
    entityFinder: "GetAll"
  },
  {
    urn: "urn:li:adTargetingFacet:ageRanges",
    name: "Member Age",
    includeOnly: true,
    entityFinder: "GetAll"
  },
  {
    urn: "urn:li:adTargetingFacet:genders",
    name: "Member Gender",
    includeOnly: true,
    entityFinder: "GetAll"
  },
  {
    urn: "urn:li:adTargetingFacet:degrees",
    name: "Degrees",
    includeOnly: false,
    entityFinder: "TypeAhead"
  },
  {
    urn: "urn:li:adTargetingFacet:fieldsOfStudy",
    name: "Fields of Study",
    includeOnly: false,
    entityFinder: "TypeAhead"
  },
  {
    urn: "urn:li:adTargetingFacet:schools",
    name: "Member Schools",
    includeOnly: false,
    entityFinder: "TypeAhead"
  },
  {
    urn: "urn:li:adTargetingFacet:jobFunctions",
    name: "Job Functions",
    includeOnly: false,
    entityFinder: "GetAll"
  },
  {
    urn: "urn:li:adTargetingFacet:seniorities",
    name: "Job Seniorities",
    includeOnly: false,
    entityFinder: "GetAll"
  },
  {
    urn: "urn:li:adTargetingFacet:titles",
    name: "Job Titles",
    includeOnly: false,
    entityFinder: "TypeAhead"
  },
  {
    urn: "urn:li:adTargetingFacet:skills",
    name: "Member Skills",
    includeOnly: false,
    entityFinder: "TypeAhead"
  },
  {
    urn: "urn:li:adTargetingFacet:yearsOfExperienceRanges",
    name: "Years of Experience",
    includeOnly: true,
    entityFinder: "GetAll"
  },
  {
    urn: "urn:li:adTargetingFacet:groups",
    name: "Member Groups",
    includeOnly: true,
    entityFinder: "TypeAhead"
  },
  {
    urn: "urn:li:adTargetingFacet:interests",
    name: "Member Interests",
    includeOnly: false,
    entityFinder: "TypeAhead"
  },
  {
    urn: "urn:li:adTargetingFacet:memberBehaviors",
    name: "Member Traits",
    includeOnly: false,
    entityFinder: "GetAll"
  },
  {
    urn: "urn:li:adTargetingFacet:audienceMatchingSegments",
    name: "Matched Audience"
  },
  {
    urn: "urn:li:adTargetingFacet:dynamicSegments",
    name: "Dynamic Matched Audience"
  }
]
