<template>
<div>
  <Dropdown trigger="click" v-on:on-click="setDirectLaunchMode" placement="bottom-end" transfer>
    <button
    class="btn btn-success btn-sm widget-element pull-right dl-btn-style"
    :disabled="showSpinner"
    >
      <i class="fa fa-plus"></i>
    </button>
    <DropdownMenu slot="list">
      <template v-for="mode in supportedModes">
          <DropdownItem :name="mode.id" :key="mode.id">{{ mode.name }}</DropdownItem>
      </template>
    </DropdownMenu>
  </Dropdown>
  <!-- <media-plan-bulk-creation ref="mediaPlanBulkOperation" :loggedInUserId="deltax.userId"></media-plan-bulk-creation> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Dropdown, DropdownMenu, DropdownItem } from "iview";
import { EventBus } from "../EventBus.js"
export default {
  components: {
    Dropdown, DropdownMenu, DropdownItem
  },
  created () {
    this.supportedModes = [
      { id: 1, name: "Full Cross Channel" },
      { id: 2, name: "Facebook" }
    ]
  },
  data () {
    return {
      mode: 0
    }
  },
  methods: {
    setDirectLaunchMode (id) {
      if (id == 1) {
        EventBus.$emit("main.js-fullCrossChannelSelected");
        // full cross channel mode
        this.$nextTick(function () {
          window.mediaPlanBulkOperation.setBulkCreate(true, 0);
        });
      } else if (id == 2) {
        // window.lineItemConfiguration.setCreateMode(5);
        EventBus.$emit("lineItemConfiguration-setCreateMode", {
          publisher: 5
        });
      }
    }
  },
  computed: {
    ...mapState(["deltax", "showSpinner"])
  }
}
</script>

<style scoped>
.dl-btn-style {
  border-color: #dcdee2 !important;
  border-left: 0;
  height: 32px;
}
</style>
