<template>
  <tr v-show="show" class="rnf-settings-row">
    <td colspan="1" class="col-md-4" id="rnf-settings">
      <Spin v-if="processing" fix>
       <i class="fa fa-spinner fa-spin fa-2x" style="color:black"></i>
     </Spin>
     <Row>
       <Row-Col>
         <h4>Settings</h4>
      </Row-Col>
     </Row>
     <Row>
       <Row-Col>
          <div class="alert" :class="alert.type" v-show="alert.show">
              {{ alert.message }}
          </div>
       </Row-Col>
     </Row>
     <VuePerfectScrollbar class="rnf-settings-scroll-area">
      <Form v-model="prediction"
        :label-width="40"
        label-position="left">
        <FormItem prop="buffer" label="Buffer" v-if="false">
          <Row>
            <Row-Col span="6">
              <Input type="number" id="rnf-settings-buffer" v-model="prediction.buffer"/>
            </Row-Col>
          </Row>
        </FormItem>
        <FormItem label="Schedule">
          <Row>
            <Row-Col span="22">
              <DatePicker id="rnf-settings-schedule"
                v-model="prediction.schedule.range"
                :options="prediction.schedule.options"
                type="datetimerange"
                placement="bottom-end"
                format="MMMM d, yyyy HH:mm"
                :clearable="false"
                :split-panels="true"
                transfer
                >
              </DatePicker>
            </Row-Col>
          </Row>
        </FormItem>
        <FormItem label="Target Frequency">
          <Row>
            <Row-Col span="6">
              <Input type="number" id="rnf-settings-target-frequency" v-model="targetFrequency"/>
            </Row-Col>
            <Row-Col span="12" offset="2">
              <Button type="button" :disabled="loadingSuggestions" @click="getSuggestions" class="btn btn-success">
                Generate Suggestions
                <i v-if="loadingSuggestions" class="fa fa-spinner fa-spin" style="color:black"></i>
              </Button>
            </Row-Col>
          </Row>
        </FormItem>
        <FormItem label="Suggestions" v-if="suggestions.length > 0">
          <Row v-for="(suggestion, index) in suggestions" :key="index">
            <Row-Col :lg="12" :md="24">
              <span style="color:black !important">Freq Cap: {{ suggestion.frequencyCap}} Time/s, {{ suggestion.days}} Day/s</span>
            </Row-Col>
            <Row-Col :lg="7" :md="12">
              <span>Frequency: {{ suggestion.frequency.toFixed(2) }}</span>
            </Row-Col>
            <Row-Col :lg="2" :md="5" offset="1">
              <a style="color:black; cursor: pointer;" @click="setSuggestion(suggestion)">Use</a>
            </Row-Col>
          </Row>
        </FormItem>
        <FormItem label="Freq Cap">
          <Row>
            <Row-Col span="6">
              <Input type="number" id="rnf-settings-frequency-cap" v-model="prediction.frequencyBalance.cap"/>
            </Row-Col>
            <Row-Col span="3" offset="1">
              <span>Times</span>
            </Row-Col>
            <Row-Col span="6" offset="2">
              <Input type="number" id="rnf-settings-frequency-days" v-model="prediction.frequencyBalance.days"/>
            </Row-Col>
            <Row-Col span="3" offset="1">
              <span>Days</span>
            </Row-Col>
          </Row>
        </FormItem>
        <FormItem label="Placements">
          <Row>
            <Row-Col>
              <RadioGroup v-model="prediction.placementMode">
                <Radio label="Automatic"></Radio>
                <Radio label="Manual"></Radio>
              </RadioGroup>
            </Row-Col>
            <Row-Col v-show="prediction.placementMode == 'Manual'">
              <Tree ref="placement-tree" :data="prediction.placements" show-checkbox multiple check-directly v-on:on-check-change="processPlacements()"></Tree>
            </Row-Col>
          </Row>
        </FormItem>
      </Form>
    </VuePerfectScrollbar>
      <!-- <Card dis-hover>
        <p slot="title">Settings</p>
        <span id="rnf-settings-error" v-show="error.show">{{ error.message }}</span>

        <DatePicker id="rnf-settings-schedule"
          v-model="prediction.schedule.range"
          type="daterange"
          placement="bottom-end"
          format="MMMM d, yyyy"
          :clearable="false"
          :split-panels="true"
          >
        </DatePicker>
      </Card> -->
    </td>
    <td colspan="2" class="col-md-8" id="rnf-settings-data">
      <Row :gutter="16" style="padding-top:10px">
        <Row-Col span="3">
          Universe
          <div class="rnf-prediction-info" id="rnf-settings-universe">{{ prediction.data.universe }}</div>
        </Row-Col>
        <Row-Col span="3">
          % of Universe
          <Input :disabled="processing" type="text" id="rnf-settings-percofuniverse" v-model="percentageOfUniverse" />
        </Row-Col>
        <Row-Col span="3">
          Net Budget
          <Input :disabled="processing" type="text" id="rnf-settings-budget" v-model="netBudget" />
        </Row-Col>
        <Row-Col span="4">
          {{ prediction.labels.reach }}
          <Input :disabled="processing" type="text" id="rnf-settings-reach" v-model="reach"/>
        </Row-Col>
        <Row-Col span="5">
          {{ prediction.labels.cpa }}
          <div class="rnf-prediction-info" type="text" id="rnf-settings-cpa">{{ prediction.data.cpa }}</div>
        </Row-Col>
        <Row-Col span="3">
          {{ prediction.labels.frequency }}
          <div class="rnf-prediction-info" type="text" id="rnf-settings-frequency">{{ prediction.data.frequency }}</div>
        </Row-Col>
      </Row>
      <Divider/>
      <Row>
        <Row-Col>
          <highcharts :options="prediction.graphs.reachBudgetCurve"></highcharts>
        </Row-Col>
      </Row>
    </td>
  </tr>
</template>
<script>
import { Divider, Input, Row, Col, Form, FormItem, Tree, Radio, RadioGroup, DatePicker, Spin } from "iview"
import { mapState, mapGetters } from "vuex"
import { APIService } from '../ApiService'
import BulkExportAPIService from '../BulkExport/Components/ApiService'
import { Chart } from "highcharts-vue";
// import Highcharts from "highcharts";
import { EventBus } from "../EventBus.js"
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    Input,
    Row,
    "Row-Col": Col,
    Form,
    FormItem,
    Divider,
    Tree,
    highcharts: Chart,
    Radio,
    RadioGroup,
    DatePicker,
    Spin,
    VuePerfectScrollbar
  },
  props: {
    budget: {
      type: Number
    },
    netPlannedBudget: {
      type: Number
    },
    configId: {
      type: Number
    },
    targetId: {
      type: [Array, Number]
    },
    index: {
      type: Number
    },
    show: {
      type: Boolean,
      default: false
    },
    schedule: {
      type: Object,
      default: function () {
        return {
          start: "",
          end: ""
        }
      }
    },
    frequencyBalance: {
      type: Object,
      default: function () {
        return {
          cap: 2,
          days: 7
        }
      }
    },
    placements: {
      type: Array
    },
    adFormats: {
      type: Array
    },
    predictionId: {
      type: [String, Number]
    },
    placementMode: {
      type: String
    },
    buffer: {
      type: [String, Number]
    },
    isCreateMode: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      processing: false,
      prediction: {
        labels: {
          reach: "Reach",
          cpa: "CPM",
          frequency: "Frequency"
        },
        buffer: 0,
        schedule: {
          start: "",
          end: "",
          range: [],
          options: {}
        },
        placements: [],
        adFormats: [],
        reach: 0,
        percentageOfUniverse: 0,
        frequencyBalance: {
          cap: 2,
          days: 7
        },
        data: {
          universe: 0,
          percentageOfUniverse: 0,
          reach: 0,
          budget: 0,
          minBudget: 0,
          cpa: 0,
          frequency: 0,
          impressionsData: []
        },
        graphs: {
          reachBudgetCurve: {
            credits: {
              enabled: false
            },
            chart: {
              width: 350,
              height: 350,
              type: 'line'
            },
            title: {
              text: 'Reach and Budget'
            },
            series: [{
              name: "Reach and Budget",
              data: [],
              point: {
                events: {
                  click: (e) => {
                    this.userClickedOnGraph(e)
                  }
                }
              }
            }],
            xAxis: {
              plotLines: [{
                color: 'blue',
                value: 0,
                width: 2
              }]
            },
            tooltip: {
              crosshairs: [true, true],
              formatter: function (x, y, z) {
                return 'Budget: <b>' +
                  parseInt(this.x / 100).toLocaleString() +
                  '</b>, Reach: <b>' + this.y.toLocaleString() +
                  '</b>';
              }
            }
          },
          frequencyByReach: {
            chart: {
              width: 350,
              height: 350,
              type: 'line'
            },
            title: {
              text: 'Reach by frequency'
            },
            series: [{
              name: "Reach by frequency",
              data: [],
              point: {
                events: {
                  click: (e) => {
                    this.userClickedOnGraph(e)
                  }
                }
              }
            }]
          }
        },
        userInput: false,
        budget: 0,
        id: 0,
        placementMode: 'Automatic'
      },
      alert: {
        show: false,
        message: "",
        type: ""
      },
      isDataComputedInternally: false,
      targetFrequency: 0.0,
      suggestions: [],
      loadingSuggestions: false
    }
  },
  created () {
    this.prediction.schedule.start = this.schedule.start;
    this.prediction.schedule.end = this.schedule.end;
    let startDate = new Date(this.schedule.start);
    let endDate = new Date(this.schedule.end);
    let now = new Date();
    if (startDate.valueOf() < now.valueOf()) {
      var currentDateString = this.getDateString(now, true);
      var currentDate = new Date(currentDateString);
      startDate = currentDate;
    }
    this.prediction.buffer = this.buffer;
    this.prediction.schedule.range = [startDate, endDate]
    if (this.frequencyBalance.cap != "") {
      this.prediction.frequencyBalance.cap = this.frequencyBalance.cap
    }
    if (this.frequencyBalance.days != "") {
      this.prediction.frequencyBalance.days = this.frequencyBalance.days;
    }
    this.prediction.placements = this.placements;
    this.prediction.placementMode = this.placementMode;
    this.prediction.adFormats = this.adFormats;
    this.prediction.budget = this.budget;
    if (now > endDate) {
      this.setAlert("alert-danger", "The line item schedule is in the past");
    }
    var self = this;
    this.prediction.schedule.options.disabledDate = function (date) {
      var currentDate = date.valueOf();
      var lineItemStartDate = new Date(self.schedule.start).valueOf();
      var lineItemEndDate = new Date(self.schedule.end).valueOf();
      return currentDate < lineItemStartDate || currentDate > lineItemEndDate;
    }
    // this.$watch("predictionDataDependencies", this.predictionDataDependenciesWatch);
    // this.$watch("netBudget", this.generatePrediction);
    this.setupWatchers();
    // this.$watch("prediction.placements", this.generatePrediction, { deep: true });
    // this.$watch("prediction.buffer", this.generatePrediction);
    this.setupConfigAndTargetWatchers();
    this.adFormatStoryEventTypes = {
      "Image or Carousel": 0,
      "Video": 128,
      "Instant Experience": 256
    }
  },
  mounted () {
  },
  watch: {
    show: function (value) {
      if (value) { this.processPlacements(false); }
      if (value && this.predictionId > 0) {
        this.getPrediction();
      } else if (value && this.predictionId == 0) {
        this.generatePrediction();
      }
    }
  },
  methods: {
    processPlacements (generatePrediction = true) {
      this.resetAlert();
      var nodes = this.$refs["placement-tree"].getCheckedAndIndeterminateNodes();
      var parentNodes = [];
      var rightHandColumnExists = false;
      if (nodes.length > 0 && this.prediction.placementMode == "Manual") {
        nodes.forEach(function (e) {
          e.disabled = false;
          if (e.children) {
            parentNodes.push(e);
          }
          if (e.title == "Right hand column") {
            rightHandColumnExists = true;
          }
        })
        if (parentNodes.length == 1) { // parent node and child
          parentNodes[0].disabled = true;
          parentNodes[0].expand = true;
          if (nodes.length == 2) {
            nodes[1].disabled = true;
          } else if (nodes.length > 2) {
            if (rightHandColumnExists) {
              this.setAlert("alert-danger", "Right hand column cannot be used with any other placement");
              return;
            }
          }
        }
      }
      if (generatePrediction) {
        this.generatePrediction();
      }
    },
    setupConfigAndTargetWatchers () {
      this.$watch("targetId", function (value) {
        if (!this.show) { return; }
        if (Array.isArray(value)) {
          if (value.length > 0) this.generatePrediction();
        } else {
          if (value && value != "") this.generatePrediction();
        }
      })
      this.$watch("configId", function () {
        this.setAlert("alert-danger", "Target has been reset. Please choose a target to generate the prediction");
      })
    },
    setupWatchers () {
      this.budgetWatcher = this.$watch("prediction.budget", function (value) { this.computeDataBy(value, "Budget") });
      this.reachWatcher = this.$watch("prediction.reach", function (value) { this.computeDataBy(value, "Reach") });
      this.frequencyCapWatcher = this.$watch("prediction.frequencyBalance.cap", this.generatePrediction);
      this.frequencyIntervalWatcher = this.$watch("prediction.frequencyBalance.days", this.generatePrediction);
      this.scheduleWatcher = this.$watch("prediction.schedule.range", function () {
        this.generatePrediction();
        this.suggestions = [];
        this.targetFrequency = 0.0;
      });
      this.bufferWatcher = this.$watch("prediction.buffer", function () {
        this.computeNetBudget();
        this.suggestions = [];
        this.targetFrequency = 0.0;
      });
      this.placementModeWatcher = this.$watch("prediction.placementMode", this.processPlacements);
      this.percentageOfUniverseWatcher = this.$watch("prediction.percentageOfUniverse", this.computeReach);
    },
    teardownWatchers () {
      this.budgetWatcher();
      this.reachWatcher();
      this.frequencyCapWatcher();
      this.frequencyIntervalWatcher();
      this.scheduleWatcher();
      this.bufferWatcher();
      this.placementModeWatcher();
      this.percentageOfUniverseWatcher();
    },
    computeNetBudget () {
      var budget = Number(this.budget) / (1 + (this.prediction.buffer / 100))
      // var budget = this.budget - (this.budget * (Number(this.prediction.buffer) / 100))
      if (this.isCreateMode) {
        this.updateLineItemData();
      } else {
        this.prediction.data.budget = this.prediction.budget = budget.toFixed(2);
      }
    },
    computeReach () {
      var reach = this.prediction.percentageOfUniverse / 100 * this.prediction.data.universe;
      this.prediction.data.reach = this.prediction.reach = parseInt(reach);
    },
    getDateString (inputDate, includeBuffer) {
      includeBuffer = includeBuffer || false;
      var diff = 1 //  Adding buffer time.
      var time = includeBuffer ? inputDate.getTime() + diff * 60000 : inputDate.getTime();
      var date = new Date(time);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var hour = date.getHours();
      var hours = hour < 10 ? `0${hour}` : hour;
      var minutes = date.getMinutes();
      minutes = minutes > 10 ? minutes : `0${minutes}`;
      var currentDate = month + "/" + day + "/" + year + " " + hours + ":" + minutes;
      return currentDate;
    },
    generatePrediction () {
      var self = this;
      self.resetAlert();
      if (this.predictionGenerationTimeout) {
        clearTimeout(this.predictionGenerationTimeout)
      }
      this.predictionGenerationTimeout = setTimeout(function () {
        var predictionStartDate = self.getDateString(self.prediction.schedule.range[0], true);
        var predictionEndDate = self.getDateString(self.prediction.schedule.range[1]);
        if (isNaN(self.prediction.frequencyBalance.days) || self.prediction.frequencyBalance.days == "") {
          self.setAlert("alert-danger", "Frequency interval must be a number");
          return;
        }
        if (isNaN(self.prediction.frequencyBalance.cap) || self.prediction.frequencyBalance.cap == "") {
          self.setAlert("alert-danger", "Frequency cap must be a number");
          return;
        }
        if (self.prediction.frequencyBalance.days <= 0) {
          self.setAlert("alert-danger", "Frequency interval must be greater than 0");
          return;
        }
        if (self.prediction.frequencyBalance.cap <= 0) {
          self.setAlert("alert-danger", "Frequency cap must be greater than 0");
          return;
        }
        var storyEventType = self.prediction.adFormats.reduce((s, e) => {
          if (self.adFormatStoryEventTypes[e] != undefined) {
            return s + self.adFormatStoryEventTypes[e];
          } else {
            return s;
          }
        }, 0)
        var data = {
          configId: self.configId,
          settings: JSON.stringify({
            budget: self.netBudget,
            startDate: predictionStartDate,
            endDate: predictionEndDate,
            frequencyInterval: self.prediction.frequencyBalance.days == "" ? 7 : self.prediction.frequencyBalance.days,
            frequencyCap: self.prediction.frequencyBalance.cap == "" ? 2 : self.prediction.frequencyBalance.cap,
            placements: self.prediction.placements,
            placementMode: self.prediction.placementMode,
            storyEventType: storyEventType
          }),
          targetId: Array.isArray(self.targetId) ? self.targetId[0] : self.targetId
        }
        var bpseId = self.getBpseIdFromConfigId(self.configId);
        self.processing = true;
        APIService.generatePrediction(bpseId, data).then(self.generatePredictionSuccessCallback).catch(self.generatePredictionErrorCallback).then(self.generatePredictionAlwaysCallback);
      }, 1000)
    },
    setSuggestion (suggestion) {
      this.prediction.frequencyBalance.cap = suggestion.frequencyCap;
      this.prediction.frequencyBalance.days = suggestion.days;
    },
    getSuggestions () {
      this.setAlert("alert-warning", "Fetching suggestions might take a while");
      this.loadingSuggestions = true
      var bpseId = this.getBpseIdFromConfigId(this.configId);
      var predictionStartDate = this.getDateString(this.prediction.schedule.range[0], true);
      var predictionEndDate = this.getDateString(this.prediction.schedule.range[1]);
      var storyEventType = this.prediction.adFormats.reduce((s, e) => {
        if (this.adFormatStoryEventTypes[e] != undefined) {
          return s + this.adFormatStoryEventTypes[e];
        } else {
          return s;
        }
      }, 0);
      var data = {
        configId: this.configId,
        rnfSettings: JSON.stringify({
          budget: this.netBudget,
          startDate: predictionStartDate,
          endDate: predictionEndDate,
          frequencyInterval: this.prediction.frequencyBalance.days == "" ? 7 : this.prediction.frequencyBalance.days,
          frequencyCap: this.prediction.frequencyBalance.cap == "" ? 2 : this.prediction.frequencyBalance.cap,
          placements: this.prediction.placements,
          placementMode: this.prediction.placementMode,
          storyEventType: storyEventType
        }),
        targetId: Array.isArray(this.targetId) ? this.targetId[0] : this.targetId,
        targetFrequency: this.targetFrequency
      }
      APIService.getReachAndFrequencySuggestions(bpseId, data)
        .then(response => {
          this.suggestions = response.data.data;
          this.resetAlert();
        })
        .catch(e => {
          this.setAlert("alert-danger", "Something went wrong while fetching the suggestions: " + ((e && e.response && e.response.data) ? e.response.data.message : ""));
        })
        .finally(() => {
          this.loadingSuggestions = false;
        });
    },
    getPrediction () {
      var bpseId = this.getBpseIdFromConfigId(this.configId);
      BulkExportAPIService.GetPredictionStatusInBulk(bpseId, this.predictionId).then(response => {
        if (response.data) {
          var data = JSON.parse(response.data)[0];
          if (data.status != 1) {
            this.alert.message = data.message;
            this.alert.type = "alert-danger";
            this.alert.show = true;
          } else {
            this.resetAlert();
            this.setPredictionData(data, false);
          }
        }
      });
    },
    generatePredictionAlwaysCallback (response) {
      this.processing = false;
    },
    generatePredictionErrorCallback (e) {
      console.log(e);
      this.setAlert("alert-danger", "Something went wrong while processing the request, please try again");
    },
    setPredictionData (response, updateLineItem = false) {
      var result = response.data;
      this.prediction.data.universe = result.target_audience_size;
      this.prediction.data.reach = result.external_reach;
      this.prediction.data.minBudget = result.external_minimum_budget;
      this.prediction.buffer = this.buffer;
      if (this.isCreateMode) {
        if (!this.budget) {
          var minimumBudgetAsReach = (result.curve_budget_reach.budget[result.curve_budget_reach.reach.findIndex(x => x == 200000)]) ? result.curve_budget_reach.budget[result.curve_budget_reach.reach.findIndex(x => x == 200000)] / 100 : 0
          this.prediction.data.budget = minimumBudgetAsReach
          this.netBudget = minimumBudgetAsReach
        } else if (this.netPlannedBudget > 0) {
          var value = (Number(this.netPlannedBudget) / 100)
          this.prediction.data.budget = value
          this.prediction.budget = value
          this.netBudget = value
        } else {
          this.prediction.data.budget = this.budget
          this.netBudget = this.budget
        }
      }
      this.computeNetBudget();
      this.prediction.data.percentageOfUniverse = (result.external_reach * 100 / result.target_audience_size).toFixed(2);
      this.prediction.data.impressions = result.external_impression;
      // need to handle case for thruplay
      this.prediction.data.cpa = (result.external_budget / result.external_impression / 100 * 1000).toFixed(2);
      this.prediction.data.frequency = (result.external_impression / result.external_reach).toFixed(2);
      var budgetData = result.curve_budget_reach.budget;
      var objective = this.getCampaignObjectiveFromConfigId(this.configId);
      var billingEvent = this.getBillingEventFromConfigId(this.configId);
      var reachData = [];
      if (objective == 12 && billingEvent == 13) {
        reachData = result.curve_budget_reach.raw_reach;
        this.impressionsData = result.curve_budget_reach.raw_impression;
      } else {
        reachData = result.curve_budget_reach.reach;
        this.impressionsData = result.curve_budget_reach.impression;
      }
      // var frequencyData = result.frequency_distribution_map_agg;
      var reachBudgetSeries = budgetData.map(function (value, index) {
        return [value, reachData[index]];
      })
      this.prediction.graphs.reachBudgetCurve.xAxis.plotLines[0].value = reachBudgetSeries[0][0];
      this.prediction.graphs.reachBudgetCurve.series[0].data = reachBudgetSeries
      this.prediction.id = response.data.id;
      this.computeDataFromReach(this.netBudget, "Budget");
      this.computeNetBudget();
      if (updateLineItem) {
        this.updateLineItemData();
      }
    },
    updateLineItemData () {
      if (this.alert.show) {
        return;
      }
      var predictionStartDate = this.getDateString(this.prediction.schedule.range[0], true);
      var predictionEndDate = this.getDateString(this.prediction.schedule.range[1]);
      var targetId = Array.isArray(this.targetId) ? this.targetId[0] : this.targetId
      this.$emit("newRnfPrediction", {
        reachAndFrequencySettings: {
          id: this.prediction.id,
          placements: this.prediction.placements,
          frequencyCap: this.prediction.frequencyBalance.cap,
          frequencyInterval: this.prediction.frequencyBalance.days,
          placementMode: this.prediction.placementMode,
          buffer: this.prediction.buffer,
          budget: this.prediction.budget,
          startDate: predictionStartDate,
          endDate: predictionEndDate,
          adformat: this.prediction.adFormats,
          reach: this.prediction.reach,
          percentageOfUniverse: this.prediction.percentageOfUniverse,
          cpa: this.prediction.data.cpa,
          frequency: this.prediction.data.frequency,
          impressions: this.prediction.data.impressions,
          universe: this.prediction.data.universe,
          targetId: targetId
        },
        reachAndFrequencyPredictionId: this.prediction.id,
        targetId: targetId
      });
    },
    generatePredictionSuccessCallback (response) {
      this.prediction.userInput = false;
      var result = response.data;
      if (result.data.status != 1) {
        this.alert.message = result.data.message;
        this.alert.type = "alert-danger";
        this.alert.show = true;
      } else {
        this.resetAlert();
        this.setPredictionData(result.data, true);
      }
    },
    resetAlert () {
      this.alert.message = "";
      this.alert.type = "";
      this.alert.show = false;
    },
    setAlert (type, message, show) {
      this.alert.type = type;
      this.alert.message = message;
      this.alert.show = true;
    },
    computeDataBy (value, metric) {
      var self = this;
      if (this.internalComputationTimeout) clearTimeout(this.internalComputationTimeout)
      this.internalComputationTimeout = setTimeout(() => {
        self.computeDataFromReach.call(this, value, metric)
      }, 500)
    },
    computeDataFromReach (value, baseMetric, updateBuffer = true) {
      this.resetAlert();
      var billingEvent = this.getBillingEventFromConfigId(this.configId);
      var costPerThousand = false;
      if (billingEvent == 13) {
        this.prediction.labels.reach = "2s Video Views";
        this.prediction.labels.cpa = "Cost / 2s Video View";
        this.prediction.labels.frequency = "Frequency"
      } else if (billingEvent == 15) {
        this.prediction.labels.reach = "Unique ThruPlays";
        this.prediction.labels.cpa = "Cost / Unique ThruPlays";
        this.prediction.labels.frequency = "Frequency"
      } else {
        this.prediction.labels.reach = "Reach";
        this.prediction.labels.cpa = "CPM";
        this.prediction.labels.frequency = "Frequency";
        costPerThousand = true;
      }
      var intervalRatio = 0;
      var reachBudgetData = this.prediction.graphs.reachBudgetCurve.series[0].data;
      var baseMetricIndex = -1;
      var dependentMetricIndex = -1;
      var minBaseValue = 0;
      var minDependentValue = 0;
      var dependentMetric = ""
      var baseMetricText = ""
      if (baseMetric == "Budget") {
        baseMetricIndex = 0;
        dependentMetricIndex = 1;
        value *= 100;
        minBaseValue = reachBudgetData[0][0];
        minDependentValue = 200000;
        dependentMetric = "Reach";
        baseMetricText = "Budget";
      } else {
        baseMetricIndex = 1;
        dependentMetricIndex = 0;
        minBaseValue = 200000;
        minDependentValue = reachBudgetData[0][0];
        dependentMetric = "Budget";
        baseMetricText = this.prediction.labels.reach;
      }
      for (var i = 0; i < reachBudgetData.length; i++) {
        var item = reachBudgetData[i];
        var baseMetricValue = item[baseMetricIndex];
        var dependentMetricValue = item[dependentMetricIndex];
        if (value < minBaseValue && i == 0) {
          if (baseMetricText == "Budget") {
            minBaseValue = minBaseValue / 100
          }
          this.setAlert("alert-danger", baseMetricText + " value is too low. Minimum " + baseMetricText + " value must be " + minBaseValue);
          return;
        }
        if (value < baseMetricValue) {
          var baseMetricLowerBound = reachBudgetData[i - 1][baseMetricIndex];
          var baseMetricUpperBound = item[baseMetricIndex];
          intervalRatio = (value - baseMetricLowerBound) / (baseMetricUpperBound - baseMetricLowerBound);
          var dependentMetricLowerBound = reachBudgetData[i - 1][dependentMetricIndex]
          var dependentMetricUpperBound = dependentMetricValue;
          var computedDependentValue = (dependentMetricUpperBound - dependentMetricLowerBound) * intervalRatio + dependentMetricLowerBound;
          if (computedDependentValue < minDependentValue) {
            this.setAlert("alert-danger", dependentMetric + " value is too low. Minimum " + dependentMetric + " value must be " + minDependentValue);
          }
          this.prediction.graphs.reachBudgetCurve.xAxis.plotLines[0].value = baseMetric == "Budget" ? baseMetricLowerBound : dependentMetricLowerBound;

          var impressionsValue = (this.impressionsData[i] - this.impressionsData[i - 1]) * intervalRatio + this.impressionsData[i - 1];
          this.teardownWatchers();
          var lineItemBudget = this.budget;
          if (this.isCreateMode && baseMetric == "Budget") {
            lineItemBudget = value / 100
            this.prediction.budget = value / 100
          }
          if (baseMetric == "Budget") {
            this.prediction.data.percentageOfUniverse = this.prediction.percentageOfUniverse = (computedDependentValue * 100 / this.prediction.data.universe).toFixed(2);
            this.prediction.data.reach = this.prediction.reach = parseInt(computedDependentValue);
            this.prediction.data.cpa = this.getCpaValue(value / 100, impressionsValue, costPerThousand);
            this.prediction.data.frequency = (impressionsValue / computedDependentValue).toFixed(2);
            if (updateBuffer && !this.isCreateMode) {
              var computedBuffer = (-(((value / 100) - lineItemBudget) / (value / 100)) * 100).toFixed(2)
              // var computedBuffer = ((lineItemBudget - (value / 100)) * 100 / lineItemBudget).toFixed(2);
              this.prediction.buffer = computedBuffer;
            }
          } else {
            computedDependentValue = computedDependentValue / 100;
            this.prediction.data.budget = this.prediction.budget = computedDependentValue.toFixed(2);
            var netBuffer = (-(((value / 100) - lineItemBudget) / (value / 100)) * 100).toFixed(2)
            if (updateBuffer && !this.isCreateMode) {
              this.prediction.buffer = netBuffer;
            }
            this.prediction.data.percentageOfUniverse = this.prediction.percentageOfUniverse = (value * 100 / this.prediction.data.universe).toFixed(2);
            // need to handle case for thruplay
            this.prediction.data.cpa = this.getCpaValue(computedDependentValue, impressionsValue, costPerThousand);
            this.prediction.data.frequency = (impressionsValue / value).toFixed(2);
          }
          if (this.prediction.buffer < 0 && !this.isCreateMode) {
            this.setAlert("alert-danger", "Net budget cannot be greater than the line item budget: " + this.budget);
          }
          this.$nextTick(function () {
            this.setupWatchers();
            this.updateLineItemData();
          })
          // update other related properties
          // this.netBudget = parseInt(computedDependentValue / 100);
          return;
        }
      }
      this.setAlert("alert-danger", "Reach is too high")
    },
    getCpaValue (budget, impressions, costPerThousand) {
      if (costPerThousand) {
        return (budget / impressions * 1000).toFixed(2);
      } else {
        var cpa = (budget / impressions);
        var cpaText = "( " + (cpa * 1000).toFixed(2) + " per 1000 )";
        return cpa.toFixed(2) + cpaText;
      }
    },
    userClickedOnGraph (event) {
      var selectedBudget = event.point.x / 100
      this.netBudget = selectedBudget
      this.reach = event.point.y
      this.$nextTick(function () {
        this.computeDataFromReach(selectedBudget, "Budget");
      })
    }
  },
  computed: {
    ...mapGetters([
      "getBpseIdFromConfigId",
      "getBillingEventFromConfigId",
      "getCampaignObjectiveFromConfigId"
    ]),
    reach: {
      get: function () {
        return this.prediction.data.reach
      },
      set: function (value) {
        this.prediction.reach = value
      }
    },
    netBudget: {
      get: function () {
        return this.prediction.data.budget
      },
      set: function (value) {
        this.prediction.budget = value
      }
    },
    percentageOfUniverse: {
      get: function () {
        return this.prediction.data.percentageOfUniverse;
      },
      set: function (value) {
        this.prediction.percentageOfUniverse = value;
      }
    },
    ...mapState(["deltax", "businessProfileId"])
  }
}
</script>
<style>
.rnf-settings-row {
  vertical-align: top;
  background-color: #f0f0f0;
}
.rnf-prediction-info {
  padding:7px;
}
#rnf-settings {
  position: relative;
}
/* #rnf-settings .ivu-spin-main {
  top: 20%
} */
.rnf-settings-scroll-area {
  position: relative;
  margin: auto;
  height: 400px;
}
.rnf-settings-row .ivu-date-picker{
  width: inherit;
}
#rnf-settings-data {
  overflow-x: hidden;
}
</style>
