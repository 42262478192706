import { brandAwareness } from "./BrandAwareness"
import { appInstalls } from "./AppInstalls"
import { leads } from "./Leads"
import * as molocoEnums from "../../Constants/MolocoEnums";

class MolocoConfig {
  constructor (Config) {
    const { count, fieldMacros } = molocoEnums.eventTracking
    const fields = Array.from(new Array(count), (_, i) => i + 1).reduce((l, e) => [...l, ...fieldMacros.map(field => field.split("$").join(e))], [])
    const hotFields = fields.map(field => `HOT_${field}`)
    const hotFieldHeaders = fields.reduce((l, field) => ({ ...l, [`HOT_${field}`]: field }), {})

    this.eventTracking = { count, fields, hotFields, hotFieldHeaders }
    this.config = Config
  }
  getConfig (configName) {
    let config = { ...this.getMolocoConfig(configName) };
    return config
  }
  getMolocoConfig (configName) {
    switch (this.config.campaign.objective) {
      case molocoEnums.objectives["Brand Awareness"]:
        return brandAwareness[configName]
      case molocoEnums.objectives["App Installs"]:
        return appInstalls[configName]
      case molocoEnums.objectives["Leads"]:
        return leads[configName]
      default:
        return null;
    }
  }
  getObjectiveClassById (id) {
    if (id == molocoEnums.objectives["Brand Awareness"]) {
      return brandAwareness;
    } else if (id == molocoEnums.objectives["App Installs"]) {
      return appInstalls;
    } else if (id == molocoEnums.objectives["Leads"]) {
      return leads;
    }
  }
  displayHeaderNameLookup () {
    return {
      "HOT_Is Valid": "Valid",
      "HOT_Ad Name": "Ad Name",
      "HOT_Asset": "Asset",
      "HOT_App Icon": "App Icon",
      "HOT_Business Title": "Business Title",
      "HOT_Description": "Description",
      "HOT_Call To Action": "Call To Action",
      "HOT_Image Banner": "Image Banner",
      "HOT_Landing Page URL": "Landing Page URL",
      "HOT_Jio Creative ID": "Jio Creative ID",
      ...this.eventTracking.hotFieldHeaders,
      "HOT_JSON": "JSON",
      "HOT_Targets": "Targets",
      "HOT_Ad Target": "Ad Target"
    }
  }
  columnWidths () {
    return {
      "default": 100,
      108: ["HOT_Image"],
      200: [
        "HOT_Ad Name",
        "HOT_Asset ",
        "HOT_App Icon",
        "HOT_Business Title",
        "HOT_Call To Action",
        "HOT_Image Banner",
        "HOT_Landing Page URL",
        "HOT_Jio Creative ID",
        ...this.eventTracking.hotFields
      ],
      240: ["HOT_Description", "HOT_JSON"]
    }
  }
}

export { MolocoConfig }
