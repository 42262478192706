<template>
  <div>
    <div v-if="showInclusion">
      <div v-if="language && includedLocations.length">
        <strong>Include</strong> people with language:
        {{ urnNameMap[language] }} AND living in:
        {{ includedLocations.map(getUrnName).join(", ") }}
      </div>

      <div v-for="(andObj, i) in targeting.include.and" :key="i">
        <div class="and-block" v-if="Object.keys(andObj.or).length">
          AND who meet ANY of the following criteria:
          <div v-for="(attributes, facetUrn, j) in andObj.or" :key="j">
            <span class="facet-name"> {{ urnNameMap[facetUrn] }}: </span>
            {{ attributes.map(getUrnName).join(", ") }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="showExclusion" class="exclusion-wrapper">
      <div>
        <strong>Exclude</strong> people who meet ANY of the following criteria:
      </div>
      <div v-if="excludedLocations.length">
        <span class="facet-name">Locations</span>:
        {{ excludedLocations.map(getUrnName).join(", ") }}
      </div>

      <div v-for="(attributes, facetUrn, j) in targeting.exclude.or" :key="j">
        <span class="facet-name"> {{ urnNameMap[facetUrn] }}: </span>
        {{ attributes.map(getUrnName).join(", ") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    targeting: Object,
    includedLocations: Array,
    excludedLocations: Array,
    language: String,
    urnNameMap: Object
  },
  computed: {
    showInclusion () {
      return (this.targeting.include.and || []).length > 0;
    },
    showExclusion () {
      return (
        Object.keys(this.targeting.exclude.or || {}).length > 0 ||
        this.excludedLocations.length > 0
      );
    }
  },
  data () {
    return {};
  },
  methods: {
    getUrnName (urnId) {
      return this.urnNameMap[urnId];
    }
  }
};
</script>

<style scoped>
.facet-name {
  font-weight: bold;
}
.and-block {
  margin-top: 6px;
}
.exclusion-wrapper {
  margin-top: 10px;
}
</style>
