<template>
  <v-highchart :options="chartOptions"></v-highchart>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import { costRadialGauge } from "../../../Directives/dxHighchartsModule.js"

highchartsMore(Highcharts);
solidGauge(Highcharts);

export default {
  props: {
    ActualCost: { default: 0 },
    CostPace: { default: 0 }
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          height: '60px',
          width: 100
        },
        title: null,
        pane: {
          center: ['50%', '100%'],
          size: '200%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: "#EEE",
            innerRadius: "60%",
            outerRadius: "100%",
            shape: "arc"
          }
        },
        tooltip: {
          enabled: true
        },
        yAxis: {
          lineWidth: 1,
          minorTickInterval: null,
          tickAmount: 0,
          showFirstLabel: false,
          showLastLabel: false,
          min: 0,
          max: 100
        },
        plotOptions: {
          series: {
            tooltip: {
              valueSuffix: ' %',
              valueDecimals: 1,
              outside: true,
              useHTML: true,
              pointFormat: '<span style="color:{point.color}"></span> {series.name}<br /><b>{point.y}</b>'
            }
          },
          solidgauge: {
            dataLabels: {
              enabled: false
            }
          },
          gauge: {
            dataLabels: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: "Cost",
            type: "solidgauge",
            data: [{ y: this.ActualCost > 100 ? 100 : this.ActualCost, color: (this.CostPace - this.ActualCost < 10) && (this.ActualCost - this.CostPace < 5) ? '#449DD1' : '#D4526E' }]
          },
          {
            name: "Pace",
            type: "gauge",
            data: [{ y: this.CostPace, color: costRadialGauge.orange }],
            dial: {
              backgroundColor: costRadialGauge.orange,
              radius: "80%",
              baseWidth: 2,
              baseLength: "10%",
              rearLength: 1
            },
            pivot: {
              backgroundColor: 'grey',
              radius: 2
            }
          }
        ]
      }
    };
  },
  components: {
    "v-highchart": Chart
  },
  watch: {
    ActualCost: function (nv, ov) {
      this.chartOptions.series[0].data = [{ y: nv, color: nv > this.CostPace ? costRadialGauge.blue : costRadialGauge.crimson }];
    },
    CostPace: function (nv, ov) {
      this.chartOptions.series[1].data = [{ y: nv, color: costRadialGauge.orange }];
    }
  }
};
</script>
