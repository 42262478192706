<template>
  <div>
    <tooltip content="Quick Edit" placement="top">
      <Button
        v-on:click="openModal = true"
        style="width: 100%;"
        >
          <i class="fa fa-clipboard" />
        </Button>
    </tooltip>
      <Modal
        v-model="openModal"
        title="Quick Edit"
        width="60%"
        @on-cancel="reset"
        :mask-closable="false"
      >
        <Alert type="error" v-if="showError" banner closable>{{ errorMessage }}</Alert>
        <div class="dx-tab-style">
          <Tabs type="card" v-model="selectedTab" style="padding: 5px;">
            <TabPane label="Edit" name="edit">
              <div class="seperator">
                <label class="seperator-label">Seperator</label>
                <RadioGroup
                  class="radiogroup"
                  vertical
                  v-model="seperatorType"
                  @on-change="seperatorChanged"
                >
                  <Radio label="Comma"></Radio>
                  <Radio label="Line"></Radio>
                </RadioGroup>
              </div>
              <div v-if="invalidInput">
                <Alert type="warning" banner closable class="targeting-warn" @on-close="resetInvalidInput">Please give propert input as per the seperator.</Alert>
              </div>
              <div>
                <i-input style="width: 99%;" type="textarea" :rows="3" v-model="inputText" :placeholder=placeholderText></i-input>
              </div>
            </TabPane>
            <TabPane label="Preview" name="preview" :disabled="!disablePreviewTab">
              <div>
                <label>Mode</label>
                <RadioGroup
                  class="radiogroup"
                  v-model="modeType"
                >
                  <Radio label="Overwrite"></Radio>
                  <Radio label="Append"></Radio>
                </RadioGroup>
              </div>
              <div v-if="validInterests.length || invalidInterests.length">
                <Alert type="warning" banner closable class="targeting-warn">{{ validateResultMessage }}</Alert>
              </div>
              <div class="preview-interests">
                <div
                class="selected-target-box"
                v-for="(item, index) in validInterests"
                :key="item.id">
                <div class="selected-target-data">
                  <div>
                    {{ item.name }}
                  </div>
                  <div>
                    <span v-for="(path, index) in item.path" :key="path.name">
                      {{ path }}
                      <Icon
                        v-if="index != item.path.length - 1"
                        type="md-arrow-dropright"
                      />
                    </span>
                  </div>
                </div>
                <div style="align-self: center;">
                  <span v-if="item.audience_size" class="badge"> {{ parseInt(item.audience_size).toLocaleString() }} </span>
                </div>
                <i class="selected-target-icon fa fa-trash" @click="removeInterests(index)"></i>
              </div>
              </div>
            </TabPane>
          </Tabs>
        </div>

        <div slot="footer">
          <input type="button" class="btn btn-white" :disabled="showBackButton" value="Back" @click="showEditTab"/>
          <button
          class="btn btn-success"
          :disabled="!showConfirmButton"
          @click="handleProcessing"
        > {{ btnText }} <span v-if="processing"><i class="fa fa-spinner fa-spin"></i></span>
        </button>
        </div>
    </Modal>
  </div>
</template>

<script>
import {
  Button,
  Modal,
  TabPane,
  Tabs,
  RadioGroup,
  Radio,
  Input,
  Alert,
  Icon
} from "iview";
import { APIService } from "../../ApiService.js";
import { tooltip } from "vue-strap";

export default {
  components: {
    Button,
    Modal,
    TabPane,
    Tabs,
    RadioGroup,
    Radio,
    "i-input": Input,
    Alert,
    Icon,
    tooltip
  },
  props: {
    selectedBpseId: Number
  },
  data () {
    return {
      openModal: false,
      seperatorType: 'Comma',
      modeType: 'Overwrite',
      selectedTab: 'edit',
      btnText: 'Next',
      inputText: '',
      placeholderText: 'Please add comma seperated Interests',
      invalidInput: false,
      showPreviewTab: false,
      validateResultMessage: '',
      validInterests: [],
      invalidInterests: [],
      interestsList: [],
      lastQuery: '',
      processing: false,
      showError: false,
      errorMessage: ''
    }
  },
  mounted () {
  },
  computed: {
    showBackButton () {
      this.showPreviewTab = false;
      return this.selectedTab == 'edit';
    },
    showConfirmButton () {
      if (this.selectedTab == 'edit') {
        this.btnText = 'Next'
        return this.inputText.length;
      } else {
        this.btnText = 'Confirm'
        return this.validInterests.length;
      }
    },
    disablePreviewTab () {
      return this.showPreviewTab;
    }
  },
  methods: {
    showEditTab () {
      this.showPreviewTab = false;
      this.selectedTab = 'edit';
    },
    handleProcessing () {
      if (this.selectedTab == 'edit') {
        if (this.lastQuery != this.inputText) {
          this.lastQuery = this.inputText;
          let isInputValid = this.validateInputText();
          if (isInputValid) {
            this.resetInvalidInput();
            this.interestsList = this.interestsList.map(word => {
              word = word.trim();
              return word.charAt(0).toUpperCase() + word.slice(1);
            });
            this.validateInterests(this.interestsList);
          }
        } else {
          this.selectedTab = 'preview';
        }
      } else if (this.selectedTab == 'preview') {
        this.validInterests.forEach((obj) => {
          obj.type = "interests";
        });
        this.$emit('add-bulk-interests', { 'data': this.validInterests, 'mode': this.modeType });
        this.reset();
      }
    },
    seperatorChanged (value) {
      if (value == 'Comma') {
        this.placeholderText = 'Please add comma seperated Interests';
      } else if (value == 'Line') {
        this.placeholderText = 'Please add line seperated Interests';
      }
      this.lastQuery = '';
    },
    resetInvalidInput () {
      this.invalidInput = false;
    },
    async validateInterests (interestsList) {
      try {
        let validateData = { "Type": 'interests', "Interests": interestsList }
        let result = await APIService.validateTargetingSearch(this.selectedBpseId, validateData);
        if (result) {
          this.showPreviewTab = true;
          this.selectedTab = 'preview';
          result.forEach(interest => {
            if (interest.valid && interest.id && interest.audience_size) {
              this.validInterests.push(interest);
            }
          });
          // We are adding interests in invalid list only if they are not present in valid lists.
          // This is because for eg - cricket we get 1 value as valid and another as invalid.
          const validNames = this.validInterests.map(item => item.name);
          this.invalidInterests = result
            .filter(item => !item.valid && !validNames.some(validName => validName.includes(item.name)))
            .map(item => item.name);

          this.updateResultMessage();
        }
      } catch (error) {
        this.showError = true;
        this.errorMessage = error.data.message;
      } finally {
        this.processing = false;
      }
    },
    removeInterests (index) {
      this.validInterests.splice(index, 1);
      this.updateResultMessage();
    },
    updateResultMessage () {
      this.validateResultMessage = '';
      if (this.validInterests.length) {
        this.validateResultMessage = `${this.validInterests.length} Interests successfully processed. `;
      }
      if (this.invalidInterests.length) {
        this.validateResultMessage += 'Interest(s) ' + this.invalidInterests.join(',') + ' unrecognized.';
      }
    },
    reset () {
      this.selectedTab = 'edit';
      this.seperatorType = 'Comma';
      this.inputText = '';
      this.lastQuery = '';
      this.modeType = 'Overwrite';
      this.resetInvalidInput();
      this.openModal = false;
    },
    validateInputText () {
      this.invalidInterests = [];
      this.validInterests = [];
      this.interestsList = [];
      this.processing = true;
      if (this.seperatorType == 'Comma') {
        let isInvalid = this.inputText.includes('\n') || this.inputText.split(',').map(item => item.trim()).filter(item => item.length == 0).length;
        if (isInvalid) {
          this.invalidInput = true;
          this.lastQuery = '';
          this.processing = false;
          return false;
        }
        this.interestsList = this.inputText.split(',');
      } else {
        let isInvalid = this.inputText.includes(',') || this.inputText.split('\n').map(item => item.trim()).filter(item => item.length == 0).length;
        if (isInvalid) {
          this.invalidInput = true;
          this.lastQuery = '';
          this.processing = false;
          return false;
        }
        this.interestsList = this.inputText.split('\n');
      }
      return true;
    }
  }
}

</script>

<style scoped>
::v-deep .ivu-modal-header{
  border-bottom: none;
}
::v-deep .ivu-radio-wrapper {
  font-size: 13px;
}
::v-deep textarea.ivu-input{
  font-size: 13px;
}
.radiogroup{
  margin-left: 25px;
  padding-top: 3px;
}
.selected-target-data {
  margin: 6px 4px;
  padding: 3px 4px;
  display: block;
  width: 95%;
  color: grey;
}
.selected-target-box {
  border: 1px solid #dcdee2;
  display: flex;
}
.selected-target-icon {
  margin: 2px 4px;
  padding: 20px 10px;
  float: inline-end;
}
.preview-interests {
  max-height: 53vh;
  overflow-y: scroll;
}
.seperator{
  display: flex;
  margin-bottom: 10px;
}
.seperator-label{
  padding-top:7px;
  font-size: 13px;
}
.targeting-warn{
  margin-top: 10px;
}
</style>
