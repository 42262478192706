<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <Row>
          <Row-Col span="20">
            <h4>Negative Keyword List <i v-show="loader" class="fa fa-spinner fa-spin" /></h4>
          </Row-Col>
          <Row-Col span="4">
            <NegativeKeywordsCreation
            @newNegativeKeywordCreated="newNegativeKeywordCreated"/>
          </Row-Col>
        </Row>
      </div>
      <template>
        <div
          style="margin-top:10px"
          v-if="businessProfilesSearchEngineId==0"
          class="alert alert-info"
        >Please select a campaign configuration to see the negative keywords.
        </div>
        <div v-else>
          <Row>
            <Row-Col span="24">
              <Input v-model="search" search placeholder="Search" class="search-bar"/>
            </Row-Col>
          </Row>
          <div :class="notificationClass" v-if="showNotification" role="alert">
            {{ notificationMessage }}
            <button type="button" class="close" aria-label="Close" @click="showNotification = false">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            style="margin-top:10px"
            v-else-if="!loader && (!negativeKeywords || negativeKeywords.length<=0)"
            class="alert alert-info"
          >
            There are no negative keywords of this type.
          </div>
          <VuePerfectScrollbar class="widget-body">
            <Row class="cl-ivu-row" v-for="item in filteredNegativeKeywords" :key="item.id">
              <Row-Col span="1">
                <i class="fas fa-copy action-icon"
                  @click="copyToClipboard(item.id)">
                </i>
              </Row-Col>
              <Row-Col offset="1" span="21" class="cl-widget-text">{{ item.name }}</Row-Col>
            </Row>
          </VuePerfectScrollbar>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { APIService } from "./../../ApiService.js";
import { locale, Card, Row, Col, Input } from "iview";
import lang from "iview/dist/locale/en-US";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import NegativeKeywordsCreation from "./NegativeKeywordsCreation.vue";
import { mapMutations, mapState } from "vuex";
import mixins from "../../../../Mixins/copyToClipboard.js";

locale(lang);

export default {
  computed: {
    ...mapState(["businessProfilesSearchEngineId"]),
    filteredNegativeKeywords () {
      let self = this;
      let searchTerm = self.search;
      if (searchTerm != "") {
        return self.negativeKeywords.filter(a => {
          return a.name.toLowerCase().includes(searchTerm.toLowerCase());
        });
      } else {
        return self.negativeKeywords;
      }
    }
  },
  mixins: [mixins],
  components: {
    Card,
    "Row-Col": Col,
    Row,
    Input,
    NegativeKeywordsCreation,
    VuePerfectScrollbar
  },
  data () {
    return {
      loader: false,
      negativeKeywords: [],
      notificationClass: '',
      showNotification: false,
      search: ""
    }
  },
  methods: {
    ...mapMutations([
      "setNegativeKeywords"
    ]),
    newNegativeKeywordCreated (keyword) {
      var self = this;
      if (self.businessProfilesSearchEngineId == keyword.businessProfilesSearchEngineId) {
        self.negativeKeywords.unshift(keyword);
        self.$store.commit("setNegativeKeywords", self.negativeKeywords);
      }
      self.showNotification = true;
      self.notificationClass = "alert alert-success";
      self.notificationMessage = "Negative Keyword created successfully";
    },
    fetchNegativeKeywords () {
      let self = this;
      this.loader = true;
      APIService.getAdwordsNegativeKeywords(this.businessProfilesSearchEngineId)
        .then(response => {
          self.negativeKeywords = response.data.data
          self.$store.commit("setNegativeKeywords", self.negativeKeywords);
        })
        .catch(() => {
          self.showNotification = true;
          self.notificationClass = "alert alert-danger";
          self.notificationMessage = "There was a problem while fetching Negative Keywords";
        })
        .finally(() => {
          self.loader = false;
        });
    }
  },
  watch: {
    businessProfilesSearchEngineId: {
      immediate: true,
      handler: function (value) {
        if (value > 0) {
          this.fetchNegativeKeywords();
        }
      }
    }
  }
}
</script>
<style scoped>
.search-bar{
  margin: 0px 0px 10px 0px;
}
</style>
<style src="../../../../Styles/dx-iview.css"></style>
