import axiosInstance from '../../../../App/Api'

let ApiService = {
  getPortals() {
    const getPortals = deltax.getPortals;
    if (AccessToken != undefined && AccessToken.length > 0) {
      const promise = axiosInstance({
        headers: { "Authorization": `bearer ${AccessToken}` },
        method: 'GET',
        url: getPortals
      });
      return promise;
    }
    const promise = axiosInstance({
      method: 'GET',
      url: getPortals
    });
    return promise;
  },
  getModules() {
    const getModules = `${deltax.apiBaseUrl}modules`;
    if (AccessToken != undefined && AccessToken.length > 0) {
      const promise = axiosInstance({
        headers: { "Authorization": `bearer ${AccessToken}` },
        method: 'GET',
        url: getModules
      });
      return promise;
    }
    const promise = axiosInstance({
      method: 'GET',
      url: getModules
    });
    return promise;
  },
  getFeatures() {
    const getFeatures = `${deltax.apiBaseUrl}features`;
    if (AccessToken != undefined && AccessToken.length > 0) {
      const promise = axiosInstance({
        headers: { "Authorization": `bearer ${AccessToken}` },
        method: 'GET',
        url: getFeatures
      });
      return promise;
    }
    const promise = axiosInstance({
      method: 'GET',
      url: getFeatures
    });
    return promise;
  },
  getBusinessProfiles(portalId) {
    var getBusinessProfiles = `${deltax.apiBaseUrl}portals/${portalId}/businessprofiles`;
    if (AccessToken != undefined && AccessToken.length > 0) {
      const promise = axiosInstance({
        headers: { "Authorization": `bearer ${AccessToken}` },
        method: 'GET',
        url: getBusinessProfiles
      });
      return promise;
    }
    const promise = axiosInstance({
      method: 'GET',
      url: getBusinessProfiles
    });
    return promise;
  },
  getBzProfiles(){
    var getBusinessProfiles = `${deltax.apiBaseUrl}portals/${portalId}/businessprofiles`;
    const promise = axiosInstance({
      method: 'GET',
      url: getBusinessProfiles
    });
    return promise;
  },
  getFeatureLogs() {
    const getFeatureLogs = deltax.getFeatureLogs;
    if (AccessToken != undefined && AccessToken.length > 0) {
      const promise = axiosInstance({
        headers: { "Authorization": `bearer ${AccessToken}` },
        method: 'GET',
        url: getFeatureLogs
      });
      return promise;
    }
    const promise = axiosInstance({
      method: 'GET',
      url: getFeatureLogs
    });
    return promise;
  },

  fetchFeatureLogsInsights(portalId, businessProfileIds, startDate, endDate) {
    var paramsObj = {};
    if (portalId != 0) {
      paramsObj = {
        portalId,
        businessProfileIds: businessProfileIds.join(','),
        startDate: startDate,
        endDate: endDate
      }
    } else {
      paramsObj = {
        businessProfileIds: businessProfileIds.join(','),
        startDate: startDate,
        endDate: endDate
      }
    }
    if (AccessToken != undefined && AccessToken.length > 0) {
      const promise = axiosInstance({
        headers: { "Authorization": `bearer ${AccessToken}` },
        method: 'GET',
        url: deltax.getFeatureLogsInsights,
        contentType: "application/json",
        params: paramsObj
      });
      return promise;
    }
    const promise = axiosInstance({
      method: 'GET',
      url: deltax.getFeatureLogsInsights,
      contentType: "application/json",
      params: paramsObj
    });
    return promise;
  }
}

export { ApiService };
