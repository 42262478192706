function equals (array1, array2) {
  var flag = true
  if (array1.length != array2.length) { return false; }
  array1.forEach(element => {
    if (!array2.includes(element)) { flag = false }
  });
  return flag
}

export { equals }
