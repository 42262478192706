<template>
  <div>
     <Modal
      v-model="openAdsetSettingsModal"
      width="75%"
      :scrollable="true"
      :styles="{top: '20px'}"
      :mask-closable="false"
    >
        <h3 class="ccHeader" slot="header">
          AdGroup Settings
        </h3>

        <div style="position: absolute;z-index: 999;right: 17px;top: 12px;">
              <Select
                  v-model="currentSelectedAdSetId"
                  :transfer="true"
                  filterable
                  @on-change="adSetChanged"
                  placeholder="AdSet"
                >
                  <Option v-for="(item, index) in adgroups" :key="index" :value="item.RowData.InternalAdgroupName">{{ item.Name }}</Option>
              </Select>
            </div>

        <Alert
          v-if="alert.show"
          :type="alert.type"
          @on-close="alert.show = false"
          closable
          >
          <span>{{ alert.message }}</span>
        </Alert>

        <Form
              ref="updateAdSetForm"
              label-position="right"
              :label-width="200"
              :model="config"
              v-show="!isPromotionFlow"
              :rules="validationRules"
            >

            <Divider orientation="left">Campaign and Adgroups</Divider>
            <Form-Item label="Date Range">
              <DatePicker
                type="daterange"
                format="MMM d, yyyy"
                :clearable="false"
                :split-panels="true"
                :editable="false"
                class="date-picker-width"
                :disabled="disableForm || isAdScheduled"
                v-model="adsetSchedule"
                :options="adsetDateOptions"
              ></DatePicker>
            </Form-Item>
            <Form-Item label="Placements" prop="adset.selectedPlacementOption">
                <Row>
                <Row-Col>
                    <RadioGroup v-model="config.adset.selectedPlacementOption">
                    <Radio label="Automatic" :disabled = disableForm></Radio>
                    <Radio label="Manual" :disabled = disableForm></Radio>
                    </RadioGroup>
                </Row-Col>
                <Row-Col v-if="config.adset.selectedPlacementOption == 'Manual'">
                    <Tree
                        :data="config.adset.placements"
                        show-checkbox multiple check-directly
                        >
                    </Tree>
                </Row-Col>
                </Row>
            </Form-Item>

            <Form-Item label="Devices" prop="adset.devices" key="adset.devices" v-show="config.adset.selectedPlacementOption == 'Manual'">
                <Row>
                <Row-Col span="9">
                    <CheckboxGroup v-model="config.adset.devices">
                    <Checkbox :disabled="isDesktopDisabled || disableForm" label="Desktop"></Checkbox>
                    <Checkbox :disabled="isMobileDisabled || disableForm" label="Mobile"></Checkbox>
                    </CheckboxGroup>
                </Row-Col>
                </Row>
            </Form-Item>

            <div v-show="config.adset.devices.includes('Mobile') && config.adset.selectedPlacementOption == 'Manual'">
              <Form-Item label="Mobile Devices" prop="adset.mobileDevices" key="adset.mobileDevices" v-show="config.adset.devices.includes('Mobile') && config.adset.selectedPlacementOption == 'Manual'">
                  <Row>
                  <Row-Col span="9">
                      <Select
                      placeholder="Mobile Devices"
                      v-model="config.adset.mobileDevices"
                      not-found-text="No results"
                      :disabled="!config.bpseId || disableForm"
                      @on-change="fetchMobileDevices"
                      >
                      <Option
                          v-for="(item, index) in mobileDevices"
                          :key="index"
                          :value="item"
                      >{{ item }}</Option>
                      </Select>
                  </Row-Col>
                  </Row>
              </Form-Item>
              <Form-Item label="Included devices" prop="adset.targeting.userDevice" key="adset.targeting.userDevice" v-show="config.adset.mobileDevices == 'Android devices only' || config.adset.mobileDevices === 'iOS devices only'">
                  <Row>
                  <Row-Col span="9">
                      <Select
                      multiple
                      placeholder="Enter a device name"
                      v-model="config.adset.targeting.userDevice"
                      not-found-text="No results"
                      filterable
                      :key="includedDeviceList.length"
                      :disabled="disableForm"
                      >
                      <Option
                          v-for="(item, index) in includedDeviceList"
                          :key="index"
                          :value="item.name"
                      >{{ item.description }}</Option>
                      </Select>
                  </Row-Col>
                  </Row>
              </Form-Item>
              <Form-Item label="Excluded devices" prop="adset.targeting.excludedUserDevice" key="adset.targeting.excludedUserDevice" v-show="config.adset.mobileDevices == 'Android devices only' || config.adset.mobileDevices === 'iOS devices only'">
                  <Row>
                  <Row-Col span="9">
                      <Select
                      multiple
                      placeholder="Enter a device name"
                      v-model="config.adset.targeting.excludedUserDevice"
                      not-found-text="No results"
                      filterable
                      :disabled="disableForm"
                      >
                      <Option
                          v-for="(item, index) in excludedDeviceList"
                          :key="index"
                          :value="item.name"
                      >{{ item.description }}</Option>
                      </Select>
                  </Row-Col>
                  </Row>
              </Form-Item>
              <Form-Item label="OS Version (min)" prop="adset.targeting.minOsVersion" key="adset.targeting.minOsVersion" v-show="config.adset.mobileDevices == 'Android devices only' || config.adset.mobileDevices === 'iOS devices only'">
                  <Row-Col span="9">
                      <Select
                      placeholder="Min"
                      v-model="config.adset.targeting.minOsVersion"
                      not-found-text="No results"
                      filterable
                      :disabled="disableForm"
                      >
                      <Option
                          v-for="(item, index) in minOSList"
                          :key="index"
                          :value="item.name"
                      >{{ item.description }}</Option>
                      </Select>
                  </Row-Col>
              </Form-Item>
              <Form-Item label="OS Version (max)" prop="adset.targeting.maxOsVersion" key="adset.targeting.maxOsVersion" v-show="config.adset.mobileDevices == 'Android devices only' || config.adset.mobileDevices === 'iOS devices only'">
                  <Row>
                  <Row-Col span="9">
                      <Select
                      placeholder="Max(Optional)"
                      v-model="config.adset.targeting.maxOsVersion"
                      not-found-text="No results"
                      filterable
                      :disabled="disableForm"
                      >
                      <Option
                          v-for="(item, index) in maxOSList"
                          :key="index"
                          :value="item.name"
                      >{{ item.description }}</Option>
                      </Select>
                  </Row-Col>
                  </Row>
              </Form-Item>
            </div>

            <Divider orientation="left">Budget Settings</Divider>
            <Form-Item label="Optimize For" prop="adset.optimizeFor">
                <Row>
                <Row-Col span="18">
                    <Select
                        placeholder="Optimization Goal"
                        v-model="config.adset.optimizeFor"
                        not-found-text="No results"
                        :disabled="config.campaign.objective === '' || disableForm"
                        >
                        <Option
                            v-for="(item, index) in optimizationGoals"
                            :key="index"
                            :value="item.id"
                        >{{ item.name }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>

            <Form-Item label="When you get charged" prop="adset.billingEvent">
                <Row>
                <Row-Col span="18">
                    <Select
                        placeholder="Billing Event"
                        not-found-text="No results"
                        v-model="config.adset.billingEvent"
                        :disabled="config.campaign.objective === '' || disableForm"
                        >
                        <Option
                            v-for="(item, index) in billingEvents"
                            :key="index"
                            :value="item.id"
                        >{{ item.name }}</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>

            <Divider v-show="!isPromotionFlow || disableForm" orientation="left">Attribution Settings</Divider>
            <Form-Item label="Click Through Window" prop="adset.clickAttributionWindow" v-show="!isPromotionFlow || disableForm">
                <Row>
                <Row-Col span="18">
                    <Select
                        placeholder="Click Through"
                        clearable
                        v-model="config.adset.clickAttributionWindow"
                        not-found-text="No results"
                        :disabled="isPromotionFlow || !clickWindows || clickWindows.length == 0 || disableForm"
                        >
                        <Option
                            v-for="item in clickWindows"
                            :value="item"
                            :key="item"
                        >{{ item }} Day(s)</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>
            <Form-Item label="View Through Window" prop="adset.viewAttributionWindow" v-show="!isPromotionFlow || disableForm">
                <Row>
                <Row-Col span="18">
                    <Select
                        placeholder="View Through"
                        clearable
                        v-model="config.adset.viewAttributionWindow"
                        not-found-text="No results"
                        :disabled="isPromotionFlow || !viewWindows || viewWindows.length == 0 || disableForm"
                        >
                        <Option
                            v-for="item in viewWindows"
                            :value="item"
                            :key="item"
                        >{{ item }} Day</Option>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>

            <Divider orientation="left" v-show="isConversionsObjective(config)">Tracking Settings</Divider>
            <Form-Item label="Custom Event Type" v-show="isConversionsObjective(config)" prop="adset.customEventType">
                <Row>
                <Row-Col span="18">
                    <Select
                        placeholder
                        v-model="config.adset.customEventType"
                        not-found-text="No results"
                        clearable
                        filterable
                        :disabled="disableForm"
                        >
                        <OptionGroup label="Standard">
                            <Option
                                v-for="(item, index) in standardEventTypes"
                                :value="item"
                                :key="index"
                            >{{ item }}</Option>
                        </OptionGroup>
                        <OptionGroup label="Custom" v-if="customEventTypes.length > 0">
                            <Option
                            v-for="item in customEventTypes"
                            :value="item.id"
                            :key="item.id"
                            :disabled="item.is_archived || item.is_unavailable"
                            >{{ item.name }} ({{ item.id }})</Option>
                        </OptionGroup>
                    </Select>
                </Row-Col>
                </Row>
            </Form-Item>

        </Form>
        <div slot="footer">
        <input type="button" class="btn btn-white mediaplan-campaign-popup-save-padding" v-on:click="close()" value="Close" />
        <input
          type="button"
          class="btn btn-success mediaplan-campaign-popup-save-padding"
          v-on:click="handleSubmit()"
          value="Save"
        />
      </div>
     </Modal>
  </div>
</template>

<script>
import { Modal, Form, FormItem, Row, Col, RadioGroup, Radio, Tree, Select, Option, OptionGroup, Divider, Alert, DatePicker, CheckboxGroup, Checkbox } from 'iview';
import { mapState, mapMutations } from "vuex";
import { APIService } from "../../ApiService.js";
import FacebookCampaignLauncherConfig from "../../Mixins/FacebookCampaignLauncherConfig.js"
import * as fbEnums from "../../Constants/FacebookEnums.js";
import {
  getRnfAdformatsByObjective,
  getRnfPlacements
} from "../../columnConfig";
export default {
  components: {
    Modal,
    Row,
    "Row-Col": Col,
    RadioGroup,
    Radio,
    Form,
    FormItem,
    Select,
    Option,
    OptionGroup,
    Tree,
    Divider,
    Alert,
    DatePicker,
    CheckboxGroup,
    Checkbox
  },
  mixins: [FacebookCampaignLauncherConfig],
  props: {
    openAdsetSettingsModal: { default: false },
    currentAdSet: Object,
    adgroups: { default: [] },
    defaultFormConfig: Object,
    isApprovalMode: { default: false }
  },
  created () {
    this.selectedAdSet = this.currentAdSet
    this.currentSelectedAdSetId = this.currentAdSet.RowData.InternalAdgroupName
    this.adsetSchedule = this.getAdsetSchedule
    this.disabledPlacements()
    this.getCustomConversions()
    this.getAttributionWindows()
  },
  mounted () {
    this.teardownWatcherForConfig()
    if (this.config.adset.selectedPlacementOption == 'Manual') {
      this.getMobileDeviceListFromAPI(true);
    }
    this.$nextTick(() => {
      this.prefillFormWithEditedSummaryData()
    })
  },
  data () {
    var that = this;
    const placementsValidator = (rule, value, callback) => {
      if (value == "Manual") {
        let placements = [...this.config.adset.placements.filter(element => {
          return element.checked || element.indeterminate
        })];
        if (placements.length == 0) { callback(new Error("Please select atleast one placement")) }
        callback();
      } else {
        callback();
      }
    }
    const customEventTypeValidator = (rule, value, callback) => {
      if (this.isConversionsObjective(this.config)) {
        if (!value) { callback(new Error("Custom Event Type is required")) }
      }
      callback();
    }
    const deviceValidator = (rule, value, callback) => {
      if (this.isDeviceValidationRequired && value.length <= 0) {
        callback(new Error("Select atleast one device"));
      }
      callback()
    }
    return {
      preConfig: {},
      validationRules: {
        "adset.optimizeFor": [
          {
            type: "number",
            required: true,
            message: "Choose your optimization goal",
            trigger: "change"
          }
        ],
        "adset.selectedPlacementOption": [
          {
            validator: placementsValidator, trigger: "blur"
          }
        ],
        "adset.customEventType": [
          {
            validator: customEventTypeValidator,
            trigger: "change",
            required: true
          }
        ],
        "adset.devices": [
          {
            validator: deviceValidator,
            trigger: "change"
          }
        ],
        "adset.billingEvent": [
          {
            type: "number",
            required: true,
            message: "Choose when you get charged",
            trigger: "change"
          }
        ],
        "adset.mobileDevices": [
          {
            required: true,
            message: "Select at least one device type",
            trigger: "change"
          }
        ]
      },
      alert: {
        message: "Message",
        show: false,
        type: "success"
      },
      selectedAdSet: {},
      currentSelectedAdSetId: '',
      updatedProperties: [],
      finalChangedData: {},
      config: JSON.parse(JSON.stringify(this.defaultFormConfig)),
      unwatch: {
        selectedPlacementOption: function () {},
        placements: function () {},
        optimizeFor: function () {},
        customEventType: function () {},
        clickAttributionWindow: function () {},
        viewAttributionWindow: function () {},
        billingEvent: function () {},
        adsetSchedule: function () {},
        devices: function () {},
        mobileDevices: function () {},
        userDevice: function () {},
        excludedUserDevice: function () {},
        minOsVersion: function () {},
        maxOsVersion: function () {}
      },
      clickWindows: [],
      viewWindows: [],
      standardEventTypes: fbEnums.standardEventTypesEnum,
      customEventTypes: [],
      adsetSchedule: [],
      isDesktopDisabled: false,
      isMobileDisabled: false,
      isDeviceValidationRequired: true,
      mobileDevices: [
        "All Mobile devices",
        "Feature phones only",
        "Android devices only",
        "iOS devices only"
      ],
      deviceList: [],
      includedDeviceList: [],
      excludedDeviceList: [],
      mobileOsVersions: [],
      iOSDevices: [],
      minOSList: [],
      maxOSList: [],
      androidDevices: [],
      androidOsVersions: [
        { name: "2.0", description: "2.0 Donut" },
        { name: "2.1", description: "2.1 Eclair" },
        { name: "2.2", description: "2.2 Froyo" },
        { name: "2.3", description: "2.3 Gingerbread" },
        { name: "3.0", description: "3.0 Honeycomb" },
        { name: "3.1", description: "3.1 Honeycomb" },
        { name: "3.2", description: "3.2 Honeycomb" },
        { name: "4.0", description: "4.0 Ice Cream Sandwich" },
        { name: "4.1", description: "4.1 Jelly Bean" },
        { name: "4.2", description: "4.2 Jelly Bean" },
        { name: "4.3", description: "4.3 Jelly Bean" },
        { name: "4.4", description: "4.4 KitKat" },
        { name: "5.0", description: "5.0 Lollipop" },
        { name: "5.1", description: "5.1 Lollipop" },
        { name: "6.0", description: "6.0 Marshmallow" },
        { name: "7.0", description: "7.0 Nougat" },
        { name: "7.1", description: "7.1 Nougat" },
        { name: "8.0", description: "8.0 Oreo" },
        { name: "8.1", description: "8.1 Oreo" },
        { name: "9.0", description: "9.0 Pie" }
      ],
      iOSVersions: [
        { name: "2.0", description: "2.0" },
        { name: "3.0", description: "3.0" },
        { name: "4.0", description: "4.0" },
        { name: "4.3", description: "4.3" },
        { name: "5.0", description: "5.0" },
        { name: "6.0", description: "6.0" },
        { name: "7.0", description: "7.0" },
        { name: "7.1", description: "7.1" },
        { name: "8.0", description: "8.0" },
        { name: "8.1", description: "8.1" },
        { name: "8.2", description: "8.2" },
        { name: "8.3", description: "8.3" },
        { name: "8.4", description: "8.4" },
        { name: "9.0", description: "9.0" },
        { name: "9.1", description: "9.1" },
        { name: "9.2", description: "9.1" },
        { name: "9.3", description: "9.1" },
        { name: "10.0", description: "10.0" },
        { name: "10.1", description: "10.1" },
        { name: "10.2", description: "10.2" },
        { name: "10.3", description: "10.3" },
        { name: "11.0", description: "11.0" },
        { name: "11.1", description: "11.1" },
        { name: "11.2", description: "11.2" },
        { name: "11.3", description: "11.3" },
        { name: "12.0", description: "12.0" },
        { name: "12.1", description: "12.1" },
        { name: "12.2", description: "12.2" },
        { name: "12.3", description: "12.3" },
        { name: "12.4", description: "12.4" },
        { name: "13.0", description: "13.0" },
        { name: "13.3", description: "13.3" }
      ],
      adsetDateOptions: {
        disabledDate (date) {
          let startDate = Date.now() > new Date(that.getAdsetSchedule[0]) ? Date.now() : new Date(that.getAdsetSchedule[0])
          if (that.getAdsetSchedule && that.getAdsetSchedule.length && that.getAdsetSchedule[1]) {
            return date && (date.valueOf() < startDate - 86400000 || date.valueOf() > new Date(that.getAdsetSchedule[1]).getTime());
          } else {
            return date && (date.valueOf() < startDate - 86400000);
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      "isPromotionFlow",
      "selectedCampaignLauncherConfig",
      "editedSummaryData",
      "mappedLineItems",
      "businessProfileId",
      "publishedEditedSummaryData"
    ]),
    ...mapMutations([
      "setEditedSummaryData"
    ]),
    optimizationGoals () {
      let goals = []
      let optimizationGoals = this.getOptimizationGoalsByObjective(this.config)
      if (optimizationGoals) {
        if (this.selectedAdSet.isRnFConfig) {
          goals = optimizationGoals["reachAndFrequency"]
            .map(x => {
              return { id: x, name: fbEnums.optimizationGoalsEnum[x] }
            })
        } else {
          goals = optimizationGoals["auction"]
            .map(x => {
              return { id: x, name: fbEnums.optimizationGoalsEnum[x] }
            })
          if (this.config.adset.destination == fbEnums.destinationType.Messenger) {
            goals = goals.filter(x => x.id != 20)
          }
          if (this.isLeadGenerationObjective(this.config)) {
            if (this.config.adset.destination == fbEnums.destinationType.PhoneCall) {
              // Optimization Goal as Calls for DestinationType PhoneCall
              goals = goals.filter(x => x.id == 30)
            } else {
              // Optimization Goal as LeadGeneration for other DestinationTypes
              goals = goals.filter(x => x.id == 17)
            }
          }
        }
      }
      if (this.config.campaign.bidStrategy === fbEnums.bidStrategy['Lowest Cost with Cost Cap']) {
        let unsupportedGoals = [7, 14, 22, 29];
        // Impressions, Reach, Value, App Events

        goals = goals.filter(x => !unsupportedGoals.includes(x.id));
      }
      if (goals.length == 1) {
        this.config.adset.optimizeFor = goals[0].id
      }

      if (this.config.campaign.isAutomatedAppAdsEnabled) {
        let unsupportedGoals = [];
        switch (this.config.campaign.bidStrategy) {
          case fbEnums.bidStrategy["Lowest Cost with Bid Cap"]:
            unsupportedGoals = [31, 22];
            break;

          case fbEnums.bidStrategy["Lowest Cost with Min Roas"]:
            unsupportedGoals = [31, 26, 2];
            break;
        }

        goals = goals.filter(x => !unsupportedGoals.includes(x.id));
      }
      return goals
    },
    billingEvents () {
      let billingEvents = []
      var billingEventsByObjective = this.getBillingEventsByObjective(this.config)
      if (billingEventsByObjective) {
        if (this.isRnFConfig) {
          billingEvents = billingEventsByObjective["reachAndFrequency"]
            .map(x => {
              return { id: x, name: fbEnums.billingEventsEnum[x] }
            })
        } else {
          billingEvents = billingEventsByObjective["auction"]
            .map(x => {
              return { id: x, name: fbEnums.billingEventsEnum[x] }
            })
        }
      }
      if (billingEvents.length == 1) {
        this.config.adset.billingEvent = billingEvents[0].id
      }
      return billingEvents
    },
    disableForm () {
      return (this.selectedAdSet.Id && this.selectedAdSet.Id != '') || this.isApprovalMode
    },
    getAdsetSchedule: {
      get: function () {
        var lineItem = this.mappedLineItems.find(lineItem => lineItem.id == this.selectedAdSet.RowData.LineItemId);
        var startDate = this.selectedAdSet.RowData.AdgroupStartDate ? new Date(this.selectedAdSet.RowData.AdgroupStartDate) : new Date(lineItem.startDate);
        var endDate = this.selectedAdSet.RowData.AdgroupEndDate ? new Date(this.selectedAdSet.RowData.AdgroupEndDate) : new Date(lineItem.endDate);
        if (this.selectedCampaignLauncherConfig.isAdsetBudget && this.currentAdSet.RowData.BudgetType == "daily_budget") {
          startDate = new Date(lineItem.startDate);
          endDate = new Date(lineItem.endDate)
        }
        return [startDate, endDate]
      }
    },
    isAdScheduled () {
      var isAdSchedule = false;
      if (this.config.adset.grouping.includes("Ad Schedule")) {
        var creativeDateArray = this.selectedAdSet.Name.split('(');
        if (creativeDateArray[1] != null) {
          let creativeDate = creativeDateArray[1].slice(0, -1)
          var validDateFormats = [
            "MM-DD-YYYY-MM-DD-YYYY",
            "MM-DD-YYYY h:mm A-MM-DD-YYYY h:mm A",
            "MM-DD-YYYY h:mm A-MM-DD-YYYY",
            "MM-DD-YYYY-MM-DD-YYYY h:mm A",
            "MM/DD/YYYY-MM/DD/YYYY",
            "MM/DD/YYYY h:mm A-MM/DD/YYYY h:mm A",
            "MM/DD/YYYY h:mm A-MM/DD/YYYY",
            "MM/DD/YYYY-MM/DD/YYYY h:mm A"
          ];
          isAdSchedule = moment(creativeDate, validDateFormats, true).isValid();
        }
      }
      return isAdSchedule
    }
  },
  methods: {
    selectedPlacementOptionWatch (value) {
      // reset min and max values on change of option
      if (this.config.campaign.buyType == 4) {
        this.processPlacementsForRnf();
      }
      if (value == 'Manual' && (!this.androidDevices.length || !this.iOSDevices.length)) {
        this.getMobileDeviceListFromAPI(true);
      }
      this.addUpdatedProperties("selectedPlacementOption", value)
    },
    placementsWatch (value) {
      this.addUpdatedProperties("placements", value)
    },
    optimizeForWatch (optimizationGoal) {
      if (this.isLeadGenerationObjective(this.config)) {
        // Billing Event as Impression when OptimzationGoal is LeadGeneration or Calls
        if (optimizationGoal == 17 || optimizationGoal == 30) {
          this.config.adset.billingEvent = 3
        } else {
          this.config.adset.billingEvent = 4
        }
      } else if (this.isDPAObjective(this.config)) {
        if (optimizationGoal != 8) {
          this.config.adset.billingEvent = 3;
        }
      }
      if (this.config.adset.clickAttributionWindow) {
        if (!this.clickWindows.includes(this.config.adset.clickAttributionWindow)) {
          this.config.adset.clickAttributionWindow = null
        }
      }
      if (this.config.adset.viewAttributionWindow) {
        if (!this.viewWindows.includes(this.config.adset.viewAttributionWindow)) {
          this.config.adset.viewAttributionWindow = null
        }
      }
      this.addUpdatedProperties("optimizeFor", optimizationGoal)
    },
    customEventTypeWatch (value) {
      this.addUpdatedProperties("customEventType", value)
    },
    clickAttributionWindowWatch (value) {
      this.addUpdatedProperties("clickAttributionWindow", value)
      this.addUpdatedProperties("viewAttributionWindow", this.config.adset.viewAttributionWindow)
    },
    viewAttributionWindowWatch (value) {
      this.addUpdatedProperties("clickAttributionWindow", this.config.adset.clickAttributionWindow)
      this.addUpdatedProperties("viewAttributionWindow", value)
    },
    billingEventWatch (value) {
      this.addUpdatedProperties("billingEvent", value)
    },
    adsetScheduleWatch (value) {
      this.addUpdatedProperties("adsetSchedule", value)
    },
    devicesWatch (value) {
      this.addUpdatedProperties("devices", value)
    },
    mobileDevicesWatch (value) {
      this.addUpdatedProperties("mobileDevices", value)
    },
    userDeviceWatch (newVal, oldVal) {
      if ((newVal.length != oldVal.length) || !this.areArraysEqual(newVal, oldVal)) {
        var self = this;
        var result = [];
        if (newVal.length - oldVal.length == 1) {
          var listOfDevices = self.deviceList.filter(function (obj) {
            return newVal.some(function (obj2) {
              return obj.name == obj2;
            });
          });
          let userSelection = newVal.filter(x => !oldVal.includes(x))
          var input = listOfDevices.filter(x => x.name == userSelection)[0];
          if (oldVal.length > 0 && input) {
            if (self.config.adset.targeting.isAndroidOs) {
              result = self.mobileDeviceParentCheckAndorid(listOfDevices, input)
            } else {
              result = self.mobileDeviceParentCheckIOS(listOfDevices, input)
            }
            self.config.adset.targeting.userDevice = result.map(m => m.name);
          }
        }
        self.excludedDeviceList = self.deviceList.filter(function (obj) {
          return !self.config.adset.targeting.userDevice.some(function (obj2) {
            return obj.name == obj2;
          });
        });
        this.addUpdatedProperties("userDevice", newVal)
      }
    },
    excludedUserDeviceWatch (newVal, oldVal) {
      if ((newVal.length != oldVal.length) || !this.areArraysEqual(newVal, oldVal)) {
        var self = this;
        var result = [];
        if (newVal.length - oldVal.length == 1) {
          var listOfDevices = self.deviceList.filter(function (obj) {
            return newVal.some(function (obj2) {
              return obj.name == obj2;
            });
          });
          let userSelection = newVal.filter(x => !oldVal.includes(x))
          var input = listOfDevices.filter(x => x.name == userSelection)[0];
          if (oldVal.length > 0 && input) {
            if (self.config.adset.targeting.isAndroidOs) {
              result = self.mobileDeviceParentCheckAndorid(listOfDevices, input)
            } else {
              result = self.mobileDeviceParentCheckIOS(listOfDevices, input)
            }
            self.config.adset.targeting.excludedUserDevice = result.map(m => m.name);
          }
        }
        self.includedDeviceList = self.deviceList.filter(function (obj) {
          return !self.config.adset.targeting.excludedUserDevice.some(function (obj2) {
            return obj.name == obj2;
          });
        });
        this.addUpdatedProperties("excludedUserDevice", newVal)
      }
    },
    minOsVersionWatch (value) {
      var osString = this.getOSVersionString()
      if (osString) this.config.adset.targeting.userOs = [osString]
      this.maxOSList = this.mobileOsVersions.filter(x => parseFloat(x.name) >= parseFloat(this.config.adset.targeting.minOsVersion));
      this.addUpdatedProperties("minOsVersion", value)
      this.addUpdatedProperties("userOs", this.config.adset.targeting.userOs)
    },
    maxOsVersionWatch (value) {
      var osString = this.getOSVersionString()
      if (osString) this.config.adset.targeting.userOs = [osString]
      if (this.config.adset.targeting.maxOsVersion) {
        this.minOSList = this.mobileOsVersions.filter(x => parseFloat(x.name) <= parseFloat(this.config.adset.targeting.maxOsVersion));
      }
      this.addUpdatedProperties("maxOsVersion", value)
      this.addUpdatedProperties("userOs", this.config.adset.targeting.userOs)
    },
    setupWatcherForConfig () {
      this.unwatch.selectedPlacementOption = this.$watch("config.adset.selectedPlacementOption", this.selectedPlacementOptionWatch);
      this.unwatch.placements = this.$watch("config.adset.placements", this.placementsWatch, { deep: true });
      this.unwatch.optimizeFor = this.$watch("config.adset.optimizeFor", this.optimizeForWatch);
      this.unwatch.customEventType = this.$watch("config.adset.customEventType", this.customEventTypeWatch);
      this.unwatch.clickAttributionWindow = this.$watch("config.adset.clickAttributionWindow", this.clickAttributionWindowWatch);
      this.unwatch.viewAttributionWindow = this.$watch("config.adset.viewAttributionWindow", this.viewAttributionWindowWatch);
      this.unwatch.billingEvent = this.$watch("config.adset.billingEvent", this.billingEventWatch);
      this.unwatch.adsetSchedule = this.$watch("adsetSchedule", this.adsetScheduleWatch);
      this.unwatch.devices = this.$watch("config.adset.devices", this.devicesWatch);
      this.unwatch.mobileDevices = this.$watch("config.adset.mobileDevices", this.mobileDevicesWatch);
      this.unwatch.userDevice = this.$watch("config.adset.targeting.userDevice", this.userDeviceWatch);
      this.unwatch.excludedUserDevice = this.$watch("config.adset.targeting.excludedUserDevice", this.excludedUserDeviceWatch);
      this.unwatch.minOsVersion = this.$watch("config.adset.targeting.minOsVersion", this.minOsVersionWatch);
      this.unwatch.maxOsVersion = this.$watch("config.adset.targeting.maxOsVersion", this.maxOsVersionWatch);
    },
    teardownWatcherForConfig () {
      this.unwatch.selectedPlacementOption();
      this.unwatch.placements();
      this.unwatch.optimizeFor();
      this.unwatch.customEventType();
      this.unwatch.clickAttributionWindow();
      this.unwatch.viewAttributionWindow();
      this.unwatch.billingEvent();
      this.unwatch.adsetSchedule();
      this.unwatch.devices();
      this.unwatch.mobileDevices();
      this.unwatch.userDevice();
      this.unwatch.excludedUserDevice();
      this.unwatch.minOsVersion();
      this.unwatch.maxOsVersion();
    },
    processPlacementsForRnf () {
      if (this.config.adset.selectedPlacementOption == "Manual") {
        var validPlacementsConfig = getRnfPlacements(this.config);
        var placements = this.getValidAdsetPlacements(validPlacementsConfig);
        this.config.adset.placements = placements;
      }
    },
    close () {
      this.resetAllFields()
      this.$emit("closeAdsetUpdateModal", false)
    },
    handleSubmit () {
      if (this.updatedProperties && this.updatedProperties.length) {
        this.finalChangedData[this.selectedAdSet.RowData.InternalCampaignName] = this.updatedProperties
      }
      if (this.finalChangedData && Object.keys(this.finalChangedData).length != 0) {
        var updatedData = ''
        if (!this.editedSummaryData || this.editedSummaryData == '' || this.editedSummaryData == '{}') {
          updatedData = JSON.stringify(this.finalChangedData)
        } else {
          var editedSummaryDataObject = JSON.parse(this.editedSummaryData)
          Object.keys(this.finalChangedData).forEach(campaign => {
            if (editedSummaryDataObject.hasOwnProperty(campaign)) {
              this.finalChangedData[campaign].forEach((adset, index) => {
                var adsetIndex = editedSummaryDataObject[campaign].findIndex(ele => ele.internalId == adset.internalId)
                if (adsetIndex != -1 && editedSummaryDataObject[campaign][adsetIndex].internalId == adset.internalId) {
                  Object.keys(adset).forEach(property => {
                    editedSummaryDataObject[campaign][adsetIndex][property] = this.finalChangedData[campaign][index][property]
                  })
                } else {
                  editedSummaryDataObject[campaign].push(adset)
                }
              })
            } else {
              editedSummaryDataObject[campaign] = this.finalChangedData[campaign]
            }
          })
          updatedData = JSON.stringify(editedSummaryDataObject)
        }
        this.$store.commit("setEditedSummaryData", updatedData)
      }
      this.$emit("closeAdsetUpdateModal")
      this.$emit("adsetUpdatedSuccessfully", this.updatedProperties)
    },
    adSetChanged () {
      this.teardownWatcherForConfig()
      this.selectedAdSet = this.adgroups.find(x => x.RowData.InternalAdgroupName == this.currentSelectedAdSetId)
      this.adsetSchedule = this.getAdsetSchedule
      this.config = JSON.parse(JSON.stringify(this.defaultFormConfig))
      this.disabledPlacements()
      this.prefillFormWithEditedSummaryData()
    },
    addUpdatedProperties (propertyName, value) {
      var editedEntityObject = {
        "internalId": this.currentSelectedAdSetId,
        "entityType": this.selectedAdSet.EntityType
      }
      if (propertyName == "adsetSchedule") {
        value = value.map(date => {
          return this.formatDate(date)
        })
        if (this.selectedCampaignLauncherConfig.isAdsetBudget && this.currentAdSet.RowData.BudgetType == "daily_budget") {
          this.currentAdSet.RowData.AdgroupStartDate = new Date(value[0])
          this.currentAdSet.RowData.AdgroupEndDate = new Date(value[1])
          this.$emit("calculateAdsetBudget", this.currentSelectedAdSetId)
        }
      }
      editedEntityObject[propertyName] = value
      if (propertyName == 'selectedPlacementOption' && value == 'Manual') {
        editedEntityObject["placements"] = this.config.adset.placements
      }
      if (this.updatedProperties && this.updatedProperties.length) {
        var previousEditedValueIndex = this.updatedProperties.findIndex(ele => ele.internalId == editedEntityObject.internalId)
        if (previousEditedValueIndex != -1) {
          this.updatedProperties[previousEditedValueIndex][propertyName] = value
          if (editedEntityObject.placements) {
            this.updatedProperties[previousEditedValueIndex]['placements'] = editedEntityObject.placements
          }
        } else {
          this.updatedProperties.push(editedEntityObject)
        }
      } else {
        this.updatedProperties.push(editedEntityObject)
      }
      this.finalChangedData[this.selectedAdSet.RowData.InternalCampaignName] = this.updatedProperties
    },
    resetAllFields () {
      this.$refs["updateAdSetForm"].resetFields();
      this.teardownWatcherForConfig();
    },
    prefillFormWithEditedSummaryData () {
      if (this.publishedEditedSummaryData) {
        var parsedPublishedEditedData = JSON.parse(this.publishedEditedSummaryData)
        if (parsedPublishedEditedData && parsedPublishedEditedData.hasOwnProperty(this.selectedAdSet.RowData.InternalCampaignName)) {
          parsedPublishedEditedData[this.selectedAdSet.RowData.InternalCampaignName].forEach((editedData, index) => {
            var that = this
            if (editedData["internalId"] == that.currentSelectedAdSetId) {
              that.setEditedData(editedData, parsedPublishedEditedData)
              if (that.selectedAdSet.Status == 'Failed') {
                if (!that.editedSummaryData) {
                  let parsedPublishedEditedDataCopy = JSON.parse(JSON.stringify(parsedPublishedEditedData))
                  parsedPublishedEditedDataCopy[this.selectedAdSet.RowData.InternalCampaignName] = [parsedPublishedEditedDataCopy[this.selectedAdSet.RowData.InternalCampaignName][index]]
                  that.$store.commit("setEditedSummaryData", JSON.stringify(parsedPublishedEditedDataCopy))
                }
              }
            }
          })
        }
      } if (this.editedSummaryData) {
        var parsedEditedData = JSON.parse(this.editedSummaryData)
        if (parsedEditedData && parsedEditedData.hasOwnProperty(this.selectedAdSet.RowData.InternalCampaignName)) {
          parsedEditedData[this.selectedAdSet.RowData.InternalCampaignName].forEach(editedData => {
            var that = this
            if (editedData["internalId"] == that.currentSelectedAdSetId) {
              that.setEditedData(editedData, parsedEditedData)
            }
          })
        }
      } if (this.finalChangedData && Object.keys(this.finalChangedData).length != 0) {
        if (this.finalChangedData && this.finalChangedData.hasOwnProperty(this.selectedAdSet.RowData.InternalCampaignName)) {
          this.finalChangedData[this.selectedAdSet.RowData.InternalCampaignName].forEach(editedData => {
            var that = this
            if (editedData["internalId"] == that.currentSelectedAdSetId) {
              Object.keys(editedData).forEach(property => {
                if (that.config.adset.hasOwnProperty(property)) {
                  if (property == "mobileDevices") {
                    that.config.adset.targeting.isAndroidOs = editedData[property] == "Android devices only";
                    if (that.config.adset.targeting.isAndroidOs && that.androidDevices.length) {
                      that.setOSLevelData(that.androidDevices, that.androidOsVersions, "Android")
                    } else if (!that.config.adset.targeting.isAndroidOs && that.iOSDevices.length) {
                      that.setOSLevelData(that.iOSDevices, that.iOSVersions, "iOS")
                    }
                  }
                  that.config.adset[property] = editedData[property]
                } else if (that.config.adset.targeting.hasOwnProperty(property)) {
                  that.config.adset.targeting[property] = editedData[property]
                }
                if (property == 'adsetSchedule') {
                  that.adsetSchedule = editedData[property].map(date => new Date(date))
                }
              })
            }
          })
        }
      }
      this.disabledPlacements();
      this.$nextTick(() => {
        this.setupWatcherForConfig();
      })
    },
    disabledPlacements () {
      if (this.disableForm) {
        this.config.adset.placements.forEach(placement => {
          placement.disabled = true
          placement.children.forEach(child => {
            child["disabled"] = true
          })
        })
      }
    },
    getCustomConversions () {
      var requests = []
      var self = this
      requests.push(APIService.getCustomConversions(self.config.bpseId))
      requests.push(APIService.getCustomEvents(self.config.bpseId))
      Promise.all(requests)
        .then(responses => {
          self.customEventTypes = responses[0].data.data;
          responses[1].data.data.forEach(r => {
            r["custom_event_type"] = "OTHER"
            r["customEventStr"] = r.name
            r["pixel"] = {}
          })
          self.customEventTypes = self.customEventTypes.concat(responses[1].data.data)
        })
        .catch(() => {
          self.customEventTypes = []
          self.alert.message = "Error while fetching custom conversions.";
          self.alert.type = "error";
          self.alert.show = true;
        });
    },
    getAttributionWindows () {
      this.clickWindows = this.getAttributionClicks(this.config, fbEnums.optimizationGoalsEnum)
      this.viewWindows = this.getAttributionViews(this.config, fbEnums.optimizationGoalsEnum)
    },
    fetchMobileDevices (option) {
      this.clearMobileOSData()
      if (option === "Android devices only") {
        this.setOSLevelData(this.androidDevices, this.androidOsVersions, "Android")
        this.config.adset.targeting.minOsVersion = "2.0"
        this.config.adset.targeting.maxOsVersion = ""
        this.config.adset.targeting.isAndroidOs = true
      } else if (option === "iOS devices only") {
        this.setOSLevelData(this.iOSDevices, this.iOSVersions, "iOS")
        this.config.adset.targeting.isAndroidOs = false
        this.config.adset.targeting.minOsVersion = "2.0"
        this.config.adset.targeting.maxOsVersion = ""
      }
    },
    setOSLevelData (devices, osVersion, os = null) {
      this.deviceList = devices
      this.includedDeviceList = devices
      this.excludedDeviceList = devices
      this.mobileOsVersions = osVersion
      this.minOSList = osVersion
      this.maxOSList = osVersion
      if (os) this.config.adset.targeting.userOs.push(os)
    },
    clearMobileOSData () {
      this.config.adset.targeting.userDevice = []
      this.config.adset.targeting.excludedUserDevice = []
      this.config.adset.targeting.userOs = []
      this.deviceList = []
      this.includedDeviceList = []
      this.excludedDeviceList = []
    },
    getMobileDeviceListFromAPI (loadingFromExisting) {
      APIService.get(
        "/App/CampaignLauncher/GetMobileDevices/" +
          this.businessProfileId +
          "?bpseId=" +
          this.config.bpseId
      ).then(response => {
        this.androidDevices = response.Data.AndroidDevices || "[]";
        this.iOSDevices = response.Data.IosDevices || "[]";
        if (loadingFromExisting) this.setExistingConfigData()
      });
    },
    setExistingConfigData () {
      if (this.config.adset.targeting.isAndroidOs) {
        this.setOSLevelData(this.androidDevices, this.androidOsVersions)
      } else {
        this.setOSLevelData(this.iOSDevices, this.iOSVersions)
      }
    },
    mobileDeviceParentCheckAndorid (existingList, input) {
      let companyName = input.description.split(" ")[0];
      let isTablet = /(\s+|^)tab(\s+|$)|(\s+|^)tablet(\s+|$)/i.test(input.description)
      if (isTablet && !input.description.includes("_")) {
        existingList = existingList.filter(s => !s.name.includes('_Tablet') && s.description != companyName);
      } else if (input.name.includes("_Smartphone")) {
        // This will remove all the devices if Android_Phone or Tablet is passed.
        existingList = existingList.filter(s => s.name.includes("_") || isTablet);
      } else if (input.name.includes("_Tablet")) {
        // This will remove all the devices if Android_Phone or Tablet is passed.
        existingList = existingList.filter(s => s.name.includes("_") || !isTablet);
      } else if (input.description.includes("Devices")) {
        existingList = existingList.filter(s => !s.description.includes(companyName) && !s.name.includes('_Smartphone'));
        existingList.push(input)
      } else {
        // This will handle case when user selects some device(Oneplus 6t), it would remove both companyParent( OnePlusDevices) and Android_Phone parent (All android Phones) .
        var parent = companyName + " Devices"
        existingList = existingList.filter(s => !s.name.includes('_Smartphone') && s.description != companyName && s.description != parent);
      }
      return existingList
    },
    mobileDeviceParentCheckIOS (existingList, input) {
      let productType = input.name.split(" ")[0];
      if (input.description.includes("(all)")) {
        existingList = existingList.filter(s => s.description.includes("(all)") || !s.description.includes(productType));
      } else {
        // Use regex here
        var parent = productType + "s (all)"
        existingList = existingList.filter(s => s.description.toLowerCase() != parent);
      }
      return existingList
    },
    getOSVersionString () {
      var versionString = ""
      var maxOsVersion = this.config.adset.targeting.maxOsVersion
      var minOsVersion = this.config.adset.targeting.minOsVersion
      versionString = this.config.adset.targeting.isAndroidOs ? "Android_ver_" : "iOS_ver_";
      if (maxOsVersion && maxOsVersion != "all" && minOsVersion != "none") {
        versionString += minOsVersion + "_to_" + maxOsVersion
      } else if (minOsVersion === "none" && maxOsVersion === "none") {
        versionString = this.config.adset.targeting.isAndroidOs ? "Android" : "iOS";
      } else if (minOsVersion === "none") {
        return "";
      } else {
        versionString += minOsVersion + "_and_above"
      }
      return versionString
    },
    formatDate (date) {
      return moment(date).format("MM/DD/YYYY")
    },
    areArraysEqual (arr1, arr2) {
      return ((arr1.length == arr2.length) && arr1.filter(value => !arr2.includes(value)).length == 0)
    },
    setEditedData (editedData, parsedEditedAdset) {
      Object.keys(editedData).forEach(property => {
        if (this.config.adset.hasOwnProperty(property)) {
          if (property == "mobileDevices") {
            this.config.adset.targeting.isAndroidOs = editedData[property] == "Android devices only";
            if (this.config.adset.targeting.isAndroidOs && this.androidDevices.length) {
              this.setOSLevelData(this.androidDevices, this.androidOsVersions, "Android")
            } else if (!this.config.adset.targeting.isAndroidOs && this.iOSDevices.length) {
              this.setOSLevelData(this.iOSDevices, this.iOSVersions, "iOS")
            }
          }
          this.config.adset[property] = editedData[property]
        } else if (this.config.adset.targeting.hasOwnProperty(property)) {
          this.config.adset.targeting[property] = editedData[property]
        }
        if (property == 'adsetSchedule') {
          this.adsetSchedule = editedData[property].map(date => new Date(date))
        }
        this.$emit("adsetUpdatedSuccessfully", parsedEditedAdset[this.selectedAdSet.RowData.InternalCampaignName])
      })
    }
  }
}
</script>

<style scoped>
.ccHeader {
  margin: 0px;
  font-size: 20px !important;
}
::v-deep .ivu-checkbox-wrapper > .ivu-checkbox {
    padding-right: 5px !important;
}
</style>
