<template>
  <div>
    <div class="widget-btn-group">
      <button @click="showModal = isCreationSupported;" class="btn btn-success btn-sm" :class="{ 'btn-disabled': !isCreationSupported }">
        <i class="fa fa-plus"></i>
      </button>
      <button @click="downloadAdExtensions" class="btn btn-success btn-sm">
        <i v-if="downloading" class="fa fa-spinner fa-spin"></i>
        <i v-else class="fa fa-download"></i>
      </button>
    </div>
    <Modal
      class="ad-extension-modal"
      title="Create AdExtensions"
      v-model="showModal"
      effect="fade"
      width="60%"
      :scrollable="true"
      :styles="{top: '20px'}"
      :mask-closable="false"
    >
      <div class="alert alert-danger" v-if="showNotification" role="alert">
        Failed to create the AdExtension
        <button type="button" class="close" aria-label="Close" @click="showNotification = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form
        :label-width="150"
        :model="adExtensionForm"
        :rules="validationRules"
        ref="adExtensionForm"
      >
        <Form-Item label="Account" key="bpseId" prop="bpseId">
          <Select
            v-model="adExtensionForm.bpseId"
            placeholder="Select an account"
            class = "ad-extension-select"
          >
            <Option
              v-for="account in googleAccounts"
              :value="account.Id"
              :key="Number(account.Id)"
            >{{ account.NickName }} ({{account.CustomerId}})
            </Option>
          </Select>
        </Form-Item>
        <Form-Item label="Extension Type" key="extensionTypeId" prop="extensionTypeId">
          <Select
            v-model="adExtensionForm.extensionTypeId"
            placeholder="Select an extension type"
            class = "ad-extension-select"
          >
            <Option
              v-for="(extensionTypeId, name) in adExtensionTypes"
              :key="extensionTypeId"
              :value="extensionTypeId"
              >{{ name }}</Option
            >
          </Select>
        </Form-Item>
        <Form-Item label="Header Language" v-if="adExtensionForm.extensionTypeId == this.adExtensionTypes.StructuredSnippet" key="structredSnippetHeaderLangId" prop="structredSnippetHeaderLangId">
          <Select
            v-model="adExtensionForm.structredSnippetHeaderLangId"
            placeholder="Header Language"
            class = "ad-extension-select"
          >
            <Option
              v-for="(languageId, name) in adwordsEnums.structredSnippetHeaderLanguages"
              :key="languageId"
              :value="languageId"
              >{{ name }}</Option
            >
          </Select>
        </Form-Item>
        <Form-Item :rules="validationRules.structredSnippetHeaderType" key="structredSnippetHeaderType" prop="structredSnippetHeaderType" v-if="adExtensionForm.extensionTypeId == this.adExtensionTypes.StructuredSnippet" label="Header Type">
          <Select
            v-model="adExtensionForm.structredSnippetHeaderType"
            placeholder="Select Header Type"
            class = "ad-extension-select"
          >
            <Option
              v-for="type in structredSnippetHeaderTypes"
              :key="type"
              :value="type"
              >{{ type }}</Option
            >
          </Select>
        </Form-Item>
        <FormItem :label="textLabel" key="text" prop="text" v-if="adExtensionForm.extensionTypeId != this.adExtensionTypes.StructuredSnippet && adExtensionForm.extensionTypeId != this.adExtensionTypes.Call">
          <i-input type="text" v-model="adExtensionForm.text" class = "ad-extension-input"/>
        </FormItem>
        <FormItem label="Description 1" key="descriptionLine1" prop="descriptionLine1" v-if="adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink">
          <i-input type="text" v-model="adExtensionForm.descriptionLine1" class = "ad-extension-input"/>
        </FormItem>
        <FormItem label="Description 2" key="descriptionLine2" prop="descriptionLine2" v-if="adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink">
          <i-input type="text" v-model="adExtensionForm.descriptionLine2" class = "ad-extension-input"/>
        </FormItem>
        <FormItem label="Final URL" key="finalUrl" prop="finalUrl" v-if="adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink">
          <i-input type="text" v-model="adExtensionForm.finalUrl" class = "ad-extension-input"/>
        </FormItem>
        <FormItem label="Final Mobile URL" key="finalMobileUrl" prop="finalMobileUrl" v-if="adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink">
          <i-input type="text" v-model="adExtensionForm.finalMobileUrl" class = "ad-extension-input"/>
        </FormItem>
        <FormItem label="Tracking Template" key="trackingTemplate" prop="trackingTemplate" v-if="adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink">
          <i-input type="text" v-model="adExtensionForm.trackingTemplate" class = "ad-extension-input"/>
        </FormItem>
        <FormItem label="Custom Parameters" key="customParameters" prop="customParameters" v-if="adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink">
          <Row v-for="(customParameter, index) in adExtensionForm.customParameters" :key="index" class="ad-extension-customParameters">
            <FormItem
              :prop="`customParameters.${index}`"
              :rules="validationRules.customParameter"
              class="ad-extension-adSchedules"
            >
              <Row-Col span="6">
                <i-input v-model="customParameter.key">
                  <span slot="prepend">{_</span>
                  <span slot="append">} =</span>
                </i-input>
              </Row-Col>
              <Row-Col span="4">
                <i-input type="text" v-model="customParameter.value"/>
              </Row-Col>
              <Row-Col span="1" offset="1">
                <Icon
                  type="md-close"
                  size="24"
                  class="iview-icons close-btn"
                  @click="removeCustomParameters(index)"
                />
              </Row-Col>
            </FormItem>
          </Row>
          <Button @click="addCustomParameters" icon="md-add">Add Custom Parameter</Button>
        </FormItem>
        <FormItem :rules="validationRules.structredSnippetValues" label="Values" key="structredSnippetValues" prop="structredSnippetValues" v-if="adExtensionForm.extensionTypeId == this.adExtensionTypes.StructuredSnippet">
          <Row v-for="(value, index) in adExtensionForm.structredSnippetValues" :key="index">
            <FormItem
             :prop="`structredSnippetValues.${index}`"
             :rules="validationRules.value"
             class="ad-extension-adSchedules"
            >
              <Row-Col span="11">
                 <i-input v-model="value.text" type="text" prop="value" class = "ad-extension-input" :placeholder="`Enter Value ${index+1}`"/>
              </Row-Col>
            </FormItem>
          </Row>
          <Button v-if="adExtensionForm.structredSnippetValues.length < 10" @click="addValues" icon="md-add">Add Value</Button>
        </FormItem>
        <FormItem label="Start Date" key="startDate" prop="startDate" v-if="adExtensionForm.extensionTypeId != this.adExtensionTypes.StructuredSnippet && adExtensionForm.extensionTypeId != this.adExtensionTypes.Call">
          <DatePicker type="date" :options="dateOptions" placeholder="Select start date" v-model="adExtensionForm.startDate" class = "ad-extension-date-picker"></DatePicker>
        </FormItem>
        <FormItem label="End Date" key="endDate" prop="endDate" v-if="adExtensionForm.extensionTypeId != this.adExtensionTypes.StructuredSnippet && adExtensionForm.extensionTypeId != this.adExtensionTypes.Call">
          <DatePicker type="date" :options="dateOptions" placeholder="Select end date" v-model="adExtensionForm.endDate" class = "ad-extension-date-picker"></DatePicker>
        </FormItem>
        <FormItem label="Country Code" key="countryCode" prop="countryCode" v-if="adExtensionForm.extensionTypeId == this.adExtensionTypes.Call">
          <i-input type="text" v-model="adExtensionForm.countryCode" class = "ad-extension-input"></i-input>
        </FormItem>
        <FormItem label="Phone Number" key="phoneNumber" prop="phoneNumber" v-if="adExtensionForm.extensionTypeId == this.adExtensionTypes.Call">
          <InputNumber v-model="adExtensionForm.phoneNumber" class = "ad-extension-input" :min=0></InputNumber>
        </FormItem>
        <FormItem label="Scheduling"  v-if="adExtensionForm.extensionTypeId != this.adExtensionTypes.StructuredSnippet">
          <Row v-for="(adSchedule, index) in adExtensionForm.adScheduleTargets" :key="index">
            <FormItem
            :prop="`adScheduleTargets.${index}`"
            :rules="validationRules.adScheduleTarget"
             class="ad-extension-adSchedules"
            >
              <Row-Col span="4">
                <Select
                  v-model="adSchedule.day"
                  placeholder="Days"
                >
                  <Option
                    v-for="(day, index) in adwordsEnums.dayOfWeek"
                    :key="index"
                    :value="Number(index)"
                    >{{ day }}
                  </Option>
                </Select>
              </Row-Col>
              <Row-Col span="4" offset="1">
                <Select
                  v-model="adSchedule.startTime"
                  placeholder="Start Time"
                  class="ad-extension-time-picker"
                >
                  <Option
                    v-for="timeRange in timeRanges()"
                    :key="timeRange"
                    :value="timeRange"
                    >{{ timeRange }}
                  </Option>
                </Select>
              </Row-Col>
              <Row-Col span="4">
                <Select
                  v-model="adSchedule.endTime"
                  placeholder="End Time"
                  class="ad-extension-time-picker"
                >
                  <Option
                    v-for="timeRange in timeRanges()"
                    :key="timeRange"
                    :value="timeRange"
                    >{{ timeRange }}
                  </Option>
                </Select>
              </Row-Col>
              <Row-Col span="1" offset="1">
                <Icon
                  type="md-close"
                  size="24"
                  class="iview-icons close-btn"
                  @click="removeSchedule(index)"
                />
              </Row-Col>
            </FormItem>
          </Row>
          <Button @click="addSchedule" icon="md-add">Add Schedule</Button>
        </FormItem>
      </Form>
      <div slot="footer">
        <button
          class="btn btn-white"
          v-on:click='resetAllFields()'
          :disabled="showLoader"
        >Reset</button>
        <button
          class="btn btn-success"
          v-on:click="createAdextension()"
          :disabled="showLoader"
        >Save<i v-if="showLoader" class="fa fa-spinner fa-spin" /></button>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  Form,
  FormItem,
  Select,
  Option,
  Button,
  Input,
  DatePicker,
  Row,
  Col,
  Icon,
  Modal,
  locale,
  InputNumber
} from "iview"
import lang from "iview/dist/locale/en-US";
import { adwordsEnums } from "../../Constants/AdwordsEnums.js";
import { APIService } from "../../ApiService.js";
locale(lang);

export default {
  components: {
    "i-input": Input,
    Form,
    FormItem,
    Select,
    Option,
    Button,
    DatePicker,
    Row,
    "Row-Col": Col,
    Icon,
    Modal,
    InputNumber
  },
  data () {
    return {
      adwordsEnums: adwordsEnums,
      showModal: false,
      showLoader: false,
      downloading: false,
      showNotification: false,
      dateOptions: {
        disabledDate (date) {
          return date && date.valueOf() < Date.now() - 1000 * 60 * 60 * 24 * 1;
        }
      },
      adExtensionForm: {
        bpseId: null,
        extensionTypeId: 3,
        startDate: null,
        endDate: null,
        text: "",
        descriptionLine1: "",
        descriptionLine2: "",
        finalUrl: "",
        finalMobileUrl: "",
        trackingTemplate: "",
        customParameters: [],
        structredSnippetValues: [
          { text: null },
          { text: null },
          { text: null }
        ],
        adScheduleTargets: [],
        structredSnippetHeaderLangId: 1,
        structredSnippetHeaderType: null,
        countryCode: "IN",
        phoneNumber: null
      },
      adExtensionTypes: { // Eligible for creation
        "SiteLink": 3,
        "Callout": 8,
        "StructuredSnippet": 9,
        "Call": 1
      },
      validationRules: {
        bpseId: [
          {
            required: true,
            type: "number",
            message: "Please select an account",
            trigger: "blur"
          }
        ],
        text: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.adExtensionForm.extensionTypeId != this.adExtensionTypes.StructuredSnippet) {
                if (!value) {
                  callback(new Error("Please enter some value"));
                }
              }
              callback();
            }
          }
        ],
        descriptionLine1: [
          {
            validator: (rule, value, callback) => {
              if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink) {
                if (!value && this.adExtensionForm.descriptionLine2) {
                  callback(new Error("Cannot be empty if Description 2 is given"));
                }
              }
              callback();
            }
          }
        ],
        descriptionLine2: [
          {
            validator: (rule, value, callback) => {
              if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink) {
                if (!value && this.adExtensionForm.descriptionLine1) {
                  callback(new Error("Cannot be empty if Description 1 is given"));
                }
              }
              callback();
            }
          }
        ],
        endDate: [
          {
            validator: (rule, value, callback) => {
              if (this.adExtensionForm.extensionTypeId != this.adExtensionTypes.StructuredSnippet) {
                if (value && this.adExtensionForm.startDate && this.adExtensionForm.startDate > value) {
                  callback(new Error("End Date cannot be before the Start Date"));
                }
              }
              callback();
            }
          }
        ],
        finalUrl: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink) {
                if (!value) {
                  callback(new Error("Final URL cannot be empty"));
                }
                if (!value.startsWith("http://") && !value.startsWith("https://")) {
                  callback(new Error("Final URL should begin with 'http://' or 'https://'"));
                }
              }
              callback();
            }
          }
        ],
        trackingTemplate: [
          {
            validator: (rule, value, callback) => {
              if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink) {
                if (value && !value.startsWith("http://") && !value.startsWith("https://")) {
                  callback(new Error("Tracking Template should begin with 'http://' or 'https://'"));
                }
              }
              callback();
            }
          }
        ],
        adScheduleTarget: [
          {
            validator: (rule, value, callback) => {
              if (this.adExtensionForm.extensionTypeId != this.adExtensionTypes.StructuredSnippet) {
                if (value.startTime && value.endTime) {
                  var startTimeList = value.startTime.split(":").map(Number)
                  var endTimeList = value.endTime.split(":").map(Number)
                  if ((startTimeList[0] > endTimeList[0]) || ((startTimeList[0] == endTimeList[0]) && (startTimeList[1] >= endTimeList[1]))) {
                    callback(new Error("End time must be after Start time"));
                  }
                }
              }
              callback();
            }
          }
        ],
        customParameter: [
          {
            validator: (rule, value, callback) => {
              if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink) {
                if (value) {
                  if (!value.key) {
                    callback(new Error("The key cannot be empty"));
                  }
                  if (value.key && !/^[a-z0-9]+$/i.test(value.key)) {
                    callback(new Error("The key contains invalid characters"));
                  }
                  if (this.adExtensionForm.customParameters.filter(cp => cp.key && cp.key == value.key).length > 1) {
                    callback(new Error("Cannot give duplicate keys"));
                  }
                }
              }
              callback();
            },
            trigger: "blur"
          }
        ],
        structredSnippetHeaderType: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.StructuredSnippet && !value) {
                callback(new Error("Please select a header type"));
              }
              callback();
            }
          }
        ],
        value: [
          {
            validator: (rule, value, callback) => {
              if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.StructuredSnippet) {
                if (value.text != null ? value.text.trim().length > 25 : false) {
                  callback(new Error("Cannot be more than 25 characters"));
                }
              }
              callback();
            }
          }
        ],
        structredSnippetValues: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.StructuredSnippet) {
                let nonEmptyValuesCount = value.filter(v => {
                  if ((v.text != null) ? v.text.trim() != '' : false) {
                    return v;
                  }
                }).length;
                if (nonEmptyValuesCount < 3) {
                  callback(new Error("Atleast 3 values required"));
                }
              }
              callback();
            }
          }
        ],
        countryCode: [{ required: true, message: "Country Code is required" }],
        phoneNumber: [{ required: true, message: "Phone Number code is required" }]
      }
    }
  },
  computed: {
    ...mapState(["googleAccounts", "selectedCampaignLauncherConfig"]),
    isCreationSupported () {
      return this.selectedCampaignLauncherConfig && this.selectedCampaignLauncherConfig.campaign && this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["Performance Max"];
    },
    textLabel () {
      if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink) {
        return 'Sitelink Text'
      } else if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.Callout) {
        return 'Callout Text'
      } else {
        return 'Text'
      }
    },
    structredSnippetHeaderTypes () {
      if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.StructuredSnippet) {
        let headers = this.adwordsEnums.structredSnippetHeaderTypes.find(t => t.languageId == this.adExtensionForm.structredSnippetHeaderLangId);
        if (headers) {
          return headers.types;
        }
      }
      return [];
    }
  },
  watch: {
    showModal () {
      this.resetAllFields()
    }
  },
  methods: {
    addCustomParameters () {
      this.adExtensionForm.customParameters.push({
        key: null,
        value: null
      });
    },
    addValues () {
      if (this.adExtensionForm.structredSnippetValues.length < 10) {
        this.adExtensionForm.structredSnippetValues.push({ text: null });
      }
    },
    removeCustomParameters (index) {
      this.adExtensionForm.customParameters.splice(index, 1)
    },
    addSchedule () {
      this.adExtensionForm.adScheduleTargets.push({
        day: 2,
        startTime: null,
        endTime: null
      });
    },
    removeSchedule (index) {
      this.adExtensionForm.adScheduleTargets.splice(index, 1)
    },
    timeRanges () {
      var timeRanges = []
      for (var hour = 0; hour < 24; hour++) {
        for (var minuteOfHour of ['00', '15', '30', '45']) {
          timeRanges.push(hour + ":" + minuteOfHour)
        }
      }
      return timeRanges;
    },
    resetAllFields () {
      this.$refs.adExtensionForm.resetFields();
      this.adExtensionForm.structredSnippetValues = [
        { text: null },
        { text: null },
        { text: null }
      ];
      this.adExtensionForm.customParameters = [];
      this.adExtensionForm.adScheduleTargets = [];
    },
    getSchedules (adScheduleTargets) {
      var schedules = []
      for (var adScheduleTarget of adScheduleTargets) {
        var startTimeList = adScheduleTarget.startTime.split(":")
        var endTimeList = adScheduleTarget.endTime.split(":")
        schedules.push({
          startHour: startTimeList[0],
          startMinute: this.adwordsEnums.minuteOfHour[startTimeList[1]],
          endHour: endTimeList[0],
          endMinute: this.adwordsEnums.minuteOfHour[endTimeList[1]],
          dayOfWeek: adScheduleTarget.day
        })
      }
      return schedules;
    },
    createAdextension () {
      this.$refs.adExtensionForm.validate((valid) => {
        if (valid) {
          var requestObject = {};
          if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.SiteLink) {
            requestObject = {
              startDate: this.adExtensionForm.startDate,
              endDate: this.adExtensionForm.endDate,
              extensionTypeId: this.adExtensionForm.extensionTypeId
            }
            requestObject["SiteLink"] = {
              text: this.adExtensionForm.text,
              descriptionLine1: this.adExtensionForm.descriptionLine1,
              descriptionLine2: this.adExtensionForm.descriptionLine2,
              finalUrl: this.adExtensionForm.finalUrl,
              finalMobileUrl: this.adExtensionForm.finalMobileUrl,
              trackingTemplate: this.adExtensionForm.trackingTemplate,
              customParameters: this.adExtensionForm.customParameters,
              adScheduleTargets: this.getSchedules(this.adExtensionForm.adScheduleTargets)
            }
          }
          if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.Callout) {
            requestObject = {
              startDate: this.adExtensionForm.startDate,
              endDate: this.adExtensionForm.endDate,
              extensionTypeId: this.adExtensionForm.extensionTypeId
            }
            requestObject["Callout"] = {
              text: this.adExtensionForm.text,
              adScheduleTargets: this.getSchedules(this.adExtensionForm.adScheduleTargets)
            }
          }
          if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.StructuredSnippet) {
            requestObject = {
              extensionTypeId: this.adExtensionForm.extensionTypeId
            }
            requestObject["StructuredSnippet"] = {
              values: this.adExtensionForm.structredSnippetValues.filter(v => {
                if ((v.text != null) ? v.text.trim() != '' : false) {
                  return v;
                }
              }).map(val => val.text.trim()),
              header: this.adExtensionForm.structredSnippetHeaderType
            }
          }
          if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.Call) {
            requestObject = {
              extensionTypeId: this.adExtensionForm.extensionTypeId
            }
            requestObject["Call"] = {
              countryCode: this.adExtensionForm.countryCode,
              phoneNumber: this.adExtensionForm.phoneNumber,
              adScheduleTargets: this.getSchedules(this.adExtensionForm.adScheduleTargets)
            }
          }
          this.showLoader = true;
          APIService.createAdExtensions(this.adExtensionForm.bpseId, requestObject)
            .then(response => {
              let extensionName = "";
              if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.StructuredSnippet) {
                extensionName = this.adExtensionForm.structredSnippetValues[0].text;
              } else if (this.adExtensionForm.extensionTypeId == this.adExtensionTypes.Call) {
                extensionName = this.adExtensionForm.phoneNumber;
              } else {
                extensionName = this.adExtensionForm.text;
              }
              this.$emit("newAdExtensionCreated",
                {
                  id: response.id,
                  name: extensionName,
                  extensionTypeId: this.adExtensionForm.extensionTypeId,
                  businessProfilesSearchEngineId: this.adExtensionForm.bpseId
                });
              this.showModal = false;
            })
            .catch(() => {
              this.showNotification = true;
            })
            .finally(() => {
              this.showLoader = false;
            });
        }
      });
    },
    getFileNameFromResponseHeader (headers, defaultFileName = null) {
      if (!headers || !headers["content-disposition"]) return defaultFileName;

      const fileNameHeader = headers["content-disposition"].split(";").map(e => e.trim().split("=")).find(e => e.length == 2 && e[0] == "filename");

      if (!fileNameHeader) return defaultFileName;

      return fileNameHeader[1];
    },
    async downloadAdExtensions () {
      if (this.downloading) return;

      try {
        this.downloading = true;

        const response = await APIService.downloadAdExtensions(this.selectedCampaignLauncherConfig.bpseId);
        const fileName = this.getFileNameFromResponseHeader(response.headers, "adextensions.csv");
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } catch (error) {
        console.error(error);
        this.showMessage("Something went wrong while processing your request, Please try again.", "error");
      } finally {
        this.downloading = false;
      }
    }
  }
}
</script>
<style scoped>
.ad-extension-modal ::v-deep .ivu-modal-body {
    height: 75vh;
    overflow-y: auto;
}
.ad-extension-select, .ad-extension-input {
  width: 300px;
}
.ad-extension-date-picker {
  width: 216px;
}
.ad-extension-adSchedules {
  padding: 5px 0px 20px;
}
.ad-extension-customParameters{
  padding: 5px 0px;
}
.close-btn {
  cursor: pointer;
}
.ad-extension-time-picker {
  padding: 0px 5px;
}
.ad-extension-adSchedules ::v-deep .ivu-form-item-content {
  padding-left: 0px;
}
.ad-extension-adSchedules ::v-deep .ivu-form-item-error-tip {
  margin-left: 0px;
  margin-top: 30px;
}
.btn {
  margin: 0px 0px 0px 15px;
}

.widget-btn-group {
  margin-inline: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.widget-btn-group .btn {
  margin: 0;
}

.widget-btn-group .fa {
  font-size: inherit !important;
}
</style>
<style src="../../../../Styles/dx-iview.css"></style>
