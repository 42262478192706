<template>
  <div :class="[isAgencyCentral ? 'agency-central' : 'component-override']">
    <div class="row filter-panel header-pua">
      <div class="row">
        <div class="col-md-2 col-sm-2" v-if="!isAgencyCentral">
          <i-select
            v-model="selectedPortalId"
            placeholder="Select Portal"
            filterable
            @on-change="portalChange()"
          >
            <i-option v-for="item in portals" :value="item.id" :key="item.id">{{
              item.domains[0]
            }}</i-option>
          </i-select>
        </div>
        <div :class="[isAgencyCentral ? 'col-md-6 col-sm-6' : 'col-md-4 col-sm-4']">
          <i-select
            v-model="selectedBusinessProfileIds"
            multiple
            placeholder="Select Business Profiles"
            filterable
          >
            <i-option
              v-for="item in businessProfiles"
              :value="item.id"
              :key="item.id"
              >{{ item.name }}</i-option
            >
          </i-select>
        </div>
        <div class="col-md-2 col-sm-2">
          <DatePicker
            type="daterange"
            placement="bottom-end"
            format="MMM d, yyyy"
            :clearable="false"
            :split-panels="true"
            :editable="false"
            v-model="dateFilters"
            class="date-picker-width"
          ></DatePicker>
        </div>
        <div class="col-md-2 col-sm-2 flag-switch">
          <span style="margin-right:5px;">Feature Count</span>
          <span>
            <i-switch v-model="flagSwitch" :disabled="!tableShow" />
          </span>
        </div>
        <div class="col-md-1 col-sm-1">
          <button
            type="button"
            :class="[isAgencyCentral ? 'btn btn-success float-right' : 'btn btn-success btn-color float-right']"
            :disabled="isDisabled"
            @click.stop="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div
      class="row filter-panel gapheight text-center padding-bottom"
      v-if="nodata && !showSpinner"
    >
      No data for the respective parameters
    </div>
    <div
      class="row filter-panel gapheight text-center error padding-bottom"
      v-if="dateRange && !showSpinner"
    >
      Date difference must be less than 45 days
    </div>
    <hr>
    <div class="row result-panel gapheight" v-if="tableShow && !showSpinner">
      <div class="row row-filter">
        <table class="table-color">
          <thead>
            <th>
              <div class="table-title-col-modules">
                <div class="div-modules">MODULES</div>
              </div>
            </th>
            <th v-for="t in tableTitle" :key="t.header">
              <div class="text-center table-title-col-weeks">
                <div class="div-weeks">
                  {{ t.header }}
                </div>
                <div style="font-size: 9px">
                  {{ t.dateRange }}
                </div>
              </div>
            </th>
          </thead>
        </table>
      </div>

      <div class="row row-filter">
        <i-table
          border
          :row-class-name="rowClassName"
          :columns="columns1"
          :data="filteredFeatureLogsInsights"
          :width="tableWidth"
        >
        </i-table>
      </div>
    </div>
    <div v-if="showSpinner" style="text-align: center">
      <i class="fa fa-spinner fa-spin fa-3x"></i>
    </div>
  </div>
</template>

<script>
import {
  Select,
  Option,
  OptionGroup,
  DatePicker,
  locale,
  Switch,
  Table,
  Button,
} from "iview";
import lang from "iview/dist/locale/en-US"; // iview is default chinese
import { ApiService } from "../Services/ApiService.js";
import JsonExcel from "vue-json-excel";
import MarkPoptip from "./MarkPopTip.vue";
locale(lang);

export default {
  components: {
    "i-select": Select,
    "i-option": Option,
    "i-table": Table,
    "i-switch": Switch,
    "download-excel": JsonExcel,
    "i-button": Button,
    MarkPoptip,
    DatePicker,
  },
  data() {
    return {
      businessProfiles: [],
      selectedBusinessProfileIds: [],
      portals: [],
      features: [],
      modules: [],
      featureLogsInsights: [],
      filteredFeatureLogsInsights: [],
      selectedPortalId: 0,
      flagSwitch: false,
      dateFilters: [],
      weekDateRange: [],
      tableShow: false,
      nodata: false,
      showSpinner: false,
      dateRange: false,
      isAgencyCentral: false,
    };
  },
  created() {
    if (typeof isAgencyCentral === "undefined" || isAgencyCentral === null) {
      this.isAgencyCentral = false;
    } else if (isAgencyCentral == 1) {
      this.isAgencyCentral = true;
    }
  },
  mounted() {
    if (deltax.QuerystartingDate.length == 0 && deltax.QueryendingDate.length == 0) {
      this.dateFilters = [
        moment().subtract(21, "days").startOf("day"),
        moment().startOf("day"),
      ];
    } else {
      this.dateFilters = [
        new Date(deltax.QuerystartingDate),
        new Date(deltax.QueryendingDate),
      ];
    }
    var promises = [];
    if (!this.isAgencyCentral) {
      var portalsPromise = ApiService.getPortals();
      promises.push(portalsPromise);
    } else if (this.isAgencyCentral) {
      var bzProfilePromise = ApiService.getBzProfiles();
      promises.push(bzProfilePromise);
    }
    var featurePromise = ApiService.getFeatures();
    promises.push(featurePromise);
    var modulePromise = ApiService.getModules();
    promises.push(modulePromise);
    var self = this;
    Promise.all(promises)
      .then(function (result) {
        if (!self.isAgencyCentral) {
          self.portals = result[0].data.data;
          self.features = result[1].data.data;
          self.modules = result[2].data.data;
          if (deltax.QueryPortalId != 0) {
            if (result[0].data.data.map((x) => x.id).includes(deltax.QueryPortalId)) {
              self.selectedPortalId = deltax.QueryPortalId;
              ApiService.getBusinessProfiles(self.selectedPortalId).then((res) => {
                if (res.data.data.length > 0) {
                  self.businessProfiles.push({id: 0, name:"all"});
                }
                self.businessProfiles.push(...res.data.data);
                if (deltax.QuerybusinessProfileIds.length > 0) {
                  deltax.QuerybusinessProfileIds.split(",").map((n) => parseInt(n)).forEach((x) => {
                    if (self.businessProfiles.map((y) => y.id).includes(x)) {
                      self.selectedBusinessProfileIds.push(x);
                    }
                  });
                }
                if (deltax.QuerybusinessProfileIds.length > 0 && deltax.QuerystartingDate.length > 0 && deltax.QuerystartingDate.length > 0 && self.selectedBusinessProfileIds.length > 0 && self.dateFilters.length == 2) {
                  self.submit();
                  self.flagSwitch = true;
                }
              });
            }
          }
        } 
        else if (self.isAgencyCentral) {
          if (result[0].data.data.length > 0) {
            self.businessProfiles.push({id: 0, name:"all"});
          }
          self.businessProfiles.push(...result[0].data.data);
          self.features = result[1].data.data;
          self.modules = result[2].data.data;
          if (deltax.QuerybusinessProfileIds.length > 0) {
            deltax.QuerybusinessProfileIds.split(",").map((n) => parseInt(n)).forEach((x) => {
              if (self.businessProfiles.map((y) => y.id).includes(x)) {
                self.selectedBusinessProfileIds.push(x);
              }
            });
          }
          if (deltax.QuerybusinessProfileIds.length > 0 && deltax.QuerystartingDate.length > 0 && deltax.QuerystartingDate.length > 0 && self.selectedBusinessProfileIds.length > 0 && self.dateFilters.length == 2) {
            self.submit();
            self.flagSwitch = true;
          }
        }
      });
  },
  computed: {
    isDisabled() {
      if (
        this.dateFilters.length == 2 &&
        this.selectedBusinessProfileIds.length > 0
      ) {
        return false;
      }
      return true;
    },
    tableTitle() {
      var tableTitleList = [];
      if (this.featureLogsInsights != null) {
        for (var x of this.featureLogsInsights[0].data) {
          var obj = {
            header:
              x.weekHeaderName.substring(0, 4).toUpperCase() +
              " " +
              x.weekHeaderName.substring(4).toUpperCase(),
            dateRange: `(${x.minDate.toString().slice(0, 10)} to ${x.maxDate
              .toString()
              .slice(0, 10)} )`,
          };
          tableTitleList.push(obj);
        }
      }
      return tableTitleList;
    },
    tableWidth() {
      var width = 402;
      if (this.featureLogsInsights != null) {
        width += this.featureLogsInsights[0].data.length * 150;
      }
      return width;
    },
    columns1() {
      var columns = [{ key: "featurename", width: 400 }];
      for (var x of this.featureLogsInsights[0].data) {
        var obj = {
          key: x.weekHeaderName,
          width: 150,
          align: "center",
          render: (h, params) => {
            var columnTitle = params.column.key;
            var weekCount = params.row[`${columnTitle}`];
            var hover = " ";
            var coltable = this.columns2;
            var dynamicId = "id" + params.index + columnTitle;
            var iconshow = this.flagSwitch;
            if (
              this.filteredFeatureLogsInsights[params.index].moduleid != null
            ) {
              hover =
                this.filteredFeatureLogsInsights[params.index][
                  `${columnTitle}` + `hover`
                ];
            }
            if (
              weekCount >= 0 &&
              this.filteredFeatureLogsInsights[params.index].moduleid != null &&
              this.flagSwitch == true
            ) {
              return h("div", [
                h(MarkPoptip, {
                  props: {
                    iconshow: iconshow,
                    weekCount: weekCount,
                    hover: hover,
                  },
                }),
              ]);
            } else if (
              weekCount > 0 &&
              this.filteredFeatureLogsInsights[params.index].moduleid != null &&
              this.flagSwitch == false
            ) {
              return h("div", [
                h(MarkPoptip, {
                  props: {
                    iconshow: iconshow,
                    weekCount: weekCount,
                    hover: hover,
                    dynamicId: dynamicId,
                  },
                }),
              ]);
            } else if (
              this.filteredFeatureLogsInsights[params.index].moduleid != null
            )
              return h("span", " ");
          },
        };
        columns.push(obj);
      }
      return columns;
    },
  },
  methods: {
    portalChange () {
      this.businessProfiles = [];
      this.selectedBusinessProfileIds = [];
      ApiService.getBusinessProfiles(this.selectedPortalId).then((res) => {
        if (res.data.data.length > 0) {
          this.businessProfiles.push({id: 0, name:"all"});
        }
        this.businessProfiles.push(...res.data.data);
      });
    },
    // link https://stackoverflow.com/questions/9045868/javascript-date-getweek
    getWeek(date, dowOffset = 0) {
      const newYear = new Date(date.getFullYear(), 0, 1);
      let day = newYear.getDay() - dowOffset; 
      day = day >= 0 ? day : day + 7;
      const daynum =
        Math.floor(
          (date.getTime() -
            newYear.getTime() -
            (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
            86400000
        ) + 1;
      if (day < 4) {
        const weeknum = Math.floor((daynum + day - 1) / 7) + 1;
        if (weeknum > 52) {
          const nYear = new Date(date.getFullYear() + 1, 0, 1);
          let nday = nYear.getDay() - dowOffset;
          nday = nday >= 0 ? nday : nday + 7;
          return nday < 4 ? 1 : 53;
        }
        return weeknum;
      } else {
        return Math.floor((daynum + day - 1) / 7);
      }
    },
    GetWeekRange() {
      this.weekDateRange = [];
      var start =  moment(this.dateFilters[0]).toDate();
      var end = moment(this.dateFilters[1]).toDate();
      var weeks = [];
      for (var dt = start; dt <= end; dt = moment(dt).add(1, "days").toDate())
      {
        var weekNo = this.getWeek(dt,1);
        if (!weeks.includes(weekNo))
        {
          weeks.push(weekNo);
          var weekObj = {
                  weekNo : weekNo,
                  startDate : moment(dt).format("YYYY/MM/DD"),
                  endDate : ""
              };
          if (weeks.length > 1)
          {
            this.weekDateRange[weeks.length - 2].endDate = moment(dt).subtract(1, "days").format("YYYY/MM/DD");
          }
         this.weekDateRange.push(weekObj);
        }
        this.weekDateRange[weeks.length - 1].endDate = moment(end).format("YYYY/MM/DD");
      }
    },
    AddNoDataFeaturesAndWeeks(insight){
      var featureLogsInsights = [];
      var featureIds = insight.map(x => x.id);
      //Adding features with no data
      var featureInsightsWithNoData = this.features.filter(f => !featureIds.includes(f.id)).map(feature => {
        var featureInsightObj = {
          id : feature.id,
          name : feature.name,
          module: {
            id: feature.moduleId,
            name: this.modules.filter(m => m.id == feature.moduleId).map(m => m.name)[0]
          },
          data : []
        };
        for(let weekObj of this.weekDateRange)
        {
          var wObj = {
            weekNo : weekObj.weekNo,
            weekHeaderName : "WEEK" + weekObj.weekNo,
            featureCount : 0,
            minDate : weekObj.startDate,
            maxDate : weekObj.endDate,
            metaData : null
          };
          featureInsightObj.data.push(wObj);
        }
        return featureInsightObj;
      });
      //
      //Adding weeks wth no data
      var featureInsightsWithMissingWeek = insight.map(x => {
        var obj = {
          id : x.id,
          name : x.name,
          module : x.module,
          data : []
        };
        for(let weekObj of this.weekDateRange)
        {
          var boolValue = x.data.map(d => d.weekNo).some(weekno => weekno == weekObj.weekNo);
          if(!boolValue){
            var wObj = {
            weekNo : weekObj.weekNo,
            weekHeaderName : "WEEK" + weekObj.weekNo,
            featureCount : 0,
            minDate : weekObj.startDate,
            maxDate : weekObj.endDate,
            metaData : null
          };
          obj.data.push(wObj);
          }
          else{
            var existingWeek = x.data.filter(d => d.weekNo == weekObj.weekNo)[0];
            var wObj = {
            weekNo : weekObj.weekNo,
            weekHeaderName : "WEEK" + weekObj.weekNo,
            featureCount : existingWeek.featureCount,
            minDate : weekObj.startDate,
            maxDate : weekObj.endDate,
            metaData : existingWeek.metaData
          };
          obj.data.push(wObj);
          }
        }
        return obj;
      });
      //
      featureLogsInsights = featureInsightsWithNoData;
      featureLogsInsights = featureLogsInsights.concat(featureInsightsWithMissingWeek);
      return featureLogsInsights;
    },
    rowClassName(row, index) {
      if (row.moduleid === null) {
        return "demo-table-info-row";
      }
      return "";
    },
    submit() {
      var businessIds = [];
      var currentPortalId = 0;
      if (this.selectedBusinessProfileIds.includes(0)) {
        businessIds = this.businessProfiles.filter((x) => x.id != 0).map((bp) => bp.id);
        this.selectedBusinessProfileIds = [0];
        currentPortalId = this.isAgencyCentral ? deltax.portalId : this.selectedPortalId;
      } else {
        businessIds = this.selectedBusinessProfileIds;
      }
      this.showSpinner = true;
      this.flagSwitch = false;
      const diffTime = Math.abs(this.dateFilters[1] - this.dateFilters[0]);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays >= 45) {
        this.tableShow = false;
        this.nodata = false;
        this.dateRange = true;
        this.showSpinner = false;
      } else {
        var featureLogsInsightspromise = ApiService.fetchFeatureLogsInsights(
          currentPortalId,
          businessIds,
          moment(this.dateFilters[0]).format("YYYY/MM/DD"),
          moment(this.dateFilters[1]).format("YYYY/MM/DD")
        );
        var featureLogsInsightspromises = [];
        featureLogsInsightspromises.push(featureLogsInsightspromise);
        var self = this;
        Promise.all(featureLogsInsightspromises).then(function (result) {
          self.GetWeekRange();
          self.featureLogsInsights = self.AddNoDataFeaturesAndWeeks(result[0].data.data);
          self.showSpinner = false;
          if (result[0].data.data.length > 0) {
            var filteredInsights = self.featureLogsInsights
              .map((x) => {
                var obj = {
                  featurename: x.name,
                  moduleid: x.module.id,
                  modulename: x.module.name,
                };
                for (var i = 0; i < x.data.length; i++) {
                  obj[`${x.data[i].weekHeaderName}`] = x.data[i].featureCount;
                  obj[`${x.data[i].weekHeaderName}` + `hover`] =
                    x.data[i].metaData;
                  obj[`${x.data[i].weekHeaderName}` + `MinDate`] =
                    x.data[i].minDate;
                  obj[`${x.data[i].weekHeaderName}` + `MaxDate`] =
                    x.data[i].maxDate;
                }
                return obj;
              })
              .sort((a, b) => a.moduleid - b.moduleid);
            var filteredLogsInsights = [];
            filteredLogsInsights.push({
              featurename: filteredInsights[0].modulename,
              moduleid: null,
              modulename: null,
            });
            filteredLogsInsights.push(filteredInsights[0]);
            if (
              filteredInsights.length > 1 &&
              filteredInsights[0].moduleid != filteredInsights[1].moduleid
            ) {
              filteredLogsInsights.push({
                featurename: filteredInsights[1].modulename,
                moduleid: null,
                modulename: null,
              });
            }
            for (var i = 1; i < filteredInsights.length - 1; i++) {
              if (
                filteredInsights[i].moduleid == filteredInsights[i + 1].moduleid
              )
                filteredLogsInsights.push(filteredInsights[i]);
              else {
                filteredLogsInsights.push(filteredInsights[i]);
                filteredLogsInsights.push({
                  featurename: filteredInsights[i + 1].modulename,
                  moduleid: null,
                  modulename: null,
                });
              }
            }
            if (filteredInsights.length > 1) {
              filteredLogsInsights.push(
                filteredInsights[filteredInsights.length - 1]
              );
            }
            self.filteredFeatureLogsInsights = filteredLogsInsights;
            self.nodata = false;
            self.dateRange = false;
            self.tableShow = true;
          } else {
            self.tableShow = false;
            self.dateRange = false;
            self.nodata = true;
          }
        });
      }
    },
  },

  watch: {

    dateFilters: function () {
      const diffTime = Math.abs(this.dateFilters[1] - this.dateFilters[0]);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays >= 45) {
        this.dateFilters = [
          moment().subtract(21, "days").startOf("day"),
          moment().startOf("day"),
        ];
        alert("Date difference must be less than 45 days");
      }
    },
  },
};
</script>


<style scoped>
.component-override {
  margin-top: -20px;
  margin-left: -5px;
}
.agency-central {
  margin-left: 10px;
  margin-right: 10px;
}
.filter-panel {
  border: 1px solid #cecdcd;
  background-color: white;
  padding: 10px 5px 0px 5px;
}
.result-panel {
  background-color: white;
  padding: 0px 5px 0px;
}
.date-picker-width {
  width: 100%;
}
.btn-color {
  background-color: #03629b;
}
.table-color {
  background-color: white;
}
::v-deep .ivu-table .demo-table-info-row td {
  background-color: lightgray;
  color: black;
  border: none;
}
::v-deep .ivu-table thead {
  display: none;
}
::v-deep .ivu-table td {
  height: 35px;
}
.table-title-col-modules {
  height: 65px;
  width: 402px;
  border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
}
.table-title-col-weeks {
  height: 65px;
  width: 150px;
  border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
}
.div-modules {
  width: 50%;
  text-align: center;
  padding: 4px;
  margin: 12px auto;
}
.div-weeks {
  width: 50%;
  text-align: center;
  padding: 4px;
  margin: 12px auto;
  font-size: 12px;
}
.row-filter {
  margin-left: 0.1px !important;
}
.gapheight {
  margin-top: 10px;
}
.text-center {
  text-align: center;
}
.error {
  background-color: #ffcccb;
  color: red;
}
.padding-bottom {
  padding-bottom: 5px;
}
.center {
  align-content: center;
}
.flag-switch {
  padding-top: 5px;
}
@media print {
  ::v-deep .ivu-table .demo-table-info-row td {
    background-color: lightgray !important;
    color: black !important;
    border: none !important;
  }
  ::v-deep .ivu-table td {
    height: 35px !important;
  }
  .table-title-col-modules {
    height: 65px !important;
    width: 402px !important;
    border-top: 1px solid lightgrey !important;
    border-left: 1px solid lightgrey !important;
    border-right: 1px solid lightgrey !important;
  }
  .table-title-col-weeks {
    height: 65px !important;
    width: 150px !important;
    border-top: 1px solid lightgrey !important;
    border-left: 1px solid lightgrey !important;
    border-right: 1px solid lightgrey !important;
  }
  .header-pua {
    display: none;
  }
}
</style>

<style src="../../../../App/Styles/dx-iview.css"></style>
