<template>
  <div>
    <div class="delete-btn" @click="$emit('removeAllFacets')">
      <i class="fa fa-trash"></i>
    </div>

    <div class="selected-preview">
      <div
        v-for="(attributeList, facet, i) of targetingObj.or"
        :key="i"
        class="preview-card"
      >
        <div style="display: flex; padding: 0 8px">
          <div>
            <span>{{ getUrnName(facet) }}</span
            >: &nbsp;
            <span>
              {{ attributeList.map(getUrnName).join(", ") }}
            </span>
          </div>
          <div
            style="margin-left: 8px; font-size: 16px; font-weight: bold; cursor: pointer;"
            @click="$emit('removeFacet', facet)"
          >
            &times;
          </div>
        </div>
      </div>
    </div>

    <div class="audience">
      <Form :label-width="60">
        <RadioGroup v-model="audienceType">
          <Radio :label="1">
            <span> Audience Attribute </span>
          </Radio>
          <Radio :label="2">
            <span> Custom Audience </span>
          </Radio>
        </RadioGroup>
        <div v-show="audienceType == 1">
        <Form-Item label="Category:">
          <Row>
            <Row-Col span="14" class="push-left">
              <Select v-model="inclusionCategory" filterable>
                <Option
                  v-for="(facet, i) in targetFacets"
                  :key="i"
                  :value="facet.urn"
                  >{{ facet.name }}</Option
                >
              </Select>
            </Row-Col></Row
          >
        </Form-Item>
        <div class="space"></div>
        <Form-Item label="Attribute:">
          <Row>
            <Row-Col span="14" class="push-left">
              <template
                v-if="(inclusionCategory || '').endsWith('yearsOfExperienceRanges')"
              >
                <Select
                  @on-change="exp => { $set(inclusionAttribute, 0, exp) }"
                  filterable
                >
                  <Option
                    v-for="(exp, index) in yearsOfExp"
                    :value="exp.urn"
                    :key="index"
                    >{{ exp.name }}
                  </Option>
                </Select>

                <span>up to</span>

                <Select
                  @on-change="exp => { $set(inclusionAttribute, 1, exp) }"
                  filterable
                >
                  <Option
                    v-for="(exp, index) in yearsOfExp"
                    :value="exp.urn"
                    :key="index"
                    >{{ exp.name }}
                  </Option>
                </Select>
              </template>

              <Select
                v-else-if="isGetAllFacet"
                v-model="inclusionAttribute"
                multiple
                filterable
                :disabled="inclusionCategory == null || loadingAttributes"
                :placeholder="
                  loadingAttributes
                    ? 'Fetching attributes...'
                    : 'Select attributes'
                "
              >
                <Option
                  v-for="(attribute, index) in attributes"
                  :value="attribute.urn"
                  :key="index"
                  >{{ attribute.name }}
                </Option>
              </Select>

              <Select
                v-else
                v-model="inclusionAttribute"
                multiple
                filterable
                placeholder="Search"
                :remote-method="getAttributesByQuery"
                :loading="loadingAttributes"
              >
                <Option
                  v-for="(attribute, index) in attributes"
                  :value="attribute.urn"
                  :key="index"
                  >{{ attribute.name }}
                </Option>
              </Select>

              <p v-if="fetchAttributesFailed" class="error-msg">
                Oops, an error occurred when searching
              </p>
            </Row-Col>
            <Row-Col style="float: right">
              <Button
                :disabled="!enableAddFacet"
                @click="emitAddFacet"
              >
                <i class="fa fa-plus"></i> Add
              </Button>
            </Row-Col>
          </Row>
        </Form-Item>
      </div>
      <div v-show="audienceType == 2">
        <Form-Item id="matchedAudience">
          <Row>
            <Row-Col span="20" class="push-left">
              <Select v-model="selectedMatchedAudiences" filterable multiple placeholder="Search Audience">
                <Option
                  v-for="(audience, i) in matchedAudiences"
                  :key="i"
                  :value="audience.id"
                  >{{ audience.name }}
                </Option>
              </Select>
            </Row-Col>
            <Row-Col style="float: right">
              <Button
                :disabled="(!this.selectedMatchedAudiences || !this.selectedMatchedAudiences.length)"
                @click="addMatchedAudience"
              >
                <i class="fa fa-plus"></i> Add
              </Button>
            </Row-Col>
          </Row>
        </Form-Item>
      </div>
      </Form>
    </div>
  </div>
</template>

<script>
import LinkedInTargetFacets from "../../Constants/LinkedInTargetFacets.js";
import { APIService } from "../../ApiService.js";

import {
  Row,
  Col,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Option,
  Button
} from "iview";

export default {
  name: "TargetFacetSelection",
  components: {
    Row,
    "Row-Col": Col,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Select,
    Option,
    Button
  },
  props: {
    targetingObj: Object,
    bpseId: Number,
    urnNameMap: Object,
    isInclusion: Boolean
  },
  data () {
    return {
      inclusionCategory: null,
      inclusionAttribute: [],
      audienceType: 1,
      // some facets can't be used for exclusion (includeOnly = true)
      targetFacets: LinkedInTargetFacets.filter(x => this.isInclusion || !x.includeOnly),
      attributes: [],
      loadingAttributes: false,
      fetchAttributesFailed: false,
      yearsOfExp: [],
      enableAddFacet: false,
      matchedAudiences: [],
      selectedMatchedAudiences: [],
      selectedMatchedAudienceIds: [],
      selectedDynamicMatchedAudienceIds: [],
      selectedAudienceNameMapper: {}
    };
  },
  created () {
    for (let i = 1; i < 13; i++) {
      this.yearsOfExp.push({
        urn: `urn:li:yearsOfExperience:${i}`,
        name: (i == 12 ? "12+" : i) + (i == 1 ? " year" : " years")
      });
    }
  },
  computed: {
    isGetAllFacet () {
      let facet = this.targetFacets.find(x => x.urn == this.inclusionCategory);
      return this.inclusionCategory == null || facet.entityFinder == "GetAll";
    }
  },
  watch: {
    inclusionCategory () {
      let facet = this.targetFacets.find(x => x.urn == this.inclusionCategory);

      if (facet && facet.entityFinder == "GetAll") {
        this.getAttributes(this.inclusionCategory, null);
      } else {
        this.attributes = [];
      }
    },
    inclusionAttribute: {
      deep: true,
      handler () {
        this.enableAddFacet = false;
        if (!this.inclusionCategory) {
          return;
        }

        if (this.inclusionCategory.endsWith("yearsOfExperienceRanges")) {
          let expStart = this.inclusionAttribute[0];
          let expEnd = this.inclusionAttribute[1];
          if (expStart && expEnd && expStart < expEnd) {
            this.enableAddFacet = true;
          }
        } else if (
          Array.isArray(this.inclusionAttribute) &&
          this.inclusionAttribute.length > 0
        ) {
          this.enableAddFacet = true;
        }
      }
    },
    "audienceType": function (val) {
      if (val == 2 && (!this.matchedAudiences || !this.matchedAudiences.length)) {
        this.getMatchedAudiences();
      }
    },
    "selectedMatchedAudiences": function (val) {
      if (val) {
        this.selectedMatchedAudienceIds = [];
        this.selectedDynamicMatchedAudienceIds = [];
        var selectedAudienceObjects = this.matchedAudiences.filter(audience => val.includes(audience.id));
        var validAudiences = selectedAudienceObjects.map(selectedAudienceObject => ({ "type": selectedAudienceObject.type, "matchedAudiences": selectedAudienceObject.destinations.filter(destination => destination.status == "READY" || destination.status == "ARCHIEVED") }))
        validAudiences.forEach(validAudienceObject => {
          if (validAudienceObject.type == "USER") {
            validAudienceObject.matchedAudiences.map(audience => {
              this.selectedDynamicMatchedAudienceIds.push(audience.destinationSegmentId)
            })
          } else {
            validAudienceObject.matchedAudiences.map(audience => {
              this.selectedMatchedAudienceIds.push(audience.destinationSegmentId)
            });
          }
          validAudienceObject.matchedAudiences.map(audience => {
            this.selectedAudienceNameMapper[audience.destinationSegmentId] = selectedAudienceObjects.find(audienceObject =>
              audienceObject.destinations.find(destination => destination.destinationSegmentId == audience.destinationSegmentId)).name
          })
        })
      }
    },
    "bpseId": function () {
      this.getMatchedAudiences();
    }
  },
  methods: {
    getAttributes (facetUrn, query) {
      this.loadingAttributes = true;
      this.fetchAttributesFailed = false;
      this.attributes = [];
      APIService.getLinkedInTargetEntities(this.bpseId, facetUrn, query)
        .then(response => {
          this.attributes = response.data.data;
        })
        .catch(error => {
          console.error(error);
          this.fetchAttributesFailed = true;
        })
        .finally(() => {
          setTimeout(() => { this.loadingAttributes = false; })
        });
    },
    getAttributesByQuery (query) {
      this.getAttributes(this.inclusionCategory, query);
    },
    getUrnName (urnId) {
      return this.urnNameMap[urnId];
    },
    emitAddFacet () {
      if (!this.inclusionCategory) { return }

      this.$emit("addFacet", {
        category: this.inclusionCategory,
        attribute: this.inclusionAttribute,
        urnNameMap: this.inclusionAttribute.reduce((dict, urn) => {
          let facet = this.attributes.find(x => x.urn == urn);
          dict[urn] = (facet && facet.name) || urn;
          return dict;
        }, {})
      });
      // reset fields after adding new facet
      this.inclusionCategory = null;
      this.inclusionAttribute = [];
      this.attributes = [];
    },
    getMatchedAudiences () {
      this.matchedAudiences = [];
      APIService.getLinkedinMatchedAudiences(this.bpseId)
        .then(response => {
          this.matchedAudiences = response.data.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    addMatchedAudience () {
      if (this.selectedDynamicMatchedAudienceIds.length) {
        this.$emit("addFacet", {
          category: "urn:li:adTargetingFacet:dynamicSegments",
          attribute: this.selectedDynamicMatchedAudienceIds,
          urnNameMap: this.selectedDynamicMatchedAudienceIds.reduce((dict, urn) => {
            dict[urn] = this.selectedAudienceNameMapper[urn]
            return dict;
          }, {})
        });
        this.selectedDynamicMatchedAudienceIds = [];
      }
      if (this.selectedMatchedAudienceIds.length) {
        this.$emit("addFacet", {
          category: "urn:li:adTargetingFacet:audienceMatchingSegments",
          attribute: this.selectedMatchedAudienceIds,
          urnNameMap: this.selectedMatchedAudienceIds.reduce((dict, urn) => {
            dict[urn] = this.selectedAudienceNameMapper[urn]
            return dict;
          }, {})
        });
        this.selectedMatchedAudienceIds = [];
      }
      this.selectedMatchedAudiences = [];
    }
  }
};
</script>

<style scoped>
.selected-preview {
  display: flex;
  flex-wrap: wrap;
}
.preview-card {
  border: 1px solid #ddd;
  margin: 0 10px 10px 0;
  background-color: white;
}
.delete-btn {
  cursor: pointer;
  float: right;
  margin: 0 10px;
}
.audience {
  border: 1px solid #ddd;
  padding: 10px;
  background: white;
}
.error-msg {
  color: #b94a48;
  margin-bottom: 0px;
}
::v-deep #matchedAudience > div {
  padding: 0px;
}
</style>
