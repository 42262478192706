<template>
  <div>
    <button class="btn btn-success btn-sm" v-on:click="openCampaignConfigModal()">
      <i class="fa fa-plus"></i>
    </button>
    <Modal
      v-model="showModal"
      width="75%"
      :scrollable="true"
      :styles="{top: '20px'}"
      :mask-closable="false"
    >
      <h3 class="ccHeader" slot="header">
          Create a Product Cluster
      </h3>
    <div>
      <Row type="flex" align="middle">
        <Row-Col span="6" class="label-text">
          <label>Cluster Mode<span class="red">*</span></label>
        </Row-Col>
        <Row-Col span="6">
          <RadioGroup align="middle" v-model="clusterMode">
            <Radio label="New" class="radio-button"></Radio>
            <Radio label="Edit Existing" ></Radio>
          </RadioGroup>
        </Row-Col>
      </Row>
      <Row type="flex" align="middle">
        <Row-Col span="6" class="select-cluster-dropdown">
          <Select
            ref="filter"
            v-model = selectedCluster
            filterable
            :placeholder="clusterDropdownPlaceholder">
              <OptionGroup label = "Exported">
                <Option
                  v-for="(cluster, index) in exportedClusters"
                  :key="index"
                  :value="cluster.id"
                  >{{ cluster.name }}</Option>
              </OptionGroup>
              <OptionGroup label = "Non-Exported">
                <Option
                  v-for="(cluster, index) in nonExportedClusters"
                  :key="index"
                  :value="cluster.id"
                  >{{ cluster.name }}</Option>
              </OptionGroup>
            </Select>
          </Row-Col>
        </Row>
      <div v-show="showForm">
        <Divider orientation="left">
          Cluster Details
        </Divider>
        <Row type="flex" align="middle">
          <Row-Col span="6" class="label-text">
             <label>Cluster Name<span class="red">*</span></label>
          </Row-Col>
          <Row-Col span="17">
            <i-input :disabled="isEditMode && CheckTargetStatus()" v-model="name"/>
          </Row-Col>
        </Row>
        <Row type="flex" align="middle">
          <Row-Col span="6" class="label-text">
          </Row-Col>
          <Row-Col span="17">
            <span style='color:#b94a48'>{{ nameErrorMessage }}</span>
          </Row-Col>
        </Row>
        <br/>
        <Row type="flex" align="middle">
          <Row-Col span="6" class="label-text">
             <label>ASINs to Advertise<span class="red">*</span></label>
          </Row-Col>
          <Row-Col span="17">
            <i-input @on-keydown="validateAsin($event)" v-model="asinsToAdvertise"/>
          </Row-Col>
        </Row>
        <Row type="flex" align="middle">
          <Row-Col span="6" class="label-text">
          </Row-Col>
          <Row-Col span="17">
            <span style='color:#b94a48'>{{ asinsToAdvertiseErrorMessage }}</span>
          </Row-Col>
        </Row>
       <br/>
        <Row type="flex" align="middle">
          <Row-Col span="6" class="label-text">
             <label>TargetingType<span class="red">*</span></label>
          </Row-Col>
          <Row-Col span="17">
            <CheckboxGroup v-model="targeting">
              <Checkbox :label="parseInt(id)" v-for="(name, id) in amsTargetingType" :key="parseInt(id)" :disabled = "isEditMode">
                <span> {{ name }} </span>
              </Checkbox>
            </CheckboxGroup>
          </Row-Col>
        </Row>
        <Row type="flex" align="middle">
          <Row-Col span="6" class="label-text">
          </Row-Col>
          <Row-Col span="17">
            <span style='color:#b94a48'>{{ targettingErrorMessage }}</span>
          </Row-Col>
        </Row>
       <br/>
      </div>
    </div>
      <div slot="footer">
        <button type="button" class="btn btn-white" style="margin-bottom:0px" v-on:click="Close()">Close</button>
        <button type="button" class="btn btn-success" v-on:click="Save()">Save</button>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  Row,
  Col,
  Modal,
  Input,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Divider,
  Select,
  Option,
  OptionGroup
} from "iview";
import { APIService } from "./../ApiService.js";
var targetingType = {
  "KeywordTargeting": 1,
  "ProductTargeting": 2
}

export default {
  components: {
    Row,
    "Row-Col": Col,
    Modal,
    CheckboxGroup,
    'i-input': Input,
    Checkbox,
    RadioGroup,
    Radio,
    Divider,
    Select,
    Option,
    OptionGroup
  },
  async mounted () {
    this.openConfigurationModal = this.openConfigurationModalProp
    this.getClusterData();
  },
  data () {
    return {
      deltax: deltax,
      openConfigurationModal: false,
      showModal: false,
      name: '',
      asinsToAdvertise: '',
      targeting: [1],
      nameErrorMessage: '',
      asinsToAdvertiseErrorMessage: '',
      targettingErrorMessage: '',
      clusterDetails: [],
      exportedClusters: [],
      nonExportedClusters: [],
      clusterMode: "New",
      selectedCluster: '',
      isEditMode: false,
      showForm: true,
      amsTargetingType: {
        2: "Product Targeting",
        1: "Keyword Targeting"
      }
    }
  },
  computed: {
    clusterDropdownPlaceholder () {
      return this.clusterMode == "New"
        ? "Load from existing"
        : "Select a cluster";
    }
  },
  methods: {
    openCampaignConfigModal () {
      this.showModal = true;
    },
    async getClusterData () {
      await APIService.getTargetsCluster(1)
        .then(response => {
          this.clusterDetails = response;
        });
      this.exportedClusters = this.clusterDetails.filter(x => x.isTargetExported == 1);
      this.nonExportedClusters = this.clusterDetails.filter(x => x.isTargetExported == 0);
    },
    Save () {
      var isValid = this.ValidateFields();
      var productClusterObj = {
        Name: this.name,
        AsinsToAdvertise: this.asinsToAdvertise,
        TargetingType: this.targeting.includes(1) && this.targeting.includes(2) ? 3 : this.targeting[0]
      };
      if (isValid && this.clusterMode == "New") {
        this.showModal = false;
        APIService.saveAmsAsinTarget(productClusterObj)
          .then(response => {
            var successMessage = "Product Cluster added successfully";
            this.ResetFields();
            this.$store.commit("set_isRefreshAmsTargets", true);
            this.$emit("ClusterAdded", successMessage);
          })
          .catch((x) => {
            var failureMessage =
                  "Failed to save your Cluster. Please contact support for further assistance.";
            this.$emit("ClusterFailed", failureMessage);
          })
      }
      if (isValid && this.clusterMode == "Edit Existing") {
        let cluster = this.clusterDetails.find(x => x.id == this.selectedCluster);
        productClusterObj.Id = cluster.id;
        productClusterObj.AsinstoTarget = cluster.asinsToTarget;
        productClusterObj.CreationType = cluster.creationType;
        productClusterObj.NegativeKeywords = cluster.negativeKeywords;
        productClusterObj.Type = cluster.type;
        productClusterObj.BusinessProfilesSearchEngineId = cluster.businessProfilesSearchEngineId;
        this.showModal = false;
        APIService.updateAmsAsinTarget(productClusterObj)
          .then(response => {
            var successMessage = "Product Cluster updated successfully";
            this.ResetFields();
            this.$emit("ClusterAdded", successMessage);
            this.getClusterData();
            this.$store.commit("set_isRefreshEntitiesData", true);
          })
          .catch((x) => {
            var failureMessage =
                  "Failed to update your Cluster. Please contact support for further assistance.";
            this.$emit("ClusterFailed", failureMessage);
          })
      }
    },
    ResetFields () {
      this.name = '';
      this.asinsToAdvertise = '';
      this.targeting = [1];
      this.nameErrorMessage = '';
      this.asinsToAdvertiseErrorMessage = '';
      this.targettingErrorMessage = '';
      this.selectedCluster = '';
      this.$refs['filter'].reset();
    },
    Close () {
      this.showModal = false;
      this.clusterMode = "New";
      this.ResetFields();
    },
    ValidateFields () {
      this.nameErrorMessage = this.name == '' ? "Cluster Name cannot be empty" : '';
      if (this.clusterMode == "New") {
        this.nameErrorMessage = this.clusterDetails.filter(x => x.name == this.name).length > 0 ? "Cluster with this name already exists" : '';
      }
      this.targettingErrorMessage = this.targeting.length <= 0 ? "Select at least one targeting type" : '';
      const regex = /^([a-zA-Z0-9]?,?)+$/;
      const alphaNumericValidateRegex = /^((?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+,?)+$/;
      if (!alphaNumericValidateRegex.test(this.asinsToAdvertise) || this.asinsToAdvertise == "") {
        if (!regex.test(this.asinsToAdvertise)) {
          this.asinsToAdvertiseErrorMessage = "ASINs To Advertise cannot have special characters"
        } else {
          this.asinsToAdvertiseErrorMessage = this.asinsToAdvertise == "" ? "ASINs to Advertise cannot be empty" : this.asinsToAdvertise.length != 10 ? "ASINs To Advertise must be 10 digit alphanumeric" : "ASINs To Advertise must be alphanumeric";
        }
      } else {
        let duplicateAsins = this.checkDuplicates(this.asinsToAdvertise);
        this.asinsToAdvertiseErrorMessage = '';
        if (duplicateAsins.length > 0) {
          this.asinsToAdvertiseErrorMessage =
            "Enter unique ASINs";
          this.IsValidFields = false;
        }
      }

      if (this.nameErrorMessage != '' || this.targettingErrorMessage != '' || this.asinsToAdvertiseErrorMessage != '') {
        return false;
      } else {
        return true;
      }
    },
    checkDuplicates (asinsToAdvertise) {
      var duplicateAsins = "";
      if (asinsToAdvertise && asinsToAdvertise.length > 1) {
        var asins = asinsToAdvertise.split(",");
        var uniqueValues = [];
        for (var i = 0; i < asins.length; i++) {
          if (uniqueValues.indexOf(asins[i]) == -1) {
            uniqueValues.push(asins[i]);
          } else {
            duplicateAsins = duplicateAsins + asins[i] + ",";
          }
        }
        return duplicateAsins;
      }
    },
    CheckTargetStatus () {
      if (this.selectedCluster === null || this.selectedCluster === undefined || this.selectedCluster === '') {
        return false;
      }
      if (this.selectedCluster !== null && this.selectedCluster !== undefined && this.selectedCluster !== '') {
        let cluster = this.clusterDetails.find(x => x.id == this.selectedCluster);
        if (cluster.isTargetExported == 1) {
          return true;
        } return false;
      }
    },
    validateAsin (e) {
      let cluster = this.clusterDetails.find(x => x.id == this.selectedCluster);
      if (cluster !== null && cluster !== undefined) {
        if (cluster.asinsToAdvertise.length == this.asinsToAdvertise.length) {
          if (this.clusterMode == "Edit Existing" && cluster.isTargetExported == 1 && e.key == "Backspace") {
            e.preventDefault();
            this.asinsToAdvertiseErrorMessage = "Cannot remove already Exported ASIN";
          } else {
            this.asinsToAdvertiseErrorMessage = "";
          }
        }
      }
    }
  },
  watch: {
    openConfigurationModalProp () {
      this.openConfigurationModal = this.openConfigurationModalProp;
    },
    selectedCluster () {
      if (this.isEditMode && this.selectedCluster !== null && this.selectedCluster !== undefined && this.selectedCluster !== '') {
        this.showForm = true;
        let cluster = this.clusterDetails.find(x => x.id == this.selectedCluster)
        this.name = cluster.name;
        this.asinsToAdvertise = cluster.asinsToAdvertise;
        this.asinsToAdvertiseErrorMessage = "";
        if (cluster.targetingType == targetingType.KeywordTargeting) {
          this.targeting = [1];
        } else if (cluster.targetingType == targetingType.ProductTargeting) {
          this.targeting = [2];
        } else {
          this.targeting = [1, 2];
        }
      } else if (!this.isEditMode && this.selectedCluster !== null && this.selectedCluster !== undefined && this.selectedCluster !== '') {
        let cluster = this.clusterDetails.find(x => x.id == this.selectedCluster)
        this.name = cluster.name;
        this.asinsToAdvertise = cluster.asinsToAdvertise;
        this.asinsToAdvertiseErrorMessage = "";
        if (cluster.targetingType == targetingType.KeywordTargeting) {
          this.targeting = [1];
        } else if (cluster.targetingType == targetingType.ProductTargeting) {
          this.targeting = [2];
        } else {
          this.targeting = [1, 2];
        }
      }
    },
    clusterMode () {
      this.ResetFields();
      if (this.clusterMode == "New") {
        this.isEditMode = false;
      } else {
        this.isEditMode = true;
      }
      if (this.isEditMode && (this.selectedCluster === null || this.selectedCluster === undefined || this.selectedCluster === '')) {
        this.showForm = false;
      } else {
        this.showForm = true
      }
    }
  }
}
</script>
<style scoped>
.label-text {
  margin-right: 2%;
  text-align: end;
}
.select-cluster-dropdown {
  margin-left: 27%;
  margin-top: 1%;
}
.radio-button {
  margin-right: 20px;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
