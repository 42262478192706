<template>
    <div>
        <i-modal v-model="showModal" class-name="modal_placement" :closable="false" title = "Confirm">
            <div class="body" style="text-align: start;">
                Are you sure you want to delete the user?
            </div>
            <div slot="footer">
              <button class="btn btn-white" @click="closeModal()">Cancel</button>
              <button class="btn btn-success" @click.prevent="onRemove(rowDataProp.id)"><span v-if="loader"><i class="fa-spinner fa-spin" aria-hidden="true"></i></span><span v-if="!loader">Yes</span></button>
            </div>
        </i-modal>
    </div>
</template>

<script>
import { Modal, locale } from "iview";
import { ApiService } from ".././Services/ApiServices.js";
import lang from "iview/dist/locale/en-US";
locale(lang);
export default {
  components: {
    "i-modal": Modal
  },
  props: {
    showRemoveUserPopupProp: Boolean,
    rowDataProp: Object
  },
  data () {
    return {
      showModal: false,
      loader: false
    };
  },
  watch: {
    showRemoveUserPopupProp () {
      if (this.showRemoveUserPopupProp) {
        this.showModal = this.showRemoveUserPopupProp;
      }
    },
    showModal () {
      if (!this.showModal) {
        this.$emit("CloseRemoveUserModal");
      }
    }
  },
  methods: {
    closeModal () {
      this.showModal = false;
    },
    onRemove (id) {
      this.apiService = new ApiService();
      this.loader = true;
      var data = [];
      this.apiService.RemoveUser(id).then((result) => {
        var businessProfiles = this.rowDataProp.businessProfiles;
        businessProfiles.forEach(bp => {
          var obj = {
            "businessProfileId": bp.id,
            "role": bp.roleId,
            "operation": 2
          }
          data.push(obj);
        });
        this.apiService.UpdateUserAccess(this.rowDataProp.id, data);
        this.loader = false;
        this.closeModal();
        this.RemoveSuccessful("User Deleted successfully");
      }).catch(() => {
        this.loader = false;
        this.closeModal();
        this.RemoveFailed("Failed to delete the user.");
      });
    },
    RemoveSuccessful (message) {
      this.$emit("RemoveSuccessful", message);
    },
    RemoveFailed (message) {
      this.$emit("RemoveFailed", message);
    }
  }
}
</script>

<style scoped>
.btn {
  margin-bottom: 0;
}
::v-deep  .modal_placement{
    display: flex;
    align-items: center;
    justify-content: center;
}
::v-deep .ivu-modal{
    top: 0;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
