<template>
  <div id ="campaign-launcher" class="elements">
    {{ allVerified }}
    <div v-if="budgetTypePivot.campaignCount > 0" class="budget-type-pivot">
      <div class="title-sticky">
        <label class="element-label">Campaign Setup</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ budgetTypeVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="budgetTypeVerified" />
          </label>
        </span>
      </div>
      <div class="budget-type-table">
        <table class="table perf">
          <thead>
            <tr>
              <th
                v-for="item in twitterEnums.budgetTypeColumns"
                :key="item"
                class="table-header"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
         <tbody v-for="(record, i) in budgetTypePivot.records" :key="i">
            <tr>
              <td class="data-center">{{ record.name }}</td>
              <td class="data-center">{{ record.status }}</td>
              <td class="data-center">{{ record.objective }}</td>
              <td class="data-center">Daily</td>
              <td class="data-center">{{ record.bpCurrency +" "+ record.lifetimeBudget + " ( " + record.bpCurrency +" "+ record.dailyBudget + " per day )"}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="targetsPivot.targetInfo.length > 0" class="targets-pivot">
      <div class="title-sticky">
        <label class="element-label">Targeting</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{targetsVerified ? "Verified" : "Check to verify"}}
            <input
              type="checkbox"
              v-model="targetsVerified"
            />
          </label>
        </span>
      </div>
      <div class="targets-table">
        <table class="table perf">
          <thead>
            <tr>
              <th
                v-for="item in twitterEnums.targetingColumns"
                :key="item"
              >{{ item }}</th>
            </tr>
          </thead>
          <tbody v-for="(targetRow,i1) in targetsPivot.targetInfo" :key="i1">
            <tr>
              <td>
                <div>
                  <label class="target-label">
                      {{targetRow.name}}
                  </label>
                    <!-- <Tooltip max-width="1000" placement="right-start" class="target-tooltip">
                      <Icon type="md-alert"/>
                      <div slot="content">
                        <div v-html="targetRow.bidsGroup[0].targetHTML"></div>
                      </div>
                    </Tooltip>
                    <div class="target-sub-label">
                    <i>{{targetRow.bidsGroup[0].targetReach | reach(bidRow)}}</i>
                  </div>
                  <div class="target-sub-label">
                    <i>{{targetRow.bidsGroup[0].targetData}}</i>
                  </div> -->
                </div>
              </td>
              <!-- <td class="data-right">{{bidRow.bid | currency(bpCurrency)}}</td> -->
              <td class="data-right">
                {{targetRow.adgroups}}
                <span class="no-of-ads">({{targetRow.ads}} ads)</span>
              </td>
            </tr>
          </tbody>
          <tfoot class="total-footer">
            <tr>
              <td colspan="1" class="table-footer-label data-right">Total</td>
              <td class="table-footer-label data-right">
                {{targetsPivot.adGroups}}
                <span class="no-of-ads">({{targetsPivot.ads}} ads)</span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div v-if="locationPivot.locationInfo.length > 0" class="locations-pivot">
      <div class="title-sticky">
        <label class="element-label">Locations</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{locationsVerified ? "Verified" : "Check to verify"}}
            <input
              type="checkbox"
              v-model="locationsVerified"
            />
          </label>
        </span>
      </div>
      <div class="locations-table">
        <table class="table perf">
          <thead>
            <tr>
              <th
                v-for="item in twitterEnums.locationsColumns"
                :key="item"
              >{{ item }}</th>
            </tr>
          </thead>
          <tbody v-for="(locationRow,i1) in locationPivot.locationInfo" :key="i1">
            <tr>
              <td>
                <div>
                  <label class="target-label">
                      {{locationRow.location}}
                  </label>
                </div>
              </td>
              <td class="data-right">
                {{locationRow.adgroups}}
                <span class="no-of-ads">({{locationRow.ads}} ads)</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="adsPivot.ads.length > 0" class="targets-pivot">
      <div class="title-sticky">
        <label class="element-label">Account and Creatives</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{pagesAndCreativeVerified ? "Verified" : "Check to verify"}}
            <input
              type="checkbox"
              v-model="pagesAndCreativeVerified"
            />
          </label>
        </span>
      </div>
      <div v-if="adsPivot.account" class="page-label">{{adsPivot.account.accountNickName}}({{adsPivot.account.accountId}})</div>
      <div class="targets-table">
        <table class="table perf">
          <thead>
            <tr>
              <th
                v-for="item in pagesAndCreativeColumn"
                :key="item"
              >{{ item }}</th>
            </tr>
          </thead>
          <tbody v-for="(adRow,i1) in adsPivot.ads" :key="i1">
            <tr>
              <td>
                <div>
                  <label class="ad-label">
                      {{adRow.adCopyId}}
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="ad-label">
                      {{adRow.creative}}
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="ad-label">
                      {{getCommaSeperatedtargetName(adRow.target)}}
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="placementPivot.placementRows.length > 0" class="targets-pivot">
      <div class="title-sticky">
        <label class="element-label">Placements</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{placementsVerified ? "Verified" : "Check to verify"}}
            <input
              type="checkbox"
              v-model="placementsVerified"
            />
          </label>
        </span>
      </div>
      <div class="placement-table">
        <table class="table perf">
          <thead>
            <tr>
              <th
                v-for="item in placementColumns"
                :key="item"
              >{{ item }}</th>
            </tr>
          </thead>
          <tbody v-for="(placements,i1) in placementPivot.placementRows" :key="i1">
            <tr>
              <td>
                <div>
                  <label class="ad-label">
                      {{placements.home}}
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="ad-label">
                      {{placements.profiles}}
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="ad-label">
                      {{placements.searchResults}}
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="ad-label">
                      {{placements.tap}}
                  </label>
                </div>
              </td>
              <td class="data-right">
                {{placements.adgroups}}
                <span class="no-of-ads">({{placements.ads}} ads)</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { locale } from "iview";
import lang from "iview/dist/locale/en-US";
import { mapState, mapMutations } from "vuex";
import { twitterEnums } from "../../../Constants/TwitterEnums.js";
import ApiService from '../ApiService.js';

locale(lang); // iview is default chinese

export default {
  mounted: function () {},
  data: function () {
    return {
      budgetTypeVerified: false,
      targetsVerified: false,
      locationsVerified: false,
      placementsVerified: false,
      pagesAndCreativeVerified: false,
      twitterEnums,
      targetingColumns: ["Target Name", "No. of Campaigns"],
      adFormatColumn: ["Ad Format", "No. of Campaigns"],
      pagesAndCreativeColumn: ["Ad Copy Id", "Creative", "Targets"],
      placementColumns: ["Home", "Profiles", "Search Results", "Twitter Audience Platform", "No. of Adgroups"]
    };
  },
  computed: {
    ...mapState([
      "twitterEntitiesResponse",
      "selectedPublisher",
      "publisher",
      "savedTargetsMapperByBpseId",
      "businessProfilesSearchEngineId",
      "campaignLauncherConfigBpseIdMapper",
      "selectedCampaignLauncherConfigId",
      "savedTargets",
      "twitterAccounts",
      "bpCurrencySymbol",
      "isCampaignLauncherVerificationEnabled",
      "existingTweets"
    ]),
    budgetTypePivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.budgetTypeVerified = false;
      }
      const twitterEntities = this.twitterEntitiesResponse.data.childEntities;
      if (!twitterEntities.length) {
        this.budgetTypeVerified = true;
        return { records: [], campaignCount: 0 };
      }
      let records = twitterEntities.map(entity => {
        return {
          name: entity.name,
          status: entity.rowData.campaignStatus,
          objective: this.twitterEnums.objectiveEnums[entity.rowData.objective],
          budgetType: entity.rowData.budgetTypeName,
          dailyBudget: entity.rowData.dailyBudget,
          lifetimeBudget: entity.rowData.lifetimeBudget,
          bpCurrency: entity.additionalData.bp_currency ? entity.additionalData.bp_currency : this.bpCurrencySymbol
        }
      });
      return { records: records, campaignCount: records.length };
    },
    targetsPivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.targetsVerified = false;
      }
      const twitterEntities = this.twitterEntitiesResponse.data.childEntities;
      const targetIds = []
      var targetingInformation = [];
      var self = this;
      twitterEntities.forEach(campaigns => {
        campaigns.childEntities.forEach(campaign => {
          if (targetIds.includes(campaign.rowData.deltaXTargetId)) {
            var targetObject = targetingInformation.find(o => o.targetId === campaign.rowData.deltaXTargetId);
            targetObject.adCount += campaign.childEntities ? campaign.childEntities.length : 0;
          } else if (!targetIds.includes(campaign.rowData.deltaXTargetId)) {
            targetIds.push(campaign.rowData.deltaXTargetId)
            targetingInformation.push({
              targetId: campaign.rowData.deltaXTargetId,
              ads: campaign.childEntities ? campaign.childEntities.length : 0,
              adgroups: 1,
              name: self.savedTargets.find(x => x.id === campaign.rowData.deltaXTargetId).name
            })
          }
        })
      })
      if (!targetingInformation.length) {
        this.targetsVerified = true;
      }
      var totalAds = targetingInformation.reduce((accum, item) => accum + item.ads, 0)
      var totalAdGroups = targetingInformation.reduce((accum, item) => accum + item.adgroups, 0)
      return { targetInfo: targetingInformation, adGroups: totalAdGroups, ads: totalAds };
    },
    locationPivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.locationsVerified = false;
      }
      const twitterEntities = this.twitterEntitiesResponse.data.childEntities;
      var locationAdsData = [];
      twitterEntities.forEach(campaign => {
        campaign.childEntities.forEach(adgroup => {
          var targetStringObject = JSON.parse(adgroup.apiData.targetString)
          targetStringObject.locations.forEach(location => {
            var currentLocation = locationAdsData.find(x => x.id == location.id)
            if (currentLocation) {
              currentLocation.adgroups++;
              currentLocation.ads += adgroup.childEntities ? adgroup.childEntities.length : 0
            } else {
              locationAdsData.push({
                id: location.id,
                location: location.name + " - " + location.country_code,
                adgroups: 1,
                ads: adgroup.childEntities ? adgroup.childEntities.length : 0
              })
            }
          });
        })
      })
      if (!locationAdsData.length) {
        this.locationsVerified = true;
      }
      return { locationInfo: locationAdsData };
    },
    placementPivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.placementsVerified = false;
      }
      const campaigns = this.twitterEntitiesResponse.data.childEntities;
      var placementDetails = []
      campaigns.forEach(campaign => {
        campaign.childEntities.forEach(adgroup => {
          var data = {
            placements: campaign.rowData.placements,
            adgroups: 1,
            ads: adgroup.childEntities ? adgroup.childEntities.length : 0
          }
          placementDetails.push(data)
        })
      })

      var uniquePlacements = {}
      placementDetails.forEach(el => {
        var placementValues = el.placements.sort().join()
        if (!uniquePlacements[placementValues]) {
          uniquePlacements[placementValues] = {
            ads: el.ads,
            adgroups: el.adgroups,
            home: el.placements.includes(this.twitterEnums.placementOption.Home) ? this.twitterEnums.placementStatus.Enabled : this.twitterEnums.placementStatus.Disabled,
            profiles: el.placements.includes(this.twitterEnums.placementOption.Profile) ? this.twitterEnums.placementStatus.Enabled : this.twitterEnums.placementStatus.Disabled,
            searchResults: el.placements.includes(this.twitterEnums.placementOption.SearchResult) ? this.twitterEnums.placementStatus.Enabled : this.twitterEnums.placementStatus.Disabled,
            tap: el.placements.includes(this.twitterEnums.placementOption.TwitterAudiencePlatform) ? this.twitterEnums.placementStatus.Enabled : this.twitterEnums.placementStatus.Disabled
          }
        } else {
          uniquePlacements[placementValues].adgroups += el.adgroups
          uniquePlacements[placementValues].ads += el.ads
        }
      })
      var rows = []
      for (var key in uniquePlacements) {
        rows.push(uniquePlacements[key])
      }
      if (!rows.length) {
        this.placementsVerified = true;
      }
      return { placementRows: rows };
    },
    adsPivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.pagesAndCreativeVerified = false;
      }
      const enitites = this.twitterEntitiesResponse.data.childEntities;
      var ads = []
      var uniqueAdCopyIds = []
      var self = this
      enitites.forEach(campaigns => {
        campaigns.childEntities.forEach(campaign => {
          if (campaign.childEntities) {
            campaign.childEntities.forEach(ad => {
              if (!uniqueAdCopyIds.includes(ad.rowData.adCopyId)) {
                uniqueAdCopyIds.push(ad.rowData.adCopyId)
                var creativeData = {
                  adCopyId: ad.rowData.adCopyId,
                  creative: ad.rowData.tweetText,
                  target: [self.savedTargets.find(x => x.id === campaign.rowData.deltaXTargetId)]
                }
                ads.push(creativeData);
              } else if (uniqueAdCopyIds.includes(ad.rowData.adCopyId)) {
                ads.find(x => x.adCopyId === ad.rowData.adCopyId).target.push(self.savedTargets.find(x => x.id === campaign.rowData.deltaXTargetId))
              }
            })
          }
        })
      })
      if (!ads.length) {
        this.pagesAndCreativeVerified = true;
      }
      var account = this.twitterAccounts.find(x => x.accountId === enitites[0].accountId);
      return { ads: ads, account };
    },
    allVerified: function () {
      this.$emit("elementsVerified", [
        { value: this.budgetTypeVerified, label: "Campaign Setup" },
        { value: this.targetsVerified, label: "Targeting" },
        { value: this.locationsVerified, label: "Locations" },
        { value: this.placementsVerified, label: "Placements" },
        { value: this.pagesAndCreativeVerified, label: "Page & Creative" }
      ]);
      return "";
    }
  },
  created: function () {
    if (this.isCampaignLauncherVerificationEnabled == false) {
      this.budgetTypeVerified = true;
      this.targetsVerified = true;
      this.locationsVerified = true;
      this.placementsVerified = true;
      this.pagesAndCreativeVerified = true;
    }
  },
  methods: {
    ...mapMutations(["set_isCampaignLauncherVerificationEnabled"]),
    getCommaSeperatedtargetName (targetsList) {
      return Array.prototype.map.call(targetsList, function (target) { return target.name; }).join(",");
    }
  }
};
</script>

<style scoped>
.title-sticky {
  padding: 0px 5px;
  top: 100px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
}
.verified-action {
  float: right;
  padding: 10px;
}
.checkbox-status-label {
  padding-right: 5px;
  color: #999;
  cursor: pointer;
}
input[type="checkbox"] {
  vertical-align: text-bottom;
}
.total-footer {
  font-weight: 600;
  background-color: #eee;
}
.table-footer-label {
  border: 1px solid #ddd;
  padding: 0px 5px;
}
.target-label {
  padding-top: 10px;
}
.target-sub-label {
  padding-top: 10px;
  font-size: 0.9em;
  color: #979898;
  max-width: 420px;
}
.page-label {
  padding: 10px 5px;
  top: 140px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.creatives-li {
  padding-bottom: 5px;
  list-style: none;
}
.more-previews {
  font-size: 1.3em;
}
.data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
.upper-case {
  text-transform: uppercase;
}
::v-deep .ivu-modal-footer {
  display: none;
}
.table-header{
  border:1px solid #ddd !important;
  padding:10px !important;
}
</style>
<style>
table.target-info-table td {
  border: 0 !important;
}
</style>
