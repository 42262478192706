<template>
  <div class="elements">
    {{ allVerified }}
    <div v-if="budgetTypePivot.campaigns > 0" class="budget-type-pivot">
      <div class="">
        <label class="element-label">Campaign Setup</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ budgetTypeVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="budgetTypeVerified" :disabled="budgetTypeVerifiedDisable"/>
          </label>
        </span>
      </div>
      <div class="budget-type-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th
                v-for="item in budgetTypeColumns"
                :key="item"
                class="table-header"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody v-for="(record, i) in budgetTypePivot.records" :key="i">
            <tr :class = "{'disable-row' : record.status === 'Success'}">
              <td class="data-center">{{ record.campaignName }}</td>
              <td class="data-center">{{ record.status }}</td>
              <td class="data-center">{{ record.clusterName }}</td>
              <td class="data-center">{{ record.targetingType }}</td>
              <td class="data-center">{{ record.bidOptimisation }}</td>
              <td class="data-center">
                <Row v-if="record.editingBudget" type="flex" align="top">
                  <column span="15" style="padding-right:5%;">
                <i-input
                      v-model.number="budgetValue"
                    >
                      <span slot="prepend">{{record.bpCurrency}}</span>
                </i-input>
                  </column>
                   <column span="2" style="padding-right:5%;">
                <span @click="saveBudgetValue(i, budgetValue)">
              <i :class="' fa fa-check ' "
                aria-hidden="true"
                title="Update"
              ></i>
            </span>
             </column>
              <column span="2">
            <span @click="record.editingBudget=false,reserveEditMode=false">
              <i class="fa fa-times" aria-hidden="true" title="Cancel"></i>
            </span>
              </column>
            </Row>
                <span v-if="!record.editingBudget" slot="content" style="padding-right:5%">{{ record.bpCurrency +" "+ record.totalBudget + " " + record.budgetType + " (" + record.budget + ")"}}</span>
                <span v-if="!record.editingBudget"
                  :class="reserveEditMode ? ' disabled': ''"
                  data-toggle="collapse"
                  @click="editBudget(i)"
                  ><i class="fa fa-pencil" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
       <div class="">
         <Row style="width:100%" type="flex" align="top">
           <column span="21">
        <label class="element-label">Targeting</label>
           </column>
        <column span="2">
            <button type="button" class="btn btn-success buttonload" @click="showBidPopUp=true">Detailed Bid</button>
          </column>
          </Row>
      </div>
      <div class="budget-type-table" slot="content">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="3" class="table-header targetWidth">Targets</th>
              <th  class="table-header">Campaigns</th>
            </tr>
          </thead>
          <tbody v-for="(record, i) in budgetTypePivot.records" :key="i">
            <tr :class = "{'disable-row' : record.status === 'Success'}">
              <td class="data-center" v-if="record.targetingType == 'Product Targeting'" ><b>Categories:</b><br/>{{ record.categories }}<br/><b>Bid Range:</b> {{ record.categoryBidRange }}</td>
              <td class="data-center" v-if="record.targetingType == 'Product Targeting'" ><b>Asins to target:</b><br/>{{ record.asinsToTarget }}<br/><b>Bid Range:</b> {{ record.asinsBidRange }}</td>
              <td class="data-center" v-if="record.targetingType == 'Product Targeting'"><b>Excluded asins:</b><br/>{{ record.negativeAsins }}</td>
              <td class="data-center" v-if="record.targetingType == 'Audience Targeting'" colspan="3"><b>Audience:</b><br/><div  v-for="(targets, i) in record.targets.Targets" :key="i">{{ targets.Name }}</div><br/><b>Bid Range:</b> {{ record.targets.BidRange }}</td>
              <td class="data-center">{{ record.campaignName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="">
        <label class="element-label">Ads</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ budgetTypeVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="budgetTypeVerified" :disabled="budgetTypeVerifiedDisable"/>
          </label>
        </span>
      </div>
      <div class="budget-type-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th  class="table-header">Brand Logo</th>
              <th  class="table-header">Headline</th>
              <th  class="table-header">Custom Image</th>
              <th  class="table-header">Products Advertised <i
                        v-tooltip="'All Ads'"
                        class="more-previews fa fa-chevron-circle-up"
                        @click.stop="showAdsPopUp=true"
                      ></i></th>
              <th  class="table-header">Campaigns</th>
            </tr>
          </thead>
          <tbody v-for="(record, i) in budgetTypePivot.records" :key="i">
            <tr :class = "{'disable-row' : record.status === 'Success'}">
              <td class="data-center"><img v-if="record.brandLogo != ''" :src = record.brandLogo style="width:100px;height:100px" /></td>
              <td class="data-center">{{ record.headLine }}</td>
              <td class="data-center"><img v-if="record.customImage != ''" :src = record.customImage style="width:100px;height:100px" /></td>
              <td class="data-center adsDisplay"><div v-for="(ad, i) in record.sdAds" :key="i"><div v-if="i<3" style="display: flex;flex-direction: column;padding: 10px"><div><img :src = ad.imageLink style="width:100px;height:100px;max-width: 287.797px;height:150px" /></div><div v-if="ad.asin!=null"> Asin : {{ ad.asin }} </div><div v-if="ad.sku!=null"> Sku : {{ ad.sku }} </div></div></div></td>
              <td class="data-center">{{ record.campaignName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal style="color:black"  v-model="showBidPopUp" @cancel="showBidPopUp=false" >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Targeting</h4>
        <Select v-model="selectedCampaign">
        <Option v-for="campaign in budgetTypePivot.records" :value="campaign.campaignName" :key="campaign.campaignName">{{ campaign.campaignName }}</Option>
      </Select>
      </div>
      <div slot="modal-body" class="modal-body" style="min-height:0px">
        <div class="budget-type-table" slot="content" v-if="bidingCampaign.targetingType == 'Product Targeting'">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th  class="table-header">Categories</th>
              <th  class="table-header">Bid</th>
            </tr>
          </thead>
          <tbody v-for="(record, i) in bidingCampaign.categoryDetails" :key="i">
            <tr>
              <td class="data-center">{{ record.CategoriesToTarget }}</td>
              <td class="data-center">{{bidingCampaign.bpCurrency}}{{ record.Bid.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="budget-type-table" slot="content" v-if="bidingCampaign.targetingType == 'Product Targeting'">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th  class="table-header">Asins</th>
              <th  class="table-header">Bid</th>
            </tr>
          </thead>
          <tbody v-for="(record, i) in bidingCampaign.targetDetails" :key="i">
            <tr>
              <td class="data-center">{{ record.AsinsToTarget }}</td>
              <td class="data-center">{{bidingCampaign.bpCurrency}}{{ record.Bid.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="budget-type-table" slot="content" v-if="bidingCampaign.targetingType == 'Product Targeting'">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th  class="table-header">Excluded Asins</th>
            </tr>
          </thead>
          <tbody v-for="(record, i) in bidingCampaign.excludedAsins" :key="i">
            <tr>
              <td class="data-center">{{ record }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="budget-type-table" slot="content"  v-if="bidingCampaign.targetingType == 'Audience Targeting'">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th  class="table-header">Audience</th>
              <th  class="table-header">Bid</th>
            </tr>
          </thead>
          <tbody v-for="(record, i) in bidingCampaign.targets.Targets" :key="i">
            <tr>
              <td class="data-center">{{ record.Name }}<div v-if="record.Refinements != ''"><b>Refinements:</b>{{ record.Refinements }}</div></td>
              <td class="data-center">{{bidingCampaign.bpCurrency}}{{ record.Bid.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
      <div slot="modal-footer">
         <div class="footer-div">
            <button type="button" class="btn btn-white" v-on:click="showBidPopUp=false">Close</button>
         </div>
      </div>
   </modal>
   <modal style="color:black"  v-model="showAdsPopUp" @cancel="showAdsPopUp=false" >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Ads</h4>
        <Select v-model="selectedAdsCampaign">
        <Option v-for="campaign in budgetTypePivot.records" :value="campaign.campaignName" :key="campaign.campaignName">{{ campaign.campaignName }}</Option>
      </Select>
      </div>
      <div slot="modal-body" class="modal-body" style="min-height:0px">
      <div class="budget-type-table" slot="content">
        <Row  v-for="(ad, i) in adsCampaign.sdAds" :key="i">
          <column span="10">
          <img :src = ad.imageLink style="width:100px;height:100px" />
          </column>
          <column span="14">
          <div>Asin : {{ ad.asin }}</div>
          <div v-if="ad.sku != null"> Sku : {{ ad.sku }}</div>
          </column>
        </Row>
      </div>
      </div>
      <div slot="modal-footer">
         <div class="footer-div">
            <button type="button" class="btn btn-white" v-on:click="showAdsPopUp=false">Close</button>
         </div>
      </div>
   </modal>
  </div>
</template>

<script>
import { locale, Select, Option, Row, Col, Input } from "iview";
import { modal } from 'vue-strap';
import ApiService from "./ApiService.js";
import lang from "iview/dist/locale/en-US";
import { mapState, mapMutations } from "vuex";

locale(lang); // iview is default chinese

export default {
  components: {
    modal,
    Select,
    Option,
    Row,
    "column": Col,
    "i-input": Input
  },
  mounted: function () {},
  props: {
    isSubmission: { default: true }
  },
  data: function () {
    return {
      budgetTypeVerified: true,
      showBidPopUp: false,
      budgetTypeVerifiedDisable: false,
      budgetTypeColumns: [
        "Campaigns",
        "Status",
        "Cluster",
        "Targeting Type",
        "Bid Optimisation",
        "Total Budget"
      ],
      targetColumns: [
        "Targets",
        "Campaigns"
      ],
      productType:
        {
          1: 'Advertised products',
          2: 'Similar to advertised products'
        },
      campaignStatus: {
        1: "Active",
        3: "Paused",
        8: "Success",
        10: "Failed"
      },
      tactics: {
        1: "Product Targeting",
        2: "Audience Targeting"
      },
      bidOptimisation: {
        1: "Page Vists",
        2: "Conversions"
      },
      creativeOptimisation: {
        "HeadlineLogo": 1,
        "CustomImage": 2
      },
      budgetType: {
        1: "Daily",
        2: "LifeTime"
      },
      collapseTab: 'Targeting',
      bidingCampaign: {},
      selectedCampaign: "",
      reserveEditMode: false,
      budgetValue: 0,
      showAdsPopUp: false,
      adsCampaign: {},
      selectedAdsCampaign: "",
      budgetUpdatedLineitems: [],
      budgetVerified: false,
      minimumBudget: 50
    };
  },
  computed: {
    ...mapState([
      "amsEntitiesResponse",
      "selectedPublisher",
      "publisher",
      "isCampaignLauncherVerificationEnabled"
    ]),
    budgetTypePivot: function () {
      const amsEntities = this.amsEntitiesResponse.data.amsEntities;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.budgetTypeVerified = false;
      }
      if (!amsEntities.length) {
        this.budgetTypeVerified = true;
        return { records: [], campaigns: 0 };
      }
      let records = amsEntities.map(campaign => ({
        campaignName: campaign.name,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        clusterName: campaign.additionalData.cluster.name,
        totalBudget: campaign.additionalData.totalBudget.toLocaleString(),
        budget: campaign.additionalData.budget.toLocaleString(),
        editingBudget: false,
        status: this.campaignStatus[campaign.status],
        bpCurrency: campaign.additionalData.bpcurrency,
        budgetType: this.budgetType[campaign.additionalData.budgetType],
        bidOptimisation: this.bidOptimisation[campaign.bidOptimization],
        asinsToTarget: campaign.additionalData.cluster.amsCluster.asinsToTarget.length > 0 ? campaign.additionalData.cluster.amsCluster.asinsToTarget.join(', ') : "(None)",
        targetingType: this.tactics[campaign.tactics],
        categories: campaign.additionalData.categories.length > 0 ? campaign.additionalData.categories.map(e => e.CategoriesToTarget).join(', ') : "(None)",
        negativeAsins: campaign.additionalData.negativeAsins.length > 0 ? campaign.additionalData.negativeAsins.join(',') : "(None)",
        clusterInfo: campaign.additionalData,
        targets: campaign.tactics == 2 ? this.getTargets(campaign.additionalData.bpcurrency, campaign.additionalData.audience) : '(None)',
        defaultBid: campaign.childEntities.length > 0 ? campaign.childEntities[0].defaultBid : campaign.defaultBid,
        lineItemId: campaign.lineItemId,
        brandLogo: campaign.creativeCustomisation == this.creativeOptimisation.HeadlineLogo ? campaign.additionalData.cluster.logo : '',
        headLine: campaign.creativeCustomisation == this.creativeOptimisation.HeadlineLogo ? campaign.additionalData.cluster.headLine : '',
        customImage: campaign.creativeCustomisation == this.creativeOptimisation.CustomImage ? campaign.additionalData.cluster.customImage : '',
        targetDetails: campaign.additionalData.productsToTargets.length > 0 ? campaign.additionalData.productsToTargets : [],
        categoryDetails: campaign.additionalData.categories.length > 0 ? campaign.additionalData.categories : [],
        excludedAsins: campaign.additionalData.negativeAsins.length > 0 ? campaign.additionalData.negativeAsins : [],
        sdAds: campaign.additionalData.amsSdAds,
        categoryBidRange: campaign.additionalData.categories.length > 0 ? this.GetBidRange(campaign.additionalData.bpcurrency, campaign.additionalData.categories) : 0,
        asinsBidRange: campaign.additionalData.productsToTargets.length > 0 ? this.GetBidRange(campaign.additionalData.bpcurrency, campaign.additionalData.productsToTargets) : 0
      }));
      for (var i = 0; i < records.length; i++) {
        if (parseInt(records[i].totalBudget) < this.minimumBudget && !this.budgetVerified) {
          this.$Message.error({
            background: true,
            content: "Budget should be greater than " + this.minimumBudget + " for the campaign" + records[i].campaignName
          });
        }
      }
      this.budgetVerified = true;
      let totalCampaigns = records.length;
      var campaignsToBeExported = records.filter(record => record.status != this.campaignStatus[8]);
      this.budgetTypeVerifiedDisable = !campaignsToBeExported.length > 0;
      return { records: records, campaigns: totalCampaigns };
    },
    allVerified: function () {
      this.$emit("elementsVerified", [
        { value: this.budgetTypeVerified, label: "Campaign Setup" }
      ]);
      return "";
    }
  },
  methods: {
    ...mapMutations(["Set_Ams_sd_entities"]),
    editBudget (index) {
      this.reserveEditMode = true;
      this.budgetValue = this.budgetTypePivot.records[index].budget;
      this.budgetTypePivot.records[index].editingBudget = true;
    },
    saveBudgetValue (index, value) {
      let updatedTotalBudget = 0;
      this.reserveEditMode = false;
      let endDate = new Date(this.budgetTypePivot.records[index].endDate);
      let startDate = new Date(this.budgetTypePivot.records[index].startDate);
      let lineitemCampaigns = this.budgetTypePivot.records.filter((x, ind) => x.lineItemId == this.budgetTypePivot.records[index].lineItemId).map((x, ind) => ({ budget: x.budget, index: ind }));
      let diff = this.budgetTypePivot.records[index].endDate != undefined ? ((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) + 1 : 30;
      updatedTotalBudget = value * diff;
      lineitemCampaigns.forEach(ele => {
        if (ele.index != index) {
          updatedTotalBudget += parseInt(ele.budget) * diff
        }
      })
      this.budgetTypePivot.records[index].budget = value;
      this.budgetTypePivot.records[index].editingBudget = false;
      lineitemCampaigns.forEach(ele => {
        this.budgetTypePivot.records[ele.index].totalBudget = updatedTotalBudget;
        this.amsEntitiesResponse.data.amsEntities[ele.index].additionalData.totalBudget = updatedTotalBudget;
      })
      let lineitemIndex = this.budgetUpdatedLineitems.findIndex(object => object.lineItemId === this.budgetTypePivot.records[index].lineItemId);
      if (lineitemIndex === -1) {
        this.budgetUpdatedLineitems.push({
          LineItemId: this.budgetTypePivot.records[index].lineItemId,
          TotalBudget: updatedTotalBudget
        });
      }
      let requestParam = {
        EntityData: JSON.stringify(this.amsEntitiesResponse.data),
        LineItemDetails: this.budgetUpdatedLineitems
      }
      this.amsEntitiesResponse.data.amsEntities[index].additionalData.budget = value;
      this.Set_Ams_sd_entities(requestParam);
    },
    getTargets (bpCurrency, audience) {
      let targets = [];
      if (audience.product != undefined) {
        audience.product.forEach(element => {
          targets.push({
            Name: "Views remarketing -" + this.productType[element.productType],
            Bid: element.bid,
            Refinements: ''
          });
        });
      }
      if (audience.category != undefined) {
        audience.category.forEach(element => {
          let refinements = element.refinements != undefined ? this.GetRefinementsData(element.refinements) : '';
          targets.push({
            Name: "Views remarketing -" + element.categoryName,
            Bid: element.bid,
            Refinements: refinements.substring(1, refinements.length)
          });
        });
      }
      if (audience.audienceToTarget != undefined) {
        audience.audienceToTarget.forEach(element => {
          targets.push({
            Name: element.audienceName,
            Bid: element.bid,
            Refinements: ''
          });
        });
      }

      let bidRange = this.GetBidRange(bpCurrency, targets);
      return {
        Targets: targets,
        BidRange: bidRange
      };
    },
    GetRefinementsData (refinements) {
      let price = refinements.minPrice != 0 && refinements.maxPrice != 0 ? ",Price:" + refinements.minPrice + "-" + refinements.maxPrice
        : refinements.maxPrice == 0 && refinements.minPrice != 0 ? ",Price:" + refinements.minPrice + " and above"
          : refinements.minPrice == 0 && refinements.maxPrice != 0 ? ",Price:" + refinements.maxPrice + " and below"
            : '';
      let rating = refinements.rating != undefined && refinements.rating != '0-5' ? ",Rating:" + refinements.rating : '';
      let shippingValue = refinements.isPrimeShippingEligible != undefined ? refinements.isPrimeShippingEligible == 1 ? ",Shipping:" + 'Prime eligible' : ",Shipping:" + 'Non Prime eligible' : '';
      return price + rating + shippingValue;
    },
    GetBidRange (bpCurrency, array) {
      let max = Math.max.apply(Math, array.map(e => e.Bid));
      let min = Math.min.apply(Math, array.map(e => e.Bid));
      return max == min ? bpCurrency + "" + min.toFixed(2) : bpCurrency + "" + max.toFixed(2) + " - " + min.toFixed(2);
    }
  },
  watch: {
    selectedCampaign: function () {
      this.bidingCampaign = this.budgetTypePivot.records.filter(record => record.campaignName == this.selectedCampaign)[0];
    },
    selectedAdsCampaign: function () {
      this.adsCampaign = this.budgetTypePivot.records.filter(record => record.campaignName == this.selectedAdsCampaign)[0];
    }
  }
};
</script>

<style scoped>
tr.disable-row{
  pointer-events: none;
  background-color: #f9f9f9;
}
.adsDisplay {
  display:flex;
}
.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
}
.verified-action {
  float: right;
  padding: 10px;
}
.checkbox-status-label {
  padding-right: 5px;
  color: #999;
  cursor: pointer;
}
input[type="checkbox"] {
  vertical-align: text-bottom;
}
.total-footer {
  font-weight: 600;
  background-color: #eee;
}
.table-footer-label {
  border: 1px solid #ddd;
  padding: 0px 5px;
}
.target-label {
  padding-top: 10px;
}
.target-sub-label {
  padding-top: 10px;
  font-size: 0.9em;
  color: #979898;
  max-width: 420px;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
::v-deep .ivu-modal-footer {
  display: none;
}
.table-header{
  border:1px solid #ddd !important;
  padding:10px !important;
}
.buttonload {
  margin-top: 2px;
}
::v-deep .ivu-collapse-header {
    display: flex !important;
}
::v-deep .ivu-collapse>.ivu-collapse-item>.ivu-collapse-header>i {
    margin-top: 1%;
}
::v-deep .ivu-collapse>.ivu-collapse-item.ivu-collapse-item-active>.ivu-collapse-header>i {
  margin-top: 0px;
}
.targetWidth {
  width: 60%;
}
</style>
