<template>
    <div id="campaign-launcher" class="card">
      <!-- <div v-if="campaign.adgroup > 0"> -->
    <div v-if="adgroups">
        <table class="table perf alternate">
        <thead>
            <tr>
                <th colspan="1"></th>
                <th colspan="2" class="table-fit">Schedule</th>
                <th v-if="!containsPublishedAdgroups && !isAdsetBudget" colspan="2" class="table-fit">Spend Limit</th>
                <th v-if="isAdsetBudget" colspan="2" class="table-fit">Adset Budget</th>
                <th v-if="containsPublishedAdgroups && !isAdsetBudget" colspan="2" class="table-fit">Min Spend Limit</th>
                <th v-if="containsPublishedAdgroups && !isAdsetBudget" colspan="2" class="table-fit">Max Spend Limit</th>
                <th v-if="selectedCampaignLauncherConfig.campaign.buyType != 4" colspan="2" class="table-fit">Settings</th>
            </tr>
            <tr>
                <th class="table-header table-fit">Adgroup</th>
                <th class="table-header table-fit">Start</th>
                <th class="table-header table-fit">End</th>
                <th v-if="isAdsetBudget" class="table-header table-fit">Budget Type</th>
                <th v-if="isAdsetBudget" class="table-header table-fit">Budget</th>
                <th v-if="!containsPublishedAdgroups && !isAdsetBudget" class="table-header table-fit">Min</th>
                <th v-if="!containsPublishedAdgroups && !isAdsetBudget" class="table-header table-fit">Max</th>
                <th v-if="containsPublishedAdgroups && !isAdsetBudget" class="table-header table-fit">Original</th>
                <th v-if="containsPublishedAdgroups && !isAdsetBudget" class="table-header table-fit">Current</th>
                <th v-if="containsPublishedAdgroups && !isAdsetBudget" class="table-header table-fit">Original</th>
                <th v-if="containsPublishedAdgroups && !isAdsetBudget" class="table-header table-fit">Current</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(record, i) in adgroups" :key="i">
                <td class="table-data-middle-vertical table-data-min-width">
                  <Icon v-if="record.ApiData.status == 'ACTIVE'" type="md-play" />
                  <Icon v-else type="md-pause" />
                  {{record.Name}}
                  <span v-if="containChanges(record)" style='color:#b94a48'> *</span><br/>
                  <span class="table-data-dpa-settings">
                    {{ getDpaSettings(record) }}
                  </span>
                  <span v-if="updatedAdsetIndex.includes(i)">
                      <i class="fa fa-pencil-square yellow"></i>
                  </span>
                </td>
                <td class="table-data-middle-vertical">{{getReadableDate(record.RowData.AdgroupStartDate)}}</td>
                <td class="table-data-middle-vertical">{{getReadableDate(record.RowData.AdgroupEndDate)}}</td>
                <template v-if="isAdsetBudget">
                  <td><Icon v-if="record.Id == '' && !isApprovalMode()" class="cursor-pointer" type="md-swap" @click="switchBudgetType(record, i)" /> {{ formatBudgetType(record.RowData.BudgetType) }}</td>
                  <!-- <slot></slot> -->
                  <td class="data-center table-fit">
                  <Tooltip theme="light">
                    <i-input
                      :disabled="record.Id != '' || isApprovalMode()"
                      :active-change="false"
                      v-model.number="adsetBudgetArray[i]"
                      v-bind:class="{ 'error-input': adsetBudgetInputInvalid(adsetBudgetArray[i]) }"
                      @on-blur="changeAdsetBudget(record, i)"
                    >
                      <span slot="prepend">{{bpCurrencySymbol}}</span>
                    </i-input>
                    <span slot="content">{{adsetBudgetArray[i] | parseBudgetInLocale}}</span>
                  </Tooltip>
                </td>
                </template>
                <template v-else>
                <td class="data-center table-fit">
                  <Tooltip theme="light">
                    <i-input
                      :disabled="record.Id != ''"
                      :active-change="false"
                      v-model.number="record.RowData.SpendLimit.min"
                      v-bind:class="{ 'error-input': isInputInvalid(record.RowData.SpendLimit.min) }"
                      @on-blur="changeAdsetSpendLimit(record, $event, false)"
                    >
                      <span slot="prepend">{{bpCurrencySymbol}}</span>
                    </i-input>
                    <span slot="content">{{record.RowData.SpendLimit.min | parseBudgetInLocale}}</span>
                  </Tooltip>
                </td>
                <td v-if="containsPublishedAdgroups" class="data-center table-fit">
                    <span v-if="(record.RowData.SpendLimitCluster && record.RowData.SpendLimitCluster.UserSpendLimit) && !dataReloading[index]">
                      <Tooltip theme="light">
                        <i-input
                          :active-change="false"
                          v-model.number="record.RowData.SpendLimitCluster.UserSpendLimit.min"
                          v-bind:class="{ 'error-input': isInputInvalid(record.RowData.SpendLimitCluster.UserSpendLimit.min) }"
                          @on-blur="changeAdsetSpendLimit(record, $event)"
                        >
                          <span slot="prepend">{{bpCurrencySymbol}}</span>
                        </i-input>
                        <span slot="content">{{record.RowData.SpendLimitCluster.UserSpendLimit.min | parseBudgetInLocale}}</span>
                      </Tooltip>
                    </span>
                    <i v-else-if="dataReloading[index]" class="fa fa-spinner fa-spin"/>
                    <span v-else >-</span>
                </td>
                <td class="data-center table-fit">
                  <Tooltip theme="light">
                    <i-input
                      :disabled="record.Id != ''"
                      :active-change="false"
                      v-model.number="record.RowData.SpendLimit.max"
                      v-bind:class="{ 'error-input': isInputInvalid(record.RowData.SpendLimit.max) }"
                      @on-blur="changeAdsetSpendLimit(record, $event, true)"
                    >
                      <span slot="prepend">{{bpCurrencySymbol}}</span>
                    </i-input>
                    <span slot="content">{{record.RowData.SpendLimit.max | parseBudgetInLocale}}</span>
                  </Tooltip>
                </td>
                <td v-if="containsPublishedAdgroups" class="data-center table-fit">
                    <span v-if="(record.RowData.SpendLimitCluster && record.RowData.SpendLimitCluster.UserSpendLimit) && !dataReloading[index]">
                      <Tooltip theme="light">
                        <i-input
                          :active-change="false"
                          v-model.number="record.RowData.SpendLimitCluster.UserSpendLimit.max"
                          v-bind:class="{ 'error-input': isInputInvalid(record.RowData.SpendLimitCluster.UserSpendLimit.max) }"
                          @on-blur="changeAdsetSpendLimit(record, $event, true)"
                        >
                          <span slot="prepend">{{bpCurrencySymbol}}</span>
                        </i-input>
                        <span slot="content">{{record.RowData.SpendLimitCluster.UserSpendLimit.max | parseBudgetInLocale}}</span>
                      </Tooltip>
                    </span>
                    <i v-else-if="dataReloading[index]" class="fa fa-spinner fa-spin"/>
                    <span v-else >-</span>
                </td>
                 </template>
                <td v-if="selectedCampaignLauncherConfig.campaign.buyType != 4" class="data-center table-fit">
                  <Icon type="md-settings" size="20" @click="updateAdsetSettingsClicked(record)" />
                </td>
            </tr>
            <template v-if="showAdsetSettingModal">
                  <adset-update-modal
                    :openAdsetSettingsModal = true
                    :currentAdSet = adSetToUpdate
                    :adgroups = adgroups
                    :defaultFormConfig = selectedCampaignLauncherConfig
                    :isApprovalMode = this.isApprovalMode()
                    @closeAdsetUpdateModal = "showAdsetSettingModal = false"
                    @adsetUpdatedSuccessfully = "showUpdatedIcon"
                    @calculateAdsetBudget = "recalculateAsetBudget">
                  </adset-update-modal>
                </template>
        </tbody>
      </table>
      </div>
      <div v-else>
        <div class="alert alert-warning" role="alert">
          No details Found
        </div>
      </div>
    </div>
</template>

<script>

import { Input, Tooltip, Icon } from "iview";
import { getOptimizationGoals } from "../../columnConfig.js";
import { mapState, mapMutations } from "vuex";
import * as fbEnums from "../../Constants/FacebookEnums.js";
import FacebookCampaignLauncherConfig from "../../Mixins/FacebookCampaignLauncherConfig.js";
import AdSetUpdateModal from "./AdSetUpdateModal.vue"
export default {
  components: {
    "i-input": Input,
    Tooltip,
    Icon,
    'adset-update-modal': AdSetUpdateModal
  },
  mounted: function () {
    var self = this;
    self.filteredLineItemsData = {};
    this.updateAdgroupDataWithAdditionalValues()
    this.adgroups.forEach(function (adgroup) {
      var lineItemId = adgroup.RowData.LineItemId;
      var fractionId = adgroup.RowData.LineItemFractionId;
      var selectedCampaignConfigId = self.selectedCampaignLauncherConfigId;
      self.mappedLineItems.forEach(function (li) {
        if (li.id == lineItemId && li.fractionId == fractionId && li.config == selectedCampaignConfigId) {
          var key = [lineItemId, fractionId].join("-");
          self.filteredLineItemsData[key] = JSON.parse(JSON.stringify(li));
        }
      })
    })
  },
  mixins: [FacebookCampaignLauncherConfig],
  props: {
    index: { default: 0 },
    adgroupsData: {
      type: Array,
      default: function () {
        return []
      }
    },
    campaignBudget: { default: 0 },
    isLifetimeBudget: { default: false },
    fetchedApiDataSuccess: { default: false },
    dataReloading: {
      type: Array,
      default: function () {
        return []
      }
    },
    campaginId: { default: null }
  },
  data: function () {
    return {
      adgroups: [],
      error: {
        existingMinSpendLimit: false,
        existingMaxSpendLimit: false,
        currentMinSpendLimit: false,
        currentMaxSpendLimit: false
      },
      disableVerifyButton: false,
      updatedData: [],
      needsDataProcessing: false,
      processedCampaigns: [],
      filteredLineItemsData: {},
      fbEnums,
      changedData: [],
      showAdsetSettingModal: false,
      adSetToUpdate: {},
      updatedAdsetIndex: []
    };
  },
  computed: {
    ...mapState(["bpCurrencySymbol", "selectedCampaignLauncherConfig", "campaignLauncherConfigs", "selectedCampaignLauncherConfigId", "selectedFbObjective", "mappedLineItems", "editedSummaryData"]),
    containsPublishedAdgroups: function () {
      let anyAdgroupPublished = this.adgroupsData.findIndex(x => x.Id != "")
      if (anyAdgroupPublished != -1) {
        return true
      }
      return false;
    },
    optimizationGoals: function () {
      let goals = []
      let optimizationGoals = this.getOptimizationGoalsByObjective(this.selectedCampaignLauncherConfig)
      if (optimizationGoals) {
        var configString = JSON.stringify(this.selectedCampaignLauncherConfig)
        var optimizationGoalList = getOptimizationGoals(configString)
        goals = optimizationGoalList
          .map(x => {
            return { id: x, name: fbEnums.optimizationGoalsEnum[x] }
          })
      }
      if (this.selectedCampaignLauncherConfig.campaign && this.selectedCampaignLauncherConfig.campaign.bidStrategy == 1) {
        return []
      }
      if (this.selectedCampaignLauncherConfig.campaign.bidStrategy === fbEnums.bidStrategy['Lowest Cost with Cost Cap']) {
        let unsupportedGoals = [7, 14, 22, 29];
        // Impressions, Reach, Value, App Events

        goals = goals.filter(x => !unsupportedGoals.includes(x.id));
      }
      return goals
    },
    getOptimizationGoal: function () {
      return (record, type = false) => {
        switch (type) {
          case 1:
            return record.RowData.OptimizationGoal.Current ? record.RowData.OptimizationGoal.Current : record.RowData.OptimizationGoal.Existing
          case 2:
            var notPublishedChanges = record.RowData.Changes.find(x => x.Property == "optimization_goal") && !record.Id
            return notPublishedChanges ? record.RowData.OptimizationGoal.Current : record.RowData.OptimizationGoal.Existing
          case 3:
            return record.RowData.OptimizationGoal.Current ? record.RowData.OptimizationGoal.Current : record.RowData.OptimizationGoal.API
          default:
            return null
        }
      }
    },
    containChanges: function () {
      // If there are any changes under campaign we need to show a red astricks
      return (data) => {
        return data.RowData.Changes ? data.RowData.Changes.filter(x => x.Status == 1).length > 0 : false
      }
    },
    showOptimizationGoalOption: function () {
      return (record, existing = true) => {
        if (existing) {
          return record.RowData.OptimizationGoal && record.RowData.OptimizationGoal.Existing
        }
        return (record.RowData.OptimizationGoal && record.RowData.OptimizationGoal.API) && !this.dataReloading[this.index]
      }
    },
    existingDisabledInPublish: function () {
      return (record) => {
        var isCurrentShown = this.showOptimizationGoalOption(record, false)
        return !!(this.containsPublishedAdgroups && isCurrentShown)
      }
    },
    isInputInvalid: function () {
      return (value) => {
        if (value < 0 || isNaN(value)) {
          this.$emit("errorInSpendLimit", true);
          return true
        }
        this.$emit("errorInSpendLimit", false);
        return false;
      }
    },
    isAdsetBudget: function () {
      return this.selectedCampaignLauncherConfig.isAdsetBudget ? this.selectedCampaignLauncherConfig.isAdsetBudget : false
    },
    adsetBudgetArray: function () {
      return this.adgroupsData.map(adgroup => adgroup.RowData.Budget)
    },
    differenceInDaysArray: function () {
      var differenceInDaysArray = []
      this.adgroups.forEach(adGroup => {
        let lineItem = this.mappedLineItems.find(lineItem => lineItem.id == adGroup.RowData.LineItemId)
        let startDate = adGroup.RowData.AdgroupStartDate ? new Date(adGroup.RowData.AdgroupStartDate) : new Date(lineItem.startDate);
        let endDate = adGroup.RowData.AdgroupEndDate ? new Date(adGroup.RowData.AdgroupEndDate) : new Date(lineItem.endDate);
        let differenceInDays = Math.abs(endDate - startDate) / (1000 * 3600 * 24);
        differenceInDaysArray.push(differenceInDays);
      })
      return differenceInDaysArray
    }
  },
  watch: {
    updatedData: {
      handler (newValue) {
        this.$emit("updatedAdgroupData", { adgroupData: this.updatedData, index: this.index });
      },
      deep: true
    },
    adgroupsData: {
      handler (newValue) {
        this.updateAdgroupDataWithAdditionalValues()
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(["setAdsetBudgetError", "setChangedData"]),
    syncApiDataAndRowDataInPublishJson (adgroups) {
      adgroups.forEach(adgroup => {
        if (adgroup.RowData.SpendLimit) {
          adgroup.RowData.SpendLimit.min = parseFloat(adgroup.RowData.SpendLimit.min)
          adgroup.RowData.SpendLimit.max = parseFloat(adgroup.RowData.SpendLimit.max)
        }
      });
      return adgroups
    },
    CreateChanges (entity, property, oldValue, newValue) {
      var notRelatedChanges = entity.RowData.Changes.filter(x => (x.Property != property && x.Status != 8)) || []
      var changes = {
        Property: property,
        OldValue: Number(oldValue) || null,
        NewValue: Number(newValue) || null,
        Status: 1
      }
      this.processedCampaigns = this.processedCampaigns.filter(item => item !== entity.RowData.CampaignId)
      notRelatedChanges.push(changes)
      return notRelatedChanges
    },
    changeAdsetSpendLimit (record, event, isMax = false) {
      var newValue = parseFloat(event.target.value)
      var result = null
      if (newValue > 0 && (newValue > parseFloat(this.campaignBudget).toFixed(2))) {
        this.$emit("errorInSpendLimit", true);
      } else {
        this.$emit("errorInSpendLimit", false);
        if (record.Id && record.RowData.SpendLimitCluster) {
          if (!isMax) {
            result =
            this.calculateMinSpendLimit(
              record.RowData.SpendLimitCluster.UserSpendLimit.min,
              newValue,
              0,
              record.RowData.SpendLimitCluster.UserSpendLimit.max);
            if (result) {
              if (record.RowData.SpendLimitCluster.APISpendLimit.min !== result) {
                record.RowData.Changes = this.CreateChanges(record, "minSpendLimit", record.RowData.SpendLimitCluster.UserSpendLimit.min, result)
                record.RowData.SpendLimitCluster.UserSpendLimit.min = result
              }
            } else {
              var autoAdjustedAmount = parseFloat(newValue / 0.9).toFixed(2);
              if (record.RowData.SpendLimitCluster.APISpendLimit.max !== autoAdjustedAmount) {
                record.RowData.Changes = this.CreateChanges(record, "minSpendLimit", record.RowData.SpendLimitCluster.UserSpendLimit.min, newValue)
                record.RowData.Changes = this.CreateChanges(record, "maxSpendLimit", record.RowData.SpendLimitCluster.UserSpendLimit.max, autoAdjustedAmount)
                record.RowData.SpendLimitCluster.UserSpendLimit.max = autoAdjustedAmount
              }
            }
          } else if (isMax) {
            var maxSpendLimit =
            this.calculateMaxSpendLimit(
              record.RowData.SpendLimitCluster.UserSpendLimit.max,
              newValue,
              record.RowData.SpendLimitCluster.UserSpendLimit.min)
            if (record.RowData.SpendLimitCluster.APISpendLimit.max !== maxSpendLimit) {
              record.RowData.Changes = this.CreateChanges(record, "maxSpendLimit", record.RowData.SpendLimitCluster.UserSpendLimit.max, maxSpendLimit)
              record.RowData.SpendLimitCluster.UserSpendLimit.max = maxSpendLimit
            }
          }
        } else {
          if (!isMax) {
            result =
            this.calculateMinSpendLimit(
              record.RowData.SpendLimit.min,
              newValue,
              0,
              record.RowData.SpendLimit.max);
            if (result) {
              record.RowData.Changes = this.CreateChanges(record, "minSpendLimit", record.RowData.SpendLimit.min, result)
              record.RowData.SpendLimit.min = result
            } else {
              var maxIncrementedValue = parseFloat(newValue / 0.9).toFixed(2)
              record.RowData.Changes = this.CreateChanges(record, "minSpendLimit", record.RowData.SpendLimit.min, newValue)
              record.RowData.Changes = this.CreateChanges(record, "maxSpendLimit", record.RowData.SpendLimit.max, maxIncrementedValue)
              record.RowData.SpendLimit.max = maxIncrementedValue
            }
          } else if (isMax) {
            var maxSpendLimitPrePublish = this.calculateMaxSpendLimit(
              record.RowData.SpendLimit.max,
              newValue,
              record.RowData.SpendLimit.min)
            record.RowData.Changes = this.CreateChanges(record, "maxSpendLimit", record.RowData.SpendLimit.max, maxSpendLimitPrePublish)
            record.RowData.SpendLimit.max = maxSpendLimitPrePublish
          }
        }
      }
      this.updatedData = this.syncApiDataAndRowDataInPublishJson(this.updatedData)
    },
    calculateMinSpendLimit (oldValue, newValue, min, max) {
      if (isNaN(parseFloat(newValue)) || (parseFloat(newValue) < min)) {
        return parseFloat(min);
      } else if (parseFloat(newValue) > (max * 0.9)) {
        return false; // This is to take the 90% of the max spend Limit
      } else return parseFloat(newValue);
    },
    calculateMaxSpendLimit (oldValue, newValue, min) {
      if (isNaN(parseFloat(newValue)) || (parseFloat(newValue * 0.9) < min)) {
        return parseFloat(min / 0.9).toFixed(2);
      } else if (parseFloat(newValue) > this.campaignBudget) {
        return parseFloat(this.campaignBudget).toFixed(2) // This is to take the 100% of the budget
      } else return parseFloat(newValue);
    },
    updateAdgroupDataWithAdditionalValues () {
      if (this.adgroupsData) {
        this.updatedData = this.adgroupsData
        this.adgroups = this.adgroupsData.map(obj => ({ ...obj, error: this.error }))
        if (!this.dataReloading[this.index]) {
          this.dataReProcess();
        }
      }
    },
    dataReProcess () {
      this.adgroups.forEach(element => {
        if (element.Id && element.RowData.SpendLimitCluster && !element.RowData.SpendLimitCluster.APISpendLimit && this.campaginId) {
          if (this.fetchedApiDataSuccess && !this.processedCampaigns.includes(this.campaginId)) {
            this.$parent.setAdgroupLiveData(this.campaginId, this.index)
            this.processedCampaigns.push(this.campaginId)
          }
        }
      })
    },
    // Optimization Type
    changedCurrentOptimizationData (changedValue, rowData, live = false) {
      if (!rowData.OptimizationGoal && !rowData.OptimizationGoal.Existing) {
        rowData["OptimizationGoal"] = {
          "Existing": null,
          "Current": null
        }
      }
      if (!rowData.Changes) {
        rowData["Changes"] = []
      }
      this.updateChangesArray("optimization_goal", rowData, changedValue)
    },
    updateChangesArray (property, rowDataRef, newValue) {
      var change = {
        Property: property,
        OldValue: rowDataRef.OptimizationGoal.API ? rowDataRef.OptimizationGoal.API : (rowDataRef.OptimizationGoal.Existing || null),
        NewValue: newValue || null,
        Status: 1
      }
      if (rowDataRef.Changes.find(x => x.Property == "optimization_goal" && x.Status != 8)) {
        rowDataRef.Changes = rowDataRef.Changes.filter(x => x.Property != "optimization_goal")
      }
      if (change.OldValue != change.NewValue) {
        rowDataRef.Changes.unshift(change)
        rowDataRef.OptimizationGoal.Current = change.NewValue
      } else {
        rowDataRef.OptimizationGoal.Current = change.NewValue
      }
    },
    getReadableDate (dateTimeStamp) {
      if (!dateTimeStamp) {
        return "-";
      }
      var date = new Date(dateTimeStamp)
      return date.toDateString();
    },
    getDpaSettings (record) {
      if (!record.RowData.ProductCatalogId) {
        return;
      }
      var lineItemId = record.RowData.LineItemId;
      var fractionId = record.RowData.LineItemFractionId;
      var key = [lineItemId, fractionId].join("-");
      if (this.filteredLineItemsData[key]) {
        var dpaSettings = this.filteredLineItemsData[key].dpaSettings;
        if (dpaSettings && dpaSettings.promotedProductSet && dpaSettings.promotedProductSet.selected) {
          var promotedProductSet = dpaSettings.promotedProductSet.selected.Name + " (" +
            dpaSettings.promotedProductSet.selected.Id + ")";
          return promotedProductSet;
        }
      }
      return "";
    },
    changeAdsetBudget (adGroup, index) {
      var errorObj = {}
      if (!this.adsetBudgetInputInvalid(this.adsetBudgetArray[index])) {
        if (this.adsetBudgetArray[index] != adGroup.RowData.AllocatedBudget) {
          var lineItem = this.mappedLineItems.find(lineItem => lineItem.id == adGroup.RowData.LineItemId)
          var adsetBugdetSum = 0
          var startDate = adGroup.RowData.AdgroupStartDate ? new Date(adGroup.RowData.AdgroupStartDate) : new Date(lineItem.startDate);
          var endDate = adGroup.RowData.AdgroupEndDate ? new Date(adGroup.RowData.AdgroupEndDate) : new Date(lineItem.endDate);
          if (adGroup.RowData.BudgetType == "daily_budget") {
            if (this.editedSummaryData) {
              var parsedEditedData = JSON.parse(this.editedSummaryData)
              if (parsedEditedData && parsedEditedData.hasOwnProperty(adGroup.RowData.InternalCampaignName)) {
                var editedProperties = parsedEditedData[adGroup.RowData.InternalCampaignName].find(editedData =>
                  editedData["internalId"] == adGroup.RowData.InternalAdgroupName
                )
                if (editedProperties && editedProperties["adsetSchedule"]) {
                  startDate = new Date(editedProperties["adsetSchedule"][0])
                  endDate = new Date(editedProperties["adsetSchedule"][1])
                  adGroup.RowData.AdgroupStartDate = startDate
                  adGroup.RowData.AdgroupEndDate = endDate
                }
              }
            }
            var self = this;
            adsetBugdetSum = this.adsetBudgetArray.map(function (adsetBudget, ind) {
              return adsetBudget * self.differenceInDaysArray[ind];
            }).reduce((partialSum, a) => partialSum + a, 0)
          } else {
            adsetBugdetSum = this.adsetBudgetArray.reduce((partialSum, a) => partialSum + a, 0)
          }
          var totalBudget = lineItem.budget
          if (adsetBugdetSum > totalBudget) {
            errorObj['isAdsetBudgetInvalid'] = true;
            errorObj['errorMessage'] = `The total adset budget ${adsetBugdetSum} is exceeding the line item budget ${totalBudget}.`
          } else {
            errorObj['isAdsetBudgetInvalid'] = false;
            errorObj['errorMessage'] = ''
            var editedEntityObject = {
              "internalId": adGroup.RowData.InternalAdgroupName,
              "entityType": adGroup.EntityType,
              "budgetType": this.formatBudgetType(adGroup.RowData.BudgetType),
              "endDate": endDate,
              "adsetBudget": this.adsetBudgetArray[index]
            }
            this.changedData[index] = editedEntityObject
          }
          if (this.changedData.length) {
            this.setChangedData({ value: this.changedData.filter(n => n), campaignId: adGroup.RowData.InternalCampaignName })
          }
        }
      } else {
        errorObj['isAdsetBudgetInvalid'] = true;
        errorObj['errorMessage'] = "Please enter a valid number for adset budget"
      }
      this.setAdsetBudgetError(errorObj)
    },
    adsetBudgetInputInvalid: function (value) {
      if (value <= 0 || isNaN(value)) {
        return true
      } else {
        return false;
      }
    },
    isApprovalMode () {
      if (window.location.href.indexOf("submission") > -1) {
        return true
      } else {
        return false
      }
    },
    updateAdsetSettingsClicked (record) {
      this.showAdsetSettingModal = true;
      this.adSetToUpdate = record
    },
    showUpdatedIcon (updatedAdSets) {
      updatedAdSets.forEach(
        updatedAdSet => {
          this.updatedAdsetIndex.push(
            this.adgroups.findIndex(adGroup => adGroup.RowData.InternalAdgroupName == updatedAdSet.internalId)
          )
        }
      )
    },
    switchBudgetType (record, index) {
      var rowData = record.RowData;
      if (rowData.BudgetType == "daily_budget") {
        rowData.BudgetType = "lifetime_budget"
      } else {
        rowData.BudgetType = "daily_budget"
      }
      this.changeAdsetBudget(record, index);
    },
    formatBudgetType: function (value) {
      if (!value) return ''
      value = value.toString()
      value = value.split("_")[0]
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    recalculateAsetBudget: function (adsetId) {
      let adsetIndex = this.adgroups.findIndex(adGroup => adGroup.RowData.InternalAdgroupName == adsetId)
      this.changeAdsetBudget(this.adSetToUpdate, adsetIndex)
    }
  },
  filters: {
    parseBudgetInLocale: function (value) {
      if (!value) return value
      var floatValue = parseFloat(value);
      return parseFloat(floatValue.toFixed(2)).toLocaleString("en")
    }
  }
}
</script>

<style scoped>
  .data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: -3px -3px 5px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background-color: rgba(1,1,1,0);
  padding: 5px
}

/* On mouse-over, add a deeper shadow */
.card:hover {box-shadow: 0 0 5px 0px rgb(0,0,0,0.5);
}
.table-header{
  border:1px solid #ddd important;
  padding:10px important;
}
.table-fit {
  width: 120px;
  text-align: center;
}
.input-error {
  border: 2px solid #ed0e0e;
}
.table-data-middle-vertical {
  vertical-align: middle;
  text-align: center;
}
.table-data-min-width {
  min-width:90px
}

.error-input {
  border: 1px solid red;
}
.table-data-dpa-settings {
  font-size: 11px;
}
</style>
