import axiosInstance from '../../Api'

const baseUrl = deltax.apiBaseUrl
  .concat(`businessProfiles/${deltax.businessProfileId}/`)
  .concat(`Targets`);
let APIService = {
  GetCategories (parentId, bpseId = 0) {
    const getCategoriesURL =
      parentId === 0
        ? baseUrl + `/GetCategories?bpseId=${bpseId}`
        : baseUrl + `/GetCategories?parentId=${parentId}&bpseId=${bpseId}`;

    return axiosInstance.get(getCategoriesURL);
  },
  GetCategoriesNames () {
    // For fetching all ams categories Name and Id from core table
    const getCategoriesNamesURL = baseUrl
      .concat(`/GetCategoriesNames`)
    const promise = axiosInstance.get(getCategoriesNamesURL);
    return promise
  },
  GetAudience (country, type) {
    // For fetching ams categories from core table
    const getCategoriesURL = baseUrl
      .concat(`/GetAudiences?type=`).concat(type).concat('&country=').concat("");
    const promise = axiosInstance.get(getCategoriesURL);
    return promise
  },
  GetAudienceNames () {
    // For fetching all ams categories Name and Id from core table
    const getAudienceNamesURL = baseUrl
      .concat(`/GetAudiencesNames`)
    const promise = axiosInstance.get(getAudienceNamesURL);
    return promise
  }
}

export { APIService };
