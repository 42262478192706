<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <div class="row">
          <div class="col-md-4">
            <h4>Targeting <i v-show="loader" class="fa fa-spinner fa-spin" /></h4>
          </div>
          <div class="col-md-6 no-padding">
            <Input v-model="search" search placeholder="Search" class="search-bar input-search-bar" />
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-success btn-sm widget-element pull-right"
              :class="{'disabled':businessProfilesSearchEngineId == 0}"
              @click="loadIframe"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <VuePerfectScrollbar class="widget-body">
          <template>
            <div
              style="margin-top:10px"
              v-if="businessProfilesSearchEngineId==0"
              class="alert alert-info"
            >Please select a campaign configuration to see the saved targets.</div>
            <div
              style="margin-top:10px"
              v-else-if="socialTargets.length<=0"
              class="alert alert-info"
            >There are no saved targets yet, create a new one to get started.</div>
          </template>
          <template v-if="businessProfilesSearchEngineId > 0">
            <Row v-for="socialTarget in filteredTargets" v-bind:key="socialTarget.Id" class="cl-ivu-row">
              <Row-Col span="1">
                <i class="fas fa-copy action-icon"
                  @click="copyToClipboard('DXT-' + Number.parseInt(businessProfileId).toString(36) + '-' + socialTarget.Id)">
                </i>
              </Row-Col>
              <Row-Col span="1" offset="1">
                <!-- <Poptip transfer placement="left-start" trigger="click">
                  <i
                    class="fa fa-info-circle social-target-info-tooltip"
                  ></i>
                  <div slot="content" class="tooltip-size">
                    <div v-html="socialTarget.generatedTarget"></div>
                  </div>
                </Poptip> -->
              </Row-Col>
              <Row-Col span="15" offset="1" class="cl-widget-text">
                <span class="target-name">{{socialTarget.Name}}</span>
              </Row-Col>
              <Row-Col span="4" offset="1">
                <span v-if="socialTarget.EstimatedReach.toString().length > 5">
                  <i>{{Intl.NumberFormat().format(socialTarget.EstimatedReach).toString().slice(0, -4) + 'k'}}</i>
                </span>
                <span v-if="socialTarget.EstimatedReach.toString().length<=5">
                  <i>{{Intl.NumberFormat().format(socialTarget.EstimatedReach)}}</i>
                </span>
              </Row-Col>
            </Row>
            <!-- <tr v-for="socialTarget in socialTargets" v-bind:key="socialTarget.Id">
            <template v-if="socialTarget.Name.toLowerCase().includes(search.toLowerCase())">
              <td class="ellipsis">
                <span class="target-name">{{socialTarget.Name}}</span>
              </td>
              <td style="width: 18%;" class="text-right">
                <span v-if="socialTarget.EstimatedReach.toString().length > 5">
                  <i>{{Intl.NumberFormat().format(socialTarget.EstimatedReach).toString().slice(0, -4) + 'k'}}</i>
                </span>
                <span v-if="socialTarget.EstimatedReach.toString().length<=5">
                  <i>{{Intl.NumberFormat().format(socialTarget.EstimatedReach)}}</i>
                </span>
              </td>
              <td style="width: 5%;"></td>
              <td style="display: flex;justify-content: space-between;" class="target-tooltip">
                <span
                  :id="'DXT'+socialTarget.Id"
                  v-tooltip="'Click to copy'"
                  @click="copyToClipboard($event)"
                >DXT-{{Number.parseInt(businessProfileId).toString(36)}}-{{socialTarget.Id}}</span>
                <span>
                  <i
                    class="fa fa-info-circle social-target-info-tooltip"
                    aria-hidden="true"
                    v-tooltip="socialTarget.generatedTarget"
                    data-placement="left"
                    data-trigger="click"
                    data-toggle="tooltip"
                    data-html="true"
                  ></i>
                </span>
              </td>
            </template>
          </tr> -->
          </template>
      </VuePerfectScrollbar>
      <TargetCreationWrapper
        v-if="!deltax.isOldTargetCreationEnabled && businessProfilesSearchEngineId"
        :showModal="showModal"
        :selectedBpseId="Number(selectedCampaignLauncherConfig.bpseId)"
        @close-modal="close()"
    ></TargetCreationWrapper>
      <v-modal
        v-if="deltax.isOldTargetCreationEnabled && businessProfilesSearchEngineId"
        class="target-modal old-modal"
        title="Create Target"
        v-model="showModal"
        @closed="close()"
        effect="fade"
      >
        <div slot="modal-header" class="modal-header">
          <div style="display:flex;justify-content: space-between;">
            <span>
              <h4 class="modal-title" style="display: inline-block;margin-right:10px">Create Target</h4>
              <span v-if="!iFrameloaded">
                <i class="fa fa-spinner fa-spin fa-lg" />
              </span>
            </span>
            <span @click="showModal=false">
              <i class="fa fa-times fa-lg" />
            </span>
          </div>
        </div>
        <div
          slot="modal-body"
          class="modal-body"
          style="overflow: hidden;height: 530px;max-height: 530px"
        >
          <iframe
            id="ifr"
            :src="iframeUrl"
            width="100%"
            height="100%"
            marginwidth="0"
            marginheight="0"
            frameborder="no"
            scrolling="yes"
            style="background:#FFF;"
            @load="onIFrameLoad"
            v-show="iFrameloaded"
          ></iframe>
        </div>
        <div slot="modal-footer" class="modal-footer hide"></div>
      </v-modal>
    </Card>
  </div>
</template>
<script>
import { APIService } from "./../ApiService.js";
import { modal } from "vue-strap";
import { mapState, mapMutations } from "vuex";
import { Input, locale, Card, Row, Col } from "iview";
import lang from "iview/dist/locale/en-US";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import TargetCreationWrapper from "./Facebook/TargetCreationWrapper.vue";

locale(lang);
export default {
  name: "TargetWidget",
  components: {
    "v-modal": modal,
    Input,
    VuePerfectScrollbar,
    Card,
    "Row-Col": Col,
    Row,
    TargetCreationWrapper
  },

  data: function () {
    return {
      search: "",
      showModal: false,
      loader: false,
      socialTargetRequestData: {},
      socialTargets: [],
      iFrameloaded: false,
      iframeUrl: ""
    };
  },
  props: {
    getAudiences: { type: String, default: "" },
    createTarget: { type: String, default: "" },
    targets: { type: Array, default: () => { return [] } }
  },
  methods: {
    async getSocialTargets () {
      var self = this;
      // self.loader = true;
      if (this.businessProfilesSearchEngineId == 0 || !self.selectedCampaignLauncherConfigId) {
        self.loader = false;
        return;
      }
      var bpseTargets = this.savedTargetsMapperByBpseId[self.businessProfilesSearchEngineId];
      if (bpseTargets) {
        if (this.selectedCampaignLauncherConfig.adset.targetSelection == "By Label") {
          this.socialTargets = await this.getTargetsForConfigByLabels();
        } else {
          this.socialTargets = this.getTargetsMappedToConfig(bpseTargets);
        }
      }
      // APIService.GetSocialTargets(
      //   this.getAudiences,
      //   this.socialTargetRequestData
      // )
      //   .then(response => {
      //     return response.json().then(json => {
      //       for (var i = 0; i < json.data.length; i++) {
      //         json.data[i]["generatedTarget"] = self.generateTargetStringHtml(
      //           JSON.parse(json.data[i]["TargetInformation"]),
      //           false,
      //           false
      //         );
      //       }
      //       return json.data;
      //     });
      //   })
      //   .then(data => {
      //     self.loader = false;
      //     this.socialTargets = this.getTargetsMappedToConfig(data);
      //   });
    },
    generateTargetStringHtml (objTarget, isFlexibleTarget, isExclusion) {
      var self = this;
      var tableContainer = document.createElement("div");
      var table = document.createElement("table");
      tableContainer.appendChild(table);
      let locationString = "";
      let excludedLocationString = "";
      if (!isFlexibleTarget && !isExclusion) {
        table.setAttribute(
          "class",
          "target-info-table scrollable ps-container ps-active-y"
        );
        table.setAttribute("data-max-height", "600");
        table.setAttribute("data-max-width", "600");
      } else if (isFlexibleTarget || isExclusion) {
        table.setAttribute(
          "style",
          "border-color:gray !important; border-style: ridge; width:100%"
        );
      }

      if (objTarget.AgeGroup != null) {
        table.appendChild(
          this.commonHtmlElement("Age Group", objTarget.AgeGroup)
        );
      }
      if (objTarget.Gender != null) {
        table.appendChild(this.commonHtmlElement("Gender", objTarget.Gender));
      }
      if (objTarget.RelationshipStatus != null) {
        table.appendChild(
          this.commonHtmlElement(
            "Relationship Status",
            objTarget.RelationshipStatus
          )
        );
      }
      if (objTarget.InterestedIn != null) {
        table.appendChild(
          this.commonHtmlElement("Interested In", objTarget.InterestedIn)
        );
      }

      if (objTarget.Countries != null) {
        locationString += objTarget.Countries + " | ";
      }
      if (objTarget.Regions != null) {
        locationString += objTarget.Regions + " | ";
      }
      if (objTarget.Cities != null) {
        locationString += objTarget.Cities + " | ";
      }
      if (objTarget.Zips != null) {
        locationString += objTarget.Zips + " | ";
      }
      if (objTarget.GeoMarkets != null) {
        locationString += objTarget.GeoMarkets + " | ";
      }
      if (objTarget.Address != null) {
        locationString += objTarget.Address + " | ";
      }
      if (objTarget.LatLong != null) {
        locationString += objTarget.LatLong + " | ";
      }
      if (objTarget.LocationTypes != null) {
        locationString += objTarget.LocationTypes + " | ";
      }
      if (locationString !== "") {
        locationString = locationString
          .trim()
          .substring(0, locationString.length - 3);
        table.appendChild(this.commonHtmlElement("Locations", locationString));
      } else if (isFlexibleTarget === false && isExclusion === false) {
        table.appendChild(this.commonHtmlElement("Locations", "Not Available"));
      }

      if (objTarget.CountriesExcluded != null) {
        excludedLocationString += objTarget.CountriesExcluded + " | ";
      }
      if (objTarget.RegionsExcluded != null) {
        excludedLocationString += objTarget.RegionsExcluded + " | ";
      }
      if (objTarget.CitiesExcluded != null) {
        excludedLocationString += objTarget.CitiesExcluded + " | ";
      }
      if (objTarget.ZipsExcluded != null) {
        excludedLocationString += objTarget.ZipsExcluded + " | ";
      }
      if (objTarget.GeoMarketsExcluded != null) {
        excludedLocationString += objTarget.GeoMarketsExcluded + " | ";
      }
      if (objTarget.AddressExcluded != null) {
        excludedLocationString += objTarget.AddressExcluded + " | ";
      }
      if (objTarget.LatLongExcluded != null) {
        excludedLocationString += objTarget.LatLongExcluded + " | ";
      }
      if (objTarget.locationTypesExcluded != null) {
        excludedLocationString += objTarget.locationTypesExcluded + " | ";
      }
      if (excludedLocationString !== "") {
        excludedLocationString = excludedLocationString
          .trim()
          .substring(0, excludedLocationString.length - 3);
        table.appendChild(
          this.commonHtmlElement("Excluded Locations", excludedLocationString)
        );
      } else if (isFlexibleTarget === false && isExclusion === false) {
        table.appendChild(
          this.commonHtmlElement("Excluded Locations", "Not Available")
        );
      }

      if (
        objTarget.flexible_spec != null &&
        !$.isEmptyObject(objTarget.flexible_spec[0])
      ) {
        var getTargetForFlexible;

        objTarget.flexible_spec.forEach(function (ele, index) {
          getTargetForFlexible = self.generateTargetStringHtml(
            ele,
            true,
            false
          );
          table.appendChild(
            self.commonHtmlElement(
              "Flexible Target " + (index + 1),
              getTargetForFlexible
            )
          );
        });
      }
      if (objTarget.exclusion != null) {
        var getTargetExclusion = self.generateTargetStringHtml(
          objTarget.exclusion,
          false,
          true
        );
        table.appendChild(
          this.commonHtmlElement("Exclusion Target", getTargetExclusion)
        );
      }
      if (objTarget.SocialInterest != null) {
        table.appendChild(
          this.commonHtmlElement("Interests", objTarget.SocialInterest)
        );
      }
      if (objTarget.SocialExcludedInterest != null) {
        table.appendChild(
          this.commonHtmlElement(
            "Excluded Interests",
            objTarget.SocialExcludedInterest
          )
        );
      }
      if (objTarget.Behaviors != null) {
        table.appendChild(
          this.commonHtmlElement("Behaviors", objTarget.Behaviors)
        );
      }
      if (objTarget.EducationLevel != null) {
        table.appendChild(
          this.commonHtmlElement("Education Level", objTarget.EducationLevel)
        );
      }
      if (objTarget.FieldsOfStudy != null) {
        table.appendChild(
          this.commonHtmlElement("Fields Of Study", objTarget.FieldsOfStudy)
        );
      }
      if (objTarget.Schools != null) {
        table.appendChild(this.commonHtmlElement("Schools", objTarget.Schools));
      }
      if (objTarget.UndergradYears != null) {
        table.appendChild(
          this.commonHtmlElement("Undergrad Years", objTarget.UndergradYears)
        );
      }
      if (objTarget.Employer != null) {
        table.appendChild(
          this.commonHtmlElement("Employer", objTarget.Employer)
        );
      }
      if (objTarget.JobTitles != null) {
        table.appendChild(
          this.commonHtmlElement("Job Titles", objTarget.JobTitles)
        );
      }
      if (objTarget.Industries != null) {
        table.appendChild(
          this.commonHtmlElement("Industries", objTarget.Industries)
        );
      }
      if (objTarget.CustomAudiances != null) {
        table.appendChild(
          this.commonHtmlElement("Custom Audiences", objTarget.CustomAudiances)
        );
      }
      if (objTarget.ExcludedAudiances != null) {
        table.appendChild(
          this.commonHtmlElement(
            "Excluded Audiences",
            objTarget.ExcludedAudiances
          )
        );
      }
      if (objTarget.Connections != null) {
        table.appendChild(
          this.commonHtmlElement("Connections", objTarget.Connections)
        );
      }
      if (objTarget.ExcludedConnections != null) {
        table.appendChild(
          this.commonHtmlElement(
            "Excluded Connections",
            objTarget.ExcludedConnections
          )
        );
      }
      if (objTarget.FriendsOfConnections != null) {
        table.appendChild(
          this.commonHtmlElement(
            "Friends Of Connections",
            objTarget.FriendsOfConnections
          )
        );
      }
      if (objTarget.EthenicAffinity != null) {
        table.appendChild(
          this.commonHtmlElement("Ethnic Affinity", objTarget.EthenicAffinity)
        );
      }
      if (objTarget.Generations != null) {
        table.appendChild(
          this.commonHtmlElement("Generations", objTarget.Generations)
        );
      }
      if (objTarget.AllParents != null) {
        table.appendChild(
          this.commonHtmlElement("All Parents", objTarget.AllParents)
        );
      }
      if (objTarget.Politics != null) {
        table.appendChild(
          this.commonHtmlElement("Politics", objTarget.Politics)
        );
      }
      if (objTarget.LifeEvents != null) {
        table.appendChild(
          this.commonHtmlElement("Life Events", objTarget.LifeEvents)
        );
      }
      if (objTarget.UserDevice != null) {
        table.appendChild(
          this.commonHtmlElement("Devices", objTarget.UserDevice)
        );
      }
      if (objTarget.UserOs != null) {
        table.appendChild(this.commonHtmlElement("User OS", objTarget.UserOs));
      }
      if (objTarget.WirelessCarrier != null) {
        table.appendChild(
          this.commonHtmlElement("Wireless Carrier", objTarget.WirelessCarrier)
        );
      }
      if (objTarget.Markets != null) {
        table.appendChild(this.commonHtmlElement("Markets", objTarget.Markets));
      }
      if (objTarget.Income != null) {
        table.appendChild(this.commonHtmlElement("Income", objTarget.Income));
      }
      if (objTarget.HomeType != null) {
        table.appendChild(
          this.commonHtmlElement("Home Type", objTarget.HomeType)
        );
      }
      if (objTarget.NetWorth != null) {
        table.appendChild(
          this.commonHtmlElement("Net Worth", objTarget.NetWorth)
        );
      }
      if (objTarget.HomeOwnership != null) {
        table.appendChild(
          this.commonHtmlElement("Home Ownership", objTarget.HomeOwnership)
        );
      }
      if (objTarget.HouseholdComposition != null) {
        table.appendChild(
          this.commonHtmlElement(
            "Household Composition",
            objTarget.HouseholdComposition
          )
        );
      }
      if (objTarget.Moms != null) {
        table.appendChild(this.commonHtmlElement("Moms", objTarget.Moms));
      }
      if (objTarget.Placements != null) {
        table.appendChild(
          this.commonHtmlElement("Placements", objTarget.Placements)
        );
      }

      if (objTarget.ProductAudienceSpec != null) {
        var finalHtmlEl = document.createElement("div");
        var br = document.createElement("br");
        if (objTarget.ProductAudienceSpec.ProductSetId != null) {
          let titleSpan = document.createElement("span");
          let b = document.createElement("b");
          b.innerHTML = "Product set id: ";
          titleSpan.appendChild(b);

          let ValueSpan = document.createElement("span");
          ValueSpan.innerHTML = objTarget.ProductAudienceSpec.ProductSetId;
          finalHtmlEl.appendChild(titleSpan);
          finalHtmlEl.appendChild(ValueSpan);
          finalHtmlEl.appendChild(br);
        }
        if (objTarget.ProductAudienceSpec.Inclusions != null) {
          let titleSpan = document.createElement("span");
          let b = document.createElement("b");
          b.innerHTML = "Inclusions: ";
          titleSpan.appendChild(b);

          let ValueSpan = document.createElement("span");
          ValueSpan.innerHTML = objTarget.ProductAudienceSpec.Inclusions;
          finalHtmlEl.appendChild(titleSpan);
          finalHtmlEl.appendChild(ValueSpan);
          finalHtmlEl.appendChild(br);
        }
        if (objTarget.ProductAudienceSpec.Exclusions != null) {
          let titleSpan = document.createElement("span");
          let b = document.createElement("b");
          b.innerHTML = "Exclusions: ";
          titleSpan.appendChild(b);

          let ValueSpan = document.createElement("span");
          ValueSpan.innerHTML = objTarget.ProductAudienceSpec.Exclusions;
          finalHtmlEl.appendChild(titleSpan);
          finalHtmlEl.appendChild(ValueSpan);
          finalHtmlEl.appendChild(br);
        }
        table.appendChild(
          this.commonHtmlElement("Product Audience Spec", finalHtmlEl.innerHTML)
        );
      }
      return table.outerHTML;
    },
    copyToClipboard (value) {
      let textarea = document.createElement("textarea")
      textarea.textContent = value
      textarea.style.position = "fixed"
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand("copy")
      } catch (e) {
        alert('Unable to copy to clipboard. Please copy manually.')
      }
      document.body.removeChild(textarea)
    },
    setSocialTargetRequestData () {
      let targetRequest = {
        saveFilter: "false",
        param: {
          columns: [
            {
              data: "Id",
              name: "Id",
              searchable: true,
              orderable: false,
              search: { value: "", regex: false }
            }
          ],
          order: [{ column: 0, dir: "desc" }],
          start: 0,
          length: 10000000
        },
        businessProfileSearchEngineId: [this.businessProfilesSearchEngineId]
      };
      this.socialTargetRequestData = targetRequest;
    },
    commonHtmlElement (name, value) {
      let tr = document.createElement("tr");
      let tdKey = document.createElement("td");
      tdKey.setAttribute("style", "width:75px; vertical-align:top");
      tdKey.innerHTML = name;

      let tdValue = document.createElement("td");
      tdValue.setAttribute("style", "text-align : left !important");
      if (value.toString().includes("HTMLTable")) {
        tdValue.appendChild(value);
      } else {
        tdValue.innerHTML = value;
      }

      tr.appendChild(tdKey);
      tr.appendChild(tdValue);
      return tr;
    },
    onIFrameLoad () {
      this.iFrameloaded = true;
    },
    loadIframe () {
      this.showModal = true;
      this.iframeUrl = this.createTarget.replaceAll('amp;', '') + this.businessProfilesSearchEngineId
      this.$store.commit('set_selectedBpseId', this.businessProfilesSearchEngineId);
      this.$store.dispatch("generate_TargetAudience");
    },
    close () {
      // check if there are any newly created targets after closing. If yes, they need to be added to store
      let self = this;
      this.showModal = false;
      if (window.campaignLauncherSavedTargets && window.campaignLauncherSavedTargets.length > 0) {
        let savedTargetsMapperByBpseId = this.savedTargetsMapperByBpseId;
        let newTargets = window.campaignLauncherSavedTargets;
        newTargets.forEach(e => {
          savedTargetsMapperByBpseId[self.businessProfilesSearchEngineId].push({ Id: e.id, Name: e.name });
        })
        let seenLabels = new Set();
        window.campaignLauncherNewLabels.forEach(label => {
          if (!seenLabels.has(label.Name)) { // prevents adding dublicate labels.
            seenLabels.add(label.Name);
            this.labels.push(label);
          }
        })
        window.campaignLauncherNewLabels = [];
        window.campaignLauncherSavedTargets = [];
        this.set_savedTargetsMapperByBpseId(savedTargetsMapperByBpseId);
        this.getSocialTargets();
      }
    },
    getTargetsMappedToConfig (targets) {
      let mappedTargetIds = [];
      this.mediaPlanLineItems.forEach(element => {
        if (element.config === this.selectedCampaignLauncherConfigId) {
          mappedTargetIds.push(element.targets);
        }
      })
      mappedTargetIds = [...new Set(mappedTargetIds.flat())];
      var targetsMapped = targets.filter(function (obj) {
        return mappedTargetIds.some(function (obj2) {
          return obj.Id == obj2;
        });
      });
      return targetsMapped;
    },
    async getTargetsForConfigByLabels () {
      let mappedlabelIds = [];
      this.mediaPlanLineItems.forEach(element => {
        if (element.config === this.selectedCampaignLauncherConfigId) {
          mappedlabelIds.push(element.labels);
        }
      })
      mappedlabelIds = [...new Set(mappedlabelIds.flat())];
      if (mappedlabelIds.length) {
        var response = await APIService.getTargetsByLabelIds(this.businessProfilesSearchEngineId, mappedlabelIds.join(","));
        let finalData = response.data.map(x => {
          return {
            ...x,
            Id: x.id,
            Name: x.name,
            EstimatedReach: x.estimatedReach
          };
        });
        return finalData;
      } else {
        this.getTargetsMappedToConfig();
      }
    },
    ...mapMutations(["set_savedTargetsMapperByBpseId"])
  },
  mounted () {
    this.socialTargets = this.targets;
    this.setSocialTargetRequestData();
    this.getSocialTargets();
  },
  computed: {
    ...mapState(["businessProfilesSearchEngineId", "businessProfileId", "savedTargetsMapperByBpseId", "labels", "selectedCampaignLauncherConfigId", "mediaPlanLineItems", "deltax", "selectedBpseId", "selectedCampaignLauncherConfig"]),
    filteredTargets () {
      let searchTerm = this.search;
      if (searchTerm != "") {
        return this.socialTargets.filter(e => {
          return e.Name.toLowerCase().includes(searchTerm.toLowerCase())
        })
      } else {
        return this.socialTargets;
      }
    }
  },
  watch: {
    businessProfilesSearchEngineId () {
      this.iFrameloaded = false;
      this.socialTargets = this.targets;
      this.setSocialTargetRequestData();
      // this.getSocialTargets();
    },
    selectedCampaignLauncherConfigId () {
      this.getSocialTargets();
    },
    mediaPlanLineItems () {
      this.getSocialTargets();
    }
  }
};
</script>
<style scoped>
.widget-body > table {
  width: 100%;
}
.widget-body > table > tr > td {
  padding-top: 10px;
}
.widget-element {
  height: 30px;
}
.widget > hr {
  margin: 0px;
}
.loader-div {
  width: inherit;
  text-align: center;
  height: inherit;
}
.ellipsis {
  width: 50%;
  position: relative;
}
.ellipsis:before {
  content: "&nbsp;";
  visibility: hidden;
}
.ellipsis span {
  width: 190px;
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.old-modal {
  margin-top: 50px;
}
</style>

<style>
.target-tooltip .tooltip .tooltip-inner {
  max-width: 600px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: normal;
  word-break: initial;
  padding: 5px 10px;
}
.target-info-table {
  max-width: 300px !important;
  min-width: 250px !important;
}
.target-modal .modal-dialog {
  width: 98% ;
  height: 100%;
  z-index: 9997;
}
.input-search-bar .ivu-input {
  height: 30px;
}
.tooltip-size {
  max-width: 500px;
  max-height: 200px;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
