import axiosInstance from '../../../../Api';

const baseUrl = deltax.apiEndPoint;
// const baseUrl = 'https://localhost:44359/v1.0/';
const businessProfileId = deltax.businessProfileId;
const htmlEndpoint = deltax.htmlApiEndpoint;

var GetAdPreview = function (sheetInformation, successCb, failCb, doneCb) {
  $.getJSON(deltax.new, { sheetInformation }, function (response) {
    successCb(response);
  }).fail(failCb).always(doneCb);
}
var PublishToApi = function (
  publishSummary,
  publishRequestId,
  successCb,
  failCb,
  doneCb,
  selectedCampaignLauncherConfigId,
  mediaPlanId,
  businessProfilesSearchEngineId,
  publishedCreatives,
  mediaPlanLineItems,
  campaignPublishDataLogId,
  mediaPlanName,
  isPromotionMode,
  currentVersionOfGeneratedEntities,
  chunkPublishId = ""
) {
  var postData = {
    data: JSON.stringify(publishSummary),
    requestId: publishRequestId,
    configId: selectedCampaignLauncherConfigId,
    mediaPlanId: mediaPlanId,
    businessProfilesSearchEngineId: businessProfilesSearchEngineId,
    creativeJson: JSON.stringify(publishedCreatives),
    mediaPlanLineItems: JSON.stringify(mediaPlanLineItems),
    mediaPlanName: mediaPlanName,
    isPromotionCampaignLaunch: isPromotionMode,
    currentVersionOfGeneratedEntities: currentVersionOfGeneratedEntities,
    chunkPublishId
  };
  if (campaignPublishDataLogId > 0) {
    postData.campaignPublishDataLogId = campaignPublishDataLogId;
  }
  $.post(deltax.uploadToApi, postData, function (response) {
    successCb(response);
  })
    .fail(function (response) {
      failCb(response);
    })
    .always(function (response) {
      doneCb(response);
    });
};
var SendSubmissionEmail = function (postData, successCb, failCb, doneCb) {
  var url = "/App/CampaignLauncher/SendSubmissionEmail/" + businessProfileId;
  $.post(url, postData, function (response) {
    if (successCb) { successCb(response); }
  })
    .fail(function (response) {
      if (failCb) { failCb(response); }
    })
    .always(function (response) {
      if (doneCb) { doneCb(response); }
    });
}
var PublishToApiAdWords = function (postData, successCb, failCb, doneCb) {
  $.post(deltax.uploadToApiAdwords, postData, function (response) {
    successCb(response);
  }).fail(function (response) {
    failCb(response);
  }).always(function () {
    doneCb();
  });
}
var PublishRequestToApi = function (data, successCb, failCb, doneCb, isFacebook = false) {
  // in case of submission, use the data log id instead of the publish stage id
  let id = data.campaignPublishDataLogId && isFacebook ? data.campaignPublishDataLogId : data.id;
  let url = baseUrl + "businessProfiles/" + businessProfileId + "/PublishStageData/" + id + "/upload";
  let body = JSON.stringify(data)
  let config = {
    headers: {
      "Content-Type": "application/json"
    }
  }
  axiosInstance.post(url, body, config).then(response => successCb(response))
    .catch(error => failCb(error))
    .finally(() => {
      if (doneCb) { doneCb() }
    })
}
var Dv360TrueViewBotDeploy = function (data, successCb, failCb, doneCb) {
  let url = baseUrl + "businessProfiles/" + businessProfileId + "/PublishStageData/" + data.id + "/botUpload";
  let body = JSON.stringify(data)
  let config = {
    headers: {
      "Content-Type": "application/json"
    }
  }
  axiosInstance.post(url, body, config).then(response => successCb(response))
    .catch(() => failCb())
    .finally(() => doneCb())
}
var DownloadDv360TrueViewZip = function (data, successCb, failCb, doneCb) {
  let url = baseUrl + "businessProfiles/" + businessProfileId + "/PublishStageData/" + data.id + "/download";

  var filename = "SDF.zip";
  var request = new XMLHttpRequest();
  request.open("POST", url);
  request.setRequestHeader(
    "Content-Type",
    "application/json;charset=UTF-8"
  );
  const accessToken = $.cookie("AccessToken");
  if (accessToken) {
    request.setRequestHeader('Authorization', 'Bearer ' + accessToken);
  }
  request.responseType = "blob";
  request.onload = function () {
    if (request.readyState === 4) {
      if (request.status === 200) {
        let link = document.createElement("a");
        document.body.appendChild(link);
        link.href = window.URL.createObjectURL(request.response);
        link.download = filename;
        link.click();
        successCb();
      } else {
        failCb();
      }
      doneCb();
    }
  };

  request.send(
    JSON.stringify(data)
  );
}
var GetPublishStatus = function (data, ifPublishInProgress, successCb, publishDone, intervalDuration = 10000) {
  var url = `${baseUrl}businessProfiles/${businessProfileId}/PublishStageData/livePublishStatus`
  $.getJSON(url, data, function (response) {
    if (!publishDone) {
      successCb(response);
      if (ifPublishInProgress()) {
        setTimeout(function () {
          GetPublishStatus(data, ifPublishInProgress, successCb, false)
        }, intervalDuration);
      } else {
        GetPublishStatus(data, ifPublishInProgress, successCb, true);
      }
    } else {
      successCb(response);
    }
  }).fail(function (response) {
    console.log(response);
  }).always(function (response) {
    // console.log(response);
  });
}
var GetPreviews = function (accountId, creative, data, successCb, failCb) {
  $.getJSON(deltax.getPreviews, { accountId, creative, data }, function (response) {
    successCb(response);
  }).fail(function (response) {
    failCb(response);
  }).always(function (response) {
    // console.log(response);
  });
}
var GetBusinessProfile = async function () {
  var response = await axiosInstance.get(`${baseUrl}businessProfiles/${deltax.businessProfileId}`)
  return response.data.data;
}
var GetBPUsers = async function () {
  var response = await axiosInstance.get(`${baseUrl}businessProfiles/${deltax.businessProfileId}/users`)
  return response.data.data;
}
var GetAccountRelatedEntities = async (bpid, bpseId) => {
  const url = "/App/CampaignLauncher/GetAccountRelatedEntities/" + bpid + "?bpseId=" + bpseId
  const response = await fetch(url)
  return response.json()
}
var GenerateAdPreview = function (creativeSpec, businessProfilesSearchEngineId, successCb, failCb, accountId = "") {
  $.post(deltax.generateAdPreview, { creative: creativeSpec, businessProfilesSearchEngineId: businessProfilesSearchEngineId, accountId: accountId }, function (response) {
    successCb(response);
  }).fail(function (response) {
    failCb(response);
  })
}
var GenerateMetaAdPreview = function (id, creativeSpec, accountId, successCb, failCb) {
  var url = deltaXApiUrl.launcherAds.generateMetaAdPreview
    .replace('{businessProfileId}', businessProfileId)
    .replace('{accountId}', accountId);
  var body = {
    id: id,
    creativeSpec: creativeSpec };

  axiosInstance.post(url, body).then(response => successCb(response))
    .catch(error => failCb(error))
}
var GetAdCreationSheet = function (engineTypeId, successCb) {
  $.getJSON(deltax.getSheetInfo, { engineTypeId }, function (response) {
    successCb(response);
  }).fail(function (response) {
    console.log(response);
  }).always(function (response) {
    // console.log(response);
  });
}
var RefreshSheet = function (sheetId, successCb, failCb) {
  $.getJSON(deltax.refreshSheet, { sheetId }, function (response) {
    successCb(response);
  }).fail(function (response) {
    failCb(response);
  }).always(function (response) {
    // console.log(response);
  });
}
var UpdateIds = function (sheetId, groupedData, successCb) {
  var postData = { groupedData: JSON.stringify(groupedData), sheetId: sheetId };
  $.post(deltax.updateSheetIds, postData, function (response) {
    successCb(response);
  }).fail(function (response) {
    console.log(response);
  }).always(function (response) {
    // console.log(response);
  });
}
var SyncPublishedCampaigns = function (groupedData, successCb) {
  var postData = { groupedData: JSON.stringify(groupedData) };
  $.post(deltax.SyncPublishedCampaigns, postData, function (response) {
    successCb(response);
  }).fail(function (response) {
    console.log(response);
  }).always(function (response) {
    // console.log(response);
  });
}

var addSubmission = function (data, userId, portalId, selectedCampaignLauncherConfigId, publishedCreatives, mediaPlanId, authFlow, name, mediaPlanName, callback, elementsTabHtml, editedSummaryData, publishStageDataId = null) {
  var url = `${baseUrl}businessProfiles/${businessProfileId}/submissions`
  var body = {
    data: JSON.stringify(data),
    userId: userId,
    portalId: portalId,
    configId: selectedCampaignLauncherConfigId,
    creativeJson: JSON.stringify(publishedCreatives),
    mediaPlanId: mediaPlanId,
    authFlow: JSON.stringify(authFlow),
    reviewName: name,
    mediaPlanName: mediaPlanName,
    elementsTabHtml: elementsTabHtml,
    editedSummaryData: editedSummaryData,
    publishAdsStageDataId: publishStageDataId };

  axiosInstance.post(url, body).then(response => callback(response))
}

var updateSubmission = function (submissionId, submissionAuthFlow, mediaPlanId, userId, callback, isNewFlow = false) {
  var url = `${baseUrl}businessProfiles/${businessProfileId}/submissions/${submissionId}`
  var body = {
    Id: submissionId,
    AuthFlow: JSON.stringify(submissionAuthFlow),
    mediaPlanId: mediaPlanId,
    userId: userId,
    status: submissionAuthFlow.Status ? submissionAuthFlow.Status : '',
    isNewFlow: isNewFlow
  };

  axiosInstance.patch(url, body).then(response => callback(response))
}
var getTargetsReach = function (bp, bpse, targets, optimizeFor, storeCodes, successCb) {
  var parameters = {
    businessProfileId: bp,
    businessProfile_SearchengineId: bpse,
    targetIds: targets,
    optimizeFor: optimizeFor,
    storeCodes: storeCodes
  };
  jQuery.ajaxSettings.traditional = true;
  $.getJSON(deltax.getTargetsReach, parameters, function (response) {
    successCb(response);
  }).fail(function (response) {
    console.log(response);
  });
}
var get = async (url) => {
  const response = await fetch(url)
  return response.json()
}
var getPublishData = (mediaPlanId, configId, searchEngineTypeId) => {
  return axiosInstance.get(
    baseUrl +
      "businessProfiles/" +
      businessProfileId +
      "/PublishStageData?mediaPlanId=" + mediaPlanId +
      "&configId=" + configId +
      "&searchEngineTypeId=" + searchEngineTypeId
  );
}
var getLiveEntityFieldsFromApi = async (requestObject, successCb, failCb, doneCb) => {
  var additionalUrl = "";
  if (requestObject.entityType == "adgroups") {
    additionalUrl = "/" + requestObject.CampaignId + "/adgroups"
  }
  var url = baseUrl
    .concat("businessProfiles/")
    .concat(businessProfileId)
    .concat("/accounts/")
    .concat(requestObject.bpseId)
    .concat("/live")
    .concat("/campaigns")
    .concat(additionalUrl)
    .concat("?ids=")
    .concat(requestObject.ids);

  axiosInstance.get(url).then(response => successCb(response, requestObject))
    .catch(error => failCb(error))
    .finally(() => doneCb())
}

var GetLineitemCampaignConfigurationData = async (campaginConfigurationId, mediaPlanIds, successCb, failCb) => {
  var url = baseUrl
    .concat("businessProfiles/")
    .concat(businessProfileId)
    .concat("/campaignConfigurations/")
    .concat(campaginConfigurationId)
    .concat("/LineItemCampaignConfigurations")
    .concat("?mediaPlanIds=")
    .concat(mediaPlanIds);

  axiosInstance.get(url).then(response => successCb(response))
    .catch(error => failCb(error))
}
var GetReachAndFrequencyPredictionHistory = async (lineItemId, targetId = null, successCb, failCb) => {
  var url = baseUrl
    .concat("businessProfiles/")
    .concat(businessProfileId)
    .concat("/lineitems/")
    .concat(lineItemId)
    .concat("/ReachAndFrequencyPredictionLogs")
    .concat("?targetId=")
    .concat(targetId);

  axiosInstance.get(url).then(response => successCb(response))
    .catch(error => failCb(error))
}
var GetPredictionStatusInBulk = async (bpseId, predictionIds) => {
  var predictions = await axiosInstance.get(`${baseUrl}businessProfiles/${businessProfileId}/accounts/${bpseId}/live/reachAndFrequency/predictionStatus?predictionIds=${predictionIds}`);
  return predictions.data
}

var GenerateRnFPrediction = async (failedPredictionId, data, successCb, failCb) => {
  var url = "/App/CampaignLauncher/GenerateReachAndFrequencyPrediction/" + businessProfileId;
  axiosInstance.post(url, data).then(response => successCb(response, failedPredictionId, data))
    .catch(error => failCb(error))
}

var updateLabelDimensionLabels = (data) => {
  return axiosInstance.patch(
    baseUrl +
      "businessProfiles/" +
      businessProfileId +
      "/labelTypes",
    JSON.stringify(JSON.stringify(data))
  );
}

var getEntitiesToPublishCount = async (businessProfileId, publishStageDataId, isRetryEnabled, campaignPublishDataLogId, isCampaignPublishOnly) => {
  let response = await axiosInstance.get(`${baseUrl}businessProfiles/${businessProfileId}/PublishStageData/getEntitiesToPublishCount`, {
    params: {
      isRetryEnabled,
      campaignPublishDataLogId,
      publishStageDataId,
      isCampaignPublishOnly
    }
  });
  return response.data;
}

var SyncEntites = async (mediaPlanId, configId) => {
  return axiosInstance.post(`${baseUrl}businessProfiles/${businessProfileId}/publishStageData/syncEntites`,
    {
      mediaPlanId,
      configId
    }
  );
}

var GetSubmission = (submissionId, isNewLauncherFlow = false) => {
  return axiosInstance.get(`${baseUrl}businessProfiles/${businessProfileId}/Submissions/${submissionId}?isNewLauncherFlow=${isNewLauncherFlow}`);
}

var SetLivePublishStatus = (requestId, finalGroupedEntities) => {
  return axiosInstance.post(`${baseUrl}businessProfiles/${businessProfileId}/PublishStageData/livePublishStatus`,
    {
      requestId,
      finalGroupedEntities
    }
  );
}

var GetAmsAdPreview = async (asin) => {
  var EndPoint = htmlEndpoint + `Amazon?asin=`;
  axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  let response = await axiosInstance.get(EndPoint.concat(asin));
  return response;
}

var GetCampaignsSummaryData = async function (businessProfileId, campaignLauncherPublishDataId, submissionId, statusIds, isSubmissionOnly) {
  var url = deltaXApiUrl.campaignLauncherPublishData.getCampaignsByFilters
    .replace('{businessProfileId}', businessProfileId)
    .replace('{campaignLauncherPublishDataId}', campaignLauncherPublishDataId)
  var params = {}
  if (submissionId) {
    params.submissionId = submissionId
  } if (statusIds) {
    params.statusIds = statusIds
  } if (isSubmissionOnly) {
    params.isSubmissionOnly = isSubmissionOnly
  }
  let response = await axiosInstance.get(url, {
    params
  })
  return response
}

var GetAdgroupsSummaryData = async function (businessProfileId, campaignLauncherPublishDataId, submissionId, statusIds, isSubmissionOnly) {
  var url = deltaXApiUrl.campaignLauncherPublishData.getAdgroupsByFilters
    .replace('{businessProfileId}', businessProfileId)
    .replace('{campaignLauncherPublishDataId}', campaignLauncherPublishDataId)
  var params = {}
  if (submissionId) {
    params.submissionId = submissionId
  } if (statusIds) {
    params.statusIds = statusIds
  } if (isSubmissionOnly) {
    params.isSubmissionOnly = isSubmissionOnly
  }
  let response = await axiosInstance.get(url, {
    params
  })
  return response
}

var GetAdsSummaryData = async function (businessProfileId, campaignLauncherPublishDataId, submissionId, statusIds, isSubmissionOnly) {
  var url = deltaXApiUrl.campaignLauncherPublishData.getAdsByFilters
    .replace('{businessProfileId}', businessProfileId)
    .replace('{campaignLauncherPublishDataId}', campaignLauncherPublishDataId)
  var params = {}
  if (submissionId) {
    params.submissionId = submissionId
  } if (statusIds) {
    params.statusIds = statusIds
  } if (isSubmissionOnly) {
    params.isSubmissionOnly = isSubmissionOnly
  }
  let response = await axiosInstance.get(url, {
    params
  })
  return response
}

var GetEntitiesData = async function (businessProfileId, campaignLauncherPublishDataId, submissionId) {
  var url = deltaXApiUrl.campaignLauncherPublishData.getEntitiesByFilters
    .replace('{businessProfileId}', businessProfileId)
    .replace('{campaignLauncherPublishDataId}', campaignLauncherPublishDataId)
  var params = {}
  if (submissionId) {
    params.submissionId = submissionId
  }
  let response = await axiosInstance.get(url, {
    params
  })
  return response
}

var UploadToAPi = async function (businessProfileId, campaignLauncherPublishDataId, postData, successCb, failCb, doneCb) {
  var url = deltaXApiUrl.campaignLauncherPublishData.upload
    .replace('{businessProfileId}', businessProfileId)
    .replace('{campaignLauncherPublishDataId}', campaignLauncherPublishDataId)
  axiosInstance.post(url, postData).then(function (response) {
    successCb(response);
  }).catch(function (response) {
    failCb(response);
  }).finally(function (response) {
    doneCb(response);
  });
}

var ValidateSubmission = function (businessProfileId, campaignLauncherPublishDataId, postData, callback) {
  var url = deltaXApiUrl.campaignLauncherPublishData.validateSubmission
    .replace('{businessProfileId}', businessProfileId)
    .replace('{campaignLauncherPublishDataId}', campaignLauncherPublishDataId)
  axiosInstance.post(url, postData).then(response => callback(response))
}

var EditPublishStageData = async function (businessProfileId, publishStageDataId, body) {
  var url = deltaXApiUrl.publishStageData.editPublishData
    .replace('{businessProfileId}', businessProfileId)
    .replace('{publishStageDataId}', publishStageDataId)
  let response = await axiosInstance.patch(url, body)
  return response;
}

var GetAccountById = async function (businessProfileId, id) {
  var url = deltaXApiUrl.accounts.getById.replace('{accountId}', id).replace('{businessProfileId}', businessProfileId)
  var bpse = await axiosInstance.get(url)
  return bpse.data.data
}

var GetTargetsbyIds = async function (businessProfileId, query) {
  var url = deltaXApiUrl.targets.getByIds.replace('{businessProfileId}', businessProfileId)
  var targets = await axiosInstance.get(url + "?type=0&ids=" + query)
  return targets.data.data
}

var SyncEntitiesAfterPublish = function (businessProfileId, campaignLauncherPublishDataId) {
  var url = deltaXApiUrl.campaignLauncherPublishData.syncEntities
    .replace('{businessProfileId}', businessProfileId)
    .replace('{campaignLauncherPublishDataId}', campaignLauncherPublishDataId)
  return axiosInstance.post(url)
}

var LinkCampaignsToLineItems = function (businessProfileId, campaignLauncherPublishDataId) {
  var url = deltaXApiUrl.campaignLauncherPublishData.linkCampaignsToLineItems
    .replace('{businessProfileId}', businessProfileId)
    .replace('{campaignLauncherPublishDataId}', campaignLauncherPublishDataId)
  return axiosInstance.post(url)
}

export default {
  GetAdPreview: GetAdPreview,
  GetAmsAdPreview: GetAmsAdPreview,
  PublishToApi: PublishToApi,
  GetBusinessProfile: GetBusinessProfile,
  PublishToApiAdWords: PublishToApiAdWords,
  PublishRequestToApi: PublishRequestToApi,
  DownloadDv360TrueViewZip,
  GetPublishStatus: GetPublishStatus,
  GetPreviews: GetPreviews,
  GetAdCreationSheet: GetAdCreationSheet,
  RefreshSheet: RefreshSheet,
  UpdateIds: UpdateIds,
  SyncPublishedCampaigns: SyncPublishedCampaigns,
  AddSubmission: addSubmission,
  UpdateSubmission: updateSubmission,
  GetTargetsReach: getTargetsReach,
  GenerateAdPreview: GenerateAdPreview,
  Get: get,
  GetLiveEntityData: getLiveEntityFieldsFromApi,
  GetBPUsers: GetBPUsers,
  GetPublishedData: getPublishData,
  GetLineitemCampaignConfigurationData: GetLineitemCampaignConfigurationData,
  Dv360TrueViewBotDeploy: Dv360TrueViewBotDeploy,
  GetAccountRelatedEntities: GetAccountRelatedEntities,
  GetReachAndFrequencyPredictionHistory: GetReachAndFrequencyPredictionHistory,
  GetPredictionStatusInBulk: GetPredictionStatusInBulk,
  GenerateRnFPrediction: GenerateRnFPrediction,
  UpdateLabelDimensionLabels: updateLabelDimensionLabels,
  SendSubmissionEmail: SendSubmissionEmail,
  GetEntitiesToPublishCount: getEntitiesToPublishCount,
  SyncEntites: SyncEntites,
  GetSubmission: GetSubmission,
  SetLivePublishStatus: SetLivePublishStatus,
  GetCampaignsSummaryData: GetCampaignsSummaryData,
  GetAdgroupsSummaryData: GetAdgroupsSummaryData,
  GetAdsSummaryData: GetAdsSummaryData,
  GetEntitiesData: GetEntitiesData,
  UploadToAPi: UploadToAPi,
  ValidateSubmission: ValidateSubmission,
  GetAccountById: GetAccountById,
  GetTargetsbyIds: GetTargetsbyIds,
  SyncEntitiesAfterPublish: SyncEntitiesAfterPublish,
  LinkCampaignsToLineItems: LinkCampaignsToLineItems,
  GenerateMetaAdPreview: GenerateMetaAdPreview,
  EditPublishStageData: EditPublishStageData
};
