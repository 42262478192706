<template>
  <div class="ad-preview">
    <div :class="{ 'mobile-preview': ad.type == adTypes.CallAd || isMobilePreview, 'desktop-preview': !isMobilePreview}">
      <div class="url-section d-flex">
        <span class="ad-text">
          Ad
          <span class="ad-dot">·</span>
        </span>
        <span class="display-url">{{ displayUrl }}</span>
        <span class="down-arrow" v-if="!isMobilePreview && ad.type != adTypes.CallAd"></span>
        <Icon
          class="info-icon"
          type="md-information-circle"
          v-if="ad.type == adTypes.CallAd || isMobilePreview"
        />
      </div>
      <div class="headline-section">
        <Icon
          class="action"
          type="md-call"
          v-if="ad.type == adTypes.CallAd"
          size="32"
        />
        <div class="headline-group">
          <div class="preview-headline" v-if="ad.type == adTypes.CallAd">
            <div class="headline">{{ ad.phoneNumber }}</div>
            <div class="subheadline">{{ ad.name }} · {{ headlinesToShow }}</div>
          </div>
          <span v-else class="headline">{{ headlinesToShow }}</span>
        </div>
      </div>
      <div class="description-section">
        <div class="description-group">
          <span
            class="description-line"
            v-for="(discreption, i) in descriptionsToShow"
            :key="i"
            >{{ discreption }}.</span
          >
        </div>
      </div>
      <div class="action-item" v-if="ad.type == adTypes.CallAd">
        <Icon class="action-item-icon" type="md-globe" />
        <span class="action-item-text">Visit website</span>
      </div>
    </div>
    </div>
</template>
<script>
import { adwordsEnums } from "../../Constants/AdwordsEnums";
import { Icon } from "iview";

export default {
  props: {
    ad: { default: "" },
    isMobilePreview: { default: true }
  },
  data () {
    return {
      adObj: {},
      adTypes: adwordsEnums.adType,
      adType: null,
      displayUrl: "",
      descriptionsToShow: [],
      headlinesToShow: ""
    };
  },
  components: {
    Icon },
  methods: {
    getDisplayUrl (ad) {
      let baseUrl = new URL(ad.finalUrls[0]);
      let displayUrl = baseUrl.protocol + '//' + baseUrl.hostname;
      if (typeof ad.displayPaths !== "undefined") {
        for (let i = 0; i < 2; i++) {
          if (typeof ad.displayPaths[i] !== "undefined") {
            displayUrl += "/" + ad.displayPaths[i];
          }
        }
      }
      this.displayUrl = displayUrl;
    },
    setDescriptionsToShow (descriptions) {
      let descriptionsToShow = [];
      let position1Descriptions = descriptions.flatMap((obj) =>
        obj.pinnedField == adwordsEnums.servedAssetFieldType.description1
          ? obj.text
          : []
      );

      let position2Descriptions = descriptions.flatMap((obj) =>
        obj.pinnedField == adwordsEnums.servedAssetFieldType.description2
          ? obj.text
          : []
      );

      let notPinnedDescriptions = descriptions.flatMap((obj) =>
        obj.pinnedField == undefined ? obj.text : []
      );

      let count = 0;
      if (position1Descriptions.length) {
        descriptionsToShow.push(position1Descriptions[0]);
        count += 1;
      }
      if (position2Descriptions.length) {
        descriptionsToShow.push(position2Descriptions[0]);
        count += 1;
      }
      let notPinnedUsedCount = 0;
      for (let i = count; i < 3; i++) {
        if (notPinnedDescriptions.length > i) {
          descriptionsToShow.push(notPinnedDescriptions[notPinnedUsedCount]);
          notPinnedUsedCount += 1;
        }
      }
      this.descriptionsToShow = descriptionsToShow;
    },
    setHeadlinesToShow (headlines) {
      let headlinesToShow = [];
      let position1Headlines = headlines.flatMap((obj) =>
        obj.pinnedField == adwordsEnums.servedAssetFieldType.headline1
          ? obj.text
          : []
      );

      let position2Headlines = headlines.flatMap((obj) =>
        obj.pinnedField == adwordsEnums.servedAssetFieldType.headline2
          ? obj.text
          : []
      );

      let position3Headlines = headlines.flatMap((obj) =>
        obj.pinnedField == adwordsEnums.servedAssetFieldType.headline3
          ? obj.text
          : []
      );

      let notPinnedHeadlines = headlines.flatMap((obj) =>
        obj.pinnedField == undefined ? obj.text : []
      );

      let count = 0;
      if (position1Headlines.length) {
        headlinesToShow.push(position1Headlines[0]);
        count += 1;
      }
      if (position2Headlines.length) {
        headlinesToShow.push(position2Headlines[0]);
        count += 1;
      }
      if (position3Headlines.length) {
        headlinesToShow.push(position3Headlines[0]);
        count += 1;
      }
      let notPinnedIndex = 0;
      for (let i = count; i < 3; i++) {
        if (notPinnedHeadlines.length > i) {
          headlinesToShow.push(notPinnedHeadlines[notPinnedIndex]);
          notPinnedIndex += 1;
        }
      }

      let totalLength = 0;
      let mergedHeadlines = "";
      headlinesToShow.forEach((el) => {
        el.split("").forEach(function (c) {
          totalLength++;
        });
      });
      // if length of three headlines > 60 chars only two headlines will be shown
      if (totalLength <= 60) {
        if (this.ad.type != this.adTypes.CallAd) {
          mergedHeadlines = headlinesToShow.join(" | ");
        } else {
          mergedHeadlines = headlinesToShow.join(". ");
        }
      } else {
        if (this.ad.type != this.adTypes.CallAd) {
          mergedHeadlines = headlinesToShow.slice(0, 2).join(" | ");
        } else {
          mergedHeadlines = headlinesToShow.slice(0, 2).join(". ");
        }
      }
      this.headlinesToShow = mergedHeadlines;
    }
  },
  created () {
    this.adType = this.ad.type;
    this.getDisplayUrl(this.ad);
    this.setDescriptionsToShow(this.ad.descriptions);
    this.setHeadlinesToShow(this.ad.headlines);
  }
};
</script>
<style scoped>

.ad-preview{
  position: relative;
}

.desktop-preview{
  padding: 5px 5px 30px 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 684px;
  background: white;
}

.mobile-preview{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  width: 398px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  background: white;
}

.url-section {
  align-items: center;
  color: #202124;
  display: flex;
  font-size: 11px;
}

.phone-ad .url-section {
  font-size: 12px;
}

.info-icon {
  color: #bdc1c6;
  margin-left: auto;
  padding-left: 5px;
}

.ad-text {
  flex-shrink: 0;
  font-weight: 700;
}
.ad-dot {
  padding: 0 5px;
}
.display-url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.down-arrow {
  border-color: #70757a transparent;
  border-style: solid;
  border-width: 5px 4px 0;
  margin: 0 3px 0 7px;
}

.action {
  color: #1a73e8;
  margin-right: 12px;
}
.headline-section {
  align-items: center;
  display: flex;
}
.headline-group {
  color: #1a0dab;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  line-height: 26px;
  margin: 3px 0;
  word-break: break-word;
}
.phone-ad .headline-group {
  color: #1558d6;
  font-size: 18px;
  line-height: 24px;
  margin: 12px 0;
}
.preview-headline {
  color: #1558d6;
  font-size: 18px;
  line-height: 24px;
}
.headline {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.subheadline {
  color: #3c4043;
  font-size: 14px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.description-section {
  display: flex;
  justify-content: space-between;
}
.description-group {
  color: #4d5156;
  word-break: break-word;
  font-size: 12px;
}
.action-item {
  align-items: center;
  border-top: 1px solid #dfe1e5;
  display: flex;
  margin-bottom: -4px;
  margin-top: 12px;
  padding-top: 12px;
}
.action-item-icon {
  margin-right: 16px;
  font-size: 24px;
  color: #4d5156;
}
.action-item-text {
  color: #202124;
  font-size: 16px;
}
</style>
