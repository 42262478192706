<template>
    <div class="cl-widget existing-tweets-widget">
      <Card>
        <div slot="title">
          <Row>
            <i-col span="10">
              <h4>
                Existing Tweets
                <i v-if="showLoader" class="fa fa-spinner fa-spin" />
              </h4>
            </i-col>
            <i-col span="13" class="no-padding searchBar">
              <Input v-model="searchTerm" search placeholder="Search by Tweet id" class="search-bar input-search-bar" />
            </i-col>
          </Row>
        </div>
        <VuePerfectScrollbar>
          <div v-if="isError">
            <div class="alert alert-danger error-message">
              There was a problem fetching Existing Tweets
            </div>
          </div>
          <div v-else>
            <div v-if="existingTweets.length == 0">
              <div class="alert alert-info no-forms-found-info" v-if="!isStringNullOrEmpty(searchTerm)">
                No Existing Tweets found for id "{{ searchTerm }}"
              </div>
              <div class="alert alert-info no-forms-info" v-else>
                No tweets found. Create a Tweet on Twitter to see them here
              </div>
            </div>
          </div>
          <div class="existing-tweets-wrapper">
            <div class="existing-tweets" v-for="tweet in existingTweets" :key="tweet.id">
              <Row>
                <i-col span="2" offset="1">
                  <i class="fas fa-copy action-icon"
                    @click="copyToClipboard(tweet.id)">
                  </i>
                </i-col>
                <i-col>
                  <i-col span="20" class="cl-widget-text">
                    <span class="existing-tweets-name">{{ tweet.full_text }}</span>
                  </i-col>
                </i-col>
              </Row>
            </div>
          </div>
        </VuePerfectScrollbar>
      </Card>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { APIService } from "../../ApiService.js";
import { Input, locale, Card, Row, Col } from "iview";
import lang from "iview/dist/locale/en-US";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import mixins from "../../../../Mixins/copyToClipboard.js";
locale(lang);
export default {
  name: "LeadFormsWidget",
  mixins: [mixins],
  props: {
  },
  components: {
    Input,
    Card,
    Row,
    "i-col": Col,
    VuePerfectScrollbar
  },
  data () {
    return {
      bpseId: 0,
      existingTweetsData: [],
      searchTerm: null,
      showLoader: false,
      isError: false,
      existingTweets: []
    }
  },
  mounted () {
    let self = this;
    self.bpseId = self.selectedCampaignLauncherConfig.bpseId;
    if (self.bpseId) {
      self.getExistingTweets();
    }
  },
  computed: {
    ...mapState(["selectedCampaignLauncherConfig"])
  },
  methods: {
    ...mapMutations(["set_existingTweets"]),
    isStringNullOrEmpty (word) {
      return word == null || word == "" || word == undefined;
    },
    getExistingTweets (tweetId = null) {
      let self = this;
      self.showLoader = true;
      APIService.getExistingTweets(self.bpseId, tweetId)
        .then(response => {
          var combinedValues = [...self.existingTweetsData, ...response.data.data.tweets];
          self.existingTweetsData = [...new Set(combinedValues.map(JSON.stringify))].map(JSON.parse)
          this.set_existingTweets(self.existingTweetsData)
          this.existingTweets = response.data.data.tweets
          self.showLoader = false;
          self.isError = false;
        })
        .catch(() => {
          self.showLoader = false;
          self.isError = true;
          self.existingTweetsData = [];
        });
    },
    searchTweets () {
      let searchTerm = this.searchTerm;
      let existingTweets = this.existingTweetsData;
      if (searchTerm) {
        this.existingTweets = this.existingTweetsData.filter(e => {
          return e.id.includes(searchTerm);
        });
        if (searchTerm.length > 5 && Number.isInteger(parseInt(searchTerm))) {
          this.getExistingTweets(searchTerm)
        }
      }

      return existingTweets;
    }
  },
  watch: {
    selectedCampaignLauncherConfig: {
      handler (value) {
        this.bpseId = value.bpseId;
        this.getExistingTweets();
      },
      deep: true
    },
    searchTerm: {
      handler (value) {
        if (!value) {
          this.existingTweets = this.existingTweetsData;
        } else {
          this.searchTweets(value);
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.existing-tweets-wrapper .existing-tweets {
  padding: 0.5rem 0rem;
}

.ps-container {
  min-height: 5rem;
  padding: 0.2rem 0rem;
  max-height: 15rem;
}
.searchBar {
  float: right;
}
</style>

<style src="../../../../Styles/dx-iview.css"></style>
