<template>
  <div>
      <Button
        size="small"
        v-on:click="openConfigurationModal = true"
        >
          Ad Sheet Settings
          <i class="fa fa-cog" />
        </Button>
      <Modal
        v-model="openConfigurationModal"
        title="Ad Sheet Settings"
        width="60%"
        @on-cancel="cancel"
        class="ad-sheet-settings"
        :styles="{ top: '20px' }"
      >
        <div class="modal-body">
          <Form>
            <Form-Item label="Type">
              <RadioGroup v-model="setupType.mode">
                <Radio
                :disabled="setupType.mode == '3'"
                  label="1"
                >Placement Customization</Radio>
                &nbsp;
                <Radio
                  :disabled="setupType.mode == '3'"
                  label="2"
                  v-if="languageCustomizationHeaders && languageCustomizationHeaders.length > 0"
                >Language Customization</Radio>
                <Radio
                  :disabled="setupType.mode !== '3'"
                  label="3"
                  v-if="DynamicCreativeHeaders && DynamicCreativeHeaders.length > 0"
                >Dynamic Creatives</Radio>
              </RadioGroup>
            </Form-Item>
          </Form>
        <div v-if="showDpaCreativeTools">
          <div class="divider">
            <span>Creative Tools</span>
          </div>
          <div class="alert alert-info">
            Select the creative tools to customize Single and Carousel ads.
          </div>
          <Row>
            <i-col
              span="7"
              offset="1"
              v-for="(creativeOption, optionName) in dpaCreativeToolTree"
              :key="optionName"
            >
              <Checkbox v-model="creativeOption.checked">
                <span class="checkbox-label"> {{ optionName }} </span>
              </Checkbox>
              <ul class="columns-list">
                <li v-for="(col, i) in creativeOption.columns" :key="i">{{ col }}</li>
              </ul>
            </i-col>
          </Row>
        </div>
        <div v-else-if="showPlacementCustomization">
            <div class="divider" style="margin: 6px 0 24px 0">
              <span>{{ setupType.header }}</span>
            </div>
            <div class="alert alert-info">
                {{ setupType.message }}
            </div>
            <Row>
                <i-col span="11" offset="1" v-if="setupType.mode == '1'">
                    <div v-if="pcGroupingData">
                        <Tree
                          :data="pcGroupingData"
                          show-checkbox multiple check-directly
                        />
                    </div>
                </i-col>
                <i-col span="11" offset="1" v-if="setupType.mode == '2'">
                  <Select v-model="languages.selected" multiple :transfer="true">
                    <Option v-for="item in languages.options" :value="item.key" :key="item.key">{{ item.name }}</Option>
                  </Select>
                </i-col>
                <div class="split"></div>
                <i-col span="11" offset="1">
                    <div v-if="pcPlacements && setupType.mode == '1'">
                        <CheckboxGroup vertical v-model="selectedHeaderOptions">
                          <Table class="fixed-table-checkbox-option">
                            <div
                              v-for="field in pcPlacements"
                              :key="field.id"
                              class="checkbox-options-selector">
                                <tr>
                                  <td>
                                    <Checkbox
                                        class="horizontal-checkbox"
                                        :label="field.id"
                                        :disabled="!anyGroupingSelected"
                                      >
                                      <span class="checkbox-label">{{ field.label }}{{extraLabel(field)}}</span>
                                    </Checkbox>
                                  </td>
                                  <td>
                                    <span class="optional-field-selector" v-if="selectedHeaderOptions.includes(field.id) && fbEnums.placementCustomization.multipleColumnIds.includes(field.id)">
                                      <InputNumber class="optional-field-selector-options" v-model="optionalHeaderData[field.id]" size="small" :editable="false" :min="1" :max="field.maxCount"></InputNumber>
                                    </span>
                                  </td>
                                </tr>
                            </div>
                          </Table>
                        </CheckboxGroup>
                    </div>
                    <div v-else-if="languageCustomization || setupType.mode == '2'">
                        <CheckboxGroup vertical v-model="selectedHeaderOptions">
                          <Table class="fixed-table-checkbox-option">
                            <div
                              v-for="field in languageCustomization"
                              :key="field.id"
                              class="checkbox-options-selector">
                                <tr>
                                  <td>
                                    <Checkbox
                                        class="horizontal-checkbox"
                                        :label="field.id"
                                      >
                                      <span class="checkbox-label">{{ field.label }}</span>
                                    </Checkbox>
                                  </td>
                                  <td>
                                  </td>
                                </tr>
                            </div>
                          </Table>
                        </CheckboxGroup>
                    </div>
                </i-col>
            </Row>
        </div>

        <div v-if="isDynamicCreative" >
          <Row class="dynamic-creative">
             <i-col>
            <CheckboxGroup vertical v-model="selectedDynamicHeaderOptions">
              <Table class="fixed-table-checkbox-option">
                <div
                  v-for="field in DynamicCreative"
                  :key="field.id"
                  class="checkbox-options-selector">
                  <tr>
                    <tr>
                      <td>
                        <Checkbox
                          class="horizontal-checkbox"
                          :label="field.id"
                        >
                        <span class="checkbox-label">{{ field.label }}{{extraLabel(field)}}</span>
                      </Checkbox>
                    </td>
                    <td>
                      <span class="optional-field-selector" v-if="selectedDynamicHeaderOptions.includes(field.id) && fbEnums.DynamicCreative.multipleColumnIds.includes(field.id)">
                        <InputNumber class="optional-field-selector-options" v-model="optionalDynamicCreativeHeaderData[field.id]" size="small" :editable="false" :min="1" :max="field.maxCount"></InputNumber>
                      </span>
                    </td>
                  </tr>
                  </div>
              </Table>
            </CheckboxGroup>
             </i-col>
            </Row>
        </div>
        <div class="ld-wrapper">
          <div class="divider">
            <span>Ad Label Dimensions</span>
          </div>
          <Row class="ld-row">
            <i-col span="20">
              <Select
                multiple
                filterable
                v-model="selectedLabelDimensions"
                placeholder="Select label dimensions"
                :transfer="true"
              >
                <Option
                  v-for="(labelDimension) in labelDimensions"
                  :key="labelDimension.id"
                  :value="Number(labelDimension.id)"
                >
                  {{ labelDimension.name }}
                </Option>
              </Select>
            </i-col>
          </Row>
        </div>
        </div>

        <div slot="footer">
          <input type="button" class="btn btn-white adsheet-settings-padding" v-on:click="cancel()" value="Cancel" />
          <button
          class="btn btn-success adsheet-settings-padding"
          v-on:click="apply(true)"
          :disabled="processing"
        > Apply <i class="fa fa-spinner fa-spin" v-show="processing"></i>
        </button>
        </div>
    </Modal>
  </div>
</template>

<script>

import { Button, Modal, Row, Col, Tree, CheckboxGroup, Checkbox, Select, Option, InputNumber, Form, FormItem, Radio, RadioGroup } from "iview";
import * as fbEnums from "../../Constants/FacebookEnums.js";
import { mapState, mapMutations } from "vuex";
import { APIService } from "../../ApiService.js"

export default {
  components: {
    Button,
    Modal,
    Row,
    'i-col': Col,
    Tree,
    CheckboxGroup,
    Checkbox,
    Select,
    Option,
    InputNumber,
    Form,
    FormItem,
    RadioGroup,
    Radio
  },
  computed: {
    ...mapState([
      "placementCustomizationSettings",
      "dynamicCreativeSettings",
      "labelDimensions",
      "facebookLanguages"
    ]),
    anyGroupingSelected () {
      var optionsChecked = this.pcGroupingData.map(x => x.children.filter(x => x.checked == true)).flat(1)
      if (optionsChecked.length > 0) {
        return true
      } else {
        this.selectedHeaderOptions = []
        return false
      }
    },
    showLabelDimensionSelection () {
      return (this.adLabelDimensionSettings || []).length > 0;
    },
    showDpaCreativeTools () {
      return this.isDpaObjective && this.tab != 'collection';
    }
  },
  data () {
    return {
      openConfigurationModal: false,
      fbEnums,
      selectedHeaderOptions: [],
      selectedDynamicHeaderOptions: [],
      optionalHeaderData: {},
      optionalDynamicCreativeHeaderData: {},
      pcGroupingData: null,
      pcPlacements: null,
      DynamicCreative: null,
      languageCustomization: null,
      groupingCompleted: false,
      processing: false,
      dpaCreativeToolTree: {
        "Frame": {
          checked: false,
          columns: ["Image", "Blending", "Opacity", "Size", "Position"]
        },
        "Catalogue Information": {
          checked: false,
          columns: ["Type", "Shape", "Font", "Shape Colour", "Text Colour", "Opacity", "Position"]
        }
      },
      selectedLabelDimensions: [],
      setupType: {
        mode: "1",
        header: "Placement Customization",
        message: "Select the placements and fields you would like to customize"
      },
      languages: {
        selected: "",
        options: []
      }
    }
  },
  props: {
    placementOptions: { default: function () { return []; } },
    placementCustomizationHeaders: { default: function () { return []; } },
    languageCustomizationHeaders: { default: function () { return []; } },
    DynamicCreativeHeaders: { default: function () { return []; } },
    publishedCreatives: { default: function () { return {}; } },
    isDpaObjective: { default: false },
    tab: { default: '' },
    adLabelDimensionSettings: { default: function () { return []; } },
    showPlacementCustomization: { default: false },
    isDynamicCreative: false
  },
  mounted () {
    this.setDataInModel()
  },
  methods: {
    ...mapMutations(["set_placementCustomizationSettings", "set_facebookLanguages", "set_dynamicCreativeSettings"]),
    apply (saveSettingsInDb = false) {
      let settings = {};
      if (this.showDpaCreativeTools) {
        settings = this.applyDpaCreativeTools(saveSettingsInDb);
      } else if (this.showPlacementCustomization && this.setupType.mode == "1") {
        settings = this.applyPlacementCustomization(saveSettingsInDb);
      } else if (this.setupType.mode == "2") {
        settings = this.applyLanguageCustomization(saveSettingsInDb);
      } else if (this.setupType.mode == "3") {
        settings = this.applyDynamicCreatives(saveSettingsInDb);
      } else if (saveSettingsInDb) {
        this.processing = true;
      }

      settings.selectedLabelDimensions = this.selectedLabelDimensions;
      settings.saveRequired = saveSettingsInDb;

      setTimeout(() => {
        this.$emit("savedAdSheetSettings", settings);
        this.processing = false
        this.openConfigurationModal = false
      })
    },
    applyDynamicCreatives (saveSettingsInDb) {
      var self = this
      if (saveSettingsInDb) {
        this.processing = true
      }
      var headersToAppend = self.fbEnums.DynamicCreative.columns.filter(function (obj) {
        return self.selectedDynamicHeaderOptions.some(function (obj2) {
          if (obj.id === obj2) {
            obj.count = self.optionalDynamicCreativeHeaderData[obj.id] || 1
          }
          return obj.id === obj2;
        });
      });
      var dynamicCreativeHeaders = []

      headersToAppend.forEach(headers => {
        for (var i = 1; i <= headers.count; i++) {
          var label = headers.label
          if (i > 0) {
            label += " - Optional " + i;
          }
          dynamicCreativeHeaders.push(label)
        }
      })

      var dcSettings = {
        selectedDynamicHeaderOptions: this.selectedDynamicHeaderOptions,
        optionalDynamicCreativeHeaderData: this.optionalDynamicCreativeHeaderData
      }
      this.set_dynamicCreativeSettings(dcSettings)
      return {
        type: "dynamicCreative",
        saveRequired: saveSettingsInDb,
        dynamicCreativeSettings: dcSettings,
        dynamicCreativeHeaders: dynamicCreativeHeaders
      };
    },
    applyLanguageCustomization (saveSettingsInDb) {
      var self = this
      if (saveSettingsInDb) {
        this.processing = true
      }
      var headersToAppend = self.fbEnums.languageCustomization.columns.filter(function (obj) {
        return self.selectedHeaderOptions.some(function (obj2) {
          if (obj.id === obj2) {
            obj.count = self.optionalHeaderData[obj.id] || 1
          }
          return obj.id === obj2;
        });
      });
      var languagesSelected = this.languages.selected;
      var languageHeaders = [];
      languagesSelected.forEach(languageKey => {
        var language = this.languages.options.filter(e => { return e.key == languageKey })[0];
        var languageName = language.name;
        headersToAppend.forEach(headers => {
          for (var i = 1; i <= headers.count; i++) {
            var label = headers.label
            if (i > 1) {
              label += " - Optional " + (i - 1)
            }
            languageHeaders.push(languageName + " - " + label)
          }
        })
      })
      var lcSettings = {
        selectedLanguages: this.languages.selected,
        selectedHeaderOptions: this.selectedHeaderOptions,
        optionalHeaderData: this.optionalHeaderData,
        languageHeaders: languageHeaders
      }
      return {
        type: "languageCustomization",
        saveRequired: saveSettingsInDb,
        languageCustomizationSettings: lcSettings,
        languageHeaders: languageHeaders
      };
    },
    applyPlacementCustomization (saveSettingsInDb) {
      var self = this
      if (saveSettingsInDb) {
        this.processing = true
      }
      var headersToAppend = self.fbEnums.placementCustomization.columns.filter(function (obj) {
        return self.selectedHeaderOptions.some(function (obj2) {
          if (obj.id === obj2) {
            obj.count = self.optionalHeaderData[obj.id] || 1
          }
          return obj.id === obj2;
        });
      });
      if (headersToAppend.some(obj => obj.id == 2)) {
        headersToAppend.push(self.fbEnums.placementCustomization.columns.find(x => x.id == 8))
      }
      var placementsEnabled = this.pcGroupingData.map(x => x.children.filter(y => y.checked)).flat()
      var placementHeaders = []
      placementsEnabled.forEach(placement => {
        headersToAppend.forEach(headers => {
          for (var i = 1; i <= headers.count; i++) {
            var label = headers.label
            if (i > 1) {
              label += " - Optional " + (i - 1)
            }
            placementHeaders.push(placement.title + " - " + label)
          }
        })
      })
      var beakerPlacementCustomizationHeaders = []
      headersToAppend.forEach(headers => {
        for (var i = 1; i <= headers.count; i++) {
          var label = headers.hotLabel
          if (i > 1) {
            label += " - Optional " + (i - 1)
          }
          beakerPlacementCustomizationHeaders.push(label)
        }
      })
      var pcSettings = {
        selectedPlacementOptions: this.pcGroupingData,
        selectedHeaderOptions: this.selectedHeaderOptions,
        optionalHeaderData: this.optionalHeaderData
      }
      this.set_placementCustomizationSettings(pcSettings)
      return {
        type: "placementCustomization",
        saveRequired: saveSettingsInDb,
        placementCustomizationSettings: pcSettings,
        placementHeaders: placementHeaders,
        beakerPlacementCustomizationHeaders: beakerPlacementCustomizationHeaders
      };
    },
    applyDpaCreativeTools (saveSettingsInDb) {
      if (saveSettingsInDb) {
        this.processing = true
      }
      let creativeToolHeaders = [];
      let creativeToolSettings = {};
      for (let [optionName, creativeOption] of Object.entries(this.dpaCreativeToolTree)) {
        creativeToolSettings[optionName] = creativeOption.checked;
        if (creativeOption.checked) {
          creativeToolHeaders.push(
            ...creativeOption.columns.map(colName => `${optionName} ${colName}`)
          );
        }
      }
      return {
        type: "dpaCreativeTool",
        saveRequired: saveSettingsInDb,
        dpaCreativeToolSettings: creativeToolSettings,
        dpaCreativeToolHeaders: creativeToolHeaders
      };
    },
    cancel () {
      this.openConfigurationModal = false
    },
    setDataInModel () {
      var adsheetData = null
      if (this.publishedCreatives) {
        adsheetData = this.publishedCreatives.adSheetSettings
      }
      if (this.isDynamicCreative) {
        this.setupType.mode = "3"
        this.setSetUpMode(this.setupType.mode)
      }
      if (adsheetData && this.showDpaCreativeTools) {
        this.dpaCreativeToolTree["Frame"].checked = adsheetData["Frame"];
        this.dpaCreativeToolTree["Catalogue Information"].checked =
          adsheetData["Catalogue Information"];
        return;
      } else if (adsheetData && adsheetData.selectedLanguages) {
        this.selectedHeaderOptions = this.publishedCreatives.adSheetSettings.selectedHeaderOptions;
        this.languagesGrouping();
        this.setupType.mode = "2";
        this.languages.selected = adsheetData.selectedLanguages;
        this.setLanguagesInfo();
        return;
      }
      if (adsheetData && !this.placementCustomizationSettings && this.publishedCreatives.adSheetSettings.selectedPlacementOptions) {
        this.pcGroupingData = this.getJSONFromCurrentPlacement(this.publishedCreatives.adSheetSettings.selectedPlacementOptions);
        this.selectedHeaderOptions = this.publishedCreatives.adSheetSettings.selectedHeaderOptions;
        if (this.publishedCreatives && this.publishedCreatives.adSheetSettings && this.publishedCreatives.adSheetSettings.optionalHeaderData) {
          this.optionalHeaderData = this.publishedCreatives.adSheetSettings.optionalHeaderData;
        }
        let settings = this.applyPlacementCustomization(false)
        this.$emit("savedAdSheetSettings", settings);
      } else if (this.placementCustomizationSettings) {
        this.pcGroupingData = this.getJSONFromCurrentPlacement(this.placementCustomizationSettings.selectedPlacementOptions)
        this.selectedHeaderOptions = this.placementCustomizationSettings.selectedHeaderOptions
        if (this.publishedCreatives && this.publishedCreatives.adSheetSettings && this.publishedCreatives.adSheetSettings.optionalHeaderData) {
          this.optionalHeaderData = this.placementCustomizationSettings.optionalHeaderData;
        }
      } else if (this.placementOptions) {
        var categoryObject = JSON.parse(JSON.stringify(fbEnums.placementCustomization.categories))
        this.placementOptions.forEach(placement => {
          var exactPlacement = placement.publisher_platforms + " " + placement.title
          for (let [key, value] of Object.entries(fbEnums.placementCustomization.categories_placements)) {
            var newPlacementObject = {
              title: exactPlacement,
              placement
            }
            if (value.includes(exactPlacement)) {
              var category = categoryObject.find(x => x.id == key)
              if (category && category.children) {
                category.children.push(newPlacementObject)
              }
            }
          }
        })
        this.pcGroupingData = categoryObject
      }
      this.placementsGrouping();

      if (adsheetData && !this.dynamicCreativeSettings && this.publishedCreatives.adSheetSettings.selectedDynamicHeaderOptions) {
        this.selectedDynamicHeaderOptions = this.publishedCreatives.adSheetSettings.selectedDynamicHeaderOptions;
        if (this.publishedCreatives && this.publishedCreatives.adSheetSettings && this.publishedCreatives.adSheetSettings.optionalDynamicCreativeHeaderData) {
          this.optionalDynamicCreativeHeaderData = this.publishedCreatives.adSheetSettings.optionalDynamicCreativeHeaderData;
        }
        let settings = this.applyDynamicCreatives(false)
        this.$emit("savedAdSheetSettings", settings);
      } else if (this.dynamicCreativeSettings) {
        this.selectedDynamicHeaderOptions = this.dynamicCreativeSettings.selectedDynamicHeaderOptions;
        if (this.dynamicCreativeSettings.optionalDynamicCreativeHeaderData) {
          this.optionalDynamicCreativeHeaderData = this.dynamicCreativeSettings.optionalDynamicCreativeHeaderData;
        }
      }
      if (this.fbEnums.placementCustomization.multipleColumnIds && Object.keys(this.optionalHeaderData).length == 0) {
        this.fbEnums.placementCustomization.multipleColumnIds.forEach(columnId => {
          this.optionalHeaderData[columnId] = 1
        })
      }
      if (this.fbEnums.DynamicCreative.multipleColumnIds && Object.keys(this.optionalDynamicCreativeHeaderData).length == 0) {
        this.fbEnums.DynamicCreative.multipleColumnIds.forEach(columnId => {
          this.optionalDynamicCreativeHeaderData[columnId] = 1
        })
      }
      this.dynamicCreativeGrouping();
      this.groupingCompleted = true
    },
    getJSONFromCurrentPlacement (currentData) {
      var updatedJSON = JSON.parse(JSON.stringify(currentData))
      var categoriesPlacements = JSON.parse(JSON.stringify(fbEnums.placementCustomization.categories_placements))
      updatedJSON.forEach(category => {
        category.children = []
      })
      this.placementOptions.forEach(placement => {
        var exactPlacement = placement.publisher_platforms + " " + placement.title
        var position = null;
        for (var key in categoriesPlacements) {
          if (categoriesPlacements[key].includes(exactPlacement)) {
            position = key
            break;
          }
        }
        if (position) {
          var currentChild = currentData.find(x => x.id == key).children.find(x => x.title == exactPlacement)
          updatedJSON.find(x => x.id == key).children.push(currentChild)
        }
      })
      return updatedJSON
    },
    placementsGrouping () {
      var headersToBeConsidered = JSON.parse(JSON.stringify(this.placementCustomizationHeaders))
      headersToBeConsidered.shift()
      var placemntsToBeShown = []
      headersToBeConsidered.forEach(header => {
        var object = this.fbEnums.placementCustomization.columns.find(x => x.hotLabel == header)
        if (object) {
          placemntsToBeShown.push(object)
        }
      })
      this.pcPlacements = placemntsToBeShown
    },
    languagesGrouping () {
      var headersToBeConsidered = JSON.parse(JSON.stringify(this.languageCustomizationHeaders))
      headersToBeConsidered.shift()
      var placemntsToBeShown = []
      headersToBeConsidered.forEach(header => {
        var object = this.fbEnums.languageCustomization.columns.find(x => x.hotLabel == header)
        if (object) {
          placemntsToBeShown.push(object)
        }
      })
      this.languageCustomization = placemntsToBeShown
    },
    dynamicCreativeGrouping () {
      var headersToBeConsidered = JSON.parse(JSON.stringify(this.DynamicCreativeHeaders))
      headersToBeConsidered.shift()
      var placemntsToBeShown = []
      headersToBeConsidered.forEach(header => {
        var object = this.fbEnums.DynamicCreative.columns.find(x => x.hotLabel == header)
        if (object) {
          placemntsToBeShown.push(object)
        }
      })
      this.DynamicCreative = placemntsToBeShown
    },
    extraLabel (field) {
      if (field.maxCount > 1) {
        return "(s)"
      }
    },
    setLanguagesInfo () {
      var mode = this.setupType.mode;
      this.setSetUpMode(mode)
      if (this.facebookLanguages.length > 0) {
        this.languages.options = this.facebookLanguages;
      }
    },
    setSetUpMode (mode) {
      if (mode == "1") {
        // placement customization
        this.setupType.message = "Select the placements and fields you would like to customize";
        this.setupType.header = "Placement Customization";
      } else if (mode == "2") {
        // language optimization
        this.setupType.message = "Select the languages and fields you would like to customize";
        this.setupType.header = "Language Customization";
      } else if (mode == "3") {
        // language optimization
        this.setupType.message = "Select the Dynamic Creatives";
        this.setupType.header = "Dynamic Creatives";
      } else {
        // not supported, don't do anything
      }
      if (this.languages.options.length == 0 && this.facebookLanguages.length == 0) {
        APIService.getFacebookLanguages().then(e => {
          this.languages.options = e.data;
          this.set_facebookLanguages(e.data);
        }).catch(e => {
          console.log(e);
        });
      } else if (this.facebookLanguages.length > 0) {
        this.languages.options = this.facebookLanguages;
      }
    }
  },
  watch: {
    openConfigurationModal: {
      immediate: true,
      handler: function () {
        if (this.openConfigurationModal) {
          this.setDataInModel()
          this.selectedLabelDimensions = [...this.adLabelDimensionSettings];
        }
      }
    },
    placementOptions: {
      handler: function () {
        this.setDataInModel()
      }
    },
    "setupType.mode": function (mode) {
      if (this.setupType.mode == "2") {
        this.languagesGrouping();
      }
      this.setLanguagesInfo();
    }
  }
}
</script>

<style scoped>
.modal-body {
  min-height: 120px;
  max-height: 72vh;
  overflow: auto;
}
.split {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    border: 1px dashed #eee;
}
.horizontal-checkbox {
  display: block;
}
.checkbox-label {
  padding-left: 5px;
}
.columns-list {
  margin-left: 24px;
}
.adsheet-settings-padding {
  margin-right: 2px !important;
}
.ld-wrapper {
  margin-top: 20px;
}
.ld-row {
  margin-left: 30px;
}
.optional-field-selector-options {
  width: 50%;
  margin-left: 10px
}
.column-count-text {
  padding-left: 20px;
  font-size: 13px;
}
.divider {
  width: 100%;
  height: 7px;
  border-bottom: 1px solid #ccc;
  position: relative;
  margin-bottom: 36px;
}
.divider > span {
  left: 36px;
  top: -2px;
  position: absolute;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #979898;
  background-color: white;
  padding: 0 10px;
}
</style>
<style>
.fixed-table-checkbox-option {
  table-layout: fixed;
}
.fixed-table-checkbox-option td {
  width: 50%;
  min-width: 160px;
}
.ad-sheet-settings .ivu-modal-body {
  padding-top: 0px !important;
}
.ccHeader {
  margin: 0px;
  font-size: 20px !important;
}
.ad-sheet-settings .ivu-modal-footer {
  padding-bottom: 2px !important;
}
.ad-sheet-settings .ivu-checkbox-checked .ivu-checkbox-inner {
    border-color: var(--main-bg-color) !important;
    background-color: var(--main-bg-color) !important;
}
.dynamic-creative {
  margin-left: 50px;
}
</style>
