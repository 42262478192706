<template>
  <div>
    <button
      class="btn btn-success btn-sm refresh-button"
      @click="openModel = true"
      ><i class="fa fa-history" />
    </button>
    <Modal
      v-model="openModel"
      title="RnF Estimate History"
      :footer-hide="true"
      width="70%"
      >
        <!-- <Table :columns="rnfEstimateHistoryColumns" :data="rnfEstimateHistoryData"></Table> -->
        <div id="campaign-launcher" v-if="rnfEstimateHistoryData.length > 0 && !callInProgress">
          <div class="budget-type-table">
            <table class="table perf">
              <thead>
                <tr>
                  <th
                    v-for="item in rnfEstimateHistoryColumns"
                    :key="item.key"
                  >{{ item.title }}</th>
                </tr>
              </thead>
              <tbody v-for="(record,i) in rnfEstimateHistoryData" :key="i">
                <tr>
                  <td
                    class="data-vertical-middle"
                  >{{record.time}}</td>
                  <td
                    class="data-right data-vertical-middle"
                  >{{record.budget}}</td>
                  <td
                    class="data-right data-vertical-middle"
                  >{{record.startDate}}</td>
                  <td
                    class="data-right data-vertical-middle"
                  >{{record.endDate}}</td>
                  <td
                    class="data-right data-vertical-middle"
                  >{{record.universe}}</td>
                  <td
                    class="data-right data-vertical-middle"
                  >{{ record.reach }}</td>
                  <td
                    class="data-right data-vertical-middle"
                  >{{record.percentageOfUniverse}} %</td>
                  <td
                    class="data-right data-vertical-middle"
                  >{{ record.frequency }}</td>
                  <td
                    class="data-right data-vertical-middle"
                  >{{ record.cpm }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else-if="callInProgress" class="center-align">
          <i class="fa fa-lg fa-spinner fa-spin" />
        </div>
        <div v-else class="alert alert-danger">
            Something went wrong. Please try again in some time.
        </div>
    </Modal>
  </div>
</template>

<script>
import { Modal } from "iview";
import ApiService from '../ApiService.js';
import { mapState } from "vuex";
import { EventBus } from "../../../EventBus.js";

export default {
  components: {
    Modal
  },
  mounted: function () {
  },
  props: {
    index: { default: 0 },
    lineItemId: { default: 0 },
    targetId: { default: 0 },
    RnfDataProcessing: { defaut: false }
  },
  computed: {
    ...mapState(["selectedCampaignLauncherConfig"])
  },
  data: function () {
    return {
      openModel: false,
      dataFetched: false,
      callInProgress: false,
      rnfEstimateHistoryColumns: [
        {
          title: 'Time',
          key: 'time'
        },
        {
          title: 'Budget',
          key: 'budget'
        },
        {
          title: 'Start Date',
          key: 'startDate'
        },
        {
          title: 'End Date',
          key: 'endDate'
        },
        {
          title: 'Universe',
          key: 'universe'
        },
        {
          title: 'Reach',
          key: 'reach'
        },
        {
          title: '% Of Universe',
          key: 'percentageOfUniverse'
        },
        {
          title: 'Frequency',
          key: 'frequency'
        },
        {
          title: 'CPM',
          key: 'cpm'
        }
      ],
      rnfEstimateHistoryData: []
    };
  },
  watch: {
    RnfDataProcessing: function (value) {
      if (value) {
        this.dataFetched = false
      }
    },
    openModel: function (value) {
      if (value && !this.dataFetched && this.lineItemId > 0 && this.targetId > 0) {
        this.getRNFData();
      }
    }
  },
  methods: {
    getRNFData () {
      this.callInProgress = true;
      ApiService.GetReachAndFrequencyPredictionHistory(
        this.lineItemId,
        this.targetId,
        this.successRnfDataCallback,
        this.failureRnFDataCallback
      );
    },
    successRnfDataCallback: function (response) {
      if (response.status == 200) {
        var logsData = response.data.reachAndFrequencyPredictionLogs
        this.processData(logsData)
        this.dataFetched = true;
        this.callInProgress = false;
      }
    },
    failureRnFDataCallback: function (response) {
      console.log("Api call failed to fetch Rnf Data Logs", response)
      this.callInProgress = false
    },
    processData (logsData) {
      let self = this
      var cleanRnfLogs = []
      logsData.forEach((log) => {
        let rnfData = {}
        rnfData.id = log.reachAndFrequencyPredictionId;
        rnfData.time = log.createdAt ? new Date(log.createdAt).toLocaleString() : "-"
        if (this.selectedCampaignLauncherConfig.adset.billingEvent == "15") {
          var reachObject = self.rnfEstimateHistoryColumns.find(x => x.key == "reach");
          reachObject.title = "Unique ThruPlays"
          var cpmObject = self.rnfEstimateHistoryColumns.find(x => x.key == "cpm");
          cpmObject.title = "Cost per ThruPlay"
        }
        var rnfSettings = JSON.parse(log.reachAndFrequencySettings)
        if (rnfSettings) {
          rnfData.cpm = rnfSettings.cpa || "-"
          rnfData.frequency = "Frequency"
          rnfData.budget = parseInt(rnfSettings.budget).toLocaleString("en") || "-"
          rnfData.percentageOfUniverse = rnfSettings.percentageOfUniverse || "-";
          rnfData.reach = (rnfSettings.reach || "-").toLocaleString("en");
          rnfData.frequency = rnfSettings.frequency || "-"
          rnfData.universe = parseInt(rnfSettings.universe).toLocaleString("en") || "-"
          rnfData.startDate = this.readableTimeFormat(rnfSettings.startDate) || "-"
          rnfData.endDate = this.readableTimeFormat(rnfSettings.endDate) || "-"
          cleanRnfLogs.unshift(rnfData)
        }
      })
      this.rnfEstimateHistoryData = cleanRnfLogs;
    },
    readableTimeFormat: function (value) {
      return new Date(value).toLocaleString()
    }
  }
}
</script>

<style>
.data-right {
  text-align: right;
}
.hot-spinner {
  font-size: 3rem;
  margin-top: 2rem;
  line-height: 5rem;
  text-align: center;
}
.center-align {
  text-align: center;
}
</style>
