<template>
    <Card style="height:100%;overflow:hidden">
      <div slot="title">
        <div class="row">
          <div class="col-md-4">
            <h4>Categories <i v-show="loader" class="fa fa-spinner fa-spin" /></h4>
          </div>
          <div class="col-md-6 no-padding">
            <Input  @keyup.enter.native="searchCategories()" v-model="search" search placeholder="Search" class="search-bar input-search-bar" />
          </div>
        </div>
      </div>
      <VuePerfectScrollbar class="widget-body">
          <template>
            <div
              style="margin-top:10px"
              v-if="businessProfilesSearchEngineId==0"
              class="alert alert-info"
            >Please select a campaign configuration to see the list of categories.</div>
          </template>
          <template v-if="businessProfilesSearchEngineId > 0">
             <Tree v-model="tree" :data="filteredData" :render="renderContent"  :load-data = "loadOptions"></Tree>
          </template>
      </VuePerfectScrollbar>
    </Card>
</template>
<script>
import { APIService } from "./../ApiService.js";
import { mapState } from "vuex";
import { locale, Card, Tree, Input } from "iview";
import lang from "iview/dist/locale/en-US";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
locale(lang);
export default {
  name: "TargetWidget",
  components: {
    VuePerfectScrollbar,
    Card,
    Tree,
    Input
  },
  data: function () {
    return {
      search: "",
      showModal: false,
      loader: false,
      searchData: [],
      data: [],
      categorydata: [],
      child: [],
      tree: "",
      filteredData: []
    };
  },
  props: {
    publisherId: {
      type: Number,
      default: 11
    }
  },
  methods: {
    renderContent (h, { root, node, data }) {
      return h('span', {
        style: {
          display: 'inline-block',
          width: '100%',
          paddingLeft: '2%',
          fontSize: '17px',
          color: data.istargetable ? 'inherit' : 'grey'
        }
      }, [
        data.istargetable
          ? h('i', {
            class: "fas fa-copy action-icon",
            style: {
              paddingRight: '2%'
            },
            on: {
              click: () => { this.copyToClipboard(data) }
            }
          }) : null,
        h('span', data.categoryName)
      ]);
    },
    copyToClipboard (data) {
      let textarea = document.createElement("textarea")
      let categoryObject = {
        amsCategoryId: data.amsCategoryId,
        categoryName: data.categoryName
      }
      if (this.publisherId == this.publisher.AMS.SponsoredProducts) {
        textarea.textContent = JSON.stringify(categoryObject)
      } else {
        textarea.textContent = data.categoryName
      }
      textarea.style.position = "fixed"
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand("copy")
      } catch (e) {
        alert('Unable to copy the category(s) to clipboard. Please copy & paste them manually')
      }
      document.body.removeChild(textarea)
    },
    loadOptions (node, callback) {
      APIService
        .GetCategories(node.amsCategoryId, this.businessProfilesSearchEngineId)
        .then((response) => {
          callback(response.data.data);
        })
    },
    fetchCategories: function () {
      var self = this;
      self.categorydata = [];
      APIService
        .GetCategories(0, this.businessProfilesSearchEngineId)
        .then((response) => {
          self.categorydata = response.data.data;
          self.categorydata.forEach(node => {
            self.data.push(node);
            if (node.hasChildren) {
              self.$set(node, 'loading', false)
            }
          })
          self.filteredData = self.data;
        })
        .catch((x) => {
          this.errorOccurred = true;
          this.errorMessage = "Unable to fetch categories data.";
        });
    },
    transverseNode (node, lowerSearchTerm) {
    // Check if the current node's categoryName matches the searchTerm
      if (node.categoryName.toLowerCase().includes(lowerSearchTerm)) {
        // Add the object to the searchData array with categoryName and isTargatable properties
        this.searchData.push({
          categoryName: node.categoryName,
          istargetable: node.istargetable
          // Add other properties if needed
        });
      }

      // Recursively transverse the children
      if (node.children && node.children.length > 0) {
        node.children.forEach(childNode => this.transverseNode(childNode, lowerSearchTerm));
      }
    },
    searchCategories () {
      var self = this;
      let searchTerm = this.search;
      self.searchData = [];
      if (searchTerm === "") {
        // If searchTerm is empty, set filteredData to the entire original tree
        self.filteredData = self.data;
      } else {
        let lowerSearchTerm = searchTerm.toLowerCase();
        self.data.forEach(parentNode => self.transverseNode(parentNode, lowerSearchTerm));
        self.filteredData = self.searchData;
      }
    }
  },
  computed: {
    ...mapState(["businessProfilesSearchEngineId", "businessProfileId", "savedTargetsMapperByBpseId", "labels", "selectedCampaignLauncherConfigId", "mediaPlanLineItems", "publisher"])
  },
  watch: {
    businessProfilesSearchEngineId () {
      this.iFrameloaded = false;
      this.fetchCategories();
    }
  }
};
</script>
<style scoped>
.widget-body > table {
  width: 100%;
}
.widget-body {
  height: 500px !important;
}
.widget-body > table > tr > td {
  padding-top: 10px;
}
.widget-element {
  height: 30px;
}
.widget > hr {
  margin: 0px;
}
.loader-div {
  width: inherit;
  text-align: center;
  height: inherit;
}
.ellipsis {
  width: 50%;
  position: relative;
}
.ellipsis:before {
  content: "&nbsp;";
  visibility: hidden;
}
.ellipsis span {
  width: 190px;
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ul .ivu-tree-children .ivu-tree ul {
font-size: 15px !important;
}
.ivu-tree {
  padding-bottom: 8%;
}
</style>

<style>
.target-tooltip .tooltip .tooltip-inner {
  max-width: 600px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: normal;
  word-break: initial;
  padding: 5px 10px;
}
.target-info-table {
  max-width: 300px !important;
  min-width: 250px !important;
}
.target-modal .modal-dialog {
  width: 98%;
  height: 100%;
}
.input-search-bar .ivu-input {
  height: 30px;
}
.tooltip-size {
  max-width: 500px;
  max-height: 200px;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
