<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <Row>
          <Row-Col>
            <h4>Store Visits Macros
            </h4>
          </Row-Col>
        </Row>
      </div>
      <VuePerfectScrollbar class="widget-body">
        <Row class="cl-ivu-row" v-for="item in pageSetMacros" :key="item">
          <Row-Col span="1">
            <i class="fas fa-copy action-icon"
              @click="copyToClipboard(item)">
            </i>
          </Row-Col>
          <Row-Col offset="1" span="21" class="cl-widget-text">{{ item }}</Row-Col>
        </Row>
      </VuePerfectScrollbar>
    </Card>
  </div>
</template>
<script>
import { Card, Row, Col } from "iview";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import mixins from "../../../Mixins/copyToClipboard.js";
export default {
  components: {
    Card,
    Row,
    "Row-Col": Col,
    VuePerfectScrollbar
  },
  mixins: [mixins],
  props: {
    deltaxConfig: { type: Object, default: () => {} }
  },
  data: function () {
    return {
      pageSetMacros: [
       	"{{page.hours.today}}",
        "{{page.location.city}}",
        "{{page.location.region}}",
        "{{page.location.postcode}}",
        "{{page.location.street_address}}",
        "{{page.name}}",
        "{{page.phone_number}}"
      ]
    }
  }
}
</script>
<style>
.fa-eye:hover {
  cursor: pointer;
}
</style>
<style scoped>
.fa-refresh {
  margin-left:5px;
  margin-right:5px;
}
</style>
