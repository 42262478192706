<template>
  <div id ="campaign-launcher" class="elements">
    {{ allVerified }}
    <div v-if="budgetTypePivot.campaignGroupCount > 0" class="budget-type-pivot">
      <div class="">
        <label class="element-label">Campaign Setup</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ budgetTypeVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="budgetTypeVerified" />
          </label>
        </span>
      </div>
      <div class="budget-type-table">
        <table class="table perf">
          <thead>
            <tr>
              <th
                v-for="item in linkedInEnums.budgetTypeColumns"
                :key="item"
                class="table-header"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(campaignGroup, j) in budgetTypePivot.records">
              <tr v-for="(campaign, i) in campaignGroup.campaigns" :key="j + '-' + i">
                <td class="data-center" v-if="i==0" :rowspan="campaignGroup.campaignCount">{{ campaignGroup.name }}</td>
                <td class="data-center">{{ campaign.name }}</td>
                <td class="data-center">
                  <span v-if="submissionId != 0">
                    <Icon :type="statusClass[campaign.status]"/> {{ campaign.status }}
                  </span>
                  <span v-else>
                    <Dropdown @on-click="editSummaryData">
                      <a href="javascript:void(0)">
                          <Icon :type="statusClass[campaign.status]"/> {{ campaign.status }}
                          <Icon type="ios-arrow-down"/>
                      </a>
                      <DropdownMenu slot="list">
                          <DropdownItem v-for="(name, enumId) in linkedInEnums.status" :key="enumId"
                            :selected="campaign.status == name.toUpperCase()"
                            :name="getEditSummaryData(campaign.internalCampaignId, 'status', enumId, linkedInEnums.entityType.campaign)">
                            <Icon :type="statusClass[name.toUpperCase()]"/> {{ name.toUpperCase() }}
                        </DropdownItem>
                      </DropdownMenu>
                  </Dropdown>
                  </span>
                </td>
                <td class="data-center">{{ campaign.objective }}</td>
                <td class="data-center">{{ campaign.budgetType}}</td>
                <td class="data-center">{{ campaign.bpCurrency +" "+ parseFloat(campaign.budget).toFixed(2)}}</td>
                <td class="data-center">
                  <i-input
                    v-model="campaign.bidAmount"
                    :disabled="campaign.biddingStrategy == 1"
                    type="number"
                    @on-change="editSummaryData(getEditSummaryData(campaign.internalCampaignId, 'bidAmount', campaign.bidAmount, linkedInEnums.entityType.campaign))"
                  >
                    <span slot="append">{{ campaign.bpCurrency }}</span>
                  </i-input>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="targetsPivot.targetInfo.length > 0" class="targets-pivot">
      <div class="title-sticky">
        <label class="element-label">Targeting</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{targetsVerified ? "Verified" : "Check to verify"}}
            <input
              type="checkbox"
              v-model="targetsVerified"
            />
          </label>
        </span>
      </div>
      <div class="targets-table">
        <table class="table perf">
          <thead>
            <tr>
              <th
                v-for="item in targetingColumns"
                :key="item"
              >{{ item }}</th>
            </tr>
          </thead>
          <tbody v-for="(targetRow,i1) in targetsPivot.targetInfo" :key="i1">
            <tr>
              <td>
                <div>
                  <label class="target-label">
                      {{targetRow.name}}
                  </label>
                    <!-- <Tooltip max-width="1000" placement="right-start" class="target-tooltip">
                      <Icon type="md-alert"/>
                      <div slot="content">
                        <div v-html="targetRow.bidsGroup[0].targetHTML"></div>
                      </div>
                    </Tooltip>
                    <div class="target-sub-label">
                    <i>{{targetRow.bidsGroup[0].targetReach | reach(bidRow)}}</i>
                  </div>
                  <div class="target-sub-label">
                    <i>{{targetRow.bidsGroup[0].targetData}}</i>
                  </div> -->
                </div>
              </td>
              <!-- <td class="data-right">{{bidRow.bid | currency(bpCurrency)}}</td> -->
              <td class="data-right">
                {{targetRow.adgroups}}
                <span class="no-of-ads">({{targetRow.ads}} ads)</span>
              </td>
            </tr>
          </tbody>
          <tfoot class="total-footer">
            <tr>
              <td colspan="1" class="table-footer-label data-right">Total</td>
              <td class="table-footer-label data-right">
                {{targetsPivot.adGroups}}
                <span class="no-of-ads">({{targetsPivot.ads}} ads)</span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div v-if="adFormatPivot.formats.length > 0" class="adFormat-pivot">
      <div class="title-sticky">
        <label class="element-label">Ad Formats</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{adFormatVerified ? "Verified" : "Check to verify"}}
            <input
              type="checkbox"
              v-model="adFormatVerified"
            />
          </label>
        </span>
      </div>
      <div class="targets-table">
        <table class="table perf">
          <thead>
            <tr>
              <th
                v-for="item in adFormatColumn"
                :key="item"
              >{{ item }}</th>
            </tr>
          </thead>
          <tbody v-for="(formatRow,i1) in adFormatPivot.formats" :key="i1">
            <tr>
              <td>
                <div>
                  <label class="adFormat-label">
                      {{linkedInEnums.adType[formatRow.adType]}}
                  </label>
                </div>
              </td>
              <!-- <td class="data-right">{{bidRow.bid | currency(bpCurrency)}}</td> -->
              <td class="data-right">
                {{formatRow.adgroup}}
                <span class="no-of-ads">({{formatRow.ads.length}} ads)</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="adsPivot.ads.length > 0" class="targets-pivot">
      <div class="title-sticky">
        <label class="element-label">Account and Creatives</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{pagesAndCreativeVerified ? "Verified" : "Check to verify"}}
            <input
              type="checkbox"
              v-model="pagesAndCreativeVerified"
            />
          </label>
        </span>
      </div>
      <div v-if="adsPivot.account" class="page-label">{{adsPivot.account.accountNickName}}({{adsPivot.account.accountId}})</div>
      <div class="targets-table">
        <table class="table perf">
          <thead>
            <tr>
              <th
                v-for="item in pagesAndCreativeColumn"
                :key="item"
              >{{ item }}</th>
            </tr>
          </thead>
          <tbody v-for="(adRow,i1) in adsPivot.ads" :key="i1">
            <tr>
              <td class="ad-status">
                <div>
                  <span v-if="submissionId != 0">
                    <Icon :type="statusClass[adRow.status]"/> {{ adRow.status }}
                  </span>
                  <Dropdown v-else @on-click="editSummaryData">
                    <a href="javascript:void(0)">
                        <Icon :type="statusClass[adRow.status]"/> {{ adRow.status }}
                        <Icon type="ios-arrow-down"/>
                    </a>
                    <DropdownMenu slot="list">
                        <DropdownItem v-for="(name, enumId) in linkedInEnums.adStatus" :key="enumId"
                          :selected="adRow.status == name.toUpperCase()"
                          :name="getEditSummaryData(adRow.adCopyId, 'status', enumId, linkedInEnums.entityType.ad)">
                          <Icon :type="statusClass[name.toUpperCase()]"/> {{ name.toUpperCase() }}
                        </DropdownItem>
                      </DropdownMenu>
                  </Dropdown>
                </div>
              </td>
              <td>
                <div>
                  <label class="ad-label">
                      {{adRow.adCopyId}}
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="ad-label">
                      {{adRow.landingPageUrl}}
                  </label>
                </div>
              </td>
              <td>
                <div>
                  <label class="ad-label">
                      {{getCommaSeperatedtargetName(adRow.target)}}
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="linkedInDisplayNetworkPivot.displayNetworksRows.length > 0" class="targets-pivot">
      <div class="title-sticky">
        <label class="element-label">Placements</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{displayNetworkVerified ? "Verified" : "Check to verify"}}
            <input
              type="checkbox"
              v-model="displayNetworkVerified"
            />
          </label>
        </span>
      </div>
      <div class="targets-table">
        <table class="table perf">
          <thead>
            <tr>
              <th
                v-for="item in displayNetworkColumn"
                :key="item"
              >{{ item }}</th>
            </tr>
          </thead>
          <tbody v-for="(displayNetwork,i1) in linkedInDisplayNetworkPivot.displayNetworksRows" :key="i1">
            <tr>
              <td>
                <div>
                  <label class="ad-label">
                      {{displayNetwork.label}}
                  </label>
                </div>
              </td>
              <td class="data-right">
                {{displayNetwork.numberOfAdgroups}}
                <span class="no-of-ads">({{displayNetwork.numberOfAds}} ads)</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { locale, DropdownItem, DropdownMenu, Dropdown, Icon, Input } from "iview";
import lang from "iview/dist/locale/en-US";
import { mapState, mapGetters, mapMutations } from "vuex";
import { linkedInEnums } from "../../../Constants/LinkedInEnums.js";

locale(lang); // iview is default chinese

export default {
  mounted: function () {},
  components: {
    DropdownItem,
    DropdownMenu,
    Dropdown,
    Icon,
    "i-input": Input
  },
  props: {
    submissionId: { default: 0 }
  },
  data: function () {
    return {
      budgetTypeVerified: false,
      targetsVerified: false,
      adFormatVerified: false,
      displayNetworkVerified: false,
      pagesAndCreativeVerified: false,
      linkedInEnums,
      targetingColumns: ["Target Name", "No. of Campaigns"],
      adFormatColumn: ["Ad Format", "No. of Campaigns"],
      pagesAndCreativeColumn: ["Status", "Ad Copy Id", "Landing Page", "Targets"],
      displayNetworkColumn: ["LinkedIn Display Network", "No. of Campaigns"],
      statusClass: {
        "ACTIVE": "ios-play",
        "PAUSED": "ios-pause",
        "ARCHIVED": "ios-archive"
      }
    };
  },
  created: function () {
    if (this.isCampaignLauncherVerificationEnabled == false) {
      this.budgetTypeVerified = true;
      this.targetsVerified = true;
      this.adFormatVerified = true;
      this.displayNetworkVerified = true;
      this.pagesAndCreativeVerified = true;
    }
  },
  computed: {
    ...mapState([
      "linkedInEntitiesResponse",
      "selectedPublisher",
      "publisher",
      "savedTargetsMapperByBpseId",
      "businessProfilesSearchEngineId",
      "campaignLauncherConfigBpseIdMapper",
      "selectedCampaignLauncherConfigId",
      "savedTargets",
      "linkedInAccounts",
      "bpCurrencySymbol",
      "isCampaignLauncherVerificationEnabled"
    ]),
    ...mapGetters(["linkedInEntitiesResponse", "editedSummaryData"]),
    budgetTypePivot: function () {
      const linkedInEntities = this.linkedInEntitiesResponse.data.childEntities;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.budgetTypeVerified = false;
      }
      if (!linkedInEntities.length) {
        this.budgetTypeVerified = true;
        return { records: [], campaignGroupCount: 0 };
      }
      let records = linkedInEntities.map(campaignGroup => {
        var campaigns = campaignGroup.childEntities.map(entity => {
          var campaignStatus = entity.rowData.campaignStatus
          if (this.editedSummaryData) {
            var editedSummaryDataJson = JSON.parse(this.editedSummaryData)
            var editedData = editedSummaryDataJson.find(esd => esd.internalId == entity.rowData.internalCampaignId && esd.status)
            if (editedData) {
              campaignStatus = linkedInEnums.status[editedData.status].toUpperCase()
            }
          }
          return {
            name: entity.name,
            status: campaignStatus,
            objective: entity.rowData.objective,
            budgetType: entity.rowData.budgetTypeName,
            budget: entity.rowData.budgetType == 1 ? entity.rowData.dailyBudget : entity.rowData.lifetimeBudget,
            bpCurrency: entity.additionalData.bp_currency ? entity.additionalData.bp_currency : this.bpCurrencySymbol,
            deltaXTargetId: entity.rowData.deltaXTargetId,
            internalCampaignId: entity.rowData.internalCampaignId,
            bidAmount: entity.rowData.bidAmount,
            biddingStrategy: entity.rowData.biddingStrategy
          }
        });

        return {
          name: campaignGroup.name,
          campaigns,
          campaignCount: campaigns.length
        }
      });
      return { records: records, campaignGroupCount: records.length };
    },
    targetsPivot: function () {
      const linkedInEntities = this.linkedInEntitiesResponse.data.childEntities;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.targetsVerified = false;
      }
      const targetIds = []
      var targetingInformation = [];
      var self = this;
      linkedInEntities.forEach(campaignGroup => {
        campaignGroup.childEntities.forEach(campaign => {
          if (targetIds.includes(campaign.rowData.deltaXTargetId)) {
            var targetObject = targetingInformation.find(o => o.targetId === campaign.rowData.deltaXTargetId);
            targetObject.adCount += campaign.childEntities.length;
          } else if (!targetIds.includes(campaign.rowData.deltaXTargetId)) {
            targetIds.push(campaign.rowData.deltaXTargetId)
            targetingInformation.push({
              targetId: campaign.rowData.deltaXTargetId,
              ads: campaign.childEntities ? campaign.childEntities.length : 0,
              adgroups: 1,
              name: self.savedTargets.find(x => x.id === campaign.rowData.deltaXTargetId).name
            })
          }
        })
      })
      if (!targetingInformation.length) {
        this.targetsVerified = true;
        return { targetInfo: [], adGroups: 0, ads: 0 };
      }
      var totalAds = targetingInformation.reduce((accum, item) => accum + item.ads, 0)
      var totalAdGroups = targetingInformation.reduce((accum, item) => accum + item.adgroups, 0)
      return { targetInfo: targetingInformation, adGroups: totalAdGroups, ads: totalAds };
    },
    adFormatPivot: function () {
      const linkedInEntities = this.linkedInEntitiesResponse.data.childEntities;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.adFormatVerified = false;
      }
      const formats = []
      linkedInEntities.forEach(campaignGroup => {
        campaignGroup.childEntities.forEach(campaign => {
          if (campaign.childEntities) {
            campaign.childEntities.forEach(ad => {
              var formatObject = formats.find(x => x.adType === ad.rowData.adType)
              if (formatObject) {
                if (!formatObject.targetId.includes(campaign.rowData.deltaXTargetId)) {
                  formatObject.adgroup++;
                  formatObject.targetId.push(campaign.rowData.deltaXTargetId)
                }
                formatObject.ads.push(ad)
              } else {
                formats.push({
                  adType: ad.rowData.adType,
                  adgroup: 1,
                  targetId: [campaign.rowData.deltaXTargetId],
                  ads: [ad]
                })
              }
            })
          }
        })
      })
      if (!formats.length) {
        this.adFormatVerified = true;
        return { formats: [] };
      }
      return { formats: formats };
    },
    adsPivot: function () {
      const linkedInEntities = this.linkedInEntitiesResponse.data.childEntities;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.pagesAndCreativeVerified = false;
      }
      var ads = []
      var uniqueAdCopyIds = []
      var self = this
      linkedInEntities.forEach(campaignGroup => {
        campaignGroup.childEntities.forEach(campaign => {
          if (campaign.childEntities) {
            campaign.childEntities.forEach(ad => {
              if (!uniqueAdCopyIds.includes(ad.rowData.adCopyId)) {
                uniqueAdCopyIds.push(ad.rowData.adCopyId)
                var adStatus = ad.rowData.adStatus
                if (this.editedSummaryData) {
                  var editedSummaryDataJson = JSON.parse(this.editedSummaryData)
                  var editedData = editedSummaryDataJson.find(esd => esd.internalId == ad.rowData.adCopyId)
                  if (editedData) {
                    adStatus = linkedInEnums.adStatus[editedData.status].toUpperCase()
                  }
                }
                var creativeData = {
                  adCopyId: ad.rowData.adCopyId,
                  landingPageUrl: ad.rowData.landingPageUrl,
                  target: [self.savedTargets.find(x => x.id === campaign.rowData.deltaXTargetId)],
                  status: adStatus
                }
                ads.push(creativeData);
              } else if (uniqueAdCopyIds.includes(ad.rowData.adCopyId)) {
                ads.find(x => x.adCopyId === ad.rowData.adCopyId).target.push(self.savedTargets.find(x => x.id === campaign.rowData.deltaXTargetId))
              }
            })
          }
        })
      })
      if (!ads.length) {
        this.pagesAndCreativeVerified = true
        return { ads: [], account: null }
      }
      var account = this.linkedInAccounts.find(x => x.accountId === linkedInEntities[0].accountId);
      return { ads: ads, account };
    },
    linkedInDisplayNetworkPivot: function () {
      const linkedInEntities = this.linkedInEntitiesResponse.data.childEntities;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.displayNetworkVerified = false;
      }
      var offsiteDelivery = []
      var displayNetworksRows = [
        {
          type: 1,
          label: "Enabled",
          numberOfAdgroups: 0,
          numberOfAds: 0
        },
        {
          type: 2,
          label: "Disabled",
          numberOfAdgroups: 0,
          numberOfAds: 0
        }
      ]
      linkedInEntities.forEach(campaignGroup => {
        campaignGroup.childEntities.forEach(campaign => {
          if (campaign.apiData.offsiteDeliveryEnabled == true) {
            displayNetworksRows[0].numberOfAdgroups++
            displayNetworksRows[0].numberOfAds += campaign.childEntities ? campaign.childEntities.length : 0
          } else if (campaign.apiData.offsiteDeliveryEnabled == false) {
            displayNetworksRows[1].numberOfAdgroups++
            displayNetworksRows[1].numberOfAds += campaign.childEntities ? campaign.childEntities.length : 0
          }
        })
      })
      if (!linkedInEntities.length) {
        this.displayNetworkVerified = true;
        return { displayNetworksRows: [] };
      }
      return { displayNetworksRows: displayNetworksRows };
    },
    allVerified: function () {
      this.$emit("elementsVerified", [
        { value: this.budgetTypeVerified, label: "Campaign Setup" },
        { value: this.targetsVerified, label: "Targeting" },
        { value: this.adFormatVerified, label: "Ad Formats" },
        { value: this.displayNetworkVerified, label: "Placements" },
        { value: this.pagesAndCreativeVerified, label: "Account and Creative" }
      ]);
      return "";
    }
  },
  methods: {
    ...mapMutations(["setEditedSummaryData"]),
    getCommaSeperatedtargetName (targetsList) {
      return Array.prototype.map.call(targetsList, function (target) { return target.name; }).join(",");
    },
    getEditSummaryData (internalId, key, value, entityType) {
      return JSON.stringify({
        "internalId": internalId,
        "key": key,
        "value": value,
        "entityType": entityType
      });
    },
    editSummaryData (editedData) {
      if (editedData) {
        editedData = JSON.parse(editedData)
        var editedSummaryDataJson = this.editedSummaryData ? JSON.parse(this.editedSummaryData) : []
        var newEditedData = editedSummaryDataJson.find(sd => sd.internalId == editedData.internalId)
        if (newEditedData) {
          newEditedData[editedData.key] = editedData.value
        } else {
          newEditedData = {
            "internalId": editedData.internalId,
            "entityType": editedData.entityType
          }
          newEditedData[editedData.key] = editedData.value
          editedSummaryDataJson.push(newEditedData)
        }
        this.setEditedSummaryData(JSON.stringify(editedSummaryDataJson))
      }
    }
  }
};
</script>

<style scoped>
.title-sticky {
  position: sticky;
  padding: 0px 5px;
  position: sticky;
  top: 100px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
}
.verified-action {
  float: right;
  padding: 10px;
}
.checkbox-status-label {
  padding-right: 5px;
  color: #999;
  cursor: pointer;
}
input[type="checkbox"] {
  vertical-align: text-bottom;
}
.total-footer {
  font-weight: 600;
  background-color: #eee;
}
.table-footer-label {
  border: 1px solid #ddd;
  padding: 0px 5px;
}
.target-label {
  padding-top: 10px;
}
.target-sub-label {
  padding-top: 10px;
  font-size: 0.9em;
  color: #979898;
  max-width: 420px;
}
.page-label {
  padding: 10px 5px;
  position: sticky;
  top: 140px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.creatives-li {
  padding-bottom: 5px;
  list-style: none;
}
.more-previews {
  font-size: 1.3em;
}
.data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
.upper-case {
  text-transform: uppercase;
}
::v-deep .ivu-modal-footer {
  display: none;
}
.table-header{
  border:1px solid #ddd !important;
  padding:10px !important;
}
/* .ad-status {
  min-width: 100px;
} */
</style>
<style>
table.target-info-table td {
  border: 0 !important;
}
</style>
