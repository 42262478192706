<template>
  <div>
    <i-modal
      :mask-closable="false"
      :closable="false"
      v-model="alwaysTrue"
      ok-text="Continue"
      cancel-text="Cancel"
      @on-ok="linkLineItems"
      @on-cancel="linkLineItemCancel"
      width="600"
    >
      <h4 class="title" slot="header" >
            {{popUpName}}
      </h4>
      <div>
        <div class="modal-body no-padding overflow-hidden">
          <div class="bottom-padding">
            <div class="row">
              <div class="col-md-6">
                <i-select
                  v-model="selectedFilters"
                  placeholder="Select media plan type"
                  multiple
                  filterable
                >
                  <i-option
                    v-for="filter in filters"
                    :value="filter.id"
                    :key="filter.id"
                    :class="filter.icon"
                  >{{ filter.name }}</i-option>
                </i-select>
              </div>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="filterQuery"
                  placeholder="Search by Media Plan Name..."
                />
              </div>
            </div>
          </div>
          <div
            id="outer-box-border"
            class="col-md-12 outer-box-border perfect-scroll-bar overflow-hidden"
          >
            <div id="all-lineitem-wrapper" class="all-lineitem-wrapper">
              <div v-for="mp in allMediaPlans" :key="mp.id">
                <div v-show="filteredMediaPlans(mp.name,mp.status)" class="media-plan">
                  <span :class="getMediaPlanStatusIcon(mp.status)"></span>
                  <strong class="media-plan-name padding-half-left">{{mp.name}}</strong>
                  <br />
                  <div class="padding-left line-item" v-for="li in mp.lineItems.filter(x => x.lineItemMode == 1)" :key="li.id">
                    <i-tooltip :disabled="!disableLineItem(li.id)" placement="right-start" transfer>
                      <div slot="content">
                        <span>LineItem already linked to '{{getReserveOrderName(li.roId)}}'</span>
                      </div>
                      <div class="line-item-level-data">
                        <label style="margin-bottom: 0px !important;">
                        <input
                          class="line-item-checkbox"
                          id="lineItemCheckbox"
                          @change="lineItemCheckBox(li.id,li.roId)"
                          :checked="isLineItemChecked(li.id)"
                          :disabled="disableLineItem(li.id)"
                          type="checkbox"
                        />
                        <span
                          for="lineItemCheckbox"
                          class="vertical-align padding-half-left"
                        >{{li.name}}</span>
                        </label>
                      </div>
                    </i-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div>
              <div class="col-md-4 no-padding" v-show="retainLinkingCheckbox" style="margin-top: 5px;">
              <label>
               <input
                type="checkbox"
                v-model="isretainLinkingChecked"
              />
               <span
               class="vertical-align padding-half-left"
                >Retain Line Item Linking</span>
              </label>
              </div>
              <div class="float-right padding-top">
              <button @click="linkLineItemCancel" class="btn">Cancel</button>
              <button @click="linkLineItems" class="btn-success btn">
                Continue
                <i :class="modalOkSpinnerClass"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="radio-group col-md-8"></div>
      </div>
    </i-modal>
  </div>
</template>

<script>
import { Select, Option, Modal, locale, Tooltip } from "iview";
import lang from "iview/dist/locale/en-US";
locale(lang); // iview is default chinese
export default {
  components: {
    "i-select": Select,
    "i-modal": Modal,
    "i-option": Option,
    "i-tooltip": Tooltip
  },
  props: [
    "allMediaPlans",
    "lineItemLinkPopup",
    "popUpName",
    "selectedEntities",
    "disabledEntities",
    "retainLinkingCheckbox"
  ],
  watch: {
    lineItemLinkPopup: function () {
      this.lineItemLinkPopupData = this.lineItemLinkPopup;
      if (this.lineItemLinkPopup) {
        this.init();
      } else {
        this.selectedLineItems = [];
      }
    }
  },
  data: function () {
    return {
      alwaysTrue: true,
      lineItemLinkPopupData: false,
      filterQuery: "",
      filters: [
        {
          id: 1,
          value: "draft",
          label: "draft",
          key: "draft",
          name: "Draft",
          icon: " fa fa-file-text-o "
        },
        {
          id: 2,
          value: "live",
          label: "live",
          key: "live",
          name: "Live",
          icon: " fa fa-play "
        },
        {
          id: 3,
          value: "archived",
          label: "archived",
          key: "archived",
          name: "Archived",
          icon: " fa fa-trash "
        },
        {
          id: 4,
          value: "upcoming",
          label: "upcoming",
          key: "upcoming",
          name: "Upcoming",
          icon: " fa fa-clock-o "
        }
      ],
      selectedFilters: [],
      selectedLineItems: [],
      modalOkSpinnerClass: "",
      isretainLinkingChecked: false
    };
  },
  mounted: function () {
    this.init();
    $(".perfect-scroll-bar").perfectScrollbar({ suppressScrollX: true });
  },
  methods: {
    getReserveOrderName: function (roId) {
      // var ro = this.roList.filter(x => x.id == roId);
      var ro = this.disabledEntities.filter(x => x.id == roId);
      return ro.length > 0 ? ro[0].name : " ";
    },
    disableLineItem: function (lineItemId) {
      var lineItemsInOtherRO = []
      this.disabledEntities.forEach(element => {
        if (element.lineItemIdsList != undefined && element.lineItemIdsList != [] && element.lineItemIdsList != null) {
          element.lineItemIdsList.forEach(li => {
            lineItemsInOtherRO.push(li)
          })
        }
      })

      var lineItemPresentInOtherRO = lineItemsInOtherRO.map(x => x)
        .includes(lineItemId);
      if (lineItemPresentInOtherRO) {
        return true;
      } else {
        return false;
      }
    },
    resetScroller: function () {
      var allLineItemsDiv = document.getElementById("outer-box-border");
      allLineItemsDiv.scrollTop = 0;
    },
    filteredMediaPlans: function (mediaPlanName, mediaPlanStatus) {
      let searched =
        this.filterQuery.length > 0
          ? mediaPlanName.toLowerCase().includes(this.filterQuery.toLowerCase())
          : true;
      let appliedFilters = true;
      if (this.selectedFilters.length > 0) {
        appliedFilters = this.selectedFilters.includes(mediaPlanStatus);
      }
      return searched && appliedFilters;
    },
    getMediaPlanStatusIcon: function (status) {
      var iconCss = "";
      if (status == 1) {
        // draft
        iconCss = " fa fa-file-text-o ";
      } else if (status == 2) {
        // live
        iconCss = " fa fa-play ";
      } else if (status == 3) {
        // Archived
        iconCss = " fa fa-trash ";
      } else if (status == 4) {
        // Upcoming
        iconCss = " fa fa-clock-o ";
      }
      return iconCss;
    },
    isLineItemChecked: function (lineItemId) {
      var res = false;
      var lineItemPresent = false;
      if (this.selectedEntities != undefined && this.selectedEntities != null && this.selectedEntities != []) {
        lineItemPresent = this.selectedEntities
          .map(x => x)
          .includes(lineItemId);
      }
      if (lineItemPresent) {
        res = true;
      }
      return res;
    },
    init: function () {
      this.selectedFilters = [2, 4]; // Default: Upcoming and Live
      // orderId 1:HasSelectedLI 2:NoLISelected
      this.selectedLineItems = [];
      this.filterQuery = "";
      this.allMediaPlans.map(x => {
        x.orderId = 2;
      });
      this.allMediaPlans.forEach(mp => {
        mp.lineItems.forEach(li => {
          if (this.selectedEntities != undefined && this.selectedEntities != null && this.selectedEntities != []) {
            if (this.selectedEntities.includes(li.id)) {
            // The Id for the newly created ReserveOrder is 0
            //, so added not 0 condition to limit lineitem selection
              mp.orderId = 1;
              if (!this.selectedFilters.includes(mp.status)) {
                this.selectedFilters.push(mp.status);
              }
              this.selectedLineItems.push({ id: li.id, roId: li.roId });
            }
          }
        });
      });
      this.allMediaPlans.sort(function (a, b) {
        return a.orderId - b.orderId;
      });
    },
    lineItemCheckBox: function (id, roId) {
      var found = this.selectedLineItems.map(x => x.id).includes(id);
      if (!found) {
        this.selectedLineItems.push({ id: id, roId: roId });
      } else {
        var index = this.selectedLineItems.map(x => x.id).indexOf(id);
        if (index > -1) {
          this.selectedLineItems.splice(index, 1);
        }
      }
    },

    linkLineItems: function () {
      this.modalOkSpinnerClass = "fa fa-spinner fa-spin";
      this.resetScroller();
      var lineItemIds = this.selectedLineItems.map(x => x.id);
      this.selectedLineItems = [];
      var linkLineItemObject = { lineItemIds: lineItemIds, retainLinking: this.isretainLinkingChecked }
      this.$emit("linkLineItems", linkLineItemObject);
    },
    linkLineItemCancel: function () {
      this.selectedLineItems = [];
      this.resetScroller();
      this.$emit("linkLineItemCancel");
    }
  }
};
</script>

<style scoped>
.float-right {
  float: right;
}
.padding-top {
  padding-top: 15px;
}
.padding-down {
  padding-bottom: 15px;
}
.padding-left {
  padding-left: 7px;
}
.padding-half-left {
  padding-left: 7px;
}
.outer-box-border {
  overflow: auto;
  border: #ccc 1px solid;
  padding-left: 0px;
  position: relative;
  padding-right: 0px;
  border-collapse: collapse;
}
.all-lineitem-wrapper {
  height: 300px;
  max-height: 300px;
}
::v-deep .ivu-modal-footer {
  display: none;
}
.bottom-padding {
  padding-bottom: 15px;
}
::v-deep .ivu-tooltip-inner {
  margin-bottom: 0px;
}
.media-plan {
  border-bottom: 1px solid #ccc;
  border-collapse: collapse;
  padding: 15px;
}
.line-item-checkbox {
  /* width: 15px;
    height: 15px;  */
}
.line-item-level-data {
  padding-left: 15px;
}
.overflow-hidden {
  overflow: hidden;
}
.ivu-select-multiple .ivu-select-item-selected:after {
  content: none;
  display: none;
}
.vertical-align {
  vertical-align: text-bottom;
}
::v-deep .ivu-modal-header{
  padding: 6px 16px !important;
  border-bottom: 2px solid #ccc !important;
}
</style>
