const twitterEnums = {
  objective: {
    awareness: {
      1: "Reach"
    },
    consideration: {
      // 2: "Video views",
      // 3: "Pre-roll views",
      // 4: "App Installs",
      // 5: "Website clicks",
      6: "Engagements"
      // 7: "Followers"
    },
    conversions: {
      // 8: "App re-engagements"
    }
  },
  pacing: {
    1: "Standard",
    2: "Accelerated"
  },
  bidType: [
    {
      id: 1,
      label: "Automatic Bid (recommended)",
      info: "Your bid will be optimized to maximize results at the lowest price within your budget."
    },
    {
      id: 2,
      label: "Landing Page Clicks",
      info: "Set a target cost to maintain an average cost per bid unit."
    },
    {
      id: 3,
      label: "Maximum Bid",
      info: "Set a maximum bid you’ll never spend over. Bidding higher helps your ad display more often."
    }
  ],
  objectiveBidTypeMapping: {
    6: [1, 3],
    1: [1, 2]
  },
  placements: {
    1: "Home",
    2: "Profile",
    3: "Search Result"
    // 4: "Twitter Audience Platform"
  },
  tapFormats: {
    1: "Full",
    2: "Full Landscape",
    3: "Banner",
    4: "Native",
    5: "Medium Rectangle"
  },
  objectivesPlacementMapping: {
    1: [1, 2, 3],
    2: [1, 2, 3],
    3: [1, 2, 3],
    4: [1, 2, 3, 4],
    5: [1, 2, 3, 4],
    6: [1, 2, 3],
    7: [1, 2],
    8: [1, 2, 3, 4]
  },
  status: {
    0: "Active",
    2: "Paused"
  },
  campaignStatus: {
    1: "New",
    3: "Paused",
    8: "Sucesss",
    10: "Failed"
  },
  budgetTypeColumns: [
    "Campaign",
    "Status",
    "Objective",
    "Budget Type",
    "Budget"
  ],
  targetingColumns: [
    "Target Name",
    "Number of Adgroups"
  ],
  locationsColumns: [
    "Location",
    "Number of Adgroups"
  ],
  pagesAndCreativeColumns: [
    "Ad Copy Id",
    "Landing Page",
    "Targets"
  ],
  placementsColumns: [
    "Home",
    "Profiles",
    "Search Results",
    "Twitter Audience Platform",
    "Number of Adgroups"
  ],
  objectiveEnums: {
    1: "Reach",
    2: "Video views",
    3: "Pre-roll views",
    4: "App Installs",
    5: "Website clicks",
    6: "Engagements",
    7: "Followers",
    8: "App re-engagements"
  },
  age: {
    type: ["All", "Age Range"],
    min: ["13", "18", "21", "25", "35", "50"],
    max: ["24", "34", "39", "54", "And Up"]
  },
  gender: ["All", "Men", "Female"],
  targetSkeleton: {
    "age": {
    },
    "gender": {
    },
    "locations": [
    ],
    "languages": [
    ],
    "platforms": [
    ],
    "platform_versions": [
    ],
    "devices": [
    ],
    "network_operators": [
    ],
    "keywords": {
      "include": [],
      "exclude": []
    },
    "interests": [
    ],
    "tv_shows": [
    ],
    "events": [
    ],
    "conversations": [
    ]
  },
  placementStatus: {
    Enabled: "Enabled",
    Disabled: "Disabled"
  },
  placementOption: {
    "Home": 1,
    "Profile": 2,
    "SearchResult": 3,
    "TwitterAudiencePlatform": 4
  },
  objectivesForAdSheet: {
    "Reach": 1,
    "Video views": 2,
    "Pre-roll views": 3,
    "App Installs": 4,
    "Website clicks": 5,
    "Engagements": 6,
    "Followers": 7,
    "App re-engagements": 8
  },
  targetForm: {
    targetName: "Target-{AgeGroup}",
    age: {
      type: 0,
      min: "13",
      max: "And Up"
    },
    gender: 0,
    locations: [],
    languages: [],
    platforms: [],
    platform_versions: {
    },
    network_operators: [],
    devices: [],
    includedKeywords: [],
    excludedKeywords: [],
    interests: [],
    tv_markets: [],
    events: [],
    conversations: [],
    tv_shows: [],
    locale: []
  }
};

export { twitterEnums };
