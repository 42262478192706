<template>
  <i-modal v-model="showModal" class-name="modal_placement" :closable="false" title="Update Campaign Monitor Access">
    <Row>
      <Column span="6" style="text-align: center;display: inline-block;line-height: 32px;vertical-align: center;">
        <label><b>Access</b></label>
      </Column>
      <Column span="18">
        <div class="radio-group">
            <input type="radio" id="allPlans" v-model.number="filterData.FilterType" value="0" />
            <label for="allPlans" class="pointer">All Plans</label>
            <input type="radio" id="filteredPlans" v-model.number="filterData.FilterType" value="1" />
            <label for="filteredPlans" class="pointer">Filtered Plans</label>
            <input type="radio" id="filteredLineItems" v-model.number="filterData.FilterType" value="2" />
            <label for="filteredLineItems" class="pointer">Filtered Line items</label>
        </div>
      </Column>
    </Row>
    <Row v-show="filterData.FilterType == 1">
      <Column span="18" offset="6">
        <div class="col-md-12 default-border scroll">
        <div class="padding-5" v-for="(nameFilter, index) in filterData.FilterByPlansFilter.PlanNameFilter" :key="index" >
              <label>Plan name contains</label>
              <input class="plan-name-input" type="text" v-model="nameFilter.Name"/>
              <span v-show="nameFilter.Name != ''" @click.stop="nameFilter.Name =''" class="clear-text-icon"><i class="fa fa-times" aria-hidden="true"></i></span>
              <label class="switch">
                  <input type="checkbox" v-model="nameFilter.Include" />
                  <span class="slider">{{nameFilter.Include ? "Include" : "Exclude"}}</span>
              </label>
              <i class="fa fa-trash" @click.stop="removeNameFilter(index)"></i>
          </div>
          <div class="padding-5">
              <a class="anchor-text" @click.stop="addNameFilter">Add Filter</a>
          </div>
        </div>
      </Column>
    </Row>
    <Row v-show="filterData.FilterType == 2">
      <Column span="18" offset="6">
       <div class="col-md-8 default-border" style="padding: 0px;">
        <div class="padding-5 clear-filters">
          <a class="anchor-text" @click.stop="clearSelectedLineItemsFilters">Clear</a>
          <i v-show="failed" :class="refreshClass" aria-hidden="true" @click.stop="getLineItemFilters"></i>
        </div>
        <div v-show="!loading" class="padding-5 scroll">
          <div v-if="lineItemsFilterData.publisherTypes.length > 0" class="">
              <label class="line-items-filter-header">Publisher Types</label>
              <div>
                  <div v-for="publisherType in lineItemsFilterData.publisherTypes" :key="publisherType.Id">
                      <input type="checkbox" :value="publisherType.Id" v-model.number="filterData.FilterByLineItemsFilter.TypeFilter" :id="'publisher'+ publisherType.Id" />
                      <Label :for="'publisher'+ publisherType.Id" class="pointer">{{publisherType.Name}}</Label>
                  </div>
              </div>
          </div>
          <div v-if="lineItemsFilterData.publisherNames.length > 0" class="">
              <label class="line-items-filter-header">Publishers</label>
              <div>
                  <div v-for="publisherName in lineItemsFilterData.publisherNames" :key="publisherName">
                      <input type="checkbox" :value="publisherName" v-model="filterData.FilterByLineItemsFilter.PublisherFilter" :id="'publisher'+ publisherName" />
                      <Label :for="'publisher'+ publisherName" class="pointer">{{publisherName}}</Label>
                  </div>
              </div>
          </div>
          <div v-if="lineItemsFilterData.labels.length > 0" class="">
              <label class="line-items-filter-header">Labels</label>
              <div>
                  <div v-for="label in lineItemsFilterData.labels" :key="label.Id">
                      <input type="checkbox" :value="label.Id" v-model.number="filterData.FilterByLineItemsFilter.LabelFilter" :id="'label'+ label.Id" />
                      <Label :for="'label'+ label.Id" class="pointer">{{label.Name}}</Label>
                  </div>
              </div>
          </div>
        </div>
        <div v-show="loading" class="padding-5">
          <label>Fetching line item filter options..</label>
        </div>
       </div>
      </Column>
    </Row>
    <div slot="footer">
      <button class="btn btn-white" @click="closeModal()">Close</button>
      <button class="btn btn-success" @click.prevent="onSave()">Save</button>
    </div>
  </i-modal>
</template>

<script>
import { Modal, Row, Col, locale } from "iview";
import lang from "iview/dist/locale/en-US";
import { CampaignMonitorAccessApiService } from '../Services/apiService.js';
locale(lang);
export default {
  components: {
    "i-modal": Modal,
    Row,
    "Column": Col
  },
  mounted: function () {
    this.apiService = new CampaignMonitorAccessApiService();
    // eslint-disable-next-line no-undef
    if (deltax.businessProfileId != undefined) {
      this.businessProfileId = deltax.businessProfileId;
    }
  },
  props: {
    isCampaignMonitorAccess: { default: false },
    userId: undefined,
    businessProfileId: undefined,
    setCmaData: Object
  },
  data: function () {
    return {
      filterData: {
        FilterType: 0,
        FilterByPlansFilter: {
          PlanNameFilter: []
        },
        FilterByLineItemsFilter: {
          TypeFilter: [],
          PublisherFilter: [],
          LabelFilter: []
        }
      },
      lineItemsFilterData: {
        publisherTypes: [],
        publisherNames: [],
        labels: []
      },
      loading: false,
      failed: false,
      refreshClass: "fa fa-refresh",
      showModal: false,
      apiService: undefined
    };
  },
  watch: {
    isCampaignMonitorAccess () {
      if (this.isCampaignMonitorAccess) {
        this.showModal = this.isCampaignMonitorAccess;
        // eslint-disable-next-line no-undef
        if (deltax.businessProfileId != undefined) {
          this.businessProfileId = deltax.businessProfileId;
        }
        this.$nextTick(function () {
          this.getLineItemFilters();
        });
        this.GetCampaignMonitorBPRoleAccessSettings();
      }
    },
    showModal () {
      if (!this.showModal) {
        this.$emit("CloseCampaignMonitorModal");
      }
    }
  },
  computed: {
  },
  methods: {
    setFilterData: function (filterData) {
      if (this.setCmaData != undefined) {
        if (this.setCmaData && Object.keys(this.setCmaData).length === 0 && Object.getPrototypeOf(this.setCmaData) === Object.prototype) {
          this.filterData = filterData;
        } else {
          this.filterData = this.setCmaData;
        }
      } else {
        this.filterData = filterData;
      }
    },
    closeModal () {
      this.showModal = false;
    },
    getFilterData: function () {
      if (this.filterData.FilterType == 0) {
        this.filterData.FilterByLineItemsFilter.TypeFilter = [];
        this.filterData.FilterByLineItemsFilter.PublisherFilter = [];
        this.filterData.FilterByLineItemsFilter.LabelFilter = [];
        this.filterData.FilterByPlansFilter.PlanNameFilter = [];
      } else if (this.filterData.FilterType == 1) {
        this.filterData.FilterByPlansFilter.PlanNameFilter =
                    this.filterData.FilterByPlansFilter.PlanNameFilter.filter(x => x.Name != "");
        this.filterData.FilterByLineItemsFilter.TypeFilter = [];
        this.filterData.FilterByLineItemsFilter.PublisherFilter = [];
        this.filterData.FilterByLineItemsFilter.LabelFilter = [];
      } else {
        this.filterData.FilterByPlansFilter.PlanNameFilter = [];
      }
      return this.filterData;
    },
    addNameFilter: function () {
      this.filterData.FilterByPlansFilter.PlanNameFilter.push({
        Include: true,
        Name: ""
      });
    },
    removeNameFilter: function (index) {
      this.filterData.FilterByPlansFilter.PlanNameFilter.splice(index, 1);
    },
    clearSelectedLineItemsFilters: function () {
      this.filterData.FilterByLineItemsFilter.TypeFilter = [];
      this.filterData.FilterByLineItemsFilter.PublisherFilter = [];
      this.filterData.FilterByLineItemsFilter.LabelFilter = [];
    },
    clearAllFilters: function () {
      this.filterData.FilterType = 0;
      this.filterData.FilterByLineItemsFilter.TypeFilter = [];
      this.filterData.FilterByLineItemsFilter.PublisherFilter = [];
      this.filterData.FilterByLineItemsFilter.LabelFilter = [];
      this.filterData.FilterByPlansFilter.PlanNameFilter = [];
    },
    getLineItemFilters: function () {
      this.loading = true;
      this.refreshClass = "fa fa-refresh fa-spin";
      var url = "/App/CommonAjax/GetCampaignMonitorFilters";
      fetch(url.concat(`/${this.businessProfileId}`), {
        method: 'POST',
        headers: { "X-Bypass-Login": "true" },
        dataType: 'json'
      }).then((res) => res.json())
        .then((data) => {
          this.loading = false;
          this.refreshClass = "fa fa-refresh";
          this.failed = false;
          this.lineItemsFilterData.publisherTypes = data.publisherTypes;
          this.lineItemsFilterData.publisherNames = data.publisherNames;
          this.lineItemsFilterData.labels = data.labels;
        })
        .catch((err) => {
          if (err.response.status == 404 && err.response.data.errorCode == 104) {
            this.loading = false;
          }
          this.refreshClass = "fa fa-refresh";
          this.failed = true;
          this.$emit("LineItemFetchFailure");
        });
    },
    GetCampaignMonitorBPRoleAccessSettings: function () {
      this.clearAllFilters();
      this.apiService.getCampaignMonitorBPRoleAccessSettings(this.userId, this.businessProfileId).then((response) => {
        console.log(response);
        if (response.data.status) {
          if (response.data.data != undefined && response.data.data != "") {
            this.setFilterData(JSON.parse(response.data.data));
          }
        } else {
          this.loading = false;
          if (!(this.setCmaData && Object.keys(this.setCmaData).length === 0 && Object.getPrototypeOf(this.setCmaData) === Object.prototype)) {
            this.filterData = this.setCmaData;
          }
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
        if (!(this.setCmaData && Object.keys(this.setCmaData).length === 0 && Object.getPrototypeOf(this.setCmaData) === Object.prototype)) {
          this.filterData = this.setCmaData;
        }
      })
    },
    onSave () {
      var campaignMonitorBPRoleAccessSettings = JSON.stringify(this.getFilterData());
      this.$emit("OnCampaignMonitorAccessSave", this.userId, this.businessProfileId, campaignMonitorBPRoleAccessSettings);
    }
  }
}
</script>

<style scoped>

.default-border{
    border: 1px solid #cccccc;
}
.padding-5{
    padding:5px;
}
label{
  padding: 0px 5px;
}
.scroll{
    max-height: 180px;
    overflow-y: auto;
}
.pointer{
    cursor: pointer !important;
}
.anchor-text{
    text-decoration: underline;
    font-size: 12px;
    color: #979898;
    cursor: pointer;
}
.btn {
  margin-left: 8px;
  margin-bottom: 0;
}
.radio-group{
    display: block;
    width: 100%;
    height: 32px;
    padding-top: 6px;
    font-size: 13px;
    line-height: 1.42857143;
}
.plan-name-input{
    width: 180px;
    height: 18px;
    padding-left: 5px;
    padding-right: 10px;
    color: #555;
    border: 1px solid #e4e4e4;
}
.clear-text-icon{
    margin-left:-16px;
    margin-right:2px;
    z-index: 1;
}
.clear-filters{
    background-color: #F5F5F5;
}
.line-items-filter-header{
    color: black !important;
}
#cma-update label{
    color: #979898;
}
.switch {
    position: relative;
    display: inline-block;
    width: 72px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    cursor: pointer;
    background-color: #CC0000;
    height: 20px !important;
    margin-bottom: 0px !important;
    width:auto;
    color: #cccccc;
    padding-left: 20px;
    padding-right: 2px;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 18px;
    left: 4px;
    bottom: 2px;
    border: 1px solid #cccccc;
    border-radius: 18px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: #0D8218;
    padding-left: 2px;
    padding-right: 20px;
}
input:checked + .slider:before {
    left: -5px;
    -webkit-transform: translateX(54px);
    -ms-transform: translateX(54px);
    transform: translateX(54px);
}
input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}
label{
    margin-bottom: 0px;
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #cccccc;
}
::-webkit-scrollbar-thumb {
    background: #A8A8A8;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>

<style>
#CampaignMonitorAccess {
    margin-bottom: 0;
}
@media (min-width: 768px){
    .modal-dialog {
        width: 735px;
    }
}
::v-deep  .modal_placement{
    display: flex;
    align-items: center;
    justify-content: center;
}
::v-deep .ivu-modal{
    top: 0;
}
::v-deep  .modal_placement{
    display: flex;
    align-items: center;
    justify-content: center;
}
::v-deep .ivu-modal{
    top: 0;
}
</style>
