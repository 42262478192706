import Vue from "vue";
import { Message, Modal } from "iview";

Vue.prototype.$Message = Message;
Vue.prototype.$Modal = Modal;

export default {
  methods: {
    showSuccessMessage (content, duration = 4) {
      this.showMessage(content, "success", duration)
    },
    showErrorMessage (content, duration = 4) {
      if (!content) {
        content = "There was a problem while processing your request"
      }
      this.showMessage(content, "error", duration);
    },
    showMessage (content, type, duration) {
      return this.$Message[type]({
        background: true,
        content,
        duration
      });
    },
    showConfirmModal (options) {
      let defaultOptions = {
        title: 'Confirm',
        content: 'Are you sure you want to cancel?',
        okText: 'Okay',
        cancelText: 'Cancel',
        onOk: () => {},
        onCancel: () => {}
      }
      this.$Modal.confirm({ ...defaultOptions, ...options })
    }
  }
}
