export const sections = {
  "information": 0,
  "branding": 1,
  "mailingList": 2,
  "dashboard": 3,
  "divisions": 4,
  "budgetCenter": 5,
  "optimizerFeatures": 6,
  "campaignLauncher": 7,
  "nomenclatureConfig": 8
}

export const alertMessage = {
  "failedToLoad": 0,
  "failedToSave": 1,
  "savedSuccessfully": 2,
  "showValidationError": 3,
  "failedToUploadImage": 4,
  "invalidNomenclature": 5
}

export const mailingListType = {
  "toMailingList": 0,
  "ccMailingList": 1,
  "bccMailingList": 2
}

export const commonNomenclatureMacros = [
  {
    section: 'BusinessProfile Info',
    values: [
      {
        name: 'BusinessProfile Name',
        macro: 'BusinessProfile.Name'
      },
      {
        name: 'BusinessProfile Currency',
        macro: 'BusinessProfile.Currency'
      }
    ]
  },
  {
    section: 'Media Plan',
    values: [
      {
        name: 'MediaPlan Id',
        macro: 'MediaPlan.Id'
      },
      {
        name: 'MediaPlan Objective',
        macro: 'MediaPlan.Objective'
      },
      {
        name: 'MediaPlan Name',
        macro: 'MediaPlan.Name'
      },
      {
        name: 'MediaPlan Landing Page Link',
        macro: 'MediaPlan.LandingPageLink'
      }
    ]
  },
  {
    section: 'Line Item',
    values: [
      {
        name: 'LineItem Note 1',
        macro: 'LineItem.Note1'
      },
      {
        name: 'LineItem Note 2',
        macro: 'LineItem.Note2'
      },
      {
        name: 'LineItem Theme',
        macro: 'LineItem.Theme'
      },
      {
        name: 'LineItem Publisher',
        macro: 'LineItem.Publisher'
      },
      {
        name: 'LineItem Buy Type',
        macro: 'LineItem.BuyType'
      },
      {
        name: 'LineItem Buy Metric',
        macro: 'LineItem.BuyMetric'
      },
      {
        name: 'LineItem Channel Type',
        macro: 'LineItem.ChannelType'
      },
      {
        name: 'LineItem Id',
        macro: 'LineItem.Id'
      },
      {
        name: 'LineItem Creative Format',
        macro: 'LineItem.CreativeFormat'
      },
      {
        name: 'LineItem Targeting',
        macro: 'LineItem.Targeting'
      },
      {
        name: 'LineItem Kpi Name',
        macro: 'LineItem.KpiName'
      },
      {
        name: 'LineItem Nickname',
        macro: 'LineItem.NickName'
      },
      {
        name: 'LineItem Cost',
        macro: 'LineItem.Cost'
      },
      {
        name: 'LineItem Unit Cost',
        macro: 'LineItem.UnitCost'
      }
    ]
  },
  {
    section: 'Dates',
    values: [
      {
        name: 'MediaPlan Start Date',
        macro: 'MediaPlan.StartDate'
      },
      {
        name: 'MediaPlan End Date',
        macro: 'MediaPlan.EndDate'
      },
      {
        name: 'MediaPlan Start Date | ddMMMyy',
        macro: 'MediaPlan.StartDate|ddMMMyy'
      },
      {
        name: 'MediaPlan End Date | ddMMMyy',
        macro: 'MediaPlan.EndDate|ddMMMyy'
      },
      {
        name: 'MediaPlan Start Date | MMM-dd',
        macro: 'MediaPlan.StartDate|MMM-dd'
      },
      {
        name: 'MediaPlan End Date | MMM-dd',
        macro: 'MediaPlan.EndDate|MMM-dd'
      },
      {
        name: 'MediaPlan Start Year',
        macro: 'MediaPlan.StartYear'
      },
      {
        name: 'MediaPlan Start Month',
        macro: 'MediaPlan.StartMonth'
      },
      {
        name: 'LineItem Start Date',
        macro: 'LineItem.StartDate'
      },
      {
        name: 'LineItem End Date',
        macro: 'LineItem.EndDate'
      },
      {
        name: 'LineItem Start Date | ddMMMyy',
        macro: 'LineItem.StartDate|ddMMMyy'
      },
      {
        name: 'LineItem End Date | ddMMMyy',
        macro: 'LineItem.EndDate|ddMMMyy'
      },
      {
        name: 'LineItem Start Date | MMM-dd',
        macro: 'LineItem.StartDate|MMM-dd'
      },
      {
        name: 'LineItem End Date | MMM-dd',
        macro: 'LineItem.EndDate|MMM-dd'
      },
      {
        name: 'LineItem Start Year',
        macro: 'LineItem.StartYear'
      },
      {
        name: 'LineItem Start Month',
        macro: 'LineItem.StartMonth'
      }
    ]
  },
  {
    section: 'Fraction',
    values: [
      {
        name: 'Fraction Note',
        macro: 'Fraction.Note'
      },
      {
        name: 'Fraction Name',
        macro: 'Fraction.Name'
      }
    ]
  },
  {
    section: 'Label Dimensions',
    values: []
  }
];

export const targetNomenclatureMacros = {
  section: 'Target Configuration',
  values: [
    {
      name: 'Target Name',
      macro: 'Target.Name'
    },
    {
      name: 'Target Label',
      macro: 'Target.Label'
    }
  ]
};

export const facebookCampaignLauncherNomenclatureMacros = [
  {
    section: 'Campaign Configuration',
    values: [
      {
        name: 'Campaign Launch Config Name',
        macro: 'CampaignLaunchConfig.Name'
      },
      {
        name: 'Campaign Objective',
        macro: 'Campaign.Objective'
      },
      {
        name: 'Campaign Buy Type',
        macro: 'Campaign.BuyType'
      },
      {
        name: 'Campaign Budget Type',
        macro: 'Campaign.BudgetType'
      }
    ]
  },
  {
    section: 'Entity Data',
    values: [
      {
        name: 'Campaign Name',
        macro: 'Campaign.Name'
      },
      {
        name: 'AdGroup Name',
        macro: 'AdGroup.Name'
      },
      {
        name: 'Ad Name',
        macro: 'Ad.Name'
      }
    ]
  }
];

export const commonCampaignLauncherNomenclatureMacros = [
  {
    section: 'Campaign Configuration',
    values: [
      {
        name: 'Campaign Launch Config Name',
        macro: 'CampaignLaunchConfig.Name'
      }
    ]
  }
];

export const locationNomenclatureMacros = {
  section: 'Locations',
  values: [
    {
      name: 'Location Name',
      macro: 'Location.Name'
    },
    {
      name: 'Location Complete Path',
      macro: 'Location.CompletePath'
    }
  ]
};

export const publisher = {
  Google: 1,
  Facebook: 5
};
