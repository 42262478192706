<template>
 <i-modal
        v-model="showBriefPopup"
        :width="briefTypeId==1?'45%':'80%'"
        :class="briefTypeId==1 ? `select-modal brief-associate-popup` : `create-modal brief-associate-popup`"
        :mask-closable="false"
        :closable="false"
    >

    <h4 class="title brief-popup-header-style" slot="header">
      <div>
      <i-radio-group v-model="briefTypeId" class="col-md-12 no-left-padding">
        <i-radio label="1" :disabled="disableBriefRadioButton">
          <span label="1" class="brief-popup-label-style">Select Brief</span>
        </i-radio>
        <i-radio label="2" class="new-brief-label-style" :disabled="disableBriefRadioButton">
          <span label="2" class="brief-popup-label-style">Create new brief</span>
        </i-radio>
      </i-radio-group>
      </div>
    </h4>
      <div class="modal-body brief-popup-body-padding" :class="briefTypeId==1 || !briefCreationNotFinished?'brief-popup-existing-brief-style':''">
        <div>
        <div class="col-md-12 no-padding vue-multiselect-tree container">
          <div class="col-md-6" v-if="briefTypeId==1" style="padding-left:0px">
            <i-select v-model="associatedBrief"
                      class="full-width"
                      placeholder="Brief"
                      :disabled="$parent.isEstimateEnabled"
                      @on-change='existingBriefChange'
                      filterable>
                    <i-option v-for="item in $parent.briefs"
                          class="height150"
                          :value="item.id"
                          :key="item.id">{{item.name}}</i-option>
          </i-select>
          </div>
          <div class="col-md-6" v-if="selectedBrief && selectedBrief.phases.length > 1">
            <i-select v-model="selectedPhase"
                      placeholder="Phase"
                      class="full-width"
                      v-if="briefTypeId==1"
                      :disabled="(associatedBrief==null||associatedBrief==undefined)"
                     >
                    <i-option v-for="(item, k) in selectedBrief.phases"
                          :value="item"
                          class="height150"
                          :key="k">{{item.name}}</i-option>
          </i-select>
          </div>
          <div v-if="showBriefCreationModal">
            <brief-creation
                ref="briefInputComponent"
                @phaseInputChanged="changePhaseValidation"
                @briefInputChanged="changeBriefValidation"
                @isDivisionEnabled="changeIsDivisionEnabled"
                :businessProfileId="businessProfileId"></brief-creation>
          </div>
        </div>
        </div>
        <span>
          <label v-show="!isExistingBriefSelected" class="required-message-style">* Required</label>
        </span>
      </div>
      <div slot="footer" class="col-md-12 brief-footer">
            <div class="col-md-6 no-padding"></div>
            <div class="col-md-6 no-padding">
               <div class="brief-footer-button-style">
                  <button
                    type="button"
                    class="btn btn-white button-style createPlan-cancel-btn"
                    @click.stop="cancelBriefCreationAction"
                  >Cancel</button>
                   <button
                    type="button"
                    class="btn btn-success button-style"
                    style="margin-top: -10px !important;"
                    :disabled="(!(isPhasesValid && (isBriefValid || !(isDivisionEnabled))) && briefTypeId == 2) || (briefTypeId == 1 && ((!associatedBrief && associatedBrief!=0) || !selectedPhase))"
                    @click.stop="createMediaPlanAndBrief()"
                  >
                  Continue
                    <i :class="briefModalOkSpinnerClass"></i>
                  </button>
              </div>
            </div>
      </div>
    </i-modal>
</template>
<script>
import {
  Select,
  Option,
  Modal,
  locale,
  RadioGroup,
  Radio
} from "iview";
import lang from "iview/dist/locale/en-US"; // iview is default chinese
import BriefCreation from "../../Brief/Components/BriefInputComponent.vue";
import { briefTypeIds } from ".././Constants/executeMediaPlan.js";

locale(lang);
export default {
  components: {
    "i-modal": Modal,
    "i-select": Select,
    "i-option": Option,
    "i-radio-group": RadioGroup,
    "i-radio": Radio,
    "brief-creation": BriefCreation
  },
  data: function () {
    return {
      showBriefPopup: false,
      associatedBrief: "",
      selectedPhase: "",
      selectedBrief: "",
      briefModalOkSpinnerClass: "",
      showBriefCreationModal: false,
      isPhasesValid: false,
      businessProfileId: deltax.businessProfileId,
      briefTypeId: this.briefType,
      isExistingBriefSelected: true,
      briefCreationNotFinished: true,
      isBriefValid: false,
      disableBriefRadioButton: true,
      isDivisionEnabled: false
    }
  },
  props: ["briefType"],
  methods: {
    init: function () {
      this.associatedBrief = "";
      this.$parent.briefType = briefTypeIds.existing;
      this.briefTypeId = briefTypeIds.existing;
      this.briefModalOkSpinnerClass = "";
      this.isExistingBriefSelected = true;
      this.disableBriefRadioButton = false;
    },
    getPhases () {
      var obj = this.$refs.briefInputComponent.getBriefDetails();
      return obj.phases;
    },
    continueCreation: function () {
      this.briefCreationNotFinished = false;
    },
    createMediaPlanAndBrief: function () {
      this.disableBriefRadioButton = true;
      if (this.briefTypeId == briefTypeIds.new) {
        let brief = this.$refs.briefInputComponent.getBriefDetails();
        brief.id = 0;
        brief.newBrief = true;
        this.$parent.briefs.push(brief);
        this.associatedBrief = brief.id;
        if (brief.phases.length == 1) {
          this.selectedPhase = brief.phases[0];
          this.createPlan();
          return;
        }
        this.briefTypeId = briefTypeIds.existing;
        return;
      }
      if (this.briefTypeId == briefTypeIds.existing) {
        var selectedbrief = this.$parent.briefs.find(x => x.id == this.associatedBrief);
        if (selectedbrief.id == 0 && selectedbrief.newBrief) {
          this.briefTypeId = briefTypeIds.new;
        }
        this.createPlan(selectedbrief);
      }
    },
    changePhaseValidation (e) {
      this.isPhasesValid = e;
    },
    createPlan (selectedBrief) {
      this.briefModalOkSpinnerClass = "fa fa-spinner fa-spin";
      this.$parent.briefType = this.briefTypeId;
      if (this.briefTypeId == briefTypeIds.existing) {
        if (this.associatedBrief && this.selectedPhase) {
          this.isExistingBriefSelected = true;
          var brief = this.$parent.briefs.find(x => x.id == this.associatedBrief);
          brief.selectedPhase = this.selectedPhase;
          this.$emit('setBriefOnCreatePlan', brief);
        } else {
          this.isExistingBriefSelected = false;
          this.briefModalOkSpinnerClass = "";
        }
      }
      if (this.briefTypeId == 2) {
        var obj = selectedBrief || this.$refs.briefInputComponent.getBriefDetails();
        if (obj != undefined && obj) {
          var briefObj = {
            "description": obj.description,
            "name": obj.name,
            "businessLeadId": obj.businessLeadId,
            "planningLeadId": obj.planningLeadId,
            "objectiveId": obj.objectiveId,
            "divisionIds": obj.divisionIds,
            "phases": obj.phases,
            "selectedPhase": this.selectedPhase,
            "status": obj.status
          }
          this.$emit('setBriefOnCreatePlan', briefObj);
        } else {
          this.briefModalOkSpinnerClass = "";
        }
      }
    },
    cancelBriefCreationAction: function () {
      this.briefModalOkSpinnerClass = "";
      this.associatedBrief = "";
      this.$parent.briefType = briefTypeIds.existing;
      this.briefTypeId = briefTypeIds.existing;
      this.showBriefPopup = false;
      this.showBriefCreationModal = false;
      this.$parent.showBriefLinkingModal = false;
      this.isExistingBriefSelected = true;
      this.disableBriefRadioButton = true;
      window.formOpen = false;
    },
    existingBriefChange: function () {
      this.isExistingBriefSelected = true;
    },
    changeBriefValidation (e) {
      this.isBriefValid = e;
    },
    changeIsDivisionEnabled (e) {
      this.isDivisionEnabled = e;
    }
  },
  watch: {
    "briefTypeId": {
      handler: async function (newValue, oldValue) {
        if (newValue == 2) {
          this.isExistingBriefSelected = true;
          this.showBriefCreationModal = true;
        } else {
          this.showBriefCreationModal = false;
          this.briefCreationNotFinished = true;
        }
      }
    },
    "associatedBrief": {
      handler: async function (newValue, oldValue) {
        this.selectedBrief = this.$parent.briefs.find(x => x.id == newValue);
        if (this.selectedBrief != null) {
          this.selectedPhase = this.selectedBrief.phases.length == 1 ? this.selectedBrief.phases[0] : "";
        }
      }
    },
    selectedBrief: function () {
      if (this.selectedBrief && this.selectedBrief.phases.length == 1) {
        this.selectedPhase = this.selectedBrief.phases[0];
      }
    }
  }
}
</script>
<style>
.brief-popup-header-style{
  padding-left: 10px;
  margin-top: 0px;
}
.brief-associate-popup .ivu-modal-header {
  padding: 26px 16px !important;
}
.brief-associate-popup .ivu-radio{
  padding-bottom: 4px !important;
}
.brief-associate-popup .ivu-modal-footer{
  padding: 38px 18px 12px 18px !important;
}
.brief-popup-label-style{
  font-weight: 400;
  font-size: 17px;
}
.brief-popup-existing-brief-style{
  height: 60px;
  overflow: visible !important;
}
.brief-associate-popup .ivu-modal-header {
  padding: 26px 16px !important;
}
.brief-associate-popup .ivu-radio{
  padding-bottom: 4px !important;
}
.brief-associate-popup .ivu-modal-footer{
  padding: 38px 18px 12px 18px !important;
}
.new-brief-label-style{
  padding-left: 20px;
}
.brief-popup-body-padding{
  margin-top: -8px;
}
.brief-footer-button-style{
  margin-right: -15px;
}
.brief-footer{
  margin-top: -30px;
}
.overflow-hidden {
  overflow: hidden;
}
.required-message-style{
  color:#CC3F44;
  font-size: 12px;
}
.full-width{
  width: 100% !important;
}
.height150{
  max-height: 150px !important;
}
.no-left-padding{
  padding-left: 0px !important;
}
.select-modal{
  top: '230px !important';
}
.create-modal{
  top: '78px !important';
}
.spinner-div{
  text-align: center;
}
</style>
<style scoped>
::v-deep .brief-popup-body-padding .ivu-select .ivu-select-dropdown{
  max-width: 400px !important;
}
</style>
