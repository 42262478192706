let ApiService = {

  SaveGeneratedKey: shipData => {
    let p = fetch(deltax.shipKeyGenerate, {
      method: "POST",
      body: JSON.stringify({ shipKey: shipData }),
      headers: {
        'Content-type': 'application/json; charset=utf-8'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch(error => {
        console.error(error);
        throw Error(error);
      });
    return p;
  },
  DeleteKey: shipKey => {
    let p = fetch(deltax.deleteShipKey, {
      method: "POST",
      body: JSON.stringify({ shipKey: shipKey }),
      headers: {
        'Content-type': 'application/json; charset=utf-8'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch(error => {
        console.error(error);
        throw Error(error);
      });
    return p;
  },
  GetListGeneratedKeys: lineItemId => {
    let p = fetch(deltax.getListGeneratedShipKeyLineItem, {
      method: "POST",
      body: JSON.stringify({ lineItemId: lineItemId }),
      headers: {
        'Content-type': 'application/json; charset=utf-8'
      }
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
      .catch(error => {
        console.error(error);
        throw Error(error);
      });
    return p;
  }
}
export { ApiService };
