<template>
  <div>
     <ul class="summmary-list">
        <li v-for="(campaign,campaignIndex) in computedPublishSummary" :key="campaignIndex">
          <div
            data-toggle="collapse"
            class="summary-collapse"
            v-bind:href="'#campaign-summary-' + campaignIndex"
          >
            <span>
              <i v-bind:class="getEntityStatusIcon(campaign.status)"></i>
              <span v-if="campaign.id != ''">({{ campaign.id }})</span>
              <span class="entity-label">
                {{ campaign.name }}
                <span
                  class="pull-right"
                >{{ campaign.childEntities.length }} adgroup(s)</span>
              </span>
            </span>
            <span
              class="entity-export-error"
              v-if="campaign.failureReason != ''"
            >{{campaign.failureReason}}</span>
          </div>
          <ul
            v-bind:id="'campaign-summary-' + campaignIndex"
            class="collapse-open collapse in campaign-summary"
          >
            <li
              class="panel-collapse"
              v-for="(adgroup,adgroupIndex) in campaign.childEntities"
              :key="adgroupIndex"
            >
              <div
                data-toggle="collapse"
                class="summary-collapse collapsed"
                :href="`#adgroup-summary-${campaignIndex}-${adgroupIndex}`"
              >
                <span>
                  <i v-bind:class="getEntityStatusIcon(adgroup.status)"></i>
                  <span v-if="adgroup.id != ''">({{ adgroup.id }})</span>
                  <span class="entity-label">
                    {{ adgroup.name }}
                    <span
                      class="pull-right"
                    >{{ adgroup.childEntities.length }} ad(s)</span>
                  </span>
                </span>
                <span
                  class="entity-export-error"
                  v-if="adgroup.failureReason != ''"
                >{{adgroup.failureReason}}</span>
              </div>
              <ul
                :id="`adgroup-summary-${campaignIndex}-${adgroupIndex}`"
                class="collapse-open collapse adgroup-summary"
              >
                <li v-if="selectedPublisher == publisher.AMS.SponsoredProducts"
                class="panel-collapse"
                >
                  <div v-if="targetingType[adgroup.amsTargetType] == targetingType[1]">
                    <div>
                    <span>
                      <i v-bind:class="getTargetsStatus(adgroup.additionalData, 'keyword')" class="no-pointer"></i>
                      <span class="entity-label"> Keyword Targets ({{ adgroup.additionalData.keywordsBidInfo.length }}) </span>
                    </span>
                    </div>
                    <div :v-if="failedCount != ''">
                      {{ failedCount }}
                    </div>
                    <div v-for="(keyword,index) in keywordFailedReason.filter(failedTargets => failedTargets.parentId == adgroup.id)" :key="index">
                        {{keyword.failedReason}}
                      </div>
                  </div>
                  <div v-if="targetingType[adgroup.amsTargetType] == targetingType[1]">
                    <div>
                    <span>
                      <i v-bind:class="getTargetsStatus(adgroup.additionalData, 'negativeKeyword')" class="no-pointer"></i>
                      <span class="entity-label"> Negative Keyword Targets ({{ adgroup.additionalData.negativeKeywords.length }}) </span>
                    </span>
                    </div>
                    <div :v-if="negativeKeywordFailedCount != ''">
                      {{ negativeKeywordFailedCount }}
                    </div>
                    <div v-for="(negativeKeyword,index) in negativeKeywordFailedReason.filter(failedTargets => failedTargets.parentId == adgroup.id)" :key="index">
                        {{negativeKeyword.failedReason}}
                      </div>
                    </div>
                  <div v-if="targetingType[adgroup.amsTargetType] == targetingType[2]">
                    <div>
                    <span>
                      <i :class="getTargetsStatus(adgroup.additionalData, 'product')" class="no-pointer"></i>
                      <span class="entity-label"> Product Targets ({{ adgroup.additionalData.categories.length +  adgroup.additionalData.productsToTargets.length }}) </span>
                    </span>
                    </div>
                    <div :v-if="asinFailedCount != ''">
                      {{ asinFailedCount }}
                    </div>
                    <div v-for="(asin,index) in asinFailedReason.filter(failedTargets => failedTargets.parentId == adgroup.id)" :key="index">
                        {{asin.failedReason}}
                      </div>
                      <div v-for="(category,index) in categoryFailedReason.filter(failedTargets => failedTargets.parentId == adgroup.id)" :key="index">
                        {{category.failedReason}}
                      </div>
                  </div>
                  <div  v-if="targetingType[adgroup.amsTargetType] == targetingType[2] ">
                    <div>
                    <span>
                      <i v-bind:class="getTargetsStatus(adgroup.additionalData, 'negativeAsin')" class="no-pointer"></i>
                      <span class="entity-label"> Negative Product Targets ({{ adgroup.additionalData.negativeAsins.length }}) </span>
                    </span>
                    </div>
                     <div :v-if="negativeAsinFailedCount != ''">
                      {{ negativeAsinFailedCount }}
                    </div>
                    <div v-for="(negativeAsin,index) in negativeAsinFailedReason.filter(failedTargets => failedTargets.parentId == adgroup.id)" :key="index">
                        {{negativeAsin.failedReason}}
                      </div>
                    </div>
                </li>
                <li v-if="selectedPublisher == publisher.AMS.SponsoredDisplay"
                class="panel-collapse"
                >
                  <div v-if="sdTactics[campaign.tactics] == sdTactics[1]">
                    <div>
                    <span>
                      <i :class="getSdTargetsStatus(campaign.additionalData, 'product')" class="no-pointer"></i>
                      <span class="entity-label"> Product Targets ({{ campaign.additionalData.categories.length +  campaign.additionalData.productsToTargets.length }}) </span>
                    </span>
                    </div>
                    <div :v-if="asinFailedCount != ''">
                      {{ asinFailedCount }}
                    </div>
                    <div v-for="(asin,index) in asinFailedReason.filter(failedTargets => failedTargets.parentId == campaign.id)" :key="index">
                        {{asin.failedReason}}
                      </div>
                      <div v-for="(category,index) in categoryFailedReason" :key="index">
                        {{category.failedReason}}
                      </div>
                  </div>
                   <div  v-if="sdTactics[campaign.tactics] == sdTactics[2]">
                    <div>
                    <span>
                      <i v-bind:class="getSdTargetsStatus(campaign.additionalData, 'audience')" class="no-pointer"></i>
                      <span class="entity-label"> Audience Targets ({{ campaign.additionalData.audience.audienceToTarget.length + campaign.additionalData.audience.category.length + campaign.additionalData.audience.product.length }}) </span>
                    </span>
                    </div>
                     <div :v-if="audienceFailedCount != ''">
                      {{ audienceFailedCount }}
                    </div>
                    <div v-for="(audience,index) in audienceFailedReason.filter(failedTargets => failedTargets.parentId == campaign.id)" :key="index">
                        {{audience.failedReason}}
                      </div>
                    </div>
                  <div v-if="sdTactics[campaign.tactics] == sdTactics[1]">
                    <div>
                    <span>
                      <i v-bind:class="getSdTargetsStatus(campaign.additionalData, 'negativeAsin')" class="no-pointer"></i>
                      <span class="entity-label"> Negative Product Targets ({{ campaign.additionalData.negativeAsins.length }}) </span>
                    </span>
                    </div>
                     <div :v-if="negativeAsinFailedCount != ''">
                      {{ negativeAsinFailedCount }}
                    </div>
                    <div v-for="(negativeAsin,index) in negativeAsinFailedReason.filter(failedTargets => failedTargets.parentId == campaign.id)" :key="index">
                        {{negativeAsin.failedReason}}
                      </div>
                    </div>
                </li>
                <li
                  class="panel-collapse"
                  v-for="(ad,adIndex) in adgroup.childEntities"
                  :key="adIndex"
                >
                  <div>
                    <span>
                      <i v-bind:class="getEntityStatusIcon(ad.status)" class="no-pointer"></i>
                      <span v-if="ad.id != ''"> ({{ ad.id }})</span>
                      <span v-if="ad.sku != null && ad.sku !='None'" class="entity-label"> {{ ad.name }} ({{ad.sku}})</span>
                      <span v-else class="entity-label"> {{ ad.name }}</span>
                    </span>
                    <span
                      class="entity-export-error"
                      v-if="ad.failureReason != ''"
                    >{{ad.failureReason}}</span>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  components: {},
  data: function () {
    return {
      exportStatus: {
        "Success": 8,
        "Failed": 10,
        "New": 1
      },
      targetingType: {
        1: "Keyword Targeting",
        2: "Product Targeting"
      },
      sdTactics: {
        1: "Product Targeting",
        2: "Audience Targeting"
      },
      entity: {
        "keyword": "keyword",
        "negativeKeyword": "negativeKeyword",
        "product": "product",
        "category": "category",
        "negativeAsin": "negativeAsin",
        "audience": "audience"
      },
      failedCount: '',
      keywordFailedReason: [],
      negativeKeywordFailedReason: [],
      asinFailedReason: [],
      negativeAsinFailedReason: [],
      categoryFailedReason: [],
      audienceFailedReason: [],
      negativeKeywordFailedCount: '',
      negativeAsinFailedCount: '',
      audienceFailedCount: '',
      asinFailedCount: ''
    };
  },
  computed: {
    ...mapState(["amsEntitiesResponse", "selectedPublisher", "publisher", "failedTargets"]),
    computedPublishSummary: function () {
      let campaigns = this.amsEntitiesResponse.data.amsEntities;
      this.keywordFailedReason = [];
      this.negativeKeywordFailedReason = [];
      this.categoryFailedReason = [];
      this.audienceFailedReason = [];
      this.asinFailedReason = [];
      this.negativeAsinFailedReason = [];
      this.negativeKeywordFailedCount = '';
      this.negativeAsinFailedCount = '';
      this.audienceFailedCount = '';
      this.asinFailedCount = '';
      this.failedCount = ''
      if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts) {
        campaigns.forEach(campaign => {
          campaign.childEntities.forEach(adgroup => {
            if (adgroup.additionalData.keywords != undefined && adgroup.additionalData.keywords.length > 0) {
              this.fetchTargetsFailedReason(adgroup.additionalData.keywords, this.entity.keyword, adgroup.id);
              this.failedCount = this.getFailedTargetCount(adgroup.additionalData.keywords, this.entity.keyword);
            }
            if (adgroup.additionalData.amazonNegativeKeywords != undefined && adgroup.additionalData.amazonNegativeKeywords.length > 0) {
              this.fetchTargetsFailedReason(adgroup.additionalData.amazonNegativeKeywords, this.entity.negativeKeyword, adgroup.id);
              this.negativeKeywordFailedCount = this.getFailedTargetCount(adgroup.additionalData.amazonNegativeKeywords, this.entity.negativeKeyword)
            }
            if (adgroup.additionalData.targets != undefined && adgroup.additionalData.targets.length > 0) {
              this.fetchTargetsFailedReason(adgroup.additionalData.targets, this.entity.product, adgroup.id);
              this.asinFailedCount = this.getFailedTargetCount(adgroup.additionalData.targets, this.entity.product);
            }
            if (adgroup.additionalData.negativeTargets != undefined && adgroup.additionalData.negativeTargets.length > 0) {
              this.fetchTargetsFailedReason(adgroup.additionalData.negativeTargets, this.entity.negativeAsin, adgroup.id);
              this.negativeAsinFailedCount = this.getFailedTargetCount(adgroup.additionalData.negativeTargets, this.entity.negativeAsin);
            }
          })
        });
        this.failedTargets.keyword = this.keywordFailedReason.map(x => x.target);
        this.failedTargets.negativeKeyword = this.negativeKeywordFailedReason.map(x => x.target);
        this.failedTargets.asin = this.asinFailedReason.map(x => x.target);
        this.failedTargets.category = this.categoryFailedReason.map(x => x.target);
        this.failedTargets.negativeAsin = this.negativeAsinFailedReason.map(x => x.target);
        this.set_failedTargets(this.failedTargets)
      } else if (this.selectedPublisher == this.publisher.AMS.SponsoredDisplay) {
        campaigns.forEach(campaign => {
          if (campaign.additionalData.amsSdTargets != undefined && campaign.additionalData.amsSdTargets.length > 0) {
            if (this.sdTactics[campaign.tactics] == this.sdTactics[1]) {
              this.fetchTargetsFailedReason(campaign.additionalData.amsSdTargets, this.entity.product, campaign.id);
              this.asinFailedCount = this.getFailedTargetCount(campaign.additionalData.amsSdTargets, this.entity.product);
            } else {
              this.fetchTargetsFailedReason(campaign.additionalData.amsSdTargets, this.entity.audience, campaign.id);
              this.audienceFailedCount = this.getFailedTargetCount(campaign.additionalData.amsSdTargets, this.entity.audience);
            }
          }
          if (campaign.additionalData.negativeTargets != undefined && campaign.additionalData.negativeAsins.length > 0) {
            this.fetchTargetsFailedReason(campaign.additionalData.negativeTargets, this.entity.negativeAsin, campaign.id);
            this.negativeAsinFailedCount = this.getFailedTargetCount(campaign.additionalData.negativeTargets, this.entity.negativeAsin);
          }
        });
        this.failedTargets.asin = this.asinFailedReason.map(x => x.target);
        this.failedTargets.category = this.categoryFailedReason.map(x => x.target);
        this.failedTargets.negativeAsin = this.negativeAsinFailedReason.map(x => x.target);
        this.failedTargets.audience = this.audienceFailedReason.map(x => x.target);
        this.set_failedTargets(this.failedTargets)
      }
      return campaigns
    }
  },
  methods: {
    ...mapMutations(["set_failedTargets"]),
    getEntityStatusIcon: function (status) {
      if (status == this.exportStatus.Success) {
        return "fa fa-check-circle green";
      } else if (status == this.exportStatus.Failed) {
        return "fa fa-exclamation-triangle red";
      } else if (status != this.exportStatus.Failed && status != this.exportStatus.Success) {
        return "fa fa-clock-o yellow";
      }
    },
    getTargetsStatus: function (targets, entityType = this.entity.keyword) {
      let statusClass = '';
      if (entityType == this.entity.keyword) {
        if (targets.keywords != null) {
          statusClass = this.filterFailedTargets(targets.keywords, this.entity.keyword);
        } else {
          statusClass = this.getEntityStatusIcon(this.exportStatus.New);
        }
      } else if (entityType == this.entity.negativeKeyword) {
        if (targets.amazonNegativeKeywords != null) {
          statusClass = this.filterFailedTargets(targets.amazonNegativeKeywords, this.entity.negativeKeyword);
        } else {
          statusClass = this.getEntityStatusIcon(this.exportStatus.New);
        }
      } else if (entityType == this.entity.product) {
        if (targets.targets != null) {
          statusClass = this.filterFailedTargets(targets.targets, this.entity.product);
        } else {
          statusClass = this.getEntityStatusIcon(this.exportStatus.New);
        }
      } else if (entityType == this.entity.negativeAsin) {
        if (targets.negativeTargets != null) {
          statusClass = this.filterFailedTargets(targets.negativeTargets, this.entity.negativeAsin)
        } else {
          statusClass = this.getEntityStatusIcon(this.exportStatus.New);
        }
      }
      return statusClass;
    },
    getSdTargetsStatus: function (targets, entityType = this.entity.keyword) {
      let statusClass = this.getEntityStatusIcon(this.exportStatus.New);
      if (entityType == this.entity.product || entityType == this.entity.audience) {
        if (targets.amsSdTargets != null) {
          statusClass = this.filterFailedTargets(targets.amsSdTargets, this.entity.product)
        }
      } else if (entityType == this.entity.negativeAsin) {
        if (targets.negativeTargets != null) {
          statusClass = this.filterFailedTargets(targets.negativeTargets, this.entity.negativeAsin)
        }
      }
      return statusClass;
    },
    fetchTargetsFailedReason (targets, entityType, id) {
      let failedTargets = targets.filter(target => target.exportStatus != 8);
      if (failedTargets.length > 0) {
        failedTargets.forEach(element => {
          if (entityType == this.entity.keyword) {
            this.keywordFailedReason.push({
              parentId: id,
              target: element.keywordText,
              failedReason: element.failureReason
            })
          }
          if (entityType == this.entity.negativeKeyword) {
            this.negativeKeywordFailedReason.push({
              parentId: id,
              target: element.keywordText,
              failedReason: element.failureReason
            })
          }
          if (entityType == this.entity.product) {
            if (element.expression[0].type == 'asinCategorySameAs') {
              this.categoryFailedReason.push({
                parentId: id,
                target: element.expression[0].value,
                failedReason: element.failureReason
              })
            } else {
              this.asinFailedReason.push({
                parentId: id,
                target: element.expression[0].value,
                failedReason: element.failureReason
              })
            }
          }
          if (entityType == this.entity.audience) {
            this.audienceFailedReason.push({
              parentId: id,
              target: element.amsAudienceExpression[0].value,
              failedReason: element.failureReason
            })
          }
          if (entityType == this.entity.negativeAsin) {
            this.negativeAsinFailedReason.push({
              parentId: id,
              target: element.expressions[0].value,
              failedReason: element.failureReason
            })
          }
        });
      }
    },
    filterFailedTargets (targets, entityType) {
      let failedTargets = targets.filter(target => target.exportStatus != 8);
      let statusIcon = failedTargets.length > 0 ? this.getEntityStatusIcon(this.exportStatus.Failed) : this.getEntityStatusIcon(this.exportStatus.Success);
      return statusIcon;
    },
    getFailedTargetCount (targets, entityType) {
      let failedTargets = targets.filter(target => target.exportStatus != 8);
      let failureCount = failedTargets.length > 0 ? failedTargets.length + ' of ' + targets.length + ' ' + entityType + ' were not exported.' : '';
      return failureCount;
    }
  }
};
</script>

<style scoped>
.a-tag-disable {
  pointer-events: none !important;
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}
::v-deep .pt-0 {
  padding-top: 0;
}
::v-deep .pt-15 {
  padding-top: 15px !important;
}
.error-message {
  white-space: pre-line;
  word-wrap: break-word;
}
.new-sheet {
  margin-left: 15px !important;
}
::v-deep .table-bordered th {
  background-color: #eee;
  text-align: center;
  padding: 10px;
  min-width: 100px;
}
.campaign-summary {
  list-style: none;
  padding-left: 20px;
}
.adgroup-summary {
  list-style: none;
  padding-left: 35px;
}
.entity-export-status {
  float: right;
}
.entity-label {
  color: #979898;
}
.green {
  color: green;
}
.grey {
  color: grey;
}
.red {
  color: red;
}
.yellow {
  color: #ffc300;
}
.fa.fa-caret-right {
  color: #979898;
}
.summmary-list {
  padding-left: 0px;
}
span.pull-right {
  display: none;
}
.ad-summary-container {
  position: sticky;
  right: 20px;
  top: 65px;
  border: 1px solid #ddd;
  background-color: white;
  margin-top: 15px;
}
.ad-summary-container ul.summmary-list {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}
.no-pointer {
  cursor: default;
}
.summary-collapse {
  cursor: pointer;
}
.summary-collapse:before {
  font-family: "FontAwesome";
  content: "\f0d7";
  color: grey;
  font-size: 1.3em;
  padding-right: 5px;
}
.summary-collapse.collapsed:before {
  content: "\f0da";
}
::v-deep .dropdown-menu {
  overflow-y: auto;
  max-height: 380px;
}
.submission-box-row {
  margin-bottom: 10px;
}
.submission-dropdown {
  margin-right: 0 !important;
}
.comment-area {
  width: 100%;
  min-height: 200px;
  max-height: 350px;
}
.tab-controls {
  padding: 0px;
  border-bottom: none !important;
}
.tab-content {
  padding: 0px !important;
  margin-bottom: 0px !important;
}
.nav.nav-tabs {
  padding-top: 15px;
  top: 48px;
  background: white;
  z-index: 20;
}
</style>
