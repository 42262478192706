export default {
  SET_IS_EDIT_ENABLED (state, value) {
    state.isEditEnabled = value;
  },
  SET_SPINNER (state, value) {
    state.spinner = value;
  },
  SET_SPINNER_TEXT (state, text) {
    state.spinnerText = text;
  },
  SET_SHOWMODAL (state, value) {
    state.showModal = value;
  },
  SHOW_MODAL (state, { title, body, callback, ok, cancel }) {
    state.showModal = true;
    state.modalTitleText = title;
    state.modalBodyText = body;
    state.modalOkText = ok;
    state.modalCancelText = cancel;
    state.modalConfirmCallback = callback;
  },
  START_MODAL_SPINNER (state) {
    state.modalOkSpinnerClass = 'fa fa-spinner fa-spin';
  },
  CLOSE_MODAL (state) {
    state.modalOkSpinnerClass = '';
    state.showModal = false;
    state.modalTitleText = '';
    state.modalBodyText = '';
    state.modalConfirmCallback = null;
  },
  SET_BPUSERS (state, bpusers) {
    state.bpusers = bpusers;
  },
  SET_AUTHFLOWS (state, authFlows) {
    authFlows.sort((a, b) => b.Id - a.Id);
    state.authFlows = authFlows;
  },
  SET_AUTHFLOW (state, { index, authFlow }) {
    state.authFlows.splice(index, 1, authFlow);
  },
  ADD_AUTHFLOW (state, authFlow) {
    state.authFlows = [{
      Id: 0,
      Name: authFlow,
      Stages: [],
      AppliesTo: []
    }, ...state.authFlows];
  },
  REMOVE_AUTHFLOW (state, { index }) {
    state.authFlows.splice(index, 1);
  },
  CHANGE_FLOW_APPLIESTO (state, { index, appliesTo }) {
    appliesTo.sort((a, b) => a.Id - b.Id);
    state.authFlows.splice(index, 1, { ...state.authFlows[index], AppliesTo: appliesTo });
  },
  CHANGE_FLOW_NAME (state, { index, name }) {
    state.authFlows.splice(index, 1, { ...state.authFlows[index], Name: name });
  },
  ADD_FLOW_STAGE (state, { index, stage }) {
    var stages = state.authFlows[index].Stages;
    stages.push(stage);
    stages.sort((a, b) => a.Order - b.Order);
    state.authFlows.splice(index, 1, { ...state.authFlows[index], Stages: stages });
  },
  REMOVE_FLOW_STAGE (state, { index, stageIndex }) {
    state.authFlows[index].Stages.splice(stageIndex, 1);
    state.authFlows.splice(index, 1, { ...state.authFlows[index] });
  },
  CHANGE_FLOW_STAGE_NAME (state, { index, stageIndex, name }) {
    state.authFlows[index].Stages[stageIndex].Name = name;
    state.authFlows.splice(index, 1, { ...state.authFlows[index] });
  },
  ADD_FLOW_STAGE_FACTION (state, { index, stageIndex, faction }) {
    var factions = state.authFlows[index].Stages[stageIndex].Factions;
    factions.push(faction);
    factions.sort((a, b) => a.Order - b.Order);
    state.authFlows.splice(index, 1, { ...state.authFlows[index] });
  },
  REMOVE_FLOW_STAGE_FACTION (state, { index, stageIndex, factionIndex }) {
    state.authFlows[index].Stages[stageIndex].Factions.splice(factionIndex, 1);
    state.authFlows.splice(index, 1, { ...state.authFlows[index] });
  },
  CHANGE_FLOW_STAGE_FACTION_NAME (state, { index, stageIndex, factionIndex, name }) {
    state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Name = name;
    state.authFlows.splice(index, 1, { ...state.authFlows[index] });
  },
  CHANGE_FLOW_STAGE_FACTION_USERS (state, { index, stageIndex, factionIndex, users }) {
    state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Users = users;
    state.authFlows.splice(index, 1, { ...state.authFlows[index] });
  },
  SET_AUTH_FLOW_STAGE_FACTION_APPROVAL (state, { index, stageIndex, factionIndex, userId, comment }) {
    if (state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Approvals) {
      state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Approvals.push({
        UserId: userId,
        Time: Date.now(),
        Comment: comment
      });
    } else {
      state.authFlows[index].Stages[stageIndex].Factions[factionIndex] = {
        ...state.authFlows[index].Stages[stageIndex].Factions[factionIndex],
        Approvals: [{
          UserId: userId,
          Time: Date.now(),
          Comment: comment
        }]
      }
    }
    if (state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Rejections) {
      state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Rejections =
                state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Rejections.filter(x => x.UserId != userId);
    }
    state.authFlows.splice(index, 1, { ...state.authFlows[index] });
  },
  SET_AUTH_FLOW_STAGE_FACTION_REJECTION (state, { index, stageIndex, factionIndex, userId, comment }) {
    if (state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Rejections) {
      state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Rejections.push({
        UserId: userId,
        Time: Date.now(),
        Comment: comment
      });
    } else {
      state.authFlows[index].Stages[stageIndex].Factions[factionIndex] = {
        ...state.authFlows[index].Stages[stageIndex].Factions[factionIndex],
        Rejections: [{
          UserId: userId,
          Time: Date.now(),
          Comment: comment
        }]
      }
    }
    if (state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Approvals) {
      state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Approvals =
                state.authFlows[index].Stages[stageIndex].Factions[factionIndex].Approvals.filter(x => x.UserId != userId);
    }
    state.authFlows.splice(index, 1, { ...state.authFlows[index] });
  },
  SET_AUTH_FLOW_STAGE_STATUS (state, { index, stageIndex, status }) {
    if (state.authFlows[index].Stages[stageIndex].Status) {
      state.authFlows[index].Stages[stageIndex].Status = status;
    } else {
      state.authFlows[index].Stages[stageIndex] = { ...state.authFlows[index].Stages[stageIndex], Status: status };
    }
    state.authFlows.splice(index, 1, { ...state.authFlows[index] });
  },
  SET_AUTH_FLOW_STATUS_AND_CURRENT_STAGE (state, { index, status, currentStage, isFlowInReview }) {
    if (state.authFlows[index].Status) {
      state.authFlows[index].Status = status;
    } else {
      state.authFlows[index] = { ...state.authFlows[index], Status: status };
    }
    if (state.authFlows[index].Status && isFlowInReview) {
      state.authFlows[index].Status = "IN_REVIEW"
    }
    if (state.authFlows[index].CurrentStage) {
      state.authFlows[index].CurrentStage = currentStage;
    } else {
      state.authFlows[index] = { ...state.authFlows[index], CurrentStage: currentStage };
    }
    state.authFlows.splice(index, 1, { ...state.authFlows[index] });
  }

}
