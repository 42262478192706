<template>
  <quill-editor
    v-model="content"
    class="editor"
    :options="editorOption"
    @input="onEditorChange">
  </quill-editor>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    'quill-editor': quillEditor
  },
  emits: ['update:content'],
  props: {
    defaultContent: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      content: '',
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }]
          ]
        }
      }
    }
  },
  watch: {
    defaultContent (value) {
      this.content = value;
    }
  },
  methods: {
    onEditorChange (newContent) {
      this.content = newContent;
      this.$emit('update:content', this.content);
    },
    getContent () {
      return this.content;
    },
    resetContent () {
      this.content = '';
    }
  }
}
</script>
<style scoped>
  .editor {
    height: 100%;
  }
</style>
<style scoped src = "../../../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css"></style>
<style src = "../../../../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.css"></style>
<style src = "../../../../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.css"></style>
<style src="../../../Styles/dx-iview.css"></style>
