import { Objective } from "./Objective.js"
import { platforms, positions } from "../../Constants/FacebookEnums.js"

let ColumnConfig = function () {
  this.single = new function () {
    this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Primary Text", "HOT_Link", "HOT_Video", "HOT_Call To Action", "HOT_Description", "HOT_Headline", "HOT_Display Link", "HOT_ViewTags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Primary Text", "HOT_Video"];
    this.card = [];
    this.requiredCard = [];
    this.callToAction = ["Learn More", "Listen Now", "Shop Now", "Sign Up", "Watch More", "Subscribe", "Download", "Get Quote", "Get Showtimes", "Book Travel"];
    this.placementCustomizationHeaders = ["HOT_Placements", "HOT_Video", "HOT_Primary Text", "HOT_Headline", "HOT_Link"];
  }();
  this.carousel = new function () {
    this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Primary Text", "HOT_ViewTags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_Optimize Card Order"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Primary Text"];
    this.card = ["HOT_Video"];
    this.requiredCard = ["HOT_Video"];
    this.callToAction = [];
  }();
}
let CreativeSpec = function () {
  this.single = new function () {
    this.video = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "video_data": {
            "video_id": "HOT_SearchEngineVideoId",
            "image_url": "HOT_Video",
            "message": "HOT_Primary Text",
            "link_description": "HOT_Description",
            "title": "HOT_Headline",
            "call_to_action": {
              "type": "HOT_Call To Action",
              "value": {
                "link": "HOT_Link",
                "link_caption": "HOT_Display Link"
              }
            }
          }
        },
        "url_tags": "HOT_Url Tags",
        "object_type": "VIDEO"
      }
    }
  }();
  this.carousel = {
    "creative": {
      "object_story_spec": {
        "link_data": {
          "message": "HOT_Primary Text",
          "child_attachments": [
            {
              "video_id": "HOT_SearchEngineVideoId",
              "picture": "HOT_Video"
            }
          ],
          "multi_share_optimized": "HOT_Optimize Card Order"
        },
        "page_id": "HOT_Page",
        "instagram_actor_id": "HOT_InstagramId"
      },
      "url_tags": "HOT_Url Tags",
      "object_type": "VIDEO"
    }
  }
  this.placementCustomization = {}
}
let AdFormats = {
  "Video": {
    "adSheets": ["single", "carousel"],
    "placements": {
      [platforms.Facebook]: [
        positions.Facebook.Feed,
        positions.Facebook.MarketPlace,
        positions.Facebook.VideoFeeds,
        positions.Facebook.Story,
        positions.Facebook.InstreamVideo,
        positions.Facebook.InstantArticle
      ],
      [platforms.Instagram]: [
        positions.Instagram.Stream,
        positions.Instagram.Story
      ]
    }
  }
  // "Instant Experience": {
  //   "adSheets": ["collection"]
  // }
}
let videoViews = new Objective(new ColumnConfig(), new CreativeSpec(), AdFormats)
export { videoViews }
