import { Objective } from "./Objective.js"
import { destinationType } from "../../Constants/FacebookEnums.js"

let ColumnConfig = function (config) {
  let callToAction = []

  switch (config.adset.destination) {
    case destinationType.Messenger:
      callToAction = ["Message Page", "Apply Now", "Learn More", "Shop Now", "Sign Up", "Book Travel", "Contact Us", "Get Quote", "Subscribe", "Pay To Access"]
      break
    case destinationType.WhatsApp:
      callToAction = ["No Button", "Apply Now", "Learn More", "Shop Now", "Sign Up", "Book Travel", "Subscribe", "Pay To Access", "Whatsapp Message", "Get Quote", "Contact Us"]
      break
  }

  let single = {
    base: ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Headline", "HOT_Text", "HOT_Call To Action", "HOT_Description", "HOT_Url Tags", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_StartDate", "HOT_EndDate", "HOT_Targets", "HOT_Locations"],
    requiredBase: ["HOT_Ad Name", "HOT_Headline", "HOT_Text", "HOT_Call To Action", "HOT_Image/Video"],
    card: [],
    requiredCard: [],
    callToAction: callToAction
  }
  let carousel = {
    base: ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Headline", "HOT_Text", "HOT_Url Tags", "HOT_Call To Action", "HOT_Optimize Card Order", "HOT_StartDate", "HOT_EndDate", "HOT_Targets", "HOT_Locations"],
    requiredBase: ["HOT_Ad Name", "HOT_Headline", "HOT_Text", "HOT_Call To Action"],
    card: ["HOT_Headline", "HOT_Image/Video", "HOT_Description"],
    requiredCard: ["HOT_Image/Video"],
    callToAction: callToAction
  }

  return { single, carousel }
}

let CreativeSpec = function (config) {
  let single = new function () {
    this.image = {
      "creative": {
        "object_story_spec": {
          "link_data": {
            "call_to_action": {
              "value": {
                "app_destination": "MESSENGER"
              },
              "type": "HOT_Call To Action"
            },
            "link": "https://fb.com/messenger_doc/",
            "message": "HOT_Text",
            "name": "HOT_Headline",
            "description": "HOT_Description",
            "picture": "HOT_Image/Video",
            "page_welcome_message": config.ad.messageTemplate.messageBody
          },
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId"
        },
        "url_tags": "HOT_Url Tags"
      }
    }
    this.video = {
      "creative": {
        "object_story_spec": {
          "video_data": {
            "call_to_action": {
              "value": {
                "app_destination": "MESSENGER"
              },
              "type": "HOT_Call To Action"
            },
            "video_id": "HOT_SearchEngineVideoId",
            "title": "HOT_Headline",
            "message": "HOT_Text",
            "link_description": "HOT_Description",
            "image_url": "HOT_Image/Video",
            "page_welcome_message": config.ad.messageTemplate.messageBody
          },
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId"
        },
        "url_tags": "HOT_Url Tags"
      }
    }
  }()
  let carousel = {
    "creative": {
      "object_story_spec": {
        "link_data": {
          "call_to_action": {
            "type": "HOT_Call To Action",
            "value": {
              "app_destination": "MESSENGER"
            }
          },
          "link": "https://fb.com/messenger_doc/",
          "message": "HOT_Text",
          "name": "HOT_Headline",
          "page_welcome_message": config.ad.messageTemplate.messageBody,
          "child_attachments": [
            {
              "call_to_action": {
                "type": "HOT_Call To Action",
                "value": {
                  "app_destination": "MESSENGER"
                }
              },
              "link": "https://fb.com/messenger_doc/",
              "picture": "HOT_Image/Video",
              "name": "HOT_Headline",
              "description": "HOT_Description"
            }
          ],
          "multi_share_optimized": "HOT_Optimize Card Order"
        },
        "page_id": "HOT_Page",
        "instagram_actor_id": "HOT_InstagramId"
      },
      "url_tags": "HOT_Url Tags"
    }
  }

  return { single, carousel }
}
let OptimizationGoal = [17, 23]

let messages = new Objective(ColumnConfig, CreativeSpec, null, OptimizationGoal)
export { messages }
