<template>
    <div>
        <i-modal v-model="showModal" :closable="true" @on-cancel="cancel()" :mask-closable="false" width="700" title="Share Documents">
            <div>
                <i-row type="flex" align="top">
                    <i-col span="3" class="label-text">
                        <label>To<span style='color:#b94a48'>*</span></label>
                    </i-col>
                    <i-col span="19">
                        <InputTag v-model="mailList" type="email" placeholder="Enter email ids separated by ,"
                            :validate="verifyMail" style="width: 95%;"
                            :style="isValidMail ? '' : 'border-color:#cc3f44'" add-tag-on-blur>
                        </InputTag>
                        <span style='color:#b94a48'>{{ mailError }}</span>
                    </i-col>
                </i-row>
                <br>
                <i-row type="flex" align="top">
                    <i-col span="3" class="label-text">
                        <label>Subject<span style='color:#b94a48'>*</span></label>
                    </i-col>
                    <i-col span="19">
                        <i-input v-model="subject" type="text" :validate="validateSubject"
                            :style="isValidSubject ? '' : 'border-color:#cc3f44'" style="width: 95%;"></i-input>
                        <span style='color:#b94a48'>{{ subjectError }}</span>
                    </i-col>
                </i-row>
                <br>
                <i-row type="flex" align="top">
                    <i-col span="3" class="label-text">
                        <label>Body<span style="color:#b94a48">*</span></label>
                    </i-col>
                    <i-col span="19">
                        <div class="editor-wrapper">
                            <quill-editor :defaultContent="body" ref="editor" @update:content="updateContent">
                            </quill-editor>
                        </div>
                        <span style="color:#b94a48; margin-top: 4%; display: inline-block;" >{{ bodyError }}</span>
                    </i-col>
                </i-row>
                <br>
                <i-row type="flex" align="top">
                    <i-col span="3" class="label-text">
                        <span>&nbsp;</span>
                    </i-col>
                    <i-col span="19">
                        <div class="checkbox">
                            <i-checkbox v-model="IncludeROAttachment">
                                Reserve Order attachment
                            </i-checkbox>
                            <i-checkbox v-model="IncludeIBLogAttachment">
                                Incoming Bill Log attachment
                            </i-checkbox>
                        </div>
                    </i-col>
                </i-row>
                <br>
            </div>
            <div slot="footer" class="footer">
                <button class="btn btn-white btn-footer" @click="cancel()">Close</button>
                <button class="btn btn-success btn-footer" @click.prevent="sendMail()">Send
                    <span v-if="isSending"><i class="fa-spinner fa-spin" aria-hidden="true"></i></span>
                </button>
            </div>
        </i-modal>
    </div>
</template>
<script>
import { Modal, Row, Col, Input, Checkbox } from "iview";
import InputTag from 'vue-input-tag';
import { ApiService } from './../Services/ApiService';
import QuillEditor from "../../ReserveOrder/Components/QuillEditor.vue";

export default ({
  components: {
    "i-modal": Modal,
    "i-row": Row,
    "i-col": Col,
    "i-input": Input,
    "i-checkbox": Checkbox,
    InputTag,
    QuillEditor
  },
  mounted () {
    this.apiService = new ApiService();
  },
  emits: ['close', 'confirm'],
  props: {
    propShowModal: Boolean,
    IncomingBillLogId: Number
  },
  data () {
    return {
      modaltitle: "Mail Incoming Bill Logs",
      showModal: false,
      includeSupportingDoc: false,
      isValidSubject: true,
      mailList: [],
      isValidMail: true,
      mailError: '',
      subject: '',
      subjectError: '',
      apiService: null,
      isSending: false,
      currentTag: '',
      IncludeROAttachment: false,
      IncludeIBLogAttachment: false,
      body: "",
      isValidBody: true,
      bodyError: ""
    }
  },
  methods: {
    updateContent (newContent) {
      this.body = newContent;
    },
    verifyMail (tag) {
      tag = tag.trim();
      if (this.mailList.length > 0 && tag == "") {
        this.mailError = "";
        this.isValidMail = true;
        return;
      }
      const mailRegex = /^[\w+.-]+@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (mailRegex.test(tag)) {
        this.mailError = "";
        this.isValidMail = true;
        return true;
      } else {
        this.mailError = "Mail id is invalid"
        this.isValidMail = false;
        return false;
      }
    },
    sendMail () {
      var self = this;
      var content = this.$refs.editor.getContent();
      this.body = content;
      if (!this.isValidMail) {
        return;
      }
      this.validateMail()
      this.validateBody()
      if (!this.validateSubject() || !this.isValidMail || !this.isValidBody) {
        return;
      }
      self.isSending = true;
      const mailObj = {
        to: this.mailList.join(),
        subject: this.subject,
        body: this.body,
        IncludeROAttachment: this.IncludeROAttachment,
        IncludeIBLogAttachment: this.IncludeIBLogAttachment
      };
      var promise = this.apiService.SendMail(mailObj, self.IncomingBillLogId);
      promise.then(() => {
        this.$emit('confirm', true);
        self.isSending = false;
        self.resetValues();
      })
        .catch(() => {
          this.$emit('confirm', false);
          self.isSending = false;
          this.$refs.editor.resetContent();
          self.resetValues();
        })
    },
    resetValues () {
      this.subject = "";
      this.mailError = "";
      this.mailList = [];
      this.subjectError = "";
      this.isValidMail = true;
      this.showModal = false;
      this.IncludeROAttachment = false;
      this.IncludeIBLogAttachment = false;
      this.body = "";
      this.isValidBody = true;
      this.bodyError = "";
    },
    cancel () {
      this.$emit('close');
      this.resetValues();
      this.$refs.editor.resetContent();
    },
    validateSubject () {
      if (this.subject.length == 0) {
        this.isValidSubject = false;
        this.subjectError = "Subject is required";
        return false;
      }
      return true;
    },
    validateMail () {
      if (this.mailList.length == 0) {
        return false;
      } else {
        this.mailList.forEach(mail => {
          if (!this.verifyMail(mail)) {
            return false;
          }
        })
        return true;
      }
    },
    validateBody () {
      if (!this.body || this.body.trim().length === 0) {
        this.isValidBody = false;
        this.bodyError = "Body is required";
      } else {
        this.isValidBody = true;
        this.bodyError = "";
      }
    }
  },
  watch: {
    propShowModal () {
      if (this.propShowModal) {
        this.showModal = this.propShowModal;
        this.mailList = [];
        this.mailList.push(JSON.parse(deltax.user).EmailAddress);
      }
    },
    subject () {
      if (this.subject.length == 0 && this.showModal == true) {
        this.subjectError = "Subject is required";
        this.isValidSubject = false;
      } else {
        this.isValidSubject = true;
        this.subjectError = "";
      }
    },
    body () {
      if (this.body.length == 0 && this.showModal == true) {
        this.bodyError = "body is required";
        this.isValidbody = false;
      } else {
        this.isValidbody = true;
        this.bodyError = "";
      }
    },
    mailList () {
      if (this.mailList.length == 0 && this.showModal == true) {
        this.mailError = "Mail id is required"
        this.isValidMail = false;
      }
    }
  }
})
</script>

<style scoped>
::v-deep .vue-input-tag-wrapper .new-tag {
    padding: 0 !important;
}

::v-deep .vue-input-tag-wrapper .input-tag {
    background-color: #dcdee2 !important;
    border: 1px solid #dcdee2 !important;
    color: #2c2e2f !important;
    margin-bottom: 2px !important;
    margin-right: 2px !important;
}

::v-deep .vue-input-tag-wrapper .input-tag .remove {
    color: #2c2e2f !important;
}

.label-text {
    margin-right: 2%;
    text-align: end;
}

.btn {
    margin-bottom: 0;
}

.footer {
    text-align: right;
}

.spacing {
    margin-right: 2%;
}

.editor-wrapper {
    height: 200px;
    width: 502.38px;
}
.checkbox{
    display: flex;
    justify-content: space-between;
    padding-left: 0;
}
::v-deep .checkbox label{
    padding-left: 0 !important;
}

::v-deep .ql-container{
    height: 90% !important;
}

</style>
<style src="../../../Styles/dx-iview.css"></style>
