export const DxToolTipDirective = {
    // When the bound element is inserted into the DOM...
    inserted: function (el, binding, vnode) {
      var boundOptions = binding.value;
      var options = {
        title: boundOptions.title,
        placement: boundOptions.placement || "top",
        container: boundOptions.container || "body"
      };
      $(el).tooltip(options);
    },
    componentUpdated: function (el, binding, vnode) {
      var boundOptions = binding.value;
      var options = {
        title: boundOptions.title,
        placement: boundOptions.placement || "top",
        container: boundOptions.container || "body"
      };
      //$(el).tooltip("destroy");
      $(el).tooltip(options);
    },
    unbind: function () {
      $(".tooltip").tooltip("hide");
    }
};