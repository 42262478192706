import axiosInstance from '../../../Api'
let ApiService = {
  getMediaPlanVersions (mediaPlanId) {
    var mediaPlanVersions = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`mediaplans/${mediaPlanId}/changelogs`)
    const promise = axiosInstance.get(mediaPlanVersions);
    return promise;
  },
  getMediaPlanObject (mediaPlanId, changeLogId) {
    var mediaPlanObj = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`mediaplans/${mediaPlanId}/changelogs/${changeLogId}`)
    const promise = axiosInstance.get(mediaPlanObj);
    return promise;
  }
}
export { ApiService };
