import { Objective } from "./Objective.js"

let ColumnConfig = function () {
  this.single = new function () {
    this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Event URL", "HOT_Primary Text", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Event URL"]
    this.card = []
    this.requiredCard = []
    this.callToAction = []
  }()
}
let CreativeSpec = function () {
  this.single = new function () {
    this.image = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "link_data": {
            "message": "HOT_Primary Text",
            "picture": "HOT_Image/Video",
            "link": "HOT_Event URL",
            "call_to_action": {
              "type": "EVENT_RSVP"
            }
          }
        },
        "object_type": "SHARE"
      }
    };
    this.video = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "video_data": {
            "call_to_action": {
              "value": {
                "link": "HOT_Event URL"
              },
              "type": "EVENT_RSVP"
            },
            "video_id": "HOT_SearchEngineVideoId",
            "message": "HOT_Primary Text",
            "image_url": "HOT_Image/Video"
          }
        },
        "object_type": "SHARE"
      }
    }
  }()
}

let eventResponses = new Objective(new ColumnConfig(), new CreativeSpec())

export { eventResponses }
