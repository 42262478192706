<template>
  <div class="cl-widget">
    <Card>
      <div>
        <Row>
          <Row-Col span="8">
            <h4>Targets
            </h4>
          </Row-Col>
          <Row-Col span="16" class="targets-create-config">
            <Select v-model="selectedAccount"
              class="accounts"
              placeholder="Select an account"
              @on-change="resetSearchTerm"
              filterable
              >
              <Option
                v-for="(account, index) in accounts"
                :key="index"
                :value="account.Id"
                >{{ account.NickName }}</Option
              >
            </Select>
            <div>
                <th v-if="selectedPublisher == publisher.Adwords" class="col-md-4 pull-right plus-button" style="display: contents;">
                    <adwords-target-creation />
                </th>
              <div v-if="selectedPublisher == publisher.Facebook" class="pull-right plus-button">
                  <facebook-target-creation
                    :getAudiences="deltax.getAudiencesURL"
                    :createTarget="deltax.createTargetURL"
                    :targets="accountTargets"
                    ></facebook-target-creation>
              </div>
            </div>
          </Row-Col>
        </Row>
      </div>
      <div>
            <Input v-model="search" search placeholder="Search" class="search-bar input-search-bar" />
      </div>
      <template>
            <div
              style="margin-top:10px"
              v-if="selectedAccount==0"
              class="alert alert-info"
            >Please select an account to see the saved targets.</div>
            <div
              style="margin-top:10px"
              v-else-if="filteredTargets.length <= 0 && !search.length"
              class="alert alert-info"
            >There are no saved targets yet.</div>
      </template>
      <VuePerfectScrollbar class="widget-body" v-if="selectedAccount > 0">
        <Row class="cl-ivu-row" v-for="item in filteredTargets" :key="item.id">
          <Row-Col span="1">
            <i class="fas fa-copy action-icon"
              @click="copyToClipboard(targetPrefix + '-' + Number.parseInt(businessProfileId).toString(36) + '-' + item.id)">
            </i>
          </Row-Col>
          <Row-Col offset="1" span="21" class="cl-widget-text">{{ item.name || item.Name }}</Row-Col>
        </Row>
      </VuePerfectScrollbar>
    </Card>
  </div>
</template>
<script>
import { Input, Card, Row, Col, Select, Option } from "iview";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import mixins from "../../../Mixins/copyToClipboard.js";
import { mapState, mapGetters } from "vuex";
import AdwordsTargetCreation from './AdWords/AdwordsTargetCreation.vue';
import FacebookTargetCreation from "./FacebookTargetCreation.vue";
import { EntityPrefixes } from "../Constants/EntityPrefix.js"

export default {
  components: {
    Card,
    Row,
    "Row-Col": Col,
    VuePerfectScrollbar,
    Input,
    Select,
    Option,
    'adwords-target-creation': AdwordsTargetCreation,
    'facebook-target-creation': FacebookTargetCreation
  },
  data: function () {
    return {
      targets: [],
      search: "",
      selectedAccount: 0,
      accountTargets: [],
      targetPrefix: EntityPrefixes.Target
    }
  },
  mixins: [mixins],
  mounted () {
  },
  computed: {
    ...mapState(["selectedCampaignLauncherConfigId", "businessProfileId", "savedTargetsMapperByBpseId", "selectedPublisher", "publisher", "deltax"]),
    ...mapGetters(["getPublisherAccounts"]),
    filteredTargets () {
      let searchTerm = this.search;
      if (searchTerm != "") {
        return this.savedTargetsMapperByBpseId[this.selectedAccount].filter(e => {
          return e.Name.toLowerCase().includes(searchTerm.toLowerCase())
        })
      } else {
        return this.savedTargetsMapperByBpseId[this.selectedAccount] || [];
      }
    },
    accounts () {
      if (this.getPublisherAccounts.length == 1) {
        this.selectedAccount = this.getPublisherAccounts[0].Id;
      }
      return this.getPublisherAccounts;
    }
  },
  methods: {
    resetSearchTerm () {
      this.search = "";
    }
  }
}
</script>
<style scoped>
.input-search-bar .ivu-input {
  height: 30px;
}
.cl-widget .ivu-card {
  font-size: 12px !important;
  border-radius:0;
}
.targets-create-config {
  display: flex;
  max-width: 250px;
  float: right;
}
.accounts {
  margin-right: 10px;
}
</style>
