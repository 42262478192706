<template>
  <modal
    :value="showModal"
    id="execute-mediaplan-modal"
    :backdrop="false"
    effect="fade"
    width="400"
  >
    <div slot="modal-header" class="modal-header">
      <h4 class="modal-title">{{headerMessage}}</h4>
    </div>
    <div slot="modal-body" class="modal-body">
      <template v-if="!isSubmitted">
        <template v-if="!isLaunchReady">
          <div class="form-horizontal" id="execute-mediaplan-form">
            <div class="form-group" v-if="isReadyForExecute">
              <label class="control-label col-md-4" style="text-align:right">
                Account
                <span class="f_req">*</span>
              </label>
              <div class="col-md-6">
                <v-select
                  v-model="selectedAccountId"
                  :options="accountsForLineItem"
                  class="form-dropdown"
                  options-value="businessProfileSearchEngineId"
                  options-label="label"
                  name="account"
                  v-validate="'required'"
                ></v-select>
                <div>
                  <span
                    v-show="errors.has('account')"
                    class="field-validation-error"
                  >{{ errors.first('account') }}</span>
                </div>
              </div>
            </div>
            <div class="alert alert-warning" v-else>
              <i class="fa-exclamation-triangle"></i> The line item is not ready for execution due to the following reasons:
              <ul>
                <li v-if="!isAccountAvailable">
                  You don't have any account set up for linking. Click
                  <a :href="channelLinkUrl">here</a> to link.
                </li>
                <li
                  v-if="isAccountAvailable && setUpCompletedAccounts.length === 0"
                >Account set up has failed. Please contact support</li>
                <li
                  v-if="(feedIntegrationFailed && roimaticConfiguration && roimaticConfiguration.category != `local-business`)"
                >Feed integration is in progress. It will be completed shortly</li>
                <li v-if="!isUserEmailVerified">User email is not verified.</li>
              </ul>
            </div>
            <template v-if="typeId == engineTypeId.Social && isReadyForExecute && selectedAccount">
              <div class="form-group">
                <label class="control-label col-md-4" style="text-align:right">
                  Page
                  <span class="f_req">*</span>
                </label>
                <div class="col-md-6">
                  <v-select
                    v-model="selectedPageId"
                    :options="pages"
                    class="form-dropdown"
                    options-value="id"
                    options-label="label"
                    name="page"
                    v-validate="'required'"
                  ></v-select>
                  <div>
                    <span
                      v-show="errors.has('page')"
                      class="field-validation-error"
                    >{{ errors.first('page') }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-md-4" style="text-align:right">
                  Instagram
                </label>
                <div class="col-md-6">
                  <v-select
                    v-model="selectedInstagramAccountId"
                    :options="instagramAccounts"
                    class="form-dropdown"
                    options-value="id"
                    options-label="label"
                    name="instagram"
                  ></v-select>
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div>
            <p>This action will activate the campaigns linked to this line item</p>
            <p>Are you sure you want to continue ?</p>
          </div>
        </template>
      </template>
      <div v-if="isProcessing">
        <p>Processing...</p>
      </div>
      <div class="alert alert-success" v-if="isSubmitSuccessful">
        <span v-if="isLaunchReady">Line Item was launched successfully</span>
        <span
          v-else
        >Line Item execution has been initiated. The associated campaigns will be exported shortly</span>
      </div>
      <div class="alert alert-danger" v-if="customError">
        <i class="fa fa-exclamation-triangle"></i>
        <span>{{customError}}</span>
      </div>
    </div>
    <div slot="modal-footer" class="modal-footer">
      <button class="btn btn-white" @click="cancelAction" v-if="!isSubmitted">Cancel</button>
      <button class="btn btn-white" @click="closeAction" v-if="isSubmitted">Close</button>
      <button
        id="execute-mediaplan"
        type="submit"
        class="btn btn-success"
        :disabled="(!isReadyForExecute || !selectedAccount) && !isLaunchReady"
        v-if="!isSubmitted"
        @click="()=>{isLaunchReady ? launch(): execute() }"
      >{{ isLaunchReady ? 'Yes': 'Continue'}}</button>
    </div>
  </modal>
</template>
<style>
#execute-mediaplan-form .dropdown-menu {
  z-index: 5000 !important;
}
#execute-mediaplan-modal .modal-body {
  overflow: visible;
  min-height: 80px !important;
}
.form-dropdown {
  cursor: pointer;
  width: 100%;
}
</style>
<script>
import { modal, select } from "vue-strap";
import { ApiService } from ".././Services/executeMediaPlanService.js";
import VeeValidate, { Validator } from "vee-validate";
import { launchStatus, searchEngineId, typeId } from ".././Constants/executeMediaPlan.js";

Vue.use(VeeValidate);
const dict = {
  custom: {
    account: {
      required: "Please select an account"
    },
    page: {
      required: "Please select a page"
    }
  }
};

Validator.localize("en", dict);

export default {
  props: ["showModal", "mediaPlan", "lineItem"],
  data () {
    return {
      selectedAccountId: undefined,
      selectedPageId: undefined,
      selectedInstagramAccountId: undefined,
      accounts: [],
      isProcessing: false, // Set this to true when clicked on Ok button.
      isSubmitted: false,
      isSubmitSuccessful: false,
      customError: "",
      apiService: null,
      businessProfileId: 0,
      productFeed: null,
      engineTypeId: typeId,
      bpseList: [],
      roimaticConfiguration: undefined
    };
  },
  components: {
    modal,
    "v-select": select
  },
  async created () {
    if (deltax.isRoimatic) {
      this.businessProfileId = deltax.businessProfileId;
      this.apiService = new ApiService(
        deltax.apiBaseUrl,
        deltax.businessProfileId
      );
      this.bpseList = await this.apiService.getBusinessProfileSearchEngines();
      this.roimaticConfiguration = await this.apiService.getRoimaticConfiguration();
      this.accounts = this.roimaticConfiguration.accounts.map(acc => {
        let bpse = this.bpseList.find(x => x.id == acc.businessProfileSearchEngineId);
        if (bpse && bpse.accountAssets) {
          let accountAssets = JSON.parse(bpse.accountAssets);
          if (accountAssets &&
            Array.isArray(accountAssets.Data) &&
            accountAssets.Data.length > 0
          ) {
            let assets = accountAssets.Data[0];
            if (Array.isArray(assets.Pages) && assets.Pages.length > 0) {
              acc.pages = assets.Pages;
            }
            if (Array.isArray(assets.Instagram) && assets.Instagram.length > 0) {
              acc.instagramAccounts = assets.Instagram
            }
          }
        }
        return acc;
      });
      this.productFeed = this.roimaticConfiguration.productFeed;
      if (this.productFeed) {
        await this.checkProducFeedSetup(
          this.roimaticConfiguration.productCatalog
        );
      }
      this.selectedAccountId =
        this.accountsForLineItem.length > 0
          ? this.accountsForLineItem[0].businessProfileSearchEngineId
          : undefined;
      this.selectedPageId =
        this.selectedAccount && this.typeId == this.engineTypeId.Social
          ? this.selectedAccount.pages[0].Id
          : undefined;
      this.selectedInstagramAccountId =
        this.selectedAccount && this.typeId == this.engineTypeId.Social
          ? this.selectedAccount.instagramAccounts[0].Id
          : this.instagramAccounts[0].id;
    }
  },
  methods: {
    // Called on hitting Cancel button.
    cancelAction (e) {
      this.$emit("cancel");
    },
    // Called on hitting Close button.
    closeAction (e) {
      this.$emit("ok", {
        isSuccessful: this.isSubmitSuccessful,
        status: this.isLaunchReady
          ? launchStatus.LaunchCompleted
          : launchStatus.ExecutionInProgress
      });
    },
    async execute () {
      let isValid = await this.$validator.validateAll();
      if (isValid) {
        this.isProcessing = true;
        this.isSubmitted = true;
        try {
          await this.apiService.executeLineItem(
            this.lineItem.id,
            this.selectedAccountId,
            this.selectedPageId,
            this.selectedInstagramAccountId
          );
          this.isSubmitSuccessful = true;
        } catch (error) {
          this.customError =
            "Oops. We are unable to complete your request. Please Contact support";
        }
        this.isProcessing = false;
      }
    },
    async launch () {
      try {
        this.isProcessing = true;
        this.isSubmitted = true;
        switch (this.typeId) {
          case this.engineTypeId.Search:
            await this.apiService.activateLineItem(
              this.mediaPlan.id,
              this.lineItem.id
            );
            break;
          case this.engineTypeId.Social:
            await this.apiService.activateLineItem(
              this.mediaPlan.id,
              this.lineItem.id
            );
            break;
          default:
            break;
        }
        this.bpseList.forEach(async bpse => {
          await this.apiService.addMediaPlanChargeTasks(
            this.mediaPlan.id,
            bpse.id
          );
        });
        this.isSubmitSuccessful = true;
      } catch (error) {
        this.customError =
          "Oops. We are unable to complete your request. Please Contact support";
      }
      this.isProcessing = false;
    },
    async checkProducFeedSetup (productCatalog) {
      if (!this.productFeed.isFacebookSetupComplete && this.accounts.some(acc => acc.engine == searchEngineId.Facebook)) {
        let response = await this.apiService.getProductsCount(
          productCatalog.id
        );
        if (response.count > 0) {
          await this.apiService.updateProductFeedStatus();
          this.productFeed.isFacebookSetupComplete = true;
        }
      }
    },
    getSelectedAccountForLineItem () {
      let account;
      switch (this.typeId) {
        case typeId.Search: {
          // search
          account = this.accounts.find(acc => acc.engine == searchEngineId.Adwords);
          break;
        }
        case typeId.Social: {
          // social
          account = this.accounts.find(acc => acc.engine == searchEngineId.Facebook);
          break;
        }
      }
      return account ? account.businessProfileSearchEngineId : undefined;
    }
  },
  computed: {
    selectedAccount () {
      return this.accounts.find(
        acc => acc.businessProfileSearchEngineId == this.selectedAccountId
      );
    },
    pages () {
      if (this.selectedAccount && Array.isArray(this.selectedAccount.pages)) {
        return this.selectedAccount.pages.map(page => ({
          id: page.Id,
          label: `${page.Name} (${page.Id})`
        }));
      }
      return [];
    },
    instagramAccounts () {
      let accounts = [];
      if (this.selectedAccount && Array.isArray(this.selectedAccount.instagramAccounts)) {
        accounts = this.selectedAccount.instagramAccounts.map(instagramAccount => ({
          id: instagramAccount.Id,
          label: `${instagramAccount.Name} (${instagramAccount.Id})`
        }));
      }
      accounts.push({
        id: '0',
        label: 'Use Selected Page'
      });
      return accounts;
    },
    isLaunchReady () {
      return (
        this.lineItem &&
        this.lineItem.launchStatus == launchStatus.ReadyForLaunch
      );
    },
    headerMessage () {
      return this.isLaunchReady ? "Activate Line Item" : "Execute Line Item";
    },
    accountsForLineItem () {
      switch (this.typeId) {
        case typeId.Search: {
          // search
          return this.accounts
            .filter(acc => acc.engine == searchEngineId.Adwords)
            .map(acc => {
              acc.label = `${acc.nickName} (ID: ${acc.accountId})`;
              return acc;
            });
        }
        case typeId.Social: {
          // social
          return this.accounts
            .filter(acc => acc.engine == searchEngineId.Facebook)
            .map(acc => {
              acc.label = `${acc.nickName} (ID: ${acc.accountId})`;
              return acc;
            });
        }
        default:
          return [];
      }
    },
    isAccountAvailable () {
      return this.accountsForLineItem.length > 0;
    },
    isUserEmailVerified () {
      // if (
      //   this.roimaticConfiguration &&
      //   this.roimaticConfiguration.campaignExecuteValidation
      // ) {
      //   return this.roimaticConfiguration.campaignExecuteValidation
      //     .isEmailVerified;
      // } else {
      //   return false;
      // }
      return true;
    },
    setUpCompletedAccounts () {
      return this.accountsForLineItem.filter(
        account => account.isSetupComplete
      );
    },
    isReadyForExecute () {
      let isLocalBusiness = this.roimaticConfiguration
        ? this.roimaticConfiguration && this.roimaticConfiguration.category == "local-business"
        : false;
      let isProductFeedSet = this.productFeed ? this.productFeed : false;
      let isFacebookSetupComplete = this.productFeed
        ? this.productFeed.isFacebookSetupComplete
        : false;
      if (this.setUpCompletedAccounts.length > 0 && this.isUserEmailVerified) {
        if (this.typeId == this.engineTypeId.Social) {
          if (isLocalBusiness) {
            return true;
          }
          return isProductFeedSet && isFacebookSetupComplete;
        }
        return true;
      } else {
        return false;
      }
    },
    typeId () {
      return this.lineItem ? this.lineItem.typeId : undefined;
    },
    channelLinkUrl () {
      switch (this.typeId) {
        case this.engineTypeId.Search:
          return `/App/BizProfiles/NewChannel/${businessProfileId}?wizardStep=False&channel=adwords&connectFlow=True`;
        case this.engineTypeId.Social:
          return `/App/BizProfiles/NewChannel/${businessProfileId}?wizardStep=False&channel=facebook&connectFlow=True`;
      }
      return `/App/Connect/List/${businessProfileId}`;
    },
    feedIntegrationFailed () {
      let isProductFeedSet = this.productFeed ? this.productFeed : false;
      let isFacebookSetupComplete = this.productFeed
        ? this.productFeed.isFacebookSetupComplete
        : false;
      return (this.typeId == this.engineTypeId.Social && (!isProductFeedSet || !isFacebookSetupComplete));
    }
  },
  watch: {
    showModal () {
      if (this.showModal) {
      }
    },
    accountsForLineItem () {
      this.selectedAccountId =
        this.accountsForLineItem.length > 0
          ? this.accountsForLineItem[0].businessProfileSearchEngineId
          : undefined;
    },
    pages () {
      this.selectedPageId =
        this.pages.length > 0 ? this.pages[0].id : undefined;
    },
    instagramAccounts () {
      this.selectedInstagramAccountId =
        this.instagramAccounts.length > 0 ? this.instagramAccounts[0].id : undefined;
    }
  }
};
</script>
