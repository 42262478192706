import { render, staticRenderFns } from "./FacebookTargetCreation.vue?vue&type=template&id=c77a0fb4&scoped=true&"
import script from "./FacebookTargetCreation.vue?vue&type=script&lang=js&"
export * from "./FacebookTargetCreation.vue?vue&type=script&lang=js&"
import style0 from "./FacebookTargetCreation.vue?vue&type=style&index=0&id=c77a0fb4&scoped=true&lang=css&"
import style1 from "./FacebookTargetCreation.vue?vue&type=style&index=1&lang=css&"
import style2 from "../../../Styles/dx-iview.css?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c77a0fb4",
  null
  
)

export default component.exports