<template>
    <div class = "entity">
        <Tooltip max-width="500" :content="tooltipText" v-if="isIconShown">
         <i v-bind:class="icon"></i>
        </Tooltip>
        <span class="name">{{name}} ({{ matchType }})</span>
    </div>

</template>
<script>
import { locale, Tooltip } from "iview";
import lang from "iview/dist/locale/en-US";
locale(lang); // iview is default chinese

export default {
  components: {
    Tooltip
  },
  props: {
    name: { default: "-" },
    matchType: { default: "-" },
    tooltipText: { default: "-" },
    isIconShown: { default: false },
    icon: { default: "fa fa-exclamation-triangle red" }
  }
}
</script>
<style scoped>
.entity {
    display: inline;
    margin-left: 5px;
}
.name{
  padding-left: 2px;
}
</style>
