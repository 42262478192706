export default {
  data () {
    return {
      ActionType: {
        Download: 1,
        Publish: 2,
        Save: 3
      },
      ParentComponents: {
        ReportPanel: 1,
        ScheduledReport: 2
      }
    };
  },
  methods: {
    handleDownloadPublishSave (action, parentComponent, row) {
      this.currentAction = action;
      this.currentRow = row;
      let isInternalDomain = this.checkIfDomainIsInternal();
      let hasAiInsights;

      if (parentComponent === this.ParentComponents.ReportPanel) {
        let commentWidgetsWithInsights = this.Sheets.flatMap(sheet => sheet.Widgets.filter(widget => widget.Type === this.WidgetType.Text && widget.IsAiInsightProp === true));
        hasAiInsights = commentWidgetsWithInsights.length > 0;
        this.hasAiInsightsEnabled = hasAiInsights;

        if (action === this.ActionType.Save) {
          this.handleSaveTemplate();
          return;
        }
      } else {
        hasAiInsights = row.hasAiInsightsEnabled !== null ? row.hasAiInsightsEnabled : false;
      }

      let showInsightsAlert = isInternalDomain && hasAiInsights;

      if (showInsightsAlert) {
        this.insightAlertVisible = true;
      } else {
        this.executeAction();
      }
    },
    checkIfDomainIsInternal () {
      let internalPortalDomains = deltax.InternalProtalDomains;
      let currentDomain = window.location.hostname;
      let isInternalDomain = internalPortalDomains.includes(currentDomain);
      return isInternalDomain;
    },
    handleInsightsResponse (answer) {
      this.insightAlertVisible = false;
      if (answer) this.executeAction();
    },
    executeAction () {
      if (this.currentAction === this.ActionType.Download) {
        this.downloadTemplate();
      } else if (this.currentAction === this.ActionType.Publish) {
        this.publishTemplate();
      }
    }
  }
};
