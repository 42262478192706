import { adwordsEnums } from "../../Constants/AdwordsEnums.js";

const schedulingColumnsCount = 7;
const siteLinkCustomParameterColumnsCount = 8;
const structuredSnippetValueColumnsCount = 10;
const structuredSnippetValueRequiredColumnsCount = 3;

const adExtensionColumnsEnum = {
  "IsValid": "Is Valid",
  "Name": "Name",
  "Account": "Account",
  "CountryCode": "Country Code",
  "PhoneNumber": "Phone Number",
  "Day": "Day",
  "StartTime": "Start Time",
  "EndTime": "End Time",
  "SitelinkText": "Sitelink Text",
  "Description1": "Description 1",
  "Description2": "Description 2",
  "FinalURL": "Final URL",
  "FinalMobileURL": "Final Mobile URL",
  "TrackingTemplate": "Tracking Template",
  "Key": "Key",
  "Value": "Value",
  "StartDate": "Start Date",
  "EndDate": "End Date",
  "CalloutText": "Callout Text",
  "HeaderType": "Header Type"
};

const schedulingColumns = [adExtensionColumnsEnum.Day, adExtensionColumnsEnum.StartTime, adExtensionColumnsEnum.EndTime];
const siteLinkCustomParameterColumns = [adExtensionColumnsEnum.Key, adExtensionColumnsEnum.Value];
const structuredSnippetValueColumns = [adExtensionColumnsEnum.Value];

function repeatColumns (columns = [], count = 0) {
  if (count <= 0) return [];
  else if (count == 1) return columns;

  const repeatedColumns = [];

  for (let i = 1; i <= count; i++) {
    repeatedColumns.push(...columns.map(e => `${e} ${i}`));
  }

  return repeatedColumns;
}

const adExtensionColumns = {
  [adwordsEnums.adExtension["Call"]]: {
    columns: [
      adExtensionColumnsEnum.IsValid,
      adExtensionColumnsEnum.Name,
      adExtensionColumnsEnum.Account,
      adExtensionColumnsEnum.CountryCode,
      adExtensionColumnsEnum.PhoneNumber,
      ...repeatColumns(schedulingColumns, schedulingColumnsCount)
    ],
    required: [adExtensionColumnsEnum.Name, adExtensionColumnsEnum.Account, adExtensionColumnsEnum.CountryCode, adExtensionColumnsEnum.PhoneNumber]
  },
  [adwordsEnums.adExtension["SiteLink"]]: {
    columns: [
      adExtensionColumnsEnum.IsValid,
      adExtensionColumnsEnum.Name,
      adExtensionColumnsEnum.Account,
      adExtensionColumnsEnum.SitelinkText,
      adExtensionColumnsEnum.FinalURL,
      adExtensionColumnsEnum.Description1,
      adExtensionColumnsEnum.Description2,
      adExtensionColumnsEnum.FinalMobileURL,
      adExtensionColumnsEnum.TrackingTemplate,
      ...repeatColumns(
        siteLinkCustomParameterColumns,
        siteLinkCustomParameterColumnsCount
      ),
      adExtensionColumnsEnum.StartDate,
      adExtensionColumnsEnum.EndDate,
      ...repeatColumns(schedulingColumns, schedulingColumnsCount)
    ],
    required: [adExtensionColumnsEnum.Name, adExtensionColumnsEnum.Account, adExtensionColumnsEnum.SitelinkText, adExtensionColumnsEnum.FinalURL]
  },
  [adwordsEnums.adExtension["Callout"]]: {
    columns: [
      adExtensionColumnsEnum.IsValid,
      adExtensionColumnsEnum.Name,
      adExtensionColumnsEnum.Account,
      adExtensionColumnsEnum.CalloutText,
      adExtensionColumnsEnum.StartDate,
      adExtensionColumnsEnum.EndDate,
      ...repeatColumns(schedulingColumns, schedulingColumnsCount)
    ],
    required: [adExtensionColumnsEnum.Name, adExtensionColumnsEnum.Account, adExtensionColumnsEnum.CalloutText]
  },
  [adwordsEnums.adExtension["Structured Snippet"]]: {
    columns: [
      adExtensionColumnsEnum.IsValid,
      adExtensionColumnsEnum.Name,
      adExtensionColumnsEnum.Account,
      adExtensionColumnsEnum.HeaderType,
      ...repeatColumns(
        structuredSnippetValueColumns,
        structuredSnippetValueColumnsCount
      )
    ],
    required: [
      adExtensionColumnsEnum.Name,
      adExtensionColumnsEnum.Account,
      adExtensionColumnsEnum.HeaderType,
      ...repeatColumns(
        structuredSnippetValueColumns,
        structuredSnippetValueRequiredColumnsCount
      )
    ]
  }
};

const adExtensionColumnFieldMapping = {
  [adExtensionColumnsEnum.Name]: "name",
  [adExtensionColumnsEnum.Account]: "businessProfilesSearchEngineId",
  [adExtensionColumnsEnum.CountryCode]: "countryCode",
  [adExtensionColumnsEnum.PhoneNumber]: "phoneNumber",
  [adExtensionColumnsEnum.SitelinkText]: "text",
  [adExtensionColumnsEnum.Description1]: "descriptionLine1",
  [adExtensionColumnsEnum.Description2]: "descriptionLine2",
  [adExtensionColumnsEnum.FinalURL]: "finalUrl",
  [adExtensionColumnsEnum.FinalMobileURL]: "finalMobileUrl",
  [adExtensionColumnsEnum.TrackingTemplate]: "trackingTemplate",
  [adExtensionColumnsEnum.StartDate]: "startDate",
  [adExtensionColumnsEnum.EndDate]: "endDate",
  [adExtensionColumnsEnum.CalloutText]: "text",
  [adExtensionColumnsEnum.HeaderType]: "header",
  "AdScheduleTargets": "adScheduleTargets",
  "CustomParameters": "customParameters",
  "Values": "values"
};

const adExtensionPayloadKeyMapping = {
  [adwordsEnums.adExtension["Call"]]: "calls",
  [adwordsEnums.adExtension["SiteLink"]]: "siteLinks",
  [adwordsEnums.adExtension["Callout"]]: "callouts",
  [adwordsEnums.adExtension["Structured Snippet"]]: "structuredSnippets"
};

const repeatingColumns = {
  schedulingColumns,
  siteLinkCustomParameterColumns,
  structuredSnippetValueColumns,
  schedulingColumnsCount,
  siteLinkCustomParameterColumnsCount,
  structuredSnippetValueColumnsCount
};

export {
  adExtensionColumnsEnum,
  adExtensionColumns,
  adExtensionColumnFieldMapping,
  adExtensionPayloadKeyMapping,
  repeatingColumns
};
