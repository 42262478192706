<template>
    <div>
        <i-modal
            v-model="showModal"
            width="45%"
            :closable="false"
            :styles="{top: '140px'}"
            :mask-closable="false">

            <div slot="header" class="col-md-12 no-padding header-div-style">
              <div class="col-md-7 margin-top-style">
                <h4>
                  <i class="fa fa-lightbulb-o icon-size"></i> AI Recommended Plan
                </h4>
              </div>
             <div class="col-md-3 no-padding padding-top-style rebalance-totalCost-padding">
                <i-input
                  :disabled="true"
                  v-model="recommendationBudget"
                  placeholder="Media Cost"
                  type="number"
                  class="budget-width"
                />
              </div>
               <div class="col-md-2 margin-top-style rebalance-div-padding">
               <button
                  type="button"
                  class="btn create-btn btn-success rebalance-button-padding"
                  @click="rebalanceBudget"
                > <i class="fa fa-retweet" style="font-size: 14px;"></i> Rebalance </button>
              </div>
            </div>

            <div class="table-wrapper" v-if="showModal" style="height: 250px; overflow-y: auto !important;">
                <i-table ref="selection" class="" size="small" :columns="tableColumns" :data="planRecommendationTableData" @on-selection-change="rowSelections">
                </i-table>
            </div>

            <div slot="footer">
              <Button class="btn create-btn btn-white button-style" @click="cancelModel">Cancel</Button>
              <Button id="btn-continue" class="btn create-btn btn-success button-style" @click="addLineItemRecommendations">Continue</Button>
            </div>
        </i-modal>
    </div>
</template>
<script>
import {
  Modal,
  Table,
  Input
} from "iview";

export default {
  components: {
    "i-modal": Modal,
    "i-table": Table,
    "i-input": Input
  },

  data () {
    return {
      showModal: false,
      tableColumns: [
        {
          type: 'selection',
          align: 'left',
          width: 25
        },
        {
          title: 'Channel',
          key: 'lineItemType',
          align: 'left',
          width: 120
        },
        {
          title: 'Publisher',
          key: 'publisher',
          align: 'left',
          width: 190
        },
        {
          title: 'BuyType',
          key: 'buyType',
          align: 'left',
          width: 90
        },
        {
          title: 'Budget',
          key: 'rebalancedBudget',
          align: 'left'
        }
      ],
      currentSelection: [],
      isAllDataSelected: false,
      recommendationBudget: 0,
      planRecommendationOrderId: 0
    }
  },
  props: [
    "planRecommendationTableData"
  ],
  methods: {
    cancelModel () {
      this.showModal = false;
      this.$parent.$parent.showRecommendationTooltip = false;
    },
    addLineItemRecommendations () {
      var self = this;
      var currentSelectionIds = self.currentSelection.map(x => x.id);
      var selectedSuggestions = self.planRecommendationTableData.filter(({ id }) => currentSelectionIds.includes(id));
      var lineItemRows = [];
      let rowsCount = self.$root.$children[0].hot.countRows();
      var deletedRows = [];
      var isRowsDeleted = false;
      for (let eachRow = 0; eachRow < rowsCount; eachRow++) {
        let rowData = self.$root.$children[0].hot.getSourceDataAtRow(eachRow);
        if (
          rowData.channelType == undefined ||
        rowData.channelType == "" ||
        rowData.channelType == null
        ) {
          isRowsDeleted = true;
          deletedRows.push(rowData);
        }
      }

      if (isRowsDeleted == true) {
        deletedRows.forEach(element => {
          var handsonData = this.$root.$children[0].hot.getSourceData();
          var rowNumber = handsonData
            .map(x => x.orderId)
            .indexOf(element.orderId);
          this.$root.$children[0].hot.alter("remove_row", rowNumber);
        });
      }
      deletedRows = [];
      isRowsDeleted = false;
      self.planRecommendationOrderId = self.$root.$children[0].hot.getSourceData().length > 0 ? Math.max.apply(Math, self.$root.$children[0].hot.getSourceData().map(x => x.orderId)) : 1
      selectedSuggestions.forEach(lineItem => {
        let lineItemRow = {
          id: 0,
          type: lineItem.lineItemTypeId,
          publisher: lineItem.publisher,
          budget: lineItem.rebalancedBudget,
          buyType: lineItem.buyType,
          buyMetric: lineItem.buyMetric,
          mediaPlanVersionId:
                    self.$root.$children[0].mediaPlanId == 0
                      ? 0
                      : self.$root.$children[0].mediaPlanVersionId,
          entityTypeId: 1,
          linkedEntities: [],
          labels: [],
          referenceKeys: [],
          lineItemDataParameters: [],
          orderId: (self.planRecommendationOrderId = self.planRecommendationOrderId + 1)
        }
        lineItemRows.push(lineItemRow);
      });
      this.$emit('addLineItemRecommendations', lineItemRows);
      this.showModal = false;
    },
    rowSelections (selection) {
      this.currentSelection = selection;
    },
    rebalanceBudget: function () {
      var multiplier;
      if (this.currentSelection.length == 0) {
        multiplier = 1;
        this.planRecommendationTableData.forEach((lineItem, index) => {
          lineItem.rebalancedBudget = this.formatNumber(multiplier * lineItem.lineItemBudget);
          lineItem._checked = false;
          this.$set(this.planRecommendationTableData, index, lineItem);
        })
        this.recommendationBudget = this.planRecommendationTableData.map(x => x.rebalancedBudget).reduce(function (a, b) { return a + b; }, 0);
      } else {
        var sum = this.currentSelection.map(x => x.lineItemBudget).reduce(function (a, b) { return a + b; }, 0);
        multiplier = this.$parent.$parent.$parent.mediaPlanObject.mediaCostValue / sum;
        this.planRecommendationTableData.forEach((lineItem, index) => {
          if (this.currentSelection.map(x => x.id).some(element => element == lineItem.id)) {
            lineItem.rebalancedBudget = this.formatNumber(multiplier * lineItem.lineItemBudget);
            lineItem._checked = true;
          } else {
            lineItem.rebalancedBudget = 0;
            lineItem._checked = false;
          }
          this.$set(this.planRecommendationTableData, index, lineItem);
        })
        this.recommendationBudget = this.planRecommendationTableData.map(x => x.rebalancedBudget).reduce(function (a, b) { return a + b; }, 0);
        var differenceAmount = this.$parent.$parent.$parent.mediaPlanObject.mediaCostValue - this.recommendationBudget;
        if (differenceAmount > 0 && this.planRecommendationTableData.length > 0) {
          var maxBudget = Math.max.apply(Math, this.planRecommendationTableData.map(x => x.rebalancedBudget))
          var index = this.planRecommendationTableData.findIndex(x => x.rebalancedBudget == maxBudget)
          this.planRecommendationTableData[index].rebalancedBudget += differenceAmount;
          this.recommendationBudget += differenceAmount;
        }
      }
    },
    formatNumber: function (number) {
      var formattedNumber = number;
      if ((number + "").split(".")[0] != undefined && (number + "").split(".")[0] != null && (number + "").split(".")[0] > 0) {
        formattedNumber = (parseInt(number / 10) * 10)
      }
      return formattedNumber;
    },
    setRebalancingBudget: function (planRecommendationTableData) {
      var rowId = 0;
      var sum = planRecommendationTableData.map(x => x.lineItemBudget).reduce(function (a, b) { return a + b; }, 0);
      var multiplier = this.$parent.$parent.$parent.mediaPlanObject.mediaCostValue / sum;
      planRecommendationTableData.forEach(lineItem => {
        lineItem._checked = true;
        lineItem.rebalancedBudget = this.formatNumber(lineItem.lineItemBudget * multiplier);
        lineItem.id = rowId++;
      });
      this.recommendationBudget = planRecommendationTableData.map(x => x.rebalancedBudget).reduce(function (a, b) {
        return a + b;
      }, 0);
      var differenceAmount = this.$parent.$parent.$parent.mediaPlanObject.mediaCostValue - this.recommendationBudget;
      if (differenceAmount > 0 && planRecommendationTableData.length > 0) {
        var maxBudget = Math.max.apply(Math, planRecommendationTableData.map(x => x.rebalancedBudget))
        var index = planRecommendationTableData.findIndex(x => x.rebalancedBudget == maxBudget)
        planRecommendationTableData[index].rebalancedBudget += differenceAmount;
        this.recommendationBudget += differenceAmount;
      }
    },
    setRecommendationData: function () {
      this.showModal = true;
    }
  },
  watch: {
    "showModal": {
      handler: async function (newValue, oldValue) {
        if (newValue == true) {
          this.setRebalancingBudget(this.planRecommendationTableData);
          this.currentSelection = this.planRecommendationTableData;
        }
      }
    }
  }
}
</script>
<style scoped>
.title{
    margin-top: 0px;
    margin-bottom: 0px;
}
::v-deep .ivu-modal-header {
  border-bottom: 2px solid #dcdee2;
}
.table-wrapper {
  display: block !important;
  justify-content: center;
}
.recommended-plan-table {
    display: block !important;
    width: 100%;
}
::v-deep .ivu-table-header {
  height: 30px !important;
  max-height: 30px !important;
}
::v-deep .ivu-table-header table thead {
    border-bottom: 0px !important;
}
::v-deep .ivu-table-header table thead tr th {
    height: 30px !important;
    max-height: 30px !important;
}
::v-deep .ivu-table-header table thead tr th div label {
    display: none !important;
}
::v-deep .ivu-table-row {
    height: 30px !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
::v-deep .ivu-modal-header {
  padding: 26px 16px !important;
}
.header-div-style{
  margin-left: -15px;
}
.margin-top-style{
  margin-top: -17px;
}
.padding-top-style{
  margin-top: -15px;
  margin-left: -10px;
}
::v-deep .ivu-table-small td{
  height: 36px !important;
}
.budget-width{
  width:80%;
}
.rebalance-button-padding{
  padding: 6px 6px !important;
}
.rebalance-div-padding{
  right: -10px;
}
.rebalance-totalCost-padding{
  right:-48px;
}
</style>
