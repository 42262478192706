import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Vue from 'vue'

export const BugsnagConfig = {
  setup () {
    Bugsnag.start({
      apiKey: 'e9a92574ef5d4efb716a37f90e7f956d',
      plugins: [new BugsnagPluginVue(Vue)],
      releaseStage: process.env.VUE_APP_RELEASE_STAGE,
      appVersion: '1.0.0'
    })
    // ignoring errors here since these are globally set variables in AccountManagerLayout.cshtml
    // eslint-disable-next-line
    // Bugsnag.setUser(loggedInUserId, loggedInUserEmail, loggedInUserName);
  }
}
