<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <Row>
          <Row-Col span="10">
            <h4>Ad Extensions <i v-show="loader" class="fa fa-spinner fa-spin" /></h4>
          </Row-Col>
          <Row-Col span="8">
            <Select v-model="selectedType" :disabled="loader || businessProfilesSearchEngineId == 0">
              <Option
                v-for="(name, enumId) in adExtensionTypes"
                :key="enumId"
                :value="Number(enumId)"
                >{{ name }}</Option
              >
            </Select>
          </Row-Col>
          <Row-Col span="6">
            <div class="plus-button">
              <!-- should show only if bulk creation is enabled in feature flags -->
              <AdExtensionBulkCreation v-if="isBulkCreationModeEnabled" :defaultTab="`${selectedType}`" @save="fetchAdExtensions" />
              <AdExtensionCreation v-else @newAdExtensionCreated="newAdExtensionCreated" />
            </div>
            <!-- <button class="btn btn-success btn-sm">
              <i class="fa fa-download"></i>
            </button> -->
          </Row-Col>
        </Row>
      </div>
      <template>
        <div
          style="margin-top:10px"
          v-if="businessProfilesSearchEngineId==0"
          class="alert alert-info"
        >Please select a campaign configuration to see the ad extensions.
        </div>
        <div v-else>
          <Row>
            <Row-Col span="24">
              <Input v-model="search" search placeholder="Search" class="search-bar"/>
            </Row-Col>
          </Row>
          <div :class="notificationClass" v-if="showNotification" role="alert">
            {{ notificationMessage }}
            <button type="button" class="close" aria-label="Close" @click="showNotification = false">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            style="margin-top:10px"
            v-else-if="!loader && (!adExtensions || adExtensions.length<=0)"
            class="alert alert-info"
        >
          There are no ad extensions of this type.
        </div>
          <VuePerfectScrollbar class="widget-body">
            <Row class="cl-ivu-row" v-for="item in filteredAdExtensions" :key="item.id">
              <Row-Col span="1">
                <i class="fas fa-copy action-icon"
                  @click="copyToClipboard(item.id)">
                </i>
              </Row-Col>
              <Row-Col offset="1" span="21" class="cl-widget-text">{{ item.name }}</Row-Col>
            </Row>
          </VuePerfectScrollbar>
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import { APIService } from "./../../ApiService.js";
import { mapState, mapMutations } from "vuex";
import { Input, locale, Card, Row, Col, Select, Option } from "iview";
import lang from "iview/dist/locale/en-US";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import mixins from "../../../../Mixins/copyToClipboard.js";
import { adwordsEnums } from "../../Constants/AdwordsEnums.js";
import AdExtensionCreation from './AdExtensionCreation.vue'
import AdExtensionBulkCreation from './AdExtensionBulkCreation.vue'
import { EventBus } from "../../../CampaignLauncher/EventBus";

locale(lang);
export default {
  components: {
    Input,
    VuePerfectScrollbar,
    Card,
    "Row-Col": Col,
    Row,
    Select,
    Option,
    AdExtensionCreation,
    AdExtensionBulkCreation
  },
  mixins: [mixins],
  data: function () {
    return {
      search: "",
      loader: false,
      selectedType: 1,
      adExtensionTypes: adwordsEnums.adExtensionTypes,
      adExtensions: [],
      showNotification: false,
      notificationMessage: null,
      notificationClass: null,
      isBulkCreationModeEnabled: false
    };
  },
  mounted () {
    if (this.businessProfile.facebookFeatureFlags) {
      const featureFlags = JSON.parse(this.businessProfile.facebookFeatureFlags);

      this.isBulkCreationModeEnabled = featureFlags.IsAdwordsAdExtensionBulkCreationEnabled || false;
    }

    EventBus.$on("adsheetWidget-configSwitched", async () => {
      if (this.publishedCreatives && this.publishedCreatives.assetGroups && this.publishedCreatives.assetGroups.length > 0) {
        this.fetchEntitiyAdExtensions(this.publishedCreatives.assetGroups);
      }
      if (this.publishedCreatives && this.publishedCreatives.adGroups && this.publishedCreatives.adGroups.length > 0) {
        this.fetchEntitiyAdExtensions(this.publishedCreatives.adGroups);
      }
    });

    this.fetchAdExtensions();
  },
  beforeDestroy () {
    EventBus.$off("adsheetWidget-configSwitched");
  },
  methods: {
    ...mapMutations(["set_adExtensions"]),
    fetchAdExtensions () {
      this.loader = true;
      APIService.getAdExtensions(this.businessProfilesSearchEngineId, this.selectedType)
        .then(response => {
          this.adExtensions = []
          let adExtensions = response.data
          let fetchedAdExtensionIds = this.allAdExtensions.map(adExtension => adExtension.id)
          let updatedAllExtensions = [...this.allAdExtensions, ...adExtensions.filter(adExtension => !fetchedAdExtensionIds.includes(adExtension.id))];
          this.set_adExtensions(updatedAllExtensions);
          adExtensions.forEach(adExtension => {
            this.adExtensions.push({ id: 'DXA-' + Number.parseInt(businessProfileId).toString(36) + '-' + adExtension.id, name: adExtension.name })
          })
        })
        .catch(() => {
          this.showNotification = true;
          this.notificationClass = "alert alert-danger"
          this.notificationMessage = "There was a problem while fetching the AdExtensions"
        })
        .finally(() => {
          this.loader = false;
        })
    },
    newAdExtensionCreated (newAdExtension) {
      if (this.businessProfilesSearchEngineId == newAdExtension.businessProfilesSearchEngineId && newAdExtension.extensionTypeId == this.selectedType) {
        this.adExtensions.unshift({ id: 'DXA-' + Number.parseInt(businessProfileId).toString(36) + '-' + newAdExtension.id, name: newAdExtension.name });
        let updatedAllExtensions = this.allAdExtensions;
        updatedAllExtensions.push(newAdExtension);
        this.set_adExtensions(updatedAllExtensions);
      }
      this.showNotification = true;
      this.notificationClass = "alert alert-success";
      this.notificationMessage = "AdExtension created succesfully"
    },
    fetchEntitiyAdExtensions (entities) {
      let adExtensionIds = [];
      entities.forEach(entity => {
        if (entity.adExtensions) {
          let formattedAdExtensionIds = entity.adExtensions.split(",");
          formattedAdExtensionIds.forEach(formattedId => {
            let id = formattedId.split("-")[2];
            adExtensionIds.push(id);
          })
        }
      })
      adExtensionIds = [...new Set(adExtensionIds)]
      if (adExtensionIds.length > 0) {
        APIService.getAdExtensions(this.businessProfilesSearchEngineId, null, adExtensionIds.join(","))
          .then(response => {
            this.set_adExtensions([]);
            this.set_adExtensions(response.data);
          })
          .catch(() => {
            this.showNotification = true;
            this.notificationClass = "alert alert-danger"
            this.notificationMessage = "There was a problem while fetching the AdExtensions"
          })
      }
    }
  },
  computed: {
    ...mapState(["businessProfilesSearchEngineId", "businessProfile", "businessProfileId", "publishedCreatives", "allAdExtensions"]),
    filteredAdExtensions () {
      let searchTerm = this.search;
      if (searchTerm != "") {
        return this.adExtensions.filter(a => {
          return a.name.toLowerCase().includes(searchTerm.toLowerCase())
        })
      } else {
        return this.adExtensions;
      }
    }
  },
  watch: {
    businessProfilesSearchEngineId (value) {
      if (value != 0) {
        this.fetchAdExtensions()
      }
    },
    selectedType: {
      handler (value) {
        this.fetchAdExtensions()
      }
    }
  }
};
</script>
<style scoped>
.search-bar{
  margin: 0px 0px 10px 0px;
}
</style>
<style src="../../../../Styles/dx-iview.css"></style>
