<template>
  <div class="planview-container">
    <i-row type="flex" v-if="!isLoading">
      <i-col>
        <span class="publisher-title">{{ publisher }}</span>
      </i-col>
      <i-col>
        <i-divider type="vertical"></i-divider>
      </i-col>
      <i-col>
        <span><i class="fa fa-image" aria-hidden="true"></i>{{ ` ${getAssetCount} Assets` }}</span>
      </i-col>
      <i-col>
        <i-divider type="vertical"></i-divider>
      </i-col>
      <i-col>
        <span><i class="fa fa-envelope" aria-hidden="true"></i>{{ ` ${getRecipientsCount} Recipients` }}</span>
      </i-col>
      <i-col class="angle-down-btn">
        <span @click="togglePublisherContainer">
          <i :class="showPublisherContainer ? 'fa fa-angle-up' : 'fa fa-angle-down'" aria-hidden="true"></i>
        </span>
      </i-col>
    </i-row>
    <div class="publisher-container" v-show="showPublisherContainer">
      <publisher-recipients-plan-view ref="publisherRecipientsPlanView">
      </publisher-recipients-plan-view>

      <line-item-plan-view v-for="(lineItem, index) in lineItemIds" :key="index" ref="lineItemPlanView"
        @linkLineItemMode="linkLineItemMode" @unLinkLineItemMode="unLinkLineItemMode">
      </line-item-plan-view>
    </div>
  </div>
</template>

<script>
import { Divider, Row, Col } from 'iview';
import PublisherRecipientsPlanView from './PublisherRecipientsPlanView.vue'
import LineItemPlanView from './LineItemPlanView.vue'

export default {
  components: {
    'i-divider': Divider,
    'i-row': Row,
    'i-col': Col,
    'publisher-recipients-plan-view': PublisherRecipientsPlanView,
    'line-item-plan-view': LineItemPlanView
  },
  data () {
    return {
      isLoading: true,
      lineItemIds: [],
      showPublisherContainer: true
    };
  },
  props: {
    publisher: String,
    isloading: Boolean
  },
  methods: {
    togglePublisherContainer () {
      this.showPublisherContainer = !this.showPublisherContainer;
    },
    publisherRecipientsData () {
      return this.$refs.publisherRecipientsPlanView;
    },
    lineItemPlanViewData (index) {
      if (this.lineItemIds.length > 0) {
        return this.$refs.lineItemPlanView[index];
      }
    },
    linkLineItemMode (lineItemId) {
      this.$emit('linkLineItemMode', lineItemId)
    },
    unLinkLineItemMode (lineItemId) {
      this.$emit('unLinkLineItemMode', lineItemId)
    }
  },
  computed: {
    getAssetCount () {
      var count = 0;
      this.lineItemIds.forEach((lineItemId, index) => {
        count += (this.$refs.lineItemPlanView[index] && this.$refs.lineItemPlanView[index].tblData)
          ? this.$refs.lineItemPlanView[index].tblData.length
          : 0;
      })

      return count;
    },
    getRecipientsCount () {
      return this.$refs.publisherRecipientsPlanView.supplierEmails.length;
    }
  }
};
</script>

<style scoped>

.angle-down-btn {
  margin-left: auto;
}
.angle-down-btn .fa{
  font-weight: 600 !important;
}
.planview-container:not(:last-child) {
  margin-bottom: 20px;
}

.publisher-container {
  border: 1px solid #dcdee2;
  padding: 14px;
  margin-top: 7px;
}

.publisher-title {
  font-weight: 600 !important;
  opacity: 0.9;
}

::v-deep .ivu-col span {
  color: #2c2e2f !important;
  font-size: 13px !important;
}
</style>
