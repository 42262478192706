<template>
    <div class="card">
      <div v-if="targetTypePivot.targets > 0">
        <table class="table table-light table-bordered">
        <thead>
            <tr>
                <th class="table-header">ASINs to Target</th>
                <th class="table-header">Bid</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(record, i) in targetTypePivot.records" :key="i">
                <td class="data-center">{{record.asinsToTarget}}</td>
                <td class="data-center">{{record.bid}}</td>
            </tr>
        </tbody>
      </table>
      </div>
      <div v-else>
        <div class="alert alert-warning" role="alert">
          No details Found
        </div>
      </div>
    </div>
</template>

<script>
export default {
  components: {},
  mounted: function () {},
  props: {
    targetDetails: {
      type: Array,
      default: function () {
        return []
      }
    },
    bid: Number
  },
  data: function () {
    return {};
  },
  computed: {
    targetTypePivot: function () {
      this.budgetTypeVerified = false;
      let records = [];
      if (this.targetDetails.length > 0) {
        for (var ind = 0; ind < this.targetDetails.length; ind++) {
          var data = this.targetDetails[ind];
          let record = {
            asinsToTarget: data.AsinsToTarget,
            bid: data.Bid == 0 ? this.bid : data.Bid
          };
          records.push(record);
        }
        let totalTargets = records.length;
        return { records: records, targets: totalTargets };
      } else {
        return { records: "(None)", targets: 0 };
      }
    }
  }
}
</script>

<style scoped>
  .data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: -3px -3px 5px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background-color: rgba(1,1,1,0);
  padding: 5px
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.table-header{
  border:1px solid #ddd !important;
  padding:10px !important;
}
</style>
