export default {
  methods: {
    getMediaPlanRequest (mediaPlan, lineItems, userId) {
      var showNameValidation = false;
      var showObjectiveValidation = false;
      var showBudgetValidation = false;
      var isError = false;
      if (mediaPlan.name == "") {
        showNameValidation = true;
        isError = true;
      }
      if (mediaPlan.objectives.selected == "" || !mediaPlan.objectives.selected) {
        showObjectiveValidation = true;
        isError = true;
      }
      if (mediaPlan.cost == "") {
        showBudgetValidation = true;
        isError = true;
      }
      if (isError) {
        return {
          error: {
            showNameValidation: showNameValidation,
            showObjectiveValidation: showObjectiveValidation,
            showBudgetValidation: showBudgetValidation
          }
        }
      }
      return {
        Name: mediaPlan.name != "" ? mediaPlan.name : "MediaPlan",
        Status: 1,
        MediaPlanVersionId: 0,
        Id: 0,
        Objective: mediaPlan.objectives.selected,
        Budget: mediaPlan.cost,
        LandingPageLink: mediaPlan.landingUrl,
        StartDate: moment(mediaPlan.schedule[0]).format(
          "MM/DD/YYYY"
        ),
        EndDate: moment(mediaPlan.schedule[1]).format(
          "MM/DD/YYYY"
        ),
        EffectiveStatus: 0,
        BenchmarksOrdering: null,
        Label: null,
        LineItemRequest: this.getLineItemsData(mediaPlan, lineItems),
        CrossChannelSuggestionEnabled: 0,
        ApprovedBuyId: 0,
        BriefId: 0,
        OwnerId: userId
      }
    },
    getLineItemsData (mediaPlan, lineItems) {
      var filteredLineItems = lineItems.filter(function (item, index) {
        var isNameNotEmpty = item.name != "";
        var isBudgetNotEmpty = item.budget > 0;
        var isKpiValueNotEmpty = item.keymetricValue != "";
        var isKpimetricNotEmpty = item.keymetricId != "";
        var isLineItemFraction = item.internalFractionId == 0;
        return (isNameNotEmpty && isBudgetNotEmpty && isKpiValueNotEmpty && isKpimetricNotEmpty && isLineItemFraction);
      });
      return filteredLineItems.map(function (item, index) {
        var dataParameters = [{
          DataParameterId: item.keymetricId,
          DataParameterValue: item.keymetricValue,
          OrderId: 1
        }];
        if (item.keymetricId != 7) { // default pricing type metric
          dataParameters.push({
            DataParameterId: 7,
            DataParameterValue: item.budget,
            OrderId: 2
          })
        }
        var budget = item.budget;
        if (item.budgetType == 2 && item.scheduleDays != "") {
          budget = budget * item.scheduleDays;
        }
        return {
          mediaPlanVersionId: 0,
          Id: 0,
          Type: 2,
          Publisher: "Facebook",
          Theme: item.name,
          Entity: 1,
          Objective: mediaPlan.objectives.selected,
          Targeting: null,
          Nickname: item.name,
          StartDate: moment(item.startDate).format(
            "MM/DD/YYYY"
          ),
          EndDate: moment(item.endDate).format(
            "MM/DD/YYYY"
          ),
          Budget: budget,
          PricingTypeId: 7,
          PricingTypeReferenceValueId: 1,
          KpiMetricId: item.keymetricId,
          UnitPrice: 1,
          Quantity: 1,
          TechnologyCostTypeId: 1,
          TechnologyCostValue: 0,
          CreativeCostTypeId: 1,
          CreativeCostValue: 0,
          AgencyComissionTypeId: 1,
          AgencyComissionValue: 0,
          TaxTypeId: 1,
          TaxValue: 0,
          CostSource: "Publisher",
          PerUnitPrice: 1,
          EntityTypeId: 1,
          OrderId: item.internalId,
          CostPacingTypeId: 1,
          CostPacingJson: null,
          TotalCost: mediaPlan.budget,
          CreativeFormat: "",
          Note1: "",
          Note2: "",
          RONumber: "",
          LinkedEntities: [],
          IsCostCalcuated: 0,
          IsDiscountOverdelivery: 0,
          DataParameter: dataParameters,
          Label: null,
          ReferenceKeys: null,
          LineItemFractions: lineItems.filter(li => li.internalId == item.internalId && li.internalFractionId != 0).map(function (li) { return { "alias": li.fractionAlias } }),
          IsFractionEnabled: item.isFractionEnabled,
          ROId: "",
          SupplierId: null,
          Universe: null,
          ReachPercentage: null
        }
      })
    }
  }
}
