import { Objective } from "./Objective.js"

let ColumnConfig = function (config) {
  let callToAction = ["No Button", "Call Now", "Get Directions", "Learn More", "Order Now", "Shop Now"]
  if (config.ad.dynamicAdVoice == "1") {
	  callToAction.push("Message Page")
  }

  let single = {
    base: ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Headline", "HOT_Text", "HOT_Call To Action", "HOT_Link", "HOT_Description", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets"],
    requiredBase: ["HOT_Ad Name", "HOT_Headline", "HOT_Text", "HOT_Image/Video", "HOT_Link"],
    card: [],
    requiredCard: [],
    callToAction: callToAction
  };

  let carousel = {
    base: ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Headline", "HOT_Text", "HOT_Call To Action", "HOT_Optimize Card Order", "HOT_Link", "HOT_Description", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_AddMap", "HOT_Targets"],
    requiredBase: ["HOT_Ad Name", "HOT_Headline", "HOT_Text", "HOT_Link"],
    card: ["HOT_Description", "HOT_Link", "HOT_Headline", "HOT_Image/Video", "HOT_Video_Thumbnail"],
    requiredCard: ["HOT_Link", "HOT_Image/Video"],
    callToAction: callToAction
  }

  return { single, carousel }
}

let CreativeSpec = function (config) {
  let single = new function () {
    this.image = {
      "creative": {
        "dynamic_ad_voice": null,
        "object_story_spec": {
          "page_id": "HOT_Page",
          "template_data": {
            "description": "HOT_Description",
            "link": "HOT_Link",
            "message": "HOT_Text",
            "name": "HOT_Headline",
            "picture": "HOT_Image/Video",
            "show_multiple_images": false,
            "force_single_link": true
          },
          "call_to_action": {
            "type": "HOT_Call To Action",
            "value": {
              "link": "HOT_Link"
            }
          }
        }
      }
    }
    this.video = {
      "creative": {
        "dynamic_ad_voice": null,
        "object_story_spec": {
          "page_id": "HOT_Page",
          "video_data": {
            "call_to_action": {
              "value": {
                "link": "HOT_Link"
              },
              "type": "HOT_Call To Action"
            },
            "video_id": "HOT_SearchEngineVideoId",
            "title": "HOT_Headline",
            "message": "HOT_Text",
            "link_description": "HOT_Description",
            "image_url": "HOT_Image/Video"
          }
        }
      }
    }
  }()
  let carousel = {
    "creative": {
      "dynamic_ad_voice": null,
      "object_story_spec": {
        "page_id": "HOT_Page",
        "template_data": {
          "description": "HOT_Description",
          "link": "HOT_Link",
          "message": "HOT_Text",
          "name": "HOT_Headline",
          "multi_share_end_card": false,
          "child_attachments": [
            {
              "description": "HOT_Description",
              "link": "HOT_Link",
              "name": "HOT_Headline",
              "picture": "HOT_Image/Video",
              "call_to_action": {
                "value": {
                  "link": "HOT_Link"
                },
                "type": "HOT_Call To Action"
              }
            }
          ],
          "multi_share_optimized": "HOT_Optimize Card Order"
        }
      }
    }
  }

  return { single, carousel }
}

let storeVisits = new Objective(ColumnConfig, CreativeSpec)
export { storeVisits }
