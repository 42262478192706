<template>
    <div>
        <AddPurchaseOrder
        ref="addPurchaseOrderPopUp"
        @cancelPurchaseOrderPopUp="cancelAddPurchaseOrder"
        >
        </AddPurchaseOrder>
        <i-modal
        v-model="showModal"
        width="80%"
        :closable="false"
        :mask-closable="false"
        >
          <div slot="header" class="header-style">
              Link PO
          </div>
          <div class="modal-body no-padding" style="height: 400px">
            <div v-if="showSpinner" style="text-align: center">
              <i class="fa fa-spinner fa-spin fa-3x"></i>
            </div>
            <div v-else class="col-md-12 no-padding" style="margin-top: 10px">
             <i-table
              ref="poTable"
              :columns="poColumns"
              :data="poData"
              border
              stripe
              size="small"
             >
             <template slot-scope="{ row }" slot="select">
                <i-checkbox
                  v-if='row.tooltip == ""'
                  v-model="row.isRowSelected"
                  :disabled="row.isRowDisabled"
                  @on-change="updateSelectedPo(row)">
                </i-checkbox>
                <i-tooltip
                  v-if='row.tooltip != ""'
                  :content="row.tooltip"
                  placement="right" >
                    <i-checkbox
                      v-model="row.isRowSelected"
                      :disabled="row.isRowDisabled"
                      @on-change="updateSelectedPo(row)"></i-checkbox>
                </i-tooltip>
             </template>
              <template slot-scope="{ row }" slot="action">
                <i-tooltip content="Edit">
                  <span class="action-button" @click="editPO(row.id)">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </span>
                </i-tooltip>
              </template>
             </i-table>
            </div>
          </div>
          <div class="footer-wrapper" slot="footer">
              <button
                class="btn create-btn btn-success button-style"
                @click="linkPurchaseOrders"
              >
                Link PO
              </button>
              <button
                class="btn create-btn btn-white button-style"
                @click="cancelAction"
              >
                Cancel
              </button>
          </div>
        </i-modal>
    </div>
</template>
<script>
import {
  Modal,
  Table,
  Tooltip,
  locale,
  Checkbox
} from "iview";
import AddPurchaseOrder from '../../PurchaseOrder/Components/AddPurchaseOrder.vue';
import { ApiService } from ".././Services/MediaPlanCoFundingApiService.js";
import lang from "iview/dist/locale/en-US"; // iview is default chinese
locale(lang);
export default {
  components: {
    "i-modal": Modal,
    "i-table": Table,
    "i-tooltip": Tooltip,
    AddPurchaseOrder,
    "i-checkbox": Checkbox
  },
  mounted () {
    $(".perfect-scroll-bar").perfectScrollbar({});
  },
  created () {
    this.apiService = new ApiService();
  },
  props: {
    budget: Number
  },
  data: function () {
    return {
      showModal: false,
      apiService: {},
      poData: [],
      poIds: [],
      showSpinner: true,
      selectedPoData: [],
      isAllRowsSelected: false,
      isSelectAllDisabled: false,
      poColumns: [
        {
          width: 60,
          align: 'center',
          key: 'select',
          slot: 'select',
          renderHeader: (h, params) => {
            var self = this;
            return h('div', [
              h('Checkbox', {
                props: {
                  value: self.isAllRowsSelected,
                  disabled: self.isSelectAllDisabled
                },
                on: {
                  input: (event) => {
                    self.setAllRowSelect(event);
                  }
                }
              })
            ])
          }
        },
        {
          title: "PO Date",
          key: "poDate",
          align: "center"
        },
        {
          title: "PO Number",
          key: "poNumber",
          align: "center"
        },
        {
          title: "Value",
          key: "value",
          align: "center"
        },
        {
          title: "Invoiced",
          key: "consumed",
          align: "center"
        },
        {
          title: "Locked",
          key: "locked",
          align: "center"
        },
        {
          title: "Balance",
          key: "remaining",
          align: "center"
        },
        {
          title: "Action",
          slot: "action",
          align: "center",
          width: 60
        }
      ]
    };
  },
  methods: {
    editPO: function (poId) {
      this.$refs.addPurchaseOrderPopUp.setupModal(poId);
    },
    cancelAddPurchaseOrder: function () {
      this.fetchData(this.poIds);
      this.$refs.addPurchaseOrderPopUp.cancelModal();
    },
    setupModal: function (purchaseOrderData) {
      this.showModal = true;
      this.poIds = purchaseOrderData.map((p) => p.id);
      this.fetchData(this.poIds);
    },
    cancelModal: function () {
      this.showModal = false;
    },
    cancelAction: function () {
      this.showModal = false;
      this.$emit("cancelPurchaseOrderPopUp");
    },
    linkPurchaseOrders: function () {
      this.$emit("linkPurchaseOrder", this.selectedPoData);
      this.showModal = false;
    },
    updateSelectedPo: function (selection) {
      var filteredData = this.$refs.poTable.$data.rebuildData.filter(x => x.isRowSelected == true);
      if (filteredData == null || filteredData == undefined) {
        return
      }

      this.selectedPoData = filteredData;
      this.checkAndSetAllRowsSelected();
    },
    fetchData: function (poIds) {
      var self = this;
      this.clearData();
      this.showSpinner = true;
      var promises = [];
      var getAllPurchaseOrdersPromise = this.apiService.GetPurchaseOrders();
      promises.push(getAllPurchaseOrdersPromise);
      Promise.all(promises)
        .then((response) => {
          var poData = [];
          var index = 0;
          response[0].data.data.purchaseOrders.forEach(element => {
            var poObj = {
              id: element.id,
              poDate: moment(element.issuedDate).format('DD MMM YYYY'),
              poNumber: element.clientIONumber,
              value: element.amount,
              index: index,
              purchaseOrderId: element.id,
              consumed: element.consumed,
              locked: element.locked,
              remaining: element.amount - element.consumed,
              approvedBuyIds: element.approvedBuyIds != undefined ? element.approvedBuyIds.split(',').map(Number) : [],
              tooltip: '',
              isRowSelected: false,
              isRowDisabled: false
            };
            if (this.budget > poObj.remaining) {
              poObj.isRowDisabled = true;
              poObj.tooltip = 'Media plan budget is greater than ' + poObj.remaining;
            } else if (poIds.includes(poObj.id)) {
              poObj.isRowDisabled = false;
              poObj.tooltip = '';
            }
            poData.push(poObj);
            index++;
          });
          this.poData = poData;
          self.isSelectAllDisabled = this.poData.find(po => po.isRowDisabled == true) != null;
          this.showSpinner = false;
        });
    },
    clearData: function () {
      this.poData = [];
      this.selectedPoData = [];
    },
    checkAndSetAllRowsSelected () {
      if (this.$refs.poTable == undefined || this.$refs.poTable == null) {
        return;
      }

      this.isAllRowsSelected = false;
      var count = this.$refs.poTable.$data.rebuildData.length;
      var filteredData = this.$refs.poTable.$data.rebuildData.filter(x => x.isRowSelected == true);
      if (filteredData == undefined || filteredData == null) {
        return;
      }

      if (filteredData.length == count) {
        this.isAllRowsSelected = true;
      }
    },
    setAllRowSelect (event) {
      this.poData = this.$refs.poTable.$data.rebuildData;
      if (event == undefined || event == null) {
        return;
      }

      this.isAllRowsSelected = event;
      if (this.poData == undefined || this.poData == null) {
        return;
      }

      this.poData.forEach(po => {
        po.isRowSelected = event;
      });
    }
  }
};
</script>
<style scoped>
.footer-wrapper {
  height: 3em;
}
.header-style {
  font-size: 20px;
  font-weight: 500;
}
::v-deep .ivu-modal-footer {
  padding-bottom: 8px !important;
}
::v-deep .ivu-modal {
  margin-top: 20px !important;
}
::v-deep .ivu-table td {
  height: 30px !important;
}
::v-deep .ivu-table-header thead{
    border-bottom: 0px !important;
}
::v-deep .ivu-table-overflowX {
  overflow-x: hidden !important;
}
::v-deep .ivu-table-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
::v-deep .ivu-table th {
  background-color: #f5f5f5 !important;
  height: 33px !important;
}
::v-deep .ivu-table td.read-only-class {
  background-color: #f5f5f5;
}
.overflow-visible {
  overflow: visible !important;
}
::v-deep .btn {
  margin-bottom: 10px !important;
}
</style>
<style>
.ivu-input::-webkit-outer-spin-button,
.ivu-input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
