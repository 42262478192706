const promotionVersionEnums = {
  Draft: 1,
  Enabled: 2
};

const promotionStatus = {
  draft: 1,
  enabled: 2,
  deleted: 3,
  disabled: 4
};

const AssetType = {
  Hq: 1,
  Local: 2
};

export { promotionVersionEnums, promotionStatus, AssetType };
