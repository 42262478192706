import axiosInstance from '../../../Api'
import { searchEngineId, mediaPlanEffectiveStatus } from '../Constants/executeMediaPlan.js'

export class ApiService {
  businessProfileId;
  apiBaseUrl;
  constructor (apiBaseUrl, businessProfileId) {
    this.apiBaseUrl = apiBaseUrl;
    this.businessProfileId = businessProfileId;
  }

  parseResponse (response) {
    return response.data.data;
  }
  async getAccounts () {
    let response = await axiosInstance.get(`${this.apiBaseUrl}businessProfiles/${this.businessProfileId}/roimatic/accounts`)
    return this.parseResponse(response);
  }

  async getRoimaticConfiguration () {
    let response = await axiosInstance.get(`${this.apiBaseUrl}businessProfiles/${this.businessProfileId}/roimatic`);
    return this.parseResponse(response);
  }

  async executeLineItem (lineItemId, businessProfileSearchEngineId, pageId, instagramAccountId) {
    let params = {};
    params.businessProfileSearchEngineId = businessProfileSearchEngineId;
    params.pageId = pageId;
    if (instagramAccountId != '0') {
      params.instagramAccountId = instagramAccountId;
    }
    await axiosInstance.post(`${this.apiBaseUrl}businessProfiles/${this.businessProfileId}/lineItems/${lineItemId}/execute`, params);
  }

  async activateFacebookCampaigns (campaignIds) {
    await axiosInstance.post(`/App/Facebook/Campaign/SocialBulkChangeStatus/${this.businessProfileId}`, {
      "lstGridFilter": [],
      "lstCampaignIds": campaignIds,
      "isCheckAll": false,
      "status": 1, // active
      "absoluteChange": false,
      "action": 0,
      "schInfo": new Object(),
      "rbeInfo": new Object(),
      "sourceType": null
    });
  }

  async activateAdwordsCampaigns (campaignIds) {
    await axiosInstance.post(`/App/Search/Campaign/SearchChangeStatus/${this.businessProfileId}`, {
      "selectedCampaignIds": campaignIds,
      "status": 1, // active
      "searchEngineId": searchEngineId.Adwords
    });
  }

  async updateEntityStatus (mediaPlanId, lineItemId) {
    await axiosInstance.post(`${this.apiBaseUrl}businessProfiles/${this.businessProfileId}/lineItems/${lineItemId}/activate`, mediaPlanId);
  }

  async getProductsCount (productCatalogId) {
    var response = await axiosInstance.get(`${this.apiBaseUrl}businessProfiles/${this.businessProfileId}/productcatalogs/${productCatalogId}/GetFacebookProductsCount`);
    return this.parseResponse(response);
  }
  async updateProductFeedStatus () {
    var patch = [{
      op: 'replace',
      path: '/productFeed/isFacebookSetupComplete',
      value: true
    }];
    await axiosInstance.patch(`${this.apiBaseUrl}businessProfiles/${this.businessProfileId}/roimatic`, patch)
  }
  async getBusinessProfileSearchEngines () {
    var response = await axiosInstance.get(`${this.apiBaseUrl}businessProfiles/${this.businessProfileId}/accounts`);
    return this.parseResponse(response);
  }
  async addMediaPlanChargeTasks (mediaPlanId, businessProfileSearchEngineId) {
    await axiosInstance.post(`${this.apiBaseUrl}businessProfiles/${this.businessProfileId}/roimatic/addMediaPlanForCharge/`, { businessProfileSearchEngineId, mediaPlanId });
  }

  async activateLineItem (mediaPlanId, lineItemId) {
    await axiosInstance.post(`${this.apiBaseUrl}businessProfiles/${this.businessProfileId}/mediaPlans/${mediaPlanId}/lineItems/activateChildEntities`, { lineItemIds: [ lineItemId ] });
  }
}
