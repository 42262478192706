<template>
  <div>
    <button
      @click="showModal = true"
      class="btn btn-success btn-sm widget-element pull-right"
    >
      <i class="fa fa-plus"></i>
    </button>
    <Modal
      width=99
      class="target-modal"
      title="Create Target"
      v-model="showModal"
      effect="fade"
      :scrollable="true"
    >

      <div class="modal-body targets-body" style="height: 80vh;">
        <Row>
          <Row-Col span="16">
            <Form
              :label-width="110"
              :model="targetForm"
              :rules="validationRules"
              ref="creationForm"
            >
              <br />
              <Row>
                <Row-Col span="9">
                  <Form-Item label="Account:" prop="bpseId">
                    <Select
                      :transfer="true"
                      v-model="targetForm.bpseId"
                      style="width:210px"
                      placeholder="Select an account"
                      filterable
                    >
                      <Option
                        v-for="account in dbmAccounts"
                        :value="account.id"
                        :key="account.id"
                        :disabled="account.isOauthExpired"
                        >{{ account.accountNickName }} ({{
                          account.accountId
                        }})</Option
                      >
                    </Select>
                  </Form-Item>
                </Row-Col>
                <Row-Col span="15">
                  <!-- <Button class="float-right" @click="generateAudience">
                    Generate Audiences
                  </Button> -->
                  <Button class="float-right" @click="clearAll"
                    >Clear all</Button
                  >
                </Row-Col>
              </Row>
              <br />

              <template v-if="targetForm.bpseId">
                <Form-Item label="Name:" prop="targetName" class="target-name">
                  <Row>
                    <Row-Col span="15">
                      <i-input v-model="targetForm.targetName" />
                    </Row-Col>
                  </Row>
                  <Row>
                    <Row-Col span="15">
                      <ul class="macro-list">
                        <li class="macros">Available Macros:</li>
                        <li
                          v-for="macroName in targetMacros"
                          :key="macroName"
                          class="pointer"
                        >
                          <a class="macros" @click="addMacro(macroName)">
                            {{ macroName }}
                          </a>
                        </li>
                      </ul>
                    </Row-Col>
                  </Row>
                </Form-Item>

                <Form-Item label="Ad Type:" prop="adType" class="target-name">
                  <Row>
                    <Row-Col span="15">
                      <RadioGroup v-model="targetForm.adType">
                        <Radio label="1">Display, Video</Radio>
                        <Radio label="4">YouTube</Radio>
                      </RadioGroup>
                    </Row-Col>
                  </Row>
                </Form-Item>

                <!-- Youtube -->
                <template v-if="targetForm.adType == 4">
                  <Form-Item label="Line Items">
                    <Row>
                      <Row-Col span="23">
                        <Collapse value="0">
                          <Panel v-for="(item, index) of ['0']"
                            :key="index"
                            :name="String(index)"
                            hide-arrow>
                            <div slot="content">
                              <Form-Item label="Inventory Sources:" class="dbm-label-required line-items-comp">
                                <Row>
                                  <Row-Col span="20">
                                    <Collapse value="0">
                                      <Panel
                                        v-for="(inventorySource, index) of target.inventorySource"
                                        :key="index"
                                        :name="String(index)"
                                        hide-arrow
                                      >
                                        <div>
                                          <!-- style="min-height: 30px;" -->
                                          <div
                                            v-if="index > 0"
                                            class="pannel-btn"
                                            @click="$delete(target.inventorySource, index)"
                                          >
                                            <i class="fa fa-trash"></i>
                                          </div>

                                          <!-- <div
                                            class="pannel-btn"
                                            @click.stop="addTarget('demographics', index)"
                                          >
                                            <i class="fa fa-plus"></i>
                                          </div> -->

                                          <div
                                            v-for="(value, header, i) in inventorySourceSummary(
                                              index
                                            )"
                                            :key="i"
                                          >
                                            <span>{{ header }}</span
                                            >: &nbsp;
                                            <span>{{ value }}</span>
                                          </div>
                                        </div>

                                        <div slot="content">
                                          <Form :label-width="80">
                                            <Form-Item>
                                              <Row>
                                                <Row-Col span="14" class="push-left">
                                                  <CheckboxGroup
                                                    v-model="target.inventorySource[index]"
                                                  >
                                                    <Checkbox :label="options.inventorySource[0].id" disabled>
                                                      {{ options.inventorySource[0].name }}
                                                    </Checkbox>
                                                    <br />
                                                    <Checkbox :label="options.inventorySource[1].id">
                                                      {{ options.inventorySource[1].name }}
                                                    </Checkbox>
                                                  </CheckboxGroup>
                                                </Row-Col>
                                              </Row>
                                            </Form-Item>
                                          </Form>
                                        </div>
                                      </Panel>
                                    </Collapse>
                                  </Row-Col>
                                </Row>
                              </Form-Item>

                              <Form-Item label="Device:" class="line-items-comp">
                                <Row>
                                  <Row-Col span="20">
                                    <Collapse value="0">
                                      <Panel
                                        v-for="(device, index) of target.ytDevices"
                                        :key="index"
                                        :name="String(index)"
                                        hide-arrow
                                      >
                                        <div>
                                          <!-- style="min-height: 30px;" -->
                                          <div
                                            v-if="index > 0"
                                            class="pannel-btn"
                                            @click="$delete(target.inventorySource, index)"
                                          >
                                            <i class="fa fa-trash"></i>
                                          </div>

                                          <!-- <div
                                            class="pannel-btn"
                                            @click.stop="addTarget('demographics', index)"
                                          >
                                            <i class="fa fa-plus"></i>
                                          </div> -->

                                          <div
                                            v-for="(value, header, i) in inventorySourceSummary(
                                              index
                                            )"
                                            :key="i"
                                          >
                                            <span>{{ header }}</span
                                            >: &nbsp;
                                            <span>{{ value }}</span>
                                          </div>
                                        </div>

                                        <div slot="content">
                                          <Form :label-width="80">
                                            <Form-Item>
                                              <Row v-for="(device, i) in options.ytDevices" :key="i">
                                                <Row-Col span="7">
                                                  <Checkbox v-model="target.ytDevices[index][device.name].selected">
                                                    {{ device.name }}
                                                  </Checkbox>
                                                </Row-Col>
                                                <Row-Col span="3" class="center-text">
                                                  Version:
                                                </Row-Col>
                                                <Row-Col span="5">
                                                  <Select
                                                    :transfer="true"
                                                    filterable
                                                    v-model="target.ytDevices[index][device.name].minVersion"
                                                  >
                                                    <Option value="minimum">Minimum</Option>
                                                    <Option
                                                      v-for="(version, id) in device.versions"
                                                      :key="id"
                                                      :value="version"
                                                      >{{ version }}</Option
                                                    >
                                                  </Select>
                                                </Row-Col>
                                                <Row-Col span="2" class="center-text">
                                                  to
                                                </Row-Col>
                                                <Row-Col span="5">
                                                  <Select
                                                    :transfer="true"
                                                    filterable
                                                    v-model="target.ytDevices[index][device.name].maxVersion"
                                                  >
                                                    <Option
                                                      v-for="(version, id) in device.versions"
                                                      :key="id"
                                                      :value="version"
                                                      >{{ version }}</Option
                                                    >
                                                    <Option value="maximum">Maximum</Option>
                                                  </Select>
                                                </Row-Col>
                                              </Row>
                                            </Form-Item>
                                          </Form>
                                            <div class="space"></div>
                                          <Form :label-width="80">
                                            <Form-Item label="Model:">
                                              <Row>
                                                <Row-Col span="14" class="push-left">
                                                  <Select
                                                    :transfer="true"
                                                    v-model="
                                                      target.ytDevices[index].models
                                                    "
                                                    multiple
                                                  >
                                                    <Option
                                                      v-for="(model, i) in options.deviceModels"
                                                      :key="i"
                                                      :value="model.id"
                                                      >{{ model.name }}</Option
                                                    >
                                                  </Select>
                                                </Row-Col>
                                              </Row>
                                            </Form-Item>
                                          </Form>
                                        </div>
                                      </Panel>
                                    </Collapse>
                                  </Row-Col>
                                </Row>
                              </Form-Item>

                              <Form-Item label="Location & Language:" class="line-items-comp">
                                <Row>
                                  <Row-Col span="20">
                                    <Collapse value="0">
                                      <Panel
                                        v-for="(locAndLang, index) of target.locAndLang"
                                        :key="index"
                                        :name="String(index)"
                                        hide-arrow
                                      >
                                        <div>
                                          <div
                                            v-if="index > 0"
                                            class="pannel-btn"
                                            @click="$delete(target.locAndLang, index)"
                                          >
                                            <i class="fa fa-trash"></i>
                                          </div>

                                          <div
                                            v-for="(value, header, i) in locAndLangSummary(
                                              index
                                            )"
                                            :key="i"
                                          >
                                            <span>{{ header }}</span
                                            >: &nbsp;
                                            <span>{{ value }}</span>
                                          </div>
                                        </div>

                                        <div slot="content">
                                          <Form :label-width="80">
                                            <Form-Item label="Location:">
                                              <Row>
                                                <Row-Col span="14" class="push-left">
                                                  <div>
                                                    <span v-if="targetForm.adType == 4" class="dbm-required"></span>
                                                    Include:
                                                    <Select
                                                      :transfer="true"
                                                      v-model="
                                                        target.locAndLang[index]
                                                          .includedLocations
                                                      "
                                                      placeholder="Search"
                                                      @on-change="updateLocationsLookup"
                                                      filterable
                                                      multiple
                                                      :remote-method="searchLocations"
                                                      :loading="
                                                        isFetchingOptions['locations']
                                                      "
                                                    >
                                                      <Option
                                                        v-for="(name, id) in options.locations"
                                                        :key="id"
                                                        :value="id"
                                                        >{{ name }}</Option
                                                      >
                                                    </Select>
                                                  </div>
                                                  <div>
                                                    Exclude:
                                                    <Select
                                                      :transfer="true"
                                                      v-model="
                                                        target.locAndLang[index]
                                                          .excludedLocations
                                                      "
                                                      placeholder="Search"
                                                      @on-change="updateLocationsLookup"
                                                      filterable
                                                      multiple
                                                      :remote-method="searchLocations"
                                                      :loading="
                                                        isFetchingOptions['locations']
                                                      "
                                                    >
                                                      <Option
                                                        v-for="(name, id) in options.locations"
                                                        :key="id"
                                                        :value="id"
                                                        >{{ name }}</Option
                                                      >
                                                    </Select>
                                                  </div>
                                                </Row-Col></Row
                                              >
                                            </Form-Item>
                                            <div class="space"></div>
                                            <Form-Item label="Language:">
                                              <Row>
                                                <Row-Col span="14" class="push-left">
                                                  <div>
                                                    <RadioGroup
                                                      v-model="
                                                        target.locAndLang[index].languageType
                                                      "
                                                    >
                                                      <Radio label="1">Include</Radio>
                                                      <Radio label="2" :disabled="targetForm.adType == 4">Exclude</Radio>
                                                    </RadioGroup>
                                                  </div>
                                                  <div>
                                                    <Select
                                                      :transfer="true"
                                                      v-model="
                                                        target.locAndLang[index].languages
                                                      "
                                                      multiple
                                                      filterable
                                                    >
                                                      <Option
                                                        v-for="(lang, i) in options.languages"
                                                        :key="i"
                                                        :value="lang.id"
                                                        >{{ lang.name }}</Option
                                                      >
                                                    </Select>
                                                  </div>
                                                </Row-Col></Row
                                              >
                                            </Form-Item>
                                          </Form>
                                        </div>
                                      </Panel>
                                    </Collapse>
                                  </Row-Col>
                                </Row>
                              </Form-Item>

                              <Form-Item label="Keywords:" class="line-items-comp">
                                <Row>
                                  <Row-Col span="20">
                                    <Collapse value="0">
                                      <Panel
                                        v-for="(keywords, index) of target.keywords"
                                        :key="index"
                                        :name="String(index)"
                                        hide-arrow
                                      >
                                        <div>
                                          <div
                                            v-if="index > 0"
                                            class="pannel-btn"
                                            @click="$delete(target.keywords, index)"
                                          >
                                            <i class="fa fa-trash"></i>
                                          </div>

                                          <div
                                            v-for="(value, header, i) in keywordsSummary(
                                              index
                                            )"
                                            :key="i"
                                          >
                                            <span>{{ header }}</span
                                            >: &nbsp;
                                            <span>{{ value }}</span>
                                          </div>
                                        </div>

                                        <div slot="content">
                                          <Form :label-width="80">
                                            <Form-Item label="Included keywords:">
                                              <Row>
                                                <Row-Col span="14" class="push-left">
                                                  <i-input
                                                    v-model="
                                                      target.keywords[index].includedKeywords
                                                    "
                                                  ></i-input>
                                                </Row-Col>
                                              </Row>
                                            </Form-Item>
                                            <Form-Item label="Excluded keywords:">
                                              <Row>
                                                <Row-Col span="14" class="push-left">
                                                  <i-input
                                                    v-model="
                                                      target.keywords[index].excludedKeywords
                                                    "
                                                  ></i-input>
                                                </Row-Col>
                                              </Row>
                                            </Form-Item>
                                          </Form>
                                        </div>
                                      </Panel>
                                    </Collapse>
                                  </Row-Col>
                                </Row>
                              </Form-Item>

                              <!-- adgroups section -->
                              <Form-Item label="Adgroups" v-for="(adGroup, adGroupIndex) in adGroups" :key="adGroup.id" style="margin-bottom:8px">
                                <Row>
                                  <Row-Col span="20">
                                    <Collapse value="'adgroup'">
                                      <Panel
                                        name="'adgroup'"
                                        hide-arrow>
                                        <div>
                                          <Row>
                                            <Row-Col span="18">
                                              <span v-if="adGroups[adGroupIndex].editNameEnabled">
                                                <span @click.stop>
                                                  <i-input v-model="adGroups[adGroupIndex].name" class="adgroup-name-input"></i-input>
                                                </span>
                                                <i class="fa fa-check title-icons icons" @click.stop="saveAdGroupName(adGroupIndex)"></i>
                                              </span>
                                              <span v-else>
                                                {{ adGroup.name }}
                                                <i class="fa fa-pencil title-icons icons" @click.stop="editAdGroupName(adGroupIndex)"></i>
                                              </span>
                                            </Row-Col>
                                            <Row-Col span="6">
                                                <i class="fa fa-plus title-icons icons"
                                                @click.stop="addAdGroup()"></i>
                                                <i class="fa fa-copy title-icons icons"
                                                @click.stop="cloneAdGroup(adGroupIndex)"></i>
                                                <i class="fa fa-trash title-icons icons"
                                                @click.stop="deleteAdGroup(adGroupIndex)"></i>
                                            </Row-Col>
                                          </Row>
                                        </div>
                                        <div slot="content">
                                          <Form-Item label="Demographics:" class="dbm-label-required line-items-comp">
                                            <Row>
                                              <Row-Col span="20">
                                                <Collapse value="'demographics'">
                                                  <Panel
                                                    name="'demographics'"
                                                    hide-arrow
                                                  >
                                                    <div>
                                                      <div
                                                        v-for="(value, header, i) in adGroupDemographicSummary(
                                                          adGroupIndex
                                                        )"
                                                        :key="i"
                                                      >
                                                        <span>{{ header }}</span
                                                        >: &nbsp;
                                                        <span>{{ value }}</span>
                                                      </div>
                                                    </div>

                                                    <div slot="content">
                                                      <Form :label-width="80">
                                                        <Form-Item label="Gender:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <Select
                                                                :transfer="true"
                                                                v-model="adGroups[adGroupIndex].demographics[0].gender"
                                                                multiple
                                                              >
                                                                <Option
                                                                  v-for="(gender, i) in options.gender"
                                                                  :key="i"
                                                                  :value="gender.id"
                                                                  >{{ gender.name }}</Option
                                                                >
                                                              </Select>
                                                            </Row-Col></Row
                                                          >
                                                        </Form-Item>
                                                        <div class="space"></div>
                                                        <Form-Item label="Age:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <Select
                                                                :transfer="true"
                                                                v-model="adGroups[adGroupIndex].demographics[0].age"
                                                                multiple
                                                              >
                                                                <Option
                                                                  v-for="(age, i) in options.age"
                                                                  :key="i"
                                                                  :value="age.id"
                                                                  >{{ age.name }}</Option
                                                                >
                                                              </Select>
                                                            </Row-Col></Row
                                                          >
                                                        </Form-Item>
                                                        <div class="space"></div>
                                                        <Form-Item label="Parental status:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <Select
                                                                :transfer="true"
                                                                v-model="adGroups[adGroupIndex].demographics[0].parentalStatus"
                                                                multiple
                                                              >
                                                                <Option
                                                                  v-for="(parent, i) in options.parent"
                                                                  :key="i"
                                                                  :value="parent.id"
                                                                  >{{ parent.name }}</Option
                                                                >
                                                              </Select>
                                                            </Row-Col></Row
                                                          >
                                                        </Form-Item>
                                                        <div class="space"></div>
                                                        <Form-Item label="Household income:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <Select
                                                                :transfer="true"
                                                                v-model="adGroups[adGroupIndex].demographics[0].income"
                                                                multiple
                                                              >
                                                                <Option
                                                                  v-for="(income, i) in options.income"
                                                                  :key="i"
                                                                  :value="income.id"
                                                                  >{{ income.name }}</Option
                                                                >
                                                              </Select>
                                                            </Row-Col></Row
                                                          >
                                                        </Form-Item>
                                                      </Form>
                                                    </div>
                                                  </Panel>
                                                </Collapse>
                                              </Row-Col>
                                            </Row>
                                          </Form-Item>

                                          <Form-Item label="Placements:" class="line-items-comp">
                                            <Row>
                                              <Row-Col span="20">
                                                <Collapse value="'0'">
                                                  <Panel
                                                    key="'0'"
                                                    name="'0'"
                                                    hide-arrow
                                                  >
                                                    <div>
                                                      <div
                                                        v-for="(value, header, i) in adGroupPlacementSummary(adGroupIndex)"
                                                        :key="i"
                                                      >
                                                        <span>{{ header }}</span
                                                        >: &nbsp;
                                                        <span>{{ value }}</span>
                                                      </div>
                                                    </div>

                                                    <div slot="content">
                                                      <Form :label-width="80">
                                                        <Form-Item label="Included YouTube channels:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <div>
                                                                <i-input
                                                                  v-model="adGroups[adGroupIndex].placements[0].includedYtChannels"
                                                                />
                                                              </div> </Row-Col
                                                          ></Row>
                                                        </Form-Item>
                                                        <Form-Item label="Excluded YouTube channels:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <div>
                                                                <i-input
                                                                  v-model="adGroups[adGroupIndex].placements[0].excludedYtChannels"
                                                                />
                                                              </div> </Row-Col
                                                          ></Row>
                                                        </Form-Item>

                                                        <Form-Item label="Included YouTube videos:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <div>
                                                                <i-input
                                                                  v-model="adGroups[adGroupIndex].placements[0].includedYtVideos"
                                                                />
                                                              </div> </Row-Col
                                                          ></Row>
                                                        </Form-Item>
                                                        <Form-Item label="Excluded YouTube videos:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <div>
                                                                <i-input
                                                                  v-model="adGroups[adGroupIndex].placements[0].excludedYtVideos"
                                                                />
                                                              </div> </Row-Col
                                                          ></Row>
                                                        </Form-Item>

                                                        <Form-Item label="Included Apps:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <div>
                                                                <i-input
                                                                  v-model="adGroups[adGroupIndex].placements[0].includedApps"
                                                                />
                                                              </div> </Row-Col
                                                          ></Row>
                                                        </Form-Item>
                                                        <Form-Item label="Excluded Apps:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <div>
                                                                <i-input
                                                                  v-model="adGroups[adGroupIndex].placements[0].excludedApps"
                                                                />
                                                              </div> </Row-Col
                                                          ></Row>
                                                        </Form-Item>
                                                      </Form>
                                                    </div>
                                                  </Panel>
                                                </Collapse>
                                              </Row-Col>
                                            </Row>
                                          </Form-Item>

                                          <Form-Item label="Keywords:" class="line-items-comp">
                                            <Row>
                                              <Row-Col span="20">
                                                <Collapse value="'keywords'">
                                                  <Panel
                                                    name="'keywords'"
                                                    hide-arrow
                                                  >
                                                    <div>

                                                      <div
                                                        v-for="(value, header, i) in adGroupKeywordsSummary(adGroupIndex)"
                                                        :key="i"
                                                      >
                                                        <span>{{ header }}</span
                                                        >: &nbsp;
                                                        <span>{{ value }}</span>
                                                      </div>
                                                    </div>

                                                    <div slot="content">
                                                      <Form :label-width="80">
                                                        <Form-Item label="Included keywords:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <i-input
                                                                v-model="adGroups[adGroupIndex].keywords[0].includedKeywords"
                                                              ></i-input>
                                                            </Row-Col>
                                                          </Row>
                                                        </Form-Item>
                                                        <Form-Item label="Excluded keywords:">
                                                          <Row>
                                                            <Row-Col span="18" class="push-left">
                                                              <i-input
                                                                v-model="adGroups[adGroupIndex].keywords[0].excludedKeywords"
                                                              ></i-input>
                                                            </Row-Col>
                                                          </Row>
                                                        </Form-Item>
                                                      </Form>
                                                    </div>
                                                  </Panel>
                                                </Collapse>
                                              </Row-Col>
                                            </Row>
                                          </Form-Item>

                                          <Form-Item label="Audience:" class="line-items-comp">
                                            <Row>
                                              <Row-Col span="20">
                                                <Collapse value="'audience'">
                                                  <Panel
                                                    name="'audience'"
                                                    hide-arrow
                                                  >
                                                    <div>
                                                      <div
                                                        v-for="(value, header, i) in adGroupAudienceSummary(adGroupIndex)"
                                                        :key="i"
                                                      >
                                                        <span>{{ header }}</span
                                                        >: &nbsp;
                                                        <span>{{ value }}</span>
                                                      </div>
                                                    </div>

                                                    <div slot="content">
                                                      <Form :label-width="80">
                                                        <Form-Item label="Include audience:">
                                                          <Row>
                                                            <Row-Col span="8" class="push-left">
                                                              <Select
                                                                :transfer="true"
                                                                v-model="adGroups[adGroupIndex].audience[0].include.type"
                                                                @on-change="resetAdGroupIncludeAudienceList(adGroupIndex)"
                                                              >
                                                                <Option
                                                                  v-for="(type, i) in options.includeAudienceType"
                                                                  :key="i"
                                                                  :value="type.id"
                                                                  >{{ type.name }}</Option
                                                                >
                                                              </Select>
                                                            </Row-Col>
                                                            <Row-Col offset="1" span="10">
                                                              <Select
                                                                :transfer="true"
                                                                v-model="adGroups[adGroupIndex].audience[0].include.list"
                                                                multiple
                                                                filterable
                                                                :remote-method="(query)=>searchAudiences(query, adGroups[adGroupIndex].audience[0].include.type)"
                                                                :loading="audienceLoading"
                                                              >
                                                                <Option
                                                                  v-for="(type, i) in getAudienceOptions(adGroups[adGroupIndex].audience[0].include.type)"
                                                                  :key="i"
                                                                  :value="type.id"
                                                                  >{{ type.name }}<span class="badge" style="float:right;">{{getAudienceType(type.type)}}</span></Option
                                                                >
                                                              </Select>
                                                            </Row-Col>
                                                          </Row>
                                                        </Form-Item>
                                                        <div class="space"></div>
                                                        <Form-Item label="Exclude audience:">
                                                          <Row>
                                                            <Row-Col span="8" class="push-left">
                                                              <Select
                                                                :transfer="true"
                                                                v-model="adGroups[adGroupIndex].audience[0].exclude.type"
                                                                @on-change="resetAdGroupExcludeAudienceList(adGroupIndex)"
                                                              >
                                                                <Option
                                                                  v-for="(loc, i) in options.excludeAudienceType"
                                                                  :key="i"
                                                                  :value="loc.id"
                                                                  >{{ loc.name }}</Option
                                                                >
                                                              </Select>
                                                            </Row-Col>
                                                            <Row-Col offset="1" span="10">
                                                              <Select
                                                                :transfer="true"
                                                                v-model="adGroups[adGroupIndex].audience[0].exclude.list"
                                                                multiple
                                                                filterable
                                                                :remote-method="(query)=>searchAudiences(query, adGroups[adGroupIndex].audience[0].exclude.type)"
                                                                :loading="audienceLoading"
                                                              >
                                                                <Option
                                                                  v-for="(type, i) in getAudienceOptions(adGroups[adGroupIndex].audience[0].exclude.type)"
                                                                  :key="i"
                                                                  :value="type.id"
                                                                  >{{ type.name }}<span class="badge" style="float:right;">{{getAudienceType(type.type)}}</span></Option
                                                                >
                                                              </Select>
                                                            </Row-Col>
                                                          </Row>
                                                        </Form-Item>
                                                      </Form>
                                                    </div>
                                                  </Panel>
                                                </Collapse>
                                              </Row-Col>
                                            </Row>
                                          </Form-Item>
                                        </div>
                                      </Panel>
                                    </Collapse>
                                  </Row-Col>
                                </Row>
                              </Form-Item>
                            </div>
                          </Panel>
                        </Collapse>
                      </Row-Col>
                    </Row>
                  </Form-Item>
                </template>

                <!-- Display -->
                <template v-if="targetForm.adType == 1">
                  <Form-Item label="Demographics:">
                    <Row>
                      <Row-Col span="20">
                        <Collapse value="0">
                          <Panel
                            v-for="(demographic, index) of target.demographics"
                            :key="index"
                            :name="String(index)"
                            hide-arrow
                          >
                            <div>
                              <!-- style="min-height: 30px;" -->
                              <div
                                v-if="index > 0"
                                class="pannel-btn"
                                @click="$delete(target.demographics, index)"
                              >
                                <i class="fa fa-trash"></i>
                              </div>

                              <!-- <div
                                class="pannel-btn"
                                @click.stop="addTarget('demographics', index)"
                              >
                                <i class="fa fa-plus"></i>
                              </div> -->

                              <div
                                v-for="(value, header, i) in demographicSummary(
                                  index
                                )"
                                :key="i"
                              >
                                <span>{{ header }}</span
                                >: &nbsp;
                                <span>{{ value }}</span>
                              </div>
                            </div>

                            <div slot="content">
                              <Form :label-width="80">
                                <Form-Item label="Gender:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.demographics[index].gender
                                        "
                                        multiple
                                      >
                                        <Option
                                          v-for="(gender, i) in options.gender"
                                          :key="i"
                                          :value="gender.id"
                                          >{{ gender.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                                <div class="space"></div>
                                <Form-Item label="Age:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="target.demographics[index].age"
                                        multiple
                                      >
                                        <Option
                                          v-for="(age, i) in options.age"
                                          :key="i"
                                          :value="age.id"
                                          >{{ age.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                                <div class="space"></div>
                                <Form-Item label="Parental status:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.demographics[index]
                                            .parentalStatus
                                        "
                                        multiple
                                      >
                                        <Option
                                          v-for="(parent, i) in options.parent"
                                          :key="i"
                                          :value="parent.id"
                                          >{{ parent.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                                <div class="space"></div>
                                <Form-Item label="Household income:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.demographics[index].income
                                        "
                                        multiple
                                      >
                                        <Option
                                          v-for="(income, i) in options.income"
                                          :key="i"
                                          :value="income.id"
                                          >{{ income.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                              </Form>
                            </div>
                          </Panel>
                        </Collapse>
                      </Row-Col>
                    </Row>
                  </Form-Item>

                  <Form-Item label="Location & Language:">
                    <Row>
                      <Row-Col span="20">
                        <Collapse value="0">
                          <Panel
                            v-for="(locAndLang, index) of target.locAndLang"
                            :key="index"
                            :name="String(index)"
                            hide-arrow
                          >
                            <div>
                              <div
                                v-if="index > 0"
                                class="pannel-btn"
                                @click="$delete(target.locAndLang, index)"
                              >
                                <i class="fa fa-trash"></i>
                              </div>

                              <div
                                v-for="(value, header, i) in locAndLangSummary(
                                  index
                                )"
                                :key="i"
                              >
                                <span>{{ header }}</span
                                >: &nbsp;
                                <span>{{ value }}</span>
                              </div>
                            </div>

                            <div slot="content">
                              <Form :label-width="80">
                                <Form-Item label="Location:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <span v-if="targetForm.adType == 4" class="dbm-required"></span>
                                        Include:
                                        <Select
                                          :transfer="true"
                                          v-model="
                                            target.locAndLang[index]
                                              .includedLocations
                                          "
                                          placeholder="Search"
                                          @on-change="updateLocationsLookup"
                                          filterable
                                          multiple
                                          :remote-method="searchLocations"
                                          :loading="
                                            isFetchingOptions['locations']
                                          "
                                        >
                                          <Option
                                            v-for="(name, id) in options.locations"
                                            :key="id"
                                            :value="id"
                                            >{{ name }}</Option
                                          >
                                        </Select>
                                      </div>
                                      <div>
                                        Exclude:
                                        <Select
                                          :transfer="true"
                                          v-model="
                                            target.locAndLang[index]
                                              .excludedLocations
                                          "
                                          placeholder="Search"
                                          @on-change="updateLocationsLookup"
                                          filterable
                                          multiple
                                          :remote-method="searchLocations"
                                          :loading="
                                            isFetchingOptions['locations']
                                          "
                                        >
                                          <Option
                                            v-for="(name, id) in options.locations"
                                            :key="id"
                                            :value="id"
                                            >{{ name }}</Option
                                          >
                                        </Select>
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                                <div class="space"></div>
                                <Form-Item label="Language:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <RadioGroup
                                          v-model="
                                            target.locAndLang[index].languageType
                                          "
                                        >
                                          <Radio label="1">Include</Radio>
                                          <Radio label="2" :disabled="targetForm.adType == 4">Exclude</Radio>
                                        </RadioGroup>
                                      </div>
                                      <div>
                                        <Select
                                          :transfer="true"
                                          v-model="
                                            target.locAndLang[index].languages
                                          "
                                          multiple
                                          filterable
                                        >
                                          <Option
                                            v-for="(lang, i) in options.languages"
                                            :key="i"
                                            :value="lang.id"
                                            >{{ lang.name }}</Option
                                          >
                                        </Select>
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                              </Form>
                            </div>
                          </Panel>
                        </Collapse>
                      </Row-Col>
                    </Row>
                  </Form-Item>

                  <Form-Item label="Brand Safety:">
                    <Row>
                      <Row-Col span="20">
                        <Collapse value="0">
                          <Panel
                            v-for="(brandSafety, index) of target.brandSafety"
                            :key="index"
                            :name="String(index)"
                            hide-arrow
                          >
                            <div>
                              <div
                                v-if="index > 0"
                                class="pannel-btn"
                                @click="$delete(target.brandSafety, index)"
                              >
                                <i class="fa fa-trash"></i>
                              </div>

                              <div
                                v-for="(value, header, i) in brandSafetySummary(
                                  index
                                )"
                                :key="i"
                              >
                                <span>{{ header }}</span
                                >: &nbsp;
                                <span>{{ value }}</span>
                              </div>
                            </div>

                            <div slot="content">
                              <Form :label-width="130">
                                <Form-Item
                                  label="Digital Content Label exclusions:"
                                >
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.brandSafety[index]
                                            .digitalContentLabelExclusions
                                        "
                                        @on-change="(newSelection) => handleDigitalContentLabelExclusionChange(index, newSelection)"
                                        multiple
                                      >
                                        <Option
                                          v-for="(option,
                                          i) in options.digitalContentLabelExclusions"
                                          :key="i"
                                          :value="option.id"
                                          :disabled="isDisabled(index, option.id)"
                                          >{{ option.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                                <div class="space"></div>
                                <Form-Item label="Sensitive Category exclusions:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.brandSafety[index]
                                            .sensitiveCategoryExclusions
                                        "
                                        multiple
                                      >
                                        <Option
                                          v-for="(option,
                                          i) in options.sensitiveCategoryExclusions"
                                          :key="i"
                                          :value="option.id"
                                          >{{ option.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                                <Form-Item label="Other Verification Services:" v-if="false">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.brandSafety[index]
                                            .otherVerificationServices
                                        "
                                      >
                                        <Option
                                          v-for="(option,
                                          i) in options.otherVerificationServices"
                                          :key="i"
                                          :value="option.id"
                                          >{{ option.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                              </Form>
                            </div>
                          </Panel>
                        </Collapse>
                      </Row-Col>
                    </Row>
                  </Form-Item>

                  <Form-Item label="Viewability:">
                    <Row>
                      <Row-Col span="20">
                        <Collapse value="0">
                          <Panel
                            v-for="(viewability, index) of target.viewability"
                            :key="index"
                            :name="String(index)"
                            hide-arrow
                          >
                            <div>
                              <div
                                v-if="index > 0"
                                class="pannel-btn"
                                @click="$delete(target.viewability, index)"
                              >
                                <i class="fa fa-trash"></i>
                              </div>

                              <div
                                v-for="(value, header, i) in viewabilitySummary(
                                  index
                                )"
                                :key="i"
                              >
                                <span>{{ header }}</span
                                >: &nbsp;
                                <span>{{ value }}</span>
                              </div>
                            </div>

                            <div slot="content">
                              <Form :label-width="80">
                                <Form-Item label="Active View:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.viewability[index].activeView
                                        "
                                        clearable
                                      >
                                        <Option
                                          v-for="(option,
                                          i) in options.viewability"
                                          :key="i"
                                          :value="option.id"
                                          >{{ option.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                              </Form>
                            </div>
                          </Panel>
                        </Collapse>
                      </Row-Col>
                    </Row>
                  </Form-Item>

                  <Form-Item label="Environment:">
                    <Row>
                      <Row-Col span="20">
                        <Collapse value="0">
                          <Panel
                            v-for="(environment, index) of target.environment"
                            :key="index"
                            :name="String(index)"
                            hide-arrow
                          >
                            <div>
                              <div
                                v-if="index > 0"
                                class="pannel-btn"
                                @click="$delete(target.environment, index)"
                              >
                                <i class="fa fa-trash"></i>
                              </div>

                              <div
                                v-for="(value, header, i) in environmentSummary(
                                  index
                                )"
                                :key="i"
                              >
                                <span>{{ header }}</span
                                >: &nbsp;
                                <span>{{ value }}</span>
                              </div>
                            </div>

                            <div slot="content">
                              <Form :label-width="80">
                                <Form-Item label="Environment:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="target.environment[index]"
                                        multiple
                                      >
                                        <Option
                                          v-for="(env, i) in options.environment"
                                          :key="i"
                                          :value="env.id"
                                          >{{ env.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                              </Form>
                            </div>
                          </Panel>
                        </Collapse>
                      </Row-Col>
                    </Row>
                  </Form-Item>

                  <Form-Item label="Keywords:">
                    <Row>
                      <Row-Col span="20">
                        <Collapse value="0">
                          <Panel
                            v-for="(keywords, index) of target.keywords"
                            :key="index"
                            :name="String(index)"
                            hide-arrow
                          >
                            <div>
                              <div
                                v-if="index > 0"
                                class="pannel-btn"
                                @click="$delete(target.keywords, index)"
                              >
                                <i class="fa fa-trash"></i>
                              </div>

                              <div
                                v-for="(value, header, i) in keywordsSummary(
                                  index
                                )"
                                :key="i"
                              >
                                <span>{{ header }}</span
                                >: &nbsp;
                                <span>{{ value }}</span>
                              </div>
                            </div>

                            <div slot="content">
                              <Form :label-width="80">
                                <Form-Item label="Included keywords:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <i-input
                                        v-model="
                                          target.keywords[index].includedKeywords
                                        "
                                      ></i-input>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                                <Form-Item label="Excluded keywords:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <i-input
                                        v-model="
                                          target.keywords[index].excludedKeywords
                                        "
                                      ></i-input>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                              </Form>
                            </div>
                          </Panel>
                        </Collapse>
                      </Row-Col>
                    </Row>
                  </Form-Item>

                  <Form-Item label="Position:">
                    <Row>
                      <Row-Col span="20">
                        <Collapse value="0">
                          <Panel
                            v-for="(positions, index) of target.positions"
                            :key="index"
                            :name="String(index)"
                            hide-arrow
                          >
                            <div>
                              <div
                                v-if="index > 0"
                                class="pannel-btn"
                                @click="$delete(target.positions, index)"
                              >
                                <i class="fa fa-trash"></i>
                              </div>

                              <div
                                v-for="(value, header, i) in positionsSummary(
                                  index
                                )"
                                :key="i"
                              >
                                <span>{{ header }}</span
                                >: &nbsp;
                                <span>{{ value }}</span>
                              </div>
                            </div>

                            <div slot="content">
                              <Form :label-width="80">
                                <Form-Item label="Position on screen:" v-if="false">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="target.positions[index].onScreen"
                                        multiple
                                      >
                                        <Option
                                          v-for="(value, i) in options.positionOnScreen"
                                          :key="i"
                                          :value="value.id"
                                          >{{ value.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                                <Form-Item label="In Context Display:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.positions[index].contextDisplay
                                        "
                                        multiple
                                      >
                                        <Option
                                          v-for="(value,
                                          i) in options.inContextDisplay"
                                          :key="i"
                                          :value="value.id"
                                          >{{ value.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                                <Form-Item label="In Context Native:" v-if="false">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.positions[index].contextNative
                                        "
                                        multiple
                                      >
                                        <Option
                                          v-for="(value,
                                          i) in options.inContextNative"
                                          :key="i"
                                          :value="i"
                                          >{{ value.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                              </Form>
                            </div>
                          </Panel>
                        </Collapse>
                      </Row-Col>
                    </Row>
                  </Form-Item>

                  <Form-Item label="Audience:">
                    <Row>
                      <Row-Col span="20">
                        <Collapse value="0">
                          <Panel
                            v-for="(audience, index) of target.audience"
                            :key="index"
                            :name="String(index)"
                            hide-arrow
                          >
                            <div>
                              <div
                                v-if="index > 0"
                                class="pannel-btn"
                                @click="$delete(target.audience, index)"
                              >
                                <i class="fa fa-trash"></i>
                              </div>

                              <div
                                v-for="(value, header, i) in audienceSummary(
                                  index
                                )"
                                :key="i"
                              >
                                <span>{{ header }}</span
                                >: &nbsp;
                                <span>{{ value }}</span>
                              </div>
                            </div>

                            <div slot="content">
                              <Form :label-width="80">
                                <Form-Item label="Include audience:">
                                  <Row>
                                    <Row-Col span="8" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.audience[index].include.type
                                        "
                                        @on-change="resetIncludeAudienceList(index)"
                                      >
                                        <Option
                                          v-for="(type, i) in options.includeAudienceType"
                                          :key="i"
                                          :value="type.id"
                                          >{{ type.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col>
                                    <Row-Col offset="1" span="10">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.audience[index].include.list
                                        "
                                        multiple
                                        filterable
                                        :remote-method="(query)=>searchAudiences(query, target.audience[index].include.type)"
                                        :loading="audienceLoading"
                                      >
                                        <Option
                                          v-for="(type, i) in getAudienceOptions(target.audience[index].include.type)"
                                          :key="i"
                                          :value="type.id"
                                          >{{ type.name }}<span class="badge" style="float:right;">{{getAudienceType(type.type)}}</span></Option
                                        >
                                      </Select>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                                <div class="space"></div>
                                <Form-Item label="Exclude audience:">
                                  <Row>
                                    <Row-Col span="9" class="push-left">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.audience[index].exclude.type
                                        "
                                        @on-change="resetExcludeAudienceList(index)"
                                      >
                                        <Option
                                          v-for="(loc, i) in options.excludeAudienceType"
                                          :key="i"
                                          :value="loc.id"
                                          >{{ loc.name }}</Option
                                        >
                                      </Select>
                                    </Row-Col>
                                    <Row-Col offset="1" span="10">
                                      <Select
                                        :transfer="true"
                                        v-model="
                                          target.audience[index].exclude.list
                                        "
                                        multiple
                                        filterable
                                        :remote-method="(query)=>searchAudiences(query, target.audience[index].exclude.type)"
                                        :loading="audienceLoading"
                                      >
                                        <Option
                                          v-for="(type, i) in getAudienceOptions(target.audience[index].exclude.type)"
                                          :key="i"
                                          :value="type.id"
                                          >{{ type.name }}<span class="badge" style="float:right;">{{getAudienceType(type.type)}}</span></Option
                                        >
                                      </Select>
                                    </Row-Col>
                                  </Row>
                                </Form-Item>
                              </Form>
                            </div>
                          </Panel>
                        </Collapse>
                      </Row-Col>
                    </Row>
                  </Form-Item>

                  <Form-Item label="Apps & URLs:">
                    <Row>
                      <Row-Col span="20">
                        <Collapse value="0">
                          <Panel
                            v-for="(appsAndUrls, index) of target.appsAndUrls"
                            :key="index"
                            :name="String(index)"
                            hide-arrow
                          >
                            <div>
                              <div
                                v-if="index > 0"
                                class="pannel-btn"
                                @click="$delete(target.appsAndUrls, index)"
                              >
                                <i class="fa fa-trash"></i>
                              </div>

                              <div
                                v-for="(value, header, i) in appsAndUrlsSummary(
                                  index
                                )"
                                :key="i"
                              >
                                <span>{{ header }}</span
                                >: &nbsp;
                                <span>{{ value }}</span>
                              </div>
                            </div>

                            <div slot="content">
                              <Form :label-width="80">
                                <Form-Item label="Included Channels:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <Select
                                          :transfer="true"
                                          v-model="
                                            target.appsAndUrls[index].includedChannels
                                          "
                                          multiple
                                        >
                                          <Option
                                            v-for="(channel, i) in options.channels"
                                            :key="i"
                                            :value="channel.id"
                                            >{{ channel.name }}</Option
                                          >
                                        </Select>
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>

                                <Form-Item label="Excluded Channels:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <Select
                                          :transfer="true"
                                          v-model="
                                            target.appsAndUrls[index].excludedChannels
                                          "
                                          multiple
                                        >
                                          <Option
                                            v-for="(channel, i) in options.channels"
                                            :key="i"
                                            :value="channel.id"
                                            >{{ channel.name }}</Option
                                          >
                                        </Select>
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>

                                <Form-Item label="Included Collections:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <Select
                                          :transfer="true"
                                          v-model="
                                            target.appsAndUrls[index].includedCollections
                                              .value
                                          "
                                          multiple
                                        >
                                          <Option
                                            v-for="(option, i) in options.collections"
                                            :key="i"
                                            :value="option.id"
                                            >{{ option.name }}</Option
                                          >
                                        </Select>
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                                <Form-Item label="Excluded Collections:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <Select
                                          :transfer="true"
                                          v-model="
                                            target.appsAndUrls[index].excludedCollections
                                          "
                                          multiple
                                        >
                                          <Option
                                            v-for="(option, i) in options.collections"
                                            :key="i"
                                            :value="option.id"
                                            >{{ option.name }}</Option
                                          >
                                        </Select>
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>

                                <!-- To-to: allow multiple urls to be inserted -->
                                <Form-Item label="Included URLs:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <i-input
                                          v-model="
                                            target.appsAndUrls[index].includedUrls
                                          "
                                        />
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                                <Form-Item label="Excluded URLs:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <i-input
                                          v-model="
                                            target.appsAndUrls[index].excludedUrls
                                          "
                                        />
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>

                                <!-- To-to: allow multiple apps to be inserted -->
                                <Form-Item label="Included Apps:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <i-input
                                          v-model="
                                            target.appsAndUrls[index].includedApps
                                          "
                                        />
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                                <Form-Item label="Excluded Apps:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <i-input
                                          v-model="
                                            target.appsAndUrls[index].excludedApps
                                          "
                                        />
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                              </Form>
                            </div>
                          </Panel>
                        </Collapse>
                      </Row-Col>
                    </Row>
                  </Form-Item>

                  <Form-Item label="Technology:">
                    <Row>
                      <Row-Col span="20">
                        <Collapse value="0">
                          <Panel
                            v-for="(technology, index) of target.technology"
                            :key="index"
                            :name="String(index)"
                            hide-arrow
                          >
                            <div>
                              <div
                                v-if="index > 0"
                                class="pannel-btn"
                                @click="$delete(target.technology, index)"
                              >
                                <i class="fa fa-trash"></i>
                              </div>

                              <div
                                v-for="(value, header, i) in technologySummary(
                                  index
                                )"
                                :key="i"
                              >
                                <span>{{ header }}</span
                                >: &nbsp;
                                <span>{{ value }}</span>
                              </div>
                            </div>

                            <div slot="content">
                              <Form :label-width="80">
                                <Form-Item label="Device:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <Select
                                          :transfer="true"
                                          v-model="
                                            target.technology[index].devices
                                          "
                                          @on-change="(newSelection) => handleDevicesChange(index, newSelection)"
                                          multiple
                                        >
                                          <Option
                                            v-for="(device, i) in options.devices"
                                            :key="i"
                                            :value="device.id"
                                            >{{ device.name }}</Option
                                          >
                                        </Select>
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>

                                <Form-Item label="Browsers:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <RadioGroup
                                          v-model="
                                            target.technology[index].browser.type
                                          "
                                        >
                                          <Radio label="1">Include</Radio>
                                          <Radio label="2">Exclude</Radio>
                                        </RadioGroup>
                                      </div>
                                      <div>
                                        <Select
                                          :transfer="true"
                                          v-model="
                                            target.technology[index].browser
                                              .values
                                          "
                                          multiple
                                        >
                                          <Option
                                            v-for="(browser, i) in options.browsers"
                                            :key="i"
                                            :value="browser.id"
                                            >{{ browser.name }}</Option
                                          >
                                        </Select>
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                                <div class="space"></div>
                                <Form-Item label="Connection speed:" v-if="false">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <Select
                                          :transfer="true"
                                          v-model="
                                            target.technology[index]
                                              .connectionSpeed
                                          "
                                          multiple
                                        >
                                          <Option value="1">placeholder</Option>
                                        </Select>
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>

                                <Form-Item label="Carrier & ISP:">
                                  <Row>
                                    <Row-Col span="14" class="push-left">
                                      <div>
                                        <RadioGroup
                                          v-model="
                                            target.technology[index].carrierAndISP
                                              .type
                                          "
                                        >
                                          <Radio label="1">Include</Radio>
                                          <Radio label="2">Exclude</Radio>
                                        </RadioGroup>
                                      </div>
                                      <div>
                                        <Select
                                          :transfer="true"
                                          v-model="
                                            target.technology[index].carrierAndISP
                                              .values
                                          "
                                          multiple
                                        >
                                          <Option
                                            v-for="(option, i) in options.carrierAndISP"
                                            :key="i"
                                            :value="option.id"
                                            >{{ option.name }}</Option
                                          >
                                        </Select>
                                      </div>
                                    </Row-Col></Row
                                  >
                                </Form-Item>
                              </Form>
                            </div>
                          </Panel>
                        </Collapse>
                      </Row-Col>
                    </Row>
                  </Form-Item>
                </template>
              </template>
              <template v-else>
                <p style="text-align: center;">
                  Please select a DV360 account to create targets
                </p>
              </template>
            </Form>
          </Row-Col>

          <Row-Col span="8">
            <div class="preivew-wrapper">
              <div class="preview-header">
                <p>New Audience Preview</p>
              </div>
              <template v-if="isFormValid">
                <div class="target-row">
                  <Row class="target-prev-header">
                    <Row-Col span="21">
                      <p>{{ getTargetName(0) }}</p>
                    </Row-Col>
                    <Row-Col span="2" offset="1">
                      <i @click="saveTarget(0)" class="fa fa-save preview-icon">
                      </i>
                    </Row-Col>
                    <!-- <Row-Col span="2">
                      <i
                        class="fa fa-trash preview-icon"
                        @click="removeTargetFromPreview()"
                      />
                    </Row-Col> -->
                  </Row>
                  <Row class="target-prev-body" v-if="targetForm.adType == 1">
                    <div
                      v-for="(summaryObj, targetName, i) in getFullSummary()"
                      :key="i"
                    >
                      <div style="font-weight: bold;">{{ targetName }}:</div>
                      <div
                        v-for="(value, header, i) in summaryObj"
                        :key="i"
                        style="margin-left: 10px;"
                      >
                        <span>{{ header }}</span
                        >: &nbsp;
                        <span>{{ value }}</span>
                      </div>
                    </div>
                  </Row>
                  <Row class="target-prev-body" v-else>
                    <div
                      v-for="(summaryObj, targetName, i) in getLineItemFullSummary()"
                      :key="i"
                    >
                      <div style="font-weight: bold;">{{ targetName }}:</div>
                      <div
                        v-for="(value, header, i) in summaryObj"
                        :key="i"
                        style="margin-left: 10px;"
                      >
                        <span>{{ header }}</span
                        >: &nbsp;
                        <span>{{ value }}</span>
                      </div>
                    </div>
                    <div
                      v-for="(adGroup, adGroupIndex) in adGroups"
                      :key="adGroup.id"
                    >
                    <div style="font-weight: bold; margin-top:4px; text-decoration: underline;">{{ adGroup.name }}</div>
                      <div
                        v-for="(adGroupSummaryObj, targetName, i) in getAdGroupFullSummary(adGroupIndex)"
                        :key="i"
                      >
                      <div style="font-weight: bold;">{{ targetName }}:</div>
                        <div
                          v-for="(value, header, i) in adGroupSummaryObj"
                          :key="i"
                          style="margin-left: 10px;"
                        >
                          <span>{{ header }}</span
                          >: &nbsp;
                          <span>{{ value }}</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </template>
              <template v-else>
                <div class="preview-placeholder">
                  Please enter the required fields to preview new audience
                </div>
              </template>
            </div>
          </Row-Col>
        </Row>
      </div>

    </Modal>
  </div>
</template>

<script>
import { APIService } from "../../ApiService.js";
import LinkedInTargetFacets from "../../Constants/LinkedInTargetFacets.js";
import { mapState, mapMutations } from "vuex";
import {
  Row,
  Col,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  Button,
  Radio,
  RadioGroup,
  Collapse,
  Panel,
  Checkbox,
  CheckboxGroup,
  locale,
  Modal
} from "iview";
import lang from "iview/dist/locale/en-US";
import { EventBus } from "../../EventBus.js";

locale(lang);

export default {
  components: {
    Row,
    "Row-Col": Col,
    Form,
    FormItem,
    Select,
    Option,
    "i-input": Input,
    Button,
    Radio,
    RadioGroup,
    Collapse,
    Panel,
    Checkbox,
    CheckboxGroup,
    Modal
  },
  data () {
    return {
      oldValues: {},
      inclusionCategory: "",
      showModal: false,
      urnNameMap: LinkedInTargetFacets.reduce((dict, facet) => {
        dict[facet.urn] = facet.name;
        return dict;
      }, {}),
      targetMacros: ["Language", "Location", "Gender", "AgeGroup"],
      languages: [],
      langPlaceholder: "Select language",
      targetForm: {
        bpseId: null,
        adType: "1",
        targetName: ""
      },
      validationRules: {
        bpseId: [
          {
            required: true,
            type: "number",
            message: "Please select an account",
            trigger: "change"
          }
        ],
        targetName: [
          { required: true, message: "Please enter a name", trigger: "blur" }
        ]
      },
      isFetchingOptions: {},
      fetchTimeout: {},
      accountId: "",
      locationsLookup: {},
      options: {
        channels: [],
        collections: [], // TARGETING_TYPE_APP_CATEGORY
        deviceModels: [],
        inventorySource: [
          { id: 1, name: "YouTube" },
          { id: 2, name: "Video Partners" }
        ],
        days: [
          { id: 0, name: "Monday" },
          { id: 1, name: "Tuesday" },
          { id: 2, name: "Wednesday" },
          { id: 3, name: "Thursday" },
          { id: 4, name: "Friday" },
          { id: 5, name: "Saturday" },
          { id: 6, name: "Sunday" }
        ],
        ytDevices: [
          { id: 1, name: "Android", versions: ["0.5", "1.0", "1.1", "1.5", "1.6", "2.0", "2.1", "2.2", "2.3", "3.0", "3.1", "3.2", "4.0", "4.1", "4.2", "4.3", "4.4", "5.0", "5.1", "6.0", "7.0", "7.1", "8.0", "8.1", "9.0", "9.1", "10.0", "11.0"] },
          { id: 2, name: "BlackBerry", versions: ["6.0", "7.0"] },
          { id: 3, name: "iOS", versions: ["2.0", "2.1", "2.2", "3.0", "3.1", "3.2", "4.0", "4.1", "4.2", "4.3", "5.0", "5.1", "6.0", "6.1", "7.0", "7.1", "8.0", "8.1", "8.2", "8.3", "8.4", "9.0", "9.1", "9.2", "9.3", "10.0", "10.1", "10.2", "10.3", "11.0", "11.1", "11.2", "11.3", "11.4", "12.0", "12.1", "12.2", "12.3", "12.4", "12.5", "13.0", "13.1", "13.2", "13.3", "13.4", "13.5", "13.6", "13.7", "14.0", "14.1", "14.2", "14.3", "14.4", "14.5"] },
          { id: 4, name: "webOS", versions: ["1.0", "1.1", "1.2", "1.3", "1.4", "2.0", "2.1", "2.2", "2.3", "3.0"] },
          { id: 5, name: "Windows Phone", versions: ["7.0", "8.0", "8.1", "10.0"] }
        ],
        gender: [
          { id: 10, name: "Male" },
          { id: 11, name: "Female" },
          { id: 20, name: "Unknown" }
        ],
        age: [
          { id: 503001, name: "18-24" },
          { id: 503002, name: "25-34" },
          { id: 503003, name: "35-44" },
          { id: 503004, name: "45-54" },
          { id: 503005, name: "55-64" },
          { id: 503006, name: "65+" },
          { id: 503999, name: "Unknown" }
        ],
        parent: [
          { id: 300, name: "Parent" },
          { id: 301, name: "Not a parent" },
          { id: 302, name: "Unknown" }
        ],
        income: [
          { id: 510006, name: "Top 10%" },
          { id: 510005, name: "11-20%" },
          { id: 510004, name: "21-30%" },
          { id: 510003, name: "31-40%" },
          { id: 510002, name: "41-50%" },
          { id: 510001, name: "Lower 50%" },
          { id: 510000, name: "Unknown" }
        ],
        locations: [],
        languages: [],
        includeAudienceType: [
          { id: 1, name: "First and third party" },
          { id: 2, name: "Google Audience" },
          { id: 3, name: "Combined" },
          { id: 4, name: "Custom List" }
        ],
        excludeAudienceType: [
          { id: 1, name: "First and third party" },
          { id: 2, name: "Google Audience" }
        ],
        audience: { // populated in targetForm.bpseId watch
          1: [],
          2: [],
          3: [],
          4: []
        },
        digitalContentLabelExclusions: [
          { id: 19875633960, name: "DL-G: General audiences" },
          { id: 19875634080, name: "DL-PG: Audiences with parental guidance" },
          { id: 19875634200, name: "DL-T: Teens and older audiences" },
          { id: 19875634320, name: "DL-MA: Mature audiences" },
          { id: 19875634440, name: "DL-?: Not yet labeled" }
        ],
        sensitiveCategoryExclusions: [
          { id: 118521027123, name: "Adult" },
          { id: 118521027843, name: "Derogatory" },
          { id: 1163177817, name: "Downloads & sharing" },
          { id: 118521028803, name: "Weapons" },
          { id: 143909494834, name: "Gambling" },
          { id: 118521028563, name: "Violence" },
          { id: 1163177997, name: "Suggestive" },
          { id: 1163178297, name: "Profanity" },
          { id: 118521027603, name: "Alcohol" },
          { id: 118521028083, name: "Drugs" },
          { id: 118521028323, name: "Tobacco" },
          { id: 1163178357, name: "Politics" },
          { id: 289334238373, name: "Religion" },
          { id: 1596254697, name: "Tragedy" },
          { id: 118521027363, name: "Transportation accidents" },
          { id: 777266439799, name: "Shocking" },
          { id: 116505648784, name: "Sensitive social issues" }
        ],
        otherVerificationServices: [],
        environment: [{ id: 508010, name: "Web" }, { id: 508012, name: "App" }],
        devices: [ // TARGETING_TYPE_DEVICE_TYPE
          { id: 30000, name: "Computer" },
          { id: 30001, name: "Smart Phone" },
          { id: 30002, name: "Tablet" },
          { id: 30004, name: "Connected TV" }
        ],
        browsers: [], // TARGETING_TYPE_BROWSER,
        carrierAndISP: [], // TARGETING_TYPE_CARRIER_AND_ISP
        viewability: [
          { name: "90% or greater", id: 509090 },
          { name: "80% or greater", id: 509080 },
          { name: "70% or greater", id: 509070 },
          { name: "60% or greater", id: 509060 },
          { name: "50% or greater", id: 509050 },
          { name: "40% or greater", id: 509040 },
          { name: "30% or greater", id: 509030 },
          { name: "20% or greater", id: 509020 },
          { name: "10% or greater", id: 509010 }
        ],
        positionOnScreen: [ // TARGETING_TYPE_ON_SCREEN_POSITION
          { id: 21, name: "Above the fold" },
          { id: 22, name: "Below the fold" },
          { id: 23, name: "Unknown" }
        ],
        inContextDisplay: [ // TARGETING_TYPE_CONTENT_OUTSTREAM_POSITION
          { id: 509500, name: "Unknown" },
          { id: 509501, name: "In-article" },
          { id: 509502, name: "In-banner" },
          { id: 509503, name: "In-feed" },
          { id: 509504, name: "Interstitial" }
        ],
        inContextNative: [
          { name: "In-article" },
          { name: "In-feed" },
          { name: "Peripheral" },
          { name: "Recommendation" },
          { name: "Unknown" }
        ]
      },
      target: null,
      audienceLoading: false,
      searchTimeout: null,
      searchText: "",
      adGroups: []
    };
  },
  mounted () {
    this.target = this.getDefaultTargetConfig();
    let time = []; let startId = 0;
    for (let hr of ["AM", "PM"]) {
      time.push({ id: startId, name: `12 ${hr}` });
      startId += 4;
      for (let i = 1; i < 12; i++, startId += 4) {
        time.push({ id: startId, name: `${i} ${hr}` });
      }
    }
    this.options.startTime = time;
    this.options.endTime = time.slice(1).concat([{ id: 96, name: "12 AM" }]);
  },
  computed: {
    ...mapState(["dbmAccounts", "savedTargetsMapperByBpseId", "savedTargets"]),
    isFormValid () {
      if (!this.targetForm.targetName) {
        return false;
      }
      if (this.targetForm.adType == 4) {
        let isDemographicValid = true;

        for (const index in this.adGroups) {
          isDemographicValid = this.adGroups[index].demographics[0].gender.length > 0 &&
          this.adGroups[index].demographics[0].age.length > 0 &&
          this.adGroups[index].demographics[0].parentalStatus.length > 0 &&
          this.adGroups[index].demographics[0].income.length > 0;
          if (!isDemographicValid) {
            break;
          }
        }
        let hasIncludedLoc = this.target.locAndLang[0].includedLocations.length > 0;
        let hasInventory = this.target.inventorySource[0].length > 0;

        return isDemographicValid && hasIncludedLoc && hasInventory;
      }
      return Object.keys(this.getFullSummary()).length > 0;
    }
  },
  watch: {
    showModal () {
      if (!this.showModal) {
        this.clearAll();
      }
    },
    "targetForm.bpseId": function (bpseId) {
      if (bpseId) {
        this.accountId = this.dbmAccounts.find(x => x.id == bpseId).accountId;

        const getTargetHelper = (targetingType, nameKey, optionsKey, nextToken = null, nameTransform = null) => {
          APIService.getDV360TargetOptions(bpseId, targetingType, nextToken).then(
            response => {
              let options = response.data.data.targetingOptions.flatMap(option => {
                let name = option[nameKey].displayName;
                if (!name) { return [] }
                if (nameTransform !== null) {
                  name = nameTransform(name);
                }
                return [{
                  id: option.targetingOptionId,
                  name
                }];
              });
              if (nextToken === null) {
                this.$set(this.options, optionsKey, options);
              } else {
                options.push(...this.options[optionsKey]);
                this.$set(this.options, optionsKey, options);
              }

              if (response.data.data.nextPageToken) {
                nextToken = response.data.data.nextPageToken;
              // getTargetHelper(targetingType, nameKey, optionsKey, nextToken, nameTransform);
              }
            }
          )
        }

        getTargetHelper("TARGETING_TYPE_LANGUAGE", "languageDetails", "languages");
        getTargetHelper("TARGETING_TYPE_BROWSER", "browserDetails", "browsers");
        getTargetHelper("TARGETING_TYPE_CARRIER_AND_ISP", "carrierAndIspDetails", "carrierAndISP");

        getTargetHelper("TARGETING_TYPE_DEVICE_MAKE_MODEL", "deviceMakeModelDetails", "deviceModels"); // 3681

        // APIService.getDV360Audience(bpseId, "FirstAndThirdPartyAudiences").then(response => {
        //   let audiences = (response.data.data.FirstAndThirdPartyAudiences || []).map(x => ({
        //     id: x.firstAndThirdPartyAudienceId,
        //     name: x.displayName
        //   }));
        //   this.$set(this.options.audience, 1, audiences);
        // });
        // APIService.getDV360Audience(bpseId, "GoogleAudiences").then(response => {
        //   let audiences = (response.data.data.googleAudiences || []).map(x => ({
        //     id: x.googleAudienceId,
        //     name: x.displayName,
        //     type: x.googleAudienceType
        //   }));
        //   this.$set(this.options.audience, 2, audiences);
        // });
        // APIService.getDV360Audience(bpseId, "CombinedAudiences").then(response => {
        //   let audiences = (response.data.data.combinedAudiences || []).map(x => ({
        //     id: x.combinedAudienceId,
        //     name: x.displayName
        //   }));
        //   this.$set(this.options.audience, 3, audiences);
        // });
        // APIService.getDV360Audience(bpseId, "CustomLists").then(response => {
        //   let audiences = (response.data.data.customLists || []).map(x => ({
        //     id: x.customListId,
        //     name: x.displayName
        //   }));
        //   this.$set(this.options.audience, 4, audiences);
        // });

        APIService.GetChannels(bpseId).then(response => {
          if (response.data.data.channels) {
            let channels = response.data.data.channels;
            this.$set(this.options, "channels", channels.map(channel => {
              return [{ id: channel.channelId, name: channel.displayName }]
            }));
          }
        })
      }
    },
    "targetForm.adType": function () {
      this.target = this.getDefaultTargetConfig();
      if (this.targetForm.adType == 4) {
        if (this.target.inventorySource &&
            this.target.inventorySource[0] &&
            !this.target.inventorySource[0].includes(1)) {
          this.target.inventorySource[0].push(1)
        }
      }
    }
  },
  methods: {
    ...mapMutations(["set_savedTargetsMapperByBpseId", "set_savedTargets"]),
    handleDigitalContentLabelExclusionChange (index, newSelection) {
      if (newSelection.length > 4) {
        this.$set(this.target.brandSafety[index], 'digitalContentLabelExclusions', newSelection.slice(0, 4));
      } else {
        this.$set(this.target.brandSafety[index], 'digitalContentLabelExclusions', newSelection);
      }
    },
    isDisabled (index, optionValue) {
      const selectedItems = this.target.brandSafety[index].digitalContentLabelExclusions;
      return selectedItems.length >= 4 && !selectedItems.includes(optionValue);
    },
    handleDevicesChange (index, newSelection) {
      if (newSelection.length == 0) {
        this.$set(this.target.technology[index], 'devices', this.oldValues["devices"].slice());
      } else if (newSelection.length >= 1) {
        this.$set(this.target.technology[index], 'devices', newSelection);
        this.oldValues["devices"] = newSelection.slice();
      }
    },
    getAudienceOptions (audienceType) {
      // if (this.targetForm.adType == 4 && audienceType == 2) { // Google Audience
      //   return this.options.audience[audienceType].filter(
      //     x => x.type == "GOOGLE_AUDIENCE_TYPE_IN_MARKET"
      //   );
      // }
      return this.options.audience[audienceType];
    },
    updateLocationsLookup (locations) {
      locations.forEach(loc => {
        if (!this.locationsLookup[loc]) {
          this.locationsLookup[loc] = this.options.locations[loc];
        }
      })
    },
    searchLocations (query) {
      this.$set(this.isFetchingOptions, "locations", false);
      clearTimeout(this.fetchTimeout["locations"]);

      query = query.trim();
      if (!query) {
        return;
      }

      let targetingType = "TARGETING_TYPE_GEO_REGION";

      this.$set(this.isFetchingOptions, "locations", true);
      this.fetchTimeout["locations"] = setTimeout(() => {
        APIService.searchDV360TargetOptions(this.targetForm.bpseId, targetingType, {
          advertiserId: this.accountId,
          geoRegionSearchTerms: {
            geoRegionQuery: query
          }
        })
          .then((response) => {
            let optionsObj = {};
            if (response.data.data.targetingOptions) {
              response.data.data.targetingOptions.forEach((option) => {
                optionsObj[option.targetingOptionId] =
                  option.geoRegionDetails.displayName;
              });
            }
            this.$set(this.options, "locations", optionsObj);
          })
          .finally(() => {
            this.$set(this.isFetchingOptions, "locations", false);
          });
      }, 300);
    },
    // locationsExtraInfo(index, ) {},
    getEnumName (optionString, enumId) {
      let res = "";
      let option = this.options;
      optionString.split('.').forEach(key => {
        option = option[key]
      });
      if (option[0].id) {
        res = option.find(x => x.id == enumId).name || "";
      } else {
        res = option[enumId];
      }
      return res;
    },
    inventorySourceSummary (index) {
      let info = this.target.inventorySource[index];
      if (info.length == 0) {
        return {}
      }
      return {
        "Inventory sources": info.map(x => this.getEnumName("inventorySource", x)).join(", ")
      }
    },
    demographicSummary (index) {
      let info = this.target.demographics[index];
      return {
        ...((info.gender || []).length > 0 && {
          Gender: info.gender.map(x => this.getEnumName("gender", x)).join(", ")
        }),
        ...((info.age || []).length > 0 && {
          Age: info.age.map(x => this.getEnumName("age", x)).join(", ")
        }),
        ...((info.parentalStatus || []).length > 0 && {
          "Parental status": info.parentalStatus
            .map(x => this.getEnumName("parent", x))
            .join(", ")
        }),
        ...((info.income || []).length > 0 && {
          Income: info.income.map(x => this.getEnumName("income", x)).join(", ")
        })
      };
    },
    locAndLangSummary (index) {
      let info = this.target.locAndLang[index];
      return {
        ...((info.includedLocations || []).length > 0 && {
          "Included Locations": info.includedLocations
            .map(x => this.locationsLookup[x])
            .join(", ")
        }),
        ...((info.excludedLocations || []).length > 0 && {
          "Excluded Locations": info.excludedLocations
            .map(x => this.locationsLookup[x])
            .join(", ")
        }),
        ...((info.languages || []).length > 0 && {
          [`${info.languageType == "1" ? "Included" : "Excluded"} languages`]: info.languages
            .map(x => this.getEnumName("languages", x))
            .join(", ")
        })
      };
    },
    technologySummary (index) {
      let info = this.target.technology[index];
      let summary = {};
      if (info.devices.length > 0) {
        summary["Devices"] = info.devices.map(x => this.getEnumName("devices", x)).join(", ");
      }
      if (info.browser.values.length > 0) {
        summary[`${info.browser.type == 1 ? 'Included' : 'Excluded'} browsers`] = info.browser.values
          .map(x => this.getEnumName("browsers", x)).join(", ");
      }
      if (info.carrierAndISP.values.length > 0) {
        summary[`${info.carrierAndISP.type == 1 ? 'Included' : 'Excluded'} Carrier & Isp`] = info.carrierAndISP.values
          .map(x => this.getEnumName("carrierAndISP", x)).join(", ");
      }
      return summary;
    },
    audienceSummary (index) {
      let info = this.target.audience[index];
      let summary = {};
      if (info.include.type && info.include.list.length > 0) {
        summary["Include audience type"] = this.getEnumName(
          "includeAudienceType",
          info.include.type
        );
        summary["Audience inclusion"] = info.include.list
          .map(x => this.getEnumName(`audience.${info.include.type}`, x))
          .join(", ");
      }
      if (info.exclude.type && info.exclude.list.length > 0) {
        summary["Exclude audience type"] = this.getEnumName(
          "excludeAudienceType",
          info.exclude.type
        );
        summary["Audience exclusion"] = info.exclude.list
          .map(x => this.getEnumName(`audience.${info.exclude.type}`, x))
          .join(", ");
      }
      return summary;
    },
    brandSafetySummary (index) {
      let info = this.target.brandSafety[index];
      let summary = {};
      if (info.digitalContentLabelExclusions.length > 0) {
        summary["Digital Content Label exclusions"] = info.digitalContentLabelExclusions
          .map(x => this.getEnumName("digitalContentLabelExclusions", x))
          .join(", ")
      }
      if (info.sensitiveCategoryExclusions.length > 0) {
        summary["Sensitive Category exclusions"] = info.sensitiveCategoryExclusions
          .map(x => this.getEnumName("sensitiveCategoryExclusions", x))
          .join(", ")
      }
      if (info.otherVerificationServices.length > 0) {
        summary["Other Verification Services"] = info.otherVerificationServices
          .map(x => this.getEnumName("otherVerificationServices", x))
          .join(", ")
      }
      return summary;
    },
    appsAndUrlsSummary (index) {
      let info = this.target.appsAndUrls[index];
      let summary = {};
      if (info.includedChannels.length > 0) {
        summary["Included channels"] = info.includedChannels
          .map(x => this.getEnumName("channels", x))
          .join(", ");
      }
      if (info.excludedChannels.length > 0) {
        summary["Excluded channels"] = info.excludedChannels
          .map(x => this.getEnumName("channels", x))
          .join(", ");
      }
      if (info.includedCollections.length > 0) {
        summary["Included collections"] = info.includedCollections
          .map(x => this.getEnumName("collections", x))
          .join(", ");
      }
      if (info.excludedCollections.length > 0) {
        summary["Excluded collections"] = info.excludedCollections
          .map(x => this.getEnumName("collections", x))
          .join(", ");
      }
      if (info.includedUrls) {
        summary["Excluded Urls"] = info.includedUrls;
      }
      if (info.excludedUrls) {
        summary["Excluded Urls"] = info.excludedUrls;
      }
      if (info.includedApps) {
        summary["Included Apps"] = info.includedApps;
      }
      if (info.excludedApps) {
        summary["Excluded Apps"] = info.excludedApps;
      }
      return summary;
    },
    placementSummary (index) {
      let info = this.target.placements[index];
      let summary = {};

      if (info.includedYtChannels) {
        summary["Included youtube channels"] = info.includedYtChannels;
      }
      if (info.excludedYtChannels) {
        summary["Excluded youtube channels"] = info.excludedYtChannels;
      }
      if (info.includedYtVideos) {
        summary["Included youtube videos"] = info.includedYtVideos;
      }
      if (info.excludedYtVideos) {
        summary["Excluded youtube videos"] = info.excludedYtVideos;
      }
      if (info.includedApps) {
        summary["Included Apps"] = info.includedApps;
      }
      if (info.excludedApps) {
        summary["Excluded Apps"] = info.excludedApps;
      }

      return summary;
    },
    viewabilitySummary (index) {
      let info = this.target.viewability[index];
      if (info.activeView) {
        return {
          "Active View": this.getEnumName("viewability", info.activeView)
        }
      }
      return {};
    },
    environmentSummary (index) {
      let info = this.target.environment[index].filter(Boolean);
      let summary = {};
      if (info.length > 0) {
        summary["Environment"] = info
          .map(x => this.getEnumName("environment", x))
          .join(", ");
      }
      return summary;
    },
    keywordsSummary (index) {
      let info = this.target.keywords[index];
      let summary = {};
      if (info.includedKeywords) {
        summary["Included keywords"] = info.includedKeywords;
      }
      if (info.excludedKeywords) {
        summary["Excluded keywords"] = info.excludedKeywords;
      }
      return summary;
    },
    positionsSummary (index) {
      let info = this.target.positions[index];
      let summary = {};
      if (info.onScreen.length > 0) {
        summary["Position on screen"] = info.onScreen.map(x =>
          this.getEnumName("positionOnScreen", x)
        ).join(", ");
      }
      if (info.contextDisplay.length > 0) {
        summary["In Context Display"] = info.contextDisplay.map(x =>
          this.getEnumName("inContextDisplay", x)
        ).join(", ");
      }
      if (info.contextNative.length > 0) {
        summary["In Context Native"] = info.contextDisplay.map(x =>
          this.getEnumName("inContextNative", x)
        ).join(", ");
      }
      return summary;
    },
    getFullSummary () {
      let summary = {
        Demographics: this.demographicSummary(0),
        "Location and Language": this.locAndLangSummary(0),
        Technology: this.technologySummary(0),
        Audience: this.audienceSummary(0),
        "Brand Safety": this.brandSafetySummary(0),
        "Apps & URLs": this.appsAndUrlsSummary(0),
        Viewability: this.viewabilitySummary(0),
        Environment: this.environmentSummary(0),
        Keywords: this.keywordsSummary(0),
        Position: this.positionsSummary(0)
      };

      return Object.fromEntries(
        Object.entries(summary).filter(
          ([_, val]) => Object.keys(val).length > 0
        )
      );
    },
    generateAudience () {
      this.$refs.creationForm.validate();
    },
    getTargetName (index) {
      // "Language", "Location", "Gender", "AgeGroup"
      let demographic = this.target.demographics[index];
      let locAndLang = this.target.locAndLang[index];
      let macroDict = {};

      if (locAndLang.includedLocations.length > 0) {
        macroDict["Location"] = locAndLang.includedLocations
          .map(x => this.locationsLookup[x])
          .join(", ");
      }
      if (demographic.gender.length > 0) {
        macroDict["Gender"] = demographic.gender
          .map(x => this.getEnumName("gender", x))
          .join(", ");
      }
      if (demographic.age.length > 0) {
        macroDict["AgeGroup"] = demographic.age
          .map(x => this.getEnumName("age", x))
          .join(", ");
      }
      if (locAndLang.languages.length > 0) {
        macroDict["Language"] = locAndLang.languages
          .map(x => this.getEnumName("languages", x))
          .join(", ");
      }

      let replacedTargetName = this.targetForm.targetName;
      for (let [macro, value] of Object.entries(macroDict)) {
        replacedTargetName = replacedTargetName.replaceAll(`{${macro}}`, value);
      }
      return replacedTargetName;
    },
    addMacro (name) {
      if (!this.targetForm.targetName.includes(`{${name}}`)) {
        if (this.targetForm.targetName) {
          this.targetForm.targetName += "-";
        }
        this.targetForm.targetName += `{${name}}`;
      }
    },
    clearAll () {
      this.$refs.creationForm.resetFields();
      this.target = this.getDefaultTargetConfig();
    },
    removeTargetFromPreview (language) {
      let index = this.targetForm.languages.indexOf(language);
      if (index >= 0) {
        this.$delete(this.targetForm.languages, index);
      }
    },
    async saveTarget (index) {
      let self = this;
      let youtubeOptionsLookup = {
        demographics: {
          gender: "gender",
          age: "age",
          parentalStatus: "parent",
          income: "income"
        }
      };
      let targetObj = JSON.parse(JSON.stringify(this.target));
      if (this.targetForm.adType == 4) {
        // convert inventory sources and day time data with names
        Object.entries(targetObj).forEach(([targetName, targetValues]) => {
          if (targetName == "inventorySource") {
            targetObj[targetName] = targetValues[0].map((x) => {
              return {
                id: x,
                name: this.getEnumName("inventorySource", x)
              };
            });
          } else if (targetName == "dayTime") {
            targetObj[targetName] = targetValues[0].flatMap((x) => {
              if (x.start == "" || x.end == "") {
                return [];
              }
              return [x.day * 10000 + x.start * 100 + x.end];
            });
          }
        });
        // convert placements fields to an array
        let adGroupsArr = JSON.parse(JSON.stringify(this.adGroups));
        adGroupsArr.forEach((adGroupObj, index) => {
          adGroupObj.id = Date.now().toString() + index;
          Object.entries(adGroupObj).forEach(([targetName, targetValues]) => {
            if (youtubeOptionsLookup[targetName]) {
              Object.entries(targetValues[0]).forEach(([key, values]) => {
                let optionName = youtubeOptionsLookup[targetName][key];
                if (!optionName) {
                  return;
                }
                targetValues[0][key] = values.map(x => {
                  return {
                    id: x,
                    name: this.getEnumName(optionName, x)
                  }
                });
              })
            } else if (targetName == "placements") {
              ["includedYtChannels", "excludedYtChannels"].forEach(channelType => {
                let channels = adGroupObj.placements[0][channelType];
                adGroupObj.placements[0][channelType] = channels.split(",").flatMap(channel => {
                  channel = channel.trim();
                  let str = "youtube.com/channel/";
                  let ind = channel.indexOf(str);
                  let channelId = channel.substring(ind + str.length);
                  return channelId && channelId.length == 24 ? [channelId] : [];
                })
              });

              ["includedYtVideos", "excludedYtVideos"].forEach(videoType => {
                let videos = adGroupObj.placements[0][videoType];
                adGroupObj.placements[0][videoType] = videos.split(",").flatMap(video => {
                  video = video.trim();

                  // reference: https://stackoverflow.com/a/9102270
                  var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
                  var match = video.match(regExp);
                  if (match && match[2].length == 11) {
                    return [match[2]];
                  }
                  return [];
                })
              })
            }
          });
          this.audienceLookUp(adGroupObj.audience[0].include.type, adGroupObj.audience[0].include.list);
        });
        // add adgroups to target obj for yt targets
        targetObj.adGroups = adGroupsArr;
      } else {
        targetObj.inventorySource = [];
        targetObj.dayTime = [];
        targetObj.placements[index].includedYtChannels = []
        targetObj.placements[index].excludedYtChannels = []
        targetObj.placements[index].includedYtVideos = []
        targetObj.placements[index].excludedYtVideos = []
      }
      let creationType = this.convertInventorySourceToId(this.target.inventorySource[index]);

      let adGroupsAudiencesWithNames = targetObj.adGroups && targetObj.adGroups.length ? JSON.parse(JSON.stringify(targetObj.adGroups)) : [];
      adGroupsAudiencesWithNames.forEach((adGroup, index) => {
        adGroup.audience[0].include.list = self.audienceLookUp(adGroup.audience[0].include.type, adGroup.audience[0].include.list)
        adGroup.audience[0].exclude.list = self.audienceLookUp(adGroup.audience[0].exclude.type, adGroup.audience[0].exclude.list)
      });

      let target = {
        type: this.targetForm.adType,
        creationType,
        locationTargetType: 0,
        name: this.getTargetName(index),
        agencyid: deltax.businessProfileId,
        advertiserid: deltax.businessProfileId,
        businessProfileId: deltax.businessProfileId,
        businessProfilesSearchEngineId: this.targetForm.bpseId,
        isCreatedOnDx: true,
        targetString: JSON.stringify(targetObj),
        dynamicTargetString: JSON.stringify({
          includedLocations: this.target.locAndLang[index].includedLocations.map(
            (x) => ({ id: x, name: this.locationsLookup[x] })
          ),
          excludedLocations: this.target.locAndLang[index].excludedLocations.map(
            (x) => ({ id: x, name: this.locationsLookup[x] })
          ),
          adGroups: adGroupsAudiencesWithNames,
          audience: [{
            include: {
              type: targetObj.audience[0].include.type,
              list: this.audienceLookUp(targetObj.audience[0].include.type, targetObj.audience[0].include.list)
            },
            exclude: {
              type: targetObj.audience[0].exclude.type,
              list: this.audienceLookUp(targetObj.audience[0].exclude.type, targetObj.audience[0].exclude.list)
            }
          }]
        }),
        estimatedReach: 0
      };
      try {
        let response = await APIService.saveTarget(target);
        target.id = response.data.data;
        this.$Message.success({
          background: true,
          content: `${target.name} successfully created`
        });

        this.set_savedTargets([...this.savedTargets, target]);

        let targetsBpseMap = this.savedTargetsMapperByBpseId;
        if (!(this.targetForm.bpseId in targetsBpseMap)) {
          targetsBpseMap[this.targetForm.bpseId] = [];
        }
        targetsBpseMap[this.targetForm.bpseId].unshift({
          BpseId: this.targetForm.bpseId,
          EstimatedReach: 0,
          Id: response.data.data,
          Name: target.name,
          Type: this.targetForm.adType,
          CreationType: creationType
        });
        this.set_savedTargetsMapperByBpseId(targetsBpseMap);
        let createdTarget = {
          bpseId: this.targetForm.bpseId,
          estimatedReach: 0,
          Id: response.data.data,
          Name: target.name,
          type: this.targetForm.adType,
          creationType: creationType,
          targetString: target.targetString
        }
        EventBus.$emit('dv360-updateSavedTarget', createdTarget, false);
      } catch (ex) {
        console.error(ex);
        this.$Message.error({
          background: true,
          content: "There was a problem while processing your request"
        });
      }
    },
    // returns 0 if none are selected | 1 if only YouTube Search is selected | 2 if only YouTube Video is selected | 3 if both are selected
    // for Website Conversions lineItem type, only targets with YouTube Video selected needs to be shown
    convertInventorySourceToId (inventorySource) {
      if (this.targetForm.adType == 1) {
        return 0;
      }

      let nameIdMap = this.options.inventorySource.reduce((obj, cur) => {
        obj[cur.name] = cur.id;
        return obj;
      }, {});

      const hasYoutube = inventorySource.includes(nameIdMap["YouTube"]);
      const hasVideoPartners = inventorySource.includes(nameIdMap["Video Partners"]);

      if (hasYoutube && hasVideoPartners) {
        return 2;
      }
      return 3;
    },
    addTarget (targetName, index) {
      this.target[targetName].splice(
        index + 1,
        0,
        this.getDefaultTargetConfig(targetName)
      );
    },
    getDefaultTargetConfig (targetName = null) {
      let defaultTarget = {
        inventorySource: [[]],
        ytDevices: [{
          models: []
        }],
        dayTime: [[{
          day: 0,
          start: "",
          end: ""
        }]],
        demographics: [
          {
            gender: [10, 11, 20],
            age: [503001, 503002, 503003, 503004, 503005, 503006, 503999],
            parentalStatus: [300, 301, 302],
            income: [510006, 510005, 510004, 510003, 510002, 510001, 510000]
          }
        ],
        locAndLang: [
          {
            includedLocations: [],
            excludedLocations: [],
            languageType: "1",
            languages: []
          }
        ],
        audience: [
          {
            include: {
              type: 1,
              list: []
            },
            exclude: {
              type: 1,
              list: []
            }
          }
        ],
        brandSafety: [
          {
            digitalContentLabelExclusions: [],
            sensitiveCategoryExclusions: [],
            otherVerificationServices: []
          }
        ],
        appsAndUrls: [
          {
            includedChannels: [],
            excludedChannels: [],

            includedUrls: "",
            excludedUrls: "",

            includedCollections: [],
            excludedCollections: [],

            includedApps: "",
            excludedApps: ""
          }
        ],
        placements: [
          {
            includedYtChannels: "",
            excludedYtChannels: "",

            includedYtVideos: "",
            excludedYtVideos: "",

            includedUrls: "",
            excludedUrls: "",

            includedApps: "",
            excludedApps: ""
          }
        ],
        keywords: [
          {
            includedKeywords: "",
            excludedKeywords: ""
          }
        ],
        technology: [
          {
            devices: [30000, 30001, 30002, 30004],
            browser: {
              type: "1",
              values: []
            },
            connectionSpeed: "",
            carrierAndISP: {
              type: "1",
              values: []
            }
          }
        ],
        // category: [],
        environment: [[]],
        positions: [
          {
            onScreen: [],
            contextDisplay: [],
            contextNative: []
          }
        ],
        viewability: [
          {
            activeView: ""
          }
        ]
      };

      this.options.ytDevices.forEach(device => {
        defaultTarget.ytDevices[0][device.name] = {
          selected: false,
          minVersion: "minimum",
          maxVersion: "maximum"
        }
      });

      // set adGroups data in default target
      if (this.targetForm.adType == 4) {
        this.adGroups = [{
          id: Date.now().toString(),
          name: "Adgroup 1",
          demographics: [{
            gender: [10, 11, 20],
            age: [503001, 503002, 503003, 503004, 503005, 503006, 503999],
            parentalStatus: [300, 301, 302],
            income: [510006, 510005, 510004, 510003, 510002, 510001, 510000]
          }],
          placements: [
            {
              includedYtChannels: "",
              excludedYtChannels: "",
              includedYtVideos: "",
              excludedYtVideos: "",
              includedUrls: "",
              excludedUrls: "",
              includedApps: "",
              excludedApps: ""
            }
          ],
          keywords: [{
            includedKeywords: "",
            excludedKeywords: ""
          }],
          audience: [{
            include: {
              type: 1,
              list: []
            },
            exclude: {
              type: 1,
              list: []
            }
          }],
          editNameEnabled: false // need to remove this property while saving target string
        }];
        defaultTarget.adGroups = this.adGroups;

        // remove default demographics values from line item level
        defaultTarget.demographics = [
          {
            gender: [],
            age: [],
            parentalStatus: [],
            income: []
          }
        ];
        defaultTarget.placements = [
          {
            includedYtChannels: [],
            excludedYtChannels: [],

            includedYtVideos: [],
            excludedYtVideos: [],

            includedUrls: "",
            excludedUrls: "",

            includedApps: "",
            excludedApps: ""
          }
        ]
      }

      if (targetName) {
        return defaultTarget[targetName][0];
      }
      return defaultTarget;
    },
    searchAudiences (query, audienceType) {
      let self = this;
      this.searchText = query;

      let searchAudienceType = "";
      let responseFieldName = "";
      let idFieldName = "";
      let typeFieldName = "";
      switch (audienceType) {
        case 1:
          searchAudienceType = "FirstAndThirdPartyAudiences"
          responseFieldName = "FirstAndThirdPartyAudiences"
          idFieldName = "firstAndThirdPartyAudienceId"
          typeFieldName = "firstAndThirdPartyAudienceType"
          break;
        case 2:
          searchAudienceType = "GoogleAudiences"
          responseFieldName = "googleAudiences"
          idFieldName = "googleAudienceId"
          typeFieldName = "googleAudienceType"
          break;
        case 3:
          searchAudienceType = "CombinedAudiences"
          responseFieldName = "combinedAudiences"
          idFieldName = "combinedAudienceId"
          typeFieldName = "combinedAudienceType"
          break;
        case 4:
          searchAudienceType = "CustomLists"
          responseFieldName = "customLists"
          idFieldName = "customListId"
          break;
        default:
          break;
      }
      if (query !== "" && searchAudienceType) {
        this.audienceLoading = true;
        clearTimeout(this.searchTimeout);

        this.searchTimeout = setTimeout(async () => {
          APIService.getDV360Audience(this.targetForm.bpseId, searchAudienceType, this.searchText).then(response => {
            let audiences = (response.data.data[responseFieldName] || []).map(x => ({
              id: x[idFieldName],
              name: x.displayName,
              type: x[typeFieldName] ? x[typeFieldName] : ""
            }));
            let existingAudiences = self.options.audience[audienceType] || [];
            audiences = [...audiences, ...existingAudiences];
            // Filter out duplicates
            let uniqueAudiences = audiences.filter((audience, index, self) =>
              index === self.findIndex((a) => (
                a.id === audience.id
              ))
            );
            self.$set(self.options.audience, audienceType, uniqueAudiences);
            self.audienceLoading = false;
          }).catch(error => {
            console.log(error);
            self.audienceLoading = false;
          });
        }, 700);
      }
    },
    resetIncludeAudienceList (index) {
      this.target.audience[index].include.list = [];
    },
    resetExcludeAudienceList (index) {
      this.target.audience[index].exclude.list = [];
    },
    getAudienceType (type) {
      if (type == "GOOGLE_AUDIENCE_TYPE_IN_MARKET") return "In-Market";
      else if (type == "GOOGLE_AUDIENCE_TYPE_AFFINITY") return "Affinity";
      else if (type == "GOOGLE_AUDIENCE_TYPE_INSTALLED_APPS") return "Apps";
      else return "";
    },
    adGroupDemographicSummary (index) {
      let info = this.adGroups[index].demographics[0];
      return {
        ...((info.gender || []).length > 0 && {
          Gender: info.gender.map(x => this.getEnumName("gender", x)).join(", ")
        }),
        ...((info.age || []).length > 0 && {
          Age: info.age.map(x => this.getEnumName("age", x)).join(", ")
        }),
        ...((info.parentalStatus || []).length > 0 && {
          "Parental status": info.parentalStatus
            .map(x => this.getEnumName("parent", x))
            .join(", ")
        }),
        ...((info.income || []).length > 0 && {
          Income: info.income.map(x => this.getEnumName("income", x)).join(", ")
        })
      };
    },
    adGroupPlacementSummary (index) {
      let info = this.adGroups[index].placements[0];
      let summary = {};

      if (info && info.includedYtChannels) {
        summary["Included youtube channels"] = info.includedYtChannels;
      }
      if (info && info.excludedYtChannels) {
        summary["Excluded youtube channels"] = info.excludedYtChannels;
      }
      if (info && info.includedYtVideos) {
        summary["Included youtube videos"] = info.includedYtVideos;
      }
      if (info && info.excludedYtVideos) {
        summary["Excluded youtube videos"] = info.excludedYtVideos;
      }
      if (info && info.includedApps) {
        summary["Included Apps"] = info.includedApps;
      }
      if (info && info.excludedApps) {
        summary["Excluded Apps"] = info.excludedApps;
      }

      return summary;
    },
    adGroupKeywordsSummary (index) {
      let info = this.adGroups[index].keywords[0];
      let summary = {};
      if (info && info.includedKeywords) {
        summary["Included keywords"] = info.includedKeywords;
      }
      if (info && info.excludedKeywords) {
        summary["Excluded keywords"] = info.excludedKeywords;
      }
      return summary;
    },
    adGroupAudienceSummary (index) {
      let info = this.adGroups[index].audience[0];
      let summary = {};
      if (info.include.type && info.include.list.length > 0) {
        summary["Include audience type"] = this.getEnumName(
          "includeAudienceType",
          info.include.type
        );
        summary["Audience inclusion"] = info.include.list
          .map(x => this.getEnumName(`audience.${info.include.type}`, x))
          .join(", ");
      }
      if (info.exclude.type && info.exclude.list.length > 0) {
        summary["Exclude audience type"] = this.getEnumName(
          "excludeAudienceType",
          info.exclude.type
        );
        summary["Audience exclusion"] = info.exclude.list
          .map(x => this.getEnumName(`audience.${info.exclude.type}`, x))
          .join(", ");
      }
      return summary;
    },
    resetAdGroupIncludeAudienceList (index) {
      this.adGroups[index].audience[0].include.list = [];
    },
    resetAdGroupExcludeAudienceList (index) {
      this.adGroups[index].audience[0].exclude.list = [];
    },
    addAdGroup () {
      this.adGroups.push({
        id: Date.now().toString(),
        name: `Adgroup ${this.adGroups.length + 1}`,
        demographics: [
          {
            gender: [10, 11, 20],
            age: [503001, 503002, 503003, 503004, 503005, 503006, 503999],
            parentalStatus: [300, 301, 302],
            income: [510006, 510005, 510004, 510003, 510002, 510001, 510000]
          }
        ],
        placements: [
          {
            includedYtChannels: "",
            excludedYtChannels: "",
            includedYtVideos: "",
            excludedYtVideos: "",
            includedUrls: "",
            excludedUrls: "",
            includedApps: "",
            excludedApps: ""
          }
        ],
        keywords: [{
          includedKeywords: "",
          excludedKeywords: ""
        }],
        audience: [{
          include: {
            type: 1,
            list: []
          },
          exclude: {
            type: 1,
            list: []
          }
        }],
        editNameEnabled: false // need to remove this property while saving target string
      });
    },
    deleteAdGroup (index) {
      if (this.adGroups.length > 1) {
        this.adGroups.splice(index, 1);
      }
    },
    cloneAdGroup (index) {
      let adGroup = this.adGroups[index];
      adGroup = JSON.parse(JSON.stringify(adGroup));
      adGroup.id = Date.now().toString();
      adGroup.name = `Adgroup ${this.adGroups.length + 1}`;

      let includedAudiences = JSON.parse(JSON.stringify(adGroup.audience[0].include.list));
      let excludedAudiences = JSON.parse(JSON.stringify(adGroup.audience[0].exclude.list));

      // after clone: selected audiences don't render up in UI because of remote-method. IView Limitation. So making the audience list empty after clone then set it in next tick.
      adGroup.audience[0].include.list = [];
      adGroup.audience[0].exclude.list = [];
      this.adGroups.push(adGroup);

      let self = this;
      this.$nextTick(() => {
        self.adGroups[self.adGroups.length - 1].audience[0].include.list = includedAudiences;
        self.adGroups[self.adGroups.length - 1].audience[0].exclude.list = excludedAudiences;
      });
    },
    editAdGroupName (index) {
      this.adGroups[index].editNameEnabled = true;
    },
    saveAdGroupName (index) {
      this.adGroups[index].editNameEnabled = false;
    },
    getLineItemFullSummary () {
      let summary = {
        "Inventory Sources": this.inventorySourceSummary(0),
        "Location and Language": this.locAndLangSummary(0),
        Keywords: this.keywordsSummary(0)
        // Devices: this.ytDevicesSummary(0) // need to create this method
      };

      return Object.fromEntries(
        Object.entries(summary).filter(
          ([_, val]) => Object.keys(val).length > 0
        )
      );
    },
    getAdGroupFullSummary (index) {
      let summary = {
        Demographics: this.adGroupDemographicSummary(index),
        Placements: this.adGroupPlacementSummary(index),
        Keywords: this.adGroupKeywordsSummary(index),
        Audience: this.adGroupAudienceSummary(index)
      };

      return Object.fromEntries(
        Object.entries(summary).filter(
          ([_, val]) => Object.keys(val).length > 0
        )
      );
    },
    audienceLookUp (audienceType, audienceIds) {
      let audiences = this.options.audience[audienceType] || [];
      let filteredAudiences = audiences.filter(x => audienceIds.includes(x.id)).map(x => ({ id: x.id, name: x.name }));
      return filteredAudiences;
    }
  }
};
</script>

<style scoped>
.line-items-comp {
  margin: 10px 0px;
}
.preivew-wrapper {
  border: 1px solid #ddd;
  margin: 15px;
  width: calc(100% - 30px);
}
.preview-placeholder {
  margin: 10px;
  font-weight: normal;
  color: #979696;
}
.preview-header {
  background-color: #f4f4f4;
  height: 35px;
  line-height: 35px;
  padding: 0 10px 0 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid var(--main-bg-color);
}
.preview-header p {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
::v-deep .push-left {
  margin-left: 20px;
}
.vertical-space {
  margin-top: 10px;
  margin-bottom: 10px;
}
.audience-wrapper {
  padding: 15px;
  border: 1px solid #ddd;
  background: #f4f4f4;
  padding: 15px;
}
.audience {
  border: 1px solid #ddd;
  padding: 10px;
  background: white;
}
.and-text {
  font-weight: bold;
}
.float-right {
  float: right;
  margin-left: 10px;
}
.macro-list {
  margin-bottom: 0px;
}
.macro-list > li {
  display: inline-block;
}
.pointer {
  cursor: pointer;
}
.macros {
  padding-right: 10px;
}
::v-deep .space {
  margin-bottom: 7px;
}
.target-row {
  margin: 10px;
  border: 1px solid #ddd;
}
.target-prev-header {
  padding: 0 5px;
  background: #f4f4f4;
  border-bottom: 2px solid var(--main-bg-color);
}
.target-prev-header p {
  margin: 2px 0 4px 0;
}
.target-prev-body {
  padding: 5px;
  font-weight: normal;
}
.preview-icon {
  margin-top: 3px;
  cursor: pointer;
  color: #888888;
}
.preview-icon:hover {
  color: #707376;
}
.preview-icon:active {
  transform: translateY(1px);
}
.divider {
  width: 100%;
  height: 7px;
  border-bottom: 1px solid #ccc;
  position: relative;
  margin-bottom: 36px;
}
.divider > span {
  left: 36px;
  position: absolute;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #979898;
  background-color: white;
  padding: 0 10px;
}
.center-text {
  text-align: center;
}
::v-deep .ivu-modal-footer {
  display: none;
}
</style>
<style>
.adgroup-name-input {
  width: 50%;
}
.adgroup-name-input > .ivu-input {
  height: 22px;
  /* background: #f7f7f7;
  border: 0px;
  border-bottom: 1px solid #ababab; */
}
.target-modal .ivu-collapse-header {
  height: auto !important;
  line-height: unset !important;
}
.target-modal .ivu-checkbox-inner {
  margin-left: 5px;
  margin-right: 5px;
}
.modal-header button.close > span {
  font-size: 22px !important;
  color: initial;
}
.target-name > .ivu-form-item-error-tip {
  padding-top: 1px;
}
.target-modal .modal-header {
  padding-bottom: 5px !important;
}
.target-modal .modal-dialog {
  width: 98%;
  height: 100%;
  z-index: 9997;
}
.targets-body {
  overflow: auto;
  padding: 0px !important;
  height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
}
.selected-preview {
  display: flex;
  flex-wrap: wrap;
}
.preview-card {
  border: 1px solid #ddd;
  margin: 0 10px 10px 0;
  background-color: white;
}
.pannel-btn {
  cursor: pointer;
  float: right;
  margin: 0 10px;
}
.audience {
  border: 1px solid #ddd;
  padding: 10px;
  background: white;
}
.error-msg {
  color: #b94a48;
  margin-bottom: 0px;
}
.dbm-required:before {
  content: '*';
  display: inline-block;
  margin-right: 4px;
  line-height: 1;
  font-family: SimSun;
  font-size: 12px;
  font-weight: lighter;
  color: #b94a48;
}
.dbm-label-required > .ivu-form-item-label:before {
  content: '*';
  display: inline-block;
  margin-right: 4px;
  line-height: 1;
  font-family: SimSun;
  font-size: 12px;
  font-weight: lighter;
  color: #b94a48;
}
.title-icons {
  margin: 0px 16px;
  padding: 2px 0px;
}
.icons {
  font-size: 13px;
  cursor: pointer;
  color: gray;
}
</style>
<style src="../../../../Styles/dx-iview.css"></style>
