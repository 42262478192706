<template>
  <div>
    <Row
      v-for="(dayPartingSchedule, index) in dayPartingSchedulesInput"
      :key="index"
      class="schedule-block"
    >
      <Row-Col span="10" class="padding-top-five">
        <ButtonGroup
          v-if="!isRnFConfig"
          v-model="dayPartingSchedule.dayIds"
          size="small"
        >
          <template v-for="day in WeekDays">
            <button
              :key="day.id"
              @click.prevent="addOrRemoveDayId(index,day.id,dayPartingSchedule)"
              :class ="{
                'btn btn-white btn-xs': !dayPartingSchedule.dayIds.includes(day.id),
                'day-button': true,
                'btn btn-success btn-xs': dayPartingSchedule.dayIds.includes(day.id)}"
                :disabled="checkIfDayIsAlreadyPresent(day.id,index,dayPartingSchedule.dayIds)
                  || isReadOnlyMode" >
                {{day.shortName}}
            </button>
          </template>
        </ButtonGroup>
        <Icon
          v-if="dayPartingSchedulesInput.length > 1"
          style="padding-left:3px;padding-bottom: 10px;"
          @click="removeSchedule(dayPartingSchedule)"
          size="18"
          class="btn-white schedule-btn-pointer"
          type="ios-close-circle-outline"
        />
      </Row-Col>
          <Row-Col
            v-for="(timeRange,tIndex) in dayPartingSchedule.timeRanges"
            :key="timeRange.id"
            span="8"
            class="margin-top-ntwo"
            :offset="tIndex > 0?10:0"
          >
            <Row-Col  span="24">
                <Select
                  :value="timeRange.start"
                  @on-change="(e)=>{setStartTime(e,timeRange,tIndex,dayPartingSchedule,index)}"
                  size="small"
                  placeholder="Start time"
                  style="width:100px"
                  :disabled="isPromotionFlow"
                >
                  <Option
                    v-for="item in displayTime"
                    :value="item.value"
                    :key="item.value">
                    {{ item.label }}
                  </Option>
                </Select> <Icon size="15" type="md-remove" />
                <Select
                  :value="timeRange.end"
                  @on-change="(e)=>{setEndTime(e,timeRange,tIndex,dayPartingSchedule,index)}"
                  size="small"
                  placeholder="End time"
                  style="width:100px"
                  :disabled="isPromotionFlow"
                >
                  <Option
                    v-for="item in displayTime"
                    :value="item.value"
                    :key="item.value">
                    {{ item.label }}
                  </Option>
                </Select>
              <Icon
                size="18"
                style="padding-left:3px"
                v-if="dayPartingSchedule.timeRanges.length > 1"
                @click="removeTimeRange(timeRange,dayPartingSchedule)"
                class="btn-white schedule-btn-pointer"
                type="ios-close-circle-outline"
              />
            </Row-Col>
          </Row-Col>
          <Row-Col span="1">
            <button
                v-if="!isRnFConfig"
                :disabled="!isNewTimeRangeAvailable(dayPartingSchedule.timeRanges)
                  || isReadOnlyMode"
                :class ="{'btn btn-white btn-sm add-time-slot': true}"
                @click.prevent="addTimerange(index,dayPartingSchedule);"
                >
            <span class="fa fa-plus-circle"></span> Add Time Slot
            </button>
          </Row-Col>
        </Row>
        <Row>
          <Row-Col span="18">
            <button
                v-if="!isRnFConfig"
                :disabled="disableAddNewschedule || isReadOnlyMode"
                :class ="{
                  'btn btn-white btn-sm add-schedule-button': true,
                  'disable-time-slot':disableAddNewschedule
                  }"
                @click.prevent="addDayPartingSchedule" >
            <span class="fa fa-plus"></span> Add More Schedules
            </button>
          </Row-Col>
          <Row-Col span="16" v-if="selectedTimeZoneType == 'ADVERTISER'">
            <div class="alert alert-info" role="alert">
                      <span class="fa-info-circle"></span>
                      TimeZone : {{businessProfileTimezone}}
                  </div>
          </Row-Col>
        </Row>
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="selectedTimeZoneType"  @on-change="setTimeZoneType">
                <Radio label="USER" :disabled="disabled">Use viewer's time zone</Radio>
                <Radio label="ADVERTISER" :disabled="disabled">Use ad account's time zone</Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
  </div>
</template>
<script>
import {
  Row,
  Col,
  Select,
  Option,
  ButtonGroup,
  RadioGroup,
  Radio,
  Icon
} from "iview";
import { mapGetters } from "vuex";
export default {
  props: {
    isRnFConfig: { default: false },
    disabled: { default: false },
    scheduleConfiguration: {
      default: function () {
        return {}
      }
    }
  },
  components: {
    Row,
    "Row-Col": Col,
    ButtonGroup,
    Option,
    Select,
    RadioGroup,
    Radio,
    Icon
  },
  data () {
    return {
      schedule: {},
      isReadOnlyMode: false,
      displayTime: [
        {
          value: 0,
          label: "12 AM (Beginning of the day)"
        },
        {
          value: 60,
          label: "1 AM"
        },
        {
          value: 120,
          label: "2 AM"
        },
        {
          value: 180,
          label: "3 AM"
        },
        {
          value: 240,
          label: "4 AM"
        },
        {
          value: 300,
          label: "5 AM"
        },
        {
          value: 360,
          label: "6 AM"
        },
        {
          value: 420,
          label: "7 AM"
        },
        {
          value: 480,
          label: "8 AM"
        },
        {
          value: 540,
          label: "9 AM"
        },
        {
          value: 600,
          label: "10 AM"
        },
        {
          value: 660,
          label: "11 AM"
        },
        {
          value: 720,
          label: "12 PM"
        },
        {
          value: 780,
          label: "1 PM"
        },
        {
          value: 840,
          label: "2 PM"
        },
        {
          value: 900,
          label: "3 PM"
        },
        {
          value: 960,
          label: "4 PM"
        },
        {
          value: 1020,
          label: "5 PM"
        },
        {
          value: 1080,
          label: "6 PM"
        },
        {
          value: 1140,
          label: "7 PM"
        },
        {
          value: 1200,
          label: "8 PM"
        },
        {
          value: 1260,
          label: "9 PM"
        },
        {
          value: 1320,
          label: "10 PM"
        },
        {
          value: 1380,
          label: "11 PM"
        },
        {
          value: 1440,
          label: "12 AM (End of the day)"
        }
      ],
      WeekDays: [{
        id: 0,
        name: 'Sunday',
        shortName: 'Sun'
      },
      {
        id: 1,
        name: 'Monday',
        shortName: 'Mon'
      },
      {
        id: 2,
        name: 'Tuesday',
        shortName: 'Tue'
      },
      {
        id: 3,
        name: 'Wednesday',
        shortName: 'Wed'
      },
      {
        id: 4,
        name: 'Thursday',
        shortName: 'Thu'
      },
      {
        id: 5,
        name: 'Friday',
        shortName: 'Fri'
      },
      {
        id: 6,
        name: 'Saturday',
        shortName: 'Sat'
      }],
      isWithinRange: false,
      timeZoneTypes: [
        {
          value: "USER",
          label: "USER"
        },
        {
          value: "ADVERTISER",
          label: "ADVERTISER"
        }
      ],
      selectedTimeZoneType: '',
      dayPartingScheduleOrderKeys: [],
      isPromotionFlow: false
    };
  },
  created () {
    var schedule = JSON.parse(JSON.stringify(this.scheduleConfiguration));
    schedule.dayParting_schedules = this.changeFromFacebookCLCFormat(schedule.dayParting_schedules);
    this.schedule = schedule;
  },
  watch: {
    schedule: {
      handler () {
        var data = JSON.parse(JSON.stringify({ ...this.schedule, isWithinRange: this.isWithinRange }));
        this.$emit('updateSchedule', data)
        data.dayParting_schedules = this.changeToFacebookCLCFormat(data.dayParting_schedules)
        this.$emit('updateFacbeookCLCSchedule', data)
      },
      deep: true
    },
    scheduleConfiguration: {
      handler (newVal, oldVal) {
        if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          var data = JSON.parse(JSON.stringify(newVal));
          data.dayParting_schedules = this.changeFromFacebookCLCFormat(data.dayParting_schedules)
          this.schedule = data
        }
      }
    }
  },
  computed: {
    ...mapGetters(["businessProfileTimezone"]),
    disableAddNewschedule () {
      let isScheduleAvailable = false;
      let days = [];
      this.dayPartingSchedulesInput.forEach(schedule => {
        if (schedule.dayIds) {
          days = days.concat(schedule.dayIds);
        }
      });
      if (days.length >= 7) {
        isScheduleAvailable = true;
      } else {
        isScheduleAvailable = false;
      }
      return isScheduleAvailable;
    },
    dayPartingSchedulesInput () {
      return this.formatDayPartingSchedules();
    }
  },
  methods: {
    setTimeZoneType (timezoneType) {
      if (timezoneType != undefined) {
        let schedules = this.schedule.dayParting_schedules;
        schedules.map(schedule => {
          schedule.timezoneType = timezoneType;
          return schedule;
        });
        this.schedule.dayParting_schedules = schedules;
      }
    },
    formatDayPartingSchedules () {
      // Groups timezonetype by days
      let dayPartingSchedules = [];
      let timeRangeByDays = this.schedule.dayParting_schedules.reduce(
        function (obj, item) {
          obj[item.days] = obj[item.days] || [];
          obj[item.days].push({
            start: item.startMinute,
            end: item.endMinute
          });
          return obj;
        },
        {}
      );

      // Groups timezonetype by days
      let timeZoneTypeByDays = this.schedule.dayParting_schedules.reduce(
        function (obj, item) {
          obj[item.days] = obj[item.days] || [];
          obj[item.days].push({ timezoneType: item.timezoneType });
          return obj;
        },
        {}
      );
      // constructs the required array for UI

      if (this.dayPartingScheduleOrderKeys) {
        // new order w.r.t old order
        let newKeysOrder = Object.keys(timeRangeByDays).sort((a, b) => {
          if (this.dayPartingScheduleOrderKeys.findIndex(x => x == a) == -1) {
            return 1;
          }
          if (this.dayPartingScheduleOrderKeys.findIndex(x => x == b) == -1) {
            return -1;
          }
          if (this.dayPartingScheduleOrderKeys.findIndex(x => x == a) < this.dayPartingScheduleOrderKeys.findIndex(x => x == b)) {
            return -1;
          } else {
            return 1
          }
        })

        this.dayPartingScheduleOrderKeys = newKeysOrder;
      } else {
        // initial order
        this.dayPartingScheduleOrderKeys = Object.keys(timeRangeByDays).sort();
      }

      dayPartingSchedules = this.dayPartingScheduleOrderKeys.map(function (key) {
        return {
          dayIds: key.split(",").map(Number),
          timeRanges: timeRangeByDays[key],
          timezoneType: timeZoneTypeByDays[key]
        };
      });
      this.selectedTimeZoneType = dayPartingSchedules.length > 0 ? dayPartingSchedules[0].timezoneType[0].timezoneType : 'USER';
      return dayPartingSchedules;
    },
    addOrRemoveDayId (index, dayId, schedule) {
      let timeRanges = schedule.timeRanges;
      let dayIds = schedule.dayIds;
      let oldDayIds = this.dayPartingScheduleOrderKeys.length > 0
        ? this.dayPartingScheduleOrderKeys[index].split(',').map(Number)
        : [];
      // If selected schedule dayIds has one element, below boolean
      // check checks if dayId is already present in selected schedule dayIds
      let isSameDay = dayIds.length == 1 && dayId == dayIds[0];
      if (!isSameDay) {
        let schedulesToBeUpdated = [];

        // schedulesToBeUpdated gets the list of dayParting_schedules
        // with matching properties
        this.schedule.dayParting_schedules.forEach(sch => {
          if (timeRanges.find(
            tr => tr.start == sch.startMinute
          ) != undefined && timeRanges.find(
            tr => tr.end == sch.endMinute
          ) != undefined && sch.days.toString() == dayIds.toString()) {
            schedulesToBeUpdated.push(sch);
          }
        });

        schedulesToBeUpdated.forEach(updateSchedule => {
          if (!updateSchedule.days.includes(dayId)) {
          // check if currently entered dayId is already present in dayParting_schedules
          // if(!this.checkIfDayIsAlreadyPresent(dayId, 8, dayIds)){
            updateSchedule.days.push(dayId);
          // }
          } else {
            if (updateSchedule.days.length > 1) {
              let dayToRemoveIndex = updateSchedule.days.indexOf(dayId);
              updateSchedule.days.splice(dayToRemoveIndex, 1);
            }
          }
        });
        if (oldDayIds.includes(dayId)) {
          let dayToRemoveIndex = oldDayIds.indexOf(dayId);
          oldDayIds.splice(dayToRemoveIndex, 1);
        } else {
          oldDayIds.push(dayId);
        }
        this.dayPartingScheduleOrderKeys[index] = oldDayIds.join(',');
      }
    },
    checkIfDayIsAlreadyPresent (dayId, cindex, dayIds) {
      let dayIsAlreadyPresent = false;
      let otherDayIds = this.dayPartingSchedulesInput
        .filter(function (item, index) {
          if (index != cindex) {
            return item;
          }
        })
        .map(dp => dp.dayIds)
        .flat();
      dayIsAlreadyPresent = otherDayIds.includes(dayId);
      return dayIsAlreadyPresent;
    },
    removeSchedule (schedule) {
      this.schedule.dayParting_schedules = this.schedule.dayParting_schedules.filter(
        item => item.days.toString() != schedule.dayIds.toString()
      );
    },
    setStartTime (startTime, timeRange, tIndex, dayPartingSchedule, index) {
      let endTime = timeRange.end;
      this.isWithinRange = false;
      let timeRanges = JSON.parse(JSON.stringify(dayPartingSchedule.timeRanges));
      // Excludes time range to which start time has to be set
      let otherTimeRanges = timeRanges.filter(function (tr, index) {
        if (index != tIndex) {
          return tr;
        }
      })
      if (otherTimeRanges != undefined) {
        for (let i = 0; i < otherTimeRanges.length; i++) {
          if (otherTimeRanges[i].start < startTime && startTime < otherTimeRanges[i].end) {
            this.isWithinRange = true;
            break;
          } else if (otherTimeRanges[i].start < endTime && endTime < otherTimeRanges[i].end) {
            this.isWithinRange = true
            break;
          } else if (otherTimeRanges[i].start == endTime && endTime == otherTimeRanges[i].end) {
            this.isWithinRange = true
            break;
          } else if (otherTimeRanges[i].start >= startTime && otherTimeRanges[i].end <= endTime) {
            this.isWithinRange = true
            break;
          }
        }
      }
      this.schedule.dayParting_schedules.forEach(sch => {
        if (
          dayPartingSchedule.dayIds.toString() == sch.days.toString() &&
              sch.endMinute == endTime
        ) {
          sch.startMinute = startTime;
        }
        return sch;
      });

      if (startTime >= endTime) {
        this.isWithinRange = true;
      }
    },
    setEndTime (endTime, timeRange, tIndex, dayPartingSchedule, index) {
      let startTime = timeRange.start;
      this.isWithinRange = false;
      let timeRanges = JSON.parse(JSON.stringify(dayPartingSchedule.timeRanges));
      let otherTimeRanges = timeRanges.filter(function (tr, index) {
        if (index != tIndex) {
          return tr;
        }
      })
      if (otherTimeRanges != undefined) {
        for (let i = 0; i < otherTimeRanges.length; i++) {
          if (otherTimeRanges[i].start < startTime && startTime < otherTimeRanges[i].end) {
            this.isWithinRange = true;
            break;
          } else if (otherTimeRanges[i].start < endTime && endTime < otherTimeRanges[i].end) {
            this.isWithinRange = true
            break;
          } else if (otherTimeRanges[i].start == endTime && endTime == otherTimeRanges[i].end) {
            this.isWithinRange = true
            break;
          } else if (otherTimeRanges[i].start >= startTime && otherTimeRanges[i].end <= endTime) {
            this.isWithinRange = true
            break;
          }
        }
      }
      this.schedule.dayParting_schedules.forEach(sch => {
        if (
          dayPartingSchedule.dayIds.toString() == sch.days.toString() &&
            sch.startMinute == startTime
        ) {
          sch.endMinute = endTime;
        }
        return sch;
      });
      if (startTime >= endTime) {
        this.isWithinRange = true;
      }
    },
    removeTimeRange (timeRange, schedule) {
      this.isWithinRange = false;
      let index = this.schedule.dayParting_schedules.findIndex(
        item =>
          item.days.toString() == schedule.dayIds.toString() &&
          (item.startMinute.toString() == timeRange.start.toString() &&
            item.endMinute.toString() == timeRange.end.toString())
      );

      this.schedule.dayParting_schedules.splice(index, 1);
    },
    isNewTimeRangeAvailable (timeRanges) {
      let rangeDifference = 0;
      timeRanges.forEach((tR) => {
        rangeDifference = rangeDifference + (tR.end - tR.start);
      });
      let isTimeRangeAvailable = true;
      if (rangeDifference == 1440) {
        isTimeRangeAvailable = false
      } else {
        isTimeRangeAvailable = true
      }
      return isTimeRangeAvailable;
    },
    addTimerange (index, dayPartingSchedule) {
      this.isWithinRange = false;
      let previousEndTime;
      let timeRanges = dayPartingSchedule.timeRanges;
      /*
          1.Get list of hours (without 1440)
          2.Remove the ones with current used starttime and endtimes
      */
      let hours = this.displayTime.filter(dT => dT.value != 1440).map(dT => dT.value);
      let availableEndHours = hours.filter(hour => (timeRanges.find(tR => tR.start <= hour && tR.end > hour) == undefined));
      if (availableEndHours.length > 0) {
        previousEndTime = availableEndHours[0];
      } else {
        previousEndTime = 0
      }
      this.schedule.dayParting_schedules.push({
        days: dayPartingSchedule.dayIds,
        startMinute: previousEndTime,
        endMinute: previousEndTime + 60,
        timezoneType: (this.schedule.dayParting_schedules) ? this.schedule.dayParting_schedules[0].timezoneType : 'USER'
      });
    },
    addDayPartingSchedule () {
      let days = [0, 1, 2, 3, 4, 5, 6];
      let usedDays = [];
      this.schedule.dayParting_schedules.forEach(schedule => {
        usedDays = usedDays.concat(schedule.days);
      });

      var nonUsedDays = days.filter(function (dayId) {
        return usedDays.indexOf(dayId) == -1;
      });

      let schedules = JSON.parse(
        JSON.stringify(this.schedule.dayParting_schedules)
      );

      nonUsedDays = nonUsedDays.sort();
      // checks the previous used day and increments to next day

      let newDay = nonUsedDays[0];
      let startMinute = 0;
      let endMinute = 1440;

      schedules.push({
        startMinute: startMinute,
        endMinute: endMinute,
        days: [newDay],
        timezoneType: (this.schedule.dayParting_schedules) ? this.schedule.dayParting_schedules[0].timezoneType : 'USER'
      });

      this.schedule.dayParting_schedules = schedules;
    },
    changeFromFacebookCLCFormat (dayPartingSchedules) {
      let mappedScheduleConfig = dayPartingSchedules.map(x => {
        return {
          startMinute: (x.start_minute || x.start_minute == 0) ? x.start_minute : x.startMinute,
          endMinute: (x.end_minute || x.end_minute == 0) ? x.end_minute : x.endMinute,
          days: x.days,
          timezoneType: (x.timezone_type) ? x.timezone_type : x.timezoneType
        }
      });
      return mappedScheduleConfig;
    },
    changeToFacebookCLCFormat (dayPartingSchedules) {
      let mappedScheduleConfig = dayPartingSchedules.map(x => {
        return {
          start_minute: (x.startMinute || x.startMinute == 0) ? x.startMinute : x.start_minute,
          end_minute: (x.endMinute || x.endMinute == 0) ? x.endMinute : x.end_minute,
          days: x.days,
          timezone_type: (x.timezoneType) ? x.timezoneType : x.timezone_type
        }
      });
      return mappedScheduleConfig;
    }
  }
};
</script>
<style scoped>
.padding-top-five{
  padding-top: 5px;
}
.margin-top-ntwo{
  margin-top: -2px;
}
</style>
