<template>
    <div class="card-block" style="display: block;">
      <div>
        <div v-for="(category, index) in browseData" :key="index">
          <Collapse :value="activeNames[index]">
            <Panel :name="index.toString()">
              <p class="content-text">{{ category.name }}</p>
              <div
                slot="content"
                v-for="(subcategory, subIndex) in category.subcategories"
                :key="subIndex"
              >
                <Collapse :value="activeNames[index + '-' + subIndex]">
                  <Panel :name="subIndex.toString()">
                    <p class="content-text">{{ subcategory.name }}</p>
                    <div
                      slot="content"
                      class="content-bg"
                      v-for="(item, itemIndex) in subcategory.items"
                      :key="itemIndex"
                    >
                      <Checkbox
                        :label="item.name"
                        @on-change="getCheckedTarget(item)"
                        :value="isSelected(item.id)"
                        >{{ item.name }}</Checkbox
                      >
                      <span class="badge new-badge">
                        {{ parseInt(item.audience_size_lower_bound).toLocaleString() }} -
                        {{ parseInt(item.audience_size_upper_bound).toLocaleString() }}
                      </span>
                    </div>
                    <div
                      slot="content"
                      v-for="(subSubcategory,
                      subSubIndex) in subcategory.subcategories"
                      :key="subSubIndex"
                    >
                      <Collapse
                        :value="
                          activeNames[
                            index + '-' + subIndex + '-' + subSubIndex
                          ]
                        "
                      >
                        <Panel :name="subSubIndex.toString()">
                          <p class="content-text">
                            {{ subSubcategory.name }}
                          </p>
                          <div
                            class="content-bg"
                            slot="content"
                            v-for="(subSubItem,
                            subSubItemIndex) in subSubcategory.items"
                            :key="subSubItemIndex"
                          >
                            <Checkbox
                              :label="subSubItem.name"
                              @on-change="getCheckedTarget(subSubItem)"
                              :value="isSelected(subSubItem.id)"
                              >{{ subSubItem.name }}</Checkbox
                            >
                            <span class="badge new-badge">
                              {{ parseInt(subSubItem.audience_size_lower_bound).toLocaleString() }} -
                              {{ parseInt(subSubItem.audience_size_upper_bound).toLocaleString() }}
                            </span>
                          </div>
                          <div
                            slot="content"
                            v-for="(level4Subcategory,
                            level4SubIndex) in subSubcategory.subcategories"
                            :key="level4SubIndex"
                          >
                            <Collapse
                              :value="
                                activeNames[
                                  index +
                                    '-' +
                                    subIndex +
                                    '-' +
                                    subSubIndex +
                                    '-' +
                                    level4SubIndex
                                ]
                              "
                            >
                              <Panel :name="level4SubIndex.toString()">
                                <p class="content-text">
                                  {{ level4Subcategory.name }}
                                </p>
                                <div
                                  slot="content"
                                  class="content-bg"
                                  v-for="(level4Item,
                                  level4ItemIndex) in level4Subcategory.items"
                                  :key="level4ItemIndex"
                                >
                                  <Checkbox
                                    :label="level4Item.name"
                                    @on-change="getCheckedTarget(level4Item)"
                                    :value="isSelected(level4Item.id)"
                                    >{{ level4Item.name }}</Checkbox
                                  >
                                  <span class="badge new-badge">
                                    {{ parseInt(level4Item.audience_size_lower_bound).toLocaleString() }}
                                    -
                                    {{ parseInt(level4Item.audience_size_upper_bound).toLocaleString() }}
                                  </span>
                                </div>
                                <div
                                  slot="content"
                                  v-for="(level5Subcategory,
                                  level5SubIndex) in level4Subcategory.subcategories"
                                  :key="level5SubIndex"
                                >
                                  <Collapse
                                    :value="
                                      activeNames[
                                        index +
                                          '-' +
                                          subIndex +
                                          '-' +
                                          subSubIndex +
                                          '-' +
                                          level4SubIndex +
                                          '-' +
                                          level5SubIndex
                                      ]
                                    "
                                  >
                                    <Panel :name="level5SubIndex.toString()">
                                      <p>{{ level5Subcategory.name }}</p>
                                      <div
                                        slot="content"
                                        class="content-bg"
                                        v-for="(level5Item,
                                        level5ItemIndex) in level5Subcategory.items"
                                        :key="level5ItemIndex"
                                      >
                                        <Checkbox
                                          :label="level5Item.name"
                                          @on-change="
                                            getCheckedTarget(level5Item)
                                          "
                                          :value="isSelected(level5Item.id)"
                                          >{{ level5Item.name }}</Checkbox
                                        >
                                        <span class="badge new-badge">
                                          {{
                                            parseInt(level5Item.audience_size_lower_bound).toLocaleString()
                                          }}
                                          -
                                          {{
                                            parseInt(level5Item.audience_size_upper_bound).toLocaleString()
                                          }}
                                        </span>
                                      </div>
                                    </Panel>
                                  </Collapse>
                                </div>
                              </Panel>
                            </Collapse>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
</template>

<script>
import {
  Collapse,
  Panel,
  Checkbox
} from "iview";

export default {
  props: {
    browseData: Array,
    mergerdTargets: Array
  },
  components: {
    Collapse,
    Panel,
    Checkbox
  },
  data: function () {
    return {
      activeNames: ["1", "2", "3", "4"]
    };
  },
  methods: {
    isSelected (id) {
      let targetIndex = this.mergerdTargets.findIndex(
        target => target.id === id
      );
      if (targetIndex >= 0) {
        return true;
      } else {
        return false;
      }
    },
    getCheckedTarget (value) {
      this.$emit("get-checked-target", value);
    }
  }
};
</script>

<style scoped>
::v-deep .ivu-collapse-item {
  margin-left: 15px;
}
.content-bg {
  display: flex;
  background-color: white;
  color: gray;
  height: 32px;
  padding: 7px 16px;
  width: 719px;
}
::v-deep .ivu-collapse-content {
  padding: 0px;
}
::v-deep .ivu-collapse-content-box {
  padding-top: 0px;
  padding-bottom: 0px;
}
::v-deep .ivu-collapse-item.ivu-collapse-item-active > .ivu-collapse-header {
  display: flex;
  border-bottom: 0;
}
::v-deep .ivu-collapse-header {
  display: flex;
}
::v-deep .ivu-icon-ios-arrow-forward {
  display: flex;
  align-items: center;
}
::v-deep .ivu-collapse {
  background-color: white;
  border: 0;
  border-radius: 0;
}
::v-deep .ivu-checkbox-wrapper {
  width: 70% !important;
  margin-left: 35px;
}
::v-deep .ivu-checkbox-wrapper-checked {
  font-weight: bold;
  color: #000000;
}

::v-deep .ivu-checkbox-checked {
  color: #000000;
  font-weight: bold;
}
.content-text {
  margin-top: 9px;
}
::v-deep .ivu-radio-group-button .ivu-radio-wrapper {
  display: inline-block;
  height: 32px;
  line-height: 30px;
  margin: 0;
  padding: 0 15px;
  font-size: 13px;
  color: #000000;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 1px solid #939393;
  background: #fff;
  position: relative;
}
::v-deep .ivu-select-multiple .ivu-tag {
  height: 24px;
  line-height: 22px;
  margin: 3px 4px 3px 0;
  max-width: 99%;
  position: relative;
}
::v-deep .ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #ebebeb;
  -webkit-box-shadow: 0 0 0 0 #cccccc;
  box-shadow: 0 0 0 0 #d7d7d7;
  z-index: 1;
}
::v-deep .ivu-radio-group-button .ivu-radio-wrapper:hover {
  color: #292929;
  background: #e5e5e5;
}
::v-deep .ivu-select-head-flex {
  display: flex;
}
::v-deep .ivu-select-prefix {
  padding: 5px;
}
.card-block {
  margin-bottom: 20px;
  display: flex;
}
.new-badge{
  width: 150px;
}
</style>
