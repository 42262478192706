<template>
  <div class="cl-widget">
    <Card>
      <div>
        <Row>
          <Row-Col span="8">
            <h4>Configs
            </h4>
          </Row-Col>
          <Row-Col span="16" class="accounts-create-config">
            <Select v-model="selectedAccount"
              class="accounts"
              placeholder="Select an account"
              filterable
              >
              <Option
                v-for="(account, index) in accounts"
                :key="index"
                :value="account.Id"
                >{{ account.NickName }}</Option
              >
            </Select>
            <div class="pull-right plus-button">
                <CampaignConfiguration
                  :config="config.config"
                  :defaultFormConfig="defaultFormConfig"
                  :campaignConfigurations="campaignConfigurations"
                  v-on:newCampaignConfigSaved="updateCampaignConfigs($event)"
                />
            </div>
          </Row-Col>
        </Row>
      </div>
      <div>
            <Input v-model="search" search placeholder="Search" class="search-bar input-search-bar" />
      </div>
      <template>
            <div
              style="margin-top:10px"
              v-if="selectedAccount==0"
              class="alert alert-info"
            >Please select an account to see the saved configurations.</div>
            <div
              style="margin-top:10px"
              v-else-if="filteredConfigs.length <= 0 && !search.length"
              class="alert alert-info"
            >There are no saved configurations yet.</div>
      </template>
      <VuePerfectScrollbar class="widget-body" v-if="selectedAccount > 0">
        <Row class="cl-ivu-row" v-for="item in filteredConfigs" :key="item.id">
          <Row-Col span="1">
            <i class="fas fa-copy action-icon"
              @click="copyToClipboard(configurationPrefix + '-' + Number.parseInt(businessProfileId).toString(36) + '-' + item.id)">
            </i>
          </Row-Col>
          <Row-Col offset="1" span="21" class="cl-widget-text">{{ item.name || item.Name }}</Row-Col>
        </Row>
      </VuePerfectScrollbar>
    </Card>
  </div>
</template>
<script>
import { Input, Card, Row, Col, Select, Option } from "iview";
import CampaignConfiguration from "./CampaignConfiguration.vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import mixins from "../../../Mixins/copyToClipboard.js";
import { EntityPrefixes } from "../Constants/EntityPrefix.js"
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    Card,
    Row,
    "Row-Col": Col,
    VuePerfectScrollbar,
    Input,
    Select,
    Option,
    CampaignConfiguration
  },
  data: function () {
    return {
      search: "",
      selectedAccount: 0,
      configurationPrefix: EntityPrefixes.Configuration
    }
  },
  props: {
    config: Object,
    defaultFormConfig: Object,
    campaignConfigurations: Array
  },
  mixins: [mixins],
  mounted () {
  },
  computed: {
    ...mapState(["selectedCampaignLauncherConfigId", "businessProfileId", "campaignLauncherConfigurations", "selectedPublisher"]),
    ...mapGetters(["getPublisherAccounts"]),
    filteredConfigs () {
      let searchTerm = this.search;
      if (searchTerm != "") {
        return this.campaignLauncherConfigurations.filter(e => {
          return e.Name.toLowerCase().includes(searchTerm.toLowerCase()) && e.BpseId == this.selectedAccount
        })
      } else {
        return this.campaignLauncherConfigurations.filter(
          config =>
            config.Status && config.searchEngineTypeId == this.selectedPublisher && config.BpseId == this.selectedAccount
        ); ;
      }
    },
    accounts () {
      if (this.getPublisherAccounts.length == 1) {
        this.selectedAccount = this.getPublisherAccounts[0].Id;
      }
      return this.getPublisherAccounts;
    }
  },
  methods: {
    updateCampaignConfigs (event) {
      this.$emit("newCampaignConfigSaved", event);
    }
  }
}
</script>
<style scoped>
.input-search-bar .ivu-input {
  height: 30px;
}
.cl-widget .ivu-card {
  font-size: 12px !important;
  border-radius:0;
}
.accounts-create-config {
  display: flex;
  max-width: 250px;
  float: right;
}
.accounts {
  margin-right: 10px;
}
</style>
