<template>
  <Form
    label-position="right"
    :label-width="200"
    :model="config"
    :rules="validationRules"
    ref="dbmConfig"
  >
    <div v-show="showConfigForm">
      <Divider orientation="left">Accounts and Assets</Divider>
      <Form-Item label="DV360 Account" prop="bpseId" key="bpseId">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.bpseId"
              placeholder="Select an account"
              :transfer="true"
              :disabled="isExistingConfig"
              filterable
            >
              <Option
                v-for="(item, index) in dbmAccounts"
                :key="index"
                :value="item.id"
                :disabled="item.isOauthExpired"
                >{{ item.accountId + " - " + item.accountNickName }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Creative Type" prop="creativeType" key="creativeType">
        <Row>
          <Row-Col span="18">
            <CheckboxGroup v-model="config.creativeType" type="button">
              <Checkbox
                :label="parseInt(id)"
                v-for="(id, name) in dbmEnums.creativeType"
                :key="parseInt(id)"
              >
                <span> {{ name }} </span>
              </Checkbox>
            </CheckboxGroup>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Exchanges">
        <Row>
          <Row-Col span="18" class="push-left">
            <Select
              :transfer="true"
              v-model="config.exchanges"
              filterable
              multiple
              :max-tag-count="20"
            >
              <Option
                v-for="(exchange, i) in dv360Exchanges"
                :key="i"
                :value="exchange.id"
                >{{ exchange.name }}</Option
              >
            </Select>
          </Row-Col></Row
        >
      </Form-Item>
      <Form-Item label="SubExchanges">
        <Row>
          <Row-Col span="18" class="push-left">
            <Select
              :transfer="true"
              v-model="config.subExchanges"
              filterable
              multiple
              :max-tag-count="20"
            >
              <Option
                v-for="(subExchange, i) in dv360SubExchanges"
                :key="i"
                :value="subExchange.id"
                >{{ subExchange.name }}</Option
              >
            </Select>
          </Row-Col></Row
        >
      </Form-Item>
      <Divider orientation="left">Campaign</Divider>
      <Form-Item label="Campaign Name" prop="campaign.name" key="campaign.name">
        <Row>
          <Row-Col span="18">
            <i-input :disabled="isCampaignNomenclatureEnabled" v-model="config.campaign.name" />
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Campaign Goal" prop="goal" key="goal">
        <Row>
          <Row-Col span="18">
            <Select
              v-model=config.goal
              placeholder="Choose goal"
              :transfer="true"
              :disabled="isExistingConfig"
              filterable
            >
              <Option
                v-for="(goal,id) in dbmEnums.campaignGoals"
                :key="parseInt(id)"
                :value="parseInt(id)"
                >{{ goal }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Campaign Frequency Cap" prop="campaign.frequency" key="campaign.frequency">
        <Row>
          <Row-Col span="4">
              <label>Limit Frequency to</label>
          </Row-Col>
          <Row-Col span="2" v-if="config.campaign.frequency.exposures.breakdown != 7">
              <InputNumber
              :min="1"
              :max="999999999"
              v-model="config.campaign.frequency.limit" style="width:70px">
              </InputNumber>
          </Row-Col>
          <Row-Col span="3" v-if="config.campaign.frequency.exposures.breakdown != 7">
              <label>exposures per</label>
          </Row-Col>
          <Row-Col span="2" v-if="config.campaign.frequency.exposures.breakdown != 7">
            <InputNumber
            :min="1"
            :max="maxExposureValue"
            v-if="config.campaign.frequency.exposures.breakdown != 1" v-model="config.campaign.frequency.exposures.value" style="width:70px">
            </InputNumber>
          </Row-Col>
          <Row-Col span="6">
            <Select
            v-model="config.campaign.frequency.exposures.breakdown"
            :transfer="true"
            style="width:90px"
            >
              <Option
                  v-for="(item, index) in dbmEnums.exposureBreakdowns"
                  :key="parseInt(index)"
                  :value="parseInt(index)"
                  >{{ item }}
              </Option>
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Divider orientation="left">Insertion Order</Divider>
      <Form-Item label="Insertion Order Name" prop="insertionOrder.name" key="insertionOrder.name">
        <Row>
          <Row-Col span="18">
            <i-input :disabled="isInsertionOrderNomenclatureEnabled" v-model="config.insertionOrder.name" />
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Insertion Order Type" prop="insertionOrder.type" key="insertionOrder.type">
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.insertionOrder.type">
              <Radio
                :label="parseInt(id)"
                v-for="(name, id) in dbmEnums.insertionOrderType"
                :key="parseInt(id)"
              >
                <span> {{ name }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Insertion Order Bidding" prop="insertionOrder.bid.kpi" id="config-bidding" key="insertionOrder.bid.kpi">
        <Row>
          <Row-Col span="3">
            <Select
            v-model="config.insertionOrder.bid.kpi"
            :transfer="true"
            style="width:70px"
            >
              <Option
                  v-for="(item, id) in dbmEnums.ioKpiType"
                  :key="parseInt(id)"
                  :value="parseInt(id)"
                  >{{ item }}
              </Option>
            </Select>
          </Row-Col>
          <Row-Col span="2">
            <InputNumber
            :formatter="value => showBidInPercentage(config.insertionOrder.bid.kpi) ? `${value}%` : `${bpCurrencySymbol}${value}`"
            :parser="value => showBidInPercentage(config.insertionOrder.bid.kpi) ? value.replace('%', '') : value.replace(bpCurrencySymbol, '')"
            :min="0"
            :max="showBidInPercentage(config.insertionOrder.bid.kpi) ? 500 : 5000000"
            v-model="config.insertionOrder.bid.amount" style="width:100px">
            </InputNumber>
          </Row-Col>
        </Row>
      </Form-Item>
      <!-- <Form-Item label="Insertion Order Status" prop="insertionOrder.status">
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.insertionOrder.status">
              <Radio
                :label="parseInt(id)"
                v-for="(name, id) in dbmEnums.ioStatus"
                :key="parseInt(id)"
              >
                <span> {{ name }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item> -->
      <Form-Item label="Insertion Order Pacing" prop="insertionOrder.pacing" key="insertionOrder.pacing">
        <Row>
          <Row-Col span="3">
            <Select
            v-model="config.insertionOrder.pacing.period"
            :transfer="true"
            style="width:70px"
            >
              <Option
                  v-for="(item, index) in dbmEnums.pacingPeriod"
                  :key="parseInt(index)"
                  :value="parseInt(index)"
                  >{{ item }}
              </Option>
            </Select>
          </Row-Col>
          <Row-Col span="3">
            <Select
            v-model="config.insertionOrder.pacing.type"
            :transfer="true"
            style="width:70px"
            >
              <Option
                  v-for="(item, index) in dbmEnums.pacingType"
                  :key="parseInt(index)"
                  :value="parseInt(index)"
                  :disabled="config.insertionOrder.pacing.period == 2 && parseInt(index) == 1"
                  >{{ item }}
              </Option>
            </Select>
          </Row-Col>
          <Row-Col span="2">
            <InputNumber
            :min="1"
            v-if="config.insertionOrder.pacing.period == 2" v-model="config.insertionOrder.pacing.value" style="width:70px">
            </InputNumber>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Insertion Order Optimization" prop="insertionOrder.optimization.type" key="insertionOrder.optimization.type">
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.insertionOrder.optimization.type">
              <Radio
                :label="parseInt(id)"
                v-for="(name, id) in dbmEnums.optimizationType"
                :key="parseInt(id)"
                :disabled="isYoutubeSelected && id == 1"
              >
                <span> {{ name }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
          <Row-Col span="12" v-if="config.insertionOrder.optimization.type == 1">
            <Select
              v-model="config.insertionOrder.optimization.biddingAdjustment"
              placeholder="Bidding Type"
              :transfer="true"
            >
              <OptionGroup label="Performance">
                <Option
                  v-for="(item, id) in dbmEnums.optimizationPerformanceOptions"
                  :key="parseInt(id)"
                  :value="parseInt(id)"
                  >{{ item }}</Option
                >
              </OptionGroup>
              <OptionGroup label="Brand">
                <Option
                  v-for="(item, id) in dbmEnums.optimizationBrandOptions"
                  :key="parseInt(id)"
                  :value="parseInt(id)"
                  >{{ item }}</Option
                >
              </OptionGroup>
            </Select>
          </Row-Col>
          <Row-Col span="18" v-if="config.insertionOrder.optimization.type == 2">
            <Checkbox v-model="config.insertionOrder.optimization.manualOptimization">Automatically optimize your budget allocation</Checkbox>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item v-if="showInvalidPacingAlert">
        <Alert type="warning" style="width:75%">You can use automatic budget allocation and Flight ASAP insertion order pacing only if creative type is Youtube.</Alert>
      </Form-Item >
      <Form-Item v-if="showInvalidLineItemAlert">
        <Alert type="warning" style="width:75%">Cannot use automatic budget allocation if creative type is both Display and Youtube.</Alert>
      </Form-Item >
      <!-- <Form-Item label="Frequency Cap" prop="insertionOrder.frequency.limit"> -->
      <Form-Item label="Insertion Order Frequency Cap" prop="insertionOrder.frequency" key="insertionOrder.frequency">
        <Row>
          <Row-Col span="4">
              <label>Limit Frequency to</label>
          </Row-Col>
          <Row-Col span="2" v-if="config.insertionOrder.frequency.exposures.breakdown != 7">
              <InputNumber
              :min="1"
              :max="999999999"
              v-model="config.insertionOrder.frequency.limit" style="width:70px">
              </InputNumber>
          </Row-Col>
          <Row-Col span="3" v-if="config.insertionOrder.frequency.exposures.breakdown != 7">
              <label>exposures per</label>
          </Row-Col>
          <Row-Col span="2" v-if="config.insertionOrder.frequency.exposures.breakdown != 7">
            <InputNumber
            :min="1"
            :max="maxExposureValue"
            v-if="config.insertionOrder.frequency.exposures.breakdown != 1" v-model="config.insertionOrder.frequency.exposures.value" style="width:70px">
            </InputNumber>
          </Row-Col>
          <Row-Col span="6">
            <Select
            v-model="config.insertionOrder.frequency.exposures.breakdown"
            :transfer="true"
            style="width:90px"
            >
              <Option
                  v-for="(item, index) in dbmEnums.exposureBreakdowns"
                  :key="parseInt(index)"
                  :value="parseInt(index)"
                  >{{ item }}
              </Option>
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Divider orientation="left">Line Item</Divider>
      <Form-Item label="Line Item Name" prop="lineItem.name" key="lineItem.name">
        <Row>
          <Row-Col span="18">
            <i-input :disabled="isLineItemNomenclatureEnabled" v-model="config.lineItem.name" />
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Line Item Pacing" prop="lineItem.pacing" key="lineItem.pacing">
        <Row>
          <Row-Col span="3">
            <Select
            v-model="config.lineItem.pacing.period"
            :transfer="true"
            style="width:70px"
            >
              <Option
                  v-for="(item, index) in dbmEnums.pacingPeriod"
                  :key="parseInt(index)"
                  :value="parseInt(index)"
                  >{{ item }}
              </Option>
            </Select>
          </Row-Col>
          <Row-Col span="3">
            <Select
            v-model="config.lineItem.pacing.type"
            :transfer="true"
            style="width:70px"
            >
              <Option
                  v-for="(item, index) in getLineItemPacingType(config.lineItem.pacing.period)"
                  :key="parseInt(index)"
                  :value="parseInt(index)"
                  :disabled="config.lineItem.pacing.period == 2 && parseInt(index) == 1"
                  >{{ item }}
              </Option>
            </Select>
          </Row-Col>
          <Row-Col span="2" v-if="config.creativeType.includes(dbmEnums.creativeType.Youtube)">
            <InputNumber
            :min="1"
            v-model="config.lineItem.pacing.value" style="width:70px">
            </InputNumber>
          </Row-Col>
          <Row-Col span="2" v-else>
            <InputNumber
            :min="1"
            v-if="config.lineItem.pacing.period == 2" v-model="config.lineItem.pacing.value" style="width:70px">
            </InputNumber>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Exposure Frequency" prop="lineItem.frequency" key="lineItem.frequency.exposure" v-if="config.creativeType.includes(dbmEnums.creativeType.Youtube)">
        <Row>
          <Row-Col span="4">
              <label>Limit Frequency to</label>
          </Row-Col>
          <Row-Col span="2">
            <InputNumber
            :min="1"
            :max="999999999"
            v-if="config.lineItem.frequency.exposures.breakdown != 1"
            v-model="config.lineItem.frequency.exposures.value"
            style="width:70px">
            </InputNumber>
          </Row-Col>
          <Row-Col span="3" v-if="config.lineItem.frequency.exposures.breakdown != 1">
              <label>exposures per</label>
          </Row-Col>
          <Row-Col span="6">
            <Select
            v-model="config.lineItem.frequency.exposures.breakdown"
            :transfer="true"
            style="width:90px"
            >
              <Option
                  v-for="(item, index) in dbmEnums.lineItemExposureBreakdowns"
                  :key="parseInt(index)"
                  :value="parseInt(index)"
                  >{{ item }}
              </Option>
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="View Frequency" prop="lineItem.frequency.views" key="lineItem.frequency.views" v-if="config.creativeType.includes(dbmEnums.creativeType.Youtube)">
        <Row>
          <Row-Col span="4">
              <label>Limit Frequency to</label>
          </Row-Col>
          <Row-Col span="2">
            <InputNumber
            :min="1"
            :max="999999999"
            v-if="config.lineItem.frequency.views.breakdown != 1"
            v-model="config.lineItem.frequency.views.value"
            style="width:70px">
            </InputNumber>
          </Row-Col>
          <Row-Col span="3" v-if="config.lineItem.frequency.views.breakdown != 1">
              <label>views per</label>
          </Row-Col>
          <Row-Col span="6">
            <Select
            v-model="config.lineItem.frequency.views.breakdown"
            :transfer="true"
            style="width:90px"
            >
              <Option
                  v-for="(item, index) in dbmEnums.lineItemExposureBreakdowns"
                  :key="parseInt(index)"
                  :value="parseInt(index)"
                  >{{ item }}
              </Option>
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Line Item Frequency Cap" prop="lineItem.frequency" key="lineItem.frequency" v-else>
        <Row>
          <Row-Col span="4">
              <label>Limit Frequency to</label>
          </Row-Col>
          <Row-Col span="2" v-if="config.lineItem.frequency.exposures.breakdown != 7">
              <InputNumber
              :min="1"
              :max="999999999"
              v-model="config.lineItem.frequency.limit" style="width:70px">
              </InputNumber>
          </Row-Col>
          <Row-Col span="3" v-if="config.lineItem.frequency.exposures.breakdown != 7">
              <label>exposures per</label>
          </Row-Col>
          <Row-Col span="2" v-if="config.lineItem.frequency.exposures.breakdown != 7">
            <InputNumber
            :min="1"
            :max="maxExposureValue"
            v-if="config.lineItem.frequency.exposures.breakdown != 1" v-model="config.lineItem.frequency.exposures.value" style="width:70px">
            </InputNumber>
          </Row-Col>
          <Row-Col span="6">
            <Select
            v-model="config.lineItem.frequency.exposures.breakdown"
            :transfer="true"
            style="width:90px"
            >
              <Option
                  v-for="(item, index) in dbmEnums.exposureBreakdowns"
                  :key="parseInt(index)"
                  :value="parseInt(index)"
                  >{{ item }}
              </Option>
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item
        label="Conversion tracking"
        prop="adGroup.conversionTracking"
        key="adGroup.conversionTracking"
      >
        <Row>
          <Row-Col span="5">Floodlight activity ID</Row-Col>
          <Row-Col span="4" offset="1">Post-click window</Row-Col>
          <Row-Col span="4" offset="1">Post-view window</Row-Col>
        </Row>
        <template v-for="(tracking, i) in config.adGroup.conversionTracking">
          <Row :key="i">
            <Row>
              <Row-Col span="5">
                <i-input
                  v-model="config.adGroup.conversionTracking[i].floodlightActivityId"
                  placeholder="Activity Id"
                />
              </Row-Col>
              <Row-Col span="4" offset="1">
                <InputNumber
                  :min="0"
                  :formatter="value => `${value / (24 * 60) }`"
                  :parser="value => String(Number(value) * 24 * 60)"
                  v-model="config.adGroup.conversionTracking[i].postClickWindow"
                  style="width: 100%"
                >
                </InputNumber>
              </Row-Col>
              <Row-Col span="4" offset="1">
                <InputNumber
                  :min="0"
                  :formatter="value => `${value / (24 * 60) }`"
                  :parser="value => String(Number(value) * 24 * 60)"
                  v-model="config.adGroup.conversionTracking[i].postViewWindow"
                  style="width: 100%"
                >
                </InputNumber>
              </Row-Col>
              <Row-Col span="3" offset="1" v-if="i > 0">
                <i-button @click="removeConversionTrackingRow(i)">remove</i-button>
              </Row-Col>
            </Row>
            <Row v-if="invalidConversionTracking.includes(Number(i))" class="error-row">
              <div
                class="ivu-form-item-error-tip"
                style="margin: 0; top: -5px"
                >Please enter a floodlight activity ID</div
              >
            </Row>
          </Row>
          <div class="vertical-space" :key="i + 'space'"></div>
        </template>
        <Row>
          <Row-Col span="3">
            <i-button @click="addConversionTrackingRow">Add</i-button>
          </Row-Col>
        </Row>
      </Form-Item>

      <template v-if="config.creativeType.includes(dbmEnums.creativeType.Youtube)">
        <Divider orientation="left">Youtube Settings</Divider>
        <Form-Item label="Line Item Objective type" prop="adGroup.type" key="adGroup.type">
          <Row>
            <Row-Col span="18">
              <Select
                v-model=config.adGroup.type
                placeholder="Select line item type"
                :transfer="true"
                :disabled="isExistingConfig"
                filterable
              >
                <Option
                  v-for="(id, type) in dbmEnums.lineItemType"
                  :key="id"
                  :value="parseInt(id)"
                  >{{ type }}</Option
                >
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Line Item Sub Types" key="adGroup.subType">
          <Row>
            <Row-Col span="18">
              <RadioGroup v-model="config.adGroup.subType">
                <Radio
                  v-for="(id, subType) in dbmEnums.objectiveBasedSubTypes[config.adGroup.type]"
                  :label="parseInt(id)"
                  :key="id"
                >
                  <span>{{ subType }}</span>
                </Radio>
              </RadioGroup>
            </Row-Col>
          </Row>
          <Row>
            <Row-Col span="18">
              <Checkbox
              v-if="config.adGroup.type == dbmEnums.lineItemType['Brand Awareness and Reach'] && config.adGroup.subType == dbmEnums.lineItemSubType['Reach']"
              v-model="config.adGroup.includeBrandShortAds"
              :disabled="true"
              >
                Include in-feed and Shorts ads
              </Checkbox>
              <Checkbox
              v-if="config.adGroup.type == dbmEnums.lineItemType['Product and Brand Consideration']"
              v-model="includeProductShortAds"
              >
                Use multiple formats, including Shorts ads
              </Checkbox>
            </Row-Col>
          </Row>
        </Form-Item>

        <Form-Item label="Adgroup Ad Format" prop="adGroup.adFormat" v-if="showAdFormats" key="adGroup.adFormat">
          <Row>
            <Row-Col span="18">
              <RadioGroup v-model="config.adGroup.adFormat">
                <Radio
                  v-for="(id, adFormat) in getSubTypeAdFormats"
                  :label="parseInt(id)"
                  :key="id"
                >
                  <span> {{ adFormat }} </span>
                </Radio>
              </RadioGroup>
            </Row-Col>
          </Row>
        </Form-Item>

        <Form-Item label="Ad group Name" prop="adGroup.name" key="adGroup.name">
          <Row>
            <Row-Col span="18">
              <Alert>Adgroup name is now configured from target creation screen.</Alert>
            </Row-Col>
          </Row>
        </Form-Item>

        <Form-Item label="Adgroup Bid Strategy" prop="adGroup.maxCPV" key="adGroup.maxCPV" v-if="config.adGroup.type != dbmEnums.lineItemType['Drive Conversions']">
          <Row>
            <Row-Col span="4">
              <label v-if="config.adGroup.type == dbmEnums.lineItemType['Brand Awareness and Reach']">Target CPM</label>
              <label v-if="config.adGroup.type == dbmEnums.lineItemType['Product and Brand Consideration']">Target CPV</label>
            </Row-Col>
            <Row-Col span="4">
              <InputNumber
                :min="0.01"
                :step="0.01"
                :max="999999999"
                placeholder="Enter bid"
                v-model="config.adGroup.maxCPV"
              >
              </InputNumber>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Adgroup Bid Strategy" v-else>
          <Row>
            <Row-Col span="4">
              <label v-if="config.adGroup.type == dbmEnums.lineItemType['Drive Conversions']">Maximize Conversion</label>
            </Row-Col>
          </Row>
        </Form-Item>

      </template>

      <Divider orientation="left">Config</Divider>
      <Form-Item label="Config Name" prop="name" key="name">
        <Row>
          <Row-Col span="18">
            <i-input v-model="config.name" clearable />
          </Row-Col>
        </Row>
      </Form-Item>
    </div>
  </Form>
</template>

<script>
import {
  Divider,
  Row,
  Col,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Option,
  OptionGroup,
  CheckboxGroup,
  Checkbox,
  Input,
  Button,
  InputNumber,
  Alert
} from "iview";
import { mapState, mapMutations } from "vuex";
import { APIService } from "./../../ApiService.js";
import { dbmEnums } from "../../Constants/DbmEnums.js";
import CLHelperMethodsMixin from "../../Mixins/CLHelperMethods.js";
import BulkExportApiService from "../../BulkExport/Components/ApiService.js"

export default {
  props: {
    loadExistingConfigId: { default: "" },
    showConfigForm: { default: false },
    isExistingConfig: { default: false },
    nomenclatureMacro: { default: null }
  },
  components: {
    Divider,
    Row,
    "Row-Col": Col,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Select,
    Option,
    OptionGroup,
    CheckboxGroup,
    Checkbox,
    "i-input": Input,
    "i-button": Button,
    InputNumber,
    Alert
  },
  data () {
    return {
      invalidConversionTracking: [],
      loadExistingConfig: false,
      dv360Exchanges: [],
      dv360SubExchanges: [],
      config: {
        searchEngineTypeId: 9,
        name: "",
        bpseId: 0,
        creativeType: [1],
        insertionOrder: {
          name: "",
          userInput: ["MP_name"],
          type: 1,
          bid: {
            kpi: 1,
            amount: null
          },
          pacing: {
            period: 1,
            type: 3,
            value: 1
          },
          status: 3,
          optimization: {
            type: 1,
            biddingAdjustment: 1,
            manualOptimization: false
          },
          frequency: {
            limit: 1,
            exposures: {
              value: 0,
              breakdown: 1
            }
          }
        },
        adGroup: {
          type: 1,
          adFormat: 1,
          maxCPV: 1,
          videoBidAdjustment: 0,
          name: "",
          conversionTracking: [
            {
              floodlightActivityId: "",
              postClickWindow: 129600,
              postViewWindow: 129600
            }
          ],
          subType: 1,
          includeBrandShortAds: false
        },
        campaign: {
          name: "",
          frequency: {
            limit: 1,
            exposures: {
              value: 0,
              breakdown: 1
            }
          }
        },
        lineItem: {
          name: "",
          pacing: {
            period: 1,
            type: 3,
            value: 1
          },
          frequency: {
            limit: 1,
            exposures: {
              value: 0,
              breakdown: 1
            },
            views: {
              value: 0,
              breakdown: 1
            }
          }
        },
        goal: null,
        exchanges: [],
        subExchanges: []
      },
      validationRules: {
        bpseId: [{ required: true, message: "Account is required" }],
        "goal": [{ required: true, message: "Goal is required" }],
        "campaign.status": [
          { required: true, message: "Please select a campaign status" }
        ],
        "campaign.name": [
          { required: true, message: "Please enter a campaign name" }
        ],
        "campaign.frequency": [
          {
            validator: (rule, value, callback) => {
              if (value.limit > 0) {
                if (value.exposures.breakdown == 1) {
                  callback();
                } else if (value.exposures.value > 0) {
                  callback();
                } else {
                  callback(new Error(`The exposure value must be greater than or equal to 1`));
                }
              } else {
                callback(new Error(`The limit value must be greater than or equal to 1`));
              }
            },
            trigger: 'change',
            required: true
          }
        ],
        "insertionOrder.name": [
          { required: true, message: "Please enter an IO name" }
        ],
        "insertionOrder.frequency": [
          {
            validator: (rule, value, callback) => {
              if (value.limit > 0) {
                if (value.exposures.breakdown == 1) {
                  callback();
                } else if (value.exposures.value > 0) {
                  callback();
                } else {
                  callback(new Error(`The exposure value must be greater than or equal to 1`));
                }
              } else {
                callback(new Error(`The limit value must be greater than or equal to 1`));
              }
            },
            trigger: 'change'
          }
        ],
        "insertionOrder.pacing": [
          {
            validator: (rule, value, callback) => {
              if (value.period == 2) {
                if (value.value > 0) {
                  callback();
                } else {
                  callback(new Error(`The value must be greater than 0`));
                }
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        name: [{ required: true, message: "Please enter a configuration name" }],
        "insertionOrder.bid.kpi": [
          {
            validator: (rule, value, callback) => {
              let amount = this.config.insertionOrder.bid.amount;
              if (!amount || amount <= 0) {
                callback(new Error("Please enter a value greater than 0"))
              }
              if (value == 4 || value == 7) {
                if (amount >= 500) {
                  callback(new Error("Please enter a value less than 500%"))
                }
              }
              if (amount >= 5000000) {
                callback(new Error("Please enter a value less than 5000000"))
              }
              callback();
            },
            required: true
          }
        ],
        // "adGroup.name": [
        //   {
        //     validator: (rule, value, callback) => {
        //       if (!value && this.isYoutubeSelected) {
        //         callback(new Error("Please enter a name"))
        //       }
        //       callback();
        //     }
        //   }
        // ],
        "adGroup.conversionTracking": [
          {
            validator: (rule, value, callback) => {
              this.invalidConversionTracking = [];
              if (this.config.creativeType.includes(1) && this.config.insertionOrder.optimization.biddingAdjustment == 1) {
                for (let [i, conv] of this.config.adGroup.conversionTracking.entries()) {
                  if (!conv.floodlightActivityId) {
                    this.invalidConversionTracking.push(i);
                  }
                }
              }
              callback();
            }
          }
        ],
        "adGroup.maxCPV": [
          {
            validator: (rule, value, callback) => {
              if ((!value || value <= 0) && this.isYoutubeSelected) {
                callback(new Error("Enter a number greater than 0"))
              }
              callback();
            },
            trigger: "change"
          }
        ],
        "lineItem.name": [
          { required: true, message: "Please enter an LI name" }
        ],
        "lineItem.frequency": [
          {
            validator: (rule, value, callback) => {
              if (this.config.creativeType.includes(dbmEnums.creativeType.Youtube)) {
                if (value.exposures.breakdown == 1) {
                  callback();
                } else if (value.exposures.value > 0) {
                  callback();
                } else {
                  callback(new Error(`The exposure value must be greater than or equal to 1`));
                }
              } else {
                if (value.limit > 0) {
                  if (value.exposures.breakdown == 1) {
                    callback();
                  } else if (value.exposures.value > 0) {
                    callback();
                  } else {
                    callback(new Error(`The exposure value must be greater than or equal to 1`));
                  }
                } else {
                  callback(new Error(`The limit value must be greater than or equal to 1`));
                }
              }
            },
            trigger: 'change'
          }
        ],
        "lineItem.frequency.views": [
          {
            validator: (rule, value, callback) => {
              if (this.config.creativeType.includes(dbmEnums.creativeType.Youtube)) {
                if (value.breakdown == 1) {
                  callback();
                } else if (value.value > 0) {
                  callback();
                } else {
                  callback(new Error(`The views value must be greater than or equal to 1`));
                }
              }
            },
            trigger: 'change'
          }
        ],
        "lineItem.pacing": [
          {
            validator: (rule, value, callback) => {
              if (!value.type) {
                callback(new Error(`Please select the Pacing type`));
              } else {
                if (this.config.creativeType.includes(this.dbmEnums.creativeType.Youtube)) {
                  if (value.value > 0) {
                    callback();
                  } else {
                    callback(new Error(`The value must be greater than 0`));
                  }
                } else {
                  if (value.period == 2) {
                    if (value.value > 0) {
                      callback();
                    } else {
                      callback(new Error(`The value must be greater than 0`));
                    }
                  } else {
                    callback();
                  }
                }
              }
            },
            trigger: 'change'
          }
        ]
      },
      dbmEnums,
      maxExposureValue: 1,
      isInsertionOrderNomenclatureEnabled: false,
      isYouTubeAdGroupNomenclatureEnabled: false,
      includeProductShortAds: true,
      isCampaignNomenclatureEnabled: false,
      isLineItemNomenclatureEnabled: false
    };
  },
  mixins: [CLHelperMethodsMixin],
  mounted () {
    this.loadNomenclatureSettings();
    APIService.getDV360Exchanges().then(res => {
      this.dv360Exchanges = res.data.data;
    });
    APIService.getDV360SubExchanges().then(res => {
      this.dv360SubExchanges = res.data.data;
    });
  },
  updated () {
    this.loadNomenclatureSettings();
  },
  watch: {
    loadExistingConfigId (id) {
      if (id) {
        // this.config = JSON.parse(this.campaignLauncherConfigs[id].config);
        this.loadExistingConfig = true
        this.setExistingConfigData(JSON.parse(this.campaignLauncherConfigs[id].config));
        this.config.insertionOrder.status = 3;
        if (!this.isExistingConfig) {
          this.config.name = this.appendCopyToConfigName(this.config.name)
        }
        // sets the subtype for old configs
        this.handleConfigSubtype();

        // for old configs set default advertiser level public inventory
        if (this.config.exchanges === null && this.config.subExchanges === null) {
          this.setPublicInventory(this.config.bpseId);
        }
      }
    },
    "config.bpseId": async function (newVal) {
      if (!this.isExistingConfig && !this.loadExistingConfig) {
        if (newVal && newVal != 0) {
          this.setPublicInventory(newVal);
        } else {
          this.config.exchanges = [];
          this.config.subExchanges = [];
        }
      } else if (!newVal) {
        this.config.exchanges = [];
        this.config.subExchanges = [];
      }
      this.loadExistingConfig = false;
    },
    "config.insertionOrder.frequency.exposures.breakdown" (newVal) {
      if (newVal == 1) {
        this.config.insertionOrder.frequency.exposures.value = 0
      } else {
        this.config.insertionOrder.frequency.exposures.value = 1;
        this.maxExposureValue = this.dbmEnums.maxExposureValue[newVal - 1]
        if (this.config.insertionOrder.frequency.exposures.value > this.maxExposureValue) {
          this.config.insertionOrder.frequency.exposures.value = this.maxExposureValue;
        }
      }
    },
    "config.insertionOrder.pacing.period" (newVal) {
      if (newVal == 2 && this.config.insertionOrder.pacing.type == 1) {
        this.config.insertionOrder.pacing.type = 2;
      }
    },
    "config.adGroup.type": function (val) {
      switch (val) {
        case 1:
          if (this.config.adGroup.subType == dbmEnums.lineItemSubType["Reach"] || this.config.adGroup.subType == dbmEnums.lineItemSubType["Non Skippable"] || this.config.adGroup.subType == dbmEnums.lineItemSubType["Target Frequency"]) {
            this.$set(this.config.adGroup, 'subType', this.config.adGroup.subType);
          } else {
            this.$set(this.config.adGroup, 'subType', dbmEnums.lineItemSubType["Reach"]);
          }
          break;
        case 3:
          this.$set(this.config.adGroup, 'subType', dbmEnums.lineItemSubType["Action"]);
          break;
        case 4:
          this.$set(this.config.adGroup, 'subType', dbmEnums.lineItemSubType["View"]);
          break;
        default:
          break;
      }
    },
    "config.adGroup.subType": function (val) {
      switch (val) {
        case 1:
          this.$set(this.config.adGroup, 'adFormat', this.config.adGroup.includeBrandShortAds ? dbmEnums.liAdformat["In-stream"] : dbmEnums.liAdformat["Efficient reach"]);
          break;
          // case is skipped
          // case 2:
          //   this.config.adGroup.adFormat = dbmEnums.liAdformat["In-stream"];
          //   break;
        case 3:
          this.$set(this.config.adGroup, 'adFormat', dbmEnums.liAdformat["Non-skippable"]);
          break;
        case 4:
          this.$set(this.config.adGroup, 'adFormat', dbmEnums.liAdformat["Responsive"]);
          break;
        case 5:
          if (this.includeProductShortAds) {
            this.$set(this.config.adGroup, 'adFormat', dbmEnums.liAdformat["Responsive"]);
          } else {
            if (this.config.adGroup.adFormat == dbmEnums.liAdformat["In-stream"] || this.config.adGroup.adFormat == dbmEnums.liAdformat["In-feed video"]) {
              this.$set(this.config.adGroup, 'adFormat', this.config.adGroup.adFormat);
            } else {
              this.$set(this.config.adGroup, 'adFormat', dbmEnums.liAdformat["In-stream"]);
            }
          }
          break;
        case 6:
          if (this.config.adGroup.adFormat == dbmEnums.liAdformat["Non-skippable"] || this.config.adGroup.adFormat == dbmEnums.liAdformat["Efficient reach"]) {
            this.$set(this.config.adGroup, 'adFormat', this.config.adGroup.adFormat);
          } else {
            this.$set(this.config.adGroup, 'adFormat', dbmEnums.liAdformat["Non-skippable"]);
          }
          break;
        default:
          break;
      }
    },
    includeProductShortAds (val) {
      if (val) {
        this.$set(this.config.adGroup, 'adFormat', dbmEnums.liAdformat["Responsive"]);
      } else {
        if (this.config.adGroup.adFormat == dbmEnums.liAdformat["In-stream"] || this.config.adGroup.adFormat == dbmEnums.liAdformat["In-feed video"]) {
          this.$set(this.config.adGroup, 'adFormat', this.config.adGroup.adFormat);
        } else {
          this.$set(this.config.adGroup, 'adFormat', dbmEnums.liAdformat["In-stream"]);
        }
      }
    },
    "config.adGroup.includeBrandShortAds": function (val) {
      if (!this.isExistingConfig) {
        if (this.config.adGroup.subType == dbmEnums.lineItemSubType["Reach"]) {
          if (val) {
            this.$set(this.config.adGroup, 'adFormat', dbmEnums.liAdformat["In-stream"]);
          } else {
            this.$set(this.config.adGroup, 'adFormat', dbmEnums.liAdformat["Efficient reach"]);
          }
        }
      }
    },
    "config.lineItem.pacing.period": function (val) {
      if (this.config.creativeType.includes(this.dbmEnums.creativeType.Youtube)) {
        if (val == 1) {
          this.config.lineItem.pacing.type = 3;
        }
      }
    },
    "config.campaign.frequency.exposures.breakdown" (newVal) {
      if (newVal == 1) {
        this.config.campaign.frequency.exposures.value = 0
      } else {
        this.config.campaign.frequency.exposures.value = 1;
        this.maxExposureValue = this.dbmEnums.maxExposureValue[newVal - 1]
        if (this.config.campaign.frequency.exposures.value > this.maxExposureValue) {
          this.config.campaign.frequency.exposures.value = this.maxExposureValue;
        }
      }
    },
    "config.lineItem.frequency.exposures.breakdown" (newVal) {
      if (!this.config.creativeType.includes(dbmEnums.creativeType.Youtube)) {
        if (newVal == 1) {
          this.config.lineItem.frequency.exposures.value = 0
        } else {
          this.config.lineItem.frequency.exposures.value = 1;
          this.maxExposureValue = this.dbmEnums.maxExposureValue[newVal - 1]
          if (this.config.lineItem.frequency.exposures.value > this.maxExposureValue) {
            this.config.lineItem.frequency.exposures.value = this.maxExposureValue;
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      "dbmAccounts",
      "businessProfileId",
      "campaignLauncherConfigs",
      "publisher",
      "deltax",
      "mediaPlanId",
      "selectedMediaPlan",
      "bpCurrencySymbol"
    ]),
    "config.insertionOrder.userInput" () {
      return [...this.config.insertionOrder.name].join();
    },
    isYoutubeSelected () {
      let isYtSelected = this.config.creativeType.includes(this.dbmEnums.creativeType.Youtube);
      if (isYtSelected) {
        this.$set(this.config.insertionOrder.optimization, "type", 2);
        // handle for old config if type or subtype is not present, for yt creative
        if (!this.config.adGroup.type) {
          this.config.adGroup.type = dbmEnums.lineItemType["Brand Awareness and Reach"];
        }
        if (!this.config.adGroup.subType) {
          this.setDefaultSubType(this.config.adGroup.type);
        }
        // set default exposure frequency if Months, Week and Days not selected for creative type youtube
        if (![1, 2, 3, 4].includes(this.config.lineItem.frequency.exposures.breakdown)) {
          this.config.lineItem.frequency.exposures.breakdown = 1;
        }
      }
      return isYtSelected;
    },
    getSubTypeAdFormats () {
      if (this.config.adGroup.subType == dbmEnums.lineItemSubType["View"] && !this.includeProductShortAds) {
        return dbmEnums.subTypeBasedAdFormats[this.dbmEnums.lineItemSubType["Simple"]];
      }
      return dbmEnums.subTypeBasedAdFormats[this.config.adGroup.subType]
    },
    showAdFormats () {
      if ((this.config.adGroup.subType == dbmEnums.lineItemSubType["View"] && this.includeProductShortAds) || (this.config.adGroup.subType == dbmEnums.lineItemSubType["Reach"] && !this.config.adGroup.includeBrandShortAds) || this.config.adGroup.subType == dbmEnums.lineItemSubType["Action"]) {
        return false
      }
      return true
    },
    showInvalidPacingAlert () {
      if (this.config.creativeType.includes(dbmEnums.creativeType.Display) && (this.config.insertionOrder.pacing.type == 2 && this.config.insertionOrder.pacing.period == 1) && (this.config.insertionOrder.optimization.type == 2 && this.config.insertionOrder.optimization.manualOptimization)) {
        return true;
      }
      return false;
    },
    showInvalidLineItemAlert () {
      if (this.config.creativeType.length > 1 && (this.config.insertionOrder.optimization.type == 2 && this.config.insertionOrder.optimization.manualOptimization)) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapMutations([]),
    setPublicInventory (id) {
      var res = this.dbmAccounts.find(account => account.id === id);
      if (res.accountAssets) {
        var parsedAccountAssets = JSON.parse(res.accountAssets);
        if (parsedAccountAssets && parsedAccountAssets.Data &&
                  parsedAccountAssets.Data instanceof Array &&
                  parsedAccountAssets.Data[0].exchanges &&
                  parsedAccountAssets.Data[0].subExchanges) {
          this.config.exchanges = parsedAccountAssets.Data[0].exchanges;
          this.config.subExchanges = parsedAccountAssets.Data[0].subExchanges;
        }
      }
    },
    getConfig () {
      // before retuning the config for save, update the line item subtype to "simple" when subtype is "View" and "includeProductShortAds" is false
      let config = this.config;
      if (this.config.adGroup.subType == dbmEnums.lineItemSubType["View"] && !this.includeProductShortAds) {
        config.adGroup.subType = dbmEnums.lineItemSubType["Simple"];
      }
      return config;
    },
    resetFields () {
      this.resetNestedFields()
      this.$refs["dbmConfig"].resetFields();
    },
    isDBMFormValid (saveCallback) {
      this.checkIfValid = true;
      if (
        this.config.insertionOrder.optimization.biddingAdjustment != 1
      ) {
        this.invalidConversionTracking = [];
      }
      this.$refs["dbmConfig"].validate(valid => {
        this.checkIfValid = false;

        if (valid && this.invalidConversionTracking.length == 0) {
          saveCallback();
        }
      });
    },
    resetNestedFields () {
      this.config.insertionOrder.bid.amount = null
      this.config.insertionOrder.optimization.biddingAdjustment = 1
      this.config.insertionOrder.optimization.manualOptimization = false
      this.config.insertionOrder.frequency.exposures.value = 0
      this.config.insertionOrder.frequency.exposures.breakdown = 1
      this.config.insertionOrder.pacing.type = 3
      this.config.insertionOrder.pacing.value = 1
    },
    addConversionTrackingRow () {
      this.config.adGroup.conversionTracking.push({
        floodlightActivityId: "",
        postClickWindow: 129600,
        postViewWindow: 129600
      });
    },
    removeConversionTrackingRow (index) {
      this.$delete(this.config.adGroup.conversionTracking, index);
    },
    loadNomenclatureSettings () {
      if (this.nomenclatureMacro) {
        if (this.nomenclatureMacro.adSet && this.nomenclatureMacro.adSet != '') {
          this.isInsertionOrderNomenclatureEnabled = true;
          this.config.insertionOrder.name = this.nomenclatureMacro.adSet;
        }
        if (this.nomenclatureMacro.youTubeAdGroup && this.nomenclatureMacro.youTubeAdGroup != '') {
          this.isYouTubeAdGroupNomenclatureEnabled = true;
          this.config.adGroup.name = this.nomenclatureMacro.youTubeAdGroup;
        }
        if (this.nomenclatureMacro.campaign && this.nomenclatureMacro.campaign != '') {
          this.isCampaignNomenclatureEnabled = true;
          this.config.campaign.name = this.nomenclatureMacro.campaign;
        }
        if (this.nomenclatureMacro.ad && this.nomenclatureMacro.ad != '') {
          this.isLineItemNomenclatureEnabled = true;
          this.config.lineItem.name = this.nomenclatureMacro.ad;
        }
      }
    },
    showBidInPercentage (value) {
      if (value == 4 || value == 7) {
        return true;
      }
      return false;
    },
    handleConfigSubtype () {
      if (this.config.adGroup.subType == dbmEnums.lineItemSubType["View"]) {
        this.includeProductShortAds = true;
      } else if (this.config.adGroup.subType == dbmEnums.lineItemSubType["Simple"]) {
        this.config.adGroup.subType = dbmEnums.lineItemSubType["View"];
        this.includeProductShortAds = false;
      }
    },
    setDefaultSubType (val) {
      switch (val) {
        case 1:
          this.$set(this.config.adGroup, 'subType', dbmEnums.lineItemSubType["Reach"]);
          break;
        case 3:
          this.$set(this.config.adGroup, 'subType', dbmEnums.lineItemSubType["Action"]);
          break;
        case 4:
          this.$set(this.config.adGroup, 'subType', dbmEnums.lineItemSubType["View"]);
          break;
        default:
          break;
      }
    },
    setExistingConfigData (existingConfig) {
      this.config = {
        searchEngineTypeId: existingConfig.searchEngineTypeId,
        name: existingConfig.name,
        bpseId: existingConfig.bpseId,
        creativeType: existingConfig.creativeType,
        insertionOrder: existingConfig.insertionOrder,
        adGroup: existingConfig.adGroup,
        campaign: existingConfig.campaign ? existingConfig.campaign : {
          name: "{MP_name}",
          frequency: existingConfig.insertionOrder.frequency ? JSON.parse(JSON.stringify(existingConfig.insertionOrder.frequency)) : {
            limit: 1,
            exposures: {
              value: 0,
              breakdown: 1
            }
          }
        },
        lineItem: existingConfig.lineItem ? existingConfig.lineItem : {
          name: "{MP_name}",
          pacing: existingConfig.insertionOrder.pacing && !existingConfig.creativeType.includes(this.dbmEnums.creativeType.Youtube) ? JSON.parse(JSON.stringify(existingConfig.insertionOrder.pacing)) : {
            period: 1,
            type: 3,
            value: existingConfig.insertionOrder.pacing.value
          },
          frequency: existingConfig.insertionOrder.frequency && !existingConfig.creativeType.includes(this.dbmEnums.creativeType.Youtube) ? {
            limit: JSON.parse(JSON.stringify(existingConfig.insertionOrder.frequency.limit)),
            exposures: JSON.parse(JSON.stringify(existingConfig.insertionOrder.frequency.exposures)),
            views: {
              value: 0,
              breakdown: 1
            }
          } : {
            limit: 1,
            exposures: {
              value: 0,
              breakdown: 1
            },
            views: {
              value: 0,
              breakdown: 1
            }
          }
        },
        goal: existingConfig.goal ? existingConfig.goal : null,
        exchanges: existingConfig.exchanges ? existingConfig.exchanges : null,
        subExchanges: existingConfig.subExchanges ? existingConfig.subExchanges : null
      }
    },
    getLineItemPacingType (pacingPeriod) {
      if (this.config.creativeType.includes(this.dbmEnums.creativeType.Youtube)) {
        if (pacingPeriod == 1) {
          return { 3: "Even" };
        } else {
          return {
            2: "ASAP",
            3: "Even"
          }
        }
      } else {
        if (pacingPeriod == 1) {
          return {
            1: "Ahead",
            3: "Even"
          };
        } else {
          return {
            2: "ASAP",
            3: "Even"
          }
        }
      }
    }
  }
};
</script>
<style scoped>
.macro-list > li {
    display: inline-block;
    *display:inline;
    cursor: pointer;
}
.macros {
  padding-right: 10px;
}
.vertical-space {
  margin-bottom: 10px;
}
.error-row {
  margin-top: 10px;
}
</style>
