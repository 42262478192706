export default {
  setIsEditEnabled (context, value) {
    context.commit('SET_IS_EDIT_ENABLED', value)
  },
  startSpinner (context, text) {
    context.commit('SET_SPINNER_TEXT', text)
    context.commit('SET_SPINNER', true)
  },
  stopSpinner (context) {
    context.commit('SET_SPINNER', false)
    context.commit('SET_SPINNER_TEXT', '')
  },
  setShowModal (context, value) {
    context.commit('SET_SHOWMODAL', value)
  },
  showModal (context, { title, body, callback, ok = 'Yes', cancel = 'Cancel' }) {
    context.commit('SHOW_MODAL', { title, body, callback, ok, cancel })
  },
  startModalSpinner (context) {
    context.commit('START_MODAL_SPINNER')
  },
  closeModal (context) {
    context.commit('CLOSE_MODAL')
  },
  setBpUsers (context, bpusers) {
    context.commit('SET_BPUSERS', bpusers)
  },
  setAuthFlows (context, authFlows) {
    context.commit('SET_AUTHFLOWS', authFlows)
  },
  setAuthFlow (context, { index, authFlow }) {
    context.commit('SET_AUTHFLOW', { index, authFlow })
  },
  addAuthFlow (context, authFlow) {
    context.commit('ADD_AUTHFLOW', authFlow)
  },
  removeAuthFlow (context, { index }) {
    context.commit('REMOVE_AUTHFLOW', { index })
  },
  changeFlowAppliesTo (context, { index, appliesTo }) {
    context.commit('CHANGE_FLOW_APPLIESTO', { index, appliesTo })
  },
  setAuthFlowName (context, { index, name }) {
    context.commit('CHANGE_FLOW_NAME', { index, name })
  },
  addAuthFlowStage (context, { index, stage }) {
    context.commit('ADD_FLOW_STAGE', { index, stage })
  },
  removeAuthFlowStage (context, { index, stageIndex }) {
    context.commit('REMOVE_FLOW_STAGE', { index, stageIndex })
  },
  setAuthFlowStageName (context, { index, stageIndex, name }) {
    context.commit('CHANGE_FLOW_STAGE_NAME', { index, stageIndex, name })
  },
  addAuthFlowStageFaction (context, { index, stageIndex, faction }) {
    context.commit('ADD_FLOW_STAGE_FACTION', { index, stageIndex, faction })
  },
  removeAuthFlowStageFaction (context, { index, stageIndex, factionIndex }) {
    context.commit('REMOVE_FLOW_STAGE_FACTION', { index, stageIndex, factionIndex })
  },
  setAuthFlowStageFactionName (context, { index, stageIndex, factionIndex, name }) {
    context.commit('CHANGE_FLOW_STAGE_FACTION_NAME', { index, stageIndex, factionIndex, name })
  },
  changeAuthFlowStageFactionUsers (context, { index, stageIndex, factionIndex, users }) {
    context.commit('CHANGE_FLOW_STAGE_FACTION_USERS', { index, stageIndex, factionIndex, users })
  },
  setUserFlowApproval (context, { index, userId, approval, comment }) {
    var totalStages = context.state.authFlows[index].Stages.length;
    var flowInreview = false;
    var stageStatus = [];
    for (var i = 0; i < totalStages; i++) {
      var factionsStatus = [];
      for (var j = 0; j < context.state.authFlows[index].Stages[i].Factions.length; j++) {
        if (context.getters.getAllUsersWhoCanAuthorizeFactions.includes(userId)) {
          var permissionType = context.state.authFlows[index].Stages[i].PermissionType
          if (permissionType === context.state.permissionType.Skip) { // Skip
            if (approval) {
              context.commit('SET_AUTH_FLOW_STAGE_FACTION_APPROVAL', { index, stageIndex: i, factionIndex: j, userId, comment })
            } else {
              context.commit('SET_AUTH_FLOW_STAGE_FACTION_REJECTION', { index, stageIndex: i, factionIndex: j, userId, comment })
            }
          } else {
            if (context.state.authFlows[index].Stages[i].Factions[j].Users.map(x => x.Id).includes(userId)) {
              if (approval) {
                context.commit('SET_AUTH_FLOW_STAGE_FACTION_APPROVAL', { index, stageIndex: i, factionIndex: j, userId, comment })
              } else {
                context.commit('SET_AUTH_FLOW_STAGE_FACTION_REJECTION', { index, stageIndex: i, factionIndex: j, userId, comment })
              }
            }
          }
        }
        factionsStatus.push(context.getters.getFactionStatus(index, i, j));
        stageStatus.push(context.getters.getFactionStatus(index, i, j));
      }
      if (factionsStatus.includes("REJECTED")) { // atleast one faction is rejected
        context.commit('SET_AUTH_FLOW_STAGE_STATUS', { index, stageIndex: i, status: "REJECTED" });
      } else if (factionsStatus.length == context.state.authFlows[index].Stages[i].Factions.length &&
                [...new Set(factionsStatus)].length == 1 &&
                [...new Set(factionsStatus)][0] == "APPROVED") { // all factions are approved
        context.commit('SET_AUTH_FLOW_STAGE_STATUS', { index, stageIndex: i, status: "APPROVED" });
      } else {
        context.commit('SET_AUTH_FLOW_STAGE_STATUS', { index, stageIndex: i, status: "IN_REVIEW" });
      }
      if (context.state.authFlows[index].Stages[i].Status == "REJECTED") {
        context.commit('SET_AUTH_FLOW_STATUS_AND_CURRENT_STAGE',
          {
            index: index,
            status: "REJECTED",
            currentStage: context.state.authFlows[index].Stages[i].Id
          });
        break;
      } else if ((!flowInreview) || context.state.authFlows[index].Stages[i].Status == "APPROVED") {
        flowInreview = context.state.authFlows[index].Stages[i].Status != "APPROVED";
        context.commit('SET_AUTH_FLOW_STATUS_AND_CURRENT_STAGE',
          {
            index: index,
            status: i == totalStages - 1
              ? context.state.authFlows[index].Stages[i].Status : "IN_REVIEW",
            currentStage: context.state.authFlows[index].Stages[i].Status != "APPROVED"
              ? context.state.authFlows[index].Stages[i].Id
              : (i + 1 < totalStages)
                ? context.state.authFlows[index].Stages[i + 1].Id
                : context.state.authFlows[index].Stages[i].Id,
            isFlowInReview: stageStatus.includes("IN_REVIEW")
          });
      }
    }
  }
}
