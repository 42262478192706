<template>
   <div class="comments-modal">
      <i-modal
         v-model="showModal"
         width="50%"
         :footer-hide="true"
         :styles="{top: '15rem'}"
         :closable="false"
         :mask-closable="false"
         >
         <h4 class="title" slot="header" >
            Comment on {{mediaPlanName}}
           <i @click="close" class='fa fa-times float-right'></i>
         </h4>
         <div class="modal-body ">

        <div class="modal-body no-padding comments-modal-body">
                <div class="col-md-12">
                    <form class="form-horizontal">
                        <div class="form-group">
                            <div class='col-md-offset-1 col-md-1 align-right '>
                            <i-tooltip :content="currentUser.firstName" :transfer="true">
                                <div class="no-padding initials-box current-user-initials" >{{currentUser.initials}}</div>
                            </i-tooltip>
                            </div>
                            <div class="col-md-6 comment-input no-padding">
                            <textarea placeholder="Enter your comment here" style="width: 100%;" class="comment-textarea" v-model="newComment" v-on:enter="addComment"></textarea>
                            </div>
                            <div class="col-md-3">
                            <button type="button" class="btn btn-success no-margin" v-on:click="addComment" :disabled="!newComment.trim()">
                                <span> Comment </span>
                                <i v-show="showSaveSpinner" class="fa fa-spinner fa-spin"></i>
                            </button>
                            </div>
                        </div>
                    </form>
                </div>
            <template v-if="!showLoader">
                <div class="row comments-container ">
                    <div class="col-md-12 no-padding "  >
                        <div v-if="comments.length!=0">
                            <template v-for="(comment,index) in sortedComments">
                                <div :key="index" class="col-md-12 no-padding comment-info">
                                    <div class="col-md-offset-1 col-md-1">
                                        <i-tooltip :content="comment.firstName">
                                        <div class="no-padding initials-box">
                                            {{comment.initials}}
                                        </div>
                                        </i-tooltip>
                                    </div>
                                    <div class="col-md-9 word-wrap ">
                                        <div>{{comment.body}}</div>
                                        <span class="date-time-info">{{comment.displayDateTime}}</span>
                                    </div>
                                </div>
                            </template>
                        </div>

                     <div class="col-md-12 no-comments-info" v-else>
                            <p> No comments added</p>
                     </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row loader-container">
                        <i class="fa fa-spinner fa-spin fa-2x" ></i>
                </div>
            </template>
        </div>
         </div>
      </i-modal>
   </div>
</template>
<script>
import {
  Modal,
  Tooltip
} from "iview";
import { ApiService } from ".././Services/mediaPlanCommentsApiService.js";
import { mediaPlanCommentType } from ".././Constants/executeMediaPlan.js";

export default {
  components: {
    "i-modal": Modal,
    "i-tooltip": Tooltip
  },
  props:
  {
    mediaPlanName: String,
    mediaPlanId: Number,
    newComment: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      showModal: false,
      showLoader: false,
      showSaveSpinner: false,
      comments: [],
      currentUser: {},
      apiService: {}
    };
  },
  watch: {
  },
  methods: {
    setupModal: function (isPopUpVisible) {
      this.showModal = isPopUpVisible
      this.setData()
    },
    addComment: function () {
      if (!this.newComment.trim()) return;
      var self = this;
      self.showSaveSpinner = true;
      var commentBody = this.newComment.replace(/#(\d+)\s(\(([^)]*)\))/g,
        function (match, p1) {
          return "#" + p1 + " ";
        });
      var saveCommentPromise = this.apiService.saveComment(this.mediaPlanId, commentBody, mediaPlanCommentType.ChangeSuggestion)
      saveCommentPromise.then(response => {
        var displayDateTime = "";
        var today = moment();
        var yesterday = moment().subtract(1, "day");

        if (moment(response.createdAt).isSame(today, "day")) { displayDateTime = "Today " + moment(response.createdAt).format("hh:mm a"); }

        if (moment(response.createdAt).isSame(yesterday, "day")) { displayDateTime = "Yesterday " + moment(response.createdAt).format("hh:mm a"); }

        displayDateTime = moment(response.createdAt).format("MMMM DD, YYYY hh:mm a");

        var mediaPlanComment = {
          id: response.id,
          body: response.body,
          createdAt: response.createdAt,
          userId: self.currentUser.id,
          firstName: self.currentUser.firstName,
          lastName: self.currentUser.lastName,
          mediaPlanId: response.mediaPlanId,
          fullName: self.currentUser.firstName + " " + self.currentUser.lastName,
          initials: self.currentUser.firstName.substring(0, 1).toUpperCase() +
                            (self.currentUser.lastName ? self.currentUser.lastName.substring(0, 1) : self.currentUser.firstName.substring(1, 2)).toUpperCase(),
          displayDateTime: displayDateTime
        };
        self.comments.push(mediaPlanComment);
        self.showSaveSpinner = false;
        self.newComment = "";
      })
    },
    mapMediaPlanComment: function (comment) {
      var displayDateTime = "";
      var today = moment();
      var yesterday = moment().subtract(1, "day");

      if (moment(comment.CreatedAt).isSame(today, "day")) { displayDateTime = "Today " + moment(comment.CreatedAt).format("hh:mm a"); }

      if (moment(comment.CreatedAt).isSame(yesterday, "day")) { displayDateTime = "Yesterday " + moment(comment.CreatedAt).format("hh:mm a"); }

      displayDateTime = moment(comment.CreatedAt).format("MMMM DD, YYYY hh:mm a");

      var mediaPlanComment = {
        body: comment.Body,
        userId: comment.UserId,
        firstName: comment.FirstName,
        lastName: comment.LastName,
        mediaPlanId: comment.MediaPlanId,
        createdAt: comment.CreatedAt,
        fullName: comment.FirstName + " " + comment.LastName,
        initials: comment.FirstName.substring(0, 1).toUpperCase() +
                        (comment.LastName ? comment.LastName.substring(0, 1) : comment.FirstName.substring(1, 2)).toUpperCase(),
        displayDateTime: displayDateTime

      };
      return mediaPlanComment;
    },
    setData: function () {
      var self = this;
      self.comments = [];
      self.showLoader = true;
      var getCommentsPromise = self.apiService.getComments(self.mediaPlanId)
      getCommentsPromise.then(response => {
        var parsedComments = JSON.parse(response);
        var fetchedComments = []
        parsedComments.forEach(comment => {
          fetchedComments.push(this.mapMediaPlanComment(comment))
        })
        self.comments = fetchedComments;
        self.showLoader = false;
      })
    },
    setupUserModel: function () {
      var user = JSON.parse(deltax.user);
      this.currentUser = {
        id: user.Id,
        firstName: user.FirstName,
        lastName: user.LastName,
        initials: user.UserInitials,
        fullName: user.FullName
      }
    },
    close: function () {
      this.showModal = false;
    }
  },

  created () {
    this.apiService = new ApiService();
  },
  mounted () {
    $(".comments-container").perfectScrollbar({ suppressScrollX: true });
    this.setupUserModel();
  },
  computed: {
    sortedComments: function () {
      var sorted = this.comments.sort(function (a, b) {
        return moment(b.createdAt) - moment(a.createdAt);
      });
      return sorted;
    }
  }
};
</script>
<style scoped>
.overflow-hidden{
  overflow-y: hidden !important;
  overflow-x: visible !important;
}
.modal-body{
    padding-bottom: 30px;
}
::v-deep .ivu-modal-body {
    padding: 0px !important;
  }
::v-deep .ivu-modal-header {
    padding-top: 1px;
    padding-bottom: 1px;
  }
::v-deep .ivu-select-input::placeholder {
  /* Firefox, Chrome, Opera */
  font-weight: 400 !important;
  border-color: 1px solid #cecdcd !important;
  color: black !important;
}
::v-deep .ivu-radio-checked .ivu-radio-inner{
  border-color: grey !important;
}
::v-deep .ivu-radio-checked .ivu-radio-inner::after{
  background-color: grey !important;
}
::v-deep .ivu-select-group-title {
    color: black;
    font-weight: bold;
}
::v-deep .ivu-input:hover {
  border-color: #ddd;
}
::v-deep .ivu-input:focus,
::v-deep .ivu-input-focused {
  border-color: #ddd !important;
  box-shadow: 0 0 0 1px white !important;
}
.icon-size{
  font-size: 20px;
  margin-top: 2px;
}
.initials-box {
    border: 1px solid #979898;
    width: 25px;
    height: 15px;
    text-align: center;
    vertical-align: middle;
    line-height: 14px;
    font-size: 10px;
}
.comment-info {
    margin: 5px 0;
}
.comment-text {
    font-size: 12px;
    white-space: pre-wrap;
}
.date-time-info {
    color: #979898;
    font-size: 9px;
}
.comments-container {
    min-height: 100px;
    max-height: 200px;
    position: relative;
    overflow: hidden;
    width: 96%;
    margin:0
}
.line-item-link {
    font-weight: 700;
    cursor: pointer;
}
.loader-container, .no-comments-info {
    min-height: 80px;
    text-align: center;
}
.loader-container i {
        position: absolute;
        top: 50%;
}
.no-comments-info p {
    top: 50%;
}
.comments-modal-body {
    padding-top: 0 !important;
    overflow-x: inherit !important;
    overflow: inherit !important;
}
.comments-modal-body .row {
    margin: 10px !important;
}
.deleted-line-item {
    font-weight: 700;
    cursor: pointer;
    color: #979898;
}
.float-right{
    float: right;
}
.align-right{
    text-align: right;
}
.comment-textarea{
    resize: vertical;
    width: 100%;
    height: 75px;
}
.word-wrap{
    word-wrap: break-word;
    white-space: pre-wrap;
}
</style>
