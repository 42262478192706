<template>
    <div>
        <i-modal
        v-model="showModal"
        width="55%"
        :closable="false"
        :mask-closable="false"
        class-name="vertical-center-modal"
        >
            <div slot="header" class="header-style">
              Add / Edit PO
            </div>
            <div v-if="showSpinner" style="text-align: center">
              <i class="fa fa-spinner fa-spin fa-3x"></i>
            </div>
            <div v-else class="modal-body no-padding" style="height: 520px;">
              <br>
                <i-row class="vertical-center" :gutter="10">
                  <i-col span="6" class="col-heading">
                      Client
                  </i-col>
                  <i-col span="17">
                      <i-select v-model="businessProfileId" :disabled="disableClientSelect">
                          <i-option
                              v-for="businessProflie in businessProfiles"
                              :value="businessProflie.id"
                              :key="businessProflie.id">{{ businessProflie.name }}</i-option>
                      </i-select>
                  </i-col>
                </i-row>
            <br>
                <i-row class="vertical-center" :gutter="10">
                  <i-col span="6" class="col-heading">
                      Client IO Number
                  </i-col>
                  <i-col span="17">
                      <i-input v-model="clientIONumber">
                      </i-input>
                  </i-col>
                </i-row>
            <br>
                <i-row class="vertical-center" :gutter="10">
                  <i-col span="6" class="col-heading">
                      Amount
                  </i-col>
                  <i-col span="17">
                      <i-input type="number" v-model="amount">
                      </i-input>
                  </i-col>
                </i-row>
            <br>
                <i-row class="vertical-center" :gutter="10">
                  <i-col span="6" class="col-heading">
                      Notes
                  </i-col>
                  <i-col span="17">
                      <i-input v-model="comment">
                      </i-input>
                  </i-col>
                </i-row>
            <br>
                <i-row class="vertical-center" :gutter="10">
                  <i-col span="6" class="col-heading">
                      Issued Date
                  </i-col>
                  <i-col span="17">
                      <i-datepicker
                      :clearable="false"
                      :editable="false"
                      format="dd MMM yyyy"
                      placement="bottom"
                      v-model="issuedDate"></i-datepicker>
                  </i-col>
                </i-row>
            <br>
                <i-row class="vertical-center" :gutter="10">
                  <i-col span="6" class="col-heading">
                      Date Range
                  </i-col>
                  <i-col span="17">
                      <i-datepicker
                        type="daterange"
                        format="MMM d, yyyy"
                        placement="top"
                        v-model="dateRange"
                        :clearable="false"
                        :editable="false">
                      </i-datepicker>
                  </i-col>
                </i-row>
            <br>
                <i-row class="vertical-center" :gutter="10">
                  <i-col span="6" class="col-heading">
                      Attachments
                  </i-col>
                  <i-col span="17">
                    <div v-if="fileName!=null">
                      Uploaded File : {{fileName}}
                      <span>
                        <i
                        class="fa fa-trash delete-file purchase-order-icon"
                        @click="deleteFile"></i>
                      </span>
                      <span v-if="showDownload">
                        <i
                        class="fa fa-download purchase-order-icon"
                        @click="downloadPO()"></i>
                      </span>
                    </div>
                    <div v-else>
                      <i-upload
                        type="drag"
                        :before-upload="uploadFile"
                        action=""
                        accept=".pdf"
                        :format="acceptedFormat"
                        name="attachments">
                        <div style="padding: 10px 0">
                          Drag & Drop the IO here or <span style="color: green;"><u> Browse files</u></span>
                        </div>
                      </i-upload>
                    </div>
                  </i-col>
                </i-row>
            <br>
            </div>
            <div class="footer-wrapper" slot="footer">
              <button
                class="btn create-btn btn-success button-style"
                @click="addPurchaseOrder"
                :disabled="buttonDisable"
              >
                <span v-if="buttonDisable"><i class="fa fa-spinner fa-spin fa-1x"></i></span>
                Save PO
              </button>
              <button
                class="btn create-btn btn-white button-style"
                @click="cancelAction"
              >
                Cancel
              </button>
          </div>
        </i-modal>
    </div>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Select,
  Option,
  Input,
  DatePicker,
  Upload,
  locale
} from "iview";
import lang from "iview/dist/locale/en-US";
var apiService = require("../PurchaseOrderApiService.js");
locale(lang);
export default {
  props: {
    clientId: Number
  },
  components: {
    "i-modal": Modal,
    "i-row": Row,
    "i-col": Col,
    "i-select": Select,
    "i-option": Option,
    "i-input": Input,
    "i-datepicker": DatePicker,
    "i-upload": Upload
  },
  mounted () {
    $(".perfect-scroll-bar").perfectScrollbar({});
  },
  data: function () {
    return {
      showModal: false,
      businessProfileId: 0,
      businessProfiles: [],
      clientIONumber: "",
      amount: null,
      issuedDate: "",
      dateRange: [],
      showSpinner: true,
      comment: "",
      file: null,
      showDownload: false,
      fileName: null,
      purchaseOrderId: null,
      disableClientSelect: false,
      buttonDisable: false,
      acceptedFormat: ['.pdf']
    };
  },
  computed: {
    startDate: function () {
      return moment(this.dateRange[0]).toDate();
    },
    endDate: function () {
      return moment(this.dateRange[1]).toDate();
    }
  },
  methods: {
    setupModal: function (poId) {
      this.showModal = true;
      this.clearData();
      this.fetchData(poId);
    },
    cancelModal: function () {
      this.showModal = false;
    },
    cancelAction: function () {
      this.showModal = false;
      this.$emit("cancelPurchaseOrderPopUp");
    },
    addPurchaseOrder: function () {
      var purchaseOrder = {
        businessProfileId: this.businessProfileId,
        clientIONumber: this.clientIONumber,
        amount: this.amount,
        comment: this.comment,
        issuedDate: moment(this.issuedDate).toDate(),
        startDate: this.startDate,
        endDate: this.endDate
      };
      if (this.purchaseOrderId != null) {
        purchaseOrder["id"] = this.purchaseOrderId;
      }
      this.buttonDisable = true;
      apiService.AddPurchaseOrder(purchaseOrder)
        .then(res => {
          if (this.file != null) {
            var formData = new FormData();
            formData.append("file", this.file);
            apiService.UploadPurchaseOrderAttachment(formData, res.data.id)
              .then(resp => {
                this.buttonDisable = false;
                this.$emit("cancelPurchaseOrderPopUp");
                this.showModal = false;
              });
          } else {
            this.buttonDisable = false;
            this.$emit("cancelPurchaseOrderPopUp");
            this.showModal = false;
          }
        }).catch((exc) => {
          console.log("Save PO Failed" + exc);
        });
    },
    uploadFile: function (file) {
      this.file = file;
      this.fileName = file.name;
    },
    deleteFile: function () {
      this.file = null;
      this.fileName = null;
      this.showDownload = false;
    },
    clearData: function () {
      if (!this.disableClientSelect) {
        this.businessProfileId = 0;
      }
      this.clientIONumber = "";
      this.amount = null;
      this.issuedDate = "";
      this.dateRange = [];
      this.comment = "";
      this.file = null;
      this.fileName = null;
      this.showDownload = false;
      this.purchaseOrderId = null;
    },
    setPoFileName: function (poId) {
      apiService.DownloadFile(poId).then(successResponse => {
        var fileName = null;
        if (successResponse.headers['content-disposition'] && successResponse.headers['content-disposition'] != "") {
          fileName = successResponse.headers['content-disposition'].split('filename=')[1].split(';')[0];
          var substringStartIndex = 0;
          var substringEndIndex = fileName.length;
          if (fileName.startsWith("\"")) {
            substringStartIndex = 1;
          }
          if (fileName.endsWith("\"")) {
            substringEndIndex = fileName.length - 1;
          }
          fileName = fileName.substring(substringStartIndex, substringEndIndex);
        }
        this.fileName = fileName;
        this.showSpinner = false;
        this.showDownload = true;
      }, failureResponse => {
        this.showSpinner = false;
        console.log("No Attachment for this purchase order");
      });
    },
    downloadPO: function () {
      apiService.DownloadFile(this.purchaseOrderId).then(successResponse => {
        const url = window.URL.createObjectURL(new Blob([successResponse.data], { type: successResponse.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        var fileName = "";
        if (successResponse.headers['content-disposition'] && successResponse.headers['content-disposition'] != "") {
          fileName = successResponse.headers['content-disposition'].split('filename=')[1].split(';')[0];
          var substringStartIndex = 0;
          var substringEndIndex = fileName.length;
          if (fileName.startsWith("\"")) {
            substringStartIndex = 1;
          }
          if (fileName.endsWith("\"")) {
            substringEndIndex = fileName.length - 1;
          }
          fileName = fileName.substring(substringStartIndex, substringEndIndex);
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }, failureResponse => {
        alert("No Attachment for this purchase order");
      });
    },
    fetchData: function (poId) {
      this.showSpinner = true;
      var promises = [];
      var getBusinessProfilesPromise = apiService.GetBusinessProfiles();
      promises.push(getBusinessProfilesPromise);
      Promise.all(promises).then((res) => {
        this.businessProfiles = res[0].data.data;
        if (this.clientId != undefined) {
          this.businessProfileId = this.clientId;
          this.disableClientSelect = true;
        }
        if (poId != undefined) {
          apiService.GetPurchaseOrdersById(poId).then((response) => {
            this.purchaseOrderId = response.data.data.id;
            this.businessProfileId = response.data.data.businessProfileId;
            this.clientIONumber = response.data.data.clientIONumber
            this.amount = response.data.data.amount;
            this.comment = response.data.data.comment;
            this.issuedDate = moment(response.data.data.issuedDate).toDate();
            this.dateRange = [
              moment(response.data.data.startDate).toDate(),
              moment(response.data.data.endDate).toDate()
            ];
            this.setPoFileName(poId);
          });
        } else {
          this.showSpinner = false;
        }
      });
    }
  }
};
</script>
<style scoped>
.footer-wrapper {
  height: 3em;
}
.header-style {
  font-size: 20px;
  font-weight: 500;
}
::v-deep .ivu-modal-footer {
  padding-bottom: 8px !important;
}
/* ::v-deep .ivu-picker-panel-body {
  z-index: 10;
} */
.overflow-visible {
  overflow: visible !important;
}
.vertical-center {
    display: flex;
    align-items: center;
}
.col-heading {
    text-align: right;
}
.vertical-center-modal{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.purchase-order-icon {
    font-size: 1.5em;
    margin-right: 10px;
    margin-left: 10px;
}
::v-deep .btn {
  margin-bottom: 10px !important;
}
</style>
<style>
.ivu-input::-webkit-outer-spin-button,
.ivu-input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
