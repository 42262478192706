<template>
  <div
    v-bind:class="[isCampaignLauncher ? 'horizontalPlacement' : 'verticalPlacement']" >
      <AuthorizationFlowStageSetup
        v-for="stage in authStages"
        :key="stage.id"
        :users="users"
        :stage="stage"
        :isCampaignLauncher="isCampaignLauncher"
        :selectedData="authData"
        @updatedAuthFlow="updateAuthorizationFlowJSON"
      />
  </div>
</template>

<script>
import AuthorizationFlowStageSetup from './AuthorizationFlowStageSetup.vue'
import { mapGetters } from "vuex";
export default {
  components: {
    AuthorizationFlowStageSetup
  },
  data: function () {
    return {
      authFlowJson: {},
      authorizationFlowJSONSkeleton: {
        "Id": null,
        "Name": null,
        "Stages": [
          {
            "Id": "STAGE_1",
            "Order": 1,
            "Name": "Reviewer",
            "PermissionType": 2,
            "Factions": [
              {
                "Order": 1,
                "Id": "FACTION_1",
                "Name": "Faction1",
                "Users": []
              }
            ],
            "Actions": []
          },
          {
            "Id": "STAGE_2",
            "Order": 2,
            "Name": "Authorizer",
            "PermissionType": 2,
            "Factions": [
              {
                "Order": 1,
                "Id": "FACTION_1",
                "Name": "Faction1",
                "Users": []
              }
            ],
            "Actions": []
          }
        ],
        "AppliesTo": []
      },
      appliesTo: {
        0: {
          "Id": 0,
          "Name": "MEDIAPLAN_BULK_CREATION",
          "Label": "MEDIA PLAN BULK CREATION"
        },
        1: {
          "Id": 1,
          "Name": "Search",
          "Label": "Search"
        },
        2: {
          "Id": 2,
          "Name": "Social",
          "Label": "Social"
        },
        11: {
          "Id": 11,
          "Name": "AMS",
          "Label": "AMS"
        }
      },
      authData: {
      },
      parsedAuthFlow: {}
    };
  },
  props: {
    users: {
      type: Array,
      default: function () {
        return []
      }
    },
    integrationType: { default: 0 },
    isCampaignLauncher: { default: false },
    authorizationFlowData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  mounted: function () {
    this.authFlowData();
  },
  computed: {
    ...mapGetters('Settings', [
      'authStages'
    ])
  },
  methods: {
    updateAuthorizationFlowJSON (value) {
      if (value.stageId != null && (!this.authorizationFlowData || Object.keys(this.authorizationFlowData).length === 0)) {
        this.alterAuthFlowJson(this.authorizationFlowJSONSkeleton, value.stageId, value.userIds, value.stagePermissionType)
      } else {
        this.alterAuthFlowJson(this.parsedAuthFlow, value.stageId, value.userIds, value.stagePermissionType)
      }
    },
    alterAuthFlowJson (processingJSON, stageId, userIds, permissionType) {
      var users = this.getUserObjectFromId(userIds)
      if (processingJSON.Stages[stageId].Factions[0]) {
        processingJSON.Stages[stageId].Factions[0].Users = users
        processingJSON.Stages[stageId].PermissionType = permissionType || 2
        processingJSON.Name = this.appliesTo[this.integrationType].Name
        processingJSON.Id = this.appliesTo[this.integrationType].Id
        processingJSON.AppliesTo[0] = this.appliesTo[this.integrationType]
        this.authFlowJson = processingJSON
      }
    },
    authFlowData () {
      if (this.authorizationFlowData && Object.keys(this.authorizationFlowData).length != 0) {
        if (this.authorizationFlowData.authorizationFlowJSON) {
          this.parsedAuthFlow = JSON.parse(this.authorizationFlowData.authorizationFlowJSON)
        } else if (this.authorizationFlowData.Id) {
          this.parsedAuthFlow = this.authorizationFlowData
        }
        if (this.parsedAuthFlow && this.parsedAuthFlow.Stages) {
          var reviewer = {
            users: this.parsedAuthFlow.Stages.find(x => x.Name === "Reviewer").Factions[0].Users,
            permissionType: this.parsedAuthFlow.Stages.find(x => x.Name === "Reviewer").PermissionType
          }
          var authorizer = {
            users: this.parsedAuthFlow.Stages.find(x => x.Name === "Authorizer").Factions[0].Users,
            permissionType: this.parsedAuthFlow.Stages.find(x => x.Name === "Authorizer").PermissionType
          }
          this.$set(this.authData, 'Reviewer', reviewer)
          this.$set(this.authData, 'Authorizer', authorizer)
        }
      }
    },
    getUserObjectFromId (userIds) {
      if (userIds) {
        var usersList = this.users.filter(function (obj) {
          return userIds.some(function (obj2) {
            return obj.id === obj2;
          });
        });
        var usableUsersList = usersList.map(x => (
          {
            "Id": x.id,
            "FirstName": x.firstName,
            "Initials": (x.firstName ? x.firstName[0] : '') + (x.lastName ? x.lastName[0] : ""),
            "LastName": x.lastName
          }
        ))
        return usableUsersList;
      }
    }
  },
  watch: {
    'authFlowJson': {
      handler (newValue) {
        this.$emit("updatedAuthFlowJSON", { authFlowJson: newValue, engineName: this.appliesTo[this.integrationType].Name });
      },
      deep: true
    }
  }
}
</script>
<style scoped>
.verticalPlacement {
    display: inline-flex;
    vertical-align: sub;
}
.horizontalPlacement {
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
}
</style>
