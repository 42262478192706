import axiosInstance from '../../../../App/Api'

let ApiService = {

  getPortals () {
    const getPortals = deltax.getPortals

    const promise = axiosInstance({
      method: 'GET',
      url: getPortals
    })
    return promise
  },
  getBusinessProfiles () {
    const getBusinessProfiles = deltax.getBusinessProfiles

    const promise = axiosInstance({
      method: 'GET',
      url: getBusinessProfiles
    })
    return promise
  },
  getFeatures () {
    const getFeatures = deltax.getFeatures
    
    const promise = axiosInstance({
      method: 'GET',
      url: getFeatures
    })
    return promise
  },
  fetchFeatureUsageInsight (featureId, portalId, businessProfileIds, startDate, endDate) {
    var promise = axiosInstance({
      method: 'GET',
      url: deltax.getFeatureInsight.replace('$$FeatureId$$', featureId),
      contentType: "application/json",
      params: {
        portalId: portalId,
        businessProfileIds: businessProfileIds.join(','),
        startDate: startDate,
        endDate: endDate
      }
    });
    return promise;
  }
}
export { ApiService };
