var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.sdtab == 'Ads')?_c('div',{key:_vm.rerenderKey,staticClass:"hot-div",staticStyle:{"width":"100%","height":"530px","overflow":"auto"}},[_c('hot-table',{ref:"hot1",attrs:{"id":'productshot',"settings":_vm.productSettings}})],1):_c('div',{key:_vm.rerenderKey,staticClass:"hot-div",staticStyle:{"width":"100%","height":"530px","overflow":"hidden"}},[_c('div',{staticClass:"flex"},[(
          this.configColumns.campaign.targetingType == Object.keys(this.amsEnums.targetingTypeForDisplay)[Object.values(this.amsEnums.targetingTypeForDisplay).indexOf('Product Targeting')] ||
            this.configColumns.campaign.targetingType == 3
        )?_c('div',{staticStyle:{"padding-left":"1%"}},[_c('hot-table',{ref:"hot2",staticClass:"ad-sheet-hot",attrs:{"id":'asinscategorieshot',"settings":_vm.asinSettings}})],1):_vm._e(),(
          this.configColumns.campaign.targetingType == Object.keys(this.amsEnums.targetingTypeForDisplay)[Object.values(this.amsEnums.targetingTypeForDisplay).indexOf('Product Targeting')] ||
            this.configColumns.campaign.targetingType == 3
        )?_c('div',{staticStyle:{"padding-left":"1%"}},[_c('hot-table',{ref:"hot3",staticClass:"ad-sheet-hot",attrs:{"id":'negativeAsinshot',"settings":_vm.negativeAsinsSettings}})],1):_vm._e(),(
          this.configColumns.campaign.targetingType == Object.keys(this.amsEnums.targetingTypeForDisplay)[Object.values(this.amsEnums.targetingTypeForDisplay).indexOf('Audience Targeting')] ||
            this.configColumns.campaign.targetingType == 3
        )?_c('div',{staticStyle:{"padding-left":"1%"}},[_c('hot-table',{ref:"hot4",staticClass:"ad-sheet-hot",attrs:{"id":'audienceshot',"settings":_vm.audienceSettings}})],1):_vm._e()]),_c('audience-popup',{attrs:{"showModalProp":_vm.showAudiencePop,"clusterId":_vm.clusterId,"audienceProp":_vm.selectedAudience,"showBidProp":true,"bidProp":this.defaultBid,"currencyCode":this.currencyResponse.bpCurrencySymbol,"country":this.country,"showRefine":true},on:{"AddAudience":function($event){return _vm.AddAudience($event)},"CloseAudiencePopUp":function($event){return _vm.CloseAudiencePopUp($event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }