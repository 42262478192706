const state = {
  isLoading: false,
  loadFailed: false,
  alerts: [
    {
      show: false,
      message: "Failed to load. Please try again",
      type: "alert alert-danger"
    },
    {
      show: false,
      message: "Failed to save your settings. Please try again",
      type: "alert alert-danger"
    },
    {
      show: false,
      message: "Settings saved successfully",
      type: "alert alert-success"
    },
    {
      show: false,
      message: "Please enter all the details properly",
      type: "alert alert-warning"
    },
    {
      show: false,
      message: "Image upload failed",
      type: "alert alert-danger"
    },
    {
      show: false,
      message: "Incorrect nomenclature settings",
      type: "alert alert-danger"
    }
  ],
  factionAuthorizationType: [
    {
      id: 1,
      name: "All"
    },
    {
      id: 2,
      name: "Any"
    },
    {
      id: 3,
      name: "Skip"
    }
  ],
  authStages: [
    {
      id: 0,
      name: "Reviewer"
    },
    {
      id: 1,
      name: "Authorizer"
    }
  ],
  engineTypes:
  {
    All: -1,
    Search: 1,
    Social: 2,
    Display: 3,
    Video: 4,
    Mobile: 5,
    Remarketing: 6,
    Affiliate: 7,
    Email: 8,
    Other: 9,
    PostBoost: 10,
    AMS: 11
  }
};

const getters = {
  isLoading: state => {
    return state.isLoading;
  },
  loadFailed: state => {
    return state.loadFailed;
  },
  alerts: state => {
    return state.alerts;
  },
  factionAuthorizationType: state => {
    return state.factionAuthorizationType;
  },
  authStages: state => {
    return state.authStages;
  },
  engineTypes: state => {
    return state.engineTypes;
  }
}

const mutations = {
  isLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  loadFailed: (state, loadFailed) => {
    state.loadFailed = loadFailed;
  },
  alerts: (state, alert) => {
    // Clear any already shown error messages
    if (alert.show) {
      state.alerts.forEach(e => {
        e.show = false;
        return e;
      });
    }
    state.alerts[alert.index].show = alert.show;
  }
}

const actions = {
  setIsLoading ({ commit }, payload) {
    commit('isLoading', payload);
  },
  setLoadFailed ({ commit }, payload) {
    commit('loadFailed', payload);
  },
  showAlert ({ commit }, payload) {
    commit('alerts', { index: payload,
      show: true });
  },
  hideAlert ({ commit }, payload) {
    commit('alerts', { index: payload,
      show: false });
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
