<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <div class="row">
          <div class="col-md-4">
            <h4>
              Targeting <i v-show="loader" class="fa fa-spinner fa-spin" />
            </h4>
          </div>
          <div class="col-md-6 no-padding">
            <Input
              v-model="search"
              search
              placeholder="Search"
              class="search-bar input-search-bar"
            />
          </div>
          <div
            class="col-md-2 widget-element pull-right"
            :class="{ disabled: businessProfilesSearchEngineId == 0 }"
          >
            <DV360TargetModal />
          </div>
        </div>
      </div>
      <VuePerfectScrollbar class="widget-body">
        <template>
          <div
            style="margin-top:10px"
            v-if="businessProfilesSearchEngineId == 0"
            class="alert alert-info"
          >
            Please select a campaign configuration to see the saved targets.
          </div>
          <div
            style="margin-top:10px"
            v-else-if="linkedInTargets.length <= 0"
            class="alert alert-info"
          >
            There are no saved targets yet, create a new one to get started.
          </div>
        </template>
        <template v-if="businessProfilesSearchEngineId > 0">
          <div v-if="isFetchingTarget">
            <i class="fa fa-spinner fa-spin"/>
          </div>
          <Row
            v-for="target in filteredTargets"
            v-bind:key="target.id"
            class="cl-ivu-row"
            v-else
          >
            <Row>
              <Row-Col span="1">
                <i
                  class="fas fa-copy action-icon"
                  @click="
                    copyToClipboard(
                      'DXT-' +
                        Number.parseInt(businessProfileId).toString(36) +
                        '-' +
                        target.id
                    )
                  "
                >
                </i>
              </Row-Col>

              <Row-Col span="15" offset="1" class="cl-widget-text">
                <span class="target-name">{{ target.name }}</span>
              </Row-Col>
            </Row>
            <Row v-for="(adGroup) in getTargetAdGroups(target.id)" :key="adGroup.id" style="margin-left:25px;">
              <Row-Col span="1">
                <i
                  class="fas fa-copy action-icon"
                  @click="
                    copyToClipboard(
                      'DXTAG-' +
                        Number.parseInt(businessProfileId).toString(36) +
                        '-' +
                        adGroup.id
                    )
                  "
                >
                </i>
              </Row-Col>

              <Row-Col span="15" offset="1" class="cl-widget-text">
                <span class="target-name">{{ adGroup.name }}</span>
              </Row-Col>
            </Row>
          </Row>
        </template>
      </VuePerfectScrollbar>
    </Card>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { Input, locale, Card, Row, Col } from "iview";
import lang from "iview/dist/locale/en-US";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import DV360TargetModal from "./DV360TargetModal.vue";
import { APIService } from "../../ApiService.js";

locale(lang);

export default {
  name: "TargetWidget",
  components: {
    Input,
    VuePerfectScrollbar,
    Card,
    "Row-Col": Col,
    DV360TargetModal,
    Row
  },
  data: function () {
    return {
      search: "",
      loader: false,
      linkedInTargets: [],
      isFetchingTarget: false
    };
  },
  props: {},
  methods: {
    copyToClipboard (value) {
      let textarea = document.createElement("textarea");
      textarea.textContent = value;
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
      } catch (e) {
        alert("Unable to copy to clipboard. Please copy manually.");
      }
      document.body.removeChild(textarea);
    },
    async getTargetsMappedToConfig () {
      let mappedTargetIds = [];
      this.mediaPlanLineItems.forEach(element => {
        if (element.config === this.selectedCampaignLauncherConfigId) {
          mappedTargetIds.push(element.targets);
        }
      });
      mappedTargetIds = new Set(mappedTargetIds.flat());
      let targetsToFetch = [...mappedTargetIds].join(",");
      var targetsMapped = [];
      if (targetsToFetch) {
        this.isFetchingTarget = true;
        try {
          const response = await APIService.getTargets(targetsToFetch);
          targetsMapped = response;
          this.isFetchingTarget = false;
        } catch (error) {
          console.log(error);
        }
      }
      // select the ad groups from target string and keep them in store for name rendering in DV360.vue
      this.$store.state.dv360AdGroups = [];
      targetsMapped.forEach(element => {
        let targetStringObj = JSON.parse(element.targetString);
        if (targetStringObj.adGroups && targetStringObj.adGroups.length) {
          this.$store.state.dv360AdGroups = [...this.$store.state.dv360AdGroups, ...targetStringObj.adGroups]
        }
      });
      return targetsMapped;
    },
    getTargetAdGroups (targetId) {
      let adGroups = [];
      let target = this.filteredTargets.find(x => x.id === targetId);
      if (target) {
        let targetStringObj = JSON.parse(target.targetString);
        adGroups = targetStringObj.adGroups;
      }
      return adGroups;
    },
    ...mapMutations(["set_savedTargetsMapperByBpseId"])
  },
  mounted () {
    this.$watch(
      vm => [
        vm.businessProfilesSearchEngineId,
        vm.selectedCampaignLauncherConfigId,
        vm.mediaPlanLineItems
      ],
      async callback => {
        this.linkedInTargets = await this.getTargetsMappedToConfig();
      }
    );
  },
  computed: {
    ...mapState([
      "businessProfilesSearchEngineId",
      "businessProfileId",
      "savedTargetsMapperByBpseId",
      "labels",
      "selectedCampaignLauncherConfigId",
      "mediaPlanLineItems",
      "savedTargets"
    ]),
    filteredTargets () {
      let searchTerm = this.search.trim();
      if (searchTerm != "") {
        return this.linkedInTargets.filter(e => {
          return e.Name.toLowerCase().includes(searchTerm.toLowerCase());
        });
      }
      return this.linkedInTargets;
    }
  }
};
</script>
<style scoped>
.widget-body > table {
  width: 100%;
}
.widget-body > table > tr > td {
  padding-top: 10px;
}
.widget-element {
  height: 30px;
}
.widget > hr {
  margin: 0px;
}
.loader-div {
  width: inherit;
  text-align: center;
  height: inherit;
}
.ellipsis {
  width: 50%;
  position: relative;
}
.ellipsis:before {
  content: "&nbsp;";
  visibility: hidden;
}
.ellipsis span {
  width: 190px;
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style>
.target-tooltip .tooltip .tooltip-inner {
  max-width: 600px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: normal;
  word-break: initial;
  padding: 5px 10px;
}
.target-info-table {
  max-width: 300px !important;
  min-width: 250px !important;
}
.target-modal .modal-dialog {
  width: 98%;
  height: 100%;
}
.input-search-bar .ivu-input {
  height: 30px;
}
.tooltip-size {
  max-width: 500px;
  max-height: 200px;
}
</style>
<style src="../../../../Styles/dx-iview.css"></style>
