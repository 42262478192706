var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lineItem-container"},[_c('i-divider'),(_vm.lineItem)?_c('i-row',{attrs:{"type":"flex"}},[_c('i-col',[_c('span',[_vm._v(" "+_vm._s(_vm._f("encodeLineItemId")(_vm.lineItem.id))+" ")])]),_c('i-col',[_c('i-divider',{attrs:{"type":"vertical"}})],1),_c('i-col',[_c('span',[_vm._v(_vm._s(_vm.lineItem.theme))])]),_c('i-col',[_c('i-divider',{attrs:{"type":"vertical"}})],1),_c('i-col',[_c('span',[_vm._v(_vm._s(((_vm.formatDate(_vm.lineItem.startDate)) + " - " + (_vm.formatDate(_vm.lineItem.endDate)))))])]),_c('i-col',[_c('i-divider',{attrs:{"type":"vertical"}})],1),_c('i-col',[_c('span',{staticClass:"currency-symbol"},[_vm._v(_vm._s(_vm.currencySymbol))]),_c('span',[_vm._v(_vm._s(((_vm.formatCurrency(_vm.lineItem.budget)) + " (" + (_vm.lineItem.pricingTypeReferenceValueName) + ")")))])])],1):_vm._e(),_c('div',[_c('i-table',{staticClass:"input-table",attrs:{"columns":_vm.tblColumns,"data":_vm.tblData,"disabled-hover":"","border":""},scopedSlots:_vm._u([{key:"language",fn:function(ref){
var index = ref.index;
return [_c('div',[_c('i-select',{class:{'error-border-plan-view': !_vm.tblData[index].isValidLanguage},attrs:{"filterable":"","disabled":!_vm.isEdit},on:{"on-change":function($event){return _vm.validateInputLanguage(index)}},model:{value:(_vm.tblData[index].language),callback:function ($$v) {_vm.$set(_vm.tblData[index], "language", $$v)},expression:"tblData[index].language"}},_vm._l((_vm.languageOptions),function(option){return _c('i-option',{key:option.id,attrs:{"value":option.id}},[_vm._v(_vm._s(option.name))])}),1)],1)]}},{key:"adType",fn:function(ref){
var index = ref.index;
return [_c('div',{class:{'error-border-plan-view': !_vm.tblData[index].isValidAdType}},[_c('i-select',{attrs:{"disabled":!_vm.isEdit || (_vm.tblData[index].specifications ? true : false),"filterable":""},on:{"on-change":function($event){return _vm.validateInputAdType(index)}},model:{value:(_vm.tblData[index].adTypeId),callback:function ($$v) {_vm.$set(_vm.tblData[index], "adTypeId", $$v)},expression:"tblData[index].adTypeId"}},_vm._l((_vm.adTypeOptions),function(optionGroup){return _c('i-optionGroup',{key:optionGroup.optionGroupName,attrs:{"label":optionGroup.optionGroupName}},_vm._l((optionGroup.adTypes),function(item){return _c('i-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.displayName))])}),1)}),1)],1)]}},{key:"specifications",fn:function(ref){
var index = ref.index;
return [_c('button',{class:['spec-button truncate-text-container', { 'disabled-tag': !_vm.isEdit }],attrs:{"disabled":!_vm.tblData[index].adTypeId || !_vm.isEdit},on:{"click":function($event){return _vm.openSpecificationsModal(index, _vm.tblData[index].specifications)}}},[(_vm.tblData[index].specifications)?_c('span',[_vm._v(_vm._s(_vm.formatSpecifications(_vm.tblData[index])))]):_c('span',{staticClass:"set-specifications"},[_c('i',{staticClass:"fa fa-sliders",attrs:{"aria-hidden":"true"}}),_vm._v("Set Specifications")])])]}},{key:"dueDate",fn:function(ref){
var index = ref.index;
return [_c('div',{class:{'error-border-plan-view': !_vm.tblData[index].isValidDueDate}},[_c('i-date-picker',{attrs:{"format":"yyyy-MM-dd","disabled":!_vm.isEdit,"options":_vm.dateOptions,"clearable":false,"editable":false},on:{"on-change":function($event){return _vm.validateInputDueDate(index)}},model:{value:(_vm.tblData[index].dueDate),callback:function ($$v) {_vm.$set(_vm.tblData[index], "dueDate", $$v)},expression:"tblData[index].dueDate"}})],1)]}},{key:"dueTime",fn:function(ref){
var index = ref.index;
return [_c('div',[_c('i-time-picker',{attrs:{"format":"HH:mm","clearable":false,"editable":false,"disabled":!_vm.isEdit},model:{value:(_vm.tblData[index].dueTime),callback:function ($$v) {_vm.$set(_vm.tblData[index], "dueTime", $$v)},expression:"tblData[index].dueTime"}})],1)]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('button',{class:['delete-row-btn', { 'disabled-tag': !_vm.isEdit }],on:{"click":function($event){return _vm.deleteRow(row)}}},[_c('i',{staticClass:"fa fa-trash",attrs:{"aria-hidden":"true"}})])]}}])})],1),_c('div',[(_vm.isEdit)?_c('p',{staticClass:"addRequirement-btn",on:{"click":_vm.addRequirement}},[_vm._v("+ Add Requirement")]):_vm._e()]),_c('div',[_c('i-modal',{attrs:{"footer-hide":false,"closable":false,"mask-closable":false,"class-name":"vertical-center-modal"},model:{value:(_vm.enableConfirmPopup),callback:function ($$v) {_vm.enableConfirmPopup=$$v},expression:"enableConfirmPopup"}},[_c('h4',{attrs:{"slot":"header"},slot:"header"},[_vm._v("\n        Delete Line Item\n      ")]),_c('div',[_c('p',{staticClass:"confirm-box-text-style"},[_vm._v("Are you sure you want to delete?")])]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"btn create-btn btn-white button-style",attrs:{"type":"button"},on:{"click":_vm.confirmBoxCancel}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn create-btn btn-success button-style",attrs:{"type":"button"},on:{"click":_vm.confirmAction}},[_vm._v("OK")])])])],1),_c('div',[_c('i-specifications-modal',{attrs:{"showModal":_vm.showSpecificationsModal,"specificationsData":_vm.specificationsModalData,"adTypeModalData":_vm.adTypeModalData,"view":"plan"},on:{"saveSpecifications":function($event){return _vm.saveSpecifications($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }