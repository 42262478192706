<template>
    <div>
        <div>
          <hot-table ref="table" :settings="settings"></hot-table>
        </div>
    </div>
</template>

<script>
import { HotTable } from "@handsontable/vue"

export default {
  components: {
    "hot-table": HotTable
  },

  data: function () {
    return {
      fixedColumns: [
        {
          title: 'Type',
          type: 'text',
          data: 'type',
          readOnly: true,
          wordWrap: false,
          minwidth: "150px",
          width: "150px"
        },
        {
          title: 'Id',
          type: 'text',
          data: 'id',
          readOnly: true,
          wordWrap: false,
          minwidth: "150px",
          width: "150px"
        },
        {
          title: 'Cost',
          type: 'text',
          data: 'cost',
          readOnly: true,
          wordWrap: false,
          minwidth: "150px",
          width: "150px"
        },
        {
          title: 'Publisher',
          type: 'text',
          data: 'publisher',
          readOnly: true,
          wordWrap: false,
          minwidth: "150px",
          width: "150px"
        }
      ],
      bundleColumns: [],
      lineItemData: [],
      lineItemModes: {
        "Digital": 1,
        "Tv": 2,
        "Radio": 3,
        "Press": 4,
        "OOH": 5,
        "Cinema": 6,
        "Magazine": 7
      },
      mergedRow: [],
      packageId: 0,
      packageTableData: [],
      bundleIdsCount: new Map()
    }
  },
  watch: {
    'mergedRow': function (value) {
      if (value.length != 0) {
        if (this.bundleColumns == 0) {
          this.bundleColumns.push({
            title: "Package Cost",
            data: "bundleCost",
            type: "numeric",
            minwidth: "100px",
            width: "100px",
            validator: 'numeric',
            numericFormat: {
              pattern: {
                thousandSeparated: true,
                mantissa: 2
              }
            }
          })
        }
      } else {
        this.bundleColumns = [];
      }
      this.hot.updateSettings({
        data: [...this.packageTableData],
        mergeCells: [...this.mergedRow],
        columns: [
          ...this.fixedColumns,
          ...this.bundleColumns
        ]
      })
    }
  },
  computed: {
    settings: function () {
      var self = this;
      return {
        data: [],
        height: 370,
        selectionMode: 'multiple',
        columns: [...self.fixedColumns],
        colWidths: [200],
        manualColumnResize: true,
        selectedRange: [],
        manualRowResize: true,
        mergeCells: [],
        afterSelectionEnd: function (row, column, row2, column2) {
          self.setValidMergefalse();
          self.setValidUnmergefalse();
          var recentSelection = [row, column, row2, column2];
          self.selectedRange = [];
          self.selectedRange.push(recentSelection);
          let totalRows = this.countRows();
          // let data = [];

          if (totalRows > 1) {
            const selected = self.selectedRange || [];
            let data = [];

            if (selected.length === 1) {
              data = this.getData(...selected[0]);
            } else {
              for (let i = 0; i < selected.length; i += 1) {
                const item = selected[i];
                data.push(this.getData(...item));
              }
            }

            if (selected.length > 0 && selected[0][1] == 3 && selected[0][3] == 3 && selected[0][0] != selected[0][2]) {
              const rowToMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1], rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };
              const exists = self.mergedRow.some(existingArray => JSON.stringify(existingArray) === JSON.stringify(rowToMerge));
              const firstData = data[0][0];
              const validmerge = data.every(array => array[0] === firstData);

              if (!exists && validmerge) {
                self.setValidMergetrue();
              }
              if (exists) {
                self.setButtonToUnmerge();
              }
            }
          }
        },
        afterChange (changes, source) {
          if (source == "edit") {
            self.editRowData(changes[0][0], changes[0][1], changes[0][3]);
          }
        }
      }
    },
    hot: function () {
      return this.$refs.table.hotInstance;
    }
  },

  methods: {
    editRowData (rowNumber, property, changedValue) {
      let lineItemData = this.hot.getSourceDataAtRow(rowNumber);
      var linItemTochange = this.packageTableData.filter(data => data.bundleId == lineItemData.bundleId);
      var totalCost = 0;
      if (property == "bundleCost") {
        linItemTochange.forEach(element => {
          element.bundleCost = changedValue
          totalCost = totalCost + element.cost;
        })

        linItemTochange.forEach(element => {
          element.bundleDiscount = ((totalCost - changedValue) * 100) / totalCost;
        })
      }
      this.setLineItemData(linItemTochange);
    },
    setLineItemData (linItemTochange) {
      this.$emit("setBundleToLineItem", linItemTochange);
      this.setPackageTableData();
    },
    resetData () {
      this.packageTableData = [];
      this.bundleColumns = [];
      this.lineItemData = [];
      this.mergedRow = [];
      this.setPackageTableData();
    },
    initiatePackageTable (digitalLineItems, offlineLineItems, isStaticPublishereEnabled, lineItemPublishersAPIData) {
      this.resetData();
      this.lineItemData = [...digitalLineItems, ...offlineLineItems];

      if (isStaticPublishereEnabled) {
        this.enableSupplierGrouping(lineItemPublishersAPIData);
      } else {
        this.enablePublisherGrouping();
      }
      this.setPackageTableData();
      this.MergeRowsoninit(this.packageTableData);
    },
    enableSupplierGrouping (lineItemPublishersAPIData) {
      let lineItemSuppliers = [
        ...new Set(this.lineItemData.map(data => data.supplierId)
          .filter(id => id !== undefined && id !== null))
      ];

      lineItemSuppliers.forEach(supplier => {
        let lineItems = this.lineItemData.filter(item => item.supplierId == supplier);
        var matchedSupplier = lineItemPublishersAPIData.find(item => item.supplierId == supplier);

        // Grouping line items by bundleId if it exists
        const groupedByBundle = lineItems.reduce((acc, item) => {
          let key = item.bundleId || "noBundle";
          if (!acc[key]) acc[key] = [];
          acc[key].push(item);
          return acc;
        }, {});

        Object.keys(groupedByBundle).forEach(bundleId => {
          const itemsInBundle = groupedByBundle[bundleId];

          itemsInBundle.forEach(element => {
            let packageData = {
              type: element.hasOwnProperty("lineItemMode") ? Object.keys(this.lineItemModes).find(key => this.lineItemModes[key] == element.lineItemMode) : "Digital",
              id: element.hasOwnProperty("liId") ? element.liId : element.encodedId,
              publisher: matchedSupplier ? matchedSupplier.supplierName : null,
              cost: element.hasOwnProperty("cost") ? element.cost : element.budget,
              bundleCost: element.bundleCost,
              bundleDiscount: element.bundleDiscount,
              bundleId: element.bundleId,
              orderId: element.orderId,
              lineItemMode: element.lineItemMode
            };

            if (element.bundleId) {
              this.packageId = element.bundleId > this.packageId ? element.bundleId : this.packageId;
            }

            this.packageTableData.push(packageData);
          });
        });
      });

      this.handleNullSuppliers();
    },

    handleNullSuppliers () {
      let nullSupplierItems = this.lineItemData.filter(item => item.supplierId == null);
      let lineItemPublishers = [
        ...new Set(nullSupplierItems.map(data => data.publisher ? data.publisher : data.channel)
          .filter(id => id !== undefined && id !== null))
      ];

      lineItemPublishers.forEach(publisher => {
        // Filter items by publisher or channel (if publisher is missing)
        let lineItems = this.lineItemData.filter(item =>
          item.publisher ? item.publisher == publisher : item.channel == publisher
        );

        // Group the items by bundleId (use "noBundle" if bundleId is absent)
        const groupedByBundle = lineItems.reduce((acc, item) => {
          let key = item.bundleId || "noBundle";
          if (!acc[key]) acc[key] = [];
          acc[key].push(item);
          return acc;
        }, {});

        // Process each group within the publisher
        Object.keys(groupedByBundle).forEach(bundleId => {
          const itemsInBundle = groupedByBundle[bundleId];

          itemsInBundle.forEach(element => {
            let packageData = {
              type: element.hasOwnProperty("lineItemMode")
                ? Object.keys(this.lineItemModes).find(key => this.lineItemModes[key] == element.lineItemMode)
                : "Digital",
              id: element.hasOwnProperty("liId") ? element.liId : element.encodedId,
              publisher: publisher,
              cost: element.hasOwnProperty("cost") ? element.cost : element.budget,
              bundleCost: element.bundleCost,
              bundleDiscount: element.bundleDiscount,
              bundleId: element.bundleId,
              orderId: element.orderId,
              lineItemMode: element.lineItemMode
            };

            if (element.bundleId) {
              this.packageId = element.bundleId > this.packageId ? element.bundleId : this.packageId;
            }

            this.packageTableData.push(packageData);
          });
        });
      });
    },

    enablePublisherGrouping () {
      let lineItemPublishers = [
        ...new Set(this.lineItemData.map(data => data.publisher ? data.publisher : data.channel)
          .filter(id => id !== undefined && id !== null))
      ];

      lineItemPublishers.forEach(publisher => {
        // Filter items by publisher or channel (if publisher is missing)
        let lineItems = this.lineItemData.filter(item =>
          item.publisher ? item.publisher == publisher : item.channel == publisher
        );

        // Group the items by bundleId (use "noBundle" if bundleId is absent)
        const groupedByBundle = lineItems.reduce((acc, item) => {
          let key = item.bundleId || "noBundle";
          if (!acc[key]) acc[key] = [];
          acc[key].push(item);
          return acc;
        }, {});

        // Process each group within the publisher
        Object.keys(groupedByBundle).forEach(bundleId => {
          const itemsInBundle = groupedByBundle[bundleId];

          itemsInBundle.forEach(element => {
            let packageData = {
              type: element.hasOwnProperty("lineItemMode")
                ? Object.keys(this.lineItemModes).find(key => this.lineItemModes[key] == element.lineItemMode)
                : "Digital",
              id: element.hasOwnProperty("liId") ? element.liId : element.encodedId,
              publisher: publisher,
              cost: element.hasOwnProperty("cost") ? element.cost : element.budget,
              bundleCost: element.bundleCost,
              bundleDiscount: element.bundleDiscount,
              bundleId: element.bundleId,
              orderId: element.orderId,
              lineItemMode: element.lineItemMode
            };

            if (element.bundleId) {
              this.packageId = element.bundleId > this.packageId ? element.bundleId : this.packageId;
            }

            this.packageTableData.push(packageData);
          });
        });
      });
    },

    getTableSourceData () {
      const tableData = this.hot.getSourceData();
      var bundleIdsPublisher = new Map();
      var bundleIdsCost = new Map();
      var bundleIdsDiscount = new Map();
      tableData.forEach(li => {
        if (!(li.bundleId == undefined || li.bundleId == null || li.bundleId == 0) && (li.publisher)) {
          if (!bundleIdsPublisher.has(li.bundleId)) {
            bundleIdsPublisher.set(li.bundleId, li.publisher);
          }
        }
        if (!(li.bundleId == undefined || li.bundleId == null || li.bundleId == 0) && (li.bundleCost)) {
          if (!bundleIdsCost.has(li.bundleId)) {
            bundleIdsCost.set(li.bundleId, li.bundleCost);
          }
        }
        if (!(li.bundleId == undefined || li.bundleId == null || li.bundleId == 0) && (li.bundleDiscount)) {
          if (!bundleIdsDiscount.has(li.bundleId)) {
            bundleIdsDiscount.set(li.bundleId, li.bundleDiscount);
          }
        }
      });
      tableData.forEach(li => {
        if (li.bundleId && bundleIdsPublisher.has(li.bundleId)) {
          li.publisher = bundleIdsPublisher.get(li.bundleId);
        }
        if (li.bundleId && bundleIdsCost.has(li.bundleId)) {
          li.bundleCost = bundleIdsCost.get(li.bundleId);
        }
        if (li.bundleId && bundleIdsDiscount.has(li.bundleId)) {
          li.bundleDiscount = bundleIdsDiscount.get(li.bundleId);
        }
      })
      return tableData;
    },
    setPackageTableData () {
      this.hot.updateSettings({
        data: [...this.packageTableData]
      })
      let tbldata = this.getTableSourceData();
      this.packageTableData = [...tbldata];
    },
    setValidMergetrue () {
      this.$root.$children[0].isValidSelectionforMerge = true;
    },
    setValidMergefalse () {
      this.$root.$children[0].isValidSelectionforMerge = false;
    },
    setValidUnmergefalse () {
      this.$root.$children[0].isValidSelectionforUnmerge = false;
    },
    setButtonToUnmerge () {
      this.$root.$children[0].isValidSelectionforUnmerge = true;
    },
    mergeCells () {
      var self = this;
      const selected = self.selectedRange || [];
      const rowToMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1], rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };
      const bundleCostMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1] + 1, rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };
      self.mergedRow.push(rowToMerge, bundleCostMerge);
      this.setBundleId();
      self.$root.$children[0].isValidSelectionforMerge = false
    },
    setBundleId () {
      var self = this;
      var minRow = self.selectedRange[0][0] < self.selectedRange[0][2] ? self.selectedRange[0][0] : self.selectedRange[0][2];
      var maxRow = self.selectedRange[0][0] < self.selectedRange[0][2] ? self.selectedRange[0][2] : self.selectedRange[0][0];

      this.packageId++;
      for (var i = minRow; i <= maxRow; i++) {
        var li = this.hot.getSourceDataAtRow(i);
        li.bundleId = this.packageId;
      }
    },
    MergeRowsoninit (lineItemData) {
      this.bundleIdsCount = new Map()
      lineItemData.forEach(li => {
        if (li.bundleId != undefined || li.bundleId != null) {
          if (this.bundleIdsCount.has(li.bundleId)) {
            var bundleCount = this.bundleIdsCount.get(li.bundleId);
            bundleCount = bundleCount + 1;
            this.bundleIdsCount.set(li.bundleId, bundleCount);
          } else {
            this.bundleIdsCount.set(li.bundleId, 1);
          }
        }
      });
      if (this.bundleIdsCount.size != 0) {
        this.mergedRow = [];
        for (var [key, value] of this.bundleIdsCount) {
          if (value > 1) {
            var rowNumber = lineItemData.findIndex(data => data.bundleId == key);
            const rowToMerge = { row: rowNumber, col: 3, rowspan: value, colspan: 1 };
            const bundleCostMerge = { row: rowNumber, col: 4, rowspan: value, colspan: 1 };
            this.mergedRow.push(rowToMerge, bundleCostMerge);
          }
        }
      }
    },
    UnmergeCells () {
      var self = this;
      const selected = self.selectedRange || [];

      const rowToMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1], rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };
      const bundleCostMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1] + 1, rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };
      var indexToRemove = [];

      for (let i = 0; i < self.mergedRow.length; i++) {
        var element = self.mergedRow[i];
        if (self.isValidIndexToUnmerge(element, rowToMerge)) {
          indexToRemove.push(i);
          continue;
        }
        if (self.isValidIndexToUnmerge(element, bundleCostMerge)) {
          indexToRemove.push(i);
          continue;
        }
      }

      var rowRange = self.mergedRow[indexToRemove[0]];
      var UnmergedLineItems = self.handleRowsUnmerege(rowRange.row, rowRange.rowspan);
      indexToRemove.sort(function (a, b) { return b - a });
      indexToRemove.forEach(element => {
        self.mergedRow.splice(element, 1);
      });
      self.setValidUnmergefalse();
      this.setLineItemData(UnmergedLineItems);
    },
    isValidIndexToUnmerge (element, row) {
      if (element.row == row.row &&
           element.col == row.col &&
           element.rowspan == row.rowspan &&
           element.colspan == row.colspan
      ) {
        return true;
      }
    },
    handleRowsUnmerege (row, rowCount) {
      var lineItemsToChange = [];
      const rowData = this.packageTableData[row];
      while (rowCount != 0) {
        const data = this.packageTableData[row];
        data.publisher = rowData.publisher;
        data.bundleCost = null;
        data.bundleDiscount = null;
        data.bundleId = null;
        rowCount--;
        row++;
        lineItemsToChange.push(data);
      }
      return lineItemsToChange;
    }
  }
}
</script>
