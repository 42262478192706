<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <div class="row">
          <div class="col-md-4">
            <h4>Product Sets <i v-show="areCatalogsBeingFetched || areProductSetsBeingFetched" class="fa fa-spinner fa-spin" /></h4>
          </div>
          <div class="col-md-6 no-padding">
            <Input v-model="search" search placeholder="Search" class="search-bar input-search-bar" />
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-success btn-sm widget-element pull-right"
              :class="{'disabled':businessProfilesSearchEngineId == 0}"
              @click="showModal=true"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div>
        <Row>
          <Row-Col>
              <Select
              placeholder="Select a Product Catalog"
              v-model="productCatalog"
              :disabled="areCatalogsBeingFetched"
              :transfer="true"
              filterable
              clearable
              >
              <OptionGroup label="DX Catalogs">
                  <Option
                  v-for="(item, index) in dxProductCatalogs"
                  :key="index"
                  :value="item.Id"
                  >{{ item.Name }} ({{ item.ProductCount }} Products)</Option>
              </OptionGroup>
              <OptionGroup label="Live Catalogs">
                  <Option
                  v-for="(item, index) in liveProductCatalogs"
                  :key="index"
                  :value="item.Id"
                  >{{ item.Name }} ({{ item.ProductCount }} Products)</Option>
              </OptionGroup>
              </Select>
          </Row-Col>
        </Row>
      </div>
      <VuePerfectScrollbar class="widget-body" style="margin-top:10px">
          <template>
            <div
              v-if="areCatalogsBeingFetched"
              class="alert alert-info"
            >Product Catalog is being fetched</div>
            <div
              v-else-if="fetchCatalogsApiCallFailed"
              class="alert alert-info"
            >Product Catalog failed to load</div>
            <div
              v-else-if="productCatalog == null"
              class="alert alert-info"
            >Please select a product catalog</div>
            <div
              v-else-if="areProductSetsBeingFetched"
              class="alert alert-info"
            >Product Sets is being fetched</div>
            <div
              v-else-if="fetchProductSetsApiCallFailed"
              class="alert alert-info"
            >Failed to load Product Sets</div>
            <div
              v-else-if="(!existingProductSets || existingProductSets.length<=0) && !areProductSetsBeingFetched"
              class="alert alert-info"
            >There are no saved product sets yet, add a new one to get started.</div>
          </template>
          <template v-if="existingProductSets || existingProductSets.length != 0">
            <Row v-for="productSet in filteredProductSets" v-bind:key="productSet[idString]" class="cl-ivu-row">
              <Row-Col span="1">
                <i class="fas fa-copy action-icon"
                  @click="copyToClipboard(productSet.id)">
                </i>
              </Row-Col>
              <Row-Col span="15" offset="1" class="cl-widget-text">
                <span class="product-set-name">{{productSet.name + " (" + productSet.product_count + " Products)" }}</span>
              </Row-Col>
            </Row>
          </template>
      </VuePerfectScrollbar>
      <v-modal
        title="Create Product Set"
        v-model="showModal"
        effect="fade"
      >
        <div slot="modal-header" class="modal-header">
          <div style="display:flex;justify-content: space-between;">
            <span>
              <h4 class="modal-title" style="display: inline-block;margin-right:10px">Create Product Set <i v-show="areCatalogsBeingFetched" class="fa fa-spinner fa-spin" /></h4>
            </span>
            <span @click="showModal=false">
              <i class="fa fa-times fa-lg" />
            </span>
          </div>
        </div>
        <div
          slot="modal-body"
          class="modal-body"
          style="min-height:200px;max-height: 530px"
        >
          <Row>
            <Row-Col>
                <Select
                placeholder="Select a Product Catalog"
                v-model="modalProductCatalog"
                :disabled="areCatalogsBeingFetched"
                filterable
                clearable
                >
                <OptionGroup label="DX Catalogs">
                    <Option
                    v-for="(item, index) in dxProductCatalogs"
                    :key="index"
                    :value="item.Id"
                    >{{ item.Name }} ({{ item.ProductCount }} Products)</Option>
                </OptionGroup>
                <OptionGroup label="Live Catalogs">
                    <Option
                    v-for="(item, index) in liveProductCatalogs"
                    :key="index"
                    :value="item.Id"
                    >{{ item.Name }} ({{ item.ProductCount }} Products)</Option>
                </OptionGroup>
                </Select>
            </Row-Col>
            <template v-if="modalProductCatalog > 0">
              <Row-Col
                style="margin:10px"
              >
                <b>Add Filters</b>
              </Row-Col>
              <Row-Col span="22">
                <product-set-filter
                  v-for="(item, index) in newProductSetFilters"
                  :key="index"
                  :filter="item"
                  :index="index"
                  @filterUpdate="updateFilter"
                  @filterRemoval="removeFilter"
                ></product-set-filter>
              </Row-Col>
              <Row-Col span="2">
                <Icon
                  type="md-add"
                  size="24"
                  class="iview-icons add-btn"
                  @click="addFilter()"
                />
              </Row-Col>
            </template>
          </Row>
        </div>
        <div slot="modal-footer" style="position: relative; min-height: 35px;">
            <Button
              type="success"
              size="large"
              :disabled="disableCreateProductSetBtn"
              @click="saveProductSet"
              style="position: absolute; right: 0;"
            >
            Create Product Set
            <i class="fa fa-spinner fa-spin" v-show="processingCreateProductSet"></i>
            </Button>
        </div>
      </v-modal>
    </Card>
  </div>
</template>

<script>
import { APIService } from "./../ApiService.js";
import { mapState } from "vuex";
import ProductSetFilter from "./ProductSetFilter.vue";
import { modal } from "vue-strap";
import { Input, locale, Card, Row, Col, Select, OptionGroup, Option, Icon, Button } from "iview";
import lang from "iview/dist/locale/en-US";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import CopyToClipboard from "./../Mixins/CopyToClipboard.js"
locale(lang);
export default {
  name: "ProductSetWidget",
  components: {
    Input,
    VuePerfectScrollbar,
    Card,
    "Row-Col": Col,
    Row,
    Select,
    Icon,
    Option,
    "product-set-filter": ProductSetFilter,
    OptionGroup,
    Button,
    "v-modal": modal
  },
  mixins: [CopyToClipboard],
  data: function () {
    return {
      search: "",
      loader: false,
      idString: "facebookKey",
      nameString: "completePath",
      config: null,
      liveProductCatalogs: [],
      dxProductCatalogs: [],
      fetchCatalogsApiCallFailed: false,
      areCatalogsBeingFetched: false,
      productCatalog: null,
      existingProductSets: [],
      fetchProductSetsApiCallFailed: false,
      areProductSetsBeingFetched: false,
      showModal: false,
      modalProductCatalog: null,
      processingCreateProductSet: false,
      newProductSetFilters: [{
        type: "availability",
        operator: "i_contains",
        tags: [],
        options: [],
        value: ""
      }],
      productSet: {
        new: null,
        filters: []
      }
    };
  },
  methods: {
    fetchProductCatalogs () {
      this.fetchCatalogsApiCallFailed = false;
      this.areCatalogsBeingFetched = true;
      APIService.getProductCatalogs(this.businessProfileId, this.businessProfilesSearchEngineId)
        .then(response => {
          this.areCatalogsBeingFetched = false;
          this.liveProductCatalogs = JSON.parse(response.data.liveProductCatalogs);
          this.dxProductCatalogs = JSON.parse(response.data.dxProductCatalogs);
        })
        .catch((error) => {
          this.areCatalogsBeingFetched = true;
          console.log(error);
          this.liveProductCatalogs = [];
          this.dxProductCatalogs = [];
          this.fetchCatalogsApiCallFailed = true;
        });
    },
    addFilter () {
      let newFilter = {
        type: "availability",
        operator: "i_contains",
        tags: [],
        options: [],
        value: ""
      };
      this.newProductSetFilters.push(newFilter);
    },
    updateFilter (updatedFilter) {
      this.newProductSetFilters[updatedFilter.index] = updatedFilter.value;
    },
    removeFilter (value) {
      this.newProductSetFilters.splice(value.index, 1);
    },
    generateFilter (value) {
      let filters = [];
      let name = [];
      value.forEach(item => {
        let filter = JSON.stringify({
          '{type}': {
            '{operator}': '{value}'
          }
        });
        filter = filter.replace("{type}", item.type);
        filter = filter.replace("{operator}", item.operator);
        filter = filter.replace("{value}", (["eq", "neq"].includes(item.operator)) ? item.value : item.tags.map(x => x.name));
        let filterValue = (["eq", "neq"].includes(item.operator)) ? [item.value] : item.tags.map(x => x.name)
        name.push(...[item.type, item.operator, ...[filterValue]]);
        filters.push(JSON.parse(filter));
      });

      name = name.join("_");
      let filter = (filters.length > 1) ? JSON.stringify({ "or": filters }) : JSON.stringify(filters[0]);
      return {
        name: name,
        filter: filter
      };
    },
    fetchExistingProductSets (productCatalogId, bpseId) {
      let self = this;
      self.fetchProductSetsApiCallFailed = false
      self.areProductSetsBeingFetched = true
      APIService.getProductSets(this.businessProfileId, bpseId, productCatalogId)
        .then(response => {
          self.areProductSetsBeingFetched = false;
          self.existingProductSets = response.data;
        })
        .catch(() => {
          self.areProductSetsBeingFetched = false
          self.fetchProductSetsApiCallFailed = true
        });
    },
    saveProductSet () {
      let self = this
      this.processingCreateProductSet = true
      var requestData = {
        CatalogId: self.modalProductCatalog,
        Filter: JSON.stringify(self.productSet.new),
        Name: self.productSet.new.name,
        BpseId: this.businessProfilesSearchEngineId
      }
      let url = this.deltax.apiEndPoint +
        "businessProfiles/" + this.businessProfileId + "/accounts/" + this.config.facebookAccountId + "/live/productcatalogs/" + this.modalProductCatalog + "/productsets"
      APIService.postProductSet(url, requestData).then(response => {
        this.processingCreateProductSet = false
        this.showModal = false
        this.$Message.success({ background: true, content: "Product set created successfully" });
        self.productCatalog = self.modalProductCatalog
      }).catch(__ => {
        this.processingCreateProductSet = false
        this.$Message.error({ background: true,
          content: "Failed to create the product set",
          duration: 10
        })
      })
    }
  },
  mounted () {
    let self = this
    this.config = JSON.parse(this.campaignLauncherConfigurations.filter(e => {
      return e.Id == self.selectedCampaignLauncherConfigId
    })[0].ConfigJson)
    this.fetchProductCatalogs();
  },
  computed: {
    ...mapState(["businessProfilesSearchEngineId", "businessProfileId", "selectedCampaignLauncherConfigId", "campaignLauncherConfigurations", "deltax"]),
    filteredProductSets () {
      let searchTerm = this.search;
      if (searchTerm != "") {
        return this.existingProductSets.filter(e => {
          return e.name.toLowerCase().includes(searchTerm.toLowerCase())
        })
      } else {
        return this.existingProductSets;
      }
    },
    disableCreateProductSetBtn () {
      if (this.modalProductCatalog <= 0) {
        return true
      }
      if (this.newProductSetFilters.length == 0) {
        return true
      }
      return this.processingCreateProductSet
    }
  },
  watch: {
    productCatalog () {
      if (this.productCatalog > 0 && !this.showModal) {
        this.fetchExistingProductSets(this.productCatalog, this.businessProfilesSearchEngineId);
      } else {
        this.existingProductSets = []
      }
    },
    newProductSetFilters: {
      handler (value) {
        this.productSet.new = this.generateFilter(value);
        this.productSet.filters = value;
      },
      deep: true
    },
    showModal () {
      if (!this.showModal) {
        this.newProductSetFilters = [{
          type: "availability",
          operator: "i_contains",
          tags: [],
          options: [],
          value: ""
        }]
        this.modalProductCatalog = null
      } else {
        this.productCatalog = null
      }
    },
    selectedCampaignLauncherConfigId () {
      this.productCatalog = 0
      this.fetchProductCatalogs()
    }
  }
}
</script>

<style scoped>
.widget-body > table {
  width: 100%;
}
.widget-body > table > tr > td {
  padding-top: 10px;
}
.widget-element {
  height: 30px;
}
.widget > hr {
  margin: 0px;
}
.loader-div {
  width: inherit;
  text-align: center;
  height: inherit;
}
.ellipsis {
  width: 50%;
  position: relative;
}
.ellipsis:before {
  content: "&nbsp;";
  visibility: hidden;
}
.ellipsis span {
  width: 190px;
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-set-filter-label {
  padding-right: 2rem;
  font-weight: bold;
}
.add-btn {
  margin-top: 0.4rem;
}
.product-set-filter-block {
  padding: 1rem;
  padding-bottom: 0.5rem;
}
.product-set-tooltip .tooltip .tooltip-inner {
  max-width: 600px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: normal;
  word-break: initial;
  padding: 5px 10px;
}
.product-set-info-table {
  max-width: 300px !important;
  min-width: 250px !important;
}
.product-set-modal .modal-dialog {
  width: 98%;
  height: 100%;
}
.input-search-bar .ivu-input {
  height: 30px;
}
.tooltip-size {
  max-width: 500px;
  max-height: 200px;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
