/* 
  Register in your main as
  import { ToolTipDirective } from "../../Directives/tooltip";;
  Vue.directive("tooltip", ToolTipDirective); 
*/

function bsTooltip (el, binding) {
  let trigger;
  if (
    binding.modifiers.focus ||
    binding.modifiers.hover ||
    binding.modifiers.click
  ) {
    const t = [];
    if (binding.modifiers.focus) t.push("focus");
    if (binding.modifiers.hover) t.push("hover");
    if (binding.modifiers.click) t.push("click");
    trigger = t.join(" ");
  }

  $(el).tooltip({
    title: binding.value,
    placement: binding.arg,
    trigger: trigger,
    html: binding.modifiers.html
  });
}
export const ToolTipDirective = {
  bind: bsTooltip,
  update: bsTooltip,
  unbind (el) {
    $(el).tooltip("destroy");
  }
};
