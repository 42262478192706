<template>
   <Alert :type="type" :fade="false" v-if="showAlert" :closable="showCloseIcon" :show-icon="showIcon" class="dx-alert">
     <slot></slot>
     <template v-slot:desc><slot name="desc"></slot></template>
   </Alert>
</template>
<script>
import { Alert } from 'view-design'
export default {
  props: {
    type: { type: String, required: true },
    showIcon: { type: Boolean, default: false },
    showAlertProp: { type: Boolean, default: true },
    showCloseIcon: { type: Boolean, default: false }
  },
  computed: {
    showAlert: {
      get () {
        return this.showAlertProp;
      },
      set (value) {
        this.$emit('update:showAlertProp', value)
      }
    }
  },
  components: {
    Alert
  }
}
</script>
<style scoped>
.dx-alert {
  padding-right: 10px;
}
</style>
