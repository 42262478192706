<template>
    <div class="card">
      <div v-if="targetTypePivot.targets > 0">
        <table class="table table-light table-bordered">
        <thead>
            <tr>
                <th class="table-header">Product Cluster</th>
                <th v-if="isKeywordTarget" class="table-header">Targeted keywords</th>
                <th v-else class="table-header">ASINs to Target</th>
                <th v-if="!isKeywordTarget" class="table-header">Negative Asins</th>
                <th v-if="!isKeywordTarget" class="table-header">Category to Target</th>
                <th class="table-header">Negative Keywords</th>
                <th class="table-header">ASINs to Advertise</th>
                <th class="table-header">Headline</th>
            </tr>
        </thead>
        <tbody  v-for="(record, i) in targetTypePivot.records" :key="i">
            <tr>
                <td class="data-center">{{record.productName}}</td>
                <td v-if="isKeywordTarget" class="data-center">
                  <span>
                    <a
                    data-toggle="collapse"
                    v-bind:href="'#keywordBid-preview-' +  record.id + record.keywordMatchType + record.lineItemId"
                  ><u>{{ record.targetedkeywords }}</u></a>
                  </span >
                </td>
                <td v-else class="data-center">
                  <span>
                    <a
                    data-toggle="collapse"
                    v-bind:href="'#targetbid-preview-' +  record.id + record.lineItemId"
                  ><u>{{ record.asinsToTarget }}</u></a>
                  </span >
                </td>
                <td v-if="!isKeywordTarget" class="data-center">{{record.negativeAsins}}</td>
                <td v-if="!isKeywordTarget" class="data-center">
                  <span>
                    <a
                    data-toggle="collapse"
                    v-bind:href="'#categorybid-preview-' +  record.id  + record.lineItemId"
                  ><u>{{ record.categoryToTarget }}</u></a>
                  </span >
                </td>
                <td class="data-center">
                  <span>
                    <a
                    data-toggle="collapse"
                    v-bind:href="'#negativeKeywordBid-preview-' +  record.id + record.keywordMatchType + record.lineItemId"
                  ><u>{{ record.negativeKeywords }}</u></a>
                  </span >
                </td>
                <td class="data-center">{{record.asinsToAdvertise}}</td>
                <td class="data-center">{{record.headline}}</td>
            </tr>
            <tr v-if= "!isKeywordTarget" class="panel-collapse collapse" v-bind:id="'targetbid-preview-' + record.id + record.lineItemId">
              <td v-bind:colspan="7">
                <amsTargetBidPreview-element
                  :targetDetails = "record.targetDetails"
                  :bid = "record.defaultBid"
                ></amsTargetBidPreview-element>
              </td>
            </tr>
            <tr v-if= "!isKeywordTarget" class="panel-collapse collapse" v-bind:id="'categorybid-preview-' + record.id + record.lineItemId">
              <td v-bind:colspan="7">
                <amsCategoryBidPreview-element
                  :categoryDetails = "record.categoryDetails"
                  :bid = "record.defaultBid"
                ></amsCategoryBidPreview-element>
              </td>
            </tr>
              <tr v-if= "isKeywordTarget" class="panel-collapse collapse" v-bind:id="'keywordBid-preview-' +  record.id + record.keywordMatchType + record.lineItemId">
              <td v-bind:colspan="7">
                <amsKeywordBidPreview-element
                  :keywordDetails = "record.keywordDetails"
                  :bid = "record.defaultBid"
                ></amsKeywordBidPreview-element>
              </td>
            </tr>
            <tr class="panel-collapse collapse" v-bind:id="'negativeKeywordBid-preview-' +  record.id + record.keywordMatchType + record.lineItemId">
              <td v-bind:colspan="7">
                <amsNegativeKeywordBidPreview-element
                  :negativeKeywordDetails = "record.negativeKeywordsDetails"
                ></amsNegativeKeywordBidPreview-element>
              </td>
            </tr>
        </tbody>
      </table>
      </div>
      <div v-else>
        <div class="alert alert-warning" role="alert">
          No details Found
        </div>
      </div>
    </div>
</template>

<script>
import TargetBidPreview from './AmsTargetsBidPreview.vue'
import KeywordBidPreview from './AmsKeywordBidPreview.vue'
import CategoryBidPreview from './AmsCategoryBidPreview.vue'
import NegativeKeywordPreview from './AmsNegativeKeywordPreview.vue'
export default {
  components: {
    'amsTargetBidPreview-element': TargetBidPreview,
    'amsKeywordBidPreview-element': KeywordBidPreview,
    'amsCategoryBidPreview-element': CategoryBidPreview,
    'amsNegativeKeywordBidPreview-element': NegativeKeywordPreview
  },
  mounted: function () {},
  props: {
    clusterDetails: {
      type: Object,
      default: function () {
        return {}
      }
    },
    bid: Number,
    lineItemId: Number
  },
  data: function () {
    return {};
  },
  computed: {
    isKeywordTarget: function () {
      return this.clusterDetails.cluster.amsCluster.targetKeywords.length > 0
    },
    targetTypePivot: function () {
      this.budgetTypeVerified = false;
      let records = [];
      if (Object.keys(this.clusterDetails).length > 0) {
        let record = {
          id: this.clusterDetails.cluster.id,
          productName: this.clusterDetails.cluster.name,
          headline: this.clusterDetails.cluster.headLine,
          asinsToTarget: this.clusterDetails.cluster.amsCluster.asinsToTarget.length > 0 ? this.clusterDetails.cluster.amsCluster.asinsToTarget.join(', ') : "(None)",
          asinsToAdvertise: this.clusterDetails.cluster.amsCluster.asinsToAdvertise.length > 0 ? this.clusterDetails.cluster.amsCluster.asinsToAdvertise.join(', ') : "(None)",
          targetedkeywords: this.clusterDetails.cluster.amsCluster.targetKeywords.length > 0 ? this.clusterDetails.cluster.amsCluster.targetKeywords.join(', ') : "(None)",
          categoryToTarget: this.clusterDetails.categories.length > 0 ? this.clusterDetails.categories.map(e => e.CategoriesToTarget).join(', ') : "(None)",
          negativeAsins: this.clusterDetails.negativeAsins.length > 0 ? this.clusterDetails.negativeAsins.join(',') : "",
          targetDetails: this.clusterDetails.productsToTargets.length > 0 ? this.clusterDetails.productsToTargets : [],
          keywordDetails: this.clusterDetails.keywordsBidInfo.length > 0 ? this.clusterDetails.keywordsBidInfo : [],
          categoryDetails: this.clusterDetails.categories.length > 0 ? this.clusterDetails.categories : [],
          keywordMatchType: this.clusterDetails.keywordsBidInfo.length > 0 ? this.clusterDetails.keywordsBidInfo[0].MatchType : "",
          negativeKeywords: this.clusterDetails.negativeKeywords.length > 0 ? this.clusterDetails.negativeKeywords.map(e => e.NegativeKeywords).join(', ') : "(None)",
          defaultBid: this.bid,
          negativeKeywordsDetails: this.clusterDetails.negativeKeywords.length > 0 ? this.clusterDetails.negativeKeywords : [],
          lineItemId: this.lineItemId
        };
        records.push(record);
        let totalTargets = records.length;
        return { records: records, targets: totalTargets };
      } else {
        return { records: [], targets: 0 };
      }
    }
  }
}
</script>

<style scoped>
  .data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: -3px -3px 5px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background-color: rgba(1,1,1,0);
  padding: 5px
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.table-header{
  border:1px solid #ddd !important;
  padding:10px !important;
}
</style>
