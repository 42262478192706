<template>
  <div class="row auth-stage">
    <div class="col-md-2">
      <div class="row">
        <div class="col-md-12">
          <input v-if="isEditEnabled" class="edit-name-box" v-model="name" ref="nameInput">
          <span v-if="isEditEnabled">
            <i
              class="fa fa-pencil edit-name-pencil"
              v-tooltip="'Edit'"
              @click.stop="focusNameInput(self)"
            ></i>
          </span>
          <label v-if="!isEditEnabled" class="edit-name-label">{{name}}</label>
        </div>
      </div>
    </div>
    <div class="col-md-10" :status="status">
      <div class="row">
        <auth-faction
          v-for="(faction, index) in factions"
          :key="index"
          :flowIndex="flowIndex"
          :stageIndex="stageIndex"
          :factionIndex="index"
          :permissionType="authStage.PermissionType"
          :focusNameInput="focusNameInput"
        ></auth-faction>
        <div v-if="isEditEnabled" v-show="factions.length < 5" class="col-md-2 add-faction">
          <div class="row">
            <div class="col-md-12">
              <a class="anchor-text" @click.stop="addFaction">+Faction</a>
            </div>
          </div>
        </div>
        <div class="col-md-1 pull-right stage-icons">
          <div class="row">
            <div class="col-md-12">
              <i
                v-if="isEditEnabled"
                class="fa fa-trash remove-stage"
                v-tooltip="'Delete'"
                @click.stop="removeAuthFlowStage({index : flowIndex, stageIndex: stageIndex})"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthFaction from "./AuthFaction.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    "auth-faction": AuthFaction
  },
  mounted: function () {
    this.$nextTick(function () { });
  },
  props: {
    flowIndex: { default: -1 },
    stageIndex: { default: -1 },
    focusNameInput: { defult: null }
  },
  data: function () {
    return {
      self: this
    };
  },
  computed: {
    ...mapGetters(["getStageByIndex"]),
    ...mapState(["isEditEnabled"]),
    authStage: function () {
      return this.getStageByIndex(this.flowIndex, this.stageIndex);
    },
    name: {
      get () {
        return this.authStage.Name;
      },
      set (value) {
        this.setAuthFlowStageName({
          index: this.flowIndex,
          stageIndex: this.stageIndex,
          name: value
        });
      }
    },
    factions: function () {
      return this.authStage.Factions;
    },
    status: function () {
      return this.authStage.Status ? this.authStage.Status : "IN_REVIEW";
    }
  },
  methods: {
    ...mapActions([
      "removeAuthFlowStage",
      "addAuthFlowStageFaction",
      "setAuthFlowStageName"
    ]),
    addFaction: function () {
      var order =
        this.factions.length > 0
          ? this.factions[this.factions.length - 1].Order + 1
          : 1;
      var id = [
        "FACTION_1",
        "FACTION_2",
        "FACTION_3",
        "FACTION_4",
        "FACTION_5"
      ].filter(x => !this.factions.map(x => x.Id).includes(x))[0];
      this.addAuthFlowStageFaction({
        index: this.flowIndex,
        stageIndex: this.stageIndex,
        faction: {
          Order: order,
          Id: id,
          Name: `Faction ${order}`,
          Users: []
        }
      });
    }
  }
};
</script>
<style scoped>
.edit-name-box {
  max-width: 120px;
}
.edit-name-label {
  text-align: center;
}
.auth-stage {
  margin-bottom: 15px;
}
.auth-stage > [class*="col-"]:before {
  background: black;
  bottom: -10px;
  content: "";
  left: -11%;
  position: absolute;
  width: 2px;
  top: 22px;
}
.auth-stage > [status*="APPROVED"]:before {
  background: green;
}
.auth-stage > [status*="REJECTED"]:before {
  background: #b94a48;
}
.auth-stage > [class*="col-"]:first-child:before {
  display: none;
}
.auth-stage:last-child > [class*="col-"]:before {
  display: none;
}
.auth-stage > [class*="col-"]:after {
  border: solid black;
  border-width: 0px 2px 2px 0px;
  bottom: -10px;
  content: "";
  left: -11%;
  margin-left: -4px;
  position: absolute;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.auth-stage > [status*="APPROVED"]:after {
  border: solid green;
  border-width: 0px 2px 2px 0px;
}
.auth-stage > [status*="REJECTED"]:after {
  border: solid #b94a48;
  border-width: 0px 2px 2px 0px;
}
.auth-stage > [class*="col-"]:first-child:after {
  display: none;
}
.auth-stage:last-child > [class*="col-"]:after {
  display: none;
}
.stage-icons {
  text-align: right;
  font-size: 1.3em;
}
.remove-stage {
  float: right;
}
</style>
