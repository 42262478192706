<template>
  <div class="authorizations">
    <v-modal class="authflow-modal" v-model="showModalValue" backdrop.bool="true" effect="fade">
      <div slot="modal-header" class="modal-header">
        <h3 class="modal-title header-content">{{modalTitleText}}</h3>
      </div>
      <div slot="modal-body" class="modal-body">
        <p class="body-content multiline-cell">{{modalBodyText}}</p>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-white"
          @click.stop="showModalValue = false"
        >{{modalCancelText}}</button>
        <button type="button" class="btn btn-success" @click.stop="modalConfirmCallback()">
          {{modalOkText}}
          <i :class="modalOkSpinnerClass"></i>
        </button>
      </div>
    </v-modal>
    <div :class="'spinner-box' + (isEditEnabled? ' spinner-box-editMode' : '')" v-show="spinner">
      <i class="fa fa-4x fa-spinner fa-pulse spinner" aria-hidden="true"></i>
      <p class="spinner-text">{{spinnerText}}</p>
    </div>
    <div class="col-md-12">
      <div v-if="isEditEnabled" class="row add-auth-flow">
        <div class="pull-right">
          <span v-if="dirty.length > 0" v-tooltip="'Save/Revert changes first'">
            <a disabled="disabled" class="btn btn-success create-button">
              <i class="fa fa-plus"></i> Authorization Flow
            </a>
          </span>
          <span v-if="dirty.length == 0">
            <a class="btn btn-success create-button" @click="addAuthFlow('New authorization')">
              <i class="fa fa-plus"></i> Authorization Flow
            </a>
          </span>
        </div>
      </div>
      <auth-flow
        v-for="(authflow, index) in authFlows"
        :flowIndex="index"
        :dirty="dirty"
        @flowChanged="setDirty"
        :key="index"
      ></auth-flow>
      <div v-if="isEditEnabled" class="row">
        <v-alert v-show="authFlows.length < 1" :type="alertType">
          <div class="noflows-alert">
            <i class="fa-exclamation-triangle"></i>
            There are no Authorization flows. Please click on 'Add Authorization Flow' to add one
          </div>
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import AuthFlow from "./AuthFlow.vue";
import { alert, modal } from "vue-strap";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    "auth-flow": AuthFlow,
    "v-alert": alert,
    "v-modal": modal
  },
  mounted: function () {
    this.$nextTick(function () {
      this.setIsEditEnabled(this.isEditMode);
      this.initialize();
    });
  },
  props: {
    isEditMode: { default: true }
  },
  data: function () {
    return {
      dirty: []
    };
  },
  computed: {
    ...mapState([
      "isEditEnabled",
      "spinner",
      "spinnerText",
      "alertType",
      "showModal",
      "modalCancelText",
      "modalOkText",
      "modalOkSpinnerClass",
      "modalTitleText",
      "modalBodyText",
      "modalConfirmCallback",
      "authFlows"
    ]),
    showModalValue: {
      get () {
        return this.showModal;
      },
      set (value) {
        this.setShowModal(value);
      }
    }
  },
  methods: {
    initialize: function () {
      this.startSpinner("Fetching authorization data...");
      this.$api.GetBPUsers(this.setStoreUsers);
      if (this.isEditMode) {
        this.$api.GetAuthFlows(this.setStoreAuthFlows);
      }
    },
    setStoreUsers: function (apiResponse) {
      if (apiResponse.Success) {
        this.setBpUsers(apiResponse.Data.BPUsers);
      } else {
        window.alert("Error Occurred while fetching Users!");
      }
      if (!this.isEditMode) {
        this.stopSpinner();
      }
    },
    setStoreAuthFlows: function (apiResponse) {
      if (apiResponse.Success) {
        this.setAuthFlows(apiResponse.Data.AuthFlows);
      } else {
        window.alert("Error Occurred while fetching Authorizations!");
      }
      this.stopSpinner();
    },
    setDirty: function (flow) {
      if (flow.dirty) {
        this.dirty.push(flow);
      } else {
        this.dirty = this.dirty.filter(x => x.key != flow.key);
      }
    },
    ...mapActions([
      "setIsEditEnabled",
      "startSpinner",
      "stopSpinner",
      "setShowModal",
      "addAuthFlow",
      "setBpUsers",
      "setAuthFlows"
    ])
  }
};
</script>

<style scoped>
.spinner-box {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 200px;
  z-index: 9998;
  width: 100%;
  text-align: center;
  background: #f6f7f8;
}
.spinner-box-editMode {
  position: absolute;
  min-height: 400px !important;
}
.spinner {
  top: 45%;
  left: 50%;
  position: absolute;
}
.spinner-text {
  top: 45%;
  left: 50%;
  margin-top: 60px;
  margin-left: -30px;
  position: absolute;
}
.multiline-cell {
  white-space: pre;
  line-height: 1em;
}
.authorizations {
  font-size: 12px;
  width: 100%;
  position: relative;
  z-index: 2;
}
.noflows-alert {
  text-align: center;
}
.add-auth-flow {
  margin-bottom: 15px;
}
</style>
<style>
.dx-top {
  z-index: 1000 !important;
  bottom: 5% !important;
}
</style>
