<template>
    <div class="card">
      <div v-if="targetTypePivot.targets > 0">
        <table class="table table-light table-bordered">
        <thead>
            <tr>
                <th class="table-header">Adgroup</th>
                <th class="table-header">Status</th>
                <th class="table-header">Cluster</th>
                <th class="table-header">Targeting Type</th>
                <th class="table-header">Default Bid</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(record, i) in targetTypePivot.records" :key="i" :class = "{'disable-row' : record.status === 'Success'}">
                <td class="data-center">{{record.adgroup}}</td>
                <td class="data-center">{{record.status}}</td>
                <td class="data-center">{{record.cluster}}</td>
                <td class="data-center">{{record.targetingType}}</td>
                <td class="data-center">{{record.bpCurrency}}{{record.defaultBid}}</td>
            </tr>
        </tbody>
      </table>
      </div>
      <div v-else>
        <div class="alert alert-warning" role="alert">
          No details Found
        </div>
      </div>
    </div>
</template>

<script>
export default {
  components: {},
  mounted: function () {},
  props: {
    adGroupDetails: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: function () {
    return {
      Status:
    {
      0: "None",
      1: "Active",
      3: "Paused",
      8: "Success",
      10: "Failed"
    }
    };
  },
  computed: {
    targetTypePivot: function () {
      this.budgetTypeVerified = false;
      let records = [];
      if (this.adGroupDetails.length > 0) {
        for (var ind = 0; ind < this.adGroupDetails.length; ind++) {
          var data = this.adGroupDetails[ind].adgroup;
          let record = {
            adgroup: data.name,
            status: this.Status[data.status],
            cluster: data.additionalData.cluster.name,
            targetingType: this.adGroupDetails[ind].targetType,
            defaultBid: data.defaultBid,
            bpCurrency: this.adGroupDetails[ind].bpCurrency
          };
          records.push(record);
        }
        let totalTargets = records.length;
        return { records: records, targets: totalTargets };
      } else {
        return { records: "(None)", targets: 0 };
      }
    }
  }
}
</script>

<style scoped>
tr.disable-row{
  pointer-events: none;
  background-color: #f9f9f9;
}
.data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
.card {
  /* Add shadows to create the "card" effect */
  transition: 0.3s;
  background-color: rgba(1,1,1,0);
  padding: 5px
}

.table-header{
  border:1px solid #ddd !important;
  padding:10px !important;
}
</style>
