<template>
  <div class="dc-container">
    <Row>
      <Row-Col span="8">
        <Row>
          <cs-hot-table
            ref="hotHeadline"
            :hotSettings="buildHotSettings(4, hotRefs['hotHeadline'])"
          ></cs-hot-table>
        </Row>
        <Row class="margin-up">
          <cs-hot-table
            v-show="showDescription"
            ref="hotDescription"
            :hotSettings="buildHotSettings(4, hotRefs['hotDescription'])"
          ></cs-hot-table>
        </Row>
      </Row-Col>

      <Row-Col span="8">
        <Row>
          <cs-hot-table
            ref="hotText"
            :hotSettings="buildHotSettings(4, hotRefs['hotText'])"
          ></cs-hot-table>
        </Row>
        <Row class="margin-up">
          <div class="cta-container" :class="{ 'disable-cta': isPublished }">
            <div class="cta-header">
              Call To Action
            </div>
            <CheckboxGroup
              v-model="selectedCTA"
              :class="{ 'disable-mouse': isPublished }"
            >
              <Checkbox
                class="cta-elem"
                v-for="(cta, index) in callToactions"
                :key="index"
                :label="cta"
                :disabled="isCtaLimitReached(cta)"
              >
              </Checkbox>
            </CheckboxGroup>
          </div>
        </Row>
      </Row-Col>

      <Row-Col span="8">
        <cs-hot-table
          class="dc-image-hot"
          ref="hotMedia"
          :hotSettings="buildHotSettings(9, hotRefs['hotMedia'])"
        ></cs-hot-table>
      </Row-Col>
    </Row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Row, Col, CheckboxGroup, Checkbox } from "iview";
import CreativeSetupHOT from "./CreativeSetupHOT.vue";
import { columnConfig } from "./../columnConfig.js";
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js";

export default {
  components: {
    Row,
    CheckboxGroup,
    Checkbox,
    "Row-Col": Col,
    "cs-hot-table": CreativeSetupHOT
  },
  mixins: [FacebookCampaignLauncherConfig],
  props: {
    dynamicCreativeData: {
      default: function () {
        return {};
      }
    },
    mainTableData: {
      default: function () {
        return {};
      }
    },
    isPublished: { default: false }
  },
  data: function () {
    return {
      loadLate: false,
      isImage: true,
      hotRefs: {
        hotHeadline: "Headline",
        hotDescription: "Description",
        hotText: "Ad Text",
        hotMedia: "Image/Video"
      },
      refsToColHeaderMap: {
        hotHeadline: ["HOT_Ad Title", "HOT_Headline"],
        hotDescription: ["HOT_Description"],
        hotText: ["HOT_Text", "HOT_Primary Text"],
        hotMedia: ["HOT_Image/Video"]
      },
      callToactions: [],
      selectedCTA: []
    };
  },
  computed: {
    ...mapState([
      "imageIdUrlMap",
      "selectedFbObjective",
      "selectedCampaignLauncherConfig"
    ]),
    showDescription () {
      return !this.isAppInstallsObjective(this.selectedCampaignLauncherConfig); // hide description for app install objective
    }
  },
  created () {},
  mounted () {},
  watch: {
    "dynamicCreativeData.hotTableData": {
      deep: true,
      immediate: true,
      handler () {
        const hotData =
          !this.dynamicCreativeData || !this.dynamicCreativeData.hotTableData
            ? {}
            : JSON.parse(JSON.stringify(this.dynamicCreativeData.hotTableData));
        this.$nextTick(() => {
          this.$refs["hotHeadline"].clearAndSetColData(
            (hotData["HOT_Headline"] || []).flat()
          );
          this.$refs["hotDescription"].clearAndSetColData(
            (hotData["HOT_Description"] || []).flat()
          );
          this.$refs["hotText"].clearAndSetColData(
            (hotData["HOT_Primary Text"] || []).flat()
          );
          this.$refs["hotMedia"].clearAndSetColData(
            (hotData["HOT_Image/Video"] || []).flat()
          );
          this.selectedCTA = hotData["HOT_Call To Action"] || [];
        });
      }
    },
    selectedFbObjective: {
      immediate: true,
      handler () {
        if (this.selectedFbObjective) {
          this.callToactions = columnConfig(
            JSON.stringify(this.selectedCampaignLauncherConfig)
          ).single.callToAction;
        }
      }
    }
  },
  methods: {
    buildHotSettings (rowCount, colName) {
      return {
        startCols: 1,
        startRows: rowCount,
        colHeaders: [colName],
        colWidths: "220px",
        ...(this.isPublished && { readOnly: "true" })
      };
    },
    isCtaLimitReached (cta) {
      return this.selectedCTA.length >= 4 && !this.selectedCTA.includes(cta);
    },
    getFormattedData () {
      const hotTableData = {
        HOT_Headline: this.removeEmptyRows(
          this.$refs["hotHeadline"].getTableData()
        ),
        HOT_Description: this.removeEmptyRows(
          this.$refs["hotDescription"].getTableData()
        ),
        "HOT_Primary Text": this.removeEmptyRows(
          this.$refs["hotText"].getTableData()
        ),
        "HOT_Image/Video": this.removeEmptyRows(
          this.$refs["hotMedia"].getTableData()
        ),
        "HOT_Call To Action": this.selectedCTA
      };
      return hotTableData;
    },
    getMainTableData (hotRefName) {
      for (let colHeader of this.refsToColHeaderMap[hotRefName]) {
        if (this.mainTableData[colHeader]) {
          return this.mainTableData[colHeader]
        }
      }
      return null
    },
    validateData () {
      let invalidAssets = [];
      let alertMessages = [];
      let emptyConfig = this.selectedCTA.length == 0;
      if (
        this.mainTableData["HOT_Call To Action"] &&
        this.selectedCTA.includes(this.mainTableData["HOT_Call To Action"])
      ) {
        alertMessages.push(
          `'${this.mainTableData["HOT_Call To Action"]}' has already been selected in the main table. Please select a unique Call To Action`
        )
      }
      for (let [hotRefName, assetName] of Object.entries(this.hotRefs)) {
        const dcData = this.$refs[hotRefName].getColumnData(0);
        emptyConfig = emptyConfig && dcData.length == 0;
        if (new Set(dcData).size !== dcData.length) {
          invalidAssets.push(assetName);
        }
        const mainData = this.getMainTableData(hotRefName)
        if (mainData != null && dcData.includes(mainData)) {
          alertMessages.push(
            `'${mainData}' has already been entered in the main table. Please enter a unique ${assetName}`
          )
        }
      }
      if (emptyConfig) {
        alertMessages.push("Please enter some assets");
      }
      if (invalidAssets.length > 0) {
        let invalidAssetsMsg = "Please enter a unique value in each cell for ";
        if (invalidAssets.length == 1) {
          invalidAssetsMsg += `${invalidAssets[0]} column`;
        } else {
          invalidAssetsMsg += `${invalidAssets.slice(0, -1).join(", ")} and ${
            invalidAssets.slice(-1)[0]
          } columns`;
        }
        alertMessages.push(invalidAssetsMsg);
      }

      const mediaData = this.$refs["hotMedia"].getColumnData(0);
      const mainData = this.getMainTableData("hotMedia");
      if (mainData != null) {
        mediaData.push(mainData)
      }
      const invalidImageVideo = mediaData.some(dxId => !(dxId in this.imageIdUrlMap))

      if (invalidImageVideo) {
        alertMessages.push("Invalid image/video id");
      } else {
        const imagesNum = mediaData.filter(dxId => dxId.startsWith("DXC")).length;
        if (imagesNum > 0 && imagesNum < mediaData.length) {
          alertMessages.push("Please use either images or videos only");
        }
      }

      return {
        showAlert: alertMessages.length > 0,
        alertMessage: alertMessages.join("<br>")
      };
    },
    removeEmptyRows (tableData) {
      return tableData.filter(row => row.some(cell => cell));
    }
  }
};
</script>

<style scoped>
.cta-elem {
  display: block;
}
.margin-up {
  margin-top: 20px;
}
.cta-container {
  border: 1px solid #ccc;
  width: 220px;
  height: 120px;
  overflow: auto;
}
.cta-header {
  height: 25px;
  text-align: center;
  background-color: #f0f0f0;
  color: #4e4e4e;
  border-bottom: 1px solid #ccc;
  line-height: 25px;
  margin-bottom: 5px;
}
.disable-cta {
  background-color: #f6f6f6 !important;
  color: #bbb !important;
}
.disable-mouse {
  pointer-events: none;
}
</style>

<style>
.dc-image-hot .ht_master {
  height: 260px;
}
.dc-container .wtHolder {
  width: inherit !important;
  height: inherit !important;
}
.cta-elem .ivu-checkbox {
  margin-left: 3px;
  margin-right: 7px;
}
.dc-container .htDimmed {
  background-color: #f6f6f6 !important;
  color: #bbb !important;
}
</style>
