const adwordsEnums = {
  adwordsBiddingStrategies: [
    { id: 3, name: 'Manual CPC' },
    { id: 9, name: 'Maximize Clicks' },
    { id: 10, name: 'Maximize Conversions' },
    { id: 11, name: 'Maximize Conversion Value' },
    { id: 15, name: 'Target Impression Share' },
    { id: 6, name: 'Target CPA' },
    { id: 8, name: 'Target ROAS' }
  ],
  adwordsBiddingStrategyTypes: {
    MaximizeClicks: 9,
    MaximizeConversions: 10,
    MaximizeConversionValue: 11,
    TargetImpressionShare: 15,
    ManualCpc: 3,
    TargetCpa: 6,
    TargetRoas: 8
  },
  deviceTypes: {
    2: "Desktop",
    3: "Mobile",
    4: "Tablets"
  },
  changeOptions: {
    1: "Increase",
    2: "Decrease"
  },
  status: {
    1: "Active",
    2: "Paused"
  },
  entityStatus: {
    2: "Active",
    3: "Paused",
    4: "Removed"
  },
  campaignType: {
    "Search": 1,
    "App": 6,
    "Performance Max": 7,
    "Display": 2,
    "Demand Gen": 8
  },
  campaignNetworks: {
    1: "Search Network",
    2: "Google Display Network"
  },
  adgroupType: {
    2: "Standard",
    3: "Display",
    0: "Unspecified"
  },
  advertisingChannelType: {
    0: "Unspecified",
    1: "Unknown",
    2: "Search",
    3: "Display",
    4: "Shopping",
    5: "Hotel",
    6: "Video",
    7: "MultiChannel",
    8: "Local",
    9: "Smart",
    10: "Performance Max",
    11: "Local Services",
    12: "Discovery",
    13: "Travel",
    14: "DemandGen"
  },
  ageRange: {
    503001: '18-24',
    503002: '25-34',
    503003: '35-44',
    503004: '45-54',
    503005: '55-64',
    503006: '65+',
    1: 'Unknown'
  },
  houseHoldIncome: {
    510006: 'Top 10%',
    510005: '11-20%',
    510004: '21-30%',
    510003: '31-40%',
    510002: '41-50%',
    510001: 'Lower 50%',
    1: 'Unknown'
  },
  gender: {
    10: "Male",
    11: "Female",
    1: "Unknown"
  },
  negativeGeoTargeting: {
    5: "Presence"
  },
  positiveGeoTargeting: {
    5: "Presence or Interest",
    7: "Presence"
  },
  demographicDefaultData: {
    gender: ["10", "11", "1"],
    age: ["503001", "503002", "503003", "503004", "503005", "503006", "1"],
    houseHoldIncome: ["510006", "510005", "510004", "510003", "510002", "510001", "1"],
    targetSave: 0,
    targetSaved: 0,
    targetFailed: 0,
    targetValidationInfo: ""
  },
  targetForm: {
    bpseId: 0,
    targetName: "Target-{AgeGroup}",
    languageIds: [],
    includedLocationIds: [],
    includedGeoTargetSettingType: "5",
    excludedLocationIds: [],
    excludedGeoTargetSettingType: "5",
    interests: [],
    customAudienceIds: [],
    userListIds: [],
    targetingMethod: "1"
  },
  budgetTypeColumns: [
    "Campaign Name",
    "Status",
    "Type",
    "Daily Budget",
    "Bidding",
    "Negative Keyword"
  ],
  adgroupColumns: [
    "AdGroup",
    "Ads",
    "Keywords",
    "Ad Extensions"
  ],
  assetGroupColumns: [
    "Asset Group Name",
    "Status",
    "Asset Type",
    "Assets"
  ],
  displayAdGroupColumns: [
    "Ad Name",
    "Asset Type",
    "Asset"
  ],
  appAdGroupColumns: [
    "Ad Name",
    "Asset Type",
    "Asset"
  ],
  targetColumns: [
    "Target Name",
    "No. of Campaigns"
  ],
  locationColumns: [
    "Location",
    "No. of Campaigns"
  ],
  networksColumns: [
    "Google Display Network",
    "Search Network",
    "No. of Campaigns"
  ],
  adsColumns: [
    "Ads",
    "Landing Page",
    "Display URL"
  ],
  adwordsColumns: [
    { title: "Adgroup Name", width: 25 },
    { title: "Adgroup Type", width: 25 },
    { title: "Ad Extensions", width: 25 },
    { title: "No. of Ads", width: 25 }
  ],
  exportStatus: {
    "Success": 8,
    "Failed": 10,
    "New": 1,
    "Edited": 11
  },
  adwordsAdFormats: {
    1: ["adGroups", "keywords", "responsiveAds", "callAds"],
    7: ["assetGroups"],
    2: ["adGroups", "responsiveDisplayAds"],
    6: ["adGroups", "appAds", "appEngagementAds"],
    8: ["adGroups", "discoveryCarouselAds", "discoveryImageAds", "discoveryVideoAds"]
  },
  adwordsAdFormatLabel: {
    "adGroups": "AdGroups",
    "keywords": "Keywords",
    "responsiveAds": "Responsive Ad",
    "textAds": "Text Ad",
    "callAds": "Call Ads",
    "assetGroups": "Asset Groups",
    "responsiveDisplayAds": "Responsive Ad",
    "appAds": "App Ads",
    "appEngagementAds": "App Engagement Ads",
    "discoveryCarouselAds": "Carousel Image",
    "discoveryImageAds": "Single Image",
    "discoveryVideoAds": "Single Video"
  },
  adwordsLabelToAdFormatMapper: {
    "AdGroups": "adGroups",
    "Keywords": "keywords",
    "Responsive Ad": "responsiveAds",
    "Text Ad": "textAds",
    "Call Ads": "callAds",
    "Asset Groups": "assetGroups",
    "App Ads": "appAds",
    "App Engagement Ads": "appEngagementAds",
    "Carousel Image": "discoveryCarouselAds",
    "Single Image": "discoveryImageAds",
    "Single Video": "discoveryVideoAds"
  },
  adType: {
    "ResponsiveSearchAd": 15,
    "ExpandedTextAd": 3,
    "CallAd": 32,
    "ResponsiveDisplayAd": 19,
    "AppAd": 17,
    "AppEngagementAd": 23,
    "DiscoveryCarouselAd": 41,
    "DiscoveryVideoAd": 42,
    "DiscoveryImageAd": 40,
    "AssetGroup": 1
  },
  adTypeAdFormat: {
    "ResponsiveSearchAd": "responsiveAds",
    "ExpandedTextAd": "textAds",
    "CallAd": "callAds",
    "ResponsiveDisplayAd": "responsiveDisplayAds",
    "AppAd": "appAds",
    "AppEngagementAd": "appEngagementAds",
    "DiscoveryCarouselAd": "discoveryCarouselAds",
    "DiscoveryVideoAd": "discoveryVideoAds",
    "DiscoveryImageAd": "discoveryImageAds",
    "AssetGroup": "assetGroups"
  },
  servedAssetFieldType: {
    "unknown": 1,
    "headline1": 2,
    "headline2": 3,
    "headline3": 4,
    "description1": 5,
    "description2": 6
  },
  keywordsMatchType: {
    Exact: 2,
    Phrase: 3,
    Broad: 4
  },
  keywordsMatchTypeMapper: {
    2: "Exact",
    3: "Phrase",
    4: "Broad"
  },
  negativeKeywordsMapper: {
    Exact: "Exact",
    Phrase: "Phrase",
    Broad: "Broad"
  },
  adFormatToIdentifierNameMapper: {
    "adGroups": "ag",
    "keywords": "kw",
    "responsiveAds": "resad",
    "textAds": "expad",
    "callAds": "callad",
    "assetGroups": "ASG",
    "responsiveDisplayAds": "resDisplayAd",
    "appAds": "aad",
    "appEngagementAds": "aed",
    "discoveryCarouselAds": "carouselad",
    "discoveryImageAds": "imagead",
    "discoveryVideoAds": "videoad"
  },
  adExtensionTypes: {
    1: "Call",
    3: "SiteLink",
    5: "App",
    6: "Location",
    8: "Callout",
    9: "Structured Snippet"
  },
  adExtension: {
    "Call": 1,
    "SiteLink": 3,
    "App": 5,
    "Location": 6,
    "Callout": 8,
    "Structured Snippet": 9
  },
  dayOfWeek: {
    2: "Monday",
    3: "Tuesday",
    4: "Wednesday",
    5: "Thursday",
    6: "Friday",
    7: "Saturday",
    8: "Sunday"
  },
  minuteOfHour: {
    "00": 2,
    "15": 3,
    "30": 4,
    "45": 5
  },
  assetType: {
    11: "Callout",
    13: "SiteLink",
    12: "Structured Snippet"
  },
  feedItemAdExtensionType: {
    3: "App",
    4: "Call",
    12: "Location"
  },
  conversionActionType: {
    "AdCall": "AdCall"
  },
  structredSnippetHeaderLanguages: {
    "English": 1,
    "Hindi": 2
  },
  structredSnippetHeaderTypes: [
    { languageId: 1,
      types: [
        "Amenities",
        "Brands",
        "Courses",
        "Degree programs",
        "Featured hotels",
        "Insurance coverage",
        "Models",
        "Neighborhoods",
        "Shows",
        "Styles",
        "Types"
      ]
    },
    {
      languageId: 2,
      types: [
        "ब्रांड",
        "सुविधाएं",
        "शैलियां",
        "प्रकार",
        "गंतव्य",
        "सेवाएं",
        "पाठ्‍यक्रम",
        "आस-पड़ोस",
        "शो",
        "बीमा कवरेज",
        "डिग्री कार्यक्रम",
        "प्रदर्शित होटल",
        "मॉडल"
      ]
    }
  ],
  locationType: {
    Standard: 1,
    Hyperlocal: 2
  },
  locationMode: {
    Standard: "Standard",
    Hyperlocal: "Hyperlocal",
    PageSets: "PageSets"
  },
  distanceUnit: {
    1: "Miles",
    2: "Kilometers"
  },
  campaignSubType: {
    "App Installs": 8,
    "App Engagement": 10
  },
  appStore: {
    "Apple App Store": 2,
    "Google App Store": 3
  },
  appCampaignBiddingFocus: {
    "Install Volume": 1,
    "In-app actions": 2,
    "In-app action value": 3
  },
  targetUsers: {
    "All Users": 1,
    "Users likely to perform an in-App action": 2
  },
  assetTypesMarketingImageCount: 20,
  callToActionTypes: [
    'Learn More',
    'Get Quote',
    'Apply Now',
    'Sign Up',
    'Contact Us',
    'Subscribe',
    'Download',
    'Book Now',
    'Shop Now',
    'See More',
    'Visit Site'
  ],
  callToActionTypesForVideoAd: [
    'Apply Now',
    'Book Now',
    'Buy Now',
    'Contact Us',
    'Donate Now',
    'Download',
    'Learn More',
    'Get Quote',
    'Learn More',
    'Order Now',
    'Learn More',
    'Play Now',
    'See More',
    'Shop Now',
    'Sign Up',
    'Start Now',
    'Subscribe',
    'Visit Site',
    'Watch Now'
  ],
  callToActionTypesForAssetGroups: [
    'Apply Now',
    'Learn More',
    'Get Quote',
    'Sign Up',
    'Contact Us',
    'Subscribe',
    'Download',
    'Book Now',
    'Shop Now'
  ],
  taxonomyType: {
    1: 'Unknown',
    2: 'Affinity',
    3: 'In-market',
    4: 'Mobile App Install User',
    5: 'Vertical Geo',
    6: 'New Smart Phone User'
  },
  targetingMethod: {
    1: "Targeting",
    2: "Observation"
  },
  customAudienceType: {
    "Auto": 2,
    "Interest": 3,
    "PurchaseIntent": 4,
    "Search": 5
  },
  countryCodes: [
    "AD",
    "AE",
    "AF",
    "AG",
    "AI",
    "AL",
    "AM",
    "AO",
    "AQ",
    "AR",
    "AS",
    "AT",
    "AU",
    "AW",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BF",
    "BG",
    "BH",
    "BI",
    "BJ",
    "BL",
    "BM",
    "BN",
    "BO",
    "BQ",
    "BR",
    "BS",
    "BT",
    "BV",
    "BW",
    "BY",
    "BZ",
    "CA",
    "CC",
    "CD",
    "CF",
    "CG",
    "CH",
    "CI",
    "CK",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CV",
    "CW",
    "CX",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DM",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "EH",
    "ER",
    "ES",
    "ET",
    "FI",
    "FJ",
    "FK",
    "FM",
    "FO",
    "FR",
    "GA",
    "GB",
    "GD",
    "GE",
    "GF",
    "GG",
    "GH",
    "GI",
    "GL",
    "GM",
    "GN",
    "GP",
    "GQ",
    "GR",
    "GS",
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HM",
    "HN",
    "HR",
    "HT",
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IO",
    "IQ",
    "IS",
    "IT",
    "JE",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KI",
    "KM",
    "KN",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LB",
    "LC",
    "LI",
    "LK",
    "LR",
    "LS",
    "LT",
    "LU",
    "LV",
    "LY",
    "MA",
    "MC",
    "MD",
    "ME",
    "MF",
    "MG",
    "MH",
    "MK",
    "ML",
    "MM",
    "MN",
    "MO",
    "MP",
    "MQ",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NF",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NR",
    "NU",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PF",
    "PG",
    "PH",
    "PK",
    "PL",
    "PM",
    "PN",
    "PR",
    "PS",
    "PT",
    "PW",
    "PY",
    "QA",
    "RE",
    "RO",
    "RS",
    "RU",
    "RW",
    "SA",
    "SB",
    "SC",
    "SD",
    "SE",
    "SG",
    "SH",
    "SI",
    "SJ",
    "SK",
    "SL",
    "SM",
    "SN",
    "SO",
    "SR",
    "SS",
    "ST",
    "SV",
    "SX",
    "SZ",
    "TC",
    "TD",
    "TF",
    "TG",
    "TH",
    "TJ",
    "TK",
    "TL",
    "TM",
    "TN",
    "TO",
    "TR",
    "TT",
    "TV",
    "TW",
    "TZ",
    "UA",
    "UG",
    "UM",
    "US",
    "UY",
    "UZ",
    "VA",
    "VC",
    "VE",
    "VG",
    "VI",
    "VN",
    "VU",
    "WF",
    "WS",
    "XK",
    "YE",
    "YT",
    "ZA",
    "ZM",
    "ZW"
  ],
  assetsSupportedByCampaignType: {
    6: ["Name", "Headline", "Description"],
    2: ["Name", "Final URL", "Headline", "Long Headline", "Description", "Business Name", "Images"],
    8: ["Name", "Final URL", "Headline", "Long Headline", "Description", "Business Name", "Images"],
    7: ["Status", "Name", "Final URL", "Headline", "Long Headline", "Description", "Business Name", "Images"]
  },
  entityType: {
    "Campaign": 1,
    "AdGroup": 2,
    "Ad": 3
  },
  campaignEditedFields: {
    "Budget": 0,
    "Schedule": 1
  },
  campaignEditActions: {
    Applied: 'Applied',
    Skipped: 'Skipped'
  }
};

export { adwordsEnums };
