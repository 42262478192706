import { render, staticRenderFns } from "./TwitterElementsView.vue?vue&type=template&id=137d710a&scoped=true&"
import script from "./TwitterElementsView.vue?vue&type=script&lang=js&"
export * from "./TwitterElementsView.vue?vue&type=script&lang=js&"
import style0 from "./TwitterElementsView.vue?vue&type=style&index=0&id=137d710a&scoped=true&lang=css&"
import style1 from "./TwitterElementsView.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137d710a",
  null
  
)

export default component.exports