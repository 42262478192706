<template>
  <div class="sidebar-content-container">
    <div class="sidebar-content-header">{{ header }}</div>
    <div class="sidebar-content-slot">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
    props: ["header"],
};
</script>

<style>
.sidebar-content-container{
    background-color: white;
    margin: 0px 0px 5px 0px;
    padding: 15px 15px 0px 15px;
}
.sidebar-content-header{
    color: #2c2e2f;
    font-size: 15px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f5f5f5;
}
.sidebar-content-slot{
  padding: 20px;
}
</style>
