<template>
  <div>
    <div
      v-if="this.sdtab == 'Ads'"
      class="hot-div"
      style="width: 100%; height: 530px; overflow: auto;"
      :key="rerenderKey"
    >
      <hot-table
        ref="hot1"
        :id="'productshot'"
        :settings="productSettings"
      ></hot-table>
    </div>
    <div
      v-else
      class="hot-div"
      style="width: 100%; height: 530px; overflow: hidden;"
      :key="rerenderKey"
    >
      <div class="flex">
        <div
          style="padding-left:1%;"
          v-if="
            this.configColumns.campaign.targetingType == Object.keys(this.amsEnums.targetingTypeForDisplay)[Object.values(this.amsEnums.targetingTypeForDisplay).indexOf('Product Targeting')] ||
              this.configColumns.campaign.targetingType == 3
          "
        >
          <hot-table
            class="ad-sheet-hot"
            ref="hot2"
            :id="'asinscategorieshot'"
            :settings="asinSettings"
          ></hot-table>
        </div>
        <div
          style="padding-left:1%;"
          v-if="
            this.configColumns.campaign.targetingType == Object.keys(this.amsEnums.targetingTypeForDisplay)[Object.values(this.amsEnums.targetingTypeForDisplay).indexOf('Product Targeting')] ||
              this.configColumns.campaign.targetingType == 3
          "
        >
          <hot-table
            class="ad-sheet-hot"
            ref="hot3"
            :id="'negativeAsinshot'"
            :settings="negativeAsinsSettings"
          ></hot-table>
        </div>
        <div
          style="padding-left:1%;"
          v-if="
            this.configColumns.campaign.targetingType == Object.keys(this.amsEnums.targetingTypeForDisplay)[Object.values(this.amsEnums.targetingTypeForDisplay).indexOf('Audience Targeting')] ||
              this.configColumns.campaign.targetingType == 3
          "
        >
          <hot-table
            class="ad-sheet-hot"
            ref="hot4"
            :id="'audienceshot'"
            :settings="audienceSettings"
          ></hot-table>
        </div>
      </div>
      <audience-popup
        :showModalProp="showAudiencePop"
        :clusterId="clusterId"
        :audienceProp="selectedAudience"
        :showBidProp="true"
        :bidProp="this.defaultBid"
        :currencyCode="this.currencyResponse.bpCurrencySymbol"
        :country="this.country"
        :showRefine="true"
        @AddAudience="AddAudience($event)"
        @CloseAudiencePopUp="CloseAudiencePopUp($event)"
      ></audience-popup>
    </div>
  </div>
</template>

<script>
import { APIService } from "../../ApiService.js";
import axios from 'axios';
import { mapState, mapGetters, mapMutations } from "vuex";
import { HotTable } from "@handsontable/vue";
import { EventBus } from "../../EventBus.js";
import Handsontable from "handsontable";
import AudiencePopUp from "../../../AudiencePopUp/Components/AudiencePopUp.vue";
import {
  columnConfig,
  displayHeaderNameLookup,
  columnConfigSDTarget,
  displayHeaderNameLookupSDTarget
} from "../../columnConfig.js";

export default {
  components: {
    "hot-table": HotTable,
    "audience-popup": AudiencePopUp
  },
  props: {
    sdtab: { default: "" }
  },
  data: function () {
    return {
      rerenderKey: 0,
      rateValue: 0,
      allcolumns: {},
      configColumns: {},
      requiredCols: {},
      lineItem: [],
      targetResponse: [],
      alreadyProcessed: {},
      clusterIds: [],
      allClusterIds: [],
      targetsData: [],
      targetsList: [],
      negativeAsins: [],
      negativeAsinsList: [],
      audiencesCluster: [],
      showAudiencePop: false,
      clusterId: 0,
      selectedAudience: {},
      selectedRow: 0,
      defaultBid: 0,
      currencyResponse: {},
      country: "",
      allCategoryNames: [],
      error: [],
      lineitemData: [],
      lineitemAdsData: [],
      previewLogo: '',
      previewHeadline: '',
      previewProductImage: '',
      previewCustomImage: '',
      showPreview: false,
      adsDetails: [],
      sheetData: {},
      previewProductTitle: '',
      asins: [],
      savedData: {},
      creativeType:
        {
          'None': 0,
          'HeadlineLogo': 1,
          'CustomImage': 2
        }
    };
  },
  computed: {
    ...mapState([
      "campaignLauncherConfigs",
      "mappedLineItems",
      "selectedCampaignLauncherConfigId",
      "mediaPlanId",
      "amsPublishedData",
      "deltax",
      "tableData",
      "businessProfileId",
      "adsData",
      "amsEnums",
      "isSdCreated",
      "isRefreshEntitiesData"
    ]),
    ...mapGetters(["getMediaPlanById", "amsSheetDetails"])
  },
  created () {
    if (this.sdtab == "Ads") {
      this.$store.commit("set_isSdCreated", !this.isSdCreated);
    }
    this.showAudiencePop = false;
    this.$store.commit("set_AmsLoadNext", true);
    this.$store.commit("set_AmsLoadSave", true);
    this.selectedMediaPlanDetails = this.getMediaPlanById(this.mediaPlanId);
    this.configColumns = JSON.parse(
      this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
    );
    this.defaultBid = this.configColumns.campaign.defaultBid;
    this.GetTargetsData();
    for (var i = 0; i < this.lineItem.length; i++) {
      this.allClusterIds.push(this.lineItem[i].clusterId);
      if (!this.lineItem[i].IsExported) {
        this.clusterIds.push(this.lineItem[i].clusterId);
      }
    }
    if (this.sdtab == "Ads") {
      this.allcolumns = columnConfig(
        this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
          .config
      );
      this.displayColHeaders = displayHeaderNameLookup(
        this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
          .config
      );
      this.productSettings = this.buildHotSettings(0);
    } else {
      this.allcolumns = columnConfigSDTarget(
        this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
          .config
      );
      this.displayColHeaders = displayHeaderNameLookupSDTarget(
        this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
          .config
      );
      if (this.configColumns.campaign.targetingType == Object.keys(this.amsEnums.targetingTypeForDisplay)[Object.values(this.amsEnums.targetingTypeForDisplay).indexOf("Product Targeting")]) {
        this.asinSettings = this.buildHotSettings(0);
        this.negativeAsinsSettings = this.buildHotSettings(1);
      } else if (this.configColumns.campaign.targetingType == Object.keys(this.amsEnums.targetingTypeForDisplay)[Object.values(this.amsEnums.targetingTypeForDisplay).indexOf("Audience Targeting")]) {
        this.audienceSettings = this.buildHotSettings(0);
      } else {
        this.asinSettings = this.buildHotSettings(0);
        this.negativeAsinsSettings = this.buildHotSettings(1);
        this.audienceSettings = this.buildHotSettings(2);
      }
    }
    EventBus.$on("SaveAmazonSDTargets", alwaysCb => {
      this.saveAlwaysCallback = alwaysCb
      this.saveAdsTableData();
      this.SaveTargetingTableData();
    });
    EventBus.$on("saveAmazonSdCreative", alwaysCb => {
      this.saveAlwaysCallback = alwaysCb
      this.saveTableData();
    });
    if (!this.isSdCreated) {
      this.GetCurrency();
      this.GetCategoriesName();
      this.getAdSheetData();
      this.FetchProductsData(this.asins.join(','));
    }
  },
  methods: {
    ...mapMutations(["set_tableData", "set_AmsLoadNext", "set_isSdCreated", "set_sdAdsData", "set_amsSheetData", "set_AmsLoadSave", "set_isRefreshEntitiesData"]),
    async GetCategoriesName () {
      let bpseId = this.configColumns.bpseId;
      await APIService
        .GetCategoriesNames(bpseId)
        .then((response) => {
          this.allCategoryNames = response.data.data;
        })
        .catch((x) => {
          this.errorOccurred = true;
          this.errorMessage = "Unable to fetch categories data.";
        });
    },
    VerifyImage (value, asinData) {
      if (value != "") {
        if (asinData !== undefined && asinData !== null) {
          // Index for CustomImage and Logo = 2
          var indexArray = asinData.map((subarray, index) => (subarray[2] === value ? index : null)).filter(index => index !== null);
          indexArray.forEach(element => {
            this.$refs.hot1.hotInstance.setDataAtCell(element, 2, "");
          });
        }
      }
    },
    getAdSheetData () {
      APIService.getAdSheetData(this.mediaPlanId, this.selectedCampaignLauncherConfigId, this.adSheetMode, 10)
        .then(res => {
          var response = res.data.data
          this.savedData = res.data.data;
          if (response != null) {
            var adsheetData = JSON.parse(response.creativeJson);
            this.set_amsSheetData(adsheetData)
          }
          this.loadData();
        });
    },
    saveTableData () {
      if (this.$refs["hot1"] !== undefined) {
        this.sheetData.Ads = this.validateAds();
      }
      if (this.$refs["hot2"] !== undefined) {
        this.sheetData.Ads = this.amsSheetDetails.Ads;
        this.sheetData.Asins = this.validateProductData();
      }
      if (this.$refs["hot3"] !== undefined) {
        this.sheetData.Ads = this.amsSheetDetails.Ads;
        this.sheetData.ExcludedAsins = this.validateNegativeProducts();
      }
      if (this.$refs["hot4"] !== undefined) {
        this.sheetData.Ads = this.amsSheetDetails.Ads;
        this.sheetData.Audience = this.validateAudienceData();
      }
      this.set_amsSheetData(this.sheetData);
      this.$store.commit("set_AmsLoadNext", false);
    },
    validateAds () {
      var creative = [];
      if (this.$refs["hot1"] !== undefined) {
        var asinData = this.$refs.hot1.hotInstance.getData();
        for (var asinIndex = 0; asinIndex < asinData.length; asinIndex++) {
          var data = asinData[asinIndex];
          if (data[1] != null && data[1] != undefined && data[1] != "") {
            if (this.configColumns.campaign.creativeCustomization == this.creativeType.HeadlineLogo) {
              creative.push({
                clusterId: data[1],
                logo: data[2],
                headline: data[3],
                asinsToAdvertise: data[4]
              });
            } else if (this.configColumns.campaign.creativeCustomization == this.creativeType.CustomImage) {
              creative.push({
                clusterId: data[1],
                customImage: data[2],
                asinsToAdvertise: data[3]
              });
            } else {
              creative.push({
                clusterId: data[1],
                asinsToAdvertise: data[2]
              });
            }
          }
        }
      }
      return creative;
    },
    validateProductData () {
      var asinData = [];
      if (this.$refs["hot2"] !== undefined) {
        var asinCategoryTargetData = this.$refs.hot2.hotInstance.getData();
        for (var asinCategoryIndex = 0; asinCategoryIndex < asinCategoryTargetData.length; asinCategoryIndex++) {
          var data = asinCategoryTargetData[asinCategoryIndex];
          if (data[1] != null && data[1] != undefined && data[1] != "") {
            asinData.push({
              clusterId: data[0],
              asin: data[1],
              bid: data[2]
            })
          }
        }
      }
      return asinData;
    },
    validateNegativeProducts () {
      var excludedAsins = [];
      if (this.$refs["hot3"] !== undefined) {
        var excludedAsinData = this.$refs.hot3.hotInstance.getData();
        for (var excludedAsinIndex = 0; excludedAsinIndex < excludedAsinData.length; excludedAsinIndex++) {
          var data2 = excludedAsinData[excludedAsinIndex];
          if (data2[1] != null && data2[1] != undefined && data2[1] != "") {
            excludedAsins.push({
              clusterId: data2[0],
              excludedAsins: data2[1]
            });
          }
        }
      }
      return excludedAsins;
    },
    validateAudienceData () {
      var audienceToTargets = [];
      if (this.$refs["hot4"] !== undefined) {
        var audienceData = this.$refs.hot4.hotInstance.getData();
        for (var audiencesIndex = 0; audiencesIndex < audienceData.length; audiencesIndex++) {
          let audiences = {};
          audiences.AudienceToTarget = [];
          audiences.audienceId = [];
          audiences.category = [];
          audiences.product = [];
          audiences.targetId = 0;
          if (audienceData[audiencesIndex][0] != null) {
            var data3 = audienceData[audiencesIndex];
            for (var audienceIndex = 0; audienceIndex < this.audiencesCluster.length; audienceIndex++) {
              if (data3[0] == this.audiencesCluster[audienceIndex].clusterId && this.audiencesCluster[audienceIndex].audience) {
                if (this.audiencesCluster[audienceIndex].audience.audienceIds) {
                  audiences.AudienceToTarget = this.audiencesCluster[audienceIndex].audience.audienceIds
                } else {
                  var audienceToTarget = [];
                  for (var i = 0; i < this.audiencesCluster[audienceIndex].audience.audienceId.length; i++) {
                    audienceToTarget.push({
                      AudienceId: this.audiencesCluster[audienceIndex].audience.audienceId[i],
                      Bid: this.defaultBid
                    })
                  }
                  audiences.AudienceToTarget = audienceToTarget
                }
                for (var j = 0; j < this.audiencesCluster[audienceIndex].audience.category.length; j++) {
                  if (this.audiencesCluster[audienceIndex].audience.category[j].bid == 0) { this.audiencesCluster[audienceIndex].audience.category[j].bid = this.defaultBid }
                }
                for (var k = 0; k < this.audiencesCluster[audienceIndex].audience.product.length; k++) {
                  if (this.audiencesCluster[audienceIndex].audience.product[k].bid == 0) { this.audiencesCluster[audienceIndex].audience.product[k].bid = this.defaultBid }
                }
                audiences.audienceId = this.audiencesCluster[audienceIndex].audience.audienceId
                audiences.category = this.audiencesCluster[audienceIndex].audience.category
                audiences.product = this.audiencesCluster[audienceIndex].audience.product
                audiences.targetId = this.audiencesCluster[audienceIndex].id
                let rowCount = audiences.audienceId.length + audiences.category.length + audiences.product.length
                audienceToTargets.push({
                  clusterId: data3[0],
                  rowCount: rowCount + ' audiences selected',
                  audience: audiences
                });
              }
            }
          }
        }
      }
      return audienceToTargets;
    },
    saveAdsTableData () {
      this.lineitemData = [];
      for (var ind = 0; ind < this.lineItem.length; ind++) {
        if (this.$refs["hot1"] !== undefined) {
          var creative = {};
          creative.Logo = "";
          creative.Headline = "";
          creative.AsinsToAdvertise = "";
          creative.CustomImage = "";
          var asinData = this.amsSheetDetails.Ads;
          for (var asinIndex = 0; asinIndex < asinData.length; asinIndex++) {
            var data = asinData[asinIndex];
            if (data.clusterId == this.lineItem[ind].clusterId) {
              if (this.configColumns.campaign.creativeCustomization == this.creativeType.HeadlineLogo) {
                creative.Logo = data.logo;
                creative.Headline = data.headline;
                creative.AsinsToAdvertise = data.asinsToAdvertise;
              } else if (this.configColumns.campaign.creativeCustomization == this.creativeType.CustomImage) {
                creative.CustomImage = data.customImage;
                creative.AsinsToAdvertise = data.asinsToAdvertise;
              } else {
                creative.AsinsToAdvertise = data.asinsToAdvertise;
              }
            }
          }
          this.lineitemData.push({
            LineItemId: this.lineItem[ind].Id,
            TargetId: this.lineItem[ind].TargetId,
            SdAds: creative
          });
          this.set_sdAdsData(this.lineitemData);
        }
      }
    },
    SaveTargetingTableData () {
      var asinCategoryTargetData = this.amsSheetDetails.Asins;
      var excludedAsinData = this.amsSheetDetails.ExcludedAsins;
      var audienceData = this.amsSheetDetails.Audience;
      for (var ind = 0; ind < this.lineItem.length; ind++) {
        var excludedAsins = [];
        var audiences = {};
        var categories = [];
        var asinToTarget = [];
        audiences.AudienceToTarget = [];
        audiences.AudienceId = [];
        audiences.Category = [];
        audiences.Product = [];
        audiences.TargetId = 0;
        this.lineitemAdsData = [];
        this.lineitemAdsData = this.adsData;
        if (this.$refs["hot2"] !== undefined) {
          for (var asinCategoryIndex = 0; asinCategoryIndex < asinCategoryTargetData.length; asinCategoryIndex++) {
            var data = asinCategoryTargetData[asinCategoryIndex];
            if (data.clusterId == this.lineItem[ind].clusterId) {
              var index = this.allCategoryNames.findIndex(x => x.categoryName == data.asin);
              if (data.asin.length == 10) {
                if (index > 0) {
                  categories.push({
                    CategoriesToTarget: data.asin,
                    Bid: data.bid == null || data.bid == undefined || data.bid == "" ? this.defaultBid : data.bid,
                    CategoryId: this.allCategoryNames[index].id
                  })
                } else {
                  asinToTarget.push({
                    AsinsToTarget: data.asin,
                    Bid: data.bid == null || data.bid == undefined || data.bid == "" ? this.defaultBid : data.bid
                  })
                }
              } else {
                if (index > 0) {
                  categories.push({
                    CategoriesToTarget: data.asin,
                    Bid: data.bid == null || data.bid == undefined || data.bid == "" ? this.defaultBid : data.bid,
                    CategoryId: this.allCategoryNames[index].id
                  })
                }
              }
            }
          }
        }
        if (this.$refs["hot3"] !== undefined) {
          for (var excludedAsinIndex = 0; excludedAsinIndex < excludedAsinData.length; excludedAsinIndex++) {
            var data2 = excludedAsinData[excludedAsinIndex];
            if (data2.clusterId == this.lineItem[ind].clusterId) {
              excludedAsins.push(data2.excludedAsins);
            }
          }
        }
        if (this.$refs["hot4"] !== undefined) {
          for (var audiencesIndex = 0; audiencesIndex < audienceData.length; audiencesIndex++) {
            var data3 = audienceData[audiencesIndex];
            if (data3.clusterId == this.lineItem[ind].clusterId) {
              audiences = data3.audience
            }
          }
        }
        for (var lineitemIndex = 0; lineitemIndex < this.lineitemAdsData.length; lineitemIndex++) {
          if (this.lineitemAdsData[lineitemIndex].LineItemId == this.lineItem[ind].Id && this.lineitemAdsData[lineitemIndex].TargetId == this.lineItem[ind].TargetId) {
            this.lineitemAdsData[lineitemIndex].AmsProductsToTarget = asinToTarget
            this.lineitemAdsData[lineitemIndex].AmsCategoryToTarget = categories
            this.lineitemAdsData[lineitemIndex].NegativeAsins = excludedAsins
            this.lineitemAdsData[lineitemIndex].Audience = audiences
          }
        }
      }
      var lineitemData = JSON.stringify(this.lineitemAdsData);
      this.set_tableData(lineitemData);
    },
    GetTargetsData () {
      var self = this;
      this.lineItem = [];
      for (var Index = 0; Index < self.mappedLineItems.length; Index++) {
        let asins = JSON.parse(self.mappedLineItems[Index].asins);
        this.asins.push(asins.asinsToAdvertise);
        self.mappedLineItems[Index].targets.forEach(element => {
          var clusterExists = self.lineItem.findIndex(
            item => item.TargetId == element
          );
          var target = self.mappedLineItems[Index].targetsInfo.filter(
            e => e.id == element
          );
          if (clusterExists === -1 && target[0] && target[0].name !== undefined) {
            self.lineItem.push({
              Id: self.mappedLineItems[Index].id,
              TargetId: element,
              TargetName: target.length > 0 ? target[0].name : "",
              clusterId: self.mappedLineItems[Index].name + "_" + target[0].name,
              IsExported: target[0].IsExported
            });
          }
        });
      }
    },
    async GetCurrency () {
      let bp = await APIService.getApiData(
        this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId
      );
      this.currencyResponse.bp = bp.data.data
      let currency = await APIService.getApiData(
        this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/Currencies/" + this.currencyResponse.bp.currencyId
      );
      currency = currency.data.data;
      this.currencyResponse.bpCurrencySymbol = currency.symbol
      let country = await APIService.getApiData(
        this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/channels/account?id=" + this.configColumns.bpseId
      );
      this.currencyResponse.country = country.data.data;
      this.currencyResponse.amsRegion = country.data.data.amsRegion;
      this.country = JSON.parse(this.currencyResponse.amsRegion).country;
    },
    GetColumnsData (colHeaders) {
      var self = this;
      let displayColHeaders = colHeaders.map(
        header => this.displayColHeaders[header]
      );
      var displayNames = [];
      var columns = [];
      for (var ind = 0; ind < colHeaders.length; ind++) {
        var header = colHeaders[ind];
        var displayName = displayColHeaders[ind];
        var displayText = displayName;
        if (this.requiredCols.base.has(header)) {
          displayText += "<span style='color:#808080'>*</span>";
        }
        displayNames.push(displayText);
        let settings = {};
        if (colHeaders[ind] == "HOT_HeadLine") {
          settings.type = "text";
          settings.data = "headline";
        } else if (colHeaders[ind] == "HOT_Logo" || colHeaders[ind] == "HOT_CustomImage") {
          if (colHeaders[ind] == "HOT_Logo") {
            settings.data = "logo";
          } else if (colHeaders[ind] == "HOT_CustomImage") {
            settings.data = "customImage";
          }
          settings.renderer = this.imgRenderer;
          settings.validator = function (value, callBack) {
            callBack(true);
            let asinData = self.$refs.hot1.hotInstance.getData();
            if (value.includes("http") || value.includes("www")) {
              value = Handsontable.helper.stringify(value);
              const img = new Image();
              img.src = value;
              img.onload = () => {
                axios
                  .get(img.src)
                  .then((response) => {
                    if (response === null || response.status != 200) {
                      self.$Message.error({
                        background: true,
                        content: "Please enter a valid image URL"
                      });
                      self.VerifyImage(value, asinData);
                      callBack(false);
                    } else {
                      callBack(true)
                    }
                  })
                  .catch(ex => {
                    self.$Message.error({
                      background: true,
                      content: "Please enter a valid image URL"
                    });
                    self.VerifyImage(value, asinData);
                    callBack(false);
                  });
              };
              img.onerror = () => {
                self.$Message.error({
                  background: true,
                  content: "Please enter a valid image URL"
                });
                self.VerifyImage(value, asinData);
                callBack(false);
              };
            } else if (value == "") {
              callBack(true);
            } else {
              self.$Message.error({
                background: true,
                content: "Please enter a valid image URL"
              });
              self.VerifyImage(value, asinData);
              callBack(false);
            }
          };
        } else if (colHeaders[ind] == "HOT_ASINs") {
          const alphaNumericValidateRegex = /^((?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+)+$/;
          settings.data = "asinsToAdvertise";
          settings.validator = function (value, callBack) {
            if (value != "") {
              var asinList = [];
              var valid = false;
              value = Handsontable.helper.stringify(value);
              var values = value.split(",");
              for (var data = 0; data < values.length; data++) {
                if (
                  alphaNumericValidateRegex.test(values[data]) &&
                  values[data].length == 10
                ) {
                  if (data == values.length - 1) {
                    valid = true;
                  }
                } else {
                  valid = false;
                  self.$Message.error({
                    background: true,
                    content:
                      "ASINs" +
                      " must only contain a combination alpha-numeric values (Eg, 0, A, a) with 10 characters"
                  });
                  callBack(false);
                }
              }
              if (valid) {
                APIService.getProductsData(value)
                  .then(response => {
                    var data = response.data
                    for (var asinId = 0; asinId < data.length; asinId++) {
                      asinList.push(data[asinId].asin)
                    }
                    for (var x = 0; x < values.length; x++) {
                      var index = asinList.findIndex(a => a == values[x]);
                      if (index != -1) {
                        callBack(true);
                      } else {
                        self.$Message.error({
                          background: true,
                          content:
                            "Product " + values[x] + " not found"
                        });
                        callBack(false);
                      }
                    }
                  })
                  .catch(x => {
                    this.$Message.error({
                      background: true,
                      content:
                          'Error while fetching Asins To Advertise'
                    });
                    callBack(false);
                  });
                self.FetchProductsData(value);
              }
            } else {
              self.$Message.error({
                background: true,
                content: "Please enter Asin to Advertise"
              });
              callBack(false);
            }
          };
        } else if (colHeaders[ind] == "HOT_ClusterId") {
          this.rowHeights = 54;
          this.minSpareRows = 0;
          settings.editor = false;
          settings.data = "clusterId";
        } else if (colHeaders[ind] == "HOT_Preview") {
          settings.readOnly = true;
          settings.copyable = false;
          settings.editor = false;
          settings.renderer = this.previewBtnRenderer;
        } else if (colHeaders[ind] == "HOT_Bid") {
          settings.type = "numeric";
          settings.numericFormat = {
            pattern: "0,0.00"
          };
          settings.manualColumnFreeze = true;
          settings.data = "bid";
        } else if (colHeaders[ind] == "HOT_Asins Or Categories To Target") {
          const alphaNumericValidateRegex = /^((?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+)+$/;
          settings.data = "asin";
          this.colWidths = [210, 200, 50];
          this.width = 460;
          settings.type = "text";
          this.minSpareRows = 1;
          settings.validator = function (value, callBack) {
            if (value != "") {
              value = Handsontable.helper.stringify(value);
              if (alphaNumericValidateRegex.test(value) && value.length == 10) {
                callBack(true);
              } else if (value.length > 0) {
                let isPresent = false;
                for (let i = 0; i < self.allCategoryNames.length; i++) {
                  if (self.allCategoryNames[i].categoryName == value) {
                    isPresent = true;
                    break;
                  }
                }
                if (isPresent) {
                  callBack(true);
                } else {
                  self.$Message.error({
                    background: true,
                    content:
                    "Invalid category entered. Please select the valid category from Assets menu"
                  });
                  callBack(false);
                }
              } else {
                self.$Message.error({
                  background: true,
                  content:
                    "ASINs and Categories must only contain a combination alpha-numeric values (Eg, 0, A, a)"
                });
                callBack(false);
              }
            } else {
              callBack(true);
            }
          };
        } else if (colHeaders[ind] == "HOT_Excluded ASINs") {
          const alphaNumericValidateRegex = /^((?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+)+$/;
          this.colWidths = [210, 200];
          this.width = 410;
          this.minSpareRows = 1;
          settings.data = "excludedAsins";
          settings.type = "text";
          settings.validator = function (value, callBack) {
            if (value == "") {
              callBack(true);
            } else if (
              alphaNumericValidateRegex.test(value) &&
              value.length == 10
            ) {
              callBack(true);
            } else {
              self.$Message.error({
                background: true,
                content:
                  "Excluded ASINs" +
                  " must only contain a combination alpha-numeric values (Eg, 0, A, a) with 10 characters"
              });
              callBack(false);
            }
          };
        } else if (colHeaders[ind] == "HOT_ClusterIds" || colHeaders[ind] == "HOT_AudienceClusterIds") {
          this.rowHeights = 20;
          settings.data = "clusterId";
          settings.type = 'dropdown';
          settings.allowInvalid = false;
          settings.source = this.clusterIds;
          this.minSpareRows = 1;
          if (colHeaders[ind] == "HOT_AudienceClusterIds") {
            this.maxRows = this.allClusterIds.length;
            var audienceCluster = this.allClusterIds
            settings.validator = function (value, callBack) {
              if (value == "") {
                callBack(true);
              }
              if (audienceCluster.includes(value)) {
                if (this.instance.getDataAtCell(this.row, 0) == value) {
                  callBack(true);
                } else if (this.instance.getDataAtProp(0).includes(value)) {
                  self.$Message.error({
                    background: true,
                    content:
                    "Cluster already chosen!"
                  });
                  callBack(false);
                } else {
                  callBack(true);
                }
              }
            }
          }
        } else if (colHeaders[ind] == "HOT_Audiences") {
          this.colWidths = [210, 200, 50];
          this.width = 460;
          settings.data = "rowCount";
          settings.renderer = this.audienceRenderer;
        }
        columns.push(settings);
      }
      this.columns = columns;
      this.colHeaders = displayNames;
    },
    audienceRenderer (instance, td, row, col, prop, value, cellProperties) {
      var clusterData = ""
      if (instance) {
        clusterData = instance.getDataAtCell(row, 0);
      }
      for (var i = 0; i < this.audiencesCluster.length; i++) {
        if (clusterData == this.audiencesCluster[i].clusterId) {
          let audienceTdWrapper = document.createElement("div");
          var newNode = document.createElement("p");
          newNode.style.display = "inline";
          newNode.appendChild(document.createTextNode(this.audiencesCluster[i].rowCount));
          audienceTdWrapper.appendChild(newNode);
          audienceTdWrapper.classList.add("edit-td-wrapper");
          let isDisabled = false;
          if (!this.clusterIds.includes(this.audiencesCluster[i].clusterId)) {
            audienceTdWrapper.classList.add("disabled-cell-text");
            isDisabled = true;
          }
          let audienceIcon = document.createElement("span");
          audienceIcon.style.float = "right";
          if (this.audiencesCluster[i].rowCount == "0 audiences selected") {
            audienceIcon.innerHTML = '<i class="fa fa-plus-square edit-icon"></i>';
          } else {
            audienceIcon.innerHTML = '<i class="far fa-edit edit-icon"></i>';
          }
          if (isDisabled) {
            audienceIcon.style.pointerEvents = "none";
          }
          audienceIcon.onclick = () => {
            this.launchAudiencePopup(row, this.audiencesCluster[i].rowCount);
          };
          audienceTdWrapper.appendChild(audienceIcon);
          Handsontable.dom.empty(td);
          td.appendChild(audienceTdWrapper);
          return td;
        }
      }
    },
    imgRenderer (instance, td, row, col, prop, value, cellProperties) {
      value = Handsontable.helper.stringify(value);
      if (value.includes("http") || value.includes("www")) {
        let img = td.querySelector("img");
        if (img && img.src == value) {
          return td;
        }
        img = document.createElement("IMG");
        img.src = value;
        img.classList.add("hot-img");
        Handsontable.dom.addEvent(img, "mousedown", function (event) {
          event.preventDefault();
        });
        Handsontable.dom.empty(td);
        td.appendChild(img);
      } else {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
      }
      return td;
    },
    buildHotSettings (tableType) {
      var self = this;
      let column = this.allcolumns[tableType];
      let colHeaders = column["base"];
      this.requiredCols = {
        base: new Set(column["requiredBase"]),
        card: new Set(column["requiredCard"]),
        baseLength: column["base"].length,
        cardLength: column["card"].length
      };
      this.GetColumnsData(colHeaders);
      return {
        startCols: colHeaders.length,
        startRows: 1,
        manualColumnResize: true,
        autoRowSize: true,
        autoColumnSize: true,
        minSpareRows: this.minSpareRows,
        colHeaders: this.colHeaders,
        rowHeights: this.rowHeights,
        height: 500,
        width: this.width,
        colWidths: this.colWidths,
        columns: this.columns,
        maxRows: this.maxRows,
        cells: (row) => {
          var cellProperties = {}
          if (
            (self.sdtab === 'Ads' && self.targetResponse[row] !== undefined && self.targetResponse[row].isExported) ||
            (self.sdtab === 'Targeting' &&
              colHeaders.length === 2 &&
              colHeaders.includes('HOT_Excluded ASINs') &&
              self.negativeAsinsList[row] !== undefined &&
              self.negativeAsinsList[row].isExported) ||
            (self.sdtab === 'Targeting' &&
              colHeaders.length === 2 &&
              colHeaders.includes('HOT_Audiences') &&
              self.audiencesCluster[row] !== undefined &&
              self.audiencesCluster[row].isExported) ||
            (self.sdtab === 'Targeting' &&
              colHeaders.length === 3 &&
              colHeaders.includes('HOT_Asins Or Categories To Target') &&
              self.targetsList[row] !== undefined &&
              self.targetsList[row].isExported)
          ) {
            cellProperties.className = 'disabled-cell-text';
            cellProperties.readOnly = true;
          }
          return cellProperties;
        },
        afterValidate: (isValid, value, row, prop, source) => {
          if (!isValid) {
            self.error.push({
              Value: value,
              IsValid: isValid,
              Row: row,
              Column: prop
            });
            this.$store.commit("set_AmsLoadNext", true);
          } else {
            var index = self.error.findIndex(e => e.Row == row && e.Column == prop);
            if (index != -1) {
              self.error.splice(index, 1);
            }
          }
        },
        afterChange: (changes, source) => {
          if (changes != undefined && changes != null) {
            this.$store.commit("set_AmsLoadNext", true);
          }
        }
      }
    },
    updateTableData (tableData, tableId, columns = '', colheaders = '', maxRows = 0) {
      var self = this;
      let updatedSettings3 = {
        data: tableData
      };
      if (columns != '' && columns != undefined) {
        updatedSettings3.columns = columns
      }
      if (colheaders != '' && colheaders != undefined) {
        updatedSettings3.colHeaders = colheaders
      }
      if (maxRows > 0) {
        updatedSettings3.maxRows = maxRows
      }
      if (self.$refs[tableId] === undefined) {
      } else {
        self.$refs[tableId].hotInstance.updateSettings(updatedSettings3);
      }
    },
    async LoadAsinsToTargetTable (targetId, clusterId, targetName, isExported) {
      var targets = [];
      var excludedAsin = [];
      await APIService.GetProductsByTargetId(targetId)
        .then(async response => {
          targets = response.data;
          await APIService.GetCategoriesByTargetId(targetId)
            .then(response => {
              var categories = response.data;
              if (categories.length != 0) {
                for (var index = 0; index < categories.length; index++) {
                  categories[index].asin = categories[index].categoryName;
                  targets.push(categories[index]);
                }
              }
              for (var i = 0; i < targets.length; i++) {
                targets[i].clusterId = clusterId;
                targets[i].isExported = isExported;
                targets[i].bid = this.defaultBid;
              }
              if (this.savedData != null) {
                var adsheetData = JSON.parse(this.savedData.creativeJson);
                excludedAsin = adsheetData.ExcludedAsins.filter(target => target.clusterId == clusterId);
                adsheetData.Asins.forEach(asin => {
                  var existingAsins = targets.filter(target => target.clusterId == asin.clusterId).map(a => a.asin);
                  if (asin.clusterId == clusterId && !existingAsins.includes(asin.asin)) {
                    targets.push(asin);
                  }
                })
              }
              this.targetsData = targets;
              this.negativeAsins = excludedAsin;
              this.$store.commit("set_AmsLoadSave", false);
            })
            .catch(x => {
              this.$Message.error({
                background: true,
                content: 'Error while fetching categories "' + targetName + '"'
              });
            });
        })
        .catch(x => {
          this.$Message.error({
            background: true,
            content: 'Error while fetching Asins To Target "' + targetName + '"'
          });
        });
    },
    async LoadAudiencesTable (targetId, clusterId, targetName, isExported) {
      var self = this;
      await APIService.getTargetsCluster(1)
        .then(response => {
          var targets = response;
          for (var i = 0; i < targets.length; i++) {
            if (targets[i].id == targetId) {
              targets[i].clusterId = clusterId;
              targets[i].isExported = isExported;
              if (targets[i].audience) {
                targets[i].rowCount =
                  targets[i].audience.category.length +
                  targets[i].audience.product.length +
                  targets[i].audience.audienceId.length +
                  " audiences selected";
              } else {
                targets[i].rowCount = "0 audiences selected";
                targets[i].audience = {};
                targets[i].audience.category = [];
                targets[i].audience.product = [];
                targets[i].audience.audienceId = [];
              }
              var index = self.audiencesCluster.findIndex(audience => audience.clusterId === targets[i].clusterId)
              if (index === -1 && targets[i].clusterId != null) {
                self.audiencesCluster.push(targets[i])
              }
              if (this.savedData != null) {
                var adsheetData = JSON.parse(this.savedData.creativeJson);
                if (adsheetData.Audience.length >= 0 && this.$refs['hot4'] != undefined) {
                  for (var j = 0; j < adsheetData.Audience.length; j++) {
                    if (adsheetData.Audience[j].clusterId == targets[i].clusterId) {
                      var existingCategories = targets[i].audience != undefined && targets[i].audience.category != undefined ? targets[i].audience.category.map(c => c.categoryId) : [];
                      var existingProducts = targets[i].audience != undefined && targets[i].audience.product != undefined ? targets[i].audience.product.map(c => c.productType) : [];
                      var audienceindex = self.audiencesCluster.findIndex(audience => audience.clusterId === adsheetData.Audience[j].clusterId)
                      if (audienceindex == -1 && targets[i].clusterId != null) {
                        targets[i].rowCount = adsheetData.Audience[j].rowCount
                        targets[i].audience = adsheetData.Audience[j].audience
                        self.audiencesCluster.push(targets[i])
                      } else {
                        let audience = {};
                        audience.category = [];
                        audience.product = [];
                        audience.audienceId = [];
                        if (self.audiencesCluster[index].audience == null) {
                          self.audiencesCluster[index].audience = audience;
                        }
                        adsheetData.Audience[j].audience.category.forEach(category => {
                          if (!(existingCategories.includes(category.categoryId))) {
                            self.audiencesCluster[index].audience.category.push(category);
                          }
                        })
                        adsheetData.Audience[j].audience.product.forEach(product => {
                          if (!(existingProducts.includes(product.productType) && self.audiencesCluster[index].audience.product[self.audiencesCluster[index].audience.product.findIndex(p => p.productType == product.productType)].lookBackDays == product.lookBackDays)) {
                            self.audiencesCluster[index].audience.product.push(product);
                          }
                        })
                        adsheetData.Audience[j].audience.audienceId.forEach(audienceId => {
                          self.audiencesCluster[index].audience.audienceId.push(audienceId);
                        })
                        self.audiencesCluster[index].rowCount = self.audiencesCluster[index].audience ? self.audiencesCluster[index].audience.category.length +
                      self.audiencesCluster[index].audience.product.length +
                      self.audiencesCluster[index].audience.audienceId.length +
                      " audiences selected" : "0 audiences selected";
                        self.audiencesCluster[index].clusterId = adsheetData.Audience[j].clusterId
                        self.audiencesCluster[index].id = targets[i].id
                      }
                    }
                  }
                }
              }
            }
          }
          this.$store.commit("set_AmsLoadSave", false);
        })
        .catch(x => {
          this.$Message.error({
            background: true,
            content: 'Error while fetching Audiences "' + targetName + '"'
          });
        });
    },
    async loadData () {
      var listOfTargets = [];
      var listOfNegativeAsins = [];
      this.targetResponse = [];
      this.audiencesCluster = [];
      for (var i = 0; i < this.lineItem.length; i++) {
        if (this.sdtab == "Ads") {
          this.LoadAsinsToAdvertiseTable(
            this.lineItem[i].clusterId,
            this.lineItem[i].TargetId,
            this.lineItem[i].TargetName,
            this.lineItem[i].IsExported
          );
        } else {
          if (this.configColumns.campaign.targetingType == Object.keys(this.amsEnums.targetingTypeForDisplay)[Object.values(this.amsEnums.targetingTypeForDisplay).indexOf('Product Targeting')] ||
              this.configColumns.campaign.targetingType == 3) {
            this.targetsData = [];
            this.negativeAsins = [];
            this.$store.commit("set_AmsLoadNext", true);
            this.$store.commit("set_AmsLoadSave", true);
            await this.LoadAsinsToTargetTable(
              this.lineItem[i].TargetId,
              this.lineItem[i].clusterId,
              this.lineItem[i].TargetName,
              this.lineItem[i].IsExported
            );
            listOfTargets = listOfTargets.concat(this.targetsData);
            this.targetsList = listOfTargets;
            listOfNegativeAsins = listOfNegativeAsins.concat(this.negativeAsins);
            this.negativeAsinsList = listOfNegativeAsins;
          }
          if (this.configColumns.campaign.targetingType == Object.keys(this.amsEnums.targetingTypeForDisplay)[Object.values(this.amsEnums.targetingTypeForDisplay).indexOf('Audience Targeting')] ||
              this.configColumns.campaign.targetingType == 3) {
            this.$store.commit("set_AmsLoadNext", true);
            this.$store.commit("set_AmsLoadSave", true);
            await this.LoadAudiencesTable(
              this.lineItem[i].TargetId,
              this.lineItem[i].clusterId,
              this.lineItem[i].TargetName,
              this.lineItem[i].IsExported
            );
          }
        }
      }
      if (this.$refs["hot2"] !== undefined) {
        let columns = this.allcolumns[0];
        let colHeaders = columns["base"];
        this.GetColumnsData(colHeaders);
        this.updateTableData(listOfTargets, 'hot2', this.columns, this.colHeaders);
      }
      if (this.$refs["hot3"] !== undefined) {
        let columns = this.allcolumns[1];
        let colHeaders = columns["base"];
        this.GetColumnsData(colHeaders);
        this.updateTableData(listOfNegativeAsins, 'hot3', this.columns, this.colHeaders);
      }
      if (this.$refs["hot4"] != undefined) {
        let columns = this.allcolumns[2];
        let colHeaders = columns["base"];
        this.GetColumnsData(colHeaders);
        this.updateTableData(this.audiencesCluster, 'hot4', this.columns, this.colHeaders, this.allClusterIds.length);
      }
    },
    FetchProductsData (products) {
      if (products && products.length > 0) {
        APIService.getProductsData(products)
          .then(response => {
            response.data.forEach(ele => {
              this.adsDetails.push(ele);
            })
          })
          .catch(x => {
            this.$Message.error({
              background: true,
              content:
                'Error while fetching Asins To Advertise'
            });
          });
      }
    },
    LoadAsinsToAdvertiseTable (clusterId, targetId, targetName, isExported) {
      var ads = [];
      APIService.getTargets(targetId.toString())
        .then(response => {
          var targets = response;
          for (var i = 0; i < targets.length; i++) {
            var asin = JSON.parse(targets[i].targetString);
            if (targetId == targets[i].id) {
              targets[i].clusterId = clusterId;
              targets[i].isExported = isExported;
            }
            targets[i].asinsToAdvertise = asin.asinsToAdvertise.join(",");
            if (this.savedData != null) {
              var adsheetData = JSON.parse(this.savedData.creativeJson);
              ads = adsheetData.Ads;
              var savedAd = ads.filter(ad => ad.clusterId == clusterId);
              if (savedAd.length > 0 && this.configColumns.campaign.creativeCustomization == this.creativeType.HeadlineLogo) {
                targets[i].logo = savedAd[0].logo;
                targets[i].headline = savedAd[0].headline;
                targets[i].asinsToAdvertise = savedAd[0].asinsToAdvertise
              } else if (savedAd.length > 0 && this.configColumns.campaign.creativeCustomization == this.creativeType.CustomImage) {
                targets[i].customImage = savedAd[0].customImage ? "" : savedAd[0].customImage;
                targets[i].asinsToAdvertise = savedAd[0].asinsToAdvertise
              } else if (savedAd.length > 0) {
                targets[i].asinsToAdvertise = savedAd[0].asinsToAdvertise
              }
            }
          }
          let existingItemIndex = this.targetResponse.findIndex(item => item.clusterId === targets[0].clusterId);
          if (existingItemIndex === -1) {
            this.targetResponse.push(targets[0]);
            if (this.$refs["hot1"] != undefined) {
              this.updateTableData(this.targetResponse, "hot1");
              let asinList = [].concat(...this.targetResponse.map(e => e.asinsToAdvertise.split(",")));
              let asinsAlreadyFetched = this.adsDetails.map(e => e.asin);
              this.FetchProductsData(asinList.filter(asin => !asinsAlreadyFetched.includes(asin)).join(","));
            }
          }
        })
        .catch(x => {
          this.$Message.error({
            background: true,
            content:
              'Error while fetching Asins To Advertise "' + targetName + '"'
          });
        });
    },
    CreatePreview (row) {
      let ads = [];
      let previewIcon = document.getElementById("previewCount" + row);
      let eyeIcon = document.getElementsByClassName("eye preivew-icon-span ivu-poptip-rel");
      this.showPreview = !this.showPreview;
      if (this.$refs["hot1"] !== undefined) {
        var asinData = this.$refs.hot1.hotInstance.getData();
        var data = asinData[row];
        if (this.configColumns.campaign.creativeCustomization == this.creativeType.CustomImage) {
          let asin = data[3].split(',');
          ads = this.adsDetails.filter(x => x.asin == asin[0]);
          if (ads.length == 0) {
            this.FetchProductsData(asin[0].toString());
            ads = this.adsDetails.filter(x => x.asin == asin[0]);
          }
          this.previewCustomImage = data[2];
          if (ads.length > 0) {
            this.previewProductTitle = ads[0].title;
            this.previewProductImage = ads[0].imageLink;
          }
          let customImageCreative = "https://production-cb-api.mediabuy.pro/t/1740?variant=Default&scaleFactor=1&format=png&ptype=n&xs=PBga59fKW5&Headline=text-";
          if (this.previewProductTitle === undefined || this.previewProductTitle === null) {
            customImageCreative += "&Title=text-";
          } else {
            customImageCreative += "&Title=text-" + encodeURI(this.previewProductTitle);
          } if (this.previewCustomImage !== undefined && this.previewCustomImage !== null && this.previewCustomImage !== "") {
            customImageCreative += "&Product=src-" + encodeURI(this.previewCustomImage);
          }
          previewIcon.innerHTML = "<div class='ivu-poptip-content' style='position:fixed'><div class='ivu-poptip-arrow' style='top:5% !important'></div> <!----> <div class='ivu-poptip-inner'><div class='ivu-poptip-body'><div class='ivu-poptip-body-content'><div class='ivu-poptip-body-content-inner'><div style='display:flex'><div span='11' style='padding-right:1%'><img src='" + customImageCreative + "' style='width: 100%;height: 100%;'></div></div></div></div></div></div></div>";
        }
        if (this.configColumns.campaign.creativeCustomization == this.creativeType.HeadlineLogo) {
          let asin = data[4].split(',');
          ads = this.adsDetails.filter(x => x.asin == asin[0]);
          if (ads.length == 0) {
            this.FetchProductsData(asin[0].toString());
            ads = this.adsDetails.filter(x => x.asin == asin[0]);
          }
          this.previewLogo = data[2];
          this.previewHeadline = data[3];
          if (ads.length > 0) {
            this.previewProductTitle = ads[0].title;
            this.previewProductImage = ads[0].imageLink;
          }
          let headLineLogoCreative = "https://production-cb-api.mediabuy.pro/t/1740?variant=Default&scaleFactor=1&format=png&ptype=n&xs=PBga59fKW5";
          if (this.previewProductTitle === undefined || this.previewProductTitle === null) {
            headLineLogoCreative += "&Title=text-";
          } else {
            headLineLogoCreative += "&Title=text-" + encodeURI(this.previewProductTitle);
          } if (this.previewHeadline === undefined || this.previewHeadline === null) {
            headLineLogoCreative += "&Headline=text-";
          } else {
            headLineLogoCreative += "&Headline=text-" + encodeURI(this.previewHeadline);
          } if (this.previewLogo !== undefined && this.previewLogo !== null && this.previewLogo !== "") {
            headLineLogoCreative += "&BrandLogo=src-" + encodeURI(this.previewLogo);
          } if (this.previewProductImage !== undefined && this.previewProductImage !== null && this.previewProductImage !== "") {
            headLineLogoCreative += "&Product=src-" + encodeURI(this.previewProductImage);
          }
          previewIcon.innerHTML = "<div class='ivu-poptip-content' style='position:fixed'><div class='ivu-poptip-arrow' style='top:5% !important'></div> <!----> <div class='ivu-poptip-inner'><div class='ivu-poptip-body'><div class='ivu-poptip-body-content'><div class='ivu-poptip-body-content-inner'><div style='display:flex'><div span='11' style='padding-right:1%'><img src='" + headLineLogoCreative + "' style='width: 100%;height: 100%;'></div></div></div></div></div></div></div>";
        }
      }
      if (this.showPreview) {
        previewIcon.style.visibility = "visible";
        for (let i = 0; i < eyeIcon.length; i++) {
          if (i != row) {
            eyeIcon[i].style.pointerEvents = "none";
            eyeIcon[i].style.opacity = "0.5";
          }
        }
      } else {
        previewIcon.style.visibility = "hidden";
        for (let i = 0; i < eyeIcon.length; i++) {
          eyeIcon[i].style.pointerEvents = "auto";
          eyeIcon[i].style.opacity = "1";
        }
      }
    },
    previewBtnRenderer (instance, td, row, col, prop, value, cellProperties) {
      let paddingValue = 25;
      if (row != 0) {
        paddingValue = paddingValue * (row + 1);
      }
      if (!td.firstElementChild) {
        let previewTdWrapper = document.createElement("div");
        previewTdWrapper.classList.add("preview-td-wrapper", "ivu-poptip");
        let previewIcon = document.createElement("span");
        previewIcon.classList.add("eye", "preivew-icon-span", "ivu-poptip-rel");
        previewIcon.innerHTML = '<i class="fas fa-eye preview-icon"></i>';
        let previewContent = document.createElement("div");
        previewContent.classList.add("ivu-poptip-popper");
        previewContent.style.position = "absolute";
        previewContent.style.willChange = "top,left";
        previewContent.style.top = paddingValue + "%";
        previewContent.style.left = "60px";
        previewContent.setAttribute("x-placement", "right")
        previewContent.id = "previewCount" + row;
        previewIcon.onclick = () => {
          this.CreatePreview(row, value);
        };
        previewTdWrapper.appendChild(previewIcon);
        previewTdWrapper.appendChild(previewContent);
        td.appendChild(previewTdWrapper);
      }
      if (this.configColumns.campaign.creativeCustomization == this.creativeType.None) {
        td.style.cursor = 'not-allowed';
        td.firstElementChild.classList.add('preview-icon-disabled')
      } else {
        td.style.cursor = 'default';
        td.firstElementChild.classList.remove('preview-icon-disabled')
      }
      return td;
    },
    CloseAudiencePopUp () {
      this.showAudiencePop = false;
    },
    launchAudiencePopup (row, value) {
      this.selectedRow = row;
      for (var i = 0; i < this.audiencesCluster.length; i++) {
        if (value == this.audiencesCluster[i].rowCount) {
          if (this.audiencesCluster[i].audience) {
            this.clusterId = this.audiencesCluster[i].id;
            this.selectedAudience = this.audiencesCluster[i].audience;
            this.showAudiencePop = true;
          } else {
            this.clusterId = this.audiencesCluster[i].id;
            this.selectedAudience = null;
            this.showAudiencePop = true;
          }
        }
      }
    },
    AddAudience (audience) {
      for (var i = 0; i < this.audiencesCluster.length; i++) {
        if (audience.targetId == this.audiencesCluster[i].id) {
          this.$set(this.audiencesCluster[i], "audience", audience);
          this.$set(
            this.audiencesCluster[i],
            "rowCount",
            audience.category.length +
              audience.product.length +
              audience.audienceId.length +
              " audiences selected"
          );
          this.showAudiencePop = false;
        }
      }
      this.$refs["hot4"].hotInstance.render()
    }
  },
  watch: {
    isRefreshEntitiesData: function () {
      if (this.isRefreshEntitiesData) {
        this.loadData();
        this.set_isRefreshEntitiesData(false);
      }
    },
    amsPublishedData () {
      for (var Index = 0; Index < this.amsPublishedData.length; Index++) {
        var data = this.amsPublishedData[Index];
        var index = this.lineItem.findIndex(item => item.Id == data.lineItemId.toString() && item.TargetId == data.clusterId);
        if (index != -1) {
          this.lineItem.splice(index, 1);
        }
      }
      if (this.lineItem.length == 0) {
        this.$store.commit("set_AmsLoadSave", false);
      } else {
      }
    },
    mappedLineItems () {
      this.GetTargetsData();
      this.allClusterIds = [];
      this.clusterIds = [];
      for (var i = 0; i < this.lineItem.length; i++) {
        this.allClusterIds.push(this.lineItem[i].clusterId);
        if (!this.lineItem[i].IsExported) {
          this.clusterIds.push(this.lineItem[i].clusterId);
        }
      }
      this.getAdSheetData();
    }
  }
};
</script>
<style>
.adPreviewTitle {
    color: rgb(17, 17, 17);
    font-size: 14px;
    font-weight: 400;
    flex-shrink: 0;
    height: auto;
    line-height: 20px;
    max-height: 60px;
    margin-bottom: 0px;
    overflow: hidden;
    overflow-wrap: break-word;
    direction: ltr;
    white-space: pre-wrap;
}
.previewHeadline {
    text-align: left;
    margin: 15px 15px 15px 0px;
    min-width: 0px;
    max-height: 36px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(204, 204, 204);
}
</style>
<style scoped>
.ad-sheet-hot .handsontable td,
.ad-sheet-hot .handsontable tr,
.ad-sheet-hot .handsontable th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
.td-text {
  display: inline-flex !important;
  float: left !important;
}
.edit-td-wrapper {
  display: flex;
  width: 100%;
  height: 50%;
}
.edit-icon {
  font-size: 18px;
  opacity: 1;
}
.edit-td-wrapper > span {
  display: inline-flex;
  float: right;
}
.preview-td-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.preview-td-wrapper > span {
  display: inline-flex;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.preview-icon {
  font-size: 18px;
  opacity: 1;
}
.preview-icon-disabled > span.eye {
  pointer-events: none;
  opacity: 0.5;
}
.hot-img {
  display: block;
  padding: 2px;
  margin: 0 auto;
  max-height: 70px;
  max-width: 100px;
}
.flex {
  display: flex;
  width: auto;
  height: auto;
}
::v-deep .ivu-poptip-popper[x-placement^=right] .ivu-poptip-arrow {
    left: -7px !important;
}
</style>
<style
  src="../../../../../../../node_modules/handsontable/dist/handsontable.full.css"
></style>
