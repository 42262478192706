<template>
    <div>
        <v-row v-for="(item, index) in molocoImpressionCaps" :key="item.id">
            <v-col span="8" v-if="item.selectedMolocoTargetKey !== undefined">
                <span class="title">Key: </span>{{getNameFromTargetingKey(item.selectedMolocoTargetKey)}}
            </v-col>
            <v-col span="16" v-if="item.selectedMolocoTargetKey !== undefined">
                <span class="title">Values: </span>{{getSelectedTargetingValues(item)}}
            </v-col>
            <v-row>
              <v-col span="24" v-if="item.selectedMolocoTargetKey !== undefined && index < molocoImpressionCaps.length -1">
                  <span class="title conditionColor">{{molocoTargetingSelectedCondition}}</span>
              </v-col>
            </v-row>
        </v-row>
        <v-row v-if="molocoSelectedCustomAudiencesNames.length">
        <br/>
          <v-col span="24" v-if="true">
            <span class="title conditionColor">AND</span>
          </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from "vuex";

import {
  Row,
  Col
} from "iview";

export default {
  props: {
    liveMolocoTarget: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  components: {
    "v-row": Row,
    "v-col": Col
  },
  data () {
    return {
      dummy: 0
    }
  },
  methods: {
    getNameFromTargetingKey (val) {
      const obj = this.molocoTargetingKeyValuePairs.find(item => item.key === val);
      return obj ? obj.name : null;
    },
    getSelectedTargetingValues (val) {
      if (val.selectedTargetingValues && val.selectedTargetingValues.length > 0) {
        return val.selectedTargetingValues.join(', ');
      } else {
        return null;
      }
    }
  },
  computed: {
    ...mapState([
      "molocoTargetingKeyValuePairs",
      "showMolocoTargetModalInSummary"
    ]),
    molocoImpressionCaps () {
      if (this.liveMolocoTarget) {
        return this.$store.state.molocoLiveImpressionCaps;
      } else {
        return this.$store.state.molocoImpressionCaps;
      }
    },
    molocoSelectedCustomAudiencesNames () {
      if (this.liveMolocoTarget) {
        return this.$store.state.molocoLiveCustomAudiencesNames;
      } else {
        return this.$store.state.molocoSelectedCustomAudiencesNames;
      }
    },
    molocoTargetingSelectedCondition () {
      if (this.liveMolocoTarget) {
        return this.$store.state.molocoTargetingLiveCondition;
      } else {
        return this.$store.state.molocoTargetingSelectedCondition;
      }
    }
  }
};
</script>

<style scoped>
.conditionColor{
  color: #979898;
  font-size: 11px;
}
.title {
  font-weight: bolder;
}
</style>
