import { FactoryConfig } from "./Configs/FactoryConfig.js";
import { FacebookConfig } from "./Configs/Facebook/Config.js";

function columnConfig (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.getConfig("columnConfig");
}

function displayHeaderNameLookup (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.displayHeaderNameLookup();
}

function columnWidths (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.columnWidths();
}

function getRnfAdformatsByObjective (config) {
  let configuration = new FacebookConfig(config);
  return configuration.getRnfAdFormats();
}
function getRnfPlacements (config) {
  let configuration = new FacebookConfig(config);
  return configuration.getPlacementsByObjectiveAndAdformats();
}

function placementOptions (config) {
  let configObj = (new FacebookConfig(JSON.parse(config))).config
  let placementType = configObj.adset.selectedPlacementOption
  let placementList = []

  if (placementType === "Manual") {
    getPlacementList(configObj, true)
  } else {
    getPlacementList(configObj, false)
  }

  function getPlacementList (configObj, isManual) {
    configObj.adset.placements.forEach(function (publishers) {
      let publisherName = publishers.title
      if (isManual) {
        publishers.children.forEach(function (placements) {
          if (placements.checked === true) {
            placements["publisher_platforms"] = publisherName
            placementList.push(placements);
          }
        });
      } else {
        publishers.children.forEach(function (placements) {
          placements["publisher_platforms"] = publisherName
          placementList.push(placements);
        });
      }
    });
  }
  return placementList
}

function getOptimizationGoals (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.getOptimizationGoals();
}

function columnConfigSDTarget (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.getTargetConfig();
}

function displayHeaderNameLookupSDTarget (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.displayHeaderNameLookupTarget();
}
function columnConfigSpKeywordTarget (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.getKeywordTargetConfig();
}

function displayHeaderNameLookupSpKeywordTarget (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.displayHeaderNameLookupTarget();
}
function columnConfigSpProductTarget (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.getProductTargetConfig();
}

function displayHeaderNameLookupSpProductTarget (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.displayHeaderNameLookupProductTarget();
}
function columnConfigSpAutoTarget (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.getAutoTargetConfig();
}

function displayHeaderNameLookupSpAutoTarget (config) {
  let service = new FactoryConfig(JSON.parse(config)).getService();
  return service.displayAutoTargetingHeaderNameLookupTarget();
}

export {
  columnConfig,
  columnWidths,
  displayHeaderNameLookup,
  placementOptions,
  getRnfAdformatsByObjective,
  getRnfPlacements,
  getOptimizationGoals,
  columnConfigSDTarget,
  displayHeaderNameLookupSDTarget,
  columnConfigSpKeywordTarget,
  columnConfigSpAutoTarget,
  displayHeaderNameLookupSpAutoTarget,
  displayHeaderNameLookupSpKeywordTarget,
  columnConfigSpProductTarget,
  displayHeaderNameLookupSpProductTarget
};
