import { ReportExportStatus } from "./constants.js";
import { APIService } from "./ApiService.js";
var pollingJobs = [];
var pollingInterval = null;
var _vueRef;

function AddJob (reportSchedulerId) {
  if (reportSchedulerId) {
    pollingJobs.push(reportSchedulerId);
  }
}

async function downloadReport (reportSchedulerId) {
  try {
    // let response = await APIService.GetReportExport(reportSchedulerId);
    var preSignedUrl = (await APIService.GetPreSignedurl(reportSchedulerId)).data;
    fetch(preSignedUrl)
      .then(res => res.blob())
      .then(blob => {
        var anchor = document.createElement('a'); // here making a temporary anchor to download report
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = preSignedUrl.split('/').pop().split('?')[0]; // correct file name
        anchor.click();
        window.URL.revokeObjectURL(anchor.href);
      });
  } catch (e) {
    console.log(e);
  }
}

async function poll () {
  if (!pollingJobs || pollingJobs.length === 0) {
    return;
  }

  try {
    const reportSchedulerIds = pollingJobs.join(',');
    const response = await APIService.GetReportExportsStatus(reportSchedulerIds);

    for (const reportScheduler of response.data) {
      if (reportScheduler.processingStatus === ReportExportStatus.Success || reportScheduler.processingStatus === ReportExportStatus.Failed) {
        pollingJobs = pollingJobs.filter(reportSchedulerId => reportSchedulerId !== reportScheduler.reportSchedulerId);

        if (reportScheduler.processingStatus === ReportExportStatus.Success) {
          await downloadReport(reportScheduler.reportSchedulerId);
        }
      }
    }

    if (pollingJobs.length === 0) {
      clearInterval(pollingInterval);
      pollingInterval = null;
    }
  } catch (error) {
    console.log(error);
    clearInterval(pollingInterval);
    pollingInterval = null;
    pollingJobs = [];
  }
}

async function Start (vueRef) {
  _vueRef = vueRef;
  pollingInterval = setInterval(async () => {
    await poll();
  }, 10000);
}

function GetPoolerStatus () {
  return pollingInterval;
}

export default {
  AddJob,
  Start,
  GetPoolerStatus
}
