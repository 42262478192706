import axiosInstance from '../../../Api'
let ApiService = {
  getChannels () {
    const channels = deltax.getChannelsUrl
    const promise = axiosInstance.get(channels);
    return promise;
  },
  getLinkEntityInformation (postData) {
    const lineItemEntityLinkDataUrl = deltax.lineItemEntityLinkDataUrl;
    const promise = axiosInstance({
      method: 'POST',
      url: lineItemEntityLinkDataUrl,
      data: postData
    });
    return promise;
  },
  linkEntity (entitiesLinked) {
    const saveLinkedEntitiesUrl = deltaXApiUrl.lineItems.saveLinkedEntities
      .replace('{businessProfileId}', deltax.businessProfileId)
      .replace(`{lineItemId}`, entitiesLinked.lineItemId)

    let data = JSON.stringify({
      entityTypeId: entitiesLinked.entityTypeId,
      LineItemEntities: entitiesLinked.lineItemEntityRequest
    });

    const promise = axiosInstance.post(saveLinkedEntitiesUrl, data);
    return promise;
  }
}
export { ApiService };
