import { engagement } from "./Engagement.js"
import { reach } from "./Reach.js"
import { twitterEnums } from "../../Constants/TwitterEnums.js";

class TwitterConfig {
  constructor (Config) {
    this.config = Config
  }
  getConfig (configName) {
    let config = { ...this.getTwitterConfig(configName) };
    return config
  }
  getTwitterConfig (configName) {
    switch (this.config.campaign.objective) {
      case twitterEnums.objectivesForAdSheet.Engagements:
        return engagement[configName]
      case twitterEnums.objectivesForAdSheet.Reach:
        return reach[configName]
      default:
        return null;
    }
  }
  getObjectiveClassById (id) {
    if (id == twitterEnums.objectivesForAdSheet["Engagements"]) {
      return engagement;
    } else if (id == twitterEnums.objectivesForAdSheet["Reach"]) {
      return reach;
    }
  }
  displayHeaderNameLookup () {
    return {
      "HOT_Is Valid": "Valid",
      "HOT_Ad Name": "Ad Name",
      "HOT_Tweet Id": "Tweet Id",
      "HOT_Target": "Target"
    }
  }
  columnWidths () {
    return {
      "default": 100,
      108: ["HOT_Is Valid"],
      200: [
        "HOT_Ad Name",
        "HOT_Tweet Id",
        "HOT_Target"
      ],
      240: []
    }
  }
}

export { TwitterConfig }
