<template>
  <div>
    <hot-table
      :key="rerenderKey"
      class="pc-hot"
      ref="placementHot"
      :hotSettings="buildHotSettings()"
      :hotData="hotData()"
    ></hot-table>
  </div>
</template>

<script>
import CreativeSetupHOT from "./CreativeSetupHOT.vue";
import { displayHeaderNameLookup } from "./../columnConfig.js";
import { mapState } from "vuex";
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js"
import * as fbEnums from "../Constants/FacebookEnums.js";

export default {
  components: {
    "hot-table": CreativeSetupHOT
  },
  props: {
    placementCustomizationData: {
      default: function () {
        return {};
      }
    },
    placementOptions: {},
    placementCustomizationHeaders: {},
    isPublished: { default: false }
  },
  mixins: [FacebookCampaignLauncherConfig],
  data: function () {
    return {
      handontableData: [],
      adData: {},
      globalDict: {},
      placementOptionRowIndex: {},
      rerenderKey: 1
    };
  },
  computed: {
    ...mapState([
      "imageIdUrlMap",
      "selectedCampaignLauncherConfig",
      "campaignLauncherConfigs",
      "selectedCampaignLauncherConfigId",
      "selectedFbObjective",
      "isPromotionMode"
    ])
  },
  methods: {
    buildHotSettings () {
      return {
        startCols: this.placementCustomizationHeaders.length,
        colHeaders: this.buildColumnHeaders(),
        colWidths: 200,
        width: "100%",
        height: 320,
        autoRowSize: true,
        autoColumnSize: true,
        viewportColumnRenderingOffset: 35,
        manualColumnResize: true,
        readOnly: "true"
      };
    },
    buildColumnHeaders () {
      let colHeaders = this.placementCustomizationHeaders
      let headerLookUp = displayHeaderNameLookup(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config)
      let displayColHeaders = colHeaders.map(x => headerLookUp[x] || headerLookUp[x.split(" - ")[0]] + " - " + x.split(" - ")[1])
      return displayColHeaders
    },
    hotData () {
      var self = this
      let placementOptions = this.placementOptions;
      let placementOptionsList = [];
      placementOptions.forEach(function (placements, index) {
        var combinedName = placements.publisher_platforms + " - " + placements.title
        placementOptionsList.push(combinedName);
        self.placementOptionRowIndex[combinedName] = index
      });
      return placementOptionsList;
    },
    appendHeadersWithData (data, tableHeaders) {
      let modifiedTableHeaders = []
      modifiedTableHeaders.push(tableHeaders)
      modifiedTableHeaders.push(data)
      return modifiedTableHeaders;
    },
    handsOnTableDataMapping (value, isPlacementData = false) {
      let data = JSON.parse(JSON.stringify(value));

      let headers = this.remapHeadlines(data[0])

      let filteredData = data.slice(1).map(item => item.reduce((obj, val, index) => {
        obj[headers[index]] = val
        return obj
      }, {}))
      return filteredData
    },
    remapHeadlines (headers) {
      var headlineRemap = {
        "HOT_Ad Title": "HOT_Headline",
        "HOT_Text": "HOT_Primary Text"
      };
      headers.forEach((e, i) => {
        Object.keys(headlineRemap).forEach(key => {
          if (e.includes(key)) {
            headers[i] = e.replace(key, headlineRemap[key])
          }
        })
      })
      return headers
    },
    removeDXidFromImageOrVideo (poupupDataAndMainTableData, pcMediaHeader) {
      var self = this;
      poupupDataAndMainTableData.forEach((elements) => {
        let value = elements[pcMediaHeader] ? elements[pcMediaHeader] : elements['HOT_Video'];
        if (value) {
          if (value.split('-')[0] === 'DXC' || self.imageIdUrlMap[value]) {
            if (self.imageIdUrlMap[value] && self.imageIdUrlMap[value]['SearchEngineVideoId']) {
              elements['HOT_Video'] = self.imageIdUrlMap[value]['SearchEngineVideoId']
              // replace thumbnail url if video thumbnail exists
              if (elements["HOT_Video_Thumbnail"]) {
                elements['thumbnail_url'] = this.setThumbnailUrl(elements, self.imageIdUrlMap);
              }
            } else if (self.imageIdUrlMap[value].hash) {
              elements['HOT_Image'] = self.imageIdUrlMap[value].hash
            } else {
              elements['HOT_Image'] = self.imageIdUrlMap[value].src
            }
          } else if (value.includes("http") || value.includes("www")) {
            elements['HOT_Image'] = value
          } else if (/^{{store\.asset([1-9]|[1-4]\d|50)}}$/.test(value)) {
            let defaultStoreAssets = this.$store.state.dbStores.find(s => s.isDefault == true).assets;
            let assetId = value.match(/\d+/)[0];
            let asset = defaultStoreAssets.find(a => a.id == parseInt(assetId, 10));
            if (asset.value.startsWith("DXC") && self.imageIdUrlMap[asset.value]) {
              if (self.imageIdUrlMap[asset.value]) {
                elements['HOT_Image'] = `{{store.asset${assetId}Hash}}`
              }
            } else if (asset.value.startsWith("DXV") && self.imageIdUrlMap[asset.value]) {
              elements['HOT_Video'] = `{{store.asset${assetId}SearchEngineVideoId}}`
              elements['thumbnail_url'] = `{{store.asset${assetId}ImageUrl}}`
            }
          } else {
            elements['HOT_Video'] = self.imageIdUrlMap[value]['SearchEngineVideoId']
            if (elements["HOT_Video_Thumbnail"]) {
              elements['thumbnail_url'] = this.setThumbnailUrl(elements, self.imageIdUrlMap);
            }
          }
        }
      })
      return poupupDataAndMainTableData
    },
    getAssetFeedSpecJSON (filteredData, mainSheetData, mainSheetColumnName) {
      let assetCR = [];
      let poupupDataAndMainTableData = []
      let placementCustomizationPopUpData = this.handsOnTableDataMapping(filteredData, true);
      let hotTableMainData = this.handsOnTableDataMapping(this.appendHeadersWithData(mainSheetData, mainSheetColumnName))
      poupupDataAndMainTableData = hotTableMainData.concat(placementCustomizationPopUpData)
      /*
      poupupDataAndMainTableData will always have main sheet data as row 0 and the the extra popup data.
      This will be the structure of  poupupDataAndMainTableData
      [
        {
        "HOT_Preview Ad": "true",
        "HOT_Ad Title": "Headline",
        "HOT_Ad Name": "AdName",
        "HOT_Link": "www.someLink.com",
        "HOT_Display Link": "www.DisplayLink.com",
        "HOT_Text": "Text",
        "HOT_Call To Action": "Apply Now",
        "HOT_Description": "Description",
        "HOT_Url Tags": "",
        "HOT_Image/Video": "DXC-2c6-19",
        "HOT_Targets": ""
        },
        {
        "HOT_Placements": "Facebook - Feed",
        "HOT_Image/Video": "DXC-2c6-19",
        "HOT_Text": "Primart Text - Popup",
        "HOT_Ad Title": "Headline - popup",
        "HOT_Link": "www.popuplink.com"
        },
        {
        "HOT_Placements": "Facebook - Right hand column",
        "HOT_Image/Video": "26665846515646854684684",
        "HOT_Text": "Primart Text - Popup",
        "HOT_Ad Title": "HeadlinePopup",
        "HOT_Link": "www.popuplink2.com"
        }
        ,
        .....
      ]
      */

      // In case of App Installs Objective we need to manually put the link part because it comes from the config.
      if (this.isAppInstallsObjective(this.selectedCampaignLauncherConfig) || this.selectedCampaignLauncherConfig.adset.destination == 2) {
        filteredData[0].push("HOT_Link")
        poupupDataAndMainTableData.forEach(row => {
          row["HOT_Link"] = this.selectedCampaignLauncherConfig.adset.app.storeUrl ? this.selectedCampaignLauncherConfig.adset.app.storeUrl : ""
        })
      }

      let placementCustomizationHeader = this.remapHeadlines(filteredData[0]);
      var headerStructure = ["HOT_Primary Text", "HOT_Headline", "HOT_Link", "HOT_Deep Link"]
      var mediaHeader = placementCustomizationHeader.find(x => x.includes("Image") || x.includes("Video"))
      let data = this.removeDXidFromImageOrVideo(poupupDataAndMainTableData, mediaHeader)
      var label = ""
      data.forEach((rowData) => {
        let referenceDict = []

        var keysInBodiesObject = Object.keys(rowData).filter(x => x.includes(headerStructure[0]))
        label = 'bodies' + '_' + Math.random().toString(36).substring(7)
        keysInBodiesObject.forEach(key => {
          rowData[key] = rowData[key] || "";
          let textPosition = [...new Set(data.map(data1 => Object.entries(data1).filter(x => x[0].includes(headerStructure[0])).map(x => x[1])).flat().filter(x => x != undefined && x != null))].indexOf(rowData[key]);
          referenceDict = this.CreatePlacementJSON('bodies', rowData, key, textPosition, referenceDict, label)
        })

        label = 'titles' + '_' + Math.random().toString(36).substring(7)
        var keysInTitlesObject = Object.keys(rowData).filter(x => x.includes(headerStructure[1]))
        keysInTitlesObject.forEach(key => {
          rowData[key] = rowData[key] || "";
          let headlinePosition = [...new Set(data.map(data1 => Object.entries(data1).filter(x => x[0].includes(headerStructure[1])).map(x => x[1])).flat().filter(x => x != undefined && x != null))].indexOf(rowData[key]);
          referenceDict = this.CreatePlacementJSON('titles', rowData, key, headlinePosition, referenceDict, label)
        })

        if (rowData["HOT_Image"]) {
          label = 'images' + '_' + Math.random().toString(36).substring(7)
          let imagePosition = [...new Set(data.map(item => item["HOT_Image"]).filter(x => x != undefined && x != null))].indexOf(rowData["HOT_Image"]);
          referenceDict = this.CreatePlacementJSON('images', rowData, "HOT_Image", imagePosition, referenceDict, label)
        } else if (rowData["HOT_Video"]) {
          label = 'videos' + '_' + Math.random().toString(36).substring(7)
          let videoPosition = [...new Set(data.map(item => item["HOT_Video"]).filter(x => x != undefined && x != null))].indexOf(rowData["HOT_Video"]);
          referenceDict = this.CreatePlacementJSON('videos', rowData, "HOT_Video", videoPosition, referenceDict, label)
        }

        rowData[headerStructure[2]] = rowData[headerStructure[2]] || rowData[headerStructure[3]] || "";
        label = 'link_urls' + '_' + Math.random().toString(36).substring(7)
        let linkPosition = [...new Set(data.map(item => item[headerStructure[2]]).filter(x => x != undefined && x != null && x != ""))].indexOf(rowData[headerStructure[2]]);
        referenceDict = this.CreatePlacementJSON('link_urls', rowData, headerStructure[2], linkPosition, referenceDict, label)

        assetCR.push(
          this.GenerateAssetCustomizationRulesJSON(rowData.HOT_Placements, referenceDict)
        )
      });
      var assetCustomizationSpec = [{ asset_customization_rules: assetCR }];
      var mainObject = {
        asset_feed_spec: Object.assign(this.adData, assetCustomizationSpec[0])
      };
      this.adData = {}
      this.globalDict = {}
      return this.putSinglePropertyData(mainObject, poupupDataAndMainTableData);
    },
    /*
    Play with the logic here -> https://playcode.io/placementcustomizationlogic_559620
    referenceName -> bodies,titles,images,videos,link_urls that need to be specified in JSON.
    position -> Used to insert the record in adlabels if duplicate columnHeader are found.
    referenceDict -> Used for a reference that could be used in adding customization rules.
    */
    CreatePlacementJSON (referenceName, rowData, columnHeader, position, referenceDict, label) {
      if (referenceName === "bodies" || referenceName === "titles" || referenceName === "link_urls") {
        rowData[columnHeader] = rowData[columnHeader] ? rowData[columnHeader].trim() : ""
      }
      referenceDict.push({ key: referenceName, value: label })
      if (this.adData[referenceName] !== undefined) {
        if (this.globalDict[columnHeader] && (rowData[columnHeader] && rowData[columnHeader] in this.globalDict[columnHeader])) {
          this.adData[referenceName][this.globalDict[columnHeader][rowData[columnHeader]].position]
            .adlabels
            .push(
              {
                name: label
              })
        } else if (rowData[columnHeader] == "" && this.adData[referenceName][0]) {
          this.adData[referenceName][0]
            .adlabels
            .push(
              {
                name: label
              })
        } else {
          this.adData[referenceName].push(this.getEntitySpecificJSON(rowData, columnHeader, label))
          if (!this.globalDict[columnHeader]) {
            this.globalDict[columnHeader] = {}
          }
          this.globalDict[columnHeader][rowData[columnHeader]] = {
            asset: rowData[columnHeader],
            label: label,
            position: position
          }
        }
      } else {
        this.adData[referenceName] = [
          this.getEntitySpecificJSON(rowData, columnHeader, label)
        ]
        if (!this.globalDict[columnHeader]) {
          this.globalDict[columnHeader] = {}
        }
        this.globalDict[columnHeader][rowData[columnHeader]] = {
          asset: rowData[columnHeader],
          label: label,
          position: position
        }
      }
      return referenceDict
    },
    GenerateAssetCustomizationRulesJSON (placements, referenceDict) {
      let publishers = {
        Facebook: "facebook",
        Instagram: "instagram",
        "Audience network": "audience_network",
        Messenger: "messenger"
      };
      let apiPositionsValueMapper = {
        Facebook: "facebook_positions",
        Instagram: "instagram_positions",
        "Audience network": "audience_network_positions",
        Messenger: "messenger_positions"
      };
      let apiPlacementsValuesMapper = {
        Feed: "feed",
        Search: "search",
        'Right hand column': 'right_hand_column',
        "Instant article": "instant_article",
        Marketplace: "marketplace",
        "Video feeds": "video_feeds",
        Story: "story",
        Stream: "stream",
        Explore: "explore",
        Classic: "classic",
        "Instream video": "instream_video",
        "Rewarded video": "rewarded_video",
        Home: "messenger_home",
        "Sponsored messages": "sponsored_messages",
        Reels: "reels",
        'Facebook Reels': 'facebook_reels'
      };
      let labelDict = {
        images: "image_label",
        bodies: "body_label",
        titles: "title_label",
        videos: "video_label",
        link_urls: "link_url_label"
      };

      let customizationSpec = {};
      referenceDict.forEach(entity => {
        customizationSpec[labelDict[entity.key]] = { name: entity.value };
      });
      // If there is no placement value then it would keep spec empty
      if (placements) {
        let placementKey = placements.split(" - ");
        customizationSpec["customization_spec"] = {
          publisher_platforms: [publishers[placementKey[0]]],
          [apiPositionsValueMapper[placementKey[0]]]: [apiPlacementsValuesMapper[this.updateWithNewPlacement(placementKey[0], placementKey[1])]]
        };
      } else {
        customizationSpec["customization_spec"] = {};
      }
      return customizationSpec;
    },
    updateWithNewPlacement (key, value) {
      if (key === "Instagram" && value === "Feed") {
        return "Stream";
      }
      if (key === "Facebook" && value === "Reels") {
        return "Facebook Reels";
      }
      return value;
    },
    /*
    This would put all the values that are not going to be more than once.
    Here data contains the main sheet data and popsheet data and have assured that main sheet data is always at the 0th index.
    */
    putSinglePropertyData (assetFeedSpec, data) {
      if (data[0]['HOT_Url Tags']) assetFeedSpec['url_tags'] = data[0]['HOT_Url Tags'];

      if (data[0]['HOT_Call To Action']) {
        let callToAction = data[0]['HOT_Call To Action'].split(' ').map(x => x.toUpperCase()).join('_')
        assetFeedSpec.asset_feed_spec['call_to_action_types'] = [callToAction]

        // Lead Generation objective needs call_to_actions to be set.
        if (this.isLeadGenerationObjective(this.selectedCampaignLauncherConfig)) {
          assetFeedSpec.asset_feed_spec["call_to_actions"] = [];
          assetFeedSpec.asset_feed_spec['call_to_action_types'].forEach(cta => {
            assetFeedSpec.asset_feed_spec["call_to_actions"].push({
              type: cta,
              value: {
                lead_gen_form_id: data[0]["HOT_Lead Form Id"]
              }
            });
          });
        }
      }

      // App Install objective needs deep link to be set in link_urls.
      if (this.isAppInstallsObjective(this.selectedCampaignLauncherConfig) || this.selectedCampaignLauncherConfig.adset.destination == fbEnums.destinationType.App) {
        assetFeedSpec.asset_feed_spec.link_urls[0]['deeplink_url'] = data[0]['HOT_Deep Link']
      }
      assetFeedSpec.asset_feed_spec['descriptions'] = [{ text: data[0]['HOT_Description'] ? data[0]['HOT_Description'] : "" }]
      assetFeedSpec.asset_feed_spec['ad_formats'] = (data[0]['HOT_Image']) ? ["SINGLE_IMAGE"] : ["SINGLE_VIDEO"]
      let instagramActorId = this.selectedCampaignLauncherConfig.instagramAccountId
      let pageId = this.selectedCampaignLauncherConfig.facebookPageId
      if (this.isPromotionMode) {
        instagramActorId = "{{store.instagramaccountid}}"
        pageId = "{{store.facebookpageid}}"
      }
      assetFeedSpec['object_story_spec'] = {
        page_id: pageId
      }
      if (instagramActorId) assetFeedSpec['object_story_spec'].instagram_actor_id = instagramActorId
      return assetFeedSpec;
    },
    getEntitySpecificJSON (rowData, columnHeader, label) {
      switch (columnHeader) {
        case "HOT_Link":
          return {
            website_url: rowData[columnHeader],
            display_url: rowData["HOT_Display Link"],
            url_tags: rowData["HOT_Url Tags"],
            adlabels: [
              {
                name: label
              }
            ]
          }
        case "HOT_Image":
          /* Check if hash or URl is comming under row data
            If the string is not a valid url we are sure that it is a hash.
          */
          if (!this.isValidURL(rowData[columnHeader])) {
            return {
              hash: rowData[columnHeader],
              adlabels: [
                {
                  name: label
                }
              ]
            }
          } else if (this.isValidURL(rowData[columnHeader])) {
            return {
              url: rowData[columnHeader],
              adlabels: [
                {
                  name: label
                }
              ]
            }
          }
          return null;
        case "HOT_Video":
          return {
            video_id: rowData[columnHeader],
            thumbnail_url: rowData["thumbnail_url"],
            adlabels: [
              {
                name: label
              }
            ]
          }
        default:
          return {
            text: rowData[columnHeader] ? rowData[columnHeader].trim() : "",
            adlabels: [
              {
                name: label
              }
            ]
          }
      }
    },
    validateData () {
      let invalidAssets = [];
      let alertMessage = "";
      const mediaData = this.$refs["placementHot"].getColumnData(1);
      const urls = this.$refs["placementHot"].getColumnData(4);
      let imagesNum = mediaData.filter(dxId => dxId.startsWith("DXC")).length;
      if (imagesNum > 0 && imagesNum < mediaData.length) {
        alertMessage += "Please use either images or videos only";
      }
      urls.forEach((url, index) => {
        if (!this.isValidURL(url) && url != "") {
          if (alertMessage !== "") {
            alertMessage += "<br>";
          }
          alertMessage += "Please use a valid Link";
        }
      })
      return {
        showAlert: alertMessage !== "",
        alertMessage
      };
    },
    isValidURL (url) {
      var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      return (res !== null)
    },
    getFormattedData () {
      this.handontableData = this.$refs["placementHot"].getRowData();
      this.handontableData.unshift(this.placementCustomizationHeaders);
      return this.handontableData;
    },
    setThumbnailUrl (elements, imageIdUrlMap) {
      let thumbnailUrl = ""
      let thumbnail = elements["HOT_Video_Thumbnail"];
      // if contains platform image then filter from imageIdUrlMap else apply the url
      if (thumbnail.split('-')[0] === 'DXC') {
        thumbnailUrl = imageIdUrlMap[thumbnail]['src']
      } else {
        thumbnailUrl = thumbnail;
      }
      return thumbnailUrl;
    }
  },
  watch: {
    "placementCustomizationData.hotTableData": {
      deep: true,
      immediate: true,
      handler () {
        this.rerenderKey ^= 1
        const hotData =
          !this.placementCustomizationData || !this.placementCustomizationData.hotTableData
            ? []
            : JSON.parse(JSON.stringify(this.placementCustomizationData.hotTableData.slice(1)));
        this.$nextTick(() => {
          hotData.forEach(rowData => {
            let rowIndex = this.placementOptionRowIndex[rowData[0]]
            this.$refs["placementHot"].setRowData(rowData, rowIndex);
          })
        });
      }
    },
    "placementCustomizationHeaders": {
      deep: true,
      immediate: true,
      handler (value) {
        this.rerenderKey ^= 1
      }
    }
  }
}
</script>

<style scoped>
.pc-hot {
  height: 400px;
}
.pc-hot .wtHolder {
  width: inherit;
  height: inherit;
}
</style>
<style>
.pc-hot .htDimmed {
  background-color: #f6f6f6 !important;
  color: #bbb !important;
}
</style>
