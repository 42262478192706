import { Objective } from "./Objective.js"
let ColumnConfig = function () {
  this["existing tweets"] = new function () {
    this.base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Tweet Id", "HOT_Target"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Tweet Id"];
    this.card = []
    this.requiredCard = []
  }();
}
let CreativeSpec = function () {
  this["existing tweets"] = new function () {
    this.creative = {
      "type": 1,
      "adName": "HOT_Ad Name",
      "tweetId": "HOT_Tweet Id",
      "targetId": "HOT_Target",
      "tweetText": "HOT_Tweet Text"
    }
  }();
}

let reach = new Objective(new ColumnConfig(), new CreativeSpec())
export { reach }
