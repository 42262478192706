import { render, staticRenderFns } from "./AmsSpTargetPreview.vue?vue&type=template&id=672a859b&scoped=true&"
import script from "./AmsSpTargetPreview.vue?vue&type=script&lang=js&"
export * from "./AmsSpTargetPreview.vue?vue&type=script&lang=js&"
import style0 from "./AmsSpTargetPreview.vue?vue&type=style&index=0&id=672a859b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "672a859b",
  null
  
)

export default component.exports