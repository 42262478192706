<template>
  <div>
    <div v-if="!enableButton" class="button-div">
      <v-tooltip trigger="hover" placement="bottom" :content="tooltipContent">
        <div class="col-md-1 plus-button">
          <button
            disabled="disabled"
            @click="showModal = true;"
            class="btn btn-margin-disable btn-success btn-sm widget-element"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </v-tooltip>
    </div>
    <div v-else class="button-div">
      <div class="col-md-1 plus-button">
        <button
          @click="showModal = true;"
          class="btn btn-margin-disable btn-success btn-sm widget-element"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
    <Modal
      class="neg-keyword-modal"
      title="New Negative Keyword List"
      v-model="showModal"
      effect="fade"
      width="80%"
      :scrollable="true"
      :styles="{top: '50px'}"
      :mask-closable="false"
    >
      <div class="alert alert-danger" v-if="showNotification" role="alert">
        {{ notificationMessage ? notificationMessage : 'Failed to create the Negative Keywords.' }}
        <button type="button" class="close" aria-label="Close" @click="showNotification = false; notificationMessage = '';">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form
        :label-width ="150"
        :model="negativeKeywordsForm"
        :rules="validationRules"
        ref="negativeKeywordsForm"
      >
        <Form-Item label="Account">
          <i-input
            type="text" disabled :placeholder= selectedBpseName class = "account-form-field"
          />
        </Form-Item>
        <div>
          <Form-Item label="Keyword List Name" prop="name">
            <i-input type="text" v-model="negativeKeywordsForm.name" class = "account-form-field"/>
          </Form-Item>
          <Row class="padding-top">
            <Row-Col class="padding-left" span="14">
              <label class="label-block">Choose Keywords</label>
            </Row-Col>
            <Row-Col span="10">
              <Row-Col span="6">
                <label class="label-block">Total Added ({{ validOutputLength }})</label>
              </Row-Col>
              <Row-Col span="6" offset="9">
                <button @click="removeAll($event)" class="btn btn-white btn-xs pull-right">Remove all</button>
              </Row-Col>
            </Row-Col>
          </Row>
          <Row>
            <Row-Col class="padding-left" span="10">
              <textarea class="textarea no-resize form-control" v-model="negativeKeywordsForm.input" cols="65" rows="10"></textarea>
            </Row-Col>
            <Row-Col class="padding-top" span="4">
              <div class="center-div">
                <CheckboxGroup v-model="negativeKeywordsForm.matchType">
                  <div
                      v-for="matchType in Object.entries(keywordsMatchType)"
                      :value="matchType[0]"
                      :key="matchType[0]"
                  >
                    <Checkbox :label="matchType[0]">
                      <Span>{{ `   ${matchType[0]}`}}</Span>
                    </Checkbox><br>
                  </div>
                </CheckboxGroup>
                <button
                  @click="generateNegativeKeywords($event)"
                  class="btn btn-success btn-sm widget-element"
                >
                  >>
                </button>
              </div>
            </Row-Col>
            <Row-Col class="padding-right" span="10">
              <div class="textarea-div">
                <div
                  v-for="(keyword, index) in validOutput"
                  :key="index"
                >
                  <Row>
                  <div class="targetedKeywordItemContainer">
                    <Row-Col span="17">
                      {{`${getKeywordWithSyntax(keyword.name, keyword.matchType)}`}}
                    </Row-Col>
                    <Row-Col span="5">
                      <div class="center-div">
                        <b>{{`${keyword.matchType}`}}</b>
                      </div>
                    </Row-Col>
                    <Row-Col span="2">
                      <div class="center-div">
                        <span @click="deleteKeyword(index)"  class="cursor-pointer delete-keyword-button fa fa-trash fa fa-white fa fa-large"></span>
                      </div>
                    </Row-Col>
                  </div>
                  </Row>
                </div>
              </div>
            </Row-Col>
          </Row>
        </div>
      </Form>
      <div slot="footer">
        <button
          class="btn btn-white"
          v-on:click='resetAllFields()'
          :disabled="showLoader"
        >Reset</button>
        <button
          class="btn btn-success"
          v-on:click="createNegativeKeywords($event)"
          :disabled="showLoader"
        >Save<i v-show="showLoader" class="fa fa-spinner fa-spin" /></button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { tooltip } from 'vue-strap'
import { mapState } from "vuex";
import {
  Form,
  FormItem,
  Input,
  Modal,
  Row,
  Col,
  Checkbox,
  CheckboxGroup,
  locale
} from "iview"
import lang from "iview/dist/locale/en-US";
import { APIService } from "../../ApiService.js";
import { adwordsEnums } from "../../Constants/AdwordsEnums.js";
locale(lang);

export default {
  computed: {
    ...mapState(["googleAccounts", "negativeKeywords", "businessProfilesSearchEngineId"]),
    validOutputLength () {
      return this.validOutput.length;
    },
    selectedBpse () {
      let self = this;
      return self.googleAccounts.find(e => e.Id == self.businessProfilesSearchEngineId);
    },
    selectedBpseName () {
      return this.selectedBpse ? `${this.selectedBpse.NickName} (${this.selectedBpse.CustomerId})` : '';
    },
    enableButton () {
      // we can have upto 20 negative keywords list for a bpse
      return (this.negativeKeywords && this.negativeKeywords.length < 20 && this.selectedBpseName);
    },
    tooltipContent () {
      return this.selectedBpseName ? "Maximum Limit Reached. An account can support upto 20 negative keyword lists only."
        : "Please select a campaign configuration to create.";
    }
  },
  components: {
    "i-input": Input,
    Form,
    FormItem,
    Modal,
    Row,
    "Row-Col": Col,
    Checkbox,
    CheckboxGroup,
    'v-tooltip': tooltip
  },
  data () {
    return {
      showModal: false,
      showNotification: false,
      notificationMessage: '',
      showLoader: false,
      negativeKeywordsForm: {
        bpseId: 0,
        name: '',
        input: '',
        matchType: []
      },
      keywordsMatchType: adwordsEnums.keywordsMatchType,
      negativeKeywordsMapper: adwordsEnums.negativeKeywordsMapper,
      validOutput: [],
      invalidChars: ['!', '@', '%', '*', '<', '(', ')', '~', '\\', '|', '?'],
      disableToolTip: false,
      validationRules: {
        name: [
          {
            required: true,
            message: "Please enter a name",
            trigger: "blur"
          }
        ]
      }
    }
  },
  methods: {
    createNegativeKeywords (event) {
      window.onbeforeunload = null;
      event.preventDefault();
      this.$refs.negativeKeywordsForm.validate((valid) => {
        let self = this;
        if (valid) {
          if (!self.validOutput || self.validOutput.length <= 0) {
            self.showNotification = true;
            self.notificationMessage = `Please enter keyword(s)`;
          } else {
            let requestObj = {};
            requestObj.Keywords = [];
            requestObj.Name = self.negativeKeywordsForm.name;
            self.validOutput.forEach(keyword => {
              requestObj.Keywords.push({
                Text: keyword.name,
                MatchType: self.keywordsMatchType[keyword.matchType]
              });
            });
            this.showLoader = true;
            APIService.postNegativeKeywords(deltax.businessProfileId, self.businessProfilesSearchEngineId, requestObj)
              .then(response => {
                this.$emit("newNegativeKeywordCreated",
                  {
                    id: response.id,
                    name: this.negativeKeywordsForm.name,
                    businessProfilesSearchEngineId: this.businessProfilesSearchEngineId
                  });
                this.showModal = false;
              })
              .catch(() => {
                this.notificationMessage = '';
                this.showNotification = true;
              })
              .finally(() => {
                this.showLoader = false;
              })
          }
        }
      });
    },
    resetAllFields () {
      this.$refs.negativeKeywordsForm.resetFields();
      this.validOutput = [];
      this.negativeKeywordsForm.input = '';
      this.negativeKeywordsForm.matchType = [];
      this.notificationMessage = '';
      this.showNotification = false;
    },
    generateNegativeKeywords (event) {
      let self = this;
      window.onbeforeunload = null;
      event.preventDefault();
      self.showNotification = false;
      self.notificationMessage = '';
      if (!self.negativeKeywordsForm.input) {
        self.showNotification = true;
        self.notificationMessage = `Please enter keyword(s)`;
      } else if (!self.negativeKeywordsForm.matchType || !self.negativeKeywordsForm.matchType.length) {
        self.showNotification = true;
        self.notificationMessage = `Please select atleast one match type from the checkbox`;
      } else {
        let keywords = [...new Set(self.negativeKeywordsForm.input.split('\n').filter(e => e))];
        self.negativeKeywordsForm.input = '';
        let allValid = true;
        keywords.forEach(keyword => self.invalidChars.forEach(invalidChar => {
          if (keyword.includes(invalidChar)) allValid = false;
        }));
        if (allValid) {
          self.negativeKeywordsForm.matchType.forEach(matchType => {
            keywords.forEach(keyw => {
              self.validOutput.push({ name: keyw, matchType: matchType });
            })
          });
        } else {
          self.showNotification = true;
          self.notificationMessage = `Keywords cannot contain non-standard characters like ${self.invalidChars.join(' ')}`;
        }
      }
    },
    getKeywordWithSyntax (keyword, matchType) {
      let self = this;
      switch (matchType) {
        case self.negativeKeywordsMapper.Exact:
          return `[${keyword}]`;
        case self.negativeKeywordsMapper.Phrase:
          return `"${keyword}"`;
        case self.negativeKeywordsMapper.Broad:
          return keyword;
        default:
          return keyword;
      }
    },
    removeAll (event) {
      window.onbeforeunload = null;
      event.preventDefault();
      this.negativeKeywordsForm.input = '';
      this.validOutput = [];
    },
    deleteKeyword (index) {
      this.validOutput.splice(index, 1);
    }
  },
  watch: {
    showModal () {
      this.resetAllFields();
    }
  }
}
</script>

<style scoped>
.modal-header button.close > span {
  font-size: 22px !important;
  color: initial;
}
.input-form > .ivu-form-item-error-tip {
  padding-top: 1px;
}
.input-form .ivu-form-item-error-tip {
  font-weight: normal !important;
}
.neg-keyword-modal ::v-deep .ivu-modal-body {
    height: 70vh;
    overflow-y: auto;
}
.btn {
  margin: 0px 0px 0px 15px;
}
.btn-margin-disable {
  margin: 0px 0px 0px 0px !important;
}
.neg-keword-body {
  overflow: auto;
  padding: 0px !important;
  height: calc(100vh - 100px);
  max-height: calc(100vh - 50px);
}
.account-form-field {
  width: 300px;
}
.label-block {
  color: #979898;
  padding: 5px;
}
.textarea {
  resize: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e4e4e4;
  height: 200px;
}
.padding-left {
  padding-left: 50px;
}
.padding-right {
  padding-right: 50px;
}
.padding-top {
  padding-top: 25px;
}
.center-div {
  width: 50%;
  margin: 0 auto;
}
.textarea-div {
    background-color: #fff;
    border: 1px solid #cbcdcc;
    font-size: 12px;
    height: 200px;
    overflow: auto;
    vertical-align: middle;
    width:100%;
}
.targetedKeywordItemContainer {
  background-color: #eee;
  border-color: silver silver -moz-use-text-color;
  float: left;
  margin: 1px;
  min-height: 15px;
  overflow: hidden;
  padding: 4px;
  text-align: left;
  vertical-align: bottom;
  width: 99%;
}
.cursor-pointer {
    cursor: pointer;
}
.delete-keyword-button {
  padding-top: 2px;
  float: right;
}
.keyword-match-type {
  float: right;
}
.plus-button {
  padding-left: 0px;
  padding-right: 30px;
}
.button-div {
  padding-left: 15px;
}
</style>
