var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cl-widget"},[_c('Card',[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h4',[_vm._v("\n            Targeting "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.loader),expression:"loader"}],staticClass:"fa fa-spinner fa-spin"})])]),_c('div',{staticClass:"col-md-6 no-padding"},[_c('Input',{staticClass:"search-bar input-search-bar",attrs:{"search":"","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-md-2 widget-element pull-right",class:{ disabled: _vm.businessProfilesSearchEngineId == 0 }},[_c('DV360TargetModal')],1)])]),_c('VuePerfectScrollbar',{staticClass:"widget-body"},[[(_vm.businessProfilesSearchEngineId == 0)?_c('div',{staticClass:"alert alert-info",staticStyle:{"margin-top":"10px"}},[_vm._v("\n          Please select a campaign configuration to see the saved targets.\n        ")]):(_vm.linkedInTargets.length <= 0)?_c('div',{staticClass:"alert alert-info",staticStyle:{"margin-top":"10px"}},[_vm._v("\n          There are no saved targets yet, create a new one to get started.\n        ")]):_vm._e()],(_vm.businessProfilesSearchEngineId > 0)?[(_vm.isFetchingTarget)?_c('div',[_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_vm._l((_vm.filteredTargets),function(target){return _c('Row',{key:target.id,staticClass:"cl-ivu-row"},[_c('Row',[_c('Row-Col',{attrs:{"span":"1"}},[_c('i',{staticClass:"fas fa-copy action-icon",on:{"click":function($event){_vm.copyToClipboard(
                    'DXT-' +
                      Number.parseInt(_vm.businessProfileId).toString(36) +
                      '-' +
                      target.id
                  )}}})]),_c('Row-Col',{staticClass:"cl-widget-text",attrs:{"span":"15","offset":"1"}},[_c('span',{staticClass:"target-name"},[_vm._v(_vm._s(target.name))])])],1),_vm._l((_vm.getTargetAdGroups(target.id)),function(adGroup){return _c('Row',{key:adGroup.id,staticStyle:{"margin-left":"25px"}},[_c('Row-Col',{attrs:{"span":"1"}},[_c('i',{staticClass:"fas fa-copy action-icon",on:{"click":function($event){_vm.copyToClipboard(
                    'DXTAG-' +
                      Number.parseInt(_vm.businessProfileId).toString(36) +
                      '-' +
                      adGroup.id
                  )}}})]),_c('Row-Col',{staticClass:"cl-widget-text",attrs:{"span":"15","offset":"1"}},[_c('span',{staticClass:"target-name"},[_vm._v(_vm._s(adGroup.name))])])],1)})],2)})]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }