<template>
  <div>
    <button
      @click="showModal = true"
      class="btn btn-success btn-sm widget-element pull-right"
    >
      <i class="fa fa-plus"></i>
    </button>

    <v-modal
      class="target-modal"
      title="Create Target"
      v-model="showModal"
      effect="fade"
    >
      <div slot="modal-body" class="targets-body">
        <Row>
          <Row-Col span="16">
            <Form
              :label-width="95"
              :model="targetForm"
              :rules="validationRules"
              ref="creationForm"
            >
              <br />

              <Row>
                <Row-Col span="9">
                  <Form-Item label="Account:" prop="bpseId">
                    <Select
                      v-model="targetForm.bpseId"
                      style="width:210px"
                      placeholder="Select an account"
                    >
                      <Option
                        v-for="account in linkedInAccounts"
                        :value="account.id"
                        :key="account.id"
                        >{{ account.accountNickName }} ({{
                          account.accountId
                        }})</Option
                      >
                    </Select>
                  </Form-Item>
                </Row-Col>
                <Row-Col span="15">
                  <Button class="float-right" @click="generateAudience">
                    Generate Audiences
                  </Button>
                  <Button class="float-right" @click="clearAll"
                    >Clear all</Button
                  >
                </Row-Col>
              </Row>
              <br />

              <template v-if="targetForm.bpseId">
                <Form-Item label="Name:" prop="targetName" class="target-name">
                  <Row>
                    <Row-Col span="15">
                      <i-input v-model="targetForm.targetName" />
                    </Row-Col>
                  </Row>
                  <Row>
                    <Row-Col span="15">
                      <ul class="macro-list">
                        <li class="macros">Available Macros:</li>
                        <li
                          v-for="macroName in targetMacros"
                          :key="macroName"
                          class="pointer"
                        >
                          <a class="macros" @click="addMacro(macroName)">
                            {{ macroName }}
                          </a>
                        </li>
                      </ul>
                    </Row-Col>
                  </Row>
                </Form-Item>

                <Form-Item label="Language:" prop="languages">
                  <Row
                    ><Row-Col span="15">
                      <Select
                        v-model="targetForm.languages"
                        multiple
                        :placeholder="langPlaceholder"
                        :disabled="languages.length == 0"
                      >
                        <Option
                          v-for="(lang, i) in languages"
                          :key="i"
                          :value="lang.urn"
                          >{{ lang.name }}</Option
                        >
                      </Select>
                    </Row-Col></Row
                  >
                </Form-Item>

                <div class="divider">
                  <span>Location</span>
                </div>
                <Form-Item label="Include:" prop="includedLocations">
                  <Row
                    ><Row-Col span="15">
                      <Select
                        multiple
                        v-model="targetForm.includedLocations"
                        filterable
                        placeholder="Search locations"
                        :remote-method="(query) => getLocations(query, 'included')"
                        :loading="locations.included.loading"
                      >
                        <Option
                          v-for="(loc, i) in locations.included.values"
                          :key="i"
                          :value="loc.urn"
                          >{{ loc.name }}</Option
                        >
                      </Select>
                    </Row-Col></Row
                  >
                </Form-Item>
                <Form-Item label="Exclude:" prop="excludedLocations">
                  <Row
                    ><Row-Col span="15">
                      <Select
                        multiple
                        v-model="targetForm.excludedLocations"
                        filterable
                        placeholder="Search locations"
                        :remote-method="(query) => getLocations(query, 'excluded')"
                        :loading="locations.excluded.loading"
                      >
                        <Option
                          v-for="(loc, i) in locations.excluded.values"
                          :key="i"
                          :value="loc.urn"
                          >{{ loc.name }}</Option
                        >
                      </Select>
                    </Row-Col></Row
                  >
                </Form-Item>

                <div class="divider">
                  <span>Audience</span>
                </div>
                <Form-Item label="Include:">
                  <Row
                    v-for="(includeObj, andArrIndex) of targetingCriteria.include
                      .and"
                    :key="andArrIndex"
                  >
                    <Row-Col span="20" class="audience-wrapper">
                      <target-facet
                        :targetingObj="includeObj"
                        :bpseId="targetForm.bpseId"
                        :urnNameMap="urnNameMap"
                        :isInclusion="true"
                        @removeAllFacets="removeInclusion(andArrIndex)"
                        @removeFacet="
                          removeIncludeFacet(andArrIndex, ...arguments)
                        "
                        @addFacet="
                          addInclusionFacet(andArrIndex, ...arguments)
                        "
                      />
                    </Row-Col>
                    <Row-Col
                      span="15"
                      v-if="
                        andArrIndex <
                          Object.keys(targetingCriteria.include.and).length - 1
                      "
                    >
                      <div class="and-text vertical-space">AND</div>
                    </Row-Col>
                  </Row>
                  <Row>
                    <Row-Col span="15">
                      <Button class="vertical-space" @click="addInclusion">
                        <i class="fa fa-plus"></i> And
                      </Button>
                    </Row-Col>
                  </Row>
                </Form-Item>

                <Form-Item label="Exclude:">
                  <Row v-if="!disableExclusion">
                    <Row-Col span="20" class="audience-wrapper">
                      <target-facet
                        :targetingObj="targetingCriteria.exclude"
                        :bpseId="targetForm.bpseId"
                        :urnNameMap="urnNameMap"
                        :isInclusion="false"
                        @removeAllFacets="disableExclusion = true"
                        @removeFacet="removeExcludeFacet"
                        @addFacet="addExclusionFacet"
                      />
                    </Row-Col>
                  </Row>
                  <Row v-else>
                    <Row-Col span="15">
                      <Button
                        class="vertical-space"
                        @click="disableExclusion = false"
                      >
                        <i class="fa fa-plus"></i> And
                      </Button>
                    </Row-Col>
                  </Row>
                </Form-Item>
              </template>
              <template v-else>
                <p style="text-align: center;">
                  Please select a LinkedIn account to create targets
                </p>
              </template>
            </Form>
          </Row-Col>

          <Row-Col span="8">
            <div class="preivew-wrapper">
              <div class="preview-header">
                <p>New Audience Preview</p>
              </div>
              <template v-if="isFormValid">
                <div
                  class="target-row"
                  v-for="(language, i) in targetForm.languages"
                  :key="i"
                >
                  <Row class="target-prev-header">
                    <Row-Col span="19">
                      <p>{{ getTargetName(language) }}</p>
                    </Row-Col>
                    <Row-Col span="2" offset="1">
                      <i
                        @click="saveTarget(language)"
                        class="fa fa-save preview-icon"
                      >
                      </i>
                    </Row-Col>
                    <Row-Col span="2">
                      <i
                        class="fa fa-trash preview-icon"
                        @click="removeTargetFromPreview(language)"
                      />
                    </Row-Col>
                  </Row>
                  <Row class="target-prev-body">
                    <target-preview-info
                      :targeting="targetingCriteria"
                      :includedLocations="targetForm.includedLocations"
                      :excludedLocations="targetForm.excludedLocations"
                      :language="language"
                      :urnNameMap="urnNameMap"
                    />
                  </Row>
                </div>
              </template>
              <template v-else>
                <div class="preview-placeholder">
                  Please enter the required fields to preview new audience
                </div>
              </template>
            </div>
          </Row-Col>
        </Row>
      </div>
      <div slot="modal-footer" class="modal-footer hide"></div>
    </v-modal>
  </div>
</template>

<script>
import { APIService } from "../../ApiService.js";
import LinkedInTargetFacets from "../../Constants/LinkedInTargetFacets.js";
import { mapState, mapMutations } from "vuex";
import {
  Row,
  Col,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  Button,
  locale
} from "iview";
import { modal } from "vue-strap";
import lang from "iview/dist/locale/en-US";
import TargetFacet from "./TargetFacet.vue";
import TargetPreviewInfo from "./TargetPreviewInfo.vue";
import { EventBus } from "../../EventBus.js";
locale(lang);

export default {
  components: {
    Row,
    "Row-Col": Col,
    Form,
    FormItem,
    Select,
    Option,
    "i-input": Input,
    Button,
    TargetFacet,
    TargetPreviewInfo,
    "v-modal": modal
  },
  data () {
    return {
      showModal: false,
      urnNameMap: LinkedInTargetFacets.reduce((dict, facet) => {
        dict[facet.urn] = facet.name;
        return dict;
      }, {}),
      targetMacros: ["Language", "Location", "Gender", "AgeGroup"],
      languages: [],
      langPlaceholder: "Select language",
      locations: {
        included: {
          values: [],
          loading: false
        },
        excluded: {
          values: [],
          loading: false
        }
      },
      targetForm: {
        bpseId: null,
        targetName: "",
        languages: [],
        includedLocations: [],
        excludedLocations: []
      },
      validationRules: {
        bpseId: [
          {
            required: true,
            type: "number",
            message: "Please select an account",
            trigger: "change"
          }
        ],
        targetName: [
          { required: true, message: "Please enter a name", trigger: "blur" }
        ],
        languages: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value || !Array.isArray(value) || value.length == 0) {
                callback(new Error("Please select a language"));
              }
              callback();
            }
          }
        ],
        includedLocations: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value || !Array.isArray(value) || value.length == 0) {
                callback(new Error("Please include a location"));
              }
              callback();
            }
          }
        ]
      },
      disableExclusion: true,
      targetingCriteria: {
        include: {
          // The include object contains a single array object called "and"
          and: [
            {
              or: {}
            }
          ]
        },
        exclude: {
          // The exclude object contains a single "or" object
          or: {}
        }
      }
    };
  },
  computed: {
    ...mapState(["linkedInAccounts", "savedTargetsMapperByBpseId", "savedTargets"]),
    isFormValid () {
      return (
        this.targetForm.includedLocations.length > 0 &&
        this.targetForm.languages.length > 0 &&
        this.targetForm.targetName
      );
    }
  },
  watch: {
    showModal () {
      if (!this.showModal) {
        this.clearAll();
      }
    },
    "targetForm.bpseId": function () {
      this.languages = [];
      if (!this.targetForm.bpseId) { return }

      this.langPlaceholder = "Fetching languages...";
      APIService.getLinkedInTargetEntities(
        this.targetForm.bpseId,
        "urn:li:adTargetingFacet:interfaceLocales"
      )
        .then(response => {
          this.languages = response.data.data;
          this.addToUrnNameMap(this.languages);
          this.langPlaceholder = "Select language";
        })
        .catch(error => {
          console.error(error);
          this.langPlaceholder = "Failed to load languages";
        });
    }
  },
  methods: {
    ...mapMutations(["set_savedTargetsMapperByBpseId", "set_savedTargets"]),
    generateAudience () {
      this.$refs.creationForm.validate()
    },
    getLocations (query, locType) {
      if (!query) {
        return;
      }
      this.$set(this.locations[locType], "loading", true);
      APIService.getLinkedInTargetEntities(
        this.targetForm.bpseId,
        "urn:li:adTargetingFacet:locations",
        query
      )
        .then(response => {
          this.$set(this.locations[locType], "values", response.data.data);
          this.addToUrnNameMap(response.data.data);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.$set(this.locations[locType], "loading", false);
        });
    },
    addToUrnNameMap (entities) {
      entities.forEach(entity => {
        this.$set(this.urnNameMap, entity.urn, entity.name);
      })
    },
    getUrnName (urnId) {
      return this.urnNameMap[urnId];
    },
    removeIncludeFacet (andArrIndex, facetName) {
      this.$delete(
        this.targetingCriteria.include.and[andArrIndex].or,
        facetName
      );
    },
    addInclusion () {
      this.targetingCriteria.include.and.push({
        or: {}
      });
    },
    removeInclusion (andArrIndex) {
      this.$delete(this.targetingCriteria.include.and, andArrIndex);
    },
    addInclusionFacet (andArrIndex, { category, attribute, urnNameMap }) {
      this.$set(
        this.targetingCriteria.include.and[andArrIndex].or,
        category,
        attribute
      );
      this.urnNameMap = { ...this.urnNameMap, ...urnNameMap };
    },
    removeExcludeFacet (facetName) {
      this.$delete(this.targetingCriteria.exclude.or, facetName);
    },
    addExclusionFacet ({ category, attribute, urnNameMap }) {
      this.$set(
        this.targetingCriteria.exclude.or,
        category,
        attribute
      );
      this.urnNameMap = { ...this.urnNameMap, ...urnNameMap };
    },
    getTargetName (langUrn) {
      let genderArr = []; let ageGroupArr = [];

      this.targetingCriteria.include.and.forEach(facetObj => {
        let genders = facetObj.or["urn:li:adTargetingFacet:genders"];
        let ageGroups = facetObj.or["urn:li:adTargetingFacet:ageRanges"];
        if (genders) {
          genderArr.push(...genders.map(this.getUrnName))
        }
        if (ageGroups) {
          ageGroupArr.push(...ageGroups.map(this.getUrnName))
        }
      })

      let macroDict = {
        Language: this.getUrnName(langUrn),
        Location: this.targetForm.includedLocations
          .map(this.getUrnName)
          .join(", "),
        Gender: [...new Set(genderArr)].join(", "),
        AgeGroup: [...new Set(ageGroupArr)].join(", ")
      };
      let replacedTargetName = this.targetForm.targetName;
      for (let [macro, value] of Object.entries(macroDict)) {
        replacedTargetName = replacedTargetName.replace(`{${macro}}`, value);
      }
      return replacedTargetName;
    },
    addMacro (name) {
      if (!this.targetForm.targetName.includes(`{${name}}`)) {
        if (this.targetForm.targetName) {
          this.targetForm.targetName += "-";
        }
        this.targetForm.targetName += `{${name}}`;
      }
    },
    clearAll () {
      this.$refs.creationForm.resetFields();
      this.$set(this.targetingCriteria.include, "and", [{ or: {} }]);
      this.$set(this.targetingCriteria.exclude, "or", {});
      this.disableExclusion = true;
    },
    removeTargetFromPreview (language) {
      let index = this.targetForm.languages.indexOf(language);
      if (index >= 0) {
        this.$delete(this.targetForm.languages, index);
      }
    },
    async saveTarget (language) {
      let targetCopy = JSON.parse(JSON.stringify(this.targetingCriteria));
      targetCopy.include.and.push({
        or: {
          "urn:li:adTargetingFacet:interfaceLocales": [language]
        }
      });
      targetCopy.include.and.push({
        or: {
          "urn:li:adTargetingFacet:locations": this.targetForm.includedLocations
        }
      });
      targetCopy.exclude.or["urn:li:adTargetingFacet:locations"] = this.targetForm.excludedLocations;

      // language => urn:li:locale:{languageCode}_{countryCode}. Eg: urn:li:locale:en_US
      let [languageCode, countryCode] = language.split(":").pop().split("_")
      let localeInfo = {
        language: languageCode,
        country: countryCode
      }

      let target = {
        locationTargetType: 0,
        name: this.getTargetName(language),
        agencyid: deltax.businessProfileId,
        advertiserid: deltax.businessProfileId,
        businessProfileId: deltax.businessProfileId,
        businessProfilesSearchEngineId: this.targetForm.bpseId,
        isCreatedOnDx: true,
        targetString: JSON.stringify(targetCopy),
        dynamicTargetString: JSON.stringify(localeInfo),
        estimatedReach: 0
      }

      try {
        let response = await APIService.saveTarget(target);
        target.id = response.data.data;
        this.$Message.success({
          background: true,
          content: `${target.name} successfully created`
        });

        this.set_savedTargets([...this.savedTargets, target]);

        let targetsBpseMap = this.savedTargetsMapperByBpseId;
        if (!(this.targetForm.bpseId in targetsBpseMap)) {
          targetsBpseMap[this.targetForm.bpseId] = [];
        }
        targetsBpseMap[this.targetForm.bpseId].push({
          BpseId: this.targetForm.bpseId,
          EstimatedReach: 0,
          Id: response.data.data,
          Name: target.name
        });
        this.set_savedTargetsMapperByBpseId(targetsBpseMap);

        // emit to show created targets in mapper screen
        target.Id = target.id;
        target.bpseId = target.businessProfilesSearchEngineId;
        target.Name = target.name;
        EventBus.$emit('linkedin-updateSavedTarget', target, false);
      } catch (ex) {
        console.error(ex);
        this.$Message.error({
          background: true,
          content: "There was a problem while processing your request"
        });
      }
    }
  }
};
</script>

<style scoped>
.preivew-wrapper {
  border: 1px solid #ddd;
  margin: 15px;
  width: calc(100% - 30px);
}
.preview-placeholder {
  margin: 10px;
  font-weight: normal;
  color: #979696;
}
.preview-header {
  background-color: #f4f4f4;
  height: 35px;
  line-height: 35px;
  padding: 0 10px 0 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid var(--main-bg-color);
}
.preview-header p {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
::v-deep .push-left {
  margin-left: 20px;
}
.vertical-space {
  margin-top: 10px;
  margin-bottom: 10px;
}
.audience-wrapper {
  padding: 15px;
  border: 1px solid #ddd;
  background: #f4f4f4;
  padding: 15px;
}
.audience {
  border: 1px solid #ddd;
  padding: 10px;
  background: white;
}
.and-text {
  font-weight: bold;
}
.float-right {
  float: right;
  margin-left: 10px;
}
.macro-list {
  margin-bottom: 0px;
}
.macro-list > li {
  display: inline-block;
}
.pointer {
  cursor: pointer;
}
.macros {
  padding-right: 10px;
}
::v-deep .space {
  margin-bottom: 7px;
}
.target-row {
  margin: 10px;
  border: 1px solid #ddd;
}
.target-prev-header {
  padding: 0 5px;
  background: #f4f4f4;
  border-bottom: 2px solid var(--main-bg-color);
}
.target-prev-header p {
  margin: 2px 0 4px 0;
}
.target-prev-body {
  padding: 5px;
  font-weight: normal;
}
.preview-icon {
  margin-top: 3px;
  cursor: pointer;
  color: #888888;
}
.preview-icon:hover {
  color: #707376;
}
.preview-icon:active {
  transform: translateY(1px);
}
.divider {
  width: 100%;
  height: 7px;
  border-bottom: 1px solid #ccc;;
  position: relative;
  margin-bottom: 36px;
}
.divider > span {
  left: 36px;
  position: absolute;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #979898;
  background-color: white;
  padding: 0 10px;
}
</style>
<style>
.modal-header button.close > span {
  font-size: 22px !important;
  color: initial;
}
.target-name > .ivu-form-item-error-tip {
  padding-top: 1px;
}
.target-modal .modal-header {
  padding-bottom: 5px !important;
}
.target-modal .modal-dialog {
  width: 98%;
  height: 100%;
  z-index: 9997;
}
.targets-body {
  overflow: auto;
  padding: 0px !important;
  height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
}
</style>
<style src="../../../../Styles/dx-iview.css"></style>
