<template>
  <Icon type="logo-google" class="picker-api-auth-btn" />
</template>

<script src="https://apis.google.com/js/api.js"></script>

<script>
import { mapState, mapMutations } from "vuex"
import { Icon } from "iview";

export default {
  components: {
    Icon
  },
  data() {
    return {
      developerKey: "AIzaSyCGd9-ToF15tANMUSUBECx8pthRm0d8bkI",
      clientId: "306874698341-h5e7q5psd2m5jf25cqup9horgs6vbaip.apps.googleusercontent.com",
      appId: "306874698341",
      scope: ["https://www.googleapis.com/auth/drive.file"],
      pickerApiLoaded: false,
      oauthToken: ""
    };
  },
  mounted() {
    if (this.isPickerApiMounted) {
      this.onApiLoad()
    } else {
      let googleApi = document.createElement("script");
      googleApi.setAttribute("src", "https://apis.google.com/js/api.js");
      googleApi.onload = () => {
        this.onApiLoad();
      };
      document.head.appendChild(googleApi);

      let pickerCss = ".picker-dialog { z-index: 1300 !important; }";
      let styleElm = document.createElement("style");
      styleElm.type = "text/css";
      styleElm.appendChild(document.createTextNode(pickerCss));
      document.head.appendChild(styleElm);

      this.$store.commit("set_isPickerApiMounted", true)
    }
  },
  computed: {
    ...mapState(["isPickerApiMounted"]),
  },
  methods: {
    ...mapMutations(["set_isPickerApiMounted"]),
    onApiLoad() {
      gapi.load("auth", { callback: this.onAuthApiLoad });
      gapi.load("picker", { callback: this.onPickerApiLoad });
    },

    onAuthApiLoad() {
      // let authBtn = document.getElementById("auth");
      let authBtns = [...document.getElementsByClassName("picker-api-auth-btn")];
      authBtns.forEach(authBtn => {
        authBtn.addEventListener("click", () => {
          window.gapi.auth.authorize(
            {
              client_id: this.clientId,
              scope: this.scope,
              immediate: false
            },
            this.handleAuthResult
          );
        });
      })
    },

    onPickerApiLoad() {
      this.pickerApiLoaded = true;
      this.createPicker();
    },

    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      }
    },

    // Create and render a Picker object for picking from Google Photos.
    createPicker() {
      if (this.pickerApiLoaded && this.oauthToken) {
        let picker = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.NAV_HIDDEN)
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .setAppId(this.appId)
          .setOAuthToken(this.oauthToken)
          .addView(google.picker.ViewId.DOCS_IMAGES)
          .addView(new google.picker.DocsUploadView())
          .setDeveloperKey(this.developerKey)
          .setCallback(this.pickerCallback)
          .build();
        picker.setVisible(true);
      }
    },

    downloadImage(url, fileName, lastModifiedDate) {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader("Authorization", "Bearer " + this.oauthToken);
      xhr.responseType = "blob";
      xhr.onload = () => {
        let image = new File([xhr.response], fileName, {
          type: xhr.response.type,
          lastModified: lastModifiedDate
        });
        // $parent is CreativeLibraryWidget.vue
        this.$emit('saveImage', image);
      };
      xhr.onerror = () => {
        console.log(`An error occurred while downloading ${fileName} - ${url}`);
      };
      xhr.send();
    },

    // A simple callback implementation.
    pickerCallback(data) {
      if (data.action == google.picker.Action.PICKED) {
        data.docs.forEach(doc => {
          let fileId = doc.id;
          let fileName = doc.name;
          let lastModifiedDate = doc.lastEditedUtc;
          this.downloadImage(
            `https://www.googleapis.com/drive/v2/files/${fileId}?alt=media`,
            fileName,
            lastModifiedDate
          );
        });
      }
    }
  }
};
</script>
