import axios from 'axios'

var HTTP = axios.create({})

var dBAuthFlowToFrontEndAuthFlow = function (dBAuthFlow) {
  var frontEndAuthFlow = JSON.parse(dBAuthFlow.AuthorizationFlowJson);
  frontEndAuthFlow.Id = dBAuthFlow.Id;
  return frontEndAuthFlow
}

var getAuthFlows = function (responseHandler) {
  HTTP.get(deltax.getAuthFlows)
    .then(httpResponse => {
      if (httpResponse.data && httpResponse.data.AuthFlows) {
        var authFlows = httpResponse.data.AuthFlows.map(x => dBAuthFlowToFrontEndAuthFlow(x));
        responseHandler({ Success: true, Data: { AuthFlows: authFlows } })
      } else {
        responseHandler({ Success: false, Data: httpResponse.message })
      }
    })
    .catch(httpResponse =>
      responseHandler({ Success: false, Data: httpResponse.message }))
}

var addAuthFlow = function (name, authflow, appliesTo, responseHandler) {
  HTTP.post(deltax.addAuthFlow, { authFlowName: name, authFlow: JSON.stringify(authflow), entityTypeIds: appliesTo })
    .then(httpResponse => {
      if (httpResponse.data && httpResponse.data.AuthFlow) {
        var newAuthflow = dBAuthFlowToFrontEndAuthFlow(httpResponse.data.AuthFlow);
        updateAuthFlow(newAuthflow.Name, newAuthflow, newAuthflow.AppliesTo.map(d => d.Id), responseHandler)
      } else {
        responseHandler({ Success: false, Data: httpResponse.message })
      }
    })
    .catch(httpResponse =>
      responseHandler({ Success: false, Data: httpResponse.message }))
}

var updateAuthFlow = function (name, authflow, appliesTo, responseHandler) {
  HTTP.post(deltax.updateAuthFlow, { authFlowId: authflow.Id, authFlowName: name, authFlow: JSON.stringify(authflow), entityTypeIds: appliesTo })
    .then(httpResponse => {
      if (httpResponse.data && httpResponse.data.AuthFlow) {
        var existingAuthFlow = dBAuthFlowToFrontEndAuthFlow(httpResponse.data.AuthFlow);
        responseHandler({ Success: true, Data: { AuthFlow: existingAuthFlow } })
      } else {
        responseHandler({ Success: false, Data: httpResponse.message })
      }
    })
    .catch(httpResponse =>
      responseHandler({ Success: false, Data: httpResponse.message }))
}

var deleteAuthFlow = function (authflow, responseHandler) {
  HTTP.post(deltax.deleteAuthFlow, { authFlowId: authflow.Id })
    .then(httpResponse => {
      if (httpResponse.data && httpResponse.data.Deleted) {
        responseHandler({ Success: true })
      } else {
        responseHandler({ Success: false, Data: httpResponse.message })
      }
    })
    .catch(httpResponse =>
      responseHandler({ Success: false, Data: httpResponse.message }))
}

var getBPUsers = function (responseHandler) {
  HTTP.get(deltax.getBPUsers)
    .then(httpResponse => {
      if (httpResponse.data && httpResponse.data.BPUsers) {
        responseHandler({ Success: true, Data: { BPUsers: httpResponse.data.BPUsers } })
      } else {
        responseHandler({ Success: false, Data: httpResponse.message })
      }
    })
    .catch(httpResponse =>
      responseHandler({ Success: false, Data: httpResponse.message }))
}

export const api = {
  GetAuthFlows: getAuthFlows,
  AddAuthFlow: addAuthFlow,
  UpdateAuthFlow: updateAuthFlow,
  DeleteAuthFlow: deleteAuthFlow,
  GetBPUsers: getBPUsers
}
