import * as molocoEnums from "../Constants/MolocoEnums";

const invalidValues = [undefined, null, ""];

export default {
  methods: {
    getDefaultConfig () {
      const {
        objectives,
        priorityList,
        countries,
        Campaignstatus,
        dealTypes,
        budgetTypes,
        fixedBudgetTypes
      } = molocoEnums;

      return {
        searchEngineTypeId: 45,
        name: null,
        bpseId: null,
        campaign: {
          name: null,
          objective: objectives["Brand Awareness"],
          app: null,
          appPlatform: null,
          priority: priorityList["HIGH"],
          mmpTrackingLink: null,
          mmpTrackingCompany: null,
          adUnitTargeting: [],
          landingUrl: null,
          jioAdsCampaignId: null,
          roId: null,
          maximumImpressions: null,
          country: countries["India"],
          conversionSettings: null,
          status: Campaignstatus["Active"],
          description: null
        },
        dealType: dealTypes[objectives["Brand Awareness"]]["DIRECT_DEALS"],
        targetCpm: null,
        targetCpl: null,
        schedule: {
          start: null,
          end: null,
          options: {
            startDateOptions: {},
            endDateOptions: {}
          }
        },
        dailyMaxImpressions: null,
        impressionInterval: {},
        cpiCeiling: null,
        budgetType: budgetTypes["Average Daily Budget"],
        averageDailyBudget: null,
        fixedDailyBudget: null,
        weeklyDailyBudget: {},
        fixedBudgetType: fixedBudgetTypes["Daily"],
        keyValueImpressionCaps: null,
        impressionIntervalValue: null,
        budgetPackage: 0,
        isScheduleEndDateNotRequired: false
      };
    },
    genericValidator (rules, value, callback) {
      const hasValue = !invalidValues.includes(value);

      if (rules.required && !hasValue) {
        callback(new Error(rules.message || "Required"));
      } else if (hasValue && typeof rules._baseValidator === "function") {
        callback(rules._baseValidator(value));
      } else callback();
    },
    numberValidator (value, min = 1, max = null) {
      const num = parseInt(value);

      if (isNaN(num)) return new Error("Please enter a valid number");
      else if (num < min && max == null) {
        return new Error(`Then value cannot be less than ${min}`);
      } else if (num < min || (max != null && num > max)) {
        return new Error(`The value must be between ${min} and ${max}`);
      }
    },
    lengthValidator (value, max) {
      if (typeof value != "string") {
        return new Error("Please enter a valid string");
      } else if (value.length > max) {
        return new Error(`Value must be within ${max} characters`);
      }
    },
    urlValidator (url) {
      const urlRegExp = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

      if (!urlRegExp.test(url)) {
        return new Error("Please enter a valid Landing URL");
      }
    },
    dailyMaxImpressionCapValidator (dailyMaxImpressionCap, maxImpressionCap) {
      if (invalidValues.includes(dailyMaxImpressionCap)) dailyMaxImpressionCap = NaN;
      if (invalidValues.includes(maxImpressionCap)) maxImpressionCap = NaN;

      dailyMaxImpressionCap = Number(dailyMaxImpressionCap);
      maxImpressionCap = Number(maxImpressionCap);

      if (isNaN(maxImpressionCap)) {
        return new Error("Please enter Campaign Maximum Impressions");
      } else if (isNaN(dailyMaxImpressionCap)) {
        return new Error("Please enter a valid number");
      } else if (dailyMaxImpressionCap > maxImpressionCap) {
        return new Error(
          "The value cannot be more than Campaign Maximum Impressions"
        );
      } else {
        return this.numberValidator(dailyMaxImpressionCap, 1);
      }
    },
    scheduleValidator (schedule, callback) {
      let { start, end, noEnd } = schedule;

      if (start && !(start instanceof Date)) start = new Date(start);
      if (end && !(end instanceof Date)) end = new Date(end);

      if (!start && !noEnd && !end) {
        callback(new Error("Please select start and end date / time"));
      } else if (!start) {
        callback(new Error("Please select start date / time"));
      } else if (!noEnd && !end) {
        callback(new Error("Please select end date / time"));
      } else if (!noEnd && start.getTime() >= end.getTime()) {
        callback(
          new Error("End date / time must be greater than start date / time")
        );
      } else callback();
    }
  }
};
