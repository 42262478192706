import axiosInstance from '../../../Api'
export class ApiService {
  async saveComment (mediaPlanId, mediaPlanComment, commentType) {
    var url = `${deltax.apiBaseUrl}businessProfiles/${deltax.businessProfileId}/mediaPlans/${mediaPlanId}/comments`;
    var payload = {
      message: mediaPlanComment,
      commentType: commentType
    };
    var response = await axiosInstance.post(url, payload);
    return response.data.data;
  }
  getComments (mediaPlanId) {
    var promise = $.ajax({
      url: deltax.getMediaPlanCommentsUrl,
      type: "POST",
      dataType: "json",
      data: { mediaPlanId: mediaPlanId }
    });
    return promise;
  }
}
