import { render, staticRenderFns } from "./LeadFormWidget.vue?vue&type=template&id=63e736c0&scoped=true&"
import script from "./LeadFormWidget.vue?vue&type=script&lang=js&"
export * from "./LeadFormWidget.vue?vue&type=script&lang=js&"
import style0 from "./LeadFormWidget.vue?vue&type=style&index=0&id=63e736c0&scoped=true&lang=css&"
import style1 from "../../../../Styles/dx-iview.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e736c0",
  null
  
)

export default component.exports