import { Objective } from "./Objective.js"
let ColumnConfig = function () {
  this.single = new function () {
    this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_Primary Text", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations"]
    this.requiredBase = ["HOT_Ad Name", "HOT_Image/Video"]
    this.card = []
    this.requiredCard = []
    this.callToAction = []
  }()
}
let CreativeSpec = function () {
  this.single = new function () {
    this.image = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "photo_data": {
            "url": "HOT_Image/Video",
            "caption": "HOT_Primary Text"
          }
        }
      }
    };
    this.video = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "video_data": {
            "image_url": "HOT_Image/Video",
            "video_id": "HOT_SearchEngineVideoId",
            "message": "HOT_Primary Text"
          }
        }
      }
    }
  }()
}

let pageLike = new Objective(new ColumnConfig(), new CreativeSpec())
export { pageLike }
