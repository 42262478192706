<template>
  <Form
    label-position="right"
    :label-width="200"
    :model="config"
    :rules="validationRules"
    ref="formAmsSB"
  >
    <div v-show="showConfigForm">
      <Divider orientation="left">Accounts and Assets</Divider>

      <Form-Item label="Type">
        <Row>
          <Row-Col span="18">
            Sponsored Brands
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Amazon Account" prop="bpseId">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.bpseId"
              placeholder="Select an account"
              :transfer="true"
              filterable
            >
              <Option
                v-for="(item, index) in amsAccounts"
                :key="index"
                :value="item.id"
                :disabled="item.IsOauthExpired"
                >{{ item.customerId + " - " + item.accountNickName }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

        <Form-Item label="Brand" prop="brand" v-if="isSellerAccount">
        <Row>
          <Row-Col span="18">
            <Select
              ref="filter"
              v-model="config.brand"
              placeholder="Select a brand"
              filterable
            >
                  <Option
                    v-for="(item, index) in brands"
                    :key="index"
                    :value="item.brandEntityId"
                    >{{ item.brandEntityId + " - " + item.brandRegistryName }}
                  </Option>
            </Select>
          </Row-Col>
          <Row-Col span="1" v-if="isFetchingBrandsData">
            <span style="display: inline-block;float: right"><i class="fa fa-lg fa-spinner fa-spin"/></span>
          </Row-Col>
        </Row>
      </Form-Item>

      <Divider orientation="left">Campaign Settings</Divider>

      <Form-Item label="Campaign Mode" prop="campaign.status">
        <Row>
          <Row-Col>
            <RadioGroup v-model="config.campaign.status">
              <Radio :label="name" v-for="(name, id) in amsEnums.status" :key="id">
                <span> {{ id }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Campaign Name" prop="campaign.name">
        <Row>
          <Row-Col span="18">
            <i-input :disabled="isNomenclatureEnabled" v-model="config.campaign.name" clearable/>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Scheduling" prop="campaign.scheduling">
        <RadioGroup v-model="config.campaign.scheduling">
          <Radio :label="name" v-for="(name, id) in amsEnums.scheduling" :key="id">
                <span> {{ id }} </span>
          </Radio>
        </RadioGroup>
      </Form-Item>
      <Divider orientation="left">Campaign & Adgroup</Divider>
      <Form-Item label="Targeting Type">
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.campaign.targetingType" type="button">
              <Radio :label="name" v-for="(name, id) in amsEnums.targetingTypeForBrands" :key="id">
                <span> {{ id }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Bid Limit" prop="campaign.bidLimit">
        <Row>
          <Row-Col span="4">
            <InputNumber v-model="config.campaign.bidLimit" clearable>
              <span slot="append">%</span>
            </InputNumber>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Default Bid" prop="campaign.defaultBid">
        <Row>
          <Row-Col span="4">
            <InputNumber v-model="config.campaign.defaultBid" placeholder="Bid" clearable/>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Config Name" prop="name">
        <Row>
          <Row-Col span="18">
            <i-input v-model="config.name" clearable/>
          </Row-Col>
        </Row>
      </Form-Item>
    </div>
  </Form>
</template>
<script>
import {
  Divider,
  Row,
  Col,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  InputNumber
} from "iview";
import { mapState, mapMutations } from "vuex";
import { APIService } from "./../../ApiService.js";
import CLHelperMethodsMixin from "../../Mixins/CLHelperMethods.js";

export default {
  props: {
    loadExistingConfigId: { default: "" },
    showConfigForm: { default: false },
    openConfigurationModal: { default: false },
    isExistingConfig: { default: false },
    nomenclatureMacro: { default: null }
  },
  components: {
    Divider,
    Row,
    "Row-Col": Col,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Select,
    Option,
    "i-input": Input,
    InputNumber
  },
  data () {
    return {
      config: {
        searchEngineTypeId: 12,
        name: "",
        bpseId: 0,
        brand: "",
        campaign: {
          name: "",
          scheduling: 1,
          targetingType: 1,
          defaultBid: null,
          bidLimit: null,
          status: 1
        }
      },
      validationRules: {
        bpseId: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.checkIfValid && (isNaN(value) || value <= 0)) {
                callback(new Error("Please select an account"));
              }
              callback();
            }
          }
        ],
        brand: [
          { required: this.isSellerAccount, message: 'Please select an brand' }
        ],
        "campaign.status": [
          { required: true, message: 'Please select a campaign status' }
        ],
        "campaign.name": [
          { required: true, message: 'Please enter a campaign name' }
        ],
        "campaign.defaultBid": [
          {
            type: "number",
            min: 0.1,
            required: true,
            message: "Please enter a valid bid",
            trigger: "change"
          }
        ],
        "campaign.scheduling": [
          { required: true, message: 'Please select a campaign schedule type' }
        ],
        "name": [
          { required: true, message: 'Please enter a configuration name' }
        ]
      },
      isNomenclatureEnabled: false,
      brands: [],
      isFetchingBrandsData: false,
      isSellerAccount: false
    };
  },
  mixins: [CLHelperMethodsMixin],
  mounted () {
    if (this.selectedMediaPlan) {
      if (this.nomenclatureMacro && this.nomenclatureMacro.campaign && this.nomenclatureMacro.campaign != '') {
        this.isNomenclatureEnabled = true;
        this.config.campaign.name = this.nomenclatureMacro.campaign;
      } else {
        this.isNomenclatureEnabled = false;
        this.config.campaign.name = this.getCampaignName();
      }
    }
  },
  watch: {
    loadExistingConfigId (id) {
      if (id) {
        this.config = JSON.parse(this.campaignLauncherConfigs[id].config);
        this.$nextTick(() => {
          this.$refs["formAmsSB"].validateField('campaign.defaultBid');
        });
        if (!this.isExistingConfig) {
          this.config.name = this.appendCopyToConfigName(this.config.name);
        }
      }
    },
    "config.bpseId": function (newValue, oldValue) {
      if (oldValue != newValue && newValue != undefined && newValue != 0) {
        var account = this.amsAccounts.filter(acc => acc.id == newValue);
        this.isSellerAccount = false;
        if (account[0].amsAccountType == 1) {
          this.$refs["formAmsSB"].rules.brand[0].required = true;
          this.isSellerAccount = true;
          this.getAmsBrands();
        } else {
          this.$refs["formAmsSB"].rules.brand[0].required = false;
          this.config.brand = "";
        }
      }
    },
    "openConfigurationModal": function () {
      if (this.openConfigurationModal) {
        this.config.campaign.name = this.getCampaignName()
      }
    }
  },
  computed: {
    ...mapState([
      "amsEnums",
      "amsAccounts",
      "businessProfileId",
      "campaignLauncherConfigs",
      "publisher",
      "deltax",
      "mediaPlanId",
      "selectedMediaPlan"
    ])
  },
  created () {
    this.getAmsBrands();
  },
  methods: {
    ...mapMutations([]),
    getAmsBrands () {
      this.isFetchingBrandsData = true;
      APIService.GetAmsBrands(this.config.bpseId)
        .then(response => {
          this.brands = response.data.data
          this.isFetchingBrandsData = false;
        })
    },
    getConfig () {
      return this.config;
    },
    resetFields () {
      this.$refs["formAmsSB"].resetFields();
    },
    isAMSFormValid (saveCallback) {
      this.checkIfValid = true;
      this.$refs["formAmsSB"].validate((valid) => {
        if (valid) {
          saveCallback();
        }
        this.checkIfValid = false;
      })
    },
    getCampaignName () {
      var suggestedSubmissionName = this.selectedMediaPlan.name + " - " + new Date().toLocaleString() + " - AMS Sponsored Brands"
      return suggestedSubmissionName
    }
  }
};
</script>
<style scoped>
::v-deep .ivu-form-item-error .ivu-select-selection {
    border: 1px solid #b94a48 !important;
}
</style>
