  <template>
  <i-modal
    v-model="alwaysTrue"
    width="50%"
    :footer-hide="true"
    :styles="{top: '100px'}"
    :closable="false"
    :mask-closable="false"
    ref="selectColumns"
  >
    <h4 class="title" slot="header" >
            Select Columns
    </h4>
    <div class="modal-body overflow-hidden select-columns-popup-modal">
      <div class="col-md-12 no-padding vue-multiselect-tree container" style="height: 350px">
        <div class="col-md-6 no-padding vue-multiselect-tree container">
          <div class="panel panel-default">
            <div class="panel-heading vue-multiselect-tree searchbar">
              <div class="input-group input-group-sm">
                <input
                  class="form-control"
                  placeholder="Benchmark Name"
                  v-model="searchedSelectColumn"
                  type="text"
                />
                <span class="input-group-btn">
                  <button class="btn btn-white" style="margin-top:0px">
                    <i class="fa fa-search"></i>&nbsp;
                  </button>
                </span>
              </div>
              <div></div>
              <div></div>
            </div>
            <div
             class="panel-body ko-multiselect-tree body perfect-scroll-bar overflow-hidden">
             <div id="all-lineitem-wrapper" class="all-lineitem-wrapper">
              <div class="checkbox-div-style" v-if="filteredAttributeList.length>0"><b>Attributes</b></div>
              <div
                v-for="filteredAttributeCheckbox in filteredAttributeList"
                :key="filteredAttributeCheckbox.id"
                :value="filteredAttributeCheckbox.name"
                class="checkbox"
              >
              <label class="label-style">
                <input
                  type="checkbox"
                  class="checkbox-style"
                  :value="filteredAttributeCheckbox.name"
                  v-model="checkedAttributes"
                  @change="checkIfAttributeCheckboxChecked($event.currentTarget.checked,filteredAttributeCheckbox.name)"
                />
                <span
                  :for="filteredAttributeCheckbox.name"
                  class="vertical-align padding-half-left"
                >{{filteredAttributeCheckbox.name}}</span>
              </label>
              </div>
              <div class="checkbox-div-style" v-if="filteredBenchMarkList.length>0"><b>Benchmarks</b></div>
              <div
                v-for="filteredBenchMarkCheckbox in filteredBenchMarkList"
                :key="filteredBenchMarkCheckbox.id"
                :value="filteredBenchMarkCheckbox.name"
                class="checkbox"
              >
              <label class="label-style">
                <input
                  type="checkbox"
                  class="checkbox-style"
                  :value="filteredBenchMarkCheckbox.name"
                  v-model="checkedBenchmarks"
                  @change="checkIfBenchMarkCheckboxChecked($event.currentTarget.checked,filteredBenchMarkCheckbox.name)"
                  :disabled="disableBenchmark(filteredBenchMarkCheckbox.name)"
                />
                <span
                  :for="filteredBenchMarkList.name"
                  class="vertical-align padding-half-left"
                >{{filteredBenchMarkCheckbox.name}}</span>
              </label>
              </div>

            </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 no-padding vue-multiselect-tree container">
          <div class="panel panel-default" style="border-left-style: none !important;">
            <div class="panel-body vue-multiselect-tree body perfect-scroll-bar overflow-hidden">
              <div class="checkbox-div-style" v-if="checkedAttributes.length>0"><b>Attributes</b></div>
              <ul class="list-style column-reorder-modal-body">
                <li
                  v-for="checkedAttribute in checkedAttributes"
                  :value="checkedAttribute"
                  :key="checkedAttribute"
                  class="row attribute-column-reorder-element"
                >
                  {{checkedAttribute}}
                  <span
                    class="close"
                    @click="uncheckAttribute(checkedAttribute)"
                  >&times;</span>
                </li>
                <div class="checkbox-div-style" v-if="checkedBenchmarks.length>0" style="margin-left: 2px; margin-top: 12px; margin-bottom: 12px;"><b>Benchmarks</b></div>
                <draggable
                  v-model="checkedBenchmarks"
                  @start="drag=true"
                  @end="drag=false"
                >
                    <li
                      v-for="checkedValue in checkedBenchmarks"
                      :key="checkedValue"
                      class="row benchmark-column-reorder-element"
                    >
                      {{checkedValue}}
                      <button
                        :disabled="disableBenchmark(checkedValue)"
                        class="close"
                        @click="uncheckBenchmark(checkedValue)"
                      >&times;</button>
                    </li>
                </draggable>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 no-padding button-class button-padding">
        <button
          type="button"
          class="btn create-btn btn-white"
          @click.stop="cancelActionForSelectColumns"
        >Cancel</button>
        <button
          type="button"
          class="btn create-btn btn-success"
          @click.stop="addColumnIfCheckboxChecked"
        >Update</button>
      </div>
    </div>
  </i-modal>
</template>
<script>
import draggable from "vuedraggable";
import { Modal } from "iview";

export default {
  components: {
    "i-modal": Modal,
    draggable: draggable
  },
  data: function () {
    return {
      alwaysTrue: true,
      attributeToRemove: [],
      benchmarkToRemove: [],
      searchedSelectColumn: "",
      allAttributes: [],
      allBenchmarks: [],
      checkedAttributes: [],
      checkedBenchmarks: [],
      disableSelectedBenchmarks: []
    }
  },
  props: [
    "checkboxAttributeList",
    "checkboxBenchMarkList",
    "selectedAttributes",
    "selectedBenchmarks",
    "benchmarksToBeDisabled"
  ],
  computed: {
    filteredAttributeList: function () {
      return this.allAttributes.filter(filteredAttributeCheckbox => {
        return filteredAttributeCheckbox.name
          .toLowerCase()
          .includes(this.searchedSelectColumn.toLowerCase());
      });
    },
    filteredBenchMarkList: function () {
      return this.allBenchmarks.filter(filteredBenchMarkCheckbox => {
        return filteredBenchMarkCheckbox.name
          .toLowerCase()
          .includes(this.searchedSelectColumn.toLowerCase());
      });
    }
  },
  methods: {
    checkIfAttributeCheckboxChecked: function (checked, checkedValue) {
      if (!checked) {
        this.uncheckAttribute(checkedValue);
      }
    },
    checkIfBenchMarkCheckboxChecked: function (checked, checkedValue) {
      if (!checked) {
        this.uncheckBenchmark(checkedValue);
      }
    },
    uncheckAttribute: function (checkedValue) {
      this.checkedAttributes = this.checkedAttributes.filter(
        name => name !== checkedValue
      );
      this.attributeToRemove.push(checkedValue);
    },
    uncheckBenchmark: function (checkedValue) {
      this.checkedBenchmarks = this.checkedBenchmarks.filter(
        name => name !== checkedValue
      );
      this.benchmarkToRemove.push(checkedValue);
    },
    addColumnIfCheckboxChecked: function () {
      var selectColumnsObject = {
        benchmarkToRemove: this.benchmarkToRemove,
        attributeToRemove: this.attributeToRemove,
        selectedBenchmarks: this.checkedBenchmarks,
        selectedAttributes: this.checkedAttributes
      }
      this.$emit("addColumnIfCheckboxChecked", selectColumnsObject);
      this.benchmarkToRemove = [];
      this.attributeToRemove = [];
      this.searchedSelectColumn = "";
    },
    cancelActionForSelectColumns: function () {
      this.$emit("cancelActionForSelectColumns");
      this.searchedSelectColumn = "";
    },
    disableBenchmark: function (benchmark) {
      if (this.disableSelectedBenchmarks.includes(benchmark)) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted () {
    $(".perfect-scroll-bar").perfectScrollbar({ suppressScrollX: true });
    this.allAttributes = this.checkboxAttributeList;
    this.allBenchmarks = this.checkboxBenchMarkList;
    this.checkedAttributes = this.selectedAttributes;
    this.checkedBenchmarks = this.selectedBenchmarks;
    this.disableSelectedBenchmarks = this.benchmarksToBeDisabled;
  }
}
</script>
<style scoped>
.select-columns-popup-modal {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
}
.vue-multiselect-tree.container,
.vue-multiselect-tree.container > .panel {
  height: 100%;
  overflow: hidden;
}
.vue-multiselect-tree.searchbar {
  padding: 10px !important;
  background-color: white !important;
  border-bottom: 2px solid #f5f5f5 !important;
}
.checkbox-div-style {
  margin-left: 12px;
  margin-top: 3px;
  font-size: 13px;
  margin-bottom: -6px;
  color: #2c2e2f;
}
.checkbox-style {
  margin-left: -12px !important;
  margin-top: 2px !important;
}
.checkbox-label {
  margin-left: 6px;
}
.benchmark-column-reorder-element {
  border-bottom: 1px solid #e5e5e5;
  /* padding: 10px; */
  margin: 0px;
  cursor: move;
  font-size: 12px;
}
.attribute-column-reorder-element {
  border-bottom: 1px solid #e5e5e5;
  /* padding: 10px; */
  margin: 0px;
  font-size: 12px;
}
.button-class {
  padding-left: 10px !important;
  margin-top: 10px;
  float: right;
}
.vue-multiselect-tree.body {
  height: 100%;
}
.list-style {
  /* margin-left: 20px;
  margin-top: 20px;
  font-size: 13px; */
}
ul {
  list-style-type: none;
  margin: 10px;
}

ul li {
  margin-top: -1px; /* Prevent double borders */
  padding: 7px;
  text-decoration: none;
  font-size: 13px;
  color: black;
  display: block;
  position: relative;
}
.close {
  cursor: pointer;
  position: absolute;
  left: 275px !important;
  transform: translate(0%, -50%);
  top: 20px;
  font-size: 18px;
}
.vertical-align {
  vertical-align: text-bottom;
  color: #2c2e2f;
}
.padding-half-left {
  padding-left: 7px;
}
.overflow-hidden{
  overflow-y: hidden !important;
  overflow-x: visible !important;
}
.all-lineitem-wrapper {
  height: 300px;
  max-height: 300px;
}
.button-padding{
   margin-right: -12px;
   margin-bottom: -16px;
   margin-top: 16px;
}
.label-style{
  margin-left: 12px !important;
}
::v-deep .ivu-modal-header{
  padding: 7px 16px !important;
  border-bottom: 2px solid #ccc !important;
}
::v-deep .ko-multiselect-tree.body {
    height: 85% !important;
}
</style>
