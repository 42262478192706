import { Objective } from "./Objective.js"
import { platforms, positions } from "../../Constants/FacebookEnums.js"

let ColumnConfig = function () {
  this.single = new function () {
    this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Link", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_Call To Action", "HOT_Ad Title", "HOT_Display Link", "HOT_Text", "HOT_Description", "HOT_Url Tags", "HOT_ViewTags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Image/Video"]
    this.card = []
    this.requiredCard = []
    this.callToAction = ["No Button", "Apply Now", "Download", "Learn More", "Shop Now", "Sign Up", "Watch More", "Book Travel", "Subscribe"]
    this.placementCustomizationHeaders = ["HOT_Placements", "HOT_Image/Video", "HOT_Text", "HOT_Ad Title", "HOT_Link"]
    this.DynamicCreativeHeaders = ["HOT_Placements", "HOT_Image/Video", "HOT_Primary Text", "HOT_Description", "HOT_Headline"]
  }();
  this.carousel = new function () {
    this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_See More Url", "HOT_Call To Action", "HOT_Optimize Card Order", "HOT_Ad Title", "HOT_Text", "HOT_Description", "HOT_See More Display Url", "HOT_Url Tags", "HOT_ViewTags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions"]
    this.requiredBase = ["HOT_Ad Name", "HOT_See More Url"]
    this.card = ["HOT_Link", "HOT_Headline", "HOT_Image/Video", "HOT_Description"]
    this.requiredCard = ["HOT_Link", "HOT_Image/Video"]
    this.callToAction = ["No Button", "Apply Now", "Download", "Learn More", "Shop Now", "Sign Up", "Watch More", "Book Travel", "Subscribe"]
  }();
}

let CreativeSpec = function () {
  this.single = new function () {
    this.image = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "link_data": {
            "picture": "HOT_Image/Video",
            "link": "HOT_Link",
            "name": "HOT_Ad Title",
            "description": "HOT_Description",
            "message": "HOT_Text",
            "caption": "HOT_Display Link",
            "call_to_action": {
              "type": "HOT_Call To Action",
              "value": {
                "link": "HOT_Link"
              }
            }
          }
        },
        "url_tags": "HOT_Url Tags"
      }
    }
    this.dynamicCreative = {
      metadata: new function () {
        this.colToAssetSpecMap = {
          "HOT_Image/Video": "images",
          "HOT_Primary Text": "bodies",
          "HOT_Call To Action": "call_to_action_types",
          "HOT_Description": "descriptions",
          "HOT_Headline": "titles"
        };

        this.headlineRemap = {
          "HOT_Ad Title": "HOT_Headline",
          "HOT_Text": "HOT_Primary Text"
        };
      }(),
      image: {
        creative: {
          object_story_spec: {
            page_id: "HOT_Page"
          },
          asset_feed_spec: {
            images: [ // required. Max: 10
              {
                url: "HOT_Image/Video"
              }
            ],
            bodies: [ // Max: 5
              {
                text: "HOT_Primary Text"
              }
            ],
            call_to_action_types: ["HOT_Call To Action"], // required. Max: 5
            descriptions: [ // Max: 5
              {
                text: "HOT_Description"
              }
            ],
            link_urls: [
              {
                website_url: "HOT_Link", // required
                display_url: "HOT_Display Link"
              }
            ],
            titles: [ // Max: 5
              {
                text: "HOT_Headline"
              }
            ],
            ad_formats: ["SINGLE_IMAGE"] // required
          }
        }
      },
      video: {
        creative: {
          object_story_spec: {
            page_id: "HOT_Page"
          },
          asset_feed_spec: {
            videos: [ // required. Max: 10
              {
                video_id: "HOT_SearchEngineVideoId",
                thumbnail_url: "HOT_Image/Video"
              }
            ],
            bodies: [ // Max: 5
              {
                text: "HOT_Primary Text"
              }
            ],
            call_to_action_types: ["HOT_Call To Action"], // required. Max: 5
            descriptions: [ // Max: 5
              {
                text: "HOT_Description"
              }
            ],
            link_urls: [
              {
                website_url: "HOT_Link", // required
                display_url: "HOT_Display Link"
              }
            ],
            titles: [ // Max: 5
              {
                text: "HOT_Headline"
              }
            ],
            ad_formats: ["SINGLE_VIDEO"] // required
          }
        }
      }
    }
    this.video = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "video_data": {
            "video_id": "HOT_SearchEngineVideoId",
            "image_url": "HOT_Image/Video",
            "title": "HOT_Ad Title",
            "message": "HOT_Text",
            "call_to_action": {
              "type": "HOT_Call To Action",
              "value": {
                "link": "HOT_Link"
              }
            }
          }
        },
        "url_tags": "HOT_Url Tags"
      }
    }
    this.imageWithoutLink = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "photo_data": {
            "url": "HOT_Image/Video",
            "caption": "HOT_Ad Title"
          }
        }
      }
    }
    this.placementCustomization = {}
  }();
  this.carousel = {
    "creative": {
      "object_story_spec": {
        "link_data": {
          "call_to_action": {
            "type": "HOT_Call To Action",
            "value": {
              "link": "HOT_See More Url"
            }
          },
          "description": "HOT_Description",
          "link": "HOT_See More Url",
          "message": "HOT_Text",
          "name": "HOT_Ad Name",
          "caption": "HOT_Display Link",
          "child_attachments": [
            {
              "link": "HOT_Link",
              "picture": "HOT_Image/Video",
              "name": "HOT_Headline",
              "description": "HOT_Description"
            }
          ],
          "multi_share_optimized": "HOT_Optimize Card Order"
        },
        "page_id": "HOT_Page",
        "instagram_actor_id": "HOT_InstagramId"
      },
      "url_tags": "HOT_Url Tags"
    }
  };
}
let AdFormats = {
  "Image or Carousel": {
    "adSheets": ["single"],
    "placements": {
      [platforms.Facebook]: [
        positions.Facebook.Feed,
        positions.Facebook.Story,
        positions.Facebook.InstantArticle,
        positions.Facebook.InstreamVideo,
        positions.Facebook.VideoFeeds,
        positions.Facebook.MarketPlace],
      [platforms.Instagram]: [
        positions.Instagram.Stream,
        positions.Instagram.Explore,
        positions.Instagram.Story
      ],
      [platforms.AudienceNetwork]: [positions.AudienceNetwork.InstreamVideo]
    }
  },
  "Video": {
    "adSheets": ["single"],
    "placements": {
      [platforms.Facebook]: [
        positions.Facebook.Feed,
        positions.Facebook.Story,
        positions.Facebook.InstantArticle,
        positions.Facebook.InstreamVideo,
        positions.Facebook.VideoFeeds],
      [platforms.Instagram]: [
        positions.Instagram.Stream,
        positions.Instagram.Explore,
        positions.Instagram.Story
      ],
      [platforms.AudienceNetwork]: [positions.AudienceNetwork.InstreamVideo]
    }
  }
}
let brandAwareness = new Objective(new ColumnConfig(), new CreativeSpec(), AdFormats)
export { brandAwareness }
