<template>
  <section id="target-name-comp">
    <div id="input-section">
      <div>
        <span class="label-names">Target Name : </span>
        <Input
          v-model="targetName"
          :placeholder="targetName"
          style="width: 400px"
        />
      </div>
      <div id="options">
        <Button
          type="default"
          @click="generateAudiences"
          style="margin-right: 10px;"
          >Generate Audiences</Button
        >
        <Button type="default" @click="clearAll">Clear All</Button>
      </div>
    </div>
    <div id="macros">
      <div class="label-names">
        Available Macros :&nbsp;
      </div>
      <span class="links" @click="updateTargetName(`{Age}`)">
        {Age},
      </span>
      &nbsp;
      <span class="links" @click="updateTargetName(`{AgeMin}`)">
        {AgeMin},
      </span>
      &nbsp;
      <span class="links" @click="updateTargetName(`{AgeMax}`)">
        {AgeMax},
      </span>
      &nbsp;
      <span class="links" @click="updateTargetName(`{Gender}`)">
        {Gender},
      </span>
      &nbsp;
      <span class="links" @click="updateTargetName(`{G}`)">
        {G},
      </span>
      &nbsp;
      <span class="links" @click="updateTargetName(`{Location}`)">
        {Location}
      </span>
    </div>
  </section>
</template>

<script>
import { Input, Button } from "iview";
import { mapState } from "vuex";

export default {
  name: "TargetName",
  props: {
    bpseId: Number,
    required: true,
    targetMode: {
      type: String
    },
    loadData: {
      type: Boolean
    }
  },
  components: {
    Input,
    Button
  },
  computed: {
    ...mapState(["facebookTargetMacroName"])
  },
  mounted () {
    this.$nextTick(() => {
      this.targetName = this.facebookTargetMacroName;
    })
  },
  data: function () {
    return {
      targetName: "Target-{Age}-{Gender}-{Location}"
    };
  },
  methods: {
    updateTargetName (value) {
      this.targetName += " " + value;
      this.$store.dispatch("setFacebookTargetMacroName", this.targetName);
    },
    clearAll () {
      this.targetName = "Target-{Age}-{Gender}-{Location}";
      this.$store.dispatch("reset_facebookTargetCreationData");
      if (this.targetMode === 'New') {
        this.$emit("reset-selected-target");
      }
    },
    generateAudiences () {
      this.$store.dispatch("generate_TargetAudience");
    },
    reset () {
      this.targetName = "Target-{Age}-{Gender}-{Location}";
    }
  },
  watch: {
    targetName (newVal, oldVal) {
      this.$store.dispatch("setFacebookTargetMacroName", newVal);
    },
    loadData: {
      handler () {
        this.targetName = this.facebookTargetMacroName
      },
      deep: true
    }
  }
};
</script>

<style scoped>
::v-deep .ivu-btn > span {
  font-size: 13px;
}
.links:hover{
  cursor: pointer;
  text-decoration: underline;
}
.links{
  cursor: pointer;
}
.label-names {
  color: #878787;
}
.radio-group {
  padding-top: 2px;
}
#macros {
  display: flex;
  margin-top: 10px;
}
#options {
  float: right;
}
#options>.ivu-btn:focus {
  box-shadow: none !important;
}
#target-name-comp {
  font-family: "sans-serif", sans-serif;
  font-size: 13px;
  font-weight: normal;
  color: #000000;
  margin: 30px 30px;
  margin-top: 0px;
  margin-bottom: 15px;
}
#card-button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
#input-section {
  display: flex;
  justify-content: space-between;
  margin-right: 45px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
