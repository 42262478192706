import axiosInstance from '../../../Api'

export class ApiService {
  SetBuinessProfileId (businessProfileId) {
    const promise = new Promise(function (resolve) {
      deltax.defaultPortalId = deltax.portalId;
      deltax.businessProfileId = businessProfileId;
      resolve("success");
    });
    return promise;
  }

  GetClients () {
    const getClientsUrl = `${deltax.apiBaseUrl}portal/${deltax.defaultPortalId}/client?businessProfileId=${deltax.businessProfileId}`;
    const promise = axiosInstance.get(getClientsUrl)
    return promise
  }

  GetAgencyUnits () {
    const getAgencyUnitsUrl = `${deltax.apiBaseUrl}portal/${deltax.defaultPortalId}/agencyUnit?businessProfileId=${deltax.businessProfileId}`;
    const promise = axiosInstance.get(getAgencyUnitsUrl)
    return promise
  }
  GetSuppliers () {
    const getAllSuppliers = deltax.apiBaseUrl
      .concat(`portal/${deltax.defaultPortalId}/Suppliers`)
    const promise = axiosInstance.get(getAllSuppliers)
    return promise
  }
  GetPublishers () {
    const getAllPublishers = deltax.apiBaseUrl
      .concat(`portal/${deltax.defaultPortalId}/Publisher`)
    const promise = axiosInstance.get(getAllPublishers)
    return promise
  }
  GetPurchaseOrders () {
    const getAllPurchaseOrders = deltax.apiBaseUrl
      .concat(`portal/${deltax.defaultPortalId}/purchaseorder?businessProfileIds=${deltax.businessProfileId}&pageNumber=-1`)
    const promise = axiosInstance.get(getAllPurchaseOrders)
    return promise
  }
  GetPurchaseOrdersByApprovedBuyId (approvedBuyId) {
    const getAllPurchaseOrdersByAbId = deltax.apiBaseUrl
      .concat(`portal/${deltax.defaultPortalId}/purchaseorder?approvedBuyIds=${approvedBuyId}&pageNumber=-1`)
    const promise = axiosInstance.get(getAllPurchaseOrdersByAbId)
    return promise
  }
  GetInvoices () {
    const getInvoicesUrl = deltax.apiBaseUrl
      .concat(`portal/${deltax.defaultPortalId}/invoice?businessProfileIds=${deltax.businessProfileId}`)
    const promise = axiosInstance.get(getInvoicesUrl)
    return promise
  }
  GetApprovedBuyById (approvedBuyId) {
    const getApprovedBuyById = deltax.apiBaseUrl
      .concat(`portal/${deltax.defaultPortalId}/approvedBuy/${approvedBuyId}`);
    const promise = axiosInstance.get(getApprovedBuyById)
    return promise
  }
  LinkPurchaseOrdersWithApprovedBuy (purchaseOrderIds, approvedBuyId) {
    var paramsObject = {
      purchaseOrderIds: purchaseOrderIds.join(',')
    };
    const linkPOsWithAb = deltax.apiBaseUrl
      .concat(`portal/${deltax.defaultPortalId}/approvedBuy/${approvedBuyId}/purchaseOrders`)
    const promise = axiosInstance.put(linkPOsWithAb, JSON.stringify(paramsObject));
    return promise;
  }
  GetMediaPlanWithChildEntities (mediaPlanId) {
    const getMediaPlanWithChildEntitiesUrl = `${deltax.apiBaseUrl}businessProfiles/${deltax.businessProfileId}/mediaPlans/mediaPlan?mediaPlanId=${mediaPlanId}`;
    const promise = axiosInstance.get(getMediaPlanWithChildEntitiesUrl);
    return promise;
  }
  createApprovedBuySignedContractTask (Id) {
    var parameter = {
      approvedBuyId: Id
    };
    const createApprovedBuySignedContractTaskUrl = `/App/BudgetCenter/BudgetCenter/CreateApprovedBuySignedContractTask/${deltax.businessProfileId}`
    const promise = axiosInstance({
      method: 'post',
      url: createApprovedBuySignedContractTaskUrl,
      data: JSON.stringify(parameter)
    })
    return promise;
  }
  updateLineItem (lineItemId, startDate, endDate, supplierId, reserveOrderDetails) {
    var paramsObject = "[{'Name':'STARTDATE','Value': '" + startDate + "'},{'Name':'ENDDATE','Value':'" + endDate + "'},{'Name':'SUPPLIERID','Value':" + supplierId + "},{'Name':'RESERVEORDERDETAILS','Value':'" + JSON.stringify(reserveOrderDetails) + "'}]"
    const updateLineItemUrl = deltax.apiBaseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`LineItems/${lineItemId}`)
    const promise = axiosInstance.patch(updateLineItemUrl, JSON.stringify(paramsObject));
    return promise
  }
  updateRemarks (approvedBuyId, remarks) {
    var properties = "[{'Name':'REMARKS','Value': '" + remarks + "'}]"
    const updateRemarksUrl = deltax.apiBaseUrl
      .concat(`portal/${deltax.defaultPortalId}/`)
      .concat(`ApprovedBuy/${approvedBuyId}`)
    const promise = axiosInstance.patch(updateRemarksUrl, JSON.stringify(properties));
    return promise
  }
  updateClientJson (approvedBuyId, properties) {
    const updateClientsJsonUrl = deltax.apiBaseUrl
      .concat(`portal/${deltax.defaultPortalId}/`)
      .concat(`ApprovedBuy/${approvedBuyId}`)
    const promise = axiosInstance({
      method: 'patch',
      url: updateClientsJsonUrl,
      data: properties
    })
    return promise
  }
}
