<template>
  <Form
    label-position="right"
    :label-width="200"
    :model="config"
    :rules="validationRules"
    ref="formAdwords"
  >
    <div v-show="showConfigForm">
      <Divider orientation="left">Accounts and Assets</Divider>

      <Form-Item label="Google Account" prop="bpseId">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.bpseId"
              placeholder="Select an account"
              :transfer="true"
              filterable
            >
              <Option
                v-for="(item, index) in googleAccounts"
                :key="index"
                :value="item.Id"
                :disabled="item.IsOauthExpired"
                >{{ item.NickName }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Divider orientation="left">Campaign Settings</Divider>

      <Form-Item label="Campaign Type" prop="campaign.type">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.type"
              placeholder="Select an campaign type"
              :transfer="true"
              :disabled="isExistingConfig"
              filterable
            >
              <Option
                v-for="(typeId, type) in adwordsEnums.campaignType"
                :key="typeId"
                :value="typeId"
                >{{ type }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Subtype"
        prop="campaign.subtype"
        v-show="config.campaign.type == adwordsEnums.campaignType.App"
      >
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.campaign.subtype">
              <Radio
                :label="id"
                v-for="(id, subtype) in adwordsEnums.campaignSubType"
                :key="id"
                :disabled="isExistingConfig"
              >
                <span> {{ subtype }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="App Store"
        prop="campaign.appStore"
        v-show="config.campaign.type == adwordsEnums.campaignType.App"
      >
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.campaign.appStore">
              <Radio
                :label="id"
                v-for="(id, store) in adwordsEnums.appStore"
                :key="id"
                :disabled="isExistingConfig"
              >
                <span> {{ store }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="App"
        prop="campaign.appId"
        v-show="config.campaign.type == adwordsEnums.campaignType.App"
        key="appId"
      >
        <Row>
          <Row-Col span="18">
            <i-input v-model="config.campaign.appId" />
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Goal"
        prop="campaign.goals"
        v-show="config.campaign.type == adwordsEnums.campaignType.Search"
      >
        <CheckboxGroup v-model="config.campaign.goals">
          <Checkbox
            v-for="(name, id) in adwordsEnums.campaignGoals"
            :label="id"
            :key="id"
            :disabled="id != 1"
            :value="id"
          >
            <span>{{ name }}</span>
          </Checkbox>
        </CheckboxGroup>
      </Form-Item>

      <Form-Item
        label="Networks"
        prop="campaign.networks"
        v-show="config.campaign.type == adwordsEnums.campaignType.Search"
      >
        <CheckboxGroup v-model="config.campaign.networks">
          <Checkbox
            v-for="(name, id) in adwordsEnums.campaignNetworks"
            :key="id"
            :label="id"
            :value="id"
          >
            <span> {{ name }} </span>
          </Checkbox>
        </CheckboxGroup>
      </Form-Item>

      <Form-Item label="Location" prop="campaign.locationIds">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.locationIds"
              placeholder="Add location"
              filterable
              multiple
              remote
              :remote-method="fetchLocations"
              :loading="loadingLocations"
            >
              <Option
                v-for="(location, index) in matchedLocations"
                :value="location.Id"
                :key="index"
                >{{ location.CompleteName }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Language" prop="campaign.languageIds">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.languageIds"
              placeholder="Add language"
              :transfer="true"
              filterable
              multiple
            >
              <Option
                v-for="(language, index) in googleLanguages"
                :key="index"
                :value="language.Id"
                >{{ language.DisplayName }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Bidding"
        prop="campaign.bidding"
        v-show="config.campaign.type == adwordsEnums.campaignType.Search"
      >
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.bidding"
              :transfer="true"
              filterable
            >
              <Option
                v-for="(name, enumId) in adwordsEnums.biddingStrategyTypes"
                :key="enumId"
                :value="Number(enumId)"
                >{{ name }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Bidding"
        prop="campaign.appCampaignBiddingFocus"
        v-show="showAppCampaignBiddingFocus"
      >
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.appCampaignBiddingFocus"
              :transfer="true"
              filterable
            >
              <Option
                v-for="(enumId, name) in adwordsEnums.appCampaignBiddingFocus"
                :key="enumId"
                :value="Number(enumId)"
                >{{ name }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Target"
        prop="campaign.target"
        v-show="showTargetUsers"
      >
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.target"
              :transfer="true"
              filterable
            >
              <Option
                v-for="(enumId, name) in adwordsEnums.targetUsers"
                :key="enumId"
                :value="Number(enumId)"
                >{{ name }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="Target Cost"
        prop="campaign.targetCost"
        v-show="config.campaign.type == adwordsEnums.campaignType.App"
      >
        <Row>
          <Row-Col span="5">
            <InputNumber
              :formatter="value => `${bpCurrencySymbol} ${value}`"
              :parser="value => value.replace(bpCurrencySymbol, '').trim()"
              :min="0"
              v-model="config.campaign.targetCost"
            >
            </InputNumber>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item
        label="In-App Actions"
        prop="campaign.conversionActionIds"
        v-show="showConversionActions"
      >
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.conversionActionIds"
              :disabled="Object.keys(conversionMetrics).length == 0"
              :placeholder="conversionMetricText"
              :transfer="true"
              filterable
              :multiple="allowMultipleConversionActions"
              :key="rerenderConversionSelect"
            >
              <template v-for="(conversionsList, header) in conversionMetrics">
                <Option disabled :key="header" :value="header">
                  <span class="ac-conv-header"> {{ header }} </span>
                </Option>
                <Option
                  v-for="conversion in conversionsList"
                  :key="conversion.id"
                  :value="Number(conversion.id)"
                  >{{ conversion.text }}</Option
                >
              </template>
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Campaign Status" prop="campaign.status">
        <Row>
          <Row-Col>
            <RadioGroup v-model="config.campaign.status">
              <Radio :label="id" v-for="(name, id) in adwordsEnums.status" :key="id">
                <span> {{ name }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Configuration Name" prop="name">
        <Row>
          <Row-Col span="18">
            <i-input v-model="config.name" :disabled="isExistingConfig" />
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Campaign Name" prop="campaign.name.userInput">
        <Row>
          <Row-Col span="18">
            <i-input :disabled="isNomenclatureEnabled" v-model="config.campaign.name.userInput" />
          </Row-Col>
        </Row>
      </Form-Item>
    </div>
  </Form>
</template>
<script>
import {
  Divider,
  Row,
  Col,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  CheckboxGroup,
  Checkbox,
  InputNumber
} from "iview";
import { mapState, mapMutations } from "vuex";
import { APIService } from "./../ApiService.js";
import CLHelperMethodsMixin from "../Mixins/CLHelperMethods.js";

export default {
  props: {
    loadExistingConfigId: { default: "" },
    showConfigForm: { default: false },
    isExistingConfig: { default: false },
    nomenclatureMacro: { default: null }
  },
  components: {
    Divider,
    Row,
    "Row-Col": Col,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Select,
    Option,
    "i-input": Input,
    CheckboxGroup,
    Checkbox,
    InputNumber
  },
  data () {
    return {
      config: {
        searchEngineTypeId: 1,
        name: "",
        bpseId: 0,
        googleAccountId: 0,
        campaign: {
          name: {
            userInput: "",
            macros: ["LI_Id"]
          },
          type: 1,
          subtype: 8,
          goals: ["1"],
          networks: ["2"],
          locations: {},
          locationIds: [],
          languageIds: [],
          bidding: 0,
          appCampaignBiddingFocus: 1,
          target: 1,
          targetCost: null,
          appId: "",
          appStore: 3,
          status: "1",
          budgetPeriod: 1,
          budgetDeliveryMethod: 1,
          conversionActionIds: null,
          conversionActionIdsList: []
        }
      },
      conversionMetrics: {},
      conversionMetricText: "Select In-App Actions",
      checkIfValid: false,
      validationRules: {
        bpseId: [
          {
            validator: (rule, value, callback) => {
              if (this.checkIfValid && (isNaN(value) || value <= 0)) {
                callback(new Error("Please select an account"));
              }
              callback();
            }
          }
        ],
        "campaign.type": [
          { required: true, message: 'Please select a campaign type' }
        ],
        "campaign.goals": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.checkIfValid && this.config.campaign.type == this.adwordsEnums.campaignType.Search && (!Array.isArray(value) || value.length == 0)) {
                callback(new Error("Please select a campaign goal"));
              }
              callback();
            }
          }
        ],
        "campaign.networks": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.checkIfValid && this.config.campaign.type == this.adwordsEnums.campaignType.Search && (!Array.isArray(value) || value.length == 0)) {
                callback(new Error("Please select networks for your campaign"));
              }
              callback();
            }
          }
        ],
        "campaign.appId": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value && this.checkIfValid && this.config.campaign.type == this.adwordsEnums.campaignType.App) {
                callback(new Error("Please enter the app store URL/Id"));
              }
              callback();
            }
          }
        ],
        "campaign.locationIds": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.checkIfValid && (!Array.isArray(value) || value.length == 0)) {
                callback(new Error("Please select a location"));
              }
              callback();
            }
          }
        ],
        "campaign.languageIds": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.checkIfValid && (!Array.isArray(value) || value.length == 0)) {
                callback(new Error("Please select a language"));
              }
              callback();
            }
          }
        ],
        "campaign.bidding": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.checkIfValid && this.config.campaign.type == this.adwordsEnums.campaignType.Search && !value) {
                callback(new Error("Please select a bidding type"));
              }
              callback();
            }
          }
        ],
        "campaign.target": [
          {
            required: true,
            type: "number",
            message: "Please select the kind of users to target"
          }
        ],
        "campaign.appCampaignBiddingFocus": [
          { required: true, message: 'Please select a Bidding type' }
        ],
        "campaign.targetCost": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value <= 0 && this.checkIfValid && this.config.campaign.type == this.adwordsEnums.campaignType.App) {
                callback(new Error("Please select a Target cost per install"));
              }
              callback();
            }
          }
        ],
        "campaign.conversionActionIds": [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (
                this.checkIfValid &&
                this.config.campaign.type == this.adwordsEnums.campaignType.App &&
                this.showConversionActions && (!Array.isArray(value) || value.length == 0)
              ) {
                if (this.allowMultipleConversionActions) {
                  if (!Array.isArray(value) || value.length == 0) {
                    callback(new Error("Please select a conversion"));
                  }
                } else if (!value) {
                  callback(new Error("Please select a conversion"));
                }
              }
              callback();
            }
          }
        ],
        name: [
          { required: true, message: "Please enter a configuration name" }
        ],
        "campaign.name.userInput": [
          { required: true, message: "Please enter a campaign name" }
        ]
      },
      loadingLocations: false,
      matchedLocations: [],
      campaignNameMacros: [
        "MP_name",
        "LI_name",
        "Campaign_type",
        "Goal",
        "Location",
        "LI_Id"
      ],
      campaignNamePreview: "",
      rerenderConversionSelect: 1,
      isNomenclatureEnabled: false
    };
  },
  mixins: [CLHelperMethodsMixin],
  updated () {
    this.loadNomenclatureMacros();
  },
  mounted () {
    this.loadNomenclatureMacros();
  },
  watch: {
    "config.bpseId": function (bpseId) {
      if (bpseId > 0) {
        this.config.googleAccountId = this.googleAccounts.find(
          item => item.Id == this.config.bpseId
        ).CustomerId;

        this.getConversionMetrics(bpseId);
      }
    },
    "config.campaign.subtype" (subtype) {
      if (subtype == this.adwordsEnums.campaignSubType["App Engagement"]) {
        this.config.campaign.appCampaignBiddingFocus =
          this.adwordsEnums.appCampaignBiddingFocus["In-app actions"];
      }
    },
    "config.campaign.locationIds": {
      deep: true,
      handler: function () {
        if (
          this.config.campaign.locationIds.length >
          Object.keys(this.config.campaign.locations).length
        ) {
          this.config.campaign.locationIds.forEach(id => {
            if (!(id in this.config.campaign.locations)) {
              let locationObj = this.matchedLocations.find(loc => loc.Id == id)
              this.config.campaign.locations[Number(id)] = locationObj.CompleteName
            }
          });
        } else {
          let selectedLocationIds = new Set(this.config.campaign.locationIds)
          for (let id in this.config.campaign.locations) {
            if (!selectedLocationIds.has(id)) {
              delete this.config.campaign.locations[id];
            }
          }
        }
      }
    },
    "config.campaign.appCampaignBiddingFocus" (bidFocus) {
      if (bidFocus == this.adwordsEnums.appCampaignBiddingFocus["In-app actions"]) {
        this.config.campaign.target =
          this.adwordsEnums.targetUsers["Users likely to perform an in-App action"];
      }
    },
    "config.campaign.conversionActionIds" (ids) {
      this.config.campaign.conversionActionIdsList =
        (!Array.isArray(ids) ? [ids] : ids).filter(Boolean);
    },
    campaignNameAndMacros () {
      let nameArr = [...this.config.campaign.name.macros.map(x => "{" + x + "}")];
      if (this.config.campaign.name.userInput != "") {
        nameArr.unshift(this.config.campaign.name.userInput);
      }
      this.campaignNamePreview = nameArr.join('-');
    },
    loadExistingConfigId (id) {
      if (id) {
        this.config = JSON.parse(this.campaignLauncherConfigs[id].config);
        this.matchedLocations = []
        for (let locationId in this.config.campaign.locations) {
          this.matchedLocations.push({
            Id: Number(locationId),
            CompleteName: this.config.campaign.locations[locationId]
          })
        }

        if (!this.isExistingConfig) {
          this.config.name = this.appendCopyToConfigName(this.config.name)
        }
      }
    }
  },
  computed: {
    ...mapState([
      "adwordsEnums",
      "googleAccounts",
      "googleLanguages",
      "bpCurrencySymbol",
      "businessProfileId",
      "campaignLauncherConfigs"
    ]),
    showAppCampaignBiddingFocus () {
      return this.config.campaign.type == this.adwordsEnums.campaignType.App &&
        this.config.campaign.subtype == this.adwordsEnums.campaignSubType["App Installs"]
    },
    showTargetUsers () {
      return (
        this.config.campaign.type == this.adwordsEnums.campaignType.App &&
        this.config.campaign.appCampaignBiddingFocus ==
          this.adwordsEnums.appCampaignBiddingFocus["Install Volume"]
      );
    },
    showConversionActions () {
      return (
        this.config.campaign.type == this.adwordsEnums.campaignType.App &&
        this.config.campaign.target != this.adwordsEnums.targetUsers["All Users"]
      );
    },
    allowMultipleConversionActions () {
      return this.config.campaign.subtype == this.adwordsEnums.campaignSubType["App Engagement"]
    },
    campaignNameAndMacros () {
      return [...this.config.campaign.name.macros, this.config.campaign.name.userInput].join();
    }
  },
  methods: {
    ...mapMutations([]),
    getConversionMetrics (bpseId) {
      this.conversionMetrics = {}
      this.conversionMetricText = "Fetching In-App Actions"
      APIService.getAdwordsConversionMetrics(bpseId).then(response => {
        response.data.conversionMetrics.forEach(metrics => {
          this.$set(this.conversionMetrics, metrics.text, metrics.children)
        })
        this.conversionMetricText = "Select In-App Actions";
        this.rerenderConversionSelect ^= 1;
      }).catch(() => {
        this.conversionMetricText = "Failed to fetch In-App Actions"
      })
    },
    fetchLocations (pattern) {
      if (pattern.length > 1) {
        this.loadingLocations = true;
        fetch(
          "/App/CampaignLauncher/GetLocations/" +
            this.businessProfileId +
            `?pattern=${pattern}`
        ).then(async response => {
          response = await response.json()
          this.matchedLocations = JSON.parse(response.foundLocations);
          this.loadingLocations = false;
        }).catch(() => {
          this.loadingLocations = false;
        });
      }
    },
    toggleMacro (macroName) {
      this.campaignNameMacros[macroName] = !this.campaignNameMacros[macroName];
    },
    getConfig () {
      if (!this.config.campaign.networks.find(x => x == 1)) {
        this.config.campaign.networks.push("1");
      }
      return this.config;
    },
    resetFields () {
      this.$refs["formAdwords"].resetFields();
    },
    isAdwordsFormValid (saveCallback) {
      this.checkIfValid = true;
      this.$refs["formAdwords"].validate((valid) => {
        if (valid) {
          saveCallback();
        }
        this.checkIfValid = false;
      })
    },
    loadNomenclatureMacros () {
      if (this.nomenclatureMacro && this.nomenclatureMacro.campaign && this.nomenclatureMacro.campaign != '') {
        this.isNomenclatureEnabled = true;
        this.config.campaign.name.userInput = this.nomenclatureMacro.campaign;
      }
    }
  }
};
</script>

<style src="../../../Styles/dx-iview.css"></style>

<style>
.ivu-form-item-error .ivu-input-number {
  border-color: #b94a48 !important;
}
</style>

<style scoped>
.macro {
  margin-right: 10px;
  cursor: poiner;
}
.ac-conv-header {
  color: #ccc;
  cursor: default !important;
}
.ac-divider {
  height: 1px !important;
  margin: 9px 0 !important;
  overflow: hidden !important;
  background-color: #e5e5e5 !important;
}
</style>
