const options = {
  channels: [],
  collections: [], // TARGETING_TYPE_APP_CATEGORY
  deviceModels: [],
  inventorySource: [
    { id: 1, name: "YouTube" },
    { id: 2, name: "Video Partners" }
  ],
  days: [
    { id: 0, name: "Monday" },
    { id: 1, name: "Tuesday" },
    { id: 2, name: "Wednesday" },
    { id: 3, name: "Thursday" },
    { id: 4, name: "Friday" },
    { id: 5, name: "Saturday" },
    { id: 6, name: "Sunday" }
  ],
  ytDevices: [
    { id: 1, name: "Android", versions: ["0.5", "1.0", "1.1", "1.5", "1.6", "2.0", "2.1", "2.2", "2.3", "3.0", "3.1", "3.2", "4.0", "4.1", "4.2", "4.3", "4.4", "5.0", "5.1", "6.0", "7.0", "7.1", "8.0", "8.1", "9.0", "9.1", "10.0", "11.0"] },
    { id: 2, name: "BlackBerry", versions: ["6.0", "7.0"] },
    { id: 3, name: "iOS", versions: ["2.0", "2.1", "2.2", "3.0", "3.1", "3.2", "4.0", "4.1", "4.2", "4.3", "5.0", "5.1", "6.0", "6.1", "7.0", "7.1", "8.0", "8.1", "8.2", "8.3", "8.4", "9.0", "9.1", "9.2", "9.3", "10.0", "10.1", "10.2", "10.3", "11.0", "11.1", "11.2", "11.3", "11.4", "12.0", "12.1", "12.2", "12.3", "12.4", "12.5", "13.0", "13.1", "13.2", "13.3", "13.4", "13.5", "13.6", "13.7", "14.0", "14.1", "14.2", "14.3", "14.4", "14.5"] },
    { id: 4, name: "webOS", versions: ["1.0", "1.1", "1.2", "1.3", "1.4", "2.0", "2.1", "2.2", "2.3", "3.0"] },
    { id: 5, name: "Windows Phone", versions: ["7.0", "8.0", "8.1", "10.0"] }
  ],
  gender: [
    { id: 10, name: "Male" },
    { id: 11, name: "Female" },
    { id: 20, name: "Unknown" }
  ],
  age: [
    { id: 503001, name: "18-24" },
    { id: 503002, name: "25-34" },
    { id: 503003, name: "35-44" },
    { id: 503004, name: "45-54" },
    { id: 503005, name: "55-64" },
    { id: 503006, name: "65+" },
    { id: 503999, name: "Unknown" }
  ],
  parent: [
    { id: 300, name: "Parent" },
    { id: 301, name: "Not a parent" },
    { id: 302, name: "Unknown" }
  ],
  income: [
    { id: 510006, name: "Top 10%" },
    { id: 510005, name: "11-20%" },
    { id: 510004, name: "21-30%" },
    { id: 510003, name: "31-40%" },
    { id: 510002, name: "41-50%" },
    { id: 510001, name: "Lower 50%" },
    { id: 510000, name: "Unknown" }
  ],
  locations: [],
  languages: [
    {
      "id": 1000,
      "name": "English"
    },
    {
      "id": 1001,
      "name": "German"
    },
    {
      "id": 1002,
      "name": "French"
    },
    {
      "id": 1003,
      "name": "Spanish"
    },
    {
      "id": 1004,
      "name": "Italian"
    },
    {
      "id": 1005,
      "name": "Japanese"
    },
    {
      "id": 1009,
      "name": "Danish"
    },
    {
      "id": 1010,
      "name": "Dutch"
    },
    {
      "id": 1011,
      "name": "Finnish"
    },
    {
      "id": 1012,
      "name": "Korean"
    },
    {
      "id": 1013,
      "name": "Norwegian"
    },
    {
      "id": 1014,
      "name": "Portuguese"
    },
    {
      "id": 1015,
      "name": "Swedish"
    },
    {
      "id": 1017,
      "name": "Chinese (simplified)"
    },
    {
      "id": 1018,
      "name": "Chinese (traditional)"
    },
    {
      "id": 1019,
      "name": "Arabic"
    },
    {
      "id": 1020,
      "name": "Bulgarian"
    },
    {
      "id": 1021,
      "name": "Czech"
    },
    {
      "id": 1022,
      "name": "Greek"
    },
    {
      "id": 1023,
      "name": "Hindi"
    },
    {
      "id": 1024,
      "name": "Hungarian"
    },
    {
      "id": 1025,
      "name": "Indonesian"
    },
    {
      "id": 1026,
      "name": "Icelandic"
    },
    {
      "id": 1027,
      "name": "Hebrew"
    },
    {
      "id": 1028,
      "name": "Latvian"
    },
    {
      "id": 1029,
      "name": "Lithuanian"
    },
    {
      "id": 1030,
      "name": "Polish"
    },
    {
      "id": 1031,
      "name": "Russian"
    },
    {
      "id": 1032,
      "name": "Romanian"
    },
    {
      "id": 1033,
      "name": "Slovak"
    },
    {
      "id": 1034,
      "name": "Slovenian"
    },
    {
      "id": 1035,
      "name": "Serbian"
    },
    {
      "id": 1036,
      "name": "Ukrainian"
    },
    {
      "id": 1037,
      "name": "Turkish"
    },
    {
      "id": 1038,
      "name": "Catalan"
    },
    {
      "id": 1039,
      "name": "Croatian"
    },
    {
      "id": 1040,
      "name": "Vietnamese"
    },
    {
      "id": 1041,
      "name": "Urdu"
    },
    {
      "id": 1042,
      "name": "Filipino"
    },
    {
      "id": 1043,
      "name": "Estonian"
    },
    {
      "id": 1044,
      "name": "Thai"
    },
    {
      "id": 1056,
      "name": "Bengali"
    },
    {
      "id": 1064,
      "name": "Persian"
    },
    {
      "id": 1072,
      "name": "Gujarati"
    },
    {
      "id": 1086,
      "name": "Kannada"
    },
    {
      "id": 1098,
      "name": "Malayalam"
    },
    {
      "id": 1101,
      "name": "Marathi"
    },
    {
      "id": 1102,
      "name": "Malay"
    },
    {
      "id": 1110,
      "name": "Punjabi"
    },
    {
      "id": 1130,
      "name": "Tamil"
    },
    {
      "id": 1131,
      "name": "Telugu"
    }
  ],
  includeAudienceType: [
    { id: 1, name: "First and third party" },
    { id: 2, name: "Google Audience" },
    { id: 3, name: "Combined" },
    { id: 4, name: "Custom List" }
  ],
  excludeAudienceType: [
    { id: 1, name: "First and third party" },
    { id: 2, name: "Google Audience" }
  ],
  audience: { // populated in targetForm.bpseId watch
    1: [],
    2: [],
    3: [],
    4: []
  },
  digitalContentLabelExclusions: [
    { id: 19875633960, name: "DL-G: General audiences" },
    { id: 19875634080, name: "DL-PG: Audiences with parental guidance" },
    { id: 19875634200, name: "DL-T: Teens and older audiences" },
    { id: 19875634320, name: "DL-MA: Mature audiences" },
    { id: 19875634440, name: "DL-?: Not yet labeled" }
  ],
  sensitiveCategoryExclusions: [
    { id: 118521027123, name: "Adult" },
    { id: 118521027843, name: "Derogatory" },
    { id: 1163177817, name: "Downloads & sharing" },
    { id: 118521028803, name: "Weapons" },
    { id: 143909494834, name: "Gambling" },
    { id: 118521028563, name: "Violence" },
    { id: 1163177997, name: "Suggestive" },
    { id: 1163178297, name: "Profanity" },
    { id: 118521027603, name: "Alcohol" },
    { id: 118521028083, name: "Drugs" },
    { id: 118521028323, name: "Tobacco" },
    { id: 1163178357, name: "Politics" },
    { id: 289334238373, name: "Religion" },
    { id: 1596254697, name: "Tragedy" },
    { id: 118521027363, name: "Transportation accidents" },
    { id: 777266439799, name: "Shocking" },
    { id: 116505648784, name: "Sensitive social issues" }
  ],
  otherVerificationServices: [],
  environment: [{ id: 508010, name: "Web" }, { id: 508012, name: "App" }],
  devices: [ // TARGETING_TYPE_DEVICE_TYPE
    { id: 30000, name: "Computer" },
    { id: 30001, name: "Smart Phone" },
    { id: 30002, name: "Tablet" },
    { id: 30004, name: "Connected TV" }
  ],
  browsers: [
    {
      "id": 500010,
      "name": "Microsoft Internet Explorer Other"
    },
    {
      "id": 500069,
      "name": "Other"
    },
    {
      "id": 500071,
      "name": "Safari Other"
    },
    {
      "id": 500072,
      "name": "Google Chrome"
    },
    {
      "id": 500074,
      "name": "Microsoft Internet Explorer 8"
    },
    {
      "id": 500075,
      "name": "Microsoft Internet Explorer 7"
    },
    {
      "id": 500076,
      "name": "Microsoft Internet Explorer 6"
    },
    {
      "id": 500077,
      "name": "Microsoft Internet Explorer 5"
    },
    {
      "id": 500084,
      "name": "Firefox"
    },
    {
      "id": 500085,
      "name": "Mozilla"
    },
    {
      "id": 500086,
      "name": "Netscape Navigator"
    },
    {
      "id": 500093,
      "name": "Opera"
    },
    {
      "id": 500095,
      "name": "Safari 4"
    },
    {
      "id": 500099,
      "name": "Safari 3"
    },
    {
      "id": 500100,
      "name": "Safari 2"
    },
    {
      "id": 500101,
      "name": "Safari 1"
    },
    {
      "id": 500102,
      "name": "Safari"
    },
    {
      "id": 500103,
      "name": "Safari (iPad)"
    },
    {
      "id": 500104,
      "name": "Safari (iPhone/iPod)"
    },
    {
      "id": 500105,
      "name": "Android Browser"
    },
    {
      "id": 500106,
      "name": "Microsoft Internet Explorer 9"
    },
    {
      "id": 500118,
      "name": "Microsoft Internet Explorer 10"
    },
    {
      "id": 500121,
      "name": "Safari 5"
    },
    {
      "id": 500122,
      "name": "Safari 6"
    },
    {
      "id": 500123,
      "name": "Microsoft Internet Explorer 11"
    },
    {
      "id": 500136,
      "name": "Opera Next"
    },
    {
      "id": 500137,
      "name": "Yandex"
    },
    {
      "id": 500153,
      "name": "Microsoft Edge"
    },
    {
      "id": 500155,
      "name": "Safari 7"
    },
    {
      "id": 500156,
      "name": "Safari 8"
    },
    {
      "id": 500157,
      "name": "Safari 9"
    },
    {
      "id": 500158,
      "name": "Safari 10"
    },
    {
      "id": 500159,
      "name": "Safari 11"
    },
    {
      "id": 500160,
      "name": "Safari 12"
    },
    {
      "id": 500189,
      "name": "Safari 13"
    },
    {
      "id": 500195,
      "name": "Safari 14"
    }
  ], // TARGETING_TYPE_BROWSER,
  carrierAndISP: [], // TARGETING_TYPE_CARRIER_AND_ISP
  viewability: [
    { name: "90% or greater", id: 509090 },
    { name: "80% or greater", id: 509080 },
    { name: "70% or greater", id: 509070 },
    { name: "60% or greater", id: 509060 },
    { name: "50% or greater", id: 509050 },
    { name: "40% or greater", id: 509040 },
    { name: "30% or greater", id: 509030 },
    { name: "20% or greater", id: 509020 },
    { name: "10% or greater", id: 509010 }
  ],
  positionOnScreen: [ // TARGETING_TYPE_ON_SCREEN_POSITION
    { id: 21, name: "Above the fold" },
    { id: 22, name: "Below the fold" },
    { id: 23, name: "Unknown" }
  ],
  inContextDisplay: [ // TARGETING_TYPE_CONTENT_OUTSTREAM_POSITION
    { id: 509500, name: "Unknown" },
    { id: 509501, name: "In-article" },
    { id: 509502, name: "In-banner" },
    { id: 509503, name: "In-feed" },
    { id: 509504, name: "Interstitial" }
  ],
  inContextNative: [
    { name: "In-article" },
    { name: "In-feed" },
    { name: "Peripheral" },
    { name: "Recommendation" },
    { name: "Unknown" }
  ]
}

export { options };
