<template>
  <div>
    <button
      @click="showModal = true;"
      class="btn btn-success btn-sm widget-element pull-right"
    >
      <i class="fa fa-plus"></i>
    </button>
    <v-modal
      class="target-modal"
      title="Create Target"
      v-model="showModal"
      effect="fade"
    >
      <div slot="modal-body" class="targets-body">
        <Row>
          <Row-Col span="16">
            <Form
              :label-width="105"
              :model="targetForm"
              :rules="validationRules"
              ref="creationForm"
            >
              <br />
              <Row>
                <Row-Col span="9">
                  <Form-Item label="Account:" prop="bpseId">
                    <Select
                      v-model="targetForm.bpseId"
                      placeholder="Select an account"
                      filterable
                    >
                      <Option
                        v-for="account in googleAccounts"
                        :value="account.Id"
                        :key="account.Id"
                      >{{ account.NickName }} ({{
                          account.CustomerId
                        }})
                      </Option>
                    </Select>
                  </Form-Item>
                </Row-Col>
                <Row-Col v-if="targetForm.bpseId" span="15">
                  <Row-Col>
                    <Button class="float-right" @click="generateTargets">
                      Generate Audiences
                    </Button>
                  </Row-Col>
                  <Row-Col>
                    <Button class="float-right" @click="clearAll">
                      Clear all
                    </Button>
                  </Row-Col>
                </Row-Col>
              </Row>
              <br />

              <template v-if="targetForm.bpseId">
                <!-- Load from existing dropdown -->
                <!-- <Form-Item label="Load From Existing:">
                  <Row>
                    <Row-Col span="15">
                      <Select
                        v-model="existingTargetId"
                        filterable
                        placeholder="Search Existing Targets"
                        :loading="loading.existingTargets"
                      >
                        <Option v-for="item in existingTargets" :value="item.value" :key="item.value">{{ item.label }}</Option>
                      </Select>
                    </Row-Col>
                  </Row>
                </Form-Item> -->
                <Form-Item
                  label="Name:"
                  prop="targetName"
                  class="input-form"
                >
                  <Row>
                    <Row-Col span="15">
                      <i-input
                        v-model="targetForm.targetName"
                        @on-change="resetAllTargetSaveFailFlag"
                      />
                    </Row-Col>
                  </Row>
                  <Row>
                    <Row-Col span="15">
                      <ul class="macro-list">
                        <li class="macros">Available Macros:</li>
                        <li
                          v-for="macroName in targetMacros"
                          :key="macroName"
                          class="pointer"
                        >
                          <a class="macros" @click="addMacro(macroName)">
                            {{ macroName }}
                          </a>
                        </li>
                      </ul>
                    </Row-Col>
                  </Row>
                </Form-Item>
                <Form-Item
                  label="Language:"
                  prop="languageIds"
                  class="input-form"
                >
                  <Row>
                    <Row-Col span="15">
                      <Select
                        v-model="targetForm.languageIds"
                        filterable
                        placeholder="Search Language"
                        :loading="loading.languages"
                        multiple
                        @on-change="resetAllTargetSaveFailFlag"
                      >
                        <Option v-for="item in languages" :value="item.value" :key="item.value">{{ item.label }}</Option>
                      </Select>
                    </Row-Col>
                  </Row>
                </Form-Item>
                <div class="divider">
                  <span>Location</span>
                </div>
                <Form-Item
                  label="Include:"
                  prop="includedLocationIds"
                  class="input-form"
                >
                  <Row>
                    <Row-Col span="15">
                      <Select
                        multiple
                        v-model="targetForm.includedLocationIds"
                        filterable
                        placeholder="Search locations"
                        :remote-method="query => getLocations(query, 'includeLocations')"
                        :loading="loading.includeLocations"
                        @on-change="resetAllTargetSaveFailFlag"
                      >
                        <Option
                          v-for="loc in includeLocations"
                          :key="loc.value"
                          :value="loc.value"
                          :label="loc.label"
                        >
                          <span>{{ loc.label }}</span>
                        </Option>
                      </Select>
                    </Row-Col>
                  </Row>
                </Form-Item>
                <Form-Item
                  label="Exclude:"
                  class="input-form"
                >
                  <Row>
                    <Row-Col span="15">
                      <Select
                        multiple
                        v-model="targetForm.excludedLocationIds"
                        filterable
                        placeholder="Search locations"
                        :remote-method="query => getLocations(query, 'excludeLocations')"
                        :loading="loading.excludeLocations"
                        @on-change="resetAllTargetSaveFailFlag"
                      >
                        <Option
                          v-for="loc in excludeLocations"
                          :key="loc.value"
                          :value="loc.value"
                          :label="loc.label"
                        >
                          <span>{{ loc.label }}</span>
                        </Option>
                      </Select>
                    </Row-Col>
                  </Row>
                </Form-Item>
                <Form-Item
                  label="Target:"
                  class="input-form"
                >
                  <Row>
                    <Row-Col span="15">
                      <RadioGroup
                        v-model="targetForm.includedGeoTargetSettingType"
                        @on-change="resetAllTargetSaveFailFlag"
                      >
                        <Radio
                          v-for="(ptarget, key) in positiveGeoTargeting"
                          :value="key"
                          :key="key"
                          :label="key"
                        >
                          <span>{{ptarget}}</span>
                        </Radio>
                      </RadioGroup>
                    </Row-Col>
                    <Row-Col span="1">
                      <Tooltip max-width="400" :transfer="true" placement="right-start">
                        <i class="fa fa-info-circle"></i>
                        <div slot="content">
                            <p><b>Presence or Interest: </b>People in, regularly in, or who've shown interest in your targeted locations<br><b>Presence: </b>People in, regularly in your targeted locations<br><b>Search Interest: </b>People searching for your targeted locations</p>
                        </div>
                      </Tooltip>
                    </Row-Col>
                  </Row>
                </Form-Item>
                <Form-Item
                  label="Exclude:"
                  class="input-form"
                >
                  <Row>
                    <Row-Col span="15">
                      <RadioGroup
                        v-model="targetForm.excludedGeoTargetSettingType"
                        @on-change="resetAllTargetSaveFailFlag"
                      >
                        <Radio
                          v-for="(ptarget, key) in negativeGeoTargeting"
                          :value="key"
                          :key="key"
                          :label="key"
                        >
                          <span>{{ptarget}}</span>
                        </Radio>
                      </RadioGroup>
                    </Row-Col>
                    <Row-Col span="1">
                      <Tooltip max-width="400" :transfer="true" placement="right-start">
                        <i class="fa fa-info-circle"></i>
                        <div slot="content">
                            <p><b>Presence: </b>People in your excluded locations</p>
                        </div>
                      </Tooltip>
                    </Row-Col>
                  </Row>
                </Form-Item>
                <div class="divider">
                  <span>Audience Segments</span>
                </div>
                <Form-Item
                  label="Interest:"
                  class="input-form"
                >
                  <Row>
                    <Row-Col span="15">
                      <Select
                        multiple
                        v-model="targetForm.interests"
                        filterable
                        placeholder="Search interests"
                        :remote-method="query => getInterests(query)"
                        :loading="loading.interests"
                        @on-change="resetAllTargetSaveFailFlag"
                      >
                        <Option
                          v-for="interest in interests"
                          :key="interest.id"
                          :value="interest.id"
                          :label="interest.name"
                        >
                          <span>{{ interest.name }}</span>
                          <span class="badge">{{ taxonomyType[interest.taxonomyType] }}</span>
                        </Option>
                      </Select>
                    </Row-Col>
                  </Row>
                </Form-Item>
                <Form-Item
                  label="Custom Interest:"
                  class="input-form"
                >
                  <Row>
                    <Row-Col span="15">
                      <Select
                        multiple
                        v-model="targetForm.customAudienceIds"
                        filterable
                        placeholder="Search custom interests"
                        :remote-method="query => getCustomInterests(query)"
                        :loading="loading.customInterests"
                        @on-change="resetAllTargetSaveFailFlag"
                      >
                        <Option
                          v-for="customInterest in customInterests"
                          :key="customInterest.id"
                          :value="customInterest.id"
                          :label="customInterest.name"
                        >
                          <span>{{ customInterest.name }}</span>
                        </Option>
                      </Select>
                    </Row-Col>
                  </Row>
                </Form-Item>
                <Form-Item
                  label="First Party Audiences:"
                  class="input-form"
                >
                  <Row>
                    <Row-Col span="15">
                      <Select
                        multiple
                        v-model="targetForm.userListIds"
                        filterable
                        placeholder="Search first party audiences"
                        :remote-method="query => getFirstPartyAudiences(query)"
                        :loading="loading.firstPartyAudiences"
                        @on-change="resetAllTargetSaveFailFlag"
                      >
                        <Option
                          v-for="firstPartyAudience in firstPartyAudiences"
                          :key="firstPartyAudience.id"
                          :value="firstPartyAudience.id"
                          :label="firstPartyAudience.name"
                        >
                          <span>{{ firstPartyAudience.name }}</span>
                        </Option>
                      </Select>
                    </Row-Col>
                  </Row>
                </Form-Item>
                <Form-Item
                  label="Targeting Method:"
                  class="input-form"
                >
                  <Row>
                    <Row-Col span="15">
                      <RadioGroup
                        v-model="targetForm.targetingMethod"
                        @on-change="resetAllTargetSaveFailFlag"
                      >
                        <Radio
                          v-for="(method, key) in targetingMethod"
                          :value="key"
                          :key="key"
                          :label="key"
                        >
                          <span>{{method}}</span>
                        </Radio>
                      </RadioGroup>
                    </Row-Col>
                    <Row-Col span="1">
                      <Tooltip max-width="400" :transfer="true" placement="right-start">
                        <i class="fa fa-info-circle"></i>
                        <div slot="content">
                          <p><b>Targeting: </b>Narrow the reach of your campaign to the selected segments, with the option to adjust the bids<br><b>Observation: </b>Don't narrow the reach of your campaign, with the option to adjust the bids on the selected segments</p>
                        </div>
                      </Tooltip>
                    </Row-Col>
                  </Row>
                </Form-Item>
                <div class="divider">
                  <span>Demographics</span>
                </div>
                <Row>
                  <Row-Col span="18">
                    <div v-show="showWarningForTargeting">
                      <Alert type="warning" banner show-icon class="targeting-warn">For Demand Gen Campaigns, targeting all Gender, Age and Household Incomes is not supported.</Alert>
                    </div>
                    <Form-Item class="form-item-demographic">
                      <Collapse
                        v-model="collapsePanel"
                        class="adwords-targets-panel border-radius-zero"
                      >
                      <div class="demographic-target-row" v-for="(demograph, index) in demographics" :key="index" :name="index.toString()">
                      <Panel hide-arrow>
                          <Row>
                            <Row-Col class="feature-col" span="21">
                              Features: <div class="error-inline"> {{demograph.targetValidationInfo}} </div>
                            </Row-Col>
                            <div @click="addFeatureRow($event)">
                              <Row-Col span="1">
                                <i class="fa align-center fa-plus"></i>
                              </Row-Col>
                            </div>
                            <div @click="copyFeatureRow($event, index)">
                              <Row-Col span="1">
                                <i class="fa align-center fa-copy" ></i>
                              </Row-Col>
                            </div>
                            <div @click="deleteFeatureRow($event, index)">
                              <Row-Col span="1">
                                  <i class="fa align-center fa-trash"></i>
                              </Row-Col>
                            </div>
                          </Row>
                          <div slot="content">
                            <Form-Item
                              label="Gender:"
                              class="padding-none asterix"
                            >
                              <Row>
                                <Row-Col span="20">
                                  <Select
                                    multiple
                                    v-model="demograph.gender"
                                    placeholder="Select Range"
                                    @on-change="onDemographicChange(index)"
                                  >
                                    <Option
                                      v-for="(gender, key) in genders"
                                      :value="key"
                                      :key="key"
                                    >{{ gender }}
                                    </Option>
                                  </Select>
                                </Row-Col>
                              </Row>
                            </Form-Item>
                            <Form-Item
                              label="Age:"
                              class="padding-bottom-none asterix"
                            >
                              <Row>
                                <Row-Col span="20">
                                  <Select
                                    multiple
                                    v-model="demograph.age"
                                    placeholder="Select Range"
                                    @on-change="onDemographicChange(index)"
                                  >
                                    <Option
                                      v-for="(age, key) in ageRange"
                                      :value="key"
                                      :key="key"
                                    >{{ age }}
                                    </Option>
                                  </Select>
                                </Row-Col>
                              </Row>
                            </Form-Item>
                            <Form-Item
                              label="Household Income:"
                              class="padding-bottom-none asterix"
                            >
                              <Row>
                                <Row-Col span="20">
                                  <Select
                                    multiple
                                    v-model="demograph.houseHoldIncome"
                                    placeholder="Select Range"
                                    @on-change="onDemographicChange(index)"
                                  >
                                    <Option
                                      v-for="(income, key) in houseHoldIncome"
                                      :value="key"
                                      :key="key"
                                    >{{ income }}
                                    </Option>
                                  </Select>
                                </Row-Col>
                              </Row>
                            </Form-Item>
                          </div>
                      </Panel>
                      <Row class="empty-divider"></Row>
                      </div>
                      </Collapse>
                    </Form-Item>
                  </Row-Col>
                </Row>
              </template>
              <template v-else>
                <p style="text-align: center;">
                  Please select an account to create targets
                </p>
              </template>
            </Form>
          </Row-Col>
          <Row-Col span="8">
            <div class="preivew-wrapper">
              <div class="preview-header">
                <p>New Audience Preview</p>
              </div>
              <template v-if="isFormValid && validDemographics.length">
                <Collapse
                  v-model="audienceCollapsePanel"
                  class="adwords-targets-panel target-row border-radius-zero"
                >
                <div class="audience-pr-target-row audience-preview" v-for="(target, index) in validDemographics" :key="index">
                <Panel class="audience-pr-panel" hide-arrow >
                  <Row class="target-prev-header">
                    <Row-Col class="target-feature-col" span="19">
                      <p>{{ getTargetName(index) }}
                        <i v-if="target.targetSaved" class="fa fa-check-circle default-pointer"></i>
                        <i v-else-if="target.targetFailed" class="fa fa-times-circle default-pointer"></i>
                      </p>
                    </Row-Col>
                    <Row-Col span="2" offset="1">
                      <i
                        v-show="!target.targetSave"
                        @click="saveTarget($event, index)"
                        class="fa fa-save preview-icon"
                      >
                      </i>
                      <i class="fa fa-spinner fa-spin preview-icon save-spinner" v-show="target.targetSave" aria-hidden="true"></i>
                    </Row-Col>
                    <Row-Col span="2">
                      <i
                        @click="removeTargetFromPreview($event, index)"
                        v-if="!target.targetSave"
                        class="fa fa-trash preview-icon"
                      />
                      <i
                        v-else
                        class="fa fa-trash disabled-btn"
                      />
                    </Row-Col>
                  </Row>
                  <div slot="content">
                  <Row class="target-prev-body">
                    <p v-html="getUserReadableTargetSummary(index)"></p>
                  </Row>
                  </div>
                </Panel>
                <Row class="empty-divider"></Row>
                </div>
                </Collapse>
              </template>
              <template v-else>
                <div class="preview-placeholder">
                  Please enter the required fields to preview new audience
                </div>
              </template>
            </div>
          </Row-Col>
        </Row>
      </div>
      <div slot="modal-footer" class="modal-footer hide">
      </div>
    </v-modal>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import {
  Row,
  Col,
  Form,
  FormItem,
  Select,
  Option,
  Button,
  Input,
  RadioGroup,
  Radio,
  Tooltip,
  Panel,
  Collapse,
  locale,
  Alert
} from "iview"
import { modal } from "vue-strap";
import lang from "iview/dist/locale/en-US";
import { adwordsEnums } from "../../Constants/AdwordsEnums.js";
import { APIService } from "../../ApiService.js";
import { EventBus } from "../../EventBus.js";
locale(lang);

export default {
  components: {
    Row,
    "Row-Col": Col,
    "i-input": Input,
    Form,
    FormItem,
    Select,
    Option,
    Button,
    RadioGroup,
    Radio,
    Tooltip,
    Panel,
    Collapse,
    "v-modal": modal,
    Alert
  },
  data () {
    return {
      existingTargetId: 0,
      existingTargets: [],
      showModal: false,
      targetMacros: ["Language", "Location", "Gender", "AgeGroup"],
      collapsePanel: "0",
      audienceCollapsePanel: "0",
      demographics: [
        { ...adwordsEnums.demographicDefaultData }
      ],
      demographicDefaultData: adwordsEnums.demographicDefaultData,
      targetForm: adwordsEnums.targetForm,
      loading: {
        languages: false,
        includeLocations: false,
        excludeLocations: false,
        existingTargets: false,
        interests: false,
        customInterests: false,
        firstPartyAudiences: false
      },
      validationRules: {
        bpseId: [
          {
            required: true,
            type: "number",
            message: "Please select an account",
            trigger: "blur"
          }
        ],
        targetName: [
          { required: true, message: "Please enter a name", trigger: "blur" }
        ],
        languageIds: [
          { required: true, message: "Please select a Language" }
        ],
        includedLocationIds: [
          { required: true, message: "Please select a Location" }
        ]
      },
      languages: [],
      includeLocations: [],
      excludeLocations: [],
      ageRange: adwordsEnums.ageRange,
      houseHoldIncome: adwordsEnums.houseHoldIncome,
      genders: adwordsEnums.gender,
      validDemographics: [],
      selectedLanguages: [],
      selectedLocations: [],
      defaultTargetForm: adwordsEnums.targetForm,
      positiveGeoTargeting: adwordsEnums.positiveGeoTargeting,
      negativeGeoTargeting: adwordsEnums.negativeGeoTargeting,
      interests: [],
      customInterests: [],
      firstPartyAudiences: [],
      selectedInterests: [],
      selectedCustomInterests: [],
      selectedFirstPartyAudiences: [],
      selectedExcludedLocations: [],
      taxonomyType: adwordsEnums.taxonomyType,
      targetingMethod: adwordsEnums.targetingMethod
    }
  },
  computed: {
    ...mapState(["googleAccounts", "savedTargetsMapperByBpseId", "savedTargets"]),
    isFormValid () {
      let tempValidDemographics = this.demographics.filter(function (e) {
        if (e.gender.length && e.age.length && e.houseHoldIncome.length) {
          e.targetValidationInfo = "";
          return e.gender.length && e.age.length && e.houseHoldIncome.length;
        }
      });
      if (this.targetForm.bpseId &&
        tempValidDemographics.length &&
        this.targetForm.targetName && this.targetForm.includedLocationIds.length && this.targetForm.languageIds.length) {
        this.validDemographics = tempValidDemographics;
        return true;
      }
      return false;
    },
    showWarningForTargeting () {
      return this.demographics[0].gender.length == 3 && this.demographics[0].age.length == 7 && this.demographics[0].houseHoldIncome.length == 7;
    }
  },
  methods: {
    ...mapMutations(["set_savedTargetsMapperByBpseId", "set_savedTargets"]),
    addMacro (name) {
      if (!this.targetForm.targetName.includes(`{${name}}`)) {
        if (this.targetForm.targetName) {
          this.targetForm.targetName += "-";
        }
        this.targetForm.targetName += `{${name}}`;
      }
    },
    clearAll () {
      this.$refs.creationForm.resetFields();
      this.clearNestedFields();
    },
    clearNestedFields () {
      this.targetForm = JSON.parse(JSON.stringify(this.defaultTargetForm));
      this.validDemographics = [];
      this.includeLocations = [];
      this.excludeLocations = [];
      this.interests = [];
      this.customInterests = [];
      this.firstPartyAudiences = [];
      this.demographics = [
        { ...this.demographicDefaultData }
      ]
    },
    getLocations (searchQuery, type) {
      if (searchQuery.length >= 3) {
        let params = {
          Keyword: searchQuery,
          HasGoogleCode: true
        };
        var self = this;
        this.$set(this.loading, type, true);
        APIService.getDbLocations(params)
          .then(response => {
            if (response) {
              let keys = self[type].map(e => e.value);
              response.forEach(e => {
                if (!keys.includes(e.googleCode)) {
                  self[type].push({ value: e.googleCode, label: e.completePath });
                  keys.push(e.googleCode);
                }
              });
            }
          })
          .catch(_ => {
            this.$Message.error({
              background: true,
              content: "Failed to fetch locations"
            });
          })
          .finally(() => {
            this.$set(this.loading, type, false);
          });
      }
    },
    getInterests (searchQuery) {
      if (searchQuery.length >= 3) {
        let params = {
          name: searchQuery,
          limit: 50
        };
        var self = this;
        this.$set(this.loading, "interests", true);
        APIService.getUserInterests(this.targetForm.bpseId, params)
          .then(response => {
            if (response) {
              let fetchedInterestIds = self.interests.map(e => e.id);
              response.forEach(res => {
                if (!fetchedInterestIds.includes(res.id)) {
                  self.interests.push(res);
                }
              })
            }
          })
          .catch(_ => {
            this.$Message.error({
              background: true,
              content: "Failed to fetch intersets"
            });
          })
          .finally(() => {
            this.$set(this.loading, "interests", false);
          });
      }
    },
    getCustomInterests (searchQuery) {
      if (searchQuery.length >= 3) {
        let params = {
          name: searchQuery,
          type: adwordsEnums.customAudienceType.Auto,
          limit: 50
        };
        var self = this;
        this.$set(this.loading, "customInterests", true);
        APIService.getCustomInterests(this.targetForm.bpseId, params)
          .then(response => {
            if (response) {
              let fetchedCustomInterestIds = self.customInterests.map(e => e.id);
              response.forEach(res => {
                if (!fetchedCustomInterestIds.includes(res.id)) {
                  self.customInterests.push(res);
                }
              })
            }
          })
          .catch(_ => {
            this.$Message.error({
              background: true,
              content: "Failed to fetch custom intersets"
            });
          })
          .finally(() => {
            this.$set(this.loading, "customInterests", false);
          });
      }
    },
    getFirstPartyAudiences (searchQuery) {
      if (searchQuery.length >= 3) {
        let params = {
          name: searchQuery,
          limit: 50
        };
        var self = this;
        this.$set(this.loading, "firstPartyAudiences", true);
        APIService.getFirstPartyAudiences(this.targetForm.bpseId, params)
          .then(response => {
            if (response) {
              let fetchedFirstPartyAudienceIds = self.firstPartyAudiences.map(e => e.id);
              response.forEach(res => {
                if (!fetchedFirstPartyAudienceIds.includes(res.id)) {
                  self.firstPartyAudiences.push(res);
                }
              })
            }
          })
          .catch(_ => {
            this.$Message.error({
              background: true,
              content: "Failed to fetch first party audiences"
            });
          })
          .finally(() => {
            this.$set(this.loading, "firstPartyAudiences", false);
          });
      }
    },
    async saveTarget (event, index) {
      event.stopPropagation();
      this.validDemographics[index].targetSave = true;
      let targetStringJson = {
        Locations: {
          Excluded: {
            LocationCodes: this.targetForm.excludedLocationIds.map(e => Number(e)),
            GeoTargetingType: this.targetForm.excludedGeoTargetSettingType
          },
          Included: {
            LocationCodes: this.targetForm.includedLocationIds.map(e => Number(e)),
            GeoTargetingType: this.targetForm.includedGeoTargetSettingType
          }
        },
        LanguageCodes: this.targetForm.languageIds,
        Genders: this.validDemographics[index].gender,
        AgeRanges: this.validDemographics[index].age,
        HouseholdIncomeRanges: this.validDemographics[index].houseHoldIncome,
        UserInterestIds: this.targetForm.interests,
        CustomAudienceIds: this.targetForm.customAudienceIds,
        UserListIds: this.targetForm.userListIds,
        TargetingMethod: this.targetForm.targetingMethod
      };
      let dynamicTargetString = {
        Interests: this.selectedInterests,
        CustomInterests: this.selectedCustomInterests,
        FirstPartyAudiences: this.selectedFirstPartyAudiences
      };
      let targetObj = {
        name: this.getTargetName(index),
        BusinessProfilesSearchEngineId: this.targetForm.bpseId,
        agencyid: deltax.businessProfileId,
        advertiserid: deltax.businessProfileId,
        businessProfileId: deltax.businessProfileId,
        isCreatedOnDx: true,
        targetString: JSON.stringify(targetStringJson),
        dynamicTargetString: JSON.stringify(dynamicTargetString)
      };
      try {
        let response = await APIService.saveTargets(targetObj);
        if (response && response.data) {
          this.$Message.success({
            background: true,
            content: `${targetObj.name} successfully created`
          });
          targetObj.id = response.data.data;
          this.set_savedTargets([...this.savedTargets, targetObj]);
          let targetsBpseMap = this.savedTargetsMapperByBpseId;
          if (!(this.targetForm.bpseId in targetsBpseMap)) {
            targetsBpseMap[this.targetForm.bpseId] = [];
          }
          targetsBpseMap[this.targetForm.bpseId].unshift({
            BpseId: this.targetForm.bpseId,
            EstimatedReach: 0,
            Id: response.data.data,
            Name: targetObj.name,
            id: response.data.data
          });
          this.set_savedTargetsMapperByBpseId(targetsBpseMap);

          // emit to show created targets in mapper screen
          targetObj.Id = targetObj.id;
          targetObj.bpseId = targetObj.BusinessProfilesSearchEngineId;
          targetObj.Name = targetObj.name;
          EventBus.$emit('adwords-updateSavedTarget', targetObj, false);
        }
        this.validDemographics[index].targetSave = false;
        this.validDemographics[index].targetSaved = true;
        this.validDemographics[index].targetFailed = false;
      } catch (ex) {
        this.$Message.error({
          background: true,
          content: "There was a problem while processing your request"
        });
        if (this.validDemographics[index]) {
          this.validDemographics[index].targetSave = false;
          this.validDemographics[index].targetSaved = false;
          this.validDemographics[index].targetFailed = true;
        }
      }
    },
    removeTargetFromPreview (event, index) {
      event.stopPropagation();
      this.validDemographics[index].targetSaved = 0;
      this.validDemographics[index].targetFailed = 0;
      this.validDemographics.splice(index, 1);
    },
    generateTargets () {
      let tempValidDemographics = this.demographics.filter(function (e) {
        if (!e.gender.length || !e.age.length || !e.houseHoldIncome.length) {
          e.targetValidationInfo = "Please select all the required fields"
        } else {
          e.targetValidationInfo = "";
          return e.gender.length && e.age.length && e.houseHoldIncome.length;
        }
      });
      if (this.targetForm.bpseId &&
        tempValidDemographics.length &&
        this.targetForm.targetName && this.targetForm.includedLocationIds.length && this.targetForm.languageIds.length) {
        this.validDemographics = tempValidDemographics;
      } else {
        this.$Message.error({
          background: true,
          content: "Please select required fields to Generate Audiences"
        });
        this.$refs["creationForm"].validate();
      }
    },
    onDemographicChange (index) {
      this.demographics[index].targetSaved = 0;
      this.demographics[index].targetFailed = 0;
    },
    resetAllTargetSaveFailFlag () {
      this.demographics.forEach(element => {
        element.targetSaved = 0;
        element.targetFailed = 0;
      });
    },
    addFeatureRow (e) {
      e.stopPropagation();
      this.demographics.push({ ...this.demographicDefaultData });
    },
    copyFeatureRow (e, index) {
      e.stopPropagation();
      let getDemographic = { ...this.demographics[index] };
      getDemographic.targetValidationInfo = "";
      this.demographics.push(getDemographic);
    },
    deleteFeatureRow (e, index) {
      e.stopPropagation();
      if (this.demographics.length > 1) {
        this.demographics.splice(index, 1);
      }
    },
    getStringForPanel (type) {
      var self = this
      if (!self[type]) {
        return null;
      }
      return self[type].join("|");
    },
    getUserReadableTargetSummary (index) {
      var message = "<b>Includes</b><br/>";
      message += `Age: ${this.validDemographics[index].age.map(e => this.ageRange[e]).join(" | ")},
        Gender: ${this.validDemographics[index].gender.map(e => this.genders[e]).join(" | ")},
        Household Income: ${this.validDemographics[index].houseHoldIncome.map(e => this.houseHoldIncome[e]).join(" | ")}<br/>`;
      message += this.selectedLanguages ? `Languages: ${this.selectedLanguages.join(" | ")}<br/>` : "";
      message += this.selectedLocations ? `Locations: ${this.selectedLocations.join(" | ")}<br/>` : "";
      message += this.selectedInterests.length > 0 ? `Interests: ${this.selectedInterests.join(" | ")}<br/>` : "";
      message += this.selectedCustomInterests.length > 0 ? `Custom Interests: ${this.selectedCustomInterests.join(" | ")}<br/>` : "";
      message += this.selectedFirstPartyAudiences.length > 0 ? `First Party Audiences: ${this.selectedFirstPartyAudiences.join(" | ")}<br/>` : "";
      message += this.selectedExcludedLocations.length > 0 ? `<b>Excludes</b><br/> Locations: ${this.selectedExcludedLocations.join(" | ")}` : "";
      return message;
    },
    getTargetName (index) {
      let macroDict = {
        Language: this.getStringForPanel('selectedLanguages'),
        Location: this.getStringForPanel('selectedLocations'),
        Gender: this.validDemographics[index].gender.map(e => this.genders[e]).join("|"),
        AgeGroup: this.validDemographics[index].age.map(e => this.ageRange[e]).join("|")
      };
      let replacedTargetName = this.targetForm.targetName;
      for (let [macro, value] of Object.entries(macroDict)) {
        if (value) {
          replacedTargetName = replacedTargetName.replace(`{${macro}}`, value);
        } else {
          replacedTargetName = replacedTargetName.replace(`{${macro}}`, "");
        }
      }
      return replacedTargetName;
    }
  },
  mounted: function () {
    APIService.getLanguages()
      .then(response => {
        if (response && response.data) {
          this.languages = [...new Set(response.data.map(function (e) {
            return { value: e.googleCode, label: e.displayName };
          }))].filter(e => e.value);
        }
      })
      .catch(_ => {
        this.$Message.error({
          background: true,
          content: "Failed to fetch languages"
        });
      });
  },
  watch: {
    showModal () {
      if (!this.showModal) {
        this.clearAll();
      }
    },
    "targetForm.bpseId": function () {
      if (this.targetForm.bpseId) {
        this.$set(this.loading, 'existingTargets', true);
        APIService.getTargetsByBpse(this.targetForm.bpseId)
          .then(response => {
            this.existingTargets = response.data;
          })
          .catch(_ => {
            this.$Message.error({
              background: true,
              content: "Failed to fetch existing targets"
            });
          })
          .finally(() => {
            this.$set(this.loading, 'existingTargets', false);
          });
      }
    },
    "targetForm.languageIds": function () {
      this.selectedLanguages = this.languages.filter(e => this.targetForm.languageIds.includes(e.value))
        .map(function (e) { return e.label });
    },
    "targetForm.includedLocationIds": function () {
      this.selectedLocations = this.includeLocations.filter(e => this.targetForm.includedLocationIds.includes(e.value))
        .map(function (e) { return e.label });
    },
    "targetForm.interests": function () {
      this.selectedInterests = this.interests.filter(e => this.targetForm.interests.includes(e.id))
        .map(function (e) { return e.name });
    },
    "targetForm.customAudienceIds": function () {
      this.selectedCustomInterests = this.customInterests.filter(e => this.targetForm.customAudienceIds.includes(e.id))
        .map(function (e) { return e.name });
    },
    "targetForm.userListIds": function () {
      this.selectedFirstPartyAudiences = this.firstPartyAudiences.filter(e => this.targetForm.userListIds.includes(e.id))
        .map(function (e) { return e.name });
    },
    "targetForm.excludedLocationIds": function () {
      this.selectedExcludedLocations = this.excludeLocations.filter(e => this.targetForm.excludedLocationIds.includes(e.value))
        .map(function (e) { return e.label });
    }
  }
}
</script>

<style scoped>
.preivew-wrapper {
  border: 1px solid #ddd;
  margin: 15px;
  width: calc(100% - 30px);
}
.preview-placeholder {
  margin: 10px;
  font-weight: normal;
  color: #979696;
}
.preview-header {
  background-color: #f4f4f4;
  height: 35px;
  line-height: 35px;
  padding: 0 10px 0 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid var(--main-bg-color);
}
.preview-header p {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
::v-deep .push-left {
  margin-left: 20px;
}
.vertical-space {
  margin-top: 10px;
  margin-bottom: 10px;
}
.audience-wrapper {
  padding: 15px;
  border: 1px solid #ddd;
  background: #f4f4f4;
  padding: 15px;
}
.audience {
  border: 1px solid #ddd;
  padding: 10px;
  background: white;
}
.and-text {
  font-weight: bold;
}
.float-right {
  float: right;
  margin-left: 10px;
}
.macro-list {
  margin-bottom: 0px;
}
.macro-list > li {
  display: inline-block;
}
.pointer {
  cursor: pointer;
}
.macros {
  padding-right: 10px;
}
::v-deep .space {
  margin-bottom: 7px;
}
.target-row {
  margin: 10px;
  border: 1px solid #ddd;
}
.target-prev-header {
  padding: 0 5px;
  background: #f4f4f4;
  border-bottom: 2px solid var(--main-bg-color);
}
.target-prev-header p {
  margin: 2px 0 4px 0;
}
.target-prev-body {
  padding: 5px;
  font-weight: normal;
}
.preview-icon {
  margin-top: 3px;
  cursor: pointer;
  color: #888888;
}
.preview-icon:hover {
  color: #707376;
}
.preview-icon:active {
  transform: translateY(1px);
}
.save-spinner {
  cursor: default !important;
}

.divider {
  width: 100%;
  height: 7px;
  border-bottom: 1px solid #ccc;
  position: relative;
  margin-bottom: 36px;
}
.divider > span {
  left: 36px;
  position: absolute;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #979898;
  background-color: white;
  padding: 0 10px;
  top: -50%;
}
.operating-system-checkbox {
  display: block;
}
.platform-version-select {
  padding-left: 20px;
}
.os-name {
  padding-left: 5px;
}
.target-age-select {
  max-width: 150px;
}
.target-label-span {
  padding-right: 10px;
}
.targeting-warn {
  font-size: 11px;
  margin-bottom: 5px;
}
::v-deep .ivu-alert-icon{
  margin-top: 2px;
}
::v-deep .ivu-alert-message{
  margin-left: 24px;
}
.badge {
  float: right;
  margin-left: auto;
  min-width: fit-content;
  margin-right: 1.5rem;
}
</style>
<style>
.modal-header button.close > span {
  font-size: 22px !important;
  color: initial;
}
.input-form > .ivu-form-item-error-tip {
  padding-top: 1px;
}
.input-form .ivu-form-item-error-tip {
  font-weight: normal !important;
}
.target-modal .modal-header {
  padding-bottom: 5px !important;
}
.target-modal .modal-dialog {
  width: 98%;
  height: 100%;
  z-index: 9997;
}
.targets-body {
  overflow: auto;
  padding: 0px !important;
  height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
}
.adwords-targets-panel .ivu-collapse-header {
  height: auto !important;
}
.adwords-targets-panel .ivu-form-item-error {
  padding-bottom: 30px;
}
.adwords-targets-panel .ivu-form-item {
  padding-top: 10px;
  padding-bottom: 10px;
}
.form-item-demographic > div {
  margin-left: 0px !important;
}
.text-block-button {
  cursor: default !important;
}
.border-radius-zero {
  border-radius: 0px !important;
}
.align-center {
  width: 100%;
  text-align: center;
}
.padding-none {
  padding: 0px !important;
}
.padding-bottom-none {
  padding-bottom: 0px !important;
}
.asterix label:before {
    content: '*';
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 12px;
    color: #ed4014;
}
.disabled-btn{
  color: #ccc !important;
  cursor: default !important;
  font-size: 18px;
  margin-top: 3px;
}
.default-pointer {
  cursor: default;
}
.audience-preview div.ivu-collapse-header {
  padding: 0px !important;
}
.audience-pr-target-row .ivu-collapse-content, .audience-pr-target-row .ivu-collapse-content-box {
  padding: 0px !important;
}
.preview-header>.ivu-collapse>.ivu-collapse-item>.ivu-collapse-header {
  padding-left: 0px !important;
}
.empty-divider {
  padding-bottom: 10px;
  background-color: #ffffff;
}
.adwords-targets-panel {
  border: none !important;
  margin-bottom: 0px !important;
}
.audience-pr-target-row .ivu-collapse-item, .demographic-target-row .ivu-collapse-item {
  border: 1px solid #ddd
}
.feature-col {
  padding-left: 10px;
  cursor: pointer;
}
.target-feature-col {
  cursor: pointer;
}
.error-inline {
  color: #b94a48 !important;
  display: inline;
  font-weight: normal !important;
  float: right;
}
</style>
<style src="../../../../Styles/dx-iview.css"></style>
