import axiosInstance from '../../../Api'

export class CampaignMonitorAccessApiService {
  getCampaignMonitorBPRoleAccessSettings (userId, businessProfileId) {
    var data = JSON.stringify({ userId: userId })
    var getCampaignMonitorBPRoleAccessSettings = "/App/AccessManagement/GetCampaignMonitorBPRoleAccessSettings";
    var endpoint = getCampaignMonitorBPRoleAccessSettings.concat(`/${businessProfileId}`);
    const promise = axiosInstance.post(endpoint, data);
    return promise;
  }
  updateCampaignMonitorAccessSettings (userId, businessProfileId, campaignMonitorBPRoleAccessSettings) {
    var data = JSON.stringify({ userId: userId, campaignMonitorBPRoleAccessSettings: campaignMonitorBPRoleAccessSettings });
    var updateCampaignMonitorBPRoleAccessSettings = "/App/AccessManagement/UpdateCampaignMonitorBPRoleAccessSettings";
    var endpoint = updateCampaignMonitorBPRoleAccessSettings.concat(`/${businessProfileId}`);
    const promise = axiosInstance.post(endpoint, data);
    return promise;
  }
}
