<template>
  <Form
    label-position="right"
    :label-width="200"
    :model="config"
    :rules="validationRules"
    ref="twitterConfig"
  >
    <div v-show="showConfigForm">
      <Divider orientation="left">Accounts and Assets</Divider>
      <Form-Item label="Twitter Account" prop="bpseId" :required="true">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.bpseId"
              placeholder="Select an account"
              :transfer="true"
              :disabled="isExistingConfig"
              filterable
            >
              <Option
                v-for="(item, index) in twitterAccounts"
                :key="index"
                :value="item.id"
                :disabled="item.isOauthExpired"
                >{{ item.accountId + " - " + item.accountNickName }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Divider orientation="left">Campaign Settings</Divider>
      <Form-Item label="Campaign Name" prop="campaign.name">
        <Row>
          <Row-Col span="18">
            <i-input :disabled="isNomenclatureEnabled" v-model="config.campaign.name" clearable/>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Objective" prop="campaign.objective">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.campaign.objective"
              placeholder="Select an Objective"
              :transfer="true"
              :disabled="isExistingConfig"
            >
              <OptionGroup label="Awareness">
                <Option
                  v-for="(item, id) in twitterEnums.objective.awareness"
                  :key="parseInt(id)"
                  :value="parseInt(id)"
                  >{{ item }}</Option
                >
              </OptionGroup>
              <OptionGroup label="Consideration">
                <Option
                  v-for="(item, id) in twitterEnums.objective.consideration"
                  :key="parseInt(id)"
                  :value="parseInt(id)"
                  >{{ item }}</Option
                >
              </OptionGroup>
              <OptionGroup label="Conversions">
                <Option
                  v-for="(item, id) in twitterEnums.objective.conversions"
                  :key="parseInt(id)"
                  :value="parseInt(id)"
                  >{{ item }}</Option
                >
              </OptionGroup>
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Funding Source" prop="campaign.fundingSource">
        <Row>
          <Row-Col span="18">
            <Select
              v-model=config.campaign.fundingSource
              placeholder="Select Funding Source"
              :transfer="true"
              :disabled="!fundingSourceFetched"
              filterable
            >
              <Option
                v-for="sources in fundingSources"
                :key="sources.id"
                :value="sources.id"
                >{{ sources.id }} - {{sources.description}} - {{sources.type}}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Pacing" prop="campaign.pacing">
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.campaign.pacing">
              <Radio
                :label="parseInt(id)"
                v-for="(pace, id) in twitterEnums.pacing"
                :key="parseInt(id)"
              >
                <span> {{ pace }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>
      <Divider orientation="left">Adgroup Settings</Divider>
      <Form-Item label="Bid Type" prop="adgroup.bidType">
        <Row>
          <Row-Col span="18">
            <Select
              v-model=config.adgroup.bidType
              placeholder="Select Bid Type"
              :transfer="true"
              filterable
            >
              <Option
                v-for="type in filteredBidType"
                :key="parseInt(type.id)"
                :value="parseInt(type.id)"
                >{{ type.label }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Bid Amount" prop="adgroup.bidAmount" v-if="config.adgroup.bidType === 2 || config.adgroup.bidType === 3">
        <Row>
          <Row-Col span="4">
            <i-input v-model.number="config.adgroup.bidAmount" placeholder="Bid" type="number" clearable >
              <span slot="prepend">{{bpCurrencySymbol}}</span>
              </i-input>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Placement" prop="adgroup.placements">
        <Row>
          <Row-Col>
            <CheckboxGroup v-model="config.adgroup.placements">
              <Checkbox :disabled="id==1" :label="parseInt(id)" v-for="(name, id) in twitterEnums.placements" :key="id">
                <span> {{ name }} </span>
              </Checkbox >
            </CheckboxGroup>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item v-if="config.adgroup.placements.includes(4)" label="TAP Formats" prop="adgroup.tapFormats">
        <Row>
          <Row-Col>
            <CheckboxGroup v-model="config.adgroup.tapFormats">
              <Checkbox  :label="parseInt(id)" v-for="(name, id) in twitterEnums.tapFormats" :key="id">
                <span> {{ name }} </span>
              </Checkbox >
            </CheckboxGroup>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item  v-if="config.adgroup.placements.includes(4)" label="Ad Category" prop="adgroup.adCategory">
        <Row>
          <Row-Col span="18">
            <Select
              v-model=config.adgroup.adCategory
              placeholder="Select Ad Category"
              :transfer="true"
              :disabled="!adCategoryFetched"
              filterable
              multiple
            >
              <Option
                v-for="category in adCategories"
                :key="category.id"
                :value="category.id"
                >{{ category.name }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item  v-if="config.adgroup.placements.includes(4)" label="Website" prop="adgroup.website">
        <Row>
          <Row-Col span="18">
            <i-input
              placeholder="Enter Website URL"
              v-model="config.adgroup.websiteUrl"
              clearable/>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Status" prop="status">
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.status">
              <Radio
                :label="parseInt(id)"
                v-for="(status, id) in twitterEnums.status"
                :key="parseInt(id)"
              >
                <span> {{ status }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>
       <Form-Item label="Configuration Name" prop="name">
        <Row>
          <Row-Col span="18">
            <i-input v-model="config.name" clearable />
          </Row-Col>
        </Row>
      </Form-Item>
    </div>
  </Form>
</template>
<script>
import {
  Divider,
  Row,
  Col,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Option,
  OptionGroup,
  Checkbox,
  CheckboxGroup,
  Input
} from "iview";
import { mapState, mapMutations } from "vuex";
import { APIService } from "./../../ApiService.js";
import { twitterEnums } from "../../Constants/TwitterEnums.js";
import CLHelperMethodsMixin from "../../Mixins/CLHelperMethods.js";

export default {
  props: {
    loadExistingConfigId: { default: "" },
    showConfigForm: { default: false },
    isExistingConfig: { default: false },
    nomenclatureMacro: { default: null }
  },
  components: {
    Divider,
    Row,
    "Row-Col": Col,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Select,
    Option,
    OptionGroup,
    Checkbox,
    CheckboxGroup,
    "i-input": Input
  },
  data () {
    return {
      config: {
        searchEngineTypeId: 6,
        name: "",
        bpseId: 0,
        status: 0,
        campaign: {
          fundingSource: null,
          name: "",
          objective: null,
          pacing: 1
        },
        adgroup: {
          bidType: 1,
          bidAmount: null,
          placements: [1],
          tapFormats: [],
          adCategory: [],
          websiteUrl: ""
        }
      },
      fundingSourceFetched: false,
      adCategoryFetched: false,
      optimizationListPresent: false,
      fundingSources: [],
      adCategories: [],
      filteredBidType: [],
      validationRules: {
        bpseId: [
          {
            validator: (rule, value, callback) => {
              if (this.checkIfValid && (isNaN(value) || value <= 0)) {
                callback(new Error("Please select an account"));
              }
              callback();
            }
          }
        ],
        "campaign.fundingSource": [
          { required: true, message: "Please select a funding source" }
        ],
        "campaign.objective": [
          { required: true, message: "Please select a campaign objective" }
        ],
        "campaign.name": [
          { required: true, message: "Please enter a campaign name" }
        ],
        name: [{ required: true, message: "Please enter a configuration name" }],
        "adgroup.bidAmount": [
          {
            type: "number",
            min: 0.1,
            required: true,
            message: "Please enter a valid bid amount",
            trigger: "change"
          },
          {
            validator: (rule, value, callback) => {
              if (!value && (this.config.bidType == 2 || this.config.bidType == 3)) {
                callback(new Error("Please enter a bid amount"));
              }
              callback();
            }
          }
        ],
        "adgroup.bidType": [
          { required: true, message: "Please select a bid type" }
        ]
      },
      twitterEnums,
      isNomenclatureEnabled: false
    };
  },
  mixins: [CLHelperMethodsMixin],
  mounted () {
    this.loadNomenclatureSettings();
  },
  updated () {
    this.loadNomenclatureSettings();
  },
  watch: {
    loadExistingConfigId (id) {
      if (id) {
        this.config = JSON.parse(this.campaignLauncherConfigs[id].config);
        if (!this.isExistingConfig) {
          this.config.name = this.appendCopyToConfigName(this.config.name)
        }
      }
    },
    "config.bpseId" () {
      if (this.config.bpseId) {
        this.fetchFundingSource(this.config.bpseId);
        this.fetchAdCategory(this.config.bpseId);
      }
    },
    "config.campaign.objective" () {
      if (this.config.campaign.objective) {
        this.setBidType();
      }
    },
    "config.config.bidType" () {
      if (this.config.bidType == 1) {
        this.config.bidAmount = null;
      }
    }
  },
  computed: {
    ...mapState([
      "twitterAccounts",
      "businessProfileId",
      "campaignLauncherConfigs",
      "publisher",
      "deltax",
      "mediaPlanId",
      "selectedMediaPlan",
      "bpCurrencySymbol"
    ])
  },
  methods: {
    ...mapMutations([]),
    getConfig () {
      return this.config;
    },
    resetFields () {
      this.$refs["twitterConfig"].resetFields();
    },
    isTwitterFormValid (saveCallback) {
      this.checkIfValid = true;
      this.$refs["twitterConfig"].validate(valid => {
        if (valid) {
          saveCallback();
        }
        this.checkIfValid = false;
      });
    },
    async fetchFundingSource (bpseId) {
      try {
        let response = await APIService.getTwitterFundingSource(bpseId);
        if (response.data) {
          this.fundingSourceFetched = true;
          this.fundingSources = response.data.data.filter(x => x.able_to_fund == true);
        }
      } catch (err) {
        console.log(err);
        this.fundingSourceFetched = false;
      }
    },
    async fetchAdCategory (bpseId) {
      try {
        let response = await APIService.getTwitterAdCategories(bpseId);
        if (response.data) {
          this.adCategoryFetched = true;
          this.adCategories = response.data.data;
        }
      } catch (err) {
        console.log(err);
        this.adCategoryFetched = false;
      }
    },
    setBidType () {
      if (this.config.campaign.objective) {
        var bidType = this.twitterEnums.objectiveBidTypeMapping[this.config.campaign.objective]
        this.filteredBidType = this.twitterEnums.bidType.filter(function (obj) {
          return bidType.some(function (obj2) {
            return obj.id === obj2;
          });
        });
      }
    },
    loadNomenclatureSettings () {
      if (this.nomenclatureMacro && this.nomenclatureMacro.campaign && this.nomenclatureMacro.campaign != '') {
        this.isNomenclatureEnabled = true;
        this.config.campaign.name = this.nomenclatureMacro.campaign;
      }
    }
  }
};
</script>
<style scoped>
.macro-list > li {
    display: inline-block;
    *display:inline;
    cursor: pointer;
}
.macros {
  padding-right: 10px;
}
</style>
