<template>
<tbody>
  <tr>
    <!-- <td class="col-md-2" v-show="row.lineItemRows" :rowspan="row.lineItemRows">{{ row.name }}</td> -->
    <td class="col-md-4">
      <div v-if="!isCreateMode">
        {{ row.name }}
        <span v-if="row.fractionAlias != '-'" class="grey-text"> - {{ row.fractionAlias }}</span>
        <span v-if="isReachAndFrequencyConfig && isTargetSelected && ifBillingEventNot2SecVideoViews && !isRnFReplanAndConfigSelectedForLaunch" v-on:click="toggleRnfDashboard()" style="float:right">
          <i v-show="!showRnfDashboard" class="fa fa-plus-square fa-lg"></i>
          <i v-show="showRnfDashboard" class="fa fa-minus-square fa-lg"></i>
        </span>
      </div>
      <div class="lineitem-create-settings" v-else-if="row.isFractionEnabled == 0 || row.internalFractionId == 0">
        <span v-show="false">{{ isLineItemUpdated }}</span>
        <Row>
          <Row-Col span="11">
            <Input v-model="row.name" placeholder="Name" style="padding-bottom:15px"/>
          </Row-Col>
          <Row-Col :offset="12" span="1">
            <div class="optionsIcon">
              <a class="fraction-btn" @click="addLineItemFraction">Add Fraction</a>
              <div v-if="isRnfConfigSelectedAndTargetMapped" class="iconContainer">
                <Tooltip content="Reach and Frequency Planner">
                  <span class= "plannerIcon" v-on:click="toggleRnfDashboard()" style="float:right">
                    <i v-show="!showRnfDashboard" class="fa fa-plus-square fa-lg"></i>
                    <i v-show="showRnfDashboard" class="fa fa-minus-square fa-lg"></i>
                  </span>
                </Tooltip>
              </div>
              <div class="iconContainer">
                <i class="fa fa-trash fa-lg trashIcon" v-on:click="removeLineItemRow"></i>
              </div>
              <div class="iconContainer">
                <Icon v-if="isValidLineItem" type="ios-checkmark-circle-outline" class="consider" size="18"/>
                <Icon v-else type="ios-alert-outline" class="skip" size="18" />
              </div>
            </div>
          </Row-Col>
        </Row>
        <Row :gutter="10" class="lineitem-create-settings-row">
          <Row-Col span="3" class="lineitem-create-label">
            Budget:
          </Row-Col>
          <Row-Col span="8">
            <Input type="number" v-model="row.budget" :disabled="isReachAndFrequencyConfig"/>
          </Row-Col>
          <Row-Col span="13" class="lineitem-create-label">
            <RadioGroup v-model="row.budgetType">
              <Radio v-for="type in budgetType.options" :true-value="type.id" :key="type.id" :label="type.id">{{ type.name }}</Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
        <Row :gutter="10" class="lineitem-create-settings-row">
          <Row-Col span="3" class="lineitem-create-label">
            KPI:
          </Row-Col>
          <Row-Col span="13">
            <Select v-model="row.keymetricId" :transfer="true" clearable placeholder="Metric">
              <Option v-for="(metric, index) in mediaPlan.keymetrics"
                :key="index"
                :value="metric.id">
                {{ metric.name }}
              </Option>
            </Select>
          </Row-Col>
          <Row-Col span="8">
            <Input type="number" v-model="row.keymetricValue" placeholder="Metric Value"/>
          </Row-Col>
        </Row>
      </div>
      <div class="lineitem-create-settings" v-else>
        <Row>
          <Row-Col span="11">
            <Input v-model="row.fractionAlias" placeholder="Fraction Alias" style="padding-bottom:15px"/>
          </Row-Col>
          <Row-Col :offset="12" span="1">
            <div class="optionsIcon">
              <div v-if="isRnfConfigSelectedAndTargetMapped" class="iconContainer">
                <Tooltip content="Reach and Frequency Planner">
                  <span class= "plannerIcon" v-on:click="toggleRnfDashboard()" style="float:right">
                    <i v-show="!showRnfDashboard" class="fa fa-plus-square fa-lg"></i>
                    <i v-show="showRnfDashboard" class="fa fa-minus-square fa-lg"></i>
                  </span>
                </Tooltip>
              </div>
              <div class="iconContainer">
                <i class="fa fa-trash fa-lg trashIcon" v-on:click="removeLineItemFraction"></i>
              </div>
            </div>
          </Row-Col>
        </Row>
      </div>
    </td>
    <td class="col-md-4" v-if="row.isFractionEnabled == 1 && row.internalFractionId == 0">
    </td>
    <td class="col-md-4" v-else>
      <Select v-model="selectedCampaignConfig" :disabled="row.isPublished || mapperTriggeredFromElementsView" :transfer="true" filterable>
        <Option
          v-for="(config, index) in getEnabledConfigs"
          :key="index"
          :value="config.Id"
          :disabled="isConfigDisabled(config)"
        >{{ config.Name }}</Option>
      </Select>
      <template v-if="Object.keys(selectedConfigObject).length > 0 && isFacebookAds && isCatalogSupportedForConfig(selectedConfigObject) && selectedConfigObject.campaign.productCatalog">
        <div class="product-set-filter-block">
          <label class="product-set-filter-label">Promoted Product Set</label>
          <RadioGroup
            v-model="selectedPromotedProductSetOption"
          >
            <Radio
              v-for="(item, index) in promotedProductSetOptions"
              :key="index"
              :label="item"
              :disabled="row.isPublished"
            >
            </Radio>
          </RadioGroup>
        </div>
        <template v-if="selectedPromotedProductSetOption == 'Use existing'">
          <span id="alert" v-if="fetchingProductsetsFailed[this.getKeyForProductsetsFailed()]">Failed to load product sets</span>
          <Select
            v-model="selectedPromotedProductSetId"
            :disabled="row.isPublished || existingProductSets.length == 0"
            filterable
          >
            <Option
              v-for="(item, index) in existingProductSets"
              :key="index"
              :value="item.id"
              :label="getProductSetLabel(item)"
            >
            </Option>
          </Select>
        </template>
        <template v-else>
          <Row>
            <Row-Col span="22">
              <product-set-filter
                v-for="(item, index) in newPromotedProductSetFilters"
                :key="index"
                :filter="item"
                :index="index"
                :productSetType="productSetTypes.promoted"
                @filterUpdate="updateFilter"
                @filterRemoval="removeFilter"
              ></product-set-filter>
            </Row-Col>
            <Row-Col span="2">
              <Icon
                type="md-add"
                size="24"
                class="iview-icons add-btn"
                @click="addFilter(productSetTypes.promoted)"
              />
            </Row-Col>
          </Row>
        </template>
        <DpaAudienceFilters
          ref="dpaAudienceFilters"
          v-if="isCatalogSupportedForConfig(selectedConfigObject)"
          :isReadOnlyMode="row.isPublished"
          :audienceType="dpaSettings.selectedAudienceType ? dpaSettings.selectedAudienceType :selectedConfigObject.adset.selectedAudienceType"
          :audienceInclusions="dpaSettings.targetProductAudience.inclusions"
          :audienceExclusions="dpaSettings.targetProductAudience.exclusions">
        </DpaAudienceFilters>
      </template>
    </td>
    <td class="col-md-4" v-if="showTargetsOrLabels && row.isFractionEnabled == 1 && row.internalFractionId == 0">
    </td>
    <td v-else-if="showTargetsOrLabels" class="col-md-4 targeting-select">
      <div v-if="isLabels">
        <div class="col-md-2 no-padding">
          Labels
        </div>
        <div class="col-md-10 no-padding">
          <Select
          :key="1"
          v-model="selectedLabels"
          :multiple="isMultiSelectTarget"
          filterable
          :disabled="row.isPublished && (isLineitemBasedGroupingConfig || publisherNotSupportingLineItemGrouping)"
          :transfer="true"
          >
            <Option
              v-for="(item, index) in labels"
              :key="index"
              :value="item.Id"
            >{{ item.Name }}</Option>
          </Select>
        </div>
      </div>

      <div v-if="!isLabels">
        <div>
          <div class="col-md-2 no-padding" v-if="selectedPublisher == publisher.Facebook || enableNewCampaignLaucherFlow">
            Targets
          </div>
          <div class="col-md-10 no-padding" style="z-index:1">
            <Select
              :key="2"
              v-model="selectedTargets"
              :multiple="isMultiSelectTarget"
              filterable
              :disabled="(row.isPublished && (isLineitemBasedGroupingConfig || publisherNotSupportingLineItemGrouping)) || mapperTriggeredFromElementsView"
              :transfer="true"
            >
              <Option
                v-for="(item, index) in filteredTargets"
                :key="index"
                :value="item.Id"
                :label="item.Name"
              >
              <span class="target-info">
                <!-- <Poptip transfer placement="left-start" trigger="hover" :title = item.Name>
                    <i class="fa fa-info-circle social-target-info-tooltip"></i>
                    <div slot="content">
                          <div v-html="item.generatedTarget"></div>
                    </div>
                  </Poptip> -->
                </span>
                <span v-if="selectedPublisher == publisher.DV360" class="target-name"
                  >{{ item.Name }} ({{
                    "DXT-" + Number.parseInt(businessProfileId).toString(36) + "-" + item.Id
                  }}) <span class="target-type">{{ getTargetTypeString(item) }}</span>
                </span>
                <span v-else class="target-name">{{ item.Name }} ({{'DXT-' + Number.parseInt(businessProfileId).toString(36) + '-' + item.Id}})</span>
                <span v-if="selectedPublisher == publisher.Facebook" class="estimated-reach">
                  <span v-if="item.EstimatedReach && item.EstimatedReach.toString().length > 5" >
                        <i>{{Intl.NumberFormat().format(item.EstimatedReach).toString().slice(0, -4) + 'k'}}</i>
                      </span>
                      <span v-if="item.EstimatedReach && item.EstimatedReach.toString().length<=5">
                        <i>{{Intl.NumberFormat().format(item.EstimatedReach)}}</i>
                      </span>
                </span>
              </Option>
            </Select>
          </div>
        </div>
        <div v-if="showlocations">
          <div class="col-md-2 no-padding" v-if="selectedLocationType != 2">
            Locations
              <span v-if="locationsLoading">
                <i class="fa fa-spinner fa-spin" />
              </span>
          </div>
          <div class="col-md-10 no-padding" v-if="selectedLocationType != 2">
            <Select :id="'LocationSelect' + row.id" v-model="selectedLocations" filterable :transfer="true" :multiple="true"
              :disabled="isStoreSelectionDisabled">
                <Option v-for="item in locations" :key="item[locationKey]" :value="item[locationKey]">{{item.completePath}}</Option>
            </Select>
          </div>
          <div class="col-md-2 no-padding" v-if="selectedLocationType == 2">
            Stores
              <span v-if="locationsLoading">
                <i class="fa fa-spinner fa-spin" />
              </span>
          </div>
          <div class="col-md-10 no-padding" v-if="selectedLocationType == 2" style="margin-top: 10px;z-index: 1;">
            <Row>
              <Row-Col span="22">
                <Select
                  :id="'StoreSelect' + row.id" v-model="selectedLocations"
                  v-if="!storesBeingFetched"
                  :transfer="true" :multiple="true"
                  :max-tag-count="1"
                  :max-tag-placeholder="(value) => { return `+ ${value} more`}"
                  disabled
                  filterable
                >
                  <Option v-for="item in locations" :key="item.storeCode" :value="item.storeCode">{{item.name}}</Option>
                </Select>
              </Row-Col>
              <Row-Col span="2">
                <button
                  v-if="!storesBeingFetched"
                  :disabled="isStoreSelectionDisabled"
                  class="btn btn-success btn-sm add-store-btn"
                  @click="showMultiselectAndFiltering"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </Row-Col>
            </Row>
          </div>
        <div>
        <div v-if="(showStoreFiltering && selectedLocationType == 2 && selectedTargets.length) || (selectedLocations.length)" class="bulkSelection">
          <Row style="z-index:1;">
            <Row-Col span="10">
                <span> Selection Type</span>
              </Row-Col>
              <Row-Col span="14">
                <RadioGroup v-model="storeSelectionType">
                  <Radio :label="0">Bulk</Radio>
                  <Radio :label="1">List</Radio>
                </RadioGroup>
              </Row-Col>
          </Row>
              <Input
                style="width: 100%"
                v-model="filterStoreParams"
                type="textarea"
                :autosize="{minRows: 2}"
                :placeholder=storeFilterMessage
                v-show="storeSelectionType==0"
              />

            <Row style="margin-top:15px" v-show="storeSelectionType==0">
              <Row-Col :span="14" >
                <Row>
                  <Row-Col span="8">Input Type:</Row-Col>
                  <Row-Col span="16">
                    <Select v-model="selectedFilterType" :clearable="false" style="width: 120px; margin-left: 5px;">
                      <Option v-for="item in fbEnums.storeFilterOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                  </Row-Col>
                </Row>
              </Row-Col>

              <Row-Col :span="10">
                <Row>
                  <div style="float:right">
                    <Row-Col span="12">
                      <Button  class="filterButton" @click="clearFilterQuery()">Clear</Button>
                    </Row-Col>
                    <Row-Col span="12">
                      <Button class="filterButton" type="success" @click="applyStores()">
                        <span id="applyText">Apply</span>
                    </Button>
                    </Row-Col>
                  </div>
                </Row>
              </Row-Col>
          </Row>
         </div>
         <div v-if="isMultiselectLoading" style="text-align: center">
          <i class="fa fa-spinner fa-spin fa-2x" />
        </div>
         <div
          v-if="showStoreSummary"
          class="alert alert-warning col-md-10 no-padding alert-header"
          role="alert"
        >
          <table class="full-width-table">
            <thead>
              <tr>
                <th>Store Selection Summary</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(message, index) in tableValueArray" :key="index">
                <td>{{ message[0] }}:</td>
                <td @click="copyUnfetchedStores">
                  <span v-html="message[1]"></span>
                </td>
              </tr>
            </tbody>
          </table>
          <button type="button" class="close" id="tableCloseButton" @click="closePromoAlert">
            ×
          </button>
        </div>
            <multi-select-list
              ref="storeSelect"
              v-if="renderMultiSelect && !isStoreSelectionDisabled"
              :selectedEntityList.sync="multiSelectArray"
              :entityList="storeSelectOject"
              :filteredEntityIds="filteredStoreIds"
              :selectedFilterCount="selectedlabelIds.length"
              :isCampaignLauncher="true"
              @dx-mulitiselect-select-clicked="renderMultiSelect = false"
              style="margin-left: 17%;"
              >
              <template v-slot:filter-block>
                <ul v-if="labelDimentionFilter.length > 0" class="list-group filterListGroup">
                  <span class="disabled" v-for="ld in labelDimentionFilter" :key="ld.id">
                  <li class="list-group filterListGroup-name bold-text selected-stores" v-if="ld.labels.length > 0">
                    {{ld.name}}
                  </li>
                    <span class="disabled" v-for="label in ld.labels" :key="label.id">
                      <li class="list-group-item selected-stores filterListGroup-item">
                        <Checkbox style="width:100%" v-model="label.isSelected" @on-change="setFilteredStoreIds"> {{label.name}} </Checkbox>
                      </li>
                    </span>
                  </span>
                </ul>
                <p v-else title="No Filters Available" class="selected-stores noLabelText filterListGroup-item"> No Filters Available </p>
              </template>
              <template v-slot:select-list-group="slotProps">
                <span class="bold-text disabled">{{ slotProps.entity.name }} </span>
              </template>
              <template v-slot:select-list-group-child="slotProps">
                <span class="disabled">
                {{ slotProps.childEntity.effectiveName }}
                <span class="label-container disabled" style="display: inline-block;" v-show="alreadyMappedStores.length && !alreadyMappedStores.includes(slotProps.childEntity.storeCode) && storeSelectionType == 0">
                  <div class="label label-item store-labels-selector" style="background-color: rgb(134, 170, 228)"> new </div>
                </span>
                </span>
                <span class="pull-right label-container disabled">
                  <div class="label label-item store-labels-selector" v-for="label in filterStoreNameLabels(slotProps.childEntity.labels, slotProps.childEntity.name, slotProps.childEntity.storeCode, slotProps.parentEntity.name)" :key="label.id" :style="getLabelBackground(label.colorCode)"> {{label.name}} </div>
                </span>
              </template>
            </multi-select-list>
          </div>
        </div>
        <div v-if="Object.keys(selectedConfigObject).length > 0 && isStoreVisitsObjective(selectedConfigObject) && selectedConfigObject.campaign.locationMode == fbEnums.locationMode.PageSets">
          <div class="col-md-2 no-padding">
            Page Sets
          </div>
          <div class="col-md-10 no-padding">
            <Select v-model="selectedPageSets" :disabled="row.isPublished" :transfer="true" filterable multiple>
                <Option
                  v-for="(pageSet, index) in allPageSets"
                  :key="index"
                  :value="pageSet.id"
                >{{ pageSet.name }}</Option>
              </Select>
          </div>
        </div>
      </div>
      <template v-if="Object.keys(selectedConfigObject).length > 0 && isFacebookAds && selectedConfigObject.campaign && isDPAObjective(selectedConfigObject) && selectedConfigObject.campaign.productCatalog">
        <div class="product-set-filter-block">
          <label class="product-set-filter-label">Target Product Set</label>
          <RadioGroup
            v-model="selectedTargetProductSetOption"
          >
            <Radio
              v-for="(item, index) in targetProductSetOptions"
              :key="index"
              :label="item"
              :disabled="row.isPublished"
            ></Radio>
          </RadioGroup>
        </div>
        <template v-if="selectedTargetProductSetOption == 'Use existing'">
          <Select
            v-model="selectedTargetProductSetId"
            :disabled="row.isPublished || existingProductSets.length == 0"
            filterable
          >
            <Option
              v-for="(item, index) in existingProductSets"
              :key="index"
              :value="item.id"
              :label="getProductSetLabel(item)"
            ></Option>
          </Select>
        </template>
        <template v-else-if="selectedTargetProductSetOption == 'Create a new set'">
          <Row>
            <Row-Col span="22">
              <product-set-filter
                v-for="(item, index) in newTargetProductSetFilters"
                :key="index"
                :filter="item"
                :index="index"
                :productSetType="productSetTypes.target"
                @filterUpdate="updateFilter"
                @filterRemoval="removeFilter"
              ></product-set-filter>
            </Row-Col>
            <Row-Col span="2">
              <Icon
                type="md-add"
                size="24"
                class="iview-icons add-btn"
                @click="addFilter(productSetTypes.target)"
              />
            </Row-Col>
          </Row>
        </template>
      </template>
    </td>
    <td v-if="selectedPublisher == publisher.AMS.SponsoredProducts ||
        selectedPublisher == publisher.AMS.SponsoredBrands ||
        selectedPublisher == publisher.AMS.SponsoredDisplay">
        <Select
          v-model="row.targetTypeId"
          style="width: 100px">
            <Option
                v-for="(name,id) in amsEnums.targetTypes"
                :disabled="name == amsEnums.targetTypes.ASINS"
                :key="name"
                :value="name"
                >{{ id }}
            </Option>
        </Select>
        <template v-if="row.targetTypeId==amsEnums.targetType.Cluster">
          <Select
          v-model="selectedTargets"
          filterable
          :transfer="true"
          :multiple="isMultiSelectTarget"
          style="width: 310px">
            <Option
            v-for="(item, index) in targets"
            :value="item.id"
            :label="item.name"
            :key="index">
              {{ item.name }}
            </Option>
          </Select>
        </template>
        <template v-if="row.targetTypeId==amsEnums.targetType.ASIN">
          <Input
          v-model="amsAsins"
          placeholder="Enter comma sepereted values"
          style="width: 310px"
          clearable/>
        </template>
    </td>
  </tr>
  <template v-if="showRnfDashboard && isReachAndFrequencyConfig && ifBillingEventNot2SecVideoViews">
    <template v-for="(rnfPlanner, index) in rnfPlanners">
      <tr :key="index + 'tr'">
        <td colspan="3" class="rnf-dashboard">
          {{ getTargetName(rnfPlanner.targetId) }}
          <i :class="showRnfByTarget[rnfPlanner.targetId] ? 'fa fa-minus-square fa-lg' : 'fa fa-plus-square fa-lg'"
          v-on:click="toggleRnfByTarget(rnfPlanner.targetId)"/>
        </td>
      </tr>
      <rnf-planner
        :key="index + 'rnf'"
        :budget="lineitemBudget"
        :netPlannedBudget="rnfPlanner.netPlannedBudget"
        :buffer="rnfPlanner.buffer"
        :configId="selectedCampaignConfig"
        :targetId="rnfPlanner.targetId"
        :index="index"
        :show="showRnfByTarget[rnfPlanner.targetId]"
        :schedule="rnfPlanner.schedule"
        :frequencyBalance="rnfPlanner.frequencyBalance"
        :placements="rnfPlanner.placements"
        :placementMode="rnfPlanner.placementMode"
        :adFormats="rnfPlanner.adFormats"
        :predictionId="rnfPlanner.predictionId"
        :isCreateMode="isCreateMode"
        @newRnfPrediction="setNewRnfPrediction"
      />
    </template>
  </template>
</tbody>
</template>
<script>
import { Select, Option, Row, Col, Icon, Input, RadioGroup, Radio, Tooltip, Checkbox, Button } from "iview";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import RnfPlanner from "./RnfPlanner.vue";
import DpaAudienceFilters from "./Facebook/DpaAudienceFilters.vue";
import targetHTMLGenerator from "../../../Mixins/targetStringToHTML.js";
import ProductSetFilter from "./ProductSetFilter.vue";
import { APIService } from "../ApiService.js";
import * as fbEnums from "../Constants/FacebookEnums.js";
import { EventBus } from "../EventBus.js";
import { dbmEnums } from "../Constants/DbmEnums.js";
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js"
import * as adwordsConstants from "../Constants/AdwordsEnums.js";
import MultiSelectList from "../../../../DeltaXComponents/dx-multiselect.vue";
import BulkStoreSelectionHelper from '../../../Mixins/bulkStoreSelectionHelper';
import mixins from "../../CampaignLauncher/Mixins/CopyToClipboard.js";

export default {
  mixins: [targetHTMLGenerator, FacebookCampaignLauncherConfig, BulkStoreSelectionHelper, mixins],
  components: {
    Select,
    Option,
    RnfPlanner,
    Input,
    Row,
    "Row-Col": Col,
    Icon,
    "product-set-filter": ProductSetFilter,
    RadioGroup,
    Radio,
    Tooltip,
    Checkbox,
    DpaAudienceFilters,
    MultiSelectList,
    Button
  },
  props: {
    index: {
      type: Number
    },
    row: {
      type: Object,
      default: () => { return {} }
    },
    campaignLauncherConfigurations: {
      type: Array,
      default: () => { return {} }
    },
    isFacebookAds: {
      type: Boolean,
      default: true
    },
    isCreateMode: {
      type: Boolean,
      default: false
    },
    mediaPlan: {
      type: Object
    },
    mapperTriggeredFromElementsView: {
      type: Boolean,
      default: false
    },
    isRefreshAmsTargets: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedLocations: [],
      locations: [],
      allLocations: [],
      locationsLoading: false,
      isShowMoreEnabled: true,
      selectedTargets: [],
      clusterList: [],
      targetsInfo: [],
      selectedLabels: [],
      selectedCampaignConfig: "",
      targets: [],
      isMultiSelectTarget: true,
      isLabels: false,
      selectedConfigObject: {},
      rnfPlanners: [],
      unwatch: {},
      targetTypeId: 1,
      amsAsins: "",
      budgetType: {
        id: 0,
        name: "",
        options: []
      },
      keymetric: {
        id: "",
        value: ""
      },
      budget: "",
      dpaSettings: {
        promotedProductSet: {
          new: null,
          selected: {
            Id: 0
          },
          option: "Use existing",
          filters: []
        },
        targetProductSet: {
          new: null,
          selected: {
            Id: 0
          },
          option: "Same as promoted set",
          filters: []
        },
        targetProductAudience: {
          inclusions: [],
          exclusions: []
        }
      },
      selectedPromotedProductSetId: 0,
      selectedTargetProductSetId: 0,
      promotedProductSetOptions: ["Use existing", "Create a new set"],
      targetProductSetOptions: ["Same as promoted set", "Use existing", "Create a new set"],
      selectedPromotedProductSetOption: "Use existing",
      selectedTargetProductSetOption: "Same as promoted set",
      existingProductSets: [],
      productSetTypes: {
        promoted: 1,
        target: 2
      },
      newPromotedProductSetFilters: [],
      newTargetProductSetFilters: [],
      fbEnums,
      selectedPageSets: [],
      allPageSets: [],
      selectedLocationType: fbEnums.locationType.Standard,
      showRnfDashboard: false,
      showRnfByTarget: {},
      renderMultiSelect: false,
      storeSelectOject: [],
      filteredStoreIds: [],
      selectedlabelIds: [],
      labelDimentions: [],
      labelDimentionFilter: [],
      multiSelectArray: [],
      storesBeingFetched: false,
      storeFilterMessage: "Enter Advertiser Ids",
      enabledStores: [],
      alreadyMappedStores: []
    };
  },
  methods: {
    ...mapMutations([
      "set_fetchingProductsetsFailed",
      "set_savedTargetsMapperByBpseId",
      "set_savedTargets",
      "set_promoAlertMessages",
      "set_bulkApplyCalledForLineItemId",
      "set_allStoresAlreadyFetched"
    ]),
    ...mapActions([
      'getLabelsTypesById'
    ]),
    async refreshTargets () {
      let targets = await APIService.getApiData(
        this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/Targets/ProcessedTargets"
      )
      targets = targets.data.data;
      targets.forEach(x => {
        x.Id = x.id
        x.BpseId = x.bpseId
        x.Name = x.name
        x.EstimatedReach = x.estimatedReach || 0
        x.Type = x.type
        x.CreationType = x.creationType
        x.TargetInformation = x.targetInformation
      });
      this.targets = targets
      this.$emit("updateIsRefreshAmsFlag");
    },
    setStoreData () {
      var self = this;
      let mappedLocations = self.tempMappedLocations;
      let temp = []
      if (this.selectedLocationType == fbEnums.locationType.Hyperlocal) {
        temp = self.locations.filter(x => self.row.locations && self.row.locations.includes(x.storeCode.toString()))
      } else {
        temp = self.locations.filter(x => self.row.locations && self.row.locations.includes(x.id.toString()))
      }
      temp.forEach(element => {
        element.rowId = self.row.id
      });
      if (mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] == null) {
        mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] = temp
      } else {
        // this condition is to handle other line items mapped to the same config.
        let otherRowLocations = mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config].filter(x => x.rowId != self.row.id);
        mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] = temp.concat(otherRowLocations)
      }
      self.$store.commit("set_tempMappedLocations", mappedLocations)
      self.$store.commit("set_savedMappedLocations", mappedLocations)
      self.$store.commit("set_mappedLocationsChanged", ++self.$store.state.mappedLocationsChanged)
    },
    showMore (value) {
      this.isShowMoreEnabled = true;
      var searchQuery = value
      if (searchQuery.length > 2) {
        this.locationsLoading = true;
        let bpseId = this.campaignLauncherConfigBpseIdMapper[this.selectedCampaignConfig].BpseId;
        var type = this.selectedPublisher == this.publisher.Facebook ? 'adgeolocation' : ''
        var limit = this.selectedPublisher == this.publisher.Facebook ? 25 : null;
        const fetchByCountry = this.selectedConfigObject.campaign && this.selectedConfigObject.campaign.isAutomatedAppAdsEnabled == true;
        APIService.getApiLocations(bpseId, searchQuery, type, limit, fetchByCountry)
          .then(response => {
            var locationsReponse = response.data.data;
            this.updateLocations(locationsReponse);
            this.locationsLoading = false;
          })
          .catch(() => {
            this.$Message.error({ background: true, content: "Error while fetching locations." });
            this.locationsLoading = false
          });
      }
    },
    updateLocations (locations) {
      if (this.selectedPublisher == this.publisher.Facebook || this.selectedPublisher == this.publisher.Adwords) {
        if (this.locationKey == "storeCode") {
          return
        }
        locations.forEach(elem => {
          if (!elem.facebookKey) {
            elem.facebookKey = elem.key
          }
          if (!elem.completePath) {
            elem["completePath"] = [elem["name"], elem["region"], elem["country_name"], elem["type"]].filter(e => e).join(",");
          }
        });
      }
      var keys = this.locations.map(x => x[this.locationKey]);
      locations.forEach(elem => {
        if (!keys.includes(elem[this.locationKey])) {
          this.locations.push(elem)
          keys.push(elem[this.locationKey])
        }
      });
    },
    locationOnQueryChange (value) {
      if (this.isShowMoreEnabled) {
        this.showMore(value)
      }
    },
    getTargetName (targetId) {
      var target = this.targets.find(t => t.Id == targetId)
      return target ? target.Name : ""
    },
    getLineItemId () {
      return this.isCreateMode ? this.row.internalId : this.row.id;
    },
    getLineItemFractionId () {
      return this.isCreateMode ? this.row.internalFractionId : this.row.fractionId;
    },
    async selectedCampaignConfigWatch () {
      if (!this.selectedCampaignConfig) { return; }
      if (this.selectedPublisher == this.publisher.Adwords) {
        if (this.deltax.isAdwordsCampaignLaucherFlow) {
          this.targets = [];
          this.selectedTargets = [];
          this.selectedLocations = [];
          this.alreadyMappedStores = [];
          this.multiSelectArray = [];
          this.targets = this.savedTargetsMapperByBpseId[this.campaignLauncherConfigBpseIdMapper[this.selectedCampaignConfig].BpseId];
          this.$emit("targetsUpdated", { targets: this.selectedTargets, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() });
          this.$emit("locationsUpdated", { locations: this.selectedLocations, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
          this.selectedConfigObject = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignConfig].config);
          this.selectedLocationType = adwordsConstants.adwordsEnums.locationType.Standard;
          if (this.selectedConfigObject && this.selectedConfigObject.campaign) {
            switch (this.selectedConfigObject.campaign.locationMode) {
              case adwordsConstants.adwordsEnums.locationMode.Hyperlocal:
                this.selectedLocationType = adwordsConstants.adwordsEnums.locationType.Hyperlocal;
                break;
              default:
                this.selectedLocationType = adwordsConstants.adwordsEnums.locationType.Standard;
                break;
            }
          }
        }
        this.$emit("configUpdated", { config: this.selectedCampaignConfig, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId(), isReachAndFrequencyConfig: false })
      } else if (this.selectedPublisher == this.publisher.Facebook) {
        this.targets = [];
        this.selectedLabels = [];
        this.selectedTargets = [];
        this.selectedLocations = [];
        this.alreadyMappedStores = [];
        this.multiSelectArray = [];
        this.selectedConfigObject = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignConfig].config);
        this.selectedLocationType = fbEnums.locationType.Standard;
        if (this.selectedConfigObject && this.selectedConfigObject.campaign) {
          switch (this.selectedConfigObject.campaign.locationMode) {
            case fbEnums.locationMode.Hyperlocal:
              this.selectedLocationType = fbEnums.locationType.Hyperlocal;
              break;
            case fbEnums.locationMode.PageSets:
              this.selectedLocationType = fbEnums.locationType.PageSets;
              break;
            default:
              this.selectedLocationType = fbEnums.locationType.Standard;
              break;
          }
        }
        this.isLabels = this.selectedConfigObject.adset.targetSelection === "By Label";
        let bpseId = this.campaignLauncherConfigBpseIdMapper[this.selectedCampaignConfig].BpseId;
        if (!this.isLabels) {
          let bpseTargets = this.savedTargetsMapperByBpseId[bpseId];
          this.targets = bpseTargets;
        }
        if (this.isReachAndFrequencyConfig) {
          this.setRnfPlannerData();
        }
        if (this.selectedConfigObject.campaign && this.isCatalogSupportedForConfig(this.selectedConfigObject) && this.selectedConfigObject.campaign.productCatalog) {
          this.dpaSettings.targetProductAudience.inclusions = this.selectedConfigObject.adset.targetProductAudience.inclusions;
          this.dpaSettings.targetProductAudience.exclusions = this.selectedConfigObject.adset.targetProductAudience.exclusions;
          this.fetchExistingProductSets(this.selectedConfigObject.campaign.productCatalog, this.selectedConfigObject.bpseId);
        }
        if (this.selectedConfigObject.campaign && (this.isStoreVisitsObjective(this.selectedConfigObject))) {
          var empty = this.selectedLocationType != fbEnums.locationType.PageSets;
          this.fetchPageSets(this.selectedConfigObject.bpseId, this.selectedConfigObject.facebookPageId, empty);
        }
        this.$emit("configUpdated", { config: this.selectedCampaignConfig, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId(), isReachAndFrequencyConfig: this.isReachAndFrequencyConfig })
        if (this.isLabels) {
          this.$emit("labelsUpdated", { targets: this.selectedLabels, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
        } else {
          this.$emit("targetsUpdated", { targets: this.selectedTargets, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
          this.$emit("locationsUpdated", { locations: this.selectedLocations, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
          this.$emit("locationsListUpdated", { locationsList: [], lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
        }
      } else if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts || this.selectedPublisher == this.publisher.AMS.SponsoredBrands || this.selectedPublisher == this.publisher.AMS.SponsoredDisplay) {
        this.targets = [];
        this.amsAsins = "";
        this.selectedTargets = [];
        var amsAsins = []
        this.selectedConfigObject = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignConfig].config);
        if (this.selectedConfigObject.searchEngineTypeId == 12) {
          await APIService.getTargetsCluster(this.amsEnums.targetType.Cluster)
            .then(response => {
              var targetsDetails = response;
              for (var i = 0; i < targetsDetails.length; i++) {
                if ((targetsDetails[i].logo && targetsDetails[i].logo != "") || (targetsDetails[i].logo && targetsDetails[i].logo != null)) {
                  if ((targetsDetails[i].headline && targetsDetails[i].headline != "") || (targetsDetails[i].headline && targetsDetails[i].headline != null)) {
                    this.targets.push(targetsDetails[i])
                  }
                }
              }
            });
        } else {
          var amsTargets = this.filterTargetsForAMS(this.savedTargets, this.amsEnums.targetType.Cluster)
          this.targets = amsTargets
        }
        this.$emit("configUpdated", { config: this.selectedCampaignConfig, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId(), isReachAndFrequencyConfig: false })
        this.$emit("targetsUpdated", { targets: this.selectedTargets, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
        this.$emit("asinsUpdated", { asins: amsAsins, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
      } else if (this.publisherNotSupportingLineItemGrouping) {
        this.targets = [];
        this.selectedTargets = [];
        this.selectedConfigObject = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignConfig].config);
        this.targets = this.savedTargetsMapperByBpseId[this.campaignLauncherConfigBpseIdMapper[this.selectedCampaignConfig].BpseId];
        if (this.selectedPublisher == this.publisher.DV360) {
          this.targets = this.targets.filter(target => {
            let creativeTypes = this.selectedConfigObject.creativeType.map(Number);
            let liType = this.selectedConfigObject.adGroup.type;
            if (target.Type == 4) {
              if (liType == dbmEnums.lineItemType['Website Conversions']) {
                return target.CreationType == 2;
              }
              return creativeTypes.includes(dbmEnums.creativeType.Youtube);
            }
            return [dbmEnums.creativeType.Display].some(x => creativeTypes.includes(Number(x)));
          })
        }
        this.isMultiSelectTarget = true
        this.$emit("configUpdated", { config: this.selectedCampaignConfig, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
        this.$emit("targetsUpdated", { targets: this.selectedTargets, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
      }
      this.closePromoAlert()
    },
    fetchExistingProductSets (productCatalogId, bpseId) {
      let self = this;
      APIService.getProductSets(this.businessProfileId, bpseId, productCatalogId)
        .then(response => {
          self.existingProductSets = response.data;
        })
        .catch(() => {
          var lineItemId = this.getLineItemId();
          if (self.getLineItemFractionId()) {
            var lineItemFractionId = this.getLineItemFractionId();
            this.set_fetchingProductsetsFailed({ lineItemId: `${lineItemId}_${lineItemFractionId}`, value: true });
          } else {
            this.set_fetchingProductsetsFailed({ lineItemId: `${lineItemId}`, value: true });
          }
        });
    },
    fetchPageSets (bpseId, facebookPageId, empty) {
      APIService.getPageSets(bpseId, [facebookPageId], empty)
        .then(response => {
          this.allPageSets = response.data.data.filter(ps => ps.name);
          if (empty) {
            if (this.allPageSets) {
              var emptyPageSet = this.allPageSets[0]
              this.$emit("updatedPageSetSettings", { pageSetSettings: { pageSets: [emptyPageSet] }, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() });
            } else {
              this.createEmptyPageSet(bpseId, 'Empty Page Set - DX', facebookPageId)
            }
          }
        })
        .catch(() => {
          this.allPageSets = [];
          this.$Message.error({ background: true, content: "Error while fetching page sets." });
        });
    },
    createEmptyPageSet (bpseId, pageSetName, facebookPageId) {
      APIService.createPageSets(bpseId, pageSetName, facebookPageId)
        .then(response => {
          var emptyPageSet = [
            {
              id: response.data.data,
              name: pageSetName,
              pages_count: 0,
              parent_page: {
                id: facebookPageId
              }
            }
          ];
          this.$emit("updatedPageSetSettings", { pageSetSettings: { pageSets: [emptyPageSet] }, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() });
        })
        .catch(() => {
          this.$Message.error({ background: true, content: "Error creating empty page set." });
        });
    },
    getProductSetLabel (productSet) {
      return `${productSet.name} (${productSet.product_count} Products)`
    },
    updateTargetProductSet () {
      if (this.dpaSettings.targetProductSet.option == "Same as promoted set") {
        this.dpaSettings.targetProductSet.new = this.dpaSettings.promotedProductSet.new;
        this.dpaSettings.targetProductSet.selected = this.dpaSettings.promotedProductSet.selected;
      }
    },
    selectedLabelsWatch () {
      this.$emit("labelsUpdated", { labels: this.selectedLabels, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
    },
    selectedTargetsWatch () {
      let campaignConfig = this.campaignLauncherConfigs[this.selectedCampaignConfig] ? JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignConfig].config) : null
      if (campaignConfig && campaignConfig.campaign && campaignConfig.campaign.isAdvantagePlusShoppingCampaign) {
        this.$emit("targetsUpdated", { targets: [this.selectedTargets], lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
      } else {
        this.$emit("targetsUpdated", { targets: this.selectedTargets, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
      }
      if (this.isReachAndFrequencyConfig) {
        this.setRnfPlannerData();
      }
      if (this.selectedTargets == null || this.selectedTargets.length == 0) this.renderMultiSelect = false;
    },
    selectedLocationsWatch () {
      if (this.locationKey == "storeCode") {
        this.allLocations = this.locations ? this.locations : [];
      } else {
        this.allLocations = this.locations.concat(this.locations.filter(x => this.selectedLocations.includes(x[this.locationKey])))
      }

      let mappedLocations = this.tempMappedLocations;
      if (mappedLocations == null) {
        mappedLocations = {}
      }
      if (!this.isCreateMode) {
        let temp = this.allLocations.filter(x => this.selectedLocations.includes(x[this.locationKey]))
        temp.forEach(element => {
          element.rowId = this.row.id
        });
        if (mappedLocations[this.$store.state.selectedMediaPlan.id + '-' + this.row.config] == null) {
          mappedLocations[this.$store.state.selectedMediaPlan.id + '-' + this.row.config] = temp
        } else {
          // JB OPEN: HANDLE OTHER ROWS / MIXTURE OF LOCATIONS AND STORES / CAN BACKEND HANDLE IT?
          let otherRowLocations = mappedLocations[this.$store.state.selectedMediaPlan.id + '-' + this.row.config].filter(x => x.rowId != this.row.id);
          mappedLocations[this.$store.state.selectedMediaPlan.id + '-' + this.row.config] = temp.concat(otherRowLocations)
        }
      } else {
        let temp = this.allLocations.filter(x => this.selectedLocations.includes(x[this.locationKey]))
        temp.forEach(element => {
          element.rowId = this.row.id
        });
        if (mappedLocations['new-' + this.row.config] == null) {
          mappedLocations['new-' + this.row.config] = temp
        } else {
          let otherRowLocations = mappedLocations['new-' + this.row.config].filter(x => x.rowId != this.row.id);
          mappedLocations['new-' + this.row.config] = temp.concat(otherRowLocations)
        }
      }
      this.$store.commit("set_tempMappedLocations", mappedLocations)

      this.$emit("locationsUpdated", { locations: this.selectedLocations, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
      if (this.selectedLocations.length && this.isHyperlocalFlow && this.showStoreSummary) {
        this.setSummaryMeassageForMultiselect()
      }
      if (this.selectedLocationType != fbEnums.locationType.Hyperlocal) {
        var locationsList = this.locations.filter(x => this.selectedLocations.includes(x[this.locationKey]));
        this.$emit("locationsListUpdated", { locationsList, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
      }
    },
    selectedAsinsWatch () {
      this.$emit("asinsUpdated", { asins: this.amsAsins.split(","), lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() })
    },
    toggleRnfDashboard () {
      this.showRnfDashboard = !this.showRnfDashboard;
    },
    toggleRnfByTarget (targetId) {
      this.$set(this.showRnfByTarget, targetId, !this.showRnfByTarget[targetId])
    },
    isConfigDisabled (config) {
      let configJson = JSON.parse(config.ConfigJson)
      if (configJson.campaign && configJson.campaign.isAdvantagePlusShoppingCampaign) {
        return configJson.campaign.isAdvantagePlusShoppingCampaign && (this.row.isFractionEnabled == 1 || (this.row.fractionAlias != '-' && this.row.fractionAlias != ""))
      }
      return false
    },
    getSocialTargets (bpseTargets) {
      var self = this
      if (bpseTargets && bpseTargets.length > 0) {
        for (var i = 0; i < bpseTargets.length; i++) {
          bpseTargets[i]["generatedTarget"] = self.generateTargetStringHtml(
            JSON.parse(bpseTargets[i]["TargetInformation"]),
            false,
            false
          );
        }
        this.targets = bpseTargets;
      }
    },
    getRnfPlanner () {
      return {
        show: false,
        schedule: {
          start: "",
          end: ""
        },
        frequencyBalance: {
          cap: "",
          days: ""
        },
        placements: [],
        adFormats: [],
        predictionId: 0,
        budget: 0
      }
    },
    setRnfPlannerData () {
      if (this.ifBillingEventNot2SecVideoViews) {
        var rnfPlannersNew = []
        for (var targetId of this.row.targets) {
          var rnfPlanner = this.getRnfPlanner();
          var rnfByTargets = {}
          var reachAndFrequencyDataByTarget = this.row.reachAndFrequencyData.find(rnf => rnf.targetId == targetId)
          if (reachAndFrequencyDataByTarget && reachAndFrequencyDataByTarget.reachAndFrequencySettings) {
            var settings = reachAndFrequencyDataByTarget.reachAndFrequencySettings;
            rnfPlanner.schedule.start = settings.startDate;
            rnfPlanner.schedule.end = settings.endDate;
            rnfPlanner.budget = Number(settings.budget);
            rnfPlanner.netPlannedBudget = Number(settings.budget)
            rnfPlanner.buffer = settings.buffer;
            rnfPlanner.frequencyBalance.cap = settings.frequencyCap;
            rnfPlanner.frequencyBalance.days = settings.frequencyInterval;
            rnfPlanner.placementMode = settings.placementMode;
            rnfPlanner.placements = settings.placements;
            rnfPlanner.adFormats = settings.adformat;
            rnfPlanner.predictionId = settings.id;
            rnfPlanner.targetId = targetId;
          } else {
            rnfPlanner.schedule.start = this.row.startDate;
            rnfPlanner.schedule.end = this.row.endDate;
            rnfPlanner.budget = Number(this.row.budget) / this.row.targets.length;
            rnfPlanner.buffer = 0;
            rnfPlanner.netPlannedBudget = 0
            rnfPlanner.frequencyBalance.cap = this.selectedConfigObject.adset.reachAndFrequencyBalance.cap;
            rnfPlanner.frequencyBalance.days = this.selectedConfigObject.adset.reachAndFrequencyBalance.days;
            rnfPlanner.placements = this.selectedConfigObject.adset.placements;
            rnfPlanner.placementMode = this.selectedConfigObject.adset.selectedPlacementOption;
            rnfPlanner.adFormats = this.selectedConfigObject.adset.adformat;
            rnfPlanner.predictionId = reachAndFrequencyDataByTarget && reachAndFrequencyDataByTarget.reachAndFrequencyPredictionId ? reachAndFrequencyDataByTarget.reachAndFrequencyPredictionId : 0;
            rnfPlanner.targetId = targetId;
          }
          rnfPlannersNew.push(rnfPlanner)
          rnfByTargets[targetId] = false;
        }
        this.rnfPlanners = rnfPlannersNew;
        this.showRnfByTarget = rnfByTargets
      }
    },
    setNewRnfPrediction (newRnfPrediction) {
      var targetId = newRnfPrediction.targetId;
      var reachAndFrequencyDataByTarget = this.row.reachAndFrequencyData.find(rnf => rnf.targetId == targetId)
      if (!reachAndFrequencyDataByTarget) {
        reachAndFrequencyDataByTarget = { targetId: targetId };
        this.row.reachAndFrequencyData.push(reachAndFrequencyDataByTarget);
      }
      reachAndFrequencyDataByTarget.reachAndFrequencySettings = newRnfPrediction.reachAndFrequencySettings;
      reachAndFrequencyDataByTarget.reachAndFrequencyPredictionId = newRnfPrediction.reachAndFrequencyPredictionId;
      var rnfPlanner = this.rnfPlanners.find(rnf => rnf.targetId == targetId)
      var settings = reachAndFrequencyDataByTarget.reachAndFrequencySettings;
      rnfPlanner.schedule.start = settings.startDate;
      rnfPlanner.schedule.end = settings.endDate;
      rnfPlanner.budget = Number(settings.budget);
      rnfPlanner.netPlannedBudget = Number(settings.budget)
      rnfPlanner.buffer = settings.buffer;
      rnfPlanner.frequencyBalance.cap = settings.frequencyCap;
      rnfPlanner.frequencyBalance.days = settings.frequencyInterval;
      rnfPlanner.placementMode = settings.placementMode;
      rnfPlanner.placements = settings.placements;
      rnfPlanner.adFormats = settings.adformat;
      rnfPlanner.predictionId = settings.id;
      this.$emit("setNewRnfPredictions", { reachAndFrequencyData: this.row.reachAndFrequencyData, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() });
    },
    teardownWatchers () {
      Object.values(this.unwatch).forEach(unwatchMethod => {
        unwatchMethod();
      });
    },
    getAmsDetails () {
      var asins = []
      if (this.amsAsins && this.row.targetTypeId == this.amsEnums.targetType.ASIN) {
        asins = this.amsAsins.split(",");
      }
      return { "targetType": this.row.targetTypeId, asins }
    },
    getLineItemScheduleInDays (start, end) {
      const ONE_DAY = 1000 * 60 * 60 * 24;
      var startDate = new Date(start);
      var endDate = new Date(end);
      const differenceMs = Math.abs(endDate - startDate);
      return Math.round(differenceMs / ONE_DAY);
    },
    setBudgetType (value) {
      this.row.budgetType = value;
      this.budgetType.name = this.budgetType.options.filter(function (item) {
        return item.id == value;
      })[0].name;
      this.$emit("lineItemConfigurationRow-lineItemUpdated");
    },
    setBudgetTypeOptions (start, end) {
      if (this.isCreateMode) {
        var lineItemScheduleDays = this.getLineItemScheduleInDays(start, end);
        var daysText = lineItemScheduleDays > 1 ? " days" : " day";
        this.budgetType.options = [{ id: 2, name: "per day" }, { id: 1, name: "for " + lineItemScheduleDays + daysText }];
        this.row.budgetType = this.budgetType.options[1].id;
        this.row.scheduleDays = lineItemScheduleDays;
        this.budgetType.name = this.budgetType.options[1].name;
        this.row.startDate = start;
        this.row.endDate = end;
      }
    },
    removeLineItemRow () {
      this.$emit("lineItemConfigurationRow-removeLineItem", this.row.internalId);
      this.$emit("lineItemConfigurationRow-lineItemUpdated", {});
    },
    removeLineItemFraction () {
      this.$emit("removeLineItemFraction", { lineItemId: this.row.internalId, fractionId: this.row.internalFractionId });
    },
    addLineItemFraction () {
      this.$emit("addLineItemFraction", this.row.internalId);
    },
    addFilter (type) {
      let newFilter = {
        type: "availability",
        operator: "i_contains",
        tags: [],
        options: [],
        value: ""
      };
      if (type == this.productSetTypes.promoted) {
        this.newPromotedProductSetFilters.push(newFilter);
      } else {
        this.newTargetProductSetFilters.push(newFilter);
      }
    },
    updateFilter (updatedFilter) {
      if (updatedFilter.type == this.productSetTypes.promoted) {
        this.newPromotedProductSetFilters[updatedFilter.index] = updatedFilter.value;
      } else {
        this.newTargetProductSetFilters[updatedFilter.index] = updatedFilter.value;
      }
    },
    removeFilter (value) {
      if (value.type == this.productSetTypes.promoted) {
        this.newPromotedProductSetFilters.splice(value.index, 1);
      } else {
        this.newTargetProductSetFilters.splice(value.index, 1);
      }
    },
    generateFilter (value) {
      let filters = [];
      let name = [];
      value.forEach(item => {
        let filter = JSON.stringify({
          '{type}': {
            '{operator}': '{value}'
          }
        });
        filter = filter.replace("{type}", item.type);
        filter = filter.replace("{operator}", item.operator);
        filter = filter.replace("{value}", (["eq", "neq"].includes(item.operator)) ? item.value : item.tags.map(x => x.name));
        let filterValue = (["eq", "neq"].includes(item.operator)) ? [item.value] : item.tags.map(x => x.name)
        name.push(...[item.type, item.operator, ...[filterValue]]);
        filters.push(JSON.parse(filter));
      });

      name = name.join("_");
      let filter = (filters.length > 1) ? JSON.stringify({ "or": filters }) : JSON.stringify(filters[0]);
      return JSON.stringify({
        name: name,
        filter: filter
      });
    },
    filterTargetsForAMS (targets, type) {
      var targetsSpecificToType = targets.filter(function (target) {
        return target.type === type;
      });
      return targetsSpecificToType
    },
    refreshBpseTargets (bpseId) {
      if (!this.campaignLauncherConfigBpseIdMapper[this.selectedCampaignConfig]) {
        return;
      }
      var currentSelectedBpseId = this.campaignLauncherConfigBpseIdMapper[this.selectedCampaignConfig].BpseId;
      if (currentSelectedBpseId == bpseId) {
        if (!this.isLabels) {
          this.targets = this.savedTargetsMapperByBpseId[currentSelectedBpseId];
        }
      }
    },
    getTargetTypeString (item) {
      if (item.CreationType == 2) {
        return "(YT - video)"
      }
      return item.Type == 4 ? "(YouTube)" : "";
    },
    getLineItemInfoToSave () {
      // for now only product audience filters are returned, but this method can be extended to include additional info
      // when required
      if (this.isFacebookAds &&
        this.selectedConfigObject.campaign &&
        this.isDPAObjective(this.selectedConfigObject)) {
        if (this.$refs["dpaAudienceFilters"]) {
          var filters = this.$refs["dpaAudienceFilters"].getAudienceFiltersInfo();
          var type = this.$refs["dpaAudienceFilters"].getAudienceTypeinfo();
          return { [this.row.internalId + "-" + this.row.fractionId]: { targetProductAudience: filters, selectedAudienceType: type } };
        }
      }
      return {};
    },
    getKeyForProductsetsFailed () {
      if (this.getLineItemFractionId()) {
        return `${this.getLineItemId()}_${this.getLineItemFractionId()}`
      } else {
        return `${this.getLineItemId()}`
      }
    },
    createStoreMultiSelectObject () {
      let dealerGroupLabelIds = [];
      let dealerGroupLabelId = this.labelDimentions.find(ld => ld.name == "DealerGroup") ? this.labelDimentions.find(ld => ld.name == "DealerGroup").id : 0;
      let storeSelectObject = [];
      this.locations.forEach((store) => {
        store.isDisabled = store.$isDisabled
        let labels = store.labels;
        let isStoreLabelGrouped = false;
        labels.forEach((label) => {
          if (label.labelTypeId == dealerGroupLabelId) {
            isStoreLabelGrouped = true;
            if (dealerGroupLabelIds.includes(label.id)) {
              storeSelectObject.forEach((ssObj) => {
                if (ssObj.name == label.name) {
                  store.effectiveName = `${store.name} (${store.storeCode})`;
                  ssObj.children.push(store);
                }
              })
            } else {
              let parent = {};
              parent.name = label.name;
              parent.isSelected = false;
              parent.id = label.id;
              parent.children = [];
              store.effectiveName = `${store.name} (${store.storeCode})`;
              parent.children.push(store);
              storeSelectObject.push(parent);
              dealerGroupLabelIds.push(label.id);
            }
          }
        })
        if (!isStoreLabelGrouped) {
          if (dealerGroupLabelIds.includes(-1)) {
            storeSelectObject.forEach((ssObj) => {
              if (ssObj.name == "Other") {
                store.effectiveName = `${store.name} (${store.storeCode})`;
                ssObj.children.push(store);
              }
            })
          } else {
            let parent = {};
            parent.name = "Other";
            parent.isSelected = false;
            parent.id = -1;
            parent.children = [];
            store.effectiveName = `${store.name} (${store.storeCode})`;
            parent.children.push(store);
            storeSelectObject.push(parent);
            dealerGroupLabelIds.push(-1);
          }
        }
      });
      return storeSelectObject.sort(function (a, b) {
        if (a.name > b.name || a.name == "Other") {
          return 1;
        } else {
          return -1;
        }
      });
    },
    setLabelDimentionFilter () {
      let labelDimentionFilterObject = [];
      let labelDimentionsFilterIds = [];
      let storeLabelId = this.labelDimentions.find(ld => ld.name == "Store").id;
      let dealerGroupLabelId = this.labelDimentions.find(ld => ld.name == "DealerGroup") ? this.labelDimentions.find(ld => ld.name == "DealerGroup").id : 0;
      let labels = this.locations.map(s => s.labels).reduce((total, amount) => {
        if (!total.includes(amount)) {
          return total.concat(amount);
        }
      }, []);
      let jsonObject = labels.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      labels = uniqueArray;
      labels.forEach((label) => {
        if (label.labelTypeId != storeLabelId && label.labelTypeId != dealerGroupLabelId) {
          if (labelDimentionsFilterIds.includes(label.labelTypeId)) {
            labelDimentionFilterObject.forEach((ldf) => {
              if (ldf.id == label.labelTypeId) {
                ldf.labels.push(label);
              }
            })
          } else {
            let parent = this.labelDimentions.find(ld => ld.id == label.labelTypeId);
            parent.labels = [];
            parent.labels.push(label);
            labelDimentionFilterObject.push(parent);
            labelDimentionsFilterIds.push(label.labelTypeId);
          }
        }
      });
      let waLdf = { "name": "WhatsApp", "labels": [] };
      waLdf.labels.push({ "description": "WhatsApp enabled stores", "id": "wa_e", "name": "Enabled" });
      waLdf.labels.push({ "description": "WhatsApp disabled stores", "id": "wa_d", "name": "Disabled" });
      labelDimentionFilterObject.push(waLdf);
      return labelDimentionFilterObject;
    },
    filterStoreNameLabels (labels, childName, code, parentName) {
      return labels.filter(lb => lb.name != `${childName} (${code})` && lb.name != parentName);
    },
    setFilteredStoreIds () {
      this.selectedlabelIds = [];
      this.labelDimentionFilter.forEach((ld) => {
        ld.labels.forEach((label) => {
          if (label.isSelected) {
            this.selectedlabelIds.push(label.id);
          }
        })
      });
      let filteredStoreIds = [];
      this.locations.forEach((store) => {
        let storeLabelIds = store.labels.map(sl => sl.id);
        this.selectedlabelIds.forEach((lId) => {
          if (storeLabelIds.includes(lId)) { filteredStoreIds.push(store.id); }
          if (lId == "wa_e" && store.isWhatsAppEnabled) { filteredStoreIds.push(store.id); }
          if (lId == "wa_d" && !store.isWhatsAppEnabled) { filteredStoreIds.push(store.id); }
        })
      })
      this.filteredStoreIds = [...new Set(filteredStoreIds)];
    },
    getLabelBackground (color) {
      return {
        backgroundColor: color
      };
    },
    async setupMultiselect () {
      if (this.dbLabelTypes == null) {
        await this.getLabelsTypesById();
      }
      this.labelDimentions = [...this.dbLabelTypes];
      this.storeSelectOject = this.createStoreMultiSelectObject();
      this.labelDimentionFilter = this.setLabelDimentionFilter();
    },
    updateSavedTarget (bpseId, targetObj, targetString, isEditFlow) {
      if (isEditFlow) {
        for (let index in this.targets) {
          if (this.targets[index].Id === targetObj.savedTargetId) {
            this.$set(this.targets[index], 'Name', targetObj.targetName);
            this.$set(this.targets[index], 'TargetInformation', targetString);
            break;
          }
        }
        this.$forceUpdate();
      } else {
        let savedTargetsMapperCopy = JSON.parse(JSON.stringify(this.savedTargetsMapperByBpseId));

        let target = {
          Id: targetObj.savedTargetId,
          BpseId: bpseId,
          Name: targetObj.targetName || targetObj.Name,
          EstimatedReach: targetObj.estimatedReach || 0,
          Type: targetObj.type || 0,
          CreationType: targetObj.creationType || 0,
          TargetInformation: targetString
        }

        if (savedTargetsMapperCopy[bpseId] && savedTargetsMapperCopy[bpseId].length) {
          if (!savedTargetsMapperCopy[bpseId].some(obj => obj.Id === target.Id)) {
            savedTargetsMapperCopy[bpseId].push(target);
          }
        } else {
          savedTargetsMapperCopy[bpseId] = [];
          savedTargetsMapperCopy[bpseId].push(target);
        }
        this.set_savedTargetsMapperByBpseId(savedTargetsMapperCopy);
        // if bpse matches the config and target is not previously present
        let selectedConfigObject = this.getEnabledConfigs.find(obj => obj.Id === this.selectedCampaignConfig);

        if (this.targets && this.targets.length) {
          if ((!this.targets.some(obj => obj.Id === target.Id)) && (selectedConfigObject && selectedConfigObject.BpseId === target.BpseId)) {
            this.targets.unshift(target);
          }
        } else {
          this.targets = [];
          if (selectedConfigObject && selectedConfigObject.BpseId === target.BpseId) {
            this.targets.push(target);
          }
        }
      }
    },
    mapConfigSavedTargets (targetObj, editMode) {
      // let newTarget = JSON.parse(JSON.stringify(targetObj));
      if (editMode) {
        for (let index in this.targets) {
          if (this.targets[index].Id === targetObj.Id) {
            this.$set(this.targets[index], 'Name', targetObj.Name);
            this.$set(this.targets[index], 'TargetInformation', targetObj.targetString);
            break;
          }
        }
        this.$forceUpdate();
      } else {
        let target = {
          Id: targetObj.Id,
          BpseId: targetObj.bpseId,
          Name: targetObj.Name,
          EstimatedReach: targetObj.estimatedReach || 0,
          Type: targetObj.type || 0,
          CreationType: targetObj.creationType || 0,
          TargetInformation: targetObj.targetString
        }
        // if bpse matches the config and target is not previously present
        let selectedConfigObject = this.getEnabledConfigs.find(obj => obj.Id === this.selectedCampaignConfig);

        if (this.targets && this.targets.length) {
          if ((!this.targets.some(obj => obj.Id === target.Id)) && (selectedConfigObject && selectedConfigObject.BpseId === target.BpseId)) {
            this.targets.unshift(target);
          }
        } else {
          this.targets = [];
          if (selectedConfigObject && selectedConfigObject.BpseId === target.BpseId) {
            this.targets.push(target);
          }
        }

        if (this.selectedPublisher == this.publisher.DV360 && (selectedConfigObject && selectedConfigObject.BpseId === target.BpseId)) {
          this.targets = this.targets.filter(target => {
            let creativeTypes = this.selectedConfigObject.creativeType.map(Number);
            let liType = this.selectedConfigObject.adGroup.type;
            if (target.Type == 4) {
              if (liType == dbmEnums.lineItemType['Website Conversions']) {
                return target.CreationType == 2;
              }
              return creativeTypes.includes(dbmEnums.creativeType.Youtube);
            }
            return [dbmEnums.creativeType.Display].some(x => creativeTypes.includes(Number(x)));
          })
        }
      }
    },
    showAllStores () {
      if (!this.allStoresAlreadyFetched) {
        this.fetchStores({ isCalledFromLauncher: true }).then(() => {
          this.set_allStoresAlreadyFetched(true);
          this.showMultiselect();
        })
      } else {
        this.showMultiselect();
      }
    },
    closePromoAlert () {
      this.set_promoAlertMessages([]);
    },
    clearFilterQuery () {
      this.filterStoreParams = '';
      this.closePromoAlert();
    },
    showMultiselect () {
      this.isViewStores = true;
      this.renderMultiSelect = true;
    },
    showMultiselectAndFiltering () {
      this.showStoreFiltering = !this.showStoreFiltering;
      this.showMultiselect()
    },
    applyStores () {
      this.set_bulkApplyCalledForLineItemId(this.row.id)
      this.bulkApply({ isCalledFromLauncher: true })
    },
    setSummaryMeassageForMultiselect () {
      // Doing it so that in Launcher when someone selects store in multiselect, it will reflect in summary message
      var promoSummaryMessages = [...this.promoAlertMessages]
      promoSummaryMessages = promoSummaryMessages.filter(str => !str.includes("New Stores selected"));
      let selectedStoreMessageIndex = promoSummaryMessages.findIndex(str => str.includes("Stores selected"))
      let newStoreMessageIndex = promoSummaryMessages.findIndex(str => str.includes("New Stores selected"))
      promoSummaryMessages[selectedStoreMessageIndex] = `Stores selected: ${this.selectedLocations.length}`
      let newlyMappedStoresCount = this.selectedLocations.length - this.alreadyMappedStores.length
      if (newlyMappedStoresCount && this.alreadyMappedStores.length) {
        if (newStoreMessageIndex != -1) {
          promoSummaryMessages[newStoreMessageIndex] = `New Stores selected: ${newlyMappedStoresCount}`
        } else {
          promoSummaryMessages.push(`New Stores selected: ${newlyMappedStoresCount}`)
        }
      }
      this.set_promoAlertMessages(promoSummaryMessages)
      this.generateTableValuesforAlert()
    }
  },
  computed: {
    ...mapState([
      "savedTargetsMapperByBpseId",
      "campaignLauncherConfigBpseIdMapper",
      "campaignLauncherConfigs",
      "labels",
      "selectedPublisher",
      "amsEnums",
      "publisher",
      "savedAmsTargetsMapper",
      "businessProfileId",
      "tempMappedLocations",
      "dbLocations",
      "dbStores",
      "dbLabelTypes",
      "savedTargets",
      "businessProfileId",
      "amsPublishedConfig",
      "deltax",
      "selectedCampaignLauncherConfigId",
      "fetchingProductsetsFailed",
      "promoAlertMessages",
      "bulkApplyCalledForLineItemId",
      "allStoresAlreadyFetched"
    ]),
    ...mapGetters([
      "getBillingEventFromConfigId",
      "initialLocations"]),
    isReachAndFrequencyConfig () {
      return this.selectedPublisher == this.publisher.Facebook && this.selectedConfigObject && this.selectedConfigObject.campaign && this.selectedConfigObject.campaign.buyType == 4
    },
    filteredTargets () {
      let campaignConfig = this.campaignLauncherConfigs[this.selectedCampaignConfig] ? JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignConfig].config) : null
      if (campaignConfig && campaignConfig.campaign && campaignConfig.campaign.isAdvantagePlusShoppingCampaign) {
        this.isMultiSelectTarget = false
        return this.targets.filter(target => JSON.parse(target.TargetInformation).Countries != undefined);
      }
      this.isMultiSelectTarget = true
      return this.targets
    },
    getEnabledConfigs () {
      if (this.row.isPublished) {
        return this.campaignLauncherConfigurations.filter(
          config => config.Id === this.row.config
        );
      }
      if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts) {
        return this.campaignLauncherConfigurations.filter(
          config =>
            config.Status && config.searchEngineTypeId == this.selectedPublisher && !this.amsPublishedConfig.includes(config.Id)
        );
      }
      return this.campaignLauncherConfigurations.filter(
        config =>
          config.Status && config.searchEngineTypeId == this.selectedPublisher
      );
    },
    isLineitemBasedGroupingConfig () {
      return (this.selectedPublisher == this.publisher.Facebook && Object.keys(this.selectedConfigObject).length > 0 && this.selectedConfigObject.campaign && this.selectedConfigObject.campaign.grouping == 2)
    },
    publisherNotSupportingLineItemGrouping () {
      return (
        this.selectedPublisher == this.publisher.LinkedIn ||
        this.selectedPublisher == this.publisher.Twitter ||
        this.selectedPublisher == this.publisher.Adwords ||
        this.selectedPublisher == this.publisher.Moloco ||
        this.showDV360Targets
      )
    },
    isTargetSelected () {
      if (Array.isArray(this.selectedTargets)) {
        return this.selectedTargets.length > 0
      } else {
        return this.selectedTargets != "" && this.selectedTargets != undefined
      }
    },
    ifBillingEventNot2SecVideoViews () {
      return this.getBillingEventFromConfigId(this.selectedCampaignConfig) != 13;
    },
    isLineItemUpdated () {
      var li = {
        name: this.row.name,
        budgetType: this.row.budgetType,
        budget: this.row.budget,
        keymetricsId: this.row.keymetricId,
        keymetricValue: this.row.keymetricValue
      };
      this.$emit("lineItemConfigurationRow-lineItemUpdated", li);
      return li;
    },
    showTargetsOrLabels () {
      return this.selectedPublisher == this.publisher.Facebook ||
      this.selectedPublisher == this.publisher.LinkedIn ||
      this.selectedPublisher == this.publisher.Twitter ||
      this.selectedPublisher == this.publisher.Moloco ||
      this.showDV360Targets ||
      this.enableNewCampaignLaucherFlow
    },
    showlocations () {
      return this.enableNewCampaignLaucherFlow ||
      (this.selectedPublisher == this.publisher.Facebook && (Object.keys(this.selectedConfigObject).length > 0 && this.selectedConfigObject.campaign && this.selectedConfigObject.adset && (!this.isStoreVisitsObjective(this.selectedConfigObject) || this.selectedConfigObject.campaign.locationMode != 'PageSets')));
    },
    showDV360Targets () {
      return this.selectedPublisher == this.publisher.DV360;
    },
    isValidLineItem () {
      var isNameNotEmpty = this.row.name && this.row.name != "";
      var isKpiValueNotEmpty = this.row.keymetricValue && this.row.keymetricValue != "";
      var isKpimetricNotEmpty = this.row.keymetricId && this.row.keymetricId != "";
      return (isNameNotEmpty && isKpiValueNotEmpty && isKpimetricNotEmpty && ((this.row.isFractionEnabled == 1 && this.row.internalFractionId == 0) || (this.selectedCampaignConfig && this.selectedTargets && this.selectedTargets.length > 0)));
    },
    isRnfConfigSelectedAndTargetMapped () {
      var config = this.campaignLauncherConfigs[this.selectedCampaignConfig] ? JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignConfig].config) : null;
      var buyType = (config && config.campaign) ? config.campaign.buyType == 4 : false
      var isLineitemMapped = this.selectedTargets.length > 0
      return buyType && isLineitemMapped
    },
    lineitemBudget () {
      return Number(this.row.budget)
    },
    isRnFReplanAndConfigSelectedForLaunch () {
      // this would try to disable to all not related config mapping.
      return this.row && this.row.config != this.selectedCampaignLauncherConfigId && this.mapperTriggeredFromElementsView
    },
    enableNewCampaignLaucherFlow () {
      return this.deltax.isAdwordsCampaignLaucherFlow && this.selectedPublisher == this.publisher.Adwords;
    },
    locationKey () {
      if (this.enableNewCampaignLaucherFlow && this.selectedLocationType != adwordsConstants.adwordsEnums.locationType.Hyperlocal) {
        return "googleCode"
      } else if (this.isHyperlocalFlow) {
        return "storeCode"
      } else {
        return "facebookKey"
      }
    },
    isStoreSelectionDisabled () {
      return (this.row.isPublished && (this.isLineitemBasedGroupingConfig || this.publisherNotSupportingLineItemGrouping)) || !this.selectedTargets || this.selectedTargets.length == 0 || this.mapperTriggeredFromElementsView;
    },
    showStores () {
      return this.isHyperlocalFlow && this.selectedLocations && this.selectedLocations.length
    },
    showStoreSummary () {
      return this.promoAlertMessages.length && this.isHyperlocalFlow && this.row.id == this.bulkApplyCalledForLineItemId
    },
    isHyperlocalFlow () {
      return this.selectedLocationType == fbEnums.locationType.Hyperlocal || this.selectedLocationType == adwordsConstants.adwordsEnums.locationType.Hyperlocal
    }
  },
  beforeDestroy () {
    EventBus.$off("facebookTargetCreation-refreshTargetsForBpse");
    EventBus.$off("facebookTargetCreation-updateSavedTarget");
    EventBus.$off("moloco-updateSavedTarget");
    EventBus.$off("dv360-updateSavedTarget");
    EventBus.$off("adwords-updateSavedTarget");
    EventBus.$off("linkedin-updateSavedTarget");
    EventBus.$off("twitter-updateSavedTarget");
  },
  mounted () {
    var self = this;
    EventBus.$on("facebookTargetCreation-refreshTargetsForBpse", function (bpseId) {
      self.refreshBpseTargets(bpseId);
    });
    EventBus.$on("facebookTargetCreation-updateSavedTarget", function (bpseId, targetObj, targetString, isEditFlow) {
      self.updateSavedTarget(bpseId, targetObj, targetString, isEditFlow);
    });
    EventBus.$on("moloco-updateSavedTarget", function (targetObj, editMode) {
      self.mapConfigSavedTargets(targetObj, editMode);
    });
    EventBus.$on("dv360-updateSavedTarget", function (targetObj, editMode) {
      self.mapConfigSavedTargets(targetObj, editMode);
    });
    EventBus.$on("adwords-updateSavedTarget", function (targetObj, editMode) {
      self.mapConfigSavedTargets(targetObj, editMode);
    });
    EventBus.$on("linkedin-updateSavedTarget", function (targetObj, editMode) {
      self.mapConfigSavedTargets(targetObj, editMode);
    });
    EventBus.$on("twitter-updateSavedTarget", function (targetObj, editMode) {
      self.mapConfigSavedTargets(targetObj, editMode);
    });
  },
  async created () {
    var self = this
    this.setBudgetTypeOptions(this.row.startDate, this.row.endDate);
    this.teardownWatchers();
    var isCampaignLauncher = window.location.href.toLowerCase().includes("campaignlauncher");
    if ((this.selectedPublisher == this.publisher.Facebook || this.selectedPublisher == this.publisher.Adwords) && isCampaignLauncher) {
      var currentConfig = null;
      if (!this.isCreateMode) {
        currentConfig = this.row.config ? JSON.parse(this.campaignLauncherConfigs[this.row.config].config) : null;
        if (this.selectedPublisher == this.publisher.Adwords) {
          this.selectedLocationType = adwordsConstants.adwordsEnums.locationType.Standard;
          if (currentConfig && currentConfig.campaign) {
            switch (currentConfig.campaign.locationMode) {
              case adwordsConstants.adwordsEnums.locationMode.Hyperlocal:
                this.selectedLocationType = adwordsConstants.adwordsEnums.locationType.Hyperlocal;
                break;
              default:
                this.selectedLocationType = adwordsConstants.adwordsEnums.locationType.Standard;
                break;
            }
          }
        } else {
          this.selectedLocationType = fbEnums.locationType.Standard;
          if (currentConfig && currentConfig.campaign) {
            switch (currentConfig.campaign.locationMode) {
              case fbEnums.locationMode.Hyperlocal:
                this.selectedLocationType = fbEnums.locationType.Hyperlocal;
                break;
              case fbEnums.locationMode.PageSets:
                this.selectedLocationType = fbEnums.locationType.PageSets;
                break;
              default:
                this.selectedLocationType = fbEnums.locationType.Standard;
                break;
            }
          }
        }
      }
      if (this.selectedPublisher != this.publisher.Adwords) {
      // locations call only when its in create mode or lyperlocal is not enabled
        if ((this.dbLocations == null) && (this.isCreateMode || (!this.isCreateMode && this.selectedLocationType != fbEnums.locationType.Hyperlocal))) {
          APIService.getLocations()
            .then(response => {
              self.locations = response.data.data;
              self.$store.commit("set_dbLocations", response.data.data)
              if (!self.isCreateMode && self.selectedLocationType != fbEnums.locationType.Hyperlocal) {
                let mappedLocations = self.tempMappedLocations;
                let temp = self.locations.filter(x => self.row.locations && self.row.locations.includes(x.facebookKey.toString()))
                temp.forEach(element => {
                  element.rowId = self.row.id
                });
                if (mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] == null) {
                  mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] = temp
                } else {
                  let otherRowLocations = mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config].filter(x => x.rowId != self.row.id);
                  mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] = temp.concat(otherRowLocations)
                }
                self.$store.commit("set_tempMappedLocations", mappedLocations)
                self.$store.commit("set_savedMappedLocations", mappedLocations)
                self.$store.commit("set_mappedLocationsChanged", ++self.$store.state.mappedLocationsChanged)
              }
            })
        } else if (this.isCreateMode || (!this.isCreateMode && this.selectedLocationType != fbEnums.locationType.Hyperlocal)) {
          this.locations = this.dbLocations
          if (!self.isCreateMode && self.$store.state.selectedMediaPlan) {
            let mappedLocations = self.tempMappedLocations;
            let temp = self.locations.filter(x => self.row.locations && self.row.locations.includes(x.facebookKey.toString()))
            temp.forEach(element => {
              element.rowId = self.row.id
            });
            if (mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] == null) {
              mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] = temp
            } else {
              let otherRowLocations = mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config].filter(x => x.rowId != self.row.id);
              mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] = temp.concat(otherRowLocations)
            }
            self.$store.commit("set_tempMappedLocations", mappedLocations)
            self.$store.commit("set_savedMappedLocations", mappedLocations)
            self.$store.commit("set_mappedLocationsChanged", ++self.$store.state.mappedLocationsChanged)
          }
        }
      }
      // for hyperlocal
      if (this.dbStores == null && !this.isCreateMode && this.isHyperlocalFlow && this.row.locations && this.row.locations.length) {
        this.storesBeingFetched = true;
        this.showStoreFiltering = false;
        APIService.getStores(false, this.row.locations.join(','), '', false)
          .then(async response => {
            self.locations = response.data.data;
            let dbStores = [];
            if (response.data.data) {
              dbStores = response.data.data.filter(x => x.name && x.radius && x.latitude && x.longitude)
            }
            self.enabledStores = response.data.data;
            self.alreadyMappedStores = response.data.data.map(store => store.storeCode)
            self.$store.commit("set_dbStores", dbStores)
            self.selectedLocations = self.row.locations;
            self.multiSelectArray = self.locations.filter(location => self.selectedLocations.includes(location.storeCode));
            this.storesBeingFetched = false;
            await this.setupMultiselect();
            self.setStoreData()
          })
      } else if (this.dbStores && !this.isCreateMode && this.isHyperlocalFlow) {
        this.locations = this.dbStores
        await this.setupMultiselect();
        if (!self.isCreateMode && self.$store.state.selectedMediaPlan) {
          this.setStoreData()
        }
      }
    }
    if (this.row.config !== "" && this.row.config != null) {
      this.selectedCampaignConfig = this.row.config;
      let config = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignConfig].config);
      if (this.isCatalogSupportedForConfig(config) && config.campaign.productCatalog) {
        this.fetchExistingProductSets(config.campaign.productCatalog, config.bpseId);
      }
      if (this.isStoreVisitsObjective(config)) {
        var empty = this.selectedLocationType != fbEnums.locationType.PageSets;
        this.fetchPageSets(config.bpseId, config.facebookPageId, empty);
      }
      this.dpaSettings = (this.row.dpaSettings == null || this.row.dpaSettings == "") ? this.dpaSettings : this.row.dpaSettings;
      this.dpaSettings.targetProductAudience = this.dpaSettings.targetProductAudience || { inclusions: [], exclusions: [] }
      this.selectedPromotedProductSetId = this.dpaSettings.promotedProductSet.selected.Id;
      this.selectedTargetProductSetId = this.dpaSettings.targetProductSet.selected.Id;
      this.selectedPromotedProductSetOption = this.dpaSettings.promotedProductSet.option;
      this.selectedTargetProductSetOption = this.dpaSettings.targetProductSet.option;
      if (this.row && this.row.pageSetSettings && this.row.pageSetSettings["pageSets"]) {
        this.selectedPageSets = this.row.pageSetSettings["pageSets"].map(ps => ps.id);
      }
    }
    if (
      this.selectedPublisher == this.publisher.Facebook &&
      this.selectedCampaignConfig !== "" &&
      ((this.row.targetsInfo !== null && this.row.targetsInfo != []) ||
        this.row.labelsInfo != [])
    ) {
      // assign locally so that it's available in the next tick
      this.targets = [];
      this.selectedConfigObject = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignConfig].config);

      this.isLabels = this.selectedConfigObject.adset.targetSelection === "By Label";
      let bpseId = this.campaignLauncherConfigBpseIdMapper[this.selectedCampaignConfig].BpseId;
      if (this.isLabels) {
        this.selectedLabels = this.row.labels;
      } else {
        this.selectedTargets = this.row.targets;
        let bpseTargets = this.savedTargetsMapperByBpseId[bpseId];
        this.getSocialTargets(bpseTargets);
        this.targets = bpseTargets;
      }
      if (this.isReachAndFrequencyConfig) {
        this.setRnfPlannerData();
      }
    } else if ((this.selectedPublisher == this.publisher.AMS.SponsoredProducts ||
      this.selectedPublisher == this.publisher.AMS.SponsoredBrands ||
      this.selectedPublisher == this.publisher.AMS.SponsoredDisplay) &&
      ((this.row.targetsInfo !== null && this.row.targetsInfo != []) ||
      this.row.asins != "") && this.selectedCampaignConfig !== "") {
      this.targets = [];
      this.selectedConfigObject = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignConfig].config);
      this.selectedTargets = this.row.targets;
      this.targetsInfo = this.row.targetsInfo;
      this.clusterList = this.row.targets;
      if (this.selectedConfigObject.searchEngineTypeId == 12) {
        await APIService.getTargetsCluster(this.amsEnums.targetType.Cluster)
          .then(response => {
            var targetsDetails = response;
            for (var i = 0; i < targetsDetails.length; i++) {
              if ((targetsDetails[i].logo && targetsDetails[i].logo != "") || (targetsDetails[i].logo && targetsDetails[i].logo != null)) {
                if ((targetsDetails[i].headline && targetsDetails[i].headline != "") || (targetsDetails[i].headline && targetsDetails[i].headline != null)) {
                  this.targets.push(targetsDetails[i])
                }
              }
            }
          });
      } else {
        this.targets = this.filterTargetsForAMS(this.savedTargets, this.amsEnums.targetType.Cluster)
      }
      this.amsAsins = this.row.asins == "" ? "" : JSON.parse(this.row.asins).asinsToAdvertise.join(',');
      // if (targetDetails && targetDetails.type == 2) {
      //   var asins = JSON.parse(targetDetails.targetString)
      //   this.amsAsins = String(asins.asinsToTarget);
      //   this.row.targetTypeId = 2
      // } else if (targetDetails && targetDetails.type == 1) {
      //   this.selectedTargets = this.row.targets;
      //   let bpseTargets = this.savedTargets;
      //   this.targets = bpseTargets;
      //   this.row.targetTypeId = 1
      // }
    } else if ((this.selectedPublisher == this.publisher.LinkedIn || this.selectedPublisher == this.publisher.Twitter || this.showDV360Targets || this.enableNewCampaignLaucherFlow || this.selectedPublisher == this.publisher.Moloco) &&
      (this.row.targetsInfo !== null && this.row.targetsInfo != []) && this.selectedCampaignConfig !== "") {
      this.targets = [];
      this.selectedConfigObject = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignConfig].config);
      let bpseId = this.campaignLauncherConfigBpseIdMapper[this.selectedCampaignConfig].BpseId;
      this.selectedTargets = this.row.targets;
      let bpseTargets = this.savedTargetsMapperByBpseId[bpseId];
      this.targets = bpseTargets;
      if (this.enableNewCampaignLaucherFlow) {
        if (this.selectedConfigObject.campaign.locationMode == adwordsConstants.adwordsEnums.locationMode.Hyperlocal) {
          this.selectedLocations = this.row.locations;
          this.multiSelectArray = this.locations.filter(location => this.selectedLocations.includes(location.storeCode));
        } else {
          this.selectedLocations = this.row.locations.map(Number);
        }
      }
    }
    this.updateLocations(this.initialLocations);
    this.$nextTick(function () {
      this.unwatch.selectedCampaignConfig = this.$watch(
        "selectedCampaignConfig",
        this.selectedCampaignConfigWatch
      );
      this.unwatch.selectedLabels = this.$watch(
        "selectedLabels",
        this.selectedLabelsWatch
      );
      this.unwatch.selectedTargets = this.$watch(
        "selectedTargets",
        this.selectedTargetsWatch
      );
      this.unwatch.selectedLocations = this.$watch(
        "selectedLocations",
        this.selectedLocationsWatch
      );
      this.unwatch.selectedAsins = this.$watch(
        "selectedAsinsWatch",
        this.selectedAsinsWatch
      );
    })
  },
  watch: {
    isRefreshAmsTargets: function () {
      if (this.isRefreshAmsTargets) {
        this.refreshTargets();
      }
    },
    initialLocations: {
      handler (value) {
        this.updateLocations(value);
      },
      deep: true
    },
    selectedLocationType: function () {
      var self = this;
      var storesPromise;
      if (this.selectedLocationType == fbEnums.locationType.Hyperlocal) {
        if (!this.dbStores && !this.storesBeingFetched && this.storeSelectionType == 1) {
          storesPromise = APIService.getStores()
            .then(response => {
              self.locations = response.data.data;
              let dbStores = [];
              if (response.data.data) {
                dbStores = response.data.data.filter(x => x.name && x.radius && x.latitude && x.longitude)
              }
              self.$store.commit("set_dbStores", dbStores)
            })
        }
        Promise.all([storesPromise]).then(() => {
          this.locations = this.dbStores;
          if (!self.isCreateMode && self.$store.state.selectedMediaPlan && this.locations && this.locations.length) {
            self.setStoreData();
            self.setupMultiselect();
          }
        });
      } else {
        this.locations = this.dbLocations
        if (!self.isCreateMode && self.$store.state.selectedMediaPlan) {
          let mappedLocations = self.tempMappedLocations;
          let temp = self.locations.filter(x => self.row.locations && self.row.locations.includes(x.facebookKey.toString()))
          temp.forEach(element => {
            element.rowId = self.row.id
          });
          if (mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] == null) {
            mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] = temp
          } else {
            let otherRowLocations = mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config].filter(x => x.rowId != self.row.id);
            mappedLocations[self.$store.state.selectedMediaPlan.id + '-' + self.row.config] = temp.concat(otherRowLocations)
          }
          self.$store.commit("set_tempMappedLocations", mappedLocations)
          self.$store.commit("set_savedMappedLocations", mappedLocations)
          self.$store.commit("set_mappedLocationsChanged", ++self.$store.state.mappedLocationsChanged)
        }
      }
    },
    'mediaPlan.schedule': function () {
      this.setBudgetTypeOptions(this.mediaPlan.schedule[0], this.mediaPlan.schedule[1]);
    },
    dpaSettings: {
      handler (value) {
        this.$emit("updatedDpaSettings", { dpaSettings: value, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId(), isReachAndFrequencyConfig: false });
      },
      deep: true
    },
    selectedPageSets: {
      handler (value) {
        var pageSets = this.allPageSets.filter(pageSet => value.includes(pageSet.id));
        this.$emit("updatedPageSetSettings", { pageSetSettings: { pageSets }, lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() });
      },
      deep: true
    },
    selectedPromotedProductSetId (value) {
      if (this.existingProductSets.length == 0 || !value) {
        return;
      }
      var oldProduct = this.existingProductSets.find(x => x.id == value)
      this.dpaSettings.promotedProductSet.selected = this.convertKeysToPascalCase(oldProduct);
      this.updateTargetProductSet();
    },
    selectedTargetProductSetId (value) {
      if (this.existingProductSets.length == 0 || !value) {
        return;
      }
      var oldProduct = this.existingProductSets.find(x => x.id == value)
      this.dpaSettings.targetProductSet.selected = this.convertKeysToPascalCase(oldProduct);
    },
    newPromotedProductSetFilters: {
      handler (value) {
        this.dpaSettings.promotedProductSet.new = this.generateFilter(value);
        this.dpaSettings.promotedProductSet.filters = value;
        this.updateTargetProductSet();
      },
      deep: true
    },
    newTargetProductSetFilters: {
      handler (value) {
        this.dpaSettings.targetProductSet.new = this.generateFilter(value);
        this.dpaSettings.targetProductSet.filters = value;
      },
      deep: true
    },
    selectedTargetProductSetOption (value) {
      this.dpaSettings.targetProductSet.option = value;
      this.updateTargetProductSet();
    },
    selectedPromotedProductSetOption (value) {
      this.dpaSettings.promotedProductSet.option = value;
      this.updateTargetProductSet();
    },
    selectedTargets (newVal, oldVal) {
      let exportedClusters = [];
      if (this.row.isPublished) {
        for (var i = 0; i < this.clusterList.length; i++) {
          let target = this.targetsInfo.find(x => x.id == this.clusterList[i])
          if (target != null && target.IsExported == 1) {
            exportedClusters.push(this.clusterList[i]);
          }
        };
        for (i = 0; i < exportedClusters.length; i++) {
          if (!newVal.includes(exportedClusters[i])) {
            this.selectedTargets.splice(i, 0, exportedClusters[i])
            this.$Message.error({ background: true, content: "Cannot remove an already exported cluster." });
          }
        };
      }
    },
    amsAsins (newAsinString) {
      this.$emit("asinsUpdated", { asins: newAsinString.split(','), lineItemId: this.getLineItemId(), lineItemFractionId: this.getLineItemFractionId() });
    },
    multiSelectArray () {
      this.selectedLocations = this.multiSelectArray.map(store => store.storeCode);
    },
    "selectedFilterType" (value) {
      if (value == 'Advertiser') {
        this.storeFilterMessage = "Enter Advertiser Ids";
      } else {
        this.storeFilterMessage = "Enter Store Codes";
      }
    },
    "storeSelectionType" (value) {
      if (value == 1) {
        this.closePromoAlert();
        this.showAllStores();
      }
    }
  }
}
</script>
<style scoped>
.targeting-select {
  padding: 10px;
}
.btn-link {
  margin: 0;
  padding: 0;
  color: #25298d;
}
.estimated-reach{
  color:#ccc;
  float: right;
  padding-right: 15px;
}
.target-name{
  padding-left: 5px;
}
.target-info{
  color:#ccc;
  float: left;
}
.lineitem-create-label {
  padding: 6px
}
.target-type {
  font-style: italic;
  font-weight: bold;
  color: #acadae;
}
.trashIcon {
  margin-left: 5px;
}
.optionsIcon {
  padding-top:10px;
  display: flex;
  float:right;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: table;
  margin: 5px 0px 0px 5px;
}
.consider {
  color: green;
}
.skip {
  color: orange;
}
.default {
  background-color: #bbb;
}
.iconContainer {
  min-height: 20px;
  max-width: 20px;
  min-width: 20px;
  display:inline;
  float:right;
  margin-right: 5px;
}
.fraction-btn {
  margin: 0px 10px;
  padding-bottom: 5px;
  width: 70px;
  cursor: pointer;
}
</style>
<style>
.lineitem-create-settings-row {
  padding-bottom:15px
}
.lineitem-create-settings .ivu-select-selection, .lineitem-create-settings .ivu-input {
  border:0;
  border-bottom: 1px solid #dcdee2;
}
.lineitem-create-settings .ivu-input:focus {
  box-shadow: none;
}
.product-set-filter-label {
  padding-right: 2rem;
  font-weight: bold;
}
.product-set-filter-block {
  padding: 1rem;
  padding-bottom: 0.5rem;
}
.add-btn {
  margin-top: 0.4rem;
}
.rnf-dashboard {
  border: 0px !important;
  background-color: #f0f0f0;
  padding: 5px !important;
}
#alert {
  color: #b94a48 !important;
}
.filterListGroup{
  list-style-type: none;
  margin-bottom: 0px;
  margin-left: 5px !important;
}
.filterListGroup-name{
   margin-bottom: 10px !important;
   margin-top: 5px !important;
}
.filterListGroup-item{
   padding: 0px 0px 0px 10px;
   border:none;
   margin-bottom: 0px !important;
}
.selected-stores {
  text-align: left;
  color: #979898 !important;
  font-size: 13px !important;
}
.bold-text {
  font-weight: bold !important;
}
.label-container{
  margin-right: 4%;
}
.store-labels-selector{
  margin-left: 5px;
}.noLabelText {
  padding-top: 6px !important;
}
.add-store-btn {
  padding: 7px 10px;
  margin-bottom: 0px;
}
.filterButton {
  width: 60px;
  margin-left: 15px;
}
.full-width-table {
  width: 100%;
  margin: 0;
  padding: 0;
}
.alert-header {
  display: flex;
  margin-left: 17%;
  position: relative;
}
#tableCloseButton {
  top: 3px;
  right: 3px;
  position: absolute;
  margin-left:5px;
}
#applyText{
  color:#fff !important;
}
.bulkSelection{
  margin-bottom: 15px;
  margin-left: 17%;
}
.grey-text {
  color: grey;
}
</style>
