<template>
  <div>
    <div class="publisher-receipt-header">
      <span class="publisher-title">Receipients</span>
      <span v-if="invalidEmailError" class="invalid-email-text"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>Invalid Email</span>
    </div>
     <div class="publisher-wrapper">
      <i-table
      class="publisher-tbl"
      :columns="tblColumns"
      :data="tblData"
      disabled-hover
      border
     >
     <template slot-scope="{ index }" slot="status">
       <i-switch v-model="tblData[index].activeStatus" size="small"></i-switch>
     </template>
     <template slot-scope="{ index }" slot="publisher">{{ tblData[index].publisher }}</template>
     <template slot-scope="{ index }" slot="email">
          <div class="error-box">
            <InputTag
            class="email-input"
            v-if="tblData[index].activeStatus"
            v-model="tblData[index].supplierEmails"
            add-tag-on-blur
            @input="validateEmails(index)"
            ></InputTag>
          </div>
     </template>
     </i-table>
     </div>
  </div>
</template>

<script>
import { Table, locale, Switch } from "iview";
import InputTag from 'vue-input-tag'
import lang from 'iview/dist/locale/en-US'

locale(lang)
export default {
  components: {
    "i-table": Table,
    "i-switch": Switch,
    InputTag
  },
  data () {
    return {
      tblColumns: [
        {
          title: "Status",
          key: "activeStatus",
          align: 'center',
          slot: 'status',
          width: 100,
          minWidth: 80
        },
        {
          title: "Publisher",
          key: "publisher",
          align: 'center',
          width: 250,
          minwidth: 200
        },
        {
          title: "Emails",
          key: "supplierEmails",
          slot: 'email',
          align: 'center',
          minwidth: 300
        }
      ],
      tblData: [],
      invalidEmailError: false
    }
  },
  methods: {
    validateEmails (index) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const invalidEmails = this.tblData[index].supplierEmails.filter(email => !emailPattern.test(email));
      this.tblData[index].supplierEmails = this.tblData[index].supplierEmails.filter(email => emailPattern.test(email));
      if (invalidEmails.length) {
        this.invalidEmailError = true;
        setTimeout(() => {
          this.invalidEmailError = false;
        }, 5000);
      } else {
        this.invalidEmailError = false;
      }
    }
  }
}
</script>

<style scoped>
.publisher-receipt-header{
  margin-bottom: 13px;
}
.publisher-title {
  font-size: 13px;
  font-weight: bold;
}
.error-box {
  border: 1px red;
}
.invalid-email-text {
  margin-left: 30px;
  color: red;
}
.fa-exclamation-triangle {
  color: red;
  font-size: 12px;
  margin-left: 20px;
  margin-right: 4px;
}
::v-deep  .publisher-tbl .ivu-table-cell{
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-weight: 500;
}
.email-input{
  border: none;
}

::v-deep .publisher-tbl .ivu-table td{
  height: 33px !important;
}
::v-deep .vue-input-tag-wrapper .input-tag {
    background-color: #dcdee2 !important;
    border: 1px solid #dcdee2 !important;
    color: #2c2e2f !important;
}
::v-deep .publisher-tbl .ivu-table th {
  height: 33px !important;
}
::v-deep .vue-input-tag-wrapper .input-tag .remove {
    color: #2c2e2f !important;
}

</style>
