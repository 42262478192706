<template>
	<div class="highcharts-description">
    	<div :class="isEditableMediaPlan()?'col-md-12 no padding brief-note':'col-md-12 no padding brief-note non-editable'" style="margin-top: 20px;">
        	<vue-editor
                v-model="approachNotes"
                :editorToolbar="toolbar"
                ref="textEditor"
                :disabled="!isEditableMediaPlan()"
            ></vue-editor>
        </div>
        <div class="col-md-12" style="padding-top: 14px;">
            <div class="col-md-6 no-padding">
            	<p class="attachments-text-style">Attachments</p>
            </div>
            <div class="col-md-6 no-padding">
                <i-upload
                        :before-upload="uploadMedia"
                        :accept="'image/*'"
                        :show-upload-list="true"
                        :default-file-list="defaultFileList"
                        action=""
                        :format="['jpg','jpeg','png']"
                        :on-remove="handleFileRemove"
						            :disabled="!isEditableMediaPlan()">
                	<button type="button" class="btn create-btn btn-success button-style">Upload <span class="fa fa-plus"></span></button>
                </i-upload>
            </div>
        </div>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import {
  Upload
} from "iview";
import { ApiService } from ".././Services/mediaPlanBulkCreateApiService.js";

export default {
  components: {
    "vue-editor": VueEditor,
    "i-upload": Upload
  },
  data: function () {
    return {
      approachNotes: "",
      imageUploadList: [],
      defaultFileList: [],
      detachAttachmentIds: [],
      removedDefaultFileList: [],
      approachNotesAPIdata: [],
      toolbar: [
        ["bold"],
        [{ list: "bullet" }]
      ]
    }
  },
  methods: {
    init: function () {
      this.imageUploadList = [];
      this.detachAttachmentIds = [];
      this.removedDefaultFileList = [];
      const getApproachNotesPromise = this.apiService.getApprochNotes(this.mediaPlanId);
      getApproachNotesPromise
        .then(res => {
          this.approachNotesAPIdata = res.data.data;
          if (this.approachNotesAPIdata != null && this.approachNotesAPIdata != undefined && this.approachNotesAPIdata.id > 0) {
            this.approachNotes = this.approachNotesAPIdata.description;
            this.defaultFileList = this.approachNotesAPIdata.attachments;
          }
        })
        .catch(x => {
          console.log("Error in fetching approachNotes data");
        });
    },
    uploadMedia: function (file) {
      var currentDefaultFileList = this.defaultFileList.filter((el) => !this.removedDefaultFileList.includes(el))
      var allUploadedList = currentDefaultFileList.concat(this.imageUploadList);
      allUploadedList = [...new Set(allUploadedList)];
      if (file.type.includes("image") && allUploadedList.length < 5) {
        this.imageUploadList.push(file);
      } else {
        return false;
      }
    },
    handleFileRemove: function (file) {
      if (file.attachmentPath != undefined) {
        this.detachAttachmentIds.push(this.approachNotesAPIdata.attachments.find(x => x.attachmentPath == file.attachmentPath).id);
        this.removedDefaultFileList = this.removedDefaultFileList.concat(this.defaultFileList.filter(x => x.attachmentPath == file.attachmentPath));
      } else {
        this.imageUploadList = this.imageUploadList.filter(x => x.uid != file.uid);
      }
    },
    cancelAction: function () {
      this.approachNotes = "";
      this.imageUploadList = [];
      this.defaultFileList = [];
      this.detachAttachmentIds = [];
    },
    isEditableMediaPlan: function () {
      if (this.isEstimateEnabled) {
        return false;
      } else {
        return true;
      }
    }
  },
  props: ["mediaPlanId"],
  created () {
    this.apiService = new ApiService();
  }
}
</script>
<style scoped>
::v-deep .brief-note.non-editable .quillWrapper{
  background: #f3f3f3;
}
.attachments-text-style{
  font-size: 15px;
  margin-top: 7px;
  margin-left: 5px;
  color: black;
}
.button-style {
  text-align: center;
  float: right !important;
}
::v-deep .highcharts-description{
      margin-top: 0%;
}
</style>
