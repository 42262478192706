<template>
  <div class='confirmation-modal-container'>
    <i-modal
    v-model="showModal"
    :footer-hide="false"
    :closable="false"
    :mask-closable="false"
    class-name="vertical-center-modal"
    >
    <h4 slot="header">
        {{ confirmModalHeader }}
    </h4>
    <div>
        <p class="confirm-box-text-style">{{ confirmModalBody }}</p>
    </div>
    <div slot="footer">
        <button
            type="button"
            class="btn create-btn btn-white button-style"
            v-on:click="cancel"
            v-show="showCancelButton"
            >Cancel</button>
        <button
            type="button"
            class="btn create-btn btn-success button-style"
            v-on:click="accept"
            >{{ approveButtonText }}</button>
        </div>
    </i-modal>
  </div>
</template>

<script>

import {
  Modal
} from "iview";

export default {
  components: {
    "i-modal": Modal
  },

  props: [
    'confirmModalHeader',
    'confirmModalBody',
    'showCancelButton',
    'approveButtonText'
  ],
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    setupModal: function (isPopUpVisible) {
      this.showModal = isPopUpVisible
    },
    accept: function () {
      this.$emit('accept')
    },
    cancel: function () {
      this.showModal = false;
      this.$emit('cancel')
    }
  }
}
</script>
<style scoped>
::v-deep .ivu-modal-content .ivu-modal-header{
    padding: 10px 16px !important;
    border-bottom: 2px solid #ccc !important;
}
.confirm-box-text-style{
  font-size: 13px;
  color: black;
}
.vertical-center-modal{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
