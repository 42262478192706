<template>
 <div>
  <Row type="flex" align="middle">
    <Column span="5" class="label-text">
      <label>Clients</label>
    </Column>
    <Column span="12">
      <Select v-model="selectedClients" multiple filterable>
        <Option v-for="item in clients" :value="item.id" :key="item.id" >{{ item.name }}</Option>
      </Select>
    </Column>
    <Column span="1" class="removeButton" v-show="selectedClients && selectedClients.length > 0">
      <Button type="text"  @click="selectedClients=[]"><i class="bi bi-x-circle-fill"></i> </Button>
    </Column>
  </Row>
  <br/>
  <Row type="flex" align="middle">
    <Column span="5" class="label-text">
      <label>Reserve order</label>
    </Column>
    <Column span="12">
      <Input placeholder="Contains" v-model="reserveOrderNameFilter" style="width:100%"/>
    </Column>
    <Column span="1" class="removeButton" v-show="reserveOrderNameFilter && reserveOrderNameFilter.length > 0">
      <Button type="text" @click="reserveOrderNameFilter=''"><i class="bi bi-x-circle-fill"></i> </Button>
    </Column>
  </Row>
  <br>
  <Row type="flex" align="middle">
    <Column span="5" class="label-text">
      <label>Received Date</label>
    </Column>
    <Column span="6" class="padding-right">
      <Select v-model="invoiceMonthFilter" filterable placeholder="Month" @on-change="setDefaultYear">
        <Option v-for="item in monthNames" :value="item.id" :key="item.id" >{{ item.name }}</Option>
      </Select>
    </Column>
    <Column span="6" class="padding-left">
      <Select v-model="invoiceYearFilter" filterable placeholder="Year">
        <Option v-for="item in yearOptions" :value="item" :key="item" >{{ item }}</Option>
      </Select>
    </Column>
    <Column span="1" class="removeButton" v-show="invoiceYearFilter && invoiceYearFilter > 0">
      <Button type="text" @click="clearDateSelection"><i class="bi bi-x-circle-fill"></i> </Button>
    </Column>
  </Row>
  <br>
  <Row type="flex" align="middle">
    <Column span="5" class="label-text">
      <label>Suppliers</label>
    </Column>
    <Column span="12">
      <Select v-model="selectedSuppliers" multiple filterable>
        <Option v-for="item in suppliers" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
    </Column>
    <Column span="1" class="removeButton" v-show="selectedSuppliers.length > 0">
      <Button type="text" @click="selectedSuppliers=''"><i class="bi bi-x-circle-fill"></i> </Button>
    </Column>
  </Row>
 </div>
</template>
<script>
import {
  Select,
  Row,
  Col,
  Input,
  Option,
  Button
} from "iview";

export default {
  components: {
    Select,
    Option,
    Row,
    Input,
    "Column": Col,
    Button
  },
  props: {
    clients: {
      type: Array
    },
    suppliers: {
      type: Array
    },
    applyFilterProp: Boolean,
    updatedFiltersProp: Object
  },
  data: function () {
    let years = []
    let currentYear = new Date().getFullYear();
    for (let i = (currentYear - 2); i <= currentYear; i++) years.push(i);

    return {
      monthNames: [
        { id: 1, name: 'Jan' },
        { id: 2, name: 'Feb' },
        { id: 3, name: 'Mar' },
        { id: 4, name: 'Apr' },
        { id: 5, name: 'May' },
        { id: 6, name: 'Jun' },
        { id: 7, name: 'Jul' },
        { id: 8, name: 'Aug' },
        { id: 9, name: 'Sep' },
        { id: 10, name: 'Oct' },
        { id: 11, name: 'Nov' },
        { id: 12, name: 'Dec' }],
      yearOptions: years,
      selectedClients: [],
      selectedSuppliers: [],
      reserveOrderNameFilter: '',
      invoiceMonthFilter: '',
      invoiceYearFilter: '',
      isDateReset: false
    };
  },
  methods: {
    clearDateSelection () {
      this.isDateReset = true
      this.invoiceYearFilter = ''
      this.invoiceMonthFilter = ''
    },
    setDefaultYear () {
      if (!this.isDateReset && (!this.invoiceYearFilter || this.invoiceYearFilter == '')) {
        this.invoiceYearFilter = this.yearOptions.find(x => x == new Date().getFullYear())
      }
      this.isDateReset = false
    },
    removeAllFilters: function () {
      this.selectedClients = [];
      this.selectedSuppliers = [];
      this.reserveOrderNameFilter = '';
      this.invoiceMonthFilter = '';
      this.invoiceYearFilter = '';
    }
  },
  watch: {
    applyFilterProp: function () {
      if (this.applyFilterProp) {
        let clients = [];
        this.selectedClients.forEach(element => {
          var data = this.clients.filter(cli => cli.id == element);
          clients.push({
            Name: data[0].name,
            Id: data[0].id
          })
        });

        let suppliers = [];
        this.selectedSuppliers.forEach(element => {
          var data = this.suppliers.filter(x => x.id == element);
          suppliers.push({
            Name: data[0].name,
            Id: data[0].id
          })
        });
        var query = {
          client: clients,
          selectedClients: this.selectedClients,
          invoiceMonthFilter: this.invoiceMonthFilter,
          invoiceYearFilter: this.invoiceYearFilter,
          reserveOrderNameFilter: this.reserveOrderNameFilter,
          selectedSuppliers: this.selectedSuppliers,
          suppliers: suppliers,
          monthName: this.monthNames.find(x => x.id == this.invoiceMonthFilter) ? this.monthNames.find(x => x.id == this.invoiceMonthFilter).name : ''
        }
        this.$emit("UpdateQuery", query);
      }
    },
    updatedFiltersProp: function () {
      if (this.updatedFiltersProp.client != undefined) {
        this.selectedClients = this.updatedFiltersProp.client
      }
      if (this.updatedFiltersProp.suppliers != undefined) {
        this.selectedSuppliers = this.updatedFiltersProp.suppliers
      }
      this.invoiceMonthFilter = this.updatedFiltersProp.month
      this.invoiceYearFilter = this.updatedFiltersProp.year
      this.reserveOrderNameFilter = this.updatedFiltersProp.reserveOrderName
    }
  }
};
</script>
<style scoped>
::v-deep .ivu-btn {
    padding: 5px 2px 6px !important;
}
::v-deep .ivu-btn-text:hover {
    color:  #515a6e !important;
    background: #eee;
}
.removeButton {
margin-left: 2%;
}
.label-text{
  margin-right: 2%;
  text-align: end;
}
.padding-right{
    padding-right: 10px;
}
.padding-left{
    padding-left: 10px;
}
::v-deep .ivu-select .ivu-select-dropdown{
  min-width: 0 !important;
}
::v-deep .ivu-select-dropdown-list{
  min-width: max-content;
  position: fixed;
  background-color: white;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
  padding: 5px 0;
  margin: 0 0 5px 0;
  max-height: 200px;
  overflow-y: auto;
  transform: translateY(-6px);
}
::v-deep .ivu-select-not-found{
  width: 222px;
}
::v-deep .ivu-select-not-found + .ivu-select-dropdown-list{
  width: 0 !important;
}
</style>
<style scoped src = "../../../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css">
</style>
<style src="../../../Styles/dx-iview.css"></style>
