<template>
  <i-modal v-model="modal" class-name="modal_placement" :closable="false" title="Division Access">
    <div v-if="isLoading" class="loader">
      <i class="fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
    </div>
    <div v-if="!isLoading" class="main">
      <Row>
        <Column span="6" class="label-text" style="text-align:center; display: inline-block; height:32px;">
          <label><b>Divisions</b></label>
        </Column>
        <Column span="18">
          <i-select
            v-model="selectedDivisions"
            multiple
            placeholder="Select Division(s)"
            style="width:60px"
          >
            <i-option
              v-for="item in divisionsList"
              :value="item.id"
              :key="item.id"
              >{{ item.name }}</i-option
            >
          </i-select>
        </Column>
      </Row>
    </div>
    <div slot="footer">
      <button class="btn btn-white" @click="closeModal()">Close</button>
      <button class="btn btn-success" @click.prevent="onSave()">Save</button>
    </div>
  </i-modal>
</template>

<script>
import { Select, Option, Row, Col, Modal, locale } from "iview";
import lang from "iview/dist/locale/en-US";
import { DivisionApiService } from "../Services/apiService.js";
locale(lang);
export default {
  components: {
    Row,
    "Column": Col,
    "i-modal": Modal,
    "i-select": Select,
    "i-option": Option
  },
  props: {
    setSelectedData: Array
  },
  data: function () {
    return {
      businessProfileId: undefined,
      userId: undefined,
      apiService: null,
      selectedDivisions: [],
      divisionsList: [],
      showAccess: false,
      isLoading: true,
      modal: false
    };
  },
  methods: {
    setupModal (value, userId, businessProfileId) {
      this.businessProfileId = businessProfileId;
      this.apiService = new DivisionApiService();
      this.init(value, userId);
    },
    init: async function (value, userId) {
      this.modal = value;
      this.userId = userId;
      this.isLoading = true;
      if (this.modal) {
        var response = await this.apiService.getDivisions(
          this.businessProfileId
        );
        if (response && response.data && response.data.data) {
          this.divisionsList = response.data.data;
        } else {
          this.$emit("DivisionsFetchFailure");
          this.modal = false;
        }
        this.clearFilters();
        this.apiService
          .getAccess(this.businessProfileId, this.userId)
          .then(response => {
            if (response && response.data && response.data.data) {
              var divisionRoles = response.data.data.filter(
                x => x.roleId == 16
              );
              if (divisionRoles != null && divisionRoles.length > 0) {
                this.setFilterData(divisionRoles[0]);
                this.isLoading = false;
              } else {
                this.isLoading = false;
                if (this.setSelectedData.length != 0) {
                  var divisions = this.setSelectedData;
                  this.selectedDivisions = divisions.map(d => d.id);
                }
              }
            } else {
              this.$emit("DivisionSettingsFetchFailure");
              this.modal = false;
            }
          })
          .catch((err) => {
            if (err.response.status == 404 && err.response.data.errorCode == 104) {
              this.isLoading = false;
            }
            if (this.setSelectedData.length != 0) {
              var divisions = this.setSelectedData;
              this.selectedDivisions = divisions.map(d => d.id);
            }
          });
      }
    },
    onSave () {
      var divisionAccessSettings = JSON.stringify(this.getFilterData());
      this.$emit("OnSave", this.businessProfileId, this.userId, divisionAccessSettings);
    },
    closeModal () {
      this.modal = false;
    },
    clearFilters: function () {
      this.selectedDivisions = [];
    },
    setFilterData: function (data) {
      var selectedDataLength = 0;
      if (this.setSelectedData != undefined) {
        selectedDataLength = this.setSelectedData.length;
      }
      if (selectedDataLength != 0) {
        var divisions = this.setSelectedData;
        this.selectedDivisions = divisions.map(d => d.id);
      } else {
        if (data && data.userSettings) {
          var userSettingsJson = JSON.parse(data.userSettings);
          divisions = JSON.parse(userSettingsJson.Divisions);
          this.selectedDivisions = divisions.map(d => d.id);
        }
      }
    },
    getFilterData: function () {
      var selectedProperties = [];
      for (var i = 0; i < this.selectedDivisions.length; i++) {
        selectedProperties.push(
          this.divisionsList.find(d => d.id === this.selectedDivisions[i])
        );
      }
      selectedProperties = selectedProperties.map(s => {
        var obj = {
          id: s.id,
          name: s.name
        };
        return obj;
      });
      var divisions = JSON.stringify(selectedProperties);
      var userSetting = [{ name: "divisions", value: divisions }];
      var userSettingString = JSON.stringify(userSetting);
      return userSettingString;
    }
  },
  created: async function () {
    // eslint-disable-next-line no-undef
    this.businessProfileId = deltax.businessProfileId;
    this.apiService = new DivisionApiService();
  }
};
</script>

<style scoped>
label {
  margin-bottom: 7px;
  margin-top: 7px;
  margin-right: 5px;
}

.ivu-select.ivu-select-multiple {
  width: 100% !important;
}

.btn {
  margin-left: 8px;
  margin-bottom: 0;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #cccccc;
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.loader{
  text-align: center;
}

input {
  margin-right: 5px;
}

.ivu-select-dropdown-list {
  margin-left: 30px !important;
}

.ivu-modal-close{
  visibility: hidden;
}

.main{
  height: 100%;
}
::v-deep  .modal_placement{
    display: flex;
    align-items: center;
    justify-content: center;
}
::v-deep .ivu-modal{
    top: 0;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
