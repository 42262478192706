<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <div class="row">
          <div class="col-md-4">
            <h4>Locations <i v-show="loader" class="fa fa-spinner fa-spin" /></h4>
          </div>
          <div class="col-md-6 no-padding">
            <Input v-model="search" search placeholder="Search" class="search-bar input-search-bar" />
          </div>
        </div>
      </div>
      <VuePerfectScrollbar class="widget-body">
          <template>
            <div
              style="margin-top:10px"
              v-if="businessProfilesSearchEngineId==0"
              class="alert alert-info"
            >Please select a campaign configuration to see the saved locations.</div>
            <div
              style="margin-top:10px"
              v-else-if="!locations || locations.length<=0"
              class="alert alert-info"
            >There are no saved locations yet, add a new one to get started.</div>
          </template>
          <template v-if="businessProfilesSearchEngineId > 0">
            <Row v-for="location in filteredLocations" v-bind:key="location[idString]" class="cl-ivu-row">
              <Row-Col span="1">
                <i class="fas fa-copy action-icon"
                  @click="copyToClipboard('DXL-' + Number.parseInt(businessProfileId).toString(36) + '-' + location[idString])">
                </i>
              </Row-Col>
              <Row-Col span="15" offset="1" class="cl-widget-text">
                <span class="location-name">{{location[nameString]}}</span>
              </Row-Col>
            </Row>
          </template>
      </VuePerfectScrollbar>
    </Card>
  </div>
</template>
<script>
import { APIService } from "./../ApiService.js";
import { mapState } from "vuex";
import { Input, locale, Card, Row, Col } from "iview";
import lang from "iview/dist/locale/en-US";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
locale(lang);
export default {
  name: "LocationWidget",
  components: {
    Input,
    VuePerfectScrollbar,
    Card,
    "Row-Col": Col,
    Row
  },
  data: function () {
    return {
      search: "",
      loader: false,
      locations: [],
      idString: "facebookKey",
      nameString: "completePath"
    };
  },
  methods: {
    copyToClipboard (value) {
      let textarea = document.createElement("textarea")
      textarea.textContent = value
      textarea.style.position = "fixed"
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand("copy")
      } catch (e) {
        alert('Unable to copy to clipboard. Please copy manually.')
      }
      document.body.removeChild(textarea)
    }
  },
  computed: {
    ...mapState(["businessProfilesSearchEngineId", "businessProfileId", "savedMappedLocations", "mappedLocationsChanged", "selectedCampaignLauncherConfigId", "mediaPlanLineItems", "selectedMediaPlan", "isPromotionMode", "promotionObject"]),
    filteredLocations () {
      let searchTerm = this.search;
      if (searchTerm != "") {
        return this.locations.filter(e => {
          return e.name.toLowerCase().includes(searchTerm.toLowerCase())
        })
      } else {
        return this.locations;
      }
    }
  },
  watch: {
    mappedLocationsChanged () {
      if (this.selectedMediaPlan) {
        this.locations = this.savedMappedLocations[this.selectedMediaPlan.id + '-' + this.selectedCampaignLauncherConfigId];
        this.locations = this.locations ? this.locations.filter((v, i, a) => a.findIndex(t => (t[this.idString] === v[this.idString])) === i) : []
      }
    },
    businessProfilesSearchEngineId () {
      if (this.selectedMediaPlan) {
        this.locations = this.savedMappedLocations[this.selectedMediaPlan.id + '-' + this.selectedCampaignLauncherConfigId];
        this.locations = this.locations ? this.locations.filter((v, i, a) => a.findIndex(t => (t[this.idString] === v[this.idString])) === i) : []
      }
    },
    selectedCampaignLauncherConfigId () {
      if (this.$store.state.selectedCampaignLauncherConfig && this.$store.state.selectedCampaignLauncherConfig.campaign) {
        if (this.$store.state.selectedCampaignLauncherConfig.campaign.locationMode == "Hyperlocal") {
          this.idString = "storeCode"
          this.nameString = "name"
        } else {
          this.idString = "facebookKey"
          this.nameString = "completePath"
        }
      }
      if (this.selectedMediaPlan) {
        this.locations = this.savedMappedLocations[this.selectedMediaPlan.id + '-' + this.selectedCampaignLauncherConfigId];
        this.locations = this.locations ? this.locations.filter((v, i, a) => a.findIndex(t => (t[this.idString] === v[this.idString])) === i) : []
      }
    },
    mediaPlanLineItems () {
      if (this.selectedMediaPlan) {
        this.locations = this.savedMappedLocations[this.selectedMediaPlan.id + '-' + this.selectedCampaignLauncherConfigId];
        this.locations = this.locations ? this.locations.filter((v, i, a) => a.findIndex(t => (t[this.idString] === v[this.idString])) === i) : []
      }
    },
    async isPromotionMode () {
      if (this.isPromotionMode) {
        let response = await APIService.getStores();
        var storeCodeList = [];
        if (response.data.data) {
          let stores = response.data.data.filter(x => x.name);
          storeCodeList = stores
            .filter(store => this.promotionObject.enabledStoreIds.includes(store.id))
            .map(({ storeCode, name }) => ({ storeCode, name }));
        }
        if (storeCodeList.length > 0) {
          storeCodeList.forEach(store => {
            this.locations.push({
              [this.idString]: store.storeCode,
              [this.nameString]: store.name
            });
          });
        }
      }
    }
  }
};
</script>
<style scoped>
.widget-body > table {
  width: 100%;
}
.widget-body > table > tr > td {
  padding-top: 10px;
}
.widget-element {
  height: 30px;
}
.widget > hr {
  margin: 0px;
}
.loader-div {
  width: inherit;
  text-align: center;
  height: inherit;
}
.ellipsis {
  width: 50%;
  position: relative;
}
.ellipsis:before {
  content: "&nbsp;";
  visibility: hidden;
}
.ellipsis span {
  width: 190px;
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style>
.location-tooltip .tooltip .tooltip-inner {
  max-width: 600px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: normal;
  word-break: initial;
  padding: 5px 10px;
}
.location-info-table {
  max-width: 300px !important;
  min-width: 250px !important;
}
.location-modal .modal-dialog {
  width: 98%;
  height: 100%;
}
.input-search-bar .ivu-input {
  height: 30px;
}
.tooltip-size {
  max-width: 500px;
  max-height: 200px;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
