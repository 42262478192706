import { Objective } from "./Objective.js"
import { objectives } from "../../Constants/FacebookEnums.js"

let ColumnConfig = function (config) {
  if (config && config.campaign && config.campaign.productCatalog) {
    let single = new function () {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Call To Action", "HOT_Primary Text", "HOT_News Feed Link Description", "HOT_Ad Title", "HOT_Url Tags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions"];
      this.requiredBase = ["HOT_Ad Name", "HOT_Call To Action", "HOT_Primary Text", "HOT_News Feed Link Description"];
      this.card = [];
      this.requiredCard = [];
      this.callToAction = ["Download", "Learn More", "Sign Up", "Subscribe", "Book Now", "Get Showtimes", "Listen Now", "Open Link", "Shop Now"];
    }();
    let carousel = new function () {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Primary Text", "HOT_Deep Link", "HOT_Call To Action", "HOT_Optimize Card Order", "HOT_Catalog Options", "HOT_Catalog Card: Creative", "HOT_Catalog Card: Headline", "HOT_Catalog Card: News Feed Link Description", "HOT_Android Url", "HOT_iOS Url", "HOT_Windows Phone Url", "HOT_Ad Title", "HOT_Url Tags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions"];
      this.requiredBase = ["HOT_Ad Name", "HOT_Primary Text", "HOT_Deep Link", "HOT_Call To Action", "HOT_Catalog Options", "HOT_Catalog Card: Creative", "HOT_Catalog Card: Headline", "HOT_Catalog Card: News Feed Link Description"];
      this.card = []
      this.requiredCard = []
      this.callToAction = ["Download", "Learn More", "Sign Up", "Subscribe", "Book Now", "Get Showtimes", "Listen Now", "Open Link", "Shop Now"];
    }();
    return { single, carousel };
  } else {
    let single = new function () {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_Call To Action", "HOT_Headline", "HOT_Primary Text", "HOT_Deep Link", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations"]
      this.requiredBase = ["HOT_Ad Name", "HOT_Call To Action", "HOT_Image/Video"]
      this.card = []
      this.requiredCard = []
      this.callToAction = ["Book Travel", "Download", "Install Mobile App", "Learn More", "Listen now", "Play Game", "Shop Now", "Sign Up", "Subscribe", "Use App", "Watch More"]
      this.placementCustomizationHeaders = ["HOT_Placements", "HOT_Image/Video", "HOT_Primary Text", "HOT_Headline"]
    }();
    let carousel = new function () {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Call To Action", "HOT_Optimize Card Order", "HOT_Primary Text", "HOT_Deep Link", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations"];
      this.requiredBase = ["HOT_Ad Name", "HOT_Call To Action"]
      this.card = ["HOT_Headline", "HOT_Image/Video", "HOT_Deep Link"]
      this.requiredCard = ["HOT_Image/Video"]
      this.callToAction = ["Book Travel", "Download", "Install Mobile App", "Learn More", "Listen now", "Play Game", "Shop Now", "Sign Up", "Subscribe", "Use App", "Watch More"]
    }();

    let singleCatalog = new function () {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Website Url", "HOT_Call To Action", "HOT_Primary Text", "HOT_Deep Link Destination", "HOT_News Feed Link Description", "HOT_ProductSets", "HOT_Android Url", "HOT_iOS Url", "HOT_Windows Phone Url", "HOT_Ad Title", "HOT_Url Tags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions"]
      this.requiredBase = ["HOT_Ad Name", "HOT_Website Url", "HOT_Call To Action", "HOT_Primary Text", "HOT_ProductSets"]
      this.card = []
      this.requiredCard = []
      this.callToAction = ["Download", "Learn More", "Sign Up", "Subscribe", "Book Now", "Get Showtimes", "Listen Now", "Open Link", "Shop Now"]
    }();

    let carouselCatalog = new function () {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Primary Text", "HOT_See More Url", "HOT_Deep Link", "HOT_Call To Action", "HOT_Catalog Options", "HOT_Deep Link Destination", "HOT_Catalog Card: Creative", "HOT_Catalog Card: Headline", "HOT_Catalog Card: News Feed Link Description", "HOT_ProductSets", "HOT_Android Url", "HOT_iOS Url", "HOT_Windows Phone Url", "HOT_Ad Title", "HOT_Url Tags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions"]
      // "HOT_Intro Card: Image", "HOT_Intro Card: Headline", "HOT_Intro Card: News Feed Link Description", "HOT_Intro Card: Website Url"
      this.requiredBase = ["HOT_Ad Name", "HOT_Primary Text", "HOT_Call To Action", "HOT_Catalog Options", "HOT_See More Url", "HOT_Catalog Card: Creative", "HOT_Catalog Card: Headline", "HOT_ProductSets"]
      this.card = []
      this.requiredCard = []
      this.callToAction = ["Download", "Learn More", "Sign Up", "Subscribe", "Book Now", "Get Showtimes", "Listen Now", "Open Link", "Shop Now"]
    }();

    if (config.campaign.objective == objectives["App Promotion"] && !config.campaign.productCatalog) {
      return { single, carousel, singleCatalog, carouselCatalog }
    }

    return { single, carousel };
  }
}

let CreativeSpec = function (config) {
  if (config && config.campaign && config.campaign.productCatalog) {
    let single = new function () {
      this.catalogueInformation = {
        overlay_shape: "HOT_Catalogue Information Shape",
        text_font: "HOT_Catalogue Information Font",
        shape_color: "HOT_Catalogue Information Shape Colour",
        text_color: "HOT_Catalogue Information Text Colour",
        opacity: "HOT_Catalogue Information Opacity",
        overlay_position: "HOT_Catalogue Information Position",
        layer_type: "text_overlay",
        content: {
          type: "HOT_Catalogue Information Type"
        }
      };
      this.frame = {
        layer_type: "frame_overlay",
        blending_mode: "HOT_Frame Blending",
        frame_image_hash: "HOT_Frame Image",
        frame_source: "custom",
        opacity: "HOT_Frame Opacity",
        overlay_position: "HOT_Frame Position",
        scale: "HOT_Frame Size"
      };
      this.creative = {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "template_data": {
            "call_to_action": {
              "type": "HOT_Call To Action"
            },
            "description": "HOT_News Feed Link Description",
            "force_single_link": true,
            "link": "HOT_Link",
            "message": "HOT_Primary Text",
            "name": "HOT_Ad Title"
          }
        },
        "url_tags": "HOT_Url Tags"
      }
    }()

    let carousel = new function () {
      this.catalogueInformation = {
        overlay_shape: "HOT_Catalogue Information Shape",
        text_font: "HOT_Catalogue Information Font",
        shape_color: "HOT_Catalogue Information Shape Colour",
        text_color: "HOT_Catalogue Information Text Colour",
        opacity: "HOT_Catalogue Information Opacity",
        overlay_position: "HOT_Catalogue Information Position",
        layer_type: "text_overlay",
        content: {
          type: "HOT_Catalogue Information Type"
        }
      };
      this.frame = {
        layer_type: "frame_overlay",
        blending_mode: "HOT_Frame Blending",
        frame_image_hash: "HOT_Frame Image",
        frame_source: "custom",
        opacity: "HOT_Frame Opacity",
        overlay_position: "HOT_Frame Position",
        scale: "HOT_Frame Size"
      };
      this.creative = {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "template_data": {
            "message": "HOT_Primary Text",
            "link": "HOT_Link",
            "name": "HOT_Catalog Card: Headline",
            "call_to_action": {
              "type": "HOT_Call To Action"
            },
            "description": "HOT_Catalog Card: News Feed Link Description",
            "format_option": "HOT_Catalog Card: Creative",
            "multi_share_end_card": false
          }
        },
        "url_tags": "HOT_Url Tags"
      }
    }()
    return { single, carousel }
  } else {
    let single = new function () {
      this.image = {
        "creative": {
          "object_story_spec": {
            "link_data": {
              "call_to_action": {
                "value": {
                  "link": "HOT_Link",
                  "app_link": "HOT_Deep Link"
                },
                "type": "HOT_Call To Action"
              },
              "picture": "HOT_Image/Video",
              "link": "HOT_Link",
              "message": "HOT_Primary Text",
              "name": "HOT_Headline"
            },
            "page_id": "HOT_Page"
          }
        }
      }
      this.video = {
        "creative": {
          "object_story_spec": {
            "video_data": {
              "call_to_action": {
                "value": {
                  "link": "HOT_Link",
                  "app_link": "HOT_Deep Link"
                },
                "type": "HOT_Call To Action"
              },
              "video_id": "HOT_SearchEngineVideoId",
              "title": "HOT_Headline",
              "message": "HOT_Primary Text",
              "image_url": "HOT_Image/Video"
            },
            "page_id": "HOT_Page"
          }
        }
      }
      this.dynamicCreative = {
        metadata: new function () {
          this.colToAssetSpecMap = {
            "HOT_Image/Video": "images",
            "HOT_Primary Text": "bodies",
            "HOT_Call To Action": "call_to_action_types",
            "HOT_Headline": "titles"
          };
          this.headlineRemap = {};
        }(),
        image: {
          creative: {
            object_story_spec: {
              page_id: "HOT_Page"
            },
            asset_feed_spec: {
              images: [ // required. Max: 10
                {
                  url: "HOT_Image/Video"
                }
              ],
              bodies: [ // Max: 5
                {
                  text: "HOT_Primary Text"
                }
              ],
              call_to_action_types: ["HOT_Call To Action"], // required. Max: 5
              link_urls: [ // optional. Max: 1
                {
                  website_url: "HOT_Link",
                  deeplink_url: "HOT_Deep Link"
                }
              ],
              titles: [ // Max: 5
                {
                  text: "HOT_Headline"
                }
              ],
              ad_formats: ["SINGLE_IMAGE"]
            }
          }
        },
        video: {
          creative: {
            object_story_spec: {
              page_id: "HOT_Page"
            },
            asset_feed_spec: {
              videos: [ // required. Max: 10
                {
                  video_id: "HOT_SearchEngineVideoId",
                  thumbnail_url: "HOT_Image/Video"
                }
              ],
              bodies: [ // Max: 5
                {
                  text: "HOT_Primary Text"
                }
              ],
              call_to_action_types: ["HOT_Call To Action"], // required. Max: 5
              link_urls: [ // optional. Max: 1
                {
                  deeplink_url: "HOT_Deep Link"
                }
              ],
              titles: [ // Max: 5
                {
                  text: "HOT_Headline"
                }
              ],
              ad_formats: ["SINGLE_VIDEO"]
            }
          }
        }
      }
      this.placementCustomization = {}
    }()
    let carousel = {
      creative: {
        object_story_spec: {
          link_data: {
            call_to_action: {
              value: {
                "link": "HOT_Link",
                "app_link": "HOT_Deep Link"
              },
              type: "HOT_Call To Action"
            },
            link: "HOT_Link",
            message: "HOT_Primary Text",
            child_attachments: [
              {
                link: "HOT_Link",
                name: "HOT_Headline",
                call_to_action: {
                  type: "HOT_Call To Action",
                  value: {
                    link: "HOT_Link",
                    app_link: "HOT_Deep Link"
                  }
                },
                picture: "HOT_Image/Video"
              }
            ],
            "multi_share_optimized": "HOT_Optimize Card Order"
          },
          page_id: "HOT_Page"
        }
      }
    }
    let singleCatalog = {
      catalogueInformation: {
        overlay_shape: "HOT_Catalogue Information Shape",
        text_font: "HOT_Catalogue Information Font",
        shape_color: "HOT_Catalogue Information Shape Colour",
        text_color: "HOT_Catalogue Information Text Colour",
        opacity: "HOT_Catalogue Information Opacity",
        overlay_position: "HOT_Catalogue Information Position",
        layer_type: "text_overlay",
        content: {
          type: "HOT_Catalogue Information Type"
        }
      },
      frame: {
        layer_type: "frame_overlay",
        blending_mode: "HOT_Frame Blending",
        frame_image_hash: "HOT_Frame Image",
        frame_source: "custom",
        opacity: "HOT_Frame Opacity",
        overlay_position: "HOT_Frame Position",
        scale: "HOT_Frame Size"
      },
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "template_data": {
            "call_to_action": {
              "type": "HOT_Call To Action"
            },
            "description": "HOT_News Feed Link Description",
            "force_single_link": true,
            "link": "HOT_Website Url",
            "message": "HOT_Primary Text",
            "name": "HOT_Ad Title"
          }
        },
        "applink_treatment": "HOT_Deep Link Destination",
        "template_url_spec": {
          "android": {
            "url": "HOT_Android Url"
          },
          "ios": {
            "url": "HOT_iOS Url"
          },
          "web": {
            "url": "HOT_Website Url"
          },
          "windows_phone": {
            "url": "HOT_Windows Phone Url"
          }
        },
        "url_tags": "HOT_Url Tags"
      }
    }
    let carouselCatalog = {
      catalogueInformation: {
        overlay_shape: "HOT_Catalogue Information Shape",
        text_font: "HOT_Catalogue Information Font",
        shape_color: "HOT_Catalogue Information Shape Colour",
        text_color: "HOT_Catalogue Information Text Colour",
        opacity: "HOT_Catalogue Information Opacity",
        overlay_position: "HOT_Catalogue Information Position",
        layer_type: "text_overlay",
        content: {
          type: "HOT_Catalogue Information Type"
        }
      },
      frame: {
        layer_type: "frame_overlay",
        blending_mode: "HOT_Frame Blending",
        frame_image_hash: "HOT_Frame Image",
        frame_source: "custom",
        opacity: "HOT_Frame Opacity",
        overlay_position: "HOT_Frame Position",
        scale: "HOT_Frame Size"
      },
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "template_data": {
            "message": "HOT_Primary Text",
            "link": "HOT_See More Url",
            "name": "HOT_Catalog Card: Headline",
            "call_to_action": {
              "type": "HOT_Call To Action"
            },
            "description": "HOT_Catalog Card: News Feed Link Description",
            "format_option": "HOT_Catalog Card: Creative",
            "multi_share_end_card": false
          }
        },
        "applink_treatment": "HOT_Deep Link Destination",
        "template_url_spec": {
          "android": {
            "url": "HOT_Android Url"
          },
          "ios": {
            "url": "HOT_iOS Url"
          },
          "web": {
            "url": "HOT_Deep Link"
          },
          "windows_phone": {
            "url": "HOT_Windows Phone Url"
          }
        },
        "url_tags": "HOT_Url Tags",
        "product_set_id": "HOT_ProductSets"
      }
    }
    if (config.campaign.objective == objectives["App Promotion"] && !config.campaign.productCatalog) {
      return { single, carousel, singleCatalog, carouselCatalog }
    }

    return { single, carousel }
  }
}

let appInstall = new Objective(ColumnConfig, CreativeSpec)
export { appInstall }
