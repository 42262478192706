import * as fbEnums from '../../CampaignLauncher/Constants/FacebookEnums';

export const PromotionStatus = {
  draft: 1,
  enabled: 2,
  deleted: 3,
  disabled: 4
};

export const AssetType = {
  hq: 1,
  local: 2
};

export const deatinationTypes = {
  Website: {
    enum: fbEnums.destinationType.Page,
    objectives: [fbEnums.objectives.Conversions, fbEnums.objectives.Traffic]
  },
  'Click to Messenger': {
    enum: fbEnums.destinationType.Messenger,
    objectives: [
      fbEnums.objectives.Conversions,
      fbEnums.objectives.Traffic,
      fbEnums.objectives.Messages
    ]
  },
  WhatsApp: {
    enum: fbEnums.destinationType.WhatsApp,
    objectives: [
      fbEnums.objectives.Conversions,
      fbEnums.objectives.Traffic,
      fbEnums.objectives.Messages
    ]
  }
};

export const StoreType = {
  selective: 1,
  all: 2,
  whatsappEnabled: 3,
  whatsappDisabled: 4
};

export const PromotionType = {
  Paid: 1,
  Organic: 2
}

export const ScheduleType = {
  AlwaysOn: 1,
  Businesshour: 2,
  Custom: 3
}

export const BudgetType = {
  Daily: 1,
  LifeTime: 2
}

export const PromoBudgetType = {
  Total: 1,
  PerStore: 2
}

export const FundingSource = {
  Hq: 1,
  Local: 2,
  CoFunded: 3
}

export const VideoProcessingStatus = {
  New: 1,
  Exported: 8,
  Failed: 10
}

export const FacebookPagePostTypes = {
  Link: 1,
  Image: 2,
  Video: 3
};

export const SearchEngines = {
  Facebook: 5,
  Google: 1
}

export const Assets = [{
  id: 1,
  label: "Asset 1",
  value: "{{store.Asset1}}"
}, {
  id: 2,
  label: "Asset 2",
  value: "{{store.Asset2}}"
}, {
  id: 3,
  label: "Asset 3",
  value: "{{store.Asset3}}"
}, {
  id: 4,
  label: "Asset 4",
  value: "{{store.Asset4}}"
}, {
  id: 5,
  label: "Asset 5",
  value: "{{store.Asset5}}"
}, {
  id: 6,
  label: "Asset 6",
  value: "{{store.Asset6}}"
}, {
  id: 7,
  label: "Asset 7",
  value: "{{store.Asset7}}"
}, {
  id: 8,
  label: "Asset 8",
  value: "{{store.Asset8}}"
}, {
  id: 9,
  label: "Asset 9",
  value: "{{store.Asset9}}"
}, {
  id: 10,
  label: "Asset 10",
  value: "{{store.Asset10}}"
}, {
  id: 11,
  label: "Asset 11",
  value: "{{store.Asset11}}"
}, {
  id: 12,
  label: "Asset 12",
  value: "{{store.Asset12}}"
}, {
  id: 13,
  label: "Asset 13",
  value: "{{store.Asset13}}"
}, {
  id: 14,
  label: "Asset 14",
  value: "{{store.Asset14}}"
}, {
  id: 15,
  label: "Asset 15",
  value: "{{store.Asset15}}"
}, {
  id: 16,
  label: "Asset 16",
  value: "{{store.Asset16}}"
}, {
  id: 17,
  label: "Asset 17",
  value: "{{store.Asset17}}"
}, {
  id: 18,
  label: "Asset 18",
  value: "{{store.Asset18}}"
}, {
  id: 19,
  label: "Asset 19",
  value: "{{store.Asset19}}"
}, {
  id: 20,
  label: "Asset 20",
  value: "{{store.Asset20}}"
}, {
  id: 21,
  label: "Asset 21",
  value: "{{store.Asset21}}"
}, {
  id: 22,
  label: "Asset 22",
  value: "{{store.Asset22}}"
}, {
  id: 23,
  label: "Asset 23",
  value: "{{store.Asset23}}"
}, {
  id: 24,
  label: "Asset 24",
  value: "{{store.Asset24}}"
}, {
  id: 25,
  label: "Asset 25",
  value: "{{store.Asset25}}"
}
]

export const PromotionVersionStatus = {
  InDraft: 1,
  Enabled: 2,
  Archived: 3
};
