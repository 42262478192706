<template>
    <div>
        <i-row type="flex" :gutter="16">
            <i-col>
                <span>Publisher Recipients</span>
            </i-col>
            <i-col><i-switch v-model="isActive" size="small" :disabled="!isEdit"></i-switch></i-col>
            <i-col><span v-if="invalidEmailError" class="invalid-email-text"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>Invalid Email</span></i-col>
        </i-row>
        <i-row>
            <i-col :span="10">
                <InputTag  v-model="supplierEmails"  v-if="isActive"  :class="{ 'disabled-input-tag': !isEdit }" @input="validateEmails"></InputTag>
            </i-col>
        </i-row>

    </div>
</template>

<script>

import { Row, Col, Switch } from 'iview';
import InputTag from 'vue-input-tag'

export default {
  components: {
    'i-row': Row,
    'i-col': Col,
    'i-switch': Switch,
    InputTag
  },
  data () {
    return {
      isActive: null,
      supplierEmails: [],
      isEdit: true,
      invalidEmailError: false
    }
  },
  methods: {
    validateEmails () {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const invalidEmails = this.supplierEmails.filter(email => !emailPattern.test(email));
      this.supplierEmails = this.supplierEmails.filter(email => emailPattern.test(email));
      if (invalidEmails.length) {
        this.invalidEmailError = true;
        setTimeout(() => {
          this.invalidEmailError = false;
        }, 5000);
      } else {
        this.invalidEmailError = false;
      }
    }
  }
}
</script>
<style scoped>
.disabled-input-tag {
    pointer-events: none;
    opacity: 0.4;
}

.fa-exclamation-triangle {
  color: red;
  font-size: 12px;
  margin-left: 20px;
  margin-right: 4px;
}

::v-deep .ivu-col .invalid-email-text {
  margin-left: 30px;
  color: red !important;
}

::v-deep .ivu-col span{
  color: #2c2e2f !important;
  font-size: 13px !important;
}
::v-deep .vue-input-tag-wrapper .input-tag{
    background-color: #dcdee2 !important;
    border: 1px solid #dcdee2 !important;
    color: #2c2e2f !important;
}
::v-deep .vue-input-tag-wrapper .input-tag .remove{
    color: #2c2e2f !important;
}

::v-deep .vue-input-tag-wrapper{
  margin-top: 7px;
}

::v-deep .ivu-col-span-10 {
    width: 50% !important;
}
</style>
