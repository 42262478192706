<template>
  <div>
    <Row style="margin: 6px 0;">
      <Row-Col span="10">
        <i-input size="small" v-model="enitySearchQuery" placeholder="Search" />
      </Row-Col>
      <Row-Col span="14">
        <button-group size="small" style="float: right;">
          <i-button v-for="(className, status) in adwordsStatusIcons" :key="status"
            :class="{ 'status-active-btn': entityStatusToShow.includes(status) }"
            @click="toggleEntityStatus(status)">
            <i v-if="status != 'All'" :class="className"></i>
            <Icon v-else :type="className"></Icon>
          </i-button>
        </button-group>
      </Row-Col>
    </Row>
    <ul class="summmary-list">
      <li v-for="(campaign, campaignIndex) in publishSummary" :key="campaignIndex">
        <div data-toggle="collapse" class="summary-collapse" v-bind:href="'#campaign-summary-' + campaignIndex">
          <span>
            <i v-bind:class="getEntityStatusIcon(campaign.processingStatus)"></i>
            <span>
              {{ campaign.name }}
            </span>
            <span v-if="campaign.id != 0
              " class="entity-label">
              ({{ campaign.id }})
            </span>
          </span>
          <span class="entity-export-error" v-if="campaign.failureReason != ''">{{
            getFailureErrorMessage(campaign.failureReason) }}</span>
        </div>
        <ul v-bind:id="'campaign-summary-' + campaignIndex" class="collapse-open collapse in campaign-summary"
          v-if="deltax.isAdwordsCampaignLaucherFlow">
          <li class="panel-collapse" v-if="campaign.negativeKeywordListIds.length != 0">
            <span>
              <i v-bind:class="campaign.isNegativeKeywordExported
                  ? 'fa fa-check-circle green'
                  : 'fa fa-clock-o yellow'
                "></i>
              <span>
                {{
                  `Negative Keywords(${campaign.negativeKeywordListIds.length})`
                }}
              </span>
            </span>
          </li>
          <li class="panel-collapse" v-for="(adgroup, adgroupIndex) in campaign.adgroups" :key="adgroupIndex + 'adg'">
            <div data-toggle="collapse" class="summary-collapse collapsed"
              :href="`#adgroup-summary-${campaignIndex}-${adgroupIndex}`">
              <span>
                <i v-bind:class="getEntityStatusIcon(adgroup.processingStatus)"></i>
                <span>
                  {{ adgroup.name }}
                </span>
                <span v-if="adgroup.id != 0" class="entity-label">
                  ({{ adgroup.id }})
                </span>
              </span>
              <span class="entity-export-error" v-if="adgroup.failureReason != ''">{{
                getFailureErrorMessage(adgroup.failureReason) }}</span>
            </div>
            <ul :id="`adgroup-summary-${campaignIndex}-${adgroupIndex}`" class="collapse-open collapse adgroup-summary">
              <li class="panel-collapse" v-for="(entity, childIndex) in getAdgroupChildEntities(adgroup)"
                :key="childIndex + 'ch'">
                <span>
                  <template v-if="entity.publishStatusMessage != ''">
                    <Tooltip max-width="200" :content="entity.publishStatusMessage">
                      <i v-bind:class="entity.publishStatusIcon"></i>
                    </Tooltip>
                  </template>
                  <template v-else>
                    <i v-bind:class="entity.publishStatusIcon"></i>
                  </template>
                  <span>
                    {{ `${entity.entityType}(${entity.count})` }}
                  </span>
                </span>
              </li>
              <li class="panel-collapse" v-for="(ad, adIndex) in adgroup.ads" :key="adIndex + 'ad'">
                <div data-toggle="collapse" class="summary-collapse collapsed"
                  :href="`#asset-collapse-${campaignIndex}-${adgroupIndex}-${adIndex}`">
                  <span>
                    <i v-bind:class="getEntityStatusIcon(ad.processingStatus)" class="no-pointer"></i>
                    <span class="adName">
                      {{ ad.name }}
                    </span>
                    <span class="adName extraAdName" v-if="ad.headlines && ad.headlines.length > 3">
                      &nbsp;+{{ ad.headlines.length - 3 }} more
                    </span>
                    <span v-if="ad.id != 0" class="entity-label">
                      ({{ ad.id }})
                    </span>
                  </span>
                  <span class="entity-export-error" v-if="ad.failureReason != ''">{{
                    getFailureErrorMessage(ad.failureReason) }}</span>
                </div>
                <div class="collapse" :id="`asset-collapse-${campaignIndex}-${adgroupIndex}-${adIndex}`">
                  <ul :id="`adgroup-summary-${campaignIndex}-${adgroupIndex}-${adIndex}`" class="collapse-open collapse in adgroup-summary">
                  <li class="panel-collapse" v-for="(entity, childIndex) in getAssetGroupChildEntities(
                    ad
                  )" :key="adgroupIndex + childIndex + 'ch'">
                    <span v-if="entity.Count > 0">
                      {{ `${entity.Name}(${entity.Count})` }}
                    </span>
                  </li>
                </ul>
                </div>
              </li>
            </ul>
          </li>
          <li class="panel-collapse" v-for="(assetGroup, assetGroupIndex) in campaign.assetGroups"
            :key="assetGroupIndex + 'asg'">
            <div data-toggle="collapse" class="summary-collapse"
              :href="`#assetGroup-summary-${campaignIndex}-${assetGroupIndex}`">
              <span>
                <i v-bind:class="getEntityStatusIcon(assetGroup.processingStatus)
                  "></i>
                <span>
                  {{ assetGroup.name }}
                </span>
                <span v-if="assetGroup.id != 0" class="entity-label">
                  ({{ assetGroup.id }})
                </span>
                <span v-if="isStatusEdited(assetGroup)" class="entity-label"> Edited: Status </span>
              </span>
              <span class="entity-export-error" v-if="assetGroup.failureReason != ''">{{
                getFailureErrorMessage(assetGroup.failureReason, true) }}</span>
            </div>
            <ul :id="`assetGroup-summary-${campaignIndex}-${assetGroupIndex}`"
              class="collapse-open collapse in adgroup-summary">
              <li class="panel-collapse" v-for="(entity, childIndex) in getAssetGroupChildEntities(
                assetGroup
              )" :key="childIndex + 'ch'">
                <span>
                  <template>
                    <i v-bind:class="getEntityStatusIcon(entity.processingStatus)
                      "></i>
                  </template>
                  <span>
                    {{ `${entity.entityType} (${entity.count})` }}
                  </span>
                  <span class="entity-export-error" v-if="entity.failureReason != ''">{{ entity.failureReason }}</span>
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <ul v-bind:id="'campaign-summary-' + campaignIndex" class="collapse-open collapse in campaign-summary" v-else>
          <li class="panel-collapse" v-for="(adgroup, adgroupIndex) in campaign.childEntities" :key="adgroupIndex">
            <div data-toggle="collapse" class="summary-collapse collapsed"
              :href="`#adgroup-summary-${campaignIndex}-${adgroupIndex}`">
              <span>
                <i v-bind:class="getEntityStatusIcon(adgroup.status)"></i>
                <span>
                  {{ adgroup.name }}
                </span>
              </span>
              <span class="entity-export-error" v-if="adgroup.failureReason != ''">{{
                getFailureErrorMessage(adgroup.failureReason) }}</span>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { adwordsEnums } from "../../Constants/AdwordsEnums";
import { Tooltip, Input, Row, Col, Icon, ButtonGroup, Button } from "iview";
import { adwordsAssetType, adwordsAppAssetType, adwordsDisplayAssetType, adwordsDemandAssetType, adwordsAssetTypeField } from "../../Constants/AdwordsAdsheetSettingsRequirements.js"

export default {
  data: function () {
    return {
      adwordsEnums: adwordsEnums,
      adwordsStatusIcons: {
        "All": "ios-albums",
        "Success": "fa fa-check-circle green",
        "Failed": "fa fa-exclamation-triangle red",
        "New": "fa fa-clock-o yellow",
        "Edited": "fa fa-pencil yellow"
      },
      entityStatusToShow: ["All"],
      enitySearchQuery: ""
    };
  },
  components: {
    Tooltip,
    "i-input": Input,
    Row,
    "Row-Col": Col,
    Icon,
    "button-group": ButtonGroup,
    "i-button": Button
  },
  computed: {
    ...mapState([
      "adwordsEntitiesResponse",
      "deltax",
      "selectedCampaignLauncherConfig"
    ]),
    publishSummary: function () {
      var adwordsEntities = [];
      if (this.deltax.isAdwordsCampaignLaucherFlow) {
        adwordsEntities = JSON.parse(JSON.stringify(this.adwordsEntitiesResponse.campaigns));
      } else {
        adwordsEntities = JSON.parse(JSON.stringy(this.adwordsEntitiesResponse.adwordsEntities));
      }

      // status and search filter logic
      const showAllStatus = this.entityStatusToShow.length == 0 || this.entityStatusToShow.includes("All");
      let query = this.enitySearchQuery.trim();
      if (query) {
        adwordsEntities = this.getQueryFilteredCampaigns(adwordsEntities, query);
      }
      if (!showAllStatus) {
        adwordsEntities = this.getStatusFilteredCampaigns(adwordsEntities);
      }
      return adwordsEntities;
    }
  },
  methods: {
    getEntitiesCount (entities) {
      let count = 0;
      entities.forEach(entity => {
        count++;
      });
      return count;
    },
    getEntitiesPublishStatusIcon (entities) {
      let newEntities = entities.filter(
        e => e.processingStatus == adwordsEnums.exportStatus.New
      );
      let failedEntities = entities.filter(
        e => e.processingStatus == adwordsEnums.exportStatus.Failed
      );

      if (newEntities.length > 0) {
        return "fa fa-clock-o yellow";
      } else if (newEntities.length == 0 && failedEntities.length > 0) {
        return "fa fa-exclamation-triangle red";
      } else {
        return "fa fa-check-circle green";
      }
    },
    getEntitiesPublishStatusMsg (entities, entityType) {
      let failedEntities = this.getEntitiesStatusCount(
        entities,
        adwordsEnums.exportStatus.Failed
      );
      if (failedEntities > 0) {
        return (
          failedEntities +
          " of " +
          entities.length +
          " " +
          entityType +
          " failed to publish"
        );
      }
      return "";
    },
    getAdgroupChildEntities (adgroup) {
      let adgroupChildEntities = [];
      // get keywords
      if (this.getEntitiesCount(adgroup.keywords) > 0) {
        adgroupChildEntities.push({
          entityType: "Keywords",
          count: this.getEntitiesCount(adgroup.keywords),
          publishStatusMessage: this.getEntitiesPublishStatusMsg(
            adgroup.keywords,
            "Keywords"
          ),
          publishStatusIcon: this.getEntitiesPublishStatusIcon(adgroup.keywords)
        });
      }

      // get AdExtension
      if (
        this.getEntitiesCount([
          ...adgroup.adgroupAssets,
          ...adgroup.adGroupFeedExtensions
        ]) > 0
      ) {
        adgroupChildEntities.push({
          entityType: "Ad Extensions",
          count: this.getEntitiesCount([
            ...adgroup.adgroupAssets,
            ...adgroup.adGroupFeedExtensions
          ]),
          publishStatusMessage: this.getEntitiesPublishStatusMsg(
            [...adgroup.adgroupAssets, ...adgroup.adGroupFeedExtensions],
            "Ad Extensions"
          ),
          publishStatusIcon: this.getEntitiesPublishStatusIcon([
            ...adgroup.adgroupAssets,
            ...adgroup.adGroupFeedExtensions
          ])
        });
      }
      return adgroupChildEntities;
    },
    getAssetGroupChildEntities (assetGroup) {
      if (this.selectedCampaignLauncherConfig.campaign.type === adwordsEnums.campaignType["Performance Max"]) {
        let assetGroupChildEntities = [];
        var nameEntity = this.getEntityObject(
          assetGroup,
          assetGroup.name,
          adwordsAssetType.name
        )
        assetGroupChildEntities = nameEntity ? [nameEntity] : []
        var finalUrlEntity = this.getEntityObject(
          assetGroup,
          assetGroup.finalUrl,
          adwordsAssetType.finalUrl
        )
        if (finalUrlEntity) {
          assetGroupChildEntities.push(finalUrlEntity);
        }
        assetGroupChildEntities.push(
          this.getEntityObject(
            assetGroup,
            assetGroup.headlines,
            adwordsAssetType.headline
          )
        );
        assetGroupChildEntities.push(
          this.getEntityObject(
            assetGroup,
            assetGroup.longHeadlines,
            adwordsAssetType.longHeadline
          )
        );
        assetGroupChildEntities.push(
          this.getEntityObject(
            assetGroup,
            assetGroup.descriptions,
            adwordsAssetType.description
          )
        );
        assetGroupChildEntities.push(
          this.getEntityObject(
            assetGroup,
            assetGroup.businessName,
            adwordsAssetType.businessName
          )
        );
        assetGroupChildEntities.push(
          this.getEntityObject(
            assetGroup,
            assetGroup.logos,
            adwordsAssetType.logo
          )
        );
        assetGroupChildEntities.push(
          this.getEntityObject(
            assetGroup,
            assetGroup.marketingImages,
            adwordsAssetType.marketingImage
          )
        );
        assetGroupChildEntities.push(
          this.getEntityObject(
            assetGroup,
            assetGroup.squareMarketingImages,
            adwordsAssetType.squareMarketingImage
          )
        );
        if (assetGroup.landscapeLogos && assetGroup.landscapeLogos.length > 0) {
          assetGroupChildEntities.push(
            this.getEntityObject(
              assetGroup,
              assetGroup.landscapeLogos,
              adwordsAssetType.landscapeLogo
            )
          );
        }
        if (assetGroup.portraitMarketingImages && assetGroup.portraitMarketingImages.length > 0) {
          assetGroupChildEntities.push(
            this.getEntityObject(
              assetGroup,
              assetGroup.portraitMarketingImages,
              adwordsAssetType.portraitMarketingImage
            )
          );
        }
        if (assetGroup.youtubeVideos) {
          var temp = this.getEntityObject(assetGroup, assetGroup.youtubeVideos, adwordsAssetType.youtubeVideo);
          if (temp.processingStatus == adwordsEnums.exportStatus.Edited || temp.count) {
            assetGroupChildEntities.push(temp);
          }
        }
        if (assetGroup.searchThemes) {
          var temp2 = this.getEntityObject(assetGroup, assetGroup.searchThemes, adwordsAssetType.searchThemes);
          if (temp2.processingStatus == adwordsEnums.exportStatus.Edited || temp2.count) {
            assetGroupChildEntities.push(temp2);
          }
        }

        var entityObj = this.getEntityObject(assetGroup, assetGroup.callToAction, adwordsAssetType.callToAction);
        if (entityObj.processingStatus == adwordsEnums.exportStatus.Edited || entityObj.count) {
          assetGroupChildEntities.push(entityObj);
        }

        return assetGroupChildEntities;
      } else if (this.selectedCampaignLauncherConfig.campaign.type === adwordsEnums.campaignType["Display"]) {
        let adGroupChildEntities = [];
        for (let property in adwordsDisplayAssetType) {
          if (property !== 'longHeadline' && property !== 'businessName') {
            if (property === 'logo' || property === 'squareLogo') {
              var countPropertyName = `${property}Images`;
            } else {
              countPropertyName = `${property}s`
            }
            adGroupChildEntities.push({
              Name: adwordsDisplayAssetType[property],
              Count: assetGroup[countPropertyName].length
            });
          }
        }

        return adGroupChildEntities;
      } else if (this.selectedCampaignLauncherConfig.campaign.type === adwordsEnums.campaignType["App"]) {
        let adGroupChildEntities = [];
        for (let item in adwordsAppAssetType) {
          let adwordAppPropertyName = "";
          adwordAppPropertyName = `${item}s`;

          if (this.selectedCampaignLauncherConfig.campaign.subtype === adwordsEnums.campaignSubType["App Installs"] && item === 'finalAppUrl') {
            continue;
          }
          adGroupChildEntities.push({
            Name: adwordsAppAssetType[item],
            Count: assetGroup[adwordAppPropertyName].length
          });
        }

        return adGroupChildEntities;
      } else if (this.selectedCampaignLauncherConfig.campaign.type === adwordsEnums.campaignType["Demand Gen"]) {
        let adGroupChildEntities = [];
        for (let property in adwordsDemandAssetType) {
          if (assetGroup[property]) {
            adGroupChildEntities.push({
              Name: adwordsDemandAssetType[property],
              Count: assetGroup[property].length
            });
          }
        }
        return adGroupChildEntities;
      }
    },
    getEntityObject (assetGroup, entity, entityType) {
      let processingStatus = assetGroup.id == 0 ? adwordsEnums.exportStatus.New : adwordsEnums.exportStatus.Success;
      if (assetGroup.editedSummary && adwordsAssetTypeField[entityType] && assetGroup.editedSummary.includes(adwordsAssetTypeField[entityType])) {
        processingStatus = adwordsEnums.exportStatus.Edited;
      }
      let errorMessage = "";
      if (assetGroup.failureReason && assetGroup.failureReason != "") {
        let error = JSON.parse(assetGroup.failureReason);
        if (error && Array.isArray(error) && error.length > 0) {
          if (error[0].Value != "AssetGroup") {
            let value = error[0].Value && error[0].Value.split(' ');
            if (value && value[0] == entityType.replaceAll(' ', '')) {
              if (value[0] == "Description") {
                if (value[1] == 1) {
                  error[0].Value = "Short " + value[0];
                } else {
                  error[0].Value = value[0] + " " + (value[1] - 1);
                }
              }
              if (assetGroup.processingStatus != adwordsEnums.exportStatus.Edited) {
                processingStatus = adwordsEnums.exportStatus.Failed;
              }
              errorMessage = "Error occurred in " + error[0].Value + "." + error[0].Message
            }
          }
        }
      }
      if (entityType === adwordsAssetType.callToAction || entityType === adwordsAssetType.searchThemes || entityType === adwordsAssetType.youtubeVideo) {
        let assetCount = 0;
        if (entityType === adwordsAssetType.callToAction && entity) {
          assetCount = 1;
        } else if (entityType === adwordsAssetType.searchThemes || entityType === adwordsAssetType.youtubeVideo) {
          assetCount = entity.length;
        }
        return {
          entityType: entityType,
          count: assetCount,
          processingStatus: processingStatus,
          failureReason: errorMessage

        }
      }
      if (entityType === adwordsAssetType.name || entityType === adwordsAssetType.finalUrl) {
        if (processingStatus == adwordsEnums.exportStatus.Edited) {
          return {
            entityType: entityType,
            count: 1,
            processingStatus: processingStatus,
            failureReason: errorMessage
          }
        } else {
          return
        }
      }

      return {
        entityType: entityType,
        count: entityType === adwordsAssetType.businessName ? 1 : entity.length,
        processingStatus: processingStatus,
        failureReason: errorMessage
      };
    },
    getFailureErrorMessage (errorMsg, isAssetGroup = false) {
      if (this.deltax.isAdwordsCampaignLaucherFlow && errorMsg) {
        var error = JSON.parse(errorMsg);
        if (error && Array.isArray(error) && error.length > 0) {
          if (isAssetGroup && error[0].Value && error[0].Value != "AssetGroup") {
            return "Error Occurred in one or more assets.";
          }
          return error[0].Message;
        }
      }
      return errorMsg;
    },
    getEntityStatusIcon (status) {
      if (this.deltax.isAdwordsCampaignLaucherFlow) {
        if (status == adwordsEnums.exportStatus.Success) {
          return "fa fa-check-circle green";
        } else if (status == adwordsEnums.exportStatus.Failed) {
          return "fa fa-exclamation-triangle red";
        } else if (status == adwordsEnums.exportStatus.Edited) {
          return "fa fa-pencil-square yellow";
        } else if (
          status != adwordsEnums.exportStatus.Failed &&
          status != adwordsEnums.exportStatus.Success &&
          status != adwordsEnums.exportStatus.Edited
        ) {
          return "fa fa-clock-o yellow";
        }
      } else {
        if (status == "Success") {
          return "fa fa-check-circle green";
        } else if (status == "Failed") {
          return "fa fa-exclamation-triangle red";
        } else if (status == "New" || status == "Processing") {
          return "fa fa-clock-o yellow";
        }
      }
    },
    getEntitiesStatusCount (
      entities,
      publishstatus = adwordsEnums.exportStatus.New
    ) {
      let count = 0;
      if (publishstatus == adwordsEnums.exportStatus.Success) {
        entities.forEach(entity => {
          if (entity.processingStatus == adwordsEnums.exportStatus.Success) {
            count++;
          }
        });
      } else if (publishstatus == adwordsEnums.exportStatus.Failed) {
        entities.forEach(entity => {
          if (entity.processingStatus == adwordsEnums.exportStatus.Failed) {
            count++;
          }
        });
      } else if (publishstatus == adwordsEnums.exportStatus.New) {
        entities.forEach(entity => {
          if (entity.processingStatus == adwordsEnums.exportStatus.New) {
            count++;
          }
        });
      }
      return count;
    },
    toggleEntityStatus: function (status) {
      const selected = this.entityStatusToShow.includes(status);
      const count = this.entityStatusToShow.length;

      if (selected && count == 1) {
        return;
      }
      if (!selected) {
        if (status == "All") {
          this.entityStatusToShow = [];
        } else {
          this.entityStatusToShow = this.entityStatusToShow.filter(x => x != "All");
        }
        this.entityStatusToShow.push(status);
      } else {
        this.entityStatusToShow = this.entityStatusToShow.filter(x => x != status);
      }
    },
    getQueryFilteredCampaigns (adwordsEntities, query) {
      let filteredEntities = [];
      adwordsEntities.forEach(val => {
        let isCurrentCampaignAdded = false;
        if (val.name && val.name.includes(query)) {
          filteredEntities.push(val);
          isCurrentCampaignAdded = true;
        }
        if (val.adgroups.length && !isCurrentCampaignAdded) {
          let adgroups = val.adgroups.filter(adgroup => adgroup.name.includes(query));
          if (adgroups.length) {
            val.adgroups = adgroups;
            filteredEntities.push(val);
            isCurrentCampaignAdded = true;
          }
          if (!isCurrentCampaignAdded) {
            val.adgroups.forEach(adgroup => {
              let ads = adgroup.ads.filter(ad => ad.name.includes(query));
              if (ads.length) {
                val.adgroups.ads = ads;
                filteredEntities.push(val);
                isCurrentCampaignAdded = true;
              }
            });
          }
        }
        if (val.assetGroups.length && !isCurrentCampaignAdded) {
          let assetGroups = val.assetGroups.filter(assetGroup => assetGroup.name.includes(query));
          if (assetGroups.length) {
            val.assetGroups = assetGroups;
            filteredEntities.push(val);
            isCurrentCampaignAdded = true;
          }
        }
      });
      return filteredEntities;
    },
    getStatusFilteredCampaigns (adwordsEntities) {
      let self = this;
      return adwordsEntities
        .filter(campaign => {
          if (campaign.adgroups.length) {
            campaign.adgroups = campaign.adgroups
              .filter(adgroup => {
                adgroup.ads = adgroup.ads.filter(ad => self.entityStatusToShow.includes(self.getProcessingStatusName(ad.processingStatus)));
                adgroup.keywords = adgroup.keywords.filter(keyword => self.entityStatusToShow.includes(self.getProcessingStatusName(keyword.processingStatus)));

                // Keep the adgroup only if it has matching ads or keywords
                return adgroup.ads.length > 0 || adgroup.keywords.length > 0 || self.entityStatusToShow.includes(self.getProcessingStatusName(adgroup.processingStatus));
              });

            // Keep the campaign only if it has matching adgroups
            return campaign.adgroups.length > 0 || self.entityStatusToShow.includes(self.getProcessingStatusName(campaign.processingStatus));
          } else if (campaign.assetGroups.length) {
            campaign.assetGroups = campaign.assetGroups
              .filter(assetGroup => {
                // Same logic for asset groups
                return self.entityStatusToShow.includes(self.getProcessingStatusName(assetGroup.processingStatus));
              });

            // Keep the campaign only if it has matching assetGroups
            return campaign.assetGroups.length > 0 || self.entityStatusToShow.includes(self.getProcessingStatusName(campaign.processingStatus));
          }
        });
    },
    getProcessingStatusName (status) {
      let exportStatus = adwordsEnums.exportStatus;
      for (let key in exportStatus) {
        if (exportStatus[key] === status) {
          return key;
        }
      }
    },
    isStatusEdited (assetGroup) {
      return assetGroup.editedSummary && assetGroup.editedSummary.includes(adwordsAssetTypeField.Status);
    }
  }
};
</script>

<style scoped>
.status-active-btn {
  background: #eaeeef;
}
.summmary-list {
  padding-left: 0px;
}

.summary-collapse {
  cursor: pointer;
}

.summary-collapse:before {
  font-family: "FontAwesome";
  content: "\f0d7";
  color: grey;
  font-size: 1.3em;
  padding-right: 5px;
}

.summary-collapse.collapsed:before {
  content: "\f0da";
}

.campaign-summary {
  list-style: none;
  padding-left: 20px;
}

.adgroup-summary {
  list-style: none;
  padding-left: 35px;
}

.entity-label {
  color: #979898;
}

.extraAdName {
  color: #979898;
}
.adName {
  font-style: italic;
}
</style>
