<template>
    <div>
      <button v-if="!hideModalButton"
        @click="showModal = true"
        class="btn btn-success btn-sm widget-element pull-right"
      >
        <i class="fa fa-plus"></i>
      </button>
      <v-modal
        class="target-modal"
        :class="loadedFromAsset ? 'targetFromAsset' : ''"
        :title="targetMode === 'New' ? 'Create Target' : 'Edit Target'"
        v-model="showModal"
        effect="fade"
      >
        <div slot="modal-body" class="targets-body">
          <Row>
            <Row-Col span="16">
              <Form
                :label-width="105"
                :model="targetForm"
                :rules="validationRules"
                ref="creationForm"
              >
                <br />
                <Row v-if="!showMolocoTargetModalInSummary">
                  <Row-Col span="9">
                    <Form-Item label="Account:" prop="bpseId">
                      <Select
                        v-model="targetForm.bpseId"
                        placeholder="Select an account"
                      >
                        <Option
                          v-for="account in molocoAccounts"
                          :value="account.id"
                          :key="account.id"
                          >{{ account.accountNickName }} ({{
                            account.accountId
                          }})</Option
                        >
                      </Select>
                    </Form-Item>
                  </Row-Col>
                  <Row-Col span="15">
                    <Button class="float-right" @click="clearAll"
                      >Clear all</Button
                    >
                  </Row-Col>
                </Row>
                <div class="edit-target-section" v-if="targetForm.bpseId">
                  <div class="target-mode">
                        <div class="label-names target-mode-label">Target Mode:</div>
                        <RadioGroup class="radio-group" v-model="targetMode">
                            <Radio label="New" :disabled="showMolocoTargetModalInSummary"></Radio>
                            <Radio label="Edit Existing" :disabled="showMolocoTargetModalInSummary"></Radio>
                        </RadioGroup>
                  </div>
                  <div class="select-target">
                      <Select
                        v-model="selectedTargetId"
                        :placeholder="selectTargetPlaceholderText"
                        clearable
                      >
                        <Option
                          v-for="target in bpseTargetList"
                          :key="showMolocoTargetModalInSummary ? target.campaignId : target.id"
                          :value="showMolocoTargetModalInSummary ? target.campaignId : target.id"
                          >
                          {{ target.name }} <span v-if="showMolocoTargetModalInSummary">({{target.campaignName}})</span>
                        </Option>
                      </Select>
                  </div>
                </div>
                <br />
                <div v-show="!showLoader">
                  <template v-if="targetForm.bpseId && showTargetForm">
                    <Form-Item
                      label="Target Name:"
                      prop="targetName"
                      class="target-name"
                    >
                      <Row>
                        <Row-Col span="15">
                          <i-input v-model="targetForm.targetName" placeholder="Enter your Target Name" :disabled="showMolocoTargetModalInSummary"/>
                        </Row-Col>
                      </Row>
                    </Form-Item>
                    <Form-Item
                      label="Targeting Parameter:"
                      prop="impressionCaps"
                      class="target-name"
                    >
                      <Row>
                        <Row-Col span="22">
                          <ImpressionCap :campaignConfiguration=false ref="impressionCapCompRef"/>
                        </Row-Col>
                      </Row>
                    </Form-Item>
                    <Form-Item
                      label="Custom Targets:"
                      prop="customTargets"
                      class="target-name"
                    >
                      <Row>
                        <Row-Col span="22">
                          <CustomTargets :bpseId="targetForm.bpseId" ref="customTargetsRef"/>
                        </Row-Col>
                      </Row>
                    </Form-Item>
                  </template>
                  <template v-else-if="!targetForm.bpseId">
                    <p style="text-align: center;">
                      Please select a Moloco account to create targets
                    </p>
                  </template>
                  <template v-else>
                    <p style="text-align: center;">
                      Please select an target to edit.
                    </p>
                  </template>
                </div>
                <div class="hot-spinner" v-show="showLoader">
                  <i class="fa fa-lg fa-spinner fa-spin" />
                </div>
              </Form>
            </Row-Col>

            <Row-Col span="8">
              <div class="preivew-wrapper">
                <div class="preview-header">
                  <p v-if="targetMode === 'New'">New Audience Preview</p>
                  <p v-else>Existing Audience Preview</p>
                </div>
                <div v-show="!showLoader">
                    <Alert type="warning" closable show-icon v-if="showMolocoTargetModalInSummary && showLiveTargetPanel" class="target-alert">
                        <p class="alert-subheading">Campaign target outdated.</p>
                        <div slot="desc">
                          Looks like the campaign was updated directly on Moloco.<br/>
                          <span @click="applyLivetarget" class="apply-link">Click here</span> to update with the latest target.
                        </div>
                    </Alert>
                  <template v-if="isFormValid">
                    <div
                      class="target-row"
                    >
                      <Row class="target-prev-header">
                        <Row-Col span="19">
                          <p>{{ getTargetName() }}</p>
                        </Row-Col>
                        <Row-Col span="4" class="header-icons">
                          <Button :disabled="isSaveDisabled" @click="saveTarget()" id="save-btn" v-if="isValidMolocoTarget">
                            <tooltip content="Save" placement="top" >
                              <i class="fa fa-save preview-icon"></i>
                            </tooltip>
                          </Button>
                          <tooltip v-else content="Missing required fields" placement="top">
                            <i class="fa fa-exclamation-circle preview-icons"></i>
                          </tooltip>
                        </Row-Col>
                      </Row>
                      <Row class="target-prev-body">
                          <!-- <p>
                            {{getUserReadableTargetSummary}}
                          </p> -->
                          <div>
                            <ImpressionKeyValuePairs/>
                          </div>
                          <div v-if="molocoSelectedCustomAudiencesNames.length > 0">
                            <span class="target-prev-body-title">Custom Targets: </span> <span>{{molocoSelectedCustomAudiencesNames.join(",")}}</span>
                          </div>
                      </Row>
                    </div>
                  </template>
                  <template v-else>
                    <div class="preview-placeholder">
                      Please enter the required fields to preview new audience
                    </div>
                  </template>
                </div>
                <div class="hot-spinner" v-show="showLoader">
                  <i class="fa fa-lg fa-spinner fa-spin" />
                </div>
              </div>
            </Row-Col>

            <!-- moloco changes section-->
            <Row-Col span="8" v-if="showMolocoTargetModalInSummary && showLiveTargetPanel">
              <div class="preivew-wrapper">
                <div class="preview-header">
                  <Row>
                        <Row-Col span="18">
                          <p >Live Target Data</p>
                        </Row-Col>
                        <Row-Col span="6" class="header-icons">
                          <Button class="live-button" @click="applyLivetarget">
                            <span>Apply</span>
                          </Button>
                        </Row-Col>
                  </Row>
                </div>
                <div v-show="!showLiveCampaignLoader">
                  <template v-if="isFormValid">
                    <div
                      class="target-row"
                    >
                      <Row class="target-prev-header">
                        <Row-Col span="19">
                          <p>{{ getTargetName() }}</p>
                        </Row-Col>
                      </Row>
                      <Row class="target-prev-body">
                          <!-- <p>
                            {{getUserReadableTargetSummary}}
                          </p> -->
                          <div>
                            <ImpressionKeyValuePairs :liveMolocoTarget="true"/>
                          </div>
                          <div v-if="molocoLiveCustomAudiencesNames.length > 0">
                            <span class="target-prev-body-title">Custom Targets: </span> <span>{{molocoLiveCustomAudiencesNames.join(",")}}</span>
                          </div>
                      </Row>
                    </div>
                  </template>
                  <template v-else>
                    <div class="preview-placeholder">
                      Please enter the required fields to preview new audience
                    </div>
                  </template>
                </div>
                <div class="hot-spinner" v-show="showLiveCampaignLoader">
                  <i class="fa fa-lg fa-spinner fa-spin" />
                </div>
              </div>
            </Row-Col>
          </Row>
        </div>
        <div slot="modal-footer" class="modal-footer hide"></div>
      </v-modal>
    </div>
  </template>

<script>
import { APIService } from "../../ApiService.js";
import { mapState, mapMutations } from "vuex";
import { EventBus } from "../../EventBus.js";
import * as molocoEnums from "../../Constants/MolocoEnums.js";
import ImpressionCap from "./ImpressionCap.vue";
import CustomTargets from "./CustomTargets.vue";
import ImpressionKeyValuePairs from "./ImpressionKeyValuePairs.vue";

import {
  Row,
  Col,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  Button,
  locale,
  RadioGroup,
  Radio,
  Alert
} from "iview";
import { modal, tooltip } from "vue-strap";
import lang from "iview/dist/locale/en-US";

locale(lang);

export default {
  props: {
    loadedFromAsset: Boolean,
    hideModalButton: {
      default: false,
      type: Boolean
    },
    summaryTargets: {
      type: Array,
      required: false
    },
    summarySelectedTarget: {
      type: Object,
      required: false
    }
  },
  components: {
    Row,
    "Row-Col": Col,
    Form,
    FormItem,
    Select,
    Option,
    "i-input": Input,
    Button,
    "v-modal": modal,
    ImpressionCap,
    tooltip,
    CustomTargets,
    ImpressionKeyValuePairs,
    RadioGroup,
    Radio,
    Alert
  },
  data () {
    return {
      molocoEnums,
      showModal: false,
      targetForm: {
        bpseId: 0,
        targetName: "",
        age: {
          type: 0,
          min: "13",
          max: "And Up"
        },
        gender: 0,
        locations: [],
        languages: []
      },
      targetStringJson: {},
      validationRules: {
        targetName: [
          { required: true, message: "Please enter a name", trigger: "blur" }
        ],
        impressionCaps: [
          { required: true, message: "Please select the targeting key values", trigger: "blur" }
        ]
      },
      targetsList: [],
      molocoTargetString: "",
      isSaveDisabled: false,
      targetMode: "New",
      bpseTargetList: [],
      selectedTargetId: 0,
      editTargetData: {},
      editTargetModeTriggered: 0,
      showLoader: false,
      showLiveCampaignLoader: false,
      selectedTargetCampaign: {},
      showLiveTargetPanel: false
    };
  },
  computed: {
    ...mapState([
      "savedTargetsMapperByBpseId",
      "savedTargets",
      "molocoAccounts",
      "isValidMolocoTarget",
      "molocoImpressionCaps",
      "molocoSelectedCustomAudiencesNames",
      "molocoLiveCustomAudiencesNames",
      "showMolocoTargetModalInSummary",
      "selectedCampaignLauncherConfig",
      "molocoCustomTargets",
      "molocoCampaignLauncherPublishDataId",
      "molocoTargetingKeyValuePairs",
      "molocoEditedTargets",
      "molocoEditedExternalAudiences"
    ]),
    ...mapMutations([
      "set_savedTargets",
      "set_savedTargetsMapperByBpseId"
    ]),
    isFormValid () {
      return (
        this.targetForm.bpseId != null &&
          // this.targetsList.length > 0 &&
          this.targetForm.targetName
      );
    },
    getUserReadableTargetSummary () {
      var message = this.targetForm.targetName
      return message
    },
    getStringForPanel () {
      return type => {
        var self = this
        if (!self.targetForm[type] || self.targetForm[type].length < 1) {
          return null;
        }
        var valuesUsed = self[type].filter(function (obj) {
          return self.targetForm[type].some(function (obj2) {
            return obj.targeting_value === obj2;
          });
        });
        return valuesUsed.map(x => x.name).join(" | ");
      }
    },
    showTargetForm () {
      if (this.targetForm.bpseId && this.targetMode === "New") {
        return true;
      } else if (this.targetForm.bpseId && this.targetMode === "Edit Existing") {
        if (this.selectedTargetId) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    selectTargetPlaceholderText () {
      return this.targetMode === 'New' ? 'Load from existing' : 'Select a target';
    }
  },
  watch: {
    showModal (newVal) {
      if (!this.showModal) {
        this.clearAll();
        this.$store.state.showMolocoTargetModalInSummary = false;
      }
      if (newVal) {
        if (!this.targetForm.bpseId && !this.showMolocoTargetModalInSummary) {
          if (this.molocoAccounts.length) {
            this.targetForm.bpseId = this.molocoAccounts[0].id
          }
        } else if (!this.targetForm.bpseId && this.showMolocoTargetModalInSummary) {
          this.targetForm.bpseId = this.selectedCampaignLauncherConfig.bpseId;
        }
        this.fetchCustomTargets(this.targetForm.bpseId);
      }
    },
    selectedTargetId (newVal) {
      if (newVal) {
        if (this.showMolocoTargetModalInSummary) {
          this.selectedTargetCampaign = this.summaryTargets.find(target => target.campaignId == newVal);
          if (!this.selectedTargetCampaign.editedSummary.includes("Targets") && !this.molocoEditedTargets.includes(this.selectedTargetCampaign.campaignId)) {
            this.fetchTargetCampaign(newVal);
          }
          this.processSelectedTarget(this.selectedTargetCampaign);
          // this.showLiveTargetPanel = true;
        } else {
          this.fetchSelectedTarget(newVal);
        }
      } else {
        if (this.$refs.impressionCapCompRef) {
          this.$refs.impressionCapCompRef.impressionCaps = [];
          this.$refs.impressionCapCompRef.selectedCondition = "";
          this.$refs.impressionCapCompRef.editTargetModeTriggered = 0;
        }
        if (this.$refs.customTargetsRef) {
          this.$refs.customTargetsRef.selectedCustomAudiences = [];
        }
        this.$store.state.isValidMolocoTarget = false;
        this.$store.state.molocoImpressionCaps = [];
        this.$store.state.molocoSelectedCustomAudiencesNames = [];
        this.targetForm.targetName = this.targetForm.bpseId ? "New Target" : "";
        this.showLiveTargetPanel = false;
      }
    },
    editTargetData (newVal) {
      // process the new fetched target
      this.processSelectedTarget(newVal);
    },
    showMolocoTargetModalInSummary (newVal) {
      if (newVal) {
        this.showModal = true;
        // set the values
        this.bpseTargetList = this.summaryTargets;
        this.targetMode = "Edit Existing"
        this.selectedTargetId = this.summarySelectedTarget.campaignId;
      }
    },
    summaryTargets: {
      immediate: true,
      deep: true,
      handler: function (newVal) {
        // console.log(newVal);
      }
    }
  },
  methods: {
    clearAll () {
      if (this.$refs.impressionCapCompRef) {
        this.$refs.impressionCapCompRef.impressionCaps = [];
        this.$refs.impressionCapCompRef.selectedCondition = "";
        this.$refs.impressionCapCompRef.editTargetModeTriggered = 0;
      }
      this.$store.state.isValidMolocoTarget = false;
      this.$store.state.molocoImpressionCaps = [];
      this.$store.state.molocoSelectedCustomAudiencesNames = [];
      this.$refs.creationForm.resetFields();
      this.targetMode = "New";
      this.bpseTargetList = [];
      this.selectedTargetId = "";
    },
    async saveTarget (language) {
      this.isSaveDisabled = true;
      if (this.showMolocoTargetModalInSummary) {
        let targetString = this.$refs.impressionCapCompRef.molocoTargetString;
        let externalAudienceId = this.$refs.customTargetsRef.selectedCustomAudiences && this.$refs.customTargetsRef.selectedCustomAudiences.length ? JSON.stringify(this.$refs.customTargetsRef.selectedCustomAudiences) : "";
        this.saveLivetarget(targetString, externalAudienceId);
      } else {
        let target = {
          locationTargetType: 0,
          Name: this.getTargetName(),
          agencyid: deltax.businessProfileId,
          advertiserid: deltax.businessProfileId,
          businessProfileId: deltax.businessProfileId,
          businessProfilesSearchEngineId: this.targetForm.bpseId,
          isCreatedOnDx: true,
          targetString: this.$refs.impressionCapCompRef.molocoTargetString,
          externalAudienceId: JSON.stringify(this.$refs.customTargetsRef.selectedCustomAudiences),
          estimatedReach: 0
        };

        try {
          if (this.targetMode === "New") {
            let response = await APIService.saveTarget(target);
            target.Id = response.data.data;
            target.id = response.data.data;
            target.name = target.Name;
            target.bpseId = target.businessProfilesSearchEngineId;
            this.$Message.success({
              background: true,
              content: `${target.Name} successfully created`
            });

            // update store data with created target
            let bpseId = target.businessProfilesSearchEngineId;
            let newTargets = [target, ...this.savedTargets]
            this.$store.commit('set_savedTargets', newTargets);
            let savedTargetsMapperCopy = JSON.parse(JSON.stringify(this.savedTargetsMapperByBpseId));
            if (savedTargetsMapperCopy[bpseId] && savedTargetsMapperCopy[bpseId].length) {
              savedTargetsMapperCopy[bpseId].unshift(target);
            } else {
              savedTargetsMapperCopy[bpseId] = [];
              savedTargetsMapperCopy[bpseId].push(target);
            }
            this.$store.commit('set_savedTargetsMapperByBpseId', savedTargetsMapperCopy);
            // logic to show it in bpse targets list
            this.bpseTargetList.unshift(target);
            EventBus.$emit('moloco-updateSavedTarget', target, false);
          } else {
            let updateTargetData = [{
              "Name": "Name",
              "Value": target.Name
            },
            {
              "Name": "TargetString",
              "Value": target.targetString
            },
            {
              "Name": "ExternalAudienceId",
              "Value": target.externalAudienceId
            }]
            let response = await APIService.updateTarget(this.editTargetData.id, updateTargetData);
            this.$Message.success({
              background: true,
              content: `${response.data.message}`
            });
            // set Id and bpseId for config object dropdown
            target.Id = this.editTargetData.id;
            target.bpseId = target.businessProfilesSearchEngineId;
            target.name = target.Name;
            // add logic to update the names in store
            let bpseId = target.businessProfilesSearchEngineId;
            // console.log(this.savedTargetsMapperByBpseId[bpseId]);
            let savedTargetsCopy = JSON.parse(JSON.stringify(this.savedTargets));
            let savedTargetsMapperCopy = JSON.parse(JSON.stringify(this.savedTargetsMapperByBpseId));
            for (let index in savedTargetsCopy) {
              if (savedTargetsCopy[index].id === this.editTargetData.id) {
                savedTargetsCopy[index].name = target.Name;
                savedTargetsCopy[index].Name = target.Name;
                break;
              }
            }
            for (let index in savedTargetsMapperCopy[bpseId]) {
              if (savedTargetsMapperCopy[bpseId][index].id === this.editTargetData.id) {
                savedTargetsMapperCopy[bpseId][index].name = target.Name;
                savedTargetsMapperCopy[bpseId][index].Name = target.Name;
                break;
              }
            }
            for (let index in this.bpseTargetList) {
              if (this.bpseTargetList[index].id === this.editTargetData.id) {
                this.bpseTargetList[index].name = target.Name;
                this.bpseTargetList[index].Name = target.Name;
                break;
              }
            }
            this.$store.commit('set_savedTargets', savedTargetsCopy);
            this.$store.commit('set_savedTargetsMapperByBpseId', savedTargetsMapperCopy);
            EventBus.$emit('moloco-updateSavedTarget', target, true);
          }
          this.isSaveDisabled = false;
        } catch (ex) {
          console.error(ex);
          this.$Message.error({
            background: true,
            content: "There was a problem while processing your request"
          });
        }
      }
    },
    getTargetName () {
      let macroDict = {
        Language: this.getStringForPanel('languages'),
        Location: this.getStringForPanel('locations'),
        Gender: 'Male',
        AgeGroup: this.getAgeStringForPanel
      };
      let replacedTargetName = this.targetForm.targetName;
      for (let [macro, value] of Object.entries(macroDict)) {
        if (value) {
          replacedTargetName = replacedTargetName.replace(`{${macro}}`, value);
        } else {
          replacedTargetName = replacedTargetName.replace(`{${macro}}`, "");
        }
      }
      return replacedTargetName;
    },
    addMacro (name) {
      if (!this.targetForm.targetName.includes(`{${name}}`)) {
        if (this.targetForm.targetName) {
          this.targetForm.targetName += "-";
        }
        this.targetForm.targetName += `{${name}}`;
      }
    },
    removeTargetFromPreview () {
      this.targetsList = []
    },
    async fetchSelectedTarget (targetId) {
      this.showLoader = true;
      try {
        let response = await APIService.getTarget(targetId);
        this.editTargetData = response;
      } catch (ex) {
        console.error(ex);
        this.$Message.error({
          background: true,
          content: "There was a problem while fetching the target"
        });
      }
      this.showLoader = false;
    },
    processSelectedTarget (target) {
      if (target) {
        let targetStringObj = JSON.parse(target.targetString);
        this.$nextTick(() => {
          this.processImpressionCapData(targetStringObj);
          this.targetForm.targetName = target.name;
          this.$refs.customTargetsRef.selectedCustomAudiences = target.externalAudienceId ? JSON.parse(target.externalAudienceId) : [];
        });
      }
    },
    processImpressionCapData (targetStringObj) {
      let targetStringCondition = "and";
      let impressionCaps = [];
      if (targetStringObj["and"]) {
        this.$refs.impressionCapCompRef.selectedCondition = "AND";
      } else {
        targetStringCondition = "or";
        this.$refs.impressionCapCompRef.selectedCondition = "OR";
      }
      let index = 0;
      for (let key in targetStringObj[targetStringCondition].pairs) {
        let pair = targetStringObj[targetStringCondition].pairs[key];
        let conditionType = pair.is_in ? "is_in" : "is_not_in";
        let obj = {
          "id": Date.now() + ++index,
          "impressionCapValue": 0,
          "selectedMolocoTargetKey": key,
          "selectedIsValue": conditionType === "is_in" ? "IS" : "IS_NOT",
          "selectedTargetingValues": pair[conditionType].values
        };
        impressionCaps.push(obj);
      }
      this.$refs.impressionCapCompRef.impressionCaps = impressionCaps;
      this.$refs.impressionCapCompRef.sameFieldWarning = new Array(impressionCaps.length);
      this.$refs.impressionCapCompRef.sameFieldWarning.fill(false);
      this.$refs.impressionCapCompRef.editTargetModeTriggered = Date.now();
    },
    processLiveImpressionCapsData (targetStringObj) {
      let targetStringCondition = "and";
      let impressionCaps = [];
      if (targetStringObj["or"]) {
        targetStringCondition = "or";
        this.$store.state.molocoTargetingLiveCondition = "OR";
      }

      let index = 0;
      for (let key in targetStringObj[targetStringCondition].pairs) {
        let pair = targetStringObj[targetStringCondition].pairs[key];
        let conditionType = pair.is_in ? "is_in" : "is_not_in";
        let obj = {
          "id": Date.now() + ++index,
          "impressionCapValue": 0,
          "selectedMolocoTargetKey": key,
          "selectedIsValue": conditionType === "is_in" ? "IS" : "IS_NOT",
          "selectedTargetingValues": pair[conditionType].values
        };
        impressionCaps.push(obj);
      }
      this.$store.state.molocoLiveImpressionCaps = impressionCaps;
    },
    processLiveCustomAudiences (customTargets) {
      let customAudienceNames = [];
      for (let item of customTargets) {
        for (let obj of this.molocoCustomTargets) {
          if (obj.id === item) {
            customAudienceNames.push(obj.title);
            break;
          }
        }
      }
      this.$store.state.molocoLiveCustomAudiencesNames = customAudienceNames;
    },
    fetchCustomTargets (bpseId) {
      APIService.getCustomAudiences(bpseId).then(response => {
        this.$store.state.molocoCustomTargets = response.audience_targets;
      });
    },
    async fetchTargetCampaign (campaignId) {
      this.showLiveCampaignLoader = true;
      // this.selectedTargetCampaign = this.summaryTargets.find(target => target.campaignId === campaignId);
      try {
        let liveCampaignData = await APIService.getMolocoCampaignData(this.selectedCampaignLauncherConfig.bpseId, campaignId);
        let reversedTargetStringObject = this.reverseLiveTargetObject(liveCampaignData.campaign.jio_support.cohort_audience);
        this.processLiveImpressionCapsData(reversedTargetStringObject);
        if (liveCampaignData.campaign.user_targets && liveCampaignData.campaign.user_targets.length) {
          this.processLiveCustomAudiences(liveCampaignData.campaign.user_targets);
        }
        this.selectedTargetCampaign.liveTargetString = JSON.stringify(reversedTargetStringObject);
        this.selectedTargetCampaign.liveCustomTargets = liveCampaignData.campaign.user_targets && liveCampaignData.campaign.user_targets.length ? JSON.stringify(liveCampaignData.campaign.user_targets) : "";

        // if live target string are same then don't show
        if ((!this.validateSameTargetString(this.selectedTargetCampaign.liveTargetString, this.selectedTargetCampaign.targetString) || !this.validateSameExternalAudiences(this.selectedTargetCampaign.liveCustomTargets, this.selectedTargetCampaign.externalAudienceId)) && (!this.selectedTargetCampaign.editedSummary.includes("Targets") && !this.molocoEditedTargets.includes(campaignId))) {
          this.showLiveTargetPanel = true;
        }
      } catch (error) {
        this.$Message.error('Failed to fetch latest campaign details from Moloco');
        console.error('Failed to fetch latest campaign details from Moloco', error);
      } finally {
        this.showLiveCampaignLoader = false;
      }
    },
    applyLivetarget () {
      // apply the live target data
      let targetToApply = {
        id: this.selectedTargetCampaign.id,
        name: this.selectedTargetCampaign.name,
        targetString: this.selectedTargetCampaign.liveTargetString,
        externalAudienceId: this.selectedTargetCampaign.liveCustomTargets
      };
      this.processSelectedTarget(targetToApply);
      this.showLiveTargetPanel = false;
    },
    saveLivetarget (targetString, externalAudienceId) {
      let targetRequestData = {
        lineItemId: this.selectedTargetCampaign.lineItemId,
        targetId: this.selectedTargetCampaign.id,
        lineItemFractionId: 0,
        PatchRequestModels: [
          {
            property: molocoEnums.campaignEditableFields.Targets,
            value: targetString
          },
          {
            property: molocoEnums.campaignEditableFields.ExternalAudiences,
            value: externalAudienceId
          }
        ]
      };

      let self = this;
      this.$Message.destroy();

      // if (!this.validateSameTargetString(self.selectedTargetCampaign.liveTargetString, targetString) || !this.validateSameExternalAudiences(self.selectedTargetCampaign.liveCustomTargets, externalAudienceId)) {
      if (!this.validateSameTargetString(self.selectedTargetCampaign.targetString, targetString) || !this.validateSameExternalAudiences(self.selectedTargetCampaign.externalAudienceId, externalAudienceId)) {
        APIService.updateMolocoCampaignData(this.molocoCampaignLauncherPublishDataId, targetRequestData).then(response => {
          self.$Message.success('Target updated successfully.');
          self.selectedTargetCampaign.targetString = targetString;
          self.selectedTargetCampaign.externalAudienceId = externalAudienceId;
          // emit changes to parent component
          self.$emit("update-campaign-target", self.selectedTargetCampaign);
          this.isSaveDisabled = false;
        }).catch(error => {
          console.log(error);
          self.$Message.error('Unable to update target.');
        });
      } else {
        // don't emit for same target
        self.$Message.success('Target updated successfully.');
        this.isSaveDisabled = false;
      }
      // } else {
      //   self.$Message.success('Target updated successfully.');
      //   self.selectedTargetCampaign.targetString = targetString;
      //   self.selectedTargetCampaign.externalAudienceId = externalAudienceId;
      //   // emit changes to parent component
      //   self.$emit("update-campaign-target", self.selectedTargetCampaign);
      //   this.isSaveDisabled = false;
      // }
    },
    reverseLiveTargetObject (targetObj) {
      let condition = Object.keys(targetObj)[0];
      let newReverseObject = {
        [condition]: {
          pairs: {}
        }
      }
      let reversedObjects = Object.keys(targetObj[condition].pairs).reverse();

      reversedObjects.forEach(item => {
        let tempObj = targetObj[condition].pairs[item];
        newReverseObject[condition].pairs[item] = tempObj;
      });
      return newReverseObject;
    },
    validateSameTargetString (targetString1, targetString2) {
      if (!targetString1 || !targetString2) {
        return false;
      }
      let obj1 = JSON.parse(targetString1);
      let obj2 = JSON.parse(targetString2);
      let condition1 = Object.keys(obj1)[0];
      let condition2 = Object.keys(obj2)[0];

      if (condition1 !== condition2) return false;

      if (Object.keys(obj1[condition1].pairs).length !== Object.keys(obj2[condition2].pairs).length) return false;

      for (let obj1Key in obj1[condition1].pairs) {
        if (obj2[condition2].pairs[obj1Key]) {
          if (Object.keys(obj1[condition1].pairs[obj1Key])[0] === Object.keys(obj2[condition2].pairs[obj1Key])[0]) {
            let isInVal = Object.keys(obj1[condition1].pairs[obj1Key])[0];
            if (!this.areEqualValues(obj1[condition1].pairs[obj1Key][isInVal].values, obj2[condition2].pairs[obj1Key][isInVal].values)) {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    },
    validateSameExternalAudiences (externalAudience1, externalAudience2) {
      if (externalAudience1 && externalAudience2) {
        return this.areEqualValues(JSON.parse(externalAudience1), JSON.parse(externalAudience2));
      } else if (!externalAudience1 && !externalAudience2) {
        return true;
      } else {
        return false;
      }
    },
    areEqualValues (arr1, arr2) {
      {
        let N = arr1.length;
        let M = arr2.length;

        if (N != M) { return false; }

        let map =
            new Map();
        let count = 0;
        for (let i = 0; i < N; i++) {
          if (map.get(arr1[i]) == null) { map.set(arr1[i], 1); } else {
            count = map.get(arr1[i]);
            count++;
            map.set(arr1[i], count);
          }
        }

        for (let i = 0; i < N; i++) {
          if (!map.has(arr2[i])) { return false; }

          if (map.get(arr2[i]) == 0) { return false; }

          count = map.get(arr2[i]);
          --count;
          map.set(arr2[i], count);
        }

        return true;
      }
    }
  },
  mounted () {
    this.$watch('targetForm.bpseId', function () {
      if (!this.showMolocoTargetModalInSummary) {
        if (this.$refs.impressionCapCompRef) {
          this.$refs.impressionCapCompRef.impressionCaps = [];
          this.$refs.impressionCapCompRef.selectedCondition = "";
        }
        if (this.$refs.customTargetsRef) {
          this.$refs.customTargetsRef.selectedCustomAudiences = [];
        }
        this.$store.state.isValidMolocoTarget = false;
        this.$store.state.molocoImpressionCaps = [];
        this.$store.state.molocoSelectedCustomAudiencesNames = [];
        this.targetForm.targetName = this.targetForm.bpseId ? "New Target" : "";
        this.selectedTargetId = "";
        this.bpseTargetList = [];
        if (this.$store.state.savedTargetsMapperByBpseId[this.targetForm.bpseId] && this.$store.state.savedTargetsMapperByBpseId[this.targetForm.bpseId].length) {
          this.bpseTargetList = this.$store.state.savedTargetsMapperByBpseId[this.targetForm.bpseId];
        }
      }
    });
  }
};
</script>
<style scoped>
  .alert-subheading {
    font-weight: bold;
    color: #E6A700 !important;
  }
  .apply-link {
    cursor: pointer;
    text-decoration: underline;
  }
  .target-alert {
    margin: 2px 10px;
  }
  ::v-deep .ivu-message-warning, .ivu-alert-warning {
  padding: 6px 26px 6px 60px !important;
}
  .live-button {
    padding: 2px 10px;
    background: #f4f4f4;
    border: 1px solid #c3c3c3;
  }
  .select-target {
    margin-left: 80px;
  }
  .target-mode-label {
    margin-right: 10px;
  }
  .target-mode {
    display: flex;
  }
  .hot-spinner {
  font-size: 3rem;
  margin-top: 2rem;
  line-height: 5rem;
  text-align: center;
}
 .label-names {
  font-weight: normal;
}
.edit-target-section {
    width: 40%;
    padding: 5px;
    margin-left: 20px;
}
.targetFromAsset {
  top: 30px;
}
#save-btn {
  padding: 0px;
  margin: 0px;
  border: 0px;
  background-color: #f4f4f4;
}
.preview-icons {
    padding-top: 4px;
}
.header-icons {
    display: flex;
    justify-content: right;
    align-items: flex-end;
    float: inline-start;
    padding: 3px;
}
.float-right {
  float: right;
  margin-left: 10px;
}
.macro-list {
  margin-bottom: 0px;
}
.macro-list > li {
  display: inline-block;
}
.target-row {
  margin: 10px;
  border: 1px solid #ddd;
}
.divider {
  width: 100%;
  height: 7px;
  border-bottom: 1px solid #ccc;
  position: relative;
  margin-bottom: 36px;
}
.divider > span {
  left: 36px;
  position: absolute;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #979898;
  background-color: white;
  padding: 0 10px;
}
.pointer {
  cursor: pointer;
}
.preview-icon {
  margin-top: 3px;
  cursor: pointer;
  color: #888888;
  float: right;
}
.preview-icon:hover {
  color: #707376;
}
.preview-icon:active {
  transform: translateY(1px);
}
.preivew-wrapper {
  border: 1px solid #ddd;
  margin: 15px;
  width: calc(100% - 30px);
}
.preview-placeholder {
  margin: 10px;
  font-weight: normal;
  color: #979696;
}
.preview-header {
  background-color: #f4f4f4;
  height: 35px;
  line-height: 35px;
  padding: 0 10px 0 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid var(--main-bg-color);
}
.target-prev-header {
  padding: 0 5px;
  background: #f4f4f4;
  border-bottom: 2px solid var(--main-bg-color);
}
.target-prev-body-title {
  font-weight: bold;
}
.target-prev-header p {
  margin: 2px 0 0 0;
  padding: 4px;
}
.target-prev-body {
  padding: 5px;
  font-weight: normal;
}
.macros {
  padding-right: 10px;
}
</style>
<style>
.modal-header button.close > span {
  font-size: 22px !important;
  color: initial;
}
.target-name > .ivu-form-item-error-tip {
  padding-top: 1px;
}
.target-modal .modal-header {
  padding-bottom: 5px !important;
}
.target-modal .modal-dialog {
  width: 98%;
  height: 100%;
  z-index: 9997;
}
.targets-body {
  overflow: auto;
  padding: 0px !important;
  height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
}
  </style>
  <style src="../../../../Styles/dx-iview.css"></style>
