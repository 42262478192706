<template>
  <div>
    <Progress v-show="percent != 0" :percent="percent" :stroke-color="color" hide-info :stroke-width="5">
    </Progress>
    <div class="info-div">
      <span v-show="percent != 0" :class="resultClass" >{{passwordResult}} </span>
      <Tooltip class="password-res" v-show="percent > 0" :placement="tooltipPlacement || 'right-start'" :content="content">
        <Icon v-show="showInformationCircle" type="ios-information-circle" size="20px"/>
      </Tooltip>
    </div>
  </div>
</template>

<script>
import { Progress, Icon, Tooltip } from "iview";
var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^a-zA-Z\\d\\s]).*$");
export default {
  components: {
    Progress,
    Icon,
    Tooltip
  },
  props: ["password", "tooltipPlacement"],
  data: function () {
    return {
      percent: 0,
      passwordResult: "",
      color: "",
      content: "",
      resultClass: "password-res",
      showInformationCircle: false
    }
  },
  watch: {
    password: function (val) {
      // trim the trailing spaces
      val = val.trim();
      var passwordLength = val.length;
      this.content = "";
      this.showInformationCircle = false;

      if (passwordLength == 0) {
        this.percent = 0;
      } else {
        this.$emit('isInvalid', false);
        var regexTest = strongRegex.test(val);
        if (passwordLength < 8) {
          this.percent = 33;
          this.resultClass = "color-error password-res"
          this.color = "#cc3f44";
          this.passwordResult = "Weak  ";
          this.showInformationCircle = true;
          this.content = "Minimum 8 characters.";
          this.$emit('isInvalid', true);
        } else if (passwordLength >= 8 && passwordLength < 12) {
          this.color = "#ffba00";
          this.resultClass = "color-good password-res";
          this.percent = 66;
          this.passwordResult = "Good";
        } else if (passwordLength >= 12 && passwordLength <= 30) {
          this.color = "#8dc63f";
          this.resultClass = "color-strong password-res";
          this.percent = 100;
          this.passwordResult = "Strong";
        } else if (passwordLength > 30) {
          this.percent = 100;
          this.resultClass = "color-error password-res";
          this.color = "#cc3f44";
          this.passwordResult = "Invalid  ";
          this.showInformationCircle = true;
          this.content = "Maximum 30 characters.";
          this.$emit('isInvalid', true);
        }

        if (!regexTest) {
          this.color = "#cc3f44";
          this.resultClass = "color-error password-res";
          this.passwordResult = "Invalid  ";
          this.showInformationCircle = true;
          const msg = "Please use at least one lowercase letter, one uppercase letter, one digit and one special character."
          if (this.content.length > 0) {
            this.content += ` ${msg}`;
          } else {
            this.content = msg;
          }
          this.$emit('isInvalid', true);
        }
      }
    }
  }
}
</script>

<style scoped>
  .info-div {
    text-align: right;
  }
  .color-error {
    color: #cc3f44;
  }
  .color-good {
    color: #ffba00;
  }
  .color-strong {
    color: #8dc63f;
  }
  .password-res {
    display: inline-block;
  }
  ::v-deep .ivu-tooltip-inner {
    width: 200px;
    white-space: normal;
  }
</style>
