<template>
  <i-modal v-model="showAccess" class-name="modal_placement" :closable="false" title="Update Dashboard Access">
    <div v-if="isLoading" class="loader">
      <i class="fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
    </div>
    <div v-if ="!isLoading">
        <Row>
          <Column span="6" class="label-text" style="text-align:center; display: inline-block; line-height:32px; vertical-align:center;">
            <label><b>Access</b></label>
          </Column>
          <Column span="18">
            <div class="radio-group">
                <input type="radio" id="all-dashboards" v-model.number="dashboardFilterType" value="0" />
                <label for="all-dashboards" class="pointer">All Dashboards</label>
                <input type="radio" id="filtered-dashboards" v-model.number="dashboardFilterType" value="1" />
                <label for="filtered-dashboards" class="pointer">Filtered Dashboards</label>
            </div>
          </Column>
        </Row>
        <Row>
          <Column span="18" offset="6">
            <div v-if="dashboardFilterType == 1 && dashboardsList && dashboardsList.length > 0">
              <i-select v-model="selectedDashboards" multiple placeholder="Select Dashboard(s)">
                <i-option v-for="item in dashboardsList" :value="item.id" :key="item.id">{{ item.name }}</i-option>
              </i-select>
            </div>
          </Column>
        </Row>
    </div>
    <div slot="footer">
      <button class="btn btn-white" @click="closeModal()">Close</button>
      <button class="btn btn-success" @click.prevent="onSave()">Save</button>
    </div>
  </i-modal>
</template>

<script>
import { Select, Option, Modal, Row, Col } from "iview";
import { DashboardRoleAccessApiService } from '../Services/apiService.js';

export default {
  components: {
    "i-select": Select,
    "i-option": Option,
    "i-modal": Modal,
    Row,
    "Column": Col
  },
  props: {
    setDashboardData: Object
  },
  data: function () {
    return {
      businessProfileId: undefined,
      apiService: null,
      dashboardFilterType: 0,
      selectedDashboards: [],
      dashboardsList: [],
      showAccess: false,
      isLoading: true,
      userId: undefined
    }
  },
  methods: {
    setupModal (value, businessProfileId, userId) {
      this.showAccess = value;
      this.businessProfileId = businessProfileId;
      this.apiService = new DashboardRoleAccessApiService();
      this.setAccess(value, userId);
    },
    setAccess: function (value, userId) {
      this.showAccess = value;
      this.userId = userId;
      this.clearFilters();
      this.isLoading = true;
      if (this.showAccess) {
        this.apiService.getDashboards(this.businessProfileId).then((response) => {
          if (response && response.data && response.data.data) {
            this.dashboardsList = response.data.data;
            this.isLoading = false;
          }
        }).catch(() => {
          this.$emit("DashboardSettingsFetchFailure");
          this.showAccess = false;
        });
        this.apiService.getAccess(this.businessProfileId, this.userId).then((response) => {
          if (response && response.data && response.data.data) {
            var dashboardRoles = response.data.data.filter(x => x.roleId == 9);
            if (dashboardRoles != null && dashboardRoles.length > 0) {
              this.setFilterData(dashboardRoles[0]);
              this.isLoading = false;
            } else {
              this.isLoading = false;
              if (!(this.setDashboardData && Object.keys(this.setDashboardData).length === 0 && Object.getPrototypeOf(this.setDashboardData) === Object.prototype)) {
                this.dashboardFilterType = this.setDashboardData.IsFiltered ? 1 : 0;
                if (this.dashboardFilterType == 1) {
                  this.selectedDashboards = this.setDashboardData.SelectedDashboards;
                }
              }
            }
          } else {
            this.$emit("DashboardSettingsFetchFailure");
            this.showAccess = false;
          }
        }).catch((err) => {
          if (err.response.status == 404 && err.response.data.errorCode == 104) {
            this.isLoading = false;
          }
          if (!(this.setDashboardData && Object.keys(this.setDashboardData).length === 0 && Object.getPrototypeOf(this.setDashboardData) === Object.prototype)) {
            this.dashboardFilterType = this.setDashboardData.IsFiltered ? 1 : 0;
            if (this.dashboardFilterType == 1) {
              this.selectedDashboards = this.setDashboardData.SelectedDashboards;
            }
          }
        });
      }
    },
    clearFilters: function () {
      this.selectedDashboards = [];
      this.dashboardFilterType = 0;
    },
    setFilterData: function (data) {
      if (this.setDashboardData != undefined) {
        if (this.setDashboardData && Object.keys(this.setDashboardData).length === 0 && Object.getPrototypeOf(this.setDashboardData) === Object.prototype) {
          if (data && data.accessConfigurationJson) {
            var accessConfigurationJson = JSON.parse(data.accessConfigurationJson);
            this.dashboardFilterType = accessConfigurationJson.IsFiltered ? 1 : 0;
            if (this.dashboardFilterType == 1) {
              this.selectedDashboards = accessConfigurationJson.SelectedDashboards;
            }
          }
        } else {
          this.dashboardFilterType = this.setDashboardData.IsFiltered ? 1 : 0;
          if (this.dashboardFilterType == 1) {
            this.selectedDashboards = this.setDashboardData.SelectedDashboards;
          }
        }
      } else {
        if (data && data.accessConfigurationJson) {
          accessConfigurationJson = JSON.parse(data.accessConfigurationJson);
          this.dashboardFilterType = accessConfigurationJson.IsFiltered ? 1 : 0;
          if (this.dashboardFilterType == 1) {
            this.selectedDashboards = accessConfigurationJson.SelectedDashboards;
          }
        }
      }
    },
    closeModal () {
      this.showAccess = false;
    },
    getFilterData: function () {
      return {
        IsFiltered: this.dashboardFilterType == 1,
        SelectedDashboards: this.selectedDashboards
      };
    },
    onSave () {
      var dashboardAccessSettings = JSON.stringify(this.getFilterData());
      this.$emit("OnDashboardAccessSave", this.businessProfileId, this.userId, dashboardAccessSettings);
    }
  },
  created: async function () {
    // eslint-disable-next-line no-undef
    this.businessProfileId = deltax.businessProfileId;
    this.apiService = new DashboardRoleAccessApiService();
  }
}
</script>

<style scoped>

.pointer{
    cursor: pointer !important;
}

.radio-group{
    display: block;
    width: 100%;
    height: 32px;
    padding-top: 6px;
    font-size: 13px;
    line-height: 1.42857143;
}

label {
    margin-bottom: 0px;
    margin-right: 5px;
}

::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #cccccc;
}
::-webkit-scrollbar-thumb {
    background: #A8A8A8;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

input {
  margin-right: 5px;
}
.btn {
  margin-left: 8px;
  margin-bottom: 0;
}
::v-deep  .modal_placement{
    display: flex;
    align-items: center;
    justify-content: center;
}
::v-deep .ivu-modal{
    top: 0;
}

</style>
