<template>
  <div class="dpa-audience-filters-settings" v-show="!isReadOnlyMode || !(inclusions.length == 0 && exclusions.length == 0)">
      <Row label="Audience Type">
        <Row-Col>
            <RadioGroup v-model="selectedAudienceType">
                <Radio label="Prospecting" :disabled="isReadOnlyMode"></Radio>
                <Radio label="Retargeting" :disabled="isReadOnlyMode"></Radio>
            </RadioGroup>
        </Row-Col>
      </Row>
  <!-- Inclusions Filter -->
    <Row class="dpa-audience-inclusion" v-if="selectedAudienceType == 'Retargeting'">
      <Row-Col class="filter-label" span="24" v-show="!isReadOnlyMode || inclusions.length > 0">
        <span>Inclusions</span>
      </Row-Col>
      <Row-Col span="22">
        <Row
          v-for="(item, index) in inclusions"
          :key="index"
          class="inclusion-exclusion-filter"
        >
          <Row-Col span="8">
            <Select
              v-model="item.value"
              placeholder="Select an action"
              :disabled="isReadOnlyMode"
              class="inclusion-exclusion-action"
            >
              <Option
                v-for="(actionItem, actionIndex) in targetProductAudienceActions"
                :key="actionIndex"
                :value="actionItem.value"
              >
                {{ actionItem.action }}
              </Option>
            </Select>
          </Row-Col>
          <Row-Col class="filter-description" span="3" offset="1">
            <span>in last</span>
          </Row-Col>
          <Row-Col span="3" offset="1">
            <input-number
              v-model="item.days"
              type="number"
              class="inclusion-exclusion-days"
              :min="1"
              :disabled="isReadOnlyMode"
            >
            </input-number>
          </Row-Col>
          <Row-Col class="filter-description" span="2" offset="2">
            <span>days</span>
          </Row-Col>
          <Row-Col class="filter-icon" span="3" offset="1">
            <Icon
              type="md-close"
              size="24"
              class="iview-icons close-btn"
              v-if="!isReadOnlyMode"
              @click.native="removeDpaFilter(index, dpaFilterTypes.inclusion)"
            />
          </Row-Col>
        </Row>
      </Row-Col>
      <Row-Col  class="filter-icon" span="2">
        <Icon
          type="md-add"
          size="24"
          id="add-inclusion"
          class="iview-icons add-btn"
          v-if="!isReadOnlyMode"
          @click.native="addDpaFilter(dpaFilterTypes.inclusion)"
        />
      </Row-Col>
    </Row>
    <!-- Exclusions Filter -->
    <Row class="dpa-audience-exclusion">
      <Row-Col span="24" v-show="!isReadOnlyMode || exclusions.length > 0">
        <span class="filter-label">Exclusions</span>
      </Row-Col>
      <Row-Col span="22">
        <Row
          v-for="(item, index) in exclusions"
          :key="index"
          class="inclusion-exclusion-filter"
        >
          <Row-Col span="8">
            <Select
              v-model="item.value"
              placeholder="Select an action"
              class="inclusion-exclusion-action"
              :disabled="isReadOnlyMode"
            >
              <Option
                v-for="(actionItem, actionIndex) in targetProductAudienceActions"
                :key="actionIndex"
                :value="actionItem.value"
              >
                {{ actionItem.action }}
              </Option>
            </Select>
          </Row-Col>
          <Row-Col class="filter-description" span="3" offset="1">
            <span>in last</span>
          </Row-Col>
          <Row-Col span="3" offset="1">
            <input-number
              v-model="item.days"
              type="number"
              :min="1"
              class="inclusion-exclusion-days"
              :disabled="isReadOnlyMode"
            >
            </input-number>
          </Row-Col>
          <Row-Col class="filter-description" span="2" offset="1">
            <span>days</span>
          </Row-Col>
          <Row-Col  class="filter-icon" span="3" offset="1">
            <Icon
              type="md-close"
              size="24"
              class="iview-icons close-btn"
              v-if="!isReadOnlyMode"
              @click.native="removeDpaFilter(index, dpaFilterTypes.exclusion)"
            />
          </Row-Col>
        </Row>
      </Row-Col>
      <Row-Col  class="filter-icon" span="2">
        <Icon
          type="md-add"
          size="24"
          id="add-exclusion"
          class="iview-icons add-btn"
          v-if="!isReadOnlyMode"
          @click.native="addDpaFilter(dpaFilterTypes.exclusion)"
        />
      </Row-Col>
    </Row>
  </div>
</template>
<script>
import { Row, Col, Select, Option, Icon, Input, RadioGroup, Radio } from "iview";
import { dpaFilterTypes } from "../../Constants/FacebookEnums.js";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["targetProductAudienceActions"])
  },
  components: {
    Row,
    "Row-Col": Col,
    Select,
    Option,
    Icon,
    'input-number': Input,
    RadioGroup,
    Radio
  },
  props: {
    isReadOnlyMode: {
      type: Boolean,
      default: false
    },
    audienceType: {
      type: String,
      default: "Prospecting"
    },
    filterTypes: {
      type: Object,
      default: function () {
        return { inclusion: 1, exclusion: 2 };
      }
    },
    audienceInclusions: {
      type: Array,
      default: function () {
        return [];
      }
    },
    audienceExclusions: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  mounted () {
    this.inclusions = JSON.parse(JSON.stringify(this.audienceInclusions));
    this.exclusions = JSON.parse(JSON.stringify(this.audienceExclusions));
    this.selectedAudienceType = this.audienceType
  },
  data () {
    return {
      inclusions: [],
      exclusions: [],
      dpaFilterTypes,
      selectedAudienceType: ''
    }
  },
  methods: {
    addDpaFilter (type) {
      if (type == dpaFilterTypes.inclusion) {
        this.inclusions.push({
          value: "ViewContent",
          days: 1
        });
      } else {
        this.exclusions.push({
          value: "Purchase",
          days: 1
        });
      }
    },
    removeDpaFilter (index, type) {
      if (type == dpaFilterTypes.inclusion) {
        this.inclusions.splice(index, 1);
      } else {
        this.exclusions.splice(index, 1);
      }
    },
    getAudienceFiltersInfo () {
      return { inclusions: this.inclusions, exclusions: this.exclusions };
    },
    getAudienceTypeinfo () {
      return this.selectedAudienceType;
    }
  },
  watch: {
    audienceInclusions: function () {
      this.inclusions = JSON.parse(JSON.stringify(this.audienceInclusions));
    },
    audienceExclusions: function () {
      this.exclusions = JSON.parse(JSON.stringify(this.audienceExclusions));
    },
    audienceType: function () {
      this.selectedAudienceType = this.audienceType
    }
  }
}
</script>
<style scoped>
.inclusion-exclusion-filter {
  padding: 0.25rem 0rem;
}
.iview-icons {
  cursor: pointer;
}
.add-btn {
  color: var(--main-bg-color);
}
.close-btn {
  color: maroon;
}
.dpa-audience-filters-settings {
  margin-top:15px;
  padding: 1rem;
}
span.filter-label {
  font-weight: bold;
}
.filter-description {
  padding-top: 5px
}
.filter-icon {
  padding-top: 3px
}
</style>
