<template>
  <div class="hot-div" style="width: 100%; height: 530px; overflow: hidden;">
    <hot-table class="adextension-hot-sheet" ref="adextension-hot-sheet" :settings="hotSettings"></hot-table>
  </div>
</template>
<script>
import { Message } from "iview";
import { mapState } from "vuex";
import { HotTable } from "@handsontable/vue";
import Handsontable from "handsontable";
import { adwordsEnums } from "../../Constants/AdwordsEnums";
import { adExtensionColumnsEnum } from "../../Configs/Adwords/AdExtension";

export default {
  components: {
    "hot-table": HotTable
  },
  props: {
    colHeaders: {
      type: Array,
      required: true,
      default () { return []; }
    },
    displayColHeaders: {
      type: Array,
      required: true,
      default () { return []; }
    },
    requiredColumns: {
      type: Array,
      required: true,
      default () { return []; }
    },
    invalidRows: {
      type: Array,
      required: true,
      default () { return []; }
    }
  },
  data: function () {
    return {
      startRows: 2,
      hotSettings: {},
      hotData: []
    };
  },
  computed: {
    ...mapState(["googleAccounts"]),
    accounts () {
      return this.googleAccounts instanceof Array ? this.googleAccounts.map(e => `${e.NickName} (${e.CustomerId})`).filter(e => e) : [];
    },
    weekDays () {
      return Object.values(adwordsEnums.dayOfWeek);
    },
    timeRanges () {
      const timeRanges = []
      for (let hour = 0; hour < 24; hour++) {
        timeRanges.push(...['00', '15', '30', '45'].map(minute => `${hour}:${minute}`));
      }

      return timeRanges;
    },
    structuredSnippetHeaderTypeOptions () {
      return adwordsEnums.structredSnippetHeaderTypes.reduce((l, e) => [...l, ...e.types], []);
    },
    countryCodeOptions () {
      return adwordsEnums.countryCodes;
    }
  },
  watch: {
    invalidRows () {
      this.$refs["adextension-hot-sheet"].hotInstance.render();
    }
  },
  created () {
    this.hotSettings = this.buildHotSettings();
  },
  methods: {
    showMessage (message, type = "success") {
      Message.destroy();
      Message[type](message);
    },
    isValidRenderer (instance, td, row, col, prop, value, cellProperties) {
      const rowData = this.hotData[row];
      const isValid = rowData instanceof Array && this.requiredColumns.map(col => this.displayColHeaders.indexOf(col)).every(index => rowData[index]);

      const isValidWrapper = document.createElement("div");
      isValidWrapper.classList.add("is-valid-extension");
      isValidWrapper.innerHTML = `<i class="${isValid ? "fa fa-check" : "fas fa-times"}"></i>`;

      Handsontable.dom.empty(td);

      td.className = cellProperties.className;
      td.appendChild(isValidWrapper);
    },
    clearHotData () {
      this.hotData = [this.colHeaders.map(_ => null)];
      this.$nextTick(() => {
        this.$refs["adextension-hot-sheet"].hotInstance.updateSettings({ data: this.hotData });
      });
    },
    getData () {
      const self = this;
      const hotSheet = self.$refs["adextension-hot-sheet"];

      if (!hotSheet || !hotSheet.hotInstance) return [];

      const data = hotSheet.hotInstance.getData();

      if (!data || !(data instanceof Array)) return [];

      return data
        .map((e, row) => e.reduce((l, e, i) => {
          if (e && self.displayColHeaders.length > i && self.displayColHeaders[i]) {
            if (l == null) l = { row };

            l[self.displayColHeaders[i]] = e;
          }

          return l;
        }, null))
        .filter(e => e);
    },
    buildHotSettings () {
      const self = this;

      self.hotData = [self.colHeaders.map(_ => null)];

      return {
        data: self.hotData,
        startCols: self.colHeaders.length,
        startRows: self.startRows,
        minSpareRows: 100,
        manualColumnResize: true,
        autoRowSize: true,
        autoColumnSize: true,
        copyPaste: {
          rowsLimit: 5000
        },
        colWidths: function (index) {
          if (self.colHeaders[index] == `HOT_${adExtensionColumnsEnum.IsValid}`) return 100;

          return 200;
        },
        colHeaders: function (index) {
          const header = self.colHeaders[index];

          let displayName = self.displayColHeaders[index];
          let displayText = displayName;

          if (self.requiredColumns instanceof Array && self.requiredColumns.includes(displayName)) {
            displayText += "<span style='color:#b94a48'>*</span>";
          }

          if (header.includes(adExtensionColumnsEnum.Day)) {
            displayText += `&nbsp<i class="fa fa-info-circle" title="Should be a valid weekday (eg. Monday, Tuesday, Wednesday, etc.)"></i>`
          } else if (header.includes(adExtensionColumnsEnum.StartTime) || header.includes(adExtensionColumnsEnum.EndTime)) {
            displayText += `&nbsp<i class="fa fa-info-circle" title="Should be in 24-hour format (h:mm) and minutes must be in multiples of 15 (eg. 15, 30, 45)"></i>`
          }

          return displayText;
        },
        renderAllRows: true,
        rowHeights: 55,
        columns: (index) => {
          if (index >= self.colHeaders.length) return;

          const settings = {};

          if (self.colHeaders[index] == `HOT_${adExtensionColumnsEnum.IsValid}`) {
            settings.readOnly = "true";
            settings.renderer = self.isValidRenderer
          } else if (self.colHeaders[index] == `HOT_${adExtensionColumnsEnum.Account}`) {
            settings.type = "dropdown";
            settings.source = self.accounts;
            settings.allowInvalid = false;
          } else if (self.colHeaders[index] == `HOT_${adExtensionColumnsEnum.HeaderType}`) {
            settings.type = "dropdown";
            settings.source = self.structuredSnippetHeaderTypeOptions;
            settings.allowInvalid = false;
          } else if (self.colHeaders[index] == `HOT_${adExtensionColumnsEnum.CountryCode}`) {
            settings.type = "autocomplete";
            settings.source = self.countryCodeOptions;
            settings.strict = true;
            settings.filter = true;
            settings.allowInvalid = false;
          } else if (self.colHeaders[index] == `HOT_${adExtensionColumnsEnum.PhoneNumber}`) {
            settings.validator = self.numberValidator;
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].includes(adExtensionColumnsEnum.StartDate) || self.colHeaders[index].includes(adExtensionColumnsEnum.EndDate)) {
            settings.type = "date";
            settings.dateFormat = "MM/DD/YYYY";
            settings.allowInvalid = false;
            settings.correctFormat = true;
            settings.allowEmpty = true;
            settings.datePickerConfig = {
              position: 'absolute',
              minDate: Date.today()
            }
          } else if (self.colHeaders[index] == `HOT_${adExtensionColumnsEnum.TrackingTemplate}` || self.colHeaders[index].includes("URL")) {
            settings.validator = self.urlValidator;
            settings.allowInvalid = false;
          }

          return settings;
        },
        cells: (row, col, prop) => {
          const cellProperties = {};

          cellProperties.className = self.invalidRows.includes(row) ? "invalid-cell" : "";

          return cellProperties;
        }
      };
    },
    numberValidator (value, callBack) {
      if (!value) return callBack(true);

      if (isNaN(Number(value))) {
        this.showMessage("Phone number should contain only digits (0-9)", "error");
        return callBack(false);
      }

      return callBack(true);
    },
    urlValidator (value, callBack) {
      if (!value) return callBack(true);

      if (!value.startsWith("http://") && !value.startsWith("https://")) {
        this.showMessage("URL should begin with 'http://' or 'https://'", "error");
        return callBack(false);
      }

      return callBack(true);
    }
  }
};
</script>
<style scoped>
.adextension-hot-sheet .handsontable td,
.adextension-hot-sheet .handsontable tr,
.adextension-hot-sheet .handsontable th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

::v-deep .invalid-cell {
  background-color: #ff000010;
}
</style>
<style>
.is-valid-extension {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
