<template>
   <div>
       <h5 class="version-header"> Versions </h5>
     <div style="height:320px !important;">
      <div v-for="mediaPlanVersion in paginatedData"
         :key="mediaPlanVersion.changeLogId"
         :class="mediaPlanVersionClass(mediaPlanVersion)"
         @click="onClickChangeLog(mediaPlanVersion)">

       <span class ="version-created">
         {{mediaPlanVersion.createdAt}}
      </span>
         <span class="italic-font" v-if="mediaPlanVersion.eventType == 2">
          Media Plan Approved
         </span>
         <span class="italic-font" v-if="mediaPlanVersion.isLatestVersion">
         Current Version
         </span>
         <i-tooltip
          placement="bottom"
          :content="mediaPlanVersion.userName">
          <span :class="userInitialClass(mediaPlanVersion.userInitials)">
          {{mediaPlanVersion.userInitials}}
          </span>
         </i-tooltip>
      </div>
     </div>
     <div class="pagination-iview" v-if = "mediaPlanVersions.length >pageSize" >
   <i-page :total="totalVersions" :page-size ="pageSize"  @on-change="pageChanged" size="small" >
      </i-page>
     </div>
   </div>
</template>
<script>
import { ApiService } from ".././Services/mediaPlanVersionApiService.js";
import {
  locale,
  Page,
  Tooltip
} from "iview";
import lang from "iview/dist/locale/en-US"; // iview is default chinese
locale(lang);
export default {
  components: {
    "i-page": Page,
    "i-tooltip": Tooltip
  },
  data () {
    return {
      mediaPlanVersions: [],
      mediaPlanObj: {},
      selectedChangeLogId: 0,
      totalVersions: 0,
      pageSize: 8,
      paginatedData: []
    }
  },
  props: {
    mediaPlanId: { default: 0 }
  },
  methods: {
    pageChanged: function (pageNumber) {
      var self = this;
      var startIndex = (pageNumber - 1) * this.pageSize;
      var endIndex = (this.pageSize * pageNumber) - 1;
      self.paginatedData = [];
      for (var i = startIndex; i <= endIndex; i++) {
        if (self.mediaPlanVersions[i] != undefined) {
          self.paginatedData.push(self.mediaPlanVersions[i]);
        }
      }
    },
    mediaPlanVersionClass: function (mediaPlanVersion) {
      var className = 'col-md-12 col-lg-12 media-plan-versions';
      if (mediaPlanVersion.eventType == 2) {
        className = className + ' disable-click';
      }
      if (mediaPlanVersion.isLatestVersion) {
        className = className + ' latest-version';
      }
      if (this.selectedChangeLogId == mediaPlanVersion.changeLogId) {
        className = className + ' selected-version';
      }
      return className;
    },
    userInitialClass: function (userInitials) {
      var userInitialClass = "";
      if (userInitials != "") {
        userInitialClass = 'user-initials-class'
      }
      return userInitialClass;
    },
    init: function (mediaPlanId) {
      if (mediaPlanId > 0) {
        var mediaPlanVersions = ApiService.getMediaPlanVersions(mediaPlanId);
        mediaPlanVersions.then(response => {
          this.mediaPlanVersions = [];
          var latestVersionAssigned = false;
          this.mediaPlanVersions = response.data.data.sort((a, b) => b.createdAt > a.createdAt ? 1 : -1);
          this.mediaPlanVersions.forEach(element => {
            element.isLatestVersion = false;
            if ((element.eventType == 0 || element.eventType == 1) && !latestVersionAssigned) {
              latestVersionAssigned = true;
              element.isLatestVersion = true;
            }
            element.createdAt = moment(element.createdAt).format('MMM D, h:mm');
            if (element.userInitials == undefined) {
              element.userInitials = "";
            }
            if (element.userName == undefined) {
              element.userName = "";
            }
          });
          this.pageChanged(1);
          this.totalVersions = this.mediaPlanVersions.length;
        }).catch(error => {
          console.log("Error in fetching mediaplanversions" + error);
        });
      }
    },
    onClickChangeLog: function (mediaPlanVersion) {
      this.selectedChangeLogId = mediaPlanVersion.changeLogId;
      var getMediaPlanObject = ApiService.getMediaPlanObject(this.mediaPlanId, mediaPlanVersion.changeLogId);
      getMediaPlanObject.then(response => {
        this.mediaPlanObj = response.data.data;
        this.$emit("updateMediaPlan", response.data.data, mediaPlanVersion.isLatestVersion);
      }).catch(error => {
        console.log("Error in fetching mediaplanversion" + error);
      });
    }
  }

}
</script>
<style src="../../../Styles/dx-iview.css">
  /*
    For default iview css, use
    iview/dist/styles/iview.css
  */
</style>
<style scoped>

.media-plan-versions {
  background-color: #f6f7f8;
 padding-top: 7px;
    padding-left: 5px;
    margin-top: 5px;
    margin-left: 5px;
    height: 35px;
  width: 98%;
  border: 1px solid;
}
.disable-click{
  pointer-events:none;
}
.latest-version{
 border: 2px solid green  !important;
}
.selected-version{
  border: 2px solid black  !important;
}
.version-header{
    font-size: 15px;
    margin-left: 5px;
}
.italic-font{
   font-style: italic;
}
.user-initials-class{
  border: 1px solid;
    float: right;
    margin-right: -10px;
    margin-top: -2px;
    width: 33px;
    padding-top: 1px;
    text-align: center;
    height: 21px;
    font-weight: bold;
}
.pagination-iview{
text-align: center;
padding-top: 28px;
}
.version-created{
  font-weight: bold;
}
</style>
