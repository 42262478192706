<template>
<div>
            <div class="col-md-12 no-padding">
               <div class="col-md-2 no-left-padding">
                     <i-select v-model="selectedBusinessProfileId" filterable :disabled="disableBusinessProfileSelection">
                        <i-option v-for="item in businessProfiles" :value="item.id" :key="item.id">{{ item.name }}</i-option>
                     </i-select>
                  <span>
                  <label v-show="!validBusinessProfile" class="required-message-style">* Required</label>
                  </span>
               </div>
               <div class="col-md-2">
                     <i-input
                        class="modal-label"
                        prefix="ios-home-outline"
                        v-model="briefName"
                        placeholder="Campaign Name"
                        @on-blur="handleBriefFocusOut"
                        />
                  <span>
                  <label  v-show="!(validBriefNameComputed && validBriefName)" class="required-message-style">* Required</label>
                  </span>
               </div>
               <div class="col-md-2">
                     <i-select v-model="briefObjective" placeholder ="Objective" >
                        <option-group v-for="(group, category) in mediaPlanObjectivesBasedOnCategory" :label="category" :key="category">
                           <i-option
                              v-for="item in group"
                              :value="item.id"
                              :key="item.id"
                              >{{item.name}}</i-option>
                        </option-group>
                     </i-select>
               </div>
               <div class="col-md-2" v-if="isDivisionEnabled">
                    <multi-select
                      v-model="divisionIds"
                      :multiple="true"
                      placeholder="Division"
                      :close-on-select="false"
                      :show-labels="false"
                      :options="divisions"
                      :tagPlaceholder="''"
                      label="name"
                      track-by="id"
                      @open="briefDivisionFocused = true"
                      @close="briefDivisionFocused = false"
                      @input="briefDivisionsChanged()"
                      :class="[briefDivisionFocused?'brief-division-focus':'brief-division-not-focus', isDivisionValid]"
                      >
                      </multi-select>
              </div>
              <div class="col-md-2">
                 <i-select v-model="businessLeadId" placeholder ="Business Lead" filterable>
                           <i-option
                              v-for="item in portalUsersList"
                              :value="item.id"
                              :key="item.id"
                              >{{item.fullName}}</i-option>
                     </i-select>
              </div>
              <div class="col-md-2">
                   <i-select placeholder="Planning Lead" v-model="planningLeadId" filterable>
                     <i-option v-for="item in portalUsersList" :value="item.id" :key="item.id">{{ item.fullName }}</i-option>
                  </i-select>
              </div>
            </div>
            <br>
            <br>
            <brief-phase-input ref="phaseInput" :verticalIntegration="false" :briefInputPhases.sync = "briefPhases"/>
            <div class="col-md-12 no-padding shift-down">
               <vue-editor v-model="briefDescription" :editorToolbar="customToolbar" ref="textEditor"></vue-editor>
            </div>
</div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import {
  OptionGroup,
  Select,
  Option,
  Input
} from "iview";
import { ApiService } from ".././Services/BriefCreateApiService.js";
import BriefPhaseInput from "../../Projects/Components/BriefPhaseInput.vue";
import Multiselect from "vue-multiselect";
export default {
  components: {
    "option-group": OptionGroup,
    "i-select": Select,
    "i-option": Option,
    "i-input": Input,
    "vue-editor": VueEditor,
    "brief-phase-input": BriefPhaseInput,
    "multi-select": Multiselect
  },
  data: function () {
    return {
      briefName: undefined,
      mediaPlanObjectivesBasedOnCategory: [],
      briefObjective: 0,
      briefDescription: '',
      portalUsersList: [],
      isPhasesValid: false,
      mediaPlanObjectivesSource: [],
      businessLeadId: 0,
      planningLeadId: 0,
      selectedBusinessProfileId: 0,
      businessProfiles: [],
      customToolbar: [
        ["bold"],
        [{ list: "bullet" }]
      ],
      validBriefName: true,
      validBudget: true,
      validBusinessProfile: true,
      divisions: [],
      divisionIds: [],
      disableBusinessProfileSelection: false,
      isDivisionEnabled: false,
      briefDivisionFocused: false,
      isDivisionValid: ''
    };
  },
  computed: {
    validBriefNameComputed: function () {
      if (this.briefName != undefined) {
        if (this.briefName.length > 0) { this.validBriefName = true; return true; } else { return false }
      } else {
        return true
      }
    }
  },
  watch: {
    selectedBusinessProfileId: function (val) {
      var self = this;
      if (val > 0 && Number.isInteger(val)) {
        self.validBusinessProfile = true;
        var mediaPlanObjectivesPromise = ApiService.getMediaPlanObjectives(val);
        mediaPlanObjectivesPromise.then(res => {
          self.mediaPlanObjectivesSource = res.data;
          var groupBy = function (objectives, key) {
            return objectives.reduce(function (element, x) {
              (element[x[key]] = element[x[key]] || []).push(x);
              return element;
            }, {});
          };
          self.mediaPlanObjectivesBasedOnCategory = groupBy(self.mediaPlanObjectivesSource, 'category')
        });

        var businessProfilePromise = ApiService.getBusinessProfile(val);
        businessProfilePromise.then(res => {
          this.isDivisionEnabled = res.data.data.isDivisionEnabled != undefined && res.data.data.isDivisionEnabled != null
            ? res.data.data.isDivisionEnabled
            : false
          this.$emit("isDivisionEnabled", this.isDivisionEnabled);
          if (this.isDivisionEnabled) {
            var divisionsPromise = ApiService.getDivisions(val);
            divisionsPromise.then(res => {
              this.divisions = res.data.data.filter(x => x.isDisabled != true).map(x => x);
              this.divisionIds.push(this.divisions[0]);
              this.$emit("briefInputChanged", true);
            })
          }
        });
      }
    }
  },
  props: ["businessProfileId"],
  methods: {
    getBriefDetails: function () {
      var self = this;
      var briefObj;

      if (!this.briefName || !this.selectedBusinessProfileId) {
        self.validBriefName = !((this.briefName === undefined || this.briefName === ""))
        self.validBusinessProfile = !((this.selectedBusinessProfileId === undefined || this.selectedBusinessProfileId === 0))
      } else {
        self.validBudget = true;
        self.validBriefName = true;
        self.validBusinessProfile = true;

        briefObj = {
          "description": this.briefDescription,
          "name": this.briefName,
          "businessLeadId": this.businessLeadId,
          "planningLeadId": this.planningLeadId,
          "objectiveId": this.briefObjective,
          "selectedBusinessProfileId": this.selectedBusinessProfileId,
          "divisionIds": this.divisionIds.map(d => d.id),
          "phases": this.briefPhases
        }
      }
      return briefObj;
    },
    briefDivisionsChanged: function () {
      if (this.divisionIds.length > 0) {
        this.$emit("briefInputChanged", true);
        this.isDivisionValid = ''
      } else {
        this.$emit("briefInputChanged", false);
        this.isDivisionValid = ' field-empty-error '
      }
    },
    handleBriefFocusOut () {
      if (this.briefPhases.length <= 1) {
        this.briefPhases[0].name = this.briefName;
      }
    }
  },
  mounted () {
    var self = this;
    this.$watch(
      "$refs.phaseInput.isValidPhaseInput",
      (newValue, oldValue) => {
        self.isPhasesValid = newValue;
        self.$emit("phaseInputChanged", newValue);
      }
    );
    var promises = [];
    var promisePortalUsers = ApiService.getAllPortalUsers();
    var promisePortalBusinessProfiles = ApiService.getBusinessProfiles();
    promises.push(promisePortalUsers);
    promises.push(promisePortalBusinessProfiles);
    Promise.all(promises).then(function (responses) {
      self.portalUsersList = responses[0].data;
      var businessProfiles = responses[1].data.data;
      self.businessProfiles = [...new Map(businessProfiles.map(item => [item['id'], item])).values()]
    })
    if (this.businessProfileId > 0) {
      this.selectedBusinessProfileId = parseInt(this.businessProfileId);
      this.disableBusinessProfileSelection = true;
    }
  }
};
</script>
<style scoped>
.icon-class {
  margin-top: 5px;
  float: left;
  margin-right: -10px;
}
.icon-size {
  font-size: 20px;
  cursor: initial;
}
.media-plan-name-cost-textbox {
  float: left;
  padding-left: 10px;
}
.modal-label {
  text-align: right !important;
}
.text-box-div {
  margin-left: 5px !important;
}
.shift-down{
  margin-top: 8px;
}
.media-plan-objective{
  margin-left: 5px !important;
}
.required-cost-message-style{
  color:#CC3F44;
  margin-left: 20px;
  margin-right: 35px;
  font-size: 12px;
}
.required-message-style{
  color:#CC3F44;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 12px;
}
::v-deep .ql-editor {
    color: #000 !important;
    font-size: 15px !important;
    max-height: 200px !important;
}

.ql-editor {
    min-height: 200px;
    font-size: 16px;
}

::v-deep .ql-editor>* {
color: #000 ;
}

::v-deep .ivu-icon .ivu-icon-ios-calendar-outline .ivu-input-icon .ivu-input-icon-normal{
  display: none !important;
}
::v-deep .ivu-select-group-title {
    color: black;
    font-weight: bold;
}
::v-deep .ivu-modal-header {
    padding: 2px 10px !important;
}
::v-deep .ivu-icon.ivu-icon-ios-calendar-outline.ivu-input-icon.ivu-input-icon-normal{
  display: none;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
}
::v-deep .ivu-date-picker-editor .ivu-input.ivu-input-default{
  padding-right: 5px;
}
::v-deep .ivu-select-input::placeholder {
  /* Firefox, Chrome, Opera */
  font-weight: 400 !important;
  border-color: 1px solid #cecdcd !important;
  color: black !important;
}

::v-deep .ivu-select-input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 400;
  border-color: 1px solid #cecdcd !important;
  color: black !important;
}

::v-deep .ivu-input.ivu-input-default {
  border: 1px solid #cecdcd !important;
}

::v-deep .ivu-select-selection {
  padding: 0 24px 0 0px;
  border: 1px solid #cecdcd !important;
}

::v-deep .ivu-select-dropdown-list {
  padding: 0;
}
.modal-body {
  padding :0px !important;
}
.no-left-padding {
  padding-left:0px !important;
}
::v-deep .ivu-select-single .ivu-select-selection .ivu-select-input::-webkit-input-placeholder {
    color: #c5c8ce !important;
  }
::v-deep .multiselect{
  font-size:12px;
  min-height: 32px;
}
::v-deep .multiselect__tags{
    padding: 4px 20px 2px 5px !important;
    border: 1px solid #cecdcd !important;
}
::v-deep .brief-division-not-focus > .multiselect__tags{
  height: 32px;
}
.field-empty-error {
  border: red solid 1px;
}
</style>
