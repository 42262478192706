export default {
  methods: {
    generateTargetStringHtml (objTarget, isFlexibleTarget, isExclusion) {
      var self = this;
      var tableContainer = document.createElement("div");
      var table = document.createElement("table");
      tableContainer.appendChild(table);
      let locationString = "";
      let excludedLocationString = "";
      if (!isFlexibleTarget && !isExclusion) {
        table.setAttribute(
          "class",
          "target-info-table scrollable ps-container ps-active-y"
        );
        table.setAttribute("data-max-height", "600");
        table.setAttribute("data-max-width", "600");
      }
      if (objTarget.AgeGroup != null) {
        table.appendChild(
          this.commonHtmlElement("Age Group", objTarget.AgeGroup)
        );
      }
      if (objTarget.Gender != null) {
        table.appendChild(this.commonHtmlElement("Gender", objTarget.Gender));
      }
      if (objTarget.RelationshipStatus != null) {
        table.appendChild(
          this.commonHtmlElement(
            "Relationship Status",
            objTarget.RelationshipStatus
          )
        );
      }
      if (objTarget.InterestedIn != null) {
        table.appendChild(
          this.commonHtmlElement("Interested In", objTarget.InterestedIn)
        );
      }

      if (objTarget.Countries != null) {
        locationString += objTarget.Countries + " | ";
      }
      if (objTarget.Regions != null) {
        locationString += objTarget.Regions + " | ";
      }
      if (objTarget.Cities != null) {
        locationString += objTarget.Cities + " | ";
      }
      if (objTarget.Zips != null) {
        var zipCodes = objTarget.Zips.split("Zips : ");
        var values = zipCodes[1].split(";");
        if (values.length > 10) {
          locationString += values.length + " Zip Codes | ";
        } else {
          locationString += objTarget.Zips + " | ";
        }
      }
      if (objTarget.GeoMarkets != null) {
        locationString += objTarget.GeoMarkets + " | ";
      }
      if (objTarget.Address != null) {
        locationString += objTarget.Address + " | ";
      }
      if (objTarget.LatLong != null) {
        locationString += objTarget.LatLong + " | ";
      }
      if (objTarget.LocationTypes != null) {
        locationString += objTarget.LocationTypes + " | ";
      }
      if (locationString !== "") {
        locationString = locationString
          .trim()
          .substring(0, locationString.length - 3);
        table.appendChild(this.commonHtmlElement("Locations", locationString));
      } else if (isFlexibleTarget === false && isExclusion === false) {
        table.appendChild(this.commonHtmlElement("Locations", "Not Available"));
      }

      if (objTarget.CountriesExcluded != null) {
        excludedLocationString += objTarget.CountriesExcluded + " | ";
      }
      if (objTarget.RegionsExcluded != null) {
        excludedLocationString += objTarget.RegionsExcluded + " | ";
      }
      if (objTarget.CitiesExcluded != null) {
        excludedLocationString += objTarget.CitiesExcluded + " | ";
      }
      if (objTarget.ZipsExcluded != null) {
        var zipCodes = objTarget.ZipsExcluded.split("Zips : ");
        var values = zipCodes[1].split(";");
        if (values.length > 10) {
          excludedLocationString += values.length + " Zip Codes | ";
        } else {
          excludedLocationString += objTarget.ZipsExcluded + " | ";
        }
      }
      if (objTarget.GeoMarketsExcluded != null) {
        excludedLocationString += objTarget.GeoMarketsExcluded + " | ";
      }
      if (objTarget.AddressExcluded != null) {
        excludedLocationString += objTarget.AddressExcluded + " | ";
      }
      if (objTarget.LatLongExcluded != null) {
        excludedLocationString += objTarget.LatLongExcluded + " | ";
      }
      if (objTarget.locationTypesExcluded != null) {
        excludedLocationString += objTarget.locationTypesExcluded + " | ";
      }
      if (excludedLocationString !== "") {
        excludedLocationString = excludedLocationString
          .trim()
          .substring(0, excludedLocationString.length - 3);
        table.appendChild(
          this.commonHtmlElement("Excluded Locations", excludedLocationString)
        );
      } else if (isFlexibleTarget === false && isExclusion === false) {
        table.appendChild(
          this.commonHtmlElement("Excluded Locations", "Not Available")
        );
      }

      if (
        objTarget.flexible_spec != null &&
        !$.isEmptyObject(objTarget.flexible_spec[0])
      ) {
        var getTargetForFlexible;

        objTarget.flexible_spec.forEach(function (ele, index) {
          getTargetForFlexible = self.generateTargetStringHtml(
            ele,
            true,
            false
          );
          table.appendChild(
            self.commonHtmlElement(
              "Flexible Target " + (index + 1),
              getTargetForFlexible
            )
          );
        });
      }
      if (objTarget.exclusion != null) {
        var getTargetExclusion = self.generateTargetStringHtml(
          objTarget.exclusion,
          false,
          true
        );
        table.appendChild(
          this.commonHtmlElement("Exclusion Target", getTargetExclusion)
        );
      }
      if (objTarget.SocialInterest != null) {
        table.appendChild(
          this.commonHtmlElement("Interests", objTarget.SocialInterest)
        );
      }
      if (objTarget.SocialExcludedInterest != null) {
        table.appendChild(
          this.commonHtmlElement(
            "Excluded Interests",
            objTarget.SocialExcludedInterest
          )
        );
      }
      if (objTarget.Behaviors != null) {
        table.appendChild(
          this.commonHtmlElement("Behaviors", objTarget.Behaviors)
        );
      }
      if (objTarget.EducationLevel != null) {
        table.appendChild(
          this.commonHtmlElement("Education Level", objTarget.EducationLevel)
        );
      }
      if (objTarget.FieldsOfStudy != null) {
        table.appendChild(
          this.commonHtmlElement("Fields Of Study", objTarget.FieldsOfStudy)
        );
      }
      if (objTarget.Schools != null) {
        table.appendChild(this.commonHtmlElement("Schools", objTarget.Schools));
      }
      if (objTarget.UndergradYears != null) {
        table.appendChild(
          this.commonHtmlElement("Undergrad Years", objTarget.UndergradYears)
        );
      }
      if (objTarget.Employer != null) {
        table.appendChild(
          this.commonHtmlElement("Employer", objTarget.Employer)
        );
      }
      if (objTarget.JobTitles != null) {
        table.appendChild(
          this.commonHtmlElement("Job Titles", objTarget.JobTitles)
        );
      }
      if (objTarget.Industries != null) {
        table.appendChild(
          this.commonHtmlElement("Industries", objTarget.Industries)
        );
      }
      if (objTarget.CustomAudiances != null) {
        table.appendChild(
          this.commonListHtmlElement("Custom Audiences", objTarget.CustomAudiances)
        );
      }
      if (objTarget.ExcludedAudiances) {
        table.appendChild(
          this.commonListHtmlElement(
            "Excluded Audiences",
            objTarget.ExcludedAudiances
          )
        );
      }
      if (objTarget.Connections != null) {
        table.appendChild(
          this.commonHtmlElement("Connections", objTarget.Connections)
        );
      }
      if (objTarget.ExcludedConnections != null) {
        table.appendChild(
          this.commonHtmlElement(
            "Excluded Connections",
            objTarget.ExcludedConnections
          )
        );
      }
      if (objTarget.FriendsOfConnections != null) {
        table.appendChild(
          this.commonHtmlElement(
            "Friends Of Connections",
            objTarget.FriendsOfConnections
          )
        );
      }
      if (objTarget.EthenicAffinity != null) {
        table.appendChild(
          this.commonHtmlElement("Ethnic Affinity", objTarget.EthenicAffinity)
        );
      }
      if (objTarget.Generations != null) {
        table.appendChild(
          this.commonHtmlElement("Generations", objTarget.Generations)
        );
      }
      if (objTarget.AllParents != null) {
        table.appendChild(
          this.commonHtmlElement("All Parents", objTarget.AllParents)
        );
      }
      if (objTarget.Politics != null) {
        table.appendChild(
          this.commonHtmlElement("Politics", objTarget.Politics)
        );
      }
      if (objTarget.LifeEvents != null) {
        table.appendChild(
          this.commonHtmlElement("Life Events", objTarget.LifeEvents)
        );
      }
      if (objTarget.UserDevice != null) {
        table.appendChild(
          this.commonHtmlElement("Devices", objTarget.UserDevice)
        );
      }
      if (objTarget.UserOs != null) {
        table.appendChild(this.commonHtmlElement("User OS", objTarget.UserOs));
      }
      if (objTarget.WirelessCarrier != null) {
        table.appendChild(
          this.commonHtmlElement("Wireless Carrier", objTarget.WirelessCarrier)
        );
      }
      if (objTarget.Markets != null) {
        table.appendChild(this.commonHtmlElement("Markets", objTarget.Markets));
      }
      if (objTarget.Income != null) {
        table.appendChild(this.commonHtmlElement("Income", objTarget.Income));
      }
      if (objTarget.HomeType != null) {
        table.appendChild(
          this.commonHtmlElement("Home Type", objTarget.HomeType)
        );
      }
      if (objTarget.NetWorth != null) {
        table.appendChild(
          this.commonHtmlElement("Net Worth", objTarget.NetWorth)
        );
      }
      if (objTarget.HomeOwnership != null) {
        table.appendChild(
          this.commonHtmlElement("Home Ownership", objTarget.HomeOwnership)
        );
      }
      if (objTarget.HouseholdComposition != null) {
        table.appendChild(
          this.commonHtmlElement(
            "Household Composition",
            objTarget.HouseholdComposition
          )
        );
      }
      if (objTarget.Moms != null) {
        table.appendChild(this.commonHtmlElement("Moms", objTarget.Moms));
      }
      if (objTarget.Placements != null) {
        table.appendChild(
          this.commonHtmlElement("Placements", objTarget.Placements)
        );
      }

      if (objTarget.ProductAudienceSpec != null) {
        var finalHtmlEl = document.createElement("div");
        var br = document.createElement("br");
        if (objTarget.ProductAudienceSpec.ProductSetId != null) {
          let titleSpan = document.createElement("span");
          let b = document.createElement("b");
          b.innerHTML = "Product set id: ";
          titleSpan.appendChild(b);

          let ValueSpan = document.createElement("span");
          ValueSpan.innerHTML = objTarget.ProductAudienceSpec.ProductSetId;
          finalHtmlEl.appendChild(titleSpan);
          finalHtmlEl.appendChild(ValueSpan);
          finalHtmlEl.appendChild(br);
        }
        if (objTarget.ProductAudienceSpec.Inclusions != null) {
          let titleSpan = document.createElement("span");
          let b = document.createElement("b");
          b.innerHTML = "Inclusions: ";
          titleSpan.appendChild(b);

          let ValueSpan = document.createElement("span");
          ValueSpan.innerHTML = objTarget.ProductAudienceSpec.Inclusions;
          finalHtmlEl.appendChild(titleSpan);
          finalHtmlEl.appendChild(ValueSpan);
          finalHtmlEl.appendChild(br);
        }
        if (objTarget.ProductAudienceSpec.Exclusions != null) {
          let titleSpan = document.createElement("span");
          let b = document.createElement("b");
          b.innerHTML = "Exclusions: ";
          titleSpan.appendChild(b);

          let ValueSpan = document.createElement("span");
          ValueSpan.innerHTML = objTarget.ProductAudienceSpec.Exclusions;
          finalHtmlEl.appendChild(titleSpan);
          finalHtmlEl.appendChild(ValueSpan);
          finalHtmlEl.appendChild(br);
        }
        table.appendChild(
          this.commonHtmlElement("Product Audience Spec", finalHtmlEl.innerHTML)
        );
      }
      return table.outerHTML;
    },
    commonHtmlElement (name, value) {
      let tr = document.createElement("tr");
      let tdKey = document.createElement("td");
      tdKey.setAttribute("style", "width:75px; vertical-align:top");
      tdKey.innerHTML = name;
      let tdValue = document.createElement("td");
      tdValue.setAttribute("style", "text-align : left !important; max-width: 700px !important;");
      if (value.toString().includes("HTMLTable")) {
        tdValue.appendChild(value);
      } else {
        tdValue.innerHTML = value;
      }
      tr.appendChild(tdKey);
      tr.appendChild(tdValue);
      return tr;
    },
    commonListHtmlElement (name, value) {
      let tr = document.createElement("tr");
      let tdKey = document.createElement("td");
      tdKey.setAttribute("style", "width:75px; vertical-align:top");
      tdKey.innerHTML = name;
      let tdValue = document.createElement("td");
      tdValue.setAttribute("style", "text-align : left !important; max-width: 700px !important;");

      let ul = document.createElement("ul");
      ul.setAttribute("style", "list-style-type:none");
      let items = value.split(",");
      for (let i = 0; i < items.length; i++) {
        let li = document.createElement("li");
        li.innerHTML = items[i].trim();
        ul.appendChild(li);
      }
      tdValue.appendChild(ul);
      tr.appendChild(tdKey);
      tr.appendChild(tdValue);
      return tr;
    },
    setSocialTargetRequestData () {
      let targetRequest = {
        saveFilter: "false",
        param: {
          columns: [
            {
              data: "Id",
              name: "Id",
              searchable: true,
              orderable: false,
              search: { value: "", regex: false }
            }
          ],
          order: [{ column: 0, dir: "desc" }],
          start: 0,
          length: 10000000
        },
        businessProfileSearchEngineId: [this.businessProfilesSearchEngineId]
      };
      this.socialTargetRequestData = targetRequest;
    }
  }
};
