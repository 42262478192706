import Constants from "../assets/Constants";

const SE = Constants.SearchEngines;
const logoPath = "/Content/Images/Channels";
const defaultLogo = "deltax.png";

const logoUrlMap = {
  [SE.GoogleAds]: "Google_Ads_small.svg",
  [SE.Bing]: "Bing_Ads_logo 2.svg",
  [SE.Facebook]: "Meta-small.svg",
  [SE.Twitter]: "x.png",
  [SE.LinkedIn]: "LinkedIn-small.svg",
  [SE.YahooGemini]: "yahoo-small.svg",
  [SE.DV360]: "dv360 1.svg",
  [SE.AMS]: "amazon 1.svg",
  [SE.Hotstar]: "hotstar-small.svg",
  [SE.Voot]: "voot.svg",
  [SE.Quora]: "quora-small.svg",
  [SE.Baidu]: "baidu-small.svg",
  [SE.Amadeus]: "amadeus.png",
  [SE.AppsFlyer]: "Appsflyer small.svg",
  [SE.ZohoCRM]: "zoho-logo.svg",
  [SE.GoogleAnalytics]: "Google_Analytics-small.svg",
  [SE.Magento]: "magneto-small.svg",
  [SE.MicrosoftDynamics]: "Microsoft_Dynamics_Logo.svg",
  [SE.Mixpanel]: "mixpanel.png",
  [SE.AdobeAnalytics]: "adobe analytics.png",
  [SE.Salesforce]: "salesforce.png",
  [SE.Shopify]: "Shopify_small.svg",
  [SE.Xandr]: "xandr.svg",
  [SE.MediaMath]: "MediaMath 1.svg",
  [SE.OutBrain]: "outbrain-small.svg",
  [SE.Pinterest]: "Pinterest-logo 1.svg",
  [SE.Naver]: "naver-small.svg",
  [SE.Snapchat]: "snapchat-logo-.svg",
  [SE.AdRoll]: "adroll-small.svg",
  [SE.Eskimi]: "eskimi.png",
  [SE.WooCommerce]: "woo-commerce.png",
  [SE.AppleSearchAds]: "apple-small.svg",
  [SE.TikTokAds]: "TikTok.svg",
  [SE.BigCommerce]: "big-commerce.png",
  [SE.TTD]: "the trade desk-1.svg",
  [SE.GoogleMyBusiness]: "google-my-business.png",
  [SE.Birdigo]: "birdigo.svg",
  [SE.Reddit]: "reddit.png",
  [SE.Instacart]: "instacart.png",
  [SE.DeltaX]: "deltax.png",
  [SE.DV360CampaignManager]: "dv360 campaign manager.png",
  [SE.Sizmek]: "sizmek.png",
  [SE.Moloco]: "moloco.png",
  [SE.Loblaws]: "Loblaws.png",
  [SE.Criteo]: "Criteo.png",
  [SE.SA360]: "sa360-logo.svg",
  [SE.GoogleDrive]: "Google_Drive.png"
};

export default function getChannelLogo (typeId) {
  return `${logoPath}/${logoUrlMap[typeId] || defaultLogo}`;
};
