// Enum Base,Format,Type values are matching with corresponding table Ids
export const Type = Object.freeze({
  "OneTime": 1,
  "Daily": 3,
  "Weekly": 4,
  "Monthly": 5,
  "Recurring": [3, 4, 5]
});

export const Schedule = Object.freeze({
  "Upcoming": 1,
  "Ongoing": 2,
  "Completed": 3
});

export const Base = Object.freeze({
  "Query Reports": 1,
  "Media Plan Reports": 2,
  "Custom Reports": 3,
  "Adhoc Reports": 4,
  "Standard Reports": 6,
  "Presentations": 7,
  "DIY Dashboard Reports": 8,
  "Power BI Dashboard Reports": 10
});

export const Format = Object.freeze({
  "Excel": 1,
  "CSV": 2,
  "Report PDF": 3,
  "Presentation PDF": 4
});

export const ReportConfiguration = {
  QueryReports: 1,
  MediaPlanReports: 2,
  PresetTemplateReports: 3,
  AdhocReports: 4,
  PortalReports: 6,
  Presentations: 7,
  DiyDashboardReports: 8,
  PowerBIDashboardReports: 10
}

export const CoreReportType = {
  StandardReport: 1,
  PortalReport: 2
}

export const ReportExportStatus = {
  InQueue: 1,
  Processing: 2,
  Success: 3,
  Failed: 4
}

export const ReportExportStatusEnum = {
  1: 'In Queue',
  2: 'Processing',
  3: 'Success',
  4: 'Failed'
}
