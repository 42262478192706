import { render, staticRenderFns } from "./AmazonSD.vue?vue&type=template&id=3398c883&scoped=true&"
import script from "./AmazonSD.vue?vue&type=script&lang=js&"
export * from "./AmazonSD.vue?vue&type=script&lang=js&"
import style0 from "./AmazonSD.vue?vue&type=style&index=0&lang=css&"
import style1 from "./AmazonSD.vue?vue&type=style&index=1&id=3398c883&scoped=true&lang=css&"
import style2 from "../../../../../../../node_modules/handsontable/dist/handsontable.full.css?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3398c883",
  null
  
)

export default component.exports