<template>
  <i-modal
      v-model="alwaysTrue"
      width="75%"
      :styles="{top: '100px'}"
      :closable="false"
      :mask-closable="false"
      ref="scheduler"
    >
      <h4 class="title" slot="header" >
              Spots Schedule
      </h4>
       <div style="height: 350px;">
        <hot-table ref="table" :settings="hotSettings"></hot-table>
      </div>
      <div slot="footer">
       <button
          type="button"
          class="btn create-btn btn-white button-style"
          v-on:click="schedulerPopUpCancel"
         >Cancel</button>
      <button
          type="button"
          class="btn create-btn btn-success button-style"
          v-on:click="save"
          >Save</button>
       </div>
  </i-modal>
  </template>
<script>
import { Modal } from "iview";
import { HotTable } from '@handsontable/vue';
export default {
  components: {
    "i-modal": Modal,
    "hot-table": HotTable
  },
  props: [
    "lineItems",
    "mediaPlanName",
    "mediaPlanStartDate",
    "mediaPlanEndDate"
  ],
  mounted () {
    var mpEndDate = new Date(this.mpEndDate);
    var date = new Date(this.mpStartDate);
    var mediaPlanDateArray = [];
    while (date <= mpEndDate) {
      // Need to use due to lint error
      var currentDate = date;
      this.dynamicHeaders.push({ title: moment(new Date(date)).format("DD MMM"),
        type: 'numeric',
        validator: (value, cb) => cb(this.numericValidator(value)),
        data: moment(new Date(date)).format("MMDDYYYY") + '.value' })
      mediaPlanDateArray.push(moment(new Date(date)).format("MMDDYYYY"))
      currentDate.setDate(date.getDate() + 1);
      date = currentDate;
    }
    var lineItemDataArray = [];
    this.lineItemData.forEach(rowData => {
      let lineItemDisaplayObject = {
        id: rowData.orderId,
        channel: rowData.channel,
        placement: rowData.placement,
        segment: rowData.segment
      }
      mediaPlanDateArray.forEach(element => {
        lineItemDisaplayObject[element] = { value: 0, readOnly: true };
      })
      var liStartDate = new Date(moment(rowData.startdate).format('YYYY-MM-DD'));
      var liEndDate = new Date(moment(rowData.enddate).format('YYYY-MM-DD'));
      var slots = 0;
      if (rowData.lineItemScheduledSlots != undefined) {
        rowData.lineItemScheduledSlots.forEach(slotData => {
          var slotDate = new Date(moment(slotData.scheduledDate).format('YYYY-MM-DD'));
          if (mediaPlanDateArray.includes(moment(new Date(slotData.scheduledDate)).format("MMDDYYYY"))) {
            lineItemDisaplayObject[moment(new Date(slotData.scheduledDate)).format("MMDDYYYY")].value = slotData.slots
            if (slotDate >= liStartDate && slotDate <= liEndDate) {
              slots += slotData.slots
            }
          }
        })
      }
      var dateIterator = new Date(this.mpStartDate);
      while (dateIterator <= mpEndDate) {
        var currentDate = dateIterator;
        lineItemDisaplayObject[moment(new Date(dateIterator)).format("MMDDYYYY")].readOnly = !(dateIterator >= liStartDate && dateIterator <= liEndDate)
        currentDate.setDate(dateIterator.getDate() + 1);
        dateIterator = currentDate;
      }
      var plannedSlots = rowData.Spots ? rowData.Spots : 0
      lineItemDisaplayObject['slotPerPlan'] = slots + " / " + plannedSlots
      lineItemDataArray.push(lineItemDisaplayObject);
    })
    this.htData = lineItemDataArray;
    this.totalHeaders = [...this.defaultHeaders, ...this.dynamicHeaders];
    this.updateColumns(lineItemDataArray);
  },
  data: function () {
    return {
      alwaysTrue: true,
      settings: {
        return: {
        }
      },
      htData: [
      ],
      totalHeaders: [],
      defaultHeaders: [
        {
          title: 'Channel',
          type: 'text',
          data: 'channel',
          readOnly: true,
          wordWrap: false,
          minwidth: "150px",
          width: "150px"
        },
        {
          title: 'Placement',
          type: 'text',
          data: 'placement',
          readOnly: true,
          wordWrap: false,
          minwidth: "150px",
          width: "150px"
        },
        {
          title: 'Segment',
          type: 'text',
          data: 'segment',
          readOnly: true,
          wordWrap: false,
          minwidth: "150px",
          width: "150px"
        },
        {
          title: 'Slots / Plan',
          type: 'text',
          data: 'slotPerPlan',
          readOnly: true,
          wordWrap: false,
          minwidth: "80px",
          width: "80px"
        }
      ],
      startRows: 2,
      dynamicHeaders: [],
      lineItemData: this.lineItems,
      mpName: this.mediaPlanName,
      mpStartDate: this.mediaPlanStartDate,
      mpEndDate: this.mediaPlanEndDate
    }
  },
  methods: {
    schedulerPopUpCancel: function () {
      this.$emit("cancelActionForScheduler");
    },
    buildHotSettings () {
      var self = this;
      return {
        data: self.htData,
        colHeaders: [...self.defaultHeaders],
        columns: [...self.defaultHeaders],
        startRows: self.startRows,
        manualColumnResize: true,
        autoColumnSize: true,
        manualRowResize: true,
        stretchH: 'all',
        colWidths: 50,
        autoWrapRow: true,
        autoWrapCol: true,
        afterChange: self.afterChange,
        rowHeights: 23,
        height: 345
      }
    },
    updateColumns (lineItemDataArray) {
      var updatedSettings = {
        columns: [...this.defaultHeaders, ...this.dynamicHeaders],
        colHeaders: this.totalHeaders.map(header => header.title),
        data: [...lineItemDataArray],
        cells: this.cellProperties,
        viewportColumnRenderingOffset: this.totalHeaders.length
      }
      this.hot.updateSettings(updatedSettings);
      this.reRendering();
    },
    afterChange (changes) {
      if (changes) {
        changes.forEach(([row, prop, oldValue, newValue]) => {
          var previousValue = 0;
          var plannedSpots = 0;
          if (typeof (newValue) == 'number') {
            plannedSpots = this.lineItemData[row].Spots ? this.lineItemData[row].Spots : 0;
            previousValue = typeof (oldValue) == 'number' ? oldValue : 0;
            this.htData[row].slotPerPlan = parseInt(this.htData[row].slotPerPlan.split('/')) + parseInt(newValue) - parseInt(previousValue) + ' / ' + plannedSpots;
          } else {
            plannedSpots = this.lineItemData[row].Spots ? this.lineItemData[row].Spots : 0
            previousValue = typeof (oldValue) == 'number' ? oldValue : 0;
            var newChangedValue = 0;
            this.htData[row].slotPerPlan = parseInt(this.htData[row].slotPerPlan.split('/')) + parseInt(newChangedValue) - parseInt(previousValue) + ' / ' + plannedSpots;
          }
        });
        this.updateColumns(this.htData);
      }
    },
    cellProperties (row, col) {
      if (col >= this.totalHeaders.length) {
        return {};
      }
      var hr = this.totalHeaders[col].data;
      var wheatherDynamic = hr.split('.').length > 1;
      var cellProp = {};
      var propertyName = hr.split('.')[0];
      if (wheatherDynamic && this.htData[row] != undefined) {
        cellProp.readOnly = this.htData[row][propertyName].readOnly;
      }
      return cellProp;
    },
    save: function () {
      this.htData.forEach(obj => {
        var scheduledSlots = [];
        var keys = Object.keys(obj);
        keys.forEach(key => {
          if (key != 'channel' && key != 'segment' && key != 'placement' && key != 'slotsPerPlan' && key != 'id') {
            if (obj[key].value > 0) {
              var mm = key.substring(0, 2).trim();
              var dd = key.substring(2, 4).trim();
              var yyyy = key.substring(4, 8).trim();
              scheduledSlots.push({
                scheduledDate: new Date(yyyy + "-" + mm + "-" + dd),
                slots: parseInt(obj[key].value)
              })
            }
          }
        })
        var index = this.lineItemData.findIndex(x => x.orderId == obj.id);
        var lineItem = this.lineItemData[index];
        lineItem.lineItemScheduledSlots = scheduledSlots;
      })
      this.$emit('saveSheduledSlots', this.lineItemData);
    },
    numericValidator (value) {
      if (typeof value === 'number' && !isNaN(value)) {
        return true
      } else {
        return false;
      }
    },
    reRendering () {
      this.$nextTick(() => {
        this.$refs.table.hotInstance.render(); // Force render after mount
      });
    }
  },
  created () {
    this.hotSettings = this.buildHotSettings();
  },
  computed: {
    hot: function () {
      return this.$refs.table.hotInstance;
    }
  }
}
</script>
