import axiosInstance from '../../../Api'

export class DashboardRoleAccessApiService {
  getDashboards (businessProfileId) {
    // eslint-disable-next-line no-undef
    const dashboardsUrl = apiBaseUrl
      .concat(`admin/businessProfiles/${businessProfileId}/Dashboards`);
    var promise = axiosInstance({
      method: "GET",
      url: dashboardsUrl
    });
    return promise;
  }

  updateAccess (businessProfileId, userId, accessConfiguration) {
    // eslint-disable-next-line no-undef
    const updateAccessUrl = apiBaseUrl
      .concat(`users/${userId}/businessProfiles/${businessProfileId}/`)
    const promise = axiosInstance.put(updateAccessUrl, {
      Role: 9,
      AccessConfigurationJson: accessConfiguration
    });
    return promise;
  }

  getAccess (businessProfileId, userId) {
    // eslint-disable-next-line no-undef
    const getAccessUrl = apiBaseUrl
      .concat(`users/${userId}/businessProfiles/${businessProfileId}/`)
    const promise = axiosInstance.get(getAccessUrl);
    return promise;
  }
}
