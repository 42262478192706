import { dbmEnums } from "../../Constants/DbmEnums.js";
import { Objective } from "./Objective.js";
// import { dbmEnums } from "../../Constants/DbmEnums.js";

let ColumnConfig = function (config) {
  let base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Youtube URL", "HOT_Display URL", "HOT_Landing Page URL", "HOT_Target", "HOT_AdGroup"];
  let requiredBase = ["HOT_Ad Name", "HOT_Youtube URL", "HOT_Display URL", "HOT_Landing Page URL"];

  if (config.creativeType.includes(dbmEnums.creativeType["Youtube"])) {
    switch (config.adGroup.adFormat) {
      case 1:
        base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Youtube URL", "HOT_Landing Page URL", "HOT_Display URL", "HOT_Call To Action", "HOT_Headline", "HOT_Target", "HOT_AdGroup"];
        requiredBase = ["HOT_Ad Name", "HOT_Youtube URL", "HOT_Landing Page URL", "HOT_Display URL"];
        break;
      case 4:
        base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Youtube URL", "HOT_Landing Page URL", "HOT_Display URL", "HOT_Call To Action", "HOT_Headline", "HOT_Target", "HOT_AdGroup"];
        requiredBase = ["HOT_Ad Name", "HOT_Youtube URL", "HOT_Landing Page URL", "HOT_Display URL"];
        break;
      case 5:
        base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Youtube URL", "HOT_Long Headline", "HOT_Description", "HOT_Description2", "HOT_Target", "HOT_AdGroup"];
        requiredBase = ["HOT_Ad Name", "HOT_Youtube URL", "HOT_Long Headline", "HOT_Description", "HOT_Description2"];
        break;
      case 6:
        base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Youtube URL", "HOT_Landing Page URL", "HOT_Call To Action", "HOT_Headline", "HOT_Long Headline", "HOT_Description", "HOT_Target", "HOT_AdGroup"];
        requiredBase = ["HOT_Ad Name", "HOT_Youtube URL", "HOT_Landing Page URL", "HOT_Call To Action", "HOT_Long Headline", "HOT_Description"];
        break;
      case 7:
        base = ["HOT_Is Valid", "HOT_Ad Name", "HOT_Youtube URL", "HOT_Landing Page URL", "HOT_Display URL", "HOT_Call To Action", "HOT_Headline", "HOT_Target", "HOT_AdGroup"];
        requiredBase = ["HOT_Ad Name", "HOT_Youtube URL", "HOT_Landing Page URL", "HOT_Display URL"];
        break;
      default:
        break;
    }
  }

  let youtube = {
    base,
    requiredBase,
    card: [],
    requiredCard: [],
    callToAction: []
  }

  return { youtube };
}
let CreativeSpec = function (config) {
  return {
    youtube: {
      landingPageUrl: "HOT_Landing Page URL",
      videoId: "HOT_Youtube URL",
      displayUrl: "HOT_Display URL",
      dxTargetId: "HOT_Target"
    }
  }
}

let youtube = new Objective(ColumnConfig, CreativeSpec)
export { youtube }
