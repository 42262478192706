<template>
  <div class="cl-widget">
    <Card>
      <div>
        <Row>
          <Row-Col>
            <h4>Stores
            </h4>
          </Row-Col>
        </Row>
      </div>
      <div>
            <Input v-model="search" search placeholder="Search" class="search-bar input-search-bar" />
      </div>
      <template>
            <div
              style="margin-top:10px"
              v-if="filteredStores.length <= 0 && !search.length"
              class="alert alert-info"
            >No stores available.</div>
      </template>
      <VuePerfectScrollbar class="widget-body">
        <Row class="cl-ivu-row" v-for="item in filteredStores" :key="item.id">
          <Row-Col span="1">
            <i class="fas fa-copy action-icon"
              @click="copyToClipboard(storePrefix + '-' + Number.parseInt(businessProfileId).toString(36) + '-' + item.storeCode)">
            </i>
          </Row-Col>
          <Row-Col offset="1" span="21" class="cl-widget-text">{{ item.name }}</Row-Col>
        </Row>
      </VuePerfectScrollbar>
    </Card>
  </div>
</template>
<script>
import { Input, Card, Row, Col } from "iview";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import mixins from "../../../Mixins/copyToClipboard.js";
import { mapState } from "vuex";
import { EntityPrefixes } from "../Constants/EntityPrefix.js"

export default {
  components: {
    Card,
    Row,
    "Row-Col": Col,
    VuePerfectScrollbar,
    Input
  },
  data: function () {
    return {
      search: "",
      storePrefix: EntityPrefixes.Store
    }
  },
  mixins: [mixins],
  mounted () {
  },
  computed: {
    ...mapState(["selectedCampaignLauncherConfigId", "businessProfileId", "dbStores", "selectedPublisher"]),
    filteredStores () {
      let searchTerm = this.search;
      if (searchTerm != "") {
        return this.dbStores.filter(e => {
          return e.name.toLowerCase().includes(searchTerm.toLowerCase())
        });
      } else {
        return this.dbStores || [];
      }
    }
  }
}
</script>
<style scoped>
.input-search-bar .ivu-input {
  height: 30px;
}
.cl-widget .ivu-card {
  font-size: 12px !important;
  border-radius:0;
}
</style>
