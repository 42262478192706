<template>
  <div>
    <Modal
      v-model="openPromoModal"
      width="75%"
      :scrollable="true"
      :styles="{top: '20px'}"
      :mask-closable="false"
      :closable="false"
    >
      <h3 class="modal-header" slot="header" >
        {{ isEditMode ? "Edit Promotion" : "Create a Promotion" }}
      </h3>

      <Form
        label-position="right"
        :label-width="200"
        :model="promoConfig"
        :rules="validationRules"
        ref="promotionForm"
        class="config-modal"
      >
        <Divider orientation="left"> General </Divider>
        <Form-Item label="Promotion Name" prop="name">
          <Row>
            <Row-Col span="19">
              <i-input :disabled="isLivePromotion || isReadOnlyMode" v-model="promoConfig.name" />
            </Row-Col>
          </Row>
        </Form-Item>

        <Form-Item label="Product" prop="productId">
          <Row>
            <Row-Col span="19">
              <Select
                v-model="promoConfig.productId"
                placeholder="Select a product"
                :transfer="true"
                :disabled="isLivePromotion || isReadOnlyMode"
                filterable
              >
                <Option
                  v-for="(product, id) in products"
                  :key="id"
                  :value="Number(id)"
                  >{{ product.title }}</Option
                >
              </Select>
            </Row-Col>
          </Row>
        </Form-Item>

          <Form-Item prop="template">
          <Row>
            <Row-Col span="19">
              <Checkbox :disabled="isLivePromotion || isReadOnlyMode" v-model="promoConfig.isTemplate">Make this promotion a template</Checkbox>
            </Row-Col>
          </Row>
        </Form-Item>
        <div v-show="promoConfig.promotionType == this.promotionTypes.Paid">
        <!-- Had to use v-show here to hide the fields because using v-if resulted in unusual behaviour where other fields were not set correctly on load-->
          <Divider orientation="left"> Budget </Divider>
          <Form-Item label="Controls" prop="settings.controls.budget">
            <Row>
              <Row-Col span="19">
                <RadioGroup v-model="promoConfig.settings.controls.budget">
                  <Radio :label="0" :disabled="isLivePromotion || isReadOnlyMode">Locked</Radio>
                  <Radio :label="1" :disabled="isLivePromotion || isReadOnlyMode">Editable</Radio>
                </RadioGroup>
              </Row-Col>
            </Row>
          </Form-Item>

          <Form-Item label="Funding Source" prop="settings.budget.fundingSource">
            <Row>
              <Row-Col span="19">
                <RadioGroup v-model="promoConfig.settings.budget.fundingSource">
                  <Radio :label="1" :disabled="isLivePromotion || isReadOnlyMode">HQ</Radio>
                  <Radio :label="2" :disabled="isLivePromotion || isReadOnlyMode">Local</Radio>
                </RadioGroup>
              </Row-Col>
            </Row>
          </Form-Item>

          <Form-Item label="Promo Budget Type" prop="settings.budget.promoBudgetType" v-if = "promoConfig.settings.budget.fundingSource == fundingSources.Hq">
            <Row>
              <Row-Col span="19">
                <RadioGroup v-model="promoConfig.settings.budget.promoBudgetType" @on-change="resetDaypartingType">
                  <Radio :label="1" :disabled="isLivePromotion || isReadOnlyMode">Total Budget</Radio>
                  <Radio :label="2" :disabled="isLivePromotion || isReadOnlyMode">Budget Per Store</Radio>
                </RadioGroup>
              </Row-Col>
            </Row>
          </Form-Item>

          <Form-Item label="Budget Type" prop="settings.budget.type">
            <Row>
              <Row-Col span="19">
                <RadioGroup v-model="promoConfig.settings.budget.type" @on-change="resetDaypartingType">
                  <Radio :label="2" :disabled="isLivePromotion || isReadOnlyMode">Lifetime</Radio>
                  <Radio :label="1" :disabled="isLivePromotion || isReadOnlyMode">Daily</Radio>
                </RadioGroup>
              </Row-Col>
            </Row>
          </Form-Item>

          <Form-Item label="Total budget" prop="settings.budget.total" v-if = "promoConfig.settings.budget.promoBudgetType == promoBudgetTypes.Total">
            <Row>
              <Row-Col span="5">
                <i-input v-model="promoConfig.settings.budget.total" :disabled="isLivePromotion || isReadOnlyMode || (promoConfig.settings.budget.fundingSource == fundingSources.Hq && promoConfig.settings.budget.promoBudgetType == promoBudgetTypes.PerStore )">
                  <span class="disabled" slot="append"> {{bpCurrency}} </span>
                </i-input>
              </Row-Col>
            </Row>
          </Form-Item>

          <Form-Item label="Target Stores Count" prop="settings.targetStoresCount" v-if = "promoConfig.settings.budget.promoBudgetType == promoBudgetTypes.Total">
            <Row>
              <Row-Col span="5">
                <InputNumber v-model="promoConfig.settings.targetStoresCount" :min="1" :disabled="isLivePromotion || isReadOnlyMode || promoConfig.settings.budget.promoBudgetType == promoBudgetTypes.PerStore"/>
              </Row-Col>
            </Row>
          </Form-Item>

          <Form-Item label="Budget - Suggested" prop="settings.budget.suggested">
            <Row>
              <Row-Col span="5">
                <i-input v-model="promoConfig.settings.budget.suggested" :disabled="isLivePromotion || isReadOnlyMode  || promoConfig.settings.budget.promoBudgetType == promoBudgetTypes.Total">
                  <span class="disabled" slot="append">1 {{bpCurrency}} </span>
                </i-input>
              </Row-Col>
            </Row>
          </Form-Item>

          <Form-Item label="Budget - Min" prop="settings.budget.min">
            <Row>
              <Row-Col span="5">
                <i-input v-model="promoConfig.settings.budget.min" :disabled="isLivePromotion || isReadOnlyMode">
                  <span class="disabled" slot="append"> {{bpCurrency}} </span>
                </i-input>
              </Row-Col>
            </Row>
          </Form-Item>

          <Form-Item label="Budget - Max" prop="settings.budget.max">
            <Row>
              <Row-Col span="5">
                <i-input v-model="promoConfig.settings.budget.max" :disabled="isLivePromotion || isReadOnlyMode">
                  <span class="disabled" slot="append"> {{bpCurrency}} </span>
                </i-input>
              </Row-Col>
            </Row>
          </Form-Item>
          <Form-Item label="Day Parting" prop="settings.schedule.type">
            <Row>
              <Row-Col span="19">
                <RadioGroup v-model="promoConfig.settings.schedule.type">
                  <Radio :disabled="isLivePromotion || isReadOnlyMode" :label="1">Always On</Radio>
                    <Tooltip max-width="300"  :disabled="promoConfig.settings.budget.type == this.budgetTypes.LifeTime || isLivePromotion || isReadOnlyMode" content="To use custom schedule you must use lifetime budget" placement="right">
                      <div>
                        <Radio :label="3"  :disabled="promoConfig.settings.budget.type != this.budgetTypes.LifeTime || isLivePromotion || isReadOnlyMode">Custom</Radio>
                      </div>
                    </Tooltip>
                </RadioGroup>
              </Row-Col>
            </Row>
          </Form-Item>
          <Form-Item
            label="Day Parting Schedules"
            v-if="promoConfig.settings.schedule.type == 3 && promoConfig.settings.budget.type == this.budgetTypes.LifeTime"
            prop="settings.schedule.dayPartingSchedules"
          >
                  <ScheduleConfiguration
                    :disabled="isLivePromotion || isReadOnlyMode"
                    @updateSchedule="updateSchedule"
                    :scheduleConfiguration="{ dayParting_schedules: promoConfig.settings.schedule.dayPartingSchedules }"
                    :isRnFConfig="promoConfig.settings.isRnFConfig"
                  />
        </Form-Item>
        <Divider orientation="left"> Schedule </Divider>
          <Form-Item label="Controls" prop="settings.controls.schedule">
            <Row>
              <Row-Col span="19">
                <RadioGroup v-model="promoConfig.settings.controls.schedule">
                  <Radio :label="0" :disabled="isLivePromotion || isReadOnlyMode">Locked</Radio>
                  <Radio :label="1" :disabled="isLivePromotion || isReadOnlyMode">Editable</Radio>
                </RadioGroup>
              </Row-Col>
            </Row>
          </Form-Item>

          <FormItem label="Start Date" prop="settings.schedule.startDate">
            <Row>
              <Row-Col span="19">
                <DatePicker
                  type="date"
                  placeholder="Select a start date"
                  v-model="promoConfig.settings.schedule.startDate"
                  :disabled="isLivePromotion || isReadOnlyMode">
                </DatePicker>
              </Row-Col>
            </Row>
          </FormItem>

          <FormItem label="End Date" prop="settings.schedule.endDate">
            <Row>
              <Row-Col span="19">
                <DatePicker
                  type="date"
                  placeholder="Select an end date"
                  v-model="promoConfig.settings.schedule.endDate"
                  :disabled="isLivePromotion || isReadOnlyMode">
                </DatePicker>
              </Row-Col>
            </Row>
          </FormItem>

            <!-- to-do custom parting -->
          <Divider orientation="left"> Targeting </Divider>
            <Form-Item label="Controls" prop="settings.controls.targeting">
              <Row>
                <Row-Col span="19">
                  <RadioGroup v-model="promoConfig.settings.controls.targeting">
                    <Radio :label="0" :disabled="isLivePromotion || isReadOnlyMode">Locked</Radio>
                    <Radio :label="1" :disabled="isLivePromotion || isReadOnlyMode">Editable</Radio>
                  </RadioGroup>
                </Row-Col>
              </Row>
            </Form-Item>

            <Form-Item label="Target Clusters" prop="settings.targeting.clusterIds">
              <Row>
                <Row-Col span="19">
                  <Select
                    multiple
                    placeholder="Select target clusters"
                    v-model="promoConfig.settings.targeting.clusterIds"
                    not-found-text="No results"
                    filterable
                    :disabled="isLivePromotion || isReadOnlyMode"
                  >
                    <Option
                      v-for="cluster in targetClusters"
                      :key="cluster.id"
                      :value="cluster.id"
                    >{{ cluster.name }}</Option>
                  </Select>
                </Row-Col>
              </Row>
            </Form-Item>

            <Form-Item label="Promo Specific Targets" prop="settings.targeting.specificTargetIds">
              <Row>
                <Row-Col span="19">
                  <Select
                    multiple
                    placeholder="Select specific targets"
                    v-model="promoConfig.settings.targeting.specificTargetIds"
                    not-found-text="No results"
                    filterable
                   :disabled="isLivePromotion || isReadOnlyMode"
                  >
                    <Option
                      v-for="target in targets"
                      :key="target.id"
                      :value="target.id"
                    >{{ target.name }}</Option>
                  </Select>
                </Row-Col>
              </Row>
            </Form-Item>

            <Divider orientation="left">Tracking Settings</Divider>
            <Form-Item  label="" prop="useAdLinkForConvDomain">
                <Row>
                <Row-Col span="19">
                    <Checkbox  :disabled="inCampaignLauncher || isLivePromotion || isReadOnlyMode" v-model="promoConfig.useAdLinkForConvDomain">Use domain from destination url</Checkbox>
                </Row-Col>
                </Row>
                <Row>
                <Row-Col span="19">
                  <div class="alert alert-info">
                    <span class="fa-info-circle tracking-settings-info-icon"></span>
                    <span>We recommend leaving this off to allow using destination URLs apart from the website (ex: whatsapp URLs)</span>
                  </div>
                </Row-Col>
                </Row>
            </Form-Item>
         </div>
          <Divider orientation="left"> Page </Divider>
            <Form-Item label="Type" prop="settings.assetType">
              <Row>
                <Row-Col span="19">
                  <RadioGroup v-model="promoConfig.settings.assetType">
                    <Radio :label="1" :disabled="isLivePromotion || isReadOnlyMode">HQ</Radio>
                    <Radio :label="2" :disabled="isLivePromotion || isReadOnlyMode">Local</Radio>
                  </RadioGroup>
                </Row-Col>
              </Row>
            </Form-Item>

          <Divider orientation="left"> Creative </Divider>
            <Form-Item label="Controls" prop="settings.controls.creatives">
              <Row>
                <Row-Col span="19">
                  <RadioGroup v-model="promoConfig.settings.controls.creatives">
                    <Radio :label="0" :disabled="isLivePromotion || isReadOnlyMode">Locked</Radio>
                    <Radio :label="1" :disabled="isLivePromotion || isReadOnlyMode">Editable</Radio>
                  </RadioGroup>
                </Row-Col>
              </Row>
            </Form-Item>

        <!-- <Divider orientation="left"> Status </Divider>
            <Form-Item label="Controls" prop="status">
              <Row>
                <Row-Col span="19">
                  <RadioGroup v-model="promoConfig.status">
                    <Radio label="1">Draft</Radio>
                    <Radio label="2">Tested</Radio>
                    <Radio label="3">Editable</Radio>
                  </RadioGroup>
                </Row-Col>
              </Row>
            </Form-Item> -->
        <template v-if="promoConfig.promotionType == this.promotionTypes.Paid">
          <Divider orientation="left">KPI</Divider>
            <Form-Item label="KPI" prop="kpiMetricId">
                <Row>
                  <Row-Col span="19">
                    <Select
                      v-model="promoConfig.kpiMetricId"
                      placeholder="Select a kpi metric"
                      :transfer="true"
                      filterable
                      :disabled="isEditMode"
                    >
                      <Option
                        v-for="dataParameter in dataParameters"
                        :key="dataParameter.id"
                        :value="dataParameter.id"
                        >{{ dataParameter.name }}</Option
                      >
                    </Select>
                  </Row-Col>
                </Row>
              </Form-Item>
        </template>
        <template v-if="promoConfig.promotionType == this.promotionTypes.Paid">
          <Divider orientation="left"> Objective </Divider>
            <Form-Item label="Objective" prop="objective">
              <Row>
                <Row-Col span="19">
                  <Select
                    v-model="promoConfig.objective"
                    placeholder="Select an objective"
                    :transfer="true"
                    filterable
                    :disabled="isEditMode || isLivePromotion || isReadOnlyMode"
                  >
                    <Option
                      v-for="objective in computedFacebookObjectives"
                      :key="objective.id"
                      :value="objective.id"
                      >{{ objective.name }}</Option
                    >
                  </Select>
                </Row-Col>
              </Row>
            </Form-Item>
            <Form-Item v-show="showDestination" label="Destination" prop="destination">
              <Row>
                <Row-Col span="19">
                  <Select
                    v-model="promoConfig.destination"
                    placeholder="Select a destination"
                    :transfer="true"
                    filterable
                    :disabled="isEditMode || isLivePromotion || isReadOnlyMode"
                  >
                    <Option
                      v-for="destination in destinations"
                      :key="destination.id"
                      :value="destination.id"
                      >{{ destination.name }}</Option
                    >
                  </Select>
                </Row-Col>
              </Row>
            </Form-Item>

          <template v-if="promoConfig.objective == 14">
          <Divider orientation="left"> Lead Form </Divider>
              <Form-Item label="Lead Form" prop="settings.storeLeadFormId">
                <Row>
                  <Row-Col span="19">
                    <Select
                      v-model="promoConfig.settings.storeLeadFormId"
                      placeholder="Select a Lead Form"
                      :transfer="true"
                      filterable
                      :disabled="isLivePromotion || isReadOnlyMode"
                    >
                      <Option
                        v-for="leadForm in leadForms"
                        :key="leadForm.id"
                        :value="leadForm.id"
                        >{{ leadForm.name }}</Option
                      >
                    </Select>
                  </Row-Col>
                </Row>
              </Form-Item>
          </template>
        </template>
        <template v-if="promoConfig.promotionType == this.promotionTypes.Paid && isHubOrganicPromotionsEnabled">
        <Divider orientation="left"> Organic Promotions </Divider>
              <Form-Item label="Organic Promotion" prop="settings.organicPromotionId">
                <Row>
                  <Row-Col span="19">
                    <Select
                      v-model="promoConfig.settings.organicPromotionId"
                      placeholder="Select an organic Promotion"
                      :transfer="true"
                      filterable
                      :disabled="isLivePromotion || isReadOnlyMode || !promoConfig.objective || (inCampaignLauncher && !promoConfig.settings.organicPromotionId) || !existingPostAllowedObjectives.includes(promoConfig.objective)"
                    >
                      <Option
                        v-for="organicPromo in organicPromotions"
                        :key="organicPromo.id"
                        :value="organicPromo.id"
                        >{{ organicPromo.name }}</Option
                      >
                    </Select>
                  </Row-Col>
                </Row>
              </Form-Item>
          </template>
        <Divider orientation="left"> Stores </Divider>
            <Form-Item label="Store Type" prop="storeType">
              <Row>
                <Row-Col span="19">
                  <RadioGroup v-model="promoConfig.storeType">
                    <Radio :label="1" :disabled="isLivePromotion || isReadOnlyMode">Selective</Radio>
                    <Radio :label="2" :disabled="isLivePromotion || isReadOnlyMode">All</Radio>
                    <Radio :label="3" :disabled="isLivePromotion || isReadOnlyMode">Whatsapp Enabled</Radio>
                    <Radio :label="4" :disabled="isLivePromotion || isReadOnlyMode">Whatsapp Disabled</Radio>
                  </RadioGroup>
                  <div class="error-tip" v-if="customErrors.stores">Please select a store</div>
                </Row-Col>
              </Row>
            </Form-Item>
            <div class="store-select" v-show="promoConfig.storeType == 1">
            <multi-select-list
                  ref="storeSelect"
                  :selectedEntityList.sync="enabledStores"
                  :entityList="storeSelectOject"
                  :isReadOnly="isReadOnlyMode"
                  :filteredEntityIds="filteredStoreIds"
                  :selectedFilterCount="selectedlabelIds.length"
                  :isLoading="isStoreSelectLoading"
                  >
                  <template v-slot:filter-block>
                          <ul v-if="labelDimentionFilter.length > 0" id="store-filter-list" class="list-group filterListGroup">
                            <span class="disabled" v-for="ld in labelDimentionFilter" :key="ld.id">
                            <li class="list-group filterListGroup-name bold-text selected-stores" v-if="ld.labels.length > 0">
                              {{ld.name}}
                            </li>
                              <span class="disabled" v-for="label in ld.labels" :key="label.id">
                                <li class="list-group-item selected-stores filterListGroup-item">
                                  <Checkbox style="width:100%" v-model="label.isSelected" @on-change="setFilteredStoreIds"> {{label.name}} </Checkbox>
                                </li>
                              </span>
                            </span>
                          </ul>
                          <p v-else title="No Filters Available" class="selected-stores noLabelText filterListGroup-item"> No Filters Available </p>
                  </template>
                  <template v-slot:select-list-group="slotProps">
                    <span class="bold-text disabled">{{ slotProps.entity.name }} </span>
                  </template>
                  <template v-slot:select-list-group-child="slotProps">
                    <span class="disabled">
                    {{ slotProps.childEntity.effectiveName }}
                    <i class="fa fa-exclamation-triangle text-danger" :title="invalidStoreErrorMessages[slotProps.childEntity.id]" v-if="slotProps.childEntity.id in invalidStoreErrorMessages"></i>
                    <i class="fa fa-exclamation-circle alert-warning" :title="getRemainingBudgetMessage(slotProps.childEntity.budgetRemaining)" v-if="isMaxBudgetOvershot(slotProps.childEntity.budgetRemaining) && isLifetimeBudgetCapEnabled"></i><i class="fa fa-exclamation-circle text-danger" :title="getRemainingBudgetMessage(slotProps.childEntity.budgetRemaining)" v-if="isMinBudgetOvershot(slotProps.childEntity.budgetRemaining) && isLifetimeBudgetCapEnabled"></i>
                    </span>
                    <span class="pull-right label-container disabled">
                      <div class="label label-item store-labels-selector" v-for="label in filterStoreNameLabels(slotProps.childEntity.labels, slotProps.childEntity.name, slotProps.childEntity.storeCode, slotProps.parentEntity.name)" :key="label.id" :style="getLabelBackground(label.colorCode)"> {{label.name}} </div>
                    </span>
                  </template>
                  <template v-slot:selected-list-group="slotProps">
                    <span class="selected-stores disabled">{{ slotProps.entity.effectiveName }}</span>
                  </template>
                  </multi-select-list>
                </div>
      </Form>
      <div slot="footer">
        <input
          v-if="!isEditMode"
          type="button"
          class="btn btn-white"
          @click="confirmReset"
          value="Reset"
        />
        <input type="button" class="btn btn-white" @click="closeModal" value="Close" />
        <input
          type="button"
          class="btn btn-success"
          @click="validatePromotion"
          :value="inCampaignLauncher || isLivePromotion ? 'Save' : 'Next'"
          :disabled="!isPromoValid"
          v-if="!isReadOnlyMode"
        />
      </div>
    </Modal>

    <campaign-configuration
      v-if="openCampaingConfig"
      :openConfigurationModalProp="openCampaingConfig"
      :defaultFormConfig="prepopulatedCampaignConfig"
      :campaignConfigurations="[]"
      :inPromoDashboard="true"
      :isEditPromoMode="isEditMode"
      @savePromoCLconfig="saveOrUpdate($event)"
      @modalStateChanged="openCampaingConfig = $event"
    ></campaign-configuration>
  </div>
</template>

<script>
import CampaignConfiguration from "../../CampaignLauncher/Components/CampaignConfiguration.vue";
import MultiSelectList from "../../../../DeltaXComponents/dx-multiselect.vue";
import ModalMessageMixin from "../Mixins/ModalMessageMixin.js";
import FacebookCampaignLauncherConfig from "../../CampaignLauncher/Mixins/FacebookCampaignLauncherConfig.js";
import ApiService from "./../ApiService.js";
import ScheduleConfiguration from "../../CampaignLauncher/Components/ScheduleConfiguration.vue";

import {
  Divider,
  Row,
  Col,
  Modal,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Checkbox,
  Option,
  Input,
  DatePicker,
  Tooltip,
  InputNumber
} from "iview";
import { mapState, mapMutations } from "vuex";
import moment from 'moment';
import { AssetType, SearchEngines, deatinationTypes, StoreType, PromotionStatus, PromotionType, ScheduleType, BudgetType, PromoBudgetType, FundingSource } from '../constants/constants';
import { APIService as campignLauncherApiService } from '../../CampaignLauncher/ApiService'
import * as fbEnum from '../../CampaignLauncher/Constants/FacebookEnums';

export default {
  props: {
    openPromoModalProp: { type: Boolean, default: false },
    promoConfigToEdit: { type: Object, default: null },
    inCampaignLauncher: { type: Boolean, default: false },
    isReadOnlyMode: { type: Boolean, default: false },
    organicPromotions: { type: Array },
    promotionType: {
      type: Number, default: PromotionType.Paid
    }
  },
  components: {
    MultiSelectList,
    CampaignConfiguration,
    Divider,
    Row,
    "Row-Col": Col,
    Modal,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Select,
    Checkbox,
    Option,
    'i-input': Input,
    DatePicker,
    Tooltip,
    ScheduleConfiguration,
    InputNumber
  },
  mixins: [ModalMessageMixin, FacebookCampaignLauncherConfig],
  data () {
    return {
      existingPostAllowedObjectives: [12, 7, 8, 16, 18, 11],
      openPromoModal: false,
      selectedlabelIds: [],
      isStoreSelectLoading: true,
      isPromoSaved: false,
      filteredStoreIds: [],
      filterModal: false,
      openCampaingConfig: false,
      promoConfig: this.getDefaultPromoConfig(),
      checkIfValid: false,
      bpCurrency: deltax.bpCurrencyCode,
      isPromoValid: true,
      invalidStoreErrorMessages: {},
      prepopulatedCampaignConfig: this.getDefaultFormConfig(),
      accounts: [],
      targets: [],
      stores: [],
      targetClusters: [],
      leadForms: [],
      dataParameters: [],
      defaultStore: {},
      bpTimeZone: null,
      objectives: [
        { id: 7, name: "Post Engagement" },
        { id: 8, name: "Conversions" },
        { id: 11, name: "Traffic" },
        { id: 12, name: "Video Views" },
        { id: 16, name: "Brand Awareness" },
        { id: 18, name: "Reach" },
        { id: 14, name: "Lead Generation" },
        { id: 20, name: "Messages" },
        { id: 21, name: "Awareness" },
        { id: 22, name: "Engagement" },
        { id: 23, name: "Leads" },
        { id: 24, name: "Sales" },
        { id: 25, name: "Traffic Outcome" },
        { id: 26, name: "App Promotion" }
      ],
      destinationEnumsByObjective: deatinationTypes,
      loadingExsisitingPromo: false,
      displayTime: [
        {
          value: 0,
          label: "12 AM (Beginning of the day)"
        },
        {
          value: 60,
          label: "1 AM"
        },
        {
          value: 120,
          label: "2 AM"
        },
        {
          value: 180,
          label: "3 AM"
        },
        {
          value: 240,
          label: "4 AM"
        },
        {
          value: 300,
          label: "5 AM"
        },
        {
          value: 360,
          label: "6 AM"
        },
        {
          value: 420,
          label: "7 AM"
        },
        {
          value: 480,
          label: "8 AM"
        },
        {
          value: 540,
          label: "9 AM"
        },
        {
          value: 600,
          label: "10 AM"
        },
        {
          value: 660,
          label: "11 AM"
        },
        {
          value: 720,
          label: "12 PM"
        },
        {
          value: 780,
          label: "1 PM"
        },
        {
          value: 840,
          label: "2 PM"
        },
        {
          value: 900,
          label: "3 PM"
        },
        {
          value: 960,
          label: "4 PM"
        },
        {
          value: 1020,
          label: "5 PM"
        },
        {
          value: 1080,
          label: "6 PM"
        },
        {
          value: 1140,
          label: "7 PM"
        },
        {
          value: 1200,
          label: "8 PM"
        },
        {
          value: 1260,
          label: "9 PM"
        },
        {
          value: 1320,
          label: "10 PM"
        },
        {
          value: 1380,
          label: "11 PM"
        },
        {
          value: 1440,
          label: "12 AM (End of the day)"
        }
      ],
      WeekDays: [{
        id: 0,
        name: 'Sunday',
        shortName: 'Sun'
      },
      {
        id: 1,
        name: 'Monday',
        shortName: 'Mon'
      },
      {
        id: 2,
        name: 'Tuesday',
        shortName: 'Tue'
      },
      {
        id: 3,
        name: 'Wednesday',
        shortName: 'Wed'
      },
      {
        id: 4,
        name: 'Thursday',
        shortName: 'Thu'
      },
      {
        id: 5,
        name: 'Friday',
        shortName: 'Fri'
      },
      {
        id: 6,
        name: 'Saturday',
        shortName: 'Sat'
      }],
      isWithinRange: false,
      timeZoneTypes: [
        {
          value: "USER",
          label: "USER"
        },
        {
          value: "ADVERTISER",
          label: "ADVERTISER"
        }
      ],
      selectedTimeZoneType: '',
      dayPartingScheduleOrderKeys: [],
      labelDimentionFilter: [],
      enabledStores: [],
      liveStores: [],
      isStoreSelectReset: false,
      insufficientBudgetStores: [],
      customErrors: {
        stores: false,
        storeBudgetInsuffient: false
      },
      labelDimentions: [],
      storeSelectOject: [],
      returnedStores: [],
      promotionTypes: PromotionType,
      scheduleTypes: ScheduleType,
      budgetTypes: BudgetType,
      promoBudgetTypes: PromoBudgetType,
      fundingSources: FundingSource,
      isHubOrganicPromotionsEnabled: deltax.isHubOrganicPromotionsEnabled,
      isStoresDataFetched: false
    }
  },
  async created () {
    let bp = await ApiService.getBusinessProfile();
    this.isStoreSelectLoading = true;
    this.set_businessProfile(bp);
  },
  mounted () {
    this.$watch(
      vm => [vm.openPromoModal, vm.openCampaingConfig],
      callback => {
        if (!this.openPromoModal && !this.openCampaingConfig) {
          setTimeout(() => {
            this.resetPromoForm()
            this.storeSelectOject = [];
          })
        } else {
          if (!this.isStoreSelectLoading) {
            this.storeSelectOject = this.createStoreMultiSelectObject();
          }
        }
      }
    );
    this.$watch(
      vm => [
        vm.promoConfig.settings.budget.fundingSource,
        vm.promoConfig.settings.assetType,
        vm.promoConfig.objective,
        vm.promoConfig.destination,
        vm.promoConfig.storeType,
        vm.promoConfig.settings.storeLeadFormId
      ],
      callback => {
        this.invalidStoreErrorMessages = {};
        this.stores.forEach(store => {
          store['$isDisabled'] = !this.isStoreEligible(store);
          return store;
        });
        this.storeSelectOject = this.createStoreMultiSelectObject();
        if (!this.loadingExsisitingPromo) {
          this.isPromoValid = true;
          this.promoConfig.enabledStoreIds = [];
          this.enabledStores = [];
        }
      }
    );
  },
  computed: {
    ...mapState(["products", "promoAlertMessages", "campaignLauncherConfigs"]),
    isLifetimeBudgetCapEnabled () {
      return deltax.isHubLifetimeBudgetCapEnabled === "True";
    },
    computedFacebookObjectives () {
      if (deltax.isPromotionWizardFlowEnabled) {
        return this.objectives;
      } else {
        return this.objectives.filter(objective => objective.id <= 20);
      }
    },
    totalMaxBudget () {
      if (this.isLifetimeBudgetCapEnabled) {
        let totalBudget = this.promoConfig.settings.budget.max ? this.promoConfig.settings.budget.max : 0;
        let budgetType = this.promoConfig.settings.budget.type;
        if (budgetType == this.budgetTypes.Daily) {
          let startDate = moment(this.promoConfig.settings.schedule.startDate);
          let endDate = moment(this.promoConfig.settings.schedule.endDate);
          totalBudget = (endDate.diff(startDate, 'days') + 1) * totalBudget;
        }
        return totalBudget;
      }
      return 0;
    },
    totalMinBudget () {
      if (this.isLifetimeBudgetCapEnabled) {
        let totalBudget = this.promoConfig.settings.budget.min ? this.promoConfig.settings.budget.min : 0;
        let budgetType = this.promoConfig.settings.budget.type;
        if (budgetType == this.budgetTypes.Daily) {
          let startDate = moment(this.promoConfig.settings.schedule.startDate);
          let endDate = moment(this.promoConfig.settings.schedule.endDate);
          totalBudget = (endDate.diff(startDate, 'days') + 1) * totalBudget;
        }
        return totalBudget;
      }
      return 0;
    },
    destinations () {
      let destinationsToShow = []
      for (let [dest, destInfo] of Object.entries(this.destinationEnumsByObjective)) {
        if (destInfo.objectives.includes(this.promoConfig.objective)) {
          destinationsToShow.push({
            id: destInfo.enum,
            name: dest
          })
        }
      }
      return destinationsToShow;
    },
    showDestination () {
      // Only for traffic, Conversions and Messages objective
      return [
        fbEnum.objectives.Conversions,
        fbEnum.objectives.Traffic,
        fbEnum.objectives.Messages
      ].includes(this.promoConfig.objective)
    },
    isEditMode () {
      return this.promoConfigToEdit != null
    },
    isLivePromotion () {
      return this.promoConfigToEdit && this.promoConfigToEdit.status && this.promoConfigToEdit.status == PromotionStatus.enabled
    },
    validationRules () {
      let validationRules = {
        name: this.buildValidator((value, callback) => {
          if (String(value).length == 0) {
            callback(new Error("Please enter a name for the promotion"))
          }
        }),
        productId: this.buildValidator((value, callback) => {
          if (!value || value <= 0) {
            callback(new Error("Please select a product"))
          }
        })
      };
      if (this.promoConfig.promotionType == this.promotionTypes.Paid) {
        Object.assign(validationRules, {
          "settings.budget.fundingSource": this.buildValidator((value, callback) => {
            if (value <= 0) {
              callback(new Error("Please select a funding source"))
            }
          }),
          "settings.budget.type": this.buildValidator((value, callback) => {
            if (value <= 0) {
              callback(new Error("Please select a budget type"))
            }
          }),
          "settings.budget.suggested": this.buildValidator((value, callback) => {
            const budget = value != "" ? parseFloat(value) : 0;
            if (isNaN(budget) || budget <= 0) {
              callback(new Error("Budget must be a valid number"))
            }
            const budgetMin = parseFloat(this.promoConfig.settings.budget.min)
            const budgetMax = parseFloat(this.promoConfig.settings.budget.max)
            if (!isNaN(budgetMin) && budget < budgetMin) {
              callback(new Error("Suggested budget cannot be lesser than minimum budget"))
            }
            if (!isNaN(budgetMax) && budget > budgetMax) {
              callback(new Error("Suggested budget cannot be greater than maximum budget"))
            }
          }),
          "settings.budget.total": this.buildValidator((value, callback) => {
            const budget = value != "" ? parseFloat(value) : 0;
            if (isNaN(budget) || budget <= 0) {
              callback(new Error("Total Budget must be a valid number"))
            }
          }),
          "settings.budget.min": this.buildValidator((value, callback) => {
            const budget = value ? Number(value) : 0;
            if (isNaN(budget) || budget < 0) {
              callback(new Error("Minimum budget must be a valid number"))
            }
          }),
          "settings.budget.max": this.buildValidator((value, callback) => {
            const budget = value ? Number(value) : 0;
            if (isNaN(budget) || budget < 0) {
              callback(new Error("Maximum budget must be a valid number"))
            }
            const budgetMin = parseFloat(this.promoConfig.settings.budget.min)
            if (!isNaN(budgetMin) && budget < budgetMin) {
              callback(new Error("Maximum budget cannot be less than minimum budget"))
            }
          }),
          "settings.schedule.dayPartingSchedules": [{
            validator: (rule, value, callback) => {
              if (this.isWithinRange) {
                callback(new Error("Please provide a valid time slot"))
              }
              callback()
            }
          }],
          "settings.schedule.startDate": this.buildValidator((value, callback) => {
            if (value == "Invalid date") {
              callback(new Error("Please select a valid start date"))
            }
            const today = moment().format("YYYY-MM-DD");
            if (!this.isLivePromotion && moment(value).isBefore(today)) {
              callback(new Error("Start date cannot be in the past"))
            }
          }),
          "settings.schedule.endDate": this.buildValidator((value, callback) => {
            if (this.promoConfig.settings.budget.type == this.budgetTypes.LifeTime && value == "Invalid date") {
              callback(new Error("Please select a valid end date"))
            }
            if (value != "Invalid date") {
              const startDate = this.promoConfig.settings.schedule.startDate;
              if (startDate != "Invalid date" && startDate > value) {
                callback(new Error("End date cannot be before start date"))
              }
              const today = moment().format("YYYY-MM-DD");
              if (moment(value).isBefore(today)) {
                callback(new Error("End date cannot be in the past"))
              }
            }
            if (this.isLifetimeBudgetCapEnabled && value == "Invalid date") {
              callback(new Error("Please select a valid end date"));
            }
          }),
          "settings.schedule.type": this.buildValidator((value, callback) => {
            if (value <= 0) {
              callback(new Error("Please select a day parting"))
            }
          }),
          "settings.targeting.clusterIds": this.buildValidator((value, callback) => {
            if (!Array.isArray(value) || value.length == 0) {
              callback(new Error("Please select a target cluster"))
            }
          }),
          kpiMetricId: this.buildValidator((value, callback) => {
            if (!value) {
              callback(new Error("Please select a Kpi"))
            }
          }),
          objective: this.buildValidator((value, callback) => {
            if (!value) {
              callback(new Error("Please select an objective"))
            }
          }),
          destination: this.buildValidator((value, callback) => {
            if (!this.showDestination) { callback() }
            if (!value) {
              callback(new Error("Please select a destination"))
            }
          }),
          "settings.storeLeadFormId": this.buildValidator((value, callback) => {
            if (this.promoConfig.objective == 14 && !value) {
              callback(new Error("Please select a lead form"))
            }
          })
        })
      }
      return validationRules;
    }
  },
  watch: {
    "promoConfig.settings.budget.total": function (value) {
      if (this.promoConfig.settings.budget.promoBudgetType == this.promoBudgetTypes.Total) {
        if (!this.promoConfig.settings.targetStoresCount) {
          this.promoConfig.settings.budget.suggested = this.promoConfig.settings.budget.total;
        } else if (this.promoConfig.settings.targetStoresCount == 0) {
          this.promoConfig.settings.budget.suggested = this.promoConfig.settings.budget.total;
        } else {
          this.promoConfig.settings.budget.suggested = (this.promoConfig.settings.budget.total / this.promoConfig.settings.targetStoresCount).toFixed(3);
        }
      }
    },
    "promoConfig.settings.targetStoresCount": function (value) {
      if (this.promoConfig.settings.budget.promoBudgetType == this.promoBudgetTypes.Total) {
        if (!this.promoConfig.settings.targetStoresCount || !this.promoConfig.settings.budget.total) {
          this.promoConfig.settings.budget.suggested = this.promoConfig.settings.budget.total;
        } else if (this.promoConfig.settings.targetStoresCount == 0) {
          this.promoConfig.settings.budget.suggested = this.promoConfig.settings.budget.total;
        } else {
          this.promoConfig.settings.budget.suggested = (this.promoConfig.settings.budget.total / this.promoConfig.settings.targetStoresCount).toFixed(3);
        }
      }
    },
    "promoConfig.settings.budget.promoBudgetType": function (value) {
      this.promoConfig.settings.budget.suggested = "";
      if (this.promoConfig.settings.budget.promoBudgetType == this.promoBudgetTypes.PerStore) {
        this.promoConfig.settings.budget.total = null;
        this.promoConfig.settings.targetStoresCount = null;
      }
    },
    "promoConfig.settings.schedule.type": function (value) {
      if (this.promoConfig.promotionType == this.promotionTypes.Organic) { return; }
      if (value == this.scheduleTypes.Custom) {
        if (this.promoConfig.settings.schedule.dayPartingSchedules.length == 0) {
          this.$set(this.promoConfig.settings.schedule, 'dayPartingSchedules', [ {
            startMinute: 0,
            endMinute: 1440,
            days: [0, 1, 2, 3, 4, 5, 6],
            timezoneType: 'USER'
          }]);
        }
      } else {
        this.$set(this.promoConfig.settings.schedule, 'dayPartingSchedules', []);
      }
    },
    openPromoModalProp () {
      this.openPromoModal = this.openPromoModalProp
    },
    openPromoModal () {
      if (this.openPromoModal) {
        if (this.promoConfigToEdit != null) {
          this.loadExistingPromoConfig();
        }
        if (!this.isStoresDataFetched) {
          this.fetchAndSetStoresData();
          this.isStoresDataFetched = true;
        }
      } else {
        (document.getElementsByClassName("config-modal")[0] || {}).scrollTop = 0;
        this.$emit('modalStateChanged', false)
      }
    },
    liveStores () {
      this.enabledStores.forEach((store) => {
        if (this.liveStores.includes(store.id)) {
          store.isDeselectDisabled = true;
        }
      })
    },
    promotionType (value) {
      this.promoConfig.promotionType = value;
    }
  },
  methods: {
    ...mapMutations(["set_promoAlertMessages", "set_promotionObject", "set_businessProfile"]),
    updateSchedule (updatedSchedule) {
      this.promoConfig.settings.schedule.dayPartingSchedules = updatedSchedule.dayParting_schedules;
      this.isWithinRange = updatedSchedule.isWithinRange
      this.selectedTimeZoneType = this.promoConfig.settings.schedule.dayPartingSchedules ? this.promoConfig.settings.schedule.dayPartingSchedules[0].timezoneType : 'USER';
    },
    async fetchAndSetStoresData () {
      let accountsRes = await ApiService.getAccounts();
      this.accounts = accountsRes.data.data;
      let targetsRes = await ApiService.getTargets();
      this.targets = targetsRes.data.data;
      let targetClustersRes = await ApiService.getTargetClusters();
      this.targetClusters = targetClustersRes.data.data;
      let leadFormsRes = await ApiService.getStoreLeadForms();
      this.leadForms = leadFormsRes.data.data;
      let dataParametersRes = await ApiService.getDataParameters();
      this.dataParameters = dataParametersRes.data;
      this.stores = await ApiService.getStores();
      this.stores.forEach(store => {
        store['$isDisabled'] = !this.isStoreEligible(store);
        return store;
      });
      let defaultStoreIndex = this.stores.findIndex(x => x.status == 1 && x.isDefault == true)
      if (defaultStoreIndex == -1) {
        this.set_promoAlertMessages([...this.promoAlertMessages, "Could not find a default store"])
        return
      }
      this.defaultStore = this.stores[defaultStoreIndex]
      this.stores.splice(defaultStoreIndex, 1)
      if (this.stores.length == 0) {
        this.set_promoAlertMessages([...this.promoAlertMessages, "Store setup is incomplete"])
      }
      if (this.promoConfigToEdit != null) {
        if (this.promoConfigToEdit.enabledStoreIds) {
          this.enabledStores = [];
          this.promoConfigToEdit.enabledStoreIds.forEach((storeId) => {
            let newStore = this.stores.find(store => { if (store.id && store.id == storeId) { return store } })
            if (newStore) { this.enabledStores.push(newStore); }
          });
        }
      }
      var storeIdList = [];
      this.stores.forEach(store => {
        storeIdList.push(store.id);
      })
      let labelsArray = this.stores.map(s => s.labels);
      let labelTypeIds = []
      labelsArray.forEach((lba) => {
        lba.forEach((lb) => {
          if (!labelTypeIds.includes(lb.labelTypeId)) {
            labelTypeIds.push(lb.labelTypeId);
          }
        })
      });
      this.labelDimentions = await ApiService.getLabelsTypesById(labelTypeIds);
      this.storeSelectOject = this.createStoreMultiSelectObject();
      this.labelDimentionFilter = this.setLabelDimentionFilter();
      this.isStoreSelectLoading = false;
      if (this.isLifetimeBudgetCapEnabled) {
        var insights = await ApiService.getStoreInsights(storeIdList.join(','));
        this.stores.forEach(store => {
          let storeInsight = insights.find(e => e.storeId == store.id);
          store.budgetRemaining = store.lifetimeBudgetCap - (storeInsight.insights.metricInsights[0].value + storeInsight.insights.metricInsights[1].value)
          if (!store.budgetRemaining || store.budgetRemaining < 0) {
            store.budgetRemaining = 0;
          }
        })
      }
    },
    isMaxBudgetOvershot (budget) {
      return budget < this.totalMaxBudget && budget >= this.totalMinBudget
    },
    setFilteredStoreIds () {
      this.selectedlabelIds = [];
      this.labelDimentionFilter.forEach((ld) => {
        ld.labels.forEach((label) => {
          if (label.isSelected) {
            this.selectedlabelIds.push(label.id);
          }
        })
      });
      let filteredStoreIds = [];
      this.stores.forEach((store) => {
        let storeLabelIds = store.labels.map(sl => sl.id);
        this.selectedlabelIds.forEach((lId) => {
          if (storeLabelIds.includes(lId)) { filteredStoreIds.push(store.id); }
          if (lId == "wa_e" && store.isWhatsAppEnabled) { filteredStoreIds.push(store.id); }
          if (lId == "wa_d" && !store.isWhatsAppEnabled) { filteredStoreIds.push(store.id); }
        })
      })
      this.filteredStoreIds = [...new Set(filteredStoreIds)];
    },
    filterStoreNameLabels (labels, childName, code, parentName) {
      return labels.filter(lb => lb.name != `${childName} (${code})` && lb.name != parentName);
    },
    getLabelBackground: function (color) {
      return {
        backgroundColor: color
      };
    },
    isMinBudgetOvershot (budget) {
      return budget < this.totalMinBudget;
    },
    allowDelete (storeId) {
      return !(this.isLivePromotion && this.liveStores.includes(storeId))
    },
    setEnabledStores (stores) {
      this.returnedStores = stores;
      this.enabledStores = stores;
    },
    setLabelDimentionFilter () {
      let labelDimentionFilterObject = [];
      let labelDimentionsFilterIds = [];
      let storeLabelId = this.labelDimentions.find(ld => ld.name == "Store").id;
      let dealerGroupLabelId = this.labelDimentions.find(ld => ld.name == "DealerGroup") ? this.labelDimentions.find(ld => ld.name == "DealerGroup").id : 0;
      let labels = this.stores.map(s => s.labels).reduce((total, amount) => {
        if (!total.includes(amount)) {
          return total.concat(amount);
        }
      }, []);
      let jsonObject = labels.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      labels = uniqueArray;
      labels.forEach((label) => {
        if (label.labelTypeId != storeLabelId && label.labelTypeId != dealerGroupLabelId) {
          if (labelDimentionsFilterIds.includes(label.labelTypeId)) {
            labelDimentionFilterObject.forEach((ldf) => {
              if (ldf.id == label.labelTypeId) {
                ldf.labels.push(label);
              }
            })
          } else {
            let parent = this.labelDimentions.find(ld => ld.id == label.labelTypeId);
            parent.labels = [];
            parent.labels.push(label);
            labelDimentionFilterObject.push(parent);
            labelDimentionsFilterIds.push(label.labelTypeId);
          }
        }
      });
      let waLdf = { "name": "WhatsApp", "labels": [] };
      waLdf.labels.push({ "description": "WhatsApp enabled stores", "id": "wa_e", "name": "Enabled" });
      waLdf.labels.push({ "description": "WhatsApp disabled stores", "id": "wa_d", "name": "Disabled" });
      labelDimentionFilterObject.push(waLdf);
      return labelDimentionFilterObject;
    },
    createStoreMultiSelectObject () {
      let dealerGroupLabelIds = [];
      let dealerGroupLabelId = this.labelDimentions.find(ld => ld.name == "DealerGroup") ? this.labelDimentions.find(ld => ld.name == "DealerGroup").id : 0;
      let storeSelectObject = [];
      this.stores.forEach((store) => {
        store.isDisabled = store.$isDisabled
        let labels = store.labels;
        let isStoreLabelGrouped = false;
        labels.forEach((label) => {
          if (label.labelTypeId == dealerGroupLabelId) {
            isStoreLabelGrouped = true;
            if (dealerGroupLabelIds.includes(label.id)) {
              storeSelectObject.forEach((ssObj) => {
                if (ssObj.name == label.name) {
                  store.effectiveName = `${store.name} (${store.storeCode})`;
                  ssObj.children.push(store);
                }
              })
            } else {
              let parent = {};
              parent.name = label.name;
              parent.isSelected = false;
              parent.id = label.id;
              parent.children = [];
              store.effectiveName = `${store.name} (${store.storeCode})`;
              parent.children.push(store);
              storeSelectObject.push(parent);
              dealerGroupLabelIds.push(label.id);
            }
          }
        })
        if (!isStoreLabelGrouped) {
          if (dealerGroupLabelIds.includes(-1)) {
            storeSelectObject.forEach((ssObj) => {
              if (ssObj.name == "Other") {
                store.effectiveName = `${store.name} (${store.storeCode})`;
                ssObj.children.push(store);
              }
            })
          } else {
            let parent = {};
            parent.name = "Other";
            parent.isSelected = false;
            parent.id = -1;
            parent.children = [];
            store.effectiveName = `${store.name} (${store.storeCode})`;
            parent.children.push(store);
            storeSelectObject.push(parent);
            dealerGroupLabelIds.push(-1);
          }
        }
      });
      return storeSelectObject.sort(function (a, b) {
        if (a.name > b.name || a.name == "Other") {
          return 1;
        } else {
          return -1;
        }
      });
    },
    customLabel (option) {
      return `${option.name} (${option.storeCode})`;
    },
    getRemainingBudgetMessage (budget) {
      return `Remaining Budget: ${budget < 0 ? 0 : budget}`
    },
    resetDaypartingType (budgetType) {
      if (budgetType == this.budgetTypes.Daily && this.promoConfig.settings.schedule.type == this.scheduleTypes.Custom) {
        this.promoConfig.settings.schedule.type = this.scheduleTypes.AlwaysOn;
      }
    },
    isStoreEligible (store) {
      delete this.invalidStoreErrorMessages[store.id];
      let isSetupComplete = true;
      let isWhatsappSetupComplete = true;
      let isMappedToSelectedPublishers = true;
      isSetupComplete = store.facebookPages.findIndex(sfp => sfp.type == this.promoConfig.settings.assetType) != -1;

      if (this.promoConfig && this.promoConfig.settings && this.promoConfig.settings.publishers) {
        this.promoConfig.settings.publishers.forEach((publisher) => {
          isMappedToSelectedPublishers = isMappedToSelectedPublishers && store.searchEngineIds.includes(publisher)
        })
      }

      if (this.promoConfig.promotionType == this.promotionTypes.Paid) {
        // account and leadform validation is only required if the promotion is a paid promotion
        let accountIndex = store.accounts.findIndex(sa => sa.type == this.promoConfig.settings.budget.fundingSource);
        // objective Id 14 => Lead Generation
        if (this.promoConfig.objective == 14) {
          var leadformId = store.storeLeadForms.find(x => x.storeLeadFormId == this.promoConfig.settings.storeLeadFormId);
          if (!leadformId) {
            this.invalidStoreErrorMessages[store.id] = "LeadForm Not Available";
            return false;
          }
        }
        isSetupComplete = isSetupComplete && accountIndex != -1;
        isWhatsappSetupComplete = this.promoConfig.destination != fbEnum.destinationType.WhatsApp || store.isWhatsAppEnabled;
      }

      if (!isMappedToSelectedPublishers) {
        this.invalidStoreErrorMessages[store.id] = "Store is not mapped to the selected publishers"
        return false
      }
      if (!isSetupComplete) {
        this.invalidStoreErrorMessages[store.id] = "Setup incomplete";
        return false;
      } else if (!isWhatsappSetupComplete) {
        this.invalidStoreErrorMessages[store.id] = "WhatsApp not enabled";
        return false;
      }
      return true;
    },
    getDefaultPromoConfig () {
      return {
        name: "",
        productId: -1,
        type: "",
        promotionType: this.promotionType,
        status: 1, // draft: 1, active: 2, deleted: 3,
        isTemplate: false,
        objective: 11,
        useAdLinkForConvDomain: false,
        destination: 1,
        publisher: [],
        settings: {
          controls: {
            budget: 0,
            creatives: 0,
            targeting: 0,
            schedule: 0
          },
          budget: {
            fundingSource: 1,
            type: 1, // 1: Daily, 2: Lifetime
            promoBudgetType: 2, // 1: Total, 2: Per Store
            total: "",
            suggested: "",
            max: "",
            min: ""
          },
          schedule: {
            startDate: moment().format("YYYY-MM-DD"),
            endDate: "",
            type: 1,
            dayPartingSchedules: []
          },
          targeting: {
            clusterIds: [],
            specificTargetIds: []
          },
          targetStoresCount: null,
          storeLeadFormId: 0,
          assetType: AssetType.hq,
          testedSearchEngines: [],
          publishers: [5],
          budgetSplit: {
            facebookBudget: 100,
            googleBudget: 0
          }
        },
        creativeJson: null,
        campaignLauncherConfiguration: null,
        kpiMetricId: null,
        enabledStoreIds: [],
        storeType: StoreType.selective
      }
    },
    buildValidator (validateFunction) {
      return [
        {
          validator: (rule, value, callback) => {
            if (this.checkIfValid) {
              validateFunction(value, callback)
            }
            callback();
          }
        }
      ]
    },
    closeModal () {
      if (this.isReadOnlyMode) {
        this.openPromoModal = false;
        return
      }
      this.showConfirmModal({
        content: 'Unsaved changes will be lost. Are you sure you want to close?',
        onOk: () => {
          this.enabledStores = [];
          this.liveStores = [];
          this.openPromoModal = false;
          this.isPromoSaved = false;
        }
      })
    },
    formatPromoSchedule () {
      let schedule = this.promoConfig.settings.schedule
      schedule.startDate = moment(schedule.startDate).format("YYYY-MM-DD")
      schedule.endDate = moment(schedule.endDate).format("YYYY-MM-DD")
    },
    validatePromotion () {
      this.isPromoSaved = true;
      this.customErrors.stores = true;
      if ((this.promoConfig.storeType != StoreType.selective) || (Array.isArray(this.enabledStores) && this.enabledStores.length > 0)) {
        this.customErrors.stores = false;
      }
      this.checkIfValid = true;
      if (this.promoConfig.promotionType == this.promotionTypes.Paid) {
        this.formatPromoSchedule();
      }
      this.$refs["promotionForm"].validate((valid) => {
        this.checkIfValid = false;
        this.$nextTick(() => {
          if (valid) {
            if (this.customErrors.stores) {
              let errors = document.getElementsByClassName("error-tip");
              if (errors.length > 0) {
                errors[0].scrollIntoView({ block: 'end' });
              }
              return;
            }
            if (!this.defaultStore || !this.isStoreEligible(this.defaultStore)) {
              this.isPromoValid = false;
              this.showErrorMessage('Default store setup is incomplete.');
            } else {
              if (this.inCampaignLauncher || (this.promoConfig.promotionType == this.promotionTypes.Organic && this.isEditMode && !this.isLivePromotion)) {
                this.updatePromotion()
              } else if (this.isLivePromotion) {
                this.updateLivePromotion();
              } else {
                if (this.promoConfig.promotionType == this.promotionTypes.Organic) {
                  // if its an organic promotion, then we must save it on click of next since there is no campaign config involved
                  this.savePromotionAndCampaignConfig();
                } else {
                  this.initializeCampaignConfiguration()
                }
              }
            }
          } else {
            this.$nextTick(function () {
              let errors = document.getElementsByClassName("ivu-form-item-error-tip");
              if (errors.length > 0) {
                errors[0].parentElement.scrollIntoView({ block: 'end' });
              }
            });
          }
        })
      })
    },
    changeToFacebookCLCFormat (dayPartingSchedules) {
      dayPartingSchedules.forEach(function (data) {
        data['start_minute'] = data['startMinute'];
        data['end_minute'] = data['endMinute'];
        data['timezone_type'] = data['timezoneType'];
        delete data['startMinute'];
        delete data['endMinute'];
        delete data['timezoneType'];
      });
      return dayPartingSchedules;
    },
    initializeCampaignConfiguration () {
      this.openPromoModal = false;
      this.openCampaingConfig = true;

      if (this.isEditMode) {
        this.prepopulatedCampaignConfig = this.handleOldFacebookConfigs(
          this.promoConfigToEdit.campaignLauncherConfiguration[SearchEngines.Facebook].configJson
        );
      } else {
        this.prepopulatedCampaignConfig = this.getDefaultFormConfig();
      }

      let bpse;
      if (this.defaultStore.accounts && this.defaultStore.accounts.length > 0) {
        let storeAccounts = this.defaultStore.accounts.filter(acc => acc.type == this.promoConfig.settings.budget.fundingSource);
        if (storeAccounts && storeAccounts.length) {
          let engineFilteredBpses = this.accounts.filter(account => account.searchEngineTypeId == SearchEngines.Facebook)
          var mappedStoreAccount = {}
          engineFilteredBpses.forEach((account) => {
            let storeAccount = storeAccounts.find(storeAcc => storeAcc.businessProfileSearchEngineId == account.id)
            if (storeAccount) {
              mappedStoreAccount = storeAccount
            }
          });
          bpse = engineFilteredBpses.find(account =>
            account.id == mappedStoreAccount.businessProfileSearchEngineId)
        }
      }

      let facebookPageId;
      if (this.defaultStore.facebookPages && this.defaultStore.facebookPages.length > 0) {
        let facebookPage = this.defaultStore.facebookPages.find(fb => fb.type == this.promoConfig.settings.assetType);
        if (facebookPage && facebookPage.facebookPageId) {
          facebookPageId = facebookPage.facebookPageId
        }
      }

      let instagramAccountId;
      if (this.defaultStore.instagramAccounts && this.defaultStore.instagramAccounts.length > 0) {
        let instagramAccount = this.defaultStore.instagramAccounts.find(insta => insta.type == this.promoConfig.settings.assetType);
        if (instagramAccount && instagramAccount.instagramAccountId) {
          instagramAccountId = instagramAccount.instagramAccountId;
        }
      }

      this.prepopulatedCampaignConfig.facebookAccountId = bpse.accountId;
      this.prepopulatedCampaignConfig.bpseId = bpse.id;
      this.prepopulatedCampaignConfig.campaign.objective = this.promoConfig.objective;
      this.prepopulatedCampaignConfig.adset.destination = this.promoConfig.destination;
      this.prepopulatedCampaignConfig.campaign.name = this.prepopulatedCampaignConfig.campaign.name ? this.prepopulatedCampaignConfig.campaign.name : `${this.promoConfig.name} - ({{store.storeCode}})`;
      this.prepopulatedCampaignConfig.name = this.promoConfig.name;
      this.prepopulatedCampaignConfig.adset.name = "Adgroup";
      this.prepopulatedCampaignConfig.campaign.budgetType = this.promoConfig.settings.budget.type;
      this.prepopulatedCampaignConfig.adset.schedule.type = this.promoConfig.settings.schedule.type;
      let dayPartingSchedulesCopy = [];
      if (this.promoConfig.settings.schedule.type == this.scheduleTypes.Custom) {
        dayPartingSchedulesCopy = this.changeToFacebookCLCFormat(JSON.parse(JSON.stringify(this.promoConfig.settings.schedule.dayPartingSchedules)));
      }
      this.prepopulatedCampaignConfig.adset.schedule.dayParting_schedules = dayPartingSchedulesCopy || [];
      this.prepopulatedCampaignConfig.facebookPageId = facebookPageId;
      this.prepopulatedCampaignConfig.instagramAccountId = instagramAccountId || "";
      this.prepopulatedCampaignConfig.adset.trackingPixel = this.defaultStore.trackingPixel || "";
      this.prepopulatedCampaignConfig.adset.conversionDomain = this.defaultStore.conversionDomain || "";
      this.prepopulatedCampaignConfig.adset.useAdLinkForConvDomain = this.promoConfig.useAdLinkForConvDomain;
      this.prepopulatedCampaignConfig.adset.appEvent = this.defaultStore.appId || "";

      if (moment(this.promoConfig.settings.schedule.endDate).isValid()) {
        this.prepopulatedCampaignConfig.adset.scheduling.option = "Use Line item schedule"
      }

      this.prepopulatedCampaignConfig.campaign.locationMode = fbEnum.locationMode.Hyperlocal;
      this.prepopulatedCampaignConfig.campaign.adgroupPerLocation = 'all';

      this.set_promotionObject(this.promoConfig)
    },
    async saveOrUpdate (clConfig) {
      if (this.isEditMode) {
        const clConfigSavedSuccessfully = await this.updateCampaignConfiguration(clConfig)
        if (clConfigSavedSuccessfully) {
          await this.updatePromotion()
        }
        this.openCampaingConfig = false
      } else {
        this.savePromotionAndCampaignConfig(clConfig)
      }
    },
    async savePromotionAndCampaignConfig (clConfig) {
      let promoConfigCopy = this.getPromotionData(clConfig);
      try {
        let response = await ApiService.savePromotion(promoConfigCopy);
        promoConfigCopy.id = this.promoConfig.id = response.data.data.promotionId;
        if (promoConfigCopy.promotionType == this.promotionTypes.Paid) {
          let config = promoConfigCopy.campaignLauncherConfiguration
          promoConfigCopy.campaignLauncherConfiguration = {}
          promoConfigCopy.campaignLauncherConfiguration[SearchEngines.Facebook] = config;
          promoConfigCopy.campaignLauncherConfiguration[SearchEngines.Facebook].promotionId = promoConfigCopy.id;
          await ApiService.saveCampaignLauncherConfiguration(promoConfigCopy.campaignLauncherConfiguration[SearchEngines.Facebook]);
        }
        let newPromotion = await ApiService.getPromotion(promoConfigCopy.id);
        if (newPromotion.campaignLauncherConfiguration && newPromotion.campaignLauncherConfiguration[SearchEngines.Facebook] && newPromotion.campaignLauncherConfiguration[SearchEngines.Facebook].configJson) {
          newPromotion.campaignLauncherConfiguration[SearchEngines.Facebook].configJson = JSON.parse(
            newPromotion.campaignLauncherConfiguration[SearchEngines.Facebook].configJson
          );
        }
        this.showSuccessMessage("The promotion was successfully created");
        // Remove onbeforeunload event handler attached to this page
        window.onbeforeunload = function () {}
        if (this.promoConfig.promotionType == this.promotionTypes.Paid) {
          window.location = `/App/CampaignLauncher/List/${deltax.businessProfileId}#/promotions/${this.promoConfig.id}`;
          this.openCampaingConfig = false;
        } else {
          // in case of organic promotion the test is done on the dashboard UI itself on a new modal
          // Therefore, close the current modal and return to PromotionDashboard, which will load the post configuration/test modal
          this.openPromoModal = false;
          this.$emit("promoUpdated", {
            promoId: this.promoConfig.id,
            newPromoObj: JSON.parse(JSON.stringify(newPromotion))
          });
        }
      } catch (err) {
        this.showErrorMessage();
      } finally {
        this.isPromoSaved = false;
      }
    },
    async updateLivePromotion () {
      const promotionId = this.promoConfigToEdit.id;
      var selectedStoreIds = this.enabledStores.map(store => store.id);
      try {
        await ApiService.patchPromotion(promotionId, {
          storeIds: selectedStoreIds
        });
        this.promoConfig.enabledStoreIds = selectedStoreIds;
        this.showSuccessMessage("The promotion was successfully updated");
        this.$emit("promoUpdated", {
          promoId: promotionId,
          newPromoObj: JSON.parse(JSON.stringify(this.promoConfig))
        });
      } catch (err) {
        this.showErrorMessage();
      } finally {
        this.openPromoModal = false;
        this.isPromoSaved = false;
      }
    },
    async updatePromotion () {
      const promotionId = this.promoConfigToEdit.id;
      let promoConfigCopy = this.getPromotionData();
      promoConfigCopy.id = promotionId;
      try {
        await ApiService.patchPromotion(promotionId, {
          name: promoConfigCopy.name,
          settings: promoConfigCopy.settings,
          productId: promoConfigCopy.productId,
          isTemplate: promoConfigCopy.isTemplate,
          storeIds: promoConfigCopy.storeIds,
          storeType: promoConfigCopy.storeType,
          creativeJson: promoConfigCopy.creativeJson
        });

        let newPromotion = await ApiService.getPromotion(promotionId);
        if (newPromotion.campaignLauncherConfiguration && newPromotion.campaignLauncherConfiguration[SearchEngines.Facebook] && newPromotion.campaignLauncherConfiguration[SearchEngines.Facebook].configJson) {
          newPromotion.campaignLauncherConfiguration[SearchEngines.Facebook].configJson = JSON.parse(
            newPromotion.campaignLauncherConfiguration[SearchEngines.Facebook].configJson
          );
        }
        if (this.inCampaignLauncher) {
          let response = await campignLauncherApiService.replacePromotionMacros(promotionId, null);
          if (response) {
            let clcConfigJson = JSON.parse(response.data.data.clcConfigJson);
            let configJson = this.handleOldFacebookConfigs(clcConfigJson)
            this.campaignLauncherConfigs[this.promoConfig.campaignLauncherConfiguration[SearchEngines.Facebook].id].config = JSON.stringify(configJson)
          }
        }
        this.promoConfig.enabledStoreIds = promoConfigCopy.storeIds;
        this.showSuccessMessage("The promotion was successfully updated");
        this.$emit("promoUpdated", {
          promoId: promotionId,
          newPromoObj: newPromotion
        });
      } catch (err) {
        console.error(err);
        this.showErrorMessage();
      } finally {
        this.openPromoModal = false;
        this.isPromoSaved = false;
      }
    },
    async updateCampaignConfiguration (clConfig) {
      const postData = {
        bpseId: clConfig.businessProfileSearchEngineId,
        name: clConfig.name,
        configJson: clConfig.configJson,
        configId: this.promoConfig.campaignLauncherConfiguration[SearchEngines.Facebook].id,
        isReachAndFrequencyConfig: false
      };
      let savedSuccessfully = true
      try {
        await ApiService.updateCampaignConfig(postData)
      } catch (err) {
        console.error(err);
        this.showErrorMessage();
        savedSuccessfully = false
      }
      return savedSuccessfully
    },
    async loadExistingPromoConfig () {
      this.loadingExsisitingPromo = true;
      let promoConfigCopy = JSON.parse(JSON.stringify(this.promoConfigToEdit))
      if (promoConfigCopy.promotionType == PromotionType.Paid) {
        if (!promoConfigCopy.settings.schedule.endDate) {
          promoConfigCopy.settings.schedule.endDate = '';
        }
        if (promoConfigCopy.campaignLauncherConfiguration[SearchEngines.Facebook]) {
          promoConfigCopy.objective =
          promoConfigCopy.campaignLauncherConfiguration[SearchEngines.Facebook].configJson.campaign.objective;

          promoConfigCopy.destination =
          promoConfigCopy.campaignLauncherConfiguration[SearchEngines.Facebook].configJson.adset.destination;

          promoConfigCopy.useAdLinkForConvDomain = promoConfigCopy.campaignLauncherConfiguration[SearchEngines.Facebook].configJson.adset.hasOwnProperty('useAdLinkForConvDomain')
            ? promoConfigCopy.campaignLauncherConfiguration[SearchEngines.Facebook].configJson.adset.useAdLinkForConvDomain : false;
        }

        if (!promoConfigCopy.settings.schedule.type) {
          promoConfigCopy.settings.schedule.type = this.scheduleTypes.AlwaysOn;
        }

        if (!promoConfigCopy.settings.targetStoresCount) {
          promoConfigCopy.settings.targetStoresCount = null;
        }
        if (!promoConfigCopy.settings.budget.promoBudgetType) {
          promoConfigCopy.settings.budget.promoBudgetType = 2;
        }
        if (!promoConfigCopy.settings.budget.total) {
          promoConfigCopy.settings.budget.total = '';
        }
      } else if (promoConfigCopy.promotionType == PromotionType.Organic) {
        // initializing missing properties since they're bound to elements in the template
        promoConfigCopy.objective = null;
        promoConfigCopy.destination = null;
        promoConfigCopy.settings.storeLeadFormId = null;
        promoConfigCopy.settings.budget = { fundingSource: null }
        promoConfigCopy.settings.schedule = {
          startDate: "",
          endDate: "",
          type: 1,
          dayPartingSchedules: []
        }
        promoConfigCopy.settings.targeting = {
          clusterIds: [],
          specificTargetIds: []
        }
        promoConfigCopy.settings.storeLeadFormId = 0
        promoConfigCopy.settings.assetType = AssetType.hq
      }
      // convert true-false to 1-0 since iview accepts only number or string
      for (let key in promoConfigCopy.settings.controls) {
        promoConfigCopy.settings.controls[key] = Number(promoConfigCopy.settings.controls[key])
      }
      this.enabledStores = [];
      if (this.isLivePromotion) {
        this.liveStores = promoConfigCopy.enabledStoreIds;
      }
      if (promoConfigCopy.enabledStoreIds) {
        promoConfigCopy.enabledStoreIds.forEach((storeId) => {
          let newStore = this.stores.find(store => { if (store.id && store.id == storeId) { return store } })
          if (newStore) { this.enabledStores.push(newStore); }
        });
      }
      this.promoConfig = promoConfigCopy
      this.$nextTick(() => {
        this.loadingExsisitingPromo = false;
      })
    },
    confirmReset () {
      this.showConfirmModal({
        content: 'Unsaved changes will be lost. Are you sure you want to reset?',
        onOk: () => {
          this.resetPromoForm();
          this.$refs.storeSelect.deselectAll();
        }
      })
    },
    resetPromoForm () {
      this.promoConfig = this.getDefaultPromoConfig();
      this.$refs["promotionForm"].resetFields() // resets the validation result
    },
    getPromotionData (clConfig) {
      if (clConfig) {
        clConfig.userId = deltax.userId
        clConfig.updatedByUserId = deltax.userId
        clConfig.isPromotion = true
      }
      let promoConfigCopy = JSON.parse(JSON.stringify(this.promoConfig))
      if (this.promoConfig.promotionType == this.promotionTypes.Paid) {
        promoConfigCopy.campaignLauncherConfiguration = clConfig;
        if (promoConfigCopy.settings.schedule.endDate == "Invalid date") {
          promoConfigCopy.settings.schedule.endDate = ""
        }
        if (this.promoConfig.settings.organicPromotionId) {
          promoConfigCopy.creativeJson = JSON.stringify({ facebook: { facebookSpec: "{\"single\":[],\"carousel\":[],\"collection\":[],\"existing posts\":[{\"hotRowId\":\"existing posts-1\",\"AdName\":\"AD1\",\"targets\":null,\"locations\":null,\"Creative\":{\"object_story_id\":\"{{promo.organicPostId}}\",\"instagram_actor_id\":\"{{store.instagramAccountId}}\"},\"StartDate\":null,\"EndDate\":null,\"StartTime\":null,\"EndTime\":null,\"Link\":\"\",\"labels\":[]}]}", handsonSpec: "{\"single\":[],\"carousel\":[],\"collection\":[],\"existing posts\":[{\"HOT_Preview Ad\":\"true\",\"HOT_Ad Name\":\"AD1\",\"HOT_Post ID\":\"{{promo.organicPostId}}\",\"HOT_Post Name\":\"AD1\",\"HOT_StartDate\":null,\"HOT_EndDate\":null,\"HOT_Targets\":null,\"HOT_Locations\":null}]}" }, google: {} })
        }
      } else if (this.promoConfig.promotionType == this.promotionTypes.Organic) {
        // getOrganicPromotionData will return promo object with only required fields set
        promoConfigCopy = this.getOrganicPromotionData(promoConfigCopy);
      }

      promoConfigCopy.settings = JSON.stringify(promoConfigCopy.settings)
      promoConfigCopy["storeIds"] = this.enabledStores.map(store => store.id);
      return promoConfigCopy;
    },
    getOrganicPromotionData (promotionData) {
      return {
        name: promotionData.name,
        promotionType: this.promotionTypes.Organic,
        type: promotionData.type,
        productId: promotionData.productId,
        status: promotionData.status ? promotionData.status : PromotionStatus.draft,
        isTested: promotionData.settings.testedSearchEngines ? promotionData.settings.testedSearchEngines.includes(SearchEngines.Facebook) : false,
        isTemplate: promotionData.isTemplate,
        settings: {
          controls: {
            budget: 0,
            creatives: promotionData.settings.controls.creatives,
            targeting: 0,
            schedule: 0
          },
          assetType: promotionData.settings.assetType
        },
        creativeJson: null,
        storeType: promotionData.storeType
      }
    }
  }
}
</script>

<style>
.dx-tab-style .ivu-tabs-tab {
  font-size: 12px !important;
  color: #555 !important;
  border-radius: 0px !important;
}
.dx-tab-style .ivu-tabs-tab:hover {
  border-top: 2px solid var(--main-bg-color) !important;
}
.dx-tab-style .ivu-tabs-tab-active, .dx-tab-style .ivu-tabs-tab-focused {
  border-top: 2px solid var(--main-bg-color) !important;
  font-weight: bold !important;
}
.dx-tab-style .ivu-tabs-nav-container:focus .ivu-tabs-tab-focused {
  border-color: var(--main-bg-color) !important;
}
.ivu-alert-close .ivu-icon-ios-close {
  top: 2px !important
}
.ivu-layout {
  background: #e9eaed !important;
}
.modal-header {
  margin: 0px;
  padding: 0px;
  font-size: 20px !important;
}
.config-modal {
  height: 70vh;
  overflow: auto;
}
.config-modal .ivu-tabs {
  height: inherit;
}
.config-modal .ivu-tabs-content {
  height: inherit;
  padding-bottom: 50px;
}
.config-modal .ivu-tabs-tabpane {
  overflow: auto;
  height: 100%;
}
.config-modal span, .config-modal label, .config-modal input {
  font-size: 13px !important;
}
.config-modal label {
  color:#979898;
}
.ivu-divider span {
  color: var(--dx-gray);
}
.config-modal .ivu-form-item {
  margin-bottom :15px !important
}
.config-modal .ivu-form-item-error-tip{
  padding-top: 0px !important;
}
.config-modal .ivu-checkbox-checked .ivu-checkbox-inner {
  border-color:  var(--main-bg-color) !important;
  background-color: var(--main-bg-color) !important;
}
.config-modal .ivu-checkbox-indeterminate .ivu-checkbox-inner{
  border-color:  var(--main-bg-color) !important;
  background-color: var(--main-bg-color) !important;
}
.config-modal .ivu-radio-checked .ivu-radio-inner{
  border-color: var(--main-bg-color) !important;
}
.config-modal .ivu-radio-checked .ivu-radio-inner::after{
  background-color: var(--main-bg-color) !important;
}
.config-modal .ivu-tabs-tab-active, .config-modal .ivu-tabs-tab-focused{
  color: #2C2E2F !important;
}
.config-modal .ivu-checkbox-wrapper > .ivu-checkbox {
  padding-right: 5px;
}

.ivu-form-item-error-tip {
  color: #b94a48 !important;
}
.ivu-form-item-error .ivu-select-arrow {
  color: #b94a48 !important;
}
.ivu-form-item-error .ivu-input-icon {
  color: #b94a48 !important;
}
.ivu-form-item-error .ivu-input {
  border-color: #b94a48 !important;
}
.ivu-form-item-error .ivu-input-group-append {
  border-color: #b94a48 !important;
}

.ivu-form-item-required .ivu-form-item-label:before {
  color: #b94a48 !important;
}

.store-multiselect .multiselect__option--highlight{
    background:#f3f3f3;
    color:black;
}
.store-multiselect .multiselect__option--selected.multiselect__option--highlight{
    background:#f3f3f3;
    color:black;
}
.store-multiselect .multiselect__option--selected{
    background:white;
    color:black;
}
.store-multiselect .multiselect{
    font-size:12px!important;
}
.store-multiselect .multiselect__tags {
    min-height: 35px;
    padding: 2px 40px 2px 4px;
    border-radius: 0px;
}
.store-multiselect .multiselect__input, .store-multiselect .multiselect__single{
    font-size: 12px!important;
    padding-top: 5px;
}
.store-multiselect .multiselect__select{
    padding: 0px 8px 6px 8px;
}
.store-labels-selector{
  margin-left: 5px;
}
.label-container{
  margin-right: 4%;
}
</style>

<style scoped>
.noLabelText {
  padding-top: 6px !important;
}
.selected-stores {
  text-align: left;
  color: #979898 !important;
  font-size: 13px !important;
}
.bold-text {
  font-weight: bold !important;
}
.store-select {
  padding-left: 2%;
  padding-right: 2%;
}
.filterListGroup{
  list-style-type: none;
  margin-bottom: 0px;
  margin-left: 5px !important;
}
.filterListGroup-name{
   margin-bottom: 10px !important;
   margin-top: 5px !important;
}
.filterListGroup-item{
   padding: 0px 0px 0px 10px;
   border:none;
   margin-bottom: 0px !important;
}
h3.modal-header {
  border: 0 !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #ffffff;
}
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.day-button{
  margin: 0.11rem;
}
.schedule-btn-pointer{
 cursor: pointer;
}
.add-time-slot{
  margin: 0px;
}
.add-schedule-button{
  margin-top: 1rem;
}
.schedule-block{
  padding-top:15px
}
.error-tip{
  color: #b94a48 !important
}
.warning-tip{
  color: #e0e40b !important
}
.custom__tag{
    position: relative;
    display: inline-block;
    padding: 0px 5px;
    margin: 2px;
    border-width: thin;
    border-style: solid;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #f7f7f7;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    line-height: 25px;
}
.custom__remove{
  color: #666;
  cursor: pointer;
  margin-left: 3px;
}
.invalid-store-message{
   float: right;
   color: #ccc;
}
.tracking-settings-info-icon{
  padding-right: 5px;
}

#store-filter-list {
  height: max-content;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: max-content;
  margin-right: 10px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
