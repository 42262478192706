<template>
  <div>
    {{ allVerified }}
    <div v-if="budgetTypePivot.campaigns > 0" class="budget-type-pivot">
      <div class="title-sticky">
        <label class="element-label">Campaign Setup</label>
        <span class="verified-action" v-if="isSubmission">
          <label class="checkbox-status-label">
            {{ budgetTypeVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="budgetTypeVerified" />
          </label>
        </span>
      </div>
      <div class="budget-type-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th
                v-for="item in budgetTypeColumns"
                :key="item"
                style="border:1px solid #ddd;padding:10px"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, i) in budgetTypePivot.records" :key="i">
              <td>{{ record.campaignName }}</td>
              <td
                v-if="i == 0"
                :rowspan="record.rowSpan"
                class="data-center upper-case"
              >
                {{ record.campaignType }}
              </td>
              <td v-if="i == 0" :rowspan="record.rowSpan" class="data-center">
                {{ record.buyType }}
              </td>
              <!-- <td v-if="i == 0" :rowspan="record.rowSpan" class="data-center">
                {{ record.budgetType }}
              </td> -->
              <td class="data-center">
                {{ record.bpCurrency + " " + record.totalBudget }}
              </td>
              <td
                v-if="i == 0"
                :rowspan="record.rowSpan"
                class="data-center upper-case"
              >
                {{ record.status }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="locationsPivot.length > 0" class="locations-pivot">
      <div class="title-sticky">
        <label class="element-label">Locations</label>
        <span class="verified-action" v-if="isSubmission">
          <label class="checkbox-status-label">
            {{ locationsVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="locationsVerified" />
          </label>
        </span>
      </div>
      <div class="locations-table">
        <table class="table table-bordered">
          <thead>
            <th
              v-for="item in locationColumns"
              :key="item"
              style="border:1px solid #ddd;padding:10px"
            >
              {{ item }}
            </th>
          </thead>
          <tbody>
            <tr v-for="(locObj, index) in locationsPivot" :key="index">
              <td>{{ locObj.location }}</td>
              <td class="data-right">
                {{ locObj.campaignsCount }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { locale } from "iview";
import CreativePreview from "./FacebookCreativePreview.vue";
import ApiService from "./ApiService.js";
import lang from "iview/dist/locale/en-US";
import { mapState } from "vuex";

locale(lang); // iview is default chinese

export default {
  components: {},
  mounted: function () {},
  props: {
    isSubmission: { default: true }
  },
  data: function () {
    return {
      budgetTypeVerified: true,
      locationsVerified: true,
      budgetTypeColumns: [
        "Campaign Name",
        "Campaign Type",
        "Buy Type",
        // "Budget Type",
        "Budget",
        "Status"
      ],
      locationColumns: ["Location", "No. of Campaigns"]
    };
  },
  computed: {
    ...mapState([
      "adwordsEnums",
      "selectedCampaignLauncherConfig",
      "adwordsEntitiesResponse"
    ]),
    budgetTypePivot: function () {
      this.budgetTypeVerified = false;

      if (JSON.stringify(this.adwordsEntitiesResponse) === "{}") return {}
      const adwordsEntities = this.adwordsEntitiesResponse.adwordsEntities;
      let records = adwordsEntities.map(campaign => {
        let record = {
          campaignName: campaign.name,
          campaignType: this.findByValue(this.adwordsEnums.campaignType, campaign.type),
          // budgetType: this.adwordsEnums.campaignBudgetPeriod[campaign.budgetPeriodType],
          totalBudget: campaign.budget.toLocaleString(),
          status: this.adwordsEnums.status[campaign.status],
          rowSpan: adwordsEntities.length,
          bpCurrency: campaign.additionalData.bpCurrencyCode
        }
        record.buyType = campaign.type == this.adwordsEnums.campaignType.Search
          ? this.adwordsEnums.biddingStrategyTypes[campaign.biddingStrategyType]
          : this.findByValue(
            this.adwordsEnums.appCampaignBiddingFocus,
            campaign.appCampaignBiddingFocus
          );

        return record;
      });
      let totalCampaigns = records.length;
      return { records: records, campaigns: totalCampaigns };
    },
    locationsPivot: function () {
      this.locationsVerified = false;

      if (JSON.stringify(this.adwordsEntitiesResponse) === "{}") return {}
      const adwordsEntities = this.adwordsEntitiesResponse.adwordsEntities;
      return adwordsEntities[0].locations.map(location => {
        return {
          location: location.completePath || location.completeName,
          campaignsCount: adwordsEntities.length
        }
      });
    },
    allVerified: function () {
      if (this.isSubmission) {
        this.$emit("elementsVerified", [
          { value: this.budgetTypeVerified, label: "Campaign Setup" },
          { value: this.locationsVerified, label: "Locations" }
        ]);
      }
      return "";
    }
  },
  methods: {
    findByValue (obj, findValue) {
      return Object.entries(obj).find(([key, val]) => val == findValue)[0]
    }
  }
};
</script>

<style scoped>
.title-sticky {
  position: sticky;
  padding: 0px 5px;
  position: sticky;
  top: 100px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
}
.verified-action {
  float: right;
  padding: 10px;
}
.checkbox-status-label {
  padding-right: 5px;
  color: #999;
  cursor: pointer;
}
input[type="checkbox"] {
  vertical-align: text-bottom;
}
.total-footer {
  font-weight: 600;
  background-color: #eee;
}
.table-footer-label {
  border: 1px solid #ddd;
  padding: 0px 5px;
}
.target-label {
  padding-top: 10px;
}
.target-sub-label {
  padding-top: 10px;
  font-size: 0.9em;
  color: #979898;
  max-width: 420px;
}
.page-label {
  padding: 10px 5px;
  position: sticky;
  top: 140px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.creatives-li {
  padding-bottom: 5px;
  list-style: none;
}
.more-previews {
  font-size: 1.3em;
}
.data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
.upper-case {
  text-transform: uppercase;
}
::v-deep .ivu-modal-footer {
  display: none;
}
</style>
