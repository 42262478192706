class Objective {
  constructor (ColumnConfig, CreativeSpec, AdFormats, OptimizationGoal) {
    this.columnConfig = ColumnConfig
    this.creativeSpec = CreativeSpec
    this.rnfAdformats = AdFormats || {};
    this.optimizationGoals = OptimizationGoal || []
  }
}

export { Objective }
