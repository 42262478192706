import { Objective } from "./Objective.js"
import { platforms, positions } from "../../Constants/FacebookEnums.js"

let ColumnConfig = function () {
  this.single = new function () {
    this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_Primary Text", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Image/Video"]
    this.card = []
    this.requiredCard = []
    this.callToAction = []
  }()
}
let CreativeSpec = function () {
  this.single = new function () {
    this.image = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "photo_data": {
            "url": "HOT_Image/Video",
            "caption": "HOT_Primary Text"
          }
        }
      }
    }
    this.video = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "video_data": {
            "image_url": "HOT_Image/Video",
            "video_id": "HOT_SearchEngineVideoId",
            "message": "HOT_Primary Text"
          }
        }
      }
    }
  }()
}
let AdFormats = {
  "Image or Carousel": {
    "adSheets": ["single"],
    "placements": {
      [platforms.Facebook]: [
        positions.Facebook.Feed,
        positions.Facebook.MarketPlace,
        positions.Facebook.VideoFeeds,
        positions.Facebook.InstantArticle,
        positions.Facebook.InstreamVideo],
      [platforms.Instagram]: [
        positions.Instagram.Stream,
        positions.Instagram.Explore
      ],
      [platforms.AudienceNetwork]: [
        positions.AudienceNetwork.InstreamVideo
      ]
    }
  },
  "Video": {
    "adSheets": ["single"],
    "placements": {
      [platforms.Facebook]: [
        positions.Facebook.Feed,
        positions.Facebook.MarketPlace,
        positions.Facebook.VideoFeeds,
        positions.Facebook.InstantArticle,
        positions.Facebook.InstreamVideo],
      [platforms.Instagram]: [
        positions.Instagram.Stream,
        positions.Instagram.Explore
      ],
      [platforms.AudienceNetwork]: [
        positions.AudienceNetwork.InstreamVideo
      ]
    }
  }
  // "Instant Experience": {
  //   "adSheets": ["collection"]
  // }
}
let postEngagement = new Objective(new ColumnConfig(), new CreativeSpec(), AdFormats)
export { postEngagement }
