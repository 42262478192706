<template>
  <div class="row">
    <div class="col-md-12 col-xs-12 auth-flow" ref="flow">
      <div
        v-if="isEditEnabled && block"
        class="blocker"
        @click="blockText = 'Save/Revert changes first'"
        @mouseleave="blockText = ''"
      >{{blockText}}</div>
      <div class="auth-flow-header">
        <div v-if="isEditEnabled" class="row nameAndSave-row">
          <div class="col-md-3">
            <input v-if="isEditEnabled" class="edit-name-box" v-model="name" ref="nameInput" />
            <span v-if="isEditEnabled">
              <i
                class="fa fa-pencil edit-name-pencil"
                v-tooltip="'Edit'"
                @click.stop="focusNameInput(self)"
              ></i>
            </span>
          </div>
          <div class="col-md-3 pull-right flow-icons">
            <label class="action-status-label" v-show="authFlowStatus.length > 0">{{authFlowStatus}}</label>
            <i
              v-show="authFlow.Id == 0 || flowChanged"
              :class="saveFlowSpinner"
              v-tooltip="'Save'"
              @click.stop="saveAuthFlow"
            ></i>
            <i
              v-show="authFlow.Id > 0 && flowChanged"
              class="fa fa-undo revert-flow"
              v-tooltip="'Revert'"
              @click.stop="revertAuthFlow"
            ></i>
            <i
              v-show="!deleting"
              class="fa fa-trash remove-flow"
              v-tooltip="'Delete'"
              @click.stop="deleteAuthFlow"
            ></i>
          </div>
        </div>
        <div v-if="isEditEnabled" class="row appliesto-row">
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-2">Apply</div>
              <div class="col-md-10">
                <div
                  v-for="flowappliesTo in authFlowAppliesTo"
                  :key="flowappliesTo.Name+flowKeyTag"
                >
                  <input
                    type="checkbox"
                    :value="flowappliesTo"
                    v-model="appliesTo"
                    :id="'appliesTo'+ flowIndex + flowappliesTo.Name"
                  />
                  <Label
                    :for="'appliesTo'+ flowIndex + flowappliesTo.Name"
                    class="pointer"
                  >{{flowappliesTo.Label}}</Label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isEditEnabled" class="row status-row">
        <div class="col-md-3">
          <label v-html="approvalStatus"></label>
        </div>
      </div>
      <div class="row stage-row">
        <div class="col-md-12 auth-stages">
          <auth-stage
            v-for="(stage, index) in stages"
            :key="stage.Id"
            :flowIndex="flowIndex"
            :stageIndex="index"
            :focusNameInput="focusNameInput"
          ></auth-stage>
          <div v-if="!isEditEnabled" class="row auth-stage">
            <div class="col-md-2">
              <div class="row">
                <div class="col-md-12">
                  <label class="edit-name-label end-stage-approved-label">Approved</label>
                </div>
              </div>
            </div>
            <div class="col-md-10"></div>
          </div>
        </div>
      </div>
      <div v-if="isEditEnabled" v-show="stages.length < 5" class="row add-stage-row">
        <div class="col-md-2">
          <div class="row">
            <div class="col-md-10">
              <a class="anchor-text" @click.stop="addStage">+Stage</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthFlowAppliesTo } from "../Constants.js";
import AuthStage from "./AuthStage.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    "auth-stage": AuthStage
  },
  mounted: function () {
    this.$nextTick(function () {
      this.unsavedFlow.id = this.authFlow.Id;
      this.unsavedFlow.flow = JSON.stringify(this.authFlow);
    });
  },
  props: {
    flowIndex: { default: -1 },
    dirty: { default: [] }
  },
  data: function () {
    return {
      self: this,
      deleting: false,
      saveFlowSpinner: "fa fa-check save-flow",
      authFlowAppliesTo: AuthFlowAppliesTo,
      unsavedFlow: { id: -1, flow: '{}' },
      authFlowStatus: "",
      flowKeyTag: 0,
      blockText: ''
    };
  },
  computed: {
    ...mapGetters(["getFlowByIndex", "getOtherFlowsAppliesToNames"]),
    ...mapState(["isEditEnabled"]),
    block: function () {
      return this.dirty.filter(x => x.key != this.flowIndex).length > 0;
    },
    authFlow: function () {
      return this.getFlowByIndex(this.flowIndex);
    },
    appliesTo: {
      get () {
        return this.authFlow.AppliesTo;
      },
      set (value) {
        var thisFlowCurrentValue = JSON.parse(JSON.stringify(this.appliesTo));
        var otherFlowsValues = this.getOtherFlowsAppliesToNames(this.flowIndex);
        var repeatedValue = value.filter(x => otherFlowsValues.includes(x.Name));
        if (repeatedValue.length <= 0) {
          this.changeFlowAppliesTo({ index: this.flowIndex, appliesTo: value });
        } else {
          this.changeFlowAppliesTo({ index: this.flowIndex, appliesTo: thisFlowCurrentValue });
          window.alert(`Cannot apply more than one authorization flow to '${repeatedValue[0].Label}'`);
        }
        this.flowKeyTag++; // re render the checkboxes with updated state
      }
    },
    name: {
      get () {
        return this.authFlow.Name;
      },
      set (value) {
        this.setAuthFlowName({ index: this.flowIndex, name: value });
      }
    },
    stages: function () {
      return this.authFlow.Stages;
    },
    flowChanged: function () {
      if (this.unsavedFlow.id == this.authFlow.Id) {
        return this.unsavedFlow.flow != JSON.stringify(this.authFlow);
      } else {
        this.unsavedFlow.id = this.authFlow.Id;
        this.unsavedFlow.flow = JSON.stringify(this.authFlow);
        return false;
      }
    },
    currentStage: function () {
      return (this.authFlow.CurrentStage
        ? this.authFlow.Stages.map(x => x.Id).indexOf(this.authFlow.CurrentStage)
        : 0) + 1;
    },
    approvalStatus: function () {
      return 'Approval Status: ' + (this.authFlow.Status && this.authFlow.Status == "REJECTED"
        ? '<span class="red">Rejected</span>'
        : this.authFlow.Stages.length > 0
          ? this.currentStage == this.authFlow.Stages.length && this.authFlow.Status && this.authFlow.Status == "APPROVED"
            ? '<span class="green">Approved</span>'
            : `Stage ${this.currentStage}/${this.authFlow.Stages.length}`
          : 'IN Review');
    }
  },
  methods: {
    ...mapActions([
      "showModal",
      "startModalSpinner",
      "closeModal",
      "setAuthFlow",
      "changeFlowAppliesTo",
      "removeFlowAppliesTo",
      "setAuthFlowName",
      "removeAuthFlow",
      "addAuthFlowStage"
    ]),
    focusNameInput: function (self) {
      $(self.$refs.nameInput).focus();
    },
    setStatus: function (status) {
      this.authFlowStatus = status;
      setTimeout(this.hideStatus, 1000);
    },
    hideStatus: function () {
      this.authFlowStatus = "";
    },
    revertAuthFlow: function () {
      this.setAuthFlow({
        index: this.flowIndex,
        authFlow: JSON.parse(this.unsavedFlow.flow)
      });
      this.setStatus("Reverted");
    },
    saveAuthFlow: function () {
      this.saveFlowSpinner = "fa fa-refresh fa-spin save-flow";
      if (this.authFlow.Id == 0) {
        // add
        this.$api.AddAuthFlow(this.name, this.authFlow, this.appliesTo.map(d => { return d.Id; }), this.saveAuthFlowCallback);
      } else if (this.authFlow.Id > 0) {
        // update
        this.$api.UpdateAuthFlow(this.name, this.authFlow, this.appliesTo.map(d => { return d.Id; }), this.saveAuthFlowCallback);
      }
    },
    saveAuthFlowCallback: function (apiResponse) {
      if (apiResponse.Success) {
        this.setAuthFlow({
          index: this.flowIndex,
          authFlow: apiResponse.Data.AuthFlow
        });
        this.unsavedFlow.flow = JSON.stringify(this.authFlow);
        this.setStatus("Saved");
      } else {
        window.alert("Error Occurred while saving Authorization!");
      }
      this.saveFlowSpinner = "fa fa-check save-flow";
    },
    deleteAuthFlow: function () {
      this.showModal({
        title: "Delete authorization flow",
        body: "Do you want to delete authorization flow?",
        callback: this.acceptDeleteAuthFlow });
    },
    acceptDeleteAuthFlow: function () {
      this.startModalSpinner();
      this.deleting = true;
      if (this.authFlow.Id == 0) {
        setTimeout(this.deleteAuthFlowCallback({ Success: true }), 500);
      } else if (this.authFlow.Id > 0) {
        this.$api.DeleteAuthFlow(this.authFlow, this.deleteAuthFlowCallback);
      }
    },
    deleteAuthFlowCallback: function (apiResponse) {
      if (apiResponse.Success) {
        this.$emit('flowChanged', { key: this.flowIndex, dirty: false });
        this.closeModal();
        this.removeAuthFlow({ index: this.flowIndex });
      } else {
        window.alert("Error Occurred while deleting Authorization!");
        this.closeModal();
      }
      this.deleting = false;
    },
    addStage: function () {
      var order =
        this.stages.length > 0
          ? this.stages[this.stages.length - 1].Order + 1
          : 1;
      var id = ["STAGE_1", "STAGE_2", "STAGE_3", "STAGE_4", "STAGE_5"].filter(
        x => !this.stages.map(x => x.Id).includes(x)
      )[0];
      this.addAuthFlowStage({
        index: this.flowIndex,
        stage: {
          Id: id,
          Order: order,
          Name: `Stage ${order}`,
          Factions: [],
          Actions: []
        }
      });
    }
  },
  watch: {
    'flowChanged': {
      handler: function (a, b) {
        this.$emit('flowChanged', { key: this.flowIndex, dirty: a });
      },
      immediate: true
    },
    'block': {
      handler: function (a, b) {
        if (a) {
          $(this.$refs.flow).find('input').attr('disabled', 'disabled');
        } else {
          $(this.$refs.flow).find('input').removeAttr('disabled');
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.auth-flow {
  background-color: white;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.edit-name-box {
  min-width: 250px;
}
.action-status-label {
  font-size: 12px;
  color: green;
  background-color: lightgreen;
  font-weight: 400;
  padding: 1px 2px;
  border-radius: 3px;
  margin: 0px;
}
.appliesto-row {
  padding-top: 15px;
}
.status-row {
  font-size: 1.2em;
  font-weight: 600;
}
.stage-row {
  padding-top: 15px;
}
.flow-icons {
  text-align: right;
  font-size: 1.3em;
}
.save-flow {
  color: green;
}
.revert-flow {
  color: red;
  margin-left: 15px;
}
.remove-flow {
  margin-left: 15px;
}
.blocker {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9998;
  position: absolute;
  width: 100%;
  text-align: center;
  background: #e9eaed;
  opacity: 0.3;
  font-size: 1.5em;
}
input[type="checkbox"] {
  vertical-align: text-bottom;
  margin-right: 4px;
  margin-top: 0;
}
.pointer {
  cursor: pointer;
}
::v-deep .anchor-text {
  text-decoration: underline;
  font-size: 12px;
  color: #979898;
  cursor: pointer;
}
::v-deep .edit-name-box {
  padding-left: 5px;
  padding-right: 18px;
  color: #555;
  border: 1px solid #e4e4e4;
}
::v-deep .edit-name-label {
  padding-right: 10px;
  color: #555;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
::v-deep .green {
  color: green;
}
::v-deep .red {
  color: #b94a48;
}
::v-deep .yellow {
  color: #ffc300;
}
::v-deep .edit-name-label:hover {
  -webkit-line-clamp: 4 !important;
}
::v-deep .edit-name-pencil {
  margin-left: -15px;
}
::v-deep .end-stage-approved-label {
  text-align: center;
}
</style>
