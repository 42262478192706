<template>
    <div class="cl-widget lead-forms-card">
      <Card>
        <div slot="title">
          <Row>
            <i-col span="8">
              <h4>
                Lead Forms
                <i v-if="showLoader" class="fa fa-spinner fa-spin" />
              </h4>
            </i-col>
            <i-col span="13" class="no-padding searchBar">
              <Input v-model="searchTerm" search placeholder="Search" class="search-bar input-search-bar" />
            </i-col>
          </Row>
        </div>
        <VuePerfectScrollbar>
          <div v-if="isError">
            <div class="alert alert-danger error-message">
              There was a problem fetching the Lead-Gen forms
            </div>
          </div>
          <div v-else>
            <div v-if="renderedForms.length == 0">
              <div class="alert alert-info no-forms-found-info" v-if="!isStringNullOrEmpty(searchTerm)">
                No Lead-Gen forms found containing "{{ searchTerm }}"
              </div>
              <div class="alert alert-info no-forms-info" v-else>
                No forms found. Create a Lead-Gen Form on LinkedIn to see them here
              </div>
            </div>
          </div>
          <div class="lead-forms-wrapper">
            <div class="lead-form" v-for="form in renderedForms" :key="form.id">
              <Row>
                <i-col span="2" offset="1">
                  <i class="fas fa-copy action-icon"
                    @click="copyToClipboard(form.id)">
                  </i>
                </i-col>
                <i-col>
                  <i-col span="20" class="cl-widget-text">
                    <span class="lead-form-name">{{ form.name }}</span>
                  </i-col>
                </i-col>
              </Row>
            </div>
          </div>
        </VuePerfectScrollbar>
      </Card>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { APIService } from "../../ApiService.js";
import { Input, locale, Card, Row, Col } from "iview";
import lang from "iview/dist/locale/en-US";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import mixins from "../../../../Mixins/copyToClipboard.js";
locale(lang);
export default {
  name: "LeadFormsWidget",
  mixins: [mixins],
  props: {
  },
  components: {
    Input,
    Card,
    Row,
    "i-col": Col,
    VuePerfectScrollbar
  },
  data () {
    return {
      bpseId: 0,
      leadForms: [],
      searchTerm: null,
      showLoader: false,
      isError: false,
      leadFormStaticData: [
        {
          id: 1,
          name: "Lead Form 1"
        },
        {
          id: 2,
          name: "Lead Form 2"
        },
        {
          id: 3,
          name: "Lead Form 3"
        }
      ]
    }
  },
  mounted () {
    let self = this;
    self.bpseId = self.selectedCampaignLauncherConfig.bpseId;
    self.getLeadForms();
  },
  computed: {
    ...mapState(["selectedCampaignLauncherConfig"]),
    renderedForms () {
      let searchTerm = this.searchTerm;
      let renderedForms = this.leadForms;
      if (!this.isStringNullOrEmpty(searchTerm)) {
        renderedForms = this.leadForms.filter(e => {
          return e.name.toLowerCase().includes(searchTerm.toLowerCase());
        });
      }

      return renderedForms;
    }
  },
  methods: {
    ...mapMutations(["set_linkedInLeadForms"]),
    isStringNullOrEmpty (word) {
      return word == null || word == "" || word == undefined;
    },
    getLeadForms () {
      let self = this;
      self.showLoader = true;
      APIService.getLinkedInLeadForms(self.bpseId)
        .then(response => {
          self.leadForms = response.data.data;
          this.set_linkedInLeadForms(self.leadForms)
          self.showLoader = false;
          self.isError = false;
        })
        .catch(() => {
          self.showLoader = false;
          self.isError = true;
          self.leadForms = [];
        });
    }
  },
  watch: {
    selectedCampaignLauncherConfig: {
      handler (value) {
        this.bpseId = value.bpseId;
        this.getLeadForms();
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.lead-forms-wrapper .lead-form {
  padding: 0.5rem 0rem;
}

.ps-container {
  min-height: 5rem;
  padding: 0.2rem 0rem;
  max-height: 15rem;
}
.searchBar {
  float: right;
}
</style>

<style src="../../../../Styles/dx-iview.css"></style>
