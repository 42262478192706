<template>
    <div>
      <template>
        <Progress
          v-if="publishProgressPercentage < 100 && publishInProgress"
          :percent="publishProgressPercentage"
          class="upload-progress"
          :stroke-width="5"
        />

        <Progress
          v-else
          :percent="publishTotalPercentage"
          :success-percent="publishSuccessPercentage"
          stroke-color="red"
          class="upload-progress"
          :stroke-width="5"
        >
          <div style="color: #999999">
            {{ uploadProgress.successCount }} / {{ uploadProgress.totalCount }}
          </div>
        </Progress>
      </template>
      <Row style="margin: 6px 0;">
        <Row-Col span="10">
          <i-input
            size="small"
            v-model="molocoSummarySearchQuery"
            placeholder="Search"
          />
        </Row-Col>
        <Row-Col span="14">
          <button-group size="small" style="float: right;">
            <i-button
              v-for="(className, status) in molocoEntitiesToShowOption"
              :key="status"
              :class="{ 'status-active-btn': molocoEntityStatusToShow.includes(status) }"
              @click="toggleMolocoEntitiesToShow(status)"
              >
                <i v-if="status != 'All'" :class="className"></i>
                <Icon v-else :type="className"></Icon>
              </i-button
            >
          </button-group>
        </Row-Col>
      </Row>
       <ul class="summmary-list">
        <li v-for="(campaign,campaignIndex) in molocoFilteredPublishSummaryComputed" :key="campaignIndex">
          <div
            data-toggle="collapse"
            class="summary-collapse"
            v-bind:href="'#campaign-summary-' + campaignIndex"
          >
            <span>
              <i
                v-bind:class="getEntityStatusIcon(campaign.processingStatus)"
              ></i>
              <span>
                {{ campaign.name }}
              </span>
              <span v-if="campaign.searchEngineCampaignID" class="entity-label">
                ({{ campaign.searchEngineCampaignID }})
              </span>
            </span>
            <span
              class="entity-export-error"
              v-if="campaign.processingMessage && showProcessingMessage(campaign.processingStatus)"
            >{{campaign.processingMessage}}<span v-if="campaign.processingStatus == molocoEnums.entityStatus.Edited && campaign.editedSummary">: {{campaign.editedSummary}}</span></span>
          </div>
          <ul
            v-bind:id="'campaign-summary-' + campaignIndex"
            class="collapse-open in campaign-summary"
          >
            <li
              class="panel-collapse"
              v-for="(adgroup,adgroupIndex) in campaign.childEntities"
              :key="adgroupIndex"
            >
              <div
                data-toggle="collapse"
                class="summary-collapse"
                :href="`#adgroup-summary-${campaignIndex}-${adgroupIndex}`"
              >
                <span>
                  <i v-bind:class="getEntityStatusIcon(adgroup.processingStatus)"></i>
                  <span>
                    {{ adgroup.name }}
                  </span>
                  <span v-if="adgroup.searchEngineAdgroupID" class="entity-label">
                    ({{ adgroup.searchEngineAdgroupID }})
                  </span>
                </span>
                <span
                  class="entity-export-error"
                  v-if="adgroup.processingMessage && showProcessingMessage(adgroup.processingStatus)"
                >{{adgroup.processingMessage}}</span>
              </div>
              <ul
                :id="`adgroup-summary-${campaignIndex}-${adgroupIndex}`"
                class="collapse-open in adgroup-summary"
              >
                <li
                  class="panel-collapse"
                  v-for="(ad,adIndex) in adgroup.childEntities"
                  :key="adIndex"
                >
                  <div>
                    <span>
                      <i v-bind:class="getEntityStatusIcon(ad.processingStatus)" class="no-pointer"></i>
                      <span>
                        {{ ad.name }}
                      </span>
                      <span v-if="ad.searchEngineAdID" class="entity-label">
                        ({{ ad.searchEngineAdID | getActualSearchEngineAdId  }})
                      </span>
                    </span>
                    <span
                      class="entity-export-error"
                      v-if="ad.processingMessage && showProcessingMessage(ad.processingStatus)"
                    >{{ customizeProcessingMessage(ad.processingStatus, ad.processingMessage, ad.editedSummary) }}</span>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
</template>

<script>
import { mapState } from "vuex";
import ApiService from '../ApiService.js';
import { EventBus } from "../../../EventBus.js";
import * as molocoEnums from "../../../Constants/MolocoEnums.js";
import { Progress, Input, Row, Col, Icon, ButtonGroup, Button } from "iview";

export default {
  components: {
    Progress,
    "i-input": Input,
    Row,
    Icon,
    "button-group": ButtonGroup,
    "i-button": Button,
    "Row-Col": Col
  },
  props: {
    submissionId: { default: 0 },
    viewType: { default: "Changes" }
  },
  data: function () {
    return {
      summaryCountResponse: {},
      entitiesResponse: [],
      molocoEnums,
      molocoSummarySearchQuery: "",
      molocoEntitiesToShowOption: {
        "All": "ios-albums",
        "Success": "fa fa-check-circle green",
        "Failed": "fa fa-exclamation-triangle red",
        "New": "fa fa-clock-o yellow"
      },
      molocoEntityStatusToShow: ["All"],
      summaryPanelData: [],
      molocoFilteredPublishSummary: [],
      inititalEntityState: {
        success: 0,
        failure: 0,
        total: 0
      },
      uploadProgress: {
        toShow: true,
        successCount: 0,
        failureCount: 0,
        totalCount: 0
      },
      getEntitiesInProgress: false
    };
  },
  created () {
    EventBus.$on("update-campaign", () => {
      this.getEntities();
    });
  },
  beforeDestroy () {
    EventBus.$off("update-campaign");
  },
  async mounted () {
    this.$watch(
      vm => [vm.molocoSummarySearchQuery, vm.molocoEntityStatusToShow, vm.summaryPanelData],
      () => {
        this.filterMolocoPublishSummary(this.summaryPanelData);
      },
      {
        deep: true,
        immediate: true
      }
    )
    await this.getEntities();
    this.initializePublishStatus()
  },
  filters: {
    getActualSearchEngineAdId (fullAdId) {
      if (fullAdId) {
        var identifiers = fullAdId.split("-")
        if (identifiers.length >= 1 && identifiers.length < 4) return identifiers[0]
        else if (identifiers.length == 4) return identifiers[2]
      } else {
        return ""
      }
    }
  },
  computed: {
    ...mapState(["molocoCampaignLauncherPublishDataId", "publishInProgress", "renderSubmissionSummary", "molocoEditedStatuses", "molocoEditedBids", "molocoEditedTargets", "molocoEditedExternalAudiences", "molocoEditedLandingUrls"]),
    publishProgressPercentage () {
      if (this.uploadProgress.toShow == false || this.uploadProgress.totalCount == -1) {
        return 0
      }
      if (this.uploadProgress.totalCount == 0) {
        return 100
      }
      let processedCount = this.uploadProgress.successCount + this.uploadProgress.failureCount
      return Math.round(processedCount / this.uploadProgress.totalCount * 100)
    },
    publishSuccessPercentage () {
      if (this.uploadProgress.totalCount == 0) {
        return 100
      }
      return Math.round(this.uploadProgress.successCount / this.uploadProgress.totalCount * 100)
    },
    publishTotalPercentage () {
      if (this.uploadProgress.totalCount == 0) {
        return 100
      }
      return Math.round((this.uploadProgress.successCount + this.uploadProgress.failureCount) / this.uploadProgress.totalCount * 100)
    },
    molocoFilteredPublishSummaryComputed () {
      var self = this;
      return this.molocoFilteredPublishSummary.map(item => {
        if (item.processingStatus === 8) {
          let messages = item.editedSummary.split(',');
          if (self.molocoEditedStatuses.includes(item.id) && !messages.includes('Status')) {
            messages.push('Status');
          }
          if (self.molocoEditedBids.includes(item.id) && !messages.includes('Bid')) {
            messages.push('Bid');
          }
          if (self.molocoEditedTargets.includes(item.id) && !messages.includes('Targets')) {
            messages.push('Targets');
          }
          if (self.molocoEditedExternalAudiences.includes(item.id) && !messages.includes('ExternalAudiences')) {
            messages.push('ExternalAudiences');
          }
          if (self.molocoEditedLandingUrls.includes(item.id) && !messages.includes('LandingUrl')) {
            messages.push('LandingUrl');
          }
          return { ...item, editedSummary: messages.join(', '), childEntities: self.updateAdgroupInfo(item.childEntities) };
        } else if (item.processingStatus === 2 || item.processingStatus === 7 || item.processingStatus === 3) {
          let messages = [];
          if (self.molocoEditedStatuses.includes(item.id)) {
            messages.push('Status');
          }
          if (self.molocoEditedBids.includes(item.id)) {
            messages.push('Bid');
          }
          if (self.molocoEditedTargets.includes(item.id)) {
            messages.push('Targets');
          }
          if (self.molocoEditedExternalAudiences.includes(item.id)) {
            messages.push('ExternalAudiences');
          }
          if (self.molocoEditedLandingUrls.includes(item.id)) {
            messages.push('LandingUrl');
          }
          return { ...item, processingStatus: messages.length > 0 ? 8 : item.processingStatus, processingMessage: messages.length > 0 ? "Edited" : item.processingMessage, editedSummary: messages.length > 0 ? messages.join(', ') : item.editedSummary, childEntities: self.updateAdgroupInfo(item.childEntities) };
        } else {
          return item;
        }
      });
    }
  },
  methods: {
    filterMolocoPublishSummary: function (summary) {
      clearTimeout(this.molocoPublishSummaryFilterTimeout);

      let query = this.molocoSummarySearchQuery.trim();
      const showAllStatus = this.molocoEntityStatusToShow.length == 0 || this.molocoEntityStatusToShow.includes("All");
      if (query == "" && showAllStatus) {
        this.molocoFilteredPublishSummary = summary;
        return;
      }

      const filterFn = (compareFn) => {
        return summary.flatMap((campaign) => {
          campaign = { ...campaign };

          campaign.childEntities = campaign.childEntities.flatMap((adgroup) => {
            adgroup = { ...adgroup };
            adgroup.childEntities = adgroup.childEntities.filter(compareFn);
            return adgroup.childEntities.length > 0 || compareFn(adgroup)
              ? [adgroup]
              : [];
          });

          return campaign.childEntities.length > 0 || compareFn(campaign)
            ? [campaign]
            : [];
        });
      };

      this.molocoPublishSummaryFilterTimeout = setTimeout(() => {
        if (query != "") {
          query = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // escape special chars in query (https://stackoverflow.com/a/6969486)
          const re = new RegExp(query, "i");
          summary = filterFn(entity => {
            const valuesToSearch = [entity.name, entity.id, entity.processingMessage];
            return valuesToSearch.some(value => re.test(value));
          })
        }
        if (!showAllStatus) {
          summary = filterFn(entity => this.molocoEntityStatusToShow.includes(this.getKeyByValue(entity.processingStatus)));
        }

        this.molocoFilteredPublishSummary = summary;
      }, 350);
    },
    toggleMolocoEntitiesToShow: function (status) {
      const selected = this.molocoEntityStatusToShow.includes(status);
      const count = this.molocoEntityStatusToShow.length;

      if (selected && count == 1) {
        return;
      }
      if (!selected) {
        if (status == "All") {
          this.molocoEntityStatusToShow = [];
        } else {
          this.molocoEntityStatusToShow = this.molocoEntityStatusToShow.filter(x => x != "All");
        }
        this.molocoEntityStatusToShow.push(status);
      } else {
        this.molocoEntityStatusToShow = this.molocoEntityStatusToShow.filter(x => x != status);
      }
    },
    getEntities: async function () {
      // clear existing edited campaigns info if present
      this.clearStoredCampaignsInfo();

      this.getEntitiesInProgress = true
      this.summaryCountResponse = {}
      this.entitiesResponse = []
      var entities = await ApiService.GetEntitiesData(deltax.businessProfileId, this.molocoCampaignLauncherPublishDataId, this.submissionId)
      this.entitiesResponse = entities.data.data.entities
      this.summaryCountResponse = entities.data.data.summary
      this.summaryPanelData = entities.data.data.entities

      this.getEntitiesInProgress = false
    },
    getEntityStatusIcon: function (status) {
      if (status == this.molocoEnums.entityStatus.Success) {
        return "fa fa-check-circle green";
      } else if (status == this.molocoEnums.entityStatus.Failed) {
        return "fa fa-exclamation-triangle red";
      } else if (status == this.molocoEnums.entityStatus.SuccessWithoutCreativeGroup) {
        return "fa fa-check-circle-o";
      } else if (status == this.molocoEnums.entityStatus.Edited) {
        return "fa fa-pencil-square yellow";
      } else if (status != this.molocoEnums.entityStatus.Failed && status != this.molocoEnums.entityStatus.Success) {
        return "fa fa-clock-o yellow";
      }
    },
    isEntityPublishedSuccessfully (status) {
      return [molocoEnums.entityStatus.Success, molocoEnums.entityStatus.SuccessWithoutCreativeGroup, molocoEnums.entityStatus.Edited].includes(status)
    },
    showProcessingMessage (status) {
      return [molocoEnums.entityStatus.Failed, molocoEnums.entityStatus.SuccessWithoutCreativeGroup, molocoEnums.entityStatus.Edited].includes(status)
    },
    customizeProcessingMessage (status, message, info) {
      if (status == molocoEnums.entityStatus.Edited) {
        return message + (info ? ": " + info.replaceAll(",", ", ") : "")
      }
      return message
    },
    getKeyByValue (value) {
      const entry = Object.entries(molocoEnums.entityStatus).find(([key, val]) => val === value);
      return entry ? entry[0] : null;
    },
    getPublishedStatus () {
      var self = this
      var published = Object.values(self.summaryCountResponse.published).reduce((acc, val) => acc + Number(val), 0)
      var failed = Object.values(self.summaryCountResponse.failed).reduce((acc, val) => acc + Number(val), 0);
      self.uploadProgress.successCount = published - self.inititalEntityState.success
      self.uploadProgress.failureCount = failed - self.inititalEntityState.failure
    },
    initializePublishStatus () {
      var total = Object.values(this.summaryCountResponse.total).reduce((acc, val) => acc + Number(val), 0)
      var published = Object.values(this.summaryCountResponse.published).reduce((acc, val) => acc + Number(val), 0)
      this.uploadProgress.totalCount = total - published;
      this.inititalEntityState.failure = 0;
      this.inititalEntityState.total = total;
      this.inititalEntityState.success = published;
    },
    clearStoredCampaignsInfo () {
      this.$store.state.molocoEditedStatuses = [];
      this.$store.state.molocoEditedBids = [];
      this.$store.state.molocoEditedTargets = [];
      this.$store.state.molocoEditedExternalAudiences = [];
      this.$store.state.molocoEditedLandingUrls = [];
    },
    updateAdgroupInfo (adgroups) {
      return adgroups.map(item => {
        if (item.searchEngineAdgroupID) {
          item.processingMessage = "";
          item.processingStatus = 2;
          return item;
        } else {
          return item;
        }
      });
    }
  },
  watch: {
    'viewType': async function (to, from) {
      if (to !== from) {
        await this.getEntities()
        this.initializePublishStatus()
      }
    },
    publishInProgress: async function (value) {
      var self = this
      if (value) {
        // setup interval
        self.publishInterval = setInterval(async function () {
          if (!self.getEntitiesInProgress) {
            await self.getEntities()
            self.getPublishedStatus()
          }
        }, 2000)
      } else {
        // call once more before stopping
        await self.getEntities()
        self.getPublishedStatus()
        clearInterval(self.publishInterval)
        self.publishInterval = null
      }
    },
    'renderSubmissionSummary': async function (value) {
      if (value) {
        await this.getEntities()
        this.initializePublishStatus()
      }
    }
  }
};
</script>
  <style scoped>
  .a-tag-disable {
    pointer-events: none !important;
    opacity: 0.6 !important;
    cursor: not-allowed !important;
  }
  ::v-deep .pt-0 {
    padding-top: 0;
  }
  ::v-deep .pt-15 {
    padding-top: 15px !important;
  }
  .error-message {
    white-space: pre-line;
    word-wrap: break-word;
  }
  .new-sheet {
    margin-left: 15px !important;
  }
  ::v-deep .table-bordered th {
    background-color: #eee;
    text-align: center;
    padding: 10px;
    min-width: 100px;
  }
  .campaign-summary {
    list-style: none;
    padding-left: 20px;
  }
  .adgroup-summary {
    list-style: none;
    padding-left: 35px;
  }
  .entity-export-status {
    float: right;
  }
  .entity-label {
    color: #979898;
  }
  .green {
    color: green;
  }
  .grey {
    color: grey;
  }
  .red {
    color: red;
  }
  .yellow {
    color: #ffc300;
  }
  .fa.fa-caret-right {
    color: #979898;
  }
  .summmary-list {
    padding-left: 0px;
  }
  .ad-summary-container {
    position: sticky;
    right: 20px;
    top: 65px;
    border: 1px solid #ddd;
    background-color: white;
    margin-top: 15px;
  }
  .ad-summary-container ul.summmary-list {
    height: 300px;
    overflow-y: auto;
    width: 100%;
  }
  .no-pointer {
    cursor: default;
  }
  .summary-collapse {
    cursor: pointer;
  }
  .summary-collapse:before {
    font-family: "FontAwesome";
    content: "\f0d7";
    color: grey;
    font-size: 1.3em;
    padding-right: 5px;
  }
  .summary-collapse.collapsed:before {
    content: "\f0da";
  }
  ::v-deep .dropdown-menu {
    overflow-y: auto;
    max-height: 380px;
  }
  .submission-box-row {
    margin-bottom: 10px;
  }
  .submission-dropdown {
    margin-right: 0 !important;
  }
  .comment-area {
    width: 100%;
    min-height: 200px;
    max-height: 350px;
  }
  .tab-controls {
    padding: 0px;
    border-bottom: none !important;
  }
  .tab-content {
    padding: 0px !important;
    margin-bottom: 0px !important;
  }
  .nav.nav-tabs {
    padding-top: 15px;
    top: 48px;
    background: white;
    z-index: 20;
  }
  .status-active-btn {
  background: #eaeeef;
}
.entity-export-error {
  color: grey;
}
</style>
