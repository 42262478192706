<template>
  <modal :value="isModalEnabled"  effect="fade">
    <div slot="modal-header" class="modal-header" style="padding-bottom: 0px">
      <button type="button" class="close" v-on:click="disableModal">
        <span>×</span>
      </button>
      <h4 class="modal-title">Generate Ship Key</h4>
    </div>
    <div slot="modal-body" class="row modal-body container ship-KeyModal" style="width:568px; padding:0px">
      <div class="col-md-6 no-padding" style="border-right: 2px solid #EEE;" >
        <div class="panel panel-default">
          <div style="margin-top: 20px">
            <div class="form-horizontal">
              <div class="form-group">
                <label class="control-label col-md-2" style="text-align:left">BpId<span class="f_req">&nbsp;*</span></label>
                <div class="col-md-5 .shipkey-bpId">
                  <input  class="form-control shipkey-bpId" v-model="bpId" type="number" min="0" v-bind:class="{showError:!validBp}"  @focus="validBp = true" style="3px" >
                </div>
                 <i class="fa fa-exclamation-circle tooltip"  @mouseover.stop="setTooltip" @mouseleave.stop="removeToltip" v-if="validBp==false" > </i><span class="tooltiptext">Invalid Business Profile</span>
              </div>
              <div class="form-group">
                <label class="control-label col-md-2">Users</label>
                <div class="col-md-10">
                  <textarea rows="3" cols="50"  class="form-control" placeholder="Enter e-mail id's" v-model="users" style="width:210px !important;height: 80px;overflow: hidden;resize: none;margin: 0px;">
                  </textarea>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-2">
               </div>
                <div class="col-md-10">
                  <button class="btn btn-success" id="generate-key" v-on:click="generateKey" :disabled="bpId==''" style="margin: 0px !important">Generate</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 no-padding">
        <div class="panel panel-default">
          <div class="panel-heading" style="padding-bottom: 1px">
            <h5 class="">Generated Ship Key({{keysList.length}})</h5>
          </div>
          <div style="overflow-y:auto;">
            <div  class="col-md-12" v-for="item in keysList" :key="item.Id">
              {{ item.ShipKey }}
              <div class="inline" style="float:right">
                <span type="button" v-bind:id="'ship-key-'+item.Id" v-on:click="deleteKey(item)" style="float-right" >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="modal-footer" class="modal-footer no-padding" style="display: none;">
    </div>
  </modal>
</template>
<script>

import { modal } from "vue-strap"
import { ApiService } from ".././Services/shipKeyApiService.js"
export default {

  components: {
    "modal": modal
  },
  data: function () {
    return {
      bpId: '',
      users: '',
      uniqueId: '',
      errorText: '',
      disableTextBox: false,
      isModalEnabled: false,
      keysList: [],
      validBp: true,
      lineItemId: 0
    };
  },

  watch: {
    isModalEnabled: function (newValue, oldValue) {
      // this.isModalEnabled=true;
    }
  },

  methods: {
    generateKey: function () {
      this.uniqueId = this.bpId + "_" + this.lineItemId + "_" + Math.random().toString(36).substr(2, 16);
      var keyInfo = {
        id: 0,
        destinationBpId: this.bpId,
        userEmail: this.users,
        shipKey: this.uniqueId,
        lineItemId: this.lineItemId,
        isEnabled: true
      }
      // api call to save genrated key in db
      var saveGeneratedKeyPromise = ApiService.SaveGeneratedKey(keyInfo);

      saveGeneratedKeyPromise
        .then(response => {
          if (response.Status == "Success") {
            this.keysList.push(response.Data);
            this.validBp = true;
          } else {
            this.errorText = response.ErrorMessage;
            this.validBp = false;
          }
        })
        .catch(error => {
          console.log("Error in Saving data" + error);
        });

      this.users = "";
      this.bpId = "";
    },

    disableModal: function () {
      this.users = "";
      this.bpId = "";
      this.isModalEnabled = false;
    },

    deleteKey: function (item) {
      var deleteKey = ApiService.DeleteKey(item);
      deleteKey.then(response => {
        var index = this.keysList.indexOf(item);
        if (index > -1) {
          this.keysList.splice(index, 1);
        }
      }).catch(error => {
        console.log("Error in deleting data" + error);

        // ajax call to delete the key from the db
        // remove the key from the array
      });
    },

    init: function () {
      this.keysList = [];
      var generatedKeys = ApiService.GetListGeneratedKeys(this.lineItemId);
      generatedKeys.then(response => {
        this.keysList = response;
      }).catch(error => {
        console.log("Error in deleting data" + error);
      });
    },
    setTooltip: function (e) {
      e.currentTarget.nextSibling.style.visibility = "visible";
      e.currentTarget.nextSibling.style.opacity = "1";
    },
    removeToltip: function (e) {
      e.currentTarget.nextSibling.style.opacity = "0";
      e.currentTarget.nextSibling.style.visibility = "hidden";
    },

    setShipKeyData: function (show, lineItemId) {
      this.isModalEnabled = show;
      this.lineItemId = lineItemId;
      this.init();
    }
  }
}
</script>

<style scoped="">
  div.panel.panel-default{
  border-left : 1px solid lightgray;
  padding-left: 5px;
  }

  .modal-body{
  padding-top: 0px!important;
  border : none!important;
  }

  div.panel.panel-default{
  border : none!important;
  border-left: px solid lightgray;
  }

  .modal-dialog{
  width: 700px;
  height: 300px;
  }
  .ship-KeyModal{
  padding-bottom:0px;
  }
  .showError{
      border-color:#b94a48;
  }
input.shipkey-bpId[type=number]::-webkit-inner-spin-button,
input.shipkey-bpId[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

input.shipkey-bpId[type=number] {
  -moz-appearance: textfield !important;
}
.tooltip {
    margin-left: auto;
    opacity: 1;
    z-index:1 !important;
    position:relative;
}

.tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: #000000;
    position: relative;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    margin-left:-35px;
    margin-top: -35px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 100%;
    margin-left: -60px;
    border-width: 5px;
    border-style: solid;
    border-color: #000000 transparent transparent transparent;
}
</style>
