import axiosInstance from '../Api'

let GlobalAPIService = {
  batchApiRequests: async (requests) => {
    return axiosInstance.post(`${deltax.batchApiUrl}batch`, {
      requests
    });
  }
}

export const AuthApiService = {
  login: function (url, deviceToken, data) {
    const headers = {
      'X-Device-Token': deviceToken,
      'Content-Type': 'application/json'
    }
    return axiosInstance.post(url, data, {
      headers: headers
    });
  },
  createSessionAction: function (url, data) {
    const headers = {
      'Content-Type': 'application/json'
    }
    return axiosInstance.post(url, data, {
      headers: headers
    })
  }
}

export { GlobalAPIService };
