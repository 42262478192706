<template>
    <i-modal ref="modal" class="modal_placement" :value="showModal"  width="50" scrollable :mask-closable="false" :closable="false">
        <div slot="header">
            <div class="modal-title"><b>Specifications</b></div>
            <div class="adtype-title">{{adTypeModalData.displayName}}</div>
        </div>
        <div class="spinner-box" v-show="loadingData">
                <i class="fa fa-3x fa-spinner fa-pulse spinner" aria-hidden="true"></i>
        </div>
        <div v-show="!loadingData">
          <div class="no-data-text" v-if="specifications.length <= 0"> No Data</div>
          <div class="form-body" v-else>
              <i-form ref="specifications" class="mainForm">
                <div v-for="(specGroup, specGroupIndex) in specifications" :key="specGroup.type">
                  <div class="specifications-header"><b>{{ specGroup.type }}</b></div>
                  <i-form-item
                  v-for="(spec, index) in specGroup.specifications" :key="specGroup.type + '-' + spec.name">
                    <i-row>
                        <i-col span="3">
                          <label class="input-label">{{spec.name}}<span class='asteriskColor' v-if="spec.isMandatory">*</span></label>
                        </i-col>
                        <i-col span="14" offset="1">
                            <i-input type="text" v-model="spec.value" placeholder="Enter something..."
                            :class="{'error-border': spec.hasError}"
                            @on-blur="validateSpecificationItem(index, specGroupIndex)"></i-input>
                        </i-col>
                    </i-row>
                  </i-form-item>
                </div>
              </i-form>
           </div>
        </div>
        <div slot="footer">
        <div>
          <button type="button" class="btn btn-white"
          @click="closeModal()" >Cancel</button>
          <button type="button" class="btn btn-success"
          :disabled="specifications.length <= 0"
          @click="saveSpecifications()">Save</button>
        </div>
      </div>
    </i-modal>
</template>

<script>
import { Modal, Form, Input, Row, Col, FormItem } from "iview";

export default {
  components: {
    "i-modal": Modal,
    "i-form": Form,
    "i-form-item": FormItem,
    "i-input": Input,
    "i-col": Col,
    "i-row": Row
  },
  props: {
    showModal: Boolean,
    specificationsData: Object,
    adTypeModalData: Object,
    view: String
  },
  data: function () {
    return {
      specifications: [],
      input: {},
      creative: {},
      loadingData: false,
      showActionModal: false,
      actionModalComment: "",
      actionModalType: "",
      creativeStatuses: [
        {
          label: "Submitted",
          value: 1
        },
        {
          label: "Accepted",
          value: 2
        },
        {
          label: "Rejected",
          value: 3
        },
        {
          label: "Flagged",
          value: 4
        }
      ]
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.setSpecificationsData();
      }
    }
  },
  computed: {
    modalTitle () {
      return "Specifications";
    }
  },
  methods: {
    setSpecificationsData () {
      var specifications = this.specificationsData;
      const specificationsObj = specifications;

      var adType = this.adTypeModalData;

      if (adType && adType.specifications && this.isValidJsonString(adType.specifications)) {
        const adTypeSpecifications = JSON.parse(adType.specifications);

        const resultantSpecifications = adTypeSpecifications.map(spec => {
          return {
            type: spec.type,
            specifications: spec.specifications.map(item => ({
              key: item.key,
              name: item.displayName,
              isMandatory: item.isMandatory,
              hasError: false,
              value: specificationsObj && specificationsObj[spec.type] && specificationsObj[spec.type][item.key] ? specificationsObj[spec.type][item.key] : '' // Value from specifications based on type and key
            }))
          };
        });

        this.specifications = [...resultantSpecifications];
      }
    },
    isValidJsonString (str) {
      if (typeof str !== 'string') return false;
      try {
        JSON.parse(str);
        return true;
      } catch {
        return false;
      }
    },
    closeModal () {
      this.specifications = [];
      this.$emit('saveSpecifications', 'closeOnly');
    },
    saveSpecifications () {
      let isValid = true;
      if (this.specifications.length <= 0) {
        isValid = false;
      }
      this.specifications.forEach((specGroup) => {
        specGroup.specifications.forEach((spec) => {
          if (spec.isMandatory &&
      (!spec.value || spec.value.trim() == "")) {
            spec.hasError = true;
            isValid = false
          } else {
            spec.hasError = false;
          }
        })
      });
      if (isValid) {
        var specifications = this.specifications;
        const result = specifications.reduce((acc, { type, specifications }) => {
          acc[type] = specifications.reduce((specObj, { key, value }) => {
            specObj[key] = value;
            return specObj;
          }, {});
          return acc;
        }, {});

        this.$emit('saveSpecifications', result);
        this.specifications = [];
      }
    },
    validateSpecificationItem (index, specGroupIndex) {
      const spec = this.specifications[specGroupIndex].specifications[index];
      if (spec.isMandatory &&
      (!spec.value || spec.value.trim() == "")) {
        spec.hasError = true;
      } else {
        spec.hasError = false;
      }
    }
  }
};
</script>

<style scoped>
.form-body {
  max-height: 400px;
  overflow: auto;
}

.no-data-text {
  text-align: center;
}

label {
  margin-left: 0px;
  float: right;
  color: #000000;
}

.asteriskColor {
  color: red;
}

.specifications-header {
  margin-left: 10px;
}

.btn-white {
  margin: 0px 10px;
}

::v-deep .error-border .ivu-input {
  border: 1px solid red !important;
}

::v-deep .ivu-form-item {
  margin-bottom: 12px;
}

</style>
