import { Objective } from "./Objective.js"
import * as fbEnums from "../../Constants/FacebookEnums";

let ColumnConfig = function (config) {
  let callToAction = ["Apply Now", "Download", "Get Quote", "Learn More", "Sign Up", "Subscribe", "Book Travel"];
  let singleBase = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Link", "HOT_Call To Action", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_Lead Form Id", "HOT_Ad Title", "HOT_Display Link", "HOT_Text", "HOT_Description", "HOT_Url Tags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations"];
  let singleRequiredBase = ["HOT_Ad Name", "HOT_Call To Action", "HOT_Image/Video", "HOT_Lead Form Id"];
  let carouselBase = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_See More Url", "HOT_Call To Action", "HOT_Optimize Card Order", "HOT_Lead Form Id", "HOT_Ad Title", "HOT_Text", "HOT_Description", "HOT_See More Display Url", "HOT_Url Tags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations"];
  let carouselRequiredBase = ["HOT_Ad Name", "HOT_Call To Action", "HOT_Lead Form Id"];

  if (config.adset.destination == fbEnums.destinationType.PhoneCall) {
    callToAction = ["Call Now"];
    customReplace(singleBase, "HOT_Lead Form Id", "HOT_Phone Number");
    customReplace(singleRequiredBase, "HOT_Lead Form Id", "HOT_Phone Number");
    customReplace(carouselBase, "HOT_Lead Form Id", "HOT_Phone Number");
    customReplace(carouselRequiredBase, "HOT_Lead Form Id", "HOT_Phone Number");
  }
  let single = {
    base: singleBase,
    requiredBase: singleRequiredBase,
    card: [],
    requiredCard: [],
    callToAction: callToAction,
    promoModeAutoFill: {
      "HOT_Lead Form Id": {
        macro: "{{store.leadFormId}}"
      }
    },
    placementCustomizationHeaders: ["HOT_Placements", "HOT_Image/Video", "HOT_Text", "HOT_Ad Title", "HOT_Link"],
    DynamicCreativeHeaders: ["HOT_Placements", "HOT_Image/Video", "HOT_Primary Text", "HOT_Description", "HOT_Headline"]
  };

  let carousel = {
    base: carouselBase,
    requiredBase: carouselRequiredBase,
    card: ["HOT_Link", "HOT_Headline", "HOT_Image/Video", "HOT_Description"],
    requiredCard: ["HOT_Link", "HOT_Image/Video"],
    callToAction: callToAction,
    promoModeAutoFill: {
      "HOT_Lead Form Id": {
        macro: "{{store.leadFormId}}"
      }
    }
  };

  return { single, carousel }
}

let CreativeSpec = function (config) {
  const isCallNowCTA = config.adset.destination == fbEnums.destinationType.PhoneCall;
  let single = new function () {
    this.image = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "link_data": {
            "call_to_action": {
              "type": isCallNowCTA
                ? "CALL_NOW"
                : "HOT_Call To Action",
              "value": isCallNowCTA
                ? { "link": "HOT_Phone Number" }
                : { "lead_gen_form_id": "HOT_Lead Form Id" }
            },
            "description": "HOT_Description",
            "picture": "HOT_Image/Video",
            "link": "HOT_Link",
            "message": "HOT_Text",
            "name": "HOT_Ad Title",
            "caption": "HOT_Display Link"
          }
        },
        "url_tags": "HOT_Url Tags"
      }
    }
    this.video = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "video_data": {
            "link_description": "HOT_Description",
            "image_url": "HOT_Image/Video",
            "video_id": "HOT_SearchEngineVideoId",
            "title": "HOT_Ad Title",
            "message": "HOT_Text",
            "call_to_action": {
              "type": isCallNowCTA
                ? "CALL_NOW"
                : "HOT_Call To Action",
              "value": isCallNowCTA
                ? { "link": "HOT_Phone Number" }
                : { "link": "HOT_Link", "lead_gen_form_id": "HOT_Lead Form Id" }
            }
          }
        },
        "url_tags": "HOT_Url Tags"
      }
    }
    this.dynamicCreative = {
      metadata: new function () {
        this.colToAssetSpecMap = {
          "HOT_Image/Video": "images",
          "HOT_Primary Text": "bodies",
          "HOT_Call To Action": "call_to_action_types",
          "HOT_Description": "descriptions",
          "HOT_Headline": "titles"
        };

        this.headlineRemap = {};
      }(),
      image: {
        creative: {
          object_story_spec: {
            page_id: "HOT_Page"
          },
          asset_feed_spec: {
            images: [ // required. Max: 10
              {
                url: "HOT_Image/Video"
              }
            ],
            bodies: [ // Max: 5
              {
                text: "HOT_Primary Text"
              }
            ],
            call_to_action_types: ["HOT_Call To Action"], // required. Max: 5
            call_to_actions: [
              {
                type: "HOT_Call To Action",
                value: {
                  lead_gen_form_id: "HOT_Lead Form Id"
                }
              }
            ],
            descriptions: [ // Max: 5
              {
                text: "HOT_Description"
              }
            ],
            link_urls: [
              {
                website_url: "HOT_Link", // required
                display_url: "HOT_Display Link"
              }
            ],
            titles: [ // Max: 5
              {
                text: "HOT_Headline"
              }
            ],
            ad_formats: ["SINGLE_IMAGE"] // required
          }
        }
      },
      video: {
        creative: {
          object_story_spec: {
            page_id: "HOT_Page"

          },
          asset_feed_spec: {
            videos: [ // required. Max: 10
              {
                video_id: "HOT_SearchEngineVideoId",
                thumbnail_url: "HOT_Image/Video"
              }
            ],
            bodies: [ // Max: 5
              {
                text: "HOT_Primary Text"
              }
            ],
            call_to_action_types: ["HOT_Call To Action"], // required. Max: 5
            descriptions: [ // Max: 5
              {
                text: "HOT_Description"
              }
            ],
            link_urls: [
              {
                website_url: "HOT_Link", // required
                display_url: "HOT_Display Link"
              }
            ],
            titles: [ // Max: 5
              {
                text: "HOT_Headline"
              }
            ],
            call_to_actions: [
              {
                type: "HOT_Call To Action",
                value: {
                  lead_gen_form_id: "HOT_Lead Form Id"
                }
              }
            ],
            ad_formats: ["SINGLE_VIDEO"] // required
          }
        }
      }
    }
  }();
  let carousel = {
    "creative": {
      "object_story_spec": {
        "page_id": "HOT_Page",
        "instagram_actor_id": "HOT_InstagramId",
        "link_data": {
          "message": "HOT_Text",
          "link": "HOT_See More Url",
          "caption": "HOT_See More Display Url",
          "child_attachments": [
            {
              "link": "HOT_Link",
              "picture": "HOT_Image/Video",
              "name": "HOT_Headline",
              "description": "HOT_Description",
              "call_to_action": {
                "type": isCallNowCTA
                  ? "CALL_NOW"
                  : "HOT_Call To Action",
                "value": isCallNowCTA
                  ? { "link": "HOT_Phone Number" }
                  : { "lead_gen_form_id": "HOT_Lead Form Id" }
              }
            }
          ],
          "multi_share_optimized": "HOT_Optimize Card Order",
          "call_to_action": {
            "type": isCallNowCTA
              ? "CALL_NOW"
              : "HOT_Call To Action",
            "value": isCallNowCTA
              ? { "link": "HOT_Phone Number" }
              : { "lead_gen_form_id": "HOT_Lead Form Id" }
          }
        }
      }
    }
  }

  return { single, carousel }
}

function customReplace (list, val, newVal) {
  list.forEach(function (item, i) { if (item == val) list[i] = newVal; });
}

let leadGeneration = new Objective(ColumnConfig, CreativeSpec)
export { leadGeneration }
