<template>
    <div class="cl-widget lead-forms-card">
      <Card>
        <div slot="title">
          <Row>
            <i-col span="8">
              <h4>
                Lead Forms
                <i v-if="showLoader" class="fa fa-spinner fa-spin" />
              </h4>
            </i-col>
            <i-col span="13" class="no-padding">
              <Input v-model="searchTerm" search placeholder="Search" class="search-bar input-search-bar" />
            </i-col>
            <i-col span="3">
              <button
                class="btn btn-success btn-sm pull-right"
                id="add-lead-form"
                @click="addLeadForm"
                :class="{'disabled':facebookAccountId == 0}"
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </i-col>
          </Row>
        </div>
        <VuePerfectScrollbar>
          <div v-if="isError">
            <div class="alert alert-danger error-message">
              There was a problem fetching the forms
            </div>
          </div>
          <div v-else>
            <div v-if="renderedForms.length == 0">
              <div class="alert alert-info no-forms-found-info" v-if="!isStringNullOrEmpty(searchTerm)">
                No forms found containing "{{ searchTerm }}"
              </div>
              <div class="alert alert-info no-forms-info" v-else>
                There are no forms yet, create one now
              </div>
            </div>
          </div>
          <div class="lead-forms-wrapper">
            <div class="lead-form" v-for="form in renderedForms" :key="form.Id">
              <Row>
                <i-col span="2" offset="1">
                  <i class="fas fa-copy action-icon"
                    @click="copyToClipboard(form.Id)">
                  </i>
                </i-col>
                <i-col>
                  <i-col span="20" offset="1" class="cl-widget-text">
                    <span class="lead-form-name">{{ form.Name }}</span>
                  </i-col>
                </i-col>
              </Row>
            </div>
          </div>
        </VuePerfectScrollbar>
      </Card>
    </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { FacebookSdk } from "../../../Plugins/facebooksdk.js";
import { APIService } from "./../ApiService.js";
import { Input, locale, Card, Row, Col } from "iview";
import lang from "iview/dist/locale/en-US";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import mixins from "../../../Mixins/copyToClipboard.js";
locale(lang);
export default {
  name: "LeadFormsWidget",
  mixins: [FacebookSdk.getMixin(Vue), mixins],
  props: {
    deltaxConfig: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    Input,
    Card,
    Row,
    "i-col": Col,
    VuePerfectScrollbar
  },
  data () {
    return {
      apiVersion: null,
      pageId: 0,
      facebookAccountId: 0,
      leadForms: [],
      searchTerm: null,
      showLoader: false,
      isError: false
    }
  },
  mounted () {
    // assign data properties
    let self = this;
    self.apiVersion = self.deltaxConfig.facebookApiVersion;
    self.pageId = self.selectedCampaignLauncherConfig.facebookPageId;
    self.facebookAccountId = self.selectedCampaignLauncherConfig.facebookAccountId;

    Vue.use(FacebookSdk, {
      appId: self.deltaxConfig.facebookAppId,
      version: self.apiVersion
    });

    self.getLeadForms();
  },
  computed: {
    ...mapState(["selectedCampaignLauncherConfig"]),
    renderedForms () {
      let searchTerm = this.searchTerm;
      let renderedForms = this.leadForms;
      if (!this.isStringNullOrEmpty(searchTerm)) {
        renderedForms = this.leadForms.filter(e => {
          return e.Name.toLowerCase().includes(searchTerm.toLowerCase());
        });
      }

      return renderedForms;
    }
  },
  methods: {
    isStringNullOrEmpty (word) {
      return word == null || word == "" || word == undefined;
    },
    addLeadForm () {
      let self = this;
      let options = {
        method: "lead_gen",
        page_id: self.pageId,
        ad_account_id: self.facebookAccountId
      };

      Vue.FB.ui(options, (response) => {
        self.getLeadForms();
      });
    },
    getLeadForms () {
      let self = this;
      self.showLoader = true;
      if (!isNaN(self.pageId)) {
        APIService.getLeadForms(self.facebookAccountId, self.pageId)
          .then(response => {
            self.leadForms = response.data;
            self.showLoader = false;
            self.isError = false;
          })
          .catch(() => {
            self.showLoader = false;
            self.isError = true;
            self.leadForms = [];
          });
      }
    }
  },
  watch: {
    selectedCampaignLauncherConfig: {
      handler (value) {
        this.facebookAccountId = value.facebookAccountId;
        this.pageId = value.facebookPageId;
        this.getLeadForms();
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.lead-forms-wrapper .lead-form {
  padding: 0.5rem 0rem;
}

.ps-container {
  min-height: 5rem;
  padding: 0.2rem 0rem;
  max-height: 15rem;
}
</style>

<style src="../../../Styles/dx-iview.css"></style>
