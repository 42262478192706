<template>
  <div>
    <div class="widget-btn-group">
      <button @click="showModal = isCreationSupported;" class="btn btn-success btn-sm" :class="{ 'btn-disabled': !isCreationSupported }">
        <i class="fa fa-plus"></i>
      </button>
      <button @click="downloadAdExtensions" class="btn btn-success btn-sm">
        <i v-if="downloading" class="fa fa-spinner fa-spin"></i>
        <i v-else class="fa fa-download"></i>
      </button>
    </div>
    <Modal class="ad-extension-bulk-creation-modal" title="Ad Extension Bulk Creation" v-model="showModal" effect="fade"
      width="95%" :scrollable="true" :styles="{ top: '20px' }" :closable="false" :mask-closable="false"
      @on-visible-change="onModalVisibleChange">
      <Tabs v-model="activeTab" :animated="false" type="card" class="tabs dx-tab-style hot-scroll">
        <TabPane class="tab-pane capitalize" v-for="(value, key) in adExtensionTabs" :key="key" :name="key"
          :label="value">
            <Button size="small" class="copy-headers" @click="copyHeaders(getDisplayColHeaders(key))">
              <i class="fa fa-copy" />
              <span> Copy Headers</span>
            </Button>
          <AdExtensionSheet :ref="`${value}HotTable`" :colHeaders="getColHeaders(key)"
            :displayColHeaders="getDisplayColHeaders(key)" :requiredColumns="getRequiredColumns(key)"
            :invalidRows="invalidRows[key]" />
        </TabPane>
      </Tabs>

      <div slot="footer">
        <button class="btn btn-white" @click="close" :disabled="showLoader">
          Close
        </button>
        <button class="btn btn-success" @click="save" :disabled="showLoader">
          <span>Save </span>
          <i v-if="showLoader" class="fa fa-spinner fa-spin" />
        </button>
      </div>
    </Modal>
  </div>
</template>
<script>
import lang from "iview/dist/locale/en-US";
import { Message, Button, Modal, Tabs, TabPane, locale } from "iview";
import { mapState } from "vuex";
import { APIService } from "../../ApiService.js";
import { adwordsEnums } from "../../Constants/AdwordsEnums";
import { adExtensionColumnsEnum, adExtensionColumns, adExtensionColumnFieldMapping, adExtensionPayloadKeyMapping, repeatingColumns } from "../../Configs/Adwords/AdExtension";

import AdExtensionSheet from "./AdExtensionSheet.vue";

locale(lang);

export default {
  components: { Button, Modal, Tabs, TabPane, AdExtensionSheet },
  props: {
    defaultTab: {
      type: String,
      required: true,
      default () { return `${adwordsEnums.adExtension.Call}`; }
    }
  },
  emits: ["save", "close"],
  data () {
    return {
      adExtensionColumns,
      showModal: false,
      showLoader: false,
      downloading: false,
      activeTab: `${adwordsEnums.adExtension.Call}`,
      adExtensionTabs: {
        [adwordsEnums.adExtension["Call"]]: "Call",
        [adwordsEnums.adExtension["SiteLink"]]: "SiteLink",
        [adwordsEnums.adExtension["Callout"]]: "Callout",
        [adwordsEnums.adExtension["Structured Snippet"]]: "Structured Snippet"
      },
      invalidAdExtensions: [],
      invalidRows: {
        [adwordsEnums.adExtension["Call"]]: [],
        [adwordsEnums.adExtension["SiteLink"]]: [],
        [adwordsEnums.adExtension["Callout"]]: [],
        [adwordsEnums.adExtension["Structured Snippet"]]: []
      }
    };
  },
  computed: {
    ...mapState(["googleAccounts", "selectedCampaignLauncherConfig"]),
    weekDays () {
      return Object.values(adwordsEnums.dayOfWeek);
    },
    timeRanges () {
      const timeRanges = []
      for (let hour = 0; hour < 24; hour++) {
        timeRanges.push(...['00', '15', '30', '45'].map(minute => `${hour}:${minute}`));
      }

      return timeRanges;
    },
    isCreationSupported () {
      return this.selectedCampaignLauncherConfig && this.selectedCampaignLauncherConfig.campaign && this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType["Performance Max"];
    }
  },
  methods: {
    showMessage (message, type = "success") {
      Message.destroy();
      Message[type](message);
    },
    getKeyByValue (object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    getColHeaders (adExtension) {
      return adExtensionColumns[adExtension].columns.map(e => `HOT_${e}`);
    },
    getDisplayColHeaders (adExtension) {
      return adExtensionColumns[adExtension].columns;
    },
    getRequiredColumns (adExtension) {
      return adExtensionColumns[adExtension].required;
    },
    copyHeaders (headers) {
      const textarea = document.createElement("textarea");
      textarea.textContent = headers.filter(e => e != adExtensionColumnsEnum.IsValid).join("\t");
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        this.showMessage("Headers copied to clipboard.", "success");
      } catch (e) {
        this.showMessage("Unable to copy to clipboard. Please copy manually.", "error");
      } finally {
        document.body.removeChild(textarea);
      }
    },
    isDateSupported (adExtension) {
      return adExtension == `${adwordsEnums.adExtension["SiteLink"]}` || adExtension == `${adwordsEnums.adExtension["Callout"]}`;
    },
    isAdScheduleSupported (adExtension) {
      return adExtension == `${adwordsEnums.adExtension["Call"]}` || adExtension == `${adwordsEnums.adExtension["SiteLink"]}` || adExtension == `${adwordsEnums.adExtension["Callout"]}`;
    },
    onModalVisibleChange (value) {
      if (!value) return;

      this.activeTab = Object.keys(this.adExtensionTabs).includes(this.defaultTab) ? this.defaultTab : `${adwordsEnums.adExtension.Call}`;
    },
    getFileNameFromResponseHeader (headers, defaultFileName = null) {
      if (!headers || !headers["content-disposition"]) return defaultFileName;

      const fileNameHeader = headers["content-disposition"].split(";").map(e => e.trim().split("=")).find(e => e.length == 2 && e[0] == "filename");

      if (!fileNameHeader) return defaultFileName;

      return fileNameHeader[1];
    },
    async downloadAdExtensions () {
      if (this.downloading) return;

      try {
        this.downloading = true;

        const response = await APIService.downloadAdExtensions(this.selectedCampaignLauncherConfig.bpseId);
        const fileName = this.getFileNameFromResponseHeader(response.headers, "adextensions.csv");
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } catch (error) {
        console.error(error);
        this.showMessage("Something went wrong while processing your request, Please try again.", "error");
      } finally {
        this.downloading = false;
      }
    },
    close (force = false) {
      if (!force && this.showLoader) return;

      this.showModal = false;
      this.$emit("close");
    },
    async save () {
      if (this.showLoader) return;

      try {
        this.showLoader = true;
        this.invalidAdExtensions = [];

        const payload = {};

        Object
          .keys(this.adExtensionTabs)
          .forEach(adExtension => {
            const tab = this.adExtensionTabs[adExtension];
            const tabInstances = this.$refs[`${tab}HotTable`];
            const requiredColumns = this.getRequiredColumns(adExtension);

            if (!tabInstances || tabInstances.length == 0) return;

            this.invalidRows[adExtension] = [];

            tabInstances.forEach(instance => {
              if (!adExtensionPayloadKeyMapping[adExtension]) return;

              const tabData = instance.getData();

              const adExtensionsData = tabData.map(dataObj => {
                let isValid = requiredColumns.every(col => dataObj[col]);
                const transformedObject = Object
                  .keys(dataObj)
                  .reduce((obj, e) => {
                    if (adExtensionColumnFieldMapping[e]) {
                      const key = adExtensionColumnFieldMapping[e];
                      let value = dataObj[e];

                      if (e == adExtensionColumnsEnum.Account) {
                        const account = this.googleAccounts.find(account => value == `${account.NickName} (${account.CustomerId})`);

                        value = account.Id;
                      }

                      obj[key] = value;
                    }

                    return obj;
                  }, {});

                if (this.isDateSupported(adExtension)) {
                  const startDate = dataObj[adExtensionColumnsEnum.StartDate] ? new Date(dataObj[adExtensionColumnsEnum.StartDate]) : null;
                  const endDate = dataObj[adExtensionColumnsEnum.EndDate] ? new Date(dataObj[adExtensionColumnsEnum.EndDate]) : null;

                  if (startDate instanceof Date && endDate instanceof Date && (startDate < Date.today() || endDate < Date.today() || startDate > endDate)) {
                    isValid = false;
                  }
                }

                if (this.isAdScheduleSupported(adExtension)) {
                  transformedObject[adExtensionColumnFieldMapping.AdScheduleTargets] = [];

                  for (let i = 1; i <= repeatingColumns.schedulingColumnsCount; i++) {
                    const schedule = [dataObj[`${adExtensionColumnsEnum.Day} ${i}`], dataObj[`${adExtensionColumnsEnum.StartTime} ${i}`], dataObj[`${adExtensionColumnsEnum.EndTime} ${i}`]];

                    if (schedule.every(e => !e)) continue;
                    else if (schedule.some(e => !e)) {
                      isValid = false;
                      continue;
                    }

                    const isValidDay = this.weekDays.includes(schedule[0]);
                    const isValidStartTime = this.timeRanges.includes(schedule[1]);
                    const isValidEndTime = this.timeRanges.includes(schedule[2]);

                    isValid = isValid && isValidDay && isValidStartTime && isValidEndTime;

                    const [startHour, startMinute] = schedule[1].split(":");
                    const [endHour, endMinute] = schedule[2].split(":");

                    const adScheduleTarget = {
                      startHour: Number(startHour),
                      endHour: Number(endHour),
                      startMinute: adwordsEnums.minuteOfHour[startMinute],
                      endMinute: adwordsEnums.minuteOfHour[endMinute],
                      dayOfWeek: Number(this.getKeyByValue(adwordsEnums.dayOfWeek, schedule[0]))
                    };

                    if (adScheduleTarget.startHour > adScheduleTarget.endHour || (adScheduleTarget.startHour == adScheduleTarget.endHour && adScheduleTarget.startMinute >= adScheduleTarget.endMinute)) {
                      isValid = false;
                    }

                    transformedObject[adExtensionColumnFieldMapping.AdScheduleTargets].push(adScheduleTarget);
                  }
                }

                if (adExtension == `${adwordsEnums.adExtension["Call"]}`) {
                  const countryCode = dataObj[adExtensionColumnsEnum.CountryCode];

                  isValid = isValid && countryCode && adwordsEnums.countryCodes.includes(countryCode);
                }

                if (adExtension == `${adwordsEnums.adExtension["SiteLink"]}`) {
                  transformedObject[adExtensionColumnFieldMapping.CustomParameters] = [];

                  for (let i = 1; i <= repeatingColumns.siteLinkCustomParameterColumnsCount; i++) {
                    const customParameter = [dataObj[`${adExtensionColumnsEnum.Key} ${i}`], dataObj[`${adExtensionColumnsEnum.Value} ${i}`]];

                    if (customParameter.every(e => !e)) continue;
                    else if (customParameter.some(e => !e)) {
                      isValid = false;
                      continue;
                    }

                    const keyRegex = new RegExp("^[a-zA-Z0-9]*$");

                    isValid = isValid && customParameter[0] && customParameter[0].match(keyRegex);

                    transformedObject[adExtensionColumnFieldMapping.CustomParameters].push({
                      key: customParameter[0],
                      value: customParameter[1]
                    });
                  }

                  if ((dataObj[adExtensionColumnsEnum.Description1] && !dataObj[adExtensionColumnsEnum.Description2]) || (!dataObj[adExtensionColumnsEnum.Description1] && dataObj[adExtensionColumnsEnum.Description2])) {
                    isValid = false;
                  }
                }

                if (adExtension == `${adwordsEnums.adExtension["Structured Snippet"]}`) {
                  transformedObject[adExtensionColumnFieldMapping.Values] = [];

                  for (let i = 1; i <= repeatingColumns.structuredSnippetValueColumnsCount; i++) {
                    if (dataObj[`${adExtensionColumnsEnum.Value} ${i}`]) transformedObject[adExtensionColumnFieldMapping.Values].push(dataObj[`${adExtensionColumnsEnum.Value} ${i}`]);
                  }
                }

                if (!isValid) {
                  this.invalidRows[adExtension].push(dataObj.row);
                }

                return transformedObject;
              });

              if (this.invalidRows[adExtension].length > 0) {
                this.invalidAdExtensions.push(tab);
                return;
              }

              payload[adExtensionPayloadKeyMapping[adExtension]] = adExtensionsData;
            });
          });

        if (this.invalidAdExtensions.length > 0) {
          this.showMessage(`Invalid AdExtensions for ${this.invalidAdExtensions.join(", ")}`, "info");
          this.showLoader = false;
          return;
        } else if (Object.values(payload).every(e => !(e instanceof Array) || e.length == 0)) {
          this.showMessage("No Ad Extensions to save", "info");
          this.showLoader = false;
          return;
        }

        await APIService.bulkCreateAdExtensions(payload);

        Object
          .values(this.adExtensionTabs)
          .forEach(tab => {
            const tabInstances = this.$refs[`${tab}HotTable`];

            tabInstances.forEach(instance => instance.clearHotData());
          });

        this.showMessage("Ad Extensions saved successfully", "success");
        this.$emit("save");
        this.close(true);
      } catch (error) {
        console.error(error);

        const isApiErrorMessage = error && error.response && error.response.data && error.response.data.errorCode && error.response.data.message;

        this.showMessage(isApiErrorMessage ? error.response.data.message : "Something went wrong while processing your request, Please try again.", "error");
      } finally {
        this.showLoader = false;
      }
    }
  }
};
</script>
<style src="../../../../Styles/dx-iview.css"></style>
<style scoped>
.ad-extension-bulk-creation-modal ::v-deep .ivu-modal-body {
  height: 80vh;
  overflow: auto;
}

.close-btn {
  cursor: pointer;
}

.btn {
  margin: 0px 0px 0px 15px;
}

.widget-btn-group {
  margin-inline: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.widget-btn-group .btn {
  margin: 0;
}

.widget-btn-group .fa {
  font-size: inherit !important;
}

.capitalize:first-letter {
  text-transform: capitalize;
}
.copy-headers {
  position: absolute;
  right: 1rem;
  top: 0rem;
  z-index: 2;
}
</style>
