<template>
  <p @click="setActiveIndex" :class="{ active: currentIndex == step - 1 }">
    <slot></slot>
  </p>
</template>

<script>
import EventBus from './event-bus.js';

export default {
  props: ["step", "fixed"],
  emits: ["fixedBlock"],
  data(){
    return {
      currentIndex : null,
    };
  },
  methods: {
    setActiveIndex() {
      if (!this.fixed){
        EventBus.$emit("showFixedBlock", false);
        EventBus.$emit("setNavActive", this.step - 1);
        EventBus.$emit("setScrollActive", this.step - 1, true);
      }
      else {
        EventBus.$emit("showFixedBlock", true);
        EventBus.$emit("setNavActive", this.step - 1);
      }
    },
  },
  mounted(){
    EventBus.$on("setNavActive", (data) => {
        this.currentIndex = data;
    });
  },
};
</script>

<style scoped>
p {
  color: #2c2e2f;
  font-size: 15px;
  border-bottom: 2px solid #f5f5f5;
  margin-top: 15px;
  cursor: pointer;
}
.active {
  color: var(--main-bg-color);
  border-bottom-color: var(--main-bg-color);
}
</style>
