<template>
  <div>
     <ul class="summmary-list">
      <li v-for="(campaign,campaignIndex) in computedPublishSummary" :key="campaignIndex">
        <div
          data-toggle="collapse"
          class="summary-collapse"
          v-bind:href="'#campaign-summary-' + campaignIndex"
        >
          <span>
            <i
              v-bind:class="getEntityStatusIcon(campaign.status)"
            ></i>
            <span>
              {{ campaign.name }}
            </span>
            <span v-if="campaign.id != ''" class="entity-label">
              ({{ campaign.id }})
            </span>
          </span>
          <span
            class="entity-export-error"
            v-if="campaign.failureReason != ''"
          >{{getFailureReasonFromList(campaign.failureReason)}}</span>
        </div>
        <ul
          v-bind:id="'campaign-summary-' + campaignIndex"
          class="collapse-open collapse in campaign-summary"
        >
          <li
            class="panel-collapse"
            v-for="(adgroup,adgroupIndex) in campaign.childEntities"
            :key="adgroupIndex"
          >
            <div
              data-toggle="collapse"
              class="summary-collapse collapsed"
              :href="`#adgroup-summary-${campaignIndex}-${adgroupIndex}`"
            >
              <span>
                <i v-bind:class="getEntityStatusIcon(adgroup.status)"></i>
                <span>
                  {{ adgroup.name }}
                </span>
                <span v-if="adgroup.id != ''" class="entity-label">
                  ({{ adgroup.id }})
                </span>
              </span>
              <span
                class="entity-export-error"
                v-if="adgroup.failureReason != ''"
              >{{getFailureReasonFromList(adgroup.failureReason)}}</span>
            </div>
            <ul
              :id="`adgroup-summary-${campaignIndex}-${adgroupIndex}`"
              class="collapse-open collapse adgroup-summary"
            >
              <li
                class="panel-collapse"
                v-for="(ad,adIndex) in adgroup.childEntities"
                :key="adIndex"
              >
                <div>
                  <span>
                    <i v-bind:class="getEntityStatusIcon(ad.status)" class="no-pointer"></i>
                    <span>
                      {{ ad.rowData.adName }}
                    </span>
                    <span v-if="ad.id != ''" class="entity-label">
                      ({{ ad.id }})
                    </span>
                  </span>
                  <span
                    class="entity-export-error"
                    v-if="ad.failureReason != ''"
                  >{{getFailureReasonFromList(ad.failureReason)}}</span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  data: function () {
    return {
      exportStatus: {
        "Success": 8,
        "Failed": 10,
        "New": 1
      }
    };
  },
  computed: {
    ...mapState(["twitterEntitiesResponse"]),
    computedPublishSummary: function () {
      return this.twitterEntitiesResponse.data.childEntities;
    }
  },
  methods: {
    getEntityStatusIcon: function (status) {
      if (status == this.exportStatus.Success) {
        return "fa fa-check-circle green";
      } else if (status == this.exportStatus.Failed) {
        return "fa fa-exclamation-triangle red";
      } else if (status != this.exportStatus.Failed && status != this.exportStatus.Success) {
        return "fa fa-clock-o yellow";
      }
    },
    getFailureReasonFromList (failureReason) {
      if (failureReason && failureReason != '') {
        try {
          var failureObject = JSON.parse(failureReason)
          return failureObject.map(x => x.message).join(", ")
        } catch (e) {
          return failureReason
        }
      }
      return failureReason
    }
  }
};
</script>

<style scoped>
.a-tag-disable {
  pointer-events: none !important;
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}
::v-deep .pt-0 {
  padding-top: 0;
}
::v-deep .pt-15 {
  padding-top: 15px !important;
}
.error-message {
  white-space: pre-line;
  word-wrap: break-word;
}
.new-sheet {
  margin-left: 15px !important;
}
::v-deep .table-bordered th {
  background-color: #eee;
  text-align: center;
  padding: 10px;
  min-width: 100px;
}
.campaign-summary {
  list-style: none;
  padding-left: 20px;
}
.adgroup-summary {
  list-style: none;
  padding-left: 35px;
}
.entity-export-status {
  float: right;
}
.entity-label {
  color: #979898;
}
.green {
  color: green;
}
.grey {
  color: grey;
}
.red {
  color: red;
}
.yellow {
  color: #ffc300;
}
.fa.fa-caret-right {
  color: #979898;
}
.summmary-list {
  padding-left: 0px;
}
.ad-summary-container {
  position: sticky;
  right: 20px;
  top: 65px;
  border: 1px solid #ddd;
  background-color: white;
  margin-top: 15px;
}
.ad-summary-container ul.summmary-list {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}
.no-pointer {
  cursor: default;
}
.summary-collapse {
  cursor: pointer;
}
.summary-collapse:before {
  font-family: "FontAwesome";
  content: "\f0d7";
  color: grey;
  font-size: 1.3em;
  padding-right: 5px;
}
.summary-collapse.collapsed:before {
  content: "\f0da";
}
::v-deep .dropdown-menu {
  overflow-y: auto;
  max-height: 380px;
}
.submission-box-row {
  margin-bottom: 10px;
}
.submission-dropdown {
  margin-right: 0 !important;
}
.comment-area {
  width: 100%;
  min-height: 200px;
  max-height: 350px;
}
.tab-controls {
  padding: 0px;
  border-bottom: none !important;
}
.tab-content {
  padding: 0px !important;
  margin-bottom: 0px !important;
}
.nav.nav-tabs {
  padding-top: 15px;
  top: 48px;
  background: white;
  z-index: 20;
}
</style>
