import axiosInstance from '../../../Api'

export class DivisionApiService {
  getDivisions (businessProfileId) {
    // eslint-disable-next-line no-undef
    const divisionsUrl = apiBaseUrl
      .concat(`businessProfiles/${businessProfileId}/divisions`);
    var promise = axiosInstance({
      headers: { "X-Bypass-Login": "true" },
      method: "GET",
      url: divisionsUrl
    });
    return promise;
  }
  getAccess (businessProfileId, userId) {
    // eslint-disable-next-line no-undef
    const getAccessUrl = apiBaseUrl
      .concat(`users/${userId}/businessProfiles/${businessProfileId}/`);
    const promise = axiosInstance.get(getAccessUrl);
    return promise;
  }

  updateAccess (businessProfileId, userId, properties) {
    // eslint-disable-next-line no-undef
    const updateAccessUrl = apiBaseUrl
      .concat(`users/${userId}/businessProfiles/${businessProfileId}/`);
    const promise = axiosInstance.patch(updateAccessUrl, properties);
    return promise;
  }
}
