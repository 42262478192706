
import axiosInstance from '../../Api'

export class ApiService {
  SaveComment (dateSelected, topicData, labelsSelected, note) {
    // eslint-disable-next-line no-undef
    const saveCommentUrl = secretKeyParams.apiBaseUrl
    // eslint-disable-next-line no-undef
      .concat(`businessProfiles/${secretKeyParams.businessProfileId}/`)
      .concat(`DashboardComments`)
    const promise = axiosInstance.post(saveCommentUrl,
      // eslint-disable-next-line no-undef
      { Date: dateSelected, Topic: topicData, Label: labelsSelected, Comment: note, UserId: loggedInUserId, UserName: loggedInUserName }
    )
    return promise
  }
  GetLabelsData () {
    // eslint-disable-next-line no-undef
    const getLabelsDataUrl = secretKeyParams.apiBaseUrl
    // eslint-disable-next-line no-undef
      .concat(`businessProfiles/${secretKeyParams.businessProfileId}/`)
      .concat(`Labels`)

    const promise = axiosInstance.get(getLabelsDataUrl)
    return promise
  }
  GetLabelTypesData () {
    // eslint-disable-next-line no-undef
    const getLabelTypesDataUrl = secretKeyParams.apiBaseUrl
    // eslint-disable-next-line no-undef
      .concat(`businessProfiles/${secretKeyParams.businessProfileId}/`)
      .concat(`LabelTypes`)

    const promise = axiosInstance.get(getLabelTypesDataUrl)
    return promise
  }
  allCommentsPage () {
    // eslint-disable-next-line no-undef
    const allCommentsPageUrl = secretKeyParams.allCommentsPageUrl
    const promise = axiosInstance.get(allCommentsPageUrl)
    return promise
  }
  getCommentById (commentId) {
    // eslint-disable-next-line no-undef
    const getCommentByIdUrl = secretKeyParams.apiBaseUrl
    // eslint-disable-next-line no-undef
      .concat(`businessProfiles/${secretKeyParams.businessProfileId}/`)
      .concat(`DashboardComments/${commentId}`)

    const promise = axiosInstance.get(getCommentByIdUrl)
    return promise
  }
  UpdateComment (dateSelected, topicData, labelsSelected, note, oldcommentId) {
    // eslint-disable-next-line no-undef
    const updateCommentUrl = secretKeyParams.apiBaseUrl
      // eslint-disable-next-line no-undef
      .concat(`businessProfiles/${secretKeyParams.businessProfileId}/`)
      .concat(`DashboardComments`)

    const promise = axiosInstance.put(updateCommentUrl,
      // eslint-disable-next-line no-undef
      { Date: dateSelected, Topic: topicData, Label: labelsSelected, Comment: note, Id: oldcommentId, UserId: loggedInUserId, UserName: loggedInUserName }
    )
    return promise
  }
}
