export default {
  methods: {
    copyToClipboard (value) {
      // create a new element
      var textArea = document.createElement("textarea");

      // set value and position
      textArea.value = value;
      textArea.style.position = "absolute";
      textArea.style.left = "-9999px";

      // append it to body
      document.body.appendChild(textArea);

      // select content and copy
      textArea.select();
      document.execCommand("copy");

      // remove element
      textArea.remove();
    }
  }
}
