import { Objective } from "./Objective.js";

let ColumnConfig = {
  video: {
    base: ["HOT_Is Valid", "HOT_Ad Name", "HOT_Video", "HOT_Landing Page URL", "HOT_Skip Button", "HOT_Companion Creative", "HOT_Target"],
    requiredBase: ["HOT_Ad Name", "HOT_Video"],
    card: [],
    requiredCard: [],
    callToAction: []
  }
}
let CreativeSpec = {
  video: {}
}

let video = new Objective(ColumnConfig, CreativeSpec)
export { video }
