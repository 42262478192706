<template>
  <div>
    <Dropdown trigger="click" v-on:on-click="loadTargetCreation" placement="bottom-end" transfer>
      <button
              class="btn btn-success btn-sm widget-element pull-right"
            >
      <i class="fa fa-plus"></i>
      </button>
      <DropdownMenu slot="list">
        <template v-for="searchEngine in accounts">
          <DropdownItem :name="searchEngine.Id" :disabled="searchEngine.IsOauthExpired" :key="searchEngine.Id">{{ searchEngine.NickName }}&nbsp;({{searchEngine.AccountId}})</DropdownItem>
        </template>
      </DropdownMenu>
    </Dropdown>
    <TargetCreationWrapper
      v-if="!deltax.isOldTargetCreationEnabled && selectedBpseId > 0"
      :showModal="showModal"
      :selectedBpseId="selectedBpseId"
      :accounts="accounts"
      @update-bpseId="handleUpdateBpseId"
      @close-modal="close()"
    ></TargetCreationWrapper>
    <v-modal
        v-else
        class="target-modal"
        title="Create Target"
        v-model="showModal"
        @closed="close()"
        effect="fade"
      >
        <div slot="modal-header" class="modal-header">
          <div class="header-style">
            <span>
              <h4 class="modal-title">Create Target</h4>
              <span v-if="!iFrameloaded">
                <i class="fa fa-spinner fa-spin fa-lg" />
              </span>
            </span>
            <span @click="showModal=false">
              <i class="fa fa-times fa-lg" />
            </span>
          </div>
        </div>
        <div
          slot="modal-body"
          class="modal-body targets-body"
        >
          <iframe
            id="ifr"
            :src="iframeUrl"
            width="100%"
            height="98%"
            marginwidth="0"
            marginheight="0"
            frameborder="no"
            scrolling="yes"
            style="background:#FFF;"
            @load="onIFrameLoad"
            v-show="iFrameloaded"
          ></iframe>
        </div>
        <div slot="modal-footer" class="modal-footer hide"></div>
    </v-modal>
  </div>
</template>
<script>
import { APIService } from "./../ApiService.js";
import { modal } from "vue-strap";
import { mapState, mapMutations } from "vuex";
import { Dropdown, DropdownMenu, DropdownItem } from "iview";
import TargetCreationWrapper from "./Facebook/TargetCreationWrapper.vue";
import targetHTMLGenerator from "../../../Mixins/targetStringToHTML.js";
import { EventBus } from "../EventBus.js";

export default {
  name: "TargetWidget",
  mixins: [targetHTMLGenerator],
  components: {
    "v-modal": modal,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    TargetCreationWrapper
  },
  data: function () {
    return {
      search: "",
      showModal: false,
      loader: false,
      socialTargetRequestData: {},
      socialTargets: [],
      iFrameloaded: false,
      iframeUrl: "",
      selectedBpseId: 0,
      targetMode: "New",
      savedTargets: []
    };
  },
  props: {
    getAudiences: { type: String, default: "" },
    createTarget: { type: String, default: "" },
    targets: { type: Array, default: () => { return [] } }
  },
  methods: {
    getSocialTargets () {
      var self = this;
      self.loader = true;
      if (this.businessProfilesSearchEngineId == 0) {
        self.loader = false;
        return;
      }
      APIService.GetSocialTargets(
        this.getAudiences,
        this.socialTargetRequestData
      )
        .then(response => {
          return response.json().then(json => {
            for (var i = 0; i < json.data.length; i++) {
              json.data[i]["generatedTarget"] = self.generateTargetStringHtml(
                JSON.parse(json.data[i]["TargetInformation"]),
                false,
                false
              );
            }
            return json.data;
          });
        })
        .then(data => {
          self.loader = false;
          this.socialTargets = data;
        });
    },
    onIFrameLoad () {
      if (this.showModal) {
        this.iFrameloaded = true;
      }
    },
    loadTargetCreation (bpseId) {
      const selectedAccount = this.accounts.find(account => account.Id === bpseId);
      if (!selectedAccount.IsOauthExpired) {
        this.showModal = true;
        this.selectedBpseId = bpseId
        this.iframeUrl = this.createTarget.replaceAll('amp;', '') + bpseId
        this.$store.dispatch("generate_TargetAudience");
      }
    },
    close () {
      // check if there are any newly created targets after closing. If yes, they need to be added to store
      let self = this;
      this.showModal = false;
      if (window.campaignLauncherSavedTargets && window.campaignLauncherSavedTargets.length > 0) {
        let savedTargetsMapperByBpseId = this.savedTargetsMapperByBpseId;
        let newTargets = window.campaignLauncherSavedTargets;
        newTargets.forEach(e => {
          if (!savedTargetsMapperByBpseId.hasOwnProperty(self.selectedBpseId)) {
            this.$set(savedTargetsMapperByBpseId, self.selectedBpseId, [])
          }
          savedTargetsMapperByBpseId[self.selectedBpseId].push({ Id: e.id, Name: e.name, generatedTarget: this.generateTargetStringHtml(JSON.parse(e.targetInformation), false, false), TargetInformation: e.targetInformation, EstimatedReach: e.estimatedReach });
        })
        let seenLabels = new Set();
        window.campaignLauncherNewLabels.forEach(label => {
          if (!seenLabels.has(label.Name)) { // prevents adding dublicate labels.
            seenLabels.add(label.Name);
            this.labels.push(label);
          }
        })
        window.campaignLauncherNewLabels = [];
        window.campaignLauncherSavedTargets = [];
        this.set_savedTargetsMapperByBpseId(savedTargetsMapperByBpseId);
        EventBus.$emit("facebookTargetCreation-refreshTargetsForBpse", self.selectedBpseId);
        this.getSocialTargets();
      }
    },
    handleUpdateBpseId (bpseId) {
      this.selectedBpseId = bpseId;
    },
    ...mapMutations(["set_savedTargetsMapperByBpseId"])
  },
  mounted () {
    this.$nextTick(() => {
      this.socialTargets = this.targets;
      this.setSocialTargetRequestData();
      this.getSocialTargets();
    });
  },
  computed: {
    ...mapState(["businessProfilesSearchEngineId", "businessProfileId", "savedTargetsMapperByBpseId", "labels", "campaignLauncherConfigBpseIdMapper", "accounts", "facebookTargetLocationData", "facebookTargetCreationData", "facebookFlexibleSpecData", "deltax", "facebookTargetMacroName"]),
    filteredTargets () {
      let searchTerm = this.search;
      if (searchTerm != "") {
        return this.socialTargets.filter(e => {
          return e.Name.toLowerCase().includes(searchTerm.toLowerCase())
        })
      } else {
        return this.socialTargets;
      }
    }
  },
  watch: {
    selectedBpseId () {
      this.iFrameloaded = false;
      this.setSocialTargetRequestData();
      this.getSocialTargets();
      // console.log(this.selectedBpseId);
      this.$store.commit('set_selectedBpseId', this.selectedBpseId);
    }
  }
};
</script>
<style scoped>
::v-deep .ivu-modal-close .ivu-icon-ios-close {
  display: none !important;
}
::v-deep .fa-lg {
    font-size: 1em;
    line-height: .75em;
    vertical-align: -15%;
}
::v-deep .ivu-select-item-focus {
    background: none;
}
.widget-body > table {
  width: 100%;
}
.widget-body > table > tr > td {
  padding-top: 10px;
}
.widget-element {
  height: 30px;
}
.widget > hr {
  margin: 0px;
}
.loader-div {
  width: inherit;
  text-align: center;
  height: inherit;
}
.header-style {
  display:flex;
  justify-content: space-between;
}
.modal-title {
  display: inline-block;
  margin-right:10px
}
.target-mode {
  display: flex;
  margin-left: 30px;
  font-size: 13px !important;
}
.select-target {
  width: 400px;
  display: block;
}
.label-names {
  color: #878787;
}
::v-deep .ivu-radio-wrapper {
  font-size: 13px;
  color: #878787;
}
::v-deep .ivu-select-item {
  font-size: 13px !important;
}
.fixed-column {
  position: sticky;
  top: 0;
}
</style>

<style>
.ivu-modal{
  top: 15px;
}
.modal-header {
    padding: 15px;
    border: none;
    min-height: 16.43px;
}
.target-tooltip .tooltip .tooltip-inner {
  max-width: 600px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: normal;
  word-break: initial;
  padding: 5px 10px;
}
.target-info-table {
  max-width: 300px !important;
  min-width: 250px !important;
}
.target-modal .modal-header {
  padding-bottom: 5px !important;
}
.target-modal .modal-dialog {
  width: 98% ;
  height: 100%;
  z-index: 9997;
}
.targets-body {
  padding: 0px !important;
  overflow: hidden;
  height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
