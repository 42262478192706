<template>
  <div class="cl-widget">
    <Card>
      <div slot="title">
        <Row>
          <Row-Col>
            <h4>Line Item Fractions
            </h4>
          </Row-Col>
        </Row>
      </div>
      <VuePerfectScrollbar class="widget-body">
        <Row class="cl-ivu-row" v-for="item in lineItemFractions" :key="item.id">
          <Row-Col span="1">
            <i class="fas fa-copy action-icon"
              @click="copyToClipboard(item.id)">
            </i>
          </Row-Col>
          <Row-Col offset="1" span="21" class="cl-widget-text">{{ item.name }}</Row-Col>
        </Row>
      </VuePerfectScrollbar>
    </Card>
  </div>
</template>
<script>
import { Card, Row, Col } from "iview";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import mixins from "../../../../Mixins/copyToClipboard.js";
import { mapState } from "vuex";
export default {
  components: {
    Card,
    Row,
    "Row-Col": Col,
    VuePerfectScrollbar
  },
  mixins: [mixins],
  data: function () {
    return {
      lineItemFractions: []
    }
  },
  computed: {
    ...mapState(["mediaPlanLineItems", "selectedCampaignLauncherConfigId", "businessProfileId"])
  },
  watch: {
    mediaPlanLineItems: {
      immediate: true,
      handler: function (value) {
        var fractions = [];
        value.forEach(element => {
          if (element.fractionId > 0) {
            fractions.push({ id: 'DXF-' + Number.parseInt(businessProfileId).toString(36) + '-' + element.fractionId, name: element.fractionAlias });
          }
        });
        this.lineItemFractions = fractions;
      }
    }
  }
}
</script>
<style>
.fa-eye:hover {
  cursor: pointer;
}
</style>
<style scoped>
.fa-refresh {
  margin-left:5px;
  margin-right:5px;
}
</style>
