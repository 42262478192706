export default {
  methods: {
    getBatchApiResponse (batchResponseData, index, endpoint) {
      var response = batchResponseData[index]
      let errorObject = this.checkIfResponseIsInValid(endpoint, response)
      if (errorObject && errorObject.hasOwnProperty("errorMsg")) {
        throw errorObject
      }
      return response.RequestResponse;
    },
    checkIfResponseIsInValid (endpoint, response) {
      if (response.StatusCode != "OK") {
        var errorObject = {
          "endpointFailed": endpoint,
          "statusCode": response.StatusCode,
          "errorMsg": response.RequestResponse
        }
        return errorObject;
      }
      return {};
    },
    initializeBatchRequests (requestsArray) {
      requestsArray.forEach((request, index) => {
        request["id"] = index
      })
    }
  }
}
