<template>
  <div>
    <div class="hot-div" style="width: 100%; height: 75vh; overflow-x:hidden; background: white; z-index:101" v-if="lineItems.length">
      <div v-if="loadingCampiagnLauncherAssets" class="overlay">
        <i class="fas fa-spinner fa-3x fa-spin dark-black"></i>
      </div>
      <hot-table
        class="sheet-hot"
        ref="hotLineItemMapper"
        :settings="hotSettings"
      ></hot-table>
    </div>
    <div v-else>
      <Alert>
        No line items available.
      </Alert>
    </div>
  </div>
</template>

<script>
import { HotTable } from "@handsontable/vue";
import Handsontable from 'handsontable';
import { Alert } from 'iview';
import { mapState, mapMutations } from "vuex";
import PromotionApiService from "../../Promotion/ApiService";
import { EventBus } from "../EventBus.js"
export default {
  props: {
    lineItems: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data: function () {
    return {
      hotSettings: {},
      htData: [],
      colHeaders: ["HOT_Lineitem", "HOT_Configuration", "HOT_Targets", "HOT_Stores"],
      displayColHeaders: ["Strategy Line Item", "Launch Configuration", "Targets", "Stores"],
      isSheetUpdated: false
    };
  },
  components: {
    "hot-table": HotTable,
    Alert
  },
  created () {
    this.hotSettings = this.buildHotSettings();
  },
  methods: {
    ...mapMutations([
      "set_loadingCampiagnLauncherAssets",
      "set_dbStores"
    ]),
    buildHotSettings () {
      var self = this;
      return {
        data: self.htData,
        manualColumnResize: true,
        autoRowSize: true,
        autoColumnSize: true,
        copyPaste: {
          rowsLimit: 5000
        },
        colWidths: function (index) {
          // return self.computeColWidths();
          if (self.colHeaders[index] == "HOT_Lineitem") {
            return 320;
          }
          if (self.colHeaders[index] == "HOT_Configuration" || self.colHeaders[index] == "HOT_Targets") {
            return 360;
          }
          return 290;
        },
        colHeaders: function (index) {
          var displayName = self.displayColHeaders[index];
          var displayText = displayName;
          return displayText;
        },
        fixedColumnsLeft: 1,
        renderAllRows: true,
        renderAllColumns: true,
        rowHeights: 55,
        viewportColumnRenderingOffset: this.colHeaders.length,
        columns: (index) => {
          var self = this;
          if (index >= self.colHeaders.length) {
            return;
          }
          let settings = {};
          return settings;
        },
        cells: (row, col, prop) => {
          let cellProperties = {};
          if (self.colHeaders[col] == "HOT_Lineitem") {
            cellProperties.renderer = this.lineItemNameRenderer;
            cellProperties.readOnly = true;
          }
          if (self.colHeaders[col] == "HOT_Configuration") {
            cellProperties.renderer = this.configNameRenderer;
          }
          if (self.colHeaders[col] == "HOT_Targets") {
            cellProperties.renderer = this.targetsNameRenderer;
          }
          if (self.colHeaders[col] == "HOT_Stores") {
            cellProperties.renderer = this.storesNameRenderer;
          }
          if (this.lineItems && this.lineItems.length) {
            let lineItem = this.lineItems[row];
            if (lineItem && lineItem.isPublished) {
              cellProperties.className = 'disabled-cell-text';
              cellProperties.readOnly = true;
            }
            // show warning for invalid cells
            if (self.invalidMapperScreenLineItems && self.invalidMapperScreenLineItems.includes(row)) {
              cellProperties.className = 'invalid-cell';
            } else if (!lineItem.isPublished) {
              cellProperties.className = '';
            }
          }
          return cellProperties;
        },
        beforeChange: (changes, source) => {
          if (!changes) {
            return null;
          }
          let self = this;
          let unfetchedStoreCodes = [];
          let storeCodesToFetch = [];
          for (let index = 0; index < changes.length; index++) {
            let change = changes[index];
            let [, col, , newVal] = change;
            if (col >= this.colHeaders.length) {
              return;
            }
            if (newVal && self.colHeaders[col] == "HOT_Configuration") {
              // set column to null if multiple config are added
              if (newVal.split(",").length > 1) {
                changes[index][3] = null;
              } else {
                let configId = newVal.split("-").pop().trim();
                let config = self.campaignLauncherConfigurations.find(co => co.id == configId);
                if (!config) {
                  changes[index][3] = null;
                }
              }
            }
            if (newVal && self.colHeaders[col] == "HOT_Targets") {
              let isInvalidTarget = false;
              let targets = newVal.split(",");
              targets.forEach(target => {
                let targetId = target.split("-").pop().trim();
                if (!self.savedTargets.some(obj => obj.id == targetId)) {
                  isInvalidTarget = true;
                }
              });
              if (isInvalidTarget) {
                changes[index][3] = null;
              }
            }
            if (newVal && self.colHeaders[col] == "HOT_Stores") {
              let enteredStores = newVal.split(",").map(str => str.trim());
              if (!self.dbStores) {
                self.$store.state.dbStores = [];
              }
              enteredStores.forEach(store => {
                if (store.startsWith("DXS")) {
                  let code = store.split(`DXS-${Number.parseInt(deltax.businessProfileId).toString(36)}-`).pop().trim();
                  const storeExists = self.dbStores.some(store => store.storeCode == code);
                  if (!storeExists) {
                    storeCodesToFetch.push(code);
                  }
                } else {
                  unfetchedStoreCodes.push(store);
                }
              })
            }
          }
          storeCodesToFetch = [...new Set(storeCodesToFetch)];
          if (storeCodesToFetch.length) {
            self.$store.commit("set_loadingCampiagnLauncherAssets", true);
            PromotionApiService.getStores(false, storeCodesToFetch.join(','), '', false)
              .then((res) => {
                let fetchedStores = res;
                let fetchedStoreCodes = fetchedStores.map(store => store.storeCode);
                // add the invalid store codes and store codes not found from api
                unfetchedStoreCodes = [...unfetchedStoreCodes, ...storeCodesToFetch.filter(store => !fetchedStoreCodes.includes(store))];
                // update dbstore with fetched stores
                self.set_dbStores([...(self.dbStores || []), ...fetchedStores]);
                self.$store.commit("set_loadingCampiagnLauncherAssets", false);

                if (unfetchedStoreCodes.length) {
                  self.bulkUpdateInvalidStoreColumns(changes, unfetchedStoreCodes);
                }
              })
              .catch((error) => {
                self.$Message.error({
                  background: true,
                  content: "Something went wrong. Unable to fetch stores."
                });
                console.log("Error fetching stores", error);
                self.$store.commit("set_loadingCampiagnLauncherAssets", false);
              });
          } else {
            // if no storecodes to fetch then bulk update the invalid store codes to null
            if (unfetchedStoreCodes.length) {
              self.bulkUpdateInvalidStoreColumns(changes, unfetchedStoreCodes);
            }
          }
        }
      };
    },
    getHtDataFromLineItems (lineItems) {
      let htData = [];
      lineItems.forEach(item => {
        let lineItemId = `DXL-${Number.parseInt(deltax.businessProfileId).toString(36)}-${item.id}`;
        let configId = item.config ? `DXCL-${Number.parseInt(deltax.businessProfileId).toString(36)}-${item.config}` : null;
        let targetIds = item.targets.map(id => `DXT-${Number.parseInt(deltax.businessProfileId).toString(36)}-${id}`).join(',');
        let lineItemLocations = item.locations && item.locations.length ? item.locations.map(id => `DXS-${Number.parseInt(deltax.businessProfileId).toString(36)}-${id}`).join(',') : null;
        htData.push([lineItemId, configId, targetIds, lineItemLocations]);
      });
      return htData;
    },
    lineItemNameRenderer (instance, td, row, col, prop, value, cellProperties) {
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }
      if (value) {
        value = Handsontable.helper.stringify(value);
        let lineItemId = value.split('-').pop();
        let lineItem = this.lineItems.find(li => li.id == lineItemId);
        let lineItemName = lineItem && lineItem.name ? lineItem.name : lineItemId;
        if (lineItem.fractionId > 0) {
          lineItemName = `(Fraction) ${lineItemName} - ${lineItem.fractionAlias}`;
        }
        td.innerHTML = '';
        let tdWrapper = document.createElement('div');
        tdWrapper.innerHTML = lineItemName;
        td.appendChild(tdWrapper);
      }
      if (cellProperties.className) td.classList.add(cellProperties.className);
      return td;
    },
    configNameRenderer (instance, td, row, col, prop, value, cellProperties) {
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }
      if (value) {
        value = Handsontable.helper.stringify(value);
        let configId = value.split("-").pop().trim();
        let config = this.campaignLauncherConfigurations.find(co => co.id == configId);
        let configName = this.getConfigWithAccountName(config);
        td.innerHTML = '';
        let tdWrapper = document.createElement('div');
        tdWrapper.innerHTML = configName;
        td.appendChild(tdWrapper);
      }
      if (cellProperties.className) td.classList.add(cellProperties.className);
      return td;
    },
    targetsNameRenderer (instance, td, row, col, prop, value, cellProperties) {
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }
      if (value) {
        let targets = value.split(",");
        let names = [];
        targets.forEach(target => {
          let targetId = target.split("-").pop().trim();
          let targetExists = this.savedTargets.find(obj => obj.id == targetId);
          if (targetExists) {
            names.push(targetExists.name);
          }
        });
        if (names.length) {
          td.innerHTML = '';
          let tdWrapper = document.createElement('div');
          // tdWrapper.innerHTML = [...new Set(names)].join(", ");
          tdWrapper.innerHTML = names.join(", ");
          td.appendChild(tdWrapper);
        } else {
          td.innerHTML = '';
        }
      }
      if (cellProperties.className) td.classList.add(cellProperties.className);
      return td;
    },
    storesNameRenderer (instance, td, row, col, prop, value, cellProperties) {
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }
      if (value) {
        let stores = value.split(",").map(str => str.trim());
        let names = [];
        stores.forEach(store => {
          let storeCode = store.split(`DXS-${Number.parseInt(deltax.businessProfileId).toString(36)}-`).pop().trim();
          let storeExists = null;
          if (this.dbStores && this.dbStores.length) {
            storeExists = this.dbStores.find(obj => obj.storeCode == storeCode);
          }
          if (storeExists) {
            names.push(storeExists.name);
          } else {
            names.push(storeCode);
          }
        });
        if (names.length) {
          td.innerHTML = '';
          let tdWrapper = document.createElement('div');
          // tdWrapper.innerHTML = [...new Set(names)].join(", ");
          tdWrapper.innerHTML = names.join(", ");
          td.appendChild(tdWrapper);
        } else {
          td.innerHTML = '';
        }
      }
      if (cellProperties.className) td.classList.add(cellProperties.className);
      return td;
    },
    getConfigWithAccountName (config) {
      let configName = config && config.name ? config.name : "";
      if (this.selectedPublisher === this.publisher.Facebook) {
        let account = this.accounts.find(acc => acc.id == config.BpseId);
        configName = account && account.NickName ? `${configName} (${account.NickName})` : configName;
      } else if (this.selectedPublisher === this.publisher.Adwords) {
        let account = this.googleAccounts.find(acc => acc.Id == config.BpseId);
        configName = account && account.NickName ? `${configName} (${account.NickName})` : configName;
      }
      return configName;
    },
    computeColWidths () {
      if (this.$refs.hotLineItemMapper && this.$refs.hotLineItemMapper.$el) {
        const tableContainer = this.$refs.hotLineItemMapper.$el.parentNode;
        const tableWidth = tableContainer.offsetWidth;
        const percentage = 25;
        return (tableWidth * percentage) / 100;
      }
      return 368;
    },
    bulkUpdateInvalidStoreColumns (changes, unfetchedStoreCodes) {
      let instance = this.$refs['hotLineItemMapper'].hotInstance;
      let bulkUpdateArray = [];
      for (let index = 0; index < changes.length; index++) {
        let change = changes[index];
        let [row, col, , newVal] = change;
        if (col >= this.colHeaders.length) {
          return;
        }
        if (this.colHeaders[col] == "HOT_Stores") {
          let enteredStores = newVal.split(",").map(str => str.trim());
          enteredStores.some(store => {
            let storeName = store.split(`DXS-${Number.parseInt(deltax.businessProfileId).toString(36)}-`).pop().trim();
            if (unfetchedStoreCodes.some(name => name == storeName)) {
              bulkUpdateArray.push([row, col, null]);
              return true;
            }
            return false;
          });
        }
      }
      if (bulkUpdateArray.length > 0) {
        setTimeout(() => {
          instance.setDataAtRowProp(bulkUpdateArray);
        });
      }
    }
  },
  watch: {
    lineItems (val) {
      if ((val && val.length) && !this.isSheetUpdated) {
        this.htData = this.getHtDataFromLineItems(val);
        this.hotSettings.data = this.htData;
      }
    },
    invalidMapperScreenLineItems (val) {
      if (val && val.length) {
        this.$refs['hotLineItemMapper'].hotInstance.render();
      }
    }
  },
  computed: {
    ...mapState([
      "campaignLauncherConfigurations",
      "deltax",
      "savedTargets",
      "dbStores",
      "loadingCampiagnLauncherAssets",
      "accounts",
      "googleAccounts",
      "publisher",
      "selectedPublisher",
      "invalidMapperScreenLineItems"
    ])
  },
  mounted () {
    EventBus.$emit("bulk-lineitem-sheet-mounted")
  }
};
</script>
<style scoped>
.disabled-cell-text {
  background-color: #f6f6f6 !important;
  color: #bbb !important;
  border: 1px solid red !important;
}
::v-deep .invalid-cell {
  border: 1px solid red !important;
}

.sheet-hot .handsontable td,
.sheet-hot .handsontable tr,
.sheet-hot .handsontable th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
</style>
