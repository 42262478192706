import { eventTracking } from "../../Constants/MolocoEnums"

class Objective {
  constructor (ColumnConfig) {
    const { count, fieldMacros } = eventTracking
    const fields = Array.from(new Array(count), (_, i) => i + 1).reduce((l, e) => [...l, ...fieldMacros.map(field => field.split("$").join(e))], [])
    const hotFields = fields.map(field => `HOT_${field}`)

    Object.keys(ColumnConfig).forEach(key => {
      ColumnConfig[key].base.push(...hotFields)
    })

    this.columnConfig = ColumnConfig
    // this.creativeSpec = CreativeSpec
  }
}

export { Objective }
