<template>
  <div>
    <div class="alert alert-danger" v-if="showNotification" role="alert">
      {{ notificationMessage }}
      <button type="button" class="close" aria-label="Close" @click="showNotification = false; notificationMessage = '';">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="hot-div" style="width: 100%; height: 530px; overflow: hidden;">
      <!-- <button @click="getPreviewableRowCaller(true)">Click AllRows</button> -->
      <!-- <button @click="getPreviewableRowCaller(false)">Click getPreviewAbleRows</button> -->
      <div v-if="loadingCampiagnLauncherAssets" class="overlay">
        <i class="fas fa-spinner fa-3x fa-spin dark-black"></i>
      </div>
      <div v-show="isPublishDataFetchComplete && htData.length < 1" class="alert alert-info">
        No published ads were found.
      </div>
      <div v-show="!isPublishDataFetchComplete" class="hot-spinner">
        <i class="fa fa-lg fa-spinner fa-spin" />
      </div>
      <hot-table v-show="isPublishDataFetchComplete && htData.length > 0" class="ad-sheet-hot" ref="hot" :settings="hotSettings"></hot-table>
    </div>
  </div>
</template>

<script>
import {
  locale
} from 'iview';
import { HotTable } from "@handsontable/vue";
import Handsontable from 'handsontable'
import lang from 'iview/dist/locale/en-US';
import { adwordsEnums } from "../../Constants/AdwordsEnums.js";
import { mapState, mapMutations, mapGetters } from "vuex"
import { APIService } from "../../ApiService";

locale(lang);

export default {
  props: {
    tab: { default: '' },
    creativeSpec: { default: function () { return {} } },
    colHeaders: { default: function () { return [] } },
    displayColHeaders: { default: function () { return [] } },
    requiredCols: { default: function () { return {} } },
    callToAction: { default: function () { return [] } },
    selectedTab: { default: '' },
    generateCampaigns: { default: {} },
    generateOldCampaigns: { default: {} },
    customizedAdWordsColHeaders: { default: [] },
    isPublishDataFetchComplete: {
      type: Boolean,
      required: true
    }
  },
  data: function () {
    return {
      hotSettings: {},
      adwordsAdFormats: adwordsEnums.adwordsAdFormats,
      startRows: 0,
      htData: [],
      campaignsDropDown: [],
      previewable: {},
      lastUsedIndex: 0,
      indexToIdentifierMapper: {},
      publishedIndex: [],
      bulkUpdateQueries: {},
      adType: adwordsEnums.adType,
      servedAssetFieldType: adwordsEnums.servedAssetFieldType,
      agList: [],
      keywordsMatchType: [],
      keywordsMatchTypeMapper: adwordsEnums.keywordsMatchType,
      adFormatToIdentifierNameMapper: adwordsEnums.adFormatToIdentifierNameMapper,
      showNotification: false,
      notificationMessage: '',
      negativeKeywordsIds: [],
      campaignType: adwordsEnums.campaignType,
      validImagePrefixes: ["image", "marketingImage", "squareMarketingImage", "logo", "squareLogo", "portraitMarketingImage", "Image", "landscapeLogo"],
      callToActionTypes: adwordsEnums.callToActionTypes,
      callToActionTypesForVideoAd: adwordsEnums.callToActionTypesForVideoAd,
      callToActionTypesForAssetGroups: adwordsEnums.callToActionTypesForAssetGroups,
      existingSettings: {},
      alreadyProcessed: {}
    }
  },
  components: {
    "hot-table": HotTable
  },
  computed: {
    ...mapState([
      "adgroups",
      "adGroupCampaignMapper",
      "publishedSheetCreatives",
      "selectedCampaignLauncherConfig",
      "selectedCampaignLauncherConfigId",
      "adGroupNameToIdentifierMapper",
      "negativeKeywords",
      "conversionAction",
      "builtImageIdUrlMap",
      "imageIdUrlMap",
      "adwordsAdSheetSettingsInfo",
      "allAdExtensions",
      "loadingCampiagnLauncherAssets",
      "mediaPlanLineItems"
    ]),
    ...mapGetters([
      "isLivePromotion"
    ])
  },
  created () {
    this.campaignsDropDown = Object.keys(this.generateCampaigns)
    this.hotSettings = this.buildHotSettings();
    this.existingSettings = this.hotSettings;
    this.keywordsMatchType = Object.keys(this.keywordsMatchTypeMapper);
  },
  watch: {
    allAdExtensions: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.length > 0 && oldVal && oldVal.length == 0) {
          this.rerender();
        }
      }
    },
    publishedSheetCreatives: {
      immediate: true,
      handler: function () {
        if (this.publishedSheetCreatives[this.tab]) {
          this.publishedSheetCreatives[this.tab].forEach((element, index) => {
            if (element && !element["HOT_IsNotPublished"]) {
              this.publishedIndex.push(index);
            }
          });
        }
        this.previewable = {};
        this.publishedRows = [];
        this.htData = [];
        this.lastUsedIndex = 0;
        if (this.publishedSheetCreatives[this.tab] && this.publishedSheetCreatives[this.tab].length) {
          let publishedRowObjects = JSON.parse(JSON.stringify(this.publishedSheetCreatives[this.tab]));
          publishedRowObjects.forEach((rowObject, rowIndex) => {
            this.lastUsedIndex++;
            if (rowObject && rowObject.Identifier) {
              this.indexToIdentifierMapper[rowIndex] = rowObject.Identifier;
            }
            let htRow = [];
            this.colHeaders.forEach(header => {
              if (header in rowObject) {
                htRow.push(rowObject[header])
              } else {
                htRow.push(null)
              }
            });
            this.htData.push(htRow);
          });
          this.publishedRows = JSON.parse(JSON.stringify(this.htData));
        } else {
          for (let i = 0; i < this.startRows; i++) {
            this.htData.push(Array(this.colHeaders.length).fill(null))
          }
        }
        this.$nextTick(() => {
          let updatedSettings = {
            data: this.htData
          }
          this.$refs['hot'].hotInstance.updateSettings(updatedSettings);
          this.$refs['hot'].hotInstance.render();
        })
      }
    },
    adGroupNameToIdentifierMapper: {
      immediate: true,
      handler: function (newVal) {
        this.agList = Object.entries(newVal).map(e => e[0]);
      }
    },
    customizedAdWordsColHeaders: {
      immediate: true,
      deep: true,
      handler: function (newVal) {
        let newColHeaders = this.colHeaders;
        let newDisplayColHeaders = this.displayColHeaders;
        var self = this;
        let updatedSettings = {
          data: self.htData,
          colHeaders: function (index) {
            var header = newColHeaders[index];
            var displayName = newDisplayColHeaders[index];
            var displayText = displayName;
            if (self.requiredCols.base.has(header)) {
              displayText += "<span style='color:#b94a48'>*</span>";
            }
            if (header.includes('headline') && self.selectedCampaignLauncherConfig.campaign.type == self.campaignType['Demand Gen']) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 40 characters'></i>"
            } else if (header.includes('headline')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 30 characters'></i>"
            }
            if (header.includes('businessName')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 25 characters'></i>"
            }
            if (header.includes('shortDescription')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 60 characters'></i>"
            }
            if (header.includes('description') || header.includes('longHeadline')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 90 characters'></i>"
            }
            if (header.includes('logo') && this.selectedTab != 'assetGroups') {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 512x128 and the aspect ratio must be 4:1 (+-1%)'></i>"
            } else if (header.includes('logo') || header.includes('squareLogo')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 128x128 and the aspect ratio must be 1:1 (+-1%)'></i>"
            }
            if (header.includes('image')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The aspect ratio must be 1.91:1 or 1:1 or 4:5'></i>"
            }
            if (header.toLowerCase().includes('portraitmarketingimage')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 480x600 and the aspect ratio must be 4:5'></i>"
            } else if (header.toLowerCase().includes('squaremarketingimage')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 300x300 and the aspect ratio must be 1:1 (+-1%)'></i>"
            } else if (header.toLowerCase().includes('marketingimage')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 600x314 and the aspect ratio must be 1.91:1 (+-1%)'></i>"
            } else if (header.toLowerCase().includes('landscapelogo')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 512x128 and the aspect ratio must be 4:1'></i>"
            }
            if (header.includes('video')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='Youtube Video URL'></i>"
            }
            if (header.includes('youtubeVideo')) {
              displayText += "&nbsp<i class='fa fa-info-circle' title='Aspect ratio of horizontal (16:9), square (1:1), or vertical (9:16); and greater than or equal to 10 seconds in duration'></i>"
            }
            return displayText;
          },
          renderAllRows: true,
          columns: new Array(newColHeaders.length).fill().map((_, index) => {
            if (index >= newColHeaders.length) { return }
            let settings = {};
            if (self.colHeaders[index].indexOf("campaign") != -1) {
              if (self.isAdFormatTab(self.tab)) {
                settings.readOnly = true;
                settings.className = 'disabled-cell-text';
                settings.type = 'text';
                settings.copyable = false;
              } else {
                settings.type = 'dropdown';
                settings.allowInvalid = false;
              }
              settings.source = self.campaignsDropDown;
            }
            if (self.colHeaders[index].indexOf("adgroup") != -1) {
              if (self.isAdFormatTab(self.tab)) {
                settings.type = 'dropdown';
                settings.source = this.getStates;
                settings.allowInvalid = false;
              }
            }
            if (self.colHeaders[index].indexOf("finalUrl") != -1 || self.colHeaders[index].indexOf("appUrl") != -1 || self.colHeaders[index].indexOf("verificationUrl") != -1) {
              settings.allowInvalid = false;
            }
            if (self.colHeaders[index].indexOf("headline") != -1) {
              settings.allowInvalid = false;
            }
            if (self.colHeaders[index].indexOf("longHeadline") != -1) {
              settings.allowInvalid = false;
            }
            if (self.colHeaders[index].indexOf("description") != -1) {
              settings.allowInvalid = false;
            }
            if (self.colHeaders[index].indexOf("shortDescription") != -1) {
              settings.allowInvalid = false;
            }
            if (self.colHeaders[index].indexOf("businessName") != -1) {
              settings.allowInvalid = false;
            }
            if (self.colHeaders[index].startsWith("youtubeVideo")) {
              settings.allowInvalid = false;
            }
            if (index == 0 && newColHeaders[index].indexOf("valid") != -1) {
              settings.readOnly = true;
              settings.copyable = false;
              settings.editor = false;
              settings.className = 'disabled-cell-text';
              settings.renderer = this.previewBtnRenderer;
            }
            if (self.colHeaders[index].indexOf("lineItem") != -1) {
              settings.renderer = this.lineItemNameRenderer;
            }
            if (this.validImagePrefixes.some(prefix => newColHeaders[index].indexOf(prefix) !== -1) && !deltax.isHotTableRendererDisabled) {
              settings.renderer = this.imageColRenderer;
            }
            if (self.colHeaders[index].indexOf("adExtensions") != -1) {
              if (self.tab == 'adGroups' || self.tab == 'assetGroups') {
                settings.allowInvalid = false;
                settings.renderer = this.adExtensionRenderer;
              }
            }
            if (self.colHeaders[index].toLowerCase().includes("calltoaction")) {
              if (self.tab == 'discoveryVideoAds') {
                settings.source = this.callToActionTypesForVideoAd;
              } else if (self.tab == 'assetGroups') {
                settings.source = this.callToActionTypesForAssetGroups;
              } else {
                settings.source = this.callToActionTypes;
              }
              settings.type = 'dropdown';
              settings.allowInvalid = false;
            }
            return settings;
          })
        }
        if (this.$refs['hot'] && this.$refs['hot'].hotInstance) {
          this.existingSettings = updatedSettings;
          this.$refs['hot'].hotInstance.updateSettings(updatedSettings);
          this.$refs['hot'].hotInstance.render();
        }
        this.rerender();
      }
    },
    builtImageIdUrlMap () {
      if (this.builtImageIdUrlMap) {
        this.rerender()
      }
    }
  },
  methods: {
    ...mapMutations([
      "setAdGroupCampaignMapper",
      "setAdGroupMapper",
      "set_adExtensions",
      "set_loadingCampiagnLauncherAssets"
    ]),
    getStates (query, callback) {
      const states = this.agList;
      callback(states);
    },
    previewBtnRenderer (instance, td, row, col, prop, value, cellProperties) {
      let rowInfo = instance.getDataAtRow(row);
      let isFlagSet = rowInfo.length ? rowInfo[col] == 'true' : false;
      isFlagSet = this.publishedIndex.includes(row) ? true : isFlagSet;
      td.innerHTML = '';
      let previewTdWrapper = document.createElement('div');
      previewTdWrapper.classList.add('preview-td-wrapper');
      let previewIcon = document.createElement('span');
      previewIcon.classList.add('eye', 'preivew-icon-span');
      previewIcon.innerHTML = '<i class="fas fa-times-circle"></i>';
      previewTdWrapper.appendChild(previewIcon);
      td.appendChild(previewTdWrapper);
      td.classList.add('disabled-cell-text')
      td.firstElementChild.firstElementChild.innerHTML = isFlagSet ? '<i class="fa fa-check"></i>' : '<i class="fas fa-times"></i>';
      return td;
    },
    buildHotSettings () {
      var self = this;
      return {
        data: self.htData,
        startCols: self.colHeaders.length,
        startRows: this.startRows,
        manualColumnResize: true,
        autoRowSize: true,
        autoColumnSize: true,
        viewportColumnRenderingOffset: this.colHeaders.length,
        colWidths: function (index) {
          if (self.colHeaders[index] && self.colHeaders[index].indexOf("valid") != -1) {
            return 100;
          }
          if (self.colHeaders[index] && self.colHeaders[index].indexOf("card") != -1) {
            return 215;
          }
          return 200;
        },
        colHeaders: function (index) {
          var header = self.colHeaders[index];
          var displayName = self.displayColHeaders[index];
          var displayText = displayName;
          if (self.requiredCols.base.has(header)) {
            displayText += "<span style='color:#b94a48'>*</span>";
          }
          if (header.includes('headline') && self.selectedCampaignLauncherConfig.campaign.type == self.campaignType['Demand Gen']) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 40 characters'></i>"
          } else if (header.includes('headline')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 30 characters'></i>"
          }
          if (header.includes('businessName')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 25 characters'></i>"
          }
          if (header.includes('shortDescription')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 60 characters'></i>"
          }
          if (header.includes('description') || header.includes('longHeadline')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The maximum length is 90 characters'></i>"
          }
          if (header.includes('logo') && self.selectedCampaignLauncherConfig.campaign.type != self.campaignType['Performance Max'] && self.selectedCampaignLauncherConfig.campaign.type != self.campaignType['Demand Gen']) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 512x128 and the aspect ratio must be 4:1 (+-1%)'></i>"
          } else if (header.includes('logo') || header.includes('squareLogo')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 128x128 and the aspect ratio must be 1:1 (+-1%)'></i>"
          }
          if (header.includes('image')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The aspect ratio must be 1.91:1 or 1:1 or 4:5'></i>"
          }
          if (header.toLowerCase().includes('portraitmarketingimage')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 480x600 and the aspect ratio must be 4:5'></i>"
          } else if (header.toLowerCase().includes('squaremarketingimage')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 300x300 and the aspect ratio must be 1:1 (+-1%)'></i>"
          } else if (header.toLowerCase().includes('marketingimage')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 600x314 and the aspect ratio must be 1.91:1 (+-1%)'></i>"
          } else if (header.toLowerCase().includes('landscapelogo')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='The minimum size is 512x128 and the aspect ratio must be 4:1'></i>"
          }
          if (header.includes('video')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='Youtube Video URL'></i>"
          }
          if (header.includes('youtubeVideo')) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='Aspect ratio of horizontal (16:9), square (1:1), or vertical (9:16); and greater than or equal to 10 seconds in duration'></i>"
          }
          return displayText;
        },
        renderAllRows: true,
        rowHeights: 74,
        columns: (index) => {
          var self = this;
          if (index >= self.colHeaders.length) { return }
          let settings = {};
          if (self.colHeaders[index].indexOf("campaign") != -1) {
            if (self.tab != 'adGroups') {
              settings.readOnly = true;
              settings.className = 'disabled-cell-text';
              settings.type = 'text';
              settings.copyable = false;
            } else {
              settings.type = 'dropdown';
              settings.allowInvalid = false;
            }
            settings.source = self.campaignsDropDown;
          } else if (self.colHeaders[index].indexOf("adgroup") != -1) {
            if (self.tab != 'adGroups') {
              settings.type = 'dropdown';
              settings.source = this.getStates;
              settings.allowInvalid = false;
            }
          } else if (self.colHeaders[index].toLowerCase().includes("calltoaction")) {
            if (self.tab == 'discoveryVideoAds') {
              settings.source = this.callToActionTypesForVideoAd;
            } else if (self.tab == 'assetGroups') {
              settings.source = this.callToActionTypesForAssetGroups;
            } else {
              settings.source = this.callToActionTypes;
            }
            settings.type = 'dropdown';
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("finalUrls") != -1) {
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("finalUrl") != -1 || self.colHeaders[index].indexOf("appUrl") != -1 || self.colHeaders[index].indexOf("verificationUrl") != -1) {
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].startsWith("youtubeVideo")) {
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("displayPath") != -1) {
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("headline") != -1) {
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("longHeadline") != -1) {
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("businessName") != -1) {
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("shortDescription") != -1) {
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("video") != -1) {
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("description") != -1) {
            settings.allowInvalid = false;
          } else if (self.colHeaders[index].indexOf("matchType") != -1) {
            if (self.tab == "keywords") {
              settings.readOnly = true;
              settings.className = 'disabled-cell-text';
              settings.type = 'dropdown';
              settings.copyable = false;
              settings.source = this.keywordsMatchType;
            }
          } else if (
            self.colHeaders[index].indexOf("hPosition") != -1 || self.colHeaders[index].indexOf("dPosition2") != -1) {
            if (self.tab == "responsiveAds") {
              settings.type = "text";
              settings.allowInvalid = false;
            }
          } else if (
            self.colHeaders[index].indexOf("descriptionPosition1") != -1 ||
            self.colHeaders[index].indexOf("descriptionPosition2") != -1
          ) {
            if (self.tab == "responsiveAds") {
              settings.type = "text";
            }
          } else if (index == 0 && self.colHeaders[index].indexOf("valid") != -1) {
            settings.readOnly = true;
            settings.copyable = false;
            settings.editor = false;
            settings.renderer = this.previewBtnRenderer;
          } else if (self.colHeaders[index].indexOf("adExtensions") != -1) {
            if (self.tab == 'adGroups' || self.tab == 'assetGroups') {
              settings.allowInvalid = false;
              settings.renderer = this.adExtensionRenderer;
            }
          } else if (self.colHeaders[index].indexOf("conversionAction") != -1) {
            if (self.tab == "callAds") {
              settings.type = 'dropdown';
              settings.allowInvalid = false;
              settings.source = this.conversionAction.map(x => x.name);
            }
          } else if (this.colHeaders[index].indexOf("lineItem") != -1) {
            settings.renderer = this.lineItemNameRenderer;
          }
          if (this.isLivePromotion) {
            settings.readOnly = true;
            settings.className = 'post-cell';
          }
          if (this.validImagePrefixes.some(prefix => this.colHeaders[index].indexOf(prefix) !== -1) && !deltax.isHotTableRendererDisabled) {
            settings.renderer = this.imageColRenderer;
          }
          return settings;
        },
        cells: (row, col, prop) => {
          let cellProperties = {}
          if (this.isLivePromotion && self.htData[row].some((ele) => ele != null)) {
            cellProperties.className = 'disabled-cell-text'
          }
          if (this.publishedSheetCreatives[this.tab] && this.publishedSheetCreatives[this.tab][row] &&
           !this.publishedSheetCreatives[this.tab][row].HOT_IsNotPublished) {
            cellProperties.readOnly = 'true'
            cellProperties.className = 'disabled-cell-text'
          }
          return cellProperties
        },
        beforeChange: (changes, source) => {
          let self = this;
          let instance = this.$refs['hot'].hotInstance;
          this.bulkUpdateQueries = {};
          let isAnyRowPeviewable = false;

          // stores the valid column value with respect to the row.
          let previewBtnRowMapper = {};

          for (let index = 0; index < changes.length; index++) {
            let change = changes[index];
            let [row, col, oldVal, newVal] = change;
            if (col >= this.colHeaders.length) {
              return;
            }
            if (self.tab == "keywords" && self.colHeaders[col] == "keyword") {
              if (newVal && newVal.startsWith('"') && newVal.endsWith('"')) {
                self.setHotCellData(row, col + 1, 'Phrase', instance, true);
              } else if (newVal && newVal.startsWith('[') && newVal.endsWith(']')) {
                self.setHotCellData(row, col + 1, 'Exact', instance, true);
              } else if (newVal) {
                self.setHotCellData(row, col + 1, 'Broad', instance, true);
              } else {
                self.setHotCellData(row, col + 1, '', instance, true);
              }
            }
            if (((self.tab == "assetGroups" || self.tab == "appAds" || self.tab == "appEngagementAds") || self.tab == "responsiveDisplayAds" || self.tab == "discoveryCarouselAds" || self.tab == "discoveryImageAds") && !self.publishedIndex.includes(row)) {
              let columnName = this.colHeaders[col];
              if (this.validImagePrefixes.some(prefix => columnName.includes(prefix))) {
                let cellProperties = {};
                let td = instance.getCell(row, col);
                if (newVal && !(newVal in this.imageIdUrlMap)) {
                  if (newVal.includes("http") || newVal.includes("www")) {
                    // process image urls if rendered enabled
                    if (!deltax.isHotTableRendererDisabled) {
                      if (this.alreadyProcessed[`${row}-${col}`]) {
                        this.alreadyProcessed[`${row}-${col}`] = false;
                      } else {
                        changes[index] = null;
                        this.processImageUrl(row, col, oldVal, newVal, instance);
                        this.imageColRenderer(instance, td, row, col, columnName, newVal, cellProperties);
                        continue
                      }
                    }
                  } else {
                    this.$Message.error({
                      background: true,
                      content: "Please select an Image"
                    })
                    changes[index] = null;
                    continue;
                  }
                } else if (td && !deltax.isHotTableRendererDisabled) {
                  // handle rendering based on feature flag
                  this.imageColRenderer(instance, td, row, col, columnName, newVal, cellProperties);
                }
                if (newVal && !deltax.isHotTableRendererDisabled) {
                  instance.render();
                }
              }
              if (newVal && this.colHeaders[col].indexOf("lineItem") != -1) {
                let cellProperties = {};
                let td = instance.getCell(row, col);
                var lineItems = newVal.split(",");
                var isInvalidLineItem = false;
                lineItems.forEach(lineItem => {
                  let enteredLineItem = newVal.split("-").pop();
                  if (!this.mediaPlanLineItems.some(obj => obj.id == enteredLineItem)) {
                    isInvalidLineItem = true;
                  };
                })
                changes[index][3] = [...new Set(newVal.split(','))].join(",");
                if (isInvalidLineItem) {
                  this.$Message.error({
                    background: true,
                    content: "Line Item not found."
                  })
                  changes[index] = null;
                  continue;
                } else if (td) {
                  this.lineItemNameRenderer(instance, td, row, col, columnName, newVal, cellProperties);
                }
                // instance.render();
              }
            }
          }
        },
        afterChange: (changes, source) => {
          if (!changes) {
            return null;
          }
          let self = this;
          let adExtensionIds = [];
          let instance = this.$refs['hot'].hotInstance;
          for (let index = 0; index < changes.length; index++) {
            let change = changes[index];
            let [, col, , newVal] = change;
            if (col >= this.colHeaders.length) {
              return;
            }
            if (newVal && (self.tab == "adGroups" || self.tab == "assetGroups") && self.colHeaders[col] == "adExtensions") {
              var enteredAdExtensions = newVal.split(",");
              enteredAdExtensions.forEach(enteredAdExtension => {
                if (enteredAdExtension.split("-").length == 3) {
                  let adExtensionId = enteredAdExtension.split("-").pop();
                  adExtensionIds.push(adExtensionId);
                }
              });
            }
          }
          adExtensionIds = [...new Set(adExtensionIds)];
          let allAdExtensionIds = self.allAdExtensions.map(adExtension => adExtension.id)
          adExtensionIds = adExtensionIds.filter(adExtensionId => !allAdExtensionIds.includes(parseInt(adExtensionId)))
          if (adExtensionIds.length > 0) {
            self.$store.commit("set_loadingCampiagnLauncherAssets", true);
            APIService.getAdExtensions(self.selectedCampaignLauncherConfig.bpseId, null, adExtensionIds.join(","))
              .then(response => {
                let updatedAllExtensions = [...self.allAdExtensions, ...response.data]
                self.set_adExtensions(updatedAllExtensions);
                let fetchedAdExtensionIds = updatedAllExtensions.map(adExtension => adExtension.id)
                let unfetchedAdExtensions = enteredAdExtensions.filter(adExtension => !fetchedAdExtensionIds.includes(parseInt(adExtension.split("-").pop())))
                self.$store.commit("set_loadingCampiagnLauncherAssets", false);
                if (unfetchedAdExtensions.length > 0) {
                  self.$store.commit("set_showCampaignLauncherError", true);
                  self.$store.commit(
                    "set_campaignLauncherAssetsWithError",
                    unfetchedAdExtensions
                  );
                  for (let index = 0; index < changes.length; index++) {
                    let change = changes[index];
                    let [row, col, , newVal] = change;
                    if (col >= this.colHeaders.length) {
                      return;
                    }
                    if (self.tab == "adGroups" && self.colHeaders[col] == "adExtensions") {
                      let currentAdExtensions = newVal.split(",");
                      let hasCommonElement = currentAdExtensions.some(adExtension => unfetchedAdExtensions.includes(adExtension));
                      if (hasCommonElement) {
                        self.setHotCellData(row, col, null, instance, true);
                      }
                    }
                  }
                } else {
                  self.$store.commit("set_showCampaignLauncherError", false);
                  self.$store.commit(
                    "set_campaignLauncherAssetsWithError",
                    []
                  );
                }
                self.rerender();
              })
              .catch(() => {
                self.showNotification = true;
                self.notificationClass = "alert alert-danger"
                self.notificationMessage = "There was a problem while fetching the AdExtensions"
              })
          }
        },
        afterRenderer: (td, row, col, prop, value, cellProperties) => {
          let instance = this.$refs['hot'].hotInstance
          if (col == 0) {
            this.previewBtnRenderer(instance, td, row, col, prop, value, cellProperties);
          }
          if (this.validImagePrefixes.some(prefix => this.colHeaders[col].indexOf(prefix) !== -1) && !deltax.isHotTableRendererDisabled) {
            this.imageColRenderer(instance, td, row, col, prop, value, cellProperties);
          }
          if (this.colHeaders[col].indexOf("lineItem") != -1) {
            this.lineItemNameRenderer(instance, td, row, col, prop, value, cellProperties);
          }
          if (this.colHeaders[col].indexOf("adExtensions") != -1) {
            this.adExtensionRenderer(instance, td, row, col, prop, value, cellProperties);
          }
        }
      }
    },
    buildRowDataObj (rowData, rowNum) {
      let rowObj = Object.assign(
        ...rowData.map((cellValue, index) => ({
          [this.colHeaders[index]]: cellValue
        }))
      );
      return rowObj;
    },
    createNewPreviewableObj () {
      return {
        'validBase': false,
        'card': new Array(this.hardcodedCardsCount).fill(0),
        'base': 0
      }
    },
    checkIfPreviewable (previewRow) {
      let validBase = previewRow["base"] >= this.requiredCols["base"].size;
      return validBase;
    },
    setHotCellData (row, col, value, instance, lazyUpdate) {
      if (lazyUpdate) {
        this.bulkUpdateQueries[[row, col]] = [row, col, value]
      } else {
        setTimeout(() => { instance.setDataAtCell(row, col, value) })
      }
    },
    getAllRows () {
      let validRows = []
      let instance = this.$refs['hot'].hotInstance
      let rowNum = 0;
      var gridData = instance.getData();
      var cleanedGridData = [];
      gridData.forEach((rowData, index) => {
        if (!instance.isEmptyRow(index)) {
          cleanedGridData.push(rowData);
        }
      })
      for (let rowData of cleanedGridData) {
        let rowDataObj = this.buildRowDataObj(rowData, rowNum);
        validRows.push(rowDataObj)
        rowNum++;
      }
      let publishedRowObjects = (this.publishedSheetCreatives[this.tab] && this.publishedSheetCreatives[this.tab].length) ? JSON.parse(JSON.stringify(this.publishedSheetCreatives[this.tab])) : [];
      let localCounter = this.lastUsedIndex + 1;
      rowNum = 0;
      let self = this;
      validRows.forEach(elem => {
        elem.Identifier = self.indexToIdentifierMapper[rowNum] ? self.indexToIdentifierMapper[rowNum] : `${self.adFormatToIdentifierNameMapper[self.tab]}${localCounter++}`;
        if (self.publishedIndex.includes(rowNum)) {
          validRows[rowNum] = publishedRowObjects[rowNum];
        } else {
          elem["HOT_IsNotPublished"] = true;
        }
        rowNum++;
      });
      return validRows;
    },
    imageColRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (this.publishedIndex.includes(row)) {
        td.classList.add('disabled-cell')
      }
      value = Handsontable.helper.stringify(value)
      let img = td.querySelector("img")
      if (value in this.imageIdUrlMap || (value.includes("http") || value.includes("www"))) {
        if (img && img.src == this.imageIdUrlMap[value]) {
          return td
        }
        if (img && img.src == value) {
          return td
        }
        img = document.createElement('IMG')
        if (value.startsWith("DXC") && value.split('-')[0] === 'DXC') {
          img.src = this.imageIdUrlMap[value]['src']
        } else if (value.includes("http") || value.includes("www")) {
          img.src = value;
        } else {
          img.src = this.imageIdUrlMap[value]['src']
        }
        img.classList.add('hot-img')
        Handsontable.dom.addEvent(img, 'mousedown', function (event) {
          event.preventDefault()
        })

        Handsontable.dom.empty(td)
        td.appendChild(img)
      } else {
        Handsontable.renderers.TextRenderer.apply(this, arguments)
      }
      if (cellProperties.className) td.classList.add(cellProperties.className);
      return td
    },
    adExtensionRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (this.publishedIndex.includes(row)) {
        td.classList.add('disabled-cell-text')
      }
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }
      if (value) {
        value = Handsontable.helper.stringify(value);
        let enteredAdExtensions = value.split(",");
        let adExtensionNames = [];
        enteredAdExtensions.forEach(enteredAdExtension => {
          let adExtensionId = enteredAdExtension.split("-").pop();
          let adExtension = this.allAdExtensions.find(x => x.id == adExtensionId)
          if (adExtension) {
            adExtensionNames.push(adExtension.name);
          }
        });
        let span = document.createElement('span');
        span.innerHTML = adExtensionNames.join(",");
        td.appendChild(span);
      }
      return td;
    },
    rerender () {
      this.$nextTick(() => {
        this.$refs['hot'].hotInstance.render()
      })
    },
    isAdFormatTab (tabName) {
      return tabName == 'responsiveDisplayAds' || tabName == 'appAds' || tabName == 'appEngagementAds' || tabName == 'discoveryCarouselAds' || tabName == 'discoveryImageAds' || tabName == 'discoveryVideoAds';
    },
    lineItemNameRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (this.publishedIndex.includes(row)) {
        td.classList.add('disabled-cell')
      }
      while (td.firstChild) {
        td.removeChild(td.firstChild);
      }
      if (value) {
        value = Handsontable.helper.stringify(value);
        let lineItems = value.split(",");
        let names = [];
        lineItems.forEach(lineItem => {
          let lineItemId = lineItem.split("-").pop();
          let lineItemExists = this.mediaPlanLineItems.find(obj => obj.id == lineItemId);
          if (lineItemExists) {
            names.push(lineItemExists.name);
          } else {
            names.push(lineItem);
          }
        });
        if (names.length) {
          td.innerHTML = '';
          let tdWrapper = document.createElement('div');
          tdWrapper.innerHTML = [...new Set(names)].join(",");
          td.appendChild(tdWrapper);
        } else {
          td.innerHTML = '';
        }

        // if (lineItem) {
        //   let span = document.createElement('span');
        //   span.innerHTML = lineItem.name;
        //   td.appendChild(span);
        // }
      }
      if (cellProperties.className) td.classList.add(cellProperties.className);
      return td;
    },
    filterIdFromImageName (imageName) {
      let imageObject = this.imageIdUrlMap[imageName];
      if (imageObject) {
        return imageObject.id;
      } else {
        return imageName;
      }
    },
    processImageUrl (row, col, oldVal, newVal, instance) {
      let img = new Image();
      img.onload = () => {
        this.alreadyProcessed[`${row}-${col}`] = true;
        instance.setDataAtCell(row, col, newVal);
      };
      img.onerror = () => {
        this.$Message.error({
          background: true,
          content: 'Please enter a valid image URL'
        })
      };
      img.src = newVal;
    }
  }
}
</script>

<style src="../../../../../../../node_modules/handsontable/dist/handsontable.full.css"></style>
<style src="../../../../Styles/dx-iview.css"></style>
<style scoped>
  .ivu-tabs-nav .ivu-tabs-tab{
    border-radius: 0 !important;
  }

.ad-sheet-hot .handsontable td,
.ad-sheet-hot .handsontable tr,
.ad-sheet-hot .handsontable th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.disabled-cell-text {
  background-color: #f6f6f6 !important;
  color: #bbb !important;
}
.preview-td-wrapper {
  cursor: default;
}
::v-deep .handsontable td.htInvalid {
  background-color: white !important;
  border: 1px solid red
}
.post-cell {
  cursor: not-allowed;
}
</style>
