<template>
  <div class="elements">
    {{ allVerified }}
    <div v-if="budgetTypePivot.campaigns > 0" class="budget-type-pivot">
      <div class="">
        <label class="element-label">Campaign Setup</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ budgetTypeVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="budgetTypeVerified" :disabled="budgetTypeVerifiedDisable"/>
          </label>
        </span>
      </div>
      <div class="budget-type-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th
                v-for="item in budgetTypeColumns"
                :key="item"
                class="table-header"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody v-for="(record, i) in budgetTypePivot.records" :key="i">
            <tr :class = "{'disable-row' : record.status === 'Success'}">
              <td class="data-center">{{ record.campaignName }}</td>
              <td class="data-center">{{ record.campaignType }}</td>
              <td v-if="record.hasCluster" class="data-center">
                  <span>
                    <a
                    data-toggle="collapse"
                    v-bind:href="'#target-preview-' + i"
                  ><u>{{ record.clusterName }}</u></a>
                  </span >
                </td>
              <td v-else class="data-center">None</td>
              <td class="data-center">
                <Row v-show="record.editingBudget" type="flex" align="top">
                  <column class="ivu-col ivu-col-span-18" style="padding-right:5%;">
                    <i-input
                          v-model.number="budgetValue"
                          type="number"
                        >
                      <span slot="prepend">{{record.bpCurrency}}</span>
                    </i-input>
                  </column>
                   <column span="2" style="padding-right:5%;">
                <span @click="saveBudgetValue(i, budgetValue)">
              <i :class="' fa fa-check ' "
                aria-hidden="true"
                title="Update"
              ></i>
            </span>
             </column>
              <column span="2">
            <span @click="record.editingBudget=false,reserveEditMode=false, closeBudgetEditing(i)">
              <i class="fa fa-times" aria-hidden="true" title="Cancel"></i>
            </span>
              </column>
            </Row>
                <span v-show="!record.editingBudget" slot="content" style="padding-right:5%">{{ record.bpCurrency +" " + " (" + record.budget + " Per Day)"}}</span>
                <span v-show="!record.editingBudget"
                  :class="reserveEditMode ? 'disabled': ''"
                  data-toggle="collapse"
                  @click="editBudget(i)"
                  ><i v-if = "record.status === 'Success'" class="fa fa-pencil" :style="{color:'grey'}" />
                  <i v-else class="fa fa-pencil" />
                </span>
              </td>
              <td class="data-center">{{ record.status }}</td>
              <td class="data-center">{{ record.schedule }}</td>
            </tr>
            <tr v-if="record.hasCluster" class="panel-collapse collapse" v-bind:id="'target-preview-' + i">
              <td v-bind:colspan="budgetTypeColumns.length">
                <amsTargetPreview-element
                  :clusterDetails = "record.clusterInfo"
                  :bid = "record.defaultBid"
                  :lineItemId = "record.lineItemId"
                ></amsTargetPreview-element>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { locale, Input, Row, Col } from "iview";
import ApiService from "./ApiService.js";
import lang from "iview/dist/locale/en-US";
import { mapState, mapMutations } from "vuex";
import TargetPreview from './AmsTargetPreview.vue'

locale(lang); // iview is default chinese

export default {
  components: {
    'amsTargetPreview-element': TargetPreview,
    "i-input": Input,
    Row,
    "column": Col
  },
  created: function () {
    this.BudgetValidation(this.budgetTypePivot.records, true);
  },
  mounted: function () {},
  props: {
    isSubmission: { default: true }
  },
  data: function () {
    return {
      budgetUpdatedLineitems: [],
      budgetValue: 0,
      minimumBudgetINR: 50,
      minimumBudgetDollar: 1,
      reserveEditMode: false,
      budgetTypeVerified: true,
      budgetTypeVerifiedDisable: false,
      budgetTypeColumns: [
        "Campaign Name",
        "Campaign Type",
        "Targeting Cluster",
        "Budget",
        "Status",
        "Schedule"
      ],
      campaignStatus: {
        1: "Active",
        3: "Paused",
        8: "Success",
        10: "Failed"
      }
    };
  },
  computed: {
    ...mapState([
      "amsSdEntities",
      "amsEntitiesResponse",
      "selectedPublisher",
      "publisher",
      "isCampaignLauncherVerificationEnabled"
    ]),
    budgetTypePivot: function () {
      const amsEntities = this.amsEntitiesResponse.data.amsEntities;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.budgetTypeVerified = false;
      }
      if (!amsEntities.length) {
        this.budgetTypeVerified = true;
        return { records: [], campaigns: 0 };
      }
      let records = amsEntities.map(campaign => ({
        campaignName: campaign.name,
        editingBudget: false,
        currencyCode: campaign.additionalData.bpcurrency.toLocaleString(),
        totalBudget: campaign.additionalData.totalBudget.toLocaleString(),
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        campaignType: this.getCampaignType(),
        clusterName: campaign.additionalData.cluster.name,
        budget: campaign.additionalData.budget.toLocaleString().split(',').join(''),
        status: this.campaignStatus[campaign.status],
        schedule: campaign.endDate == null ? new Date(campaign.startDate).toDateString() : new Date(campaign.startDate).toDateString() + " To " + new Date(campaign.endDate).toDateString(),
        bpCurrency: campaign.additionalData.bpcurrency,
        clusterInfo: campaign.additionalData,
        hasCluster: Object.keys(campaign.additionalData.cluster).length > 0,
        defaultBid: campaign.childEntities.length > 0 ? campaign.childEntities[0].defaultBid : campaign.defaultBid,
        lineItemId: campaign.lineItemId
      }));
      let totalCampaigns = records.length;
      var campaignsToBeExported = records.filter(record => record.status != this.campaignStatus[8]);
      this.budgetTypeVerifiedDisable = !campaignsToBeExported.length > 0;
      return { records: records, campaigns: totalCampaigns };
    },
    allVerified: function () {
      this.$emit("elementsVerified", [
        { value: this.budgetTypeVerified, label: "Campaign Setup" }
      ]);
      return "";
    }
  },
  methods: {
    ...mapMutations(["Set_Ams_sd_entities"]),
    BudgetValidation: function (records, showMessage) {
      let minimumBudget = records[0].currencyCode == "INR" ? this.minimumBudgetINR : this.minimumBudgetDollar;
      let allBudgetsValid = true;
      for (var i = 0; i < records.length; i++) {
        if (parseInt(records[i].budget) < minimumBudget && !this.budgetVerified) {
          allBudgetsValid = false;
          if (showMessage) {
            this.$Message.error({
              background: true,
              content: "Budget should be greater than " + minimumBudget + " for the campaign" + records[i].campaignName
            });
          }
          break;
        }
      }
      this.$emit('allBudgetsVerified', allBudgetsValid);
    },
    editBudget (index) {
      this.$Message.info({
        background: true,
        content: "Editing budget will effect the LineItem budget in MediaPlan"
      });
      this.reserveEditMode = true;
      this.budgetValue = this.budgetTypePivot.records[index].budget;
      this.budgetTypePivot.records[index].editingBudget = true;
    },
    closeBudgetEditing (index) {
      this.budgetTypePivot.records[index].editingBudget = false
      this.reserveEditMode = false;
    },
    saveBudgetValue (index, value) {
      let minBudget = this.budgetTypePivot.records[index].currencyCode == "INR" ? this.minimumBudgetINR : this.minimumBudgetDollar;
      if (value < minBudget) {
        this.$Message.error({
          background: true,
          content: "Budget should be greater than " + minBudget + " for the campaign" + this.budgetTypePivot.records[index].campaignName
        });
      } else {
        this.budgetTypePivot.records[index].budget = value;
        this.reserveEditMode = false;
        let endDate = new Date(this.budgetTypePivot.records[index].endDate);
        let startDate = new Date(this.budgetTypePivot.records[index].startDate);
        let lineitemCampaigns = this.budgetTypePivot.records.filter((x, ind) => x.lineItemId == this.budgetTypePivot.records[index].lineItemId).map((x, ind) => ({ budget: x.budget, index: ind }));
        let dateDiff = this.budgetTypePivot.records[index].endDate != undefined ? ((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) + 1 : 30;
        let updatedTotalBudget = value * dateDiff;
        lineitemCampaigns.forEach(ele => {
          if (ele.index != index) {
            updatedTotalBudget += parseInt(ele.budget) * dateDiff
          }
        })
        this.budgetTypePivot.records[index].editingBudget = false;
        lineitemCampaigns.forEach(ele => {
          this.budgetTypePivot.records[ele.index].totalBudget = updatedTotalBudget;
          this.amsEntitiesResponse.data.amsEntities[ele.index].additionalData.totalBudget = updatedTotalBudget;
        })
        let lineitemIndex = this.budgetUpdatedLineitems.findIndex(object => object.LineItemId == this.budgetTypePivot.records[index].lineItemId);
        if (lineitemIndex === -1) {
          this.budgetUpdatedLineitems.push({
            LineItemId: this.budgetTypePivot.records[index].lineItemId,
            TotalBudget: updatedTotalBudget
          });
        } else {
          this.budgetUpdatedLineitems[lineitemIndex].TotalBudget = updatedTotalBudget;
        }
        this.amsEntitiesResponse.data.amsEntities[index].additionalData.budget = value;
        let requestParam = {
          EntityData: JSON.stringify(this.amsEntitiesResponse.data),
          LineItemDetails: this.budgetUpdatedLineitems
        }
        this.Set_Ams_sd_entities(requestParam);
        this.BudgetValidation(this.budgetTypePivot.records, false);
      }
    },
    getCampaignType: function () {
      if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts) {
        return "Sponsored Products";
      } else if (this.selectedPublisher == this.publisher.AMS.SponsoredBrands) {
        return "Sponsored Brands";
      }
      return "None";
    }
  }
};
</script>

<style scoped>
tr.disable-row{
  pointer-events: none;
  background-color: #f9f9f9;
}
.title-sticky {
  position: sticky;
  padding: 0px 5px;
  position: sticky;
  top: 100px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
}
.verified-action {
  float: right;
  padding: 10px;
}
.checkbox-status-label {
  padding-right: 5px;
  color: #999;
  cursor: pointer;
}
input[type="checkbox"] {
  vertical-align: text-bottom;
}
.total-footer {
  font-weight: 600;
  background-color: #eee;
}
.table-footer-label {
  border: 1px solid #ddd;
  padding: 0px 5px;
}
.target-label {
  padding-top: 10px;
}
.target-sub-label {
  padding-top: 10px;
  font-size: 0.9em;
  color: #979898;
  max-width: 420px;
}
.page-label {
  padding: 10px 5px;
  position: sticky;
  top: 140px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.creatives-li {
  padding-bottom: 5px;
  list-style: none;
}
.more-previews {
  font-size: 1.3em;
}
.data-right {
  text-align: right;
}
.data-center {
  text-align: left;
  vertical-align: middle;
}
.upper-case {
  text-transform: uppercase;
}
::v-deep .ivu-modal-footer {
  display: none;
}
.table-header{
  border:1px solid #ddd !important;
  padding:10px !important;
}
</style>
