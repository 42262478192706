export default {
  getFlowByIndex: (state) => (index) => {
    return state.authFlows[index];
  },
  getStageByIndex: (state) => (flowIndex, stageIndex) => {
    return state.authFlows[flowIndex].Stages[stageIndex];
  },
  getFactionByIndex: (state) => (flowIndex, stageIndex, factionIndex) => {
    return state.authFlows[flowIndex].Stages[stageIndex].Factions[factionIndex];
  },
  getOtherFlowsAppliesToNames: (state) => (flowIndex) => {
    var copyFlows = JSON.parse(JSON.stringify(state.authFlows));
    copyFlows.splice(flowIndex, 1); // remove current flow
    var otherFlowsAppliesToNames = copyFlows.map(x => x.AppliesTo).flat().map(x => x.Name);
    return otherFlowsAppliesToNames;
  },
  getAllUsersInFirstFlowCurrentStage: (state) => {
    if (state.authFlows.length > 0) {
      var flow = state.authFlows[0];
      var unApprovedStages = flow.Stages.filter(x => typeof (x.Status) === 'undefined' || (x.Status && x.Status != "APPROVED"));
      if (unApprovedStages.length > 0) {
        var users = unApprovedStages[0].Factions.map(f => f.Users).flat().map(u => u.Id);
        return [...new Set(users)]; // return distinct users
      }
      return [];
    }
    return [];
  },
  getAllUsersInFirstFlow: (state) => {
    if (state.authFlows.length > 0) {
      var flow = state.authFlows[0];
      var users = flow.Stages.map(x => x.Factions).flat().map(f => f.Users).flat().map(u => u.Id);
      return [...new Set(users)]; // return distinct users
    } else {
      return [];
    }
  },
  getAllUsersInFirstFlowUnApprovedFactions: (state) => {
    if (state.authFlows.length > 0) {
      var flow = state.authFlows[0];
      var approvalUserList = [];
      var rejectedUserList = [];

      var usersInvolvedInAuthorization = flow.Stages.filter(x => x.Status !== "APPROVED").map(x => x.Factions).flat().map(x => x.Users).flat().map(x => x.Id)
      var approvals = flow.Stages.map(x => x.Factions).flat().filter(x => x.Approvals).map(x => x.Approvals).flat()
      if (approvals.length > 0) {
        approvalUserList = approvals.map(x => x.UserId)
      }

      var rejections = flow.Stages.map(x => x.Factions).flat().filter(x => x.Rejections).map(x => x.Rejections).flat()
      if (rejections.length > 0) {
        rejectedUserList = rejections.map(x => x.UserId)
      }

      var usersAlreadyTookAction = [...new Set([...approvalUserList, ...rejectedUserList])];
      var actionNeededFromUsers = [...new Set([...usersInvolvedInAuthorization.filter((user) => !usersAlreadyTookAction.includes(user))])];
      var bpUsersId = state.bpusers.map(x => x.Id);
      if (flow.Stages.map(x => x.PermissionType).includes(3) && bpUsersId.includes(window.currentUserId)) {
        actionNeededFromUsers.push(window.currentUserId)
      }
      return [...new Set(actionNeededFromUsers)]; // return distinct users
    } else {
      return [];
    }
  },
  getAllApprovedUsersInFirstFlow: (state) => {
    if (state.authFlows.length > 0) {
      var flow = state.authFlows[0];
      var factions = flow.Stages.map(x => x.Factions).flat()
      var users = factions.filter(x => x.Approvals).map(f => f.Approvals).flat().map(u => u.UserId);
      users = [...users, ...factions.filter(x => x.Rejections).map(f => f.Rejections).flat().map(u => u.UserId)]
      return [...new Set(users)]; // return distinct users
    } else {
      return [];
    }
  },
  getFlowForFacebookBulkAdCreation: (state) => {
    return state.authFlows.filter(x => x.AppliesTo.map(y => y.Name).includes("Social"))[0] || null;
  },
  getFlowForPublisher: (state) => (engineId) => {
    // var authFlowObject = state.authFlows.find(x => x.Id === parseInt(publisherId))
    // if (authFlowObject) {
    //   var usersPresent = authFlowObject.Stages[0].Factions[0].Users.length > 0 &&
    //   authFlowObject.Stages[1].Factions[0].Users.length > 0
    //   return (usersPresent && authFlowObject) || null;
    // } else {
    //   return null;
    // }
    return state.authFlows.find(x => x.Name === state.engineTypes[engineId].Name) || null
  },
  getFactionStatus: (state) => (flowIndex, stageIndex, factionIndex) => {
    var faction = state.authFlows[flowIndex].Stages[stageIndex].Factions[factionIndex];
    var permissionType = state.authFlows[flowIndex].Stages[stageIndex].PermissionType;
    var approvals = [];
    var rejections = [];
    if (faction.Approvals) {
      approvals = faction.Approvals;
    }
    if (faction.Rejections) {
      rejections = faction.Rejections;
    }
    if (rejections.length > 0) {
      return "REJECTED"
    }

    var users = faction.Users.map(x => x.Id)
    var approvedByUsers = []
    if (faction.Approvals) {
      approvedByUsers = faction.Approvals.map(x => x.UserId)
    }

    if (!permissionType) {
      return rejections.length > 0 ? "REJECTED" : approvals.length > 0 ? "APPROVED" : "IN_REVIEW";
    } else if (permissionType === state.permissionType.All) {
      var isApprovedByAllUser = users.reduce((a, b) => a && approvedByUsers.includes(b), true);
      if (isApprovedByAllUser) {
        return "APPROVED"
      }
    } else if (permissionType === state.permissionType.Any) {
      var approvedBy = users.filter(value => approvedByUsers.includes(value))
      if (approvedBy.length > 0) return "APPROVED"
    } else if (permissionType === state.permissionType.Skip) {
      return "APPROVED"
    }
    return rejections.length > 0 ? "REJECTED" : "IN_REVIEW";
  },
  getAllUsersWhoCanAuthorizeFactions: (state) => {
    if (state.authFlows.length > 0) {
      var flow = state.authFlows[0];

      var usersInvolvedInAuthorization = flow.Stages.filter(x => x.Status !== "APPROVED").map(x => x.Factions).flat().map(x => x.Users).flat().map(x => x.Id)
      if (flow.Stages.map(x => x.PermissionType).includes(3)) {
        usersInvolvedInAuthorization.push(window.currentUserId)
      }
      return [...new Set(usersInvolvedInAuthorization)]; // return distinct users
    } else {
      return [];
    }
  }

}
